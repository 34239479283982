import { Box } from '@mui/material';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectProcessScore } from '../ActivityLog/lib/processActivityLogSlice';
import { selectIsSubscriptionPremium } from '../../routes-old/Billing/state/billingSlice';

const ProcessScore = (): JSX.Element | null => {
  const score = useAppSelector(selectProcessScore);
  const isPremium = useAppSelector(selectIsSubscriptionPremium);

  if (!isPremium) {
    return null;
  }

  return (
    <Box className="row">
      <Box className="title">Forecast AI</Box>
      <Box className="value">
        {score}
        %
      </Box>
    </Box>
  );
};

export default ProcessScore;
