import { Box, styled, Typography } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const CustomizationMenuSubtitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 700,
  color: stagesetColors.newGrey[500],
}));

export const CustomizationSwitchWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '12px',
  width: '100%',
}));

export const CustomizationSwitchTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
}));

export const CustomizationMenuContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
}));
