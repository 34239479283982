import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessLinkDTO } from '../../../../shared/AccessLinkDTO';
import SwitchWithTitleWrapper from '../Utils/SwitchWithTitleWrapper';
import { ProtectedIcon20px, UnprotectedIcon20px } from '../../../icons/ProtectionIcons';
import { AppInput } from '../../../shared/AppInput/AppInput';
import UserChip from '../../../shared/AssigneeSelection/UserChip';

interface Props {
  isChecked: boolean;
  emailsAndDomains: string[];
  onLinkUpdate: (update: Partial<AccessLinkDTO>) => void;
}

const Hint = ({ children, error }: { children: ReactNode, error: boolean }) => (
  <Typography sx={{
    fontSize: '12px',
    lineHeight: '16px',
    color: (theme) => (error ? theme.palette.error[600] : theme.palette.grey[400]),
  }}
  >
    { children }
  </Typography>
);

const emailRegex = /@.+\..+/ig;

const ShareDealDomainProtection = ({
  isChecked,
  emailsAndDomains,
  onLinkUpdate,
}: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(isChecked);
  const [inputValue, setInputValue] = useState('');
  const [blur, setBlur] = useState(false);
  const [error, setError] = useState<string>();

  const onAddNewDomain = (e): void => {
    const newDomain: string = e.target.value;
    if (!emailRegex.test(newDomain)) {
      setError('Please use "@domain.com format"');
      return;
    }
    if (
      !emailsAndDomains.includes(newDomain)
      && !!newDomain.match(/^@[A-Z0-9-]+.+.[A-Z]{2,4}$/i)
      && newDomain.split('.').length > 1
    ) {
      onLinkUpdate({
        isDomainProtected: true,
        emailsAndDomains: [...emailsAndDomains, newDomain],
      });
      e.target.value = '';
      setInputValue('');
    }
    if (
      !emailsAndDomains.includes(newDomain)
      && !!newDomain.match(/^[A-Z0-9-]+.+.[A-Z]{2,4}$/i)
      && newDomain.split('.').length > 1
      && newDomain[0] !== '@'
    ) {
      onLinkUpdate({
        isDomainProtected: true,
        emailsAndDomains: [...emailsAndDomains, `@${newDomain}`],
      });
      e.target.value = '';
      setInputValue('');
    }
  };

  const onDomainCopy = (name: string) => {
    navigator.clipboard.writeText(name);
    enqueueSnackbar(
      `Domain ${name} copied`,
      {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000,
      },
    );
  };

  const endAornment = useMemo(() => {
    if (inputValue) {
      if (/@.+\..+/ig.test(inputValue)) {
        setError(undefined);
        return <Hint error={!!error}>Press enter</Hint>;
      }
      return <Hint error={!!error}>Invalid domain</Hint>;
    }
    setError(undefined);

    if (emailsAndDomains?.length) {
      return (
        <ProtectedIcon20px />
      );
    }
    return (<UnprotectedIcon20px />);
  }, [blur, emailsAndDomains, inputValue]);

  const onDomainDelete = (domainToDelete: string) => {
    const newDomains = emailsAndDomains.filter((item) => item !== domainToDelete);

    onLinkUpdate(
      {
        isDomainProtected: !!newDomains.length,
        emailsAndDomains: newDomains,
      },
    );
  };

  const onProtectionStatusChange = (newStatus: boolean) => {
    setChecked(newStatus);
    if (!!emailsAndDomains.length && newStatus) {
      onLinkUpdate({ isDomainProtected: true });
    }
    if (!newStatus) {
      onLinkUpdate({ isDomainProtected: false });
    }
  };

  return (
    <SwitchWithTitleWrapper
      isChecked={checked}
      onChange={onProtectionStatusChange}
      title={t('Room_Restricted_by_domain')}
      description={t('Room_Set_domain_restriction_to_protect_your_room')}
    >
      <AppInput
        placeholder={!emailsAndDomains.length ? t('Room_Use_domain_mask_to_specify') : undefined}
        multiline
        maxRows={4}
        color="primary"
        error={!!error}
        helperText={error}
        size="m"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        sx={{
          width: '100%',
          minHeight: '40px',
          paddingY: '0px',
          '& .MuiInputBase-root': {
            display: 'flex',
            gap: '4px',
          },
        }}
        InputProps={{
          startAdornment: emailsAndDomains.length ? (
            <>
              {
                emailsAndDomains.map((domain) => (
                  <UserChip
                    key={domain}
                    label={domain}
                    onDelete={
                      (e) => {
                        e.stopPropagation();
                        // setDomains((current) => current.filter((item) => item !== domain));
                        onDomainDelete(domain);
                      }
                    }
                    onClick={() => onDomainCopy(domain)}
                  />
                ))
              }
            </>
          ) : null,
          sx: {
            padding: '8.5px 14px',
            borderRadius: '8px',
            background: 'white',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            lineHeight: '24px',
            fontSize: '16px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
            },
          },
          endAdornment: endAornment,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          onFocus: () => {
            setBlur(false);
          },
          onBlur: (e) => {
            setBlur(true);
            onAddNewDomain(e);
          },
          onKeyDown: (e) => {
            if (e.nativeEvent.key === 'Enter') {
              e.currentTarget.blur();
            }
          },
          sx: {
            minWidth: '150px',
            flex: 1,
          },
        }}
      />
    </SwitchWithTitleWrapper>
  );
};

export default ShareDealDomainProtection;
