import { EActivationLinkType } from './auth.shcema';

export enum EJWTTokenType {
  AUTH = 'AUTH',
  PROCESS_AUTH = 'PROCESS_AUTH',
  GUEST_AUTH = 'GUEST_AUTH',
  SHARE = 'SHARE',
}

export interface IJWTTokenPayloadBase {
  exp?: number;
  iat?: number;
  sub?: string;
  tokenType: EJWTTokenType
}

export interface IAuthJWTokenPayload extends IJWTTokenPayloadBase {
  id: string;
  email: string;
  organizationId: string;
  templateId?: string;
}

export interface IGuestJWTokenPayload extends IJWTTokenPayloadBase {
  id: string;
  email: string;
  validHashes?: string[]
}

export interface IProcessAuthJWTokenPayload extends IJWTTokenPayloadBase {
  id: string;
  email: string;
  processId: string;
  templateId?: string;
}

export interface IShareJWTTokenPayload extends IJWTTokenPayloadBase {
  id?: string;
  accessLinkId: string;
  processId: string;
  accountLinkHash?: string;
  email?: string;
  isPasswordProtected?: boolean;
  isEmailProtected?: boolean;
  templateId?: string;
  emailApproved: boolean;
}

export interface IReqWrapper<T> extends TUnifiedToken {
  data: T;
}

export type TUnifiedToken = IAuthJWTokenPayload & IShareJWTTokenPayload & IProcessAuthJWTokenPayload;

export interface ILoginResponse {
  tokenType: EJWTTokenType;
  access_token: string;
  userId?: string;
  organizationId?: string;
  organizationDomain?: string;
}

export enum ELoginErrorName {
  NOT_ACTIVE_NO_LINK = 'NOT_ACTIVE_NO_LINK',
  NOT_ACTIVE_SOCIAL_SIGNUP = 'NOT_ACTIVE_SOCIAL_SIGNUP',
  NOT_ACTIVE_PASSWORD_SIGNUP = 'NOT_ACTIVE_PASSWORD_SIGNUP',
  DELETED_ACCOUNT_SIGNUP = 'DELETED_ACCOUNT_SIGNUP',
}

export interface LoginErrorResponse {
  errorName: ELoginErrorName;
  message: string;
  email: string;
  accountLinkType: EActivationLinkType;
}

