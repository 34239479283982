import { SvgIcon, SvgIconProps } from '@mui/material';

export const CancelIconBold12px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '12px',
    }}
    {...props}
  >
    <path
      d="M9 3L3 9"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 3L9 9"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
