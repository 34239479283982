import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { Controller, FieldError } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsFormWrapper,
  SettingsTabCardsGrid,
  SettingsTabCardsWrapper,
  SettingsTabContentWrapper,
  SettingsTabSubtitle,
  SettingsTabSubtitleWrapper,
} from './Styles/GeneralTabStyles';
import { AppButton } from '../../../shared/AppButton/AppButton';
import useUserOrganizationStatus from '../../../hooks/useSubscriptionStatus';
import { useOrganization } from '../../../hooks/useOrganization';
import { ECustomDomainEnableStatus } from '../../../../shared/CustomDomains';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import {
  ButtonWrapper,
  DomainSwitchWrapper,
  SubdomainInputWrapper,
  SubdomainLabel,
  SwitchTitle,
  TitleSwitchGroup,
  SubdomainSVGIconGreyWrapper,
  SubdomainSVGIconRedWrapper,
  CustomDomainDNSStatusText,
  CustomDomainContainer,
  CustomDomainDNSAlertWrapper,
} from './Styles/CustomDomainTabStyles';
import { UpdateIcon20 } from '../../../icons/UpdateIcon';
import { QuestionIcon20 } from '../../../icons/QuestionIcon';
import { useCustomDomainSettings } from '../../../hooks/useCustomDomainSettings';
import PremiumUserGuide from './tabItems/CustomDomainTab/PremiumUserGuide';
import { OrdinaryUserInitialHint, OrdinaryUserSuccessHint } from './tabItems/CustomDomainTab/OrdinaryUserHints';
import { AppTooltip } from '../../../shared/AppTooltip/AppTooltip';
import { AlertCircle20 } from '../../../icons/AlertCircle';
import { CheckCircle20 } from '../../../icons/CheckCircle';
import { AppDashedBorderBox } from '../../../shared/AppDashedBorderBox/AppDashedBorderBox';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';

const TeammanagerCustomDomainTab = (): JSX.Element => {
  const { organization } = useOrganization();
  const { hasCustomDomains, isPremium } = useUserOrganizationStatus();

  const {
    disabled,
    saving,
    getUrlFromOrigin,
    customDomainStatus,
    getData,
    submitCustomDomainForm,
    activateEnabled,
    activateLoading,
    handleActivateCustomDomain,
    handleCustomDomainDeactivationBySwitch,
    isActivated,
    isBasic,
    customDomainFormMethods,
  } = useCustomDomainSettings();
  const domainDNSStatus = customDomainStatus.DNSEnabled && customDomainStatus.Resolved && customDomainStatus.SSLEnabled;
  const [domainFieldEnabledBySwitch, setDomainFieldEnabledBySwitch] = useState<boolean>(
    organization.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE,
  );
  const { t } = useTranslation();

  const handleSwitchChange = (check: boolean) => {
    if (!hasCustomDomains() || !organization?.domain) return;
    setDomainFieldEnabledBySwitch(check);
    handleCustomDomainDeactivationBySwitch();
  };

  useEffect(() => {
    if (!hasCustomDomains() || !organization.domain || !organization.customDomain) {
      setDomainFieldEnabledBySwitch(false);
    } else {
      setDomainFieldEnabledBySwitch(true);
    }
  }, [organization]);

  const {
    handleSubmit,
    control,
  } = customDomainFormMethods;

  const guide = () => {
    if (hasCustomDomains()) return <PremiumUserGuide />;
    if (!hasCustomDomains() && organization.domain) return <OrdinaryUserSuccessHint />;
    return <OrdinaryUserInitialHint />;
  };

  const endAdornmentIconHandler = (value: string, error: FieldError | undefined) => {
    if (error?.message) {
      return (
        <SubdomainSVGIconRedWrapper>
          <AlertCircle20 />
        </SubdomainSVGIconRedWrapper>
      );
    } if (value !== '') {
      return (
        <SubdomainSVGIconGreyWrapper>
          <CheckCircle20 />
        </SubdomainSVGIconGreyWrapper>
      );
    }
    return null;
  };

  const DNSstatus = useMemo(() => {
    if (customDomainStatus.multipleDNS) {
      return (
        <AppTooltip
          title="Please check your DNS configuration for multiple records"
        >
          <CustomDomainDNSAlertWrapper>
            <AlertCircle20 />
            <CustomDomainDNSStatusText
              className="dns-status-multiple-dns"
            >
              Multiple DNS
            </CustomDomainDNSStatusText>
          </CustomDomainDNSAlertWrapper>
        </AppTooltip>
      );
    } if (domainDNSStatus) {
      return (
        <CustomDomainDNSStatusText
          className="dns-status-success"
        >
          Confirmed
        </CustomDomainDNSStatusText>
      );
    }
    return (
      <CustomDomainDNSStatusText>
        Unconfirmed
      </CustomDomainDNSStatusText>
    );
  }, [customDomainStatus]);

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Settings_Custom_Domain')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Setup which domain should be displayed for your company. This feature may require a special plan.
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      {(isPremium() || isBasic) && (
      <SettingsTabCardsWrapper>
        <SettingsTabCardsGrid
          className={classNames('cards-grid-aligned', { 'single-column-grid': isMobile })}
        >
          <SettingsFormWrapper
            className={classNames('settings-column', { 'settings-mobile': isMobile })}
            component="form"
            onSubmit={handleSubmit(submitCustomDomainForm)}
          >
            {/* domain */}
            <SubdomainInputWrapper>
              <Controller
                name="domain"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <AppInput
                    className="settings-menu-input"
                    fullWidth
                    label="Subdomain"
                    value={value}
                    onChange={onChange}
                    disabled={domainFieldEnabledBySwitch}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={{
                      endAdornment: endAdornmentIconHandler(value, error),
                    }}
                  />
                )}
              />
              <SubdomainLabel>
                {getUrlFromOrigin()}
              </SubdomainLabel>
            </SubdomainInputWrapper>
            <DomainSwitchWrapper>
              <TitleSwitchGroup
                onClick={() => handleSwitchChange(!domainFieldEnabledBySwitch)}
              >
                <AppSwitch
                  checked={domainFieldEnabledBySwitch}
                  disabled={!hasCustomDomains() || !organization?.domain}
                  onChange={(event) => handleSwitchChange(event.target.checked)}
                />
                <SwitchTitle>
                  Use custom domain
                </SwitchTitle>
                <AppTooltip
                  title="To activate custom domain, need to specify subdomain.
                We support first-level domains (name.com) and second level (subname.name.com)"
                  placement={isMobile ? 'left' : undefined}
                >
                  <SubdomainSVGIconGreyWrapper>
                    <QuestionIcon20 />
                  </SubdomainSVGIconGreyWrapper>
                </AppTooltip>
              </TitleSwitchGroup>
            </DomainSwitchWrapper>
            {/* Custom domain */}
            {hasCustomDomains() && domainFieldEnabledBySwitch && (
            <CustomDomainContainer>
              <Controller
                name="customDomain"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <AppInput
                    className="settings-menu-input"
                    fullWidth
                    label="Custom Domain"
                    value={value}
                    disabled={!organization.domain}
                    onChange={onChange}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              {organization.customDomain && organization.customDomain.length > 0 && (
              <>
                <SettingsTabSubtitleWrapper>
                  <SettingsTabSubtitle>
                    DNS Status
                  </SettingsTabSubtitle>
                  <AppDashedBorderBox />
                  {DNSstatus}
                  <AppIconButton
                    variant="lite-primary"
                    icon={<UpdateIcon20 />}
                    onClick={getData}
                  />
                </SettingsTabSubtitleWrapper>
                <AppButton
                  variant={isActivated !== ECustomDomainEnableStatus.ACTIVE ? 'secondary-success' : 'danger-secondary'}
                  disabled={!activateEnabled || activateLoading}
                  onClick={handleActivateCustomDomain}
                >
                  {isActivated !== ECustomDomainEnableStatus.ACTIVE ? 'Activate' : 'Deactivate'}
                </AppButton>
              </>
              )}
            </CustomDomainContainer>
            )}
            <ButtonWrapper>
              <AppButton
                type="submit"
                loading={saving}
                disabled={disabled}
                fullWidth
                variant="primary"
                size="l"
              >
                Save
              </AppButton>
            </ButtonWrapper>
          </SettingsFormWrapper>
          {guide()}
        </SettingsTabCardsGrid>
      </SettingsTabCardsWrapper>
      )}
    </SettingsTabContentWrapper>
  );
};

export default TeammanagerCustomDomainTab;
