import { TabPanel, TabPanelProps } from '@mui/lab';
import {
  Box, FormControl, FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  PRICING_PERIODS,
  PricingPeriod,
} from '../../../../../shared/pricing-table/PricingTableTypes';
import { handleNumericalData } from '../../../../features/Stages/Helpers/PricingTableHelpers';
import { stagesetColors } from '../../../../theme/stagesetPalette';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import { AppSwitch } from '../../../../shared/AppSwitch/AppSwitch';
import { PTTabHeader, PTTabToggleButton, PTToggleButtonGroup } from '../../../../features/Stages/Styles/SidebarPricingTableTabStyles';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { DEFAULT_PRODUCT_DTO, ProductWithOwnerDTO } from '../../../../../shared/library/LibraryDTO';
import LibraryFeedHeader from '../LibraryFeedHeader';
import { useOrganization } from '../../../../hooks/useOrganization';
import {
  createProductItem,
  selectEditProductItem,
  selectLibraryFeedOpen,
  setLibraryFeedTab,
  updateProductItem,
} from '../../lib/librarySlice';
import { ELibraryFeedTab } from '../../helpers/library.helper';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { selectUserId } from '../../../../core/store/appState/appState';
import CurrencySelector from '../../../../shared/CurrencySelector/CurrencySelector';

enum EProductEditState {
  CLOSED = 'CLOSED',
  ADDING = 'ADDING',
  EDITING = 'EDITING',
  SAVING = 'SAVING',
}

interface Props extends TabPanelProps {
  value: ELibraryFeedTab,
}

const ProductFeedTab = ({ value }: Props): JSX.Element => {
  const { organization } = useOrganization();
  const editingProduct: ProductWithOwnerDTO = useAppSelector(selectEditProductItem);
  const defaultProduct = { ...DEFAULT_PRODUCT_DTO, currency: organization.currency };
  const isLibraryProductSideMenuOpen: boolean = useAppSelector(selectLibraryFeedOpen);
  const [productObject, setProductObject] = useState<ProductWithOwnerDTO>(editingProduct || defaultProduct);
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  let paramsState: EProductEditState = editingProduct ? EProductEditState.EDITING : EProductEditState.ADDING;
  const [unitsInputError, setUnitsInputError] = useState<boolean>(false);
  const [unitsMinInputError, setUnitsMinInputError] = useState<boolean>(false);
  const [unitsMaxInputError, setUnitsMaxInputError] = useState<boolean>(false);
  const [unitsHelperText, setUnitsHelperText] = useState<string>('');
  const [minUnitsHelperText, setMinUnitsHelperText] = useState<string>('');
  const [maxUnitsHelperText, setMaxUnitsHelperText] = useState<string>('');
  const [openPricingPeriod, setOpenPricingPeriod] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<string | undefined>(productObject?.pricingPeriod || '');

  const handleUnitsInput = (event: string | undefined) => {
    const newValue = handleNumericalData({
      currentValue: event,
      previousValue: productObject.units.value,
      fraction: true,
    });
    const units = {
      ...productObject.units || {
        enabled: false, min: 0, max: undefined, value: 0,
      },
      value: newValue,
    };
    if (productObject.units.enabled) {
      if (newValue
        && productObject.units.min
        && newValue < productObject.units.min) {
        setUnitsInputError(true);
        setUnitsHelperText('units must be greater than min');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      if (newValue
        && productObject.units.max
        && newValue > productObject.units.max) {
        setUnitsInputError(true);
        setUnitsHelperText('units must be less than max');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
    }
    setUnitsInputError(false);
    setUnitsHelperText('');
    setProductObject({
      ...productObject,
      units,
    });
    return null;
  };

  const updateCurrency = (currency: string) => {
    setProductObject({
      ...productObject,
      currency,
    });
  };

  const handleMinUnitsInput = (event: string | undefined) => {
    if (productObject.units.enabled) {
      const newValue = handleNumericalData({
        currentValue: event,
        previousValue: productObject.units.min,
        fraction: true,
      });
      const units = {
        ...productObject.units || {
          enabled: false, min: 0, max: undefined, value: 0,
        },
        min: newValue,
      };
      if (newValue
        && productObject.units.value
        && newValue > productObject.units.value) {
        setUnitsMinInputError(true);
        setMinUnitsHelperText('min must be less than units');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      if (newValue
        && productObject.units.max
        && newValue > productObject.units.max) {
        setUnitsMinInputError(true);
        setMinUnitsHelperText('min must be greater than max');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      setUnitsMinInputError(false);
      setMinUnitsHelperText('');
      setProductObject({
        ...productObject,
        units,
      });
      return null;
    } return null;
  };

  const handleMaxUnitsInput = (event: string | undefined) => {
    if (productObject.units.enabled) {
      const newValue = handleNumericalData({
        currentValue: event,
        previousValue: productObject.units.min,
        fraction: true,
      });
      const units = {
        ...productObject.units || {
          enabled: false, min: 0, max: 0, value: 0,
        },
        max: newValue,
      };
      if (newValue
        && productObject.units.value
        && newValue < productObject.units.value) {
        setUnitsMaxInputError(true);
        setMaxUnitsHelperText('max must be greater than units');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      if (newValue
        && productObject.units.min
        && newValue < productObject.units.min) {
        setUnitsMaxInputError(true);
        setMaxUnitsHelperText('max must be greater than min');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      setUnitsMaxInputError(false);
      setMaxUnitsHelperText('');
      setProductObject({
        ...productObject,
        units,
      });
      return null;
    } return null;
  };

  useEffect(() => {
    if (productObject.units.enabled) {
      handleMinUnitsInput(productObject.units.min?.toString());
      handleMaxUnitsInput(productObject.units.max?.toString());
    } else {
      setUnitsInputError(false);
      setUnitsMinInputError(false);
      setUnitsMaxInputError(false);
      setUnitsHelperText('');
    }
  }, [productObject.units.enabled]);

  useEffect(() => {
    setSelectedPeriod(productObject.pricingPeriod || '');
  }, [productObject.pricingPeriod]);

  useEffect(() => {
    setProductObject(editingProduct);
    paramsState = editingProduct ? EProductEditState.EDITING : EProductEditState.ADDING;
  }, [editingProduct]);

  useEffect(() => {
    if (!isLibraryProductSideMenuOpen) {
      setProductObject(defaultProduct);
    }
  }, [isLibraryProductSideMenuOpen]);

  const handleSubmit = () => {
    const payload = {
      ...productObject,
      userId: userId || '',
      organizationId: organization.id || '',
    };
    if (paramsState === EProductEditState.EDITING) {
      dispatch(updateProductItem(payload));
    } else {
      dispatch(createProductItem((payload)));
    }
    dispatch(setLibraryFeedTab({
      open: false,
      type: ELibraryFeedTab.NONE,
      item: undefined,
    }));
  };

  return (
    <TabPanel
      value={value}
      id="pricing-table-side-panel"
      sx={{
        padding: '0px',
        // display: tab === ESidebarTabs.FILTERS ? 'flex' : 'none',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        backgroundColor: stagesetColors.white[100],
        width: '100%',
      }}
    >
      <LibraryFeedHeader name={productObject.name} />
      <Box
        sx={{
          padding: '24px',
          height: 'calc(100% - 128px)',
          backgroundColor: stagesetColors.background[100],
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box>
            <AppInput
              fullWidth
              label="Name"
              value={productObject.name}
              onChange={(event) => {
                setProductObject({
                  ...productObject,
                  name: event.target.value,
                });
              }}
            />
          </Box>
          <Box>
            <AppInput
              fullWidth
              label="Description"
              defaultValue={productObject.shortDescription}
              value={productObject.shortDescription}
              multiline
              rows={4}
              onChange={(event) => {
                setProductObject({
                  ...productObject,
                  shortDescription: event.target.value,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              paddingTop: '16px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flex: 1,
                paddingRight: '4px',
              }}
            >
              <InputLabel id="role-select-label">Model</InputLabel>
              <Select
                fullWidth
                labelId="role-select-label"
                id="period-select"
                open={openPricingPeriod}
                onClose={() => {
                  setOpenPricingPeriod(false);
                }}
                onOpen={() => {
                  setOpenPricingPeriod(true);
                }}
                value={selectedPeriod}
                onChange={(event: any) => {
                  setProductObject({
                    ...productObject,
                    pricingPeriod: event.target.value,
                  });
                }}
                sx={{
                  borderRadius: '10px',
                  '& .MuiSelect-select': {
                    padding: '8px',
                  },
                }}
              >
                {
                  Object.keys(PRICING_PERIODS).map((key) => (
                    <MenuItem value={key} key={key}>{PRICING_PERIODS[key as PricingPeriod].displayText}</MenuItem>
                  ))
                }
              </Select>
            </Box>
            <Box
              sx={{
                flex: 1,
                paddingLeft: '4px',
              }}
            >
              <InputLabel id="role-select-label">Currency</InputLabel>
              <CurrencySelector
                initialValue={organization.currency}
                onSelect={updateCurrency}
                disableLabel
                height="40px"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              border: '1px solid',
              borderColor: stagesetColors.newGrey[300],
              borderRadius: '16px',
              overflow: 'hidden',
              // eslint-disable-next-line max-len
              boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08), 0px 0px 1px 0px rgba(30, 41, 59, 0.08), 0px 1px 1px 0px rgba(30, 41, 59, 0.07), 0px 3px 2px 0px rgba(30, 41, 59, 0.04)',
            }}
          >
            <FormGroup
              sx={{
                display: 'flex',
                padding: '16px',
                gap: '24px',
              }}
            >
              <FormControl>
                <AppInput
                  error={unitsInputError}
                  helperText={unitsHelperText}
                  fullWidth
                  label="Units"
                  defaultValue={productObject.units.value}
                  value={productObject.units.value}
                  type="number"
                  onChange={(event) => {
                    handleUnitsInput(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <AppSwitch
                  checked={productObject.units.enabled}
                  onChange={(e) => {
                    const units = {
                      ...productObject.units || {
                        enabled: false, min: 0, max: undefined, value: 0,
                      },
                      enabled: e.target.checked,
                    };
                    setProductObject({
                      ...productObject,
                      units,
                    });
                  }}
                />
                <PTTabHeader>
                  Variable quantity
                </PTTabHeader>
              </FormControl>
            </FormGroup>
            {productObject.units.enabled && (
            <FormGroup
              sx={{
                display: 'flex',
                gap: '30px',
                background: stagesetColors.background[200],
                padding: '16px 16px 24px 16px',
                borderTop: '1px solid',
                borderColor: stagesetColors.newGrey[300],
                boxShadow: '0px 0px 4px 0px rgba(2, 69, 115, 0.15) inset',
              }}
            >
              <FormControl>
                <AppInput
                  error={unitsMinInputError}
                  helperText={minUnitsHelperText}
                  fullWidth
                  label="Min value"
                  defaultValue={productObject.units.min}
                  value={productObject.units.min}
                  type="number"
                  onChange={(event) => {
                    handleMinUnitsInput(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl>
                <AppInput
                  error={unitsMaxInputError}
                  helperText={maxUnitsHelperText}
                  fullWidth
                  label="Max value"
                  defaultValue={productObject.units.max}
                  value={(productObject.units.max)}
                  type="number"
                  onChange={(event) => {
                    handleMaxUnitsInput(event.target.value);
                  }}
                />
              </FormControl>
            </FormGroup>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              border: '1px solid',
              borderColor: stagesetColors.newGrey[300],
              borderRadius: '16px',
              overflow: 'hidden',
              // eslint-disable-next-line max-len
              boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08), 0px 0px 1px 0px rgba(30, 41, 59, 0.08), 0px 1px 1px 0px rgba(30, 41, 59, 0.07), 0px 3px 2px 0px rgba(30, 41, 59, 0.04)',
            }}
          >
            <FormGroup
              sx={{
                width: '100%',
              }}
            >
              <FormControl
                sx={{
                  padding: '16px 16px 0px 16px',
                }}
              >
                <AppInput
                  fullWidth
                  label="Price"
                  defaultValue={productObject.price}
                  value={productObject.price}
                  type="number"
                  onChange={(event) => {
                    const newValue = handleNumericalData({
                      currentValue: event.target.value,
                      previousValue: productObject.price,
                      fraction: true,
                    });
                    setProductObject({
                      ...productObject,
                      price: newValue,
                    });
                  }}
                />
              </FormControl>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px',
                  alignItems: 'center',
                  padding: '16px',
                  borderBottom: productObject.tax.enabled ? '1px solid' : 'none',
                  borderColor: stagesetColors.newGrey[300],
                }}
              >
                <AppSwitch
                  checked={productObject.tax.enabled}
                  onChange={(e) => {
                    const tax = {
                      ...productObject.tax || {
                        enabled: false, isPercentage: false, value: 0,
                      },
                      enabled: e.target.checked,
                    };
                    setProductObject({
                      ...productObject,
                      tax,
                    });
                  }}
                />
                <PTTabHeader>
                  Tax
                </PTTabHeader>
              </FormControl>
              {productObject.tax.enabled
                && (
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '16px',
                      background: stagesetColors.background[200],
                      boxShadow: '0px 0px 4px 0px rgba(2, 69, 115, 0.15) inset',
                    }}
                  >
                    <AppInput
                      sx={{
                        width: '140px',
                      }}
                      label="Tax"
                      defaultValue={productObject.tax.value}
                      value={productObject.tax.value}
                      type="number"
                      onChange={(event) => {
                        const newValue = handleNumericalData({
                          currentValue: event.target.value,
                          previousValue: productObject.tax.value,
                          fraction: true,
                          isPercentage: productObject.tax.isPercentage,
                        });
                        const tax = {
                          ...productObject.tax || { enabled: false, isPercentage: false, value: 0 },
                          value: newValue,
                        };
                        setProductObject({
                          ...productObject,
                          tax,
                        });
                      }}
                    />
                    <PTToggleButtonGroup
                      color="primary"
                      value={productObject.tax.isPercentage ? '0' : '1'}
                      exclusive
                      onChange={(e, toggleValue) => {
                        const newValue = handleNumericalData({
                          currentValue: `${productObject.tax.value}`,
                          fraction: true,
                          togglePercents: toggleValue === '0',
                        });
                        const tax = {
                          ...productObject.tax || {
                            enabled: false, isPercentage: false, value: 0,
                          },
                          isPercentage: toggleValue === '0',
                          value: newValue,
                        };
                        setProductObject({
                          ...productObject,
                          tax,
                        });
                      }}
                      aria-label="Platform"
                    >
                      <PTTabToggleButton
                        value="0"
                      >
                        %
                      </PTTabToggleButton>
                      <PTTabToggleButton
                        value="1"
                      >
                        {getSymbolFromCurrency(productObject.currency)}
                      </PTTabToggleButton>
                    </PTToggleButtonGroup>
                  </FormControl>
                )}
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '16px',
                  gap: '12px',
                  borderTop: '1px solid',
                  borderBottom: productObject.discount.enabled ? '1px solid' : 'none',
                  borderColor: stagesetColors.newGrey[300],
                }}
              >
                <AppSwitch
                  checked={productObject.discount.enabled}
                  onChange={(e) => {
                    const discount = {
                      ...productObject.discount || {
                        enabled: false, name: '', isPercentage: false, value: 0,
                      },
                      enabled: e.target.checked,
                    };
                    setProductObject({
                      ...productObject,
                      discount,
                    });
                  }}
                />
                <PTTabHeader>
                  Discount
                </PTTabHeader>
              </FormControl>
              {productObject.discount.enabled
                && (
                  <FormControl
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      padding: '16px',
                      background: stagesetColors.background[200],
                      boxShadow: '0px 0px 4px 0px rgba(2, 69, 115, 0.15) inset',
                    }}
                  >
                    <AppInput
                      fullWidth
                      label="Name"
                      defaultValue={productObject.discount.name}
                      value={productObject.discount.name}
                      onChange={(e) => {
                        const discount = {
                          ...productObject.discount || { enabled: false, isPercentage: false, value: 0 },
                          name: e.target.value,
                        };
                        setProductObject({
                          ...productObject,
                          discount,
                        });
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <AppInput
                        sx={{
                          width: '140px',
                        }}
                        label="Discount"
                        value={productObject.discount.value}
                        defaultValue={productObject.discount.value}
                        type="number"
                        onChange={(event) => {
                          const newValue = handleNumericalData({
                            currentValue: event.target.value,
                            previousValue: productObject.discount.value,
                            fraction: true,
                            isPercentage: productObject.discount.isPercentage,
                          });
                          const discount = {
                            ...productObject.discount || {
                              enabled: false, name: '', isPercentage: false, value: 0,
                            },
                            value: newValue,
                          };
                          setProductObject({
                            ...productObject,
                            discount,
                          });
                        }}
                      />
                      <PTToggleButtonGroup
                        color="primary"
                        value={productObject.discount.isPercentage ? '0' : '1'}
                        exclusive
                        onChange={(e, toggleValue) => {
                          const newValue = handleNumericalData({
                            currentValue: `${productObject.discount.value}`,
                            fraction: true,
                            togglePercents: toggleValue === '0',
                          });
                          const discount = {
                            ...productObject.discount || {
                              enabled: false, name: '', isPercentage: false, value: 0,
                            },
                            isPercentage: toggleValue === '0',
                            value: newValue,
                          };
                          setProductObject({
                            ...productObject,
                            discount,
                          });
                        }}
                        aria-label="Platform"
                      >
                        <PTTabToggleButton
                          value="0"
                        >
                          %
                        </PTTabToggleButton>
                        <PTTabToggleButton
                          value="1"
                        >
                          {getSymbolFromCurrency(productObject.currency)}
                        </PTTabToggleButton>
                      </PTToggleButtonGroup>
                    </Box>
                  </FormControl>
                )}
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: '56px',
          padding: '8px 16px',
          boxShadow: '0px 0px 7px -3px rgba(0, 57, 96, 0.1), 0px -5px 5px -2px rgba(0, 57, 96, 0.05)',
        }}
      >
        <AppButton
          disabled={unitsInputError || unitsMaxInputError || unitsMinInputError}
          onClick={handleSubmit}
          fullWidth
          variant={(unitsInputError || unitsMaxInputError || unitsMinInputError) ? 'transparent' : 'primary'}
          size="l"
        >
          {paramsState === EProductEditState.EDITING ? 'Save' : 'Add'}
        </AppButton>
      </Box>
    </TabPanel>
  );
};

export default ProductFeedTab;
