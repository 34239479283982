import { useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { TaskTitleWithEditor } from '../../TaskList/TaskTitleWithEditor';
import { AppCheckbox } from '../../../../shared/AppCheckbox/AppCheckbox';
import { AgendaItemContainer, AgendaItemControlsWrapper } from './styles/AgendaStyles';
import { DnDIcon16 } from '../../../../features/Tiptap/icons/DnDIcon';
import { AppDropdown } from '../../../../shared/AppDropdown/AppDropdown';
import TrashIcon from '../../../../icons/TrashIcon';
import { DuplicateIcon20 } from '../../../../icons/DuplicateIcon';
import { SubTaskType, SubTaskVariableType } from './AgendaSubTasks';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';

interface Props {
  id: number,
  pos: number;
  subTask: SubTaskType ;
  onSubtaskUpdate: (pos: number, data?: SubTaskVariableType) => void;
  onDuplicate: (pos: number, data: SubTaskVariableType) => void;
}

const AgendaSubtaskItem = ({
  id,
  pos,
  subTask,
  onSubtaskUpdate,
  onDuplicate,
}: Props) => {
  const itemContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
  });

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(itemContainerRef.current);
  };

  const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onTaskCheck = (checked?: boolean) => {
    onSubtaskUpdate(pos, { status: checked || false });
  };

  const onTitleUpdate = (name: string) => {
    onSubtaskUpdate(pos, { name });
  };

  const onDelete = () => {
    onSubtaskUpdate(pos);
    setAnchorEl(null);
  };

  const onDuplicateItem = () => {
    onDuplicate(pos, { name: subTask.name, status: subTask.status });
    setAnchorEl(null);
  };

  return (
    <AgendaItemContainer
      ref={setNodeRef}
      className={classNames({ 'agenda-items-container-mobile': isMobile })}
      sx={{
        transition,
        transform: CSS.Transform.toString(transform),
        '& .more-menu-button': {
          visibility: anchorEl ? 'visible' : 'hidden',
        },
        '&:hover': {
          '& .more-menu-button': {
            visibility: 'visible ',
          },
        },
      }}
    >
      <AgendaItemControlsWrapper
        ref={itemContainerRef}
      >
        {!isMobile && (
          <AppIconButton
            {...attributes}
            {...listeners}
            variant="lite-primary"
            ref={setActivatorNodeRef}
            className="more-menu-button"
            icon={<DnDIcon16 />}
            size="xxs"
            autoWidth
            onClick={onClick}
          />
        )}
        <AppCheckbox
          size="s"
          isEditable
          isChecked={!!subTask?.status}
          onCheckChange={onTaskCheck}
        />
      </AgendaItemControlsWrapper>
      <TaskTitleWithEditor
        title={subTask.name || 'New action'}
        onSave={onTitleUpdate}
        isEditable
        trackEvent={() => { }}
        fontWeight={400}
        maxRows={10}
        placeholder="New action"
        variant="lite-grey"
      />
      <AppDropdown
        id="section-options-menu"
        lastElementHasAnotherColor
        items={[
          {
            label: 'Duplicate',
            startIcon: <DuplicateIcon20 />,
            onClick: onDuplicateItem,
          },
          {
            label: '',
            divider: true,
          },
          {
            label: 'Delete',
            startIcon: <TrashIcon />,
            onClick: onDelete,
          },
        ]}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
        sx={{
          '& ul': {
            padding: '0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </AgendaItemContainer>
  );
};

export default AgendaSubtaskItem;
