import { createContext, useContext } from 'react';

export const NewPageContext = createContext('defaultValue');

export const NewPageContextProvider = ({
  children,
  props,
}: any): JSX.Element => (
  <NewPageContext.Provider value={props}>
    {children}
  </NewPageContext.Provider>
);

export const useNewPageContext = () => {
  const c = useContext(NewPageContext);
  return c;
};
