
export enum DealsType {
    SELLER_DEALS = 'SELLER_DEALS',
    BUYER_DEALS = 'BUYER_DEALS',
    TEAM_DEALS = 'TEAM_DEALS',
}

export enum CustomisableElementGroup {
    ALL = 'all',
    BUTTONS = 'buttons',
    CHECKBOXES = 'checkboxes',
    SIDEBAR = 'sidebar',
}