import {
  createAsyncThunk, createEntityAdapter, createSlice, EntityState,
} from '@reduxjs/toolkit';
import { ProcessDTO } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { ProcessUserDTO } from '../../../../shared/UserDTO';
import { ProcessApi } from '../../../api/ProcessApi';
import { EStateStatus } from '../../../core/commonTypes';
import { AsyncThunkConfig, RootState } from '../../../core/store/store';
import { DealsType } from '../../../../shared/process/Process';

const processApi = new ProcessApi();

interface BuyerDealsAdditionalState {
  status: EStateStatus;
  isProcessStatsLoaded: boolean;
  isFirstFetchFinished: boolean;
}

const buyerDealsAdapter = createEntityAdapter<ProcessDTO>({
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

export const dealsReducerName: string = 'buyer-deals';

export enum EBuyerDealsActions {
  GET_BUYER_DEALS = 'GET_BUYER_DEALS',
  GET_BUYER_DEALS_STATS = 'GET_BUYER_DEALS_STATS',
  GET_BUYER_DEALS_NOTIFICATIONS = 'GET_BUYER_DEALS_NOTIFICATIONS',
  LEAVE_DEAL = 'LEAVE_DEAL',
}

export type TDealsState = BuyerDealsAdditionalState & EntityState<ProcessDTO>;

export const initialState: TDealsState = buyerDealsAdapter.getInitialState({
  status: EStateStatus.IDLE,
  isProcessStatsLoaded: false,
  isFirstFetchFinished: false,
});

export const getBuyerDeals = createAsyncThunk(
  `${dealsReducerName}/${EBuyerDealsActions.GET_BUYER_DEALS}`,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.getBuyerDeals({
      search: state.dealsGridFilter.search,
      params: state.dealsGridFilter.gridFilters[DealsType.BUYER_DEALS],
    });
  },
);

export const leaveDeal = createAsyncThunk<ProcessUserDTO, string, AsyncThunkConfig>(
  `${dealsReducerName}/${EBuyerDealsActions.LEAVE_DEAL}`,
  async (payload: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.leaveDeal({
      userId: state.app.user.id,
      processId: payload,
    });
  },
);

export const buyerDealsSlice = createSlice({
  name: dealsReducerName,
  initialState,
  reducers: {
    clearBuyerDeals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuyerDeals.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getBuyerDeals.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        buyerDealsAdapter.setAll(state, action.payload);
        state.isProcessStatsLoaded = false;
        state.isFirstFetchFinished = true;
      })
      .addCase(getBuyerDeals.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(leaveDeal.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(leaveDeal.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        buyerDealsAdapter.removeOne(state, action.meta.arg);
      })
      .addCase(leaveDeal.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      });
  },
});

export const {
  clearBuyerDeals,
} = buyerDealsSlice.actions;

const { selectAll } = buyerDealsAdapter.getSelectors();

export const allBuyerDealsSelector = (state: RootState) => selectAll(state.buyerDeals);
export const selectFirstFetchStatus = (state: RootState): boolean => state.buyerDeals.isFirstFetchFinished;
