import { useState, useEffect } from 'react';
import { UserOrganizationApi } from '../../../api/UserOrganizationApi';
import { UserRolesDTO } from '../../../../shared/user-organization';

const userOrganizationApi: UserOrganizationApi = new UserOrganizationApi();

export function useUserOrganizationFetch(organizationId: string) {
  const [data, setData] = useState<UserRolesDTO[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userOrganizationApi.getOrganizationUsers(organizationId);
        setData(response);
      } catch (e: any) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationId]);

  return { data, isLoading, error };
}
