import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { ErrorCode, FileRejection } from 'react-dropzone';
import { isMobile } from 'react-device-detect';
import { FilesDragAndDrop } from '../../../common/FilesDragAndDrop/FilesDragAndDrop';
import { MediaFieldUploadIcon } from '../../../icons/DndIcons/DnDIcons';
import {
  imgFormats, allFormats, checkFileAcceptance, getSubtypeByMIME,
} from '../Helpers/MediaFieldHelpers';
import { AppLoader } from '../../../shared/AppLoader/AppLoader';
import { useCurrentStage } from '../../../hooks/useCurrentStage';
import { StageContentType } from '../../../../shared/Stage';
import { ItemContext } from '../../Layout/ItemContextProvider';
import { EMediaFieldStorageType } from '../../../../shared/Field/Field';

interface Props {
  onFilesUpload: (files: File[]) => void | Promise<void>;
  uploadLimitation?: string | string[];
  maxSize?: number;
  description?: string;
  extensions?: string[];
}

const MediaFieldUpload = ({
  onFilesUpload,
  uploadLimitation,
  maxSize,
  description,
  extensions,
}: Props): JSX.Element => {
  const { onUpdate } = useContext(ItemContext);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [formatError, setFormatError] = useState<string>('');
  const currentStage = useCurrentStage();

  const uploadContainerHeight = () => {
    if (currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE && isMobile) return 'calc(100vh - 52px)';
    if (currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE) return '100vh';
    return '110px';
  };

  const handleUploadStart = async (data: File[], rejections?: FileRejection[]): Promise<void> => {
    if (!!rejections && !!rejections.length) {
      const reason = rejections[0].errors[0].code;
      if (reason === ErrorCode.FileInvalidType) {
        setFormatError('Wrong file format!');
        return;
      }
      if (reason === ErrorCode.FileTooLarge) {
        setFormatError('Wrong file size!');
        return;
      }
    }
    if (!data.length) {
      setFormatError('File couldn\'t be handled. Check constraints and try again.');
      return;
    }
    if (!checkFileAcceptance(data[0].name, extensions)) {
      setFormatError('Wrong file format');
    } else {
      setFormatError('');
      setUploading(true);
      try {
        await onFilesUpload(data);
        // const url = URL.createObjectURL(data[0]);
        // if (onUpdate) {
        //   onUpdate(
        //     {
        //       url,
        //       subType: getSubtypeByMIME(data[0].type),
        //       storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
        //     },
        //     [{
        //       localUrl: url,
        //       name: data[0].name,
        //       type: data[0].type,
        //     }],
        //   );
        // }
      } catch (e) {
        console.log('error occurred file uploading process');
      }
      setUploading(false);
    }
  };

  const dndText: JSX.Element = (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <MediaFieldUploadIcon />
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            color: 'rgba(32, 32, 32, 1)',
            marginLeft: '6px',
          }}
        >
          Drop your file here or
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            marginLeft: '5px',
            color: '#0073F5',
          }}
        >
          &nbsp;browse
        </Typography>
      </Box>
      <Box>
        <Typography
          className="DnDAcceptedFormats"
          sx={{
            marginTop: '8px',
            fontSize: '12px',
            lineHeight: '16px',
            color: 'rgba(112, 132, 180, 1)',
          }}
        >
          {
            description || (uploadLimitation ? imgFormats.join(', ').toUpperCase() : allFormats.join(', ').toUpperCase())
          }
        </Typography>
        {
          formatError && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '0px',
              }}
            >
              <Typography
                color="error"
                sx={{
                  fontSize: '12px',
                  paddingTop: '5px',
                }}
              >
                {formatError}
              </Typography>
            </Box>
          )
        }
      </Box>
    </>
  );

  return (
    <Box
      id="upload-process-field"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: uploadContainerHeight,
      }}
    >
      {
        !isUploading
        && (
          <Box
            sx={{
              height: '110px',
              width: '100%',
            }}
          >
            <FilesDragAndDrop
              dropzoneOptions={{
                accept: extensions && `.${extensions.join(', .')}`,
                maxSize,
              }}
              onFileDrop={handleUploadStart}
              text={dndText}
              icon="none"
              border="1px dashed #C8D0E0"
              styles={{
                borderRadius: '8px',
                height: '110px',
                maxWidth: 'unset',
                minHeight: '110px',
                padding: '0px',
                background: 'white',
              }}
              textStyle={{
                alignSelf: 'center',
                fontSize: '14px',
                paddingLeft: '3px',
              }}
              textContainerStyles={{
                background: 'white',
              }}
            />
          </Box>
        )
      }
      {
        isUploading
        && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppLoader size={24} />
          </Box>
        )
      }
    </Box>
  );
};

export default MediaFieldUpload;
