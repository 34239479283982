import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';
import {
  EProcessBackgroundStorageType,
  EProcessBackgroundType,
} from '../../../../shared/process/ProcessMilestoneActionDTO';

const dealCustomizationReducerName: string = 'dealCustomization';

export interface CustomizationProcessStyles {
  localId: number | undefined,
  displayBackground: boolean | undefined,
  url: string | undefined,
  backgroundColor: string | undefined,
  backgroundStorage: string | undefined,
  type: EProcessBackgroundType | undefined,
  theme: {
    sidebar?: string | undefined,
  },
  displayLogo: boolean | undefined,
}

const initialState: CustomizationProcessStyles = {
  localId: undefined,
  displayBackground: true,
  url: undefined,
  backgroundColor: undefined,
  backgroundStorage: undefined,
  type: undefined,
  theme: {
    sidebar: undefined,
  },
  displayLogo: true,
};

export const dealCustomizationSlice = createSlice({
  name: dealCustomizationReducerName,
  initialState,
  reducers: {
    setDealStyles: (state, { payload }: PayloadAction<CustomizationProcessStyles | undefined | Partial<CustomizationProcessStyles>>) => ({
      ...state,
      ...payload,
    }),
    setBackgroundType: (state, { payload }: PayloadAction<EProcessBackgroundType | undefined>) => ({
      ...state,
      type: payload,
    }),
    setBackgroundDisplayStatus: (state, { payload }: PayloadAction<boolean | undefined>) => ({
      ...state,
      displayLogo: payload,
    }),
    setLogoDisplayStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      displayBackground: payload,
    }),
    setDealThemePaletteSidebar: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      theme: {
        ...state.theme,
        sidebar: payload,
      },
    }),
    resetDealStyles: () => initialState,
  },
});

export const {
  setBackgroundType,
  setBackgroundDisplayStatus,
  setDealStyles,
  resetDealStyles,
  setLogoDisplayStatus,
  setDealThemePaletteSidebar,
} = dealCustomizationSlice.actions;

export const selectBackground = (state: RootState): number => state.dealCustomization.localId ?? state.process?.process?.styles?.localId;
// eslint-disable-next-line max-len
export const selectBackgroundType = (state: RootState): EProcessBackgroundType | undefined => state.dealCustomization.type ?? state.process?.process?.styles?.type;
// eslint-disable-next-line max-len
export const selectBackgroundStorageType = (state: RootState): EProcessBackgroundStorageType => state.dealCustomization?.backgroundStorage ?? state.process?.process?.styles?.backgroundStorage;
// eslint-disable-next-line max-len
export const selectProcessBackgroundDisplayStatus = (state: RootState): boolean => state.dealCustomization?.displayBackground ?? state?.process?.process?.styles?.displayBackground;
// eslint-disable-next-line max-len
export const selectLogoDisplayStatus = (state: RootState): boolean => state.dealCustomization?.displayLogo ?? state?.process?.process?.styles?.displayLogo ?? true;
// eslint-disable-next-line max-len
export const selectProcessBackgroundColor = (state: RootState): string => state.dealCustomization?.backgroundColor ?? state.process?.process?.styles?.backgroundColor;
// eslint-disable-next-line max-len
export const selectProcessBackgroundUrl = (state: RootState): string => state.dealCustomization?.url ?? state?.process?.process?.styles?.url ?? '';
// eslint-disable-next-line max-len
export const selectDealThemePaletteCommon = (state: RootState): string | undefined => state.dealCustomization?.theme?.common ?? state?.process?.process?.styles?.theme?.common;
// eslint-disable-next-line max-len
export const selectDealThemePaletteSidebar = (state: RootState): string | undefined => state.dealCustomization?.theme?.sidebar ?? state?.process?.process?.styles?.theme?.sidebar;
export const selectProcessDealStyles = (state: RootState): CustomizationProcessStyles => state.dealCustomization;
// eslint-disable-next-line max-len
export const selectInitialDealStyles = (state: RootState): string | undefined => state?.process?.process?.styles;
// eslint-disable-next-line max-len
export const selectProcessBackgroundInitialColor = (state: RootState): string => state?.process?.process?.styles?.backgroundColor;
// eslint-disable-next-line max-len
export const selectInitialDealPaletteCommon = (state: RootState): string | undefined => state?.process?.process?.styles?.theme?.common;
// eslint-disable-next-line max-len
export const selectInitialDealPaletteSidebar = (state: RootState): string | undefined => state?.process?.process?.styles?.theme?.sidebar;
