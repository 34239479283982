import { FormEvent, useState } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import { EOrganizationRoles, EUserOrganizationPermissions, RoleDTO } from '../../../../../../shared/permissions';
import { updateOrganizationOwner, updateOrganizationUser } from '../../../../../core/store/appState/appState';
import { useAppDispatch } from '../../../../../hooks/stateHooks';
import { stagesetColors } from '../../../../../theme/stagesetPalette';
import { CheckIcon20px } from '../../../../../icons/CheckIcon';
import {
  RoleField,
  TeamPopoverMenuItem,
  RoleTitle,
  TitleWrapperWithIcon,
  TitleWrapper,
  CellTitle,
  TeamTabMoreMenuPopover,
  TeamPopoverButton,
} from '../../Styles/TeamTabStyles';
import { MenuSettingsIcon20 } from '../../../../../icons/MenuIcons/SettingsIcon';
import { ChevronRight20 } from '../../../../../icons/ChevronRight';
import { useTeamTabTableContext } from '../TeamTabTableContext';
import { useOrganization } from '../../../../../hooks/useOrganization';
import useUserOrganizationPermissions from '../../../../../hooks/useOrganizationPermissions';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../../../../common/DeleteTypography';
import NormalizedSvgIcon from '../../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import AlertTriangle from '../../../../../icons/AlertTriangle.svg';

interface Props {
  mobilePopup?: boolean;
}

const RoleSelectorCell = ({
  mobilePopup,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const {
    organizationOwner, roles, roleNames, currentUser,
  } = useOrganization();
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const teamManager: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);

  const teammate = useTeamTabTableContext();
  const [isChangeOwnerConfirmationDialogOpen, setChangeOwnerConfirmationDialogOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const handleChangeOwner = () => {
    setChangeOwnerConfirmationDialogOpen(true);
  };

  const onChangeOwnerConfirm = (): void => {
    setChangeOwnerConfirmationDialogOpen(false);
    if (!!value && !!selectedRoleId) {
      dispatch(updateOrganizationOwner({
        userId: (teammate as any).id,
        ownerId: organizationOwner?.id || '',
        oldOwnerNewRoleId: value,
        ownerRoleId: selectedRoleId,
      }));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const userRole = roles?.find((x: RoleDTO) => x.name === EOrganizationRoles.SELLER);
  const adminRole = roles?.find((x: RoleDTO) => x.name === EOrganizationRoles.ADMIN);

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to change the Owner?
      If yes select role for the old owner
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {!!userRole && (<FormControlLabel value={userRole.id} control={<Radio />} label={roleNames[userRole.name]} />)}
          {!!adminRole && (<FormControlLabel value={adminRole.id} control={<Radio />} label={roleNames[adminRole.name]} />)}
        </RadioGroup>
      </FormControl>
    </DeleteTypography>
  );

  const onRoleSelect = (currentRoleId: string) => {
    if (!currentRoleId) return;

    const role: RoleDTO = roles?.find((x: RoleDTO) => x.id === currentRoleId);

    if (teamManager && teammate) {
      if (role.name === EOrganizationRoles.OWNER) {
        setSelectedRoleId(currentRoleId);
        handleChangeOwner();
      } else {
        dispatch(updateOrganizationUser({ ...teammate, rolesIds: [currentRoleId] }));
      }
      if (mobilePopup) setAnchor(null);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const isOwner: boolean = organizationOwner?.id === teammate?.id;
  const isCurrentUserOwner: boolean = !!organizationOwner?.id && (organizationOwner?.id === currentUser?.id);

  const currentRole: RoleDTO | undefined = roles?.find((role: RoleDTO) => role.id === teammate.rolesIds[0]);

  return (
    <>
      {
        isOwner
        && (
          <RoleTitle
            className={classNames({ 'role-title-desktop': !isMobile })}
          >
            {roleNames[EOrganizationRoles.OWNER]}
          </RoleTitle>
        )
      }
      {!isOwner
        && teamManager
        && !isMobile
        && (
        <RoleField
          variant="standard"
          id="Option-Select"
          fullWidth
          select
          color="secondary"
          value={teammate.rolesIds[0]}
          onChange={(event: FormEvent<HTMLSpanElement>) => onRoleSelect(event.target.value as string)}
          InputProps={{
            disableUnderline: true,
            sx: {
              color: stagesetColors.newGrey[800],
              fontSize: '16px',
              lineHeight: '22px',
              backgroundColor: stagesetColors.white[100],
              padding: isMobile ? '0px' : '5px 8px',
              marginLeft: isMobile ? '0px' : '-5px',
              marginRight: isMobile ? '-20px' : '0px',
              borderRadius: isMobile ? 'none' : '8px',
              border: isMobile ? 'none' : '1px solid',
              borderColor: stagesetColors.newGrey[100],
              '& .MuiSvgIcon-root': {
                width: '0',
                height: '0',
                visibility: 'hidden',
              },
              '& .MuiSelect-standard': {
                backgroundColor: 'transparent !important',
              },
            },
          }}
          SelectProps={{
            MenuProps: {
              autoFocus: false,
              PaperProps: {
                sx: {
                  marginLeft: isMobile ? '10px' : '40px',
                  marginTop: '8px',
                  border: '1px solid',
                  borderRadius: '8px',
                  borderColor: stagesetColors.newGrey[400],
                  paddingX: '8px',
                  fontSize: '16px',
                  lineHeight: '22px',
                  backgroundColor: stagesetColors.white[100],
                  color: stagesetColors.newGrey[800],
                  '& .MuiMenuItem-root.Mui-selected': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiSvgIcon-root': {
                      visibility: 'visible',
                    },
                  },
                },
              },
            },
          }}
        >
            {
              roles.map((role: RoleDTO) => {
                if (role.name !== EOrganizationRoles.OWNER || isCurrentUserOwner) {
                  return (
                    <TeamPopoverMenuItem
                      className="team-role-menu"
                      disableRipple
                      key={role.id}
                      value={role.id}
                    >
                      {roleNames[role.name]}
                      <CheckIcon20px />
                    </TeamPopoverMenuItem>
                  );
                } return null;
              })
            }
        </RoleField>
        )}
      {
        ((!isOwner && !teamManager)
        || (!mobilePopup && !isOwner && isMobile))
        && (
          <RoleTitle
            className={classNames({ 'role-title-desktop': !isMobile })}
          >
            { currentRole && roleNames ? roleNames[currentRole.name] : '' }
          </RoleTitle>
        )
      }
      {
        mobilePopup
        && !isOwner
        && isMobile
        && (
          <>
            <TeamPopoverButton
              fullWidth
              onClick={handleClick}
            >
              <TitleWrapperWithIcon>
                <MenuSettingsIcon20 />
                <TitleWrapper>
                  <CellTitle className="cell-title-mobile">
                    Role
                  </CellTitle>
                  <CellTitle className="cell-title-grey-small">
                    { currentRole && roleNames ? roleNames[currentRole.name] : '' }
                  </CellTitle>
                </TitleWrapper>
              </TitleWrapperWithIcon>
              <ChevronRight20 />
            </TeamPopoverButton>
            <TeamTabMoreMenuPopover
              className={classNames({ 'more-menu-popover-mobile': isMobile })}
              id={anchor ? 'team-tab-popover' : undefined}
              open={!!anchor}
              anchorEl={anchor}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {
              roles.map((role: RoleDTO) => {
                if (role.name !== EOrganizationRoles.OWNER || isCurrentUserOwner) {
                  return (
                    <TeamPopoverMenuItem
                      className={classNames(
                        { 'team-role-menu-selected': teammate.rolesIds[0] === role.id },
                        { 'team-role-menu': teammate.rolesIds[0] !== role.id },
                      )}
                      disableRipple
                      key={role.id}
                      value={role.id}
                      onClick={() => onRoleSelect(role.id)}
                    >
                      {roleNames[role.name]}
                      <CheckIcon20px />
                    </TeamPopoverMenuItem>
                  );
                } return null;
              })
        }
            </TeamTabMoreMenuPopover>
          </>
        )
      }
      <ConfirmationDialog
        isDialogOpen={isChangeOwnerConfirmationDialogOpen}
        onCancel={() => setChangeOwnerConfirmationDialogOpen(false)}
        onConfirm={onChangeOwnerConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Change"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </>
  );
};

export default RoleSelectorCell;
