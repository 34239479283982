import { SvgIcon } from '@mui/material';

export const AttachmentIcon = (): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    sx={{
      fontSize: '16px',
    }}
  >
    <path
      d="M14.2934 7.36678L8.1667 13.4934C7.41613 14.244
6.39815 14.6657 5.3367 14.6657C4.27524 14.6657 3.25726
14.244 2.5067 13.4934C1.75613 12.7429 1.33447 11.7249
1.33447 10.6634C1.33447 9.60199 1.75613 8.58401 2.5067
7.83344L8.63336 1.70678C9.13374 1.2064 9.81239 0.925293
 10.52 0.925293C11.2277 0.925293 11.9063 1.2064 12.4067
  1.70678C12.9071 2.20715 13.1882 2.88581 13.1882
  3.59344C13.1882 4.30108 12.9071 4.97973 12.4067
  5.48011L6.27336 11.6068C6.02318 11.857 5.68385
  11.9975 5.33003 11.9975C4.97621 11.9975 4.63688
  11.857 4.3867 11.6068C4.13651 11.3566 3.99596
  11.0173 3.99596 10.6634C3.99596 10.3096 4.13651
  9.9703 4.3867 9.72011L10.0467 4.06678"
      fill="none"
      stroke="#7084B4"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
