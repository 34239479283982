import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { AssigneeTeam } from '../../../../../../shared/TaskDTO';
import { ProcessUserDTO } from '../../../../../../shared/UserDTO';
import { EProcessType } from '../../../../../../shared/process/ProcessMilestoneActionDTO';
import { useAppSelector } from '../../../../../hooks/stateHooks';
import { useHover } from '../../../../../hooks/useHover';
import { getMilestoneAssigneesAvatarsData } from '../../../../../routes-old/process/helpers';
import { selectProcess } from '../../../../../routes-old/process/state/processSlice';
import AssigneeSelection from '../../../../../shared/AssigneeSelection/AssigneeSelection';
import UserItemAvatar from '../../../../../shared/UserAvatar/UserItemAvatar';
import { UserAvatarBox } from '../../../../TaskUsers/UserAvatarsBox';
import UserAvatarsEmptyIcon from '../../../../TaskUsers/UserAvatarsEmptyIcon';
import UserAvatarsGroup from '../../../../TaskUsers/UserAvatarsGroup';
import UserAvatarsPopover from '../../../../TaskUsers/UserAvatarsPopover';
import { stagesetColors } from '../../../../../theme/stagesetPalette';

export interface UserAvatarsProps {
  assigneesIds: string[];
  assigneesTeams?: AssigneeTeam[];
  showTeams?: boolean;
  isEditable?: boolean;
  sx?: SxProps<Theme>;
  cellId?: string;
  onAssigneesUpdate: (ids: string[], teams?: AssigneeTeam[]) => void;
  disabled?: boolean;
  size?: number;
  marginTop?: number;
}

export interface IUserAvatarLinkOrLetter {
  id: string;
  avatarSrc?: string;
  letter?: string;
  colorId?: number;
}

export const AgendaNodeAssignee = ({
  assigneesIds,
  assigneesTeams,
  isEditable,
  sx,
  cellId,
  onAssigneesUpdate,
  disabled,
  size = 24,
  showTeams = false,
  marginTop,
}: UserAvatarsProps): JSX.Element => {
  const processUsers = useAppSelector((state) => state.process.processUsers);
  const [links, setLinks] = useState<IUserAvatarLinkOrLetter[]>([]);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const process = useAppSelector(selectProcess);

  const [aUsers, setAUsers] = useState(assigneesIds);
  const [aTeams, setATeams] = useState(assigneesTeams);

  const marginLeft = -12;

  useEffect(() => {
    setAUsers(assigneesIds);
  }, [assigneesIds]);

  useEffect(() => {
    setATeams(assigneesTeams);
  }, [assigneesTeams]);

  const updateAssigneesList = (uUsers: string[], uTeams: any) => {
    setAUsers(uUsers);
    setATeams(uTeams);
  };

  useEffect(() => {
    setLinks(
      getMilestoneAssigneesAvatarsData(
        aUsers,
        processUsers,
        {
          assigneesTeams: aTeams || [],
          sellerAvatar: process?.sellerLogoSrc || '',
          buyerAvatar: process?.buyerLogoSrc || '',
        },
      ),
    );
  }, [processUsers, aUsers, aTeams]);

  const onClick = (e: SyntheticEvent) => {
    if (!process || process.type === EProcessType.TEMPLATE) {
      return;
    }
    if (!disabled) {
      e.stopPropagation();
      if (isEditable && menuAnchorRef.current) {
        setAnchorElement(menuAnchorRef.current);
      }
    }
  };
  const [isHovering, hoverProps] = useHover();

  const onClose = (e: SyntheticEvent) => {
    if (e) {
      e.stopPropagation();
    }
    onAssigneesUpdate(aUsers, aTeams);
    setAnchorElement(null);
  };

  return (
    <UserAvatarBox
      id={cellId}
      onClick={onClick}
      onMouseDown={(e) => e.stopPropagation()}
      {...hoverProps}
      ref={menuAnchorRef}
      sx={{
        paddingLeft: !links.length ? 0 : '12px',
        marginTop: `${marginTop}px`,
        '&:hover': {
          '& .AvatarPlaceholder': {
            color: isEditable ? stagesetColors.red[600] : 'none',
            borderColor: isEditable ? `${stagesetColors.red[600]} !important` : 'none',
          },
        },
      }}
    >
      {
        !links.length
        && (
          <UserAvatarsEmptyIcon isParentHovered={isHovering} />
        )
      }
      <UserAvatarsGroup
        sx={{
          zIndex: (theme) => (!!anchorElement && isEditable ? theme.zIndex.drawer + 50 : 1),
          ...sx,
          '& .MuiAvatar-root': {
            minHeight: `${size}px`,
            height: `${size}px`,
            minWidth: `${size}px`,
            width: `${size}px`,
            '&.MuiAvatarGroup-avatar': {
              zIndex: links.length,
              marginLeft: `${marginLeft}px`,
              backgroundColor: stagesetColors.white[100],
            },
          },
        }}
        max={isMobile ? 2 : 4}
      >
        {!!links.length && (
          links.map((link: IUserAvatarLinkOrLetter, index) => (
            <UserItemAvatar
              key={link?.id}
              size={size}
              colorId={link?.colorId}
              userName={link?.letter}
              fontSize={10}
              fontWeight={400}
              disableWrapper
              avatarSrc={link?.avatarSrc}
              marginLeft={marginLeft}
              zIndex={index}
            />
          ))
        )}
      </UserAvatarsGroup>
      <UserAvatarsPopover
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={onClose}
      >
        <AssigneeSelection
          users={processUsers.map(({
            id, name, email, avatarSrc,
          }: ProcessUserDTO) => ({
            id,
            name: name || email,
            avatarSrc,
          }))}
          selectedIds={aUsers}
          selectedTeams={aTeams}
          onAssigneeUpdate={updateAssigneesList}
          showTeams={showTeams}
        />
      </UserAvatarsPopover>
    </UserAvatarBox>
  );
};
