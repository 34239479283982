import { BoxProps } from '@mui/material';
import { AnimatedLoaderIcon20, LoaderIcon20 } from '../../icons/LoaderIcon';
import { StyledAnimatedAppLoader, StyledAppLoader } from './AppLoaderStyles';

type Props = {
  size?: number;
} & BoxProps;

export const AnimatedAppLoader = ({ size = 20, ...boxProps }: Props) => (
  <StyledAnimatedAppLoader
    component="span"
    height={size}
    width={size}
    {...boxProps}
  >
    <AnimatedLoaderIcon20
      sx={{
        fontSize: size,
      }}
    />
  </StyledAnimatedAppLoader>
);

export const AppLoader = ({ size = 20, ...boxProps }: Props) => (
  <StyledAppLoader
    component="span"
    height={size}
    width={size}
    {...boxProps}
  >
    <LoaderIcon20
      sx={{
        fontSize: size,
      }}
    />
  </StyledAppLoader>
);
