import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { EAppRoutes } from '../../core/router';
import { logout } from '../../routes-old/auth/state/authState';
import { useAppDispatch } from '../../hooks/stateHooks';
import { AppLinkButton } from '../AppButton/AppLinkButton';
import Add from '../../icons/Add.svg';

const BackToLogin = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(logout()), 200);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
      }}
    >
      <AppLinkButton
        size="xs"
        startIcon={<KeyboardArrowLeft />}
        onClick={() => {
          dispatch(logout());
          navigate(`${EAppRoutes.auth}${EAppRoutes.login}`);
        }}
      >
        Back to Login
      </AppLinkButton>
    </Box>
  );
};

export default BackToLogin;
