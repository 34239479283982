import {
  EntityState, PayloadAction, createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import { OrganizationAdminDTO, UpdateOrganizationAdminPayload } from '../../../../shared/OrganizationDTO';
import { EStateStatus } from '../../../core/commonTypes';
// import { OrganizationApi } from '../../../api/OrganizationApi';
import { RootState } from '../../../core/store/store';
import { EAdminFeedTab } from '../helpers/AdminHelpers';
import { AdminApi } from '../../../api/AdminApi';
import { AdminPageContentType } from '../../../../shared/admin/Types';
import { UpdateOrganizationOwnerPayload } from './adminStateTypes';

// const organizationApi: OrganizationApi = new OrganizationApi();
const adminApi: AdminApi = new AdminApi();

// TODO change state to substates i.e. one for organizations one for smth else if needed etc
interface IAdminState {
  status: EStateStatus;
  currentTab: EAdminFeedTab;
  selectedOrganization: OrganizationAdminDTO | null;
  isFirstFetchFinished: boolean;
  organizationsLoadingJobs: number;
  isDeletedFirstFetchFinished: boolean;
  deletedOrganizationsLoadingJobs: number;
  gridToRender: AdminPageContentType;
  organizations: EntityState<OrganizationAdminDTO>;
  deletedOrganizations: EntityState<OrganizationAdminDTO>;
}

const adminOrganizationsAdapter = createEntityAdapter<OrganizationAdminDTO>();
const adminDeletedOrganizationsAdapter = createEntityAdapter<OrganizationAdminDTO>();

export const adminReducerName: string = 'admin';

export enum EAdminOrganizationActions {
  GET_ADMIN_ORGANIZATIONS = 'GET_ADMIN_ORGANIZATIONS',
  GET_DELETED_ADMIN_ORGANIZATIONS = 'GET_DELETED_ADMIN_ORGANIZATIONS',
  UPDATE_ADMIN_ORGANIZATION = 'UPDATE_ADMIN_ORGANIZATION',
  DELETE_USER = 'DELETE_USER',
  DELETE_ADMIN_ORGANIZATION = 'DELETE_ORGANIZATION',
  RESTORE_ORGANIZATION = 'RESTORE_ORGANIZATION',
  UPDATE_ORGANIZATION_OWNER = 'UPDATE_ORGANIZATION_OWNER',
  UPDATE_ORGANIZATION_CD_ENABLED = 'UPDATE_ORGANIZATION_CD_ENABLED',
  UPDATE_ORGANIZATION_PUBLIC_API_ENABLED = 'UPDATE_ORGANIZATION_PUBLIC_API_ENABLED',
}

export const initialState: IAdminState = {
  status: EStateStatus.IDLE,
  currentTab: EAdminFeedTab.none,
  selectedOrganization: null,
  isFirstFetchFinished: false,
  organizationsLoadingJobs: 0,
  isDeletedFirstFetchFinished: false,
  deletedOrganizationsLoadingJobs: 0,
  gridToRender: AdminPageContentType.Active,
  organizations: adminOrganizationsAdapter.getInitialState(),
  deletedOrganizations: adminDeletedOrganizationsAdapter.getInitialState(),
};

export const getAdminOrganizations = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.GET_ADMIN_ORGANIZATIONS}`,
  async () => adminApi.getOrganizations(),
);

export const getDeletedAdminOrganizations = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.GET_DELETED_ADMIN_ORGANIZATIONS}`,
  async () => adminApi.getDeletedOrganizations(),
);

export const updateAdminOrganization = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.UPDATE_ADMIN_ORGANIZATION}`,
  async (payload: UpdateOrganizationAdminPayload) => adminApi.updateOrganization(payload),
);

export const adminDeleteUser = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.DELETE_USER}`,
  // eslint-disable-next-line max-len
  async (payload: { userId: string, organizationId: string, type: string }) => adminApi.deleteUser(payload.userId, payload.organizationId, payload.type),
);

export const adminDeleteOrganization = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.DELETE_ADMIN_ORGANIZATION}`,
  async (payload: { organizationId: string, type: string }, thunkAPI) => {
    await adminApi.deleteOrganization(payload.organizationId, payload.type);
    thunkAPI.dispatch(getAdminOrganizations());
  },
);

export const adminRestoreOrganization = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.RESTORE_ORGANIZATION}`,
  async (payload: string, thunkAPI) => {
    await adminApi.resoreOrganization(payload);
    thunkAPI.dispatch(getDeletedAdminOrganizations());
  },
);

export const adminUpdateOrganizationOwner = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.UPDATE_ORGANIZATION_OWNER}`,
  async (payload: UpdateOrganizationOwnerPayload) => adminApi.updateOrganizationOwner(payload.organizationId, {
    userId: payload.newOwnerId,
    ownerId: payload.oldOwnerId,
    ownerRoleId: payload.newOwnerRoleId,
    oldOwnerNewRoleId: payload.oldOwnerRoleId,
  }),
);

export const adminUpdateOrganizationCDEnabled = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.UPDATE_ORGANIZATION_CD_ENABLED}`,
  // eslint-disable-next-line max-len
  async (payload: { organizationId: string; enableCustomDomain: boolean }) => adminApi.updateOrganizationCustomDomainEnable(payload.organizationId, {
    enableCustomDomain: payload.enableCustomDomain,
  }),
);

export const adminUpdateOrganizationPublicApiEnabled = createAsyncThunk(
  `${adminReducerName}/${EAdminOrganizationActions.UPDATE_ORGANIZATION_PUBLIC_API_ENABLED}`,
  // eslint-disable-next-line max-len
  async (payload: { organizationId: string; enablePublicApi: boolean }) => adminApi.updateOrganizationPublicApiEnable(payload.organizationId, {
    enablePublicApi: payload.enablePublicApi,
  }),
);

export const adminSlice = createSlice({
  name: adminReducerName,
  initialState,
  reducers: {
    setAdminFeedTab: (state, action: PayloadAction<EAdminFeedTab>) => {
      state.currentTab = action.payload;
    },
    setCurrentOrganization: (state, action: PayloadAction<OrganizationAdminDTO>) => {
      state.selectedOrganization = action.payload;
    },
    setAdminGridToRender: (state, { payload }: PayloadAction<AdminPageContentType>) => {
      state.gridToRender = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminOrganizations.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.organizationsLoadingJobs += 1;
      })
      .addCase(getAdminOrganizations.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getAdminOrganizations.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isFirstFetchFinished = true;
        state.organizationsLoadingJobs -= 1;
        adminOrganizationsAdapter.setAll(state.organizations, action.payload);
      })
      .addCase(getDeletedAdminOrganizations.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.deletedOrganizationsLoadingJobs += 1;
      })
      .addCase(getDeletedAdminOrganizations.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getDeletedAdminOrganizations.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isDeletedFirstFetchFinished = true;
        state.deletedOrganizationsLoadingJobs -= 1;
        adminDeletedOrganizationsAdapter.setAll(state.deletedOrganizations, action.payload);
      })
      .addCase(updateAdminOrganization.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateAdminOrganization.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateAdminOrganization.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        adminOrganizationsAdapter.updateOne(state.organizations, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
      })
      .addCase(adminDeleteUser.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(adminDeleteUser.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(adminDeleteUser.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        adminOrganizationsAdapter.updateOne(state.organizations, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
        state.selectedOrganization = action.payload;
      })
      .addCase(adminUpdateOrganizationOwner.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.organizationsLoadingJobs += 1;
      })
      .addCase(adminUpdateOrganizationOwner.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(adminUpdateOrganizationOwner.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.organizationsLoadingJobs -= 1;
        adminOrganizationsAdapter.updateOne(state.organizations, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
        state.selectedOrganization = action.payload;
      })
      .addCase(adminUpdateOrganizationCDEnabled.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.organizationsLoadingJobs += 1;
      })
      .addCase(adminUpdateOrganizationCDEnabled.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(adminUpdateOrganizationCDEnabled.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.organizationsLoadingJobs -= 1;
        adminOrganizationsAdapter.updateOne(state.organizations, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
        state.selectedOrganization = action.payload;
      });
  },
});

export const {
  setAdminFeedTab,
  setCurrentOrganization,
  setAdminGridToRender,
} = adminSlice.actions;

export const { selectAll, selectById, selectIds } = adminOrganizationsAdapter.getSelectors();
export const {
  selectAll: selectAllDeleted,
  selectById: selectByIdDeleted,
  selectIds: selectIdsDeleted,
} = adminDeletedOrganizationsAdapter.getSelectors();

export const allOrganizationsSelector = (state: RootState) => selectAll(state.admin.organizations);
export const allDeletedOrganizationsSelector = (state: RootState) => selectAllDeleted(state.admin.deletedOrganizations);
export const selectFirstFetchStatus = (state: RootState): boolean => state.admin.isFirstFetchFinished;
export const selectDeletedFirstFetchStatus = (state: RootState): boolean => state.admin.isDeletedFirstFetchFinished;
export const selectOrganizationsLoadingJobs = (state: RootState) => state.admin.organizationsLoadingJobs;
export const selectDeletedOrganizationsLoadingJobs = (state: RootState) => state.admin.deletedOrganizationsLoadingJobs;
export const selectCurrentAdminFeedTab = (state: RootState) => state.admin.currentTab;
export const selectSelectedOrganization = (state: RootState) => state.admin.selectedOrganization;
export const selectAdminGridToRender = (state: RootState) => state.admin.gridToRender;
