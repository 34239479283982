import { SvgIcon, SvgIconProps } from '@mui/material';

export const EventVideoIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M15.0264 4.28002C14.9472 3.96363
      14.7859 3.67373 14.5588 3.43962C14.3317
      3.20551 14.0469 3.03548 13.7331 2.94669C12.5864
      2.66669 7.99973 2.66669 7.99973 2.66669C7.99973
      2.66669 3.41306 2.66669 2.2664 2.97335C1.95256
      3.06214 1.66772 3.23218 1.44063 3.46629C1.21354
      3.7004 1.05226 3.99029 0.973063 4.30669C0.763206
      5.47039 0.660554 6.65089 0.666397 7.83335C0.658916
      9.02472 0.761575 10.2142 0.973063 11.3867C1.06037
      11.6932 1.22527 11.9721 1.45183 12.1963C1.67838
      12.4206 1.95894 12.5826 2.2664 12.6667C3.41306
      12.9734 7.99973 12.9734 7.99973 12.9734C7.99973
      12.9734 12.5864 12.9734 13.7331 12.6667C14.0469
      12.5779 14.3317 12.4079 14.5588 12.1737C14.7859
      11.9396 14.9472 11.6497 15.0264 11.3334C15.2346
      10.1784 15.3373 9.00691 15.3331 7.83335C15.3405
      6.64199 15.2379 5.45249 15.0264 4.28002Z"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.5 10.0133L10.3333
      7.83332L6.5 5.65332V10.0133Z"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventLinkIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M12 8.66667V12.6667C12 13.0203 11.8595
13.3594 11.6095 13.6095C11.3594 13.8595 11.0203
14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595
2.39052 13.6095C2.14048 13.3594 2 13.0203 2
12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052
4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10 2H14V6"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.66699 9.33333L14.0003 2"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventFileAddedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M9.33366 1.33331H4.00033C3.6467 1.33331 3.30756
1.47379 3.05752 1.72384C2.80747 1.97389 2.66699
2.31302 2.66699 2.66665V13.3333C2.66699 13.6869
2.80747 14.0261 3.05752 14.2761C3.30756 14.5262
3.6467 14.6666 4.00033 14.6666H12.0003C12.3539
14.6666 12.6931 14.5262 12.9431 14.2761C13.1932
14.0261 13.3337 13.6869 13.3337 13.3333V5.33331L9.33366
1.33331Z"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.33301 1.33331V5.33331H13.333"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8 12V8"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6 10H10"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventFileDeletedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M9.33366 1.33331H4.00033C3.6467 1.33331 3.30756
1.47379 3.05752 1.72384C2.80747 1.97389 2.66699
2.31302 2.66699 2.66665V13.3333C2.66699 13.6869
2.80747 14.0261 3.05752 14.2761C3.30756 14.5262
3.6467 14.6666 4.00033 14.6666H12.0003C12.3539
14.6666 12.6931 14.5262 12.9431 14.2761C13.1932
14.0261 13.3337 13.6869 13.3337 13.3333V5.33331L9.33366
1.33331Z"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.33301 1.33331V5.33331H13.333"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6 10H10"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export const EventFileIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M9.33366 1.33331H4.00033C3.6467 1.33331 3.30756
1.47379 3.05752 1.72384C2.80747 1.97389 2.66699
2.31302 2.66699 2.66665V13.3333C2.66699 13.6869
2.80747 14.0261 3.05752 14.2761C3.30756 14.5262
3.6467 14.6666 4.00033 14.6666H12.0003C12.3539
14.6666 12.6931 14.5262 12.9431 14.2761C13.1932
14.0261 13.3337 13.6869 13.3337 13.3333V5.33331L9.33366
1.33331Z"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventUserAddedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M10.667 14V12.6667C10.667 11.9594
10.386 11.2811 9.88594 10.781C9.38585
10.281 8.70757 10 8.00033 10H3.33366C2.62641
 10 1.94814 10.281 1.44804 10.781C0.947944
 11.2811 0.666992 11.9594 0.666992 12.6667V14"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.66667 7.33333C7.13943 7.33333
8.33333 6.13943 8.33333 4.66667C8.33333
3.19391 7.13943 2 5.66667 2C4.19391 2
 3 3.19391 3 4.66667C3 6.13943 4.19391
 7.33333 5.66667 7.33333Z"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.333 5.33334V9.33334"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.333 7.33334H11.333"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventUserIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M13.3337 14V12.6667C13.3337 11.9594 13.0527 11.2811
12.5526 10.781C12.0525 10.281 11.3742 10 10.667
10H5.33366C4.62641 10 3.94814 10.281 3.44804 10.781C2.94794
11.2811 2.66699 11.9594 2.66699 12.6667V14"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.99967 7.33333C9.47243 7.33333 10.6663
 6.13943 10.6663 4.66667C10.6663 3.19391 9.47243 2
  7.99967 2C6.52692 2 5.33301 3.19391 5.33301 4.66667C5.33301
  6.13943 6.52692 7.33333 7.99967 7.33333Z"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventUserRemovedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M10.667 14V12.6667C10.667 11.9594
10.386 11.2811 9.88594 10.781C9.38585
10.281 8.70757 10 8.00033 10H3.33366C2.62641
 10 1.94814 10.281 1.44804 10.781C0.947944
 11.2811 0.666992 11.9594 0.666992 12.6667V14"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.66667 7.33333C7.13943 7.33333
8.33333 6.13943 8.33333 4.66667C8.33333
3.19391 7.13943 2 5.66667 2C4.19391 2
 3 3.19391 3 4.66667C3 6.13943 4.19391
 7.33333 5.66667 7.33333Z"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.333 7.33334H11.333"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventCompleteIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M13.3337 4L6.00033 11.3333L2.66699 8"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventCancelIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M12 4L4 12"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4L12 12"
      stroke="#455273"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const EventActionIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M7.66699 3.34326H14.3337"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 9.98212H14.3337"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 5.99225H10.5785"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 12.6799H10.5785"
      stroke="#455273"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="path-5-inside-1_12866_203862"
      fill="white"
    >
      <rect
        x="1.66699"
        y="2.6756"
        width="4"
        height="4"
        rx="1"
      />
    </mask>
    <rect
      x="1.66699"
      y="2.6756"
      width="4"
      height="4"
      rx="1"
      stroke="#455273"
      strokeWidth="2.66"
      mask="url(#path-5-inside-1_12866_203862)"
    />
    <mask
      id="path-6-inside-2_12866_203862"
      fill="white"
    >
      <rect
        x="1.66699"
        y="9.32422"
        width="4"
        height="4"
        rx="1"
      />
    </mask>
    <rect
      x="1.66699"
      y="9.32422"
      width="4"
      height="4"
      rx="1"
      stroke="#455273"
      strokeWidth="2.66"
      mask="url(#path-6-inside-2_12866_203862)"
    />
  </SvgIcon>
);

export const EventButtonIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <rect
      x="2.3"
      y="6.22969"
      width="15.4"
      height="7.07285"
      rx="2.2"
      stroke="#1E293B"
      strokeWidth="1.6"
      fill="none"
    />
    <path
      d="M6.00004 9.76562H9.95097H14"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);

export const EventPricingTableIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
      stroke="#64748B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8 4.05566V11.9449"
      stroke="#64748B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.79326 5.49023H7.10374C6.77087 5.49023 6.45162 5.62247 6.21625 5.85785C5.98087 6.09323 5.84863 6.41247 5.84863 6.74534C5.84863 7.07822 5.98087 7.39746 6.21625 7.63284C6.45162 7.86822 6.77087 8.00045 7.10374 8.00045H8.89676C9.22963 8.00045 9.54888 8.13269 9.78425 8.36807C10.0196 8.60345 10.1519 8.92269 10.1519 9.25557C10.1519 9.58844 10.0196 9.90768 9.78425 10.1431C9.54888 10.3784 9.22963 10.5107 8.89676 10.5107H5.84863"
      stroke="#64748B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
