import { SvgIcon, SvgIconProps } from '@mui/material';

export const BookOpenIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M1.66666 2.91667H6.66666C7.55071 2.91667
      8.39856 3.26786 9.02368 3.89298C9.6488 4.5181
      9.99999 5.36595 9.99999 6.25V17.9167C9.99999
      17.2536 9.7366 16.6177 9.26776 16.1489C8.79891
      15.6801 8.16303 15.4167 7.49999 15.4167H1.66666V2.91667Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M18.3333 2.91667H13.3333C12.4493 2.91667 11.6014
      3.26786 10.9763 3.89298C10.3512 4.5181 10 5.36595 10
      6.25V17.9167C10 17.2536 10.2634 16.6177 10.7322
      16.1489C11.2011 15.6801 11.837 15.4167 12.5 15.4167H18.3333V2.91667Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
