import { SvgIcon, SvgIconProps } from '@mui/material';

export const AlertCircle20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <g clipPath="url(#clip0_654_3720)">
      <path
        d="M10 18.3333C14.6024 18.3333 18.3334
        14.6024 18.3334 10C18.3334 5.39763 14.6024 1.66667 10
        1.66667C5.39765 1.66667 1.66669 5.39763 1.66669
        10C1.66669 14.6024 5.39765 18.3333 10 18.3333Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.0084 13.3333L10.0084 10"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.0084 6.66667L10 6.66667"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_654_3720">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
