/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

export const InfoIcon = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_37203_32709)">
      <path
        d="M10.2357 18.3302C14.838 18.3302 18.569 14.5993 18.569 9.99691C18.569 5.39453 14.838 1.66357 10.2357 1.66357C5.6333 1.66357 1.90234 5.39453 1.90234 9.99691C1.90234 14.5993 5.6333 18.3302 10.2357 18.3302Z"
        stroke="#64748B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.2441 13.3301L10.2441 9.99674"
        stroke="#64748B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2441 6.66357L10.2358 6.66357"
        stroke="#64748B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_37203_32709">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.236328 -0.00317383)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
