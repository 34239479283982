import { Box } from '@mui/material';
import { DndContext, DragOverlay, rectIntersection } from '@dnd-kit/core';
import { useEffect, useMemo, useState } from 'react';
import { DealBoardCard } from './DealBoardCard';
import { stagesetColors } from '../../theme/stagesetPalette';
import { useDealsBoardDnD } from '../../hooks/useDealsBoardDnD';
import { DealBoardColumn } from './DealBoardColumn';
import { ProcessDTO, ProcessStatus } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  allDealsSelector, getProcesses, updateProcess,
} from '../../routes-old/deals/state/dealsSlice';
import { selectFilterSearch, selectGridFilters } from '../Deals/DataGridFilter/lib/dealsGridSlice';
import { DealsType } from '../../../shared/process/Process';
import { useTranslation } from "react-i18next";

export const DealBoard = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    handleDragStart, handleDragEnd, sensors, draggableElement,
  } = useDealsBoardDnD();
  const filter = useAppSelector(selectGridFilters);
  const search = useAppSelector(selectFilterSearch);
  const processes = useAppSelector(allDealsSelector);
  const [localProcesses, setLocalProcesses] = useState(processes || []);

  useEffect(() => {
    setLocalProcesses(processes);
  }, [processes]);
  const kanban = useMemo(() => localProcesses.reduce<Map<ProcessStatus, ProcessDTO[]>>((result, process) => {
    if (typeof process.status === 'undefined') {
      result.set(ProcessStatus.IN_PROGRESS, result.get(ProcessStatus.IN_PROGRESS)?.concat(process) ?? [process]);
    } else {
      result.set(process.status, result.get(process.status)?.concat(process) ?? [process]);
    }
    return result;
  }, new Map<ProcessStatus, ProcessDTO[]>()), [localProcesses]);

  const updateProcessStatus = (id: string, processStatus: ProcessStatus, currentStatus: ProcessStatus) => {
    if (processStatus === currentStatus) return;
    dispatch(updateProcess({
      id,
      status: processStatus,
    }));
  };

  const onDragEnd = (event: any) => {
    const { current, target } = handleDragEnd(event);
    if (target && target.id in ProcessStatus) {
      const currentIndex = localProcesses.findIndex((process) => process.id === current.id);
      setLocalProcesses((prev) => {
        const element = { ...prev[currentIndex] };
        element.status = target.id as ProcessStatus;
        const prevCopy = [...prev];
        prevCopy[currentIndex] = element;
        return prevCopy;
      });
      updateProcessStatus(current.id.toString(), target.id.toString() as ProcessStatus, current.data.current.status);
    }
  };

  useEffect(() => {
    dispatch(getProcesses());
  }, [filter[DealsType.SELLER_DEALS], search]);

  return (
    <Box sx={{
      width: '100%',
      background: '#fff',
      height: '100%',
      '& .columns-header': {
        display: 'flex',
        padding: '8px 24px',
        border: (theme) => `1px solid ${theme.palette.grey[100]}`,
        background: stagesetColors.background[200],
        gap: '16px',
      },
      '& .column-header': {
        display: 'flex',
        color: '#7084B4',
        fontSize: '14px',
        flex: 1,
      },
      '& .column': {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        flex: 1,
        overflow: 'overlay',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '& .column-card:last-child': {
          marginBottom: '40px',
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '& .cards': {
        display: 'flex',
        flexDirection: 'row',
        padding: '16px 24px 0px 24px',
        gap: '16px',
        height: '100%',
      },
      cursor: draggableElement ? 'grabbing' : 'default',
    }}
    >
      <Box className="columns-header">
        <Box className="column-header">
          {t('Status_In_progress')} (
          {kanban.get(ProcessStatus.IN_PROGRESS)?.length ?? 0}
          )
        </Box>
        <Box className="column-header">
          {t('Status_On_hold')} (
          {kanban.get(ProcessStatus.ON_HOLD)?.length ?? 0}
          )
        </Box>
        <Box className="column-header">
          {t('Status_Closed_won')} (
          {kanban.get(ProcessStatus.CLOSED_WON)?.length ?? 0}
          )
        </Box>
        <Box className="column-header">
          {t('Status_Closed_lost')} (
          {kanban.get(ProcessStatus.CLOSED_LOST)?.length ?? 0}
          )
        </Box>
      </Box>
      <DndContext
        collisionDetection={rectIntersection}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={onDragEnd}
      >
        <Box className="cards">
          <DealBoardColumn status={ProcessStatus.IN_PROGRESS}>
            {kanban.get(ProcessStatus.IN_PROGRESS)?.map((process) => (
              <DealBoardCard key={process.id} process={process} />
            ))}
          </DealBoardColumn>
          <DealBoardColumn status={ProcessStatus.ON_HOLD}>
            {kanban.get(ProcessStatus.ON_HOLD)?.map((process) => (
              <DealBoardCard key={process.id} process={process} />
            ))}
          </DealBoardColumn>
          <DealBoardColumn status={ProcessStatus.CLOSED_WON}>
            {kanban.get(ProcessStatus.CLOSED_WON)?.map((process) => (
              <DealBoardCard key={process.id} process={process} />
            ))}
          </DealBoardColumn>
          <DealBoardColumn status={ProcessStatus.CLOSED_LOST}>
            {kanban.get(ProcessStatus.CLOSED_LOST)?.map((process) => (
              <DealBoardCard key={process.id} process={process} />
            ))}
          </DealBoardColumn>
        </Box>
        <DragOverlay>
          {draggableElement ? (
            <DealBoardCard process={draggableElement.data.current} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
