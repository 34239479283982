import { useAppSelector } from './stateHooks';
import { selectStage } from '../routes-old/process/state/processSlice';
import { StageContentType } from '../../shared/Stage';
import { useCurrentStage } from './useCurrentStage';

export const useContentWidth = () => {
  const currentStage = useCurrentStage();

  return {
    contentWidth: currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE ? 'calc(100%)' : '900px',
  };
};
