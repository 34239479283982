import {
  Box, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import DealsGridFilterInput from '../../features/Deals/DataGridFilter/GridFilterInput/DealsGridFilterInput';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { SellerView } from './Deals';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import { AppButton } from '../../shared/AppButton/AppButton';
import { Add20px } from '../../icons/Add';
import StarOutline from '../../icons/DealView/StarOutline.svg';
import Table from '../../icons/DealView/Table.svg';
import { DealsType } from '../../../shared/process/Process';
import AppTabsWithIndicator from '../../shared/AppTabs/AppTabsWithIndicator';
import { useAppDispatch } from '../../hooks/stateHooks';
import { setGridToRender } from './state/dealsSlice';

interface Props {
  gridToRender: DealsType | string;
  sellerView: SellerView;
  setSellerView: (view: SellerView) => void;
  onCreateDealDialogOpen: (templateId?: string) => void;
  allowTeamManagement: boolean
}

const DealsTabsHeader = ({
  gridToRender,
  onCreateDealDialogOpen,
  sellerView,
  setSellerView,
  allowTeamManagement,
}: Props): JSX.Element => {
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const dispatch = useAppDispatch();
  const isOwnedDealsVisible: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_DEALS_OWNED);
  const isJoinedDealsVisible: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_DEALS_PARTICIPANT);
  const { t } = useTranslation();

  const handleChange = (newValue: DealsType) => {
    dispatch(setGridToRender(newValue));
  };

  const options = [
    { title: t('Overview_Owned'), value: DealsType.SELLER_DEALS, disabled: !isOwnedDealsVisible },
    { title: t('Overview_Joined'), value: DealsType.BUYER_DEALS, disabled: !isJoinedDealsVisible },
    // ...(isTeamDealsVisible ? [{ title: t('Overview_Team'), value: DealsType.TEAM_DEALS }] : []),
  ];

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 0,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            height: '56px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: isMobile ? '16px' : '24px',
            background: 'white',
            borderBottom: '1px solid #EEF0FA',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              lineHeight: '26px',
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {t('Overview_Rooms')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              '& button': {
                minWidth: '70px',
              },
            }}
          >
            <AppTabs
              variant="boxedNew-contrast"
              size="m-new"
              value={gridToRender}
              onChange={(option) => handleChange(option.value)}
              options={options as any[]}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          marginTop: isMobile ? '20px' : '24px',
          marginBottom: isMobile ? '20px' : '40px',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingX: isMobile ? '5px' : '24px',
          gap: '24px',
        }}
      >
        { gridToRender === DealsType.SELLER_DEALS && !isMobile && (
          <Box sx={{
            maxWidth: '200px',
          }}
          >
            <AppTabs
              options={[
                {
                  title: '', value: SellerView.GRID, icon: <Table />,
                },
                {
                  title: '', value: SellerView.KANBAN, icon: <StarOutline />,
                },
              ]}
              value={sellerView}
              onChange={(option) => {
                localStorage.setItem('sellerView', option.value);
                setSellerView(option.value);
              }}
              color="light"
              variant="boxed"
              aria-label="Library tabs"
              size="l"
              sx={{
                '& button': {
                  minWidth: '50px',
                },
              }}
            />
          </Box>
        )}
        <DealsGridFilterInput />
        {
         !isMobile && (
         <AppButton
           onClick={() => onCreateDealDialogOpen()}
           size="l"
           variant="primary"
           startIcon={<Add20px />}
         >
           {t('Overview_Room')}
         </AppButton>
         )
        }
      </Box>
    </>
  );
};

export default DealsTabsHeader;
