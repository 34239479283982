import {
  EntityState, PayloadAction, createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import {
  LibraryProductFilterCategory,
  LibraryProductFilterInfo,
  LibraryProductFilterParams,
  LibraryProductFilterSet,
  LibraryFilterSetDTO,
} from '../../../../shared/library/LibraryFilter';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import { LibraryApi } from '../../../api/LibraryApi';
import { LibraryFilterApi } from '../../../api/LibraryFilterApi';

export enum ELibraryProductFilterActions {
  GET_FILTER_INFO = 'GET_FILTER_INFO',
  GET_ALL_PRODUCT_FILTERS = 'GET_ALL_PRODUCT_FILTERS',
  SAVE_NEW_FILTER = 'SAVE_NEW_FILTER',
  UPDATE_FILTER = 'UPDATE_FILTER',
  DELETE_FILTER = 'DELETE_FILTER',
}

const libraryApi: LibraryApi = new LibraryApi();
const libraryFilterApi: LibraryFilterApi = new LibraryFilterApi();

const filtersProductsAdapter = createEntityAdapter<LibraryFilterSetDTO>({});

export interface LibraryProductFilterState {
  status: EStateStatus;
  search: string;
  expanded: boolean;
  popupCategory: LibraryProductFilterCategory | null;
  filterInfo: LibraryProductFilterInfo;
  newFilterSetDialog: boolean;
  manageFilterSetsDialog: boolean;
  mobileMenu: boolean;
  filterParams: LibraryProductFilterParams;
  productsFilters: EntityState<LibraryFilterSetDTO>;
}

export const libraryProductFilterReducerName: string = 'libraryProductGridFilter';

const initialState: LibraryProductFilterState = {
  status: EStateStatus.IDLE,
  filterInfo: {
    owner: [],
  },
  search: '',
  expanded: false,
  popupCategory: null,
  newFilterSetDialog: false,
  manageFilterSetsDialog: false,
  mobileMenu: false,
  filterParams: {},
  productsFilters: filtersProductsAdapter.getInitialState(),
};

export const getLibraryProductFilterInfo = createAsyncThunk(
  `${libraryProductFilterReducerName}/${ELibraryProductFilterActions.GET_FILTER_INFO}`,
  async () => libraryApi.getProductFilterInfo(),
);

export const getUserProductFilters = createAsyncThunk(
  `${libraryProductFilterReducerName}/${ELibraryProductFilterActions.GET_ALL_PRODUCT_FILTERS}`,
  async () => libraryFilterApi.getLibraryProductFilters(),
);

export const createNewFilter = createAsyncThunk(
  `${libraryProductFilterReducerName}/${ELibraryProductFilterActions.SAVE_NEW_FILTER}`,
  async (payload: LibraryProductFilterSet) => libraryFilterApi.createLibraryFilter(payload),
);

export const updateFilter = createAsyncThunk(
  `${libraryProductFilterReducerName}/${ELibraryProductFilterActions.UPDATE_FILTER}`,
  async (payload: {
    id: string;
    filterSet: Partial<LibraryProductFilterSet>;
  }) => libraryFilterApi.updateProductFilter(payload.id, payload.filterSet),
);

export const deleteFilter = createAsyncThunk(
  `${libraryProductFilterReducerName}/${ELibraryProductFilterActions.DELETE_FILTER}`,
  async (payload: string, thunkAPI) => {
    await libraryFilterApi.deleteFilter(payload);
    thunkAPI.dispatch(getUserProductFilters());
  },
);

export const libraryProductGridFilterSlice = createSlice({
  name: libraryProductFilterReducerName,
  initialState,
  reducers: {
    resetFilter: () => initialState,
    setLibraryProductSearch: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      search: payload,
    }),
    setLibraryProductGridFilter: (state, { payload }: PayloadAction<LibraryProductFilterParams>) => ({
      ...state,
      filterParams: payload,
    }),
    setLibraryProductFilterExpanded: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      expanded: payload,
    }),
    setLibraryProductFilterPopupCategory: (state, { payload }: PayloadAction<LibraryProductFilterCategory | null>) => ({
      ...state,
      popupCategory: payload,
    }),
    setLibraryProductNewFilterSetDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      newFilterSetDialog: payload,
    }),
    setLibraryProductManageFilterSetsDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      manageFilterSetsDialog: payload,
    }),
    setLibraryProductMobileFilterMenu: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      mobileMenu: payload,
    }),
  },
  extraReducers: ((builder) => builder
    .addCase(getLibraryProductFilterInfo.fulfilled, (state, action) => {
      state.filterInfo = action.payload;
    })
    .addCase(getUserProductFilters.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(getUserProductFilters.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      filtersProductsAdapter.setAll(state.productsFilters, action.payload);
    })
    .addCase(getUserProductFilters.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(createNewFilter.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(createNewFilter.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      filtersProductsAdapter.setAll(state.productsFilters, action.payload);
    })
    .addCase(createNewFilter.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(updateFilter.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(updateFilter.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      filtersProductsAdapter.updateOne(state.productsFilters, {
        id: action.payload.id,
        changes: {
          ...action.payload,
        },
      });
    })
    .addCase(updateFilter.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
  ),
});

export const {
  resetFilter,
  setLibraryProductSearch,
  setLibraryProductGridFilter,
  setLibraryProductFilterExpanded,
  setLibraryProductFilterPopupCategory,
  setLibraryProductNewFilterSetDialogStatus,
  setLibraryProductManageFilterSetsDialogStatus,
  setLibraryProductMobileFilterMenu,
} = libraryProductGridFilterSlice.actions;

export const {
  selectAll: ProductSelectAll,
  selectById: ProductSelectById,
  selectIds: ProductSelectIds,
  selectTotal: ProductSelectTotal,
} = filtersProductsAdapter.getSelectors();

export const allProductFiltersSelector = (state: RootState) => ProductSelectAll(state.libraryProductFilter.productsFilters);
export const selectLibraryProductFilterSearch = (state: RootState) => state.libraryProductFilter.search;
export const selectLibraryProductGridFilter = (state: RootState) => state.libraryProductFilter.filter;
export const selectLibraryProductFilterInfo = (state: RootState) => state.libraryProductFilter.filterInfo;
export const selectLibraryProductExpanded = (state: RootState) => state.libraryProductFilter.expanded;
export const selectLibraryProductPopupCategory = (state: RootState) => state.libraryProductFilter.popupCategory;
export const selectLibraryProductCollectFilterSetInfoDialogStatus = (state: RootState) => state
  .libraryProductFilter
  .newFilterSetDialog;
export const selectLibraryProductManageFilterSetsDialogStatus = (state: RootState) => state
  .libraryProductFilter
  .manageFilterSetsDialog;
export const selectLibraryProductMobileFilterMenu = (state: RootState) => state
  .libraryProductFilter
  .mobileMenu;
export const selectLibraryProductFilterParams = (state: RootState) => state
  .libraryProductFilter
  .filterParams;
