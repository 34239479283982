import { useEffect } from 'react';

export const useBlure = (toCheck: boolean = true, exitAction?: () => void) => {
  useEffect(() => {
    const appLayoutContainer: HTMLElement | null = document.querySelector('.AppLayoutContainer');
    if (appLayoutContainer && toCheck) {
      appLayoutContainer.style.filter = 'blur(5px)';
    }
    return () => {
      if (appLayoutContainer) {
        if (exitAction) {
          exitAction();
        }
        appLayoutContainer.style.filter = '';
      }
    };
  }, [toCheck]);
};
