import { SvgIcon, SvgIconProps } from '@mui/material';

export const Help12px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142
      1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
      stroke="#A3B0D1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.54492 4.50004C4.66247 4.16587 4.8945
       3.88409 5.1999 3.70461C5.5053 3.52512 5.86437 3.45951
        6.21351 3.5194C6.56265 3.57928 6.87933 3.7608 7.10746
         4.0318C7.33559 4.30281 7.46045 4.6458 7.45992
          5.00004C7.45992 6.00004 5.95992 6.50004
           5.95992 6.50004"
      stroke="#A3B0D1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6 8.5H6.005"
      stroke="#A3B0D1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
