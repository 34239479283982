import {
  Grid, Typography,
} from '@mui/material';

type Props = {
  title: string | JSX.Element,
  menu: JSX.Element | null
};

const MobileTableRowHeader = ({ title, menu }: Props) => {
  const renderTitle = () => {
    switch (typeof title) {
      case 'string':
        return (
          <Typography color="#1A1929" fontSize={16}>
            {title}
          </Typography>
        );
      default:
        return title;
    }
  };

  return (
    <Grid
      container
      className="mobile-table-row-header"
      sx={{
        minHeight: '40px',
        padding: '0px 0px 8px',
      }}
    >
      <Grid
        item
        xs={10}
        sx={{
          alignItems: 'center',
        }}
      >
        {renderTitle()}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {menu}
      </Grid>
    </Grid>
  );
};

export default MobileTableRowHeader;
