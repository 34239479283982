import { ESocialAuthProvider, ESocialAuthTypes } from '../../../shared/schema/auth.shcema';
import { EJWTTokenType, ILoginResponse } from '../../../shared/schema/token.schema';
import { DomainHelper } from './DomainHelper';
import { SharedLocalStorage } from './SharedLocalStorage';

export interface RedirectRetval {
  subdomain?: string;
  customDomain?: string;
  shouldRedirect: boolean;
}

export class OrganizationSubdomainHelper {
  static getSubdomainToRedirect = (domain: string): RedirectRetval => {
    const subdomain = DomainHelper.getSubdomainValue();
    const retval: RedirectRetval = {
      subdomain: '',
      shouldRedirect: false,
    };

    /**
     * the || '' was added in case if one is empy and the other is undefined to not enter the if
     */
    if ((subdomain || '').toLowerCase() != (domain || '').toLowerCase()) {
      retval.subdomain = domain || '';
      retval.shouldRedirect = true;
    } else {
      retval.shouldRedirect = false;
    }
    /**
     * The need to relocate will be indicated by the shouldRedirect attribute
     * because in case of the location is with subdomain but the organization does not have
     * a subdomain, the user should be relocated to the main domain
     */
    return retval;
  };

  static getCustomDomainToRedirect = (customDomain: string): RedirectRetval => {
    let retval: RedirectRetval = {
      subdomain: '',
      shouldRedirect: false,
    };
    if (!!customDomain && window.location.host !== customDomain) {
      retval = {
        customDomain,
        shouldRedirect: true,
      };
    }

    return retval;
  };

  static redirectToSubdomain = (subdomain: string, access_token: string, tokenType: string) => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = DomainHelper.replaceSubdomain(subdomain, access_token, tokenType);
  };

  static redirectToCustomDomain = (customDomain: string, access_token: string, tokenType: string) => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = DomainHelper.replaceCustomDomain(customDomain, access_token, tokenType);
  };

  static getLoginForSubdomain(): ILoginResponse | undefined {
    const tokenType = SharedLocalStorage.getItem('tokenType');
    const access_token = SharedLocalStorage.getItem('token');

    if (!!tokenType && !!access_token) {
      /**
       * Refresh items in local storage in case the values are retrieved from cookie
       */

      return {
        tokenType: tokenType as EJWTTokenType,
        access_token,
      };
    }

    return undefined;
  }

  static redirectToCustomDomainSocialLogin(
    socialAuthType: ESocialAuthTypes,
    provider: ESocialAuthProvider,
    celloReferrer?: string,
  ) {
    window.location.href = DomainHelper.replaceCustomDomainAuth(socialAuthType, provider, celloReferrer);
  }
}
