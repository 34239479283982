import { Box } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { stagesetPalette } from '../../theme/stagesetPalette';

type Props = {
  children: JSX.Element[]
};

const OwnerCardWrapper = ({ children }: Props): JSX.Element => (
  <Box sx={{
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: '8px',
    border: `1px solid ${stagesetPalette.grey?.[100]}`,
    padding: '24px',
    backgroundColor: 'white',
    flexDirection: 'column',
  }}
  >
    {children}
  </Box>
);

export default OwnerCardWrapper;
