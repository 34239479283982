import { styled } from '@mui/system';
import { Menu } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledAppDropdown = styled<any>(Menu)(({ theme }) => ({
  zIndex: 1301,
  '& > .MuiPaper-root': {
    border: '1px solid',
    borderColor: stagesetColors.newGrey[400],
    boxShadow: `0px 3px 2px rgba(0, 113, 190, 0.04),
              0px 1px 1px rgba(0, 113, 190, 0.07),
              0px 0px 1px rgba(0, 113, 190, 0.08),
              0px 0px 0px rgba(0, 113, 190, 0.08) !important`,
    borderRadius: '10px',
    ...isMobile ? {
      maxHeight: '380px',
      overflow: 'auto',
    } : null,
    '&.size-s:not(.auto-size)': {
      width: '240px',
      '& .app-dropdown-item': {
        height: '40px',
      },
    },
    '&.size-m:not(.auto-size)': {
      width: '240px',
      '& .app-dropdown-item': {
        height: '40px',
      },
    },
    '&.size-l:not(.auto-size)': {
      width: '280px',
      '& .app-dropdown-item': {
        height: '40px',
      },
    },
    '&.size-xl:not(.auto-size)': {
      width: '320px',
      '& .app-dropdown-item': {
        height: '40px',
      },
    },
    '&.size-xxl:not(.auto-size)': {
      width: '390px',
      '& .app-dropdown-item': {
        height: '40px',
      },
    },
    '& ul': {
      padding: 0,
      '& hr': {
        margin: '4px 0px !important',
      },
    },
    '& li:first-of-type': {
      marginTop: '8px',
    },
    '& li:last-of-type': {
      marginBottom: '8px',
    },
    '& li': {
      marginLeft: '8px',
      marginRight: '8px',
    },
    '& .search-container': {
      padding: '12px 16px',
    },
    '& .app-dropdown-item': {
      display: 'flex',
      backgroundColor: stagesetColors.white[100],
      borderRadius: '10px',
      padding: '9px 12px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&.different-color-for-last-element': {
      '& li:not(:last-of-type)': {
        '& path': {
          stroke: stagesetColors.newGrey[500],
        },
        '& p': {
          color: stagesetColors.newGrey[800],
        },
        '&:hover': {
          backgroundColor: stagesetColors.grey[100],
        },
      },
      '& li:last-of-type': {
        '& path': {
          stroke: stagesetColors.red[600],
        },
        '& p': {
          color: stagesetColors.red[600],
        },
        '&:hover': {
          backgroundColor: stagesetColors.red[100],
        },
      },
    },
    '& :not(.different-color-for-last-element)': {
      '& .app-dropdown-item': {
        '& path': {
          stroke: stagesetColors.newGrey[500],
        },
        '& p': {
          color: stagesetColors.newGrey[800],
        },
        '&:hover': {
          backgroundColor: stagesetColors.grey[100],
        },
      },
    },
    '& .app-dropdown-item:active': {
      backgroundColor: stagesetColors.grey[100],
    },
    '& .app-dropdown-item.disabled': {
      stroke: theme.palette.grey[300],
    },
    '& [data-focused=y]': {
      backgroundColor: theme.palette.primary[100],
    },
  },
}));
