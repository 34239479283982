import { Box, Typography } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';
import { BaseSyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EDealStatus, EProcessType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { NavigationLink } from '../../common/ToolBarNavigation/NavigationStyle';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';
import { EAppRoutes } from '../../core/router';
import { selectFilterSearch, selectGridFilters } from '../../features/Deals/DataGridFilter/lib/dealsGridSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  getBuyerDeals, selectFirstFetchStatus,
} from '../BuyerDeals/state/buyerDealsSlice';
import { clearProcessState } from '../process/state/processSlice';
import JoinedDealsListMenu from './JoinedDealsListMenu';
import { ExtendedGridColumns } from '../../types/mobile-table';
import { DealsGrid } from './DealPageStyles/DealsListStyle';
import { NameCell } from '../../features/Organization/OrganizationUsersDataGrid/NameCell';
import { GridItemSkeleton, GridTitleItemSkeleton, skeletonRows } from './DealsHelpers/DealsHelpers';
import { CrossDimensionDataGrid } from '../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { DealsType } from '../../../shared/process/Process';
import { selectProcessesLoadingJobs } from './state/dealsSlice';

const analytics: Analytics = Analytics.getInstance();

enum EDataGridColumns {
  status = 'status',
  title = 'title',
  menu = ' ',
  seller = 'seller',
  lastMileStone = 'lastMileStone',
  health = 'health',
}

const BuyerDataGrid = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.app);
  const processes = Object.values(useAppSelector((state) => state.buyerDeals.entities));
  // const { status, isProcessStatsLoaded } = useAppSelector((state) => state.buyerDeals);
  const processesLoadingJobs = useAppSelector(selectProcessesLoadingJobs);
  const filter = useAppSelector(selectGridFilters);
  const search = useAppSelector(selectFilterSearch);
  const firstFetchStatus = useAppSelector(selectFirstFetchStatus);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getBuyerDeals());
    dispatch(clearProcessState());
  }, [user?.id]);

  useEffect(() => {
    dispatch(getBuyerDeals());
  }, [filter[DealsType.BUYER_DEALS], search]);

  const onRowClick = (row: any) => {
    navigate(`${EAppRoutes.deal}/${row.id}`);
  };

  const preventLinkBehavior = (e: BaseSyntheticEvent) => {
    e.preventDefault();
  };

  const columns: ExtendedGridColumns = [
    {
      field: EDataGridColumns.title,
      sortable: true,
      headerName: t('Overview_Name'),
      flex: 4,
      align: 'left',
      cellClassName: 'pro-grid-cell',
      renderCell: ({
        id,
        value,
      }) => (
        value !== 'skeleton' ? (
          <NavigationLink
            onClick={preventLinkBehavior}
            className="RowLink"
            to={`${EAppRoutes.deal}/${id}`}
          >
            <NameCell name={value.title} avatarSrc={value.logo} />
          </NavigationLink>
        ) : (
          <GridTitleItemSkeleton
            disableSubtext
          />
        )
      ),
    },
    {
      sortable: true,
      headerName: t('Overview_Created_by'),
      field: EDataGridColumns.seller,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({
        id,
        value,
      }) => (
        value !== 'skeleton' ? (
          <NavigationLink
            onClick={preventLinkBehavior}
            className="RowLink"
            to={`${EAppRoutes.deal}/${id}`}
          >
            {value}
          </NavigationLink>
        ) : (
          <GridItemSkeleton />
        )
      ),
      renderMobileCell: ({ row, value }) => (
        <NavigationLink
          onClick={preventLinkBehavior}
          className="RowLink"
          to={`${EAppRoutes.deal}/${row.id}`}
        >
          {value}
        </NavigationLink>
      ),
    },
    {
      field: EDataGridColumns.menu,
      cellClassName: 'pro-grid-cell',
      hideSortIcons: true,
      align: 'right',
      renderCell: (
        {
          id,
          value,
        },
      ) => (
        value !== 'skeleton' ? (
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          >
            <Box>
              <JoinedDealsListMenu
                id={id as string}
                title={value?.title}
              />
            </Box>
          </Box>
        ) : <Box />
      ),
    },
  ];

  let rows: GridRowsProp = [];
  if (processes.length) {
    rows = processes
      .filter((process: ProcessDTO) => process.type === EProcessType.DEAL)
      .map((process: ProcessDTO) => {
        const trackEvent = (event: EMixPanelEvents) => {
          analytics.track(event, {
            dealId: process?.id,
            dealTitle: process?.title,
            dealTemplate: process?.templateId,
            lastActive: process?.processStats?.lastActiveUser,
            lastMilestone: {
              lastMilestoneId: process?.processStats?.lastMilestone?.id,
              lastMilestoneTitle: process?.processStats?.lastMilestone?.title,
            },
          });
        };
        const getRowStatus = (): number => {
          if (process?.notifications?.length) {
            return 1;
          } if (
            process.processStats?.tasksStatus === EDealStatus.DUE_TODAY
            || process.processStats?.tasksStatus === EDealStatus.OVERDUE
            || process.processStats?.tasksStatus === EDealStatus.NOT_STARTED
            || process.processStats?.tasksStatus === EDealStatus.NOTHING_IN_PROGRESS
          ) {
            return 1;
          }
          return 0;
        };
        const rowStatus = getRowStatus();

        return {
          [EDataGridColumns.status]: rowStatus,
          [EDataGridColumns.menu]: {
            trackEvent,
            title: process.summaryTitle,
            type: process.type,
            notifications: process.notifications,
          },
          [EDataGridColumns.title]: {
            logo: process.buyerLogoSrc,
            title: process.summaryTitle,
          },
          id: process.id,
          [EDataGridColumns.health]: process.lastActive,
          [EDataGridColumns.seller]: process.processStats?.organizationName,
          [EDataGridColumns.lastMileStone]: {
            dealTitle: process.title,
            lastActive: process.processStats?.lastActiveUser,
            dealTemplate: process.templateId,
            status: process.processStats?.status,
            lastMilestone: process.processStats?.lastMilestone,
            tasksStats: process.processStats?.tasksStats,
            tasksStatus: process.processStats?.tasksStatus,
          },
        };
      });
  }
  return (
    <Box
      id="joined-deals-tab-container"
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        display: 'inline-flex',
        gap: '4px',
        marginBottom: '12px',
        marginLeft: '12px',
      }}
      >
        {
          firstFetchStatus ? (
            <>
              <Typography sx={{
                fontSize: '16px',
                color: (theme) => theme.palette.grey[600],
              }}
              >
                {processes.length}
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                color: (theme) => theme.palette.grey[400],
                paddingTop: '2px',
              }}
              >
                {t('Overview_Rooms').toLowerCase()}
              </Typography>
            </>
          ) : (
            <GridItemSkeleton />
          )
        }
      </Box>
      <CrossDimensionDataGrid
        rows={rows}
        columns={columns}
        getId={(row) => row.id}
        renderMobileTitle={(row) => (
          <NameCell
            name={row[EDataGridColumns.title].title}
            avatarSrc={row[EDataGridColumns.title].logo}
          />
        )}
        renderMobileMenu={(row) => (
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          >
            <Box>
              <JoinedDealsListMenu
                id={row.id}
                title={row[EDataGridColumns.title]}
              />
            </Box>
          </Box>
        )}
        onRowClick={(row) => {
          onRowClick(row);
        }}
        renderDesktopGridComponent={(props) => (
          <DealsGrid
            // loading={processesLoadingJobs > 0}
            disableSelectionOnClick
            disableColumnMenu
            hideFooter
            columns={columns}
            rows={firstFetchStatus ? rows : skeletonRows}
            headerHeight={36}
            rowHeight={65}
            onRowClick={(rowParams) => onRowClick(rowParams.row)}
            getRowClassName={(params) => (params.row.status === 1 ? 'highlighted_row' : '')}
          />
        )}
      />
    </Box>
  );
};

export default BuyerDataGrid;
