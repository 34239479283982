import { styled } from '@mui/styles';
import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { ElementType } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const NormalizedSvgIcon = styled<
OverridableComponent<SvgIconTypeMap<{
  component: ElementType
}, 'svg'>>
>(SvgIcon)(() => ({
  color: 'transparent',
}));

export default NormalizedSvgIcon;
