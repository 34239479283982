import { SvgIcon } from '@mui/material';

export const AvatarPlusIcon20 = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M13.333 17.5V15.8333C13.333 14.9493 12.9818 14.1014
      12.3567 13.4763C11.7316 12.8512 10.8837 12.5 9.99967
      12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932
      13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M7.08333 9.16667C8.92428 9.16667 10.4167 7.67428
      10.4167 5.83333C10.4167 3.99238 8.92428 2.5 7.08333
      2.5C5.24238 2.5 3.75 3.99238 3.75 5.83333C3.75 7.67428
      5.24238 9.16667 7.08333 9.16667Z"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M16.667 6.66675V11.6667"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M19.167 9.16675H14.167"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
