import {
  SwipeableDrawer,
} from '@mui/material';
import { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { CommonOptionsTab } from './CommonOptionsTab';
import { useStages } from '../../../hooks/useStages';
import { TemplatesTab } from '../CommonMobileTabs/TemplatesTab';
import { AddTab } from '../CommonMobileTabs/AddTab';
import { CreateStageTab } from '../CommonMobileTabs/CreateStageTab';
import { Puller } from '../../../shared/Puller/Puller';
import CollectTemplateInfoDialog from '../../CollectTemplateInfoDialog/CollectTemplateInfoDialog';

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void;
  id: string;
  onDelete: () => void;
  onEdit: () => void;
};

export const MobileTabs = ({
  open, setOpen, id, onDelete, onEdit,
}: Props) => {
  const {
    onCreateNewStage, setActiveTab, activeTab, currentPosition,
  } = useStages(id);

  useEffect(() => {
    if (open) {
      setActiveTab(0);
    }
  }, [open]);

  const onNew = () => {
    setActiveTab(3);
  };

  const onTemplateSelect = (templateId: string) => {
    onCreateNewStage({ templateId, position: currentPosition });
    setOpen(false);
  };

  const onTemplate = () => {
    setActiveTab(2);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onGoBack = (tab?: number) => {
    if (tab !== undefined) {
      setActiveTab(tab);
      return;
    }
    if (activeTab === 0) {
      handleClose();
      return;
    }
    setActiveTab(activeTab - 1);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="temporary"
      onOpen={() => {
        setOpen(true);
      }}
      swipeAreaWidth={56}
      disableSwipeToOpen
      PaperProps={{
        sx: {
          borderRadius: '0px',
          '& .mobile-table-row': {
            borderWidth: '1px',
            borderRadius: '12px',
          },
          '& .mobile-table-row-header': {
            minHeight: 'auto',
            padding: '0px',
          },
        },
      }}
    >
      <Puller />
      <SwipeableViews
        index={activeTab}
        onChangeIndex={setActiveTab}
        disabled
        containerStyle={{
          height: '100%',
        }}
        style={{
          height: '100%',
        }}
      >
        <CommonOptionsTab
          onGoBack={onGoBack}
          id={id}
          onChangeTab={() => setActiveTab(1)}
          onDelete={onDelete}
          onClose={() => setOpen(false)}
          onEdit={onEdit}
        />
        <AddTab onTemplate={onTemplate} onNew={onNew} onGoBack={onGoBack} onClose={() => setOpen(false)} />
        <TemplatesTab onTemplateSelect={onTemplateSelect} onGoBack={() => onGoBack(1)} onClose={() => setOpen(false)} />
        <CreateStageTab onGoBack={onGoBack} onClose={() => setOpen(false)} onCreate={onCreateNewStage} />
      </SwipeableViews>
    </SwipeableDrawer>
  );
};
