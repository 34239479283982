import { Avatar, Badge, ListItemAvatar } from '@mui/material';
import { useCallback } from 'react';
import { iconColorPalette } from '../../routes-old/process/helpers';

interface Props {
  size: number;
  fontSize: number;
  fontWeight: number;
  isBadgeEnabled?: boolean;
  isOnline?: boolean;
  avatarSrc?: string;
  isTeamMember?: boolean;
  userName?: string;
  colorId?: number;
  marginLeft?: number;
  marginRight?: number;
  disableWrapper?: boolean;
  zIndex?: number;
}

const UserItemAvatar = ({
  isBadgeEnabled,
  isOnline,
  avatarSrc,
  isTeamMember,
  userName,
  colorId,
  size,
  fontSize,
  fontWeight,
  marginLeft,
  marginRight,
  disableWrapper,
  zIndex,
}: Props): JSX.Element => {
  const letter: string | undefined = userName ? userName[0].toUpperCase() : undefined;

  const avatarBackground: string = colorId ? `${iconColorPalette[colorId].back} !important` : '#bdbdbd !important';

  const avatarTextColor: string = colorId ? `${iconColorPalette[colorId].letter} !important` : '';

  const avatarBaseStyles = {
    minHeight: `${size}px`,
    height: `${size}px`,
    minWidth: `${size}px`,
    width: `${size}px`,
    marginLeft: `${marginLeft}px !important`,
    zIndex,
  };

  const avatarWrapperStyle = {
    marginLeft: `${marginLeft}px`,
    marginRight: `${marginRight}px`,
  };

  const avatarContentStyles = {
    fontSize: `${fontSize}px`,
    fontWeight,
    color: avatarTextColor,
    background: avatarBackground,
    marginLeft: `${marginLeft}px !important`,
    zIndex: `${zIndex}`,
  };

  const AvatarWithWrapper = useCallback((): JSX.Element => (
    <ListItemAvatar
      sx={{
        ...avatarBaseStyles,
        ...avatarWrapperStyle,
        background: 'transparent',
      }}
    >
      {isBadgeEnabled && (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        variant="dot"
        sx={{
          '& .MuiBadge-badge': {
            border: (theme) => `2px solid ${theme.palette.background.paper}`,
            borderRadius: '50%',
            width: '12px',
            height: '12px',
            backgroundColor: (theme) => (isOnline ? theme.palette.primary.main : '#AAA'),
          },
        }}
      >
        <Avatar
          src={avatarSrc}
          sx={{
            ...avatarBaseStyles,
            ...avatarContentStyles,
            border: isTeamMember ? '2px solid' : '2px solid',
            borderColor: isTeamMember ? (theme) => theme.palette.primary.main : 'white',
          }}
        >
          {letter}
        </Avatar>
      </Badge>
      )}
      {
          !isBadgeEnabled
          && (
            <Avatar
              src={avatarSrc}
              sx={{
                ...avatarBaseStyles,
                ...avatarContentStyles,
                borderColor: isTeamMember ? (theme) => theme.palette.primary.main : 'white',
              }}
            >
              {letter}
            </Avatar>
          )
        }
    </ListItemAvatar>
  ), [isBadgeEnabled, isTeamMember]);

  const AvatarWithoutWrapper = useCallback((): JSX.Element => (
    <>
      {isBadgeEnabled && (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        variant="dot"
        sx={{
          '& .MuiBadge-badge': {
            border: (theme) => `2px solid ${theme.palette.background.paper}`,
            borderRadius: '50%',
            width: '12px',
            height: '12px',
            backgroundColor: (theme) => (isOnline ? theme.palette.primary.main : '#AAA'),
          },
        }}
      >
        <Avatar
          src={avatarSrc}
          sx={{
            ...avatarBaseStyles,
            ...avatarContentStyles,
            border: '2px solid',
            borderColor: isTeamMember ? (theme) => theme.palette.primary.main : 'white',
          }}
        >
          {letter}
        </Avatar>
      </Badge>
      )}
      {
          !isBadgeEnabled
          && (
          <Avatar
            src={avatarSrc}
            sx={{
              '&.MuiAvatar-root': {
                zIndex,
              },
              ...avatarBaseStyles,
              ...avatarContentStyles,
              borderColor: isTeamMember ? (theme) => theme.palette.primary.main : 'white',
            }}
          >
              {letter}
          </Avatar>
          )
        }
    </>
  ), [isBadgeEnabled, isTeamMember, zIndex]);

  if (disableWrapper) {
    return <AvatarWithoutWrapper />;
  }

  return (
    <AvatarWithWrapper />
  );
};

export default UserItemAvatar;
