import { SvgIcon } from '@mui/material';

export const AvatarIcon20 = (): JSX.Element => (
  <SvgIcon
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <path
      d="M36.4001 38.1999V34.5999C36.4001 32.6903
 35.6415 30.859 34.2913 29.5087C32.941 28.1585 31.1097
  27.3999 29.2001 27.3999H14.8001C12.8905 27.3999 11.0592
   28.1585 9.70893 29.5087C8.35867 30.859 7.6001
    32.6903 7.6001 34.5999V38.1999"
      fill="none"
      stroke="#7084B4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9998 20.1998C25.9763 20.1998
 29.1998 16.9763 29.1998 12.9998C29.1998 9.02335
  25.9763 5.7998 21.9998 5.7998C18.0234 5.7998
   14.7998 9.02335 14.7998 12.9998C14.7998
   16.9763 18.0234 20.1998 21.9998 20.1998Z"
      stroke="#7084B4"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
