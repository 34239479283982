import { Box } from '@mui/material';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { memo } from 'react';
import useFieldsLayoutSlice from '../../hooks/useFieldsLayoutSlice';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { FormattedLabel } from '../../shared/AssigneeSelection/FormattedLabel';

interface Props {
  id: string;
  title: string;
  isFirst: boolean;
  isLast: boolean;
}

// eslint-disable-next-line react/display-name
const StagesSidebarSubitem = memo(({
  id,
  title,
  isFirst,
  isLast,
}: Props): JSX.Element => {
  const {
    isActiveSection, scrollToBlock, onDrawerStatusUpdate,
  } = useFieldsLayoutSlice(id);

  const onSubItemClick = () => {
    scrollToBlock(isFirst || isLast ? 'start' : undefined);
    if (isMobile) {
      onDrawerStatusUpdate(false);
    }
  };

  return (
    <Box
      className="StageSideBarSubItem"
      sx={{
        display: 'flex',
        alignItems: 'center',
        transition: 'background 0.1s',
        cursor: 'pointer',
        color: (theme) => (greySidebarContrastText(theme, 0.8)),
        '&:hover': {
          '& .StageSideBarSubItemHighlight': {
            background: (theme) => greySidebarContrastText(theme, 0.3),
          },
        },
      }}
      onClick={(e) => { e.stopPropagation(); onSubItemClick(); }}
    >
      <Box
        sx={{
          padding: '0px 0px 0px 34px',
        }}
      >
        <Box
          className={classNames('StageSideBarSubItemHighlight', { 'StageSideBarSubItemHighlight-active': isActiveSection })}
          sx={{
            width: '6px',
            height: '6px',
            borderRadius: '1px 1px 1px 1px',
            transition: 'background 0.1s',
            '&.StageSideBarSubItemHighlight-active': {
              background: (theme) => greySidebarContrastText(theme, 0.8),
            },
          }}
        />
      </Box>
      <Box
        sx={{
          padding: '8px 16px 8px 28px',
        }}
      >
        <FormattedLabel
          label={title ?? 'New section'}
          len={24}
          tooltip
          placement="right"
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
          }}
        />
      </Box>
    </Box>
  );
});

export default StagesSidebarSubitem;
