import { Fade, Typography } from '@mui/material';
import React from 'react';

interface Props {
  message?: string;
  isVisible: boolean;
}

const FormHint = ({ message, isVisible }: Props): JSX.Element => (
  <Fade in={isVisible} exit>
    <Typography
      sx={{
        color: (theme) => theme.palette.grey?.[600],
        fontSize: '14px',
      }}
    >
      {message}
    </Typography>
  </Fade>
);

export default FormHint;
