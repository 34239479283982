import {
  createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid';
import {
  CreateProcessByTemplateIdRequest,
  EProcessType,
  ProcessDTO,
  ProcessStatsDTO,
} from '../../../../shared/process/ProcessMilestoneActionDTO';
import { NotificationsApi } from '../../../api/NotificationsApi';
import { ProcessApi } from '../../../api/ProcessApi';
import { ProcessStatsApi } from '../../../api/ProcessStatsApi';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import { DealsType } from '../../../../shared/process/Process';
import { prepareStats } from './calculate-status-and-last-milestone';
import { SellerView } from '../model/enums';
import { sessionToDealType } from './deals-slice.helper';

const processApi: ProcessApi = new ProcessApi();
const processStatsApi: ProcessStatsApi = new ProcessStatsApi();
const notificationsApi: NotificationsApi = new NotificationsApi();

interface IDealsAdditionalState {
  status: EStateStatus;
  templateId: string;
  selectedTemplateId: string;
  isCreateDialogOpen: boolean;
  isEditDealDialogOpen: boolean;
  isCloneTemplateDialogOpen: boolean;
  selectedDealId: string;
  error?: string;
  isProcessStatsLoaded: boolean;
  isFirstFetchFinished?: boolean;
  processesLoadingJobs: number;
  gridToRender: DealsType;
  totalCount: number;
  sortModel: GridSortModel;
}

const dealsAdapter = createEntityAdapter<ProcessDTO>({
  // sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const DEALS_INITIAL_REQUEST_SIZE: number = 50;
const DEALS_ADDITIONAL_REQUEST_SIZE: number = 50;

export const dealsReducerName: string = 'deals';

export enum EDealsActions {
  GET_DEALS = 'GET_DEALS',
  CREATE_DEAL = 'CREATE_DEAL',
  UPDATE_DEAL = 'UPDATE_DEAL',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  DELETE_PROCESS = 'DELETE_PROCESS',
  GET_DEALS_STATS = 'GET_DEALS_STATS',
  GET_DEALS_NOTIFICATIONS = 'GET_DEALS_NOTIFICATIONS',
  GET_FILTER_INFO = 'GET_FILTER_INFO',
  GET_ADDITIONAL_DEALS = 'GET_ADDITIONAL_DEALS',
}

export type TDealsState = IDealsAdditionalState & EntityState<ProcessDTO>;

export const initialState: TDealsState = dealsAdapter.getInitialState({
  status: EStateStatus.IDLE,
  selectedTemplateId: '',
  selectedDealId: '',
  isCreateDialogOpen: false,
  isEditDealDialogOpen: false,
  isCloneTemplateDialogOpen: false,
  templateId: '',
  isProcessStatsLoaded: false,
  isFirstFetchFinished: false,
  processesLoadingJobs: 0,
  gridToRender: sessionToDealType(sessionStorage.getItem('dealGridToRender') as DealsType),
  totalCount: 0,
  sortModel: [{
    field: 'lastActive',
    sort: 'desc',
  }],
});

export const getProcesses = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.GET_DEALS}`,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let limit: number = DEALS_INITIAL_REQUEST_SIZE;
    if (localStorage.getItem('sellerView') === SellerView.KANBAN) {
      limit = -1;
    }
    return processApi.getSellerDeals({
      search: state.dealsGridFilter.search,
      params: state.dealsGridFilter.gridFilters[DealsType.SELLER_DEALS],
      sortModel: state.deals.sortModel,
    }, limit);
  },
);

export const getAdditionalProcesses = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.GET_ADDITIONAL_DEALS}`,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { selectTotal } = dealsAdapter.getSelectors();
    let limit: number = DEALS_ADDITIONAL_REQUEST_SIZE;
    if (localStorage.getItem('sellerView') === SellerView.KANBAN) {
      limit = -1;
    }
    return processApi.getSellerDeals(
      {
        search: state.dealsGridFilter.search,
        params: state.dealsGridFilter.gridFilters[DealsType.SELLER_DEALS],
        sortModel: state.deals.sortModel,
      },
      limit,
      selectTotal(state.deals),
    );
  },
);

export const getProcessesStats = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.GET_DEALS_STATS}`,
  async (payload: string[]) => processStatsApi.getProcessesStats(payload),
);

export const getProcessesNotifications = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.GET_DEALS_NOTIFICATIONS}`,
  async (payload: string[]) => notificationsApi.getUserDealsNotificationsBulk(payload),
);

export const createProcess = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.CREATE_DEAL}`,
  async (payload: CreateProcessByTemplateIdRequest, thunkAPI) => {
    const createdDTO: ProcessDTO = await processApi.createProcessByTemplateId(payload);
    thunkAPI.dispatch(getProcesses);
    return createdDTO;
  },
);

export const deleteProcess = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.DELETE_PROCESS}`,
  async (payload: string) => processApi.deleteProcess(payload),
);

export const updateProcess = createAsyncThunk(
  `${dealsReducerName}/${EDealsActions.UPDATE_DEAL}`,
  async (payload: Partial<ProcessDTO> & Pick<ProcessDTO, 'id'>) => processApi.updateProcess(payload),
);

export const dealsSlice = createSlice({
  name: dealsReducerName,
  initialState,
  reducers: {
    clearDealsState: () => initialState,
    openEditDealDialog: (state, action: PayloadAction<string>) => {
      state.isEditDealDialogOpen = true;
      state.selectedDealId = action.payload;
    },
    closeEditDealDialog: (state) => {
      state.isEditDealDialogOpen = false;
      state.selectedDealId = '';
    },
    setCreateDealDialogStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateDialogOpen = payload;
    },
    setGridToRender: (state, { payload }: PayloadAction<DealsType>) => {
      state.gridToRender = payload;
    },
    setSortModel: (state, { payload }: PayloadAction<{ field: string; sort: string; }>) => {
      state.sortModel = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcesses.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.processesLoadingJobs += 1;
      })
      .addCase(getProcesses.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        dealsAdapter.setAll(state, action.payload.processes);
        state.isProcessStatsLoaded = false;
        state.isFirstFetchFinished = true;
        state.processesLoadingJobs -= 1;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getProcesses.rejected, (state) => {
        state.status = EStateStatus.ERROR;
        state.processesLoadingJobs -= 1;
      })
      .addCase(getAdditionalProcesses.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.processesLoadingJobs += 1;
      })
      .addCase(getAdditionalProcesses.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        dealsAdapter.addMany(state, action.payload.processes);
        state.isProcessStatsLoaded = false;
        state.isFirstFetchFinished = true;
        state.processesLoadingJobs -= 1;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getAdditionalProcesses.rejected, (state) => {
        state.status = EStateStatus.ERROR;
        state.processesLoadingJobs -= 1;
      })
      .addCase(createProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(createProcess.fulfilled, (state) => {
        state.status = EStateStatus.IDLE;
        // dealsAdapter.addOne(state, action.payload);
      })
      .addCase(createProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        dealsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
      })
      .addCase(updateProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(deleteProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(deleteProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        dealsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessesStats.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessesStats.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        action.payload.forEach((processStats: ProcessStatsDTO) => {
          dealsAdapter.updateOne(state, {
            id: processStats.processId,
            changes: {
              processStats: {
                ...processStats,
                ...prepareStats(processStats.tasks),
              },
            },
          });
        });
        state.isProcessStatsLoaded = true;
      })
      .addCase(getProcessesStats.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      });
  },
});

export const {
  clearDealsState,
  openEditDealDialog,
  closeEditDealDialog,
  setCreateDealDialogStatus,
  setGridToRender,
  setSortModel,
} = dealsSlice.actions;

export const {
  selectAll,
  selectById,
  selectIds,
  selectTotal,
} = dealsAdapter.getSelectors();

export const allDealsSelector = (state: RootState) => selectAll(state.deals);
export const processByIdSelector = (state: RootState, id: string) => selectById(state.deals, id);
export const getSelectedDial = (state: RootState) => selectById(state.deals, state.deals.selectedDealId);
export const selectCreateDealDialogStatus = (state: RootState): boolean => state.deals.isCreateDialogOpen;
export const selectFirstFetchStatus = (state: RootState): boolean => state.deals.isFirstFetchFinished;
export const getSelectedTemplate = (state: RootState) => selectById(state.deals, state.deals.selectedTemplateId);
export const dealsSelector = (state: RootState) => selectAll(state.deals).filter((deal: ProcessDTO) => deal.type === EProcessType.DEAL);
export const selectEditDealDialogStatus = (state: RootState) => state.deals.isEditDealDialogOpen;
export const selectSelectedDealId = (state: RootState) => state.deals.selectedDealId;
export const selectGridToRender = (state: RootState) => state.deals.gridToRender;
export const selectProcessesLoadingJobs = (state: RootState) => state.deals.processesLoadingJobs;
export const selectDealsCurrentPage = (state: RootState) => state.deals.currentPage;
export const selectDealsTotalCount = (state: RootState) => state.deals.totalCount;
export const lastIdSelector = (state: RootState) => {
  const ids = selectIds(state.deals);
  return ids.length ? ids[ids.length - 1] : '';
};
export const totalFetchedSelector = (state: RootState) => selectTotal(state.deals);
export const selectSortModel = (state: RootState) => state.deals.sortModel;
