import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ErrorCode, FileRejection } from 'react-dropzone';
import { FilesDragAndDrop } from '../../../../../common/FilesDragAndDrop/FilesDragAndDrop';
import {
  checkFileAcceptance, getSubtypeByMIME, mediaFieldFormats,
} from '../../../../MediaField/Helpers/MediaFieldHelpers';
import { MediaFieldUploadIcon } from '../../../../../icons/DndIcons/DnDIcons';
import { EMediaFieldStorageType } from '../../../../../../shared/Field/Field';
import { useFileUploadContext } from '../../../../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { MediaNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';

const MediaNodeUpload = (
  {
    onUpdate,
  }: {
    onUpdate: (data: { [key: string] : any }) => void
  },
): JSX.Element => {
  const { data: blockData } = useBlockContext<MediaNodeAttrs>();
  const { onUpload } = useFileUploadContext();
  const [formatError, setFormatError] = useState<string>('');

  const handleUploadStart = async (data: File[], rejections?: FileRejection[]): Promise<void> => {
    if (!!rejections && !!rejections.length) {
      const reason = rejections[0].errors[0].code;
      if (reason === ErrorCode.FileInvalidType) {
        setFormatError('Wrong file format!');
        return;
      }
      if (reason === ErrorCode.FileTooLarge) {
        setFormatError('Wrong file size!');
        return;
      }
    }
    if (!data.length) {
      setFormatError('File couldn\'t be handled. Check constraints and try again.');
      return;
    }
    if (!checkFileAcceptance(data[0].name, mediaFieldFormats)) {
      setFormatError('Wrong file format');
    } else {
      setFormatError('');
      // setUploading(true);
      try {
        const itemId = await onUpload(data[0], blockData.blockId);
        onUpdate({
          doc: itemId,
          subType: getSubtypeByMIME(data[0].type),
          storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
        });
      } catch (e) {
        console.log('error occurred file uploading process');
      }
      // setUploading(false);
    }
  };

  const dndText: JSX.Element = (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <MediaFieldUploadIcon />
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            color: 'rgba(32, 32, 32, 1)',
            marginLeft: '6px',
          }}
        >
          Drop your file here or
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            marginLeft: '5px',
            color: '#0073F5',
          }}
        >
          &nbsp;browse
        </Typography>
      </Box>
      <Box>
        <Typography
          className="DnDAcceptedFormats"
          sx={{
            marginTop: '8px',
            fontSize: '12px',
            lineHeight: '16px',
            color: 'rgba(112, 132, 180, 1)',
          }}
        >
          {
           mediaFieldFormats.join(', ').toUpperCase()
          }
        </Typography>
        {
          formatError && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '0px',
              }}
            >
              <Typography
                color="error"
                sx={{
                  fontSize: '12px',
                  paddingTop: '5px',
                }}
              >
                {formatError}
              </Typography>
            </Box>
          )
            }
      </Box>
    </>
  );

  return (
    <FilesDragAndDrop
      dropzoneOptions={{
        accept: `.${mediaFieldFormats.join(', .')}`,
      }}
      onFileDrop={handleUploadStart}
      text={dndText}
      icon="none"
      border="1px dashed #C8D0E0"
      styles={{
        borderRadius: '8px',
        height: '110px',
        maxWidth: 'unset',
        minHeight: '110px',
        padding: '0px',
        background: 'white',
      }}
      textStyle={{
        alignSelf: 'center',
        fontSize: '14px',
        paddingLeft: '3px',
      }}
      textContainerStyles={{
        background: 'white',
      }}
    />
  );
};

export default MediaNodeUpload;
