import {
  Box, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { CloseIcon } from '../../../icons/Close16px';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { ELibraryFeedTab } from '../helpers/library.helper';
import { selectLibraryFeedTab, selectLibraryFeedTitle, setLibraryFeedTab } from '../lib/librarySlice';

type Props = {
  name?: string
};

const LibraryFeedHeader = ({ name } :Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentFeedTab: ELibraryFeedTab = useAppSelector(selectLibraryFeedTab);
  const tabTitle = useAppSelector(selectLibraryFeedTitle);

  const FeedHeader = styled(Box)(() => ({
    height: '72px',
    minHeight: '72px',
    display: 'flex',
    padding: '24px',
    flexDirection: 'row',
    background: stagesetColors.white[100],
    color: stagesetColors.grey[500],
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    zIndex: 10,
    boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  }));

  const productHeader = () => {
    switch (currentFeedTab) {
      case ELibraryFeedTab.NONE:
        return undefined;
      case ELibraryFeedTab.PRODUCT:
        return tabTitle;
      default:
        return '';
    }
  };

  return (
    <FeedHeader
      id="deal-feed-header"
    >
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '26px',
          paddingY: '4px',
          paddingLeft: '4px',
          color: stagesetColors.grey[600],
        }}
      >
        {name || productHeader()}
      </Typography>
      <AppIconButton
        onClick={() => {
          dispatch(setLibraryFeedTab({
            open: false,
            type: ELibraryFeedTab.NONE,
            item: undefined,
          }));
        }}
        icon={<CloseIcon />}
        size="m"
      />
    </FeedHeader>
  );
};

export default LibraryFeedHeader;
