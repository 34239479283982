import { Button, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EAppRoutes } from '../../core/router';

const DealError = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        position: 'absolute',
        right: '0',
        top: '0',
        bottom: '0',
        left: '0',
        margin: 'auto',
        borderRadius: '16px',
        aspectRatio: '6/1',
        maxWidth: '800px',
        padding: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          mb: '32px',
        }}
        variant="h3"
      >
        Error!
      </Typography>
      <Typography>
        You do not have access to this deal or deal does not exist
      </Typography>
      <Button
        variant="contained"
        sx={{
          m: '32px',
          mt: '48px',
        }}
        onClick={() => navigate(EAppRoutes.home)}
      >
        Home
      </Button>
    </Paper>
  );
};

export default DealError;
