import { Box, CardMedia } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import MediaResizePlugin from './MediaResizePlugin';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { StageContentType } from '../../../shared/Stage';
import { EFieldsubType } from '../../../shared/Field/Field';
import useScreenDimensions from '../../hooks/useScreenDimensions';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsToolbarNotShown } from '../../routes-old/auth/state/authState';
import { MediaNodeAttrs, TiptapNodeUpdate } from '../../../shared/Tiptap/TiptapTypes';

interface Props {
  url: string;
  onUpdate: (data: TiptapNodeUpdate<MediaNodeAttrs>) => void;
  subType?: EFieldsubType;
  width?: number;
  height?: number;
  adjustBy: string | undefined;
}

const MediaFieldImgComponent = ({
  url,
  onUpdate,
  subType,
  width,
  height,
  adjustBy,
}: Props): JSX.Element => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const currentStage = useCurrentStage();
  const [screenHeight, screenWidth] = useScreenDimensions(true);
  const [componentAdjust, setComponentAdjust] = useState('');
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const [imageAspectRatio, setImageAspectRatio] = useState<string>('');
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | null>(null);
  const receivedAdjustingByHeight = imageAspectRatio === 'high' && componentAdjust === 'height';
  const receivedAdjustingByWidth = imageAspectRatio === 'high' && componentAdjust === 'width';
  const isToolbarNotShown = useAppSelector(selectIsToolbarNotShown);
  const wideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;

  useEffect(() => {
    if (!!url && !!imageIsLoading) {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        setLoadedImage(image);
        setImageIsLoading(false);
      };
    }
  }, []);

  useEffect(() => {
    if (subType === EFieldsubType.IMG) {
      if (screenWidth
        && screenHeight
        && loadedImage
      ) {
        if (isMobile) {
          const heightDifference = loadedImage.naturalHeight / (screenHeight - 52);
          setImageAspectRatio(loadedImage.naturalWidth / heightDifference > screenWidth ? 'wide' : 'high');
        } else {
          const heightDifference = loadedImage.naturalHeight / (screenHeight);
          setImageAspectRatio(loadedImage.naturalWidth / heightDifference > (screenWidth - 300) ? 'wide' : 'high');
        }
      }
    }
  }, [loadedImage, screenWidth, screenHeight]);

  useEffect(() => {
    if (subType === EFieldsubType.IMG) {
      if (!!adjustBy && adjustBy === 'height') {
        setComponentAdjust('height');
        return;
      }
      setComponentAdjust('width');
    }
  }, [adjustBy]);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent(ECustomEventsNames.IMG_ADJUST_FOR_BUTTONS, { detail: { adjust: componentAdjust } }));
  }, [componentAdjust]);

  useEffect(() => {
    const onEvent = (e: CustomEvent) => {
      setComponentAdjust(e.detail.adjust);
    };
    document.addEventListener(ECustomEventsNames.IMG_ADJUST, onEvent as EventListener);
    return () => {
      document.removeEventListener(ECustomEventsNames.IMG_ADJUST, onEvent as EventListener);
    };
  }, []);

  const calculateHeight = () => {
    switch (isMobile) {
      case true:
        switch (receivedAdjustingByWidth) {
          case true:
            return '100%';
          default:
            return 'calc(100vh - 52px)';
        }
      default:
        switch (receivedAdjustingByWidth) {
          case true:
            return '100%';
          default:
            switch (isToolbarNotShown) {
              case true:
                return 'calc(100vh)';
              default:
                return 'calc(100vh - 48px)';
            }
        }
    }
  };

  const calculateWidth = () => {
    switch (isMobile) {
      case true:
        switch (receivedAdjustingByHeight) {
          case true:
            return 'auto';
          default:
            return 'calc(100vw)';
        }
      default:
        switch (receivedAdjustingByHeight) {
          case true:
            return 'auto';
          default:
            return 'calc(100vw - 300px)';
        }
    }
  };

  const imageResizeSwitcher = () => {
    if (wideScreenStage) {
      return (
        <Box
          sx={{
            display: 'flex',
            userSelect: 'none',
            overflow: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            width: calculateWidth(),
            height: calculateHeight(),
          }}
        >
          <CardMedia
            component="img"
            src={url}
            sx={{
              userSelect: 'none',
              width: adjustBy === 'width'
                ? '100%'
                : 'auto',
              height: adjustBy === 'width'
                ? 'auto'
                : '100%',
            }}
          />
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          userSelect: 'none',
          maxWidth: 'calc( 100% )',
        }}
      >
        <Box
          ref={imgRef}
          sx={{
            display: 'flex',
            width: width ? `${width}px` : 'auto',
            height: height ? `${height}px` : 'auto',
            maxWidth: 'calc( 100% )',
          }}
        >
          <CardMedia
            component="img"
            src={url}
            sx={{
              userSelect: 'none',
              borderRadius: '15px',
              objectFit: 'cover',
            }}
          />
        </Box>
        {
          !!imgRef?.current
          && !wideScreenStage
          && !isMobile && (
            <MediaResizePlugin
              containerRef={imgRef.current}
              width={width}
              height={height}
              onUpdate={onUpdate}
            />
          )
        }
      </Box>
    );
  };
  return (
    <>
      {imageResizeSwitcher()}
    </>
  );
};

export default MediaFieldImgComponent;
