import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronLeft20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g
      id="chevron-left  20px"
    >
      <path
        fill="none"
        id="Vector"
        d="M12.5 15L7.5 10L12.5 5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
