import { Box } from '@mui/system';
import { useEffect } from 'react';
import { ProcessStageDTO } from '../../../shared/Field/Field';
import DealFeed from '../../routes-old/process/DealFeed/DealFeed';
import RoomToolbar from '../Stages/RoomToolbar';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { getTemplateStageItems, selectCurrentStage } from '../ProcessFields/lib/processItemsSlice';
import StageContent from '../Layout/StageContent';

interface Props {
  id: string;
  onClose?: () => void;
  toolbar?: boolean;
}

const StageDisplay = ({
  id,
  onClose,
  toolbar,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const stageTemplateData = useAppSelector(selectCurrentStage);

  useEffect(() => {
    dispatch(getTemplateStageItems(id));
  }, [id]);

  // TODO: skeleton
  if (!stageTemplateData) {
    return null;
  }

  return (
    <>
      {toolbar && !onClose && <RoomToolbar toolbarPlace="stage" />}
      <Box
        className="StageDisplayScrollableContainer"
        sx={{
          width: '100%',
          background: 'transparent',
          height: 'auto',
          overflow: 'auto',
          maxHeight: 'calc( 100% )',
          display: 'flex',
          justifyContent: 'center',
          '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
            display: 'none',
          },
        }}
      >
        <StageContent
          stage={stageTemplateData as ProcessStageDTO}
        />
        <DealFeed />
      </Box>
    </>
  );
};

export default StageDisplay;
