import mixpanel, { Dict } from 'mixpanel-browser';
import { config } from './config';
import { store } from './store/store';

export enum EMixPanelEvents {
  APPLICATION_OPENED = 'APPLICATION_OPENED',
  DEAL_CREATED = 'DEAL_CREATED',
  CREATE_ROOM_TEMPLATE_FROM_SCRATCH = 'CREATE_ROOM_TEMPLATE_FROM_SCRATCH',
  CREATE_PAGE_TEMPLATE_FROM_SCRATCH = 'CREATE_PAGE_TEMPLATE_FROM_SCRATCH',
  CREATE_SECTION_TEMPLATE_FROM_SCRATCH = 'CREATE_SECTION_TEMPLATE_FROM_SCRATCH',
  VIEW_ROOM_ANONYMOUS_BUTTON_PRESSED = 'VIEW_ROOM_ANONYMOUS_BUTTON_PRESSED',
  VIEW_ROOM_ANONYMOUS_WITH_EMAIL_BUTTON_PRESSED = 'VIEW_ROOM_ANONYMOUS_WITH_EMAIL_BUTTON_PRESSED',
  EDIT_ROOM_TEMPLATE_BUTTON_PRESSED = 'EDIT_ROOM_TEMPLATE_BUTTON_PRESSED',
  EDIT_PAGE_TEMPLATE_BUTTON_PRESSED = 'EDIT_PAGE_TEMPLATE_BUTTON_PRESSED',
  EDIT_SECTION_TEMPLATE_BUTTON_PRESSED = 'EDIT_SECTION_TEMPLATE_BUTTON_PRESSED',
  TEMPLATE_FROM_PAGE_CREATED = 'TEMPLATE_FROM_PAGE_CREATED',
  ADD_PAGE_FROM_TEMPLATE_TEMPLATE_CHOSEN = 'ADD_PAGE_FROM_TEMPLATE_TEMPLATE_CHOSEN',
  TEMPLATE_FROM_SECTION_CREATED = 'TEMPLATE_FROM_SECTION_CREATED',
  MILESTONE_CLICKED = 'MILESTONE_CLICKED',
  ROW_CLICKED = 'ROW_CLICKED',
  LAST_MILESTONE_CLICKED = 'LAST_MILESTONE_CLICKED',
  THREE_DOTS_CLICKED = 'THREE_DOTS_CLICKED',
  THREE_DOTS_EDIT_OPTION_CLICKED = 'THREE_DOTS_EDIT_OPTION_CLICKED',
  THREE_DOTS_CREATE_OPTION_CLICKED = 'THREE_DOTS_CREATE_OPTION_CLICKED',
  THREE_DOTS_DELETE_OPTION_CLICKED = 'THREE_DOTS_DELETE_OPTION_CLICKED',
  USE_TEMPLATE_CLICKED = 'USE_TEMPLATE_CLICKED',
  EDIT_TEMPLATE_CLICKED = 'EDIT_TEMPLATE_CLICKED',
  CLONE_TEMPLATE_CLICKED = 'CLONE_TEMPLATE_CLICKED',
  DELETE_TEMPLATE_CLICKED = 'DELETE_TEMPLATE_CLICKED',
  TEMPLATES_GALLERY_CLICKED = 'TEMPLATES_GALLERY_CLICKED',
  DEAL_SUMMARY_TITLE_CLICKED = 'DEAL_SUMMARY_TITLE_CLICKED',
  MILESTONE_SUMMARY_TITLE_CLICKED = 'MILESTONE_SUMMARY_TITLE_CLICKED',
  DEAL_SUMMARY_TEXT_CLICKED = 'DEAL_SUMMARY_TEXT_CLICKED',
  MILESTONE_SUMMARY_TEXT_CLICKED = 'MILESTONE_SUMMARY_TEXT_CLICKED',
  BUYERS_LOGO_CLICKED = 'BUYERS_LOGO_CLICKED',
  SELLERS_LOGO_CLICKED = 'SELLERS_LOGO_CLICKED',
  USER_AVATAR_CLICKED = 'USER_AVATAR_CLICKED',
  MILESTONE_THREE_DOTS_CLICKED = 'MILESTONE_THREE_DOTS_CLICKED',
  MILESTONE_THREE_DOTS_DELETE_OPTION_CLICKED = 'MILESTONE_THREE_DOTS_DELETE_OPTION_CLICKED',
  MILESTONE_THREE_DOTS_CREATE_OPTION_CLICKED = 'MILESTONE_THREE_DOTS_CREATE_OPTION_CLICKED',
  ASSIGNEES_CLICKED = 'ASSIGNEES_CLICKED',
  DEAL_VIEW_ASSIGNEES_CLICKED = 'DEAL_VIEW_ASSIGNEES_CLICKED',
  MILESTONE_HEADER_ROW_ASSIGNEES_CLICKED = 'MILESTONE_HEADER_ROW_ASSIGNEES_CLICKED',
  MILESTONE_STATUS_CELL_CLICKED = 'MILESTONE_STATUS_CELL_CLICKED',
  MILESTONE_HEADER_STATUS_CELL_CLICKED = 'MILESTONE_HEADER_STATUS_CELL_CLICKED',
  COMPLETE_ACTION_CHECKBOX_CLICKED = 'COMPLETE_ACTION_CHECKBOX_CLICKED',
  TASK_EDIT_TITLE_CLICKED = 'TASK_EDIT_TITLE_CLICKED',
  TASK_DELETE_CLICKED = 'TASK_DELETE_CLICKED',
  ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED',
  ACCOUNT_LOGIN = 'ACCOUNT_LOGIN',
  ACCOUNT_GOOGLE_LOGIN = 'ACCOUNT_GOOGLE_LOGIN',
  ACCOUNT_LINKEDIN_LOGIN = 'ACCOUNT_LINKEDIN_LOGIN',
  ACCOUNT_SOCIAL_LOGIN = 'ACCOUNT_SOCIAL_LOGIN',
  ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT',
  BUTTON_EDIT_TITLE_CLICKED = 'BUTTON_EDIT_TITLE_CLICKED',
  TEMPLATE_FROM_ROOM_CREATED = 'TEMPLATE_FROM_ROOM_CREATED',
  ADD_MEDIA_FROM_LIBRARY_FOR_PAGE = 'ADD_MEDIA_FROM_LIBRARY_FOR_PAGE',
}

export class Analytics {
  static instance: Analytics;

  private constructor() {
    mixpanel.init(config.mixpanel.key, { ignore_dnt: true });
  }

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  }

  track(
    event_name: EMixPanelEvents,
    properties?: Dict,
  ): void {
    const { user, organization } = store.getState().app;
    mixpanel.track(
      event_name,
      {
        user: user?.email,
        title: user?.title,
        name: user?.name,
        organizationId: organization?.id,
        ...properties,
      },
    );
  }

  addUser(
    properties: Dict,
  ): void {
    mixpanel.identify(properties.email);
    mixpanel.people.set_once({
      distinct_id: properties.email,
      $name: properties.data.fullName,
      $email: properties.email,
    });
  }

  userLogout(): void {
    mixpanel.reset();
  }

  userLogin(
    properties: Dict,
  ): void {
    mixpanel.identify(properties.data.email);
  }
}
