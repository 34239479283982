import { EAppRoutes } from '../router';
import { ESubscriptionType } from '../../../shared/Subscription';
import { EMenuTabs } from '../../features/Layout/lib/fieldsLayoutSlice';
import { MenuTemplateIcon20 } from '../../icons/MenuIcons/TemplateIcon';
import { MenuSettingsIcon20 } from '../../icons/MenuIcons/SettingsIcon';
import { MenuRoomsIcon20 } from '../../icons/MenuIcons/RoomsIcon';
import AdminRocket from '../../icons/rocket.svg';
import Folder20px from '../../icons/Folder20px.svg';

export type SideMenuItem = {
  text: string,
  icon: JSX.Element,
  premiumOnly?: boolean,
  disabled?: boolean,
  tooltip?: string,
  link?: EAppRoutes,
  subscriptionExeptions?: ESubscriptionType[],
  menuTab: EMenuTabs,
  adminOnly?: boolean,
};

export const sideMenuItems: SideMenuItem[] = [
  {
    text: 'Rooms',
    icon: <MenuRoomsIcon20 />,
    link: EAppRoutes.home,
    menuTab: EMenuTabs.ROOMS,
  },
  {
    text: 'Templates',
    icon: <MenuTemplateIcon20 />,
    link: EAppRoutes.templates,
    menuTab: EMenuTabs.TEMPLATES,
  },
  {
    text: 'Library',
    icon: <Folder20px />,
    link: EAppRoutes.library,
    menuTab: EMenuTabs.LIBRARY,
  },
  {
    text: 'Settings',
    icon: <MenuSettingsIcon20 />,
    premiumOnly: false,
    link: EAppRoutes.settings,
    menuTab: EMenuTabs.SETTINGS,
    // disabled: true,
    // tooltip: 'Coming soon',
  },
  {
    text: 'Administrator',
    icon: <AdminRocket />,
    premiumOnly: false,
    link: EAppRoutes.admin,
    menuTab: EMenuTabs.ADMIN,
    adminOnly: true,
  },
];
