import { DateTime } from 'luxon';
import { EDealStatus, ProcessStatsDTO, StatsTask } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { ETaskStatus, ETaskTypes } from '../../../../shared/TaskDTO';

const sortTasksByOrderDesc = (tasks: StatsTask[]): StatsTask[] => [...tasks].sort((a: StatsTask, b: StatsTask) => b.order - a.order);
// const sortTasksByOrderAsc = (tasks: StatsTask[]): StatsTask[] => [...tasks].sort((a: StatsTask, b: StatsTask) => a.order - b.order);

export interface StatusAndLastMilestone {
  status: EDealStatus;
  lastMilestone?: {
    title: string;
    id: string;
  };
}

export type PreparedStats = Pick<ProcessStatsDTO, 'status'
| 'tasksStats'
| 'lastMilestone'
| 'tasksStatus'
>;

export const calculateStatusAndLastMilestone = (tasks: StatsTask[]): StatusAndLastMilestone => {
  const onHoldTasks: StatsTask[] = sortTasksByOrderDesc(tasks.filter((task: StatsTask) => task.status === ETaskStatus.ON_HOLD));
  if (onHoldTasks.length > 0) {
    return {
      status: EDealStatus.ON_HOLD,
      lastMilestone: {
        id: onHoldTasks[0].id,
        title: onHoldTasks[0].title,
      },
    };
  }

  const overdueTasks: StatsTask[] = tasks.filter((task: StatsTask) => task.status === ETaskStatus.OVERDUE);
  if (overdueTasks.length > 0) {
    return {
      status: EDealStatus.OVERDUE,
      lastMilestone: {
        id: overdueTasks[0].id,
        title: overdueTasks[0].title,
      },
    };
  }

  const notCompletedTasks: StatsTask[] = tasks.filter((task: StatsTask) => task.status !== ETaskStatus.COMPLETE);
  if (notCompletedTasks.length === 0) {
    return {
      status: EDealStatus.COMPLETED,
    };
  }

  const startedTasks: StatsTask[] = tasks.filter((task: StatsTask) => task.status !== ETaskStatus.PENDING);
  if (startedTasks.length === 0) {
    return {
      status: EDealStatus.NOT_STARTED,
    };
  }

  const inProgressTasks: StatsTask[] = sortTasksByOrderDesc(tasks.filter(
    (task: StatsTask) => task.status !== ETaskStatus.PENDING && task.status !== ETaskStatus.COMPLETE,
  ));
  if (inProgressTasks.length === 0) {
    return {
      status: EDealStatus.NOTHING_IN_PROGRESS,
    };
  }
  const dueTodayMilestone: StatsTask | undefined = inProgressTasks.find(
    // @ts-ignore
    (task: StatsTask) => DateTime.fromISO(task.timeLineEndDate).toISODate() === DateTime.local().toISODate(),
  );
  if (dueTodayMilestone) {
    return {
      status: EDealStatus.DUE_TODAY,
      lastMilestone: {
        id: dueTodayMilestone.id,
        title: dueTodayMilestone.title,
      },
    };
  }

  return {
    status: EDealStatus.IN_PROGRESS,
    lastMilestone: {
      id: inProgressTasks[0].id,
      title: inProgressTasks[0].title,
    },
  };
};

export enum EStatsTaskStatus {
  OVERDUE = 'OVERDUE',
  DUE_TODAY = 'DUE_TODAY',
  DUE_TOMORROW = 'DUE_TOMORROW',
  DEFAULT = 'DEFAULT',
}

export const calculateTaskStatsStatus = (task: StatsTask): EStatsTaskStatus => {
  // @ts-ignore
  const taskLuxonTime: DateTime = DateTime.fromISO(task.timeLineEndDate);
  if (
    taskLuxonTime.toISODate() === DateTime.local().toISODate()
    && task.status !== ETaskStatus.COMPLETE
  ) {
    return EStatsTaskStatus.DUE_TODAY;
  }
  if (taskLuxonTime.startOf('day') < DateTime.local().startOf('day')) {
    return EStatsTaskStatus.OVERDUE;
  }
  if (
    taskLuxonTime > DateTime.local().plus({ day: 1 }).startOf('day')
    && taskLuxonTime < DateTime.local().plus({ day: 2 }).startOf('day')
    && task.status !== ETaskStatus.COMPLETE
  ) {
    return EStatsTaskStatus.DUE_TOMORROW;
  }
  return EStatsTaskStatus.DEFAULT;
};

export const prepareStats = (tasks: StatsTask[]): PreparedStats => {
  const { status, lastMilestone }: StatusAndLastMilestone = calculateStatusAndLastMilestone(
    tasks.filter((task: StatsTask) => task.type === ETaskTypes.MILESTONE),
  );

  const actions: StatsTask[] = tasks.filter((task: StatsTask) => task.type === ETaskTypes.ACTION ?? task.status !== ETaskStatus.COMPLETE);
  const dueToday: StatsTask[] = [];
  const dueTomorrow: StatsTask[] = [];
  const overdue: StatsTask[] = [];
  const notStarted: StatsTask[] = [];

  if (tasks) {
    for (const action of actions) {
      const statsStatus: EStatsTaskStatus = calculateTaskStatsStatus(action);
      switch (statsStatus) {
        case EStatsTaskStatus.DUE_TODAY:
          dueToday.push(action);
          break;
        case EStatsTaskStatus.DUE_TOMORROW:
          dueTomorrow.push(action);
          break;
        case EStatsTaskStatus.OVERDUE:
          overdue.push(action);
          break;
        case EStatsTaskStatus.DEFAULT:
          notStarted.push(action);
          break;
        default:
          break;
      }
    }
  }

  const getTasksStatus = (): EDealStatus => {
    if (
      status === EDealStatus.NOTHING_IN_PROGRESS
      || status === EDealStatus.NOT_STARTED
      || status === EDealStatus.IN_PROGRESS
      || status === EDealStatus.DUE_TODAY
    ) {
      if (dueToday.length > 0) {
        return EDealStatus.DUE_TODAY;
      }
      if (overdue.length > 0) {
        return EDealStatus.OVERDUE;
      }
    }
    return status;
  };

  const tasksStatus: EDealStatus = getTasksStatus();

  return {
    status,
    tasksStatus,
    lastMilestone,
    tasksStats: {
      overdue,
      dueToday,
      dueTomorrow,
      notStarted,
    },
  };
};
