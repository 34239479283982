import { TabPanel, TabPanelProps } from '@mui/lab';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../../../hooks/stateHooks';
import { EFeedTab, selectFeedAgenda, selectFeedTitle } from '../../../../features/DealFeed/lib/feedSlice';
import LexicalContextProvider from '../../../../shared/LexicalRTE/LexicalRTEProvider';
import LexicalRTE from '../../../../shared/LexicalRTE/LexicalRTE';
import AgendaFiles from './AgendaFiles';
import AgendaTimePicker from './AgendaTimePicker';
import AgendaEvent from './AgendaEvent';
import { TaskEventData } from '../../../../../shared/TaskDTO';
import AgendaSubTasks from './AgendaSubTasks';
import useBlockUpdateEvent from '../../../../features/Tiptap/Hooks/useBlockUpdate';
import { useItemFileUpload } from '../../../../features/FilesUpload/FilesUploadProvider/FilesUploadProvider';
import useFrontEventsApi from '../../../../hooks/useFrontEventsApi';
import { AppInputWithLimitCounter } from '../../../../shared/AppInput/AppInputWithLimitCounter';
import { stagesetColors } from '../../../../theme/stagesetPalette';

interface Props extends TabPanelProps {
  value: EFeedTab,
}

const AgendaTab = ({
  value,
}: Props): JSX.Element | null => {
  const agenda = useAppSelector(selectFeedAgenda);
  const { onUpdate } = useBlockUpdateEvent(agenda.blockId!);
  const { onUpload } = useItemFileUpload();
  const feedTitle = useAppSelector(selectFeedTitle);

  const {
    createDescriptionUpdateEvent,
    createTaskEventUpdateEvent,
    createTaskTimelineUpdateEvent,
  } = useFrontEventsApi();

  const onDescriptionSave = (newDescription: string) => {
    if (agenda && agenda.blockId) {
      onUpdate(
        {
          description: newDescription,
        },
      );
      createDescriptionUpdateEvent({ taskId: agenda.blockId });
    }
  };

  const onEndDateUpdate = (newDate?: Date | null) => {
    if (agenda && agenda.blockId) {
      onUpdate(
        {
          timeLineEndDate: newDate,
        },
      );
      createTaskTimelineUpdateEvent({ taskId: agenda.blockId, from: agenda.timeLineEndDate, to: newDate });
    }
  };

  const onEventDataUpdate = (newData: TaskEventData | null) => {
    if (agenda && agenda.blockId) {
      onUpdate(
        {
          eventData: newData,
        },
      );
      createTaskEventUpdateEvent({ taskId: agenda.blockId, ...newData });
    }
  };

  const onSubtasksUpdate = (subTasks: { name: string, status: boolean }[]): void => {
    if (agenda && agenda.blockId) {
      onUpdate(
        {
          subTasks,
        },
      );
    }
  };

  const onDocsUpdate = (newDocs: string[]) => {
    onUpdate({ docs: newDocs });
  };

  const onFileUpload = async (files: File[]): Promise<void> => {
    const reqs = [];
    for (const file of files) {
      reqs.push(onUpload(file, agenda.blockId));
    }
    const newIds = await Promise.all(reqs);
    onDocsUpdate([...agenda.docs, ...newIds]);
  };

  const onFileDelete = (fileId: string) => {
    onDocsUpdate([...agenda.docs].filter((docItem) => docItem !== fileId));
  };

  if (!agenda.blockId) {
    return null;
  }

  const handleSave = (title: string) => {
    onUpdate({ name: title || '' });
  };

  return (
    <TabPanel
      value={value}
      sx={{
        flex: 1,
        backgroundColor: stagesetColors.newGrey[50],
        height: '100%',
        width: '100%',
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '& .AgendaFieldItemContainer': {
          background: stagesetColors.white[100],
          border: '1px solid',
          borderColor: stagesetColors.newGrey[200],
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxShadow: `
            0px 3px 2px rgba(0, 113, 190, 0.04),
            0px 1px 1px rgba(0, 113, 190, 0.07),
            0px 0px 1px rgba(0, 113, 190, 0.08),
            0px 0px 0px rgba(0, 113, 190, 0.08)`,
        },
        '& .AgendaFieldItemTitle': {
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          color: stagesetColors.newGrey[800],
        },
        '& .AgendaFieldItemContainer-transparent': {
          background: 'transparent',
          borderRadius: '10px',
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        },
      }}
    >
      <Box>
        <AppInputWithLimitCounter
          variant="lite"
          title={feedTitle}
          onSave={handleSave}
          size="l-heading-font"
          maxRows={13}
        />
        <LexicalContextProvider>
          <LexicalRTE
            editable
            toolbar
            rteString={agenda?.description ?? ''}
            saveContent={onDescriptionSave}
            agendaId={agenda.id}
            isRowSelected
            agendaTabDescription
          />
        </LexicalContextProvider>
      </Box>
      <Box
        className="AgendaFieldItemContainer-transparent"
      >
        <AgendaTimePicker
          date={agenda.timeLineEndDate}
          onDateChange={onEndDateUpdate}
        />
        <AgendaEvent
          id={agenda.blockId}
          eventData={agenda.eventData}
          onEventDataUpdate={onEventDataUpdate}
        />
      </Box>
      <Box
        className="AgendaFieldItemContainer"
      >
        <Typography
          className="AgendaFieldItemTitle"
          sx={{
            padding: '16px 24px 12px 24px',
          }}
        >
          Attachments
        </Typography>
        <AgendaFiles
          docs={agenda.docs}
          onFileUpload={onFileUpload}
          onFileDelete={onFileDelete}
          onDocsUpdate={onDocsUpdate}
        />
      </Box>
      <Box
        className="AgendaFieldItemContainer"
      >
        <Typography
          className="AgendaFieldItemTitle"
          sx={{
            padding: '16px 24px 12px 24px',
          }}
        >
          Subtasks
        </Typography>
        <AgendaSubTasks
          subTasks={agenda.subTasks}
          onUpdate={onSubtasksUpdate}
        />
      </Box>
    </TabPanel>
  );
};

export default AgendaTab;
