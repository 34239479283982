import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Matercolor from 'matercolors';
import { stagesetPalette } from '../../../../../theme/stagesetPalette';
import { newShade } from '../../../../ButtonField/button-color.helper';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { useAppSelector } from '../../../../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../../../../routes-old/process/state/processSlice';
import useFrontEventsApi from '../../../../../hooks/useFrontEventsApi';

const mainColors: { [key: string]: string } = {
  primary: stagesetPalette.newGrey?.[800],
  secondary: stagesetPalette.newGrey?.[200],
  neutral: '#ffffff',
};

const hoverColors: { [key: string]: string } = {
  primary: stagesetPalette.newGrey?.[600],
  secondary: stagesetPalette.newGrey?.[300],
  neutral: stagesetPalette.newGrey?.[100],
};

const activeColors: { [key: string]: string } = {
  primary: stagesetPalette.newGrey?.[500],
  secondary: stagesetPalette.newGrey?.[500],
  neutral: stagesetPalette.newGrey?.[200],
};

const ButtonNodeComponent = (): JSX.Element | null => {
  const {
    data: {
      widthType, title, color, url, align, blockId,
    },
    onUpdate: onSave,
  } = useBlockContext();
  const isEditable = useAppSelector(selectLayouEditingStatus);
  const [limitCounter, setLimitCounter] = useState<number | undefined>(undefined);
  const [textFieldValue, setTextFieldValue] = useState<string>(title);
  const shadowTitleRef = useRef<HTMLSpanElement>(null);
  const [textAreaWidth, setTextAreaWidth] = useState<number | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>();
  const buttonComponentWrapperRef = useRef<HTMLDivElement>(null);
  const [backgroundColor, setBackgroundColor] = useState(color || 'primary');
  const [backgroundActiveColor, setBackgroundActiveColor] = useState(color || 'primary');
  const [backgroundHoverColor, setBackgroundHoverColor] = useState(color || 'primary');
  const [contrastText, setContrastText] = useState('white');
  const colorNameToHex = (c: 'white' | 'black') => (c === 'white' ? '#ffffff' : stagesetPalette.newGrey?.[800]);
  const isDark = (c: string) => c === 'black';
  const isCustom = (c: string) => !!c?.startsWith('#');

  const { createButtonNodeInteraction } = useFrontEventsApi();

  const calculateMainColor = (c: string) => {
    if (isCustom(c)) {
      return c;
    }
    return mainColors[c];
  };
  const calculateHoverColor = (c: string, dark: boolean) => {
    if (isCustom(c)) {
      return newShade(c, dark ? -40 : 40);
    }
    return hoverColors[c];
  };
  const calculateActiveColor = (c: string, dark: boolean) => {
    if (isCustom(c)) {
      return newShade(c, dark ? -80 : 80);
    }
    return activeColors[c];
  };

  const openUrl = () => {
    if (!isEditable && !!url) {
      createButtonNodeInteraction({ blockId, url, title });
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  const startEditing = () => {
    if (isEditable) {
      // trackEvent(EMixPanelEvents.BUTTON_EDIT_TITLE_CLICKED);
    }
    if (inputRef?.current?.value && inputRef.current.value.length >= 985) {
      setLimitCounter(1000 - inputRef.current.value.length);
    }
    if (!isEditable) {
      openUrl();
    }
  };

  const onSubmit = () => {
    if (inputRef?.current?.value && isEditable) {
      if (title !== inputRef.current.value) {
        onSave({
          title: inputRef.current.value,
        });
      }
    }
    setLimitCounter(undefined);
  };

  useEffect(() => {
    if (title !== textFieldValue) {
      setTextFieldValue(title);
    }
    if (inputRef.current && inputRef.current.value !== title) {
      inputRef.current.value = title;
    }
  }, [title]);

  useEffect(() => {
    if (!inputRef.current) return;

    if (inputRef.current.value.length >= 1000 && inputRef?.current?.value) {
      inputRef.current.value = inputRef.current.value.slice(0, 1000);
      setLimitCounter(0);
    } else if (inputRef.current.value.length >= 985) {
      setLimitCounter(1000 - inputRef.current.value.length);
    } else {
      setLimitCounter(undefined);
    }
  }, [textFieldValue]);

  useEffect(() => {
    if (shadowTitleRef.current) {
      if (textFieldValue) {
        setTextAreaWidth(shadowTitleRef.current.clientWidth);
      } else {
        setTextAreaWidth(100);
      }
    }
  }, [textFieldValue]);

  useEffect(() => {
    const value = color || 'primary';
    const buttonColor = new Matercolor(calculateMainColor(value), { showContrastText: true });
    const colorData: {
      hex: string,
      contrastText: string
    } | undefined = Object.values(buttonColor).find((c) => c.hex === buttonColor.color.toLowerCase());

    setBackgroundColor(calculateMainColor(value) as string);
    setBackgroundHoverColor(calculateHoverColor(value, isDark(colorData?.contrastText || '')));
    setBackgroundActiveColor(calculateActiveColor(value, isDark(colorData?.contrastText || '')));
    setContrastText(colorNameToHex(colorData?.contrastText ?? buttonColor[600].contrastText));
  }, [color]);

  return (
    <Box
      id="button-container"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: align ?? 'center',
        }}
      >
        <Box
          id="button-to-click"
          sx={{
            paddingHeight: '5px',
            paddingRight: '15px',
            cursor: 'pointer',
            maxHeight: '250px',
            minHeight: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 16px',
            alignSelf: 'flex-center',
            flex: 1,
            pointerEvents: isEditable ? 'all' : 'visible',
            boxShadow: color === 'neutral' ? '0px 3px 2px rgba(30, 41, 59, 0.04), 0px 1px 1px rgba(30, 41, 59, 0.07),'
            + ' 0px 0px 1px rgba(30, 41, 59, 0.08), 0px 0px 0px rgba(30, 41, 59, 0.08)' : '',
            backgroundColor,
            '&:hover': {
              backgroundColor: backgroundHoverColor,
            },
            '&:active': {
              backgroundColor: backgroundActiveColor,
            },
            borderRadius: '12px',
            width: (widthType === 'full_width' ? '100%' : 'fit-content'),
          }}
          onClick={() => {
            openUrl();
          }}
        >
          <Typography
            ref={shadowTitleRef}
            sx={{
              paddingLeft: '3px',
              paddingRight: '6px',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
              height: '0px',
              overflow: 'hidden',
            }}
          >
            {`${textFieldValue}`}
          </Typography>
          <TextField
            value={textFieldValue}
            multiline
            disabled={!isEditable}
            placeholder="Button name"
            variant="standard"
            onChange={(e) => setTextFieldValue(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            sx={{
              width: textAreaWidth ?? '100%',
              pointerEvents: isEditable ? 'all' : 'none',
            }}
            InputProps={{
              disableUnderline: true,
              sx: {
                borderRadius: '4px',
                padding: '0px',
                paddingLeft: '2px',
                background: 'transparent',
                backgroundColor: 'transparent !important',
              },
            }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ref: inputRef,
              onKeyDown: (e) => {
                if (e.nativeEvent.key === 'Enter') {
                  inputRef.current?.blur();
                }
              },
              onBlur: onSubmit,
              onFocus: startEditing,
              sx: {
                border: 'none',
                background: 'transparent',
                '&.Mui-disabled': {
                  WebkitTextFillColor: 'currentcolor',
                },
                color: contrastText,
                padding: '0px',
                height: '22px',
                fontSize: '16px',
              },
            }}
          />
          <Box
            ref={buttonComponentWrapperRef}
            sx={{
              display: 'flex',
              width: '100%',
              height: '0px',
              overflow: 'visible',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                height: '16px',
                fontSize: '12px',
                lineHeight: '14px',
                color: '#C94937',
              }}
            >
              {limitCounter}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ButtonNodeComponent;
