/* eslint-disable no-underscore-dangle */
import {
  Avatar, Box, Link, SvgIcon, Typography,
} from '@mui/material';
import { EDealEventName } from '../../../shared/events/Events';
import { useAppSelector } from '../../hooks/stateHooks';
import {
  selectBuyerLogo,
  selectBuyerName,
  selectProcessUserById,
  selectSellerLogo,
  selectSellerName,
  selectUsersDataForOwnershipEvent,
} from '../../routes-old/process/state/processSlice';
import { DocumentIcon } from '../../features/DocumentsField/DocumentIcon';
import {
  EventCancelIcon,
  EventCompleteIcon,
  EventFileAddedIcon,
  EventLinkIcon,
  EventActionIcon,
  EventUserAddedIcon,
  EventVideoIcon,
  EventUserRemovedIcon,
  EventFileDeletedIcon,
  EventUserIcon,
  EventFileIcon,
  EventButtonIcon,
  EventPricingTableIcon,
} from '../../icons/ProcessTimelineEventIcons';
import { ETaskStatus } from '../../../shared/TaskDTO';
import useProcessField from '../../hooks/useProcessField';
import { EFieldType, ProcessStageDTO } from '../../../shared/Field/Field';
import { WideScreenSectionType } from '../../../shared/Stage';
import CompassIcon from '../../icons/AddSectionIcons/Compass.svg';
import MediaIcon from '../../icons/AddSectionIcons/Media24.svg';
import BasciStageIcon from '../../icons/StageMenu/BasciStageIcon.svg';
import { selectFlatItem } from '../../features/ProcessFields/lib/processItemsSlice';
import { mediaSources, sourceHostMap } from '../../features/MediaField/Helpers/MediaFieldHelpers';

interface EventTitleItemProps {
  text: string;
  type: 'reg' | 'highlight' | 'url',
  url?: string;
}

const EventTitleItem = ({ text, type, url }: EventTitleItemProps) => {
  switch (type) {
    case 'reg':
      return (
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[600],
            fontSize: '16px',
            lineHeight: '26px',
            wordBreak: 'break-all',
            minWidth: '75px',
          }}
        >
          {text}
        </Typography>
      );
    case 'highlight':
      return (
        <Typography
          sx={{
            color: '#1E293B',
            fontSize: '16px',
            lineHeight: '26px',
            fontWeight: 500,
            wordBreak: 'break-all',
            // '&:hover': {
            //   color: '#FB982A',
            // },
          }}
        >
          {text}
        </Typography>
      );
    case 'url':
      return (
        <Typography
          sx={{
            color: '#0073F5',
            fontSize: '16px',
            lineHeight: '26px',
            fontWeight: 500,
            wordBreak: 'break-all',
            '&:hover': {
              color: '#FB982A',
            },
          }}
        >
          <a
            href={url ?? text}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            {text}
          </a>
        </Typography>
      );
    default:
      return null;
  }
};

interface EventIconProps {
  icon: JSX.Element | string;
}

const EventIcon = ({ icon }: EventIconProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '36px',
      height: '36px',
      padding: '10px',
      borderRadius: '8px',
      // eslint-disable-next-line max-len
      boxShadow: '0px 0px 0px 0px rgba(0, 113, 190, 0.08), 0px 0px 1px 0px rgba(0, 113, 190, 0.08), 0px 1px 1px 0px rgba(0, 113, 190, 0.07), 0px 3px 2px 0px rgba(0, 113, 190, 0.04)',
    }}
  >
    {icon}
  </Box>
);

interface TaskTitleProps {
  taskId: string;
  entityId: string;
}

const TaskTitle = ({ taskId, entityId }: TaskTitleProps) => {
  if (!taskId) return null;

  const data = useAppSelector((state) => selectFlatItem(state, entityId, taskId));

  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.grey[600],
        fontSize: '16px',
        lineHeight: '26px',
        fontWeight: 500,
      }}
    >
      {data?.name}
    </Typography>
  );
};

const StageTitle = ({ stageId }:{ stageId:string }) => {
  const { data } = useProcessField({ id: stageId, type: EFieldType.STAGE }) as { data: ProcessStageDTO };

  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.grey[600],
        fontSize: '16px',
        lineHeight: '26px',
        fontWeight: 500,
      }}
    >
      {data?.title}
    </Typography>
  );
};

interface DescriptionUserItemProps {
  name: string;
  avatarSrc?: string;
}

const DescriptionUserItem = ({
  name,
  avatarSrc,
}: DescriptionUserItemProps) => (
  <Box
    sx={{
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    }}
  >
    <Avatar
      src={avatarSrc}
      sx={{
        width: '24px',
        height: '24px',
        fontSize: '14px',
      }}
    >
      {name[0]}
    </Avatar>
    <Typography
      sx={{
        color: '#1A1929',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
      }}
    >
      {name}
    </Typography>
  </Box>
);

const TaskOwnershipTeamDisplay = ({
  teamName,
}: {
  teamName: 'seller' | 'buyer' | string,
}) => {
  let avatar = '';
  let name = '';

  switch (teamName) {
    case 'seller':
      avatar = useAppSelector(selectSellerLogo);
      name = useAppSelector(selectSellerName);
      break;
    case 'buyer':
      avatar = useAppSelector(selectBuyerLogo);
      name = useAppSelector(selectBuyerName);
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      <Avatar
        src={avatar}
        sx={{
          width: '24px',
          height: '24px',
          fontSize: '14px',
        }}
      >
        {name[0]}
      </Avatar>
      <Typography
        sx={{
          color: (theme) => theme.palette.grey[600],
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '16px',
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

interface TaskOwnershipDescriptionProps {
  from: string[];
  to: string[];
  fromTeam: string[];
  toTeam: string[];
}

const TaskTitleOwnershipDescription = ({
  from,
  to,
  fromTeam,
  toTeam,
}: TaskOwnershipDescriptionProps) => {
  const fromUsers = useAppSelector((state) => selectUsersDataForOwnershipEvent(state, from));
  const toUsers = useAppSelector((state) => selectUsersDataForOwnershipEvent(state, to));

  const fromList = from?.length ? (
    fromUsers.map((item) => (
      <DescriptionUserItem
        key={`task-ownership-change-item-from-${item.id}`}
        name={item.name}
        avatarSrc={item.avatarSrc}
      />
    ))
  ) : null;

  const toList = to?.length ? (
    toUsers.map((item) => (
      <DescriptionUserItem
        key={`task-ownership-change-item-to-${item.id}`}
        name={item.name}
        avatarSrc={item.avatarSrc}
      />
    ))
  ) : null;

  const fromTeamList = fromTeam?.length ? (
    fromTeam.map((item) => (
      <TaskOwnershipTeamDisplay
        key={`task-ownership-change-item-from-team-${item}`}
        teamName={item}
      />
    ))
  ) : null;

  const toTeamList = toTeam?.length ? (
    toTeam.map((item) => (
      <TaskOwnershipTeamDisplay
        key={`task-ownership-change-item-to-team-${item}`}
        teamName={item}
      />
    ))
  ) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '& .TaskOwnershipChangeList': {
          minHeight: '24px',
          padding: '16px 12px',
          border: '1px solid #EEF0FA',
          borderRadius: '12px',
          background: '#F9FBFF',
          overflow: 'hidden',
          width: '49%',
          display: 'flex',
          gap: '6px',
          flexDirection: 'column',
          '& .TaskOwnershipChangeListName': {
            color: '#1A1929',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            marginBottom: '12px',
          },
        },
      }}
    >
      <Box
        className="TaskOwnershipChangeList"
      >
        <Typography
          className="TaskOwnershipChangeListName"
        >
          From:
        </Typography>
        {fromTeamList}
        {fromList}
      </Box>
      <Box
        className="TaskOwnershipChangeList"
      >
        <Typography
          className="TaskOwnershipChangeListName"
        >
          To:
        </Typography>
        {toTeamList}
        {toList}
      </Box>
    </Box>
  );
};

interface FileEventDescriptionProps {
  name: string;
  url: string;
}

const FileEventDescription = ({
  name,
  url,
}:FileEventDescriptionProps) => (
  <Link
    href={url || ''}
    download
    target="_blank"
    rel="noreferrer"
    sx={{
      textDecoration: 'none',
      cursor: 'pointer',
      marginTop: '8px',
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        '& .docIconContainer': {
          display: 'flex',
          alignItems: 'flex-end',
        },
      }}
    >
      <DocumentIcon ext={name.split('.').pop()} />
    </Box>
    <EventTitleItem
      text={name}
      type="highlight"
    />
  </Link>
);

const LinkEventDescription = ({
  name,
  url,
}:FileEventDescriptionProps) => (
  <Link
    href={url || ''}
    download
    target="_blank"
    rel="noreferrer"
    sx={{
      textDecoration: 'none',
      cursor: 'pointer',
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
    }}
  >
    <EventTitleItem
      text={name}
      type="highlight"
    />
  </Link>
);

const EventTArgetUser = ({ userId }: { userId:string }) => {
  const user = useAppSelector((state) => selectProcessUserById(state, userId));

  if (typeof user === 'string') {
    return (
      <EventTitleItem
        type="reg"
        text={user ?? ''}
      />
    );
  }

  return (
    <EventTitleItem
      type="reg"
      text={user?.name ?? user?.email ?? ''}
    />
  );
};

const getStageIcon = (wideScreenSectionType?: number) => {
  if (typeof wideScreenSectionType === 'undefined') {
    return (
      <SvgIcon
        className="ExpandedStageIcon"
        component={BasciStageIcon}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        sx={{
          fontSize: '16px',
          fill: 'none',
          transition: 'transform 0.2s',
          stroke: 'rgb(69, 82, 115)',
          '& path': {
            stroke: 'inherit',
          },
        }}
      />
    );
  }
  switch (wideScreenSectionType) {
    case WideScreenSectionType.EMBED:
      return (
        <SvgIcon
          component={CompassIcon}
          sx={{
            fontSize: '16px',
            fill: 'none',
            stroke: 'rgb(69, 82, 115)',
            '& path': {
              stroke: 'inherit',
            },
          }}
        />
      );
    case WideScreenSectionType.MEDIA:
      return (
        <SvgIcon
          component={MediaIcon}
          sx={{
            fontSize: '16px',
            fill: 'none',
            stroke: 'rgb(69, 82, 115)',
            '& path': {
              stroke: 'inherit',
            },
          }}
        />
      );
    default:
      return (
        <SvgIcon
          className="ExpandedStageIcon"
          component={BasciStageIcon}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          sx={{
            fontSize: '16px',
            fill: 'none',
            transition: 'transform 0.2s',
            stroke: 'rgb(69, 82, 115)',
            '& path': {
              stroke: 'inherit',
            },
          }}
        />
      );
  }
};

export const generateTimelineSessionItemContent = (event: any) => {
  switch (event.name) {
    case EDealEventName.DEAL_USER_OFFLINE:
      return null;
    case EDealEventName.DEAL_USER_ONLINE:
      return null;
    case EDealEventName.DEAL_FIELD_UPDATED:
      return null;
    case EDealEventName.DEAL_FIELD_CREATED:
      return null;
    case EDealEventName.DEAL_FIELD_DELETED:
      return null;
    default:
      break;
  }

  const parsedEvent: { [key:string]: any } = {};
  const date = new Date(event.createdAt);

  parsedEvent.date = (
    <Typography
      sx={{
        color: '#7084B4',
        fontSize: '12px',
        lineHeight: '16px',
      }}
    >

      {`${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`}
    </Typography>
  );

  switch (event.name) {
    case EDealEventName.DEAL_USER_ADDED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventUserAddedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Send invite to"
          />
        ),
        (
          <EventTArgetUser
            key={`event-${event._id}-title-item-1`}
            userId={event.payload.data.addedUserId ?? ''}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_USER_DELETED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventUserRemovedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Removed"
          />
        ),
        (
          <EventTArgetUser
            key={`event-${event._id}-title-item-1`}
            userId={event.payload.data.addedUserId ?? ''}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_DOCUMENT_ADDED:
    case EDealEventName.ITEM_FILE_UPLOADED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileAddedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Uploaded the file"
          />
        ),
      ];
      parsedEvent.description = (
        <FileEventDescription
          name={event?.payload?.data?.docName ?? event?.payload?.data?.fileData.name}
          url={event?.payload?.data?.docUrl ?? event?.payload?.data?.fileData.url}
        />
      );
      break;
    case EDealEventName.DEAL_TASK_TITLE_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Updated title of"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_CREATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Created new action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_DELETED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Deleted action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
      break;
    case EDealEventName.DEAL_TASK_ASSIGNEES_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventUserIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Changed ownership of"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      parsedEvent.description = (
        <TaskTitleOwnershipDescription
          from={event?.payload?.data?.from}
          to={event?.payload?.data?.to}
          fromTeam={event?.payload?.data?.fromTeams}
          toTeam={event?.payload?.data?.toTeams}
        />
      );
      break;
    case EDealEventName.DEAL_TASK_DESCRIPTION_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Updated action description for"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_SUBTASK_ADDED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Added subaction"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-2`}
            type="reg"
            text="to action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-3`}
            taskId={event.payload?.data?.parentId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_SUBTASK_DELETED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Deleted subaction"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-2`}
            type="reg"
            text="of action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-3`}
            taskId={event.payload?.data?.parentId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_DOCUMENT_ADDED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileAddedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Added a file"
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-2`}
            type="reg"
            text="to action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-3`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      parsedEvent.description = (
        <FileEventDescription
          name={event?.payload?.data?.docName ?? ''}
          url={event?.payload?.data?.docUrl}
        />
      );
      break;
    case EDealEventName.DEAL_TASK_DOCUMENT_DELETED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileDeletedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Deleted a file"
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text={event.payload.data.docName}
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-2`}
            type="reg"
            text="from action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-3`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_DOCUMENT_DOWNLOADED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileDeletedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Downloaded file"
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-2`}
            type="reg"
            text="from action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-3`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      parsedEvent.description = (
        <FileEventDescription
          name={event?.payload?.data?.docName ?? ''}
          url={event?.payload?.data?.docUrl}
        />
      );
      break;
    case EDealEventName.DEAL_TASK_EVENT_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          event?.payload?.data?.url ? (
            <EventTitleItem
              key={`event-${event._id}-title-item-0`}
              type="reg"
              text="Added link to action"
            />
          ) : (
            <EventTitleItem
              key={`event-${event._id}-title-item-0`}
              type="reg"
              text="Removed link from action"
            />
          )
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_TIMELINE_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventActionIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Set a date for action"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_STATUS_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={event?.payload?.data?.to === ETaskStatus.COMPLETE ? <EventCompleteIcon /> : <EventCancelIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Changed status of"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-1`}
            taskId={event.payload?.data?.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_FILE_DOWNLOADED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Downloaded file"
          />
        ),
      ];
      parsedEvent.description = (
        <FileEventDescription
          name={event?.payload?.data?.docName ?? ''}
          url={event?.payload?.data?.docUrl}
        />
      );
      break;
    case EDealEventName.DEAL_FILE_DELETED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileDeletedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Deleted file"
          />
        ),
      ];
      parsedEvent.description = (
        <FileEventDescription
          name={event?.payload?.data?.docName ?? ''}
          url={event?.payload?.data?.docUrl}
        />
      );
      break;
    case EDealEventName.DEAL_VIDEO_WATCHED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventVideoIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Watched a video"
          />
        ),
      ];
      parsedEvent.description = (
        <EventTitleItem
          type="url"
          text={event.payload?.data?.linkUrl}
        />
      );
      break;
    case EDealEventName.DEAL_DOCUMENT_INTERACTION:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventFileIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Interacted with document"
          />
        ),
      ];
      if (event.payload?.data?.name) {
        parsedEvent.text.push(
          (
            <EventTitleItem
              key={`event-${event._id}-title-item-0`}
              type="highlight"
              text={event.payload?.data?.name}
            />
          ),
        );
      } else {
        parsedEvent.description = (
          <EventTitleItem
            type="reg"
            text={event.payload?.data?.linkUrl}
          />
        );
      }
      break;
    case EDealEventName.DEAL_LINK_PREVIEW_INTERACTION:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Interacted with link preview"
          />
        ),
      ];
      parsedEvent.description = (
        <EventTitleItem
          type="reg"
          text={event.payload?.data?.linkUrl}
        />
      );
      break;
    case EDealEventName.DEAL_RICH_MEDIA_INTERACTION:

      let host;
      try {
        host = sourceHostMap[new URL(event.payload.data.linkUrl).host]
          || sourceHostMap[new URL(event.payload.data.linkUrl).host.replace('www.', '')];
      } catch (e) {
        console.log('Failed to create URL from event link');
      }
      parsedEvent.icon = (
        <EventIcon
          icon={mediaSources.find((item) => item.contentTag === host)?.icon ?? <EventLinkIcon />}
        />
      );
      if (host) {
        parsedEvent.text = [
          (
            <EventTitleItem
              key={`event-${event._id}-title-item-0`}
              type="reg"
              text="Interacted with"
            />
          ),
          (
            <EventTitleItem
              key={`event-${event._id}-title-item-0`}
              type="url"
              text={host.toLowerCase()}
              url={event.payload?.data?.linkUrl}
            />
          ),
        ];
      } else {
        parsedEvent.text = [
          (
            <EventTitleItem
              key={`event-${event._id}-title-item-0`}
              type="reg"
              text="Interacted with rich media"
            />
          ),
        ];
      }
      // parsedEvent.description = (
      //   <EventTitleItem
      //     type="reg"
      //     text={event.payload?.data?.linkUrl}
      //   />
      // );
      break;
    case EDealEventName.DEAL_WEB_EMBED_INTERACTION:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Interacted with embeded site"
          />
        ),
      ];
      parsedEvent.description = (
        <EventTitleItem
          type="reg"
          text={event.payload?.data?.linkUrl}
        />
      );
      break;
    case EDealEventName.DEAL_LINK_CLICKED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Clicked on the link"
          />
        ),
        (
          <LinkEventDescription
            key={`event-${event._id}-title-item-1`}
            name={event?.payload?.data.linkName}
            url={event?.payload?.data.linkUrl}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_LINK_CLICKED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Clicked on the link"
          />
        ),
        (
          <LinkEventDescription
            key={`event-${event._id}-title-item-1`}
            name={event?.payload?.data.linkName}
            url={event?.payload?.data.linkUrl}
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="in"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-0`}
            taskId={event?.payload?.data.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_TASK_EVENT_LINK_CLICKED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Clicked on the link"
          />
        ),
        (
          <LinkEventDescription
            key={`event-${event._id}-title-item-1`}
            name={event?.payload?.data.linkName}
            url={event?.payload?.data.linkUrl}
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-2`}
            type="reg"
            text="in"
          />
        ),
        (
          <TaskTitle
            key={`event-${event._id}-title-item-3`}
            taskId={event?.payload?.data.taskId}
            entityId={event.payload.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_ACCESS_LINK_UPDATED:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventLinkIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Updated access link"
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_INVITE_LINK_BTN_INTERACTION:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventUserAddedIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Clicked on Invite"
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_STAGE_SELECTED:
      parsedEvent.icon = (
        <EventIcon
          icon={getStageIcon(event.payload.data.wideScreenSectionType)}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Opened page"
          />
        ),
        (
          <StageTitle
            key={`event-${event._id}-title-item-1`}
            stageId={event.payload.data.stageId}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_BUTTON_INTERACTION:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventButtonIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text="Clicked"
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-1`}
            type="highlight"
            text={event.payload.data.title}
          />
        ),
      ];
      break;
    case EDealEventName.DEAL_PRICING_TABLE_UNITS_CHANGE:
      parsedEvent.icon = (
        <EventIcon
          icon={<EventPricingTableIcon />}
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text={`Changed units from ${event.payload.data.from} to ${event.payload.data.to} for`}
          />
        ),
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-1`}
            type="highlight"
            text={event.payload.data.name}
          />
        ),
      ];
      break;
    default:
      parsedEvent.icon = (
        <EventIcon
          icon=" "
        />
      );
      parsedEvent.text = [
        (
          <EventTitleItem
            key={`event-${event._id}-title-item-0`}
            type="reg"
            text={event.name}
          />
        ),
      ];
      break;
  }
  return parsedEvent;
};
