import { DialogProps } from '@mui/material';
import { useAppDispatch } from '../../../hooks/stateHooks';
import Signup from '../../auth/Signup/Signup';
import { setShareSingUpOpen } from '../../auth/state/authState';
import DealSignupDialogWrapper from './DealSignupDialogWrapper';

const ShareSignUpDialog = (props: DialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <DealSignupDialogWrapper
      {...props}
      onClose={() => dispatch(setShareSingUpOpen(false))}
    >
      <Signup />
    </DealSignupDialogWrapper>
  );
};

export default ShareSignUpDialog;
