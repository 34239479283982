import {
  Avatar, Box, MenuItem, SvgIcon, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';
import { formateValueForLastActive } from '../../../routes-old/process/helpers';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { CreateDealFormWrapper } from '../TemplatePickerStyles';
import AvatarPlaceholder from '../../../icons/AvatarPlaceholder.svg';
import { AppInput } from '../../../shared/AppInput/AppInput';
import CompanyAndLogoSelect, { disabledLogoCompany, initialCompany } from '../../CreateDealDialog/CompanyAndLogoSelect';
import { EProcessType, ProcessDTO } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { Opportunity } from '../../../../shared/salesforce/salesforce-sobjects';
import { ClearbitAutocompleteDTO } from '../../../../shared/ClearbitDTO';
import { ICreateDealFormState } from '../../../routes-old/deals/CreateDealDialog/CreateDealDialog';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { EStateStatus } from '../../../core/commonTypes';
import { createProcess } from '../../../routes-old/deals/state/dealsSlice';
import { ESubscriptionType } from '../../../../shared/Subscription';
import { upgradeToSellerSubscription } from '../../../routes-old/Billing/state/billingSlice';
import { EAppRoutes } from '../../../core/router';
import { useCrmOpportunities } from '../../../routes-old/deals/model/useCrmOpportunities';
import useDealCreationData from '../lib/useDealCreationDataSlice';
import { DefaultOrgCurrency } from '../../../../shared/OrganizationDTO';
import { selectOrganization } from '../../../core/store/appState/appState';
import { selectAllTemplates } from '../../../pages/templates/lib/templatesSlice';

export type Props = {
  templateId: string;
  onGoBack: () => void;
  onClose: () => void;
};

export const CreateDealFormTab = ({ templateId, onGoBack, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectOrganization);
  const { subscription } = useAppSelector((state) => state.billing);
  const templates = useAppSelector(selectAllTemplates);
  const chosenTemplate = useMemo(() => templates.find((template) => template.id === templateId), [templates, templateId]);
  const { isUserHaveIntegration } = useAppSelector((state) => state.salesForce);
  const opportunities: Opportunity[] = useCrmOpportunities();

  const { status } = useAppSelector((state) => state.deals);
  const [selectedCompany, setSelectedCompany] = useState<ClearbitAutocompleteDTO>(disabledLogoCompany);
  const navigate = useNavigate();
  const { onDealToCreateDataUpdate, onResetDealCreationData } = useDealCreationData();

  const isLoading: boolean = status === EStateStatus.LOADING;
  const [dealValue, setDealValue] = useState<number | null>(null);

  const schema = yup.object({
    dealTitle: yup.string().required('Opportunity title is required'),
    client: yup.string().required('Client is required'),
    value: yup
      .number()
      .typeError('Value should be a number')
      .nullable()
      .min(0)
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    templateId: yup.string().required('Template is required'),
  }).required();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    register,
    getValues,
  } = useForm<ICreateDealFormState>({
    defaultValues: {
      dealTitle: '',
      client: '',
      value: null,
      templateId: templateId || '',
      opportunityId: '',
    },
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (data: ICreateDealFormState) => {
    try {
      const newDeal: ProcessDTO = await dispatch(createProcess({
        title: data.dealTitle,
        ...data,
        value: data.value ?? 0,
        logo: selectedCompany?.logo,
        type: EProcessType.DEAL,
        salesforceOpportunityId: data.opportunityId,
      })).unwrap();
      if (subscription.type === ESubscriptionType.BUYER) {
        await dispatch(upgradeToSellerSubscription())
          .unwrap();
      }
      setSelectedCompany(initialCompany);
      reset();
      onResetDealCreationData();
      onClose();
      navigate(`${EAppRoutes.deal}/${newDeal.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  register('value', {
    onChange: (e) => {
      setDealValue(parseInt(e.target.value));
    },
  });

  const [isAutocompleteVisible, setAutocompleteVisible] = useState(false);
  register('client', {
    onChange: (e) => {
      setSelectedCompany(initialCompany);
      if (e.target.value && !isAutocompleteVisible) {
        setAutocompleteVisible(true);
      }
      if (!e.target.value && isAutocompleteVisible) {
        setAutocompleteVisible(false);
      }
      onDealToCreateDataUpdate({ clientName: e.target.value });
    },
  });

  useEffect(() => {
    setValue('templateId', templateId || '');
  }, [templateId]);

  const handleCompanySelect = (data: ClearbitAutocompleteDTO) => {
    if (data.name) {
      setValue('client', data.name);
      setValue('dealTitle', `${data.name} deal`);
    }
    onDealToCreateDataUpdate({
      clientName: data.name,
      clientLogo: data.logo,
    });
    setSelectedCompany(data);
    setAutocompleteVisible(false);
  };

  return (
    <CreateDealFormWrapper
      className="mobile"
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '20px',
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
          >

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >

              <Box>
                <AppIconButton icon={<ChevronLeft />} onClick={onGoBack} />
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  {chosenTemplate?.title}
                </Typography>
                <Typography sx={{
                  fontSize: '12px',
                  color: '#7084B4',
                }}
                >
                  {
                    `Updated ${formateValueForLastActive(DateTime.fromISO(chosenTemplate?.lastInteraction).toMillis() as number)}`
                  }
                </Typography>
              </Box>
            </Box>

            <Box sx={{
              display: 'flex',
            }}
            >
              <AppLinkButton
                onClick={onClose}
                sx={{
                  paddingRight: '0px !important',
                  minWidth: '100% !important',
                  width: '100% !important',
                }}
              >
                Cancel
              </AppLinkButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Controller
              name="client"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}
                >

                  <Box sx={{
                    width: '100%',
                    height: '40px',
                  }}
                  >
                    <AppInput
                      required
                      label="Company"
                      id="client-name-field"
                      placeholder="Type here"
                      onFocus={() => setAutocompleteVisible(true)}
                      onBlur={(e) => {
                        if (e.relatedTarget?.id !== 'company-information-autocomplete') {
                          setAutocompleteVisible(false);
                        }
                        if (!getValues().dealTitle && !!e.target.value.trim()) {
                          setValue('dealTitle', `${e.target.value} deal`);
                        }
                      }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                    <CompanyAndLogoSelect
                      isAutocompleteVisible={isAutocompleteVisible}
                      value={value}
                      setSelectedCompany={handleCompanySelect}
                      positionOffset="0px"
                    />
                  </Box>
                  <Avatar
                    src={selectedCompany.logo}
                    alt=""
                    sx={{
                      height: '40px',
                      width: '40px',
                      backgroundColor: 'white',
                      margin: '0px 0px 0px 12px',
                    }}
                  >
                    <Box sx={{
                      border: '1px solid #EEF0FA',
                      borderRadius: '40px',
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <SvgIcon
                        component={AvatarPlaceholder}
                        sx={{
                          color: 'transparent',
                        }}
                      />
                    </Box>
                  </Avatar>
                </Box>
              )}
            />
          </Box>
          <Controller
            name="dealTitle"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                required
                label="Room name"
                id="deal-title-field"
                placeholder="Type here"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                onFocus={(e) => {
                  if (!!e.target.value && e.target.value === `${getValues().client} deal`) {
                    e.target.select();
                  }
                }}
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <AppInput
                label={`Value, ${getSymbolFromCurrency(organization.currency ?? DefaultOrgCurrency)}`}
                type="number"
                id="deal-value-field"
                value={dealValue}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                inputProps={{
                  value: dealValue?.toString() ?? '',
                  // inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            )}
          />
          {
            isUserHaveIntegration && (
              <Controller
                name="opportunityId"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <AppInput
                    select
                    id="deal-salesforce-opportunity-field"
                    value={value}
                    label="Salesforce Opportunity"
                    onChange={onChange}
                  >
                    <MenuItem key="none" value="">None</MenuItem>
                    {opportunities?.map((item: Opportunity) => (
                      <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                    ))}
                  </AppInput>
                )}
              />
            )
          }
        </Box>
      </Box>

      <AppButton
        loading={isLoading}
        variant="primary"
        size="l"
        type="submit"
      >
        Create
      </AppButton>
    </CreateDealFormWrapper>
  );
};
