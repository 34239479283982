/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DnDIcon16 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px !important',
    }}
    {...props}
  >
    <path
      d="M5.67008 8.66341C6.03827 8.66341 6.33675 8.36493 6.33675 7.99674C6.33675 7.62855 6.03827 7.33008 5.67008 7.33008C5.30189 7.33008 5.00342 7.62855 5.00342 7.99674C5.00342 8.36493 5.30189 8.66341 5.67008 8.66341Z"
      stroke="inherit"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.33 8.66341C10.6982 8.66341 10.9967 8.36493 10.9967 7.99674C10.9967 7.62855 10.6982 7.33008 10.33 7.33008C9.96181 7.33008 9.66333 7.62855 9.66333 7.99674C9.66333 8.36493 9.96181 8.66341 10.33 8.66341Z"
      stroke="inherit"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.67008 3.99666C6.03827 3.99666 6.33675 3.69819 6.33675 3.33C6.33675 2.96181 6.03827 2.66333 5.67008 2.66333C5.30189 2.66333 5.00342 2.96181 5.00342 3.33C5.00342 3.69819 5.30189 3.99666 5.67008 3.99666Z"
      stroke="inherit"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.33 3.99666C10.6982 3.99666 10.9967 3.69819 10.9967 3.33C10.9967 2.96181 10.6982 2.66333 10.33 2.66333C9.96181 2.66333 9.66333 2.96181 9.66333 3.33C9.66333 3.69819 9.96181 3.99666 10.33 3.99666Z"
      stroke="inherit"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.67008 13.3299C6.03827 13.3299 6.33675 13.0314 6.33675 12.6632C6.33675 12.2951 6.03827 11.9966 5.67008 11.9966C5.30189 11.9966 5.00342 12.2951 5.00342 12.6632C5.00342 13.0314 5.30189 13.3299 5.67008 13.3299Z"
      stroke="inherit"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.33 13.3299C10.6982 13.3299 10.9967 13.0314 10.9967 12.6632C10.9967 12.2951 10.6982 11.9966 10.33 11.9966C9.96181 11.9966 9.66333 12.2951 9.66333 12.6632C9.66333 13.0314 9.96181 13.3299 10.33 13.3299Z"
      stroke="inherit"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
