import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { GridRowsProp } from '@mui/x-data-grid';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { DealsGrid } from '../../../deals/DealPageStyles/DealsListStyle';
import { CrossDimensionDataGrid } from '../../../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import {
  allApiTokensSelector, deleteApiToken, getApiTokens, selectFirstFetchStatus,
} from './lib/publicApiTokenSlice';
import { EStateStatus } from '../../../../core/commonTypes';
import { ExtendedGridColumns } from '../../../../types/mobile-table';
import { EApiTokenDataGridColumns } from './helpers/types';
import { GridItemSkeleton, GridTitleItemSkeleton, skeletonRows } from '../../../deals/DealsHelpers/DealsHelpers';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { Add20px } from '../../../../icons/Add';
import GenerateApiTokeDialog from './GenerateApiTokeDialog';
import { CopyLinkIconSq } from '../../../../icons/CopyLinkIcon';
import Trash from '../../../../icons/Trash.svg';
import ConfirmationDialog from '../../../../common/ConfirmationDialog/ConfirmationDialog';
import { DeleTeIcon } from '../../../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import DeleteTypography from '../../../../common/DeleteTypography';
import { ApiTokenDTO } from '../../../../../shared/api-token/ApiTokenDTO';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { selectOrganization } from '../../../../core/store/appState/appState';

const SettingsPublicApiToken = (): JSX.Element | null => {
  const organization = useAppSelector(selectOrganization);

  if (!organization.isPublicApiEnabled) {
    return null;
  }

  const dispatch = useAppDispatch();
  const apiTokens = useAppSelector(allApiTokensSelector);
  const { status } = useAppSelector((state) => state.apiTokens);
  const firstFetchStatus = useAppSelector(selectFirstFetchStatus);
  const [searchFilter, setSearchFilter] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  let copyLinkSnackbarId: SnackbarKey | undefined;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState<ApiTokenDTO | undefined>(undefined);

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getApiTokens());
    }
    setIsDialogOpen(false);
  }, []);

  const updateSearchFilter = (filter: string) => {
    setSearchFilter(filter);
  };

  const handleCreateButtonClick = () => {
    setIsDialogOpen(true);
  };

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    if (copyLinkSnackbarId) {
      closeSnackbar(copyLinkSnackbarId);
      copyLinkSnackbarId = undefined;
    }
    copyLinkSnackbarId = enqueueSnackbar(
      'Api Token copied',
      {
        variant: 'success',
      },
    );
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to delete token
      <b>
        {' '}
        {selectedToken?.name}
      </b>
      ?
    </DeleteTypography>
  );

  const onItemDelete = (): void => {
    if (selectedToken) {
      dispatch(deleteApiToken(selectedToken.id));
    }
    setDeleteDialogOpen(false);
  };

  const handleDeleteClick = (token: ApiTokenDTO) => {
    setSelectedToken(token);
    setDeleteDialogOpen(true);
  };

  const columns: ExtendedGridColumns = [
    {
      field: EApiTokenDataGridColumns.name,
      sortable: true,
      headerName: 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 3,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton' ? (
          <Box>
            {value}
          </Box>
        ) : (
          <GridTitleItemSkeleton />
        )
      ),
    },
    {
      sortable: true,
      headerName: 'Email',
      field: EApiTokenDataGridColumns.user_email,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton' ? (
          <Box>
            {value}
          </Box>
        ) : (
          <GridItemSkeleton />
        )
      ),
    },
    {
      field: EApiTokenDataGridColumns.issued_date,
      sortable: true,
      headerName: 'Issued At',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 0.8,
      renderCell: ({
        value,
      }) => (value !== 'skeleton' ? (
        <Box>
          {value}
        </Box>
      ) : (
        <GridItemSkeleton />
      )
      ),
      renderMobileCell: ({ value }) => (
        <Box>
          {value}
        </Box>
      ),
    },
    {
      field: EApiTokenDataGridColumns.menu,
      cellClassName: 'pro-grid-cell',
      hideSortIcons: true,
      headerName: '',
      align: 'right',
      renderCell: (
        {
          value,
          row,
        },
      ) => (
        value !== 'skeleton'
          ? (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            >
              <AppIconButton
                id="action-copy-menu-button"
                onClick={() => copyToClipboard(row.token)}
                onMouseDown={(e) => e.stopPropagation()}
                icon={<CopyLinkIconSq />}
              />
              <AppIconButton
                id="action-delete-menu-button"
                onClick={() => handleDeleteClick(row)}
                onMouseDown={(e) => e.stopPropagation()}
                icon={<Trash />}
              />
            </Box>
          ) : (
            <></>)

      ),
    },
  ];

  let rows: GridRowsProp = [];
  if (apiTokens.length) {
    rows = apiTokens
      .filter((token) => (
        !searchFilter
        || (!!token.email && token.email.toUpperCase().indexOf(searchFilter.toUpperCase()) >= 0))
        || (!!token.name && token.name.toUpperCase().indexOf(searchFilter.toUpperCase()) >= 0))
      .map((token) => ({
        ...token,
        [EApiTokenDataGridColumns.name]: token.name,
        [EApiTokenDataGridColumns.user_email]: token.email,
        [EApiTokenDataGridColumns.issued_date]: token.issuedAt,
        [EApiTokenDataGridColumns.menu]: {},
      }));
  } else if (apiTokens && !apiTokens.length) {
    rows = [];
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box
      sx={{
        paddingLeft: '50px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          marginTop: isMobile ? '20px' : '24px',
          marginBottom: isMobile ? '20px' : '40px',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingX: isMobile ? '5px' : '24px',
          gap: '24px',
        }}
      >
        <AppInput
          fullWidth
          label="Search"
          defaultValue={searchFilter}
          value={searchFilter}
          rows={4}
          onChange={(event: any) => {
            updateSearchFilter(event.target.value);
          }}
        />
        <AppButton
          onClick={handleCreateButtonClick}
          size="l"
          variant="primary"
          startIcon={<Add20px />}
        >
          Api Token
        </AppButton>
      </Box>
      <Box
        id="my-deals-tab-container"
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          padding: '20px',
          width: '100%',
        }}
      >
        <Box sx={{
          display: 'inline-flex',
          gap: '4px',
          marginBottom: '12px',
          marginLeft: '12px',
        }}
        >
          {
          firstFetchStatus ? (
            <>
              <Typography sx={{
                fontSize: '16px',
                color: (theme) => theme.palette.grey[600],
              }}
              >
                {rows.length}
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                color: (theme) => theme.palette.grey[400],
                paddingTop: '2px',
              }}
              >
                Api Tokens
              </Typography>
            </>
          ) : (
            <GridItemSkeleton />
          )
        }
        </Box>
        <CrossDimensionDataGrid
          rows={rows}
          columns={columns}
          getId={(row) => row.id}
          renderMobileTitle={(row) => (
            <Box>
              {row.name}
            </Box>
          )}
          renderMobileMenu={(row) => (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
              <AppIconButton
                id="action-copy-menu-button"
                onClick={() => copyToClipboard(row.token)}
                onMouseDown={(e) => e.stopPropagation()}
                icon={<CopyLinkIconSq />}
              />
              <AppIconButton
                id="action-delete-menu-button"
                onClick={() => handleDeleteClick(row)}
                onMouseDown={(e) => e.stopPropagation()}
                icon={<Trash />}
              />
            </Box>
          )}
          renderDesktopGridComponent={() => (
            <DealsGrid
            // loading={processesLoadingJobs > 0}
              disableSelectionOnClick
              disableColumnMenu
              columns={columns}
              rows={firstFetchStatus ? rows : skeletonRows}
              headerHeight={36}
              rowHeight={65}
              getRowClassName={(params) => (params.row.status === 1 ? 'highlighted_row' : '')}
              hideFooter
            />
          )}
        />
      </Box>
      <GenerateApiTokeDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onCopy={copyToClipboard}
      />
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={isDeleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={onItemDelete}
        dialogContentText={deleteTypography}
        dialogTitle="Delete token"
      />
    </Box>
  );
};

export default SettingsPublicApiToken;
