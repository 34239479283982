import {
  Box, List, Typography, SvgIcon, Divider,
} from '@mui/material';
import { AppLinkButton } from '../../AppButton/AppLinkButton';
import { OptionItem } from './OptionItem';
import {
  RTEFormatLeftIcon, RTELinkIcon, RTEOLIcon, RTEULIcon,
} from '../../../icons/LexicalToolbar/LexicalToolbarIcons';
import Trash from '../../../icons/Trash.svg';
import DualArrow from '../../../icons/Dual arrow 20px.svg';
import LexicalRTEBtnBase from '../LexicalRTEToolbarBtns/LexicalRTEBtnBase';
import { AppSwitch } from '../../AppSwitch/AppSwitch';
import { useAppSelector } from '../../../hooks/stateHooks';
import { isMoveRowMode } from '../../../features/BlockContentSection/lib/sectionsSlice';

export type Props = {
  onClose: () => void;
  onAlignment: () => void;
  onMoveMode: () => void;
  onHyperlink: () => void;
  onBulletList: () => void;
  onNumberedList: () => void;
  onDelete: () => void;
  showControls?: boolean;
};

export const CommonOptionsTab = ({
  onClose, onAlignment, onMoveMode, onHyperlink, onBulletList, onNumberedList, onDelete, showControls,
}: Props) => {
  const moveMode = useAppSelector(isMoveRowMode);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: '16px 16px 8px 16px',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginBottom: '20px',
      }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <Typography
            sx={{
              fontFamily: 'Manrope',
              fontSize: '14px',
              fontWeight: 600,
              color: (theme) => theme.palette.grey[600],
              maxWidth: '100px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            Options
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
        }}
        >
          <AppLinkButton
            onClick={onClose}
            sx={{
              paddingRight: '0px !important',
              minWidth: '100% !important',
              width: '100% !important',
            }}
          >
            Cancel
          </AppLinkButton>
        </Box>
      </Box>
      <Box sx={{
        height: '100%',
        overflow: 'overlay',
      }}
      >
        <List sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <OptionItem
            title="Bullet list"
            onClick={onBulletList}
            startIcon={<LexicalRTEBtnBase content={<RTEULIcon />} />}
          />
          <OptionItem
            title="Numbered list"
            onClick={onNumberedList}
            startIcon={<LexicalRTEBtnBase content={<RTEOLIcon />} />}
          />
          <OptionItem
            title="Align"
            onClick={onAlignment}
            startIcon={<LexicalRTEBtnBase content={<RTEFormatLeftIcon />} />}
          />
          <OptionItem
            title="Hyperlink"
            onClick={onHyperlink}
            startIcon={<LexicalRTEBtnBase content={<RTELinkIcon />} />}
          />
          { showControls && (
          <>
            <Divider />
            <OptionItem
              title="Move mode"
              onClick={onMoveMode}
              startIcon={(
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '32px',
                  aspectRatio: '1/1',
                }}
                >
                  <SvgIcon
                    component={DualArrow}
                    sx={{
                      color: 'transparent',
                      '& g': {
                        stroke: (theme) => theme.palette.primary[600],
                      },
                    }}
                  />
                </Box>
              )}
              endIcon={<AppSwitch checked={moveMode} />}
            />
            <Divider />
            <OptionItem
              title="Delete"
              onClick={() => {
                onDelete();
                onClose();
              }}
              labelColor={(theme) => theme.palette.error[600]}
              startIcon={(
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '32px',
                  aspectRatio: '1/1',
                }}
                >
                  <Trash />
                </Box>
              )}
            />
          </>
          )}

        </List>
      </Box>
    </Box>
  );
};
