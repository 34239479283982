import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const LexicalRTEWrapperBox = styled(Box)(() => ({
  maxWidth: '908px',
  padding: '8px',
  '&.agenda-tab-description': {
    '&:hover': {
      background: stagesetColors.white[100],
      borderRadius: '8px',
      boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
              + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
              + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
              + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
    },
    '& span': {
      color: stagesetColors.newGrey[500],
    },
  },
  '&:not(.agenda-tab-description)': {
    '& span': {
      color: (theme) => theme.palette.grey[600],
    },
    '& p > span': {
      color: '#2C3241',
    },
  },
  '& .editor-text-bold': {
    fontWeight: 'bold',
  },
  '& .editor-text-italic': {
    fontStyle: 'italic',
  },
  '& .editor-text-underline': {
    textDecoration: 'underline',
  },
  '& .editor-link': {
    cursor: 'pointer',
    color: (theme) => theme.palette.primary.main,
    '&:hover': {
      color: (theme) => theme.palette.warning[600],
    },
  },
  '& .editor-paragraph': {
    margin: '0px 0px',
  },

  '& .editor-heading-h1': {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 600,
    margin: '4px 0px',
  },
  '& .editor-heading-h2': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    margin: '0px 0px',
  },
  '& .mentions-menu': {
    width: '200px',
    background: 'white',
    '& ul': {
      listStyleType: 'none',
      padding: 0,
    },
  },
}));
