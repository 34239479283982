import currencyToSymbolMap from 'currency-symbol-map/map';
import { InputAdornment } from '@mui/material';
import { MenuItemProps } from 'antd';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { InputText } from '../../features/LanguageSwitcher/LanguageSwitcherInputStyles';
import { ChevronDown20 } from '../../icons/ChevronDown';
import {
  CurrencyAutocomplete, CurrencyItem, CurrencyLogo,
  CurrencyPaper,
} from '../../routes-old/account/SettingsTabs/Styles/CurrencySelectorFullNameStyles';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppInput, AppInputProps, AppInputSize } from '../AppInput/AppInput';

type CurrencyProps = {
  label: string,
  startIcon: string,
  currencyCode: string,
};

interface Props {
  initialValue?: string;
  onSelect?: (val: string) => void;
  disabled?: boolean;
  error?: FieldError;
  disableLabel?: boolean;
  height?: string;
  size?: AppInputSize;
}

const CurrencySelector = ({
  initialValue,
  disabled,
  onSelect,
  error,
  disableLabel,
  height,
  size,
}: Props) => {
  const currencyName = new Intl.DisplayNames(['en'], { type: 'currency' });
  const [inputCurrency, setInputCurrency] = useState('');

  const currencySelection = useMemo(() => Object.entries(currencyToSymbolMap).map((item) => ({
    label: currencyName.of(item[0]),
    startIcon: item[1],
    currencyCode: item[0],
  })), []);

  const [currencyObject, setCurrencyObject] = useState<CurrencyProps | null | undefined>(
    initialValue ? currencySelection?.find((x) => x.currencyCode === initialValue) : undefined,
  );

  const CustomPaper = useCallback(
    (props) => (
      <CurrencyPaper
        {...props}
      />
    ),
    [],
  );

  return (
    <CurrencyAutocomplete
      disabled={disabled}
      options={currencySelection}
      value={currencyObject}
      onChange={(event: any, newValue: CurrencyProps | null) => {
        if (newValue?.currencyCode) {
          setCurrencyObject(newValue);
          if (onSelect) {
            onSelect(newValue?.currencyCode);
          }
        }
      }}
      disableClearable
      popupIcon={<ChevronDown20 />}
      inputValue={inputCurrency}
      onInputChange={(event: any, newInputValue: string) => {
        setInputCurrency(newInputValue);
      }}
      PaperComponent={CustomPaper}
      renderOption={(props: MenuItemProps, option: CurrencyProps) => (
        <CurrencyItem
          className={classNames('organization-tab-currency-item')}
          key={option.label}
          value={option.currencyCode}
          {...props}
        >
          <CurrencyLogo>
            {option.startIcon}
          </CurrencyLogo>
          <InputText>
            {option.label}
          </InputText>
        </CurrencyItem>
      )}
      renderInput={(params: AppInputProps) => (
        <AppInput
          {...params}
          size={size ?? 's'}
          helperText={error ? error.message : null}
          InputProps={{
            ...params.InputProps,
            sx: {
              height,
            },
            startAdornment: (
              <InputAdornment position="start">
                <CurrencyLogo
                  className="currency-input-logo"
                >
                  {currencyObject?.startIcon}
                </CurrencyLogo>
              </InputAdornment>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            sx: {
              height: 'auto !important',
            },
          }}
          label={disableLabel ? undefined : 'Currency'}
          fullWidth
          disabled={false}
          sx={{
            height,
          }}
        />
      )}
      sx={{
        height,
        '& svg': {
          background: 'transparent',
          '& path': {
            stroke: stagesetColors.newGrey[800],
          },
        },
      }}
    />
  );
};

export default CurrencySelector;
