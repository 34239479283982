import BackgroundColorLayerSelector from './BackgroundColorLayerSelector';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  selectInitialDealPaletteSidebar,
  setDealThemePaletteSidebar,
  selectDealThemePaletteSidebar,
} from './lib/dealCustomizationSlice';
import { stagesetColors, stagesetPalette } from '../../theme/stagesetPalette';
import { CustomizationMenuContainer, CustomizationMenuSubtitle } from './Styles/DealCustomizationMenuStyles';

const BackgroundColorPickerPanel = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const dealThemePaletteSidebar = useAppSelector(selectDealThemePaletteSidebar);
  const initialDealPaletteSidebar = useAppSelector(selectInitialDealPaletteSidebar);

  const onColorUpdate = (color: string) => {
    dispatch(setDealThemePaletteSidebar(color));
  };

  return (
    <CustomizationMenuContainer>
      <CustomizationMenuSubtitle>
        Sidebar
      </CustomizationMenuSubtitle>
      <BackgroundColorLayerSelector
        key="Sidebar"
        initialColor={initialDealPaletteSidebar}
        currentColor={dealThemePaletteSidebar ?? stagesetPalette.background?.default}
        defaultColor={stagesetColors.newGrey[50]}
        onColorUpdate={(newColor) => onColorUpdate(newColor)}
      />
    </CustomizationMenuContainer>
  );
};

export default BackgroundColorPickerPanel;
