import { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import { AppIconButton } from '../../../../../shared/AppButton/AppIconButton';
import { MoreVertical20 } from '../../../../../icons/MoreVertical';
import { TeamTabMoreMenuPopover } from '../../Styles/TeamTabStyles';
import CellMoreMenu from './CellMoreMenu';
import useUserOrganizationPermissions from '../../../../../hooks/useOrganizationPermissions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/stateHooks';
import findOrganizationOwner from '../../../../../core/store/appState/selectors/ownerSelector';
import { EUserOrganizationPermissions } from '../../../../../../shared/permissions';
import { useTeamTabTableContext } from '../TeamTabTableContext';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
import NormalizedSvgIcon from '../../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import DeleteTypography from '../../../../../common/DeleteTypography';
import AlertTriangle from '../../../../../icons/AlertTriangle.svg';
import { organizationDeactivateUser } from '../../../../../core/store/appState/appState';

export const CellMoreMenuPopover = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [isDeleteUserConfirmationDialogOpen, setDeleteUserConfirmationDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const teammate = useTeamTabTableContext();
  const organizationOwner = useAppSelector(findOrganizationOwner);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const removeAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_REMOVE);
  const canRemove = (id: string) => removeAvailable && id !== organizationOwner?.id;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleRemove = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDeleteUserConfirmationDialogOpen(true);
    handleClose();
  };

  const onDeleteConfirm = (): void => {
    setDeleteUserConfirmationDialogOpen(false);
    dispatch(organizationDeactivateUser({ userId: teammate.id }));
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to exclude
      <b>
        {' '}
        {teammate.name || teammate.email}
        {' '}
      </b>
      from the team?
    </DeleteTypography>
  );

  return (
    <Box
      hidden={!canRemove(teammate.id)}
    >
      <AppIconButton
        icon={<MoreVertical20 />}
        size={isMobile ? 'm' : 'l'}
        variant="transparent"
        onClick={handleClick}
      />
      <TeamTabMoreMenuPopover
        className={classNames({ 'more-menu-popover-mobile': isMobile })}
        id={anchor ? 'team-tab-popover' : undefined}
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CellMoreMenu
          handleRemove={handleRemove}
        />
      </TeamTabMoreMenuPopover>
      <ConfirmationDialog
        isDialogOpen={isDeleteUserConfirmationDialogOpen}
        onCancel={() => setDeleteUserConfirmationDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Exclude"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </Box>
  );
};
