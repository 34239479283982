import { ListItemProps } from '@mui/material';
import { memo, useMemo } from 'react';
import {
  InviteItemContainer,
  InviteItemDescriptionContainer,
  InviteItemTitle,
  InviteItemSubTitle,
} from './AssigneeSelectionStyles';
import { emailSchema } from './emailSchema';
import { AvatarPlusIcon20 } from '../../icons/AvatarPlusIcon';

type Props = {
  email: string
  itemProps: ListItemProps
};

const InviteListItem = ({
  email,
  itemProps,
}: Props) => {
  const isCorrectEmail = useMemo(() => emailSchema.isValidSync(email), [email]);

  return (
    <InviteItemContainer
      {...itemProps}
    >
      <AvatarPlusIcon20 />
      <InviteItemDescriptionContainer>
        <InviteItemTitle>
          Invite “
          <span>
            {email}
          </span>
          ” via email
        </InviteItemTitle>
        <InviteItemSubTitle>
          {isCorrectEmail ? 'Press Enter to sent' : 'Email not valid'}
        </InviteItemSubTitle>
      </InviteItemDescriptionContainer>
    </InviteItemContainer>
  );
};

export default memo(InviteListItem);
