import { isMobile } from 'react-device-detect';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsTemplate, selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import { selectSidebarTab } from '../Layout/lib/fieldsLayoutSlice';
import useDealPermissions from '../../hooks/useDealPermissions';
import OwnerCardPopover from '../OwnerCard/OwnerCardPopover';
import StagesSidebarInviteBtn from './StagesSidebarInviteBtn';
import { SidebarFooterWrapper } from './Styles/SidebarFooterStyles';
import { EJWTTokenType } from '../../../shared/schema/token.schema';

interface Props {
  setContainerHeight: (stagesContainer: string) => void;
}

const StagesSidebarFooter = ({ setContainerHeight }: Props): JSX.Element | null => {
  const isTemplate = useAppSelector(selectIsTemplate);
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);
  const [checkPermissions] = useDealPermissions();
  const isLayoutEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_LAYOUT_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const tab = useAppSelector(selectSidebarTab);
  const tokenType = useAppSelector((state) => state.auth.tokenType);
  const noToolbarShown = tokenType === EJWTTokenType.SHARE || tokenType === EJWTTokenType.GUEST_AUTH;

  const buttons = [
    {
      toCheck: !isLayoutEditMode && !isLayoutEditable,
      onMobile: false,
      component: <OwnerCardPopover key="owner-card-popover" />,
    },
    {
      toCheck: !isTemplate && !isLayoutEditMode && !isLayoutEditable,
      onMobile: false,
      component: <StagesSidebarInviteBtn key="invite-button" />,
    },
  ];

  const buttonsArray = useMemo(
    () => buttons
      .filter((button) => (
        (button.toCheck && button.onMobile) || (button.toCheck && !isMobile)
      ))
      .map(({ component }) => (component)),
    [isMobile, isLayoutEditable, tab, isLayoutEditMode],
  );

  useEffect(() => {
    const stagesContainer = () => `calc(100vh - ${buttonsArray.length * 40 + 64}px)`;
    if (isMobile) {
      if (buttonsArray.length === 0) {
        setContainerHeight('calc(100% - 64px)');
      } else {
        setContainerHeight(stagesContainer());
      }
    } else if (buttonsArray.length === 0) {
      setContainerHeight('calc(100% - 70px)');
    } else {
      setContainerHeight('calc(100% - 132px)');
    }
  }, [buttonsArray,
    isLayoutEditable,
    tab,
    isLayoutEditMode,
    noToolbarShown]);

  if (buttonsArray.length === 0) return null;

  return (
    <SidebarFooterWrapper
      className={classNames({ 'sidebar-footer-wrapper': !isMobile })}
    >
      {buttonsArray}
    </SidebarFooterWrapper>
  );
};

export default StagesSidebarFooter;
