import { Popover, PopoverProps } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props extends PopoverProps {

}

const UserAvatarsPopover = (props: Props): JSX.Element => (
  <Popover
    onClick={(e) => e.stopPropagation()}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    slotProps={{
      paper: {
        sx: {
          boxSizing: 'border-box',
          width: isMobile ? '90%' : '320px',
          boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
          pb: 0,
          borderRadius: '12px',
          border: '1px solid',
          borderColor: stagesetColors.newGrey[300],
        },
      },
    }}
    transitionDuration={0}
    {...props}
    sx={{
      maxWidth: '320px',
    }}
  />
);

export default UserAvatarsPopover;
