import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { stagesetPalette } from '../../theme/stagesetPalette';

export const StyledSubscriptionButton = styled(Button)(() => ({
  height: '48px',
  width: '100%',
  borderRadius: '12px',
  backgroundColor: stagesetPalette.secondary?.main,
  color: stagesetPalette.primary?.main,
  '&.subscription-active': {
    borderRadius: '8px',
    color: '#7084B4',
    backgroundColor: '#fff',
    '& .MuiButton-startIcon': {
      stroke: '#7084B4',
    },
    '& path': {
      stroke: 'inherit',
    },
  },
  '&.isSellerPremiumActive': {
    backgroundColor: stagesetPalette.primary?.main,
    color: '#fff',
    '& .MuiButton-startIcon': {
      stroke: '#fff',
    },
    '& path': {
      stroke: 'inherit',
    },
  },
  '&.isSellerPremium': {
    backgroundColor: '#fff',
    color: stagesetPalette.primary?.main,
    '& .MuiButton-startIcon': {
      stroke: stagesetPalette.primary?.main,
    },
    '& path': {
      stroke: 'inherit',
    },
  },
  '&:hover': {
    backgroundColor: stagesetPalette.primary?.dark,
    color: '#fff',
  },
  '&:active': {
    backgroundColor: stagesetPalette.primary?.light,
    color: '#fff',
  },
}));

export const SubscriptionsStats = styled(Box)(() => ({
  minWidth: !isMobile ? '751px' : '316px',
  border: '1px solid #EEF0FA',
  borderRadius: '24px',
  padding: '24px',
  gap: '12px',
  backgroundColor: '#fff',
  '& .header-text': {
    fontWeight: 400,
    fontSize: '16px',
    color: '#7084B4',
  },
}));

export const SubscriptionsTabPaper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  alignItems: 'center',
  '& .SubscriptionTabSwitcher': {
    '&.MuiTabs-root': {
      borderRadius: '10px',
      padding: '4px',
      maxWidth: '210px',
      height: '48px',
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.1)',
      background: '#F2F3F7',
    },
    '& button': {
      height: '40px',
    },
  },
  '& .PlaneOptions': {
    borderBottom: '1px solid #F0F0F0',
    backgroundColor: 'none',
    height: '48px',
    padding: '0 8px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#EEF0FA',
    },
    '& .OptionTitleContainer': {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      '& .OptionNameActive': {
        color: 'black',
      },
      '& .OptionName': {
        color: '#7E7E7E',
      },
    },
    '& .IconContainer': {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      justifyContent: 'center',
      '& .CheckIcon': {
        color: '#62B839',
      },
      '& .Icon': {
        color: '#7E7E7E',
      },
    },
  },
}));

export const MobileSubscriptionsGridContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '24px',
  '& .item': {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '24px',
    backgroundColor: '#fff',
    border: '1px solid #EEF0FA',
  },
  '& .item.item-active': {
    backgroundColor: '#F9FBFF',
  },
  '& .top-container': {
    minHeight: '52px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .title': {
      fontWeight: 600,
      fontSize: '20px',
      marginBottom: '12px',
    },
    '& .description-text': {
      color: '#7084B4',
    },
    '& .description-container': {
      color: '#7084B4',
    },
  },
  '& .middle-container': {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    gap: '12px',
    '& .CheckIcon': {
      color: '#62B839',
    },
    '& .Icon': {
      color: '#7E7E7E',
    },
    '& .advantage-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '16px',
    },
  },
}));

export const PlanUpperContainer = styled(Grid)(() => ({
  padding: '0 24px',
  justifyContent: 'start',
  '& .PlanDescription': {
    display: 'flex',
    flex: '1',
    alignItems: 'left',
    flexDirection: 'column',
    '& .PlanName': {
      paddingTop: '0px',
      paddingBottom: '16px',
    },
  },
}));
