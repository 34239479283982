export enum EStateStatus {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  IDLE = 'IDLE',
  TYPING = 'TYPING',
}

export interface IStateBase {
  status: EStateStatus,
  error?: string;
}

export const getInitialBaseState = (): IStateBase => ({
  status: EStateStatus.IDLE,
});
