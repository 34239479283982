import { Avatar, AvatarProps } from '@mui/material';
import { useMemo } from 'react';
import deepmerge from 'deepmerge';
import { iconColorPalette } from '../routes-old/process/helpers';
import { stagesetColors } from '../theme/stagesetPalette';

export type AppAvatarProps = {
  name?: string,
  src?: string,
  colorId?: number,
} & AvatarProps;

export const AppAvatar = (props: AppAvatarProps) => {
  const { colorId } = props;

  const colorStyles = useMemo(() => {
    if (!colorId) return {};
    return {
      color: iconColorPalette[colorId].letter,
      background: iconColorPalette[colorId].back,
    };
  }, [colorId]);

  const {
    name,
    src,
    alt,
    ...avatarProps
  } = deepmerge<Partial<AppAvatarProps>, Required<Pick<AppAvatarProps, 'name' | 'sx'>>>({
    name: 'unknown',
    sx: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: stagesetColors.newGrey[200],
      ...colorStyles,
    },
  }, props);

  delete avatarProps.colorId;

  return (
    <Avatar
      src={src}
      alt={name}
      {...avatarProps}
    >
      {name[0]}
    </Avatar>
  );
};
