import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import {
  deleteTemplate,
  ETemplatesTypes,
  getTemplates,
  selectTemplatesTab,
  setSelectedTemplateData,
  templatesTypeConverter,
} from '../pages/templates/lib/templatesSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { selectProcessId } from '../routes-old/process/state/processSlice';
import useModalWindow from './useModalWindow';
import { ProcessDTO } from '../../shared/process/ProcessMilestoneActionDTO';
import { FieldSectionDTO, ProcessStageDTO } from '../../shared/Field/Field';
import { CloseIcon20 } from '../icons/CloseIcon';
import {
  useCreateTemplateFromSectionMutation,
  useCreateTemplateFromStageMutation,
  useDeleteSectionMutation,
  useDeleteStageMutation,
} from '../features/ProcessFields/lib/processFieldsApi';
import { createProcess } from '../routes-old/deals/state/dealsSlice';

const useTemplates = () => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(selectProcessId);
  const currentTab = useAppSelector(selectTemplatesTab);
  const [deleteStage] = useDeleteStageMutation();
  const [deleteSection] = useDeleteSectionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [copyTemplate] = useCreateTemplateFromStageMutation();
  const [createSectionTemplate] = useCreateTemplateFromSectionMutation();
  const { setTemplateModalWindowState, onModalWindowStateReset } = useModalWindow();

  const duplicateTemplate = async (id: string, title?: string): Promise<ProcessStageDTO[] | FieldSectionDTO[] | ProcessDTO[] | null> => {
    let result;
    try {
      switch (currentTab) {
        case ETemplatesTypes.ROOMS:
          result = await dispatch(createProcess({
            title: `${title} Copy`,
            type: templatesTypeConverter[currentTab],
            templateId: id!,
          })).unwrap();
          break;
        case ETemplatesTypes.PAGES:
          result = await copyTemplate({ id, processId }).unwrap();
          break;
        case ETemplatesTypes.SECTIONS:
          result = await createSectionTemplate({ id, processId }).unwrap();
          break;
        default:
          return null;
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  };

  const onTemplateCopy = async (id: string, title?: string) => {
    try {
      setTemplateModalWindowState();
      const newTemplate = await duplicateTemplate(id, title);
      if (newTemplate) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CloseIcon20 />
              </Box>
            ),
          },
        );
        dispatch(getTemplates(''));
        dispatch(setSelectedTemplateData({
          id: newTemplate.id,
          title: newTemplate.title ?? '',
          type: templatesTypeConverter[currentTab],
        }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      onModalWindowStateReset();
    }
  };

  const onTemplateDelete = (id: string): void => {
    switch (currentTab) {
      case ETemplatesTypes.ROOMS:
        dispatch(deleteTemplate(id));
        break;
      case ETemplatesTypes.PAGES:
        deleteStage({
          stageId: id,
          processId,
        });
        break;
      case ETemplatesTypes.SECTIONS:
        deleteSection({
          id,
          processId,
          parentId: processId,
        });
        break;
      default:
        break;
    }
  };

  return {
    templatesTypeConverter,
    onTemplateCopy,
    onTemplateDelete,
  };
};

export default useTemplates;
