import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import {
  EContentDisplayDirection, ProcessStageDTO,
} from '../../../shared/Field/Field';
import { StageContentType } from '../../../shared/Stage';
import StageContent from '../Layout/StageContent';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectCurrentStage } from '../ProcessFields/lib/processItemsSlice';

const BlockContentSectionContainer = (): JSX.Element | null => {
  const currentStage = useAppSelector(selectCurrentStage);

  // TODO: skeleton
  if (!currentStage) {
    return null;
  }

  return (
    <Box
      onDragStart={() => false}
      className="BlockSectionContainer"
      sx={{
        height: '100%',
        width: '100%',
        background: 'transparent',
        minHeight: '40px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : (currentStage?.contentDisplayDirection ?? ''),
        justifyContent: 'flex-start',
        alignItems: currentStage?.contentDisplayDirection === EContentDisplayDirection.ROW ? 'flex-start' : 'center',
        flex: 1,
        paddingY: currentStage?.depth === 1 && currentStage?.stageContentType !== StageContentType.WIDESCREENSTAGE ? '8px' : '0px',
        opacity: '1',
        transition: 'padding 0.2s',
      }}
    >
      <StageContent
        stage={currentStage as any as ProcessStageDTO}
      />
    </Box>
  );
};

export default BlockContentSectionContainer;
