import {
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';
import { SelectedSourceOption } from '../../MediaField/Helpers/MediaFieldHelpers';
import { LibraryFileDTO } from '../../../../shared/library/LibraryDTO';

const sectionsSliceReducerName = 'sectionsSlice';

type SectionToDeleteData = {
  id: string,
  parentId: string,
  popUpTitle?: string,
  popUpText?: string,
};

type SectonsSliceType = {
  selectedRowId: string,
  parentColId: string,
  colEditMode: boolean,
  moveRowMode: boolean,
  preventSeletionChange?: boolean,
  embedSectionData?: SelectedSourceOption,
  currentStageNeedsToBeUpdated?: boolean,
  sectionToDeleteData?: SectionToDeleteData,
  colResizeStatus?: boolean,
  libraryItemToAdd?: LibraryFileDTO;
};

const initialState: SectonsSliceType = {
  selectedRowId: '',
  parentColId: '',
  preventSeletionChange: false,
  colEditMode: false,
  moveRowMode: false,
  embedSectionData: undefined,
  currentStageNeedsToBeUpdated: false,
  sectionToDeleteData: undefined,
  colResizeStatus: false,
};

export const sectionsSlice = createSlice({
  name: sectionsSliceReducerName,
  initialState,
  reducers: {
    setSelectedSectionRow: (state, { payload }) => ({
      ...state,
      selectedRowId: state.preventSeletionChange ? state.selectedRowId : payload.rowId,
      parentColId: payload.colId ?? '',
      colEditMode: false,
      moveRowMode: false,
    }),
    setPreventRowSelectionChange: (state, { payload }) => ({
      ...state,
      preventSeletionChange: payload,
    }),
    changeColEditMode: (state) => ({
      ...state,
      moveRowMode: false,
      colEditMode: !state.colEditMode,
    }),
    changeColResizeStatus: (state) => ({
      ...state,
      colResizeStatus: !state.colResizeStatus,
    }),
    changeMoveRowMode: (state) => ({
      ...state,
      colEditMode: false,
      moveRowMode: !state.moveRowMode,
    }),
    setEmbedSectionData: (state, { payload }) => ({
      ...state,
      embedSectionData: { ...payload },
    }),
    setCurrentStageNeedsToBeUpdated: (state, { payload }) => ({
      ...state,
      currentStageNeedsToBeUpdated: payload,
    }),
    clearEmbedSectionData: (state) => ({
      ...state,
      embedSectionData: undefined,
    }),
    setSectionToDeleteData: (state, { payload }) => ({
      ...state,
      sectionToDeleteData: payload,
    }),
    setLibraryFileToAdd: (state, { payload }) => ({
      ...state,
      libraryItemToAdd: payload,
    }),
  },
});

export const {
  setSelectedSectionRow,
  changeColEditMode,
  changeMoveRowMode,
  setEmbedSectionData,
  clearEmbedSectionData,
  setSectionToDeleteData,
  setCurrentStageNeedsToBeUpdated,
  setPreventRowSelectionChange,
  changeColResizeStatus,
  setLibraryFileToAdd,
} = sectionsSlice.actions;

export const isSectionsRowSelected = (state: RootState, rowId: string): boolean => (state.sections.selectedRowId === rowId);
export const isChildRowSelected = (state: RootState, colId: string): boolean => state.sections.parentColId === colId;
export const isColEditMode = (state: RootState): boolean => state.sections.colEditMode;
export const selectConResizeStatus = (state: RootState): boolean => state.sections.colResizeStatus;
export const isMoveRowMode = (state: RootState): boolean => state.sections.moveRowMode;
export const selectEmbedSectionData = (state: RootState): SelectedSourceOption | undefined => state.sections.embedSectionData;
export const selectCurrentStageNeedsToBeUpdated = (state: RootState): boolean => state.sections.currentStageNeedsToBeUpdated;
export const selectSectionToDeleteData = (state: RootState): SectionToDeleteData | undefined => state.sections.sectionToDeleteData;
export const selectLibraryFileToAdd = (state: RootState): LibraryFileDTO | undefined => state.sections.libraryItemToAdd;
