import { isMobile } from 'react-device-detect';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { AttachmentIcon } from '../../../../../icons/AttachmentIcon';
import { ExternalLinkGrey16px } from '../../../../../icons/ExternalLink';
import { AgendaNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import { AgendaNodeInfoAnchorContainer, AgendaNodeInfoItemContainer, AgendaNodeInfoItemTitle } from './Styles/AgendaNodeStyles';
import { TaskIcon } from '../../../icons/MenuIcons';
import { FormattedLabel } from '../../../../../shared/AssigneeSelection/FormattedLabel';
import { stagesetColors } from '../../../../../theme/stagesetPalette';

export const AgendaTimelineInfo = (): JSX.Element | null => {
  const { data } = useBlockContext();

  if (!data.timeLineEndDate) {
    return null;
  }

  return (
    <AgendaNodeInfoItemContainer>
      <AgendaNodeInfoItemTitle>
        {data.timeLineEndDate && new Date(data.timeLineEndDate).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
        })}
      </AgendaNodeInfoItemTitle>
    </AgendaNodeInfoItemContainer>
  );
};

export const AgendaDocumentsInfo = (): JSX.Element | null => {
  const { data } = useBlockContext();

  if (!data.docs.length) {
    return null;
  }

  return (
    <AgendaNodeInfoItemContainer>
      <AttachmentIcon />
      <AgendaNodeInfoItemTitle>
        {data.docs?.length}
      </AgendaNodeInfoItemTitle>
    </AgendaNodeInfoItemContainer>
  );
};

export const AgendaSubtasksInfo = (): JSX.Element | null => {
  const { data } = useBlockContext<AgendaNodeAttrs>();

  if (!data?.subTasks?.length) {
    return null;
  }

  return (
    <AgendaNodeInfoItemContainer>
      <TaskIcon />
      <AgendaNodeInfoItemTitle>
        {data.subTasks.length}
      </AgendaNodeInfoItemTitle>
    </AgendaNodeInfoItemContainer>
  );
};

export const AgendaEventInfo = (): JSX.Element | null => {
  const { data } = useBlockContext();

  if (!data.eventData?.url) {
    return null;
  }

  return (
    <AgendaNodeInfoAnchorContainer
      href={data.eventData?.url}
      target="_blank"
      rel="noreferrer"
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <ExternalLinkGrey16px />
      <FormattedLabel
        label={data.eventData?.name}
        len={isMobile ? 12 : 24}
        tooltip
        placement="right"
        sx={{
          fontSize: '14px',
          lineHeight: '16px',
          color: stagesetColors.newGrey[500],
          whiteSpace: 'nowrap',
          textDecoration: 'none',
        }}
      />
    </AgendaNodeInfoAnchorContainer>
  );
};
