import { JSONContent } from '@tiptap/react';
import { TBlockMenuItem } from './TiptapTypes';
import {
  BulletListIcon,
  ButtonIcon,
  EmbedIcon,
  FileIcon,
  Header1Icon,
  Header2Icon,
  Header3Icon,
  MediaIcon,
  NumberedListIcon,
  PricingTableIcon,
  TaskIcon,
  TemplatesIcon,
  TextIcon,
  ThreeColumnsIcon,
  TwoColumnsIcon,
} from '../icons/MenuIcons';

const defaultListContent: JSONContent[] = [
  {
    type: 'listItem',
    content: [
      {
        type: 'paragraph',
        content: [],
      }],
  },
];

const BlockMenuItems: TBlockMenuItem[] = [
  {
    label: 'Heading H1',
    type: 'heading',
    content: [],
    attrs: { level: 1 },
    icon: <Header1Icon />,
  },
  {
    label: 'Heading H2',
    type: 'heading',
    content: [],
    attrs: { level: 2 },
    icon: <Header2Icon />,
  },
  {
    label: 'Heading H3',
    type: 'heading',
    content: [],
    attrs: { level: 3 },
    icon: <Header3Icon />,
  },
  {
    label: 'Text',
    type: 'paragraph',
    content: [],
    attrs: {},
    icon: <TextIcon />,
  },
  {
    label: 'Bullet list',
    type: 'bulletList',
    content: defaultListContent,
    attrs: {},
    icon: <BulletListIcon />,
  },
  {
    label: 'Numbered list',
    type: 'orderedList',
    content: defaultListContent,
    attrs: {},
    icon: <NumberedListIcon />,
  },
  {
    label: 'Task',
    type: 'agenda',
    content: [],
    attrs: {},
    icon: <TaskIcon />,
  },
  {
    label: 'Media',
    type: 'media',
    content: [],
    attrs: {},
    icon: <MediaIcon />,
  },
  {
    label: 'Documents',
    type: 'documents',
    content: [],
    attrs: {},
    icon: <FileIcon />,
  },
  {
    label: 'Embed',
    type: 'embed',
    content: [],
    attrs: {},
    icon: <EmbedIcon />,
  },
  {
    label: '2 Cols',
    type: 'table',
    columns: 2,
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableHeader',
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          },
          {
            type: 'tableHeader',
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          },
        ],
      },
    ],
    attrs: {},
    icon: <TwoColumnsIcon />,
  },
  {
    label: '3 Cols',
    type: 'table',
    columns: 3,
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableHeader',
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          },
          {
            type: 'tableHeader',
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          },
          {
            type: 'tableHeader',
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          },
        ],
      },
    ],
    attrs: {},
    icon: <ThreeColumnsIcon />,
  },
  {
    label: 'Pricing table',
    type: 'pricingTable',
    content: [],
    attrs: {},
    icon: <PricingTableIcon />,
  },
  {
    label: 'Button',
    type: 'buttonNode',
    content: [],
    attrs: {},
    icon: <ButtonIcon />,
  },
  {
    label: 'Template',
    type: 'template',
    content: [],
    attrs: {},
    icon: <TemplatesIcon />,
  },
];

export default BlockMenuItems;
