import { DomainHelper } from "./DomainHelper";
import Cookies from 'js-cookie';

export class SharedSessionStorage {
  static setItem = (key: string, value: string) => {
    sessionStorage.setItem(key, value);

    Cookies.set(key, value, { domain: DomainHelper.getFirstValidDomain() });
  }

  static getItem = (key: string) => {
    return window.sessionStorage.getItem(key) || SharedSessionStorage.getCookie(key);
  }

  static removeItem = (key: string) => {
    window.sessionStorage.removeItem(key);
  }

  static getCookie = (name: string) => {
    const cookie =  Cookies.get(name);

    return cookie;
  }

  static removeCookie = (key: string) => {
    const domain = DomainHelper.getFirstValidDomain();
    Cookies.remove(key, { domain });
    /**
     * in case the cookie is not in the main domain
     */
    const domains = DomainHelper.getAllAvailableDomains();
    for (const dm of domains) {
      Cookies.remove(key, { domain: dm });
    }
  }

  static clearLocalStorageSession = () => {
    sessionStorage.clear();
  }

  static clearItem(key: string) {
    SharedSessionStorage.removeCookie(key);
    SharedSessionStorage.removeItem(key);
  }
}
