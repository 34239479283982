import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  resetUpdatesError,
  selectFailedItemNamesUpdates,
  selectFailedItemUpdates,
  selectReadyUpdates,
  selectUpdatesErrorMessage,
  updateManyProcessItems,
} from '../ProcessFields/lib/processItemsSlice';
import { ProcessStageDTO } from '../../../shared/Field/Field';
import useDebounceSwitch from '../../hooks/useDebounceSwitch';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import useCurrentItemUpdate from '../../hooks/useProcessItemUpdate';

const ProcessItemsUpdatePlugin = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const updates = useAppSelector(selectReadyUpdates);
  const [switchStatus, setSwitch] = useDebounceSwitch(true, 1500, true);
  const errorMessage = useAppSelector(selectUpdatesErrorMessage);
  const failedUpdates = useAppSelector(selectFailedItemUpdates);
  const failedUpdatesNames = useAppSelector(selectFailedItemNamesUpdates);
  const { updateCurrentItem } = useCurrentItemUpdate();

  const [open, setOpen] = useState(false);

  const handleUpdate = (event: CustomEvent<{ id: string, data: Partial<ProcessStageDTO> }>) => {
    const { id, data } = event.detail;
    updateCurrentItem(id, data);
  };

  useEffect(() => {
    document.addEventListener(ECustomEventsNames.PROCESS_ITEM_UPDATE, handleUpdate as EventListener);
    return () => document.removeEventListener('process-item-update', handleUpdate as EventListener);
  }, []);

  const preventClose = () => {
    if (updates.length) {
      dispatch(updateManyProcessItems(updates));
    }
    return '';
  };

  useEffect(() => {
    if (updates.length) {
      window.onbeforeunload = preventClose;
    }
    if (updates.length && switchStatus) {
      dispatch(updateManyProcessItems(updates));
      setSwitch(false);
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [updates, switchStatus]);

  useEffect(() => {
    if (failedUpdates.length && errorMessage === 'Payload too large') {
      setOpen(true);
    }
  }, [failedUpdates, errorMessage]);

  const onClose = () => {
    dispatch(resetUpdatesError);
    setOpen(false);
  };

  return (
    <ConfirmationDialog
      hideCancel
      isDialogOpen={open}
      onConfirm={onClose}
      onCancel={() => {}}
      dialogContentText={(
        <Box>
          <Typography>
            Failed to update stages:
          </Typography>
          {
          failedUpdatesNames.map((title: string, i: number) => <Typography key={`update-stage-error-name-${i}`}>{title}</Typography>)
        }
        </Box>
    )}
      dialogTitle="Page size is too large"
      confirmText="Ok"
    />
  );
};

export default ProcessItemsUpdatePlugin;
