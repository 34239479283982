import { SvgIcon, SvgIconProps } from '@mui/material';

export const MenuRoomsIcon20 = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M16.9648 5.91724H3.63151C2.71104
      5.91724 1.96484 6.66343 1.96484
      7.5839V15.9172C1.96484 16.8377 2.71104
      17.5839 3.63151 17.5839H16.9648C17.8853
      17.5839 18.6315 16.8377 18.6315
      15.9172V7.5839C18.6315 6.66343 17.8853
      5.91724 16.9648 5.91724Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.6315 17.584V4.25065C13.6315 3.80862
      13.4559 3.3847 13.1434 3.07214C12.8308 2.75958
      12.4069 2.58398 11.9648 2.58398H8.63151C8.18948
      2.58398 7.76556 2.75958 7.453 3.07214C7.14044
      3.3847 6.96484 3.80862 6.96484 4.25065V17.584"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
