/* eslint-disable react/no-unused-prop-types */
import {
  Box, Grid, Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { SubscriptionButton } from './SubscriptionButton';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { PlanUpperContainer } from './SubscriptionsStyles';
import { ESubscriptionType, appSumoSubscriptionTypes } from '../../../shared/Subscription';
import NormalizedSvgIcon from '../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import CheckCircle from '../../icons/CheckCircle.svg';

interface Props {
  title: string,
  description: string | JSX.Element,
  features?: string[],
  type?: ESubscriptionType,
  btnTitle?: string,
  btnIcon?: JSX.Element,
  currentBtnTitle?: string,
  current?: boolean,
  currentBtnIcon?: JSX.Element,
  priceId?: string,
}

export const SubscriptionGridCard = (props: Props): JSX.Element => {
  const {
    title,
    description,
    features,
    type,
    btnTitle,
    current,
    currentBtnTitle,
    currentBtnIcon,
    btnIcon,
    priceId,
  } = {
    btnTitle: 'Get started',
    currentBtnTitle: 'Current plan',
    currentBtnIcon: <NormalizedSvgIcon component={CheckCircle} />,
    ...props,
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      padding: '24px 0px',
      gap: '8px',
      maxWidth: '315px',
      minwidth: '200px',
      height: appSumoSubscriptionTypes.includes(type) ? '480px' : '440px',
      border: `1px solid ${stagesetPalette.grey?.[100]}`,
      borderRadius: '24px',
      background: (theme) => (type === ESubscriptionType.SELLER_PREMIUM ? theme.palette.primary.main : '#fff'),
      color: type === ESubscriptionType.SELLER_PREMIUM ? '#fff' : stagesetPalette.grey?.[600],
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
      >
        <PlanUpperContainer
          container
          sx={{
            borderBottom: (theme) => (type === ESubscriptionType.SELLER_PREMIUM
              ? `1px solid ${theme.palette.primary.dark}`
              : `1px solid ${stagesetPalette.grey?.[100]}`),
          }}
        >
          <Grid
            className="PlanDescription"
            xs={12}
            item
          >
            <Typography
              className="PlanName"
              sx={{
                fontWeight: 600,
                fontSize: '20px',
              }}
            >
              {title}
            </Typography>
            {description}
          </Grid>
        </PlanUpperContainer>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px',
          gap: '12px',
        }}
        >
          {
                  features?.map((feature, i) => (
                    <Box
                      key={`${title}-subscription-feature-${i}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <Check sx={{ fontSize: '18px !important' }} />
                      <Typography fontSize={18}>
                        {feature}
                      </Typography>
                    </Box>
                  ))
              }
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 32px',
      }}
      >
        <SubscriptionButton
          current={current}
          currentIcon={currentBtnIcon}
          currentTitle={currentBtnTitle}
          icon={btnIcon}
          title={btnTitle}
          priceId={priceId}
          type={type}
        />
      </Box>
    </Box>
  );
};
