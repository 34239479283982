import { EUserDealPermissions, EUserOrganizationPermissions } from '../../shared/permissions';
import useDealPermissions from './useDealPermissions';

export const useTaskPermissions = () => {
  const [checkPermissions] = useDealPermissions();

  const isActionDeleteEnabled: boolean = checkPermissions(
    EUserDealPermissions.DEAL_ACTIONS_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const isUserAssignEnabled: boolean = checkPermissions(
    EUserDealPermissions.DEAL_ACTIONS_ASSIGN,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const isActionStatusEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_ACTIONS_STATUS,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const isDndEnabled: boolean = checkPermissions(
    EUserDealPermissions.DEAL_ACTIONS_REORDER,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const isActionTitleEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_ACTIONS_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );

  return {
    isActionDeleteEnabled,
    isUserAssignEnabled,
    isActionStatusEditable,
    isDndEnabled,
    isActionTitleEditable,
  };
};
