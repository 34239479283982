import {
  selectOrganizationLogo,
  selectOrganizationName,
} from '../../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import {
  DealCreationDataSliceType,
  resetDealCreationData,
  selectDealToCreateClientLogo,
  selectDealToCreateClientName,
  updateDealCreationData,
} from './dealCreationDataSlice';

const useDealCreationData = () => {
  const dispatch = useAppDispatch();
  const creatorLogo = useAppSelector(selectOrganizationLogo);
  const creatorName = useAppSelector(selectOrganizationName);
  const clientName = useAppSelector(selectDealToCreateClientName);
  const clientLogo = useAppSelector(selectDealToCreateClientLogo);

  const onDealToCreateDataUpdate = (data: DealCreationDataSliceType) => {
    dispatch(updateDealCreationData(data));
  };

  const onResetDealCreationData = () => {
    dispatch(resetDealCreationData());
  };

  return {
    creatorName,
    creatorLogo,
    clientName,
    clientLogo,
    onDealToCreateDataUpdate,
    onResetDealCreationData,
  };
};

export default useDealCreationData;
