import { TabPanel } from '@mui/lab';
import {
  Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { EAdminFeedTab } from '../../helpers/AdminHelpers';
import { stagesetColors } from '../../../../theme/stagesetPalette';
import { adminDeleteOrganization, selectSelectedOrganization } from '../../state/adminSlice';
import useScreenDimensions from '../../../../hooks/useScreenDimensions';
import OrganizationDetails from './OrganizationDetails';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { OrganizationAdminDTO } from '../../../../../shared/OrganizationDTO';
import DeleteTypography from '../../../../common/DeleteTypography';
import ConfirmationDialog from '../../../../common/ConfirmationDialog/ConfirmationDialog';
import NormalizedSvgIcon from '../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import AlertTriangle from '../../../../icons/AlertTriangle.svg';

const OrganizationFeedTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedOrganization: OrganizationAdminDTO = useAppSelector(selectSelectedOrganization);
  const [height] = useScreenDimensions(true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteUserConfirmationDialogOpen, setDeleteUserConfirmationDialogOpen] = useState<boolean>(false);
  const [value, setValue] = useState('disable');

  const handleDelete = () => {
    setDeleteUserConfirmationDialogOpen(true);
    setAnchorEl(null);
  };

  const onDeleteConfirm = (): void => {
    setDeleteUserConfirmationDialogOpen(false);
    dispatch(adminDeleteOrganization({ organizationId: selectedOrganization.id, type: value }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to delete
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="delete" control={<Radio />} label="Delete permanently" />
          <FormControlLabel value="disable" control={<Radio />} label="Disable Organization" />
        </RadioGroup>
      </FormControl>
      <Box>
        <b>
          {' '}
          {selectedOrganization.name}
          {' '}
        </b>
        ?
      </Box>
    </DeleteTypography>
  );

  return (
    <>
      <TabPanel
        value={EAdminFeedTab.organizationDetails}
        id="pricing-table-side-panel"
        sx={{
          padding: '0px',
          // display: tab === ESidebarTabs.FILTERS ? 'flex' : 'none',
          flexDirection: 'column',
          height: `calc(${height - 48}px)`,
          justifyContent: 'space-between',
          paddingTop: '16px',
          backgroundColor: stagesetColors.white[100],
          width: '100%',
          '& .AdminOrganizationDetailsSection': {
            background: '#fff',
            border: '1px solid #EEF0FA',
            borderRadius: '10px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            boxShadow: `
              0px 3px 2px rgba(0, 113, 190, 0.04),
              0px 1px 1px rgba(0, 113, 190, 0.07),
              0px 0px 1px rgba(0, 113, 190, 0.08),
              0px 0px 0px rgba(0, 113, 190, 0.08)`,
          },
          '& .OrganizationSectionTitle': {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            color: (theme) => theme.palette.grey[600],
          },
        }}
      >
        <Box
          sx={{
            height: '64px',
            paddingX: '24px',
            paddingY: '24px',
            boxShadow: '0px 5px 7px -3px rgba(0, 57, 96, 0.1), 0px 0px 5px -2px rgba(0, 57, 96, 0.05)',
            marginBottom: '6px',
            marginTop: '-12px',
          }}
        >
          <Typography
            className="OrganizationSectionTitle"
          >
            {selectedOrganization.name}
          </Typography>
        </Box>
        <OrganizationDetails organizationAdmin={selectedOrganization} />
        <Box
          sx={{
            padding: '8px 16px 16px 16px',
          }}
        >
          <AppButton
            onClick={handleDelete}
            fullWidth
            variant="danger"
            size="l"
          >
            Delete
          </AppButton>
        </Box>
      </TabPanel>
      <ConfirmationDialog
        isDialogOpen={isDeleteUserConfirmationDialogOpen}
        onCancel={() => setDeleteUserConfirmationDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Delete"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </>
  );
};

export default OrganizationFeedTab;
