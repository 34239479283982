import { Box } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = { lastActive?: number, callback?: () => void; isLast?: boolean; };

export const DealHealth = ({ lastActive, callback, isLast }: Props) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<Element | null>(null);
  const params: { color: (theme: Theme) => string, status: string } = useMemo(() => {
    if (!lastActive) {
      return {
        color: '#EEF0FA',
        status: t('Health_Unopened'),
      };
    }
    if (moment().diff(lastActive, 'days') < 14) {
      return {
        color: '#C4E8C3',
        status: t('Health_Hot'),
      };
    }
    return {
      color: '#C2DEFF',
      status: t('Health_Cold'),
    };
  }, [lastActive]);

  let observer: IntersectionObserver | undefined;

  useEffect(() => {
    if (isLast && wrapperRef.current && !observer) {
      observer = new IntersectionObserver((ent) => {
        if (ent[0].isIntersecting) {
          if (callback) {
            callback();
          }
          observer!.unobserve(ent[0].target);
        }
      });

      observer.observe(wrapperRef.current!);
    }
    return () => {
      if (isLast && wrapperRef.current && observer) {
        observer.unobserve(wrapperRef.current);
        observer = undefined;
      }
    };
  }, [wrapperRef, observer]);

  return (
    <Box
      ref={wrapperRef}
      sx={{
        background: params.color,
        padding: '0px 6px',
        borderRadius: '4px',
        color: 'black',
      }}
    >
      {params.status}
    </Box>
  );
};
