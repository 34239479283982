import { AllFieldsDTO } from '../../shared/Field/Field';
import { selectProcessId } from '../routes-old/process/state/processSlice';
import { useAppSelector } from './stateHooks';
import { selectProcessItem } from '../features/ProcessFields/lib/processItemsSlice';
import { useItemUpdate } from '../features/Layout/ItemContextProvider';

export type UseProcessField<T> = {
  data: T,
  isLoading: boolean,
  onFieldUpdate: (data: Partial<AllFieldsDTO>) => void,
  changeSource: () => void,
};

interface Props {
  id: string;
}

// TODO fix fetch for templates and query refetch conditions
const useProcessField = <T>({
  id,
}: Props): {
    data: T,
    onFieldUpdate: (data: Partial<T>) => void,
  } => {
  const fieldData = useAppSelector((state) => selectProcessItem(state, id));

  const { onUpdate } = useItemUpdate(id);
  const onFieldUpdate = (data: Partial<AllFieldsDTO>) => {
    if (!fieldData) return;
    onUpdate({
      ...data,
    });
  };

  return {
    data: fieldData as T,
    onFieldUpdate,
  };
};

export default useProcessField;
