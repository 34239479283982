import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Avatar, Box, SvgIcon, Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import getSymbolFromCurrency from 'currency-symbol-map';
import { StyledDialogActions } from '../../../common/ConfirmationDialog/ConfirmationDialogStyle';
import { ClearbitAutocompleteDTO } from '../../../../shared/ClearbitDTO';
import CompanyAndLogoSelect, {
  disabledLogoCompany,
  initialCompany,
} from '../../../features/CreateDealDialog/CompanyAndLogoSelect';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { addBlure } from '../../process/helpers';
import { StyledCreateDealDialog } from '../DealPageStyles/CreateDealDialogStyle';
import { processByIdSelector, updateProcess } from '../state/dealsSlice';
import AvatarPlaceholder from '../../../icons/AvatarPlaceholder.svg';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { CloseIcon } from '../../../icons/Close16px';
import { selectOrganization, selectOrganizationName } from '../../../core/store/appState/appState';
import { DefaultOrgCurrency } from '../../../../shared/OrganizationDTO';

export interface IEditDealFormState {
  title: string;
  client: string;
  value: number;
  logo: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  processId: string;
}

const EditDealDialog = ({
  isOpen,
  onClose,
  processId,
}: Props) => {
  const dispatch = useAppDispatch();
  const process = useAppSelector((state) => processByIdSelector(state, processId));
  const organizationName = useAppSelector(selectOrganizationName);
  const organization = useAppSelector(selectOrganization);
  const dialogRef = useRef<HTMLDivElement>(null);

  const schema = yup.object({
    title: yup.string()
      .required('Opportunity title is required'),
    client: yup.string()
      .required('Client is required'),
    value: yup
      .number()
      .typeError('Value should be a number')
      .min(0)
      .transform((_, val) => Number(val)),
  })
    .required();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    register,
    getValues,
  } = useForm<IEditDealFormState>({
    defaultValues: process,
    resolver: yupResolver(schema),
  });

  const [isAutocompleteVisible, setAutocompleteVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ClearbitAutocompleteDTO>(disabledLogoCompany);
  register('client', {
    onChange: (e) => {
      setSelectedCompany(initialCompany);
      if (e.target.value && !isAutocompleteVisible) {
        setAutocompleteVisible(true);
      }
      if (!e.target.value && isAutocompleteVisible) {
        setAutocompleteVisible(false);
      }
    },
  });

  useEffect(() => {
    if (process) {
      setSelectedCompany({
        name: process.client || '',
        logo: process.buyerLogoSrc || '',
        domain: '',
      });
      setValue('value', process.value || 0);
      setValue('title', process.title);
      setValue('client', process.client || '');
      // clearbit field fix
      setTimeout(() => dialogRef.current?.click());
    }
  }, [process]);

  const handleCompanySelect = (data: ClearbitAutocompleteDTO) => {
    if (data.name) {
      setValue('client', data.name);
      setValue('title', `${data.name} deal`);
    }
    setSelectedCompany(data);
    setAutocompleteVisible(false);
  };

  const handleClose = () => {
    setSelectedCompany(initialCompany);
    reset();
    onClose();
  };

  const onFormSubmit = async (data: IEditDealFormState) => {
    if (!process) return;

    try {
      dispatch(updateProcess({
        ...process,
        ...data,
        summaryTitle: `${data.client} x ${organizationName}`,
        buyerLogoSrc: selectedCompany.logo,
      }));

      setSelectedCompany(initialCompany);
      reset();
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    switch (isOpen) {
      case true:
        addBlure(true);
        break;
      case false:
        addBlure(false);
        break;
      default:
        addBlure(false);
        break;
    }
  }, [isOpen]);

  if (!process) return null;

  return (
    <StyledCreateDealDialog
      ref={dialogRef}
      open={isOpen}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        id: 'create-deal-dialog',
        sx: {
          boxShadow: 'none',
          overflow: 'visible',
        },
      }}
    >
      <Box
        component="form"
        id="create-deal-form"
        autoComplete="off"
        onSubmit={handleSubmit(onFormSubmit)}
        noValidate
      >
        <DialogTitle sx={{
          padding: '24px 24px 0px 24px !important',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Typography
            sx={{
              lineHeight: '25px',
              fontWeight: 500,
              fontSize: '20px',
              letterSpacing: '-1px',
            }}
          >
            Edit deal
          </Typography>
          <Box>
            <AppIconButton icon={<CloseIcon />} onClick={onClose} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{
          padding: '20px 24px !important',
        }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '4px',
            }}
          >
            <Controller
              name="client"
              control={control}
              defaultValue=""
              render={({
                field: {
                  onChange,
                  value,
                },
                fieldState: { error },
              }) => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  margin: '5px 0px',
                }}
                >
                  <Box sx={{
                    width: '100%',
                    height: '50px',
                  }}
                  >
                    <AppInput
                      required
                      label="Company"
                      id="client-name-field"
                      placeholder="Type here"
                      onFocus={() => setAutocompleteVisible(true)}
                      onBlur={(e) => {
                        if (e.relatedTarget?.id !== 'company-information-autocomplete') {
                          setAutocompleteVisible(false);
                        }
                        if (!getValues().title && !!e.target.value.trim()) {
                          setValue('title', `${e.target.value} deal`);
                        }
                      }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                    <CompanyAndLogoSelect
                      isAutocompleteVisible={isAutocompleteVisible}
                      value={value}
                      setSelectedCompany={handleCompanySelect}
                    />
                  </Box>

                  <Avatar
                    src={selectedCompany.logo}
                    alt=""
                    sx={{
                      height: '40px',
                      width: '40px',
                      backgroundColor: 'white',
                      margin: '0px 0px 0px 12px',
                    }}
                  >
                    <Box sx={{
                      border: '1px solid #EEF0FA',
                      borderRadius: '40px',
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <SvgIcon
                        component={AvatarPlaceholder}
                        sx={{
                          color: 'transparent',
                        }}
                      />
                    </Box>
                  </Avatar>
                </Box>
              )}
            />
          </Box>
          <Controller
            name="title"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                required
                label="Name"
                id="deal-title-field"
                placeholder="Type here"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                onFocus={(e) => {
                  if (!!e.target.value && e.target.value === `${getValues().client} deal`) {
                    e.target.select();
                  }
                }}
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                label={`Value, ${getSymbolFromCurrency(organization.currency ?? DefaultOrgCurrency)}`}
                type="number"
                id="deal-value-field"
                onChange={onChange}
                value={value}
                error={!!error}
                helperText={error ? error.message : null}
                inputProps={{
                  // inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            )}
          />
        </DialogContent>
        <StyledDialogActions
          sx={{
            padding: '12px 24px !important',
            justifyContent: 'space-between !important',
            marginTop: '0px !important',
          }}
        >
          <AppButton
            variant="primary"
            size="l"
            type="submit"
            fullWidth
          >
            Save
          </AppButton>
        </StyledDialogActions>
      </Box>
    </StyledCreateDealDialog>
  );
};

export default EditDealDialog;
