import {
  Box,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AppInput } from '../../../shared/AppInput/AppInput';

interface Props {
  searchFilter: string;
  setSearchFilter: (filter: string) => void;
}

const AdminOrganizationsHeader = ({
  searchFilter,
  setSearchFilter,
}: Props): JSX.Element => {
  const updateSearchFilter = (filter: string) => {
    setSearchFilter(filter);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        marginTop: isMobile ? '20px' : '24px',
        marginBottom: isMobile ? '20px' : '40px',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingX: isMobile ? '5px' : '24px',
        gap: '24px',
      }}
    >
      <AppInput
        fullWidth
        label="Search"
        defaultValue={searchFilter}
        value={searchFilter}
        rows={4}
        onChange={(event: any) => {
          updateSearchFilter(event.target.value);
        }}
      />
    </Box>
  );
};

export default AdminOrganizationsHeader;
