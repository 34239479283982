import { CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppSelector } from '../../hooks/stateHooks';

const DealConfirmBuyerAccountHeader = (): JSX.Element => {
  const process: ProcessDTO = useAppSelector((state) => state.process.process);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      {
        (process?.buyerLogoSrc || process?.sellerLogoSrc)
        && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            '& .SignUpLogoContainer': {
              border: '1px solid #EEF0FA',
              display: 'flex',
              width: '32px',
              height: '32px',
              borderRadius: '9px',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              '& .SignUpLogo': {
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '32px', // width of logo slot
                width: 'auto',
                maxHeight: '32px',
                objectFit: 'scale-down',
                borderRadius: '10px',

              },
            },
          }}
        >
          {
          process?.buyerLogoSrc
          && (
            <Box
              className="SignUpLogoContainer"
            >
              <CardMedia
                className="SignUpLogo"
                component="img"
                image={process?.buyerLogoSrc}
                alt="logo"
              />
            </Box>
          )
        }
          {
          process?.sellerLogoSrc
          && (
            <Box
              className="SignUpLogoContainer"
              sx={{
                marginLeft: process?.buyerLogoSrc ? '-6px' : '0px',
              }}
            >
              <CardMedia
                className="SignUpLogo"
                component="img"
                image={process?.sellerLogoSrc}
                alt="logo"
              />
            </Box>
          )
        }
        </Box>
        )
      }
      {
        process?.summaryTitle
        && (
          <Box>
            <Typography
              sx={{
                color: '#455273',
                fontSize: '20px',
                lineHeight: '26px',
                fontWeight: 600,
                overflowWrap: 'break-word',
                maxWidth: '330px',
              }}
            >
              {process?.summaryTitle}
            </Typography>
          </Box>
        )
      }
    </Box>
  );
};

export default DealConfirmBuyerAccountHeader;
