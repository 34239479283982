import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/system';
import { EMediaFieldStorageType } from '../../../../shared/Field/Field';
import { StageContentType } from '../../../../shared/Stage';
import { useCurrentStage } from '../../../hooks/useCurrentStage';
import MediaResizePlugin from '../MediaResizePlugin';
import IFrameCover from '../../../shared/IFrameCover/IFrameCover';
import { MediaFieldUpdateData } from '../MediaFieldContainer';
import useFrontEventsApi from '../../../hooks/useFrontEventsApi';
import { isGoogleViewer } from '../Helpers/MediaFieldHelpers';
import { selectIsToolbarNotShown } from '../../../routes-old/auth/state/authState';
import { useAppSelector } from '../../../hooks/stateHooks';

interface Props {
  url: string;
  onUpdate: (data: MediaFieldUpdateData) => void;
  width?: number;
  height?: number;
  storageType?: EMediaFieldStorageType;
  name?:string,
}

const MediaFieldIFrameFile = ({
  url,
  onUpdate,
  width: initialWidth,
  height: initialHeight,
  storageType,
  name,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [isActive, setActive] = useState(false);
  const [isInteractionLogged, setInteractionLogged] = useState(false);
  const isToolbarNotShown = useAppSelector(selectIsToolbarNotShown);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const currentStage = useCurrentStage();
  const wideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;

  const { createDocumentInteraction } = useFrontEventsApi();

  const src: string = useMemo(() => {
    if (isGoogleViewer(url)) {
      if (url.startsWith('blob:')) {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`.replace('blob:', '');
      }
      return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
    }
    if (url.startsWith('blob:')) {
      return url.replace('blob:', '');
    }
    return url;
  }, [url]);

  const eventHandler = () => {
    if (!isInteractionLogged) {
      createDocumentInteraction({
        linkUrl: src,
        isPdf: false,
        storageType,
        name,
      });
      setInteractionLogged(true);
    }
  };

  useEffect(() => {
    if (isActive && !isInteractionLogged) {
      window.addEventListener('blur', eventHandler);
    }
    return () => {
      if (isActive) {
        window.removeEventListener('blur', eventHandler);
      }
    };
  }, [isActive, isInteractionLogged]);

  const renderHeight = () => {
    if (wideScreenStage) {
      if (isMobile) return 'calc(100vh - 52px)';
      if (isToolbarNotShown) return '100vh';
      return 'calc(100vh - 48px)';
    }
    if (isMobile) return undefined;
    if (height) return height - 2;
    return '524px';
  };

  const getDefaultWidth = useMemo(() => {
    if (isMobile) {
      return window.screen.width - 32;
    }
    if (width) {
      return `${width}px`;
    }
    if (!width && !!iframeRef.current?.parentElement?.clientWidth) {
      return `${iframeRef.current.parentElement?.clientWidth}px`;
    }
    return 'calc( 100% )';
  }, [width, iframeRef.current]);

  const getMaxWidth = useCallback(() => {
    if (iframeRef.current) {
      return `${iframeRef.current?.parentElement?.clientWidth}px`;
    }
    return 'calc( 100% )';
  }, [iframeRef.current]);

  const renderWidth = () => {
    if (wideScreenStage) {
      return '100%';
    }
    return getDefaultWidth;
  };

  const renderMaxWidth = () => {
    if (wideScreenStage) {
      return isMobile ? '100vw' : '100%';
    }
    return getMaxWidth;
  };

  const parentRef = iframeRef.current?.parentElement;

  const generateIframeSize = useCallback(() => {
    let calcHeight;
    if (
      isMobile
      && wideScreenStage
    ) {
      calcHeight = 'calc( 100% )';
    } else {
      calcHeight = height ?? '100%';
    }
    return {
      width: isMobile ? '100%' : width ?? parentRef?.clientWidth ?? '100%',
      height: isMobile ? '100%' : calcHeight,
      maxWidth: parentRef?.clientWidth,
    };
  }, [width, height, parentRef]);

  return (
    <Box
      sx={{
        height: renderHeight(),
        width: renderWidth() ?? '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: wideScreenStage ? undefined : '10px',
        maxWidth: renderMaxWidth(),
      }}
      ref={iframeRef}
    >
      <IFrameCover
        width={width}
        height={height}
      />
      <iframe
        id="doc-iframe-id"
        title="media-field"
        className={width || height ? 'widht-height' : ''}
        src={src}
        style={{
          userSelect: 'none',
          margin: '0px',
          borderRadius: wideScreenStage ? undefined : '10px',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          width: generateIframeSize().width,
          height: generateIframeSize().height,
          maxWidth: generateIframeSize().maxWidth,
          aspectRatio: isMobile ? '1.52' : undefined,
        }}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      />
      {iframeRef?.current
        && !wideScreenStage
        && !isMobile
        && (
        <MediaResizePlugin
          containerRef={iframeRef.current}
          width={width}
          height={height}
          onUpdate={onUpdate}
          onWidthUpdate={(newWidth) => setWidth(newWidth)}
          onHeightUpdate={(newHeight) => setHeight(newHeight)}
        />
        )}
    </Box>
  );
};

export default MediaFieldIFrameFile;
