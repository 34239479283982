import { IUser } from './user.schema';

export interface IAuthGoogleLoginResponse {
  access_token: string;
  user: IUser;
}

interface ICredentialsBase {
  email: string;
  password: string;
}

export interface ILoginRequest extends ICredentialsBase { }

export interface GuestValidationData {
  processId: string;
  email: string;
  validHashes?: string[];
}

export interface GuestConfirmationData {
  hash: string;
  confirmationCode: string;
  shouldActivate: boolean;
  validHashes?: string[];
}

export interface GuestValidationResponce {
  title: string;
  description: string;
  isValid: boolean;
  shouldActivate: boolean;
  hash?: string;
  newToken?: string;
  isConfirmed?: boolean;
}

export interface ILoginResponse {
  access_token: string;
}

export interface ISignUpRequest extends ICredentialsBase {
  fullName: string;
  deal?: string | null;
  celloReferrer?: string | null;
}

export enum EActivationLinkStatus {
  READY = 'READY',
  ACTIVATED = 'ACTIVATED',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
}

export enum EActivationLinkType {
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_SOCIAL_SIGNUP = 'ORGANIZATION_SOCIAL_SIGNUP',
  ORGANIZATION_APPSUMO = 'ORGANIZATION_APPSUMO',
  PROCESS = 'PROCESS',
  PROCESS_BUYER_SIGNUP = 'PROCESS_BUYER_SIGNUP',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export interface IConfirmAccountRequest extends ICredentialsBase {
  fullName?: string;
  lastName?: string;
  hash: string;
  title?: string;
  type: EActivationLinkType;
  meetingScheduler?: string;
  contactEmail?: string;
  phone?: string;
  joinedDeal?: string | null;
  reason?: string;
}

export interface ConfirmSocialAccountRequest extends Omit<IConfirmAccountRequest, 'password'> {
  password?: string;
}

export interface ResetPasswordConfirmRequest extends ICredentialsBase {
  hash: string;
  type: EActivationLinkType;
}

export interface IShareLoginRequest {
  hash?: string;
  processId?: string;
}

export interface ShareLoginProtectedRequest extends IShareLoginRequest {
  email?: string;
  password?: string;
  accessLinkId?: string;
}

export interface IConfirmProcessAccountRequest extends IConfirmAccountRequest {
  processId: string;
}

export enum ESocialAuthTypes {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  PROCESS_SIGNUP = 'PROCESS_SIGNUP',
}

export enum ESocialAuthProvider {
  google = 'google',
  linkedin = 'linkedin',
}

export interface SocialAuthQuery {
  socialAuthType: ESocialAuthTypes;
  provider: ESocialAuthProvider;
  title?: string;
  fullName?: string;
  email?: string;
  hash?: string;
  celloReferrer?: string;
}

export interface AuthQueryParams {
  email: string | null;
  hash: string | null;
  type: EActivationLinkType;
  fullName: string | null;
  joinedDeal: string | null;
}
