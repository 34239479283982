import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Page } from 'react-pdf';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { ECustomEventsNames } from '../../../../shared/events/CustomEvents';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfScaleValues } from '../../BlockContentSection/BlockContentRowMenu/WideScreenPdfSectionMenu';

interface Props {
  index: number;
  mediaComponentWrapperRef?: HTMLDivElement | undefined
  numPages?: number
  setPdfLoading: (loading: boolean) => void,
}

const setInitialScale = isMobile ? pdfScaleValues[2] : pdfScaleValues[4];

const PdfPageContainer = ({
  index,
  mediaComponentWrapperRef,
  numPages,
  setPdfLoading,
}: Props): JSX.Element | null => {
  const targetRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(setInitialScale.scale / 100);
  const [pixelRatio, setPixelRatio] = useState<number | undefined>(0.5);

  const dispatchScaleForButtonsEvent = (scaleObjectForButtons: {
    id: string,
    scale: number,
    adjust: string
  }) => {
    document.dispatchEvent(new CustomEvent(ECustomEventsNames.PDF_SCALE_FOR_BUTTONS, {
      detail: {
        id: scaleObjectForButtons.id,
        scale: scaleObjectForButtons.scale,
        adjust: scaleObjectForButtons.adjust,
      },
    }));
  };

  const onPageRenderSuccess = () => {
    if (index === numPages) {
      setPdfLoading(false);
    }
  };

  useEffect(() => {
    const onEvent = (e: CustomEvent) => {
      setPdfLoading(true);
      if (e.detail.adjust === 'width') {
        if (mediaComponentWrapperRef?.clientWidth && targetRef?.current?.clientWidth) {
          const containerWidth = mediaComponentWrapperRef?.clientWidth;
          const targetWidth = targetRef?.current?.clientWidth;
          const scaleAdjustedByWidth = scale * (containerWidth / targetWidth);
          setScale(scaleAdjustedByWidth);
          dispatchScaleForButtonsEvent({ id: '', scale: (Math.floor(scaleAdjustedByWidth * 100)), adjust: 'width' });
        }
      } else if (e.detail.adjust === 'height') {
        const scaleAdjustedByHeight = isMobile ? 0.6 : 1;
        const id = isMobile ? '3' : '5';
        setScale(scaleAdjustedByHeight);
        dispatchScaleForButtonsEvent({ id, scale: (scaleAdjustedByHeight * 100), adjust: 'height' });
      } else if (e.detail.adjust === 'default') {
        setScale(e.detail.scale / 100);
        setPixelRatio(0.5);
        dispatchScaleForButtonsEvent({ id: e.detail.id, scale: e.detail.scale, adjust: e.detail.adjust });
      }
    };
    document.addEventListener(ECustomEventsNames.PDF_SCALE, onEvent as EventListener);
    return () => {
      document.removeEventListener(ECustomEventsNames.PDF_SCALE, onEvent as EventListener);
    };
  }, []);

  let observer: IntersectionObserver | undefined;

  useLayoutEffect(() => {
    if (pageRef.current && !observer) {
      observer = new IntersectionObserver((ent) => {
        if (ent[0].isIntersecting) {
          setPixelRatio(undefined);
          observer!.unobserve(ent[0].target);
        }
      });
      observer.observe(pageRef.current!);
    }
    return () => {
      if (pageRef.current && observer) {
        observer.disconnect();
      }
    };
  }, [pageRef, observer, scale]);

  return (
    <Box
      ref={pageRef}
      sx={{
        width: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Page
        inputRef={targetRef}
        scale={scale}
        pageNumber={index}
        renderTextLayer
        renderAnnotationLayer
        devicePixelRatio={pixelRatio}
        onRenderSuccess={onPageRenderSuccess}
      />
    </Box>
  );
};

export default PdfPageContainer;
