import { Box } from '@mui/material';
import SquaredLogoPlaceholder from './SquaredLogoPlaceholder';
import { AppAvatar } from '../../shared/AppAvatar';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectProcessOrgSquaredLogo } from '../../routes-old/process/state/processSlice';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import SquaredLogoPlaceholderIcon from '../../icons/SquaredLogoPlaceholderIcon';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { EJWTTokenType } from '../../../shared/schema/token.schema';
import useDealPermissions from '../../hooks/useDealPermissions';

const SquaredLogoPanel = (): JSX.Element | null => {
  const processOrgSquaredLogo = useAppSelector(selectProcessOrgSquaredLogo);
  const [orgPermissions] = useUserOrganizationPermissions();
  const isOrgManagePermited = orgPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);
  const tokenType = useAppSelector((state) => state.auth.tokenType);
  const registeredUser = tokenType !== EJWTTokenType.SHARE && tokenType !== EJWTTokenType.GUEST_AUTH;
  const [checkPermissions] = useDealPermissions();
  const isLayoutEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_LAYOUT_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );

  return (
    <Box>
      {
          !processOrgSquaredLogo
            ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '40px',
                  height: '40px',
                  borderRadius: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: (theme) => `1px solid ${greySidebarContrastText(theme, 0.1)}`,
                }}
              >
                {isOrgManagePermited && registeredUser && isLayoutEditable
                  ? <SquaredLogoPlaceholder />
                  : <SquaredLogoPlaceholderIcon />}
              </Box>
            )
            : (
              <AppAvatar
                src={processOrgSquaredLogo}
                sx={{
                  borderRadius: '10px',
                  border: (theme) => `1px solid ${greySidebarContrastText(theme, 0.1)}`,
                }}
              />
            )
        }
    </Box>
  );
};

export default SquaredLogoPanel;
