import { Box, Popover, Typography } from '@mui/material';
import { useState, useRef } from 'react';
import { RGBColor } from 'react-color';
import BackgroundColorPicker from './BackgroundColorPicker';
import { rgbaObjToString } from './helpers/DealCustomizationMenuHelpers';
import { AppDashedBorderBox } from '../../shared/AppDashedBorderBox/AppDashedBorderBox';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { ReplaceIcon20 } from '../../icons/ReplaceIcon';

interface Props {
  defaultColor?: string;
  initialColor?: string;
  onColorUpdate: (color: string) => void;
  currentColor?: string;
}

const BackgroundColorLayerSelector = ({
  currentColor,
  initialColor = stagesetColors.white[100],
  onColorUpdate,
  defaultColor = stagesetColors.white[100],
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [color, setColor] = useState<string>(initialColor);
  const colorSelectionLayerRef = useRef<HTMLDivElement | null>(null);
  const colorRef = useRef(initialColor);

  const handleOpen = () => {
    setAnchorEl(colorSelectionLayerRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setColor(colorRef.current);
    onColorUpdate(colorRef.current);
  };

  const onColorChange = (newColor: string | RGBColor) => {
    colorRef.current = typeof newColor === 'string' ? newColor : rgbaObjToString(newColor);
  };

  const onColorReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onColorUpdate(defaultColor);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 500,
        }}
      >
        Background
      </Typography>
      <AppDashedBorderBox />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          alignItems: 'center',
        }}
      >
        {
          initialColor !== color
            && (
              <AppIconButton
                variant="lite-primary"
                onClick={onColorReset}
                icon={<ReplaceIcon20 />}
              />
            )
        }
        <Box
          onClick={handleOpen}
          ref={colorSelectionLayerRef}
          sx={{
            width: '24px',
            minWidth: '24px',
            height: '24px',
            minHeight: '24px',
            border: '1px solid',
            borderRadius: '50%',
            borderColor: stagesetColors.newGrey[300],
            backgroundColor: currentColor || color,
            cursor: 'pointer',
          }}
        />
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '4px',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: stagesetColors.newGrey[300],
              background: stagesetColors.white[100],
              // eslint-disable-next-line max-len
              boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), 0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
            },
          },
        }}
      >
        <BackgroundColorPicker
          initialColor={color}
          onColorConfirm={onColorChange}
        />
      </Popover>
    </Box>
  );
};

export default BackgroundColorLayerSelector;
