import { SvgIcon, Box } from '@mui/material';
import { useState } from 'react';
import { MediaSource, mediaSources } from '../MediaField/Helpers/MediaFieldHelpers';
import LinkIcon from '../../icons/link20.svg';
import { ContentType } from '../../../shared/Stage';

type Props = {
  contentType?: ContentType;
};

export const DocumentLinkIcon = ({ contentType }: Props) => {
  const [sourceVariants] = useState<MediaSource[]>(mediaSources);

  const getIcon = () => {
    if (!contentType) {
      return <LinkIcon />;
    }
    const item = sourceVariants.find((x) => x.contentTag === contentType);
    if (!item || !item.icon) {
      return <LinkIcon />;
    }
    return item.icon;
  };

  return (
    <Box
      className="docIconContainer"
      sx={{
        width: '20px',
        height: '20px',
        position: 'relative',
      }}
    >
      {getIcon()}
    </Box>
  );
};
