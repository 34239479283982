import {
  Box, SwipeableDrawer, Tabs, styled,
} from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const AppMobileBottomDrawer = styled(SwipeableDrawer)(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  '& .MuiPaper-root': {
    backgroundColor: stagesetColors.white[100],
    borderRadius: '8px 8px 0px 0px',
    boxShadow: '0px -3px 2px 0px rgba(30, 41, 59, 0.04),'
      + '0px -1px 1px 0px rgba(30, 41, 59, 0.07),'
      + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
      + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  },
}));

export const MobileBottomDrawerPuller = styled(Box)(() => ({
  width: '40px',
  height: '4px',
  backgroundColor: stagesetColors.newGrey[200],
  borderRadius: '2px',
  position: 'absolute',
  top: '8px',
  left: 'calc(50% - 15px)',
}));

export const PullerWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '16px',
}));

export const MobileBottomDrawerTabs = styled(Tabs)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));
