import { ApiService } from '../core/api';

export class HubspotAuthApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('hubspot-auth');
  }

  async startAuthSession(): Promise<void> {
    const authUrl = await this.api.get<string>('/');
    window.location.href = authUrl;
  }
}
