import { useSnackbar } from 'notistack';

const maxFileSize = 1024 * 1024; // 1mb

export const useFileHelpers = () => {
  const { enqueueSnackbar } = useSnackbar();

  const validateFile = (file?: File): boolean => {
    if (!file) return false;
    if (file.size > maxFileSize) {
      enqueueSnackbar('Max file size is 1mb', { variant: 'warning' });
      return false;
    }
    return true;
  };

  return {
    validateFile,
  };
};
