import { SvgIcon } from '@mui/material';
import ContactRow from '../ContactRow';
import MailContactIcon from '../../../icons/MailContact.svg';

type Props = {
  email: string
};

export const MailContact = ({ email }: Props): JSX.Element => (
  <ContactRow
    text={email}
    icon={(
      <SvgIcon
        viewBox="0 0 20 20"
        component={MailContactIcon}
        sx={{
          color: 'transparent',
        }}
      />
)}
    link={`mailto:${email}`}
  />
);

export default MailContact;
