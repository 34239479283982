import { Box, Typography } from '@mui/material';

interface Props {
  onClick: () => void;
  icon?: JSX.Element;
  text?: string;
  width?: string;
}

const WhiteIconButton = ({
  onClick,
  icon,
  text,
  width,
}: Props): JSX.Element => {
  const qwe = 123;
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        boxShadow: '0px 0px 6px 0px rgb(0 57 96 / 10%)',
        borderRadius: '8px',
        height: '40px',
        aspectRatio: text || width ? 'unset' : '1/1',
        width: width || 'auto',
        gap: '8px',
        cursor: 'pointer',
      }}
    >
      {
        icon
        && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </Box>
        )
      }
      {
        text
        && (
          <Typography
            sx={{
              color: '#1A1929',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 600,
            }}
          >
            {text}
          </Typography>
        )
      }
    </Box>
  );
};

export default WhiteIconButton;
