import { Box } from '@mui/material';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { NavLink } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { DealHealth } from '../../shared/DealHealth/DealHealth';
import { stagesetColors } from '../../theme/stagesetPalette';
import { ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { AppAvatar } from '../../shared/AppAvatar';
import { EAppRoutes } from '../../core/router';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectOrganization } from '../../core/store/appState/appState';
import { DefaultOrgCurrency } from '../../../shared/OrganizationDTO';

type Props = {
  process: ProcessDTO;
  draggable?: boolean;
};

export const DealBoardCard = ({ process, draggable }: Props) => {
  const {
    attributes, listeners, setNodeRef, transform,
  } = useDraggable({
    id: process.id,
    data: process,
  });

  const organization = useAppSelector(selectOrganization);
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <Box
      id={process.id.toString()}
      className="column-card"
      sx={{
        userSelect: 'none',
        transform: style.transform,
        borderRadius: '12px',
        border: (theme) => `1px solid ${theme.palette.grey[100]}`,
        background: draggable ? stagesetColors.grey[100] : stagesetColors.background[200],
        boxShadow: `0px 0px 1px 1px rgba(0, 113, 190, 0.07),
                    0px 0px 2px 3px rgba(0, 113, 190, 0.04),
                    0px 0px 1px 0px rgba(0, 113, 190, 0.08)`,

        '& .section': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        '& .bottom-section': {
          gap: '5px',
        },
        '& .top-section': {
          gap: '12px',
        },
        '& .logo': {
          display: 'flex',
        },
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <NavLink
        to={`${EAppRoutes.deal}/${process.id}`}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          cursor: 'inherit',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          padding: '12px',
        }}
        >
          <Box className="top-section section">
            <Box className="logo">
              <AppAvatar src={process.buyerLogoSrc} />
            </Box>
            <Box className="company-name">
              { process.title }
            </Box>
          </Box>
          <Box className="bottom-section section">
            <Box className="value">
              {getSymbolFromCurrency(organization.currency || DefaultOrgCurrency)}
              &nbsp;
              {process.value}
            </Box>
            <Box>
              •
            </Box>
            <Box className="health">
              <DealHealth lastActive={process.lastActive} />
            </Box>
          </Box>
        </Box>
      </NavLink>

    </Box>
  );
};
