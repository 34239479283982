import { EFileEditType, EFileType, ELibraryType } from '../../../../shared/library/Library';
import { FileWithOwnerDTO, ProductWithOwnerDTO } from '../../../../shared/library/LibraryDTO';

export enum EFileGridColumns {
  name = 'name',
  owner = 'owner',
  updated = 'updated',
  menu = 'menu',
  currency = 'currency',
  model = 'model',
}

export enum ELibraryFeedTab {
  NONE = 'NONE',
  PRODUCT = 'PRODUCT',
}

export interface EditFileItemPayload {
  open: boolean;
  item?: FileWithOwnerDTO;
  editType?: EFileEditType;
}

export interface EditProductItemPayload {
  open: boolean;
  type: ELibraryFeedTab;
  item?: ProductWithOwnerDTO;
}

export function libraryTypeToItemDescription(type: ELibraryType, subType: EFileType | undefined): string {
  switch (type) {
    case ELibraryType.FILE:
      if (subType === EFileType.FILE) {
        return 'File';
      }
      return 'Link';
    case ELibraryType.PRODUCT:
      return 'Product';
    default:
      return 'Library item';
  }
}
