import { SvgIcon, SvgIconProps } from '@mui/material';

export const SettingsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_7817_42396)">
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5
       8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5
        10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        color="inherit"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.1673 12.5002C16.0564 12.7515
       16.0233 13.0303 16.0723 13.3007C16.1213
       13.571 16.2502 13.8204 16.4423 14.0168L16.4923
        14.0668C16.6473 14.2216 16.7702 14.4054 16.8541
         14.6078C16.938 14.8101 16.9811 15.027 16.9811
          15.246C16.9811 15.465 16.938 15.6819 16.8541
           15.8842C16.7702 16.0866 16.6473 16.2704 16.4923
            16.4252C16.3375 16.5801 16.1537 16.7031 15.9514
             16.7869C15.7491 16.8708 15.5322 16.914 15.3131
              16.914C15.0941 16.914 14.8772 16.8708 14.6749
              16.7869C14.4726 16.7031 14.2888 16.5801 14.134
               16.4252L14.084 16.3752C13.8876 16.183 13.6381
                16.0542 13.3678 16.0052C13.0975 15.9561 12.8187
                 15.9892 12.5673 16.1002C12.3208 16.2058 12.1106
                  16.3812 11.9626 16.6048C11.8145 16.8283 11.7351
                   17.0903 11.734 17.3585V17.5002C11.734 17.9422
                    11.5584 18.3661 11.2458 18.6787C10.9333 18.9912
                     10.5093 19.1668 10.0673 19.1668C9.62529
                      19.1668 9.20137 18.9912 8.88881
                       18.6787C8.57625 18.3661 8.40065
                        17.9422 8.40065 17.5002V17.4252C8.3942
                         17.1493 8.30492 16.8818 8.14441
                          16.6574C7.9839 16.433 7.7596 16.2621
                           7.50065 16.1668C7.2493 16.0559 6.97049
                            16.0228 6.70016 16.0718C6.42983 16.1208
                             6.18038 16.2497 5.98398 16.4418L5.93398
                              16.4918C5.7792 16.6468 5.59538 16.7697
                               5.39305 16.8536C5.19072 16.9375 4.97384
                                16.9806 4.75482 16.9806C4.53579 16.9806
                                4.31891 16.9375 4.11658 16.8536C3.91425
                                 16.7697 3.73044 16.6468 3.57565 16.4918C3.42069
                                  16.337 3.29776 16.1532 3.21388 15.9509C3.13001
                                   15.7486 3.08684 15.5317 3.08684 15.3127C3.08684
                                    15.0936 3.13001 14.8768 3.21388 14.6744C3.29776
                                    14.4721 3.42069 14.2883 3.57565 14.1335L3.62565
                                     14.0835C3.81776 13.8871 3.94664 13.6376 3.99565
                                     13.3673C4.04467 13.097 4.01158 12.8182 3.90065
                                     12.5668C3.79501 12.3204 3.61961 12.1101 3.39604
                                     11.9621C3.17246 11.814 2.91047 11.7346 2.64232
                                     11.7335H2.50065C2.05862 11.7335 1.6347 11.5579
                                     1.32214 11.2453C1.00958 10.9328 0.833984 10.5089 0.833984
                                      10.0668C0.833984 9.6248 1.00958 9.20088 1.32214 8.88832C1.6347
                                       8.57576 2.05862 8.40016 2.50065 8.40016H2.57565C2.85148
                                        8.39371 3.11899 8.30443 3.3434 8.14392C3.56781 7.98341
                                         3.73875 7.75911 3.83398 7.50016C3.94491 7.24882 3.978
                                         6.97 3.92899 6.69967C3.87997 6.42934 3.7511 6.17989
                                         3.55898 5.9835L3.50898 5.9335C3.35402 5.77871 3.23109
                                          5.59489 3.14722 5.39256C3.06334 5.19023 3.02017 4.97335
                                           3.02017 4.75433C3.02017 4.5353 3.06334 4.31843 3.14722
                                           4.1161C3.23109 3.91377 3.35402 3.72995 3.50898 3.57516C3.66377
                                            3.4202 3.84759 3.29727 4.04992 3.2134C4.25225 3.12952 4.46912
                                            3.08635 4.68815 3.08635C4.90718 3.08635 5.12405 3.12952 5.32638
                                            3.2134C5.52871 3.29727 5.71253 3.4202 5.86732 3.57516L5.91732
                                            3.62516C6.11372 3.81728 6.36316 3.94615 6.63349 3.99517C6.90382
                                            4.04418 7.18264 4.01109 7.43398 3.90016H7.50065C7.74712 3.79453
                                             7.95733 3.61913 8.10539 3.39555C8.25346 3.17198 8.33291 2.90998
                                             8.33398 2.64183V2.50016C8.33398 2.05814 8.50958 1.63421 8.82214
                                             1.32165C9.1347 1.00909 9.55862 0.833496 10.0007 0.833496C10.4427
                                             0.833496 10.8666 1.00909 11.1792 1.32165C11.4917 1.63421 11.6673
                                             2.05814 11.6673 2.50016V2.57516C11.6684 2.84332 11.7478 3.10531
                                             11.8959 3.32888C12.044 3.55246 12.2542 3.72786 12.5006
                                             3.8335C12.752 3.94443 13.0308 3.97752 13.3011 3.9285C13.5715
                                             3.87948 13.8209 3.75061 14.0173 3.5585L14.0673 3.5085C14.2221
                                              3.35354 14.4059 3.2306 14.6082 3.14673C14.8106 3.06286 15.0275
                                               3.01968 15.2465 3.01968C15.4655 3.01968 15.6824 3.06286
                                                15.8847 3.14673C16.087 3.2306 16.2709 3.35354 16.4256
                                                3.5085C16.5806 3.66328 16.7035 3.8471 16.7874 4.04943C16.8713
                                                 4.25176 16.9145 4.46864 16.9145 4.68766C16.9145 4.90669
                                                  16.8713 5.12357 16.7874 5.3259C16.7035 5.52823 16.5806
                                                  5.71204 16.4256 5.86683L16.3756 5.91683C16.1835 6.11323
                                                   16.0547 6.36268 16.0056 6.633C15.9566 6.90333 15.9897
                                                   7.18215 16.1006 7.4335V7.50016C16.2063 7.74664 16.3817
                                                    7.95684 16.6053 8.10491C16.8288 8.25297 17.0908 8.33243
                                                     17.359 8.3335H17.5006C17.9427 8.3335 18.3666 8.50909
                                                      18.6792 8.82165C18.9917 9.13421 19.1673 9.55813
                                                       19.1673 10.0002C19.1673 10.4422 18.9917 10.8661
                                                       18.6792 11.1787C18.3666 11.4912 17.9427 11.6668
                                                       17.5006 11.6668H17.4256C17.1575 11.6679 16.8955
                                                       11.7474 16.6719 11.8954C16.4484 12.0435 16.273
                                                        12.2537 16.1673 12.5002V12.5002Z"
        color="inherit"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_7817_42396">
        <rect width="20" height="20" fill="none" />
      </clipPath>
    </defs>
  </SvgIcon>
);
