import classNames from 'classnames';
import { AppBaseButton, AppBaseButtonProps } from './AppBaseButton';

type AppLinkButtonSize = 'xs' | 's' | 'm' | 'l';

export type AppLinkButtonProps = {
  size?: AppLinkButtonSize,
} & Omit<AppBaseButtonProps, 'size'>;

export const AppLinkButton = ({
  className,
  ...buttonProps
}: AppLinkButtonProps) => (
  <AppBaseButton {...buttonProps} className={classNames('link-button', className)} />
);
