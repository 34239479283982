import { Box } from '@mui/material';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectProcessLastActive } from '../../routes-old/process/state/processSlice';
import { DealHealth } from '../../shared/DealHealth/DealHealth';

const ProcessHealthDisplay = () => {
  const lastActive = useAppSelector(selectProcessLastActive);

  return (
    <Box className="row">
      <Box className="title">Health</Box>
      <Box className="value">
        <DealHealth lastActive={lastActive} />
      </Box>
    </Box>
  );
};

export default ProcessHealthDisplay;
