export enum ESubscriptionLimits {
  TEAM_CAN_MANAGE = 'TEAM_CAN_MANAGE',
  SELLER_DEAL_BOARD = 'SELLER_DEAL_BOARD',
  SELLER_FILE_MANAGEMENT = 'SELLER_FILE_MANAGEMENT',
}

export enum ESubscriptionType {
  INTERNAL = 'INTERNAL',
  SELLER = 'SELLER',
  BUYER = 'BUYER',
  SELLER_BASIC = 'SELLER_BASIC',
  SELLER_PREMIUM = 'SELLER_PREMIUM',
  SELLER_APPSUMO_TIER_1 = 'SELLER_APPSUMO_TIER_1',
  SELLER_APPSUMO_TIER_2 = 'SELLER_APPSUMO_TIER_2',
  SELLER_APPSUMO_TIER_3 = 'SELLER_APPSUMO_TIER_3',
}

export const sellerSubscriptions = [
  ESubscriptionType.SELLER,
  ESubscriptionType.SELLER_BASIC,
  ESubscriptionType.SELLER_PREMIUM,
  ESubscriptionType.SELLER_APPSUMO_TIER_1,
  ESubscriptionType.SELLER_APPSUMO_TIER_2,
  ESubscriptionType.SELLER_APPSUMO_TIER_3,
]

export const premiumSubscriptionTypes = [ESubscriptionType.SELLER_PREMIUM, ESubscriptionType.SELLER_APPSUMO_TIER_2, ESubscriptionType.SELLER_APPSUMO_TIER_3]

export const basicSubscriptionTypes = [ESubscriptionType.SELLER_APPSUMO_TIER_1, ESubscriptionType.SELLER_BASIC]

export const appSumoSubscriptionTypes = [ESubscriptionType.SELLER_APPSUMO_TIER_1, ESubscriptionType.SELLER_APPSUMO_TIER_2, ESubscriptionType.SELLER_APPSUMO_TIER_3]

export enum ESubscriptionLimitStatus {
  ACTIVE = 'ACTIVE',
  HIDDEN = 'HIDDEN',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ESubscriptionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
  DISABLED = 'DISABLED'
}

export interface SubscriptionLimits {
  [ESubscriptionLimits.TEAM_CAN_MANAGE]: ESubscriptionLimitStatus,
  [ESubscriptionLimits.SELLER_DEAL_BOARD]: ESubscriptionLimitStatus,
  [ESubscriptionLimits.SELLER_FILE_MANAGEMENT]: ESubscriptionLimitStatus,
}

export class SubscriptionDTO {
  id: string;
  limits: SubscriptionLimits;
  title: string;
  status: ESubscriptionStatus;
  type: ESubscriptionType;
  appSumoPlan: string;
}

export interface StripeInvoice {
  id: string;
  nextPaymentMS: number;
  periodEndMS: number;
  periodStartMS: number;
  total: number;
  currency: string;
  currentPrice: string;
  endAt?: number;
  credit?: number;
}

export enum SubscriptionEventName {
  ACTIVATE = 'ACTIVATE',
  RESUME = 'RESUME',
  CANCEL = 'CANCEL',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
}

export interface SubscriptionEventDTO {
  organizationId: string;
  eventName: SubscriptionEventName;
  newStatus?: ESubscriptionStatus;
  oldStatus?: ESubscriptionStatus;
  newSubscriptionType?: ESubscriptionType;
  oldSubscriptionType?: ESubscriptionType;
  customerId?: string;
  oldSubscriptionId?: string;
  newSubscriptionId?: string;
  newDocId?: string;
  oldDocId?: string;
}
