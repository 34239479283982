import { memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SettingsMenuItem, settingsMenuItems } from '../../core/menu/settingsMenu';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { ESettingsTabs, selectSettingsTab, setSettingsTab } from '../Layout/lib/fieldsLayoutSlice';
import { SettingsTab, SettingsTabs } from './SettingsMenuStyles';
import { selectIsSubscriptionBasic, selectIsSubscriptionPremium } from '../../routes-old/Billing/state/billingSlice';
import { ESubscriptionType } from '../../../shared/Subscription';
import { selectOrganization } from '../../core/store/appState/appState';

const SettingsMenu = () => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(selectSettingsTab);
  const isBasic = useAppSelector(selectIsSubscriptionBasic);
  const isPremium = useAppSelector(selectIsSubscriptionPremium);
  const organization = useAppSelector(selectOrganization);
  const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: ESettingsTabs) => {
    dispatch(setSettingsTab(newValue));
    sessionStorage.setItem('settings-tab', newValue);
  };

  useEffect(() => {
    if (!sessionStorage.getItem('settings-tab')) return;
    const currentSessionTab = sessionStorage.getItem('settings-tab') as ESettingsTabs;
    dispatch(setSettingsTab(currentSessionTab));
  }, [currentTab]);

  const isAvailable = (item: ESubscriptionType) => {
    if (isPremium) return false;
    if (isBasic) {
      switch (item) {
        case ESubscriptionType.SELLER_PREMIUM:
          return true;
        case ESubscriptionType.SELLER_BASIC:
          return false;
        default:
          return false;
      }
    }
    if (!isPremium) {
      switch (item) {
        case ESubscriptionType.SELLER_PREMIUM:
          return true;
        case ESubscriptionType.SELLER_BASIC:
          return true;
        default:
          return false;
      }
    } return true;
  };

  const filterAvailableItems = (item: SettingsMenuItem) => {
    if (item.requirePublicApiEnabled) {
      return organization.isPublicApiEnabled;
    }

    return true;
  };

  const items = settingsMenuItems.filter((x) => filterAvailableItems(x)).map((item) => (
    <SettingsTab
      className={classNames(
        { 'settings-tab-desktop': !isMobile },
        { 'settings-tab-mobile': isMobile },
      )}
      disableRipple
      key={item.text}
      label={t(item.text)}
      value={item.settingsTab}
      disabled={isAvailable(item.subscription)}
    />
  ));
    // useMemo(() => {

  // }, [currentTab]);
  return (
    <SettingsTabs
      className={classNames({ 'settings-tabs-mobile': isMobile })}
      variant="scrollable"
      scrollButtons={isMobile ? 'auto' : false}
      orientation={isMobile ? 'horizontal' : 'vertical'}
      value={currentTab}
      onChange={handleChange}
      aria-label={isMobile ? 'mobile settings tabs' : 'desktop settings tabs'}
    >
      { items }
    </SettingsTabs>
  );
};

export default memo(SettingsMenu);
