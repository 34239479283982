import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { UserDTO } from '../../../../../../shared/UserDTO';
import { AppAvatar } from '../../../../../shared/AppAvatar';
import { ChevronDown16 } from '../../../../../icons/ChevronDown';
import { ChevronUp16 } from '../../../../../icons/ChevronUp';
import { AppIconButton } from '../../../../../shared/AppButton/AppIconButton';
import MoreVert from '../../../../../icons/MoreVertical.svg';
import { AppDropdown } from '../../../../../shared/AppDropdown/AppDropdown';
import Trash from '../../../../../icons/Trash.svg';
import { adminDeleteUser } from '../../../state/adminSlice';
import { useAppDispatch } from '../../../../../hooks/stateHooks';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
import NormalizedSvgIcon from '../../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import AlertTriangle from '../../../../../icons/AlertTriangle.svg';
import DeleteTypography from '../../../../../common/DeleteTypography';

interface Props {
  user: UserDTO;
  isExpanded: boolean;
  organizationId: string;
  isOwner?: boolean;
  setExpanded: (expanded: string) => void;
}

const UserDetailsItemHeader = ({
  user,
  isExpanded,
  organizationId,
  isOwner,
  setExpanded,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [userObject, setUserObject] = useState<UserDTO>(user);
  const myButtonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteUserConfirmationDialogOpen, setDeleteUserConfirmationDialogOpen] = useState<boolean>(false);
  const [value, setValue] = useState('delete');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setDeleteUserConfirmationDialogOpen(true);
    setAnchorEl(null);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(myButtonRef?.current);
  };

  const selectUser = () => {
    setExpanded(userObject.id);
  };

  const onDeleteConfirm = (): void => {
    setDeleteUserConfirmationDialogOpen(false);
    dispatch(adminDeleteUser({ userId: user.id, organizationId, type: value }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="delete" control={<Radio />} label="Delete permanently" />
          <FormControlLabel value="disable" control={<Radio />} label="Delete from Organization" />
        </RadioGroup>
      </FormControl>
      <Box>
        <b>
          {' '}
          {user.name}
          {' '}
        </b>
        ?
      </Box>
    </DeleteTypography>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '12px 0',
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            background: isExpanded ? (theme) => theme.palette.newGrey[100] : 'white',
            padding: '4px',
            borderRadius: '10px',
            marginRight: '12px',
          }}
          onClick={selectUser}
        >
          <Box>
            {userObject.avatarSrc && userObject.name && (
              <AppAvatar name={userObject.name} src={userObject.avatarSrc} colorId={userObject.colorId} />
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              padding: '8px 0 0 8px',
            }}
          >
            <Typography>
              {userObject.name}
            </Typography>
          </Box>
          <Box>
            {!isExpanded
              ? (
                <ChevronDown16 />
              )
              : <ChevronUp16 />}
          </Box>
        </Box>
        {!isOwner && (
          <Box
            sx={{
              background: isExpanded ? (theme) => theme.palette.newGrey[100] : 'white',
              padding: '8px 4px 4px 4px',
              borderRadius: '10px',
            }}
            ref={myButtonRef}
          >
            <AppIconButton
              id="admin-action-more-menu-button"
              onClick={handleButtonClick}
              icon={<MoreVert />}
            />
          </Box>
        )}
      </Box>
      <AppDropdown
        id="user-menu"
        items={[
          {
            label: 'Delete',
            onClick: handleDeleteClick,
            startIcon: <Trash />,
          },
        ]}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        disableAutoFocus
        disableAutoFocusItem
        lastElementHasAnotherColor
      />
      <ConfirmationDialog
        isDialogOpen={isDeleteUserConfirmationDialogOpen}
        onCancel={() => setDeleteUserConfirmationDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Delete"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </>
  );
};

export default UserDetailsItemHeader;
