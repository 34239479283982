import { SvgIcon } from '@mui/material';

export const DeleTeIcon = () => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7898 3.85996L2.31978 18C2.14514 18.3024
 2.05274 18.6453 2.05177 18.9945C2.05079 19.3437 2.14127
  19.6871 2.3142 19.9905C2.48714 20.2939 2.7365 20.5467
   3.03748 20.7238C3.33847 20.9009 3.68058 20.9961 4.02978
    21H20.9698C21.319 20.9961 21.6611 20.9009 21.9621
     20.7238C22.2631 20.5467 22.5124 20.2939 22.6854
      19.9905C22.8583 19.6871 22.9488 19.3437 22.9478
      18.9945C22.9468 18.6453 22.8544 18.3024 22.6798
       18L14.2098 3.85996C14.0315 3.56607 13.7805 3.32308
        13.481 3.15444C13.1814 2.98581 12.8435 2.89722
        12.4998 2.89722C12.156 2.89722 11.8181 2.98581
         11.5186 3.15444C11.2191 3.32308 10.968 3.56607
         10.7898 3.85996V3.85996Z"
      stroke="#C94937"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.5 9V13"
      stroke="#C94937"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.5 17H12.51"
      stroke="#C94937"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
