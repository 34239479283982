import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { CopyLinkIconSq } from '../../../icons/CopyLinkIcon';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { useAppSelector } from '../../../hooks/stateHooks';
import {
  selectAccessLink,
} from '../../../routes-old/process/state/processSlice';
import { useTranslation } from 'react-i18next';

const CopyLinkButton = (): JSX.Element => {
  const accessLink = useAppSelector(selectAccessLink);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  let copyLinkSnackbarId: SnackbarKey | undefined;
  const onCopyClick = () => {
    navigator.clipboard.writeText(accessLink.url);
    if (copyLinkSnackbarId) {
      closeSnackbar(copyLinkSnackbarId);
      copyLinkSnackbarId = undefined;
    }
    copyLinkSnackbarId = enqueueSnackbar(
      t('Room_Link_copied'),
      {
        variant: 'success',
      },
    );
  };

  useEffect(() => () => {
    if (copyLinkSnackbarId) {
      closeSnackbar(copyLinkSnackbarId);
      copyLinkSnackbarId = undefined;
    }
  }, []);

  return (
    <AppButton
      variant="primary"
      onClick={() => onCopyClick()}
      startIcon={<CopyLinkIconSq />}
      size="l"
      fullWidth
    >
      {t('Room_Copy_link')}
    </AppButton>
  );
};

export default CopyLinkButton;
