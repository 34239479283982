import {
  Box, Link, Typography,
} from '@mui/material';
import {
  ChangeEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { DocumentIcon } from '../../features/DocumentsField/DocumentIcon';
import { AppLoader } from '../AppLoader/AppLoader';
import { useFileItemSelect } from '../../features/FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { FileEventData } from '../../../shared/FileItems/FileItems';
import { DnDIcon16 } from '../../features/Tiptap/icons/DnDIcon';
import TrashIcon from '../../icons/TrashIcon';
import { AppDropdown } from '../AppDropdown/AppDropdown';
import { PenIcon20 } from '../../icons/PenIcon';
import {
  AgendaFileItemWrapper,
  AgendaItemContainer,
  AgendaItemControlsWrapper,
} from '../../routes-old/process/DealFeed/Agenda/styles/AgendaStyles';
import { ReplaceIcon20 } from '../../icons/ReplaceIcon';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppInput } from '../AppInput/AppInput';
import { getSubtypeByMIME, mediaFieldFormats, mediaFieldMimes } from '../../features/MediaField/Helpers/MediaFieldHelpers';
import { EMediaFieldStorageType } from '../../../shared/Field/Field';
import { AppIconButton } from '../AppButton/AppIconButton';
import useCurrentItemUpdate from '../../hooks/useProcessItemUpdate';
import useBlockUpdateEvent from '../../features/Tiptap/Hooks/useBlockUpdate';
import { selectFeedAgenda } from '../../features/DealFeed/lib/feedSlice';

interface Props {
  id: string;
  onFileDelete: (doc: FileEventData) => void;
  uploading?: boolean;
  onDownload?: (doc: FileEventData) => void;
}

const convertSize = (size: number): string => {
  if (size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} Mb`;
  }
  return `${(size / 1000).toFixed(2)}Kb`;
};

const MAX_SIZE = 1024 * 1024 * 50;

const FileDisplay = ({
  id,
  onFileDelete,
  uploading,
  onDownload,
}: Props): JSX.Element | null => {
  // ////file replace////
  // const fileInputRef = useRef<HTMLInputElement>(null);
  // ////file replace////
  const inputRef = useRef<HTMLTextAreaElement>();
  const file = useFileItemSelect(id);

  const fileContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formattedFileName, setFormattedFileName] = useState<string>('');
  const [fileNameEdit, setFileNameEdit] = useState<boolean>(false);
  const { updateCurrentItemFile } = useCurrentItemUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const inputAccepts = mediaFieldMimes(mediaFieldFormats);

  useEffect(() => {
    if (
      file
      && formattedFileName !== file.name.replaceAll('_', ' ')
    ) {
      setFormattedFileName(file.name.replaceAll('_', ' '));
    }
  }, [file]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
  });

  if (!file) {
    return null;
  }

  const { name, size, url } = file;

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(fileContainerRef.current);
  };

  const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onDelete = () => {
    if (!uploading) {
      onFileDelete({ ...file, docId: id });
      setAnchorEl(null);
    }
  };

  const onFileNameEditStart = () => {
    setFileNameEdit(true);
    setAnchorEl(null);
    setTimeout(() => inputRef?.current?.select(), 10);
  };

  const onSubmit = () => {
    if (!uploading) {
      setFileNameEdit(false);
      updateCurrentItemFile({ [id]: { ...file, name: formattedFileName } });
    }
  };

  // ////file replace////
  // const onReplaceMedia = () => {
  //   if (fileInputRef?.current) fileInputRef.current.click();
  //   setAnchorEl(null);
  // };

  // const handleMediaFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
  //   // onUpload(file, {fieldId: agenda.blockId, docId: currentFileId})
  //   if (!event?.target?.files?.[0]) return;
  //   if (event?.target?.files?.[0].size > MAX_SIZE) {
  //     enqueueSnackbar('File must be smaller than 50mb!', { variant: 'error' });
  //   } else {
  //     const fileBody = event?.target?.files?.[0];
  //     const fileName = event?.target?.files?.[0].name;
  //     try {
  //       updateCurrentItemFile({ [id]: { ...fileBody, name: fileName } });
  //     } catch (e) {
  //       console.log('error occurred file uploading process');
  //     }
  //   }
  // };
  // ////file replace////

  return (
    <AgendaItemContainer
      ref={setNodeRef}
      className={classNames('agenda-file-container', { 'agenda-items-container-mobile': isMobile })}
      sx={{
        transition,
        transform: CSS.Transform.toString(transform),
        '& .more-menu-button': {
          visibility: anchorEl ? 'visible' : 'hidden',
        },
        '&:hover': {
          '& .more-menu-button': {
            visibility: 'visible ',
          },
        },
      }}
    >
      <AgendaItemControlsWrapper
        ref={fileContainerRef}
      >
        {!isMobile && (
        <AppIconButton
          {...attributes}
          {...listeners}
          variant="lite-primary"
          ref={setActivatorNodeRef}
          className="more-menu-button"
          icon={<DnDIcon16 />}
          size="xxs"
          autoWidth
          onClick={onClick}
        />
        )}
      </AgendaItemControlsWrapper>
      <AgendaFileItemWrapper>
        <DocumentIcon
          ext={name.split('.').pop()}
          size={24}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '8px',
            maxWidth: '100%',
            overflow: 'auto',
            maxHeight: '24px',
          }}
        >
          <Typography
            sx={{
              maxWidth: '100%',
              paddingLeft: '3px',
              paddingRight: '6px',
              fontWeight: 400,
              wordBreak: 'break-word',
              height: '0px',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${formattedFileName}`}
          </Typography>
          {fileNameEdit ? (
            <AppInput
              fullWidth
              multiline
              disabled={!fileNameEdit}
              size="xs"
              rows={1}
              value={formattedFileName}
              variant="lite"
              onChange={(e) => setFormattedFileName(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              inputProps={{
                ref: inputRef,
                onKeyDown: (e) => {
                  if (e.nativeEvent.key === 'Enter') {
                    inputRef.current?.blur();
                  }
                },
                onBlur: onSubmit,
              }}
            />
          ) : (
            <Link
              href={url}
              underline="none"
              download
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                if (onDownload) {
                  onDownload({ ...file, docId: id });
                }
              }}
              sx={{
                maxHeight: '24px',
                overflow: 'hidden',
                '& span': {
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: stagesetColors.newGrey[800],
                },
              }}
            >
              <span>
                {formattedFileName}
              </span>
            </Link>
          )}
        </Box>
        <Typography
          sx={{
            marginLeft: '4px',
            fontSize: '16px',
            lineHeight: '24px',
            color: stagesetColors.newGrey[500],
            display: 'inline-block',
            whiteSpace: 'nowrap',
          }}
        >
          {convertSize(size)}
        </Typography>
      </AgendaFileItemWrapper>
      {
        uploading && (
          <AppLoader
            size={20}
            hidden={false}
          />
        )
      }
      <AppDropdown
        id="section-options-menu"
        lastElementHasAnotherColor
        items={[
          {
            label: 'Rename',
            startIcon: <PenIcon20 />,
            onClick: onFileNameEditStart,
          },
          // ////file replace////
          // {
          //   label: 'Replace',
          //   startIcon: <ReplaceIcon20 />,
          //   onClick: onReplaceMedia,
          // },
          // ////file replace////
          {
            label: '',
            divider: true,
          },
          {
            label: 'Delete',
            startIcon: <TrashIcon />,
            onClick: onDelete,
          },
        ]}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
        sx={{
          '& ul': {
            padding: '0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />

      {/*
        // ////file replace////
      <input
        hidden
        type="file"
        accept={inputAccepts}
        ref={fileInputRef}
        onChange={handleMediaFileChange}
        className="input"
      />
        // ////file replace////
      */}
    </AgendaItemContainer>
  );
};

export default FileDisplay;
