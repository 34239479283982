import {
  Node, NodeViewProps, ReactNodeViewRenderer, mergeAttributes,
} from '@tiptap/react';
import { ExtensionNames } from '../../Utils/TiptapHelpers';
import AgendaNodeComponent from './AgendaNodeComponent/AgendaNodeComponent';
import TiptapNodeWrapper from '../../TiptapNodeWrapper';

const AgendaNodeExtension = Node.create({
  name: ExtensionNames.AGENDA, // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      name: {
        default: 'New Agenda',
      },
      status: {
        default: false,
      },
      assigneesIds: {
        default: [],
      },
      assigneesTeams: {
        default: [],
      },
      docs: {
        default: [],
      },
      description: {
        default: '',
      },
      timeLineEndDate: {
        default: null,
      },
      subTasks: {
        default: [],
      },
      eventData: {
        default: {
          url: '',
          name: '',
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: ExtensionNames.AGENDA,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [ExtensionNames.AGENDA, mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      (nodeProps: NodeViewProps) => (
        <TiptapNodeWrapper
          {...nodeProps}
          component={<AgendaNodeComponent />}
          className={ExtensionNames.AGENDA}
        />
      ),
    );
  },
});

export default AgendaNodeExtension;
