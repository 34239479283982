import { Box, Typography } from '@mui/material';
import { AppAvatar } from '../../../shared/AppAvatar';

type Props = {
  avatarSrc?: string
  colorId?: number
  name: string
  title?: string
};

export const NameCell = ({
  avatarSrc,
  colorId,
  name,
  title,
}: Props) => (
  <Box sx={{
    display: 'inline-flex',
    gap: '12px',
    alignItems: 'center',
  }}
  >
    <AppAvatar name={name} src={avatarSrc} colorId={colorId} />
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box>
        <Typography sx={{
          color: '#1A1929',
        }}
        >
          {name}
        </Typography>
      </Box>
      {!!title && (
        <Box hidden={!title}>
          <Typography sx={{
            fontSize: '12px',
            color: '#7084B4',
          }}
          >
            {title}
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
);
