import {
  Autocomplete, CircularProgress, Paper, TextField,
} from '@mui/material';
import { AutocompleteRenderOptionState, createFilterOptions } from '@mui/material/Autocomplete/Autocomplete';
import * as React from 'react';
import {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { Theme } from '@mui/material/styles';
import AssigneeSelectionPopper from './AssigneeSelectionPopper';
import { AssigneeSelectionUser } from './assigneeSelectionUser';
import UserChip from './UserChip';
import UserSelectionListItem from './UserSelectionListItem';
import InviteListItem from './InviteListItem';
import { inviteUserToProcess } from '../../routes-old/process/state/dealActions';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { emailSchema } from './emailSchema';
import { handleScroll } from '../../routes-old/process/helpers';
import { AssigneeTeam } from '../../../shared/TaskDTO';
import { selectProcess } from '../../routes-old/process/state/processSlice';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  users: AssigneeSelectionUser[];
  selectedIds: string[];
  selectedTeams?: AssigneeTeam[];
  onAssigneeUpdate: (ids: string[], teams?: AssigneeTeam[]) => void;
  showTeams: boolean;
}

type InviteOption = { id?: undefined, email: string };
type TeamOption = { id: string, teamName: string, category: string };
type AssigneeOption = InviteOption | AssigneeSelectionUser | TeamOption;

const filter = createFilterOptions<AssigneeOption>();

const AssigneeSelection = ({
  selectedIds,
  selectedTeams,
  users,
  onAssigneeUpdate,
  showTeams,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [assigneeUsers, setAssigneeUsers] = useState<{ id: string, name?: string, category?: string }[]>(users);
  const [value, setValue] = useState<AssigneeOption[]>([]);
  const dispatch = useAppDispatch();
  const process = useAppSelector(selectProcess);

  useEffect(() => {
    if (!showTeams) {
      setAssigneeUsers(users);
      return;
    }
    const teams = [
      {
        id: 'buyer', name: process.client, category: 'Teams', avatarSrc: process.buyerLogoSrc,
      },
      {
        id: 'seller', name: process.sellerName, category: 'Teams', avatarSrc: process.sellerLogoSrc,
      },
    ];
    setAssigneeUsers(users.concat(teams));
  }, [users, selectedTeams]);

  useEffect(() => {
    const chosenOptions = assigneeUsers.filter((user) => selectedIds.includes(user.id) || selectedTeams?.includes(user.id as AssigneeTeam));
    setValue(chosenOptions);
  }, [selectedIds, assigneeUsers]);

  const options = useMemo(() => assigneeUsers.filter((user) => !value.find((val) => val.id === user.id)), [value, users]);

  const isAllAvailableUsersChosen = selectedIds.length === assigneeUsers.length;

  const isOpen = !loading && (!!inputValue || !isAllAvailableUsersChosen);

  const onUserInvite = async (email: string): Promise<void> => {
    const isCorrectEmail = await emailSchema.isValid(email);
    if (!isCorrectEmail) return;
    setLoading(true);
    const user = await dispatch(inviteUserToProcess({ email })).unwrap();
    setLoading(false);
    if (!user.id) {
      // todo: add alert on error
      console.log('Invite process failed');
      return;
    }
    onAssigneeUpdate([...selectedIds, user.id], selectedTeams);
  };

  const inputBorder = {
    border: 'none',
    borderRadius: '6px',
    borderColor: (theme: Theme) => theme.palette.primary.light,
  };

  const CustomPaper = useCallback(
    (props) => (
      <Paper
        sx={{
          padding: '12px 8px 8px 8px',
          backgroundColor: stagesetColors.white[100],
          color: stagesetColors.newGrey[800],
          '& .MuiListSubheader-root': {
            padding: '0px 0px 4px 0px',
            lineHeight: '16px',
            fontSize: '14px',
            fontWeight: 700,
          },
          '& .MuiAutocomplete-groupUl': {
            '& li': {
              backgroundColor: stagesetColors.white[100],
              borderRadius: '10px',
              '&:hover .Mui-focused': {
                backgroundColor: stagesetColors.newGrey[50],
              },
              '&.Mui-focused:not(:hover)': {
                backgroundColor: stagesetColors.white[100],
              },
            },
            padding: '0 !important',
          },
        }}
        {...props}
      />
    ),
    [],
  );

  return (
    <Autocomplete
      autoHighlight
      disablePortal
      onChange={(event: any, newValue: AssigneeOption[]) => {
        const inviteOption = newValue.find((value) => 'email' in value && !value.category);
        // invite option will be in available
        // only if no user found by given search string
        // invite option could contain invalid email
        if (inviteOption) {
          onUserInvite((inviteOption as InviteOption).email);
        } else {
          const newUsers = newValue.filter((user) => user.id && !user.category) as AssigneeSelectionUser[];
          const newTeams = newValue.filter((teamOption) => teamOption.id && teamOption.category === 'Teams');
          const teamsIds = newTeams.map((teamOption) => teamOption.id) as AssigneeTeam[];
          onAssigneeUpdate(newUsers.map((user) => user.id), teamsIds);
        }
      }}
      loading={loading}
      disabled={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      PaperComponent={CustomPaper}
      sx={{
        width: '100%',
        display: 'flex',
        flex: 1,
        minWidth: '60px',
        '& .MuiAutocomplete-input': {
          width: '100% !important',
          backgroundColor: stagesetColors.newGrey[50],
          padding: '0px !important',
        },
        '& .MuiInputBase-root': {
          display: 'flex',
          gap: '4px',
        },
      }}
      multiple
      value={value}
      groupBy={(option: AssigneeOption) => ('category' in option ? option.category : 'Stakeholders')}
      renderTags={
        (
          tagValue: AssigneeOption[],
          getTagProps,
        ) => {
          const userOptions = tagValue.filter((option) => option.id) as AssigneeOption[];
          return userOptions.map((userOption, index) => (
            // eslint-disable-next-line react/jsx-key
            <UserChip
              label={userOption.name || userOption.email}
              avatar={userOption.avatarSrc}
              {...getTagProps({ index })}
            />
          ));
        }
      }
      options={options}
      clearIcon={null}
      forcePopupIcon={false}
      open={isOpen}
      disableListWrap
      ListboxProps={{
        className: 'AssigneeAutocompleteListBox',
        onScroll: (e) => handleScroll(e.target as Element, 'AssigneeAutocompleteListBox-active'),
        // @ts-ignore
        sx: {
          padding: '0px',
          '&::-webkit-scrollbar': {
            width: '7px',
            height: '7px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: '#c7c7c7',
            opacity: '50%',
            visibility: 'hidden',
            '&:hover': {
              visibility: 'visible',
            },
          },
          '&.AssigneeAutocompleteListBox-active': {
            '&::-webkit-scrollbar-thumb': {
              visibility: 'visible',
            },
          },
        },
      }}
      getOptionLabel={(option) => ('name' in option ? option.name! : 'unknown')}
      renderInput={(params) => (
        <TextField
          placeholder={loading ? 'Inviting a new user...' : 'Add owner'}
          autoFocus
          sx={{
            '& .MuiOutlinedInput-root': {
              boxSizing: 'border-box',
              '& fieldset': inputBorder,
              '&:hover fieldset': inputBorder,
              '&.Mui-focused fieldset': inputBorder,
              backgroundColor: stagesetColors.newGrey[50],
              borderRadius: '0px',
              color: stagesetColors.newGrey[800],
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '22px',
              padding: '8px',
              display: 'flex',
              gap: '4px',
            },
          }}
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: AssigneeOption,
        state: AutocompleteRenderOptionState,
      ) => {
        const isInviteOption = !option.id && 'email' in option;

        if (isInviteOption) {
          return (
            <InviteListItem
              itemProps={props}
              email={option.email}
            />
          );
        }

        return (
          <UserSelectionListItem
            key={`assignee-item-${option.id}`}
            title={option.name}
            avatar={option.avatarSrc}
            OptionProps={props}
            state={state}
          />
        );
      }}
      PopperComponent={(props) => (<AssigneeSelectionPopper {...props} />)}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params);
        const { inputValue: filterInputValue } = params;
        const shouldRenderInviteOption = filtered && filtered.length <= 0 && inputValue !== '';
        if (shouldRenderInviteOption) {
          filtered.push({
            email: filterInputValue,
          });
        }
        return filtered;
      }}
    />
  );
};

export default AssigneeSelection;
