import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { login, setIsShareProtectedAuthDialogOpen } from '../state/authState';
import { IFormState, LoginForm } from './LoginForm';
import { Analytics, EMixPanelEvents } from '../../../core/Analytics';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';

const analytics: Analytics = Analytics.getInstance();

export const Login = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { status, error, isShareProtectedAuthDialogOpen } = useAppSelector((state) => state.auth);

  const onSubmit = (data: IFormState): void => {
    if (isShareProtectedAuthDialogOpen) {
      dispatch(setIsShareProtectedAuthDialogOpen(false));
    }
    window.sessionStorage.clear();
    dispatch(login({
      ...data,
    }));
    analytics.track(EMixPanelEvents.ACCOUNT_LOGIN);
    analytics.userLogin({
      data,
    });
  };

  return (
    <AuthPaper>
      <LoginForm onSubmit={onSubmit} status={status} errorMessage={error?.message} />
    </AuthPaper>
  );
};
