import { useEffect, useState } from 'react';
import { Opportunity } from '../../../../shared/crm/Opportunity';
import { CrmApi } from '../../../api/CrmApi';
import { useAppSelector } from '../../../hooks/stateHooks';

const crmApi: CrmApi = new CrmApi();

export const isUserHaveAnyCrmIntegration = (): any => {
  const { isUserHaveIntegration: pipedrive } = useAppSelector((state) => state.pipedrive);
  const { isUserHaveIntegration: hubspot } = useAppSelector((state) => state.hubspot);
  const { isUserHaveIntegration: salesforce } = useAppSelector((state) => state.salesForce);
  return pipedrive || hubspot || salesforce;
};

export const useCrmOpportunities = (): any => {
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const isUserHaveIntegration = isUserHaveAnyCrmIntegration();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const getOpportunities = async (): Promise<void> => {
    try {
      const data: Opportunity[] = await crmApi.getOpportunities();
      setOpportunities(data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (isUserHaveIntegration && isAuthenticated) {
      getOpportunities();
    }
  }, [isUserHaveIntegration]);

  return opportunities;
};

export const useCrmDealContacts = (dealId: string): any => {
  const [dealContacts, setDealContacts] = useState<any[]>([]);
  const isUserHaveIntegration = isUserHaveAnyCrmIntegration();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const getDealContacts = async (): Promise<void> => {
    try {
      const data: any[] = await crmApi.getDealContacts(dealId);
      setDealContacts(data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (isUserHaveIntegration && isAuthenticated) {
      getDealContacts();
    }
  }, [isUserHaveIntegration]);

  return [dealContacts];
};
