import {
  Box, Menu, TextField, Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ClearRounded } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import {
} from '../../../icons/AddSectionIcons/AddSectionIcons';
import {
  iframeSiteOption,
  iframeSourceNoContentOption,
  iframeSourceOption,
  MediaSource,
  mediaSources,
  SelectedSourceOption,
} from '../../MediaField/Helpers/MediaFieldHelpers';
import SourceOption from '../../MediaField/MediaFieldEmpty/SourceOption';
import { selectEmbedSectionData, setEmbedSectionData } from '../lib/sectionsSlice';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import SearchActive from '../../../icons/SearchActive.svg';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';

interface Props {
  anchorEl: any;
  open: boolean;
  handleClose: () => void;
  goBack: () => void;
  position?: number;
  newStage?: boolean;
  className?: string;
}

const AddEmbedSectionMenu = ({
  anchorEl,
  open,
  handleClose,
  position,
  goBack,
  newStage,
  className,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>('');
  const [sourceVariants, setSourceVariants] = useState<MediaSource[]>(mediaSources);
  const selectedCurrently = useAppSelector(selectEmbedSectionData);

  useEffect(() => {
    const newVariants: MediaSource[] = [];
    mediaSources.forEach((source: MediaSource) => {
      if (source.name.toLocaleLowerCase().includes(searchValue.toLowerCase())) {
        newVariants.push(source);
      }
    });
    setSourceVariants(newVariants);
  }, [searchValue]);

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleOptionSelect = (data: Partial<SelectedSourceOption>): void => {
    dispatch(setEmbedSectionData({
      ...data,
      newStage,
      position,
    }));
    handleClose();
  };

  const getEmbedOptions = () => {
    let typeTag = '';
    let newTypeTag = '';
    let header = false;

    return sourceVariants.map((source: MediaSource, i:number) => {
      newTypeTag = source.typeTag ?? '';
      header = false;

      if (newTypeTag !== typeTag) {
        typeTag = newTypeTag;
        header = true;
      }
      return (
        <SourceOption
          key={source.contentTag + i}
          name={source.name}
          source={source.link}
          typeTag={source.typeTag}
          description={source.description}
          header={header}
          onSelect={(data) => handleOptionSelect({
            ...data, provider: source.provider, tag: source.typeTag,
          })}
          icon={source.icon}
          selectedCurrently={selectedCurrently?.name ?? ''}
        />
      );
    });
  };

  return (
    <Menu
      id="section-options-menu"
      className={className}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          onClick: (e) => e.stopPropagation(),
          sx: {
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            padding: '0px',
            width: isMobile ? '100%' : '250px',
            marginTop: '4px',
          },
        },
        // background: {
        //   onMouseDown: handleClose,
        //   sx: {
        //     background: 'transparent',
        //   },
        // },
      }}
      MenuListProps={{
        onMouseDown: (e) => e.stopPropagation(),
      }}
    >
      <Box
        onClick={(e) => { e.stopPropagation(); goBack(); }}
        sx={{
          width: '100%',
          height: '52px',
          paddingX: '16px',
          borderBottom: '1px solid #EEF0FA',
          background: '#FCFDFD',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          cursor: 'pointer',
        }}
      >

        <AppIconButton icon={<ChevronLeft />} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
          }}
        >
          Embed
        </Typography>
      </Box>
      <TextField
        value={searchValue}
        onChange={handleSearchValueChange}
        fullWidth
        onClick={(e) => e.stopPropagation()}
        // size="small"
        variant="standard"
        color="secondary"
        placeholder="Search"
        sx={{
          marginX: '16px',
          height: '40px',
          border: '1px solid #7084B4',
          borderRadius: '8px',
          width: 'calc( 100% - 32px)',
          paddingX: '12px',
          justifyContent: 'center',
          marginY: '8px',
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '0px',
            '& .EmbedOptionsSearchIcons': {
              color: '#949494',
            },
          },
          endAdornment: (
            searchValue && (
            <ClearRounded
              className="EmbedOptionsSearchIcons"
              onClick={() => setSearchValue('')}
              sx={{
                cursor: 'pointer',
              }}
            />
            )
          ),
          startAdornment: (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <SearchActive />
            </Box>
          ),
        }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          sx: {
            width: '90%',
            padding: '4px 0px 4px 10px',
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: isMobile ? 'auto' : '356px',
          '@-moz-document url-prefix()': {
            scrollbarWidth: 'none',
          },
          '&::-webkit-scrollbar': {
            width: '14px',
            borderRadius: '0px 7px 7px 0px',
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '0px 7px 7px 0px',
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '7px',
            backgroundColor: '#c7c7c7',
            opacity: '50%',
            visibility: 'visible',
            padding: '4px',
            border: '4px solid white',
          },
        }}
      >
        <SourceOption
          onSelect={() => { handleOptionSelect(iframeSourceNoContentOption); }}
          {...iframeSourceNoContentOption}
          selectedCurrently={selectedCurrently?.name ?? ''}
        />
        <SourceOption
          onSelect={() => { handleOptionSelect(iframeSiteOption); }}
          {...iframeSiteOption}
          selectedCurrently={selectedCurrently?.name ?? ''}
        />
        <SourceOption
          onSelect={() => { handleOptionSelect(iframeSourceOption); }}
          {...iframeSourceOption}
          selectedCurrently={selectedCurrently?.name ?? ''}
        />
        {
            getEmbedOptions()
          }
      </Box>
    </Menu>
  );
};

export default AddEmbedSectionMenu;
