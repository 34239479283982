import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../../../common/DeleteTypography';
import { EAppRoutes } from '../../../../core/router';
import { useAppDispatch } from '../../../../hooks/stateHooks';
import { deactivateUser } from '../../state/dealActions';
import DeactivateUser from '../../../../icons/DeactivateUser.svg';
import { AppButton } from '../../../../shared/AppButton/AppButton';

interface Props {
  name: string | undefined,
  email: string,
  id: string
  isShared: boolean;
  isRemoveUserAvailable: boolean;
  isSelf: boolean;
}

const RemoveUser = ({
  name,
  email,
  id,
  isShared,
  isRemoveUserAvailable,
  isSelf,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDeleteUserConfirmationDialogOpen, setDeleteUserConfirmationDialogOpen] = useState<boolean>(false);

  const onDeleteConfirm = async (userId: string): Promise<void> => {
    setDeleteUserConfirmationDialogOpen(false);
    try {
      await dispatch(deactivateUser(userId))
        .unwrap();
      if (isSelf) {
        navigate(EAppRoutes.home);
      }
    } catch (e) {

    }
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to deactivate
      <b>
        {' '}
        {name || email}
      </b>
      ?
    </DeleteTypography>
  );

  const leaveTypography = (
    <DeleteTypography>
      Are you sure you want to leave this deal?
    </DeleteTypography>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        (!isShared && isRemoveUserAvailable)
        && (
          <>
            <AppButton
              fullWidth
              onClick={() => { setDeleteUserConfirmationDialogOpen(true); }}
              startIcon={<DeactivateUser className="deal-user-list-remove-icon" />}
              variant="danger"
            >
              Deactivate
            </AppButton>
            <ConfirmationDialog
              isDialogOpen={isDeleteUserConfirmationDialogOpen}
              onCancel={() => setDeleteUserConfirmationDialogOpen(false)}
              onConfirm={() => onDeleteConfirm(id)}
              dialogContentText={isSelf ? leaveTypography : deleteTypography}
              dialogTitle={isSelf ? 'Leave Deal' : 'Deactivate Participant'}
            />
          </>
        )
      }
    </>
  );
};

export default RemoveUser;
