import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ESubscriptionStatus,
  ESubscriptionType,
  StripeInvoice,
  SubscriptionDTO,
  appSumoSubscriptionTypes,
  basicSubscriptionTypes,
  premiumSubscriptionTypes,
  sellerSubscriptions,
} from '../../../../shared/Subscription';
import { SubscriptionApi } from '../../../api/SubscriptionApi';
import { UserOrganizationApi } from '../../../api/UserOrganizationApi';
import { EStateStatus, getInitialBaseState, IStateBase } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import { StripeApi } from '../../../api/StripeApi';
import { StagesetPlansDTO, StripeInterval, StripePrice } from '../../../../shared/stripe';

const userOrganizationApi = new UserOrganizationApi();
const subscriptionApi = new SubscriptionApi();
const stripeApi = new StripeApi();

export interface BillingState extends IStateBase {
  subscription: SubscriptionDTO | null;
  nextInvoice?: StripeInvoice | '';
  isSeller: boolean;
  stagesetPlans: StagesetPlansDTO[];
  stagesetPrices: StripePrice[];
  subscriptionInterval: StripeInterval;
}

export enum EBillingActions {
  BILLING_GET_CURRENT_SUBSCRIPTION = 'BILLING_GET_CURRENT_SUBSCRIPTION',
  BILLING_GET_NEXT_INVOICE = 'BILLING_GET_NEXT_INVOICE',
  BILLING_UPGRADE_SUBSCRIPTION = 'BILLING_UPDATE_SUBSCRIPTION',
  GET_STAGESET_PLANS = 'GET_STAGESET_PLANS',
  GET_STAGESET_PRICES = 'GET_STAGESET_PRICES',
}

export const billingReducerName = 'billing';

const initialState: BillingState = {
  ...getInitialBaseState(),
  subscription: null,
  isSeller: false,
  stagesetPlans: [],
  stagesetPrices: [],
  subscriptionInterval: 'month',
};

export const billingGetSubscription = createAsyncThunk(
  `${billingReducerName}/${EBillingActions.BILLING_GET_CURRENT_SUBSCRIPTION}`,
  async () => userOrganizationApi.getSubscription(),
);

export const billingGetNextInvoice = createAsyncThunk(
  `${billingReducerName}/${EBillingActions.BILLING_GET_NEXT_INVOICE}`,
  async () => subscriptionApi.getNextInvoice(),
);

export const upgradeToSellerSubscription = createAsyncThunk(
  `${billingReducerName}/${EBillingActions.BILLING_UPGRADE_SUBSCRIPTION}`,
  async () => subscriptionApi.upgradeToSellerSubscription(),
);

export const getStagesetPlans = createAsyncThunk(
  `${billingReducerName}/${EBillingActions.GET_STAGESET_PLANS}`,
  async () => stripeApi.getStagesetPlans(),
);

export const getStagesetPrices = createAsyncThunk(
  `${billingReducerName}/${EBillingActions.GET_STAGESET_PRICES}`,
  async () => stripeApi.getPrices(),
);

export const billingSlice = createSlice({
  name: billingReducerName,
  initialState,
  reducers: {
    setSubscriptionInterval: (state, { payload }: PayloadAction<StripeInterval>) => ({
      ...state,
      subscriptionInterval: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(billingGetSubscription.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(billingGetSubscription.fulfilled, (state, { payload }) => {
      state.subscription = payload;
      if (sellerSubscriptions.includes(payload.type)) {
        state.isSeller = true;
      }
      if (payload.type === ESubscriptionType.SELLER) {
        state.nextInvoice = '';
      }
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(billingGetSubscription.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(billingGetNextInvoice.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(billingGetNextInvoice.fulfilled, (state, { payload }) => {
      state.nextInvoice = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(billingGetNextInvoice.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(upgradeToSellerSubscription.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(upgradeToSellerSubscription.fulfilled, (state, { payload }) => {
      state.subscription = payload;
      state.isSeller = true;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(upgradeToSellerSubscription.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getStagesetPlans.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(getStagesetPlans.fulfilled, (state, { payload }) => {
      state.stagesetPlans = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getStagesetPlans.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getStagesetPrices.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(getStagesetPrices.fulfilled, (state, { payload }) => {
      state.stagesetPrices = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getStagesetPrices.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
  },
});

export const {
  setSubscriptionInterval,
} = billingSlice.actions;

export const selectOrgSubscriptionType = (state: RootState) => state?.billing?.subscription?.type;
export const selectOrgSubscriptionStatus = (state: RootState): ESubscriptionStatus => state?.billing?.subscription?.status;
export const selectIsSubscriptionPremium = (state: RootState) => premiumSubscriptionTypes.includes(state?.billing?.subscription?.type);
export const selectIsSubscriptionBasic = (state: RootState) => basicSubscriptionTypes.includes(state?.billing?.subscription?.type);
export const selectStagesetPlans = (state: RootState): StagesetPlansDTO[] => state?.billing?.stagesetPlans ?? [];
export const selectStagesetPrices = (state: RootState) => state?.billing?.stagesetPrices ?? [];
export const selectCurrentPlan = (state: RootState): StagesetPlansDTO | null => {
  if (state?.billing?.nextInvoice?.currentPrice && state?.billing?.stagesetPlans?.length) {
    return state.billing.stagesetPlans.find((item:StagesetPlansDTO) => item.priceId === state.billing.nextInvoice.currentPrice);
  }
  return null;
};

export const selectSubscriptionInteval = (state: RootState): StripeInterval => state?.billing?.subscriptionInterval;
export const selectIsAppsumo = (state: RootState): boolean => state?.billing?.subscription?.type
  && appSumoSubscriptionTypes.includes(state?.billing?.subscription?.type);

export const selectCurrentPrice = (state: RootState): string => state?.billing?.nextInvoice?.currentPrice;
