import { useState } from 'react';
import {
  Active,
  DragEndEvent, DragStartEvent, PointerSensor, TouchSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { isMobile } from 'react-device-detect';
import { ProcessDTO } from '../../shared/process/ProcessMilestoneActionDTO';

type DraggableItem = Active & { data: { current: ProcessDTO } };

export const useDealsBoardDnD = () => {
  const [draggableElement, setDraggableElement] = useState<DraggableItem | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    if (!active) return;

    setDraggableElement(active as DraggableItem);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setDraggableElement(null);
    return {
      current: active as DraggableItem,
      target: over,
    };
  };

  const sensors = useSensors(
    useSensor(isMobile ? TouchSensor : PointerSensor, {
      activationConstraint: isMobile ? {
        delay: 1000,
        tolerance: 5,
      } : {
        distance: 5,
      },
    }),
  );

  return {
    draggableElement,
    handleDragStart,
    handleDragEnd,
    sensors,
  };
};
