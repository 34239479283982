import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useRef } from 'react';
import { AppLoader } from '../../shared/AppLoader/AppLoader';
import { AppButton } from '../../shared/AppButton/AppButton';
import MenuLogo from '../../icons/UploadFilePlaceholder.svg';
import NormalizedSvgIcon from '../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import TrashIcon from '../../icons/TrashIcon';

interface Props {
  onClick: () => void;
  onDelete: () => void;
  background: string;
  onNewUpload: (file: File) => void;
  loading?: boolean;
}

const CustomBackgroundPreview = ({
  onClick,
  background,
  onDelete,
  onNewUpload,
  loading,
}: Props): JSX.Element => {
  const replaceBackgroundRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    onClick();
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        borderRadius: '4px',
        width: 'calc( 100% )',
        aspectRatio: '109/25',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'hidden',
        userSelect: 'none',
        cursor: 'pointer',
        '&:hover': {
          '& .CustomBackgroundControls': {
            display: 'flex',
          },
        },
      }}
    >
      <Box
        sx={{
          height: '0px',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={background}
          sx={{
            width: '100%',
            aspectRatio: '109/25',
            objectFit: 'cover',
            objectPosition: 'center',
            opacity: loading ? '0.2' : '1',
          }}
        />
      </Box>
      {
        loading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppLoader size={30} />
          </Box>
        ) : (
          <Box
            className="CustomBackgroundControls"
            sx={{
              display: isMobile ? 'flex' : 'none',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <AppButton
              onClick={(e) => {
                e.stopPropagation();
                if (replaceBackgroundRef.current) {
                  replaceBackgroundRef.current.click();
                }
              }}
              startIcon={<NormalizedSvgIcon component={MenuLogo} />}
              variant="tertiary-newGrey"
              size="l"
            >
              Upload
            </AppButton>
            <AppIconButton
              size="l"
              onClick={(e) => { e.stopPropagation(); onDelete(); }}
              icon={<TrashIcon />}
              variant="danger-secondary"
            />
          </Box>
        )
      }
      <input
        hidden
        type="file"
        ref={replaceBackgroundRef}
        onClick={(e) => { e.stopPropagation(); }}
        onChange={(e) => {
          if (e.target?.files?.length && e.target.files[0].type.split('/')[0] === 'image') {
            onNewUpload(e.target.files[0]);
          }
          e.target.value = '';
        }}
        accept=".png, .jpg, .jpeg, .webp, .svg"
      />
    </Box>
  );
};

export default CustomBackgroundPreview;
