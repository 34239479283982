import { Tooltip, TooltipProps } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  placement?:
  'bottom'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
  | undefined,
  children: JSX.Element,
}

export const AppTooltip = ({
  placement = 'bottom',
  children,
  ...tooltipProps
}: Props & TooltipProps): JSX.Element | null => (
  <Tooltip
    {...tooltipProps}
    placement={placement}
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          background: stagesetColors.newGrey[800],
        },
      },
      arrow: {
        sx: {
          color: stagesetColors.newGrey[800],
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
