import { useEffect, useState } from 'react';
import { config } from '../../core';

const CelloAttribution = (): null => {
  const [isLoaded, setLoaded] = useState(false);
  const scriptId = 'cello-attribution';
  const { attributionUrl } = config.cello;

  useEffect(() => {
    const existingScript = document.getElementById(scriptId);
    if (!isLoaded && attributionUrl) {
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = attributionUrl;
        script.type = 'module';
        script.id = scriptId;
        document.body.appendChild(script);

        script.onload = () => {
          setLoaded(true);
        };
      }
    }

    return () => {
      if (existingScript && isLoaded) {
        existingScript.remove();
      }
    };
  }, [isLoaded, attributionUrl]);

  return null;
};

export default CelloAttribution;
