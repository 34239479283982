import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const DealLayoutMainWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

export const DealLayoutContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#F9FBFF',
  display: 'flex',
  flexDirection: 'row',
  '&.deal-layout-with-toolbar': {
    height: 'calc(100% - 48px)',
  },
}));
