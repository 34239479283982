import {
  Box,
  Dialog,
  MenuItem,
  Typography,
  styled,
} from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const LibraryDialogTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 24px',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const LibraryDialogTitle = styled(Typography)(() => ({
  lineHeight: '26px',
  fontWeight: 600,
  fontSize: '18px',
}));

export const LibraryDialogContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 24px 12px 24px',
  gap: '20px',
}));

export const LibraryDialogFooterWrapper = styled(Box)(() => ({
  padding: '12px 24px',
  background: stagesetColors.grey[100],
}));

export const LibraryDialogMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
}));

// Dialog
export const StyledLibraryDialog = styled(Dialog)(() => ({
  // Paper
  '& .MuiPaper-root, .MuiPaper-elevation, .css-1trks2i-MuiPaper-root-MuiDialog-paper': {
    maxWidth: '500px',
    width: '100%',
    borderRadius: '12px',
    boxShadow: '0px 0px 18px -2px rgba(30, 41, 59, 0.10), 0px 0px 3px 0px rgba(30, 41, 59, 0.10)',
    '& h2': {
      margin: '0',
    },
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(128, 128, 128, .5)',
      opacity: '50%',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#7E7E7E',
    },
  },
  // Title
  '& .MuiTypography-root, .MuiTypography-h6, .MuiDialogTitle-root, .css-kxlkcf-MuiTypography-root-MuiDialogTitle-root': {
    boxShadow: 'none',
    margin: '0',
    padding: '0',
  },
  // FieldsContainer
  '& .MuiDialogContent-root, .css-qu6ihs-MuiDialogContent-root': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-evenly',
    height: 'auto',
    padding: '0',
    width: '100%',
    '& .MuiFormControl-root, .MuiTextField-root, .css-1u3bzj6-MuiFormControl-root-MuiTextField-root': {
      width: '100%',
      margin: '5px 0',
    },
  },
  // ButtonsContainer
  '& .MuiDialogActions-root, .MuiDialogActions-spacing, .css-hlj6pa-MuiDialogActions-root': {
    justifyContent: 'space-around',
    marginTop: '10px',
    padding: '0',
    '& .StyledCreateBytton': {
      width: '100%',
      backgroundColor: '#62B839',
      color: '#fff',
      borderRadius: '5px',
      height: '3rem',
    },
  },
  '#client-name-field': {
    width: '100%',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    height: '40px',
    alignItems: 'center',
  },
}));
