import { PopperProps, Paper } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

const AssigneeSelectionPopper = ({ open, children }: PopperProps): JSX.Element => (
  <Paper
    sx={{
      borderTop: `1px solid ${stagesetColors.newGrey[200]}`,
      borderRadius: '0px',
    }}
    hidden={!open}
  >
    { children }
  </Paper>
);

export default AssigneeSelectionPopper;
