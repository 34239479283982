import {
  createAsyncThunk, createEntityAdapter, createSlice, EntityState,
} from '@reduxjs/toolkit';
import { EDealEventName } from '../../../../shared/events/Events';
import { DealNotification, DealNotificationPayload } from '../../../../shared/notifications/Notifications';
import { NotificationsApi } from '../../../api/NotificationsApi';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';

const notificationsApi: NotificationsApi = new NotificationsApi();

export interface NotificationsState {
  status: EStateStatus;
  error?: string;
  createdAtLTE?: string;
  lastRequestTimestampMS?: number;
}

const dealNotificationsAdapter = createEntityAdapter<DealNotification<DealNotificationPayload>>({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

export const dealNotificationsReducerName: string = 'deal-notifications';
export enum EDealNotificationActions {
  GET_DEAL_NOTIFICATION = 'GET_DEAL_NOTIFICATION',
  GET_UPDATES = 'GET_UPDATES',
  MARK_AS_READ = 'MARK_AS_READ',
}

export const getUserDealNotifications = createAsyncThunk(
  `${dealNotificationsReducerName}/${EDealNotificationActions.GET_DEAL_NOTIFICATION}`,
  async (payload: string) => notificationsApi.getUserDealNotifications(payload),
);

export const getUserDealNotificationsUpdates = createAsyncThunk(
  `${dealNotificationsReducerName}/${EDealNotificationActions.GET_UPDATES}`,
  async (
    { processId, createdAtLTE }: { processId: string; createdAtLTE: string },
  ) => notificationsApi.getNewUserDealNotifications(processId, createdAtLTE),
);

export const markDealNotificationsAsRead = createAsyncThunk(
  `${dealNotificationsReducerName}/${EDealNotificationActions.MARK_AS_READ}`,
  async (payload: string[]) => notificationsApi.markAsRead(payload),
);

export type TDealNotificationsState = NotificationsState & EntityState<DealNotification<DealNotificationPayload>>;

export const initialState: TDealNotificationsState = dealNotificationsAdapter.getInitialState({
  status: EStateStatus.IDLE,
});

export const dealNotificationsSlice = createSlice({
  name: dealNotificationsReducerName,
  initialState,
  reducers: {
    clearNotificationsState: (state) => dealNotificationsAdapter.removeAll(state),
  },
  extraReducers: ((builder) => builder
    .addCase(getUserDealNotifications.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(getUserDealNotifications.fulfilled, (state, { payload }) => {
      state.status = EStateStatus.IDLE;
      const { data, createdAtLTE } = payload;
      dealNotificationsAdapter.addMany(state, data);
      state.createdAtLTE = createdAtLTE;
    })
    .addCase(getUserDealNotifications.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(getUserDealNotificationsUpdates.pending, (state) => {
      state.status = EStateStatus.LOADING;
      state.lastRequestTimestampMS = Date.now();
    })
    .addCase(getUserDealNotificationsUpdates.fulfilled, (state, { payload }) => {
      state.status = EStateStatus.IDLE;
      const { data, createdAtLTE } = payload;
      dealNotificationsAdapter.addMany(state, data);
      state.createdAtLTE = createdAtLTE;
      state.lastRequestTimestampMS = Date.now();
    })
    .addCase(getUserDealNotificationsUpdates.rejected, (state) => {
      state.status = EStateStatus.ERROR;
      state.lastRequestTimestampMS = Date.now();
    })
    .addCase(markDealNotificationsAsRead.fulfilled, (state, { payload }) => {
      dealNotificationsAdapter.removeMany(state, payload);
    })
    .addCase(markDealNotificationsAsRead.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
  ),
});

export const {
  clearNotificationsState,
} = dealNotificationsSlice.actions;
export const { selectById, selectAll } = dealNotificationsAdapter.getSelectors();
export const filesNotificationsSelector = (state: RootState) => selectAll(state.dealNotifications)
  .filter((notification: DealNotification<DealNotificationPayload>) => notification.eventName === EDealEventName.DEAL_FILE_ADDED);

export const usersNotificationsSelector = (state: RootState) => selectAll(state.dealNotifications)
  .filter((notification: DealNotification<DealNotificationPayload>) => notification.eventName === EDealEventName.DEAL_USER_ADDED);

export const chatNotificationsSelector = (state: RootState) => selectAll(state.dealNotifications)
  .filter((notification: DealNotification<DealNotificationPayload>) => notification.eventName === EDealEventName.DEAL_MESSAGE_ADDED);
