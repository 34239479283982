import { SvgIcon } from '@mui/material';
import ContactRow from '../ContactRow';
import CalendlyContactIcon from '../../../icons/CalendarContact.svg';

type Props = {
  calendlyLink: string
};

export const CalendlyContact = ({ calendlyLink }: Props): JSX.Element => (
  <ContactRow
    text="Book a meeting with me"
    icon={(
      <SvgIcon
        viewBox="0 0 20 20"
        component={CalendlyContactIcon}
        sx={{
          color: 'transparent',
        }}
      />
    )}
    link={calendlyLink}
  />
);

export default CalendlyContact;
