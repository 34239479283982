import { ListNodeTagType } from '@lexical/list/LexicalListNode';
import { HeadingTagType } from '@lexical/rich-text';

export enum ELexicalBlockTypes {
  PARAGRAPH = 'paragraph',
  H1 = 'h1',
  H2 = 'h2',
  UL = 'ul',
  OL = 'ol',
}

export type LexicalBlockTypes = ListNodeTagType | HeadingTagType | 'paragraph';

export const getBlockName = (blockType: LexicalBlockTypes): string => {
  switch (blockType) {
    case ELexicalBlockTypes.PARAGRAPH:
      return 'Normal Text';
    case ELexicalBlockTypes.H1:
      return 'Heading 1';
    case ELexicalBlockTypes.H2:
      return 'Heading 2';
    case ELexicalBlockTypes.UL:
      return 'Bullet List';
    case ELexicalBlockTypes.OL:
      return 'Ordered List';
    default:
      return 'Normal Text';
  }
};
