import {
  SwipeableDrawer,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Puller } from '../../../../../shared/Puller/Puller';
import { FiltersTab } from './FiltersTab';
import { useGridFilter } from '../../../../../hooks/useGridFilter';
import {
  getProcessesFilterInfo,
  selectMobileFilterMenu,
  setExpanded,
  setMobileFilterMenu,
} from '../../lib/dealsGridSlice';
import { AppDispatch } from '../../../../../core/store/store';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/stateHooks';
import { useGetSavedFilterSetsQuery } from '../../api/dealFilterApi';
import { selectGridToRender } from '../../../../../routes-old/deals/state/dealsSlice';

type Props = {
  categoryTab?: number
};

export const MobileTabs = ({ categoryTab }: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const gridToRender = useAppSelector(selectGridToRender);

  const {
    data: savedFilters,
  } = useGetSavedFilterSetsQuery({ grid: gridToRender });
  const open = useAppSelector(selectMobileFilterMenu);

  const {
    allOpts,
    healthOpts,
    statusOpts,
    ownerOpts,
    companyOpts,
    debouncedGridFilters,
    filterParams,
  } = useGridFilter({
    savedFilters,
  });

  useEffect(() => {
    dispatch(setExpanded(!!Object.keys(filterParams).length));
  }, [filterParams]);

  useEffect(() => {
    dispatch(getProcessesFilterInfo());
  }, [debouncedGridFilters]);

  useEffect(() => {
    if (typeof categoryTab === 'number') {
      setActiveTab(categoryTab);
    }
  }, [categoryTab]);

  const handleClose = () => {
    dispatch(setMobileFilterMenu(false));
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="temporary"
      onOpen={() => {
        dispatch(setMobileFilterMenu(true));
      }}
      swipeAreaWidth={56}
      disableSwipeToOpen
      PaperProps={{
        sx: {
          height: '100%',
          borderRadius: '0px',
          '& .mobile-table-row': {
            borderWidth: '1px',
            borderRadius: '12px',
          },
          '& .mobile-table-row-header': {
            minHeight: 'auto',
            padding: '0px',
          },
          padding: '16px 16px 8px 16px',
        },
      }}
    >
      <Puller />
      <SwipeableViews
        index={activeTab}
        onChangeIndex={setActiveTab}
        disabled
        containerStyle={{
          height: '100%',
        }}
        style={{
          height: '100%',
        }}
      >
        <FiltersTab
          items={allOpts}
          onNext={(tab) => {
            setActiveTab(tab);
          }}
          onClose={handleClose}
          title="Filters"
        />
        <FiltersTab
          items={healthOpts}
          onClose={handleClose}
          title="Health"
        />
        <FiltersTab
          items={statusOpts}
          onClose={handleClose}
          title="Status"
        />
        <FiltersTab
          items={ownerOpts}
          onClose={handleClose}
          title="Owner"
          enableSearch
        />
        <FiltersTab
          items={companyOpts}
          onClose={handleClose}
          title="Company"
          enableSearch
        />
      </SwipeableViews>

    </SwipeableDrawer>
  );
};
