import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronDown20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M5 7.5L10 12.5L15 7.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const ChevronDown16 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M4 6L8 10L12 6"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
