import { TabPanel } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { EFeedTab, selectIsInternalFilters } from '../lib/feedSlice';
import TimelineContent from '../../../widgets/ProcessTimeline/TimelineContent';
import { useAppSelector } from '../../../hooks/stateHooks';
import InternalTabFilters from './InternalTabFilters';

const InternalModeTab = (): JSX.Element => {
  const isFilters = useAppSelector(selectIsInternalFilters);

  if (isFilters && isMobile) {
    return (
      <InternalTabFilters />
    );
  }

  return (
    <TabPanel
      value={EFeedTab.INTERNAL}
      sx={{
        padding: '0px 24px',
        height: '100%',
      }}
    >
      <TimelineContent />
    </TabPanel>
  );
};

export default InternalModeTab;
