const percentageValidator: RegExp = /^(?:100(?:\.0(?:0)?)?|\d{1,2}(?:\.\d{1,2})?)$/;
// 2 decimals
const decimalValidator: RegExp = /^(\d+\.?\d{0,2}|\.\d{1,9})$/;
// 1 decimal
// const decimalValidator: RegExp = /^\d+([.]\d)?$/;

const currencyToPercents = (currentaValue: number) => {
  const length = Math.ceil(Math.log10(currentaValue + 1));
  const divisionedValue = currentaValue / (10 ** (length - 3));
  if (divisionedValue > 100) return Math.round(divisionedValue * 1e1) / 1e2;
  return Math.round(divisionedValue * 1e2) / 1e2;
};

export const handleNumericalData = ({
  currentValue,
  previousValue,
  fraction,
  togglePercents,
  isPercentage,
}: {
  currentValue: string | undefined,
  previousValue?: number | null,
  fraction?: boolean,
  togglePercents?: boolean,
  isPercentage?: boolean,
}) => {
  if (currentValue) {
    if (Number(currentValue) < 0) return 0;
    if (fraction) {
      if (togglePercents) {
        if (!percentageValidator.test(currentValue)) {
          if (!decimalValidator.test(currentValue)) return previousValue || 0;
          return currencyToPercents(Number(currentValue));
        }
      }
      if (isPercentage) {
        if (Number(currentValue) >= 100) return 100;
        if (!percentageValidator.test(currentValue)) return previousValue || 0;
      }
      if (!decimalValidator.test(currentValue)) return previousValue || 0;
      return Number(currentValue);
    }
    if (Number(currentValue) % 1 !== 0) return previousValue || 0;
    return Number(currentValue);
  } return null;
};

export const roundNumber = (number: number | null) => {
  const factor = 10 ** 2;
  if (number) return Math.round(number * factor) / factor;
  return 0;
};
