import { Box, styled, Typography } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const ProcessTimelineMenuItemContainer = styled(Box)(() => ({
  maxHeight: '400px',
  padding: '16px 12px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  borderRadius: '12px',
  gap: '12px',
  backgroundColor: '#FFF',
  overflow: 'hidden',
}));

export const ProcessTimelineMenuItemHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: '12px',
}));

export const ProcessTimelineMenuItemTitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[800],
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',
}));

export const ProcessTimelineMenuItemSearchVariantsContainer = styled(Box)(() => ({
  minHeight: '75px',
  maxHeight: '175px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const ProcessTimelineMenuItemSearchItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '4px 0px',
  gap: '12px',
  cursor: 'pointer',
  alignItems: 'center',
  '&:first-child': {
    marginTop: '12px',
  },
  '&:last-child': {
    marginBottom: '12px',
  },
}));

export const ProcessTimelineMenuItemSearchItemTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[800],
}));

export const ProcessTimelineMenuItemNotFoundContainer = styled(Box)(() => ({
  height: '75px',
  paddingTop: '12px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ProcessTimelineMenuItemNotFoundText = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  color: stagesetColors.newGrey[500],
}));
