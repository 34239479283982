import { Typography, TypographyProps } from '@mui/material';

const DeleteTypography = (props: TypographyProps): JSX.Element => (
  <Typography
    component="span"
    {...props}
    sx={{
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
    }}
  >
    {props.children}
  </Typography>
);

export default DeleteTypography;
