import { SvgIcon, SvgIconProps } from '@mui/material';

export const PageIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M14.0302 5.88406L14.0311 5.8646L14.0311
      5.84512L14.0311 3.67245L16.686 6.24753L14.3886
      6.24753C14.1537 6.22883 14.0225 6.04271 14.0302 5.88406Z"
      strokeWidth="1.6"
      fill="none"
    />
    <path
      d="M3.23018 3.83325C3.23018 2.61823 4.21515 1.63325
      5.43018 1.63325H13.3417C13.9934 1.63325 14.6116 1.9222
      15.0296 2.42222L17.7847 5.71796C18.1156 6.11372 18.2968
      6.61315 18.2968 7.12899V16.1666C18.2968 17.3816 17.3119
      18.3666 16.0968 18.3666H5.43018C4.21515 18.3666 3.23018
      17.3816 3.23018 16.1666V3.83325Z"
      strokeWidth="1.6"
      fill="none"
    />
    <path
      d="M6.59668 14.9514H14.93"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M6.59668 11.9097H11.6503"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </SvgIcon>
);
