

export enum StageContentType {
    DEFAULT,
    WIDESCREENSTAGE,
    ESIGNATURE,
}

export enum WideScreenSectionType {
    EMBED,
    MEDIA,
}

export enum ContentType {
    GOOGLE_SHEETS = 'GOOGLE_SHEETS',
    GOOGLE_DOCS = 'GOOGLE_DOCS',
    GOOGLE_SLIDES = 'GOOGLE_SLIDES',
    GOOGLE_FORMS = 'GOOGLE_FORMS',
    GOOGLE_DRIVE = 'GOOGLE_DRIVE',
    ONE_DRIVE_WORD = 'ONE_DRIVE_WORD',
    ONE_DRIVE_EXCEL = 'ONE_DRIVE_EXCEL',
    ONE_DRIVE_POWER_POINT = 'ONE_DRIVE_POWER_POINT',
    YOUTUBE = 'YOUTUBE',
    VIMEO = 'VIMEO',
    LOOM = 'LOOM',
    PITCH = 'PITCH',
    BEHANCE = 'BEHANCE',
    PREZI = 'PREZI',
    DROPBOX = 'DROPBOX',
    TYPEFORM = 'TYPEFORM',
    AIRTABLE = 'AIRTABLE',
    VIDYARD = 'VIDYARD',
    FIGMA = 'FIGMA',
    IFRAME = 'IFRAME',
    URL = 'URL',
    CALENDLY = 'CALENDLY',
    CANVAS = 'CANVAS',
    MEDIA = 'MEDIA',
}

export type StageSignature = {
    requested: boolean;
    users: StageSignatureUser[]
}

export type StageSignatureUserAwaitingSign = {
    signed: boolean;
    ip: string;
    text: string;
    date: Date;
}
export type StageSignatureUserSigned = {
    signed: true;
    ip: string;
    text: string;
    date: Date;
}

export type StageSignatureUser = {
    id: string;
} & (StageSignatureUserAwaitingSign | StageSignatureUserSigned)
