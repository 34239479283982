import { SubscriptionTabPaymentPeriodOption, SubscriptionTabPaymentPeriodOptionProps } from './SubscriptionTabPaymentPeriodOption';
import { ESubscriptionType } from '../../../../../shared/Subscription';

export type PaymentPeriodOptionType = {
  title: string,
  value: SubscriptionTabPaymentPeriodOptionProps,
};

interface Props {
  options: PaymentPeriodOptionType[],
  type?: ESubscriptionType,
}

const SubscriptionTabPaymentPeriod = ({
  options,
  type,
}: Props): JSX.Element => (
  <SubscriptionTabPaymentPeriodOption {...options[0].value} type={type} />
);

export default SubscriptionTabPaymentPeriod;
