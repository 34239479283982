import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { ESubscriptionLimits } from '../../../shared/Subscription';
import { EAppRoutes } from '../../core/router';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import BuerDealItem from './BuyerDealItem';
import { allBuyerDealsSelector, getBuyerDeals } from './state/buyerDealsSlice';

const BuyerDeals = (): JSX.Element => {
  const buyerDeals = useAppSelector((state) => allBuyerDealsSelector(state));
  const { user } = useAppSelector((state) => state.app);
  const { subscription } = useAppSelector((state) => state.billing);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getBuyerDeals());
  }, [user?.id]);
  if (buyerDeals.length === 1 && subscription?.limits[ESubscriptionLimits.SELLER_DEAL_BOARD] !== 'ACTIVE') {
    navigate(`${EAppRoutes.deal}/${buyerDeals[0].id}`);
  }
  return (
    <>
      {(!!buyerDeals?.length)
        && (
          <Grid
            container
            spacing={3}
            sx={{
              pt: '20px',
              mt: '50px',
            }}
          >
            {
              buyerDeals?.map((process: ProcessDTO, i: number) => (
                <BuerDealItem key={i} deal={process} />
              ))
            }
          </Grid>
        )}
      {!buyerDeals?.length && (
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '93vh',
            flexDirection: 'column',
          }}
        >
          You haven't been invited to any deal as a Buyer yet.
        </Box>
      )}
    </>

  );
};

export default BuyerDeals;
