import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export const CloseIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12 4L4 12"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4L12 12"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
