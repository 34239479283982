import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, TextField, Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IShareJWTTokenPayload } from '../../../../shared/schema/token.schema';
import { EAuthResponcesAndErrors } from '../../../../shared/response/AuthResponse';
import { Analytics, EMixPanelEvents } from '../../../core/Analytics';
import DealConfirmBuyerAccountHeader from '../../../features/DealConfirmBuyerAccount/DealConfirmBuyerAccountHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import AuthButton from '../Components/AuthButton';
import PasswordVisibilityIcon from '../Components/PasswordVisibilityIcon';
import { logout, shareLoginProtected } from '../state/authState';
import FormHint from '../Components/FormHint';
import { EAppRoutes } from '../../../core/router';
import { Login } from '../Login/Login';
import PolicyDisclaimer from '../../../features/PolicyDisclaimer/PolicyDisclaimer';
import LanguageSwitcher from '../../../features/LanguageSwitcher/LanguageSwitcher';

interface FormState {
  email: string;
  password: string;
}

const analytics: Analytics = Analytics.getInstance();

const DealConfirmBuyerAccount = (): JSX.Element => {
  const navigate = useNavigate();
  const authToken: string = useAppSelector((state) => state.auth.authToken);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [dialogState, setDialogState] = useState<'signup' | 'login'>('signup');

  const {
    processId,
    accessLinkId,
    isEmailProtected,
    isPasswordProtected,
  }: IShareJWTTokenPayload = jwtDecode<IShareJWTTokenPayload>(authToken);

  const { status } = useAppSelector((state) => state.auth);

  const validationSchemaConfig = {};

  if (isPasswordProtected) {
    validationSchemaConfig.password = yup.string()
      .required(t('Error_Password_is_required'));
  }
  if (isEmailProtected) {
    validationSchemaConfig.email = yup.string().email()
      .required(t('Error_Email_is_required'));
  }

  const schema = yup.object(validationSchemaConfig).required();

  const onSubmit = async ({ email, password }: FormState) => {
    if (email || password) {
      try {
        await dispatch(shareLoginProtected({
          email,
          password,
          processId,
          accessLinkId,
        })).unwrap();
      } catch (e) {
        if (e && e.message) {
          setErrorMessage(e.message);
          if (e.message === EAuthResponcesAndErrors.EMAIL_TAKEN) {
            window.sessionStorage.setItem('userEmail', email);
            setDialogState('login');
          }
        }
      }
      analytics.track(EMixPanelEvents.VIEW_ROOM_ANONYMOUS_WITH_EMAIL_BUTTON_PRESSED);
    }
  };

  const {
    handleSubmit,
    control,
    register,
  } = useForm<FormState>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  register('email', {
    onChange: () => {
      setErrorMessage(undefined);
    },
  });

  register('password', {
    onChange: () => {
      setErrorMessage(undefined);
    },
  });

  const clearLocalStorageAuth = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('persist:auth');
    localStorage.removeItem('persist:root');
  };

  return (
    <>
      {
        dialogState === 'signup'
        && (
        <>
          <DealConfirmBuyerAccountHeader />
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              width: '100%',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <FormError */}
            {/* isVisible={!!errorMessage} */}
            {/* message={errorMessage} */}
            {/* /> */}
            <FormHint
              isVisible
              message={t('Room_Please_enter_mail_to_access_room')}
            />
            {
          isEmailProtected && (
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('Room_Email')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    borderRadius: '8px',
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '8px',
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error || ((!!errorMessage) && (errorMessage !== 'An invitation email was sent'))}
                  helperText={error ? error.message
                    : (errorMessage === 'An invitation email was sent')
                      ? 'Please login with your account to view the deal' : errorMessage}
                />
              )}
            />
          )
        }
            {
          isPasswordProtected && (
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('Room_Password')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error || !!errorMessage}
                  helperText={error ? error.message : null}
                  type={isPasswordVisible ? 'text' : 'password'}
                  sx={{
                    borderRadius: '8px',
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '8px',
                    },
                    endAdornment: <PasswordVisibilityIcon
                      setPasswordVisible={setPasswordVisible}
                      isPasswordVisible={isPasswordVisible}
                    />,
                  }}
                />
              )}
            />
          )
        }
            {
          (errorMessage === EAuthResponcesAndErrors.EMAIL_TAKEN) && (
            <Box
              onClick={() => {
                dispatch(logout());
                navigate(`${EAppRoutes.auth}${EAppRoutes.login}`);
              }}
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#0073F5',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowLeft />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  color: '#0073F5',
                  paddingTop: '1px',
                }}
              >
                Back to Login
              </Typography>
            </Box>
          )
        }
            <AuthButton status={status} title={t('Room_View_your_room')} />
            <PolicyDisclaimer />
          </Box>
          <Box>
            <LanguageSwitcher
              anchorVertical="top"
              anchorHorizontal="right"
              transformVertical="top"
              transformHorizontal="left"
            />
          </Box>
        </>
        )
      }
      {
        dialogState === 'login'
        && (
          <Login />
        )
      }
    </>
  );
};

export default DealConfirmBuyerAccount;
