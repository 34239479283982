import { SvgIcon, SvgIconProps } from '@mui/material';

export const RocketIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_7058_8689)">
      <path
        d="M6.03266 12.9582L6.08362 12.9079L6.12484
        12.8493C6.1271 12.8461 6.12959 12.8431 6.13228
        12.8402C6.32323 12.7244 6.51962 12.5466 6.63082
        12.2685C6.72789 12.0258 6.72409 11.7893 6.70757
        11.6223C6.80148 8.00238 7.84912 4.47115 9.74503
        1.38562L9.75308 1.37252L9.76062 1.35913C9.78245
        1.32032 9.81433 1.28821 9.85288 1.26613C9.89149
        1.24402 9.93535 1.23277 9.97982 1.23357C10.0243
        1.23437 10.0678 1.2472 10.1057 1.27072C10.1434
        1.29418 10.1742 1.32747 10.1947 1.36711L10.2106
        1.39791L10.2291 1.42721C11.234 3.01753 12.0002
        4.7466 12.5033 6.5592L12.5033 6.55922L12.5063
        6.56948C13.0143 8.3092 13.2884 10.1087 13.3216
        11.9207C13.3234 12.2109 13.4383 12.4894 13.6425
        12.6963L13.683 12.7373L13.7289 12.7721C14.058
        13.0216 14.3252 13.3434 14.5099 13.7127C14.6943
        14.0814 14.7915 14.4876 14.7939 14.8998C14.7939
        14.9005 14.7939 14.9011 14.7939 14.9018L14.7941
        18.2316C14.3518 17.627 13.8959 17.0054 13.4171
        16.4155C13.108 16.0166 12.707 15.6985 12.2483
        15.4882C11.7934 15.2797 11.2952 15.1835 10.7956
        15.2075L9.1747 15.2074C9.17414 15.2074 9.17358
        15.2074 9.17302 15.2074C8.61801 15.2054 8.07102
        15.3397 7.58 15.5983C7.0945 15.854 6.67837 16.2235
        6.36716 16.6752L5.15123 18.2906L5.14435 14.9903C5.16908
        14.2242 5.48698 13.4967 6.03266 12.9582ZM14.813
        19.2312C14.8129 19.2306 14.8127 19.2299 14.8126
        19.2293L14.813 19.2312Z"
        strokeWidth="1.6"
        fill="none"
      />
      <circle
        cx="9.99747"
        cy="8.64786"
        r="0.8"
        strokeWidth="1.3405"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_7058_8689">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>

  </SvgIcon>
);
