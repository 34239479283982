import { Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { shareLogin } from '../state/authState';
import { addUserByIdToProcessById, getProcessAccessLinkByHash } from '../../process/state/dealActions';
import { IShareJWTTokenPayload } from '../../../../shared/schema/token.schema';
import { selectAccessLink } from '../../process/state/processSlice';
import { useQuery } from '../../../hooks/useQuery';
import { AppLoader } from '../../../shared/AppLoader/AppLoader';

export const ShareAuthorization = (): JSX.Element => {
  const { hash: hashFromParams } = useParams();
  const query = useQuery();
  const hashFromQuery: string | null = query.get('hash');
  const hash = hashFromParams ?? hashFromQuery;
  const accessLink = useAppSelector(selectAccessLink);
  const [userInDeal, setUserInDeal] = useState(false);
  const {
    isAuthenticated,
    authToken,
  } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hash) dispatch(getProcessAccessLinkByHash(hash));
  }, [hash]);

  const handleAccessLinkChange = async () => {
    if (accessLink && hash) {
      if (isAuthenticated && authToken) {
        const { id: userId }: IShareJWTTokenPayload = jwtDecode<IShareJWTTokenPayload>(authToken);
        if (userId) {
          await dispatch(addUserByIdToProcessById({
            userId,
            processId: accessLink.processId,
          }));
        }
        setUserInDeal(true);
      } else {
        dispatch(shareLogin({
          hash,
        }));
      }
    }
  };

  useEffect(() => {
    handleAccessLinkChange();
  }, [accessLink]);

  useEffect(() => {
    if (isAuthenticated && accessLink && userInDeal) {
      navigate(`${EAppRoutes.deal}/${accessLink.processId}`);
    }
  }, [isAuthenticated, accessLink, userInDeal]);

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        gap: '8px',
      }}
    >
      <AppLoader
        size={24}
      />
      <Typography
        sx={{
          color: '#1A1929',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '26px',
        }}
      >
        Loading
      </Typography>
    </Container>
  );
};
