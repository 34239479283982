import { ESubscriptionType } from '../../../shared/Subscription';
import { ESettingsTabs } from '../../features/Layout/lib/fieldsLayoutSlice';
import ProfileTab from '../../routes-old/account/SettingsTabs/ProfileTab';
import SubscriptionTab from '../../routes-old/account/SettingsTabs/SubscriptionTab';
import TeamTab from '../../routes-old/account/SettingsTabs/TeamTab';
import SettingsPublicApiToken from '../../routes-old/account/SettingsTabs/public-api-settings/SettingsPublicApiToken';
import TeammateOrganizationTab from '../../routes-old/account/SettingsTabs/TeammateOrganizationTab';
import TeammanagerCustomDomainTab from '../../routes-old/account/SettingsTabs/TeammanagerCustomDomainTab';
import TeammateCustomDomainTab from '../../routes-old/account/SettingsTabs/TeammateCustomDomainTab';
import TeammanagerOrganizationTab from '../../routes-old/account/SettingsTabs/TeammanagerOrganizationTab';
import TeammanagerIntegrationsTab from '../../routes-old/account/SettingsTabs/TeammanagerIntegrationsTab';

export type SettingsMenuItem = {
  text: string,
  subscription: ESubscriptionType,
  settingsTab: ESettingsTabs,
  teammanagerComponent: JSX.Element,
  teammateComponent: JSX.Element,
  requirePublicApiEnabled?: boolean,
};

export const settingsMenuItems: SettingsMenuItem[] = [
  {
    text: 'Settings_Profile',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.PROFILE,
    teammanagerComponent: <ProfileTab />,
    teammateComponent: <ProfileTab />,
  },
  {
    text: 'Team_Team',
    subscription: ESubscriptionType.SELLER_BASIC,
    settingsTab: ESettingsTabs.SETTINGS_TEAM,
    teammanagerComponent: <TeamTab />,
    teammateComponent: <TeamTab />,
  },
  {
    text: 'Settings_Custom_Domain',
    subscription: ESubscriptionType.SELLER_BASIC,
    settingsTab: ESettingsTabs.CUSTOM_DOMAIN,
    teammanagerComponent: <TeammanagerCustomDomainTab />,
    teammateComponent: <TeammateCustomDomainTab />,
  },
  {
    text: 'Settings_Organization',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.ORGANIZATION,
    teammanagerComponent: <TeammanagerOrganizationTab />,
    teammateComponent: <TeammateOrganizationTab />,
  },
  {
    text: 'Subscriptions_Subscription',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.SUBSCRIPTION,
    teammanagerComponent: <SubscriptionTab />,
    teammateComponent: <SubscriptionTab />,
  },
  {
    text: 'Integrations_Integrations',
    subscription: ESubscriptionType.SELLER_PREMIUM,
    settingsTab: ESettingsTabs.INTEGRATIONS,
    teammanagerComponent: <TeammanagerIntegrationsTab />,
    teammateComponent: <TeammanagerIntegrationsTab />,
  },
  {
    text: 'Settings_Public_Api_Token',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.PUBLIC_API_TOKENS,
    teammanagerComponent: <SettingsPublicApiToken />,
    teammateComponent: <SettingsPublicApiToken />,
    requirePublicApiEnabled: true,
  },
];
