import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { CloseIcon20 } from '../../../icons/CloseIcon';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import ProcessTimelineDateFilter from '../../../widgets/ProcessTimeline/ProcessTimelineDateFilter';
import ProcessTimelineEventsFilterList from '../../../widgets/ProcessTimeline/ProcessTimelineEventsFilterList';
import ProcessTimelineTeammatesFilter from '../../../widgets/ProcessTimeline/ProcessTimelineTeammatesFilter';
import ProcessTimeLineUsersFilterList from '../../../widgets/ProcessTimeline/ProcessTimeLineUsersFilterList';
import {
  SidebarTabContentWrapper, SidebarHeader, HeaderTitle, SidebarContent,
} from '../../Stages/Styles/SidebarStyles';
import { setIsInternalFilters } from '../lib/feedSlice';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { stagesetColors } from '../../../theme/stagesetPalette';

const InternalTabFilters = (): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        width: isMobile ? '100%' : '375px',
        background: stagesetColors.newGrey[50],
      }}
    >
      <SidebarTabContentWrapper
        className="sidebar-wrapper-default"
      >
        {
          !isMobile
          && (
          <SidebarHeader>
            <HeaderTitle
              className="grey-header-title"
            >
              Filters
            </HeaderTitle>
            <AppIconButton
              transparentStroke
              icon={<CloseIcon20 />}
              onClick={() => dispatch(setIsInternalFilters(false))}
            />
          </SidebarHeader>
          )
        }
        <SidebarContent
          className="sidebar-filters"
        >
          <ProcessTimeLineUsersFilterList />
          <ProcessTimelineEventsFilterList />
          <ProcessTimelineDateFilter />
          <ProcessTimelineTeammatesFilter />
        </SidebarContent>
      </SidebarTabContentWrapper>
    </Box>
  );
};

export default InternalTabFilters;
