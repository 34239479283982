import { config } from '..';
import { ESocialAuthProvider, ESocialAuthTypes } from '../../../shared/schema/auth.shcema';

export class DomainHelper {
  static getActualDomain(isRedirect: boolean = false): string {
    /**
     * Find what is the actual domain, because sometimes it can be app.staging.stageset.io or app.stageset.io
     */
    const { host } = window.location;

    const domainUrl = new URL(config.app.domain as string);
    let actualDomain = domainUrl.host;

    // If we need the actual domain to calcualte the redirect url we need the domain from config
    if (isRedirect) {
      return actualDomain as string;
    }

    const originUri = new URL(config.app.origin);
    if (host.includes(originUri.host)) {
      actualDomain = originUri.host;
    }

    const appOriginUri = new URL(config.app.origin);
    if (host.includes(appOriginUri.host)) {
      actualDomain = appOriginUri.host;
    }

    return actualDomain as string;
  }

  static getAllAvailableDomains(): string[] {
    const domains = [];

    const domainUrl = new URL(config.app.domain as string);
    domains.push(domainUrl.hostname);

    const originUri = new URL(config.app.origin);
    domains.push(originUri.hostname);

    const appOriginUri = new URL(config.app.origin);
    domains.push(appOriginUri.hostname);

    return domains;
  }

  static getSubdomainValue(isRedirect: boolean = false): string {
    let subdomain = '';
    let { host } = window.location;
    // Sometimes the main domain can have multiple parts, for example in staging env
    if (!DomainHelper.deductIfMayHaveSubdomain(host, isRedirect)) {
      return subdomain;
    }

    /**
     * remove the actual domain part of host so that the subdomain extraction will be easier
     */
    const actualDomain = DomainHelper.getActualDomain(isRedirect);
    host = host.replace(actualDomain, '');
    // remove the . at the end
    if (host.endsWith('.')) {
      subdomain = host.slice(0, -1);
    }

    return subdomain;
  }

  static deductIfMayHaveSubdomain(currentHost: string, isRedirect: boolean = false): boolean {
    const actualDomain = DomainHelper.getActualDomain(isRedirect);
    if (currentHost == actualDomain) {
      return false;
    }

    return true;
  }

  static replaceSubdomain(newSubdomain: string, access_token: string, tokenType: string): string {
    const subdomain = DomainHelper.getSubdomainValue(!!newSubdomain);
    const location = window.location.href;
    let newLocation = '';

    if (DomainHelper.isCustomDomain()) {
      const originUrl = new URL(config.app.origin as string);
      const originPath = originUrl.pathname == '/' ? '' : originUrl.pathname;
      const domainUrl = new URL(config.app.domain as string);
      const newHostname = `${newSubdomain}${newSubdomain ? '.' : ''}${newSubdomain ? domainUrl.hostname : originUrl.hostname}`;

      const newHref = location.replace(window.location.hostname, newHostname);
      const newHrefUrl = new URL(newHref);

      const newUrl = new URL(`${newHrefUrl.origin}${originPath}/auth/custom-domain`);
      newUrl.searchParams.append('access_token', access_token);
      newUrl.searchParams.append('tokenType', tokenType);
      newLocation = newUrl.toString();
    } else if (subdomain) {
      /**
       * in case we need to redirect to the main domain
       */
      const toReplace = newSubdomain ? subdomain : `${subdomain}.`;
      newLocation = location.replace(toReplace, newSubdomain);
    } else if (newSubdomain) {
      const { host } = window.location;
      const newHost = `${newSubdomain}.${host}`;
      newLocation = location.replace(host, newHost);
    } else {
      /**
       * This the case that the old and new location are empty string
       */
      newLocation = location;
    }
    /**
     * trim the auth/login, if we redirect we do not want to redirect to login page again
     */
    newLocation = newLocation.replace('auth/login', '');

    return newLocation;
  }

  static replaceCustomDomain(customDomain: string, access_token: string, tokenType: string): string {
    const location = window.location.href;
    const newLocation = `http://${DomainHelper.getDomainFromString(customDomain)}`;

    const originUrl = new URL(config.app.origin);
    const originPath = originUrl.pathname == '/' ? '' : originUrl.pathname;
    const url: URL = new URL(`${newLocation}${originPath}/auth/custom-domain`);
    url.searchParams.append('access_token', access_token);
    url.searchParams.append('tokenType', tokenType);

    return url.toString();
  }

  static getFirstValidDomain() : string {
    const url = new URL(config.app.domain as string);
    const retval = url.hostname;

    return retval;
  }

  static getDomainFromString(input: string): string {
    try {
      if (input.toLowerCase().startsWith('http')) {
        const url = new URL(input);

        return url.hostname;
      }

      return input;
    } catch (e) {
      // Silent catch
    }
    return '';
  }

  static isCustomDomain(): boolean {
    const baseUrl = new URL(config.app.domain as string);

    return !window.location.hostname.includes(baseUrl.hostname);
  }

  static replaceCustomDomainAuth(
    socialAuthType: ESocialAuthTypes,
    provider: ESocialAuthProvider,
    celloReferrer?: string,
  ): string {
    const url = new URL(`${config.app.origin}/auth/custom-domain-social-auth`);
    url.searchParams.append('socialAuthType', socialAuthType);
    url.searchParams.append('provider', provider);
    if (celloReferrer) {
      url.searchParams.append('celloReferrer', celloReferrer);
    }

    return url.toString();
  }
}
