import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckCircle20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <g clipPath="url(#clip0_709_4054)">
      <path
        d="M10 18.3333C14.6024 18.3333 18.3333
        14.6024 18.3333 10C18.3333 5.39763 14.6024
        1.66667 10 1.66667C5.39762 1.66667 1.66666
        5.39763 1.66666 10C1.66666 14.6024 5.39762
        18.3333 10 18.3333Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.5 7.5L8.6875 12.5L6.5 10.2273"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_709_4054">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
