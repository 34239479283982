import { styled } from '@mui/styles';
import { Tab, Tabs } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const SettingsTabs = styled(Tabs)(() => ({
  '& button': {
    color: stagesetColors.newGrey[500],
  },
  '&.settings-tabs-mobile': {
    display: 'flex',
    gap: '2px',
    padding: '2px',
    backgroundColor: stagesetColors.white[100],
    borderRadius: '10px 0 0 10px',
    margin: '8px 0px 8px 16px',
    minHeight: '40px',
    height: '40px',
    boxShadow: '0px 0px 0px 0px rgba(0, 113, 190, 0.08),'
    + '0px 0px 1px 0px rgba(0, 113, 190, 0.08),'
    + '0px 1px 1px 0px rgba(0, 113, 190, 0.07),'
    + '0px 3px 2px 0px rgba(0, 113, 190, 0.04)',
    '& button': {
      color: stagesetColors.newGrey[800],
      minHeight: '36px',
      height: '36px',
    },
    '& .Mui-disabled': {
      color: stagesetColors.newGrey[300],
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .Mui-selected': {
    borderRadius: '10px',
    backgroundColor: stagesetColors.newGrey[200],
    color: stagesetColors.newGrey[800],
  },
  '& .Mui-disabled': {
    '& button': {
      color: stagesetColors.newGrey[300],
    },
  },
}));

export const SettingsTab = styled(Tab)(() => ({
  '&.settings-tabs-mobile': {
    textAlign: 'center',
    alignItems: 'center',
    padding: '8px 16px 8px 0px',
    marginBottom: '12px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  '&.settings-tab-desktop': {
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '9px 16px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    borderRadius: '10px',
    '&:hover': {
      background: stagesetColors.newGrey[50],
      color: stagesetColors.newGrey[800],
    },
    '&:active': {
      background: stagesetColors.newGrey[300],
      color: stagesetColors.newGrey[800],
    },
  },
}));
