import { UserDealNotificationsBulkResponse, UserDealNotificationsResponse } from '../../shared/notifications/Notifications';
import { config } from '../core';
import { ApiService } from '../core/api';

export class NotificationsApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('notifications', config.api.notificationsBaseUrl, config.api.notificationsUrlPrefix);
  }

  async getUserDealNotifications(processId: string): Promise<UserDealNotificationsResponse> {
    return this.api.post<UserDealNotificationsResponse>('/user-deal', { processId });
  }

  async getNewUserDealNotifications(processId: string, createdAtLTE?: string): Promise<UserDealNotificationsResponse> {
    return this.api.post<UserDealNotificationsResponse>(
      '/user-deal-new',
      { processId, createdAtLTE },
      { timeout: 10000 },
    );
  }

  async getUserDealsNotificationsBulk(processIds: string[]): Promise<UserDealNotificationsBulkResponse> {
    return this.api.post<UserDealNotificationsBulkResponse>('/user-deal-bulk', { processIds });
  }

  async markAsRead(processIds: string[]): Promise<string[]> {
    return this.api.post<string[]>('/mark-as-completed', { ids: processIds });
  }
}
