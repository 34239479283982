import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { EMediaTypeTags } from '../Helpers/MediaFieldHelpers';
import { ContentType } from '../../../../shared/Stage';

interface Props {
  name: string,
  source?: string,
  header?: boolean,
  typeTag?: EMediaTypeTags,
  contentTag?: ContentType,
  onSelect: (data: any) => void,
  description: string,
  icon?: JSX.Element,
  selectedCurrently: string,
}

const SourceOption = ({
  name,
  source,
  header,
  typeTag,
  contentTag,
  onSelect,
  description,
  icon,
  selectedCurrently,
}: Props): JSX.Element => {
  const isSelected: boolean = selectedCurrently === name;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {
        header
        && !isMobile
        && (
          <Typography
            sx={{
              height: '24px',
              paddingLeft: '16px',
              fontSize: '12px',
              color: '#455273',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {typeTag}
          </Typography>
        )
      }
      <Box
        sx={{
          paddingLeft: '28px',
          display: 'flex',
          width: '100%',
          height: '48px',
          cursor: 'pointer',
          '&:hover': {
            background: '#E5EDFF',
          },
        }}
        onClick={(e) => {
          e.stopPropagation(); onSelect({
            name,
            source,
            description,
            icon,
            contentTag,
          });
        }}
      >
        {
          icon
          && (
            <Box
              sx={{
                width: '30px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {icon}
            </Box>
          )
      }
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: '#1A1929',
            fontSize: '14px',
            fontWeight: 400,
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default SourceOption;
