import { Box, Typography } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import classNames from 'classnames';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { formateValueForLastActive } from '../../routes-old/process/helpers';
import ProcessHealthDisplay from '../../widgets/ProcessTimeline/ProcessHealthDisplay';
import { ESidebarTabs, selectSidebarTab } from '../Layout/lib/fieldsLayoutSlice';
import ProcessScore from '../ProcessScore/ProcessScore';
import StagesSidebarHeader from './StagesSidebarHeader';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectProcess } from '../../routes-old/process/state/processSlice';
import { DefaultOrgCurrency } from '../../../shared/OrganizationDTO';
import { selectOrganization } from '../../core/store/appState/appState';
import { SidebarTabContentWrapper, SidebarTabPanel } from './Styles/SidebarStyles';

const SidebarInternalTab = (): JSX.Element => {
  const currentTab = useAppSelector(selectSidebarTab) === ESidebarTabs.INTERNAL;
  const process = useAppSelector(selectProcess);
  const organization = useAppSelector(selectOrganization);

  return (
    <SidebarTabPanel
      value={ESidebarTabs.INTERNAL}
      className={classNames('sidebar-themed', { 'active-sidebar-tab': currentTab })}
    >
      <SidebarTabContentWrapper
        className="sidebar-wrapper-default"
      >
        <StagesSidebarHeader />
        <Box sx={{
          display: 'flex',
          marginLeft: '26px',
          marginRight: '26px',
          flexDirection: 'column',
          overflow: 'auto',
          gap: '10px',
          '& .row': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          },
          '& .title': {
            fontSize: '14px',
            fontFamily: 'Inter',
            minWidth: '120px',
            color: (theme) => greySidebarContrastText(theme, 0.6),
          },
          '& .value': {
            fontSize: '16px',
            fontFamily: 'Inter',
            display: 'flex',
            gap: '4px',
            color: (theme) => greySidebarContrastText(theme, 0.8),
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        >
          <ProcessHealthDisplay />
          <Box className="row">
            <Box className="title">Company</Box>
            <Box className="value">{process?.client}</Box>
          </Box>
          <Box className="row">
            <Box className="title">Value</Box>
            <Box className="value">
              <Typography>
                {getSymbolFromCurrency(organization.currency || DefaultOrgCurrency)}
                &nbsp;
                {process?.value}
              </Typography>
            </Box>
          </Box>
          <Box className="row">
            <Box className="title">Last active</Box>
            <Box className="value">{formateValueForLastActive(process?.lastActive)}</Box>
          </Box>
          <ProcessScore />
        </Box>
      </SidebarTabContentWrapper>
    </SidebarTabPanel>
  );
};

export default SidebarInternalTab;
