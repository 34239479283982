import {
  Box, Popper,
} from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TCommandListProps } from '../../Utils/TiptapTypes';
import { useAppSelector } from '../../../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../../../routes-old/process/state/processSlice';
import TiptapNodeMenuItems from '../../CreateNodeMenu/TiptapNodeMenuItems';
import { stagesetColors } from '../../../../theme/stagesetPalette';

const CommandList = (props: TCommandListProps): JSX.Element => {
  const {
    decorationNode,
    editor,
    range,
    items,
  } = props;
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const [isTable, setIsTable] = useState<boolean>(false);

  useEffect(() => {
    const parentName = editor?.$pos(range.from)?.parent?.node?.type?.name;
    setIsTable((parentName === 'tableHeader') || (parentName === 'listItem'));
  }, [range]);

  return (
    <NodeViewWrapper>
      <Popper
        open={decorationNode && isLayoutEdit && !!items.length}
        disablePortal
        anchorEl={decorationNode}
        placement="right-start"
        sx={{
          zIndex: 11,
          border: '1px solid',
          borderColor: `${stagesetColors.newGrey[400]} !important`,
          boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04),'
              + '0px 1px 1px rgba(0, 113, 190, 0.07),'
              + '0px 0px 1px rgba(0, 113, 190, 0.08),'
              + '0px 0px 0px rgba(0, 113, 190, 0.08) !important',
          borderRadius: '12px',
          backgroundColor: stagesetColors.white[100],
          width: '240px',
          padding: '8px',
          ...isMobile ? {
            maxHeight: '380px',
            height: '380px',
            overflow: 'auto',
          } : {
            overflow: 'hidden',
          },
        }}
        modifiers={
          [
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]
        }
      >
        <TiptapNodeMenuItems
          editor={editor}
          onClose={() => setIsTable(false)}
          range={range}
          items={items}
          isTable={isTable}
        />
      </Popper>
    </NodeViewWrapper>
  );
};

export default CommandList;
