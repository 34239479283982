import Cookies from 'js-cookie';
import { DomainHelper } from './DomainHelper';
import { config } from '../../core'

export class SharedLocalStorage {

  static setItem = (key: string, value: string) => {
    localStorage.setItem(key, value);

    Cookies.set(`${config.app.cookiePrefix}${key}`, value, { domain: DomainHelper.getFirstValidDomain() });
  }

  static getItem = (key: string) => {
    return window.localStorage.getItem(key) || SharedLocalStorage.getCookie(key);
  }

  static removeItem = (key: string) => {
    window.localStorage.removeItem(key);
  }

  static getCookie = (name: string) => {
    const cookie =  Cookies.get(`${config.app.cookiePrefix}${name}`);

    return cookie;
  }

  static removeCookie = (key: string) => {
    const domain = DomainHelper.getFirstValidDomain();
    Cookies.remove(`${config.app.cookiePrefix}${key}`, { domain });
    /**
     * in case the cookie is not in the main domain
     */
    const domains = DomainHelper.getAllAvailableDomains();
    for (const dm of domains) {
      Cookies.remove(`${config.app.cookiePrefix}${key}`, { domain: dm });
    }
  }

  static clearLocalStorageSession = () => {
    localStorage.clear();
  }
}
