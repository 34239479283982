import { useState } from 'react';
import AdminOrganizationsHeader from './AdminOrganizationsHeader';
import AdminOrganizationsDataGrid from './AdminOrganizationsDataGrid';

const AdminOrganizationsContainer = (): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState('');

  return (
    <>
      <AdminOrganizationsHeader searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
      <AdminOrganizationsDataGrid searchFilter={searchFilter} />
    </>
  );
};

export default AdminOrganizationsContainer;
