import {
  ConfirmSocialAccountRequest,
  GuestConfirmationData,
  GuestValidationData,
  GuestValidationResponce,
  IConfirmAccountRequest,
  ILoginRequest,
  IShareLoginRequest,
  ResetPasswordConfirmRequest, ShareLoginProtectedRequest,
} from '../../shared/schema/auth.shcema';
import { ILoginResponse } from '../../shared/schema/token.schema';
import { ApiService } from '../core/api';

export class AuthApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('auth');
  }

  async login(payload: ILoginRequest): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('login', payload, { });
  }

  async validateGuest(payload: GuestValidationData): Promise<GuestValidationResponce> {
    return this.api.post<GuestValidationResponce>('guest-validate', payload, { });
  }

  async confirmGuest(payload: GuestConfirmationData): Promise<any> {
    return this.api.post<any>('guest-confirm', payload, { });
  }

  async signup(payload: { email: string, celloReferrer?: string }, deal: string | null): Promise<string> {
    return this.api.post<string>('signup', { email: payload.email, celloReferrer: payload.celloReferrer, deal });
  }

  async resetPassword(payload: string): Promise<void> {
    return this.api.post<void>('reset-password', { email: payload });
  }

  async resetPasswordConfirm(payload: ResetPasswordConfirmRequest): Promise<void> {
    return this.api.post<void>('reset-password-confirm', payload);
  }

  async confirmAccount(payload: IConfirmAccountRequest): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('confirm-account', payload, {});
  }

  async confirmAccountSocialSignup(payload: ConfirmSocialAccountRequest): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('confirm-account-social', payload, {});
  }

  async confirmProcessAccount(payload: IConfirmAccountRequest): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('confirm-process-account', payload, {});
  }

  async shareLogin(payload: IShareLoginRequest): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('share-login', payload, {});
  }

  async shareLoginProtected(payload: ShareLoginProtectedRequest): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('share-login-protected', payload, {});
  }

  async resendActivationEmail(email: string): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>('resend-activation-link', { email });
  }
}
