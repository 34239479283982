import { Paper, PaperProps } from '@mui/material';

const AuthPaper = (props: PaperProps) => {
  const { children } = props;
  return (
    <Paper
      sx={{
        padding: '32px',
        width: '400px',
        boxShadow: '0px 4px 20px -2px rgba(0, 57, 96, 0.05), 0px 0px 3px rgba(0, 57, 96, 0.1)',
        background: 'white',
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default AuthPaper;
