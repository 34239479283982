import { Box, Popover } from '@mui/material';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { TaskEventData } from '../../../../../shared/TaskDTO';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { CloseIcon } from '../../../../icons/Close16px';
import { ExternalLink } from '../../../../icons/ExternalLink';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import { stagesetColors } from '../../../../theme/stagesetPalette';
import { ThreeDotsVert20px } from '../../../../icons/ThreeDots';
import { AppDropdown } from '../../../../shared/AppDropdown/AppDropdown';
import TrashIcon from '../../../../icons/TrashIcon';
import { PenIcon20 } from '../../../../icons/PenIcon';
import useFrontEventsApi from '../../../../hooks/useFrontEventsApi';
import {
  AgendaEventLinkButtonContainer,
  AgendaEventPopoverHeaderContainer,
  AgendaEventPopoverInputsContainer,
  AgendaEventPopoverSubtitle,
  AgendaEventPopoverTitle,
  AgendaEventPopoverTitleWrapper,
} from './styles/AgendaStyles';
import { CloseIcon20 } from '../../../../icons/CloseIcon';
import { parseUrlStart } from '../../../../common/helpers';

interface Props {
  id: string;
  eventData: TaskEventData;
  onEventDataUpdate: (newData: TaskEventData) => void;
}

export interface FormState {
  linkName: string;
  linkUrl: string;
}

const AgendaEvent = ({
  id,
  onEventDataUpdate,
  eventData,
}: Props): JSX.Element => {
  const {
    url = '',
    name = '',
  } = eventData;
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<Element | null>(null);
  const { createTaskEventLinkClickedEvent } = useFrontEventsApi();
  const [disabled, setDisabled] = useState<boolean>(true);

  const linkButtonRef = useRef<HTMLButtonElement | null>(null);
  const moreMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const agendaEventSchemaConfig = useMemo(() => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    validationSchemaConfigTemp.linkName = yup.string();
    validationSchemaConfigTemp.linkUrl = yup.string().required('URL is required');
    return validationSchemaConfigTemp;
  }, []);

  const schema = yup.object(agendaEventSchemaConfig).required();

  const agendaEventDefaultData: FormState = {
    linkName: url,
    linkUrl: name,
  };

  const agendaEventDeleteData: FormState = {
    linkName: '',
    linkUrl: '',
  };

  const agendaEventFormMethods = useForm<FormState>({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: agendaEventDefaultData,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isValid, isDirty },
  } = agendaEventFormMethods;

  const handleMoreMenuClose = () => {
    setMoreMenuAnchorEl(null);
  };

  const handleOpen = () => {
    if (url) {
      createTaskEventLinkClickedEvent({
        taskId: id,
        linkUrl: url,
        linkName: name,
      });
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      setPopoverAnchorEl(linkButtonRef.current);
    }
  };

  useEffect(() => {
    if (url) {
      reset({
        linkName: name,
        linkUrl: url,
      });
    }
  }, [url, name]);

  const handleMoreMenuOpen = () => {
    setMoreMenuAnchorEl(moreMenuButtonRef.current);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    if (!isValid || !isDirty) {
      setDisabled(true);
    }
    if (isDirty && isValid) {
      setDisabled(false);
    }
  }, [isValid, isDirty]);

  const eventDataSave = (data: FormState) => {
    const eventURL = parseUrlStart(data.linkUrl);
    onEventDataUpdate({ url: eventURL, name: data.linkName });
    reset({ linkUrl: eventURL, linkName: data.linkName });
    handleClose();
  };

  return (
    <>
      <AgendaEventLinkButtonContainer
        ref={linkButtonRef}
      >
        <AppButton
          size={url ? 'l-ds' : 'l'}
          variant="tertiary-newGrey-ds-left"
          startIcon={<ExternalLink />}
          onClick={handleOpen}
          autoWidth
          sx={{
            justifyContent: url ? 'start' : 'center',
            '& span:last-of-type': {
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <span>
            {url !== '' ? name ?? 'External link' : 'Add link'}
          </span>
        </AppButton>
        {url
          && (
          <AppIconButton
            size="l-ds-icon"
            variant="tertiary-newGrey-ds-right"
            icon={<ThreeDotsVert20px />}
            onClick={handleMoreMenuOpen}
          />
          )}
        <Box ref={moreMenuButtonRef} />
      </AgendaEventLinkButtonContainer>
      <AppDropdown
        size="m"
        items={[
          {
            label: 'Edit',
            startIcon: <PenIcon20 />,
            onClick: () => {
              setPopoverAnchorEl(linkButtonRef.current);
              handleMoreMenuClose();
            },
          },
          {
            label: '',
            divider: true,
          },
          {
            label: 'Delete',
            labelColor: '#C94937',
            startIcon: <TrashIcon
              sx={{
                stroke: stagesetColors.red[600],
              }}
            />,
            onClick: () => {
              onEventDataUpdate({ name: '', url: '' });
              reset(agendaEventDeleteData);
              handleMoreMenuClose();
            },
          },
        ]}
        lastElementHasAnotherColor
        open={!!moreMenuAnchorEl}
        anchorEl={moreMenuAnchorEl}
        onClose={() => setMoreMenuAnchorEl(null)}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& ul': {
            width: '240px',
          },
        }}
      />
      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              background: stagesetColors.white[100],
              width: '400px',
              minWidth: '300px',
              border: '1px solid',
              borderColor: stagesetColors.blue[100],
              borderRadius: '16px',
            },
          },
        }}
      >
        <AgendaEventPopoverHeaderContainer>
          <AgendaEventPopoverTitleWrapper>
            <AgendaEventPopoverTitle>
              Create link
            </AgendaEventPopoverTitle>
            <AgendaEventPopoverSubtitle>
              Add a call-to-action based on links
            </AgendaEventPopoverSubtitle>
          </AgendaEventPopoverTitleWrapper>
          <AppIconButton
            onClick={handleClose}
            transparentStroke
            icon={<CloseIcon />}
            size="m"
          />
        </AgendaEventPopoverHeaderContainer>
        <AgendaEventPopoverInputsContainer
          component="form"
          onSubmit={handleSubmit(eventDataSave)}
        >
          <Controller
            name="linkName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AppInput
                fullWidth
                value={value}
                onChange={onChange}
                size="m"
                label="Link name"
                InputProps={{
                  endAdornment: (value !== ''
                    ? (
                      <button
                        className="end-adorment-button"
                        type="button"
                        aria-label="name"
                        onClick={() => {
                          setValue('linkName', '', { shouldDirty: true });
                        }}
                        onMouseDown={() => {
                          setValue('linkName', '', { shouldDirty: true });
                        }}
                      >
                        <CloseIcon20 />
                      </button>
                    ) : null
                  ),
                }}
              />
            )}
          />
          <Controller
            name="linkUrl"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AppInput
                required
                label="URL"
                fullWidth
                value={value}
                onChange={onChange}
                size="m"
                InputProps={{
                  endAdornment: (value !== ''
                    ? (
                      <button
                        className="end-adorment-button"
                        type="button"
                        aria-label="url"
                        onClick={() => {
                          setValue('linkUrl', '', { shouldDirty: true });
                        }}
                        onMouseDown={() => {
                          setValue('linkUrl', '', { shouldDirty: true });
                        }}
                      >
                        <CloseIcon20 />
                      </button>
                    ) : null
                  ),
                }}
              />
            )}
          />
          <AppButton
            variant="primary"
            type="submit"
            fullWidth
            size="l"
            disabled={disabled}
            sx={{
              marginTop: '8px',
            }}
          >
            Save
          </AppButton>
        </AgendaEventPopoverInputsContainer>
      </Popover>
    </>
  );
};

export default AgendaEvent;
