import { v4 } from 'uuid';
import {
  ProcessStageDTO,
  TCreateProcessItemReq, TStageItem, TUpdateProcessItem,
} from '../../shared/Field/Field';
import { ApiService } from '../core/api';
import { TFileItemUpload } from '../../shared/FileItems/FileItems';

export const getFileName = (fileName: string, shouldEscape?: boolean) => {
  let result = fileName;
  if (shouldEscape) {
    result = fileName.replaceAll(' ', '_').replaceAll('+', '_');
  }
  return `${v4()}_${result}`;
};

export const prepareFormData = async (payload: TFileItemUpload, parse?: boolean): Promise<FormData> => {
  const formData: FormData = new FormData();

  formData.append('file', payload.file.slice());
  formData.append('fileName', getFileName(payload.name, parse));

  for (const k of Object.keys(payload)) {
    if (k !== 'file' && k !== 'fileName') {
      formData.append(k, payload[k]);
    }
  }

  return formData;
};

export class ProcessItemsApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('process-fields');
  }

  async getProcessItems(id: string): Promise<TStageItem[]> {
    return this.api.get<TStageItem[]>(`/process-items/${id}`);
  }

  async getTeplateStageItems(id: string): Promise<TStageItem[]> {
    return this.api.get<TStageItem[]>(`/template-stage-items/${id}`);
  }

  async getTeplateSectionItems(id: string): Promise<TStageItem[]> {
    return this.api.get<TStageItem[]>(`/template-section-items/${id}`);
  }

  async getProcessItem(id: string): Promise<TStageItem> {
    return this.api.get<TStageItem>(`/process-item/${id}`);
  }

  async updateProcessItem(data: TUpdateProcessItem): Promise<TStageItem> {
    return this.api.post<TStageItem>('/process-items/update', data);
  }

  async updateManyProcessItems(data: TUpdateProcessItem[]): Promise<ProcessStageDTO[]> {
    return this.api.post<ProcessStageDTO[]>('/process-items/update-many', data);
  }

  async uploadItemFile(data: TFileItemUpload): Promise<ProcessStageDTO> {
    const formData = await prepareFormData(data);

    return this.api.post<ProcessStageDTO>('/process-items/upload-file', formData);
  }
}
