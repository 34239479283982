import { MoreVert } from '@mui/icons-material';
import {
  Avatar, Box, Menu, TextField,
} from '@mui/material';
import {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ClearbitAutocompleteDTO } from '../../../../shared/ClearbitDTO';
import AddSectionItem from '../../../features/BlockContentSection/BlockContentRowMenu/AddSectionItem';
import CompanyAndLogoSelect from '../../../features/CreateDealDialog/CompanyAndLogoSelect';
import { CompanyLogoIcon } from '../../../icons/CompanyLogoIcon';
import { ReplaceIcon20 } from '../../../icons/ReplaceIcon';
import DeleteSectionSvg from '../../../icons/SectionMenuIcons/Delete';
import { useAppSelector } from '../../../hooks/stateHooks';
import { selectOrganizationSignup } from '../../../core/store/appState/appState';
import { EOrganizationRoles } from '../../../../shared/permissions';

const maxFileSize = 1024 * 1024; // 1mb

export const defauldLogoData = {
  file: undefined,
  url: undefined,
  role: undefined,
};

export type AccConfCompanyData = {
  file?: File,
  url?: string,
  name?: string,
  role?: string
};

interface Props {
  companyData: AccConfCompanyData;
  setCompanyData: React.Dispatch<React.SetStateAction<AccConfCompanyData>>;
}

const ConfirmAccountOrgDataField = ({
  companyData,
  setCompanyData,
}:Props): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const companuLogoMenuBtnRef = useRef<HTMLDivElement>(null);

  const [autocompleteFilter, setAutocompleteFilter] = useState('');
  const { name: organizationName, organizationLogo, role: userRole } = useAppSelector(selectOrganizationSignup);
  const { control, setValue } = useFormContext();

  const updateCompanyData = (newData: AccConfCompanyData): void => {
    setCompanyData((actualData) => ({
      ...actualData,
      ...newData,
    }));
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuBtnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const validateFile = (file?: File): boolean => {
    if (!file || (file.size > maxFileSize)) {
      return false;
    }
    return true;
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const [file] = e.target.files || [];

    if (!validateFile(file)) return;
    setAnchorEl(null);
    updateCompanyData({
      file,
      url: URL.createObjectURL(file),
    });
  };

  const handleLogoRemove = () => {
    setAnchorEl(null);
    updateCompanyData(defauldLogoData);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleAutocompleteSelect = (company: ClearbitAutocompleteDTO) => {
    updateCompanyData({
      ...defauldLogoData,
      url: company.logo,
    });
    setAutocompleteFilter('');
    setValue('companyName', company.name);
  };

  useEffect(() => {
    if (userRole === EOrganizationRoles.OWNER) {
      updateCompanyData({
        ...defauldLogoData,
        role: userRole,
      });
    } else if (
      !!organizationName
      && userRole !== EOrganizationRoles.OWNER
    ) {
      updateCompanyData({
        ...defauldLogoData,
        name: organizationName,
        url: organizationLogo,
        role: userRole,
      });
    }
  }, [organizationName]);

  const rolesComponentSwitcher = () => {
    switch (userRole) {
      case EOrganizationRoles.OWNER:
        return (
          <>
            <Box
              key={userRole}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Controller
                name="companyName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState: { isSubmitted },
                }) => (
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="Company"
                    autoComplete="off"
                    placeholder="Your company"
                    value={value}
                    error={!!error && isSubmitted}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setAutocompleteFilter(e.target.value);
                      updateCompanyData({
                        name: e.target.value,
                      });
                    }}
                    onFocus={(e) => setAutocompleteFilter(e.target.value)}
                    onBlur={(e) => {
                      if (e.relatedTarget?.id !== 'company-information-autocomplete') {
                        setAutocompleteFilter('');
                      }
                    }}
                    InputLabelProps={{
                      className: 'title-field-input-label',
                    }}
                    InputProps={{
                      multiline: true,
                      className: 'title-field-input',
                      sx: {
                        borderRadius: '8px',
                      },
                    }}
                    sx={{
                      borderRadius: '8px',
                    }}
                  />
                )}
              />
              <CompanyAndLogoSelect
                isAutocompleteVisible={!!autocompleteFilter}
                value={autocompleteFilter}
                setSelectedCompany={handleAutocompleteSelect}
                positionOffset="0px"
              />
            </Box>
            <Box
              sx={{
                '&:hover': {
                  '& .CompanyLogoMenuBtn': {
                    visibility: 'visible',
                  },
                  '& .CompanyLogo': {
                    opacity: '0.5',
                  },
                },
              }}
            >
              <input
                hidden
                type="file"
                accept="image/png, image/jpeg"
                ref={fileInputRef}
                onChange={handleLogoChange}
                className="input"
              />
              {
              companyData.url
              && (
              <Box
                sx={{
                  height: '0px',
                  zIndex: 2,
                }}
              >
                <Box
                  className="CompanyLogoMenuBtn"
                  sx={{
                    height: '40px',
                    width: '40px',
                    position: 'relative',
                    zIndex: 2,
                    visibility: 'visible',
                    // visibility: 'hidden',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box
                    onClick={handleMenuBtnClick}
                    ref={companuLogoMenuBtnRef}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '16px',
                      width: '16px',
                      background: '#E5EFFF',
                      borderRadius: '6px',
                    }}
                  >
                    <MoreVert
                      sx={{
                        fontSize: '12px',
                        color: '#0073F5',
                      }}
                    />
                  </Box>
                  <Menu
                    id="section-options-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      sx: {
                        marginTop: '4px',
                        border: '1px solid #7084B4',
                        padding: '0px',
                      },
                    }}
                    MenuListProps={{
                      sx: {
                        padding: '0px',
                      },
                    }}
                  >
                    <AddSectionItem
                      key="delete-company-logo-btn"
                      itemName="Clear"
                      itemAction={handleLogoRemove}
                      icon={<DeleteSectionSvg />}
                    />
                    <AddSectionItem
                      itemName="Replace"
                      itemAction={handleUploadClick}
                      icon={<ReplaceIcon20 />}
                    />
                  </Menu>
                </Box>
              </Box>
              )
            }
              <Avatar
                onClick={handleUploadClick}
                src={companyData.url}
                className="CompanyLogo"
                sx={{
                  height: 40,
                  width: 40,
                  background: 'white',
                }}
              >
                <CompanyLogoIcon />
              </Avatar>
            </Box>
          </>
        );
      default:
        return (
          <>
            <Box
              key={userRole}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextField
                fullWidth
                size="small"
                label="Company"
                autoComplete="off"
                placeholder="Your company"
                value={organizationName}
                disabled
                InputLabelProps={{
                  shrink: true,
                  className: 'title-field-input-label',
                }}
                InputProps={{
                  readOnly: true,
                  multiline: true,
                  className: 'title-field-input',
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                sx={{
                  borderRadius: '8px',
                }}
              />
            </Box>
            <Avatar
              onClick={handleUploadClick}
              src={companyData.url}
              className="CompanyLogo"
              sx={{
                height: 40,
                width: 40,
                background: 'white',
              }}
            >
              <CompanyLogoIcon />
            </Avatar>
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
      }}
    >
      {rolesComponentSwitcher()}
    </Box>
  );
};

export default ConfirmAccountOrgDataField;
