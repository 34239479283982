import { ILoginResponse } from '../../shared/schema/token.schema';
import { CheckoutSessionResponse } from '../../shared/stripe';
import { SubscriptionDTO } from '../../shared/Subscription';
import {
  UserRolesDTO,
  DeactivateUserRequest,
  IAddUserToOrganizationRequest,
  UpdateOrganizationOwnerRequest,
  UserOrganizationDTO,
  UpdateOrganizationOwnerResponse,
} from '../../shared/user-organization';
import { OrganizationUserDTO, UserDTO } from '../../shared/UserDTO';
import { ApiService } from '../core/api';

export class UserOrganizationApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('user-organization');
  }

  async getUserOrganizationData(): Promise<UserOrganizationDTO> {
    return this.api.post<UserOrganizationDTO>('', {}, {});
  }

  async createCheckoutSession(priceId: string): Promise<void> {
    const res: CheckoutSessionResponse = await this.api.post<CheckoutSessionResponse>('/create-checkout-session', { priceId });
    window.location.href = res.redirectUrl;
  }

  async changeSubscriptionPlan(newPriceId: string): Promise<void> {
    await this.api.post<void>('/change-subscription-plan', { newPriceId });
  }

  async organizationAddUser(payload: IAddUserToOrganizationRequest): Promise<UserDTO> {
    return this.api.post<UserDTO>('/invite', payload);
  }

  async organizationDeactivateUser(payload: DeactivateUserRequest): Promise<string> {
    return this.api.post<string>('/deactivate', payload);
  }

  async getSubscription(): Promise<SubscriptionDTO> {
    return this.api.get('/subscription');
  }

  async updateUser(payload: OrganizationUserDTO): Promise<OrganizationUserDTO> {
    return this.api.post<OrganizationUserDTO>('/update-user', payload);
  }

  async updateOrganizationOwner(payload: UpdateOrganizationOwnerRequest): Promise<UpdateOrganizationOwnerResponse> {
    return this.api.post<UpdateOrganizationOwnerResponse>('/update-organization-owner', payload);
  }

  async transferUserToNewOrganization(payload: string): Promise<ILoginResponse> {
    return this.api.post<ILoginResponse>(`/transfer-organization/${payload}`, {});
  }

  async declineOrganizationInvite(payload: string): Promise<void> {
    return this.api.post<void>(`/decline-transfer-organization/${payload}`, {});
  }

  async getOrganizationUsers(payload: string): Promise<UserRolesDTO[]> {
    return this.api.get<UserRolesDTO[]>(`/organization-users/${payload}`);
  }
}
