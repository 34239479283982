import { SvgIcon, SvgIconProps } from '@mui/material';

export const ProtectedIcon20px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M9.99999 2L2.02058 5.58621C2.02058
5.58621 1.19512 18 9.99999 18C18.8049
18 17.9794 5.58621 17.9794 5.58621L9.99999 2Z"
      stroke="#27AE60"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8 10.6667L9.11111 12L12 8"
      stroke="#27AE60"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const UnprotectedIcon20px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M9.99999 2L2.02058 5.58621C2.02058
  5.58621 1.19512 18 9.99999 18C18.8049 18
  17.9794 5.58621 17.9794 5.58621L9.99999 2Z"
      stroke="#7084B4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8 12L12 8"
      stroke="#7084B4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12 12L8 8"
      stroke="#7084B4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
