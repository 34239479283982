import {
  Box, Typography, styled,
} from '@mui/material';

export const LibraryCreateItemMenuTabWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LibraryCreateItemMenuTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '0px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const LibraryCreateItemMenuTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500,
}));

export const LibraryCreateItemMenuButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '8px 0px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));
