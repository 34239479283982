import { Box } from '@mui/material';
import { useMemo } from 'react';
import { generateTimelineSessionItemContent } from './ProcessTimelineHelpers';
import useProcessTimelineEventsFilter from '../../hooks/useProcessTimelineEventsFilter';

interface Props {
  item: any;
}

const ProcessTimelineSessionItem = ({
  item,
}: Props): JSX.Element | null => {
  // eslint-disable-next-line no-underscore-dangle
  const event = useMemo(() => generateTimelineSessionItemContent(item), [item._id]);

  const { isSelected } = useProcessTimelineEventsFilter(item.name);

  if (!event || !isSelected) {
    return null;
  }

  return (
    <Box
      sx={{
        paddingY: '12px',
        height: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {event?.icon}
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            {event?.text}
            {' '}
            {event?.date}
          </Box>
        </Box>
        {
          event?.description
          && (
          <Box
            sx={{
              paddingLeft: '52px',
            }}
          >
            {event?.description}
          </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default ProcessTimelineSessionItem;
