import { SvgIcon, SvgIconProps } from '@mui/material';

export const StrikedEyeIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M12.3064 11.7666C12.0775 12.0122
        11.8015 12.2092 11.4948 12.3459C11.1882
        12.4825 10.8571 12.556 10.5215 12.5619C10.1858
        12.5678 9.85235 12.5061 9.54106 12.3803C9.22976
        12.2546 8.94698 12.0675 8.70959 11.8301C8.47219
        11.5927 8.28504 11.3099 8.1593 10.9986C8.03357
        10.6873 7.97182 10.3539 7.97774 10.0182C7.98366
        9.68252 8.05714 9.35148 8.19378 9.04481C8.33042
        8.73815 8.52742 8.46215 8.77305 8.23327M15.4897
        14.9499C14.0652 16.0358 12.3306 16.6373 10.5397
        16.6666C4.70638 16.6666 1.37305 9.99994 1.37305
        9.99994C2.40962 8.06819 3.84733 6.38045 5.58971
        5.04994L15.4897 14.9499ZM8.78971 3.53327C9.36332
        3.39901 9.9506 3.33189 10.5397 3.33327C16.373
        3.33327 19.7064 9.99994 19.7064 9.99994C19.2005
        10.9463 18.5973 11.8372 17.9064 12.6583L8.78971
        3.53327Z"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.37305 0.833252L19.7064 19.1666"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
