import { useState } from 'react';
import AdminDeletedOrganizationsHeader from './AdminDeletedOrganizationsHeader';
import AdminDeletedOrganizationsDataGrid from './AdminDeletedOrganizationsDataGrid';

const AdminDeletedOrganizationsContainer = (): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState('');

  return (
    <>
      <AdminDeletedOrganizationsHeader searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
      <AdminDeletedOrganizationsDataGrid searchFilter={searchFilter} />
    </>
  );
};

export default AdminDeletedOrganizationsContainer;
