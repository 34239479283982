import {
  Box, List,
} from '@mui/material';
import { useState } from 'react';
import { AppLinkButton } from '../../../../../shared/AppButton/AppLinkButton';
import { AppInput } from '../../../../../shared/AppInput/AppInput';
import { MobileFilterItem, MobileFilterItemProps } from '../MobileFilterItem';

export type Props = {
  items: MobileFilterItemProps[],
  onClose: () => void;
  title: string;
  onNext?: (tab: number) => void;
  enableSearch?: boolean;
};

export const FiltersTab = ({
  items, onClose, title, onNext, enableSearch,
}: Props) => {
  const [search, setSearch] = useState<string>('');

  const handleClick = (e: any, opt: MobileFilterItemProps, index: number) => {
    if (onNext && opt.childrenItems) {
      onNext(index + 1);
    } else if (opt.onClick) {
      opt.onClick(e);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginBottom: '20px',
      }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          {title}
        </Box>
        <Box sx={{
          display: 'flex',
        }}
        >
          <AppLinkButton
            onClick={onClose}
            sx={{
              paddingRight: '0px !important',
              minWidth: '100% !important',
              width: '100% !important',
            }}
          >
            Cancel
          </AppLinkButton>
        </Box>
      </Box>
      <Box hidden={!enableSearch}>
        <AppInput
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          size="m"
          placeholder="Search"
          sx={{
            marginBottom: '16px',
          }}
        />
      </Box>
      <Box sx={{
        height: '100%',
        overflow: 'overlay',
      }}
      >
        <List sx={{
          gap: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {items.filter((opt) => opt?.label?.toLowerCase()?.includes(search?.toLowerCase()))?.map((opt, index) => (
            <MobileFilterItem key={opt.label} {...opt} onClick={(e) => handleClick(e, opt, index)} />
          ))}
        </List>
      </Box>
    </Box>
  );
};
