import Salesforce from '../icons/Integrations/Salesforce.svg';
import HubSpot from '../icons/Integrations/HubSpot.svg';
import Pipedrive from '../icons/Integrations/Pipedrive.svg';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { PipedriveAuthApi } from '../api/PipedriveAuthApi';
import { HubspotAuthApi } from '../api/HubspotAuthApi';
import { SalesforceAuthApi } from '../api/SalesforceAuthApi';
import { pipedriveCancelIntegration } from '../core/crmintegrations/pipedrive';
import { hubspotCancelIntegration } from '../core/crmintegrations/hubspot';
import { salesforceCancelIntegration } from '../core/crmintegrations/salesforce';
import { isUserHaveAnyCrmIntegration } from '../routes-old/deals/model/useCrmOpportunities';

const pipedriveAuthApi: PipedriveAuthApi = new PipedriveAuthApi();
const hubspotAuthApi: HubspotAuthApi = new HubspotAuthApi();
const salesforceAuthApi: SalesforceAuthApi = new SalesforceAuthApi();

export const useIntegrationsSettings = () => {
  const dispatch = useAppDispatch();

  const integrationsArray = useAppSelector((state) => ([
    {
      integrationName: 'Hubspot',
      integrationLogo: <HubSpot />,
      isUserHaveAnyCrmIntegration: isUserHaveAnyCrmIntegration(),
      onIntegrationCancel: () => dispatch(hubspotCancelIntegration()),
      onIntegrationSet: () => hubspotAuthApi.startAuthSession(),
      ...state.hubspot,
    },
    {
      integrationName: 'Pipedrive',
      integrationLogo: <Pipedrive />,
      isUserHaveAnyCrmIntegration: isUserHaveAnyCrmIntegration(),
      onIntegrationCancel: () => dispatch(pipedriveCancelIntegration()),
      onIntegrationSet: () => pipedriveAuthApi.startAuthSession(),
      ...state.pipedrive,
    },
    {
      integrationName: 'Salesforce',
      integrationLogo: <Salesforce />,
      isUserHaveAnyCrmIntegration: isUserHaveAnyCrmIntegration(),
      onIntegrationCancel: () => dispatch(salesforceCancelIntegration()),
      onIntegrationSet: () => salesforceAuthApi.startAuthSession(),
      ...state.salesForce,
    },
  ]));

  return {
    integrationsArray,
  };
};
