import {
  styled,
  Typography,
  Box,
  ListItem,
} from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const InviteItemContainer = styled(ListItem)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '9px 12px',
  gap: '6px',
  '& svg': {
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
  },
}));

export const InviteItemDescriptionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const InviteItemTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
  wordBreak: 'break-all',
}));

export const InviteItemSubTitle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
}));
