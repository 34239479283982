import {
  Node, NodeViewProps, ReactNodeViewRenderer, mergeAttributes,
} from '@tiptap/react';
import { ExtensionNames } from '../../Utils/TiptapHelpers';
import TiptapNodeWrapper from '../../TiptapNodeWrapper';
import PricingTableFieldContainer from '../../../PricingTable/PricingTableFieldContainer';

const PricingTableExtension = Node.create({
  name: ExtensionNames.PRICING_TABLE, // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      items: {
        default: [],
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: ExtensionNames.PRICING_TABLE,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [ExtensionNames.PRICING_TABLE, mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer((nodeProps: NodeViewProps) => (
      <TiptapNodeWrapper
        {...nodeProps}
        component={<PricingTableFieldContainer />}
        className={ExtensionNames.PRICING_TABLE}
      />
    ));
  },
});

export default PricingTableExtension;
