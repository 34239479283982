import { saveAs } from 'file-saver';
import { v4 } from 'uuid';
import { CopyTeamFileToProcessRequest, DeleteFileRequest, FileDTO } from '../../shared/FileDTO';
import { OrganizationDTO } from '../../shared/OrganizationDTO';
import { ELogoType } from '../../shared/process/ProcessMilestoneActionDTO';
import { UserDTO } from '../../shared/UserDTO';
import { ApiService } from '../core/api';

export interface IUploadFilesFormData {
  file: File;
  userId: string;
  organizationId?: string;
  uploaderOrganization?: string;
  processId?: string;
  fieldId?: string;
  logoType?: ELogoType;
  fileName?: string;
}

export const getFileName = (fileName: string, shouldEscape?: boolean) => {
  let result = fileName;
  if (shouldEscape) {
    result = fileName.replaceAll(' ', '_').replaceAll('+', '_');
  }
  return `${v4()}_${result}`;
};

export const prepareFormData = async (payload: IUploadFilesFormData, parse?: boolean): Promise<FormData> => {
  const formData: FormData = new FormData();
  for (const key in payload) {
    if (key !== 'file' && key !== 'fileName') {
      formData.append(key, payload[key]);
    }
  }

  const buffer = await payload.file.slice();
  formData.append('file', buffer);
  formData.append('fileName', getFileName(payload.fileName || payload.file.name, parse));
  formData.append('fileType', payload.file.type);

  return formData;
};

export class FileStorageApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('files');
  }

  async uploadFiles(
    payload: IUploadFilesFormData,
    updateProgress: (percent: number | undefined, name: string | undefined) => void,
  ): Promise<FileDTO[]> {
    const formData: FormData = await prepareFormData(payload);
    const progress = {
      onUploadProgress: (p) => {
        const loadingProgress = Math.round((p.loaded * 100) / p.total);
        updateProgress(loadingProgress, payload.file.name);
        if (loadingProgress === 100) {
          updateProgress(undefined, undefined);
        }
      },
    };

    return this.api.post<FileDTO[]>('/upload', formData, progress);
  }

  async uploadFieldFile(
    payload: IUploadFilesFormData,
  ): Promise<string> {
    const formData: FormData = await prepareFormData(payload, true);

    return this.api.post<string>('/upload-field-file', formData);
  }

  async uploadOrganizationFiles(
    payload: IUploadFilesFormData,
    updateProgress: (percent: number | undefined, name: string | undefined) => void,
  ): Promise<FileDTO[]> {
    const formData: FormData = await prepareFormData(payload);
    const progress = {
      onUploadProgress: (p) => {
        const loadingProgress = Math.round((p.loaded * 100) / p.total);
        updateProgress(loadingProgress, payload.file.name);
        if (loadingProgress === 100) {
          updateProgress(undefined, undefined);
        }
      },
    };

    return this.api.post<FileDTO[]>('/organization', formData, progress);
  }

  async uploadAvatar(payload: IUploadFilesFormData, progress?: any): Promise<UserDTO> {
    const formData: FormData = await prepareFormData(payload, true);
    return this.api.post<UserDTO>('/upload-avatar', formData, progress);
  }

  async uploadTeamLogo(payload: IUploadFilesFormData): Promise<OrganizationDTO> {
    const formData: FormData = await prepareFormData(payload, true);

    return this.api.post<OrganizationDTO>('/upload-organization-logo', formData);
  }

  async uploadTeamWideLogo(payload: IUploadFilesFormData): Promise<OrganizationDTO> {
    const formData: FormData = await prepareFormData(payload);

    return this.api.post<OrganizationDTO>('/upload-organization-wide-logo', formData);
  }

  async downloadFileByPath(path: string, name: string): Promise<void> {
    const data: Blob = await this.api.post('/download', { path }, {
      responseType: 'blob',
    });
    // @ts-ignore
    saveAs(data, name, {});
  }

  async streamFileByPath(path: string): Promise<ArrayBuffer> {
    const data: ArrayBuffer = await this.api.post('/stream', { path }, {
      responseType: 'arraybuffer',
    });
    // @ts-ignore
    return data;
  }

  async deleteFileByPath(req: DeleteFileRequest): Promise<string> {
    return this.api.post<string>('/delete', req);
  }

  async getOrganizationFiles(): Promise<FileDTO[]> {
    return this.api.get<FileDTO[]>('/organization');
  }

  async copyTeamFileToProcess(payload: CopyTeamFileToProcessRequest): Promise<FileDTO[]> {
    return this.api.post<FileDTO[]>('/copy-to-process', payload);
  }
}
