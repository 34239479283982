import { TUpdateUserDataRequest, UserDTO } from '../../shared/UserDTO';
import { FilterSet } from '../../shared/process/ProcessFilter';
import { ApiService } from '../core/api';

export class UserApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('user');
  }

  async getUserData(): Promise<UserDTO> {
    return this.api.get<UserDTO>('/user-data');
  }

  async updateUserData(payload: TUpdateUserDataRequest): Promise<UserDTO> {
    return this.api.post<UserDTO>('', payload, {});
  }

  async uploadUserAvatar(): Promise<UserDTO> {
    return this.api.post<UserDTO>('', {}, {});
  }

  async saveUserLastFilter(filter: Partial<FilterSet>): Promise<void> {
    this.api.post<FilterSet>('/update-filter', filter, {});
  }
}
