import { useState } from 'react';

interface IOnHoverProps {
  onMouseEnter: () => void,
  onMouseLeave: () => void,
}

export const useHover = (): [boolean, IOnHoverProps] => {
  const [hovering, setHovering] = useState(false);
  const onHoverProps: IOnHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };
  return [hovering, onHoverProps];
};
