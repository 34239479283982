import { Box } from '@mui/material';
import { useCurrentEditor } from '@tiptap/react';
import React from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';

const TiptapSlotAfter = (): JSX.Element | null => {
  const { editor } = useCurrentEditor();
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isLayoutEdit) return;
    const doc = editor?.state.doc;

    editor
      ?.chain()
      .focus()
      .insertContentAt(
        doc?.content.size ?? 0,
        {
          type: 'paragraph',
        },
      )
      .run();
  };

  return (
    <Box
      className="TiptapSlotAfter"
      onClick={handleClick}
      sx={{
        minHeight: '200px',
        display: 'flex',
        flex: '1 1 auto',
        cursor: 'text',
      }}
    />
  );
};

export default TiptapSlotAfter;
