import { EUserOrganizationPermissions } from '../../shared/permissions';
import { useAppSelector } from './stateHooks';

const useUserOrganizationPermissions = (): [
  (userOrganizationPermission?: EUserOrganizationPermissions) => boolean,
] => {
  const { permissions, organization } = useAppSelector((state) => state.app);

  const checkPermissions = (userOrganizationPermission?: EUserOrganizationPermissions): boolean => {
    if (!permissions || !organization) {
      return false;
    }
    if (!userOrganizationPermission) {
      return false;
    }
    return permissions[userOrganizationPermission];
  };

  return [checkPermissions];
};

export default useUserOrganizationPermissions;
