import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isMobile } from 'react-device-detect';
import {
  List, ListItem, ListItemIcon, ListItemText, TextField,
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import Trash from '../../icons/Trash.svg';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { LibraryFilterSetDTO } from '../../../shared/library/LibraryFilter';

type Props = {
  open: boolean,
  savedFilters: LibraryFilterSetDTO[],
  updateFilterSet: (id: string, name: string) => void,
  deleteFilterSet: (deleteId: string) => void,
  setOpen: (open: boolean) => void,
};

const ManageLibraryFilterSets = ({
  open,
  savedFilters,
  updateFilterSet,
  deleteFilterSet,
  setOpen,
}: Props) => {
  const [deleteId, setDeleteId] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (id: string, name: string) => {
    await updateFilterSet(id, name);
    enqueueSnackbar('The filter set has been successfully changed', {
      variant: 'success',
    });
  };

  const handleDelete = async () => {
    if (deleteId) {
      await deleteFilterSet(deleteId);
      if (savedFilters?.length === 1) {
        handleClose();
      }
    }
    setDeleteId(undefined);
    enqueueSnackbar('The filter set successfully deleted', {
      variant: 'success',
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        onBackdropClick={() => handleClose()}
        BackdropProps={{
          sx: {
            backgroundColor: '#4e4e4e21',
          },
        }}
        PaperProps={{
          sx: {
            overflowX: 'hidden',
            minWidth: isMobile ? '90%' : '500px',
            borderRadius: '16px',
          },
        }}
        sx={{
          marginTop: '40px',
          '& .MuiDialog-container': {
            height: 'auto',
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle
          sx={{
            padding: '16px 24px',
            fontSize: '18px',
            color: (theme) => theme.palette.grey[500],
            fontFamily: 'Inter',
            fontWeight: 600,
          }}
        >
          Saved filters
        </DialogTitle>
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            padding: '10px 24px 24px 24px !important',
          }}
        >
          <List sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
          }}
          >
            {savedFilters?.map((filterSet: DealFilterSetDTO) => (
              <ListItem
                key={filterSet.id}
                sx={{
                  padding: '10px 12px 10px 8px',
                  borderRadius: '12px',
                  border: (theme) => `1px solid ${theme.palette.grey[100]}`,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText sx={{
                  width: '93%',
                }}
                >
                  <TextField
                    defaultValue={filterSet.name}
                    fullWidth
                    onBlur={(e) => handleChange(filterSet.id, e.target.value)}
                    sx={{
                      height: 'auto',
                      padding: '2px',
                      borderRadius: '4px',
                      '& fieldset': {
                        height: 'auto',
                        border: 'none',
                      },
                      '& input': {
                        padding: '0px',
                      },
                      '&:hover': {
                        background: (theme) => theme.palette.primary[100],
                      },
                    }}
                  />
                </ListItemText>
                <ListItemIcon sx={{
                  width: '7%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  minWidth: 'auto',
                }}
                >
                  <AppIconButton
                    onClick={() => setDeleteId(filterSet.id)}
                    variant="danger-transparent"
                    icon={<Trash />}
                  />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        isDialogOpen={!!deleteId}
        onCancel={() => setDeleteId(undefined)}
        onConfirm={handleDelete}
        dialogContentText={<Box>Are you sure about deleting the filter?</Box>}
        dialogTitle="Delete filter set"
        titleVariant="danger-primary"
      />
    </>
  );
};

export default ManageLibraryFilterSets;
