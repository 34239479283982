import {
  Box, List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LibraryFileDTO } from '../../../../../shared/library/LibraryDTO';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import LinkIcon from '../../../../icons/link20.svg';
import DocumentIcon from '../../../../icons/DocIcon.svg';
import { EFileType } from '../../../../../shared/library/Library';
import { useGetLibraryFilesQuery } from '../../../../pages/library/lib/libraryRTKApi';
// import { AppButton } from '../../../../shared/AppButton/AppButton';
// import ChevronLeft from '../../../../icons/ChevronLeft.svg';
// import { MediaFieldMenuState } from './lib/MediaFieldMenuState';

type Props = {
  // setMenuState: (state: MediaFieldMenuState) => void;
  onLibraryItemAdd: (item: LibraryFileDTO) => void;
};

const MediaFieldMenuLibrary = ({
  // setMenuState,
  onLibraryItemAdd,
}: Props): JSX.Element => {
  const { data: files } = useGetLibraryFilesQuery({ types: [EFileType.FILE] });
  const [searchFilter, setSearchFilter] = useState('');
  const [rows, setRows] = useState<LibraryFileDTO[]>([]);

  useEffect(() => {
    let tempRows: LibraryFileDTO[] = files || [];

    if (searchFilter) {
      tempRows = tempRows.filter((x: Partial<LibraryFileDTO>) => (
        !!x.name && x.name.toUpperCase().indexOf(searchFilter.toUpperCase()) >= 0
      ));
    }

    setRows(tempRows);
  }, [searchFilter, files]);

  // const handleBack = () => {
  //   setMenuState(MediaFieldMenuState.UPLOAD);
  // };

  const fileIcon = (type: EFileType): JSX.Element | null => {
    switch (type) {
      case EFileType.FILE:
        return (
          <DocumentIcon />
        );
      case EFileType.LINK:
        return (
          <LinkIcon />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* <Box
        sx={{
          padding: '20px 22px 0',
          '& button': {
            padding: '20px 5px 20px 0 !important',
            minWidth: '50px !important',
          },
        }}
      >
        <AppButton
          onClick={handleBack}
        >
          <ChevronLeft />
          Back
        </AppButton>
      </Box> */}
      <Box
        sx={{
          width: '100%',
          padding: '5px 22px',
        }}
      >
        <AppInput
          placeholder="Search"
          defaultValue={searchFilter}
          type="text"
          value={searchFilter}
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          sx={{
            width: '100%',
          }}
        />
      </Box>
      <Box
        sx={{
          maxHeight: 300,
          overflowY: 'auto',
          marginBottom: '12px',
          padding: '0 4px',
        }}
      >
        <List>
          {
            rows.map((x: LibraryFileDTO) => (
              <ListItemButton
                onClick={() => {
                  onLibraryItemAdd(x);
                }}
                key={x.name}
              >
                <ListItemIcon>
                  {fileIcon(x.type)}
                </ListItemIcon>
                <ListItemText primary={x.name} />
              </ListItemButton>
            ))
          }
        </List>
      </Box>
    </>
  );
};

export default MediaFieldMenuLibrary;
