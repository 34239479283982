import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import {
  DEFAULT_LANG, LANGUAGES, getLanguageName, getLanguageFlag,
} from './Languages';
import { LanguageSwitcherProperties } from './LanguageProperties';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { ChevronUp20 } from '../../icons/ChevronUp';
import { ChevronDown20 } from '../../icons/ChevronDown';

const LanguageSwitcher = (props: LanguageSwitcherProperties): JSX.Element | null => {
  const { i18n } = useTranslation();
  const [ancor, setAnchor] = useState<HTMLElement | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState(getLanguageName(i18n?.language));
  const [flagUrl, setFlagUrl] = useState(getLanguageFlag(i18n?.language));
  const myRef = useRef(null);

  useEffect(() => {
    console.log(myRef);
    if (myRef.current) {
      console.log(myRef.current);
    }
  }, []);

  useEffect(() => {
    let langName = getLanguageName(i18n?.language);
    let langFlag = getLanguageFlag(i18n?.language);
    if (!langName) {
      i18n.changeLanguage(DEFAULT_LANG);
      langName = getLanguageName(DEFAULT_LANG) as string;
      langFlag = getLanguageFlag(DEFAULT_LANG) as string;
    }
    setCurrentLanguage(langName);
    setFlagUrl(langFlag);
  }, [i18n?.language]);

  const openSelection = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(myRef?.current);
  };

  return (
    <>
      <Box
        ref={myRef}
        onClick={openSelection}
        sx={{
          cursor: 'pointer',
          border: (theme) => `1px solid ${greySidebarContrastText(theme, 0.1)}`,
          display: 'inline-flex',
          background: (theme) => theme.dealOverrides.sidebar.backgroundColor,
          borderRadius: '10px',
          padding: '5px 10px',
        }}
      >
        <Box>
          <img
            src={flagUrl}
            style={{
              display: 'inline-block',
              width: '1.2em',
              height: '1.2em',
              verticalAlign: 'middle',
              marginRight: '5px',
              marginBottom: '2px',
              borderRadius: '0.6em',
            }}
          />
        </Box>
        <Box
          sx={{
            color: (theme) => greySidebarContrastText(theme, 0.8),
          }}
        >
          {currentLanguage}
        </Box>
        <Box
          sx={{
            marginLeft: '5px',
            marginTop: '2px',
            height: '1em',
            stroke: (theme) => greySidebarContrastText(theme, 0.8),
            '& path': {
              stroke: 'inherit',
            },
          }}
        >
          {!ancor ? <ChevronDown20 /> : <ChevronUp20 />}
        </Box>
      </Box>
      <AppDropdown
        items={LANGUAGES.map((lng) => ({
          id: lng.lng,
          label: lng.name,
          onClick: (e) => {
            e.stopPropagation();
            i18n.changeLanguage(lng.lng);
            setAnchor(null);
          },
        }))}
        open={!!ancor}
        anchorEl={ancor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: props.anchorVertical, horizontal: props.anchorHorizontal }}
        transformOrigin={{ vertical: props.transformVertical, horizontal: props.transformHorizontal }}
      />
    </>
  );
};

export default LanguageSwitcher;
