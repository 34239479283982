import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';
import { wait } from '../../../core/utils/wait';

export const sideMenuReducerName: string = 'sideMenu';

export const delayOpenMenu = createAsyncThunk(
  `${sideMenuReducerName}/delayOpenMenu`,
  async () => {
    await wait(200);
  },
);

const initialState = {
  isMenuOpened: false,
  isMenuDelayed: false,
  isUserMenuOpened: false,
};

export const sideMenuSlice = createSlice({
  name: sideMenuReducerName,
  initialState,
  reducers: {
    openSideMenu: (state) => {
      state.isMenuDelayed = false;
      state.isMenuOpened = true;
    },
    closeSideMenu: (state) => {
      state.isMenuDelayed = false;
      state.isUserMenuOpened = false;
      state.isMenuOpened = false;
    },
    delaySideMenu: (state) => {
      state.isMenuDelayed = true;
    },
    toggleSideMenu: (state) => {
      state.isMenuDelayed = false;
      state.isMenuOpened = !state.isMenuOpened;
    },
    toggleUserMenu: (state) => {
      state.isUserMenuOpened = !state.isUserMenuOpened;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(delayOpenMenu.fulfilled, (state) => {
      if (state.isMenuDelayed) state.isMenuOpened = true;
    });
  },
});

export const {
  openSideMenu,
  closeSideMenu,
  delaySideMenu,
  toggleSideMenu,
  toggleUserMenu,
} = sideMenuSlice.actions;

export const selectSideMenuOptions = (state: RootState) => state.sideMenu;
