import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EFieldType } from '../../../shared/Field/Field';
import EmbedSectionDialog from '../../features/BlockContentSection/BlockEmbedSectionDialog/BlockEmbedSectionDialog';
import SectionDisplay from '../../features/TemplateDisplay/SectionDisplay';
import StageDisplay from '../../features/TemplateDisplay/StageDisplay';
import { useAppDispatch } from '../../hooks/stateHooks';
import { setIsLayoutEditActive } from '../../routes-old/process/state/processSlice';
import { setTemplateEditMode } from './lib/templatesSlice';
import { resetItemsSlice } from '../../features/ProcessFields/lib/processItemsSlice';

const TemplatePage = (): JSX.Element => {
  const { type, id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => () => { dispatch(resetItemsSlice()); }, []);

  useEffect(() => {
    dispatch(setTemplateEditMode(true));
    dispatch(setIsLayoutEditActive(true));
    return () => {
      dispatch(setTemplateEditMode(false));
      dispatch(setIsLayoutEditActive(false));
    };
  }, []);

  return (
    <Box
      sx={{
        height: 'calc( 100% )',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {
        type === EFieldType.STAGE
        && id
        && (
        <StageDisplay
          id={id}
          toolbar
        />
        )
      }
      {
        type === EFieldType.SECTION
        && id
        && (
        <SectionDisplay
          id={id}
          toolbar
        />
        )
      }
      <EmbedSectionDialog />
    </Box>
  );
};

export default TemplatePage;
