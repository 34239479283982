import { TabPanelProps } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { EProcessType } from '../../../../../shared/process/ProcessMilestoneActionDTO';
import { ETaskTypes, TaskDTO } from '../../../../../shared/TaskDTO';
import InviteUserPanel from '../../../../features/InviteUserPanel/InviteUserPanel';
import ShareDealLinkAndOptions from '../../../../features/ShareDealPopup/ProtectionOptions/ShareDealLinkAndOptions';
import { useUpdateTaskByIdMutation } from '../../../../features/Tasks/lib/tasksApi';
import { useAppSelector } from '../../../../hooks/stateHooks';
import {
  selectProcess,
  selectProcessUsers,
  selectOnlineProcessUsers,
} from '../../state/processSlice';
import AssigneesList from './AssigneesList';
import AssigneesListWrappers from './AssigneesListsWrapper';
import { DealUsersList } from './DealUsersList';

import {
  DealFeedTabItemContainer, DealFeedTabItemWrapper, DealFeedTabPanel, DealFeedTabSubtitle,
} from '../Styles/DealFeedStyles';

interface Props extends TabPanelProps {
  selectedTask?: TaskDTO;
  isShared: boolean;
  processId: string;
}

const UsersTab = ({
  selectedTask, value, sx, isShared, processId, ...props
}: Props): JSX.Element => {
  const process = useAppSelector(selectProcess);
  const processUsers = useAppSelector(selectProcessUsers);
  const onlineUsers = useAppSelector(selectOnlineProcessUsers);
  const [updateTask] = useUpdateTaskByIdMutation();
  const { t } = useTranslation();

  const onAssigneeAdd = (userId: string): void => {
    if (selectedTask) {
      updateTask({
        id: selectedTask.id,
        processId,
        update: {
          assigneesIds: [
            ...selectedTask.assigneesIds,
            userId,
          ],
        },
      });
      if (selectedTask.type === ETaskTypes.ACTION) {
        console.log('IMPLEMENT PARENT TASK ASSIGNEE UPDATE');
        // updateParentTask({ parentId: selectedTask.parentId, userId }));
      }
    }
  };

  const onAssigneeRemove = (userId: string): void => {
    if (selectedTask) {
      updateTask({
        id: selectedTask.id,
        processId: process?.id,
        update: {
          assigneesIds: selectedTask?.assigneesIds?.filter(
            (assigneeId: string) => assigneeId !== userId,
          ),
        },
      });
    }
  };

  const isAssigneeListVisible = selectedTask
    && process?.type === EProcessType.DEAL;

  const isDealUserListVisible = !selectedTask
    && process?.type === EProcessType.DEAL;

  return (
    <DealFeedTabPanel
      sx={{
        ...sx,
      }}
      value={value}
      {...props}
    >
      <DealFeedTabItemWrapper>
        <DealFeedTabSubtitle>
          {t('Room_General_access')}
        </DealFeedTabSubtitle>
        <DealFeedTabItemContainer>
          <ShareDealLinkAndOptions />
        </DealFeedTabItemContainer>
      </DealFeedTabItemWrapper>
      <AssigneesListWrappers
        sx={{
          maxHeight: '100%',
          display: isAssigneeListVisible ? 1 : 'none',
        }}
      >
        {isAssigneeListVisible
            && (
              <AssigneesList
                onlineUserIds={onlineUsers}
                assigneesIds={selectedTask ? selectedTask.assigneesIds : []}
                processUsers={processUsers || []}
                onAssigneeAdd={((userId) => onAssigneeAdd(userId))}
                onAssigneeRemove={((userId) => onAssigneeRemove(userId))}
              />
            )}
      </AssigneesListWrappers>
      <DealFeedTabItemWrapper>
        <DealFeedTabSubtitle>
          {t('Room_People_with_access')}
        </DealFeedTabSubtitle>
        <DealFeedTabItemContainer
          sx={{
            marginBottom: isMobile ? '16px' : '24px',
          }}
        >
          <InviteUserPanel />
          {
            isDealUserListVisible
            && (
              <DealUsersList
                onlineUserIds={onlineUsers}
                processUsers={processUsers}
                isShared={isShared}
              />
            )
          }
        </DealFeedTabItemContainer>
      </DealFeedTabItemWrapper>
    </DealFeedTabPanel>
  );
};

export default UsersTab;
