import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';

interface Props {
  editMode: boolean;
  saveContent: (newContent: string) => void;
  rteString?: string;
  editable?: boolean;
  agendaId?: string;
}

const LexicalRTEEditModePlugin = ({
  editMode,
  saveContent,
  rteString,
  editable,
  agendaId,
}: Props) => {
  const isLayoutEditActiveState = useAppSelector(selectLayouEditingStatus);
  const isLayoutEditActive = editable ?? isLayoutEditActiveState;
  const [editor] = useLexicalComposerContext();
  const { createDealLinkClickedEvent, createTaskLinkClickedEvent } = useFrontEventsApi();

  const handleLinkClick = (text: string, url: string) => {
    if (!editMode) {
      if (agendaId) {
        createTaskLinkClickedEvent({
          linkName: text,
          linkUrl: url,
          taskId: agendaId,
        });
      } else {
        createDealLinkClickedEvent({
          linkName: text,
          linkUrl: url,
        });
      }
    }
  };

  // Sets lexical state since initial state has no access to rteString
  useEffect(() => {
    if (rteString) {
      try {
        const initialState = editor.parseEditorState(rteString);
        if (initialState) {
          editor.setEditorState(initialState);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [rteString]);

  useEffect(() => {
    editor.setEditable(isLayoutEditActive);
  }, [isLayoutEditActive]);

  useEffect(() => {
    document.querySelectorAll('.editor-link')
      .forEach((el) => {
        if (el instanceof HTMLAnchorElement) {
          el.addEventListener('click', () => {
            handleLinkClick(el.innerText, el.href);
          });
        }
      });
  }, []);

  useEffect(
    () =>
    // editor.setEditable(editMode);
      () => {
        if (editMode && !editor.getEditorState().isEmpty()) {
          saveContent(JSON.stringify(editor.getEditorState().toJSON()));
        }
      },
    [editMode],
  );

  return null;
};

export default LexicalRTEEditModePlugin;
