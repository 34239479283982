import { Box } from '@mui/material';

interface Props {
  onClick?: (e?: any) => void;
  content: JSX.Element | string;
  highlight?: boolean;
}

const LexicalRTEBtnBase = ({
  onClick,
  content,
  highlight,
}: Props) => (
  <Box
    onMouseDown={onClick}
    sx={{
      height: '32px',
      aspectRatio: '1/1',
      fontWeight: 600,
      borderRadius: '8px',
      background: (theme) => (highlight ? theme.palette.grey[200] : 'transparent'),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: '0.2s',
      '&:hover': {
        background: (theme) => theme.palette.grey[100],
        color: (theme) => theme.palette.grey[500],
      },
      '& svg': {
        transition: '0.2s',
        fill: (theme) => theme.palette.grey[500],
        '& path': {
          transition: '0.2s',
          stroke: (theme) => theme.palette.grey[500],
        },
      },
    }}
  >
    {content}
  </Box>
);

export default LexicalRTEBtnBase;
