import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ChangeEvent, useEffect, useState } from 'react';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import classNames from 'classnames';
import { CheckboxProps } from '@mui/material';
import { StyledAppCheckbox } from './AppCheckboxStyles';

type AppCheckboxSize =
  | 's'
  | 'm';

export type AppCheckboxVariant =
  | 'success-primary'
  | 'secondary'
  | 'primary';

export type AppCheckboxProps = {
  variant?: AppCheckboxVariant,
  size?: AppCheckboxSize,
  onCheckChange: (checked?: boolean) => void,
  isChecked?: boolean,
  sx?: SxProps<Theme>,
  isEditable?: boolean,
} & Omit<CheckboxProps, 'variant' | 'size'>;

export const AppCheckbox = ({
  variant = 'primary',
  size = 'm',
  className,
  isChecked = false,
  onCheckChange,
  sx,
  isEditable,
}: AppCheckboxProps): JSX.Element => {
  const [checkState, setCheckState] = useState<boolean>(isChecked);

  const onCheck = (event: ChangeEvent<HTMLInputElement>, checked?: boolean) => {
    if (!isEditable) return;
    setCheckState(!checkState);
    if (event && event.target) {
      onCheckChange(checked || false);
    }
  };

  useEffect(() => {
    setCheckState(isChecked);
  }, [isChecked]);

  return (
    <StyledAppCheckbox
      className={classNames(`size-${size} variant-${variant}`, className)}
      id="action-checkbox"
      disabled={!isEditable}
      disableRipple
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      icon={<RadioButtonUnchecked />}
      checkedIcon={<CheckCircle />}
      color="primary"
      checked={checkState}
      onChange={onCheck}
      sx={{ ...sx }}
    />
  );
};
