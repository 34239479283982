import { SvgIcon, SvgIconProps } from '@mui/material';

export const Resize16px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M3.60938 12.0161L12.0153 3.6102"
      stroke="inherit"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
    <path
      d="M8.60352 12.3896L12.3899 8.60324"
      stroke="inherit"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default Resize16px;
