import { useMemo } from 'react';
import { selectEventProducerId } from '../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { selectIsOwnersTeammate, selectProcessId } from '../routes-old/process/state/processSlice';
import { EDealEventName } from '../../shared/events/Events';
import {
  DealFileInteraction,
  DealNotificationPayload,
  DealTaskDocumentAdded,
  DealTaskEventLinkClicked,
  DealLinkClicked,
  DealVideoWatched,
  DealDocumentInteraction,
  DealFileDeleted,
  DealTaskFileInteraction,
  DealTaskFileDeleted,
  DealTaskLinkClicked,
  DealStageSelected,
  DealEmbedInteraction,
  DealTaskTitleUpdatedPayload,
  DealTaskAssigneesUpdatedPayload,
  DealTaskPayloadBase,
  DealTaskEventDataUpdatedPayload,
  DealTaskStatusUpdatedPayload,
  DealTaskTimelineUpdatedPayload,
  DealButtonNodeInteraction,
  DealPricingTableUnitsChangeEvent,
} from '../../shared/notifications/Notifications';
import { EIdTagsForAPIs } from '../../shared/ProcessIdsTags';
import { selectCurrentStageId } from '../features/ProcessFields/lib/processItemsSlice';
import { generateDealEvent } from '../api/FrontEventsApi';

const useFrontEventsApi = () => {
  const dispatch = useAppDispatch();
  const producerId = useAppSelector(selectEventProducerId);
  const processId = useAppSelector(selectProcessId);
  const stageId = useAppSelector(selectCurrentStageId);

  const isTeammate = useAppSelector(selectIsOwnersTeammate);

  const payloadBase = useMemo(() => ({
    producerId,
    processId,
    stageId,
  }), [producerId, processId]);

  const createDealEvent = (name: EDealEventName, data?: DealNotificationPayload) => {
    if (!processId || processId === EIdTagsForAPIs.TEMPLATE) return;
    dispatch(generateDealEvent({ ...payloadBase, name, data }));
  };

  const createDealFileDownLoadEvent = (data: DealFileInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_FILE_DOWNLOADED,
      data,
    );
  };

  const createDealFileUploadedEvent = (data: DealFileInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_DOCUMENT_ADDED,
      data,
    );
  };

  const createDealFileDeletedEvent = (data: DealFileDeleted) => {
    createDealEvent(
      EDealEventName.DEAL_FILE_DELETED,
      data,
    );
  };

  const createDealLinkClickedEvent = (data: DealLinkClicked) => {
    createDealEvent(
      EDealEventName.DEAL_LINK_CLICKED,
      data,
    );
  };

  const createVideoWatchedEvent = (data: DealVideoWatched) => {
    createDealEvent(
      EDealEventName.DEAL_VIDEO_WATCHED,
      data,
    );
  };

  const createDocumentInteraction = (data: DealDocumentInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_DOCUMENT_INTERACTION,
      data,
    );
  };

  const createWebEmbedInteraction = (data: DealEmbedInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_WEB_EMBED_INTERACTION,
      data,
    );
  };

  const createRichMediaInteraction = (data: DealEmbedInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_RICH_MEDIA_INTERACTION,
      data,
    );
  };

  const createLinkPreviewInteraction = (data: DealEmbedInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_LINK_PREVIEW_INTERACTION,
      data,
    );
  };

  const createStageSelectedEvent = (data: DealStageSelected) => {
    if (!isTeammate) {
      createDealEvent(
        EDealEventName.DEAL_STAGE_SELECTED,
        data,
      );
    }
  };

  const createInviteLinkInteraction = () => {
    createDealEvent(
      EDealEventName.DEAL_INVITE_LINK_BTN_INTERACTION,
    );
  };

  // AGENDA
  const createTaskFileUploadedEvent = (data: DealTaskDocumentAdded) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_DOCUMENT_ADDED,
      data,
    );
  };

  const createTaskFileDownLoadEvent = (data: DealTaskFileInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_DOCUMENT_DOWNLOADED,
      data,
    );
  };

  const createTaskFileDeletedEvent = (data: DealTaskFileDeleted) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_DOCUMENT_DELETED,
      data,
    );
  };

  const createTaskEventLinkClickedEvent = (data: DealTaskEventLinkClicked) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_EVENT_LINK_CLICKED,
      data,
    );
  };

  // TASK description link clicked
  const createTaskLinkClickedEvent = (data: DealTaskLinkClicked) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_LINK_CLICKED,
      data,
    );
  };

  const createTitleUpdateEvent = (data: DealTaskTitleUpdatedPayload) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_TITLE_UPDATED,
      data,
    );
  };

  const createStatusUpdateEvent = (data: DealTaskStatusUpdatedPayload) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_STATUS_UPDATED,
      data,
    );
  };

  const createAssigneesUpdateEvent = (data: DealTaskAssigneesUpdatedPayload) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_ASSIGNEES_UPDATED,
      data,
    );
  };

  const createDescriptionUpdateEvent = (data: DealTaskPayloadBase) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_DESCRIPTION_UPDATED,
      data,
    );
  };

  const createTaskEventUpdateEvent = (data: DealTaskEventDataUpdatedPayload) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_EVENT_UPDATED,
      data,
    );
  };

  const createTaskTimelineUpdateEvent = (data: DealTaskTimelineUpdatedPayload) => {
    createDealEvent(
      EDealEventName.DEAL_TASK_TIMELINE_UPDATED,
      data,
    );
  };

  const createButtonNodeInteraction = (data: DealButtonNodeInteraction) => {
    createDealEvent(
      EDealEventName.DEAL_BUTTON_INTERACTION,
      data,
    );
  };

  const createPricingTableUnitsChangeEvent = (data: DealPricingTableUnitsChangeEvent) => {
    createDealEvent(
      EDealEventName.DEAL_PRICING_TABLE_UNITS_CHANGE,
      data,
    );
  };

  return ({
    createDealFileDownLoadEvent,
    createDealFileUploadedEvent,
    createDealFileDeletedEvent,
    createDealLinkClickedEvent,
    createVideoWatchedEvent,
    createDocumentInteraction,
    createTaskEventLinkClickedEvent,
    createTaskFileUploadedEvent,
    createTaskFileDownLoadEvent,
    createTaskFileDeletedEvent,
    createTaskLinkClickedEvent,
    createStageSelectedEvent,
    createInviteLinkInteraction,
    createWebEmbedInteraction,
    createRichMediaInteraction,
    createLinkPreviewInteraction,
    createTitleUpdateEvent,
    createStatusUpdateEvent,
    createAssigneesUpdateEvent,
    createDescriptionUpdateEvent,
    createTaskEventUpdateEvent,
    createTaskTimelineUpdateEvent,
    createButtonNodeInteraction,
    createPricingTableUnitsChangeEvent,
  });
};

export default useFrontEventsApi;
