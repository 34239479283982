import { DateTime } from 'luxon';
import { Error } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import {
  buyerRoles, EDealRoles, sellerRoles, TAllRoleNames,
} from '../../../shared/permissions';
import { AssigneeTeam, ETaskStatus } from '../../../shared/TaskDTO';
import { UserDTO } from '../../../shared/UserDTO';
import { IUserAvatarLinkOrLetter } from '../../features/TaskUsers/UserAvatars';
import {
  CompleteIcon,
  InProgressIcon,
  NotStartedIcon,
  OnHoldIcon,
} from '../../icons/StatusIcons/StatusIcons';
import { uniq } from '../../core/utils/uniq';
import { DealsType } from '../../../shared/process/Process';

// eslint-disable-next-line max-len
export const emptyDescription: string = '{"blocks":[{"key":"3ut9t","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

export const getMilestoneAssigneesAvatarsData = (
  assigneesIds: string[] | undefined,
  users: UserDTO[] | undefined,
  teamData: {
    assigneesTeams: AssigneeTeam[];
    sellerAvatar: string;
    buyerAvatar: string;
  },
): IUserAvatarLinkOrLetter[] => {
  const data: IUserAvatarLinkOrLetter[] = [];
  if (!assigneesIds || !users) {
    return [];
  }
  for (const team of uniq(teamData.assigneesTeams)) {
    switch (team) {
      case 'buyer':
        data.push({
          id: 'buyer',
          avatarSrc: teamData.buyerAvatar,
        });
        break;
      case 'seller':
        data.push({
          id: 'seller',
          avatarSrc: teamData.sellerAvatar,
        });
        break;
    }
  }
  for (const assigneeId of assigneesIds) {
    const user: UserDTO | undefined = users.find((item: UserDTO) => item.id === assigneeId);
    if (!user) {
      data.push({
        id: 'unknown',
        letter: undefined,
      });
    } else if (user.avatarSrc) {
      data.push({
        id: user.id,
        avatarSrc: user.avatarSrc,
      });
    } else if (user.organizationLogo) {
      data.push({
        id: user.id,
        avatarSrc: user.organizationLogo,
      });
    } else if (user.name) {
      data.push({
        id: user.id,
        letter: user.name[0],
        colorId: user.colorId,
      });
    } else {
      data.push({
        id: user.id,
        letter: undefined,
        colorId: user.colorId,
      });
    }
  }
  return data;
};
export const getStatusBackgroundColor = (status: ETaskStatus | undefined): string => {
  switch (status) {
    case ETaskStatus.COMPLETE:
      return '#27AE60';
    case ETaskStatus.IN_PROGRESS:
      // return '#0894E4';
      return '#1E1E1E';
    case ETaskStatus.PENDING:
      return 'rgba(126, 126, 126, 1)';
    case ETaskStatus.ON_HOLD:
      return '#d32f2f';
    case ETaskStatus.OVERDUE:
      return '#d32f2f';
    default:
      return 'transparent';
  }
};

export const getActivityStatusBackgroundColor = (status: ETaskStatus | undefined, theme: Theme): string => {
  switch (status) {
    case ETaskStatus.COMPLETE:
      return theme.palette.success[100];
    case ETaskStatus.IN_PROGRESS:
      return theme.palette.grey[100];
    case ETaskStatus.ON_HOLD:
      return theme.palette.error[100];
    case ETaskStatus.OVERDUE:
      return theme.palette.warning[100];
    default:
      return 'transparent';
  }
};

export const getTaskTitleColor = (status: ETaskStatus | undefined): string => {
  switch (status) {
    case ETaskStatus.COMPLETE:
      return '#1E1E1E';
    case ETaskStatus.IN_PROGRESS:
      return '#1E1E1E';
    case ETaskStatus.PENDING:
      return '#1E1E1E';
    case ETaskStatus.ON_HOLD:
      return '#1E1E1E';
    case ETaskStatus.OVERDUE:
      return '#1E1E1E';
    default:
      return 'transparent';
  }
};

export const getTaskStatusText = (status: ETaskStatus | undefined): string => {
  switch (status) {
    case ETaskStatus.COMPLETE:
      return 'Done';
    case ETaskStatus.IN_PROGRESS:
      return 'In progress';
    case ETaskStatus.PENDING:
      return 'Not started';
    case ETaskStatus.ON_HOLD:
      return 'On hold';
    default:
      return 'Status ERROR';
  }
};

export const getTaskStatusIcon = (status: ETaskStatus | undefined): JSX.Element => {
  switch (status) {
    case ETaskStatus.COMPLETE:
      return <CompleteIcon />;
    case ETaskStatus.IN_PROGRESS:
      return <InProgressIcon />;
    case ETaskStatus.PENDING:
      return <NotStartedIcon />;
    case ETaskStatus.ON_HOLD:
      return <OnHoldIcon />;
    default:
      return <Error />;
  }
};

const level: number = 0.7;
export const iconColorPalette: {
  [key: number]: {
    back: string,
    letter: string,
    transparentBack: string,
  }
} = {
  1: {
    back: 'rgba(233, 246, 254, 1)',
    transparentBack: `rgba(233, 246, 254, ${level})`,
    letter: 'rgba(37, 161, 232, 1)',
  },
  2: {
    back: 'rgba(211, 199, 245, 1)',
    transparentBack: `rgba(211, 199, 245, ${level})`,
    letter: 'rgba(112, 70, 231, 1)',
  },
  3: {
    back: 'rgba(249, 199, 177, 1)',
    transparentBack: `rgba(249, 199, 177, ${level})`,
    letter: 'rgba(243, 103, 43, 1)',
  },
  4: {
    back: 'rgba(251, 233, 188, 1)',
    transparentBack: `rgba(251, 233, 188, ${level})`,
    letter: 'rgba(236, 184, 49, 1)',
  },
  5: {
    back: 'rgba(207, 245, 248, 1)',
    transparentBack: `rgba(207, 245, 248, ${level})`,
    letter: 'rgba(32, 194, 204, 1)',
  },
  6: {
    back: 'rgba(255, 189, 189, 1)',
    transparentBack: `rgba(255, 189, 189, ${level})`,
    letter: 'rgba(208, 10, 10, 1)',
  },
  7: {
    back: 'rgba(154, 170, 227, 1)',
    transparentBack: `rgba(154, 170, 227, ${level})`,
    letter: 'rgba(31, 71, 211, 1)',
  },
  8: {
    back: 'rgba(247, 177, 249, 1)',
    transparentBack: `rgba(247, 177, 249, ${level})`,
    letter: 'rgba(209, 28, 215, 1)',
  },
  9: {
    back: 'rgba(201, 251, 188, 1)',
    transparentBack: `rgba(201, 251, 188, ${level})`,
    letter: 'rgba(98, 184, 57, 1)',
  },
  10: {
    back: 'rgba(206, 206, 206, 1)',
    transparentBack: `rgba(206, 206, 206, ${level})`,
    letter: 'rgba(113, 113, 113, 1)',
  },
  11: {
    back: 'rgba(150, 189, 196, 1)',
    transparentBack: `rgba(150, 189, 196, ${level})`,
    letter: 'rgba(21, 91, 103, 1)',
  },
  12: {
    back: 'rgba(249, 229, 218, 1)',
    transparentBack: `rgba(249, 229, 218, ${level})`,
    letter: 'rgba(171, 126, 100, 1)',
  },
  13: {
    back: 'rgba(214, 220, 186, 1)',
    transparentBack: `rgba(214, 220, 186, ${level})`,
    letter: 'rgba(105, 122, 27, 1)',
  },
  14: {
    back: 'rgba(214, 220, 186, 1)',
    transparentBack: `rgba(214, 220, 186, ${level})`,
    letter: 'rgba(122, 79, 50, 1)',
  },
  15: {
    back: 'rgba(253, 250, 207, 1)',
    transparentBack: `rgba(253, 250, 207, ${level})`,
    letter: 'rgba(180, 169, 12, 1)',
  },
  16: {
    back: 'rgba(206, 233, 216, 1)',
    transparentBack: `rgba(206, 233, 216, ${level})`,
    letter: 'rgba(45, 130, 76, 1)',
  },
  17: {
    back: 'rgba(220, 205, 176, 1)',
    transparentBack: `rgba(220, 205, 176, ${level})`,
    letter: 'rgba(142, 117, 68, 1)',
  },
  18: {
    back: 'rgba(168, 170, 208, 1)',
    transparentBack: `rgba(168, 170, 208, ${level})`,
    letter: 'rgba(69, 73, 160, 1)',
  },
  19: {
    back: 'rgba(175, 243, 219, 1)',
    transparentBack: `rgba(175, 243, 219, ${level})`,
    letter: 'rgba(122, 79, 50, 1)',
  },
  20: {
    back: 'rgba(253, 250, 207, 1)',
    transparentBack: `rgba(253, 250, 207, ${level})`,
    letter: 'rgba(180, 169, 12, 1)',
  },
};

export const formateValueForLastActive = (value?: number): string => {
  if (typeof value !== 'number') return '';
  const diffInTime = DateTime.fromMillis(value).diffNow(['years', 'months', 'days', 'hours', 'minutes']);
  const hours: number = Math.floor(Math.abs(diffInTime.hours));
  const days: number = Math.floor(Math.abs(diffInTime.days));
  const months: number = Math.floor(Math.abs(diffInTime.months));
  const years: number = Math.floor(Math.abs(diffInTime.years));
  const minutes: number = Math.floor(Math.abs(diffInTime.minutes));

  if (years) {
    if (years === 1) {
      return `${years} year ago`;
    }
    return `${years} years ago`;
  }
  if (months) {
    if (months === 1) {
      return `${months} month ago`;
    }
    return `${months} months ago`;
  }
  if (days) {
    if (days === 1) {
      return `${days} day ago`;
    }
    return `${days} days ago`;
  }
  if (hours) {
    if (hours === 1) {
      return `${hours} hour ago`;
    }
    return `${hours} hours ago`;
  }
  if (minutes) {
    return `${minutes} minutes ago`;
  }
  return '< 1 minute ago';
};

export const handleScroll = (target: Element, classNameActive: string): void => {
  const timeout = setTimeout(() => {
    target.classList.remove(classNameActive);
  }, 1000);

  if (!target.classList.contains(classNameActive)) {
    target.classList.add(classNameActive);
  } else {
    clearTimeout(timeout);
  }
};

export const addBlure = (value: boolean): void => {
  const appLayoutContainer: HTMLElement | null = document.querySelector('.AppLayoutContainer');
  if (appLayoutContainer) {
    if (value === true) {
      appLayoutContainer.style.filter = 'blur(5px)';
    }
    if (value === false) {
      appLayoutContainer.style.filter = '';
    }
  }
};

export const getTabForOverview = (userProcessRoleName: TAllRoleNames, manager?: boolean): void => {
  sessionStorage.removeItem('dealGridToRender');
  if (sellerRoles.includes(userProcessRoleName as EDealRoles)) {
    return sessionStorage.setItem('dealGridToRender', DealsType.SELLER_DEALS);
  }
  if (buyerRoles.includes(userProcessRoleName as EDealRoles)) {
    return sessionStorage.setItem('dealGridToRender', DealsType.BUYER_DEALS);
  }
  if (manager) {
    return sessionStorage.setItem('dealGridToRender', DealsType.TEAM_DEALS);
  }
};
