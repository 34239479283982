import { Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../hooks/useQuery';
import { ESocialAuthProvider, ESocialAuthTypes } from '../../../../shared/schema/auth.shcema';
import { SessionStorageWrapper } from '../../../common/sessionStorageWrapper';
import { config } from '../../../core';
import { EAppRoutes } from '../../../core/router';

const CustomDomainSoacialAuthStart = (): JSX.Element => {
  const query = useQuery();
  const navigate = useNavigate();
  const socialAuthType: ESocialAuthTypes = query.get('socialAuthType') as ESocialAuthTypes;
  const provider: ESocialAuthProvider = query.get('provider') as ESocialAuthProvider;
  const sessionStorageWrapper: SessionStorageWrapper = new SessionStorageWrapper();

  const getCelloReferrer = () => {
    if (query.has('celloReferrer')) {
      return query.get('celloReferrer');
    }
    if (typeof window.CelloAttribution === 'function') {
      return window.CelloAttribution('getReferral');
    }
    return undefined;
  };

  const celloReferrer: ESocialAuthProvider = getCelloReferrer() as ESocialAuthProvider;

  if (socialAuthType && provider) {
    sessionStorageWrapper.storeSocialAuthQuery({
      socialAuthType,
      provider,
      celloReferrer: celloReferrer ?? undefined,
    });
    window.location.href = `${config.api.origin}/${provider}`;
  } else {
    navigate(`${EAppRoutes.auth}/${EAppRoutes.login}`);
  }
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CustomDomainSoacialAuthStart;
