import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { useAppDispatch } from '../../hooks/stateHooks';
import {
  ESidebarTabs, setDealViewMode, setSidebarTab,
} from './lib/fieldsLayoutSlice';
import { setIsLayoutEditActive } from '../../routes-old/process/state/processSlice';

// Plugin for live updates
const LayoutStateControlPlugin = (): null => {
  const dispatch = useAppDispatch();
  const onEvent = (e: CustomEvent) => {
    dispatch(setDealViewMode(e.detail));
    if (e.detail === 'internal' && !isMobile) {
      dispatch(setSidebarTab(ESidebarTabs.INTERNAL));
    } else if (e.detail === 'editor') {
      dispatch(setIsLayoutEditActive(true));
      dispatch(setSidebarTab(ESidebarTabs.STAGES));
    } else {
      dispatch(setIsLayoutEditActive(false));
      dispatch(setSidebarTab(ESidebarTabs.STAGES));
    }
  };

  useEffect(() => {
    document.addEventListener(ECustomEventsNames.CHANGE_LAYOUT_STATE, onEvent as EventListener);
    return () => {
      document.removeEventListener(ECustomEventsNames.CHANGE_LAYOUT_STATE, onEvent as EventListener);
    };
  }, []);

  return null;
};

export default LayoutStateControlPlugin;
