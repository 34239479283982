import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M4.53337 15.2992L15.4728 4.31845"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M4.52539 4.28003L15.5772 15.3033"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const CloseIcon16 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4L4 12"
      stroke="#1E293B"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4 4L12 12"
      stroke="#1E293B"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
