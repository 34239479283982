import { Radio } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/stateHooks';
import { declineOrganizationInvite, transferUserToNewOrganization } from '../core/store/appState/appState';
import { OrganizationDTO } from '../../shared/OrganizationDTO';
import { EAppRoutes } from '../core/router';
import { AppLoader } from '../shared/AppLoader/AppLoader';
import ConfirmationDialog from './ConfirmationDialog/ConfirmationDialog';
import { AppAvatar } from '../shared/AppAvatar';
import { BuildingsIcon20 } from '../icons/BuildingsIcon';
import { stagesetColors } from '../theme/stagesetPalette';
import {
  CODAvatarPlaceholderContainer,
  CODLoaderContainer,
  CODOrganizationItemContainer,
  CODOrganizationListContainer,
  CODOrganizationTitle,
  CODTitleWrapper,
} from './Styles/CrossOrganizationInviteDialogStyles';

const CrossOrganizationInviteDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orgInvites = useAppSelector((state) => state.app.invites);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [choosenOrganization, setChoosenOrganization] = useState<string | null>();
  const [radioError, setRadioError] = useState<boolean>(false);

  const onAccept = async (id: string) => {
    setLoading(true);
    try {
      await dispatch(transferUserToNewOrganization(id));
      setOpen(false);
      navigate(EAppRoutes.home);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orgInvites?.length) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [orgInvites]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoosenOrganization(event.target.value);
    setRadioError(false);
  };

  const onCancel = () => {
    orgInvites.map((invite: any) => (
      dispatch(declineOrganizationInvite(invite.organization))
    ));
    return setOpen(false);
  };

  const onConfirm = () => {
    if (!choosenOrganization) {
      return setRadioError(true);
    }
    setOpen(false);
    return onAccept(choosenOrganization);
  };

  const loader = (
    <CODLoaderContainer>
      <AppLoader size={40} />
    </CODLoaderContainer>
  );

  const listOfInvites = (
    <CODOrganizationListContainer>
      {orgInvites?.map((invite: any) => {
        const inviterData: OrganizationDTO = invite.organizationData?.[0];
        return (
          <CODOrganizationItemContainer
            key={`invite-to-${invite.organization}`}
          >
            <CODTitleWrapper>
              {inviterData.organizationLogo ? (
                <AppAvatar
                  src={inviterData.organizationLogo}
                  className="logo"
                  sx={{
                    borderRadius: '8px',
                    height: '32px',
                    width: '32px',
                    border: '1px solid',
                    borderColor: stagesetColors.newGrey[100],
                  }}
                />
              ) : (
                <CODAvatarPlaceholderContainer>
                  <BuildingsIcon20 />
                </CODAvatarPlaceholderContainer>
              )}
              <CODOrganizationTitle>
                {inviterData.name}
              </CODOrganizationTitle>
            </CODTitleWrapper>
            <Radio
              // color={radioError ? 'warning' : 'primary'}
              checked={invite.organization === choosenOrganization}
              onChange={handleChange}
              value={invite.organization}
              name="radio-buttons"
              sx={{
                height: '20px',
                width: '20px',
                '& svg': {
                  fill: radioError ? stagesetColors.red[600] : stagesetColors.newGrey[500],
                },
              }}
            />
          </CODOrganizationItemContainer>
        );
      })}
    </CODOrganizationListContainer>
  );

  return (
    <ConfirmationDialog
      isDialogOpen={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      dialogContentText={loading ? loader : listOfInvites}
      dialogTitle="Invite to team"
      confirmText="Accept"
      cancelText="Decline"
    />
  );
};

export default CrossOrganizationInviteDialog;
