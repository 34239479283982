import {
  useEffect, useState,
} from 'react';
import {
  getLogLimit,
  getProcessTimelines,
  selectEventsFilterMap,
  selectIsEventsFilterActive,
  selectIsUserFilterActive,
  selectTimelineTeamfilter,
  selectUserFilterMap,
  setScoreData,
} from '../features/ActivityLog/lib/processActivityLogSlice';
import { selectProcessId, selectProcessUsersMap } from '../routes-old/process/state/processSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';
import useProcessTimelineDateFilter from './useProcessTimelineDateFilter';
import { selectTeammatesIdsMap, selectUserId } from '../core/store/appState/appState';

const useProcessTimelines = () => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(selectProcessId);

  const initiatorId = useAppSelector(selectUserId);

  const processUsersMap = useAppSelector(selectProcessUsersMap);

  const teammatesIds = useAppSelector(selectTeammatesIdsMap);
  const areTeammatesDisabled = !useAppSelector(selectTimelineTeamfilter);

  const isUserFilter = useAppSelector(selectIsUserFilterActive);
  const userFilterMap = useAppSelector(selectUserFilterMap);

  const isEventFilter = useAppSelector(selectIsEventsFilterActive);
  const eventFiltersMap = useAppSelector(selectEventsFilterMap);

  const { timelineStart: startDate, timelineEnd: endDate } = useProcessTimelineDateFilter();

  const timeline = useAppSelector((state) => state?.processActivityLog?.timelines?.[startDate]);

  const limit = useAppSelector(getLogLimit);

  const [filteredTimeline, setFilteredTimeline] = useState<Array<any> | null>(null);

  useEffect(() => {
    if (timeline) {
      let filtered: Array<any> = timeline;
      let daysWithoutActivity = 30;
      let currentDate: number;
      let newDate: number;
      const entries: { [k: string]: string } = {};

      // if (areTeammatesDisabled || isUserFilter || isEventFilter) {
      filtered = timeline.filter(
        (session) => {
          newDate = new Date(session[0].createdAt).setHours(0, 0, 0, 0);
          if (!processUsersMap.has(session[0].payload.producerId)) {
            return false;
          }
          if (newDate !== currentDate) {
            currentDate = newDate;
            if (!teammatesIds.has(session[0].payload.producerId)) {
              daysWithoutActivity -= 1;
            }
          }
          if (!teammatesIds.has(session[0].payload.producerId)) {
            entries[session[0].payload.producerId] = session[0].payload.producerId;
          }
          if (
            isUserFilter
              && areTeammatesDisabled
              && teammatesIds.has(session[0].payload.producerId)
              && !userFilterMap.has(session[0].payload.producerId)
          ) {
            return false;
          }
          if (areTeammatesDisabled && teammatesIds.has(session[0].payload.producerId)) {
            return false;
          }
          if (isUserFilter && !userFilterMap.has(session[0].payload.producerId)) {
            return false;
          }
          if (isEventFilter && !session.find((event) => eventFiltersMap.has(event.name))) {
            return false;
          }
          return true;
        },
      );
      // }
      dispatch(setScoreData({
        inactiveDays: daysWithoutActivity,
        uniqueEntries: Object.keys(entries).length > 3 ? 3 : Object.keys(entries).length,
      }));
      setFilteredTimeline(filtered);
    }
  }, [
    timeline,
    teammatesIds,
    areTeammatesDisabled,
    isUserFilter,
    userFilterMap,
    isEventFilter,
    eventFiltersMap,
    processUsersMap,
  ]);

  const refetch = () => {
    setFilteredTimeline(null);
    dispatch(
      getProcessTimelines({
        processId, startDate, endDate, initiatorId, offset: new Date().getTimezoneOffset(),
      }),
    );
  };

  const fetch = async () => {
    dispatch(
      getProcessTimelines({
        processId, startDate, endDate, initiatorId, offset: new Date().getTimezoneOffset(),
      }),
    ).unwrap();
  };

  useEffect(() => {
    fetch();
  }, [processId, startDate, endDate]);

  return {
    timeline: filteredTimeline?.slice(0, limit),
    startDate,
    refetch,
  };
};

export default useProcessTimelines;
