import { GridRowsProp, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { ExtendedGridColumns } from '../types/mobile-table';
import { OrganizationUserDTO } from '../../shared/UserDTO';
import { EUserOrganizationStatus } from '../../shared/user-organization';
import { RoleDTO } from '../../shared/permissions';
import { useOrganization } from './useOrganization';
import RoleSelectorCell from '../routes-old/account/SettingsTabs/tabItems/TeamTabCells/RoleSelectorCell';
import { NameTitleCell } from '../routes-old/account/SettingsTabs/tabItems/TeamTabCells/NameTitleCell';
import { CellMoreMenuPopover } from '../routes-old/account/SettingsTabs/tabItems/TeamTabCells/CellMoreMenuPopover';
import { TeamTabTableContextProvider } from '../routes-old/account/SettingsTabs/tabItems/TeamTabTableContext';

export enum ETeamTabDataGridColumns {
  email = 'email',
  name = 'name',
  title = 'title',
  menu = 'menu',
  status = 'status',
  actions = 'actions',
  lastActive = 'lastActive',
  role = 'role',
  value = 'value',
  client = 'client',
  template = 'template',
  lastMileStone = 'lastMileStone',
  health = 'health',
}

export const useTeamTabDataGrid = () => {
  const { roles, users } = useOrganization();

  const columns: ExtendedGridColumns = [
    {
      sortable: true,
      headerName: 'Name',
      field: ETeamTabDataGridColumns.name,
      sortComparator: (
        val1,
        val2,
        conf1,
        conf2,
      ) => gridStringOrNumberComparator(val1?.name, val2?.name, conf1, conf2),
      minWidth: 100,
      flex: 5,
      renderCell: (
        {
          value: {
            name, avatarSrc, title, colorId, email,
          },
        },
      ) => (
        <NameTitleCell name={name ?? email} title={title} colorId={colorId} avatarSrc={avatarSrc} />
      ),
    },
    {
      sortable: true,
      headerName: 'Email',
      field: ETeamTabDataGridColumns.email,
      minWidth: 200,
      flex: 1,
      renderMobileCell: true,
    },
    {
      sortable: false,
      headerName: 'Role',
      field: ETeamTabDataGridColumns.role,
      minWidth: 150,
      flex: 1,
      cellClassName: 'RoleSellector',
      renderCell: ({ value }) => (
        <TeamTabTableContextProvider props={value.user}>
          <RoleSelectorCell />
        </TeamTabTableContextProvider>
      ),
      renderMobileCell: ({ value }) => (
        <TeamTabTableContextProvider props={value.user}>
          <RoleSelectorCell />
        </TeamTabTableContextProvider>
      ),
    },
    {
      sortable: true,
      headerName: 'Status',
      field: ETeamTabDataGridColumns.status,
      minWidth: 50,
      flex: 1,
      renderCell: (
        {
          value,
        },
      ) => (
        value === EUserOrganizationStatus.ACTIVE ? 'Active' : 'Pending'
        // @ts-ignore
      ),
      renderMobileCell: ({ value }) => (
        <span>
          {value === EUserOrganizationStatus.ACTIVE ? 'Active' : 'Pending'}
        </span>
      ),
    },
    {
      headerName: '',
      minWidth: 50,
      field: ETeamTabDataGridColumns.menu,
      flex: 1,
      renderCell: ({
        value,
      }) => (
        <TeamTabTableContextProvider
          props={value}
        >
          <CellMoreMenuPopover />
        </TeamTabTableContextProvider>
      ),
    },
  ];

  let rows: GridRowsProp = [];

  if (users?.length) {
    rows = users
      .map((user: OrganizationUserDTO) => ({
        [ETeamTabDataGridColumns.title]: user,
        [ETeamTabDataGridColumns.role]: {
          roleId: user.rolesIds ? roles.filter((role: RoleDTO) => user.rolesIds.includes(role.id))[0].id : ' ',
          user,
        },
        [ETeamTabDataGridColumns.email]: user.email,
        [ETeamTabDataGridColumns.name]: user,
        [ETeamTabDataGridColumns.status]: user.organizationStatus,
        [ETeamTabDataGridColumns.menu]: user,
        id: user.id,
      }));
  }

  return {
    rows,
    columns,
  };
};
