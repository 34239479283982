import {
  Node, NodeViewProps, ReactNodeViewRenderer, mergeAttributes,
} from '@tiptap/react';
import { ExtensionNames } from '../../Utils/TiptapHelpers';
import TiptapNodeWrapper from '../../TiptapNodeWrapper';
import IframelyNodeComponent from './IframelyNodeComponent/IframelyNodeComponent';

const IframelyExtension = Node.create({
  name: ExtensionNames.LINK_PREVIEW, // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      url: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: ExtensionNames.LINK_PREVIEW,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [ExtensionNames.AGENDA, mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(
      (nodeProps: NodeViewProps) => (
        <TiptapNodeWrapper
          {...nodeProps}
          component={<IframelyNodeComponent />}
          className={ExtensionNames.LINK_PREVIEW}
        />
      ),
    );
  },
});

export default IframelyExtension;
