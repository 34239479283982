import {
  Box,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { AppButton } from '../../shared/AppButton/AppButton';
import { CloseIcon } from '../../icons/Close16px';
import { StyledLibraryDialog } from './styles/LibraryDialogStyle';
import { StyledDialogActions } from '../../common/ConfirmationDialog/ConfirmationDialogStyle';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { addBlure } from '../../routes-old/process/helpers';
import MediaFieldUpload from '../../features/MediaField/MediaFieldEmpty/MediaFieldUpload';
import { selectUserId, selectOrganizationId } from '../../core/store/appState/appState';
import {
  createFileItem, selectEditFileType, selectEditItem, selectLibraryGridToRender, updateFileItem,
} from './lib/librarySlice';
import { EFileEditType, EFileType, ELibraryType } from '../../../shared/library/Library';
import { AppInput } from '../../shared/AppInput/AppInput';
import { getSubtypeByMIME, mediaFieldFormats } from '../../features/MediaField/Helpers/MediaFieldHelpers';
// import DeleteSectionSvg from '../../icons/SectionMenuIcons/Delete';

export interface IEditDealFormState {
  title: string;
  client: string;
  value: number;
  logo: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LibraryItemDialog = ({
  isOpen,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();

  const [dialogTitle, setDialogTitle] = useState('Add file');
  const editType: EFileEditType = useAppSelector(selectEditFileType);
  const dialogRef = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const userId = useAppSelector(selectUserId);
  const organizationId = useAppSelector(selectOrganizationId);
  const editItem = useAppSelector(selectEditItem);
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const [fileLink, setFileLink] = useState<string>(editType !== EFileEditType.EditFile ? editItem?.link : undefined);
  const [fileName, setFileName] = useState<string>(editItem?.name);
  const [fileSubtype, setFileSubtype] = useState(editItem?.fileSubtype);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const action: string = editItem ? 'Edit' : 'Add';
    if (gridToRender === ELibraryType.FILE) {
      setDialogTitle(`${action} File`);
      setFileSubtype(editItem?.fileSubtype);
    } else {
      setDialogTitle(`${action} Product`);
    }
    if (editType !== EFileEditType.EditFile) {
      setFileLink(editItem?.link);
    }
    setFileName(editItem?.name);
  }, [editItem]);

  const handleClick = () => {
    if (editItem && !!userId && !!organizationId) {
      dispatch(updateFileItem({
        id: editItem.id,
        name: fileName,
        userId,
        file,
        organizationId,
        type: EFileType.FILE,
        link: fileLink,
        fileUid: editItem.fileUid,
        fileSubtype,
      }));
      setFile(undefined);
      onClose();
    } else if (!!file && !!userId && !!organizationId) {
      dispatch(createFileItem({
        name: fileName,
        userId,
        file,
        organizationId,
        type: EFileType.FILE,
        fileSubtype,
      }));
      setFile(undefined);
      onClose();
    }
  };

  useEffect(() => {
    switch (isOpen) {
      case true:
        addBlure(true);
        break;
      case false:
        addBlure(false);
        break;
      default:
        addBlure(false);
        break;
    }
  }, [isOpen]);

  const onFilesUpload = (files: File[]) => {
    if (files?.length) {
      setFile(files[0]);
      setFileName(files[0].name);
      setFileSubtype(getSubtypeByMIME(files[0].type));
    }
  };

  // const onDelete = () => {
  //   setFileLink('');
  //   setFile(undefined);
  // };

  return (
    <StyledLibraryDialog
      ref={dialogRef}
      open={isOpen}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        id: 'create-deal-dialog',
        sx: {
          boxShadow: 'none',
          overflow: 'visible',
        },
      }}
    >
      <Box
        component="form"
        id="create-deal-form"
        autoComplete="off"
        noValidate
      >
        <DialogTitle sx={{
          padding: '24px 24px 0px 24px !important',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Typography
            sx={{
              lineHeight: '25px',
              fontWeight: 500,
              fontSize: '20px',
              letterSpacing: '-1px',
            }}
          >
            {dialogTitle}
          </Typography>
          <Box>
            <AppIconButton icon={<CloseIcon />} onClick={onClose} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{
          padding: '20px 24px !important',
        }}
        >
          {((fileLink || file) && editType === EFileEditType.EditName) ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                gap: '4px',
                paddingBottom: '8px',
                fontWeight: '500',
              }}
            >
              <AppInput
                fullWidth
                label="Name"
                defaultValue={fileName}
                value={fileName}
                onChange={(event) => {
                  setFileName(event.target.value);
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                fontWeight: '500',
              }}
            >
              {file ? fileName : ''}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '4px',
            }}
          >
            {(!(fileLink || file) || editType === EFileEditType.EditFile) && (
              <MediaFieldUpload
                // uploadLimitation={uploadLimitation}
                onFilesUpload={onFilesUpload}
                extensions={mediaFieldFormats}
                description={mediaFieldFormats.join(', ').toUpperCase()}
              />
            )}
          </Box>
        </DialogContent>
        <StyledDialogActions
          sx={{
            padding: '12px 24px !important',
            justifyContent: 'space-between !important',
            marginTop: '0px !important',
          }}
        >
          <AppButton
            variant="primary"
            size="l"
            fullWidth
            onClick={handleClick}
            disabled={!(file || fileLink) || !fileName}
          >
            Save
          </AppButton>
        </StyledDialogActions>
      </Box>
    </StyledLibraryDialog>
  );
};

export default LibraryItemDialog;
