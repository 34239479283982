import { ObjectBase } from '../../../../shared/Tiptap/TiptapTypes';
import { ECustomEventsNames } from '../../../../shared/events/CustomEvents';

const useBlockUpdateEvent = <T extends ObjectBase>(id: string) => {
  const onUpdate = (data: Partial<T>) => {
    document.dispatchEvent(new CustomEvent(`${ECustomEventsNames.TIPTAP_BLOCK_UPDATE}-${id}`, {
      detail: data,
    }));
  };

  return { onUpdate };
};

export default useBlockUpdateEvent;
