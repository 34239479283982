import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { EFileType } from '../../../../shared/library/Library';
import { FileIcon } from '../../../features/Tiptap/icons/MenuIcons';
import { LinkIcon } from '../../../features/Tiptap/icons/ToolbarIcons';
import { useMobileBottomDrawer } from '../../../hooks/useMobileBottomDrawer';
import { AppButton } from '../../../shared/AppButton/AppButton';
import {
  LibraryCreateItemMenuButtonWrapper,
  LibraryCreateItemMenuTabWrapper,
  LibraryCreateItemMenuTitle,
  LibraryCreateItemMenuTitleWrapper,
} from './styles/LIbraryCreateItemMenuStyles';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import {
  changeFileLinkDialogStatus,
  createFileItem,
} from '../lib/librarySlice';
import { selectOrganizationId, selectUserId } from '../../../core/store/appState/appState';
import { getSubtypeByMIME, mediaFieldFormats, mediaFieldMimes } from '../../../features/MediaField/Helpers/MediaFieldHelpers';

const MAX_SIZE = 1024 * 1024 * 5;

const LibraryCreateItemMenu = (): JSX.Element | null => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const organizationId = useAppSelector(selectOrganizationId);
  const { closeDrawer } = useMobileBottomDrawer();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File[] | undefined>([]);
  const inputAccepts = mediaFieldMimes(mediaFieldFormats);
  const createFile = () => inputRef.current?.click();

  const handleUploadStart = (data: File[]) => {
    if (organizationId && userId) {
      dispatch(createFileItem({
        name: data[0].name,
        userId,
        file: data[0],
        organizationId,
        type: EFileType.FILE,
        fileSubtype: getSubtypeByMIME(data[0].type),
      }));
      setFile(undefined);
      enqueueSnackbar(
        'File uploaded successfully',
        {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 1000,
        },
      );
    }
  };

  useEffect(() => {
    if (file?.[0]) {
      if (file?.[0].size > MAX_SIZE) {
        enqueueSnackbar('Wrong file size!', { variant: 'error' });
      } else {
        handleUploadStart(file);
      }
    }
  }, [file]);

  const handleCreateLinkClick = () => {
    dispatch(changeFileLinkDialogStatus({
      open: true,
    }));
    closeDrawer();
  };

  return (
    <LibraryCreateItemMenuTabWrapper>
      <LibraryCreateItemMenuTitleWrapper>
        <LibraryCreateItemMenuTitle>
          Add
        </LibraryCreateItemMenuTitle>
        <AppButton
          size="s-without-padding"
          onClick={closeDrawer}
        >
          Cancel
        </AppButton>
      </LibraryCreateItemMenuTitleWrapper>
      <LibraryCreateItemMenuButtonWrapper>
        <input
          hidden
          type="file"
          ref={inputRef}
          onChange={(e) => e.target.files && setFile([...e.target.files])}
          className="input"
          accept={inputAccepts}
        />
        <AppButton
          onClick={() => {
            createFile();
            closeDrawer();
          }}
          fullWidth
          disableRipple
          variant="transparent-grey-icon"
          size="l-flex-start"
          startIcon={<FileIcon />}
        >
          File
        </AppButton>
        <AppButton
          onClick={handleCreateLinkClick}
          fullWidth
          disableRipple
          variant="transparent-grey-icon"
          size="l-flex-start"
          startIcon={<LinkIcon />}
        >
          Link
        </AppButton>
      </LibraryCreateItemMenuButtonWrapper>
    </LibraryCreateItemMenuTabWrapper>
  );
};

export default LibraryCreateItemMenu;
