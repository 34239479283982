import { BubbleMenu } from '@tiptap/react';
import TiptabToolbarItems from './TiptapToolbarItems';
import TiptapToolbarItemsWrapper from './TiptapToolbarItemsWrapper';

const TiptapToolbar = (): JSX.Element | null => (
  <BubbleMenu
    shouldShow={
        ({ editor: e }) => !document.querySelector('.TiptapBlockMenu-open')
          && e.state.selection.from !== e.state.selection.to
          && e.isEditable
          && (e?.isActive('paragraph') || e?.isActive('heading'))
      }
  >
    <TiptapToolbarItemsWrapper>
      <TiptabToolbarItems />
    </TiptapToolbarItemsWrapper>
  </BubbleMenu>
);

export default TiptapToolbar;
