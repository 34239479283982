import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  ariaLabel?: string;
  className?: string;
  tabValue?: string | number | boolean;
  setTabValue: (event: SyntheticEvent, newValue: any) => void;
  items: any[];
}

const AppTabsWithIndicator = ({
  ariaLabel,
  className,
  tabValue,
  setTabValue,
  items,
}: Props) => {
  const handleChange = (event: SyntheticEvent, newValue: any) => {
    setTabValue(event, newValue);
  };

  const controlButtons = useMemo(() => items.map((item) => (
    <Tab
      key={item.value}
      label={item.title}
      value={item.value}
    />
  )), [items]);

  return (
    <Tabs
      className={className}
      value={tabValue}
      onChange={handleChange}
      aria-label={ariaLabel}
      sx={{
        display: 'flex',
        minHeight: '40px',
        height: '40px',
        padding: '4px',
        alignItems: 'center',
        '& .MuiTabs-flexContainer': {
          height: '40px',
          minHeight: '40px',
        },
        '& .MuiButtonBase-root': {
          color: (theme: any) => theme.palette.primary.main,
          fontWeight: 500,
          lineHeight: '16px',
          fontSize: '14px',
        },
        '& .MuiButtonBase-root:not(.main-tabs-mobile):not(.Mui-selected):hover': {
          color: (theme: any) => theme.palette.primary.main,
        },
        '& .MuiButtonBase-root:not(.main-tabs-mobile):not(.Mui-selected):focus': {
          color: (theme: any) => theme.palette.primary.main,
        },
        '& button': {
          height: '32px',
          minHeight: '32px',
          padding: '6px',
          backgroundColor: 'transparent',
        },
      }}
    >
      {controlButtons}
    </Tabs>
  );
};

export default AppTabsWithIndicator;
