import {
  EditorProvider, Content,
  EditorEvents,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TableRow from '@tiptap/extension-table-row';
import Placeholder from '@tiptap/extension-placeholder';
import Collaboration from '@tiptap/extension-collaboration';
import {
  useContext,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import Table from '@tiptap/extension-table';
import { isMobile } from 'react-device-detect';
import MediaNodeExtension from './Extensions/MediaNode/MediaNodeExtension';
import AgendaNodeExtension from './Extensions/AgendaNode/AgendaNodeExtension';
import { UniqueID } from './Extensions/UiqueIdPlugin/UiqueIdPlugin';
import {
  WrappedBulletList, WrappedHeading, WrappedOrderedList, WrappedParagraph,
} from './Extensions/DefaultWithWrapper/WrappedTiptapNodes';
import { TiptapCustomEvents, TiptapEventListnerPlugin } from './TiptapEventListnerPlugin';
import IframelyExtension from './Extensions/LiniPreviewNode/IframelyExtension';
import DocumentsNodeExtension from './Extensions/DocumentsNode/DocumentsNodeExtension';
import CommandsExtension from './Extensions/Commands/CommandsExtension';
import CommandsSuggestions from './Extensions/Commands/CommandSuggestions';
import TiptapStyledWrapper from './styles/TiptapStyledWrapper';
import PricingTableExtension from './Extensions/PricingTableNode/PricingTableExtension';
import TiptapNodeMenu from './CreateNodeMenu/TiptapNodeMenu';
import ButtonNodeExtension from './Extensions/ButtonNode/ButtonNodeExtension';
import TiptapToolbar from './TiptapToolbar';
import TiptapButtonToolbar from './TiptapButtonToolbar';
import useTiptapSockets from './useTiptapSockets';
import TiptapSlotAfter from './TiptapSlotAfter';
import { StageContentType } from '../../../shared/Stage';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import CommandTemplateVars from './Extensions/TemplateVars/CommandTemplateVars';
import TempVarsExtensions from './Extensions/TemplateVars/TemplateVarsExtension';
import { ItemContext } from '../Layout/ItemContextProvider';
import { AppSkeletonStrings } from '../../shared/AppSkeleton/AppSkeletonStrings';
import useEditorEvent from './Hooks/useTiptapEditorEvents';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions } from '../../../shared/permissions';
import TiptapMobileToolbar from './TiptapMobileToolbar';

const Tiptap = ({
  content,
  wide,
  offline,
}: {
  content: Content,
  wide?: boolean,
  offline?: boolean,
}) => {
  const { ydoc } = useTiptapSockets(offline);
  const data = useCurrentStage();
  const { dispatchEditorEvent } = useEditorEvent();
  const [checkPermissions] = useDealPermissions();
  const { onUpdate } = useContext(ItemContext);
  const [isCreated, setIsCreated] = useState(false);
  const wideScreenStage = data?.stageContentType === StageContentType.WIDESCREENSTAGE;
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const [showToolbar, setShowToolbar] = useState<boolean>(true);

  const handleUpdate = ({ editor, transaction }: EditorEvents['update']) => {
    if (offline && isCreated && transaction.steps.length && onUpdate && editor) {
      dispatchEditorEvent({ eventType: TiptapCustomEvents.SAVE_UPDATE });
    }
  };

  const generateInitialContent = useMemo(() => {
    if (offline) {
      return content;
    } if (!offline && ydoc && ydoc.store.clients.size === 0) {
      return content;
    }
    return content;
    // return undefined;
  }, [ydoc]);

  if (!ydoc && !offline) {
    return (
      <AppSkeletonStrings />
    );
  }

  const baseExtensions = [
    Document,
    Link,
    Underline,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    WrappedParagraph,
    WrappedHeading,
    WrappedBulletList,
    WrappedOrderedList,
    MediaNodeExtension,
    DocumentsNodeExtension,
    IframelyExtension,
    PricingTableExtension,
    AgendaNodeExtension,
    Table.configure({
      HTMLAttributes: {
        class: 'tiptap-column-table',
      },
      resizable: checkPermissions(EUserDealPermissions.DEAL_LAYOUT_EDIT) && !isMobile,
      lastColumnResizable: false,
    }),
    TableCell,
    TableHeader,
    TableRow,
    ButtonNodeExtension,
    ListItem.configure({
      HTMLAttributes: {
        class: 'tiptap-list-item',
      },
    }),
    CommandsExtension.configure({
      suggestion: CommandsSuggestions,
    }),
    TempVarsExtensions.configure({
      tempVars: CommandTemplateVars,
    }),
    Placeholder.configure({
      placeholder: 'Enter `/` to add element',
    }),
    UniqueID.configure({
      types: [
        'media',
        'agenda',
        'paragraph',
        'heading',
        'bulletList',
        'orderedList',
        'linkPreview',
        'documents',
        'table',
        'pricingTable',
        'buttonNode',
      ],
    }),
  ];

  const extensions = offline ? [
    StarterKit.configure({
      document: false,
      heading: false,
      paragraph: false,
      listItem: false,
      orderedList: false,
      bulletList: false,
      code: false,
      codeBlock: false,
    }),
    ...baseExtensions,
  ] : [
    StarterKit.configure({
      document: false,
      heading: false,
      paragraph: false,
      listItem: false,
      orderedList: false,
      bulletList: false,
      history: false,
      code: false,
      codeBlock: false,
    }),
    Collaboration.configure({
      document: ydoc,
    }),
    ...baseExtensions,
  ];

  return (
    <TiptapStyledWrapper
      className={classNames('TiptapEditorWrapper', {
        widescreen: wide,
        editable: isLayoutEdit,
        mobile: isMobile,
      })}
    >
      <EditorProvider
        extensions={extensions}
        content={generateInitialContent}
        onUpdate={handleUpdate}
        slotAfter={wideScreenStage ? null : <TiptapSlotAfter />}
        onSelectionUpdate={() => { if (!showToolbar) { setShowToolbar(true); } }}
        onCreate={() => {
          setIsCreated(true);
        }}
      >
        <TiptapEventListnerPlugin
          onUpdate={onUpdate}
        />
        {
          !isMobile && (
            <>
              <TiptapToolbar />
              <TiptapButtonToolbar />
            </>
          )
        }
        {
          isMobile && (
            <TiptapMobileToolbar
              show={showToolbar}
              setShow={setShowToolbar}
            />
          )
        }
        <TiptapNodeMenu />
      </EditorProvider>
    </TiptapStyledWrapper>
  );
};

export default Tiptap;
