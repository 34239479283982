import { StagesetPlansDTO, StripePrice } from '../../shared/stripe';
import { ApiService } from '../core/api';

export class StripeApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('stripe');
  }

  async getPrices(): Promise<StripePrice[]> {
    return this.api.get<StripePrice[]>('/prices');
  }

  async getProducts(): Promise<any> {
    return this.api.get<any>('/products');
  }

  async getStagesetPlans(): Promise<StagesetPlansDTO[]> {
    return this.api.get<any>('/stageset-plans');
  }
}
