import {
  ChangeEvent, ReactElement, useMemo, useState,
} from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Unopened from '../icons/DealsFiltersInput/Health/Unopened.svg';
import Cold from '../icons/DealsFiltersInput/Health/Cold.svg';
import Hot from '../icons/DealsFiltersInput/Health/Hot.svg';
import { ProcessStatus } from '../../shared/process/ProcessMilestoneActionDTO';
import StatusInProgress from '../icons/DealStatusIcons/Play.svg';
import StatusOnHold from '../icons/DealStatusIcons/Pause.svg';
import Check from '../icons/DealStatusIcons/Check.svg';
import StatusClosedLost from '../icons/DealStatusIcons/Cross.svg';
import { AppAvatar } from '../shared/AppAvatar';
import { AppDropdownItemProps } from '../shared/AppDropdown/AppDropdownItem';
import Health from '../icons/DealsFiltersInput/Health.svg';
import Status from '../icons/DealsFiltersInput/Status.svg';
import Owner from '../icons/DealsFiltersInput/Owner.svg';
import Company from '../icons/DealsFiltersInput/Company.svg';
import {
  DealFilterSetDTO,
  FilterCategory,
  FilterInfoItem,
  FilterParams,
  FilterTitle,
  MultipleFilter,
  OwnerFilterItem,
  SubItem,
} from '../../shared/process/ProcessFilter';
import { useAppDispatch, useAppSelector } from './stateHooks';
import {
  selectCollectFilterSetInfoDialogStatus,
  selectExpanded,
  selectFilterInfo,
  selectFilterInfos,
  selectFilterSearch,
  selectGridFilters,
  selectManageFilterSetsDialogStatus,
  selectPopupCategory,
  setExpanded,
  setGridFilters,
  setManageFilterSetsDialogStatus,
  setMobileFilterMenu,
  setPopupCategory,
  setSearch,
} from '../features/Deals/DataGridFilter/lib/dealsGridSlice';
import useDebounce from './useDebounce';
import { AppButton } from '../shared/AppButton/AppButton';
import { selectGridToRender } from '../routes-old/deals/state/dealsSlice';
import { DealsType } from '../../shared/process/Process';
import { updateUserFilter } from '../core/store/appState/appState';

type Params = {
  savedFilters?: DealFilterSetDTO[]
};

type UpdateMultipleFilter<T = {}> = { category: FilterCategory, item: SubItem<T>, title: FilterTitle };
type RemoveMultipleFilterValue = { category: FilterCategory, value: string };

export const useGridFilter = ({ savedFilters = [] }: Params) => {
  const dispatch = useAppDispatch();
  const search: string = useAppSelector(selectFilterSearch);
  const gridFilters = useAppSelector(selectGridFilters);
  // const dealsFilterInfo = useAppSelector(selectFilterInfo);
  const dealsFilterInfos = useAppSelector(selectFilterInfos);
  const expanded = useAppSelector(selectExpanded);
  const popupCategory = useAppSelector(selectPopupCategory);
  const gridToRender = useAppSelector(selectGridToRender);
  const newFilterSetDialogStatus = useAppSelector(selectCollectFilterSetInfoDialogStatus);
  const manageFilterSetsDialogStatus = useAppSelector(selectManageFilterSetsDialogStatus);
  const [anchorEl, setAnchorEl] = useState<DOMRect>();
  const debouncedGridFilters = useDebounce(gridFilters, 250);
  const debouncedFilterSearch = useDebounce(search, 250);
  const filterParams = useMemo(() => gridFilters[gridToRender], [gridFilters, gridToRender]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const closeMenu = () => {
    if (isMobile) {
      dispatch(setMobileFilterMenu(false));
    } else {
      setAnchorEl(undefined);
    }
  };

  const openMenu = (node: Element, category?: FilterCategory) => {
    dispatch(setPopupCategory(category || null));
    if (isMobile) {
      dispatch(setMobileFilterMenu(true));
    } else {
      setAnchorEl(node.getBoundingClientRect());
    }
  };

  const getCategoryItem = (category: FilterCategory, value: string | number) => category in filterParams
      && (filterParams[category]! as MultipleFilter)
        .subItems.find((subItem) => subItem.value === value);

  const updateFilterParams = async (params: FilterParams) => {
    try {
      await dispatch(updateUserFilter({ dealType: gridToRender, filterParams: params })).unwrap();
    } catch (e) { /* Silent catch */ }
    dispatch(setGridFilters({ grid: gridToRender, params }));
  };

  const updateSearch = (searchQuery: string) => {
    dispatch(setSearch(searchQuery));
  };

  const updateMultipleFilter = (toUpdate: UpdateMultipleFilter) => {
    const mutableFilters: FilterParams = JSON.parse(JSON.stringify(filterParams));
    const currentFilter = mutableFilters[toUpdate.category];
    if (!currentFilter) {
      mutableFilters[toUpdate.category] = {
        category: toUpdate.category,
        title: toUpdate.title,
        subItems: [toUpdate.item],
      } as MultipleFilter;
      return updateFilterParams(mutableFilters);
    }
    const currentMultipleFilter = currentFilter as MultipleFilter;
    const itemIndex = currentMultipleFilter.subItems.findIndex((subItem) => toUpdate.item.value === subItem.value);
    if (itemIndex === -1) {
      currentMultipleFilter.subItems.push(toUpdate.item);
    } else {
      if (currentMultipleFilter.subItems.length === 1) {
        delete mutableFilters[currentMultipleFilter.category];
        return updateFilterParams(mutableFilters);
      }
      currentMultipleFilter.subItems.splice(itemIndex, 1);
    }
    mutableFilters[currentMultipleFilter.category] = currentMultipleFilter;
    return updateFilterParams(mutableFilters);
  };

  const removeMultipleFilter = (toRemove: RemoveMultipleFilterValue) => {
    const mutableFilters: FilterParams = JSON.parse(JSON.stringify(filterParams));
    const currentFilter = mutableFilters[toRemove.category];
    if (!currentFilter) {
      return null;
    }
    const currentMultipleFilter = currentFilter as MultipleFilter;
    const itemIndex = currentMultipleFilter.subItems.findIndex((subItem) => toRemove.value === subItem.value);
    if (itemIndex === -1) return null;
    if (currentMultipleFilter.subItems.length === 1) {
      delete mutableFilters[currentMultipleFilter.category];
      return updateFilterParams(mutableFilters);
    }
    currentMultipleFilter.subItems.splice(itemIndex, 1);
    mutableFilters[currentMultipleFilter.category] = currentMultipleFilter;
    return updateFilterParams(mutableFilters);
  };

  const removeFilter = (key: FilterCategory) => {
    const mutableFilters: FilterParams = JSON.parse(JSON.stringify(filterParams));
    delete mutableFilters[key];
    updateFilterParams(mutableFilters);
  };

  const multipleMenuItemParams = (
    filter: UpdateMultipleFilter,
    icon?: ReactElement,
  ): Partial<AppDropdownItemProps> & Pick<AppDropdownItemProps, 'label'> => ({
    label: filter.item.title,
    onClick: () => {
      dispatch(setPopupCategory(filter.category));
      updateMultipleFilter(filter);
    },
    startIcon: icon,
    endIcon: getCategoryItem(filter.category, filter.item.value) ? (
      <Box sx={{
        position: 'absolute',
        right: '15px',
        top: '30%',
      }}
      >
        <Check />
      </Box>
    ) : undefined,
    sx: {
      minWidth: '200px',
    },
    selected: !!getCategoryItem(filter.category, filter.item.value),
    selectable: ['owner', 'company'].includes(filter.category),
    value: filter.item.value,
  });

  const resetCategoryFilterParams = (category: FilterCategory): Partial<AppDropdownItemProps> => ({
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();
      removeFilter(category);
    },
    endIcon: category in filterParams ? undefined : (
      <Box sx={{
        position: 'absolute',
        right: '15px',
        top: '30%',
      }}
      >
        <Check />
      </Box>
    ),
  });

  const healthOpts = useMemo(() => [
    {
      label: t('Filters_All'),
      ...resetCategoryFilterParams('health'),
    },
    multipleMenuItemParams({
      category: 'health',
      title: 'Health',
      item: { title: 'Unopened', value: 'unopened' },
    }, <Unopened />),
    multipleMenuItemParams({
      category: 'health',
      title: 'Health',
      item: { title: 'Cold', value: 'cold', extra: { lt: moment().subtract(14, 'days').valueOf() } },
    }, <Cold />),
    multipleMenuItemParams({
      category: 'health',
      title: 'Health',
      item: { title: 'Hot', value: 'hot', extra: { gte: moment().subtract(14, 'days').valueOf() } },
    }, <Hot />),
  ], [filterParams]);

  const statusOpts = useMemo(() => [
    {
      label: t('Filters_All_statuses'),
      ...resetCategoryFilterParams('status'),
    },
    multipleMenuItemParams({
      category: 'status',
      title: 'Status',
      item: { title: 'In progress', value: ProcessStatus.IN_PROGRESS },
    }, <StatusInProgress />),
    multipleMenuItemParams({
      category: 'status',
      title: 'Status',
      item: { title: 'On hold', value: ProcessStatus.ON_HOLD },
    }, <StatusOnHold />),
    multipleMenuItemParams({
      category: 'status',
      title: 'Status',
      item: { title: 'Closed won', value: ProcessStatus.CLOSED_WON },
    }, <Check />),
    multipleMenuItemParams({
      category: 'status',
      title: 'Status',
      item: { title: 'Closed lost', value: ProcessStatus.CLOSED_LOST },
    }, <StatusClosedLost />),
  ], [filterParams]);

  const ownerOpts = useMemo(() => [
    {
      label: t('Filters_All'),
      ...resetCategoryFilterParams('owner'),
    },
  ].concat((dealsFilterInfos && dealsFilterInfos[gridToRender] && dealsFilterInfos[gridToRender].owners ? dealsFilterInfos[gridToRender].owners : []).map((owner: OwnerFilterItem) => multipleMenuItemParams({
    category: 'owner',
    title: 'Owner',
    item: {
      title: owner.name, value: owner.id, avatar: owner.avatar, colorId: owner.colorId,
    },
  }, <AppAvatar src={owner.avatar} name={owner.name} colorId={owner.colorId} />))), [filterParams, dealsFilterInfos[gridToRender]]);

  const companyOpts = useMemo(() => [
    {
      label: t('Filters_All'),
      ...resetCategoryFilterParams('company'),
    },
  ].concat((dealsFilterInfos && dealsFilterInfos[gridToRender] && dealsFilterInfos[gridToRender].companies ? dealsFilterInfos[gridToRender].companies : []).map((company: FilterInfoItem) => multipleMenuItemParams({
    category: 'company',
    title: 'Company',
    item: { title: company.name, value: company.id },
  }, <AppAvatar src={company.avatar} />))), [filterParams, dealsFilterInfos[gridToRender]]);

  const allOpts: AppDropdownItemProps[] = useMemo(() => {
    let mainOpts: AppDropdownItemProps[] = [
      {
        label: 'Health',
        startIcon: <Health />,
        childrenItems: healthOpts,
      },
      {
        label: 'Status',
        startIcon: <Status />,
        childrenItems: statusOpts,
        hidden: gridToRender === DealsType.BUYER_DEALS,
      },
      {
        label: 'Owner',
        startIcon: <Owner />,
        childrenItems: ownerOpts,
      },
      {
        label: 'Company',
        startIcon: <Company />,
        childrenItems: companyOpts,
      }];

    if (gridToRender === DealsType.BUYER_DEALS) {
      mainOpts = mainOpts.filter((opt) => opt.label === 'Owner');
    }

    const savedFiltersOpts: AppDropdownItemProps[] = [];

    if (savedFilters?.length) {
      const savedFiltersHeaderOpts = [
        { label: '', divider: true },
        {
          label: 'Saved',
          category: true,
          endIcon: <AppButton
            onClick={() => {
              dispatch(setManageFilterSetsDialogStatus(true));
              dispatch(setMobileFilterMenu(false));
              setAnchorEl(undefined);
            }}
            size="xs"
            sx={{
              padding: 0,
            }}
          >
            Settings
          </AppButton>,
        },
      ];

      const savedFiltersMenuItems = savedFilters?.map((filter) => ({
        label: filter.name,
        onClick: () => {
          updateFilterParams(filter.filterParams);
          enqueueSnackbar(`The filter set "${filter.name}" has been chosen`, {
            variant: 'success',
          });
          closeMenu();
        },
      }));

      savedFiltersOpts
        .push(...savedFiltersHeaderOpts, ...savedFiltersMenuItems);
    }

    return mainOpts.concat(savedFiltersOpts);
  }, [healthOpts, statusOpts, ownerOpts, companyOpts, savedFilters, gridToRender]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    updateSearch(e.target.value);
  };

  const getPopupItems = () => {
    switch (popupCategory) {
      case 'company':
        return companyOpts;
      case 'health':
        return healthOpts;
      case 'owner':
        return ownerOpts;
      case 'status':
        return statusOpts;
      default:
        return allOpts;
    }
  };

  const mobileIndex = useMemo(() => {
    switch (popupCategory) {
      case 'company':
        return 4;
      case 'health':
        return 1;
      case 'owner':
        return 3;
      case 'status':
        return 2;
      default:
        return 0;
    }
  }, [popupCategory]);

  const openFilter = () => {
    dispatch(setExpanded(true));
  };

  const closeFilter = () => {
    if (isMobile) return;
    dispatch(setExpanded(false));
  };

  return {
    dealsFilterInfos,
    removeFilter,
    allOpts,
    anchorEl,
    companyOpts,
    expanded,
    ownerOpts,
    statusOpts,
    healthOpts,
    handleSearchChange,
    getPopupItems,
    setAnchorEl,
    setPopupCategory,
    popupCategory,
    mobileIndex,
    getCategoryItem,
    removeMultipleFilter,
    openFilter,
    closeFilter,
    newFilterSetDialogStatus,
    manageFilterSetsDialogStatus,
    openMenu,
    closeMenu,
    debouncedGridFilters,
    debouncedFilterSearch,
    updateFilterParams,
    updateSearch,
    filterParams,
    search,
    gridToRender,
  };
};
