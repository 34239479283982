import { ofType } from "redux-observable";
import { getUserOrganization, redirectAfterOrgUpdate } from "../appState/appState";
import { EMPTY, switchMap, withLatestFrom, of } from 'rxjs';
import { confirmProcessAccount, login, loginSuccess, logout } from "../../../routes-old/auth/state/authState";
import { SharedLocalStorage } from "../../helpers/SharedLocalStorage";
import { OrganizationSubdomainHelper } from "../../helpers/OrganizationSubdomainHelper";
import { ECustomDomainEnableStatus } from '../../../../shared/CustomDomains';
import { DomainHelper } from "../../helpers/DomainHelper";


export const subdomainOrgLoadSuccessEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(getUserOrganization.fulfilled),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (action.payload?.organization?.customDomain && action.payload?.organization?.customDomainEnabledStatus == ECustomDomainEnableStatus.ACTIVE) {
        const domainRetval = OrganizationSubdomainHelper.getCustomDomainToRedirect(action.payload?.organization?.customDomain);

        if (domainRetval.shouldRedirect) {
          const domain = DomainHelper.getDomainFromString(action.payload.organization.customDomain);
          const access_token = SharedLocalStorage.getItem('token');
          const tokenType = SharedLocalStorage.getItem('tokenType');
          if (access_token && tokenType) {
            OrganizationSubdomainHelper.redirectToCustomDomain(action.payload.organization.customDomain as string, access_token, tokenType);
          }
        }
      } else {
        const subRetval = OrganizationSubdomainHelper.getSubdomainToRedirect(action.payload?.organization?.domain);

        if (subRetval?.shouldRedirect) {
          const access_token = SharedLocalStorage.getItem('token');
          const tokenType = SharedLocalStorage.getItem('tokenType');
          if (access_token && tokenType) {
            SharedLocalStorage.setItem('token', access_token);
            SharedLocalStorage.setItem('tokenType', tokenType);
          }
          OrganizationSubdomainHelper.redirectToSubdomain(subRetval.subdomain as string, access_token as string, tokenType as string);
        }
      }
      return EMPTY;
    }),
  );
};

export const subdomainLoginSuccessEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(login.fulfilled),
    switchMap((action: any) => {
      if (action.payload?.customDomain && action.payload?.customDomainEnabledStatus == ECustomDomainEnableStatus.ACTIVE) {
        const domainRetval = OrganizationSubdomainHelper.getCustomDomainToRedirect(action.payload?.customDomain);

        if (domainRetval.shouldRedirect) {
          SharedLocalStorage.setItem('token', action.payload.access_token);
          SharedLocalStorage.setItem('tokenType', action.payload.tokenType);
          OrganizationSubdomainHelper.redirectToCustomDomain(action.payload.customDomain as string, action.payload.access_token, action.payload.tokenType);
          return EMPTY;
        }
      } else {
        const subRetval = OrganizationSubdomainHelper.getSubdomainToRedirect(action.payload?.organizationDomain);

        if (subRetval?.shouldRedirect) {
          SharedLocalStorage.setItem('token', action.payload.access_token);
          SharedLocalStorage.setItem('tokenType', action.payload.tokenType);
          OrganizationSubdomainHelper.redirectToSubdomain(subRetval.subdomain as string, action.payload.access_token, action.payload.tokenType);
          return EMPTY;
        }
      }
      return of(loginSuccess(action.payload));
    }),
  );
};

export const subdomainconfirmProcessAccountSuccessEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(confirmProcessAccount.fulfilled),
    switchMap((action: any) => {
      SharedLocalStorage.setItem('token', action.payload.access_token);
      SharedLocalStorage.setItem('tokenType', action.payload.tokenType);

      return EMPTY;
    }),
  );
};

export const subdomainLogoutSuccessEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(logout.pending),
    switchMap((action) => {
      SharedLocalStorage.removeCookie('token');
      SharedLocalStorage.removeCookie('tokenType');

      return EMPTY;
    }),
  );
};

export const redirectOnOrganizationUpdateEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(redirectAfterOrgUpdate.fulfilled),
    switchMap((action: any) => {
      if (action.payload?.customDomain && action.payload?.customDomainEnabledStatus == ECustomDomainEnableStatus.ACTIVE) {
        const domainRetval = OrganizationSubdomainHelper.getCustomDomainToRedirect(action.payload?.customDomain);

        if (domainRetval.shouldRedirect) {
          const domain = DomainHelper.getDomainFromString(action.payload.customDomain);
          const access_token = SharedLocalStorage.getItem('token');
          const tokenType = SharedLocalStorage.getItem('tokenType');
          if (access_token && tokenType) {
            OrganizationSubdomainHelper.redirectToCustomDomain(action.payload.customDomain as string, access_token, tokenType);
          }
        }
      } else {
        const subRetval = OrganizationSubdomainHelper.getSubdomainToRedirect(action.payload?.domain);

        if (subRetval?.shouldRedirect) {
          const access_token = SharedLocalStorage.getItem('token');
          const tokenType = SharedLocalStorage.getItem('tokenType');
          if (access_token && tokenType) {
            SharedLocalStorage.setItem('token', access_token);
            SharedLocalStorage.setItem('tokenType', tokenType);
          }
          OrganizationSubdomainHelper.redirectToSubdomain(subRetval.subdomain as string, access_token as string, tokenType as string);
        }
      }
      return EMPTY;
    }),
  );
};
