import { StageContentType } from '../../../../shared/Stage';
import { useCurrentStage } from '../../../hooks/useCurrentStage';
import { EPricingItemMenuActions } from './PricingItemMenuTypes';
import Trash from '../../../icons/Trash.svg';
import { AppDropdown } from '../../../shared/AppDropdown/AppDropdown';
import { ChevronDown20 } from '../../../icons/ChevronDown';
import { ChevronUp20 } from '../../../icons/ChevronUp';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { PenIcon20 } from '../../../icons/PenIcon';
import { CopyIcon20 } from '../../../icons/CopyIcon';
import { EraserIcon20 } from '../../../icons/EraserIcon';

interface Props {
  anchorEl: any;
  open: boolean;
  handleClose: () => void;
  handleAction: (type: EPricingItemMenuActions) => void;
}

const PricingTableItemMenu = ({
  anchorEl,
  open,
  handleClose,
  handleAction,
}: Props): JSX.Element => {
  const currentStage = useCurrentStage();
  const isWideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;
  const isProposalStage = currentStage?.stageContentType === StageContentType.ESIGNATURE;

  return (
    <AppDropdown
      anchorEl={anchorEl}
      open={open}
      onClick={(e) => e.stopPropagation()}
      onClose={() => handleClose()}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      lastElementHasAnotherColor
      items={[
        {
          label: 'Edit',
          startIcon: <PenIcon20
            sx={{
              height: '20px',
              width: '20px',
            }}
          />,
          onClick: () => {
            handleAction(EPricingItemMenuActions.EDIT);
          },
          hidden: isWideScreenStage,
        },
        {
          label: 'Duplicate',
          startIcon: <CopyIcon20
            sx={{
              height: '20px',
              width: '20px',
            }}
          />,
          onClick: () => {
            handleAction(EPricingItemMenuActions.DUPLICATE);
          },
          hidden: isWideScreenStage,
        },
        {
          label: 'Clear contents',
          startIcon: <EraserIcon20
            sx={{
              height: '20px',
              width: '20px',
            }}
          />,
          onClick: () => {
            handleAction(EPricingItemMenuActions.CLEAR_CONTENT);
          },
          hidden: isProposalStage,
        },
        {
          label: 'Move up',
          startIcon: <ChevronUp20
            sx={{
              height: '20px',
              width: '20px',
            }}
          />,
          onClick: () => {
            handleAction(EPricingItemMenuActions.MOVE_UP);
          },
          hidden: isProposalStage,
        },
        {
          label: 'Move down',
          startIcon: <ChevronDown20
            sx={{
              height: '20px',
              width: '20px',
            }}
          />,
          onClick: () => {
            handleAction(EPricingItemMenuActions.MOVE_UP);
          },
          hidden: isProposalStage,
        },
        {
          label: '',
          divider: true,
        },
        {
          label: 'Delete',
          startIcon: <Trash />,
          onClick: () => {
            handleAction(EPricingItemMenuActions.DELETE);
          },
          hidden: isProposalStage,
        },

      ]}
    />
  );
};

export default PricingTableItemMenu;
