import {
  Avatar, Box, SvgIcon,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import ChevronLeft from '../../icons/ChevronLeft.svg';
import { CreateDealFormWrapper } from './TemplatePickerStyles';
import AvatarPlaceholder from '../../icons/AvatarPlaceholder.svg';
import { AppInput } from '../../shared/AppInput/AppInput';
import CompanyAndLogoSelect, { disabledLogoCompany, initialCompany } from '../CreateDealDialog/CompanyAndLogoSelect';
import { EProcessType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { ClearbitAutocompleteDTO } from '../../../shared/ClearbitDTO';
import { ICreateDealFormState } from '../../routes-old/deals/CreateDealDialog/CreateDealDialog';
import { AppButton } from '../../shared/AppButton/AppButton';
import { EStateStatus } from '../../core/commonTypes';
import { createProcess, setCreateDealDialogStatus } from '../../routes-old/deals/state/dealsSlice';
import { ESubscriptionType } from '../../../shared/Subscription';
import { upgradeToSellerSubscription } from '../../routes-old/Billing/state/billingSlice';
import { EAppRoutes } from '../../core/router';
import { isUserHaveAnyCrmIntegration, useCrmOpportunities } from '../../routes-old/deals/model/useCrmOpportunities';
import { Opportunity } from '../../../shared/crm/Opportunity';
import useDealCreationData from './lib/useDealCreationDataSlice';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import { DefaultOrgCurrency } from '../../../shared/OrganizationDTO';
import { selectOrganization } from '../../core/store/appState/appState';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';

const analytics: Analytics = Analytics.getInstance();

export type Props = {
  templateId: string;
  onGoBack: () => void;
};

export const CreateDealForm = ({ templateId, onGoBack }: Props) => {
  const dispatch = useAppDispatch();
  const { subscription } = useAppSelector((state) => state.billing);
  const isUserHaveIntegration = isUserHaveAnyCrmIntegration();
  const opportunities: Opportunity[] = useCrmOpportunities();
  const organization = useAppSelector(selectOrganization);
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);
  const { status } = useAppSelector((state) => state.deals);
  const [selectedCompany, setSelectedCompany] = useState<ClearbitAutocompleteDTO>(disabledLogoCompany);
  const navigate = useNavigate();
  const { onDealToCreateDataUpdate, onResetDealCreationData } = useDealCreationData();
  const [nodeRef, setNodeRef] = useState<HTMLDivElement | null>(null);

  const isLoading: boolean = status === EStateStatus.LOADING;
  const [dealValue, setDealValue] = useState<number | null>(null);

  const schema = yup.object({
    dealTitle: yup.string().required('Opportunity title is required'),
    client: yup.string().required('Client is required'),
    clientName: yup.string(),
    value: yup
      .number()
      .typeError('Value should be a number')
      .nullable()
      .min(0)
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    templateId: yup.string().required('Template is required'),
  }).required();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    register,
    getValues,
  } = useForm<ICreateDealFormState>({
    defaultValues: {
      dealTitle: '',
      client: '',
      clientName: '',
      value: null,
      templateId: templateId || '',
      opportunityId: '',
    },
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (data: ICreateDealFormState) => {
    try {
      const newDeal: ProcessDTO = await dispatch(createProcess({
        title: data.dealTitle,
        ...data,
        value: data.value ?? 0,
        logo: selectedCompany?.logo,
        type: EProcessType.DEAL,
        opportunityId: data.opportunityId,
      })).unwrap();
      if (subscription.type === ESubscriptionType.BUYER) {
        await dispatch(upgradeToSellerSubscription())
          .unwrap();
      }
      setSelectedCompany(initialCompany);
      reset();
      onResetDealCreationData();
      dispatch(setCreateDealDialogStatus(false));
      navigate(`${EAppRoutes.deal}/${newDeal.id}`);
    } catch (e) {
      console.log(e);
    }
    analytics.track(EMixPanelEvents.DEAL_CREATED);
  };

  register('value', {
    onChange: (e) => {
      setDealValue(parseInt(e.target.value));
    },
  });

  const [isAutocompleteVisible, setAutocompleteVisible] = useState(false);
  register('client', {
    onChange: (e) => {
      setSelectedCompany(initialCompany);
      if (e.target.value && !isAutocompleteVisible) {
        setAutocompleteVisible(true);
      }
      if (!e.target.value && isAutocompleteVisible) {
        setAutocompleteVisible(false);
      }
      onDealToCreateDataUpdate({ clientName: e.target.value });
    },
  });

  useEffect(() => {
    setValue('templateId', templateId || '');
  }, [templateId]);

  const handleCompanySelect = (data: ClearbitAutocompleteDTO) => {
    if (data.name) {
      setValue('client', data.name);
      setValue('dealTitle', `${data.name} deal`);
    }
    setSelectedCompany(data);
    onDealToCreateDataUpdate({
      clientName: data.name,
      clientLogo: data.logo,
    });
    setAutocompleteVisible(false);
  };

  return (
    <CreateDealFormWrapper
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '20px',
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
          >

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >

              <Box>
                <AppButton startIcon={<ChevronLeft />} onClick={onGoBack}>All templates</AppButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Controller
              name="client"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}
                >

                  <Box sx={{
                    width: '100%',
                  }}
                  >
                    <AppInput
                      required
                      label="Company"
                      id="client-name-field"
                      autoComplete="off"
                      placeholder="Type here"
                      onFocus={() => setAutocompleteVisible(true)}
                      onBlur={(e) => {
                        if (e.relatedTarget?.id !== 'company-information-autocomplete') {
                          setAutocompleteVisible(false);
                        }
                        if (!getValues().dealTitle && !!e.target.value.trim()) {
                          setValue('dealTitle', `${e.target.value} deal`);
                        }
                      }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                      size="l"
                    />
                    <CompanyAndLogoSelect
                      isAutocompleteVisible={isAutocompleteVisible}
                      value={value}
                      setSelectedCompany={handleCompanySelect}
                      positionOffset="0px"
                    />
                  </Box>
                  <Avatar
                    src={selectedCompany.logo}
                    alt=""
                    sx={{
                      height: '40px',
                      width: '40px',
                      backgroundColor: 'white',
                      margin: '0px 0px 0px 12px',
                    }}
                  >
                    <Box sx={{
                      border: '1px solid #EEF0FA',
                      borderRadius: '40px',
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <SvgIcon
                        component={AvatarPlaceholder}
                        sx={{
                          color: 'transparent',
                        }}
                      />
                    </Box>
                  </Avatar>
                </Box>
              )}
            />
          </Box>
          <Controller
            name="dealTitle"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                required
                label="Room name"
                id="deal-title-field"
                placeholder="Type here"
                autoComplete="off"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                size="l"
                onFocus={(e) => {
                  if (!!e.target.value && e.target.value === `${getValues().client} deal`) {
                    e.target.select();
                  }
                }}
              />
            )}
          />
          <Controller
            name="clientName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                label="Name"
                id="deal-title-field"
                placeholder="Type here"
                autoComplete="off"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                size="l"
                onFocus={(e) => {
                  if (!!e.target.value && e.target.value === `${getValues().client} deal`) {
                    e.target.select();
                  }
                }}
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <AppInput
                label={`Value, ${getSymbolFromCurrency(organization.currency ?? DefaultOrgCurrency)}`}
                type="number"
                id="deal-value-field"
                value={dealValue}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                size="l"
                inputProps={{
                  value: dealValue?.toString() ?? '',
                  // inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            )}
          />
          {
            isUserHaveIntegration && (
              <Controller
                name="opportunityId"
                control={control}
                defaultValue=""
                render={() => (
                  <>
                    <AppInput
                      id="deal-crm-opportunity-field"
                      label="Crm Opportunity"
                      size="l"
                      contentEditable={false}
                      value={opportunity?.name}
                      onClick={(e) => { setNodeRef(e.currentTarget); }}
                      InputLabelProps={{
                        shrink: !!opportunity?.name,
                      }}
                      autoComplete="off"
                      sx={{
                        cursor: 'pointer',
                        '& input': {
                          cursor: 'pointer',
                          caretColor: 'transparent',
                        },
                      }}
                    />
                    <AppDropdown
                      searchType="default"
                      size="xxl"
                      items={opportunities.map((op) => ({
                        id: op.id,
                        label: op.name,
                        onClick: () => {
                          setValue('opportunityId', op.id);
                          setOpportunity(op);
                          setNodeRef(null);
                        },
                      }))}
                      open={!!nodeRef}
                      anchorEl={nodeRef}
                      onClose={() => setNodeRef(null)}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      sx={{
                        zIndex: 1301,
                        maxHeight: '400px',
                        '& li': {
                          '& p': {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '358px',
                          },
                        },
                      }}
                    />
                  </>
                )}
              />
            )
          }
          <AppButton
            loading={isLoading}
            variant="primary"
            size="xl"
            type="submit"
            fullWidth
          >
            Create
          </AppButton>
        </Box>
      </Box>

    </CreateDealFormWrapper>
  );
};
