import { AppIconButton } from '../../../../../shared/AppButton/AppIconButton';
import { ReplaceIcon20 } from '../../../../../icons/ReplaceIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/stateHooks';
import {
  ENewPageTabs,
  ESidebarTabs,
  setNewPageTab,
  setSidebarTab,
} from '../../../../Layout/lib/fieldsLayoutSlice';
import { setCurrentStageNeedsToBeUpdated } from '../../../../BlockContentSection/lib/sectionsSlice';
import { selectLayouEditingStatus } from '../../../../../routes-old/process/state/processSlice';
import { useCurrentStage } from '../../../../../hooks/useCurrentStage';
import { StageContentType } from '../../../../../../shared/Stage';

const ExternalMediaToolbarItems = (): JSX.Element | null => {
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);
  const currentStage = useCurrentStage();
  const isWideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;
  const dispatch = useAppDispatch();

  const handleWizardButtonClick = () => {
    dispatch(setSidebarTab(ESidebarTabs.NEWPAGE));
    dispatch(setNewPageTab(ENewPageTabs.BLANK));
    dispatch(setCurrentStageNeedsToBeUpdated(true));
  };

  if (!isLayoutEditMode || !isWideScreenStage) return null;

  return (
    <AppIconButton
      onClick={handleWizardButtonClick}
      icon={<ReplaceIcon20 />}
    />
  );
};

export default ExternalMediaToolbarItems;
