import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';

export enum EShareDealStage {
  USERS = 'USERS',
  MESSAGE = 'MESSAGE',
}

const initialState = {
  isOpen: false,
  stage: EShareDealStage.USERS,
  isContinueVisible: false,
};

const shareDealPopupSliceReducerName: string = 'shareDealPopup';

export const shareDealPopupSlice = createSlice({
  name: shareDealPopupSliceReducerName,
  initialState,
  reducers: {
    setSharePopupOpen: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOpen: payload,
    }),
    setSharePopupStage: (state, { payload }: PayloadAction<EShareDealStage>) => ({
      ...state,
      stage: payload,
    }),
    setContinueVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isContinueVisible: payload,
    }),
  },
});

export const {
  setSharePopupOpen,
  setSharePopupStage,
  setContinueVisible,
} = shareDealPopupSlice.actions;

export const selectIsSharePopUpOpen = (state: RootState) => state.shareDealPopup.isOpen;
export const selectSharePopupStage = (state: RootState) => state.shareDealPopup.stage;
export const selectShareContinue = (state: RootState) => state.shareDealPopup.isContinueVisible;
