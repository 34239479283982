import {
  Box, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { CloseIcon } from '../../icons/Close16px';

type Props = {
  onClose: () => void;
};

const TemplatesPickerHeader = ({ onClose }: Props): JSX.Element => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flex: 0,
      flexDirection: 'row',
      paddingX: isMobile ? '16px' : '24px',
      paddingY: '16px',
      height: isMobile ? '80px' : '64px',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'white',
        borderRadius: '16px 16px 0px 0px',
      }}
    >
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 600,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        Create room
      </Typography>
    </Box>
    <Box>
      <AppIconButton icon={<CloseIcon />} onClick={onClose} />
    </Box>
  </Box>
);

export default TemplatesPickerHeader;
