import { isMobile } from 'react-device-detect';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { TaskTitleWithEditor } from '../../../../../routes-old/process/TaskList/TaskTitleWithEditor';
import { useTaskPermissions } from '../../../../../hooks/useTaskPermissions';
import useFrontEventsApi from '../../../../../hooks/useFrontEventsApi';
import { ETaskStatus } from '../../../../../../shared/TaskDTO';

const AgendaNodeName = (): JSX.Element | null => {
  const { data, onUpdate } = useBlockContext();
  const { createTitleUpdateEvent } = useFrontEventsApi();

  const {
    isActionTitleEditable,
  } = useTaskPermissions();

  if (!data) {
    return null;
  }

  const handleTitleChange = (title: string) => {
    onUpdate(
      {
        name: title,
      },
    );
    createTitleUpdateEvent({ from: data.name, to: title, taskId: data.blockId });
  };

  return (
    <TaskTitleWithEditor
      title={data.name}
      onSave={handleTitleChange}
      isEditable={isActionTitleEditable && !isMobile}
      maxRows={13}
      taskStatus={data.status}
      variant={data.status === ETaskStatus.COMPLETE ? 'lite-grey' : 'lite'}
    />
  );
};

export default AgendaNodeName;
