import {
  Dialog, DialogProps, Paper,
} from '@mui/material';
import { useAppSelector } from '../../../hooks/stateHooks';

const DealSignupDialogWrapper = (props: DialogProps): JSX.Element => {
  const { process } = useAppSelector((state) => state.process);

  return (
    <Dialog
      id="share-signup-dialog-backdrop"
      {...props}
      PaperProps={{
        id: 'share-signup-dialog',
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          maxWidth: '420px',
          minWidth: '420px',
          alignItems: 'center',
          marginTop: '40px',
        },
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
    >
      {/* { */}
      {/*  process && ( */}
      {/*    <Box */}
      {/*      sx={{ */}
      {/*        display: 'flex', */}
      {/*        flex: 1, */}
      {/*        maxWidth: '100%', */}
      {/*        alignItems: 'center', */}
      {/*        justifyContent: 'space-between', */}
      {/*        height: '90px', */}
      {/*        minHeight: '90px', */}
      {/*        maxHeight: '90px', */}
      {/*        marginBottom: '16px', */}
      {/*      }} */}
      {/*    > */}
      {/*      <CompanyLogo */}
      {/*        src={process?.buyerLogoSrc} */}
      {/*        process={process} */}
      {/*        organizationId={process?.organizationId} */}
      {/*        userId={' '} */}
      {/*        logoType={ELogoType.BuyerLogo} */}
      {/*        isEditable={false} */}
      {/*      /> */}
      {/*      <CompanyLogo */}
      {/*        src={process?.sellerLogoSrc} */}
      {/*        process={process} */}
      {/*        organizationId={process?.organizationId} */}
      {/*        userId={' '} */}
      {/*        logoType={ELogoType.SellerLogo} */}
      {/*        isEditable={false} */}
      {/*      /> */}
      {/*    </Box> */}
      {/*  ) */}
      {/* } */}
      <Paper
        sx={{
          padding: '32px',
          width: '400px',
          boxShadow: '0px 4px 20px -2px rgba(0, 57, 96, 0.05), 0px 0px 3px rgba(0, 57, 96, 0.1)',
          background: 'white',
          gap: '24px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        {props.children}
      </Paper>
    </Dialog>
  );
};

export default DealSignupDialogWrapper;
