import {
  Box, styled, SwipeableDrawer,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { resetSelectedTemplateData, setSelectedTemplateData } from '../../../pages/templates/lib/templatesSlice';
import { EProcessType } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { TemplateListTab } from './TemplateListTab';
import { TemplatePreviewTab } from './TemplatePreviewTab';
import { CreateDealFormTab } from './CreateDealFormTab';
import { clearNotificationsState } from '../../../routes-old/process/state/dealNotificationsSlice';
import { clearTasksState } from '../../../routes-old/process/state/tasksSlice';
import { clearProcessState } from '../../../routes-old/process/state/processSlice';

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void;
};

const Puller = styled(Box)(({ theme }) => ({
  width: 40,
  height: 4,
  backgroundColor: theme.palette.grey[100],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 20px)',
}));

export const MobileTabs = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const [chosenTemplate, chooseTemplate] = useState<string>();
  const [activeTab, setActiveTab] = useState(0);

  const cleanProcessState = () => {
    dispatch(clearProcessState());
    dispatch(clearTasksState());
    dispatch(clearNotificationsState());
  };

  useEffect(() => {
    if (open) {
      setActiveTab(0);
    }
    return () => cleanProcessState();
  }, [open]);

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(resetSelectedTemplateData());
      cleanProcessState();
    }
  }, [activeTab]);

  const onTemplateSelect = (id: string, title: string) => {
    dispatch(setSelectedTemplateData({ id, title, type: EProcessType.TEMPLATE }));
    setActiveTab(1);
  };

  const onTemplateApprove = (id: string) => {
    chooseTemplate(id);
    setActiveTab(2);
  };

  const handleClose = () => {
    chooseTemplate(undefined);
    setOpen(false);
  };

  const onGoBack = () => {
    if (activeTab === 0) {
      handleClose();
      return;
    }
    setActiveTab(activeTab - 1);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="temporary"
      onOpen={() => {
        setOpen(true);
      }}
      swipeAreaWidth={56}
      disableSwipeToOpen
      PaperProps={{
        sx: {
          borderRadius: '0px',
          '& .mobile-table-row': {
            borderWidth: '1px',
            borderRadius: '12px',
          },
          '& .mobile-table-row-header': {
            minHeight: 'auto',
            padding: '0px',
          },
        },
      }}
    >
      <Puller />
      <SwipeableViews
        index={activeTab}
        animateHeight
        onChangeIndex={setActiveTab}
        disabled
        containerStyle={{
          height: '100%',
          paddingBottom: '54px',
          maxHeight: 'calc( 100vh - 56px )',
        }}
        style={{
          overflowY: 'hidden',
          height: '100%',
        }}
      >
        <TemplateListTab onTemplateSelect={onTemplateSelect} onGoBack={onGoBack} />
        <TemplatePreviewTab onTemplateSelect={onTemplateApprove} onGoBack={onGoBack} onClose={handleClose} />
        <CreateDealFormTab templateId={chosenTemplate!} onGoBack={onGoBack} onClose={handleClose} />
      </SwipeableViews>

    </SwipeableDrawer>
  );
};
