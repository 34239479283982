import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import Add from '../../../icons/DealsFiltersInput/Add.svg';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { ESidebarTabs, setSidebarTab, setStagesFiltersDrawerOpen } from '../../../features/Layout/lib/fieldsLayoutSlice';
import TimelineContent from '../../../widgets/ProcessTimeline/TimelineContent';

export const InternalDealView = () => {
  const dispatch = useAppDispatch();

  const handleFiltersClick = () => {
    dispatch(setSidebarTab(ESidebarTabs.FILTERS));
    if (isMobile) {
      dispatch(setStagesFiltersDrawerOpen(true));
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'sticky',
          top: '0px',
          zIndex: 4,
          background: 'white',
          boxShadow: '0px 0px 10px -3px rgba(0, 33, 56, 0.06), 0px 0px 2px 0px rgba(5, 37, 58, 0.11)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            paddingY: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '900px',
          }}
        >
          <Typography
            sx={{
              width: '100%',
              fontWeight: 500,
              fontSize: '24px',
              color: '#1A1929',
              paddingLeft: '16px',
            }}
          >
            Engagement
          </Typography>
          <AppButton
            onClick={handleFiltersClick}
            fixedColors
            size="l"
            startIcon={<Add />}
          >
            Filters
          </AppButton>
        </Box>
      </Box>
      <TimelineContent />
    </>
  );
};
