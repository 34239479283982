import { SvgIcon, SvgIconProps } from '@mui/material';

export const CompanyLogoPlaceholder40 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      width: '40px',
      height: '40px',
    }}
  >
    <path
      d="M2.03113 23.8502C8.80567 21.7594
      16.0648 21.914 22.3743 23.8502C28.865
      25.842 34.3541 29.5994 37.5279 26.0339C37.7495
      26.4401 37.9271 26.8374 38.0629 27.2261C38.0668
      27.2986 38.0708 27.3711 38.0748 27.4436C38.1343
      28.5237 38.1939 29.6066 37.965 30.6503C37.7239
      31.7499 37.1057 32.7727 36.1394 33.5074C31.6417
      36.9265 26.177 38.8341 20.5285 38.9566C14.8801
      39.0791 9.3378 37.4103 4.69609 34.1893C4.05177
      33.7422 3.54744 33.1686 3.19334 32.5252C2.71216
      31.6508 2.38547 30.6748 2.24096 29.6873C1.95659
      27.7441 1.8886 25.7869 2.03113 23.8502Z"
      fill="#CBD5E1"
    />
    <path
      d="M26.1014 3.59164C25.7334 11.5258 20.0584
      17.5794 15.1398 22.8262C10.5494 27.723 6.61774
      31.917 8.27371 36.2809C7.00287 35.6704 5.77648
      34.9575 4.60722 34.1461C3.9629 33.699 3.45857
      33.1255 3.10447 32.482C2.62329 31.6077 2.2966
      30.6317 2.15209 29.6442C1.33402 24.0539 2.3067
      18.3482 4.93072 13.3447C7.55474 8.34126 11.6954
      4.29694 16.7592 1.79141C16.8308 1.75598 16.9029
      1.72246 16.9753 1.69082C18.12 1.19092 19.4138 0.96752
      20.6597 1.05567C21.2348 1.09636 21.8124 1.23967 22.367
      1.49502C23.672 2.09584 24.9202 2.7978 26.1014 3.59164Z"
      fill="#64748B"
    />
    <path
      d="M32.8992 35.6206C26.2972 31.3183 23.6531 23.1861
      21.5802 16.3417C19.6123 9.84369 19.2463 3.91324 14.5166
      3.26829C14.5502 3.20358 14.5843 3.13991 14.6188 3.07726C15.3394
      2.63066 16.0831 2.21717 16.8479 1.83877C16.9195 1.80334 16.9916
      1.76982 17.064 1.73819C18.2086 1.23828 19.5025 1.01488 20.7484
      1.10304C21.3235 1.14372 21.901 1.28703 22.4557 1.54238C27.5877
      3.90511 31.84 7.83188 34.6031 12.7599C36.8681 16.7994 38.0371
      21.3417 38.0209 25.9387C38.0191 26.4383 38.0468 26.9428 38.0746
      27.4478C38.1341 28.528 38.1937 29.6108 37.9648 30.6545C37.7237
      31.7541 37.1055 32.7769 36.1392 33.5116C35.368 34.1579 33.677
      35.2027 32.8992 35.6206Z"
      fill="#94A3B8"
    />
    <circle cx="20.0005" cy="20.7967" r="7.50247" fill="#F5F9FF" />
  </SvgIcon>
);
