import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomDomainApi } from "../../../../api/CustomDomainApi";

export const customDomainReducerName: string = 'custom-domains';

export enum ECustomDomainActions {
  GET_CUSTOM_DOMAIN_PUBLISH_STATUS = 'GET_CUSTOM_DOMAIN_PUBLISH_STATUS',
  ACTIVATE_CUSTOM_DOMAIN = 'ACTIVATE_CUSTOM_DOMAIN',
}

const customDomainApi = new CustomDomainApi();

export const getCustomDomainStatus = createAsyncThunk(
  `${customDomainReducerName}/${ECustomDomainActions.GET_CUSTOM_DOMAIN_PUBLISH_STATUS}`,
  async (payload: Record<string, string>, thunkAPI) => {
    return customDomainApi.getCustomDomainStatus(payload.customDomain, payload.organizationId);
  },
);

export const activateCustomDomain = createAsyncThunk(
  `${customDomainReducerName}/${ECustomDomainActions.ACTIVATE_CUSTOM_DOMAIN}`,
  async (payload: Record<string, unknown>, thunkAPI) => {
    return customDomainApi.activateCustomDomain(payload);
  },
);
