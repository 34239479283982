import { SvgIcon, SvgIconProps } from '@mui/material';

export const MoreVertical20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M10.7018 9.16659C10.2416 9.16658 9.86849
      9.53968 9.86849 9.99992C9.86849 10.4602 10.2416
      10.8333 10.7018 10.8333C11.1621 10.8333 11.5352
      10.4602 11.5352 9.99992C11.5352 9.53968 11.1621
      9.16659 10.7018 9.16659Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7018 15.0001C10.2416 15.0001 9.86849
      15.3732 9.86849 15.8334C9.86849 16.2937 10.2416
      16.6667 10.7018 16.6667C11.1621 16.6667 11.5352
      16.2937 11.5352 15.8334C11.5352 15.3732 11.1621
      15.0001 10.7018 15.0001Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7018 3.33333C10.2416 3.33333 9.86849
      3.70643 9.86849 4.16667C9.86849 4.6269 10.2416
      5 10.7018 5C11.1621 5 11.5352 4.6269 11.5352
      4.16667C11.5352 3.70643 11.1621 3.33333 10.7018
      3.33333Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

  </SvgIcon>
);
