import {
  Grid, Box, Typography,
} from '@mui/material';
import MobileTableRowHeader from './MobileTableRowHeader';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectTemaplatesLoadingStatus } from '../../pages/templates/lib/templatesSlice';
import { EStateStatus } from '../../core/commonTypes';
import { AppSkeletonTemplateStrings } from '../AppSkeleton/AppSkeletonStrings';

type Props = {
  title: string | JSX.Element,
  menu: JSX.Element | null,
  data: { key: string, value: JSX.Element }[]
  onClick: () => void;
};

const MobileTableRow = ({
  title, data, menu, onClick,
}: Props) => {
  const templatesLoadingStatus = useAppSelector(selectTemaplatesLoadingStatus);
  const renderData = () => data.map((row) => (
    <Grid
      key={row.key}
      container
      className="mobile-table-row-property"
      sx={{
        minHeight: '32px',
        padding: '4px 0px',
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography color="#7084B4" fontSize="14px">
          {row.key}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography color="#1A1929">
          {row.value}
        </Typography>
      </Grid>
    </Grid>
  ));

  return (
    <Box
      onClick={() => onClick()}
      className="mobile-table-row"
      sx={{
        backgroundColor: '#FFFFFF',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#EEF0FA',
        width: '100%',
        padding: '16px',
      }}
    >
      {templatesLoadingStatus === EStateStatus.LOADING ? (
        <AppSkeletonTemplateStrings />
      ) : (
        <>
          <MobileTableRowHeader title={title} menu={menu} />
          {renderData()}
        </>
      )}
    </Box>
  );
};

export default MobileTableRow;
