import { CardMedia } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import MediaNodeUpload from './MediaNodeUpload';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { EFieldsubType, EMediaFieldStorageType } from '../../../../../../shared/Field/Field';
import { IFramelyComponent } from '../../../../MediaField/IFramelyComponent';
import MediaFieldImgComponent from '../../../../MediaField/MediaFieldImgComponent';
import MediaFieldVideoComponent from '../../../../MediaField/MediaFieldVideoComponent';
import MediaFieldFile from '../../../../MediaField/MediaFieldFile';
import MediaFieldIframe from '../../../../MediaField/MediaFieldIframe';
import { isGif, isPDF } from '../../../../MediaField/Helpers/MediaFieldHelpers';
import { useFileItemSelect, useLibraryFile } from '../../../../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { MediaNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import MediaNodeContentWrapper from './MediaNodeContentWrapper';
import MediaFieldPdfFile from '../../../../MediaField/MediaFieldFileComponents/MediaFieldPdfFile';
import MediaFieldIFrameFile from '../../../../MediaField/MediaFieldFileComponents/MediaFieldIFrameFile';
import { ContentType } from '../../../../../../shared/Stage';

const MediaNodeComponent = (): JSX.Element | null => {
  const { data, onUpdate } = useBlockContext<MediaNodeAttrs>();
  const stateFile = useFileItemSelect(data.doc);
  const [file, setFile] = useState(stateFile);
  const [link, setLink] = useState(data.src);
  const modifiedWidth = isMobile ? undefined : data?.width;
  const modifiedHeight = isMobile ? undefined : data?.height;
  const [subType, setSubType] = useState(data.subType);

  const getLinkSubType = (linkType: ContentType | undefined) => {
    if (linkType !== ContentType.URL) {
      return EFieldsubType.MIXED;
    }
  };

  const setFileFromLib = async () => {
    const fileItem = await useLibraryFile(data.doc);
    setFile(fileItem);
    setSubType(fileItem.subType || data.subType);
  };
  const setLinkFromLib = async () => {
    const linkItem = await useLibraryFile(data.src || '');
    setLink(linkItem.url);
    setSubType(getLinkSubType(linkItem.linkType) || data.subType);
  };

  useEffect(() => {
    if (data.doc?.startsWith('library_')) {
      setFileFromLib();
    } else {
      setFile(stateFile);
      setSubType(data.subType);
    }

    if (data.src?.startsWith('library_')) {
      setLinkFromLib();
    } else {
      setLink(data.src);
      setSubType(data.subType);
    }
  }, [data, stateFile]);

  if (!data.src && !data.doc) {
    return (
      <MediaNodeUpload onUpdate={onUpdate} />
    );
  }

  if (data.src && isGif(data.src)) {
    return (
      <MediaNodeContentWrapper>
        <CardMedia
          component="video"
          autoPlay
          loop
          muted
          // height={mediaImgHeight}
          src={data.src}
          poster={data.src}
          sx={{
            // maxHeight: getMaxImageHeightFromSiblingsCount(siblingsCount),
            borderRadius: '15px',
            objectFit: 'contain',
          }}
        />
      </MediaNodeContentWrapper>
    );
  }

  if (data.src && data.storageType === EMediaFieldStorageType.EXTERNAL) {
    switch (subType) {
      case EFieldsubType.MIXED_URL:
        return (
          <MediaNodeContentWrapper>
            <IFramelyComponent
              onUpdate={onUpdate}
              url={link || data.src}
              width={modifiedWidth}
              height={modifiedHeight}
              type={subType}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.MIXED:
        return (
          <MediaNodeContentWrapper>
            <IFramelyComponent
              onUpdate={onUpdate}
              url={link || data.src}
              width={modifiedWidth}
              height={modifiedHeight}
              type={subType}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.VIDEO:
        return (
          <MediaNodeContentWrapper>
            <MediaFieldVideoComponent
              onUpdate={onUpdate}
              url={link || data.src}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.IFRAME:
        return (
          <MediaNodeContentWrapper>
            <MediaFieldIframe
              url={link || data.src}
              width={modifiedWidth}
              height={modifiedHeight}
              onUpdate={onUpdate}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.FILE:
        return (
          <MediaNodeContentWrapper>
            <MediaFieldFile
              url={link || data.src}
              width={modifiedWidth}
              height={modifiedHeight}
              onUpdate={onUpdate}
              storageType={data.storageType}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.IMG:
        return (
          <MediaNodeContentWrapper>
            <MediaFieldImgComponent
              onUpdate={onUpdate}
              subType={subType}
              url={link || ''}
              width={modifiedWidth}
              height={modifiedHeight}
              adjustBy={data.adjustBy}
            />
          </MediaNodeContentWrapper>
        );
      default:
        return (
          <MediaNodeUpload onUpdate={onUpdate} />
        );
    }
  }

  if (data.doc && data.storageType === EMediaFieldStorageType.INTERNAL_S3_PUBLIC && file?.url) {
    switch (subType) {
      case EFieldsubType.VIDEO:
        return (
          <MediaNodeContentWrapper>
            <MediaFieldVideoComponent
              onUpdate={onUpdate}
              url={file.url}
              name={file.name}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.IMG:
        return (
          <MediaNodeContentWrapper>
            <MediaFieldImgComponent
              onUpdate={onUpdate}
              subType={data.subType}
              url={file?.url || ''}
              width={modifiedWidth}
              height={modifiedHeight}
              adjustBy={data.adjustBy}
            />
          </MediaNodeContentWrapper>
        );
      case EFieldsubType.FILE:
        if (file?.url) {
          if (isPDF(file.name) || isPDF(file.url)) {
            return (
              <MediaNodeContentWrapper isPdf>
                <MediaFieldPdfFile
                  url={file.url}
                  name={file.name}
                  onUpdate={onUpdate}
                  width={modifiedWidth}
                  height={modifiedHeight}
                  storageType={data.storageType}
                />
              </MediaNodeContentWrapper>
            );
          }
          return (
            <MediaNodeContentWrapper>
              <MediaFieldIFrameFile
                url={file.url}
                name={file.name}
                onUpdate={onUpdate}
                width={modifiedWidth}
                height={modifiedHeight}
                storageType={data.storageType}
              />
            </MediaNodeContentWrapper>
          );
        }
        break;
      default:
        return (
          <MediaNodeUpload onUpdate={onUpdate} />
        );
    }
  }

  return null;
};

export default MediaNodeComponent;
