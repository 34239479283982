import {
  Autocomplete, TextField, Typography,
} from '@mui/material';
import {
  Filter,
  FilterCategory, FilterParams,
  MultipleFilter,
} from '../../../../../shared/process/ProcessFilter';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import Maximiz from '../../../../icons/DealsFiltersInput/Maximiz.svg';
import { DeepChip } from './DeepChip';

type Props = {
  filterParams: FilterParams,
  preview?: boolean,
  hidden?: boolean,
  onDelete: (category: FilterCategory) => void,
  setExpanded: (expanded: boolean) => void,
  expanded: boolean,
  onChipClick: (e: any, category: FilterCategory) => void
};

export const FilterAutocomplete = ({
  filterParams, preview, hidden, onDelete, setExpanded, expanded, onChipClick,
}: Props) => (
  <>
    <Autocomplete
      open
      disablePortal
      hidden={hidden}
      sx={{
        '& .MuiInputBase-root': {
          display: 'flex',
          flexDirection: preview ? 'row' : 'column',
          alignItems: preview ? 'center' : 'flex-start',
          alignContent: 'center',
          gap: '8px',
          minHeight: '32px',
          justifyContent: 'flex-end',
          '& input': {
            display: 'none',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={({
            sx: {
              border: 'none',
              padding: '0px !important',
              '& input': {
                padding: '0px !important',
                height: '100%',
              },
              '& fieldset': {
                border: 'none',
              },
            },
            endAdornment: <Maximiz />,
            ...params.InputProps,
          })}
          fullWidth
          variant="outlined"
        />
      )}
      options={Object.values<MultipleFilter>(filterParams as any)}
      renderOption={() => null}
      PopperComponent={() => null}
      getOptionLabel={(filter) => filter.category}
      popupIcon={null}
      multiple
      readOnly
      value={Object.values<MultipleFilter>(filterParams as any)}
      renderTags={(val: Filter[]) => val.map((filter) => (
        <DeepChip
          key={filter.value}
          filter={filter as MultipleFilter}
          preview={preview}
          onDelete={onDelete}
          onClick={(e) => onChipClick(e, filter.category)}
        />
      ))}
      limitTags={preview ? 2 : Infinity}
      getLimitTagsText={(more) => (
        <Typography sx={{
          fontSize: '14px',
          color: (theme) => theme.palette.grey[400],
        }}
        >
          {more}
          {' '}
          more
        </Typography>
      )}
    />
    {
      !expanded
      && preview
      && !!Object.values(filterParams).length
      && (
        <AppIconButton
          onClick={() => setExpanded(!expanded)}
          icon={(
            <Maximiz />
          )}
        />
      )
    }
  </>
);
