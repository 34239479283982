import { Close } from '@mui/icons-material';
import {
  Box, Dialog, DialogContent, Typography,
} from '@mui/material';
import {
  ChangeEvent,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { clearEmbedSectionData, selectEmbedSectionData } from '../lib/sectionsSlice';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { compareLinkAndSource, formatUrl } from '../../MediaField/Helpers/MediaFieldHelpers';
import { EFieldsubType, EMediaFieldStorageType } from '../../../../shared/Field/Field';
import useEditorEvent from '../../Tiptap/Hooks/useTiptapEditorEvents';
import { TiptapCustomEvents } from '../../Tiptap/TiptapEventListnerPlugin';
import { ExtensionNames, generateDocWithMediaNode } from '../../Tiptap/Utils/TiptapHelpers';
import { useStages } from '../../../hooks/useStages';
import { StageContentType, WideScreenSectionType } from '../../../../shared/Stage';

const EmbedSectionDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedData = useAppSelector(selectEmbedSectionData);
  const { dispatchEditorEvent } = useEditorEvent();
  const [newUrl, setNewUrl] = useState<string>('');
  const [linkError, setLinkError] = useState<string>('');
  const { onCreateNewStage } = useStages();

  const handleLinkFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (linkError) {
      setLinkError('');
    }
    setNewUrl(e.target.value);
  };

  const cleanNewLinkField = (): void => {
    setLinkError('');
    setNewUrl('');
  };

  const clearDialog = () => {
    dispatch(clearEmbedSectionData());
    cleanNewLinkField();
  };

  const onCreate = () => {
    if (!selectedData) return;
    if (linkError) return;
    if (selectedData.contentTag && !compareLinkAndSource(newUrl.trim(), selectedData.contentTag)) return;
    const formattedUrl: string | null = formatUrl(newUrl, selectedData.contentTag);
    switch (!!selectedData.newStage) {
      case true:
        onCreateNewStage({
          stageContentType: StageContentType.WIDESCREENSTAGE,
          wideScreenSectionType: WideScreenSectionType.EMBED,
          items: generateDocWithMediaNode(
            {
              src: formattedUrl,
              subType: selectedData.subType ?? EFieldsubType.MIXED,
              storageType: EMediaFieldStorageType.EXTERNAL,
            },
          ),
        });
        clearDialog();
        break;
      case false:
        dispatchEditorEvent(
          {
            position: selectedData.position,
            eventType: TiptapCustomEvents.CREATE,
            type: ExtensionNames.MEDIA,
            attrs: {
              subType: selectedData.subType ?? EFieldsubType.MIXED,
              storageType: EMediaFieldStorageType.EXTERNAL,
              src: formattedUrl,
            },
          },
        );
        clearDialog();
        break;
      default: break;
    }
  };

  return (
    <Dialog
      open={!!selectedData}
      onClose={clearDialog}
      PaperProps={{
        sx: {
          // eslint-disable-next-line max-len
          boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), 0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
          background: '#F9FBFF',
          border: '1px solid #E5EFFF',
          borderRadius: '10px',
          padding: '16px',
          width: '600px',
          marginTop: '40px',
        },
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
    >
      <DialogContent
        sx={{
          gap: '12px',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
        }}
      >
        <Box
          sx={{
            gap: '12px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box>
            {selectedData?.icon}
          </Box>
          <Box>
            <Typography
              sx={{
                color: 'rgba(69, 82, 115, 1)',
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 600,
              }}
            >
              {selectedData?.name}
            </Typography>
            <Typography
              sx={{
                color: 'rgba(112, 132, 180, 1)',
                fontSize: '14px',
                lineHeight: '16px',
              }}
            >
              {selectedData?.description}
            </Typography>
          </Box>
        </Box>
        <AppInput
          multiline
          size="m"
          error={!!linkError}
          fullWidth
          value={newUrl}
          maxRows={7}
          placeholder="Paste embed link from source"
          onChange={handleLinkFieldChange}
          helperText={linkError}
          onKeyDown={(e) => {
            if (e.nativeEvent.key === 'Enter') {
              e.preventDefault();
              onCreate();
            }
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: newUrl ? (
              <Box
                onClick={cleanNewLinkField}
                sx={{
                  cursor: 'pointer',
                  color: '#7084B4',
                  height: '20px',
                  width: '20px',
                  marginLeft: '8px',
                }}
              >
                <Close
                  sx={{
                    fontSize: '20px',
                  }}
                />
              </Box>
            ) : undefined,
            sx: {
              padding: '12px',
            },
          }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            sx: {
              overflow: 'auto',
              '@-moz-document url-prefix()': {
                scrollbarWidth: 'none',
              },
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            },
          }}
        />
        <AppButton
          onClick={onCreate}
          fullWidth
          variant="primary"
        >
          Insert
        </AppButton>
      </DialogContent>
    </Dialog>
  );
};

export default EmbedSectionDialog;
