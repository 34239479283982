import {
  PayloadAction, createAsyncThunk, createSlice,
} from '@reduxjs/toolkit';
import { EStateStatus, IStateBase } from '../../../core/commonTypes';
import {
  ProcessStageDTO,
  TStageItem,
  TUpdateProcessItem,
} from '../../../../shared/Field/Field';
import { ProcessItemsApi } from '../../../api/ProcessItemsApi';
import { RootState } from '../../../core/store/store';
import {
  FielItemsRecord, FileItem, TFileItemUpload, UploadItemStatus,
} from '../../../../shared/FileItems/FileItems';
import { ObjectBase } from '../../../../shared/Tiptap/TiptapTypes';
import { StageContentType } from '../../../../shared/Stage';
import { EProcessType } from '../../../../shared/process/ProcessMilestoneActionDTO';

// TODO: combine section and stage template items getter

const processItemsApi = new ProcessItemsApi();

const parseProcessItems = (data: TStageItem[]) => {
  const items: { [key: string]: TStageItem } = {};
  const flat: ObjectBase = {};
  const bookmarks: any = {};

  let parsed: ObjectBase = {};
  let contentReturn: boolean = false;
  let itemBookmarks: ObjectBase = [];

  const cleanParseData = () => {
    parsed = {};
    contentReturn = false;
    itemBookmarks = [];
  };

  const updateParseData = (d: ObjectBase, type: string) => {
    parsed[d.blockId] = { ...d, type };
    contentReturn = true;
  };

  const parseContent = (content: ObjectBase, prevDepth?: number) => {
    const depth = prevDepth || 0;
    if (content?.attrs?.blockId) {
      updateParseData(content.attrs, content.type);
    }
    if (depth === 1 && content?.type === 'heading' && content?.attrs?.level === 1 && content?.content?.[0].text) {
      itemBookmarks.push([content.attrs.blockId, content.content[0].text]);
    }
    if (!!content.content && !!content.content.length) {
      for (const subContent of content.content) {
        parseContent(subContent, depth + 1);
      }
    }
  };

  for (const item of data) {
    items[item.id] = item;
    if (item.items) {
      cleanParseData();
      parseContent(item.items);
      if (contentReturn) {
        flat[item.id] = parsed;
        bookmarks[item.id] = itemBookmarks;
      }
    }
  }

  cleanParseData();
  return { items, flat, bookmarks };
};

export interface IProcessItemsState extends IStateBase {
  currentStage: TStageItem | null;
  processItems: { [key: string]: TStageItem };
  itemsFetchStatus: null | EStateStatus;
  // tempItems: { [key: string]: string };
  updates: { [key: string]: TUpdateProcessItem };
  files: { [key: string]: FielItemsRecord };
  stageUsers: { [key: string]: string[] };
  socketId: string;
  flatItems: { [key: string]: { [key: string]: any } };
  bookmarks: { [key: string]: [string, string][] };
  updateAttempts: number;
  failedUpdates: string[];
  errorMessage?: string | null;
}

export interface AddTempItemData {
  itemData: TStageItem;
  parentId: string;
  position: number;
}

export enum EProcessItemsActions {
  GET_ITEMS = 'GET_ITEMS',
  GET_TEMPLATE_STAGE_ITEMS = 'GET_TEMPLATE_STAGE_ITEMS',
  GET_TEMPLATE_SECTION_ITEMS = 'GET_TEMPLATE_SECTION_ITEMS',
  GET_ITEM = 'GET_ITEM',
  CREATE_ITEM = 'CREATE_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  UPDATE_MANY_ITEMS = 'UPDATE_MANY_ITEMS',
  UPLOAD = 'UPLOAD',
  SELECT_CURRENT_FILES = 'SELECT_CURRENT_FILES',
}

export const processItemsReducesName = 'processItems';

const initialState: IProcessItemsState = {
  currentStage: null,
  processItems: {},
  status: EStateStatus.IDLE,
  itemsFetchStatus: null,
  updates: {},
  files: {},
  stageUsers: {},
  socketId: '',
  flatItems: {},
  bookmarks: {},
  updateAttempts: 0,
  failedUpdates: [],
};

export const getProcessItems = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.GET_ITEMS}`,
  async (id: string) => processItemsApi.getProcessItems(id),
);

export const getTemplateStageItems = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.GET_TEMPLATE_STAGE_ITEMS}`,
  async (id: string) => processItemsApi.getTeplateStageItems(id),
);

export const getTemplateSectionItems = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.GET_TEMPLATE_SECTION_ITEMS}`,
  async (id: string) => processItemsApi.getTeplateSectionItems(id),
);

export const getProcessItem = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.GET_ITEM}`,
  async (id: string) => processItemsApi.getProcessItem(id),
);

export const updateProcessItem = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.UPDATE_ITEM}`,
  async (data: TUpdateProcessItem, thunkApi) => {
    const state: RootState = thunkApi.getState();
    if (data?.data?.id) {
      data.data.id = state.processItems.processItems[data.id].id;
    }
    return processItemsApi.updateProcessItem(data);
  },
);

export const updateManyProcessItems = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.UPDATE_MANY_ITEMS}`,
  async (data: TUpdateProcessItem[]) => processItemsApi.updateManyProcessItems(data),
);

export const fileItemUpload = createAsyncThunk<ProcessStageDTO, TFileItemUpload>(
  `${processItemsReducesName}/${EProcessItemsActions.UPLOAD}`,
  async (data: TFileItemUpload) => processItemsApi.uploadItemFile(data),
);

export const selectCurrentEntityFiles = createAsyncThunk(
  `${processItemsReducesName}/${EProcessItemsActions.SELECT_CURRENT_FILES}`,
  async (entityId: string, { getState }) => ({
    ...(getState() as RootState).processItems.processItems[entityId].files,
    ...(getState() as RootState).processItems.files[entityId],
  }),
);

export const processItemsSlice = createSlice({
  name: processItemsReducesName,
  initialState,
  reducers: {
    setCurrentStage: (state, { payload }: PayloadAction<string | null>) => ({
      ...state,
      currentStage: payload ? state.processItems[payload] : null,
    }),
    resetItemsSlice: (state) => ({
      ...initialState,
      updates: state.updates,
    }),
    resetUpdatesError: (state) => ({
      ...state,
      updateAttempts: 0,
      errorMessage: undefined,
      failedUpdates: [],
    }),
    setItem: (state, { payload }: PayloadAction<TStageItem>) => {
      const { flat, bookmarks } = parseProcessItems([payload]);
      return ({
        ...state,
        currentStage: payload.id === state?.currentStage?.id ? payload : state.currentStage,
        processItems: {
          ...state.processItems,
          [payload.id]: payload,
        },
        flatItems: {
          ...state.flatItems,
          [payload.id]: flat[payload.id],
        },
        bookmarks: {
          ...state.bookmarks,
          [payload.id]: bookmarks[payload.id],
        },
      });
    },
    setStageUsers: (state, { payload }: PayloadAction<{ [key: string]: string[] }>) => ({
      ...state,
      stageUsers: payload,
    }),
    setSocketId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      socketId: payload,
    }),
    addItemFile: (
      state,
      {
        payload: { fileData, fileId, entityId },
      }: PayloadAction<{ fileData: FileItem, fileId: string, entityId: string }>,
    ) => ({
      ...state,
      processItems: {
        ...state.processItems,
        [entityId]: {
          ...state.processItems[entityId],
          files: {
            ...(state.processItems[entityId].files ?? {}),
            [fileId]: fileData,
          },
        },
      },
    }),
    addItemUpdate: (
      state,
      { payload }: PayloadAction<{ id: string, data: Partial<ProcessStageDTO> }>,
    ) => ({
      ...state,
      currentStage: payload.id === state?.currentStage?.id ? {
        ...state.currentStage, ...payload.data, files: state.currentStage.files,
      } : state.currentStage,
      updates: {
        ...state.updates,
        [payload.id]: state.updates[payload.id] ? {
          id: payload.id,
          processId: state.currentStage?.processId,
          data: {
            ...state.updates[payload.id].data,
            ...payload.data,
            id: state.processItems[payload.id].id,
            files: {
              ...state.updates[payload.id].files,
              ...payload.data.files,
            },
          },
        } : {
          id: payload.id,
          processId: state.currentStage?.processId,
          data: {
            ...payload.data,
            id: state.processItems[payload.id].id,
          },
        },
      },
    }),
    clearUpdates: (state) => ({
      ...state,
      updates: {},
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessItems.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessItems.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.itemsFetchStatus = EStateStatus.IDLE;
        const { items, flat, bookmarks } = parseProcessItems(action.payload);
        state.processItems = items;
        state.flatItems = flat;
        state.bookmarks = bookmarks;
        // action.payload.forEach((item) => {
        //   state.processItems[item.id] = item;
        // });
      })
      .addCase(getProcessItems.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getTemplateStageItems.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getTemplateStageItems.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.itemsFetchStatus = EStateStatus.IDLE;
        const { items, flat } = parseProcessItems(action.payload);
        state.processItems = items;
        state.flatItems = flat;
        state.currentStage = items[action.meta.arg];
      })
      .addCase(getTemplateStageItems.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getTemplateSectionItems.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getTemplateSectionItems.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.itemsFetchStatus = EStateStatus.IDLE;
        const { items, flat } = parseProcessItems(action.payload);
        state.processItems = items;
        state.flatItems = flat;
        state.currentStage = items[action.meta.arg];
      })
      .addCase(getTemplateSectionItems.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessItem.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessItem.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.itemsFetchStatus = EStateStatus.IDLE;
        const { flat, bookmarks } = parseProcessItems([action.payload]);
        state.processItems[action.payload.id] = action.payload;
        state.flatItems[action.payload.id] = flat[action.payload.id];
        state.bookmarks[action.payload.id] = bookmarks[action.payload.id];
        if (state.currentStage?.id === action.payload.id) {
          state.currentStage = action.payload;
        }
      })
      .addCase(getProcessItem.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateProcessItem.pending, (state, action) => {
        const { id, data } = action.meta.arg;
        state.processItems[id] = { ...state.processItems[id], ...data };
      })
      .addCase(updateProcessItem.fulfilled, (state) => {
        state.status = EStateStatus.IDLE;
      })
      .addCase(updateProcessItem.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateManyProcessItems.pending, (state, action) => {
        state.updates = {};
        for (const { id, data } of action.meta.arg) {
          if (data) {
            state.processItems[id] = {
              ...state.processItems[id],
              ...data,
              files: { ...state.processItems[id].files, ...data.files },
            };
            if (data?.items) {
              const newBookmarks: [string, string][] = [];
              data?.items?.content?.forEach((item: any) => {
                if (item?.type === 'heading' && item?.attrs?.level === 1 && item?.content?.[0].text) {
                  newBookmarks?.push([item?.attrs?.blockId, item?.content?.[0].text]);
                }
              });
              state.bookmarks[id] = newBookmarks;
            }
          }
        }
        state.status = EStateStatus.IDLE;
      })
      .addCase(updateManyProcessItems.fulfilled, (state) => {
        state.updateAttempts = 0;
        state.errorMessage = undefined;
        state.status = EStateStatus.IDLE;
      })
      .addCase(updateManyProcessItems.rejected, (state, action) => {
        state.updateAttempts += 1;
        state.errorMessage = action.error.message;
        if (state.updateAttempts < 20) {
          for (const {
            id, data, files, processId,
          } of action.meta.arg) {
            if (state.updates[id]) {
              state.updates[id] = {
                id,
                processId,
                data: {
                  ...data,
                  ...state.updates[id]?.data,
                  files: { ...files, ...state.updates[id].files },
                },
              };
            } else {
              state.updates[id] = {
                id, data, processId, files,
              };
            }
          }
        } else {
          state.updateAttempts = 0;
          state.failedUpdates = action.meta.arg.map((item) => item.id);
        }
        state.status = EStateStatus.ERROR;
      })
      .addCase(fileItemUpload.pending, (state, action) => {
        const {
          entityId, fileId, url, size, name,
        } = action.meta.arg;
        if (state.files[entityId]) {
          state.files[entityId][fileId] = {
            url, size, name, status: UploadItemStatus.IN_PROGRESS,
          };
        } else {
          state.files[entityId] = {
            [fileId]: {
              url, size, name, status: UploadItemStatus.IN_PROGRESS,
            },
          };
        }
      })
      .addCase(fileItemUpload.fulfilled, (state, action) => {
        const {
          entityId, fileId, size, name,
        } = action.meta.arg;
        const { files } = action.payload;
        const { url } = files[fileId];
        if (state.processItems?.[entityId]?.files) {
          state.processItems[entityId].files[fileId] = {
            url, size, name, status: UploadItemStatus.UPLOADED,
          };
        } else if (state.processItems?.[entityId]) {
          state.processItems[entityId].files = {
            [fileId]: {
              url, size, name, status: UploadItemStatus.UPLOADED,
            },
          };
        }
        if (state.files?.[entityId]?.[fileId]) {
          delete state.files[entityId][fileId];
        }
        if (state.files?.[entityId] && !Object.keys(state.files[entityId]).length) {
          delete state.files[entityId];
        }
      })
      .addCase(fileItemUpload.rejected, (state, action) => {
        state.status = EStateStatus.ERROR;
      });
  },
});

export const {
  setCurrentStage,
  addItemUpdate,
  clearUpdates,
  setItem,
  addItemFile,
  setStageUsers,
  setSocketId,
  resetItemsSlice,
  resetUpdatesError,
} = processItemsSlice.actions;

export const selectProcessItemsFetchStatus = (state: RootState) => state.processItems.itemsFetchStatus;
export const selectCurrentStage = (state: RootState) => state.processItems.currentStage;
export const selectCurrentStageId = (state: RootState) => state.processItems.currentStage?.id;
export const selectProcessItems = (state: RootState): { [key: string]: TStageItem } => state?.processItems?.processItems;
export const selectProcessItem = (state: RootState, id: string) => state?.processItems?.processItems[id];
export const selectItemDBId = (state: RootState, id: string) => {
  if (state.processItems.tempItems[id] && state.processItems.tempItems[id] === id) {
    return undefined;
  }
  return state?.processItems?.processItems?.[id]?.id;
};
export const selectIsUpdateSource = (state: RootState) => (
  state?.processItems?.socketId === state.processItems?.stageUsers?.[state?.processItems?.currentStage?.id]?.[0]
);

export const selectItemUpdates = (state: RootState) => state?.processItems.updates;

// eslint-disable-next-line max-len
export const selectIsWidescreenPage = (state: RootState) => state?.processItems?.currentStage?.stageContentType !== StageContentType.DEFAULT;

export const selectReadyUpdates = (state: RootState) => {
  const updates: { [key: string]: TUpdateProcessItem } = { ...state.processItems.updates };
  return Object.values(updates);
};

export const selectFileItem = (
  state: RootState,
  entityId: string,
  fileId?: string,
): FileItem | undefined => {
  if (fileId) {
    return state.processItems?.processItems?.[entityId]?.files?.[fileId]
      || state.processItems?.files?.[entityId]?.[fileId];
  }
  return undefined;
};

export const selectEntityFiles = (
  state: RootState,
  entityId: string,
): FielItemsRecord | undefined => ({
  ...state.processItems?.processItems?.[entityId]?.files,
  ...state.files[entityId],
});

export const selectCurrentStageWithUpdates = (state: RootState) => {
  const currentStageId = state.processItems.currentStage?.id;
  if (!currentStageId) return null;
  const currentStage = state.processItems.processItems[currentStageId];
  const currentStageUpdates = state.processItems.updates[currentStageId];
  if (!currentStageUpdates || !Object.entries(currentStage).length) {
    return currentStage;
  }
  return {
    ...currentStage,
    files: { ...currentStage.files, ...currentStageUpdates.files },

  };
};

export const selectIsStageTemplate = (state: RootState) => state.processItems.currentStage?.template;
export const selectProcessItemsLoadingStatus = (state: RootState) => state.processItems.status;

export const selectItemBookmarks = (state: RootState, id: string): [string, string][] => state.processItems?.bookmarks?.[id] ?? [];
export const selectStageFirstLastChildStatus = (
  state: RootState,
  id: string,
): [boolean, boolean] => {
  if (!state.processItems.currentStage || !state.processItems?.bookmarks?.[id]?.length) { return [false, false]; }
  const currentStageBookmarks = state.processItems?.bookmarks?.[state.processItems.currentStage.id];
  if (!currentStageBookmarks) return [false, false];
  return [currentStageBookmarks[0][0] === id, currentStageBookmarks[currentStageBookmarks.length - 1][0] === id];
};

export const selectFlatItem = (state: RootState, entityId: string, itemId: string) => state.processItems?.flatItems?.[entityId]?.[itemId];

export const selectIsTemplateItem = (state: RootState): boolean => state.process?.process?.type === EProcessType.TEMPLATE
  || state.processItems?.currentStage?.template;

export const selectFailedItemUpdates = (state: RootState) => state.processItems?.failedUpdates;
export const selectFailedItemNamesUpdates = (state: RootState) => state.processItems?.failedUpdates?.map(
  (id: string) => state.processItems?.processItems?.[id]?.title,
).filter((item: any) => !!item);
export const selectUpdatesErrorMessage = (state: RootState) => state.processItems?.errorMessage;

export const selectProcessStages = (state: RootState) => state.process.process.fields.map(
  (field) => state.processItems.processItems[field.id],
);
