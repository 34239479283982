import { Box } from '@mui/material';
import MediaFieldUpload from '../../../MediaField/MediaFieldEmpty/MediaFieldUpload';

type Props = {
  onFileUpload: (files: File[]) => void;
};

const MediaFieldMenuUpload = ({
  onFileUpload,
}: Props): JSX.Element => {
  const onFilesUpload = (files: File[]) => {
    onFileUpload(files);
  };

  return (
    <Box
      sx={{
        padding: '16px 5px 0',
      }}
    >
      <MediaFieldUpload
        onFilesUpload={onFilesUpload}
      />
    </Box>
  );
};

export default MediaFieldMenuUpload;
