import { TAllPermissions, TAllRoleNames } from './permissions';

export enum EUserProcessStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
  INVITED_AND_DEACTIVATED = 'INVITED_AND_DEACTIVATED',
}

export const userProcessActiveStatuses = [EUserProcessStatus.ACTIVE, EUserProcessStatus.INVITED]

export class UserProcessPermissionsDTO {
  processPermissions: TAllPermissions;
  roleNames: TAllRoleNames[];
}

export interface IAddUserToProcessRequest {
  userId: string;
  processId: string;
  roleIds: string[];
}

export interface IUserProcessDataRequest {
  processId: string;
  userId: string
}
