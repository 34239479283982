import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { AppButton } from '../../shared/AppButton/AppButton';
import { Add20px } from '../../icons/Add';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { EFileType, ELibraryType } from '../../../shared/library/Library';
import {
  changeFileLinkDialogStatus,
  createFileItem,
  selectLibraryGridToRender,
  setLibraryFeedTab,
  setLibraryFeedTitle,
} from './lib/librarySlice';
import { stagesetColors } from '../../theme/stagesetPalette';
import ChevronDown from '../../icons/SectionMenuIcons/ChevronDown20.svg';
import { getSubtypeByMIME, mediaFieldFormats, mediaFieldMimes } from '../../features/MediaField/Helpers/MediaFieldHelpers';
import { selectOrganizationId, selectUserId } from '../../core/store/appState/appState';
import { ELibraryFeedTab } from './helpers/library.helper';
import { ProductWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import LinkIcon from '../../icons/link20.svg';

const MAX_SIZE = 1024 * 1024 * 5;

const LibraryHeaderUploadButton = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const anchorRef = useRef<HTMLDivElement>(null);
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const userId = useAppSelector(selectUserId);
  const organizationId = useAppSelector(selectOrganizationId);
  const [file, setFile] = useState<File[] | undefined>([]);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const inputAccepts = mediaFieldMimes(mediaFieldFormats);
  const createFile = () => inputRef.current?.click();

  const handleUploadStart = (data: File[]) => {
    if (organizationId && userId) {
      dispatch(createFileItem({
        name: data[0].name,
        userId,
        file: data[0],
        organizationId,
        type: EFileType.FILE,
        fileSubtype: getSubtypeByMIME(data[0].type),
      }));
      setFile(undefined);
      enqueueSnackbar(
        'File uploaded successfully',
        {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 1000,
        },
      );
    }
  };

  useEffect(() => {
    if (file?.[0]) {
      if (file?.[0].size > MAX_SIZE) {
        enqueueSnackbar('Wrong file size!', { variant: 'error' });
      } else {
        handleUploadStart(file);
      }
    }
  }, [file]);

  const onCreateProductItemOpen = (item?: ProductWithOwnerDTO) => {
    dispatch(setLibraryFeedTitle(item?.name || 'New product'));
    dispatch(setLibraryFeedTab({
      open: true,
      type: ELibraryFeedTab.PRODUCT,
      item,
    }));
  };

  const handleCreateButtonClick = () => {
    if (gridToRender === ELibraryType.FILE) {
      createFile();
    } else {
      onCreateProductItemOpen();
    }
  };

  const handleCreateLinkClick = () => {
    setOpen((prevOpen) => !prevOpen);
    dispatch(changeFileLinkDialogStatus({
      open: true,
    }));
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) return;
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
      >
        <input
          hidden
          type="file"
          ref={inputRef}
          onChange={(e) => e.target.files && setFile([...e.target.files])}
          className="input"
          accept={inputAccepts}
        />
        <AppButton
          onClick={handleCreateButtonClick}
          size="l"
          variant="primary"
          sx={gridToRender !== ELibraryType.PRODUCT ? {
            minWidth: '10px !important',
            borderTopRightRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
            borderColor: `${stagesetColors.newGrey[600]} !important`,
          } : {}}
          startIcon={<Add20px />}
        >
          {gridToRender === ELibraryType.FILE ? 'File' : 'Product'}
        </AppButton>
        {gridToRender !== ELibraryType.PRODUCT && (
        <AppButton
          onClick={handleToggle}
          size="l"
          variant="primary"
          sx={{
            minWidth: '10px !important',
            borderTopLeftRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
          }}
          startIcon={<ChevronDown />}
        />
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width: '240px',
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
            placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key="create-link"
                    onClick={() => handleCreateLinkClick()}
                  >
                    <LinkIcon />
                    <Box
                      sx={{
                        marginLeft: '10px',
                      }}
                    >
                      Link
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LibraryHeaderUploadButton;
