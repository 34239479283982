import { Box, Typography } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { EStateStatus } from '../../core/commonTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import {
  ETemplatesTypes,
  resetSelectedTemplateData,
  selectTemplatesFilter,
  selectTemplatesTab,
  selectTemplateData,
  setSelectedTemplateData,
  clearTemplatesState,
  deleteTemplate,
  getTemplates,
  selectAllTemplates,
} from '../../pages/templates/lib/templatesSlice';
import { formateValueForLastActive } from '../../routes-old/process/helpers';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { ExtendedGridColumns } from '../../types/mobile-table';
import { AppButton } from '../../shared/AppButton/AppButton';
import { CrossDimensionDataGrid } from '../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { AppDataGrid } from '../../shared/AppDataGrid';

enum EProcessTemplatesColumns {
  name = 'name',
  menu = 'menu',
}

type Props = {
  onTemplateUse: (id: string) => void;
  onTemplateSelect: (id: string, title: string) => void;
};

const TemplateList = ({ onTemplateUse, onTemplateSelect }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector(selectAllTemplates);
  const selectedTemplateData = useAppSelector(selectTemplateData);
  const currentTab = useAppSelector(selectTemplatesTab);

  const filter = useAppSelector(selectTemplatesFilter);
  const status = useAppSelector((state) => state.templates.status);

  useEffect(() => {
    if (
      templates.length
      && selectedTemplateData?.type !== EProcessType.TEMPLATE
      && currentTab === ETemplatesTypes.ROOMS
      && !isMobile
    ) {
      dispatch(setSelectedTemplateData({
        id: templates[0].id,
        title: templates[0].title,
        type: EProcessType.TEMPLATE,
      }));
    }
    if (
      !templates?.length
      && selectedTemplateData?.type !== EProcessType.TEMPLATE
      && currentTab === ETemplatesTypes.ROOMS
    ) {
      dispatch(resetSelectedTemplateData());
    }
  }, [templates, currentTab, selectedTemplateData?.type, isMobile]);

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getTemplates(filter));
    }
    return () => dispatch(clearTemplatesState());
  }, [filter]);

  const [templateToDelete, setTemplateToDelete] = useState<{ id: string, title: string } | null>(null);

  const onDeleteDialogAccept = (choice: boolean) => {
    if (choice && !!templateToDelete) {
      dispatch(deleteTemplate(templateToDelete.id));
      if (
        selectedTemplateData?.id === templateToDelete.id
      ) {
        const newTemplate = templates.find((template) => template.id !== templateToDelete.id);
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: newTemplate.title,
            type: EProcessType.TEMPLATE,
          }));
        }
      }
      setTemplateToDelete(null);
    } else {
      setTemplateToDelete(null);
    }
  };

  const columns: ExtendedGridColumns = [
    {
      field: EProcessTemplatesColumns.name,
      sortable: true,
      headerName: isMobile ? 'Last updated' : 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: (
        {
          value,
        },
      ) => (
        <Box
          className="RowLink"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '20px',
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[600],
            }}
          >
            {value.title}
          </Typography>
          <Typography
            sx={{
              color: stagesetPalette?.grey[400],
              fontSize: '12px',
            }}
          >
            {
              `Updated ${formateValueForLastActive(DateTime.fromISO(value?.lastInteraction).toMillis() as number)}`
            }
          </Typography>
        </Box>
      ),
    },
    {
      field: EProcessTemplatesColumns.menu,
      sortable: false,
      headerName: '',
      align: 'right',
      cellClassName: 'pro-grid-cell',
      width: 100,
      maxWidth: 100,
      renderCell: ({ id }) => (
        <Box
          sx={{
            display: 'flex',
            paddingRight: '16px',
            justifyContent: 'flex-end',
          }}
          className={classNames('menu-cell', { 'menu-cell--active': selectedTemplateData?.id === id })}
        >
          <AppButton
            sx={{
              width: 'auto !important',
              minWidth: 'auto !important',
            }}
            variant="primary"
            size="l"
            onClick={() => onTemplateUse(id.toString())}
          >
            Use
          </AppButton>
        </Box>
      ),
    },
  ];

  const rows: GridRowsProp = templates.map((template) => ({
    id: template.id,
    title: template.title,
    [EProcessTemplatesColumns.name]: {
      title: template.title,
      lastInteraction: template.lastInteraction,
    },
    [EProcessTemplatesColumns.menu]: template.id,
  }));

  return (
    <>
      {/* <Box sx={{
        marginBottom: '16px',
      }}
      >
        <LibraryFilter />
      </Box> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: 'calc( 100% )',
          maxHeight: 'calc( 100% )',
          '& .menu-cell:not(.menu-cell--active)': {
            visibility: 'hidden',
          },
          '& .MuiDataGrid-row:hover .menu-cell': {
            visibility: 'visible',
          },
          '& .template-list': {
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              border: (theme) => `1px solid ${theme.palette.grey[100]}`,
              '&:hover': {
                backgroundColor: stagesetPalette?.primary[50],
              },
            },
            '& .SelectedRow': {
              border: `1px solid ${stagesetPalette?.primary[400]}`,
            },
          },
        }}
      >
        <CrossDimensionDataGrid
          columns={columns}
          rows={rows}
          getId={(row) => row.id}
          renderMobileTitle={(row) => row[EProcessTemplatesColumns.name].title}
          renderMobileMenu={() => null}
          onRowClick={(row) => onTemplateSelect(row.id, row[EProcessTemplatesColumns.name].title)}
          renderDesktopGridComponent={() => (
            <AppDataGrid
              className="hiddenHeader template-list"
              disableSelectionOnClick
              disableColumnMenu
              hideFooter
              columns={columns}
              rows={rows}
              rowHeight={74}
              headerHeight={-4}
              getRowSpacing={() => ({
                top: 4,
                bottom: 4,
              })}
              onRowClick={(rowParams) => onTemplateSelect(rowParams.id as string, rowParams.row.title)}
              getRowClassName={(params) => (params.row.id === selectedTemplateData?.id ? 'SelectedRow' : '')}
            />
          )}
        />
      </Box>
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={!!templateToDelete}
        onConfirm={() => onDeleteDialogAccept(true)}
        onCancel={() => onDeleteDialogAccept(false)}
        dialogContentText={(
          <Box>
            Are you sure you want to delete template?
          </Box>
        )}
        dialogTitle={templateToDelete?.title ?? 'Delete process template'}
        confirmText="Delete"
      />
    </>
  );
};

export default TemplateList;
