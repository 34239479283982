import { SvgIcon } from '@mui/material';
import AddUsers from './AddUsers.svg';

const ShareIcon = (): JSX.Element => (
  <SvgIcon
    component={AddUsers}
    viewBox="0 0 20 20"
    sx={{
      width: '20px',
      height: '20px',
      color: 'transparent',
    }}
  />
);

export default ShareIcon;
