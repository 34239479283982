import { SvgIcon, SvgIconProps } from '@mui/material';

export const ThreeDotsVert20px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M9.99998 9.16667C9.53974 9.16667 9.16665 9.53976 9.16665
      10C9.16665 10.4602 9.53974 10.8333 9.99998
      10.8333C10.4602 10.8333 10.8333 10.4602
      10.8333 10C10.8333 9.53977 10.4602 9.16667 9.99998 9.16667Z"
      fill="none"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99998 15C9.53974 15 9.16665 15.3731 9.16665
      15.8333C9.16665 16.2936 9.53974 16.6667 9.99998
      16.6667C10.4602 16.6667 10.8333 16.2936 10.8333
      15.8333C10.8333 15.3731 10.4602 15 9.99998 15Z"
      fill="none"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99998 3.33333C9.53974 3.33333 9.16665 3.70643
      9.16665 4.16667C9.16665 4.6269 9.53974 5 9.99998
      5C10.4602 5 10.8333 4.6269 10.8333 4.16667C10.8333
      3.70643 10.4602 3.33333 9.99998 3.33333Z"
      fill="none"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
