import { Box, CircularProgress } from '@mui/material';
import { StagesetLogoFull } from './StagesetLogoFull';

const AppLoadingScreen = (): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '93vh',
      flexDirection: 'column',
    }}
  >
    <StagesetLogoFull />
    <CircularProgress sx={{ mt: '32px' }} color="primary" />
  </Box>
);

export default AppLoadingScreen;
