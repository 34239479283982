import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { EAppRoutes } from '../../core/router';
import SquaredLogoPlaceholderIcon from '../../icons/SquaredLogoPlaceholderIcon';

const SquaredLogoPlaceholder = (): JSX.Element => (
  <Tooltip
    title="Upload your company logo in settings"
    placement="bottom-start"
    componentsProps={{
      tooltip: {
        sx: {
          background: (theme) => theme.palette.grey[400],
        },
      },
      arrow: {
        sx: {
          color: (theme) => theme.palette.grey[400],
        },
      },
    }}
  >
    <NavLink to={EAppRoutes.settings} style={{ display: 'flex' }}>
      <SquaredLogoPlaceholderIcon />
    </NavLink>
  </Tooltip>
);
export default SquaredLogoPlaceholder;
