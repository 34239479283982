import { useAppDispatch } from '../../hooks/stateHooks';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import { LineChartIcon20 } from '../../icons/LineChartIcon';
import { AppButton } from '../../shared/AppButton/AppButton';
import { setFeedTab, EFeedTab, setFeedTitle } from '../DealFeed/lib/feedSlice';

const RGMActivityBtn = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { closeDrawer } = useMobileBottomDrawer();

  return (
    <AppButton
      size="l-flex-start"
      variant="transparent-grey-icon"
      fullWidth
      disableRipple
      startIcon={<LineChartIcon20 />}
      onClick={() => {
        dispatch(setFeedTab(EFeedTab.INTERNAL));
        dispatch(setFeedTitle('Activity'));
        closeDrawer();
      }}
    >
      Activity
    </AppButton>
  );
};

export default RGMActivityBtn;
