import { useState, useEffect } from 'react';
import { OrganizationApi } from '../../../api/OrganizationApi';
import { OrganizationDTO } from '../../../../shared/OrganizationDTO';

const organizationApi: OrganizationApi = new OrganizationApi();

export function useOrganizationFetchData(organizationId: string) {
  const [data, setData] = useState<OrganizationDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await organizationApi.getOrganizationData(organizationId);
        setData(response);
      } catch (e: any) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationId]);

  return { data, isLoading, error };
}
