import { selectCreateDealDialogStatus, setCreateDealDialogStatus } from '../routes-old/deals/state/dealsSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';

const useDealsSlice = () => {
  const dispatch = useAppDispatch();
  const isDialogOpen = useAppSelector(selectCreateDealDialogStatus);

  const onDialogStatusChange = (newStatus: boolean) => {
    dispatch(setCreateDealDialogStatus(newStatus));
  };

  return {
    isDialogOpen,
    onDialogStatusChange,
  };
};

export default useDealsSlice;
