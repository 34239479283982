import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ProcessDTO } from '../../shared/process/ProcessMilestoneActionDTO';
import { EJWTTokenType } from '../../shared/schema/token.schema';
import {
  ESubscriptionLimits, ESubscriptionLimitStatus, ESubscriptionType, SubscriptionDTO,
} from '../../shared/Subscription';
import { EStateStatus } from '../core/commonTypes';
import AppRouter, {
  BuyerRouter, EAppRoutes, GuestRouter, SharedRouter,
} from '../core/router';
import { salesforceGetIntegration } from '../core/crmintegrations/salesforce';
import { getUserData, getUserFilter, getUserOrganization } from '../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../hooks/stateHooks';
import { billingGetSubscription } from '../routes-old/Billing/state/billingSlice';
import { getBuyerDeals } from '../routes-old/BuyerDeals/state/buyerDealsSlice';
import AppLoadingScreen from './AppLoadingScreen';
import Page from './Page/Page';
import { pipedriveGetIntegration } from '../core/crmintegrations/pipedrive';
import { hubspotGetIntegration } from '../core/crmintegrations/hubspot';
import { logout } from '../routes-old/auth/state/authState';
import CrossOrganizationInviteDialog from './CrossOrganizationInviteDialog';
import CelloIntegration from '../features/CelloIntegration/CelloIntegration';
import ProcessItemsUpdatePlugin from '../features/Layout/ProcessItemsUpdatePlugin';

const AppLayout = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { tokenType, isAuthenticated } = useAppSelector((state) => state.auth);
  const { userOrganizationDataLoadingStatus, user } = useAppSelector((state) => state.app);
  const { subscription } = useAppSelector((state) => state.billing);
  const navigate = useNavigate();
  const [isAppDataLoaded, setIsAppDataLoaded] = useState<boolean>(false);

  if (!isMobile) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'm6snnga4',
      name: `${user?.name}`, // Full name
      email: `${user?.email}`, // Email address
    // created_at: 'user.createdAt', // Signup date as a Unix timestamp
    });
    window.Intercom('update', {
      hide_default_launcher: false,
    });
  }
  useEffect(() => {
    if (userOrganizationDataLoadingStatus === EStateStatus.ERROR) {
      dispatch(logout());
    }
    setIsAppDataLoaded(userOrganizationDataLoadingStatus === EStateStatus.IDLE && user?.id);
  }, [userOrganizationDataLoadingStatus]);

  const getAppData = async (): Promise<void> => {
    if (isAuthenticated && tokenType === EJWTTokenType.AUTH && userOrganizationDataLoadingStatus !== EStateStatus.LOADING && !user) {
      try {
        await dispatch(getUserFilter()).unwrap();
      } catch (e) { /* silent catch, to not break the app if filters are not laoded */ }
      const buyerDeals: ProcessDTO[] = await dispatch(getBuyerDeals()).unwrap();
      const orgSubscription: SubscriptionDTO = await dispatch(billingGetSubscription()).unwrap();
      if (
        buyerDeals?.length === 1
        && orgSubscription?.type === ESubscriptionType.BUYER
        && !sessionStorage.getItem('session')
      ) {
        sessionStorage.setItem('session', 'true');
        navigate(`${EAppRoutes.deal}/${buyerDeals[0].id}`);
      }
      // await dispatch(getOrganizationFiles());
      // await dispatch(getUserOrganization()).unwrap();
      await dispatch(getUserOrganization());
      dispatch(pipedriveGetIntegration());
      dispatch(hubspotGetIntegration());
      dispatch(salesforceGetIntegration());
    }
    if (isAuthenticated && (tokenType === EJWTTokenType.PROCESS_AUTH || tokenType === EJWTTokenType.GUEST_AUTH)) {
      dispatch(getUserData());
    }
  };

  useEffect(() => {
    if (user?.tourStatus?.shouldSeeBilling) {
      navigate(`${EAppRoutes.billing}`);
    }
  }, [user?.tourStatus?.shouldSeeBilling]);

  useEffect(() => {
    getAppData();
  }, [tokenType, isAuthenticated]);

  const getRouter = (): JSX.Element => {
    if (tokenType === EJWTTokenType.SHARE) {
      return <SharedRouter />;
    }
    if (tokenType === EJWTTokenType.GUEST_AUTH) {
      return <GuestRouter />;
    }
    if (subscription?.limits?.[ESubscriptionLimits.SELLER_DEAL_BOARD] === ESubscriptionLimitStatus.ACTIVE) {
      return <AppRouter />;
    }
    return <BuyerRouter />;
  };

  return (
    <>
      <Box
        className="AppLayoutContainer"
        sx={{
          flex: 1,
          display: 'flex',
          height: '100%',
        }}
      >
        <ProcessItemsUpdatePlugin />
        {
        tokenType === EJWTTokenType.SHARE && (
          <Page>
            {getRouter()}
          </Page>
        )
      }
        {
        tokenType === EJWTTokenType.GUEST_AUTH && (
          <Page>
            {getRouter()}
          </Page>
        )
      }
        {
        tokenType !== EJWTTokenType.SHARE
        && tokenType !== EJWTTokenType.GUEST_AUTH
        && (
          <>
            {isAppDataLoaded && (
              <>
                <CelloIntegration />
                <Page>
                  {getRouter()}
                </Page>
              </>
            )}
            {(!isAppDataLoaded) && (
              <AppLoadingScreen />
            )}
          </>
        )
      }
      </Box>
      <CrossOrganizationInviteDialog />
    </>
  );
};

export default AppLayout;
