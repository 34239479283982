import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const InputValueWrapper = styled<any>(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
}));

export const InputImage = styled<any>(Box)(() => ({
  borderRadius: '50%',
  width: '16px',
  height: '16px',
}));

export const InputText = styled<any>(Box)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));
