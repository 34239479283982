import { CancelSubscriptionRequest } from '../../shared/stripe';
import { StripeInvoice, SubscriptionDTO } from '../../shared/Subscription';
import { ApiService } from '../core/api';

export class SubscriptionApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('organization-subscription');
  }

  async updateSubscriptionStatus(payload: CancelSubscriptionRequest): Promise<void> {
    return this.api.post('/status-update', payload);
  }

  async getNextInvoice(): Promise<StripeInvoice> {
    return this.api.get('/next-invoice');
  }

  async upgradeToSellerSubscription(): Promise<SubscriptionDTO> {
    return this.api.get<SubscriptionDTO>('/upgrade-to-seller-subscription');
  }
}
