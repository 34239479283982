import { Box, Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ELoginErrorName } from '../../../shared/schema/token.schema';
import { AuthRouter, EAppRoutes } from '../../core/router';
import { useAppSelector } from '../../hooks/stateHooks';
// @ts-ignore
import Logo from '../../public/logo-dark-full.png';

const AuthLayout = (): JSX.Element => {
  window.Intercom('shutdown');
  const { error } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.error === ELoginErrorName.NOT_ACTIVE_PASSWORD_SIGNUP) {
      navigate(`${EAppRoutes.auth}${EAppRoutes.resendActivationLetter}?email=${error.email}`);
    }
    if (error?.error === ELoginErrorName.NOT_ACTIVE_SOCIAL_SIGNUP) {
      navigate(`${EAppRoutes.auth}${EAppRoutes.useSocialLogin}`);
    }
  }, [error?.error]);

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: 'calc( 100vh )',
        maxHeight: 'calc( 100vh )',
        height: 'calc( 100vh )',
      }}
    >
      <AuthRouter />
    </Grid>
  );
};

export default AuthLayout;
