import { AccessLinkDTO, AccessLinkGuestData, AccessLinkProtectionUpdate } from '../../shared/AccessLinkDTO';
import { ApiService } from '../core/api';

export class AccessLinkApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('access-link');
  }

  async updateLinkById(linkId: string, update: Partial<AccessLinkDTO>): Promise<AccessLinkDTO> {
    return this.api.patch<AccessLinkDTO>(`${linkId}`, update);
  }

  async updateLinkProtection(linkId: string, update: AccessLinkProtectionUpdate): Promise<AccessLinkDTO> {
    return this.api.patch<AccessLinkDTO>(`protection/${linkId}`, update);
  }

  async getAccessLinkByProcessId(linkId: string): Promise<AccessLinkDTO> {
    return this.api.get<AccessLinkDTO>(`${linkId}`);
  }

  async getAccessLinkByHash(hash: string): Promise<AccessLinkDTO> {
    return this.api.get<AccessLinkDTO>(`by-hash/${hash}`);
  }

  async getProcessGuests(id: string): Promise<AccessLinkGuestData[]> {
    return this.api.get<AccessLinkGuestData[]>(`get-guests/${id}`);
  }
}
