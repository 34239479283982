import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../core/subscriptions';
import { ESubscriptionStatus, ESubscriptionType, StripeInvoice } from '../../shared/Subscription';
import {
  billingGetNextInvoice,
  billingGetSubscription,
  selectCurrentPlan,
  selectIsAppsumo,
  selectOrgSubscriptionType,
  selectStagesetPlans,
  selectSubscriptionInteval,
} from '../routes-old/Billing/state/billingSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { organizationGetSubscription, selectOrganization } from '../core/store/appState/appState';
import { SubscriptionApi } from '../api/SubscriptionApi';
import { UserOrganizationApi } from '../api/UserOrganizationApi';
import { StagesetPlansDTO } from '../../shared/stripe';

const userOrganizationApi: UserOrganizationApi = new UserOrganizationApi();

const formatDate = (timeMS: number): string => (timeMS
  ? DateTime.fromMillis(timeMS)
    .setLocale('en')
    .toLocaleString({
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      weekday: 'long',
    })
  : ' ');

const subscriptionApi: SubscriptionApi = new SubscriptionApi();

// todo: add correct logic
export const useSubscriptionsNew = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const plans = useAppSelector(selectStagesetPlans);
  const currentPlanObj = useAppSelector(selectCurrentPlan);
  const interval = useAppSelector(selectSubscriptionInteval);
  const organization = useAppSelector(selectOrganization);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { subscription, nextInvoice } = useAppSelector((state) => state.billing);
  const checkIfCurrentSubscriptionIsAppSumo = useAppSelector(selectIsAppsumo);
  const currentPlan = useAppSelector(selectOrgSubscriptionType);
  const isAppSumoSubscription: boolean = currentPlan && checkIfCurrentSubscriptionIsAppSumo;

  const formPrice = (price: number) => (organization?.celloReferrer ? ((price / 100) * 0.7).toFixed(2) : price / 100);

  const title = useMemo(() => {
    switch (currentPlan) {
      case ESubscriptionType.SELLER_APPSUMO_TIER_1:
        return 'License Tier 1';
      case ESubscriptionType.SELLER_APPSUMO_TIER_2:
        return 'License Tier 2';
      case ESubscriptionType.SELLER_APPSUMO_TIER_3:
        return 'License Tier 3';
      case ESubscriptionType.SELLER_BASIC:
        return 'Basic';
      case ESubscriptionType.SELLER_PREMIUM:
        return 'Pro';
      default:
        return 'Trial';
    }
  }, [currentPlan]);

  const subscriptionPlans: {
    [key: string]: StagesetPlansDTO | { [key: string] : any };
  } = useMemo(() => {
    const res = {
      basic: {
        priceId: '',
        unitAmount: 0,
      },
      premium: {
        priceId: '',
        unitAmount: 0,
      },
    };
    if (plans.length) {
      plans.forEach((item: StagesetPlansDTO) => {
        if (item.interval !== interval) return;
        switch (item.subscriptionName) {
          case ESubscriptionType.SELLER_BASIC:
            res.basic = item;
            break;
          case ESubscriptionType.SELLER_PREMIUM:
            res.premium = item;
            break;
          default:
            break;
        }
      });
    }
    return res;
  }, [plans, interval]);

  const formNextPaymentData = () => {
    if (subscription.type === ESubscriptionType.SELLER) return ' ';
    if (nextInvoice?.endAt) {
      return ', ends at: ';
    }
    return `, ${t('Subscriptions_Next_Payment')} `;
  };

  const sleep = async (ms: number): Promise<void> => new Promise((resolve: () => void): void => {
    setTimeout(resolve, ms);
  });

  const onSubscriptionStatusUpdate = async () => {
    setIsLoading(true);
    await subscriptionApi.updateSubscriptionStatus({
      stagesetSubscriptionId: subscription?.id,
    });
    await sleep(2000);
    await dispatch(organizationGetSubscription()).unwrap();
    await dispatch(billingGetSubscription()).unwrap();
    await dispatch(billingGetNextInvoice()).unwrap();
    setIsLoading(false);
  };

  const onPremiumSubscriptionStart = async (priceId?: string) => {
    if (priceId) {
      userOrganizationApi.createCheckoutSession(priceId);
    }
  };

  const onSubscriptionPlanChange = async (newPriceId: string) => {
    if (currentPlan && currentPlan.priceId !== newPriceId) {
      setIsLoading(true);
      await userOrganizationApi.changeSubscriptionPlan(newPriceId);
      await sleep(2000);
      await dispatch(organizationGetSubscription()).unwrap();
      await dispatch(billingGetSubscription()).unwrap();
      dispatch(billingGetNextInvoice());
      setIsLoading(false);
    }
  };

  const checkIfSubscriptionActive = (subscrpn: Subscription) => (subscrpn.status === ESubscriptionStatus.ACTIVE);

  const subscriptions: Subscription[] = [
    {
      title: 'Basic trial',
      features: [
        t('Subscriptions_Unlimited_deal_rooms'),
        t('Subscriptions_Unlimited_clients'),
        t('Subscriptions_Analytics'),
        t('Subscriptions_Mutual_Action_Plan'),
      ],
      description: [
        {
          title: 'Month',
          value: {
            value: 'Free',
            perUser: false,
          },
        },
      ],
      btnTitle: t('Subscriptions_Cancel_Subscription'),
      type: ESubscriptionType.SELLER,
      status: (subscription?.type === ESubscriptionType.SELLER) ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE,
    },
    {
      title: 'Basic',
      priceId: subscriptionPlans?.basic?.priceId,
      features: [
        t('Subscriptions_Unlimited_deal_rooms'),
        t('Subscriptions_Unlimited_clients'),
        t('Subscriptions_Analytics'),
        t('Subscriptions_Mutual_Action_Plan'),
      ],
      description: [
        {
          title: 'Month',
          value: {
            value: `$${subscriptionPlans?.basic?.unitAmount ? formPrice(subscriptionPlans.basic.unitAmount) : '-'}`,
            perUser: true,
            interval: subscriptionPlans.basic.interval,
          },
        },
      ],
      btnTitle: t('Subscriptions_Get_started'),
      type: ESubscriptionType.SELLER_BASIC,
      status: (subscription?.type === ESubscriptionType.SELLER_BASIC) ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE,
    },
    {
      title: 'Pro',
      priceId: subscriptionPlans?.premium?.priceId,
      features: [
        t('Subscriptions_Everything_in_Basic'),
        t('Subscriptions_Integrations'),
        t('Subscriptions_Advanced_Analytics'),
        t('Subscriptions_Stageset_AI'),
        t('Subscriptions_StagesetSign'),
      ],
      description: [
        {
          title: 'Month',
          value: {
            value: `$${subscriptionPlans?.premium?.unitAmount ? formPrice(subscriptionPlans.premium.unitAmount) : '-'}`,
            perUser: true,
            interval: subscriptionPlans.premium.interval,
          },
        },
      ],
      btnTitle: t('Subscriptions_Get_started'),
      type: ESubscriptionType.SELLER_PREMIUM,
      status: (subscription?.type === ESubscriptionType.SELLER_PREMIUM) ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE,
    },
  ];

  const appSumoSubscriptions: Subscription[] = [
    {
      title: 'License Tier 1',
      features: [
        `5 ${t('Subscriptions_users')}`,
        t('Subscriptions_Unlimited_deal_rooms'),
        t('Subscriptions_Unlimited_clients'),
        t('Subscriptions_Analytics'),
        t('Subscriptions_Mutual_Action_Plan'),
        t('Subscriptions_Track_buyer_engagement'),
      ],
      description: [
        {
          title: 'Month',
          value: {
            value: '$59',
            perUser: false,
          },
        },
      ],
      btnTitle: t('Subscriptions_Get_started'),
      type: ESubscriptionType.SELLER_APPSUMO_TIER_1,
      status: (subscription?.type === ESubscriptionType.SELLER_APPSUMO_TIER_1) ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE,
    },
    {
      title: 'License Tier 2',
      features: [
        `10 ${t('Subscriptions_users')}`,
        t('Subscriptions_Everything_in_Tier_1'),
        t('Subscriptions_Advanced_Analytics'),
        t('Subscriptions_Integrations_future_included'),
        t('Subscriptions_Stageset_AI'),
        t('Subscriptions_StagesetSign'),
      ],
      description: [
        {
          title: 'Month',
          value: {
            value: '$119',
            perUser: false,
          },
        },
      ],
      btnTitle: t('Subscriptions_Get_started'),
      type: ESubscriptionType.SELLER_APPSUMO_TIER_2,
      status: (subscription?.type === ESubscriptionType.SELLER_APPSUMO_TIER_2) ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE,
    },
    {
      title: 'License Tier 3',
      features: [
        `50 ${t('Subscriptions_users')}`,
        t('Subscriptions_Everything_in_Tier_1'),
        t('Subscriptions_Advanced_Analytics'),
        t('Subscriptions_Integrations_future_included'),
        t('Subscriptions_Stageset_AI'),
        t('Subscriptions_StagesetSign'),
      ],
      description: [
        {
          title: 'Month',
          value: {
            value: '$259',
            perUser: false,
          },
        },
      ],
      btnTitle: t('Subscriptions_Get_started'),
      type: ESubscriptionType.SELLER_APPSUMO_TIER_3,
      status: (subscription?.type === ESubscriptionType.SELLER_APPSUMO_TIER_3) ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE,
    },
  ];

  return {
    appSumoSubscriptions,
    checkIfCurrentSubscriptionIsAppSumo,
    checkIfSubscriptionActive,
    currentPlanObj,
    formatDate,
    formNextPaymentData,
    isAppSumoSubscription,
    isLoading,
    nextInvoice: nextInvoice as StripeInvoice,
    onPremiumSubscriptionStart,
    onSubscriptionPlanChange,
    onSubscriptionStatusUpdate,
    subscription,
    subscriptions,
    title,
  };
};
