import {
  Avatar, Box, Popover, styled,
} from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const OwnerCardStyledAvatar = styled<any>(Avatar)(() => ({
  height: '40px',
  minHeight: '40px',
  width: '40px',
  minWidth: '40px',
  fontSize: '16px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  '&.avatar-with-clear-icon': {
    border: 'none',
    backgroundColor: 'transparent',
    color: stagesetColors.newGrey[800],
    stroke: stagesetColors.newGrey[800],
  },
  '&.avatar-with-clear-icon-black': {
    color: stagesetColors.white[100],
    stroke: stagesetColors.white[100],
  },
}));

export const OwnerContactInfoWrapper = styled<any>(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const OwnerContactsPopover = styled<any>(Popover)(() => ({
  boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04),'
    + '0px 1px 1px rgba(0, 113, 190, 0.07),'
    + '0px 0px 1px rgba(0, 113, 190, 0.08),'
    + '0px 0px 0px rgba(0, 113, 190, 0.08)',
  '&.owner-contacts-popover-mobile': {
    zIndex: 99,
    top: '-42px',
    '& .MuiBackdrop-root': {
      height: '100vh',
      width: '100vw',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    '& .MuiPaper-root': {
      zIndex: 100,
      width: 'calc(100vw - 16px)',
    },
  },
  '&.owner-contacts-popover-desktop': {
    marginTop: '-22px',
    '& .MuiPaper-root': {
      borderRadius: '8px ',
    },
  },
}));
