import { SvgIcon, SvgIconProps } from '@mui/material';

export const BroomIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      fill="none"
      d="M9.875 1.625C9 1.625 8.375 2.25 8.375
      3.125V8C8.375 8.125 8.25 8.25 8.125
      8.25H2.75C2.625 8.25 2.5 8.375 2.625
      8.625L3.625 11.875V12L2.5 17.25C2.5
      17.375 2.625 17.5 2.75 17.5H17.25C17.375
      17.5 17.5 17.375 17.5 17.25L16.375
      12V11.875L17.5 8.625C17.5 8.5 17.375
      8.25 17.25 8.25H11.75C11.625 8.25 11.5
      8.125 11.5 8V3.125C11.5 2.25 10.75
      1.625 9.875 1.625Z"
      strokeWidth="1.6"
      strokeMiterlimit="10"
    />
    <path
      d="M3.625 11.875H16.375"
      strokeWidth="1.6"
      strokeMiterlimit="10"
    />
    <path
      d="M10 17.5V14.625"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.875 17.5V15.875"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.125 17.5V15.875"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
