// @ts-ignore
import { SvgIcon } from '@mui/material';
import Logo from '../public/google-g-logo.svg';

export const GoogleGLogo = (): JSX.Element => (
  <SvgIcon
    width={24}
    height={24}
    component={Logo}
    alt=""
  />
);
