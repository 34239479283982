import { Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TabContext } from '@mui/lab';
import { EAdminFeedTab } from './helpers/AdminHelpers';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectCurrentAdminFeedTab, setAdminFeedTab } from './state/adminSlice';
import OrganizationFeedTab from './feed-tabs/organizations/OrganizationFeedTab';

const AdminFeed = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentFeedTab: EAdminFeedTab = useAppSelector(selectCurrentAdminFeedTab);
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(setAdminFeedTab(EAdminFeedTab.none));
  };

  useEffect(() => {
    switch (currentFeedTab) {
      case EAdminFeedTab.none:
        setOpen(false);
        break;
      case EAdminFeedTab.organizationDetails:
        setOpen(true);
        break;
      default:
        setOpen(false);
        break;
    }
  }, [currentFeedTab]);

  return (
    <Drawer
      anchor="right"
      open={open}
      variant={isMobile ? 'persistent' : 'temporary'}
      onClose={toggleDrawer()}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '500px',
          overflow: 'hidden',
          position: isMobile ? 'absolute' : 'fixed',
        },
      }}
      sx={{
        display: 'flex',
        overflow: 'hidden',
        zIndex: 1300,
      }}
    >
      <TabContext
        value={currentFeedTab}
      >
        <OrganizationFeedTab />
      </TabContext>
    </Drawer>
  );
};

export default AdminFeed;
