import {
  Box,
  SvgIcon,
} from '@mui/material';
import * as React from 'react';
// import { useSnackbar } from 'notistack';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../common/DeleteTypography';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import Edit from '../../icons/Edit24.svg';
import Replace from '../../icons/Refresh20.svg';
import Download from '../../icons/download20px.svg';
import Trash from '../../icons/Trash.svg';
import { ThreeDotsVert20px } from '../../icons/ThreeDots';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import { FileWithOwnerDTO, ProductWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import { libraryTypeToItemDescription } from './helpers/library.helper';
import { deleteFileItem, deletePriductItem, selectLibraryGridToRender } from './lib/librarySlice';
import { EFileEditType, EFileType, ELibraryType } from '../../../shared/library/Library';
import { LibraryApi } from '../../api/LibraryApi';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';

interface Props {
  item: FileWithOwnerDTO | ProductWithOwnerDTO;
  onCreateFileItemDialogOpen: (item?: FileWithOwnerDTO, editType?: EFileEditType) => void;
  onCreateProductItemDialogOpen: (item?: ProductWithOwnerDTO) => void;
}

const LibraryGridItemMenu = ({
  item,
  onCreateFileItemDialogOpen,
  onCreateProductItemDialogOpen,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const libraryApi: LibraryApi = new LibraryApi();

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    if (gridToRender === ELibraryType.PRODUCT) {
      onCreateProductItemDialogOpen(item as ProductWithOwnerDTO);
    } else {
      onCreateFileItemDialogOpen(item as FileWithOwnerDTO, EFileEditType.EditName);
    }
    handleClose();
  };

  const handleDownloadClick = async (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    if ((item as FileWithOwnerDTO).type !== EFileType.FILE || !(item as FileWithOwnerDTO).link) {
      return;
    }
    try {
      const response = await libraryApi.getDownloadFile((item as FileWithOwnerDTO).id);
      if (!response) {
        throw new Error('Network response was not ok');
      }

      const blob = await response;

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const { link } = (item as FileWithOwnerDTO);
      const linkExtension = link.substring(link.lastIndexOf('.') + 1);
      let fileName = (item as FileWithOwnerDTO).name || 'downloaded_file';
      const fileNameExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (linkExtension !== fileNameExtension || fileName.lastIndexOf('.') < 0) {
        fileName = `${fileName}.${linkExtension}`;
      }
      a.download = fileName;

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('There was an error downloading the file:', error);
    }
    handleClose();
  };

  const handleReplaceClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    onCreateFileItemDialogOpen(item as FileWithOwnerDTO, EFileEditType.EditFile);
    handleClose();
  };

  const handleDeleteClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setDeleteDialogOpen(true);
    handleClose();
  };

  const onItemDelete = (): void => {
    setDeleteDialogOpen(false);
    if (!item?.id) {
      return;
    }
    if (gridToRender === ELibraryType.PRODUCT) {
      dispatch(deletePriductItem(item.id));
    } else {
      dispatch(deleteFileItem(item.id));
    }
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to delete
      <b>
        &nbsp;
        {item?.name}
      </b>
      ?
    </DeleteTypography>
  );

  const linkItems = [
    {
      label: 'Edit',
      onClick: handleEditClick,
      startIcon: <SvgIcon
        component={Edit}
        sx={{
          color: 'transparent',
          width: '20px',
          height: '20px',
        }}
      />,
    },
  ];

  const productItems = [
    {
      label: 'Edit',
      onClick: handleEditClick,
      startIcon: <SvgIcon
        component={Edit}
        sx={{
          color: 'transparent',
          width: '20px',
          height: '20px',
        }}
      />,
    },
  ];

  const fileItems = [
    {
      label: 'Rename',
      onClick: handleEditClick,
      startIcon: <SvgIcon
        component={Edit}
        sx={{
          color: 'transparent',
          width: '20px',
          height: '20px',
        }}
      />,
    },
    {
      label: 'Download',
      onClick: handleDownloadClick,
      startIcon: <SvgIcon
        component={Download}
        sx={{
          color: 'transparent',
          width: '20px',
          height: '20px',
        }}
      />,
    },
    {
      label: 'Replace',
      onClick: handleReplaceClick,
      startIcon: <SvgIcon
        component={Replace}
        sx={{
          color: 'transparent',
          width: '20px',
          height: '20px',
        }}
      />,
    },
  ];

  const getMenuItems = () => {
    if (gridToRender === ELibraryType.PRODUCT) {
      return productItems;
    }
    if ((item as FileWithOwnerDTO)?.type === EFileType.FILE) {
      return fileItems;
    }
    return linkItems;
  };

  return (
    <Box>
      <AppIconButton
        id="seller-deals-more-menu"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleOpen}
        transparentStroke
        icon={<ThreeDotsVert20px />}
      />
      <AppDropdown
        items={[
          ...getMenuItems(),
          {
            label: '',
            divider: true,
          },
          {
            label: 'Delete',
            onClick: handleDeleteClick,
            startIcon: <Trash />,
          },
        ]}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocus
        disableAutoFocusItem
        lastElementHasAnotherColor
      />
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={isDeleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={onItemDelete}
        dialogContentText={deleteTypography}
        dialogTitle={`Delete ${libraryTypeToItemDescription(
          gridToRender,
          gridToRender === ELibraryType.FILE ? (item as FileWithOwnerDTO)?.type : undefined,
        )}`}
      />
    </Box>
  );
};

export default LibraryGridItemMenu;
