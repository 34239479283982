import {
  Grid, InputAdornment, SvgIcon, TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FilterCategory, FilterSet } from '../../../../../shared/process/ProcessFilter';
import SearchActive from '../../../../icons/SearchActive.svg';
import Minimiz from '../../../../icons/DealsFiltersInput/Minimiz.svg';
import Save from '../../../../icons/DealsFiltersInput/Save.svg';
import Add from '../../../../icons/DealsFiltersInput/Add.svg';
import Reset from '../../../../icons/DealsFiltersInput/Reset.svg';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { AppDropdown } from '../../../../shared/AppDropdown/AppDropdown';
import { FilterAutocomplete } from './FilterAutocomplete';
import { useGridFilter } from '../../../../hooks/useGridFilter';
import {
  getProcessesFilterInfo, setExpanded, setManageFilterSetsDialogStatus, setNewFilterSetDialogStatus,
} from '../lib/dealsGridSlice';
import { AppDispatch } from '../../../../core/store/store';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { MobileTabs } from './MobileTabs/MobileTabs';
import { useGetSavedFilterSetsQuery, useSaveFilterSetMutation } from '../api/dealFilterApi';
import NewFilterSetDialog from './NewFilterSetDialog';
import ManageFilterSetsDialog from './ManageFilterSetsDialog';
import { selectGridToRender, selectProcessesLoadingJobs } from '../../../../routes-old/deals/state/dealsSlice';
import { AppLoader } from '../../../../shared/AppLoader/AppLoader';

const DealsGridFilterInput = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const gridToRender = useAppSelector(selectGridToRender);
  const processesLoadingJobs = useAppSelector(selectProcessesLoadingJobs);

  const {
    data: savedFilters,
  } = useGetSavedFilterSetsQuery({ grid: gridToRender });
  const [
    saveFilter,
  ] = useSaveFilterSetMutation();

  const {
    expanded,
    removeFilter,
    anchorEl,
    handleSearchChange,
    getPopupItems,
    mobileIndex,
    popupCategory,
    removeMultipleFilter,
    closeFilter,
    newFilterSetDialogStatus,
    manageFilterSetsDialogStatus,
    openMenu,
    closeMenu,
    updateSearch,
    updateFilterParams,
    filterParams,
    debouncedGridFilters,
    debouncedFilterSearch,
    search,
  } = useGridFilter({ savedFilters });

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (!Object.keys(filterParams).length) {
      dispatch(setExpanded(false));
    } else if (isMobile) {
      dispatch(setExpanded(true));
    }
  }, [filterParams]);

  useEffect(() => {
    // updateFilterParams({});
    updateSearch('');
  }, []);

  useEffect(() => {
    dispatch(getProcessesFilterInfo());
  }, [debouncedGridFilters, debouncedFilterSearch]);

  const handleFilterSave = async (filterSet: FilterSet) => {
    await saveFilter(filterSet);
    enqueueSnackbar('The filter set has been saved', {
      variant: 'success',
    });
  };

  return (
    <>
      <Grid
        id="deals-headers-filter"
        container
        p={0.5}
        sx={{
          height: expanded ? 'calc(100%)' : '30px',
          transition: 'all 0.3s ease-in-out',
          borderRadius: '10px',
          border: (theme) => `1px solid ${theme.palette.grey[200]}`,
          background: '#fff',
          boxSizing: 'content-box',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            '& .MuiInputAdornment-positionEnd': {
              height: 'auto',
            },
          }}
        >
          <Grid item xs={expanded ? 12 : true} p={0}>
            <TextField
              fullWidth
              value={search}
              autoComplete="off"
              onChange={handleSearchChange}
              variant="outlined"
              placeholder={t('Overview_Search')}
              sx={{
                paddingLeft: '12px',
              }}
              InputProps={{
                sx: {
                  border: 'none',
                  padding: '0px !important',
                  '& input': {
                    padding: '0px !important',
                    height: '30px',
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      component={SearchActive}
                      sx={{
                        color: 'transparent',
                        position: 'relative',
                        top: '1px',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: expanded && Object.values(filterParams).length ? (
                  <InputAdornment
                    position="end"
                  >
                    {!isMobile && <AppIconButton onClick={() => closeFilter()} icon={<Minimiz />} />}
                  </InputAdornment>
                ) : (
                  <InputAdornment
                    position="end"
                  >
                    <AppButton
                      autoWidth
                      onClick={(e) => {
                        openMenu(e.currentTarget);
                      }}
                      startIcon={<Add />}
                      sx={{
                        color: (theme) => theme.palette.grey[400],
                        stroke: (theme) => theme.palette.grey[400],
                        position: 'relative',
                        top: '-1px',
                      }}
                    >
                      {t('Overview_Filters')}
                    </AppButton>
                  </InputAdornment>
                ),
              }}
            />

          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              display: processesLoadingJobs <= 0 ? 'none' : 'flex',
              flexDirection: 'row',
            }}
          >
            <AppLoader
              size={32}
            />
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              display: expanded || !Object.values(filterParams).length ? 'none' : 'flex',
              flexDirection: 'row',
            }}
          >
            <FilterAutocomplete
              filterParams={filterParams}
              preview
              hidden={expanded}
              onDelete={(category: FilterCategory) => removeFilter(category)}
              expanded={expanded}
              setExpanded={(val) => dispatch(setExpanded(val))}
              onChipClick={(e, category) => {
                openMenu(e.currentTarget, category);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FilterAutocomplete
            filterParams={filterParams}
            hidden={!expanded}
            onDelete={(category) => removeFilter(category)}
            expanded={expanded}
            setExpanded={(val) => dispatch(setExpanded(val))}
            onChipClick={(e, category) => {
              openMenu(e.currentTarget, category);
            }}
          />
        </Grid>

        <Grid
          container
          mt={1}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            md={4}
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <AppButton
              autoWidth
              startIcon={<Add />}
              transparentStroke
              sx={{
                color: (theme) => theme.palette.grey[400],
                stroke: (theme) => theme.palette.grey[400],
              }}
              onClick={(e) => {
                openMenu(e.currentTarget);
              }}
            >
              Add
            </AppButton>
            <AppButton
              autoWidth
              onClick={() => dispatch(setNewFilterSetDialogStatus(true))}
              startIcon={<Save />}
              transparentStroke
              sx={{
                color: (theme) => theme.palette.grey[400],
                stroke: (theme) => theme.palette.grey[400],
              }}
            >
              Save
            </AppButton>
          </Grid>
          <Grid
            item
            md={8}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <AppButton
              autoWidth
              variant="danger-transparent"
              startIcon={<Reset />}
              onClick={() => {
                updateFilterParams({});
                closeFilter();
              }}
            >
              Reset
            </AppButton>
          </Grid>
        </Grid>
      </Grid>
      {
        isMobile
          ? (
            <MobileTabs
              categoryTab={mobileIndex}
            />
          )
          : (
            <AppDropdown
              hideNavigation
              searchType="multiselect"
              onChipDelete={(item) => {
                if (!popupCategory) return;
                removeMultipleFilter({
                  category: popupCategory,
                  value: item.value as string,
                });
              }}
              sx={{
                maxHeight: '400px',
                maxWidth: '250px',
                '& li': {
                  height: '50px',
                  width: '100%',
                },
                '& .app-dropdown-label': {
                  width: '150px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              }}
              autoSize
              preventFallback
              items={getPopupItems()}
              disableAutoFocus
              disableAutoFocusItem
              id="action-more-menu"
              anchorPosition={anchorEl && {
                top: anchorEl.top + anchorEl.height,
                left: anchorEl.left,
              }}
              open={!!anchorEl}
              onMouseDown={(e) => e.stopPropagation()}
              onClose={() => {
                closeMenu();
              }}
              anchorReference="anchorPosition"
            />
          )
      }
      <NewFilterSetDialog
        open={newFilterSetDialogStatus}
        setOpen={(status) => dispatch(setNewFilterSetDialogStatus(status))}
        onSubmit={({ name }) => handleFilterSave({ name, dealType: gridToRender, filterParams })}
      />
      <ManageFilterSetsDialog
        open={manageFilterSetsDialogStatus}
        setOpen={(status) => dispatch(setManageFilterSetsDialogStatus(status))}
      />
    </>
  );
};

export default DealsGridFilterInput;
