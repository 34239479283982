import { SvgIcon, SxProps, useTheme } from '@mui/material';

interface Props {
  className?: string;
  sx?: SxProps;
}

const CalendarIcon = ({ className, sx }: Props): JSX.Element => (
  <SvgIcon
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="10px"
    height="10px"
    viewBox="0 0 18 18"
    sx={{
      width: '17px',
      height: '17px',
      ...sx,
    }}
  >
    <path d="M13.168 2.4987H16.5013C16.7223 2.4987
    16.9343 2.5865 17.0906 2.74278C17.2468 2.89906
    17.3346 3.11102 17.3346 3.33203V16.6654C17.3346
    16.8864 17.2468 17.0983 17.0906 17.2546C16.9343
     17.4109 16.7223 17.4987 16.5013 17.4987H1.5013C1.28029
     17.4987 1.06833 17.4109 0.912046 17.2546C0.755766 17.0983
     0.667969 16.8864 0.667969 16.6654V3.33203C0.667969 3.11102
     0.755766 2.89906 0.912046 2.74278C1.06833 2.5865 1.28029
     2.4987 1.5013 2.4987H4.83464V0.832031H6.5013V2.4987H11.5013V0.832031H13.168V2.4987ZM2.33464
     7.4987V15.832H15.668V7.4987H2.33464ZM4.0013
     9.16536H5.66797V10.832H4.0013V9.16536ZM4.0013
     12.4987H5.66797V14.1654H4.0013V12.4987ZM7.33464
     9.16536H14.0013V10.832H7.33464V9.16536ZM7.33464
     12.4987H11.5013V14.1654H7.33464V12.4987Z"
    />
  </SvgIcon>
);

export const CalendarIcon18px = (): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
    x="0px"
    y="0px"
    width="10px"
    height="10px"
    viewBox="0 0 15 22"
    sx={{
      width: '18px',
      height: '18px',
    }}
      fill="none"
    >
      <path
        d="M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5
5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667
18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5
16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333
3.3335Z"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.3334 1.6665V4.99984"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M6.66663 1.6665V4.99984"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M2.5 8.3335H17.5"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export const CalendarIcon20px = (): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5
5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667
18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5
16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333
3.3335Z"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.3334 1.6665V4.99984"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M6.66663 1.6665V4.99984"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M2.5 8.3335H17.5"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default CalendarIcon;
