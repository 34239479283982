import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { IShareJWTTokenPayload } from '../../shared/schema/token.schema';
import { EWSDealEvents } from '../../shared/websocket/websocket';
import { config } from '../core';
import { useLazyGetTaskByIdQuery } from '../features/Tasks/lib/tasksApi';
import {
  getProcessAccessLink, getProcessFields, getProcessFiles, getProcessGuests, getProcessUsers,
} from '../routes-old/process/state/dealActions';
import { setOnlineUsers } from '../routes-old/process/state/processSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';
import {
  addItemFile,
  getProcessItem,
  selectCurrentStageId,
  setItem,
  setSocketId,
  setStageUsers,
} from '../features/ProcessFields/lib/processItemsSlice';
import { AdminWSCommands } from '../../shared/admin/Commands';
import useEditorEvent from '../features/Tiptap/Hooks/useTiptapEditorEvents';
import { TiptapCustomEvents } from '../features/Tiptap/TiptapEventListnerPlugin';

const useDealWebsocket = (dealId: string) => {
  const token = useAppSelector((state) => state.auth?.authToken);
  const [userId, setUserId] = useState<string>();
  const [socket, setSocket] = useState<undefined | Socket>(undefined);
  const currentStageId = useAppSelector(selectCurrentStageId);
  const { dispatchEditorEvent } = useEditorEvent();

  useEffect(() => {
    const { id }: IShareJWTTokenPayload = jwtDecode<IShareJWTTokenPayload>(token);
    if (id) {
      setUserId(id);
    }
  }, [token]);

  const dispatch = useAppDispatch();

  const [refetchTask] = useLazyGetTaskByIdQuery();

  const onWsMessage = (type: EWSDealEvents | AdminWSCommands, payload: any): void => {
    switch (type) {
      case EWSDealEvents.ONLINE_USERS:
        dispatch(setOnlineUsers(payload));
        break;
      case EWSDealEvents.NEW_GUEST:
        dispatch(getProcessGuests(dealId));
        break;
      case EWSDealEvents.TASK_UPDATED: {
        if (payload?.producerId !== userId && payload?.taskId) {
          refetchTask({ processId: dealId, id: payload.taskId });
        }
        break;
      }
      case EWSDealEvents.FIELD_UPDATED: {
        if (payload?.producerId !== userId && payload?.fieldId) {
          dispatch(setItem(payload.itemData));
        }
        break;
      }
      case EWSDealEvents.PROCESS_FIELDS_UPDATED: {
        if (payload?.producerId !== userId) {
          dispatch(getProcessFields());
          if (payload?.data?.fieldId) {
            dispatch(getProcessItem(payload.data.fieldId));
          }
        }
        break;
      }
      case EWSDealEvents.USERS_UPDATED: {
        if (payload?.producerId !== userId) {
          dispatch(getProcessUsers(dealId));
        }
        break;
      }
      case EWSDealEvents.FILES_UPDATED: {
        if (payload?.producerId !== userId) {
          dispatch(getProcessFiles());
        }
        break;
      }
      case EWSDealEvents.ACCESS_LINK_UPDATED: {
        dispatch(getProcessAccessLink(dealId));
        break;
      }
      case EWSDealEvents.ITEM_FILE_UPLOADED: {
        if (payload?.producerId !== userId) {
          const { data: { fileId, fileData }, entityId } = payload;
          dispatch(addItemFile({ fileData, fileId, entityId }));
        }
        break;
      }
      case EWSDealEvents.UPDATE_STAGE_USERS: {
        dispatch(setStageUsers(payload));
        break;
      }
      case AdminWSCommands.FORCE_STAGE_ITEMS_UPDATE: {
        dispatchEditorEvent(
          {
            eventType: TiptapCustomEvents.FORCE_UPDATE,
            newContent: payload.newItems,
            stageId: payload.stageId,
          },
        );
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (userId && dealId !== 'share') {
      const s = io(config.api.wsHref, {
        query: {
          roomId: dealId,
          token,
          userId,
        },
      });
      s.connect();
      s.onAny((message: EWSDealEvents, event: any) => {
        onWsMessage(message, event);
      });
      setSocket(s);
      return (): void => {
        s.disconnect();
      };
    }
  }, [dealId, userId, token]);

  useEffect(() => {
    if (currentStageId && socket) {
      dispatch(setSocketId(socket.id ?? ''));
      socket?.emit(
        'stageUsersUpdate',
        {
          type: 'enter',
          stageId: currentStageId,
          processId: dealId,
          userId,
        },
      );
    }
    return () => {
      if (currentStageId && socket) {
        dispatch(setSocketId(''));
        socket?.emit(
          'stageUsersUpdate',
          {
            type: 'leave',
            stageId: currentStageId,
            processId: dealId,
            userId,
          },
        );
      }
    };
  }, [currentStageId, socket]);
};

export default useDealWebsocket;
