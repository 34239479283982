import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Collapse,
  Dialog,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { EProcessBackgroundStorageType, EProcessBackgroundType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { AppSwitch } from '../../shared/AppSwitch/AppSwitch';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import useProcessBackground from '../../hooks/useProcessBackground';
import { uploadBackground } from '../../routes-old/process/state/dealActions';
import { selectOrganizationId, selectUserId } from '../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectProcess } from '../../routes-old/process/state/processSlice';
import ImageCrop from '../ImageCrop/ImageCrop';
import {
  CustomizationProcessStyles,
  selectLogoDisplayStatus,
  selectProcessBackgroundColor,
  selectProcessBackgroundInitialColor,
  selectProcessDealStyles,
  setDealStyles,
  setLogoDisplayStatus,
} from './lib/dealCustomizationSlice';
import { CustomizationMenuSubtitle, CustomizationSwitchTitle, CustomizationSwitchWrapper } from './Styles/DealCustomizationMenuStyles';
import { stagesetColors } from '../../theme/stagesetPalette';
import BackgroundColorLayerSelector from './BackgroundColorLayerSelector';
import CustomBackgroundUpload from './CustomBackgroundUpload';
import CustomBackgroundPreview from './CustomBackgroundPreview';

const DealCustomizationBannerSection = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const process = useAppSelector(selectProcess);
  const organizationId = useAppSelector(selectOrganizationId);
  const userId = useAppSelector(selectUserId);
  const [loading, setLoading] = useState(false);
  const [tempFile, setTempFile] = useState<File | undefined>();
  const displayLogo = useAppSelector(selectLogoDisplayStatus);
  const initialBannerColor = useAppSelector(selectProcessBackgroundInitialColor);
  const bannerColor = useAppSelector(selectProcessBackgroundColor);

  const {
    onBackgroundDisplayUpdate,
    display: displayBackground,
    url,
    background,
    onBackgroundDataUpdate,
    onBackgroundTypeUpdate,
  } = useProcessBackground();

  const [backgroundtab, setBackgroundTab] = useState<EProcessBackgroundType>(process?.styles?.type ?? EProcessBackgroundType.IMG);
  const dealStyles = useAppSelector(selectProcessDealStyles);

  const onBannerColorUpdate = (color: string) => {
    const styles: CustomizationProcessStyles = { ...dealStyles };
    styles.backgroundColor = color;
    dispatch(setDealStyles(styles));
  };

  const onNewBackgroundSelect = (
    backgroundStorage: EProcessBackgroundStorageType,
    type: EProcessBackgroundType,
    localId?: number,
    newUrl?: string,
    display? : boolean,
  ) => {
    onBackgroundDataUpdate({
      localId: localId ?? background,
      type,
      backgroundStorage,
      displayBackground: display ?? displayBackground,
      url: newUrl,
    });
  };

  const onUrlClear = () => {
    onBackgroundDataUpdate({
      url: '',
    });
  };

  const onCustomBackgroundUpdate = async (file: File): Promise<void> => {
    setTempFile(file);
  };

  const handleTabChange = (val: EProcessBackgroundType) => {
    onBackgroundTypeUpdate(val);
    setBackgroundTab(val);
  };

  const handleLogoDisplayUpdate = (val: boolean) => {
    dispatch(setLogoDisplayStatus(val));
  };

  const onCustomBackgroundUpload = async (file: File): Promise<void> => {
    try {
      setLoading(true);
      const updatedProcess = await dispatch(uploadBackground({
        file,
        processId: process?.id,
        userId: userId || '',
        organizationId,
      })).unwrap();
      setTempFile(undefined);
      setLoading(false);
      onBackgroundDataUpdate({
        url: updatedProcess.styles.url,
        displayBackground: true,
      });
      if (file?.size) {
        onBackgroundTypeUpdate(EProcessBackgroundType.IMG);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onAWSBackgroundSelect = () => {
    onNewBackgroundSelect(
      EProcessBackgroundStorageType.AWS,
      EProcessBackgroundType.IMG,
      background,
      url,
      true,
    );
  };

  return (
    <>
      <CustomizationMenuSubtitle>
        Header
      </CustomizationMenuSubtitle>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <CustomizationSwitchWrapper>
            <AppSwitch
              checked={displayBackground}
              onChange={() => onBackgroundDisplayUpdate(!displayBackground)}
            />
            <CustomizationSwitchTitle>
              Show banner
            </CustomizationSwitchTitle>
          </CustomizationSwitchWrapper>
          <Collapse
            in={displayBackground}
            sx={{
              '& .MuiCollapse-wrapperInner': {
                marginTop: '16px',
              },
            }}
          >
            <AppTabs
              options={[
                { title: 'Image', value: EProcessBackgroundType.IMG },
                { title: 'Color', value: EProcessBackgroundType.COLOR },
              ]}
              value={backgroundtab}
              onChange={(option) => {
                handleTabChange(option.value);
              }}
              color="white"
              variant="boxedNew"
              size="m-new"
            />
            <TabContext
              value={backgroundtab}
            >
              <TabPanel
                value={EProcessBackgroundType.IMG}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  padding: 0,
                  marginTop: '12px',
                }}
              >
                {url ? (
                  <CustomBackgroundPreview
                    background={url}
                    onClick={() => onAWSBackgroundSelect()}
                    onDelete={onUrlClear}
                    onNewUpload={onCustomBackgroundUpdate}
                    loading={loading}
                  />
                ) : (
                  <CustomBackgroundUpload
                    isVisible={!url}
                    onUpload={(files) => onCustomBackgroundUpdate(files[0])}
                    loading={loading}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    color: stagesetColors.newGrey[400],
                  }}
                >
                  Ideal format is SVG file type with aspect ratio 1600х366px
                </Typography>
              </TabPanel>
              <TabPanel
                value={EProcessBackgroundType.COLOR}
                sx={{
                  padding: '0px',
                }}
              >
                <BackgroundColorLayerSelector
                  key="Banner"
                  initialColor={initialBannerColor}
                  currentColor={bannerColor ?? stagesetColors.white[100]}
                  defaultColor={stagesetColors.blue[600]}
                  onColorUpdate={(newColor) => onBannerColorUpdate(newColor)}
                />
              </TabPanel>
            </TabContext>
          </Collapse>
        </Box>
        <CustomizationSwitchWrapper>
          <AppSwitch
            checked={displayLogo}
            onChange={(_, checked) => handleLogoDisplayUpdate(checked)}
          />
          <CustomizationSwitchTitle>
            Show logo
          </CustomizationSwitchTitle>
        </CustomizationSwitchWrapper>
      </Box>
      <Dialog
        open={!!tempFile && !loading}
        onClose={() => setTempFile(undefined)}
        keepMounted={false}
        PaperProps={{
          sx: {
            width: 'auto',
            maxHeight: '80vh',
            height: 'auto',
            padding: '24px',
          },
        }}
      >
        <ImageCrop
          image={tempFile}
          onCropAccept={onCustomBackgroundUpload}
          onCancel={() => setTempFile(undefined)}
        />
      </Dialog>
    </>
  );
};

export default DealCustomizationBannerSection;
