import {
  Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import * as React from 'react';
import { preventFeedClosure } from '../../features/DealFeed/lib/feedSlice';
import { useAppDispatch } from '../../hooks/stateHooks';
import { useBlure } from '../../hooks/useBlure';
import { StyledDialogActions } from './ConfirmationDialogStyle';
import { AppButton } from '../../shared/AppButton/AppButton';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppBaseButtonVariant } from '../../shared/AppButton/AppBaseButton';

interface Props {
  isDialogOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  dialogContentText: JSX.Element;
  icon?: JSX.Element;
  dialogTitle: string;
  titleVariant?: AppBaseButtonVariant;
  confirmButtonVariant?: AppBaseButtonVariant;
  confirmText?: string;
  cancelText?: string;
  hideCancel?: boolean;
}

const ConfirmationDialog = ({
  isDialogOpen,
  dialogContentText,
  onConfirm,
  onCancel,
  dialogTitle,
  icon,
  titleVariant = 'primary',
  confirmText,
  cancelText,
  confirmButtonVariant,
  hideCancel,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  useBlure(isDialogOpen);

  React.useEffect(() => {
    if (isDialogOpen) {
      dispatch(preventFeedClosure(true));
    }
    return () => {
      if (isDialogOpen) {
        dispatch(preventFeedClosure(false));
      }
    };
  }, [isDialogOpen]);

  return (
    <Dialog
      sx={{
        height: 'auto',
        '& .MuiDialog-scrollPaper': {
          alignItems: 'flex-start',
        },
      }}
      open={isDialogOpen}
      onClose={onCancel}
      className="ConfirmationDialog"
      hideBackdrop
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onBackdropClick={(e) => {
        e.stopPropagation();
      }}
      PaperProps={{
        id: 'confirmation-dialog',
        onClick: (e) => {
          e.stopPropagation();
        },
        sx: {
          boxShadow: '0px 0px 18px -2px rgba(30, 41, 59, 0.10),'
            + '0px 0px 3px 0px rgba(30, 41, 59, 0.10)',
          borderRadius: '16px',
          margin: '40px',
        },
      }}
    >
      <DialogContent sx={{
        height: 'auto',
        width: '350px',
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 600,
            padding: 0,
            gap: '12px',
            color: titleVariant === 'primary' ? stagesetColors.newGrey[800] : stagesetColors.red[600],
          }}
        >
          <>
            {icon}
            {dialogTitle}
          </>
        </DialogTitle>
        <DialogContentText
          id="dialog-content-text"
          sx={{
            overflowWrap: 'break-word',
            fontSize: '16px',
            color: stagesetColors.newGrey[800],
            lineHeight: '24px',
          }}
        >
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        {
          !hideCancel
          && (
            <AppButton
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}
              id="dialog-cancel"
              variant="transparent"
              size="l"
              autoWidth
            >
              { cancelText || 'Cancel'}
            </AppButton>
          )
        }
        <AppButton
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          id="dialog-confirm"
          autoFocus
          autoWidth
          variant={confirmButtonVariant ?? titleVariant ?? 'danger-primary'}
          size="l"
        >
          { confirmText || 'Confirm'}
        </AppButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
