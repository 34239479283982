import {
  createAsyncThunk, createSlice, PayloadAction, SerializedError,
} from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import storage from 'redux-persist/lib/storage';
import { REHYDRATE } from 'redux-persist';
import { isMobile } from 'react-device-detect';
import {
  GuestConfirmationData,
  GuestValidationData,
  IConfirmAccountRequest,
  ILoginRequest,
  IShareLoginRequest,
  ResetPasswordConfirmRequest,
  ShareLoginProtectedRequest,
} from '../../../../shared/schema/auth.shcema';
import {
  EJWTTokenType, ILoginResponse, IShareJWTTokenPayload, LoginErrorResponse,
} from '../../../../shared/schema/token.schema';
import { AuthApi } from '../../../api/AuthApi';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';

export const authReducerToken = 'auth';

const authApi = new AuthApi();

export interface IAuthState {
  status: EStateStatus;
  error?: SerializedError & LoginErrorResponse;
  isAuthenticated: boolean;
  authToken: string;
  tokenType: EJWTTokenType | null;
  email?: string;
  password: string;
  isShareSingUpOpen: boolean;
  isShareProtectedAuthDialogOpen: boolean;
  isSignupDialogOpen: boolean;
  isSharedUser: boolean | undefined;
  guestId?: string;
}

export const initialState: IAuthState = {
  status: EStateStatus.IDLE,
  isAuthenticated: false,
  authToken: '',
  tokenType: null,
  password: '',
  isShareSingUpOpen: false,
  isShareProtectedAuthDialogOpen: false,
  isSignupDialogOpen: false,
};

export enum EAuthActions {
  LOGIN = 'LOGIN',
  GUEST_VALIDATE = 'GUEST_VALIDATE',
  GUEST_CONFIRM = 'GUEST_CONFIRM',
  SIGNUP = 'SIGNUP',
  SHARE_LOGIN = 'SHARE_LOGIN',
  SHARE_LOGIN_PROTECTED = 'SHARE_LOGIN_PROTECTED',
  LOGOUT = 'LOGOUT',
  CHANGE = 'CHANGE',
  CONFIRM = 'CONFIRM',
  CONFIRM_PROCESS = 'CONFIRM_PROCESS',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_CONFIRM = 'RESET_PASSWORD_CONFIRM',
}

export const changeValue = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.CHANGE}`,
  async (payload: ILoginRequest) => {
    try {
      return payload;
    } catch (e) {
      console.log(e);
    }
  },
);

export const login = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.LOGIN}`,
  async (payload: ILoginRequest, { rejectWithValue }) => {
    try {
      const result: ILoginResponse = await authApi.login(payload);
      if (result.tokenType === EJWTTokenType.SHARE) {
        sessionStorage.setItem('token', result.access_token);
        sessionStorage.setItem('tokenType', result.tokenType);
      } else {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('tokenType', result.tokenType);
      }
      return result;
    } catch (e) {
      return rejectWithValue(e.data);
    }
  },
);

export const validateGuest = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.GUEST_VALIDATE}`,
  async (payload: GuestValidationData) => {
    try {
      const res = await authApi.validateGuest(payload);
      return res;
    } catch (e) {
      return {
        title: 'Error',
        description: 'Error',
        isValid: false,
      };
    }
  },
);

export const confirmGuest = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.GUEST_CONFIRM}`,
  async (payload: GuestConfirmationData) => {
    try {
      const res = await authApi.confirmGuest(payload);
      if (res) {
        localStorage.setItem('token', res.access_token);
        localStorage.setItem('tokenType', res.tokenType);
      }
      return res;
    } catch (e) {
      return {
        title: 'Error',
        description: 'Error',
        isValid: false,
      };
    }
  },
);

export const signup = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.SIGNUP}`,
  async (payload:{ email: string, celloReferrer?: string }, { rejectWithValue }) => {
    try {
      const res = await authApi.signup(payload, sessionStorage.getItem('joinedDeal'));
      localStorage.clear();
      sessionStorage.clear();
      storage.removeItem('persist:auth');
      storage.removeItem('persist:root');
      return res;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.data);
    }
  },
);

export const resetPassword = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.RESET_PASSWORD}`,
  async (payload: string) => {
    try {
      return await authApi.resetPassword(payload);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
);

export const logout = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.LOGOUT}`,
  async () => {
  },
);

export const confirmAccount = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.CONFIRM}`,
  async (payload: IConfirmAccountRequest) => {
    try {
      const pload = { ...payload };
      if (pload.joinedDeal === null) pload.joinedDeal = sessionStorage.getItem('joinedDeal');
      if (pload.joinedDeal === null) {
        delete pload.joinedDeal;
      }
      const result = await authApi.confirmAccount(pload);
      sessionStorage.removeItem('joinedDeal');
      if (result.tokenType === EJWTTokenType.SHARE) {
        sessionStorage.setItem('token', result.access_token);
        sessionStorage.setItem('tokenType', result.tokenType);
      } else {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('tokenType', result.tokenType);
      }
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
);

export const resetPasswordConfirm = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.RESET_PASSWORD_CONFIRM}`,
  async (payload: ResetPasswordConfirmRequest) => {
    try {
      return await authApi.resetPasswordConfirm(payload);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
);

export const confirmProcessAccount = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.CONFIRM_PROCESS}`,
  async (payload: IConfirmAccountRequest) => {
    try {
      const result = await authApi.confirmProcessAccount(payload);
      if (result.tokenType === EJWTTokenType.SHARE) {
        sessionStorage.setItem('token', result.access_token);
        sessionStorage.setItem('tokenType', result.tokenType);
      } else {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('tokenType', result.tokenType);
      }
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
);

export const shareLogin = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.SHARE_LOGIN}`,
  async (payload: IShareLoginRequest) => {
    try {
      const result = await authApi.shareLogin(payload);
      sessionStorage.setItem('token', result.access_token);
      sessionStorage.setItem('tokenType', result.tokenType);
      if (result.userId) {
        sessionStorage.setItem('showPolicyDisclaimer', 'true');
        sessionStorage.setItem('ws-uid', result.userId);
      }
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
);

export const shareLoginProtected = createAsyncThunk(
  `${authReducerToken}/${EAuthActions.SHARE_LOGIN_PROTECTED}`,
  async (payload: ShareLoginProtectedRequest) => {
    try {
      const result = await authApi.shareLoginProtected(payload);
      sessionStorage.setItem('token', result.access_token);
      sessionStorage.setItem('tokenType', result.tokenType);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
);

export const authSlice = createSlice({
  name: authReducerToken,
  initialState,
  reducers: {
    resetAuthError: (state) => {
      state.error = undefined;
      state.status = EStateStatus.IDLE;
    },
    setTokenAndTokenType: (state, { payload }: PayloadAction<ILoginResponse>) => {
      const {
        access_token,
        tokenType,
      } = payload;
      state.status = EStateStatus.IDLE;
      state.isAuthenticated = true;
      state.authToken = access_token;
      state.tokenType = tokenType;
      state.isSharedUser = tokenType === EJWTTokenType.SHARE;
      if (tokenType === EJWTTokenType.SHARE) {
        sessionStorage.setItem('token', access_token);
        sessionStorage.setItem('tokenType', tokenType);
      } else {
        localStorage.setItem('token', access_token);
        localStorage.setItem('tokenType', tokenType);
      }
    },
    setShareSingUpOpen: (state, action: PayloadAction<boolean>) => {
      state.isShareSingUpOpen = action.payload;
    },
    setIsShareProtectedAuthDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isShareProtectedAuthDialogOpen = action.payload;
    },
    setIsSignupDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSignupDialogOpen = payload;
    },
    loginSuccess: (state, action) => {
      state.status = EStateStatus.IDLE;
      state.isAuthenticated = true;
      // @ts-ignore
      state.isSharedUser = action.payload.tokenType === EJWTTokenType.SHARE;
      // @ts-ignore
      state.tokenType = action.payload.tokenType;
      // @ts-ignore
      state.authToken = action.payload.access_token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(signup.fulfilled, (state) => {
        state.status = EStateStatus.IDLE;
      })
      .addCase(signup.rejected, (state, action) => {
        const { payload } = action;
        state.status = EStateStatus.ERROR;
        // @ts-ignore
        state.error = payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = EStateStatus.IDLE;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = EStateStatus.ERROR;
        // @ts-ignore
        state.error = action.error;
      })
      .addCase(login.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(login.rejected, (state, action) => {
        const { payload } = action;
        state.status = EStateStatus.ERROR;
        // @ts-ignore
        state.error = payload;
      })
      .addCase(confirmGuest.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(confirmGuest.fulfilled, (state, action) => {
        if (action && action.payload) {
          state.isAuthenticated = true;
          // @ts-ignore
          state.isSharedUser = false;
          // @ts-ignore
          state.tokenType = action.payload.tokenType;
          // @ts-ignore
          state.authToken = action.payload.access_token;
        }
        state.status = EStateStatus.IDLE;
      })
      .addCase(confirmGuest.rejected, (state) => {
        state.status = EStateStatus.ERROR;
        // @ts-ignore
        state.error = 'Error';
      })
      .addCase(shareLogin.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(shareLogin.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isAuthenticated = true;
        state.isSharedUser = action.payload.tokenType === EJWTTokenType.SHARE;
        state.tokenType = action.payload.tokenType;
        state.authToken = action.payload.access_token;
        state.guestId = action.payload.userId;
        state.email = undefined;
      })
      .addCase(shareLogin.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(shareLoginProtected.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(shareLoginProtected.fulfilled, (state, action) => {
        const {
          email,
        }: IShareJWTTokenPayload = jwtDecode<IShareJWTTokenPayload>(action.payload.access_token);
        state.status = EStateStatus.IDLE;
        state.isAuthenticated = true;
        state.authToken = action.payload.access_token;
        state.tokenType = action.payload.tokenType;
        state.isSharedUser = false;
        state.email = email;
        state.isShareProtectedAuthDialogOpen = false;
      })
      .addCase(shareLoginProtected.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(confirmAccount.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(confirmAccount.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isAuthenticated = true;
        state.isSharedUser = action.payload.tokenType === EJWTTokenType.SHARE;
        state.tokenType = action.payload.tokenType;
        state.authToken = action.payload.access_token;
      })
      .addCase(confirmAccount.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(confirmProcessAccount.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(confirmProcessAccount.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isAuthenticated = true;
        state.isSharedUser = action.payload.tokenType === EJWTTokenType.SHARE;
        state.tokenType = action.payload.tokenType;
        state.authToken = action.payload.access_token;
      })
      .addCase(confirmProcessAccount.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.authToken = '';
        state.isSharedUser = undefined;
      })
      .addCase(REHYDRATE, (state, action) => {
        // @ts-ignore
        if (!!action && !!action.payload && action.payload.tokenType === EJWTTokenType.SHARE && !sessionStorage.getItem('token')) {
          // @ts-ignore
          action.payload.isAuthenticated = false;
          // @ts-ignore
          action.payload.authToken = '';
          // @ts-ignore
          action.payload.isSharedUser = undefined;
        }
      });
  },
});

export const {
  setShareSingUpOpen,
  setIsShareProtectedAuthDialogOpen,
  setIsSignupDialogOpen,
  loginSuccess,
} = authSlice.actions;

export const {
  resetAuthError,
  setTokenAndTokenType,
} = authSlice.actions;
export const selectIsSignupDialogOpen = (state: RootState): boolean => state.auth.isSignupDialogOpen;

export const selectAuthToken = (state: RootState): string => state.auth.authToken;
export const selectIsAuthenticated = (state: RootState): string => state.auth.isAuthenticated;
export const selectIsToolbarNotShown = (state: RootState): boolean => isMobile
  || state.auth.tokenType === EJWTTokenType.SHARE
  || state.auth.tokenType === EJWTTokenType.GUEST_AUTH;
