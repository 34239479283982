import { SnackbarKey, useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { setFeedTab, EFeedTab } from '../DealFeed/lib/feedSlice';
import {
  selectAccessLink,
  // selectAccessLink,
  selectIsOwnersTeammate,
  // selectProcessDomain,
} from '../../routes-old/process/state/processSlice';
import { EMenuTabs, selectCurrentMenuTab, selectDealViewMode } from '../Layout/lib/fieldsLayoutSlice';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
// import { CopyLinkIconSq } from '../../icons/CopyLinkIcon';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';
import { AvatarPlusIcon20 } from '../../icons/AvatarPlusIcon';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { StageButton } from './StageButton';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { AppButton } from '../../shared/AppButton/AppButton';
import { useDealTheme } from '../../hooks/useDealTheme';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';

// import { DomainHelper } from '../../core/helpers/DomainHelper';
// import { config } from '../../core';

interface Props {
  roomGeneralMobileMenu?: boolean
  toolbar?: boolean
}

const StagesSidebarInviteBtn = ({
  roomGeneralMobileMenu,
  toolbar,
}: Props) => {
  const dispatch = useAppDispatch();
  const accessLink = useAppSelector(selectAccessLink);
  const viewMode = useAppSelector(selectDealViewMode);
  const { createInviteLinkInteraction } = useFrontEventsApi();
  const isTeammate = useAppSelector(selectIsOwnersTeammate);
  const currentTab = useAppSelector(selectCurrentMenuTab);
  const [checkPermissions] = useDealPermissions();
  const canInvite = checkPermissions(
    EUserDealPermissions.DEAL_USERS_INVITE,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_MANAGE,
  ) && isTeammate;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isDeal: boolean = useAppSelector((state) => state.process?.process?.type) === EProcessType.DEAL;
  const { dealTheme: { dealOverrides: { sidebar: { contrastText } } } } = useDealTheme();
  const changeColor = contrastText === '#000000';
  const { closeDrawer } = useMobileBottomDrawer();

  let copyLinkSnackbarId: SnackbarKey | undefined;

  const handleClick = () => {
    if (canInvite) return dispatch(setFeedTab(EFeedTab.USERS));
    navigator.clipboard.writeText(accessLink.url);
    if (copyLinkSnackbarId) {
      closeSnackbar(copyLinkSnackbarId);
      copyLinkSnackbarId = undefined;
    }
    createInviteLinkInteraction();
    copyLinkSnackbarId = enqueueSnackbar(
      t('Room_Link_copied'),
      {
        variant: 'success',
      },
    );
    return null;
  };

  if (
    isMobile && (
      !currentTab
    || currentTab === EMenuTabs.TEMPLATES)
  ) {
    return (
      <Box
        sx={{
          width: '32px',
        }}
      />
    );
  }

  if (roomGeneralMobileMenu) {
    return (
      <AppButton
        size="l-flex-start"
        variant="transparent-grey-icon"
        fullWidth
        disableRipple
        startIcon={<AvatarPlusIcon20 />}
        onClick={() => {
          dispatch(setFeedTab(EFeedTab.USERS));
          closeDrawer();
        }}
      >
        Share
      </AppButton>
    );
  }

  if (isMobile && isDeal) {
    return (
      <AppButton
        variant={changeColor ? 'primary' : 'tertiary-newGrey'}
        size="m-minWidth"
        disableRipple
        onClick={handleClick}
        hidden={viewMode === 'internal'}
      >
        Share
      </AppButton>
    );
  }

  if (isMobile) {
    return (
      <AppIconButton
        disableRipple
        fullWidth
        variant="transparent"
        icon={<AvatarPlusIcon20 />}
        onClick={handleClick}
        hidden={viewMode === 'internal'}
      />
    );
  }

  if (toolbar) {
    return (
      <AppButton
        variant="tertiary-newGrey"
        size="l-minWidth"
        disableRipple
        onClick={handleClick}
        hidden={viewMode === 'internal'}
      >
        Share
      </AppButton>
    );
  }

  if (isDeal) {
    return (
      <AppButton
        variant={changeColor ? 'primary' : 'tertiary-newGrey'}
        size="l-minWidth"
        disableRipple
        onClick={handleClick}
        hidden={viewMode === 'internal'}
      >
        Share
      </AppButton>
    );
  }

  return (
    <StageButton
      disableRipple
      fullWidth
      startIcon={<AvatarPlusIcon20 />}
      onClick={handleClick}
      hidden={viewMode === 'internal'}
    >
      {t('Room_Invite')}
    </StageButton>
  );
};

export default StagesSidebarInviteBtn;
