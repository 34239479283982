import { SvgIcon } from '@mui/material';

interface Props {
  status: boolean;
  error?: boolean;
}

const PasswordSecurityIdentificatorIcon = ({
  status = false,
  error,
}: Props): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    sx={{
      fontSize: '16px',
    }}
  >
    {
      !status
      && !error
      && (
        <>
          <g clipPath="url(#clip0_8451_86672)">
            <path
              d="M8 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667
               7.99998C14.6667 4.31808 11.6819 1.33331 8 1.33331C4.3181
                1.33331 1.33334 4.31808 1.33334 7.99998C1.33334 11.6819
                4.3181 14.6666 8 14.6666Z"
              fill="none"
              stroke="#A3B0D1"
              strokeWidth="1.33"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8.00667 10.6667L8.00667 8.00002" stroke="#A3B0D1" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.00667 5.33331L8 5.33331" stroke="#A3B0D1" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_8451_86672">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    }
    {
      !status
      && error
      && (
        <>
          <g clipPath="url(#clip0_7_1778)">
            <path
              d="M7.99998 14.6667C11.6819 14.6667 14.6666
 11.6819 14.6666 8C14.6666 4.3181 11.6819
  1.33334 7.99998 1.33334C4.31808 1.33334
   1.33331 4.3181 1.33331 8C1.33331 11.6819
    4.31808 14.6667 7.99998 14.6667Z"
              fill="none"
              stroke="#EA5D3F"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 5.33334V8"
              fill="none"
              stroke="#EA5D3F"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 10.6667H8.00667"
              fill="none"
              stroke="#EA5D3F"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_7_1778">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    }
    {
      status
      && (
      <>
        <g clipPath="url(#clip0_8451_86681)">
          <path
            d="M8 14.6666C11.6819 14.6666 14.6667 11.6819
              14.6667 7.99998C14.6667 4.31808 11.6819 1.33331
               8 1.33331C4.3181 1.33331 1.33334 4.31808 1.33334
               7.99998C1.33334 11.6819 4.3181 14.6666 8 14.6666Z"
            fill="none"
            stroke="#27AE60"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8 6L6.95 10L5.2 8.18182"
            fill="none"
            stroke="#27AE60"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_8451_86681">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </>
      )
      }
  </SvgIcon>
);

export default PasswordSecurityIdentificatorIcon;
