import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  Box, Menu, MenuItem, SvgIcon, Typography,
} from '@mui/material';
import {
  $isAtNodeEnd,
} from '@lexical/selection';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection, CONTROLLED_TEXT_INSERTION_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import {
  useState, useCallback, useEffect, useMemo, useRef, MutableRefObject,
} from 'react';
import { $isLinkNode } from '@lexical/link';
import { $isHeadingNode } from '@lexical/rich-text';
import { isMobile } from 'react-device-detect';
import { KeyboardArrowRightRounded } from '@mui/icons-material';
import LexicalRTEBtnBase from './LexicalRTEToolbarBtns/LexicalRTEBtnBase';
import LexicalLinkEditor from './LexicalRTEToolbarBtns/LexicalLinkEditor';
import {
  RTEBoldIcon,
  RTEFormatCenterIcon,
  RTEFormatJustifyIcon,
  RTEFormatLeftIcon,
  RTEFormatRightIcon, RTEInsertTemplateVarIcon,
  RTEItalicIcon,
  RTELinkIcon,
  RTEOLIcon,
  RTEULIcon,
  RTEUnderlineIcon,
} from '../../icons/LexicalToolbar/LexicalToolbarIcons';
import { ELexicalBlockTypes, LexicalBlockTypes } from './LexicalRTEhelpers';
import LexicalRTEHeaderMenu from './LexicalRTEToolbarBtns/LexicalRTEHeaderMenu';
import { ThreeDotsVert20px } from '../../icons/ThreeDots';
import { AppDropdown } from '../AppDropdown/AppDropdown';
import { AppButton } from '../AppButton/AppButton';
import { renderTemplateVar, templateVars } from '../../../shared/templateVars';
import { TemplateVarDropdown } from '../../features/TemplateVarDropdown/TemplateVarDropdown';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsTemplate } from '../../routes-old/process/state/processSlice';
import CheckIcon from '../../icons/Check20.svg';
import { AppDropdownItemProps } from '../AppDropdown/AppDropdownItem';
import { MobileTabs } from './MobileTabs/MobileTabs';
import { ChevronUp20 } from '../../icons/ChevronUp';
import { ChevronDown20 } from '../../icons/ChevronDown';

function getSelectedNode(selection: RangeSelection) {
  const { anchor } = selection;
  const { focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

enum EMobileRTEmenuStages {
  MAIN = 'MAIN',
  ALIGN = 'ALIGN',
}

interface Props {
  show?: boolean;
  onSectionDelete: () => void;
  panelRef?: MutableRefObject<HTMLDivElement | undefined>;
  showControls?: boolean;
}

const LexicalRTEToolbarPlugin = ({
  show,
  onSectionDelete,
  panelRef,
  showControls,
}:Props): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setBold] = useState(false);
  const [isItalic, setItalic] = useState(false);
  const [isUnderline, setUnderline] = useState(false);
  const [blockType, setBlockType] = useState<LexicalBlockTypes>(ELexicalBlockTypes.PARAGRAPH);
  const [isLink, setIsLink] = useState(false);
  const [linkEditMode, setLinkEditMode] = useState(false);
  const isTemplate = useAppSelector(selectIsTemplate);
  const [activeAlignIndex, setActiveAlignIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorTemplateEl, setAnchorTemplateEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>();

  const [editMode, setEditMode] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root'
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type:LexicalBlockTypes = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else if ($isParagraphNode(element)) {
          setBlockType(ELexicalBlockTypes.PARAGRAPH);

          if (element.hasFormat('left') && !element.hasFormat('end')) {
            setActiveAlignIndex(0);
          } else if (element.hasFormat('center') && !element.hasFormat('left')) {
            setActiveAlignIndex(1);
          } else if (element.hasFormat('right')) {
            setActiveAlignIndex(2);
          } else if (element.hasFormat('justify')) {
            setActiveAlignIndex(3);
          }
        } else if ($isHeadingNode(element)) {
          setBlockType(element.getTag());
        }
      }

      setBold(selection.hasFormat('bold'));
      setItalic(selection.hasFormat('italic'));
      setUnderline(selection.hasFormat('underline'));

      const node = getSelectedNode(selection);

      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    editor.registerUpdateListener(() => {
      setEditMode(editor.isEditable());
    });
    mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        1,
      ),
    );
  }, [updateToolbar, editor]);

  const icons = [
    <RTEFormatLeftIcon key={1} />,
    <RTEFormatCenterIcon key={2} />,
    <RTEFormatJustifyIcon key={3} />,
    <RTEFormatJustifyIcon key={4} />,
  ];

  const alignItems: AppDropdownItemProps[] = useMemo(() => [
    {
      key: 'MobileRTEMenu-Left',
      label: 'Align left',
      onClick: () => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
      },
    },
    {
      key: 'MobileRTEMenu-Center',
      label: 'Align center',
      onClick: () => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
      },
    },
    {
      key: 'MobileRTEMenu-Right',
      label: 'Align right',
      onClick: () => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
      },
    },
    {
      key: 'MobileRTEMenu-Justify',
      label: 'Justify',
      onClick: () => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
      },
    },
  ].map((element, index) => ({
    ...element,
    startIcon: <LexicalRTEBtnBase
      content={icons[index]}
      highlight={false}
    />,
    endIcon: index === activeAlignIndex ? (
      <SvgIcon
        component={CheckIcon}
        sx={{
          '& path': {
            color: 'transparent',
            stroke: (theme) => theme.palette.primary[600],
          },
        }}
      />
    ) : undefined,
    onMouseDown: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
  })), [activeAlignIndex]);

  const formatBulletList = () => {
    if (blockType !== ELexicalBlockTypes.UL) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== ELexicalBlockTypes.OL) {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const onLinkBtnClick = () => {
    setLinkEditMode(!linkEditMode);
  };

  useEffect(() => {
    if (!editMode) {
      setLinkEditMode(false);
    }
  }, [editMode]);

  if (!editMode && !show) {
    return null;
  }

  const insertTemplateVar = (str: string) => {
    editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, renderTemplateVar(str));
  };

  return (
    <Box
      className="LexicalRTEToolbar"
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
      sx={{
        width: isMobile ? '295px' : 'auto',
        background: 'transparent',
        padding: '4px 0px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        height: 'auto',
        transition: 'height 0.25s',
        '& .RTEToolbarButtonGroupContainer': {
          width: 'auto',
          display: 'flex',
          gap: '4px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          flexWrap: 'nowrap',
          justifyContent: 'flex-end',
        }}
      >
        <LexicalRTEHeaderMenu
          editor={editor}
          blockType={blockType}
        />
        <Box
          className="RTEToolbarButtonGroupContainer"
        >
          <LexicalRTEBtnBase
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            content={<RTEBoldIcon />}
            highlight={isBold}
          />
          <LexicalRTEBtnBase
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
            content={<RTEItalicIcon />}
            highlight={isItalic}
          />
          <LexicalRTEBtnBase
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
            content={<RTEUnderlineIcon />}
            highlight={isUnderline}
          />
        </Box>
        {
          !isMobile
            ? (
              <>
                <Box
                  className="RTEToolbarButtonGroupContainer"
                >
                  <LexicalRTEBtnBase
                    onClick={formatBulletList}
                    content={<RTEULIcon />}
                    highlight={blockType === ELexicalBlockTypes.UL}
                  />
                  <LexicalRTEBtnBase
                    onClick={formatNumberedList}
                    content={<RTEOLIcon />}
                    highlight={blockType === ELexicalBlockTypes.OL}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '32px',
                  }}
                >
                  <LexicalRTEBtnBase
                    onClick={onLinkBtnClick}
                    content={<RTELinkIcon />}
                    highlight={isLink}
                  />
                  <LexicalLinkEditor
                    linkEditMode={linkEditMode}
                    toolbarRef={panelRef ?? containerRef!}
                    editor={editor}
                    closeEditor={() => setLinkEditMode(false)}
                  />
                </Box>
                <Box
                  className="RTEToolbarButtonGroupContainer"
                >
                  <AppButton
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    endIcon={anchorEl ? <ChevronUp20 /> : <ChevronDown20 />}
                    sx={{
                      width: 'auto !important',
                      minWidth: 'auto !important',
                      '& .MuiButton-endIcon': {
                        marginLeft: '0px !important',
                      },
                    }}
                  >
                    {icons[activeAlignIndex]}
                  </AppButton>
                  <AppDropdown
                    open={!!anchorEl}
                    onClose={() => { setAnchorEl(null); }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& ul': {
                        minWidth: '200px',
                      },
                      '& .app-dropdown-label': {
                        width: '100px',
                      },
                    }}
                    items={alignItems}
                  />
                </Box>
                <Box
                  className="RTEToolbarButtonGroupContainer"
                  sx={{
                    display: isTemplate ? 'block !important' : 'none !important',
                  }}
                >
                  <AppButton
                    onClick={(e) => setAnchorTemplateEl(e.currentTarget)}
                    endIcon={anchorTemplateEl ? <ChevronUp20 /> : <ChevronDown20 />}
                    sx={{
                      width: 'auto !important',
                      minWidth: 'auto !important',
                      '& .MuiButton-endIcon': {
                        marginLeft: '0px !important',
                      },
                    }}
                  >
                    <RTEInsertTemplateVarIcon />
                  </AppButton>
                  <TemplateVarDropdown
                    onInsert={() => insertTemplateVar(templateVars.companyName)}
                    open={!!anchorTemplateEl}
                    onClose={() => { setAnchorTemplateEl(null); }}
                    anchorEl={anchorTemplateEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  />
                </Box>
              </>
            ) : (
              <LexicalRTEBtnBase
                onClick={(e) => setAnchorEl(e.currentTarget)}
                content={<ThreeDotsVert20px />}
                highlight={!!anchorEl}
              />
            )
          }
        { isMobile && (
        <MobileTabs
          editor={editor}
          open={!!anchorEl}
          onBulletList={formatBulletList}
          onNumberList={formatNumberedList}
          setOpen={() => setAnchorEl(null)}
          onDelete={onSectionDelete}
          showControls={showControls}
        />
        )}
      </Box>
    </Box>
  );
};

export default LexicalRTEToolbarPlugin;
