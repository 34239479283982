const DeleteSectionSvg = (): JSX.Element => (
  <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.00146 4.15796H2.66813H16.0015"
      stroke="#C94937"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.16809 4.15804V2.49137C4.16809 2.04935 4.34369 1.62542 4.65625
     1.31286C4.96881 1.0003 5.39273 0.824707 5.83476 0.824707H9.16809C9.61012
      0.824707 10.034 1.0003 10.3466 1.31286C10.6592 1.62542 10.8348 2.04935
       10.8348 2.49137V4.15804M13.3348 4.15804V15.8247C13.3348 16.2667
        13.1592 16.6907 12.8466 17.0032C12.534 17.3158 12.1101 17.4914
         11.6681 17.4914H3.33476C2.89273 17.4914 2.46881 17.3158 2.15625
          17.0032C1.84369 16.6907 1.66809 16.2667 1.66809
           15.8247V4.15804H13.3348Z"
      stroke="#C94937"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

export default DeleteSectionSvg;
