import { useAppSelector } from '../../hooks/stateHooks';
import AdminOrganizations from './organizations/AdminOrganizations';

const AdminPage = (): JSX.Element => {
  const user = useAppSelector((state) => state.app.user);
  window.Intercom('update', {
    hide_default_launcher: true,
  });
  return (
    <AdminOrganizations />
  );
};

export default AdminPage;
