import { useEffect, useRef, useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';
import { Box } from '@mui/material';
import { PlusMenuIcon } from './icons/PlusMenuIcon';
import { useBlockContext } from './TiptapBlockContextProvider';
import useTiptapBlockMenu from './Hooks/useTiptapBlockMenu';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';

const TiptapNodeMenuBtn = (): JSX.Element => {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const { editor } = useCurrentEditor();
  const {
    node, getPos, data,
  } = useBlockContext();
  const [error, setError] = useState(false);

  // TODO: Find better way
  const [pos, setPos] = useState<number | undefined>(undefined);

  let targetPos: number | undefined;

  try {
    const newPos = getPos();
    if (typeof newPos === 'number' && newPos > -1) {
      targetPos = newPos;
      if (error) {
        setError(false);
      }
    }
  } catch (e) {
    if (!error) {
      setError(true);
    }
    // muted error
  }

  useEffect(() => {
    if (typeof targetPos === 'number' && targetPos > -1) {
      setPos(targetPos);
    }
  }, [targetPos]);

  const { insertParagraph } = useTiptapBlockMenu({ editor, position: pos ? pos + node.nodeSize : 0 });

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      typeof pos === 'number'
      && (!['heading', 'paragraph'].includes(node.type.name) || !!node.textContent.length)
    ) {
      insertParagraph(pos + node.nodeSize);
      e.currentTarget.classList.add('NodeMenuBtn-active');
      return;
    }
    window.dispatchEvent(new CustomEvent(
      ECustomEventsNames.TIPTAP_OPEN_BLOCK_MENU,
      {
        detail: {
          newBlockId: data.blockId,
          isTable: editor?.$pos(pos)?.node?.type?.name === 'tableHeader',
        },
      },
    ));
  };

  return (
    <Box
      className="NodeMenuBtn"
      ref={btnRef}
      onClick={handleClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        stroke: '#1E293B',
        width: '24px',
        height: '24px',
        '&:hover': {
          stroke: '#FB982A',
        },
      }}
    >
      <PlusMenuIcon />
    </Box>
  );
};

export default TiptapNodeMenuBtn;
