import { useState, useEffect } from 'react';
import { Editor, Range } from '@tiptap/react';
import { Box, Typography } from '@mui/material';
import useTiptapBlockMenu from '../Hooks/useTiptapBlockMenu';
import BlockMenuItems from '../Utils/MenuItems';
import { TBlockMenuItem } from '../Utils/TiptapTypes';
import { useAppSelector } from '../../../hooks/stateHooks';
import { selectOrganizationId } from '../../../core/store/appState/appState';
import { useGetSectionTemplatesByOrgIdQuery } from '../../ProcessFields/lib/processFieldsApi';
import { TiptapMenuItem } from '../Components/Menu/TiptapMenu';
import { MediaIcon } from '../icons/MenuIcons';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import MediaFieldMenu from '../../BlockContentSection/BlockContentRowMenu/MediaFieldMenu/MediaFieldMenu';
import { AddsectionChildData, EMediaFieldStorageType } from '../../../../shared/Field/Field';
import { selectCurrentStage } from '../../ProcessFields/lib/processItemsSlice';
import EmbedNodeMenu from './EmbedNodeMenu/EmbedNodeMenu';
import { getSubtypeByMIME } from '../../MediaField/Helpers/MediaFieldHelpers';
import { ExtensionNames } from '../Utils/TiptapHelpers';

interface Props {
  position?: number;
  onClose: () => void;
  range?: Range;
  editor: Editor | null;
  items?: any;
  isTable?: boolean;
}

const TiptapNodeMenuItems = ({
  onClose = () => {},
  position,
  range,
  editor,
  items,
  isTable,
}: Props): JSX.Element => {
  const [menuItems, setMenuItems] = useState<'reg' | 'temp' | 'emb'>('reg');
  const organizationId = useAppSelector(selectOrganizationId);
  const currentStage = useAppSelector(selectCurrentStage);

  const {
    replaceParagraph,
    useTemplate,
  } = useTiptapBlockMenu({ editor, range, position });

  const [mediaMenuOpen, setMediaMenuOpen] = useState(false);

  const {
    data: sectionTemplates,
    refetch,
    isUninitialized,
  } = useGetSectionTemplatesByOrgIdQuery({ organizationId }, { skip: !organizationId });

  useEffect(() => {
    if (organizationId && !isUninitialized) {
      refetch();
    }
  }, [currentStage, organizationId]);

  const onTemplate = (status?: boolean) => {
    if (status) {
      setMenuItems('temp');
    } else {
      setMenuItems('reg');
    }
  };

  const onSelect = (item: TBlockMenuItem) => {
    if (item.type === 'embed') {
      setMenuItems('emb');
      return;
    }
    if (item.type === 'template') {
      onTemplate(true);
      return;
    }
    if (item.type === 'media') {
      setMediaMenuOpen(true);
      return;
    }
    replaceParagraph(item);
    onClose();
  };

  const handleMediaFieldAdd = (childData?: AddsectionChildData, mediaFiles?: File[]) => {
    onClose();
    const attributes = mediaFiles?.[0] ? {
      subType: getSubtypeByMIME(mediaFiles?.[0].type),
      storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
    } : {};
    replaceParagraph({
      label: 'Media',
      type: 'media',
      content: [],
      attrs: {
        ...(childData?.embedData?.newUrl
          ? {
            doc: childData.embedData.newUrl,
            subType: childData.embedData.newSubType,
            storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
          }
          : attributes),
      },
      icon: <MediaIcon />,
    }, undefined, mediaFiles);
  };

  const handleMediaMenuClose = () => {
    setMediaMenuOpen(false);
  };

  return (
    <Box
      sx={{
        width: 'auto',
        maxHeight: '618px',
        overflow: 'auto',
      }}
    >
      {
          menuItems === 'reg' && (
            [
              (
                (items ?? BlockMenuItems).filter((item) => (isTable ? (
                  (item.type !== ExtensionNames.AGENDA)
                  && (item.type !== ExtensionNames.PRICING_TABLE)
                  && (item.type !== 'table')
                  && (item.type !== 'template')
                ) : item)).map((item: any, i: number) => (
                  <TiptapMenuItem
                    key={`block-menu-item-${i}`}
                    onClick={() => onSelect(item)}
                    title={item.label}
                    icon={item.icon}
                  />
                ))
              ),
            ]
          )
      }
      {
          menuItems === 'temp' && (
            [

              (
                <Box
                  onClick={() => { onTemplate(); }}
                  key="node-menu-back-btn"
                  sx={{
                    width: '100%',
                    height: '52px',
                    paddingX: '16px',
                    borderBottom: '1px solid #EEF0FA',
                    background: '#FCFDFD',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    cursor: 'pointer',
                  }}
                >
                  <AppIconButton icon={<ChevronLeft />} />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                    }}
                  >
                    Back
                  </Typography>
                </Box>
              ),
              (
                sectionTemplates?.map((item) => {
                  if (item?.items) {
                    return (
                      <TiptapMenuItem
                        key={`template-menu-item-${item.id}`}
                        onClick={() => {
                          useTemplate(item);
                          onClose();
                        }}
                        title={`${item.title}`}
                      />
                    );
                  }
                  return null;
                })),
            ]
          )
      }
      {
          menuItems === 'emb'
          && (
            <EmbedNodeMenu
              handleClose={onClose}
              goBack={() => setMenuItems('reg')}
              position={position}
              items={menuItems}
            />
          )
      }
      <MediaFieldMenu
        isOpen={mediaMenuOpen}
        onClose={handleMediaMenuClose}
        onResult={handleMediaFieldAdd}
      />
    </Box>
  );
};

export default TiptapNodeMenuItems;
