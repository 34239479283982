import { DialogProps } from '@mui/material';
import DealConfirmBuyerAccount from '../../auth/DealConfirmBuyerAccont/DealConfirmBuyerAccount';
import DealSignupDialogWrapper from './DealSignupDialogWrapper';

const SignupDialog = (props: DialogProps): JSX.Element => (
  <DealSignupDialogWrapper
    {...props}
  >
    <DealConfirmBuyerAccount />
  </DealSignupDialogWrapper>
);

export default SignupDialog;
