import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsFormWrapper,
  SettingsTabContentWrapper,
  SettingsTabData,
  SettingsTabSubtitle,
  SettingsTabSubtitleWrapper,
} from './Styles/GeneralTabStyles';
import useUserOrganizationStatus from '../../../hooks/useSubscriptionStatus';
import { useCustomDomainSettings } from '../../../hooks/useCustomDomainSettings';
import { AppDashedBorderBox } from '../../../shared/AppDashedBorderBox/AppDashedBorderBox';
import { ECustomDomainEnableStatus } from '../../../../shared/CustomDomains';
import { useOrganization } from '../../../hooks/useOrganization';

const TeammateCustomDomainTab = (): JSX.Element => {
  const { organization } = useOrganization();
  const { hasCustomDomains, isPremium } = useUserOrganizationStatus();

  const {
    getUrlFromOrigin,
    customDomainStatus,
    isBasic,
  } = useCustomDomainSettings();

  const { t } = useTranslation();

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Settings_Custom_Domain')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Company domain information.
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      {(isPremium() || isBasic) && (
      <SettingsFormWrapper
        className={classNames('settings-column', { 'settings-mobile': isMobile })}
      >
        <SettingsTabSubtitleWrapper>
          <SettingsTabSubtitle>
            Subdomain
          </SettingsTabSubtitle>
          <AppDashedBorderBox />
          <SettingsTabData>
            {`${organization?.domain || ''}${getUrlFromOrigin()}`}
          </SettingsTabData>
        </SettingsTabSubtitleWrapper>
        {hasCustomDomains()
              && organization.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE && (
              <>
                <SettingsTabSubtitleWrapper>
                  <SettingsTabSubtitle>
                    Custom domain
                  </SettingsTabSubtitle>
                  <AppDashedBorderBox />
                  <SettingsTabData>
                    {organization?.customDomain}
                  </SettingsTabData>
                </SettingsTabSubtitleWrapper>
                <SettingsTabSubtitleWrapper>
                  <SettingsTabSubtitle>
                    DNS
                  </SettingsTabSubtitle>
                  <AppDashedBorderBox />
                  <SettingsTabData>
                    {customDomainStatus.DNSEnabled ? 'Confirmed' : 'Invalid' }
                  </SettingsTabData>
                </SettingsTabSubtitleWrapper>
              </>
        )}
      </SettingsFormWrapper>
      )}
    </SettingsTabContentWrapper>
  );
};

export default TeammateCustomDomainTab;
