import { SvgIcon, SvgIconProps } from '@mui/material';

export const PenIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      fill="none"
      d="M10.5 16.6667H18"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M14.25 2.91669C14.5815 2.58517 15.0312 2.39893
      15.5 2.39893C15.7321 2.39893 15.962 2.44465 16.1765
      2.53349C16.391 2.62233 16.5858 2.75254 16.75 2.91669C16.9142
      3.08085 17.0444 3.27572 17.1332 3.4902C17.222 3.70467
      17.2678 3.93455 17.2678 4.16669C17.2678 4.39884 17.222
      4.62871 17.1332 4.84319C17.0444 5.05766 16.9142 5.25254
      16.75 5.41669L6.33333 15.8334L3 16.6667L3.83333 13.3334L14.25 2.91669Z"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
