import {
  RefObject, useEffect, useRef, useState,
} from 'react';
import { Box, TextField } from '@mui/material';
import classNames from 'classnames';
import { useTemplateVars } from '../../hooks/useTemplateVars';
import { TemplateVarDropdown } from '../TemplateVarDropdown/TemplateVarDropdown';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

interface Props {
  title?: string;
  disabled?: boolean;
  lock?: boolean;
  setDisabled: (_: boolean) => void;
  textFieldRef: RefObject<HTMLInputElement>;
  handleSave: ({ title }: { title: string }) => void;
}

const StagesSidebarItemTitle = ({
  title,
  disabled,
  setDisabled,
  textFieldRef,
  handleSave,
  lock,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();
  const [textFieldValue, setTextFieldValue] = useState<string>(title || '');
  const {
    anchorTemplatePos,
    setAnchorTemplatePos,
    onInsertTemplateVar,
    lastPos,
  } = useTemplateVars({
    input: inputRef,
    textFieldValue,
    setTextFieldValue,
  });

  useEffect(() => {
    if (title) setTextFieldValue(title);
  }, [title]);

  useEffect(() => {
    if (!anchorTemplatePos && lastPos) {
      setDisabled(false);
      setTimeout(() => {
        textFieldRef.current?.setSelectionRange(lastPos, lastPos);
        textFieldRef.current?.focus();
      });
    }
  }, [anchorTemplatePos]);

  return (
    <Box
      sx={{
        padding: '7px 0px',
      }}
    >
      <TextField
        autoComplete="off"
        inputRef={textFieldRef}
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        variant="standard"
        className={classNames('stage-input', { disabled })}
        placeholder="Stage name"
        onClick={(e) => {
          if (disabled) e.preventDefault();
          if (!disabled) e.stopPropagation();
        }}
        onBlur={() => {
          setDisabled(true);
          if (textFieldRef!.current!.value.trim()) {
            handleSave({ title: textFieldRef!.current!.value });
          } else {
            setTextFieldValue(title ?? '');
          }
        }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          ref: inputRef,
          'data-lpignore': 'true',
        }}
        sx={{
          pointerEvents: disabled ? 'none' : 'initial',
          '&.disabled input': {
            cursor: 'pointer',
          },
          '&.disabled .MuiInput-root': {
            cursor: 'pointer',
          },
          '& .MuiInput-root:after': {
            display: 'none',
          },
          '& .MuiInput-root:before': {
            display: 'none',
          },
          '& input': {
            maxWidth: 'calc(100%)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: anchorTemplatePos ? 'initial' : 'ellipsis',
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '24px',
            color: (theme) => (lock
              ? greySidebarContrastText(theme, 0.3)
              : greySidebarContrastText(theme, 0.8)),
            fontWeight: 500,
            height: '32px',
            width: '180px',
            paddingLeft: '15px',
            paddingTop: '0px',
            paddingBottom: '0px',
            WebkitTextFillColor: 'initial !important',
          },
        }}
      />
      <TemplateVarDropdown
        onInsert={onInsertTemplateVar}
        open={!!anchorTemplatePos}
        onClose={() => {
          setAnchorTemplatePos(null);
          setDisabled(false);
          setTimeout(() => {
            if (lastPos) inputRef.current?.setSelectionRange(lastPos, lastPos);
            inputRef.current?.focus();
          });
        }}
        anchorReference="anchorPosition"
        anchorPosition={anchorTemplatePos || undefined}
      />
    </Box>
  );
};

export default StagesSidebarItemTitle;
