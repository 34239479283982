import { SvgIcon, SvgIconProps } from '@mui/material';

const TrashIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 21"
    sx={{
      width: '20px',
      height: '21px',
      color: 'transparent',
    }}
    {...props}
  >
    <path
      d="M2.5 5.30127H4.16667H17.5"
      color="inherit"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.6665 5.30111V3.63444C6.6665 3.19241 6.8421 2.76849 7.15466 2.45593C7.46722 2.14337 7.89114 1.96777
      8.33317 1.96777H11.6665C12.1085 1.96777 12.5325 2.14337 12.845 2.45593C13.1576 2.76849 13.3332 3.19241 13.3332
      3.63444V5.30111M15.8332 5.30111V16.9678C15.8332 17.4098 15.6576 17.8337 15.345 18.1463C15.0325 18.4588 14.6085
      18.6344 14.1665 18.6344H5.83317C5.39114 18.6344 4.96722 18.4588 4.65466 18.1463C4.3421 17.8337 4.1665 17.4098
      4.1665 16.9678V5.30111H15.8332Z"
      color="inherit"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default TrashIcon;
