import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';
import { ContentType } from '../../../../shared/Stage';

export enum EFeedTab {
  USERS = 'USERS',
  FILES = 'FILES',
  CHAT = 'CHAT',
  STALKER = 'STALKER',
  PROCESS = 'PROCESS',
  STAGES = 'STAGES',
  ACTIVITY = 'ACTIVITY',
  AGENDA = 'AGENDA',
}

export type DealViewMode = 'preview' | 'editor' | 'internal';

export enum ESidebarTabs {
  STAGES = 'STAGES',
  CUSTOMIZATION = 'CUSTOMIZATION',
  FILTERS = 'FILTERS',
  INTERNAL = 'INTERNAL',
  PRICING_TABLE_DETAILS = 'PRICING_TABLE_DETAILS',
  SETTINGS = 'SETTINGS',
  NEWPAGE = 'NEW_PAGE',
  CANVAS = 'CANVAS',
  MEDIA = 'MEDIA',
  EMBED = 'EMBED',
}

export enum ENewPageTabs {
  BLANK = 'BLANK',
  TEMPLATE = 'TEMPLATE',
  TEMPLATE_EDIT = 'TEMPLATE_EDIT',
  LIBRARY = 'LIBRARY',
  LIBRARY_EDIT = 'LIBRARY_EDIT',
}

export enum EMenuTabs {
  ROOMS = 'ROOMS',
  TEMPLATES = 'TEMPLATES',
  TEAM = 'TEAM',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  INTEGRATIONS = 'INTEGRATIONS',
  SETTINGS = 'SETTINGS',
  ADMIN = 'ADMIN',
  LIBRARY = 'LIBRARY',
}

export enum ESettingsTabs {
  PROFILE = 'PROFILE',
  SETTINGS_TEAM = 'SETTINGS_TEAM',
  CUSTOM_DOMAIN = 'CUSTOM_DOMAIN',
  ORGANIZATION = 'ORGANIZATION',
  SUBSCRIPTION = 'SUBSCRIPTION',
  INTEGRATIONS = 'INTEGRATIONS',
  PUBLIC_API_TOKENS = 'PUBLIC_API_TOKENS',
}

export enum EBottomDrawerTabs {
  ROOM_GENERAL_MENU = 'ROOM_GENERAL_MENU',
  LIBRARY_CREATE_ITEM_MENU = 'LIBRARY_CREATE_ITEM_MENU',
  NULL = 'NULL',
}

const fieldsLayoutReducerName: string = 'fieldsLayout';

export interface IFieldsLayoutState {
  activeSection?: string;
  isActiveSectionUpdateDisabled: boolean;
  isDrawerOpen: boolean;
  mobileBottomDrawerTab: EBottomDrawerTabs;
  isFiltersDrawerOpen: boolean;
  sidebarTab: ESidebarTabs;
  newPageTab: ENewPageTabs;
  newPageEmbedTab: ContentType;
  settingsTab: ESettingsTabs;
  menuTab: EMenuTabs | undefined;
  singleQueries: { [key: string]: string };
  toRefetch: { [key: string]: string };
  viewMode: DealViewMode;
}

const initialState: IFieldsLayoutState = {
  activeSection: undefined,
  isActiveSectionUpdateDisabled: false,
  isDrawerOpen: false,
  mobileBottomDrawerTab: EBottomDrawerTabs.NULL,
  isFiltersDrawerOpen: false,
  sidebarTab: ESidebarTabs.STAGES,
  newPageTab: ENewPageTabs.BLANK,
  newPageEmbedTab: ContentType.URL,
  settingsTab: ESettingsTabs.PROFILE,
  menuTab: undefined,
  singleQueries: {},
  toRefetch: {},
  viewMode: 'preview',
};

export const fieldsLayoutSlice = createSlice({
  name: fieldsLayoutReducerName,
  initialState,
  reducers: {
    setActiveSection: (state, { payload }: PayloadAction<string | undefined>) => {
      if (!state.isActiveSectionUpdateDisabled) {
        return {
          ...state,
          activeSection: payload,
        };
      }
      return state;
    },
    setActiveSectionUpdateDisabled: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isActiveSectionUpdateDisabled: payload,
    }),
    updateFieldsLayoutState: (state, { payload }: PayloadAction<Partial<IFieldsLayoutState>>) => ({
      ...state,
      ...payload,
    }),
    setStagesDrawerOpen: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerOpen: payload,
    }),
    setStagesFiltersDrawerOpen: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isFiltersDrawerOpen: payload,
    }),
    setMobileBottomDrawerTab: (state, { payload }: PayloadAction<EBottomDrawerTabs>) => ({
      ...state,
      mobileBottomDrawerTab: payload,
    }),
    setSidebarTab: (state, { payload }: PayloadAction<ESidebarTabs>) => ({
      ...state,
      sidebarTab: payload,
    }),
    setNewPageTab: (state, { payload }: PayloadAction<ENewPageTabs>) => ({
      ...state,
      newPageTab: payload,
    }),
    setNewPageEmbedTab: (state, { payload }: PayloadAction<ContentType>) => ({
      ...state,
      newPageEmbedTab: payload,
    }),
    setSettingsTab: (state, { payload }: PayloadAction<ESettingsTabs>) => ({
      ...state,
      settingsTab: payload,
    }),
    setCurrentMenuTab: (state, { payload }: PayloadAction<EMenuTabs>) => ({
      ...state,
      menuTab: payload,
    }),
    addSingleQuery: (state, { payload }: PayloadAction<string>) => {
      state.singleQueries[payload] = payload;
      return state;
    },
    addFieldToRefetch: (state, { payload }: PayloadAction<string>) => {
      state.toRefetch[payload] = payload;
      return state;
    },
    removeFieldToRefetch: (state, { payload }: PayloadAction<string>) => {
      delete state.toRefetch[payload];
      return state;
    },
    resetFieldsLayoutSlice: () => ({
      ...initialState,
    }),
    setDealViewMode: (state, { payload }: PayloadAction<DealViewMode>) => {
      state.viewMode = payload;
    },
  },
});

export const {
  setActiveSection,
  setActiveSectionUpdateDisabled,
  updateFieldsLayoutState,
  setStagesDrawerOpen,
  setStagesFiltersDrawerOpen,
  setMobileBottomDrawerTab,
  setSidebarTab,
  setNewPageTab,
  setNewPageEmbedTab,
  setSettingsTab,
  setCurrentMenuTab,
  resetFieldsLayoutSlice,
  addSingleQuery,
  addFieldToRefetch,
  removeFieldToRefetch,
  setDealViewMode,
} = fieldsLayoutSlice.actions;

export const selectActiveSection = (state: RootState): string | undefined => state.fieldsLayout.activeSection;
export const selectIsActiveSection = (state: RootState, id: string): boolean => (id ? state.fieldsLayout.activeSection === id : false);
export const selectIsActiveSectionUpdateDisabled = (state: RootState): boolean => state.fieldsLayout.isActiveSectionUpdateDisabled;
export const selectStagesDrawerStatus = (state: RootState): boolean => state.fieldsLayout.isDrawerOpen;
export const selectMobileBottomDrawerTab = (state: RootState): EBottomDrawerTabs => state.fieldsLayout.mobileBottomDrawerTab;
export const selectStagesFiltersDrawerStatus = (state: RootState): boolean => state.fieldsLayout.isFiltersDrawerOpen;
export const selectSidebarTab = (state: RootState): ESidebarTabs => state.fieldsLayout.sidebarTab;
export const selectNewPageTab = (state: RootState): ENewPageTabs => state.fieldsLayout.newPageTab;
export const selectNewPageEmbedTab = (state: RootState): ContentType => state.fieldsLayout.newPageEmbedTab;
export const selectSettingsTab = (state: RootState): ESettingsTabs => state.fieldsLayout.settingsTab;
export const selectIsCustomizationTab = (state: RootState): boolean => state.fieldsLayout.sidebarTab === ESidebarTabs.CUSTOMIZATION;
export const selectCurrentMenuTab = (state: RootState) => state.fieldsLayout.menuTab;
export const selectDealViewMode = (state: RootState): DealViewMode => state?.fieldsLayout.viewMode;
export const selectIsSingleQuery = (state: RootState, id?: string) => id && state.fieldsLayout.singleQueries[id];
export const selectFieldShouldRefetch = (state: RootState, id?: string) => id && state.fieldsLayout.toRefetch[id];
