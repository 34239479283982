import { LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { HeadingNode } from '@lexical/rich-text';
import { TextNode } from 'lexical';
import { MentionNode } from './LexicalRTETemplateNode';

interface Props{
  children: JSX.Element | JSX.Element[];
}

const theme = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  quote: 'editor-quote',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
  },
  image: 'editor-image',
  link: 'editor-link',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'editor-text-underline',
    strikethrough: 'editor-text-strikethrough',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
    code: 'editor-text-code',
  },
};

function onError(error: any) {
  console.error(error);
}

const initialConfig: InitialConfigType = {
  namespace: 'MyEditor',
  theme,
  onError,
  editable: false,
  nodes: [
    HeadingNode,
    TextNode,
    ListNode,
    ListItemNode,
    LinkNode,
    MentionNode,
  ],
};

const LexicalContextProvider = ({ children }: Props): JSX.Element => (
  <LexicalComposer initialConfig={initialConfig}>
    {children}
  </LexicalComposer>
);

export default LexicalContextProvider;
