import { useEffect, useState } from 'react';
import { APIDocEvents } from '../../../shared/events/APIDocumentEvents';

interface ActionOnTemplateUpdateData {
  action: () => void;
  prevent?: boolean;
  checkAgainst?: any;
}

export function useActionOnFieldsUpdate({
  action,
  prevent,
  checkAgainst,
}: ActionOnTemplateUpdateData): void {
  const [lastUpdated, setLastUpdated] = useState<number | null>(null);

  function performAction() {
    if (!lastUpdated || (Date.now() - lastUpdated > 60000)) {
      setLastUpdated(Date.now());
      action();
    }
  }

  useEffect(() => {
    if (!prevent) {
      document.addEventListener(APIDocEvents.PROCESS_FIELDS_DATA_UPDATE, performAction);
    }
    return () => {
      if (!prevent) {
        document.removeEventListener(APIDocEvents.PROCESS_FIELDS_DATA_UPDATE, performAction);
      }
    };
  }, [lastUpdated, prevent, checkAgainst]);
}
