export const templateVars = {
    buyerName: 'buyer_name',
    buyerJobTitle: 'buyer_job_title',
    buyerEmail: 'buyer_email',
    buyerPhone: 'buyer_phone',
    buyerWebsite: 'buyer_website',
    buyerCountry: 'buyer_country',
    buyerCompanyName: 'buyer_company_name',
    sellerFirstName: 'seller_first_name',
    sellerLastName: 'last_first_name',
    sellerJobTitle: 'seller_job_title',
    sellerEmail: 'seller_email',
    sellerPhone: 'seller_phone',
    sellerCompanyName: 'seller_company_name',
    formsText: 'forms_text',
    formsEmail: 'forms_email',
    formsPhone: 'forms_phone',
    companyName: 'company_name',
    firstName: 'first_name',
    lastName: 'last_name',
    roomName: 'room_name',
    clientName: 'client_name',
};

export type TemplateVars = typeof templateVars;
export type TemplateVarKey = keyof TemplateVars;
export type TemplateVarValue = TemplateVars[TemplateVarKey];

export const renderTemplateVar = (str: string) => {
    return `{{${str}}}`;
}

export const releaseTemplateVar = (str: TemplateVarValue, valueMap: Partial<Record<TemplateVarKey, string>>) => {
    const sanitizedStr = str.replaceAll(/[{}]/g, '').trim();
    switch (sanitizedStr) {
        case 'buyer_company_name':
            return valueMap.buyerCompanyName;
        case 'company_name':
            return valueMap.companyName;
    }
    return str;
}
