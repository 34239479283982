import { OrganizationAdminDTO, UpdateOrganizationAdminPayload } from '../../shared/OrganizationDTO';
import { UpdateOrganizationCDEnablesRequest, UpdateOrganizationOwnerRequest, UpdateOrganizationPublicApiEnablesRequest } from '../../shared/user-organization';
import { ApiService } from '../core/api';

export interface IUpdateOrgFormData {
  id: string;
  organizationLogo?: string;
}

export class AdminApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('admin');
  }

  async getOrganizations(): Promise<OrganizationAdminDTO[]> {
    return this.api.get<OrganizationAdminDTO[]>('organizations');
  }

  async getDeletedOrganizations(): Promise<OrganizationAdminDTO[]> {
    return this.api.get<OrganizationAdminDTO[]>('deleted/organizations');
  }

  async updateOrganization(payload: UpdateOrganizationAdminPayload): Promise<OrganizationAdminDTO> {
    return this.api.put('update-organization', payload);
  }

  async deleteUser(userId: string, organizationId: string, type: string = 'delete'): Promise<OrganizationAdminDTO> {
    return this.api.delete<OrganizationAdminDTO>(`delete-user/${organizationId}/${userId}?type=${type}`);
  }

  async deleteOrganization(organizationId: string, type: string = 'disable'): Promise<void> {
    this.api.delete<OrganizationAdminDTO[]>(`delete-organization/${organizationId}?type=${type}`);
  }

  async resoreOrganization(organizationId: string): Promise<OrganizationAdminDTO> {
    return this.api.put(`restore-organization/${organizationId}`, {});
  }

  async updateOrganizationOwner(organizationId: string, payload: UpdateOrganizationOwnerRequest): Promise<OrganizationAdminDTO> {
    return this.api.put(`update-organization-owner/${organizationId}`, payload);
  }

  async updateOrganizationCustomDomainEnable(
    organizationId: string,
    payload: UpdateOrganizationCDEnablesRequest,
  ): Promise<OrganizationAdminDTO> {
    return this.api.put(`update-organization-custom-domain-enable/${organizationId}`, payload);
  }

  async updateOrganizationPublicApiEnable(
    organizationId: string,
    payload: UpdateOrganizationPublicApiEnablesRequest,
  ): Promise<OrganizationAdminDTO> {
    return this.api.put(`update-organization-public-api-enable/${organizationId}`, payload);
  }
}
