import { Box, styled } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const RoomButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingX: '0px 16px',
}));

export const RoomGeneralMenuTabWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const RoomGeneralMenuFooter = styled(Box)(() => ({
  padding: '4px 0px 8px 0px',
  borderTop: `1px solid ${stagesetColors.newGrey[200]}`,
}));
