import OwnerCardHeader from './OwnerCardHeader';
import OwnerCardWrapper from './OwnerCardWrapper';
import OwnerCardContent from './OwnerCardContent';
import { useAppSelector } from '../../hooks/stateHooks';
import { ProcessUserDTO } from '../../../shared/UserDTO';
import { MailContact } from '../../shared/Contact/Contacts/MailContact';
import { CalendlyContact } from '../../shared/Contact/Contacts/CalendlyContact';
import { LinkedInContact } from '../../shared/Contact/Contacts/LinkedInContact';
import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';
import Phone20 from '../../icons/Phone20.svg';
import ContactRow from '../../shared/Contact/ContactRow';

const OwnerCard = ({ isLoaded }: { isLoaded: boolean }): JSX.Element => {
  const ownerId = useAppSelector((state) => state.process.owner);
  const owner = useAppSelector(
    (state) => state.process.processUsers.find((user: ProcessUserDTO) => user.id === ownerId),
  );

  const renderContacts = () => {
    const contacts = [];

    if (owner.email || owner.contactEmail) {
      contacts.push(<MailContact key="email" email={owner.contactEmail ? owner.contactEmail : owner.email} />);
    }
    if (owner.phone) {
      contacts.push(<ContactRow
        key="phone"
        text={owner.phone}
        icon={<Phone20 />}
        link={`tel:${owner.phone}`}
      />);
    }
    if (owner.externalLinks.calendly) {
      contacts.push(<CalendlyContact key="calendlyLink" calendlyLink={owner.externalLinks.calendly} />);
    }
    if (owner.externalLinks.linkedIn) {
      contacts.push(<LinkedInContact key="linkedInLink" linkedInLink={owner.externalLinks.linkedIn} />);
    }
    return contacts;
  };

  return (
    <>
      {!isLoaded && (
        <AppSkeleton
          animation="wave"
          variant="rectangular"
          width="calc( 100% )"
          height="140px"
          sx={{
            borderRadius: '5px',
          }}
        />
      )}
      {
        isLoaded
        && owner
        && (
        <OwnerCardWrapper>
          <OwnerCardHeader
            avatar={owner.avatarSrc}
            name={owner.name}
            position={owner.title}
            colorId={owner.colorId}
          />
          <OwnerCardContent>
            {renderContacts()}
          </OwnerCardContent>
        </OwnerCardWrapper>
        )
}
    </>

  );
};

export default OwnerCard;
