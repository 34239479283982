import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';

export const modalWindowSliceName: string = 'modalWindow';

export interface IModalWindowState {
  open: boolean;
  text?: string;
  subText?: string;
}

export enum EModalWindowStates {
  TEMPLATE = 'template',
  PROCESS = 'process',
  EMPTY = 'empty',
}

export type TModalWindowStates = EModalWindowStates.TEMPLATE | EModalWindowStates.EMPTY | EModalWindowStates.PROCESS;

const initialState: IModalWindowState = {
  open: false,
  text: undefined,
  subText: undefined,
};

export const ModalWindowStates: { [key: string]: IModalWindowState } = {
  template: {
    open: true,
    text: 'Creating template',
    subText: 'This may take some time',
  },
  process: {
    open: true,
    text: 'Duplicating Room',
    subText: 'This may take some time',
  },
  empty: initialState,
};

export const modalWindowSlice = createSlice({
  name: modalWindowSliceName,
  initialState,
  reducers: {
    setModalWindowState: (state, { payload }: PayloadAction<IModalWindowState | TModalWindowStates>) => {
      if (typeof payload === 'string') {
        return ModalWindowStates[payload];
      }
      return ({
        ...payload,
      });
    },
    updateModalWindowState: (state, { payload }: PayloadAction<Partial<IModalWindowState>>) => ({
      ...state,
      ...payload,
    }),
    resetModalWindowState: () => ({
      ...initialState,
    }),
  },
  extraReducers: ((builder) => builder

  ),
});

export const {
  setModalWindowState,
  updateModalWindowState,
  resetModalWindowState,
} = modalWindowSlice.actions;

export const getModalWindowState = (state: RootState): IModalWindowState => state.modalWindow;
