import { useState } from 'react';
import { PaletteMode } from '@mui/material';
import theme from '../theme';

export const useAppTheme = () => {
  // todo: change store
  const getModeFromStore = () => (localStorage.getItem('theme') === 'dark'
    ? 'dark'
    : 'light') as PaletteMode;

  const [mode, setMode] = useState<PaletteMode>(getModeFromStore());

  const updateMode = (paletteMode: PaletteMode) => {
    localStorage.setItem('theme', paletteMode);
    setMode(paletteMode);
  };

  return {
    mode,
    updateMode,
    currentTheme: theme[mode],
  };
};
