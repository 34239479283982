import {
  Box, Grid, ListItemProps, ListItemText, Typography,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { EUserOrganizationStatus } from '../../../../../../shared/user-organization';
import { EUserProcessStatus } from '../../../../../../shared/user-process';
import { OrganizationUserDTO, ProcessUserDTO } from '../../../../../../shared/UserDTO';
import FeedListItem from '../../FeedListItem';
import UserItemAvatar from '../../../../../shared/UserAvatar/UserItemAvatar';
import { stagesetPalette } from '../../../../../theme/stagesetPalette';

export interface Props extends ListItemProps {
  user: Partial<ProcessUserDTO | OrganizationUserDTO>;
  isBadgeEnabled?: boolean;
  isTeamMember?: boolean
  isDeleteActive?: boolean;
  isOnline: boolean;
  secondaryAction: JSX.Element;
  isIntegrationUser?: boolean;
  linkedIn?: string;
  calendly?: string;
}

const StyledTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 'auto',
  maxWidth: '100%',
  marginBottom: '0px',
  whiteSpace: 'nowrap',
  // letterSpacing: '-0.01em',
}));

export const UserItem = ({
  user,
  isOnline,
  linkedIn,
  calendly,
  isBadgeEnabled,
  isTeamMember,
  secondaryAction,
  isIntegrationUser,
}: Props): JSX.Element => {
  let listItemText: JSX.Element;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  if (
    // @ts-ignore
    user.processStatus === EUserProcessStatus.ACTIVE
    // @ts-ignore
    || user.organizationStatus === EUserOrganizationStatus.ACTIVE
    || isIntegrationUser
  ) {
    listItemText = (
      <>
        <StyledTypography
          // color="text.primary"
          sx={{
            fontWeight: 500,
            color: stagesetPalette.grey?.[600],
            fontSize: '16px',
            lineHeight: '24px',
          }}
          gutterBottom
        >
          {user.name ? user.name : user.email}
        </StyledTypography>
        <Box sx={{
          maxWidth: '100%',
          display: 'flex',
        }}
        >
          <StyledTypography
            sx={{
              display: 'inline-block',
              width: 'auto',
              color: stagesetPalette.grey?.[400],
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 400,
            }}
          >
            {user.title || ''}
          </StyledTypography>
        </Box>
      </>
    );
  } else {
    listItemText = (
      <>
        <StyledTypography
          color="text.secondary"
          sx={{
            fontWeight: 500,
            color: stagesetPalette.grey?.[600],
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {user.email}
        </StyledTypography>
        <StyledTypography
          sx={{
            color: stagesetPalette.grey?.[400],
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
          }}
          gutterBottom
        >
          Invitation pending
        </StyledTypography>
      </>
    );
  }

  return (
    <FeedListItem
      className="UserItem"
      // secondaryAction={(
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       flex: 1,
      //       alignItems: 'center',
      //       justifyContent: 'center',
      //     }}
      //   >
      //     {secondaryAction}
      //   </Box>
      //   )}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <UserItemAvatar
          avatarSrc={user?.avatarSrc}
          isTeamMember={isTeamMember}
          isOnline={isOnline}
          isBadgeEnabled={isBadgeEnabled}
          userName={user?.name}
          colorId={user?.colorId}
          size={39}
          fontSize={16}
          fontWeight={400}
        />
        <ListItemText
          sx={{
            ml: '12px',
            marginTop: '0px',
            marginBottom: '0px',
            overflow: 'clip',
          }}
        >
          {listItemText}
        </ListItemText>
        <KeyboardArrowDown
          sx={{
            marginRight: '10px',
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
            height: '20px',
            width: '20px',
            color: stagesetPalette.primary?.main,
          }}
        />
      </Box>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          width: '100%',
          paddingTop: '10px',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={3}
          >
            <StyledTypography
              sx={{
                color: stagesetPalette.grey?.[400],
                fontSize: '12px',
                lineHeight: '36px',
                fontWeight: 400,
              }}
              gutterBottom
            >
              Email
            </StyledTypography>
          </Grid>
          <Grid item xs={9}>
            <StyledTypography
              sx={{
                color: stagesetPalette.grey?.[600],
                fontSize: '16px',
                lineHeight: '36px',
                fontWeight: 400,
              }}
              gutterBottom
            >
              {user?.email}
            </StyledTypography>
          </Grid>
          {
            (linkedIn)
            && (
              <>
                <Grid item xs={3}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[400],
                      fontSize: '12px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    Linkedin
                  </StyledTypography>
                </Grid>
                <Grid item xs={9}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[600],
                      fontSize: '16px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    <a
                      href={linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        color: stagesetPalette.primary?.main,
                      }}
                    >
                      Connect with me
                    </a>
                  </StyledTypography>
                </Grid>
              </>
            )
          }
          {
            (calendly)
            && (
              <>
                <Grid item xs={3}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[400],
                      fontSize: '12px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    Scheduler
                  </StyledTypography>
                </Grid>
                <Grid item xs={9}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[600],
                      fontSize: '16px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    <a
                      href={calendly}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        color: stagesetPalette.primary?.main,
                      }}
                    >
                      Book a meeting with me
                    </a>
                  </StyledTypography>
                </Grid>
              </>
            )
          }
          {
            (user?.phone)
            && (
              <>
                <Grid item xs={3}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[400],
                      fontSize: '12px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    Phone
                  </StyledTypography>
                </Grid>
                <Grid item xs={9}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[600],
                      fontSize: '14px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    {user.phone}
                  </StyledTypography>
                </Grid>
              </>
            )
          }
          {
            (user?.lastActive || isOnline)
            && (
              <>
                <Grid item xs={3}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[400],
                      fontSize: '12px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    Last Active
                  </StyledTypography>
                </Grid>
                <Grid item xs={9}>
                  <StyledTypography
                    sx={{
                      color: stagesetPalette.grey?.[600],
                      fontSize: '14px',
                      lineHeight: '36px',
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    {
                      isOnline ? (
                        DateTime.fromMillis(Date.now()).toLocaleString(DateTime.DATETIME_SHORT)
                      ) : (
                        DateTime.fromMillis(user?.lastActive).toLocaleString(DateTime.DATETIME_SHORT) ?? '')
                    }
                  </StyledTypography>
                </Grid>
              </>
            )
          }
          <Grid
            item
            xs={3}
          >
            <StyledTypography
              sx={{
                color: stagesetPalette.grey?.[400],
                fontSize: '12px',
                lineHeight: '36px',
                fontWeight: 400,
              }}
              gutterBottom
            >
              {user?.processStatus === EUserProcessStatus.INVITED ? 'Invited' : 'Joined'}
            </StyledTypography>
          </Grid>
          <Grid item xs={9}>
            <StyledTypography
              sx={{
                color: stagesetPalette.grey?.[600],
                fontSize: '14px',
                lineHeight: '36px',
                fontWeight: 400,
              }}
              gutterBottom
            >
              {DateTime.fromMillis(user?.joinedAt).toLocaleString(DateTime.DATETIME_SHORT) ?? ''}
            </StyledTypography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {secondaryAction}
        </Box>
      </Collapse>
    </FeedListItem>
  );
};
