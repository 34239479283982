import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { EActivationLinkType } from '../../../../shared/schema/auth.shcema';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { useQuery } from '../../../hooks/useQuery';
import BackToLogin from '../../../shared/AuthBtns/BackToLogin';
import {
  passwordStatusManager,
  EPasswordSecurityStatuses,
  passwordStatusesParser,
} from '../../../shared/PasswordSecurityCheck/helpers/PasswordSecurityCheckHelpers';
import PasswordSecurityIdentificator from '../../../shared/PasswordSecurityCheck/PasswordSecurityIdentificator';

import AuthButton from '../Components/AuthButton';
import { TitleTypographyWrapper, TitleTypography } from '../Components/AuthStyledComponents';
import { resetAuthError, resetPasswordConfirm } from '../state/authState';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';

interface FormState {
  password: string;
}

const ResetPasswordConfirm = (): JSX.Element => {
  const query = useQuery();
  const email: string | null = query.get('email');
  const hash: string | null = query.get('hash');
  const type: EActivationLinkType = query.get('invite-type') as EActivationLinkType;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated, status } = useAppSelector((state) => state.auth);

  const onSubmit = (data: FormState) => {
    if (email && hash && type) {
      if (type === EActivationLinkType.RESET_PASSWORD) {
        try {
          dispatch(resetPasswordConfirm({
            ...data,
            hash,
            email,
            type,
          })).unwrap();
          navigate(`${EAppRoutes.auth + EAppRoutes.login}`);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {
    if (!email || !hash) {
      navigate(EAppRoutes.auth + EAppRoutes.login);
    }
    if (isAuthenticated) {
      navigate(EAppRoutes.home);
    }
  });

  const [passwordStatuses, setPasswordStatuses, checkPasswordStatus] = passwordStatusManager();

  const schema = yup.object({
    password: yup.string()
      .ensure()
      .min(8, EPasswordSecurityStatuses.LENGTH)
      .matches(/(?=.*[A-Z])/, EPasswordSecurityStatuses.UPPER)
      .matches(/(?=.*[a-z])/, EPasswordSecurityStatuses.LOWER)
      .matches(/(?=.*\d)/, EPasswordSecurityStatuses.NUMBER)
      .test((value: string) => {
        setPasswordStatuses(passwordStatusesParser(value, passwordStatuses));
        return checkPasswordStatus();
      }),
  }).required();

  const {
    handleSubmit,
    control,
    register,
    // For some reason password status check does not work without form state errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isSubmitted },
  } = useForm<FormState>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  register('password', {
    onChange: () => {
      dispatch(resetAuthError());
    },
  });

  return (
    <AuthPaper>
      <>
        <TitleTypographyWrapper>
          <TitleTypography>
            Create new password
          </TitleTypography>
          <Typography
            sx={{
              color: ' #7084B4',
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            An email with a recovery link was just sent to your email
          </Typography>
        </TitleTypographyWrapper>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error: passwordError } }) => (
              <TextField
                label="New Password"
                variant="outlined"
                size="small"
                fullWidth
                value={value}
                onChange={onChange}
                error={!!passwordError && isSubmitted}
                type="password"
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          />
          <PasswordSecurityIdentificator
            statuses={passwordStatuses}
            error={isSubmitted}
          />
          <AuthButton status={status} title="Save" />
        </Box>
        <BackToLogin />
      </>
    </AuthPaper>
  );
};

export default ResetPasswordConfirm;
