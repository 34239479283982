import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { NodeViewProps } from '@tiptap/react';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { ObjectBase, TiptapNodeUpdate } from '../../../shared/Tiptap/TiptapTypes';

export type TTiptapBlockContext<T = ObjectBase> = {
  data: T,
  onUpdate:((newData: TiptapNodeUpdate<T>) => void)
} & NodeViewProps;

export const TiptapBlockContext = createContext<TTiptapBlockContext | null>(null);

interface Props {
  children: JSX.Element;
  nodeProps: NodeViewProps;
}

export const TiptapBlockContextProvider = (
  {
    children,
    nodeProps,
  }: Props,
) => {
  const onUpdate = (data: { [key: string]: any }) => {
    nodeProps.updateAttributes({ ...data });
  };

  const [context, setContext] = useState({ data: nodeProps.node.attrs, onUpdate, ...nodeProps });

  const onEventUpdate = (event: CustomEvent<{ [key: string]: any }>) => {
    onUpdate(event.detail);
  };

  useEffect(() => {
    setContext({ data: nodeProps.node.attrs, onUpdate, ...nodeProps });
    document.addEventListener(
      `${ECustomEventsNames.TIPTAP_BLOCK_UPDATE}-${nodeProps.node.attrs.blockId}`,
      onEventUpdate as EventListener,
    );
    return () => {
      document.removeEventListener(
        `${ECustomEventsNames.TIPTAP_BLOCK_UPDATE}-${nodeProps.node.attrs.blockId}`,
        onEventUpdate as EventListener,
      );
    };
  }, [nodeProps]);

  return (
    <TiptapBlockContext.Provider value={context}>
      {children}
    </TiptapBlockContext.Provider>
  );
};

export const useBlockContext = <T extends ObjectBase>() => {
  const c = useContext(TiptapBlockContext);
  return c as unknown as TTiptapBlockContext<T>;
};
