import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EStateStatus, getInitialBaseState, IStateBase } from '../commonTypes';
import { CrmApi } from '../../api/CrmApi';

const crmApi: CrmApi = new CrmApi();

export interface HubspotIntegrationState extends IStateBase {
  integration: any | null;
  isUserHaveIntegration: boolean;
}

export enum EHubspotIntegrationActions {
  GET_INTEGRATION = 'GET_INTEGRATION',
  CANCEL_INTEGRATION = 'CANCEL_INTEGRATION',
}

export const hubspotIntegrationReducerName = 'hubspotIntegration';

const initialState: HubspotIntegrationState = {
  ...getInitialBaseState(),
  integration: null,
  isUserHaveIntegration: false,
};

export const hubspotGetIntegration = createAsyncThunk(
  `${hubspotIntegrationReducerName}/${EHubspotIntegrationActions.GET_INTEGRATION}`,
  async () => crmApi.getUserIntegration('hubspot'),
);

export const hubspotCancelIntegration = createAsyncThunk(
  `${hubspotIntegrationReducerName}/${EHubspotIntegrationActions.CANCEL_INTEGRATION}`,
  async () => crmApi.cancelUserIntegration(),
);

export const hubspot = createSlice({
  name: hubspotIntegrationReducerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hubspotGetIntegration.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(hubspotGetIntegration.fulfilled, (state, { payload }) => {
      state.integration = payload;
      state.isUserHaveIntegration = true;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(hubspotGetIntegration.rejected, (state) => {
      state.status = EStateStatus.IDLE;
      state.isUserHaveIntegration = false;
    });
    builder.addCase(hubspotCancelIntegration.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(hubspotCancelIntegration.fulfilled, (state) => {
      state.integration = null;
      state.isUserHaveIntegration = false;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(hubspotCancelIntegration.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
  },
});
