import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { AppAvatar } from '../../../../../shared/AppAvatar';
import {
  NameTitleTextFieldsWrapper, NameTitleWrapper, CellTitle,
} from '../../Styles/TeamTabStyles';

type Props = {
  avatarSrc?: string
  colorId?: number
  name: string
  title?: string
};

export const NameTitleCell = ({
  avatarSrc, colorId, name, title,
}: Props) => (
  <NameTitleWrapper>
    <AppAvatar name={name} src={avatarSrc} colorId={colorId} />
    <NameTitleTextFieldsWrapper>
      <CellTitle
        className={classNames({ 'cell-title-mobile': isMobile })}
      >
        {name}
      </CellTitle>
      {title && (
        <CellTitle
          className="cell-title-grey"
        >
          {title}
        </CellTitle>
      )}
    </NameTitleTextFieldsWrapper>
  </NameTitleWrapper>
);
