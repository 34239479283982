import { SvgIcon } from '@mui/material';

export const PlusMenuIcon = (): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
  >
    <path d="M8 3.33325V12.6666" stroke="inherit" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.33325 8H12.6666" stroke="inherit" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
