/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

export const BoldIcon = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M5.9502 3.3335H12.6169C13.5009 3.3335 14.3488 3.68469 14.9739 4.30981C15.599 4.93493 15.9502 5.78277 15.9502 6.66683C15.9502 7.55088 15.599 8.39873 14.9739 9.02385C14.3488 9.64897 13.5009 10.0002 12.6169 10.0002H5.9502V3.3335Z"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.9502 10H13.4502C14.3343 10 15.1821 10.3512 15.8072 10.9763C16.4323 11.6014 16.7835 12.4493 16.7835 13.3333C16.7835 14.2174 16.4323 15.0652 15.8072 15.6904C15.1821 16.3155 14.3343 16.6667 13.4502 16.6667H5.9502V10Z"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const ItalicIcon = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M16.7832 3.3335H9.2832"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6172 16.6665H5.11719"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4502 3.3335L8.4502 16.6668"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const StrikeThrough = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M17.9502 10H3.9502"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.1169 4.1665H8.86686C8.09331 4.1665 7.35145 4.47379 6.80447 5.02078C6.25749 5.56776 5.9502 6.30962 5.9502 7.08317C5.9502 7.85672 6.25749 8.59858 6.80447 9.14557C7.35145 9.69255 8.09331 9.99984 8.86686 9.99984H13.0335C13.8071 9.99984 14.5489 10.3071 15.0959 10.8541C15.6429 11.4011 15.9502 12.143 15.9502 12.9165C15.9502 13.6901 15.6429 14.4319 15.0959 14.9789C14.5489 15.5259 13.8071 15.8332 13.0335 15.8332H5.9502"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const UnderlineIcon = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M5.9502 2.5V8.33333C5.9502 9.65942 6.47698 10.9312 7.41466 11.8689C8.35234 12.8065 9.62411 13.3333 10.9502 13.3333C12.2763 13.3333 13.548 12.8065 14.4857 11.8689C15.4234 10.9312 15.9502 9.65942 15.9502 8.33333V2.5"
      stroke="#1E293B"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.2832 17.5H17.6165"
      stroke="#1E293B"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const LinkIcon = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M15.9502 10.8333V15.8333C15.9502 16.2754 15.7746 16.6993 15.462 17.0118C15.1495 17.3244 14.7256 17.5 14.2835 17.5H5.11686C4.67483 17.5 4.25091 17.3244 3.93835 17.0118C3.62579 16.6993 3.4502 16.2754 3.4502 15.8333V6.66667C3.4502 6.22464 3.62579 5.80072 3.93835 5.48816C4.25091 5.17559 4.67483 5 5.11686 5H10.1169"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.4502 2.5H18.4502V7.5"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.2832 11.6667L18.4499 2.5"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const AlignLeft = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M14.444 9.01611H2.77734"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7773 5.68262H2.77734"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7773 12.3491H2.77734"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.444 15.6826H2.77734"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const AlignCenter = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M15.2773 9.01611H5.27734"
      stroke="#64748B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7773 5.68262H2.77734"
      stroke="#64748B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7773 12.3491H2.77734"
      stroke="#64748B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2773 15.6826H5.27734"
      stroke="#64748B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const AlignRight = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M6.11068 9.01611H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.77734 5.68262H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.77734 12.3491H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.11068 15.6826H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const Justify = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fontSize: '20px' }}
  >
    <path
      d="M6.11068 9.01611H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.11068 5.68262H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.11068 12.3491H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.11068 15.6826H17.7773"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const ArrowDown = (): JSX.Element => (
  <SvgIcon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '13px',
    }}
  >
    <path
      d="M3.27734 5.18262L6.27734 8.18262L9.27734 5.18262"
      stroke="#94A3B8"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
