import {
  SwipeableDrawer,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { AppDispatch } from '../../../core/store/store';
import { Puller } from '../../../shared/Puller/Puller';
import { FiltersTab } from '../../../features/Deals/DataGridFilter/GridFilterInput/MobileTabs/FiltersTab';
import { AppDropdownItemProps } from '../../../shared/AppDropdown/AppDropdownItem';

type Props = {
  categoryTab?: number
  allOpts: AppDropdownItemProps[];
  ownerOpts: any[],
  typeOpts: any[],
  debouncedGridFilters: any,
  filterParams: any,
  getFilterInfo: () => void;
  selectMobileFilterMenu: any;
  setLibraryFilterExpanded: any;
  setMobileFilterMenu: any;
};

export const LibraryMobileTabs = ({
  categoryTab,
  allOpts,
  ownerOpts,
  typeOpts,
  debouncedGridFilters,
  filterParams,
  getFilterInfo,
  selectMobileFilterMenu,
  setLibraryFilterExpanded,
  setMobileFilterMenu,
}: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const open = useAppSelector(selectMobileFilterMenu);

  useEffect(() => {
    dispatch(setLibraryFilterExpanded(!!Object.keys(filterParams).length));
  }, [filterParams]);

  useEffect(() => {
    getFilterInfo();
  }, [debouncedGridFilters]);

  useEffect(() => {
    if (typeof categoryTab === 'number') {
      setActiveTab(categoryTab);
    }
  }, [categoryTab]);

  const handleClose = () => {
    dispatch(setMobileFilterMenu(false));
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="temporary"
      onOpen={() => {
        dispatch(setMobileFilterMenu(true));
      }}
      swipeAreaWidth={56}
      disableSwipeToOpen
      PaperProps={{
        sx: {
          height: '100%',
          borderRadius: '0px',
          '& .mobile-table-row': {
            borderWidth: '1px',
            borderRadius: '12px',
          },
          '& .mobile-table-row-header': {
            minHeight: 'auto',
            padding: '0px',
          },
          padding: '16px 16px 8px 16px',
        },
      }}
    >
      <Puller />
      <SwipeableViews
        index={activeTab}
        onChangeIndex={setActiveTab}
        disabled
        containerStyle={{
          height: '100%',
        }}
        style={{
          height: '100%',
        }}
      >
        <FiltersTab
          items={allOpts}
          onNext={(tab) => {
            setActiveTab(tab);
          }}
          onClose={handleClose}
          title="Filters"
        />
        {(ownerOpts && ownerOpts.length) && (
          <FiltersTab
            items={ownerOpts}
            onClose={handleClose}
            title="Owner"
            enableSearch
          />
        )}
        {(typeOpts && typeOpts.length) && (
          <FiltersTab
            items={typeOpts}
            onClose={handleClose}
            title="Type"
            enableSearch
          />
        )}
      </SwipeableViews>

    </SwipeableDrawer>
  );
};
