import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import useCelloUi from '../../hooks/useCelloUi';
// import bg from './CelloBannerBG.png';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectOrganization } from '../../core/store/appState/appState';
import { CelloApi } from '../../api/CelloApi';

const celloApi = new CelloApi();

const CelloBanner = (): JSX.Element | null => {
  const organization = useAppSelector(selectOrganization);
  const [referrerName, setReferrerName] = useState('');
  const { open } = useCelloUi();
  const bg = 'https://d2nfbjsnctzi3k.cloudfront.net/cello-banner/5de829aca76d3ad174da.png';

  const getReferrerName = async () => {
    if (organization.celloReferrer) {
      celloApi.getReferrerName(organization.celloReferrer).then((res) => setReferrerName(res));
    }
  };

  useEffect(() => {
    if (organization?.celloReferrer && !referrerName) {
      getReferrerName();
    }
  }, [organization?.celloReferrer, referrerName]);

  if (!organization.celloReferrer) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: isMobile ? '343px' : 'auto',
        maxHeight: isMobile ? '343px' : '176px',
        flexDirection: isMobile ? 'column' : 'row',
        borderRadius: '24px',
        width: '100%',
        minWidth: isMobile ? '' : '400px',
        background: 'linear-gradient(143deg, #E5EDFF 40.08%, #C2DEFF 89.91%)',
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          padding: isMobile ? '24px 24px 24px 24px' : '24px 0px 24px 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: isMobile ? '100%' : 'max-content',
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[800],
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '26px',
            }}
          >
            You have 30% off thanks to being invited
            {referrerName ? ` by ${referrerName}` : ''}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[500],
              fontSize: '16px',
              lineHeight: '22px',
            }}
          >
            Invite friends and earn up to $5,000 per referral
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <AppButton
            onClick={open}
            variant="tertiary"
          >
            Invite friends
          </AppButton>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: '400px',
          height: 'calc( 100% )',
          display: 'flex',
          aspectRatio: 1388 / 608,
        }}
      >
        <img
          alt=""
          src={bg}
          style={{
            height: isMobile ? undefined : '100%',
            width: isMobile ? '100%' : undefined,
          }}
        />
      </Box>
    </Box>
  );
};

export default CelloBanner;
