import { useEffect, useRef, useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { ExtensionNames } from './Utils/TiptapHelpers';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { useItemContext } from '../Layout/ItemContextProvider';
import useTiptapBlockMenu from './Hooks/useTiptapBlockMenu';
import { NodeTypes } from './Utils/TiptapTypes';
import { selectCurrentStageId } from '../ProcessFields/lib/processItemsSlice';
import useDebounceSwitch from '../../hooks/useDebounceSwitch';

export enum TiptapCustomEvents {
  CREATE = 'CREATE',
  SET_CONTENT = 'SET_CONTENT',
  CLEAR_CONTENT = 'CLEAR_CONTENT',
  FORCE_UPDATE = 'FORCE_UPDATE',
  SAVE_UPDATE = 'SAVE_UPDATE',
}

export type TiptapCustomEventDetail = {
  eventType: TiptapCustomEvents;
  type?: ExtensionNames;
  position?: number;
  attrs?: { [key: string]: any };
  newContent?: any;
  itemId?: string;
  stageId?: string;
};

export const TiptapEventListnerPlugin = ({
  onUpdate,
}: any): null => {
  const isLayoutEditable = useAppSelector(selectLayouEditingStatus);
  const { data } = useItemContext();
  const { editor } = useCurrentEditor();
  const { replaceParagraph } = useTiptapBlockMenu({ editor });
  const currentStageId = useAppSelector(selectCurrentStageId);
  const [switchStatus, setSwitch] = useDebounceSwitch(false, 500, true);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleEditorEvent = ({
    detail: {
      eventType,
      attrs = {},
      type,
      newContent,
      position,
      stageId,
    },
  }: CustomEvent<TiptapCustomEventDetail>) => {
    switch (eventType) {
      case TiptapCustomEvents.CREATE:
        replaceParagraph(
          {
            type: type as NodeTypes,
            attrs,
            label: type,
            content: [],
          },
          position ?? editor?.$pos(editor?.state.selection.$anchor.pos).from,
        );
        break;
      case TiptapCustomEvents.SET_CONTENT:
        editor?.commands.setContent(newContent);
        break;
      case TiptapCustomEvents.FORCE_UPDATE:
        if (stageId === currentStageId) {
          editor?.commands.setContent(JSON.parse(newContent));
        }
        break;
      case TiptapCustomEvents.CLEAR_CONTENT:
        editor?.commands.clearContent();
        break;
      case TiptapCustomEvents.SAVE_UPDATE:
        if (editor && onUpdate) {
          setIsUpdate(true);
          setSwitch(true, true);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isUpdate && switchStatus && onUpdate && editor) {
      onUpdate({ items: editor.getJSON() });
      setSwitch(false, false);
      setIsUpdate(false);
    }
  }, [isUpdate, switchStatus]);

  const selected = useRef<string[]>([]);
  const selectionContent = editor?.state.selection.content().content;
  const wrapperRef = document.querySelector('.TiptapEditorWrapper');
  const selectionLength = selectionContent?.toJSON()?.length;

  if (selectionLength) {
    if (selectionLength > 1) {
      wrapperRef?.classList.add('multiselect');
    } else {
      wrapperRef?.classList.remove('multiselect');
      document.querySelectorAll('.selectedNode').forEach((item) => item.classList.remove('selectedNode'));
    }

    const newSelection: { [key: string]: boolean } = {};

    selectionContent!.forEach((item) => {
      newSelection[item.attrs.blockId as string] = true;
      document.getElementById(item.attrs.blockId)?.classList.add('selectedNode');
    });

    if (selected.current.length) {
      selected.current.forEach(
        (node) => !newSelection[node] && document.getElementById(node)?.classList.remove('selectedNode'),
      );
    }
    selected.current = Object.keys(newSelection);
  } else {
    wrapperRef?.classList.remove('multiselect');
    document.querySelectorAll('.selectedNode').forEach((item) => item.classList.remove('selectedNode'));
    if (editor?.state.selection?.$anchor?.parent) {
      document.getElementById(editor?.state.selection?.$anchor?.parent.attrs.blockId)?.classList.add('selectedNode');
    }
    selected.current = [editor?.state.selection?.$anchor?.parent.attrs.blockId];
  }

  useEffect(() => {
    if (editor && !editor.isEditable && isLayoutEditable) {
      editor?.setEditable(true);
    }
    if (editor && editor.isEditable && !isLayoutEditable) {
      editor?.setEditable(false);
    }
    if (!editor?.isFocused) {
      wrapperRef?.classList.remove('multiselect');
      document.querySelectorAll('.selectedNode').forEach((item) => item.classList.remove('selectedNode'));
      if (selected.current.length) {
        selected.current = [];
      }
    }
  }, [isLayoutEditable, data?.id, editor, editor?.isFocused]);

  useEffect(() => {
    document.addEventListener(ECustomEventsNames.TIPTAP_EDITOR_CUSTOM_EVENT, handleEditorEvent as EventListener);
    return () => {
      document.removeEventListener(ECustomEventsNames.TIPTAP_EDITOR_CUSTOM_EVENT, handleEditorEvent as EventListener);
    };
  }, []);

  return null;
};
