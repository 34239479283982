import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridCellValue } from '@mui/x-data-grid';
import { formateValueForLastActive } from '../process/helpers';
import { LastActiveContainer } from './DealPageStyles/DealsListStyle';

interface Props {
  value: GridCellValue
}

const LastActiveCell = ({
  value,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    }}
  >
    {value && (
    <LastActiveContainer>
      <Typography
        className="LastActiveName"
      >
        {
          // @ts-ignore
          formateValueForLastActive(value as number)
         }
      </Typography>
    </LastActiveContainer>
    )}
    {!value
        && (
          <Typography
            variant="caption"
          >
            -
          </Typography>
        )}
  </Box>
);

export default LastActiveCell;
