import { Box, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { Resize16px } from '../../icons/Resize';
import { Help12px } from '../../icons/Help';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { changeColResizeStatus } from '../BlockContentSection/lib/sectionsSlice';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { MediaNodeAttrs, TiptapNodeUpdate } from '../../../shared/Tiptap/TiptapTypes';
import { selectIsWidescreenPage } from '../ProcessFields/lib/processItemsSlice';

interface Props {
  containerRef?: HTMLDivElement | HTMLImageElement;
  width?: number;
  height?: number;
  onUpdate: (data: TiptapNodeUpdate<MediaNodeAttrs>) => void;
  onWidthUpdate?: (x: number) => void;
  onHeightUpdate?: (y: number) => void;
}

const round = (x: number) => Math.round(x);

const maxWidth = 732;

const MediaResizePlugin = ({
  containerRef,
  width,
  height,
  onUpdate,
  onWidthUpdate,
  onHeightUpdate,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const isWidescreen = useAppSelector(selectIsWidescreenPage);
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const sizeDisplayRef = useRef<HTMLDivElement>(null);
  const [dW, setDW] = useState(width ?? undefined);
  const [dH, setDH] = useState(height ?? undefined);
  const [isResize, setResize] = useState(false);
  const [resizeBarWidth, setResizeBarWidth] = useState<number>(0);

  if (!containerRef || isMobile || !isLayoutEdit) {
    return null;
  }

  const onResize = (newWidth: number, newHeight: number) => {
    onUpdate({ width: newWidth, height: newHeight });
  };

  const onResizeStart = (initEvent: React.MouseEvent<HTMLDivElement>) => {
    initEvent.stopPropagation();
    if (!containerRef) {
      return;
    }
    document.body.style.setProperty('cursor', 'grabbing');
    setResize(true);
    dispatch(changeColResizeStatus());

    const startX = initEvent.clientX;

    const wrapperWidth = containerRef.parentElement!.parentElement!.clientWidth;

    const initialWidth = containerRef.clientWidth;
    const initialHeight = containerRef.clientHeight;
    const initialImageCenterX: number = (initEvent.clientX - (initialWidth / 2));
    const initialImageTop: number = (initEvent.clientY - (initialHeight));

    let newWidth: number = initialWidth;
    let newHeight: number = initialHeight;

    const resize = (mvEvent: MouseEvent) => {
      let cW;
      let cH;

      if (mvEvent.buttons === 0) {
        onResize(newWidth, newHeight);
        document.body.style.setProperty('cursor', '');
        setDW(newWidth);
        setDH(newHeight);
        document.removeEventListener('mousemove', resize);
        setResize(false);
        dispatch(changeColResizeStatus());
      }

      if (mvEvent.ctrlKey || mvEvent.metaKey) {
        cW = initialWidth;
      } else {
        cW = (initialWidth + (mvEvent.clientX - startX) > wrapperWidth) ? wrapperWidth : round((mvEvent.clientX - initialImageCenterX) * 2);
      }

      if (mvEvent.altKey) {
        cH = round(cW / (initialWidth / initialHeight));
      } else if (mvEvent.shiftKey) {
        cH = initialHeight;
      } else {
        cH = round(mvEvent.clientY - initialImageTop);
      }

      if (cH < 32) {
        cH = 32;
      }

      if (cW < 32) {
        cW = 32;
      }
      if (!isWidescreen && cW > 732) {
        cW = maxWidth;
      }

      // eslint-disable-next-line no-param-reassign
      containerRef.style.width = `${cW}px`;
      // eslint-disable-next-line no-param-reassign
      containerRef.style.height = `${cH}px`;

      if (sizeDisplayRef.current) {
        sizeDisplayRef.current.innerText = `${cW}x${cH}`;
      }

      if (onWidthUpdate) {
        onWidthUpdate(cW);
      }
      if (onHeightUpdate) {
        onHeightUpdate(cH);
      }

      setResizeBarWidth(cW);

      newWidth = cW;
      newHeight = cH;
    };

    document.addEventListener('mousemove', resize);
  };

  return (
    <Box
      className={classNames('MediaResizePlugin', { 'MediaResizePlugin-active': isResize })}
      sx={{
        height: '0px !important',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: resizeBarWidth > 0 ? resizeBarWidth : '100%',
        // visibility: 'hidden',
        visibility: 'visible',
        '&.MediaResizePlugin-active': {
          visibility: 'visible',
          cursor: 'grabbing',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          height: '24px !important',
          width: '100%',
          fontSize: '12px',
          borderRadius: '0 0 10px 10px',
          paddingLeft: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: 'rgba(69, 82, 115, 0.5)',
          backdropFilter: 'blur(1.6px)',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            gap: '6px',
          }}
        >
          {
            dW
           && containerRef.clientWidth > 90
            && (
              <>
                <Box
                  ref={sizeDisplayRef}
                  sx={{
                    display: 'flex',
                  }}
                >
                  {dW && round(dW)}
                  x
                  {dH && round(dH)}
                </Box>
                <Tooltip
                  arrow
                  title={navigator.platform.indexOf('Mac') > -1
                    ? 'Use Command, CTRL and Shift when resizing ' : 'Use Alt, CTRL and Shift when resizing'}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        background: (theme) => theme.palette.grey[400],
                      },
                    },
                    arrow: {
                      sx: {
                        color: (theme) => theme.palette.grey[400],
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Help12px
                      sx={{
                        fontSize: '12px',
                      }}
                    />
                  </Box>
                </Tooltip>
              </>
            )
          }
        </Box>
        <Box
          className={classNames('MediaResizePluginIcon', { 'MediaResizePluginIcon-active': isResize })}
          onMouseDown={onResizeStart}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          sx={{
            height: '24px !important',
            width: '24px',
            cursor: 'grab',
            stroke: '#fff',
            '&:hover': {
              stroke: 'rgba(251, 152, 42, 1)',
            },
            '&.MediaResizePluginIcon-active': {
              cursor: 'grabbing',
              stroke: 'rgba(251, 152, 42, 1)',
            },
          }}
        >
          <Resize16px
            sx={{
              stroke: 'inherit',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MediaResizePlugin;
