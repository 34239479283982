import { ProcessStageDTO } from '../../shared/Field/Field';
import { addItemUpdate, selectCurrentStageId } from '../features/ProcessFields/lib/processItemsSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';

const useCurrentItemUpdate = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(selectCurrentStageId);

  const updateCurrentItem = (itemId: string, data: Partial<ProcessStageDTO>) => {
    dispatch(addItemUpdate({ id: itemId, data }));
  };

  const updateCurrentItemFile = (data: Pick<ProcessStageDTO, 'files'>) => {
    dispatch(addItemUpdate({
      id,
      data: {
        files: data,
      },
    }));
  };

  return {
    updateCurrentItem,
    updateCurrentItemFile,
  };
};

export default useCurrentItemUpdate;
