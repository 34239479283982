import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';
import deepmerge from 'deepmerge';
import { useMemo } from 'react';
import { TeamTabDesktopDataGrid } from '../Styles/TeamTabStyles';
import { ExtendedGridColumns } from '../../../../types/mobile-table';
import TeamTabMobileTable, { MobileGridProps } from './TeamTabMobileTable';

type Props<T> = {
  columns: ExtendedGridColumns,
  rows: readonly T[],
  renderDesktopGridComponent?: (props: DataGridProps) => JSX.Element,
  renderMobileGridComponent?: (props: MobileGridProps) => JSX.Element,
  getId: (row: T) => string | number,
  renderMobileTitle: (row: T) => string | JSX.Element,
  renderMobileMenu: (row: T) => JSX.Element | null,
  onRowClick?: (row: any) => void
};

type DefaultProps<T> = Required<Pick<Props<T>, 'onRowClick' | 'renderDesktopGridComponent' | 'renderMobileGridComponent'>>;

// eslint-disable-next-line
export const TeamTabDataGrid = <T,>({
  renderMobileMenu,
  renderMobileTitle,
  rows,
  columns,
  getId,
  ...restProps
}: Props<T>) => {
  const grid = useMemo(() => {
    const {
      renderDesktopGridComponent,
      renderMobileGridComponent,
      onRowClick,
    } = deepmerge<Props<T> & DefaultProps<T>>({
      renderDesktopGridComponent: (gridProps) => <TeamTabDesktopDataGrid {...gridProps} />,
      renderMobileGridComponent: (gridProps) => <TeamTabMobileTable {...gridProps} />,
      onRowClick: () => {},
    }, restProps);

    return isMobile
      ? renderMobileGridComponent({
        rows,
        columns,
        onRowClick,
        getId,
        renderTitle: renderMobileTitle,
        renderMenu: renderMobileMenu,
      })
      : renderDesktopGridComponent({
        columns,
        rows,
        onRowClick,
        disableSelectionOnClick: true,
        disableColumnMenu: true,
        hideFooter: true,
        getRowSpacing: () => ({
          top: 4,
          bottom: 4,
        }),
      });
  }, [restProps]);

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      overflow: 'overlay',
    }}
    >
      { grid }
    </Box>
  );
};
