import { ECustomDomainEnableStatus } from "./CustomDomains";
import { UserRolesDTO } from './user-organization';

export class OrganizationDTO {
    id: string;
    name: string;
    street: string;
    city: string;
    zipcode: string;
    phone: string;
    email: string;
    isPremium: boolean;
    orgSupportId: string;
    organizationLogo?: string;
    wideLogo?: string;
    domain?: string;
    currency?: string
    customDomain?: string;
    celloReferrer?: string;
    customDomainEnabledStatus?: ECustomDomainEnableStatus;
    enableCustomDomain?: boolean;
    delete?: boolean;
    isPublicApiEnabled?: boolean;
}

export const DefaultOrgCurrency = 'USD'

export class OrganizationNameAndLogoDTO {
    name: string;
    organizationLogo?: string;
    role: string
}

export interface OrganizationAdminDTO {
    id: string;
    name: string;
    email: string;
    organizationLogo: string;
    orgSupportId: string;
    domain?: string;
    ownerCount: number;
    adminCount: number;
    userCount: number;
    tariff: string;
    currency: string;
    totalProcesses: number;
    enableCustomDomain?: boolean;
    isPublicApiEnabled?: boolean;
}

export interface UpdateOrganizationAdminPayload {
    organization: Partial<OrganizationDTO>
    users: Partial<UserRolesDTO>[];
}
