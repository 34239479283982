import { styled } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { greySidebarContrastText } from '../../../core/utils/greyContrastText';

export const SidebarFooterWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${greySidebarContrastText(theme as Theme, 0.1)}`,
  '&.sidebar-footer-wrapper': {
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: theme.dealOverrides.sidebar.backgroundColor,
  },
}));
