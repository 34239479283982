import { SvgIcon } from '@mui/material';

export const CompanyLogoIcon = (): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M17 21V19C17 17.9391 16.5786 16.9217
15.8284 16.1716C15.0783 15.4214 14.0609 15 13
15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143
16.9217 1 17.9391 1 19V21"
      fill="none"
      stroke="#7084B4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11C11.2091 11 13 9.20914 13 7C13
 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086
 5 7C5 9.20914 6.79086 11 9 11Z"
      fill="none"
      stroke="#7084B4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 20.9999V18.9999C22.9993 18.1136
 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517
  20.8581 15.3515 20 15.1299"
      fill="none"
      stroke="#7084B4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 3.12988C16.8604 3.35018
17.623 3.85058 18.1676 4.55219C18.7122
 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078
 7.89305 18.7122 8.75596 18.1676 9.45757C17.623
  10.1592 16.8604 10.6596 16 10.8799"
      fill="none"
      stroke="#7084B4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
