import { Box } from '@mui/material';
import { selectFeedAgenda, setSelectedFeedAgenda } from '../../../features/DealFeed/lib/feedSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import useBlockUpdateEvent from '../../../features/Tiptap/Hooks/useBlockUpdate';
import { AgendaNodeAttrs } from '../../../../shared/Tiptap/TiptapTypes';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { useTaskPermissions } from '../../../hooks/useTaskPermissions';
import { ETaskStatus } from '../../../../shared/TaskDTO';
import { AppCheckbox } from '../../../shared/AppCheckbox/AppCheckbox';
import useFrontEventsApi from '../../../hooks/useFrontEventsApi';
import { AgendaNodeAssignee } from '../../../features/Tiptap/Extensions/AgendaNode/AgendaNodeComponent/AgendaNodeAssignee';

const AgendaFeedHeader = (): JSX.Element => {
  const selectedAgenda = useAppSelector(selectFeedAgenda);
  const { onUpdate } = useBlockUpdateEvent<AgendaNodeAttrs>(selectedAgenda.blockId!);
  const { createStatusUpdateEvent, createAssigneesUpdateEvent } = useFrontEventsApi();
  const dispatch = useAppDispatch();
  const { isActionStatusEditable, isUserAssignEnabled } = useTaskPermissions();

  const onCheckChange = (checked?: boolean) => {
    if (selectedAgenda && selectedAgenda.blockId) {
      onUpdate({
        status: checked ? ETaskStatus.COMPLETE : ETaskStatus.PENDING,
      });
      createStatusUpdateEvent({
        from: selectedAgenda.status,
        to: checked ? ETaskStatus.COMPLETE : ETaskStatus.PENDING,
        taskId: selectedAgenda.blockId,
      });
      const renewedAgenda = { ...selectedAgenda };
      renewedAgenda.status = checked ? ETaskStatus.COMPLETE : ETaskStatus.PENDING;
      dispatch(setSelectedFeedAgenda(renewedAgenda));
    }
  };

  const onAssigneesUpdate = (u: string[], t: string[]) => {
    onUpdate({ assigneesIds: u, assigneesTeams: t });
    createAssigneesUpdateEvent({
      from: selectedAgenda.assigneesIds,
      to: u,
      fromTeams: selectedAgenda.assigneesTeams,
      toTeams: t,
      taskId: selectedAgenda.blockId,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '32px',
      }}
    >
      <AppButton
        startIcon={(
          <AppCheckbox
            size="s"
            variant={selectedAgenda.status === ETaskStatus.COMPLETE ? 'secondary' : 'success-primary'}
            isEditable={isActionStatusEditable}
            isChecked={selectedAgenda.status === ETaskStatus.COMPLETE}
            onCheckChange={onCheckChange}
          />
        )}
        onClick={() => onCheckChange(selectedAgenda.status !== ETaskStatus.COMPLETE)}
        variant={selectedAgenda.status === ETaskStatus.COMPLETE ? 'primary-success' : 'secondary-success'}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        {selectedAgenda.status === ETaskStatus.PENDING ? 'Complete' : 'Completed'}
      </AppButton>
      <AgendaNodeAssignee
        showTeams
        size={30}
        isEditable={isUserAssignEnabled}
        assigneesIds={selectedAgenda.assigneesIds}
        assigneesTeams={selectedAgenda.assigneesTeams}
        onAssigneesUpdate={onAssigneesUpdate}
      />
    </Box>
  );
};

export default AgendaFeedHeader;
