import { Box, styled } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const FileItemContainer = styled(Box)(() => ({
  display: 'inline-flex',
  gap: '12px',
  alignItems: 'center',
}));

export const FileItemName = styled(Box)(() => ({
  color: stagesetColors.grey[600],
}));

export const LibraryGridContainer = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  flexDirection: 'column',
  '&.grid-loading': {
    position: 'relative',
    '&.grid-loading:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      height: '100%',
      backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%)',
      zIndex: 3,
    },
  },

}));
