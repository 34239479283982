import { JSONContent } from '@tiptap/core';
import { MediaNodeAttrs } from '../../../../shared/Tiptap/TiptapTypes';

export enum TiptapClassNames {
  MEDIA = 't-media-node',
  AGENDA = 't-agenda-node',
}

export enum ExtensionNames {
  PARAGRAPH = 'paragraph',
  HEADING = 'heading',
  BULLET_LIST = 'bulletList',
  ORDERED_LIST = 'orderedList',
  MEDIA = 'media',
  AGENDA = 'agenda',
  LINK_PREVIEW = 'linkPreview',
  DOCUMENTS = 'documents',
  PRICING_TABLE = 'pricingTable',
  TABLE = 'table',
  TABLE_ROW = 'tableRow',
  TABLE_HEADER = 'tableHeader',
  BUTTON = 'buttonNode',
  TEXT = 'text',
}

export const generateDocWithMediaNode = (data: MediaNodeAttrs): JSONContent => ({
  type: 'doc',
  content: [
    {
      type: 'media',
      attrs: {
        src: data.src,
        doc: data.doc,
        subType: data.subType,
        storageType: data.storageType,
      },
    },
  ],
});

export const generateDocWithCanvasNode = (): JSONContent => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      attrs: {
        openMenu: false,
        textAlign: 'left',
      },
    },
  ],
});
