import currencyToSymbolMap from 'currency-symbol-map/map';
import {
  Box,
  FormControl,
  Popover,
  Slide,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import {
  DEFAULT_PRICING_ITEM,
  DEFAULT_PRICING_ITEM_DISCOUNT,
  DEFAULT_PRICING_ITEM_TAX,
  DEFAULT_PRICING_ITEM_UNITS,
  PricingItem,
  PricingTableAttributes,
} from '../../../shared/pricing-table/PricingTableTypes';
import { useAppDispatch } from '../../hooks/stateHooks';
import PricingTableItemMenu from './PricingTableItemMenu/PricingTableItemMenu';
import { EPricingItemMenuActions } from './PricingTableItemMenu/PricingItemMenuTypes';
import {
  editProduct,
} from './lib/pricingTableSlice';
import { AppInput } from '../../shared/AppInput/AppInput';
import { AppButton } from '../../shared/AppButton/AppButton';
import MoreVert from '../../icons/MoreVertical.svg';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { stagesetColors } from '../../theme/stagesetPalette';
import { ItemsContainer, MobileTitle, Value } from './PricingTableHeaderStyles';
import {
  EFeedTab,
  setFeedTab,
  setFeedTitle,
} from '../DealFeed/lib/feedSlice';
import { ChevronDown16 } from '../../icons/ChevronDown';
import { ChevronUp16 } from '../../icons/ChevronUp';
import { handleNumericalData } from '../Stages/Helpers/PricingTableHelpers';
import { MoreVertical16 } from '../../icons/MoreVertical16';
import { useBlockContext } from '../Tiptap/TiptapBlockContextProvider';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';

interface Props {
  task: string;
  index: number;
  canEdit?: boolean;
  currency?: string;
  duplicate?: (index: number) => void;
  moveItem?: (index: number, direction: number) => void;
  updateTotal?: (index: number, taskObject: PricingItem) => void;
  deleteTotal?: (index: number) => void;
}

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const PricingTableItem = ({
  task,
  index,
  canEdit,
  currency,
  duplicate,
  moveItem,
  updateTotal,
  deleteTotal,
}: Props): JSX.Element => {
  const [taskObject, setTaskObject] = useState<PricingItem>(DEFAULT_PRICING_ITEM);

  const { data, onUpdate } = useBlockContext();

  const helperTextInitialValue: string = `Min ${taskObject.units.min}${taskObject.units.max ? `, Max ${taskObject.units.max}` : ''}`;
  const dispatch = useAppDispatch();
  const myRef = useRef(null);
  const myButtonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [selected, setSelected] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [anchorUnits, setAnchorUnits] = useState<HTMLElement | null>(null);
  const unitsRef = useRef(null);
  const [editUnitValue, setEditUnitValue] = useState<number | null>(0);
  const [inputError, setInputError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');

  const { createPricingTableUnitsChangeEvent } = useFrontEventsApi();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(myRef?.current);
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(myButtonRef?.current);
  };

  const getDiscount = (value: number): number => {
    let discountValue = 0;
    if (taskObject?.discount?.enabled) {
      if (taskObject.discount?.isPercentage) {
        discountValue = (taskObject.discount?.value || 0) * value * 0.01;
      } else {
        discountValue = taskObject.discount?.value || 0;
      }
    }

    return discountValue;
  };

  const getTax = (value: number): number => {
    if (taskObject?.tax.value && taskObject?.tax.enabled) {
      if (taskObject.tax.isPercentage) {
        return value * taskObject.tax.value * 0.01;
      }
      return taskObject.tax.value;
    }
    return 0;
  };

  const getTotal = () => {
    let itemValueBeforeDisc = (taskObject?.price || 0) * (taskObject?.units?.value || 0);
    const discount = getDiscount(itemValueBeforeDisc);
    itemValueBeforeDisc -= discount;
    const taxAmount = getTax(itemValueBeforeDisc);
    itemValueBeforeDisc += taxAmount;

    return Math.round((itemValueBeforeDisc + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    try {
      const parsedItem = JSON.parse(task as string);
      setTaskObject(parsedItem);
    } catch (e) {
      const parsedItem = _.cloneDeep(DEFAULT_PRICING_ITEM);
      setTaskObject(parsedItem);
    }
  }, [task]);

  const sendNewValue = () => {
    if (updateTotal) {
      updateTotal(index, taskObject);
    }
  };

  useEffect(() => {
    sendNewValue();
    setTotal(getTotal());
    setHelperText(helperTextInitialValue);
  }, [taskObject]);

  const itemActions: { [key in EPricingItemMenuActions]: () => void } = {
    [EPricingItemMenuActions.EDIT]: () => {
      dispatch(setFeedTab(EFeedTab.PRICING_TABLE));
      dispatch(setFeedTitle('Edit product'));
      dispatch(editProduct({ fieldId: data.blockId, productId: index, product: taskObject }));
    },
    [EPricingItemMenuActions.DUPLICATE]: () => {
      if (duplicate) {
        duplicate(index);
      }
    },
    [EPricingItemMenuActions.CLEAR_CONTENT]: () => {
      setTaskObject({
        ...taskObject,
        tax: DEFAULT_PRICING_ITEM_TAX,
        discount: DEFAULT_PRICING_ITEM_DISCOUNT,
        price: 0,
        units: DEFAULT_PRICING_ITEM_UNITS,
      });
      onUpdate({
        items: [...data.items].map((item, i) => {
          if (i === index) {
            return JSON.stringify({
              ...JSON.parse(item),
              tax: DEFAULT_PRICING_ITEM_TAX,
              discount: DEFAULT_PRICING_ITEM_DISCOUNT,
              price: 0,
              units: DEFAULT_PRICING_ITEM_UNITS,
            });
          }
          return item;
        }),
      });
    },
    [EPricingItemMenuActions.MOVE_UP]: () => {
      if (moveItem) {
        moveItem(index, -1);
      }
    },
    [EPricingItemMenuActions.MOVE_DOWN]: () => {
      if (moveItem) {
        moveItem(index, 1);
      }
    },
    [EPricingItemMenuActions.DELETE]: () => {
      if (deleteTotal) {
        deleteTotal(index);
      }
    },
  };

  const handleAction = (action: EPricingItemMenuActions) => {
    itemActions[action]();
    setAnchorEl(null);
  };

  const openUnits = () => {
    setEditUnitValue(taskObject.units.value);
    setAnchorUnits(unitsRef?.current);
  };

  const handleCloseUnits = () => {
    setAnchorUnits(null);
  };

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = handleNumericalData({
      currentValue: event.target.value,
      previousValue: editUnitValue,
      fraction: true,
    });
    if (value !== null
      && taskObject.units.min !== null
      && value === 0
      && taskObject.units.min === 0
    ) {
      setInputError(false);
      setHelperText(helperTextInitialValue);
      setEditUnitValue(value);
      return null;
    }
    if (!value
      || (value
        && taskObject.units.min
        && value < taskObject.units.min)) {
      setInputError(true);
      setHelperText(`Min quantity is ${taskObject.units.min}`);
      setEditUnitValue(value);
      return null;
    }
    if (value
      && taskObject.units.max
      && value > taskObject.units.max
    ) {
      setInputError(true);
      setHelperText(`Max quantity is ${taskObject.units.max}`);
      setEditUnitValue(value);
      return null;
    }
    setInputError(false);
    setHelperText(helperTextInitialValue);
    setEditUnitValue(value);
    return null;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        width: '100%',
        borderTop: isMobile ? '' : `1px solid ${stagesetColors.newGrey[100]} !important`,
        '&:not(.mobile):hover .task-drag-indicator': {
          opacity: 1,
        },
        ...(isMobile && {
          flexDirection: 'column',
          border: `1px solid ${stagesetColors.newGrey[100]} !important`,
          borderRadius: '12px',
          padding: '16px',
          '&:not(:last-child)': {
            marginBottom: '12px',
          },
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
        }}
      >
        <ItemsContainer
          className="name-container"
          sx={{
            width: isMobile ? '100%' : '52%',
            maxWidth: isMobile ? '100%' : '52%',
            textAlign: 'left',
          }}
        >
          <Box sx={{
            display: 'grid',
            width: isMobile ? '50%' : '100%',
            gap: '6px',
          }}
          >
            <Typography
              sx={{
                wordBreak: 'break-word',
                ...(isMobile
                  ? {
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }
                  : {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '16px',
                  }),
              }}
            >
              {taskObject.name}
            </Typography>
            {taskObject?.shortDescription
              && (
                <Typography
                  sx={{
                    color: stagesetColors.newGrey[500],
                    fontSize: '14px',
                    lineHeight: '16px',
                    wordBreak: 'break-word',
                  }}
                >
                  {taskObject.shortDescription}
                </Typography>
              )}
            {(taskObject?.discount?.enabled && !!taskObject?.discount?.value)
              && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '16px',
                    display: 'inline-block',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      display: 'inline',
                      padding: '2px 5px',
                      backgroundColor: stagesetColors.green[100],
                      wordBreak: 'break-word',
                    }}
                  >
                    {taskObject.discount.name}
                    {' '}
                    {!taskObject.discount.isPercentage ? currency : ''}
                    {taskObject.discount.value}
                    {taskObject.discount.isPercentage ? '%' : ''}
                  </Typography>
                </Typography>
              )}
          </Box>
          {isMobile && canEdit && (
            <Box sx={{ textAlign: 'right', width: '50%' }} ref={myButtonRef}>
              <AppIconButton
                id="action-more-menu-button"
                onClick={handleButtonClick}
                onMouseDown={(e) => e.stopPropagation()}
                icon={<MoreVert />}
              />
            </Box>
          )}
        </ItemsContainer>
        <ItemsContainer
          className="price-container"
        >
          {isMobile && (
            <MobileTitle>
              Price
            </MobileTitle>
          )}
          <Value>
            {taskObject.currency ? currencyToSymbolMap[taskObject.currency] : currency}
            {taskObject.price || 0}
          </Value>
        </ItemsContainer>
        <ItemsContainer
          className="units-container"
          sx={{
            ...(taskObject.units.enabled
              && !isMobile && {
              paddingTop: '4px',
              paddingRight: '6px',
            }),
          }}
        >
          {isMobile && (
            <MobileTitle>
              Units
            </MobileTitle>
          )}
          {taskObject.units.enabled
            ? (
              <FormControl
                id="give-me-a-break"
                variant="standard"
                sx={{
                  padding: isMobile ? '' : '2px',
                }}
              >
                <Box
                  ref={unitsRef}
                  onClick={openUnits}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    stroke: stagesetColors.newGrey[500],
                    borderRadius: '4px',
                    gap: '6px',
                    '& path': {
                      stroke: 'inherit',
                    },
                    '&:hover': {
                      backgroundColor: stagesetColors.newGrey[200],
                    },
                  }}
                >
                  {!anchorUnits
                    ? (
                      <ChevronDown16 />
                    )
                    : <ChevronUp16 />}
                  <Value>
                    {taskObject.units.value || 0}
                  </Value>
                </Box>
                <Popover
                  id={anchorUnits ? 'owner-card-popover' : undefined}
                  open={Boolean(anchorUnits)}
                  anchorEl={isMobile ? undefined : anchorUnits}
                  anchorPosition={isMobile ? {
                    top: 0,
                    left: 0,
                  } : undefined}
                  onClose={handleCloseUnits}
                  TransitionComponent={isMobile ? Transition : undefined}
                  slotProps={{
                    paper: {
                      sx: {
                        boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), '
                          + '0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: stagesetColors.newGrey[500],
                        padding: '16px',
                        width: '280px',
                        ...(isMobile && {
                          width: '100%',
                          maxWidth: '100%',
                          left: '0 !important',
                          minHeight: '200px',
                          top: 'auto !important',
                          bottom: '0',
                        }),
                      },
                    },
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    ...(isMobile && {
                      '.MuiModal-backdrop': {
                        backgroundColor: 'black',
                        opacity: '0.2 !important',
                      },
                    }),
                  }}
                >
                  {isMobile
                    && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '5px 0 20px',
                          fontWeight: '600',
                          fontSize: '16px',
                        }}
                      >
                        <Box>
                          {taskObject.name}
                        </Box>
                      </Box>
                    )}
                  <Box
                    sx={{
                      paddingBottom: isMobile ? '35px' : '25px',
                    }}
                  >
                    <AppInput
                      error={inputError}
                      fullWidth
                      label="Units"
                      defaultValue={editUnitValue}
                      type="number"
                      value={editUnitValue}
                      helperText={helperText}
                      InputProps={{ inputProps: { min: taskObject.units.min || 0, max: taskObject.units.max } }}
                      onChange={(event) => {
                        handleInput(event);
                      }}
                    />
                  </Box>
                  <Box>
                    <AppButton
                      disabled={inputError}
                      onClick={(e) => {
                        const units = {
                          ...taskObject.units || {
                            enabled: false, min: 0, max: 0, value: 0,
                          },
                          value: editUnitValue,
                        };
                        setTaskObject({
                          ...taskObject,
                          units,
                        });
                        createPricingTableUnitsChangeEvent({
                          from: taskObject.units.value,
                          to: editUnitValue,
                          blockId: data.blockId,
                          name: taskObject.name,
                        });
                        setAnchorUnits(null);
                      }}
                      fullWidth
                      variant="primary"
                      size="l"
                    >
                      Apply
                    </AppButton>
                  </Box>
                </Popover>
              </FormControl>
            )
            : (
              <Value>
                {taskObject.units.value || 0}
              </Value>
            )}
        </ItemsContainer>
        <ItemsContainer
          className="tax-container"
        >
          {isMobile && (
            <MobileTitle>
              Tax
            </MobileTitle>
          )}
          <Value>
            {taskObject.tax.enabled && taskObject.tax.value
              ? (
                <>
                  {!taskObject.tax.isPercentage ? currency : ''}
                  {taskObject.tax.value}
                  {' '}
                  {taskObject.tax.isPercentage ? '%' : ''}
                </>
              ) : 0}
          </Value>
        </ItemsContainer>
        <ItemsContainer
          className="total-container"
          sx={{
            borderRight: 'none',
          }}
        >
          {isMobile && (
            <MobileTitle>Total</MobileTitle>
          )}
          <Value
            sx={{ fontWeight: 500 }}
          >
            {currency}
            {total}
          </Value>
        </ItemsContainer>
      </Box>
      {!isMobile && canEdit && (
        <Box
          ref={myRef}
          className="task-drag-indicator"
          sx={{
            position: 'absolute',
            top: '30%',
            right: '-9px',
            height: '30px',
            cursor: 'pointer',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
            backgroundColor: stagesetColors.newGrey[200],
            borderRadius: '8px',
            padding: '8px 0px',
            '& path': {
              stroke: stagesetColors.grey[800],
            },
          }}
          onClick={handleOpen}
        >
          <MoreVertical16 />
        </Box>
      )}
      <PricingTableItemMenu
        correctDepth // ToDo
        anchorEl={anchorEl}
        open={!!anchorEl}
        handleAction={handleAction}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default PricingTableItem;
