import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AdminPageContentType } from '../../../../shared/admin/Types';
import { useAppSelector } from '../../../hooks/stateHooks';
import { selectAdminGridToRender } from '../state/adminSlice';
import AdminDeletedOrganizationsContainer from './AdminDeletedOrganizationsContainer';
import AdminOrganizationsContainer from './AdminOrganizationsContainer';
import AdminTabsHeader from './AdminTabsHeaders';
import AdminFeed from '../AdminFeed';
import AdminRoomStats from '../Rooms/AdminRoomStats';

const AdminOrganizations = (): JSX.Element => {
  const gridToRender = useAppSelector(selectAdminGridToRender);

  const renderGridContainer = () => {
    switch (gridToRender) {
      case AdminPageContentType.Active:
        return (
          <AdminOrganizationsContainer />
        );

      case AdminPageContentType.Deleted:
        return (
          <AdminDeletedOrganizationsContainer />
        );
      case AdminPageContentType.Rooms:
        return (
          <AdminRoomStats />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <AdminTabsHeader
        gridToRender={gridToRender}
      />
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          maxHeight: isMobile ? 'calc( 100% - 136px )' : 'calc( 100% - 160px )',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {renderGridContainer()}
      </Box>
      <AdminFeed />
    </>
  );
};

export default AdminOrganizations;
