import { BubbleMenu } from '@tiptap/react';
import TiptapButtonNodeToolbarItems from './TiptapButtonNodeToolbarItems';
import TiptapToolbarItemsWrapper from './TiptapToolbarItemsWrapper';

const TiptapButtonToolbar = (): JSX.Element | null => (
  <BubbleMenu
    shouldShow={
        ({ editor: e }) => !document.querySelector('.TiptapBlockMenu-open')
          && e.isEditable
          && e?.isActive('buttonNode')
      }
  >
    <TiptapToolbarItemsWrapper>
      <TiptapButtonNodeToolbarItems />
    </TiptapToolbarItemsWrapper>
  </BubbleMenu>
);

export default TiptapButtonToolbar;
