export type ProfileTabItem = {
  name: 'avatar'
  | 'fullName'
  | 'lastName'
  | 'email'
  | 'contactEmail'
  | 'title'
  | 'phone'
  | 'externalLinks.linkedIn'
  | 'externalLinks.calendly',
  label: string,
  disabled: boolean,
};

export const profileTabItemsWitHalfWidth: ProfileTabItem[] = [
  {
    name: 'fullName',
    label: 'First name',
    disabled: false,
  },
  {
    name: 'lastName',
    label: 'Last name',
    disabled: false,
  },
  {
    name: 'email',
    label: 'Account email',
    disabled: true,
  },
  {
    name: 'contactEmail',
    label: 'Contact email',
    disabled: false,
  },
];

export const profileTabItemsWitFullWidth: ProfileTabItem[] = [
  {
    name: 'title',
    label: 'Job title',
    disabled: false,
  },
  {
    name: 'phone',
    label: 'Phone',
    disabled: false,
  },
  {
    name: 'externalLinks.linkedIn',
    label: 'LinkedIn',
    disabled: false,
  },
  {
    name: 'externalLinks.calendly',
    label: 'Meeting scheduler link',
    disabled: false,
  },
];
