import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import {
  validateGuest, logout, shareLogin, confirmGuest, selectAuthToken, selectIsAuthenticated,
} from '../state/authState';
import { EAppRoutes } from '../../../core/router';
import { useQuery } from '../../../hooks/useQuery';
import { GuestValidationResponce } from '../../../../shared/schema/auth.shcema';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { IGuestJWTokenPayload } from '../../../../shared/schema/token.schema';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';
import { TitleTypographyWrapper, TitleTypography, TitleAdditionalTypography } from '../Components/AuthStyledComponents';

const DealGuestAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const authToken = useAppSelector(selectAuthToken);
  const { id } = useParams();
  const query = useQuery();
  const [isError, setError] = useState(false);
  const [isValidGuest, setValidGuest] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [hash, setHash] = useState<string>('');
  const [shouldActivate, setShouldActivate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getValidation = (vEmail: string, vProcessId: string, validHashes?: string[]) => dispatch(validateGuest(
    {
      processId: vProcessId!,
      email: vEmail,
      validHashes,
    },
  ))
    .then((res: { payload: GuestValidationResponce }) => {
      if (res.payload.isConfirmed) {
        navigate(`${EAppRoutes.deal}/${vProcessId}`);
      }
      if (res.payload.isValid) {
        setValidGuest(true);
        if (res.payload.hash) {
          setHash(res.payload.hash);
        }
        if (res.payload.shouldActivate) {
          setShouldActivate(true);
        }
        setTitle(res.payload.title);
        setDescription(res.payload.description);
      }
      if (!res.payload.isValid) {
        setError(true);
      }
    });

  useEffect(() => {
    if (!isAuthenticated) {
      if (id && !!query.has('email')) {
        getValidation(query.get('email')!, id);
      }
      if (id && !query.has('email')) {
        dispatch(shareLogin(
          {
            processId: id,
          },
        ))
          .unwrap()
          .catch(
            () => {
              setTimeout(() => {
                dispatch(
                  logout(),
                );
                navigate(EAppRoutes.login);
              }, 2000);
            },
          );
      }
      if (!id && !!query.has('email') && !!query.has('processId')) {
        getValidation(query.get('email')!, query.get('processId')!);
      }
    }
    if (isAuthenticated) {
      const {
        email,
        validHashes,
      }: IGuestJWTokenPayload = jwtDecode<IGuestJWTokenPayload>(authToken);
      if (validHashes) {
        getValidation(email, query.get('processId')!, validHashes);
      }
    }
  }, [id, query, isAuthenticated, authToken]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        dispatch(
          logout(),
        );
        navigate(EAppRoutes.login);
      }, 2000);
    }
  }, [isError]);

  const confirm = async () => {
    if (confirmationCode && !loading) {
      setLoading(true);
      const res: any = await dispatch(confirmGuest({ hash, confirmationCode, shouldActivate }));
      if (res?.payload?.tokenType && res?.payload?.userId) {
        navigate(`${EAppRoutes.deal}/${id ?? query.get('processId')}`);
      }
      if (!res?.payload) {
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      confirm();
    }
  };

  useEffect(() => {
    if (confirmationCode) {
      document.addEventListener('keydown', handleSubmit);
    }
    return () => {
      document.removeEventListener('keydown', handleSubmit);
    };
  }, [confirmationCode]);

  if (isValidGuest) {
    return (
      <AuthPaper>
        <TitleTypographyWrapper>
          <TitleTypography>
            {title}
          </TitleTypography>
        </TitleTypographyWrapper>
        <TitleAdditionalTypography>
          {description}
        </TitleAdditionalTypography>
        <AppInput
          size="m"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
        <AppButton
          size="l"
          variant="primary"
          onClick={confirm}
          loading={loading}
        >
          Confirm
        </AppButton>
      </AuthPaper>
    );
  }

  return null;
};

export default DealGuestAuth;
