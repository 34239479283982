import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { greySidebarContrastText } from '../../../core/utils/greyContrastText';

export const AppSidebar = styled(Box)(({ theme }) => ({
  '&.sidebar': {
    width: '300px',
    maxWidth: '100vw',
    justifyContent: 'space-between',
    zIndex: 5,
    outline: `1px solid ${greySidebarContrastText(theme, 0.1)}`,
    background: stagesetColors.white[100],
  },
  '&.sidebar-mobile': {
    width: '100%',
    height: 'calc(100%)',
  },
  '&.sidebar-settings': {
    width: '240px',
    padding: '0px 16px',
    backgroundColor: stagesetColors.white[100],
  },
}));

export const SettingsTabHeader = styled(Typography)(() => ({
  lineHeight: '26px',
  fontSize: '20px',
  fontWeight: 600,
  padding: '12px 0',
  color: stagesetColors.newGrey[800],
}));

export const SidebarHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  marginBottom: '8px',
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  color: greySidebarContrastText(theme, 0.8),
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: 600,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
  '&.grey-header-title': {
    color: stagesetColors.newGrey[800],
  },
}));

export const SidebarTabButtonWrapper = styled(Box)(() => ({
  padding: '8px 16px',
}));

export const SidebarTabContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '&.sidebar-wrapper-default': {
    height: '100%',
  },
  '&.sidebar-wrapper-new-page': {
    height: 'calc(100% - 56px)',
  },
}));

export const SidebarContent = styled(Box)(() => ({
  padding: '0px 24px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  '&.sidebar-filters': {
    gap: '16px',
  },
  '&.sidebar-new-page': {
    gap: '20px',
    padding: '20px 24px 0px 24px',
  },
  '&.sidebar-new-page-template': {
    gap: '20px',
    padding: '20px 0px 0px 0px',
    height: '100%',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const SidebarTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'column',
  '&.active-sidebar-tab': {
    height: 'calc( 100% )',
  },
  '&.sidebar-themed': {
    background: theme.dealOverrides.sidebar.backgroundColor,
  },
  '&.sidebar-new-page': {
    borderRight: `1px solid ${stagesetColors.newGrey[200]}`,
    background: stagesetColors.newGrey[50],
  },
}));
