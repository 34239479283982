import { SvgIcon, SvgIconProps } from '@mui/material';

export const MenuTemplateIcon20 = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M7.60495 11.7463H4.61182C4.05953 11.7463
      3.61182 12.1941 3.61182 12.7463V15.7395C3.61182
      16.2918 4.05953 16.7395 4.61182 16.7395H7.60495C8.15723
      16.7395 8.60495 16.2918 8.60495 15.7395V12.7463C8.60495
      12.1941 8.15723 11.7463 7.60495 11.7463Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.9843 11.7507H12.9912C12.4389 11.7507
      11.9912 12.1984 11.9912 12.7507V15.7439C11.9912
      16.2961 12.4389 16.7439 12.9912 16.7439H15.9843C16.5366
      16.7439 16.9843 16.2961 16.9843 15.7439V12.7507C16.9843
      12.1984 16.5366 11.7507 15.9843 11.7507Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.9824 3.4248H12.9893C12.437 3.4248 11.9893
      3.87252 11.9893 4.4248V7.41794C11.9893 7.97022 12.437
      8.41794 12.9893 8.41794H15.9824C16.5347 8.41794 16.9824
      7.97022 16.9824 7.41794V4.4248C16.9824 3.87252 16.5347
      3.4248 15.9824 3.4248Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.61182 5.90479L8.60989 5.94187"
      strokeWidth="1.6"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M6.09229 8.4231L6.12937 3.42502"
      strokeWidth="1.6"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);
