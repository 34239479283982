import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useSubscriptionsNew } from '../../../../hooks/useSubscriptionsNew';
import { SubscriptionsTabGridContainer } from '../Styles/SubscriptionTabStyles';
import { SubscriptionTabGridCard } from './SubscriptionTabGridCard';
import SubscriptionTabPaymentPeriod from './SubscriptionTabPaymentPeriod';

export const SubscriptionTabGrid = () => {
  const {
    checkIfSubscriptionActive,
    subscriptions,
    appSumoSubscriptions,
    checkIfCurrentSubscriptionIsAppSumo,
    currentPlanObj,
    subscription: current,
  } = useSubscriptionsNew();

  return (
    <SubscriptionsTabGridContainer
      className={classNames({ 'subscriptions-tab-grid-container-mobile': isMobile })}
    >
      {
        checkIfCurrentSubscriptionIsAppSumo && (
          appSumoSubscriptions.map((subscription) => {
            const description = typeof subscription.description === 'string'
              ? subscription.description
              : <SubscriptionTabPaymentPeriod options={subscription.description} type={subscription.type} />;

            return (
              <SubscriptionTabGridCard
                key={`mobile-subscription-card-${subscription.type}`}
                title={subscription.title}
                description={description}
                features={subscription.features}
                type={subscription.type}
                btnTitle={subscription.btnTitle}
                current={checkIfSubscriptionActive(subscription)}
              />
            );
          })
        )
    }
      {
        !checkIfCurrentSubscriptionIsAppSumo && (
          subscriptions.map((subscription) => {
            const description = typeof subscription.description === 'string'
              ? subscription.description
              : <SubscriptionTabPaymentPeriod options={subscription.description} type={subscription.type} />;
            return (
              <SubscriptionTabGridCard
                key={`mobile-subscription-card-${subscription.type}`}
                title={subscription.title}
                description={description}
                features={subscription.features}
                type={subscription.type}
                btnTitle={subscription.btnTitle}
                current={subscription.priceId === currentPlanObj?.priceId && (current?.type === subscription?.type)}
                priceId={subscription.priceId}
              />
            );
          })
        )
    }
    </SubscriptionsTabGridContainer>
  );
};
