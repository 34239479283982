import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../core/api';

const apiBase = 'events';

const EventApiPaths = {
  createDealEvent: 'create-deal-event',
};

class EventsApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('events');
  }

  async generateDealEvent(payload: any): Promise<void> {
    return this.api.post<void>(`/${EventApiPaths.createDealEvent}`, payload);
  }
}

const eventsApi = new EventsApi();

export const generateDealEvent = createAsyncThunk(
  `${apiBase}/${EventApiPaths.createDealEvent}`,
  async (payload: any) => eventsApi.generateDealEvent(payload),
);
