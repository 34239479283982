import { Avatar, Chip, ChipProps } from '@mui/material';
import { FormattedLabel } from './FormattedLabel';
import { stagesetColors } from '../../theme/stagesetPalette';
import { CloseIcon16 } from '../../icons/CloseIcon';

interface Props extends ChipProps {
  avatar?: string;
  label: string;
}
// TODO: avatar border
const UserChip = ({ avatar, label, onDelete }: Props): JSX.Element => (
  <Chip
    avatar={(
        avatar ? (
          <Avatar
            src={avatar}
          />
        ) : undefined
    )}
    label={(
      <FormattedLabel
        label={label}
        len={24}
        tooltip
        placement="top"
      />
    )}
    onDelete={onDelete}
    deleteIcon={(
      <CloseIcon16
        sx={{
          width: '16px',
          height: '16px',
        }}
      />
    )}
    sx={{
      height: '24px',
      minHeight: '24px',
      borderRadius: avatar ? '16px 4px 4px 16px' : '4px',
      color: stagesetColors.newGrey[800],
      borderColor: 'transparent',
      '& .MuiAvatar-root': {
        marginLeft: '2px',
        width: '20px',
        height: '20px',
      },
      backgroundColor: stagesetColors.newGrey[200],
      '& .MuiChip-label': {
        marginRight: 0,
        padding: avatar ? '0px 6px 0px 12px' : '0px 6px',
      },
      '& .MuiSvgIcon-root': {
        margin: 0,
      },
      '& .MuiChip-deleteIcon': {
        '& path': {
          stroke: stagesetColors.newGrey[800],
        },
        '&:hover': {
          '& path': {
            stroke: stagesetColors.orange[600],
          },
        },
        '&:active': {
          '& path': {
            stroke: stagesetColors.newGrey[600],
          },
        },
      },
    }}
  />
);
export default UserChip;
