import { SvgIcon, SvgIconProps } from '@mui/material';

export const PictureIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M16.1117 2.5H4.44499C3.52451 2.5 2.77832 3.24619 2.77832
      4.16667V15.8333C2.77832 16.7538 3.52451 17.5 4.44499 17.5H16.1117C17.0321
      17.5 17.7783 16.7538 17.7783 15.8333V4.16667C17.7783 3.24619 17.0321 2.5
      16.1117 2.5Z"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.36133 8.33325C8.05168 8.33325 8.61133 7.77361 8.61133
      7.08325C8.61133 6.3929 8.05168 5.83325 7.36133 5.83325C6.67097
      5.83325 6.11133 6.3929 6.11133 7.08325C6.11133 7.77361 6.67097
      8.33325 7.36133 8.33325Z"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7786 12.4999L13.612 8.33325L4.44531 17.4999"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
