import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { iconColorPalette } from '../../routes-old/process/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import useSideMenu from '../../hooks/useSideMenu';
import {
  FooterWrapper,
  LogOutButton,
  ProfileSettingsButton,
  SettingsButtonSubtitle,
  SettingsButtonTitle,
  StyledAvatar,
  UserTextInfoWrapper,
} from './SideMenuStyles';
import { EAppRoutes } from '../../core/router';
import { LogOutIcon20 } from '../../icons/LogOutIcon';
import {
  ESettingsTabs, ESidebarTabs, setSettingsTab, setSidebarTab,
} from '../Layout/lib/fieldsLayoutSlice';
import { ESubscriptionType } from '../../../shared/Subscription';
import { RocketIcon20 } from '../../icons/RocketIcon';

type SideMenuFooterProps = {
  setOwnerPopoverStatus?: (state: boolean) => void,
  setAnchor?: (event: Element | null) => void,
};
const SideMenuFooter = ({
  setAnchor,
  setOwnerPopoverStatus,
}: SideMenuFooterProps) => {
  const {
    onLogout,
    close,
  } = useSideMenu();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.app.user);
  const billing = useAppSelector((state) => state.billing);
  const dispatch = useAppDispatch();
  const showTryPremiumSection = billing.subscription?.type === ESubscriptionType.SELLER;

  const handleProfileSettingsButtonClick = () => {
    dispatch(setSidebarTab(ESidebarTabs.SETTINGS));
    sessionStorage.setItem('settings-tab', ESettingsTabs.PROFILE);
    dispatch(setSettingsTab(ESettingsTabs.PROFILE));
    navigate(EAppRoutes.settings);
    if (!isMobile && setOwnerPopoverStatus) setOwnerPopoverStatus(false);
    if (isMobile) return close();
    if (setAnchor) return setAnchor(null);
    return null;
  };

  const handleSubscriptionsSettingsButtonClick = () => {
    dispatch(setSidebarTab(ESidebarTabs.SETTINGS));
    sessionStorage.setItem('settings-tab', ESettingsTabs.SUBSCRIPTION);
    dispatch(setSettingsTab(ESettingsTabs.SUBSCRIPTION));
    navigate(EAppRoutes.settings);
    close();
  };

  return (
    <FooterWrapper
      className={classNames({ 'side-menu-footer-wrapper-mobile': isMobile })}
    >
      <ProfileSettingsButton
        className={classNames({ 'profile-settings-button-mobile': isMobile })}
        fullWidth
        disableRipple
        onClick={handleProfileSettingsButtonClick}
      >
        <StyledAvatar
          alt={user?.name || ''}
          src={user?.avatarSrc}
          sx={{
            color: user?.colorId ? iconColorPalette[user.colorId].letter : '',
            background: user?.colorId ? iconColorPalette[user.colorId].back : '',
          }}
        >
          {user?.name ? user?.name[0] : undefined}
        </StyledAvatar>
        <UserTextInfoWrapper>
          <SettingsButtonTitle>
            {user?.name ? user?.name : undefined}
          </SettingsButtonTitle>
          <SettingsButtonSubtitle>
            {user?.email ? user?.email : undefined}
          </SettingsButtonSubtitle>
        </UserTextInfoWrapper>
      </ProfileSettingsButton>
      {showTryPremiumSection && isMobile && (
        <ProfileSettingsButton
          className="profile-settings-button-subscription"
          disableRipple
          fullWidth
          onClick={handleSubscriptionsSettingsButtonClick}
        >
          <RocketIcon20 />
          <UserTextInfoWrapper>
            <SettingsButtonTitle>
              Upgrade your plan
            </SettingsButtonTitle>
            <SettingsButtonSubtitle>
              Get more information about your customers actions
            </SettingsButtonSubtitle>
          </UserTextInfoWrapper>
        </ProfileSettingsButton>
      )}
      <LogOutButton
        className={classNames({ 'log-out-button-mobile': isMobile })}
        fullWidth
        disableRipple
        onClick={onLogout}
      >
        <LogOutIcon20 />
        Log out
      </LogOutButton>
    </FooterWrapper>
  );
};

export default SideMenuFooter;
