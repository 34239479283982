import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { useEffect, useRef } from 'react';

type Props = {
  params: AutocompleteRenderInputParams,
  onChange: (value: string) => void,
  value: string,
};

export const AutocompleteTextField = ({ params, onChange, value }: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <TextField
      {...params}
      inputRef={inputRef}
      placeholder="Search"
      onKeyDown={(e) => e.stopPropagation()}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
      InputProps={({
        sx: {
          border: 'none',
          padding: '8px 16px !important',
          '& fieldset': {
            border: 'none',
          },
          '& input': {
            padding: '0 !important',
            minWidth: '100% !important',
          },
        },
        ...params.InputProps,
        endAdornment: null,
      })}
      variant="outlined"
    />
  );
};
