import { Box } from '@mui/system';

type Props = {
  children: JSX.Element[]
};

const OwnerCardContent = ({ children }: Props): JSX.Element => (
  <Box sx={{
    marginTop: '12px',
  }}
  >
    {children}
  </Box>
);

export default OwnerCardContent;
