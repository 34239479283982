import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ECreateTemplateRequestType, EProcessType, ProcessDTO } from '../../../../shared/process/ProcessMilestoneActionDTO';
import {
  CancelButton,
  ConfirmButton,
  LoadingConfirmButton,
  StyledDialogActions,
} from '../../../common/ConfirmationDialog/ConfirmationDialogStyle';
import { EStateStatus } from '../../../core/commonTypes';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { ICreateDealFormState } from '../CreateDealDialog/CreateDealDialog';
import { getSelectedTemplate } from '../state/dealsSlice';
import { closeCloneTemplateDialog, createTemplate } from '../../../pages/templates/lib/templatesSlice';

export interface CLoneDealFormState {
  dealTitle: string;
  templateId?: string;
}

const CloneTemplateDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedTemplateId } = useAppSelector((state) => state.deals);
  const { isCloneTemplateDialogOpen, status } = useAppSelector((state) => state.templates);
  const template = useAppSelector((state) => getSelectedTemplate(state));

  const isLoading: boolean = status === EStateStatus.LOADING;

  const schema = yup.object({
    dealTitle: yup.string().required('Template Title is required'),
    templateId: yup.string().required('Template is required'),
  }).required();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<CLoneDealFormState>({
    defaultValues: {
      dealTitle: `${template?.title} (clone)` || '',
      templateId: template?.title || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('dealTitle', `${template?.title} (clone)`);
    setValue('templateId', template?.title);
  }, [selectedTemplateId]);

  const handleClose = () => {
    reset();
    dispatch(closeCloneTemplateDialog());
  };

  const onFormSubmit = async (data: ICreateDealFormState) => {
    try {
      if (selectedTemplateId) {
        const clonedTemplate: ProcessDTO = await dispatch(createTemplate({
          title: data.dealTitle,
          templateId: selectedTemplateId,
          createType: ECreateTemplateRequestType.CLONE,
          type: EProcessType.TEMPLATE,
        })).unwrap();
        reset();
        navigate(`${EAppRoutes.deal}/${clonedTemplate.id}`);
      }
      dispatch(closeCloneTemplateDialog());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={isCloneTemplateDialogOpen}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle
          sx={{
            padding: '20px 20px 8px 20px',
          }}
        >
          Clone Template
        </DialogTitle>
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'space-evenly',
            height: '300px',
            width: '350px',
          }}
        >
          <Controller
            name="dealTitle"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Title"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="templateId"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                disabled
                label="Template"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </DialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          {isLoading ? (
            <LoadingConfirmButton
              loading
            />
          ) : (
            <ConfirmButton type="submit" color="success">Clone</ConfirmButton>)}
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

export default CloneTemplateDialog;
