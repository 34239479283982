import { useLocation } from 'react-router-dom';
import useSideMenu from '../../hooks/useSideMenu';
import { EAppRoutes } from '../../core/router';
import { SideMenuContentWrapper } from './SideMenuStyles';
import { useAppSelector } from '../../hooks/stateHooks';
import { ESubscriptionType } from '../../../shared/Subscription';
import SideMenuList from './SideMenuList';
import { EJWTTokenType } from '../../../shared/schema/token.schema';

const SideMenuContent = () => {
  const { isOpened } = useSideMenu();
  const location = useLocation();
  const tokenType = useAppSelector((state) => state.auth.tokenType);
  const billing = useAppSelector((state) => state.billing);
  const isBuyer = billing.subscription?.type === ESubscriptionType.BUYER;
  // there is special menu for billing page
  const isNotBillingPage = location.pathname !== EAppRoutes.billing;
  const showSellerMemberCard = isBuyer && isNotBillingPage;

  if (tokenType !== EJWTTokenType.SHARE) {
    return (
      <SideMenuContentWrapper>
        {/* <Toolbar className={classNames('toolbar', { 'toolbar-mobile': isMobile })} /> */}
        <SideMenuList isOpened={isOpened} listProps={{ hidden: showSellerMemberCard }} />
      </SideMenuContentWrapper>
    );
  }
  return (
    null
  );
};

export default SideMenuContent;
