import { Box, styled } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const AppDashedBorderBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '1px',
  border: '1px dashed',
  borderColor: stagesetColors.newGrey[200],
}));
