import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledAppLoader = styled(Box)(() => ({
  '& svg': {
    display: 'inline-block',
    margin: 'auto',
    transform: 'scaleX(-1)',
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '50%': {
        transform: 'rotate(180deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
    animation: 'rotate 2s linear infinite',
    '& defs': {
      '& radialGradient': {
        '& stop': {
          stopColor: stagesetColors.newGrey[800],
        },
      },
    },
  },
}));

export const StyledAnimatedAppLoader = styled(Box)(() => ({
  '& svg': {
    display: 'inline-block',
    margin: 'auto',
    '& circle': {
      '@keyframes rotateandprogress': {
        '0%': {
          strokeDasharray: '1px, 200px',
          strokeDashoffset: 0,
          transform: 'rotate(0deg)',
        },
        '50%': {
          strokeDasharray: '100px, 200px',
          strokeDashoffset: '-5px',
          transform: 'rotate(180deg)',
        },
        '100%': {
          strokeDasharray: '100px, 200px',
          strokeDashoffset: '-125px',
          transform: 'rotate(360deg)',
        },
      },
      transformOrigin: 'center center',
      animation: 'rotateandprogress 2s ease-in-out infinite',
      strokeDasharray: '80px, 200px',
      strokeDashoffset: 0,
      transition: 'stroke 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      stroke: stagesetColors.newGrey[800],
    },
  },
}));
