import { Box } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import StagesSidebar from './StagesSidebar';
import useFieldsLayoutSlice from '../../hooks/useFieldsLayoutSlice';
import { useActionOnOutsideClick } from '../../hooks/useClickedOutside';
import {
  ESidebarTabs, selectSidebarTab, selectStagesFiltersDrawerStatus, setSidebarTab,
} from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { EAppRoutes } from '../../core/router';
import { MobileSideMenuDrawerFullScreen, SideMenuDrawer, SideMenuDrawerWrapper } from '../SideMenu/SideMenuStyles';
import useSideMenu from '../../hooks/useSideMenu';

const StagesDrawer = (): JSX.Element => {
  const { isDrawerOpen, onDrawerStatusUpdate } = useFieldsLayoutSlice();
  const stagesDrawerRef = useRef<HTMLDivElement | null>(null);
  const tab = useAppSelector(selectSidebarTab);
  const mobileFiltersTab = useAppSelector(selectStagesFiltersDrawerStatus);
  const { pathname } = useLocation();
  const checkPathName = (route: EAppRoutes) => pathname.includes(route);
  const dispatch = useAppDispatch();

  useActionOnOutsideClick(stagesDrawerRef, () => onDrawerStatusUpdate(false), isDrawerOpen && !isMobile);

  const {
    open, close,
  } = useSideMenu();

  useEffect(() => () => {
    dispatch(setSidebarTab(ESidebarTabs.STAGES));
  }, []);

  const currentStage = useMemo(() => {
    if (isMobile) {
      if (
        (mobileFiltersTab && tab === ESidebarTabs.FILTERS)
        || (isDrawerOpen && tab === ESidebarTabs.CUSTOMIZATION)
      ) {
        return (
          <SideMenuDrawerWrapper>
            <MobileSideMenuDrawerFullScreen
              ref={stagesDrawerRef}
              variant={isMobile ? 'persistent' : 'permanent'}
              open={isDrawerOpen || mobileFiltersTab}
              onClose={close}
              onOpen={open}
              anchor="bottom"
            >
              <StagesSidebar />
            </MobileSideMenuDrawerFullScreen>
          </SideMenuDrawerWrapper>
        );
      } if (isDrawerOpen) {
        return (
          <SideMenuDrawerWrapper>
            <SideMenuDrawer
              ref={stagesDrawerRef}
              variant={isMobile ? 'persistent' : 'permanent'}
              open={isDrawerOpen}
              onClose={close}
              onOpen={open}
              anchor="bottom"
            >
              <StagesSidebar />
            </SideMenuDrawer>
          </SideMenuDrawerWrapper>
        );
      }
    } else if (
      isDrawerOpen
      && (
        checkPathName(EAppRoutes.templates)
        || checkPathName(EAppRoutes.home)
      )) {
      return (
        <Box
          sx={{
            display: isDrawerOpen ? 'flex' : 'none',
            width: '350px',
            height: checkPathName(EAppRoutes.home) ? 'calc(100vh - 220px)' : '100vh',
            overflow: 'hidden',
            transition: '0.2s',
            zIndex: 101,
          }}
        >
          <StagesSidebar />
        </Box>
      );
    } return undefined;
  }, [tab, isDrawerOpen, pathname, mobileFiltersTab]);

  return (
    <Box
      sx={{
        position: 'absolute',
      }}
    >
      { currentStage }
    </Box>
  );
};

export default StagesDrawer;
