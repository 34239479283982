/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

export const RTEBoldIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M5 3.48315H11.6667C12.5507 3.48315 13.3986 3.83434 14.0237 4.45946C14.6488 5.08459 15 5.93243 15 6.81649C15 7.70054 14.6488 8.54839 14.0237 9.17351C13.3986 9.79863 12.5507 10.1498 11.6667 10.1498H5V3.48315Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5 10.1498H12.5C13.3841 10.1498 14.2319 10.501 14.857 11.1261C15.4821 11.7512 15.8333 12.5991 15.8333 13.4831C15.8333 14.3672 15.4821 15.215 14.857 15.8401C14.2319 16.4653 13.3841 16.8164 12.5 16.8164H5V10.1498Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const RTEUnderlineIcon = (): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >

    <path
      d="M5.5 2.64978V8.48311C5.5 9.8092 6.02678 11.081 6.96447 12.0186C7.90215 12.9563 9.17392 13.4831 10.5 13.4831C11.8261 13.4831 13.0979 12.9563 14.0355 12.0186C14.9732 11.081 15.5 9.8092 15.5 8.48311V2.64978"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.83398 17.6498H17.1673"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const RTEItalicIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M15.834 3.48315H8.33398"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.666 16.8164H4.16602"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 3.48315L7.5 16.8165"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const RTELinkIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M8.53516 11.5734C8.84989 11.9942 9.25143 12.3423 9.71255 12.5943C10.1737 12.8462 10.6836 12.996 11.2077 13.0335C11.7318 13.0711 12.2578 12.9954 12.7501 12.8118C13.2425 12.6282 13.6895 12.3408 14.061 11.9692L16.2596 9.77056C16.9271 9.07945 17.2964 8.15383 17.2881 7.19305C17.2797 6.23227 16.8944 5.31321 16.215 4.63381C15.5356 3.95441 14.6165 3.56903 13.6557 3.56069C12.6949 3.55234 11.7693 3.92168 11.0782 4.58917L9.81768 5.84238"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.466 10.1077C11.1512 9.6869 10.7497 9.33875 10.2886 9.08682C9.82745 8.83489 9.31754 8.68508 8.79344 8.64755C8.26933 8.61001 7.74328 8.68563 7.25097 8.86928C6.75866 9.05292 6.3116 9.3403 5.94012 9.71192L3.74152 11.9105C3.07403 12.6016 2.70468 13.5272 2.71303 14.488C2.72138 15.4488 3.10675 16.3679 3.78615 17.0473C4.46555 17.7267 5.38462 18.112 6.3454 18.1204C7.30617 18.1287 8.2318 17.7594 8.9229 17.0919L10.1761 15.8387"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const RTEULIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M6.66602 5.14978H17.4993"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66602 10.1498H17.4993"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66602 15.1498H17.4993"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 5.14978H2.50833"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 10.1498H2.50833"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 15.1498H2.50833"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const RTEOLIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M6.66602 5.14978H17.4993"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66602 10.1498H17.4993"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66602 15.1498H17.4993"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.14062 3.80603V6.64978H2.48242V4.54822L1.83398 4.73767V4.23767L3.08008 3.80603H3.14062Z"
      fill="inherit"
      strokeWidth="0.2"
    />
    <path
      d="M3.66797 11.142V11.6498H1.68359V11.2201L2.59766 10.2592C2.67839 10.1667 2.74349 10.084 2.79297 10.0111C2.84245 9.93689 2.87826 9.86983 2.90039 9.80994C2.92383 9.75004 2.93555 9.696 2.93555 9.64783C2.93555 9.5658 2.92318 9.49744 2.89844 9.44275C2.875 9.38676 2.83984 9.34444 2.79297 9.3158C2.7474 9.28715 2.69076 9.27283 2.62305 9.27283C2.55534 9.27283 2.49609 9.29236 2.44531 9.33142C2.39453 9.37048 2.35482 9.42387 2.32617 9.49158C2.29883 9.55929 2.28516 9.63546 2.28516 9.72009H1.625C1.625 9.54561 1.66732 9.38611 1.75195 9.24158C1.83789 9.09705 1.95703 8.98181 2.10938 8.89587C2.26172 8.80863 2.43815 8.76501 2.63867 8.76501C2.84831 8.76501 3.02409 8.79757 3.16602 8.86267C3.30794 8.92777 3.41471 9.02218 3.48633 9.14587C3.55924 9.26827 3.5957 9.41606 3.5957 9.58923C3.5957 9.68819 3.58008 9.78324 3.54883 9.87439C3.51758 9.96554 3.47266 10.056 3.41406 10.1459C3.35547 10.2344 3.28385 10.3256 3.19922 10.4193C3.11589 10.5131 3.02083 10.6127 2.91406 10.7181L2.54688 11.142H3.66797Z"
      fill="inherit"
      strokeWidth="0.2"
    />
    <path
      d="M2.2832 14.9525H2.60352C2.68555 14.9525 2.7526 14.9382 2.80469 14.9095C2.85807 14.8796 2.89779 14.8379 2.92383 14.7845C2.94987 14.7299 2.96289 14.6654 2.96289 14.5912C2.96289 14.5339 2.95052 14.4812 2.92578 14.433C2.90234 14.3848 2.86589 14.3464 2.81641 14.3177C2.76693 14.2878 2.70378 14.2728 2.62695 14.2728C2.57487 14.2728 2.52409 14.2839 2.47461 14.306C2.42513 14.3269 2.38411 14.3568 2.35156 14.3959C2.32031 14.4349 2.30469 14.4825 2.30469 14.5385H1.64453C1.64453 14.377 1.6888 14.239 1.77734 14.1244C1.86719 14.0085 1.98503 13.92 2.13086 13.8588C2.27799 13.7963 2.4362 13.765 2.60547 13.765C2.80729 13.765 2.98438 13.7963 3.13672 13.8588C3.28906 13.92 3.40755 14.0111 3.49219 14.1322C3.57812 14.252 3.62109 14.4004 3.62109 14.5775C3.62109 14.6752 3.59831 14.767 3.55273 14.8529C3.50716 14.9388 3.44336 15.0144 3.36133 15.0795C3.2793 15.1446 3.18294 15.196 3.07227 15.2338C2.96289 15.2702 2.8431 15.2885 2.71289 15.2885H2.2832V14.9525ZM2.2832 15.4427V15.1146H2.71289C2.85482 15.1146 2.98438 15.1309 3.10156 15.1635C3.21875 15.1947 3.31966 15.2416 3.4043 15.3041C3.48893 15.3666 3.55404 15.4441 3.59961 15.5365C3.64518 15.6276 3.66797 15.7325 3.66797 15.851C3.66797 15.9838 3.64128 16.1023 3.58789 16.2064C3.53451 16.3106 3.45964 16.3985 3.36328 16.4701C3.26693 16.5417 3.1543 16.5964 3.02539 16.6342C2.89648 16.6706 2.75651 16.6888 2.60547 16.6888C2.48698 16.6888 2.36849 16.6732 2.25 16.642C2.13151 16.6094 2.02344 16.5599 1.92578 16.4935C1.82812 16.4258 1.74935 16.3399 1.68945 16.2357C1.63086 16.1302 1.60156 16.0046 1.60156 15.8588H2.26172C2.26172 15.9187 2.27799 15.9733 2.31055 16.0228C2.3444 16.071 2.38932 16.1094 2.44531 16.1381C2.5013 16.1667 2.56185 16.181 2.62695 16.181C2.70768 16.181 2.77604 16.1661 2.83203 16.1361C2.88932 16.1049 2.93294 16.0632 2.96289 16.0111C2.99284 15.959 3.00781 15.9011 3.00781 15.8373C3.00781 15.7409 2.99154 15.6641 2.95898 15.6068C2.92773 15.5495 2.88151 15.5079 2.82031 15.4818C2.76042 15.4558 2.68815 15.4427 2.60352 15.4427H2.2832Z"
      fill="inherit"
      strokeWidth="0.2"
    />
  </SvgIcon>
);

export const RTEFormatLeftIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M14.1667 8.48315H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 5.14978H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 11.8164H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1667 15.1498H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const RTEFormatCenterIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M15 9.17395H5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 5.84058H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 12.5072H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 15.8406H5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const RTEFormatRightIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M5.83333 9.17395H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 5.84058H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12.5072H17.5"
      stroke="#0073F5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83333 15.8406H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const RTEFormatJustifyIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M2.5 9.17395H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 5.84058H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12.5072H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 15.8406H17.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const RTEInsertTemplateVarIcon = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    sx={{
      color: 'transparent',
    }}
  >
    <path xmlns="http://www.w3.org/2000/svg" d="M12.8105 17.7859H14.377C14.9255 17.7859 15.3765 17.6344 15.7299 17.3315C16.0832 17.0287 16.2599 16.6444 16.2599 16.1787V11.7574C16.2599 11.6218 16.3074 11.4907 16.4023 11.3641L17.0748 10.5571C17.2225 10.3763 17.2225 10.1955 17.0748 10.0146L16.4023 9.20768C16.3074 9.07658 16.2599 8.94547 16.2599 8.81437V4.39981C16.2599 3.92965 16.0832 3.54312 15.7299 3.24023C15.3765 2.93734 14.9255 2.78589 14.377 2.78589L12.8105 2.78589" stroke="#0073F5" strokeWidth="1.6" />
    <path xmlns="http://www.w3.org/2000/svg" d="M7.93555 17.7859H6.24409C5.69557 17.7859 5.24462 17.6344 4.89124 17.3315C4.53787 17.0287 4.36118 16.6444 4.36118 16.1787L4.36118 11.7574C4.36118 11.6218 4.31371 11.4907 4.21877 11.3641L3.54631 10.5571C3.39863 10.3763 3.39863 10.1955 3.54631 10.0146L4.21877 9.20768C4.31371 9.07658 4.36118 8.94547 4.36118 8.81437L4.36118 4.39981C4.36118 3.92965 4.53787 3.54312 4.89124 3.24023C5.24462 2.93734 5.69557 2.78589 6.24409 2.78589L7.93555 2.78589" stroke="#0073F5" strokeWidth="1.6" />
  </SvgIcon>
);
