import { Box, styled, Typography } from '@mui/material';
import { stagesetColors } from '../../../../../../theme/stagesetPalette';

export const AgendaNodeInfoItemTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
  whiteSpace: 'nowrap',
  textDecoration: 'none',
}));

export const AgendaNodeInfoItemContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '2px',
  alignItems: 'flex-end',
  paddingTop: '6px',
  '& svg': {
    fontSize: '16px',
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
    '& rect': {
      stroke: stagesetColors.newGrey[500],
    },
  },
}));

export const AgendaNodeInfoAnchorContainer = styled('a')(() => ({
  display: 'flex',
  gap: '2px',
  alignItems: 'flex-end',
  textDecoration: 'none',
  paddingTop: '6px',
  '& svg': {
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
  },
}));
