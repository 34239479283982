export interface WebsocketConnectionQuery {
  token: string;
  roomId: string;
  userId: string;
}

export enum EWSDealEvents {
  ONLINE_USERS = 'ONLINE_USERS',
  GET_ONLINE_USERS = 'GET_ONLINE_USERS',
  TASK_UPDATED = 'TASK_UPDATED',
  FIELD_UPDATED = 'FIELD_UPDATED',
  FILES_UPDATED = 'FILES_UPDATED',
  USERS_UPDATED = 'USERS_UPDATED',
  PROCESS_FIELDS_UPDATED = 'PROCESS_FIELDS_UPDATED',
  ACCESS_LINK_UPDATED = 'ACCESS_LINK_UPDATED',
  NEW_GUEST = 'NEW_GUEST',
  ITEM_FILE_UPLOADED = 'ITEM_FILE_UPLOADED',
  UPDATE_STAGE_USERS = 'UPDATE_STAGE_USERS',
}

export type ProcessRoomData = {
  ownerOrganization: string,
  organizationOwner: string,
}

export type WSUpdateStageUsers = {
  type: 'leave' | 'enter',
  stageId: string,
  processId: string,
  userId: string,
  socketId?: string,
}
