import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  SvgIcon,
} from '@mui/material';
import {
  MouseEvent, useEffect, useRef, useState,
} from 'react';
import * as _ from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isMobile } from 'react-device-detect';
import PricingTableFieldItem from './PricingTableFieldItem';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  EPricingTableEditState,
  closeParams,
  createNewProduct,
  selectFieldId,
  selectItemId,
  selectProduct,
  selectState,
} from './lib/pricingTableSlice';
import { setSidebarTab, ESidebarTabs } from '../Layout/lib/fieldsLayoutSlice';
import { AppLinkButton } from '../../shared/AppButton/AppLinkButton';
import Add from '../../icons/Add.svg';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import {
  DEFAULT_PRICING_ATTRIBUTES,
  PRICING_PERIODS,
  PricingItem,
  PricingPeriod,
  PricingTableAttributes,
} from '../../../shared/pricing-table/PricingTableTypes';
import {
  PTNameCell,
  PTRow,
  PTTable,
  PTTableBody,
  PTValueCell,
} from './PricingTableStyles';
import { stagesetColors } from '../../theme/stagesetPalette';
import {
  setFeedTab,
  EFeedTab,
  setFeedTitle,
} from '../DealFeed/lib/feedSlice';
import { roundNumber } from '../Stages/Helpers/PricingTableHelpers';
import { selectLayouEditingStatus, selectProcessCurrency } from '../../routes-old/process/state/processSlice';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { useBlockContext } from '../Tiptap/TiptapBlockContextProvider';
import DNDActiveIcon from '../../icons/DndIcons/DNDActive.svg';

type TTotalSum = {
  total: number,
  discount: number,
  tax: number,
};

interface Column {
  id: 'name' | 'price' | 'units' | 'tax' | 'total';
  label: string;
  minWidth?: number;
  width?: string;
  align?: 'left' | 'right';
  borderLeft?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    width: isMobile ? '100%' : '52%',
    borderLeft: `1px solid ${stagesetColors.newGrey[100]}`,
    borderTopLeftRadius: '12px',
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 50,
    align: 'right',
    width: isMobile ? '100%' : '12%',
  },
  {
    id: 'units',
    label: 'Units',
    minWidth: 50,
    align: 'right',
    width: isMobile ? '100%' : '12%',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'tax',
    label: 'Tax',
    minWidth: 50,
    align: 'right',
    width: isMobile ? '100%' : '12%',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 50,
    align: 'right',
    width: isMobile ? '100%' : '12%',
    borderTopRightRadius: '12px',
    format: (value: number) => value.toFixed(2),
  },
];

const PricingTableFieldContainer = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { data, onUpdate } = useBlockContext();
  const [items, setItems] = useState<string[]>([]);
  const paramsState = useAppSelector((state) => selectState(state));
  const paramsProduct = useAppSelector((state) => selectProduct(state));
  const paramsFieldId = useAppSelector((state) => selectFieldId(state));
  const paramsItemId = useAppSelector((state) => selectItemId(state));
  const [totalTable, setTotalTable] = useState<PricingItem[]>([]);
  const [totalSum, setTotalSum] = useState<TTotalSum>({ total: 0, discount: 0, tax: 0 });
  const [checkPermissions] = useDealPermissions();
  const isLayoutEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_LAYOUT_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const processCurrency = useAppSelector((state) => selectProcessCurrency(state));
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const myRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [attributes, setAttributes] = useState<PricingTableAttributes>(DEFAULT_PRICING_ATTRIBUTES);
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);

  const handleOpen = (event: MouseEvent<HTMLDivElement | HTMLLIElement>, key?: string) => {
    event.stopPropagation();
    if (!anchorEl) return setAnchorEl(myRef?.current);
    updatePricingTableField(key as PricingPeriod);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (processCurrency) {
      setCurrency(getSymbolFromCurrency(processCurrency));
    }
  }, [processCurrency]);

  useEffect(() => {
    if (data.items) {
      setItems(data.items);
    }
  }, [data.items]);

  useEffect(() => {
    if (paramsProduct && paramsState === EPricingTableEditState.SAVING && paramsFieldId === data.blockId) {
      if (typeof paramsItemId === 'number') {
        const current = [...data.items];
        current[paramsItemId] = JSON.stringify(paramsProduct);
        onUpdate({ items: current });
      } else {
        onUpdate({ items: [...data.items, JSON.stringify(paramsProduct)] });
      }
      dispatch(closeParams());
      dispatch(setSidebarTab(ESidebarTabs.STAGES));
    }
  }, [paramsProduct, paramsState, paramsFieldId, paramsItemId]);

  const submit = () => {
    dispatch(setFeedTab(EFeedTab.PRICING_TABLE));
    dispatch(setFeedTitle('Add product'));
    dispatch(createNewProduct({ fieldId: data.blockId }));
  };

  const duplicateItem = (index: number) => {
    onUpdate({ items: [...data.items, data.items[index]] });
  };

  const updateOrder = (index: number, direction: number) => {
    const tempItems = [...data.items];
    if ((index + direction) >= tempItems.length) {
      const last = tempItems.pop();
      tempItems.unshift(last);
    } else if ((index + direction) < 0) {
      const first = tempItems.shift();
      tempItems.push(first);
    } else {
      [tempItems[index], tempItems[index + direction]] = [tempItems[index + direction], tempItems[index]];
    }
    return onUpdate({ items: tempItems });
  };

  const updateTotal = async (index: number, updatedItem: PricingItem) => {
    setTotalTable((prevTable) => {
      const newTable = [...prevTable];
      newTable[index] = updatedItem;
      return newTable;
    });
  };

  const deleteTotal = (index: number) => {
    const newItems = [...data.items];
    newItems.splice(index, 1);
    onUpdate({ items: newItems });
  };

  useEffect(() => {
    const onEvent = (e: CustomEvent) => {
      updatePricingTableField(e.detail.newPeriod);
    };
    document.addEventListener(ECustomEventsNames.PRICING_TABLE_PERIOD_TO_UPDATE, onEvent as EventListener);
    return () => {
      document.removeEventListener(ECustomEventsNames.PRICING_TABLE_PERIOD_TO_UPDATE, onEvent as EventListener);
    };
  }, [attributes]);

  const updatePricingTableField = (periodValue: PricingPeriod) => {
    if (attributes.period === periodValue) {
      return;
    }
    setAttributes({
      period: periodValue,
    });
  };

  useEffect(() => {
    setTotalTable(items.map((item) => JSON.parse(item)));
  }, [items]);

  useEffect(() => {
    const payload = totalTable.reduce((accumulator, currentValue) => {
      const totalValue = (currentValue.price ?? 0) * (currentValue?.units?.value ?? 0);
      accumulator.total += totalValue;

      let discountValue = 0;
      if (currentValue?.discount?.enabled) {
        if (currentValue.discount?.isPercentage) {
          discountValue = (currentValue.discount?.value || 0) * totalValue * 0.01;
        } else {
          discountValue = currentValue.discount?.value || 0;
        }
      }

      let taxValue = 0;
      if (currentValue?.tax.value && currentValue?.tax.enabled) {
        if (currentValue.tax.isPercentage) {
          taxValue = (totalValue - discountValue) * currentValue.tax.value * 0.01;
        } else {
          taxValue = currentValue.tax.value;
        }
      }
      accumulator.discount += discountValue;
      accumulator.tax += taxValue;

      return accumulator;
    }, { total: 0, discount: 0, tax: 0 });
    setTotalSum(payload);
  }, [totalTable]);

  return (
    <Box
      id="pricing-table-container"
      sx={{
        display: 'grid',
        width: '100%',
        gap: '12px',
      }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        sx={{
          width: '100%',
          border: isMobile ? 'none' : '1px solid',
          borderColor: stagesetColors.newGrey[100],
          borderRadius: '12px',
        }}
      >
        <TableHead>
          <TableRow>
            {!isMobile && (
              columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    fontSize: '12px',
                    lineHeight: '14px',
                    minWidth: column.minWidth,
                    width: column.width,
                    borderLeft: column.borderLeft,
                    borderRight: '1px solid',
                    borderColor: stagesetColors.newGrey[100],
                    color: stagesetColors.newGrey[400],
                    borderBottom: 'none',
                    borderTopLeftRadius: column.borderTopLeftRadius,
                    borderTopRightRadius: column.borderTopRightRadius,
                    padding: '8px 12px',
                    '&:last-child': {
                      borderRight: 'none',
                    },
                    '&:first-of-type': {
                      borderLeft: 'none',
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))
            )}
          </TableRow>
        </TableHead>
        <PTTableBody>
          <TableRow tabIndex={-1}>
            <TableCell
              colSpan={5}
              sx={{
                padding: '0',
                borderBottom: 'none',
              }}
            >
              {!totalTable?.length && (
                <Box
                  sx={{
                    width: '100%',
                    padding: '16px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    textAlign: 'center',
                    fontSize: '14px',
                    color: stagesetColors.newGrey[500],
                    border: isMobile ? '1px solid' : 'none',
                    borderColor: stagesetColors.newGrey[100],
                    borderRadius: '12px',
                  }}
                >
                  No products
                </Box>
              )}
              {
                items.map((item: string, i:number) => (
                  <PricingTableFieldItem
                    key={`${data.id}-${i}`}
                    index={i}
                    task={item}
                    canEdit={isLayoutEditMode}
                    duplicate={duplicateItem}
                    moveItem={updateOrder}
                    updateTotal={updateTotal}
                    currency={currency}
                    deleteTotal={deleteTotal}
                  />
                ))
              }
            </TableCell>
          </TableRow>
        </PTTableBody>
      </Table>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: isMobile ? 'column' : 'row',
      }}
      >
        <Box>
          {isLayoutEditMode && (
            <AppLinkButton
              size="l"
              startIcon={<Add />}
              onClick={submit}
              sx={{
                justifyContent: 'flex-start',
                padding: '6px 4px !important',
                width: '100px',
                fontSize: '14px !important',
                lineHeight: '16px !important',
                fontWeight: '600 !important',
                marginBottom: '12px',
              }}
            >
              Product
            </AppLinkButton>
          )}
        </Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {!!totalTable?.length
          && (
          <PTTable
            stickyHeader
            aria-label="sticky table"
            sx={{
              '&:not(.mobile):hover .task-drag-indicator': {
                opacity: 1,
              },
            }}
          >
            <PTTableBody
              className={isMobile ? 'total-table-body-mobile' : 'total-table-body'}
            >
              <PTRow>
                <PTNameCell>
                  Subtotal
                </PTNameCell>
                <PTValueCell>
                  {currency}
                  {roundNumber(totalSum.total)}
                </PTValueCell>
              </PTRow>
              {!!totalSum.discount && (
                <PTRow>
                  <PTNameCell>
                    Discount
                  </PTNameCell>
                  <PTValueCell>
                    -&nbsp;&nbsp;
                    {currency}
                    {roundNumber(totalSum.discount)}
                  </PTValueCell>
                </PTRow>
              )}
              {!!totalSum.tax && (
                <PTRow>
                  <PTNameCell>
                    Tax
                  </PTNameCell>
                  <PTValueCell>
                    +&nbsp;&nbsp;
                    {currency}
                    {roundNumber(totalSum.tax)}
                  </PTValueCell>
                </PTRow>
              )}
              <PTRow>
                <PTNameCell sx={{ fontWeight: 600 }}>
                  Total
                </PTNameCell>
                <PTValueCell sx={{ fontWeight: 500 }}>
                  <Box>
                    {currency}
                    {roundNumber(totalSum.total - totalSum.discount + totalSum.tax)}
                    {PRICING_PERIODS[attributes.period].valueText}
                  </Box>
                  {!isMobile && isLayoutEditable && isLayoutEditMode && (
                    <Box
                      ref={myRef}
                      className="task-drag-indicator"
                      onClick={handleOpen}
                      sx={{
                        position: 'absolute',
                        top: 'calc(100% - 30px)',
                        right: '-9px',
                        cursor: 'grab',
                        opacity: 0,
                        transition: 'opacity 0.2s ease-in-out',
                        backgroundColor: stagesetColors.newGrey[100],
                        height: '24px',
                        paddingTop: '2px',
                        borderRadius: '8px',
                        paddingRight: '1px',
                      }}
                    >
                      <SvgIcon
                        component={DNDActiveIcon}
                        sx={{
                          color: stagesetColors.newGrey[800],
                          stroke: stagesetColors.newGrey[800],
                          '& path': {
                            stroke: 'inherit',
                          },
                          width: '17px',
                          height: '17px',
                        }}
                        viewBox="0 0 16 16"
                      />
                    </Box>
                  )}
                  <AppDropdown
                    items={Object.keys(PRICING_PERIODS).map((key) => ({
                      id: key,
                      label: PRICING_PERIODS[key as PricingPeriod].displayText,
                      onClick: (e) => {
                        e.stopPropagation();
                        updatePricingTableField(key as PricingPeriod);
                        setAnchorEl(null);
                      },
                    }))}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  />
                </PTValueCell>
              </PTRow>
            </PTTableBody>
          </PTTable>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PricingTableFieldContainer;
