import {
  Box,
} from '@mui/material';
import { useEffect } from 'react';
import { stagesetColors } from '../../../../theme/stagesetPalette';
import { MediaFieldMenuState } from './lib/MediaFieldMenuState';
import MediaFieldMenuEmbedLink from './MediaFieldMenuEmbedLink';
import MediaFieldMenuUpload from './MediaFieldMenuUpload';
import MediaFieldMenuLibrary from './MediaFieldMenuLibrary';
import { LibraryFileDTO } from '../../../../../shared/library/LibraryDTO';
import { AppTabs } from '../../../../shared/AppTabs/AppTabs';
import { useAppSelector } from '../../../../hooks/stateHooks';

type Props = {
  menuState: MediaFieldMenuState;
  setMenuState: (state: MediaFieldMenuState) => void
  onFileUpload: (files: File[]) => void;
  onLinkAdd: (link: string) => void;
  onLibraryItemAdd: (item: LibraryFileDTO) => void;
};

const MediaFieldMenuContainer = ({
  menuState,
  setMenuState,
  onFileUpload,
  onLinkAdd,
  onLibraryItemAdd,
}: Props): JSX.Element => {
  const isShared: boolean = useAppSelector((state) => state.auth?.isSharedUser);

  useEffect(() => {
    if (isShared) {
      setMenuState(MediaFieldMenuState.UPLOAD);
    }
  }, [isShared]);

  const content = (): JSX.Element | null => {
    switch (menuState) {
      case MediaFieldMenuState.LIBRARY:
        return (
          <MediaFieldMenuLibrary
            onLibraryItemAdd={onLibraryItemAdd}
          />
        );
      case MediaFieldMenuState.EMBED:
        return (
          <MediaFieldMenuEmbedLink
            onLinkAdd={onLinkAdd}
          />
        );
      case MediaFieldMenuState.UPLOAD:
        return (
          <MediaFieldMenuUpload
            onFileUpload={onFileUpload}
          />
        );
      default:
        return null;
    }
  };

  const handleStateChange = (
    newState: MediaFieldMenuState,
  ) => {
    setMenuState(newState);
  };
  return (
    <Box
      sx={{
        padding: '16px !important',
        borderRadius: '10px',
        backgroundColor: stagesetColors.newGrey[50],
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        {!isShared && (
          <AppTabs
            color="white"
            variant="boxedNew"
            size="l-new"
            value={menuState}
            onChange={(option) => {
              handleStateChange(option.value);
            }}
            options={[
              {
                title: 'Upload', value: MediaFieldMenuState.UPLOAD,
              },
              {
                title: 'From Library', value: MediaFieldMenuState.LIBRARY,
              },
            ]}
          />
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {content()}
      </Box>
      {/* {menuState === MediaFieldMenuState.UPLOAD && (
        <Box
          sx={{
            width: '100%',
            padding: '5px',
          }}
        >
          <AppButton
            fullWidth
            size="l"
            variant="secondary-newGrey"
            onClick={() => {
              setMenuState(MediaFieldMenuState.LIBRARY);
            }}
          >
            From library
          </AppButton>
        </Box>
      )} */}
    </Box>
  );
};

export default MediaFieldMenuContainer;
