import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { EActivationLinkType } from '../../../../shared/schema/auth.shcema';
import { Analytics, EMixPanelEvents } from '../../../core/Analytics';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import PasswordSecurityIdentificator from '../../../shared/PasswordSecurityCheck/PasswordSecurityIdentificator';
import {
  passwordStatusManager,
  EPasswordSecurityStatuses,
  passwordStatusesParser,
} from '../../../shared/PasswordSecurityCheck/helpers/PasswordSecurityCheckHelpers';
import AuthButton from '../Components/AuthButton';
import { TitleTypography } from '../Components/AuthStyledComponents';
import PasswordVisibilityIcon from '../Components/PasswordVisibilityIcon';
import { confirmAccount, confirmProcessAccount, logout } from '../state/authState';
import useAuthQuery from '../useAuthQuery';
import AvatarFilePreviev from '../../../shared/AvatarFilePreview/AvatarFilePreview';
import ConfirmAccountOrgDataField, { AccConfCompanyData, defauldLogoData } from './ConfirmAccauntOrgDataField';
import {
  selectUser,
  getOrganizationNameAndLogoByHash,
  updateOrganization,
  uploadTeamLogo,
  uploadUserAvatar,
  selectOrganizationSignup,
} from '../../../core/store/appState/appState';
import { ILoginResponse } from '../../../../shared/schema/token.schema';
import { ELocalStorageItems } from '../../../../shared/LocalStorageItems/LocalStorageItems';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';
import { UserAppStatus } from '../../../../shared/UserDTO';
import { EOrganizationRoles } from '../../../../shared/permissions';

interface FormState {
  fullName: string;
  lastName: string;
  password: string;
  title: string;
  phone: string;
  meetingScheduler?: string;
  companyName?: string;
  reason: string;
}

const analytics: Analytics = Analytics.getInstance();

interface Props {
  isDialog?: boolean;
}

export const ConfirmAccount = ({ isDialog }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  if (user && user?.appStatus === UserAppStatus.GUEST) {
    dispatch(logout());
  }

  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
  const [prams] = useAuthQuery();
  const {
    email, type, hash, fullName, joinedDeal,
  } = prams;
  const navigate = useNavigate();
  const { isAuthenticated, status } = useAppSelector((state) => state.auth);
  const isSocialSignup: boolean = type === EActivationLinkType.ORGANIZATION_SOCIAL_SIGNUP;
  const [userAvatarFile, setUserAvatarFile] = useState<File | undefined>(undefined);
  const [companyData, setCompanyData] = useState<AccConfCompanyData>(defauldLogoData);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [requested, setRequested] = useState(false);
  const { role: userRole } = useAppSelector(selectOrganizationSignup);

  useEffect(() => {
    if (hash) {
      dispatch(getOrganizationNameAndLogoByHash(hash));
    }
  }, [hash]);

  if (localStorage.getItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA)) {
    localStorage.removeItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA);
  }
  if (!requested) {
    dispatch(getOrganizationNameAndLogoByHash(hash));
    setRequested(true);
  }

  const onSubmit = async (data: FormState) => {
    if (joinedDeal) sessionStorage.setItem('signUpJoinedId', joinedDeal);
    if (email && hash && type) {
      if (type === EActivationLinkType.ORGANIZATION || isSocialSignup) {
        try {
          const activatedUserData: ILoginResponse = await dispatch(confirmAccount({
            ...data,
            hash,
            email,
            type,
            joinedDeal,
          })).unwrap();
          if (activatedUserData.userId && userAvatarFile) {
            await dispatch(uploadUserAvatar({ file: userAvatarFile, userId: activatedUserData.userId })).unwrap();
          }
          if (userRole === EOrganizationRoles.OWNER && activatedUserData.organizationId) {
            if (data.companyName) {
              await dispatch(updateOrganization({
                id: activatedUserData.organizationId,
                name: data.companyName,
              })).unwrap();
            }
            if (!companyData.file && companyData.url) {
              await dispatch(updateOrganization({
                id: activatedUserData.organizationId,
                organizationLogo: companyData.url,
              })).unwrap();
            }
            if (companyData.file) {
              await dispatch(uploadTeamLogo({
                file: companyData.file,
                userId: '',
                organizationId: activatedUserData.organizationId,
              }));
            }
          }
          setConfirmationSuccess(true);
        } catch (e) {
          setConfirmationSuccess(false);
          console.log(e);
        }
      } else if (type === EActivationLinkType.ORGANIZATION_APPSUMO) {
        try {
          await dispatch(confirmAccount({
            ...data,
            fullName: email.split('@')[0],
            hash,
            email,
            type,
          }));
          setConfirmationSuccess(true);
        } catch (e) {
          console.log(e);
        }
      } else if (type === EActivationLinkType.PROCESS) {
        try {
          const activatedUserData: ILoginResponse = await dispatch(confirmProcessAccount({
            ...data,
            hash,
            email,
            type,
            joinedDeal,
          })).unwrap();
          if (activatedUserData.userId && userAvatarFile) {
            await dispatch(uploadUserAvatar({ file: userAvatarFile, userId: activatedUserData.userId })).unwrap();
          }
          setConfirmationSuccess(true);
        } catch (e) {
          setConfirmationSuccess(false);
          console.log(e);
        }
      }
      analytics.track(EMixPanelEvents.ACCOUNT_CONFIRMED);
      analytics.addUser({
        email,
        data,
      });
    }
  };

  useEffect(() => {
    if (!isDialog) {
      if ((!email || !hash) && (!isAuthenticated && !confirmationSuccess)) {
        navigate(EAppRoutes.auth + EAppRoutes.login);
      }
      if ((!email || !hash) && (isAuthenticated && confirmationSuccess)) {
        navigate(EAppRoutes.home);
      }
      if (isAuthenticated && confirmationSuccess) {
        navigate(EAppRoutes.home);
      }
    }
  }, [confirmationSuccess, isAuthenticated]);

  const [passwordStatuses, setPasswordStatuses, checkPasswordStatus] = passwordStatusManager();

  const validationSchemaConfig = useMemo(() => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};

    if (type !== EActivationLinkType.ORGANIZATION_APPSUMO) {
      validationSchemaConfigTemp.fullName = yup.string().required('First Name is required');
      validationSchemaConfigTemp.lastName = yup.string().required('Last Name is required');
      validationSchemaConfigTemp.title = yup.string().required('Title is required');
      validationSchemaConfigTemp.phone = yup.lazy((value) => {
        if (!value) return yup.mixed();
        return yup.string();
      });
      validationSchemaConfigTemp.meetingScheduler = yup.string().url();
      if (userRole === EOrganizationRoles.OWNER) {
        validationSchemaConfigTemp.companyName = yup.string().required('Company Name is required');
      }
      validationSchemaConfigTemp.meetingScheduler = yup.string().url();
    }
    if (!isSocialSignup) {
      validationSchemaConfigTemp.password = yup.string()
        .ensure()
        .min(8, EPasswordSecurityStatuses.LENGTH)
        .matches(/(?=.*[A-Z])/, EPasswordSecurityStatuses.UPPER)
        .matches(/(?=.*[a-z])/, EPasswordSecurityStatuses.LOWER)
        .matches(/(?=.*\d)/, EPasswordSecurityStatuses.NUMBER)
        .test((value: string) => {
          setPasswordStatuses(passwordStatusesParser(value, passwordStatuses));
          return checkPasswordStatus();
        });
    }
    validationSchemaConfigTemp.reason = yup.string().required('Please provide a reason for account creation');
    return validationSchemaConfigTemp;
  }, [isSocialSignup, type, userRole]);

  const schema = yup.object(validationSchemaConfig).required();

  const methods = useForm<FormState>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      fullName: fullName || '',
      lastName: '',
      password: '',
      title: '',
      phone: '',
      companyName: '',
      reason: '',
    },
    resolver: yupResolver(schema, { abortEarly: false }),
  });

  const {
    handleSubmit,
    control,
    // For some reason password status check does not work without form state errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isSubmitted },
  } = methods;

  if (type === EActivationLinkType.ORGANIZATION_APPSUMO) {
    return (
      <AuthPaper>
        <>
          {
      !isDialog && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '325px',
            flexDirection: 'column',
          }}
        >
          <TitleTypography>
            Confirm Account
          </TitleTypography>
        </Box>
      )
    }
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              disabled
              size="small"
              autoComplete="off"
              label="Email"
              variant="outlined"
              fullWidth
              sx={{
                borderRadius: '8px',
              }}
              defaultValue={email}
              InputProps={{
                sx: {
                  borderRadius: '8px',
                },
              }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                'data-lpignore': 'true',
              }}
            />
            {
          !isSocialSignup && (
            <>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    required
                    size="small"
                    autoComplete="off"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    sx={{
                      borderRadius: '8px',
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error && isSubmitted}
                    type={isPasswordVisible ? 'text' : 'password'}
                    InputProps={{
                      sx: {
                        borderRadius: '8px',
                      },
                      endAdornment: <PasswordVisibilityIcon
                        setPasswordVisible={setPasswordVisible}
                        isPasswordVisible={isPasswordVisible}
                      />,
                    }}
                  />
                )}
              />
              <PasswordSecurityIdentificator
                statuses={passwordStatuses}
                error={isSubmitted}
              />
            </>
          )
              }
            <AuthButton status={status} title="Confirm" />
          </Box>
        </>
      </AuthPaper>
    );
  }

  return (
    <AuthPaper>
      <>
        {
      !isDialog && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '325px',
            flexDirection: 'column',
          }}
        >
          <TitleTypography>
            Confirm Account
          </TitleTypography>
        </Box>
      )
    }
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AvatarFilePreviev
            setAvatarFile={setUserAvatarFile}
          />
          <Controller
            name="fullName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                required
                size="small"
                autoComplete="off"
                label="First Name"
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                required
                size="small"
                autoComplete="off"
                label="Last Name"
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
              />
            )}
          />
          <Controller
            name="title"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                required
                size="small"
                autoComplete="off"
                label="Job Title"
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
              />
            )}
          />
          {/*       <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                size="small"
                autoComplete="off"
                label="Phone"
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
              />
            )}
          /> */}
          {/* <Controller
            name="meetingScheduler"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                size="small"
                autoComplete="off"
                label="Meeting scheduler link"
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
              />
            )}
          /> */}
          <input style={{ display: 'none' }} type="text" name="fake" />
          {
          type !== EActivationLinkType.PROCESS
            && (
              <FormProvider {...methods}>
                <ConfirmAccountOrgDataField
                  companyData={companyData}
                  setCompanyData={setCompanyData}
                />
              </FormProvider>
            )
        }
          {
          !isSocialSignup && (
            <>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    required
                    size="small"
                    autoComplete="off"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    sx={{
                      borderRadius: '8px',
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error && isSubmitted}
                    type={isPasswordVisible ? 'text' : 'password'}
                    InputProps={{
                      sx: {
                        borderRadius: '8px',
                      },
                      endAdornment: <PasswordVisibilityIcon
                        setPasswordVisible={setPasswordVisible}
                        isPasswordVisible={isPasswordVisible}
                      />,
                    }}
                  />
                )}
              />
              <PasswordSecurityIdentificator
                statuses={passwordStatuses}
                error={isSubmitted}
              />
            </>
          )
          }
          <Controller
            name="reason"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                required
                size="small"
                autoComplete="off"
                label="How do you want Stageset to help you?"
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
              />
            )}
          />
          <AuthButton status={status} title="Confirm" />
        </Box>
      </>
    </AuthPaper>
  );
};
