import { ReactRenderer } from '@tiptap/react';
import CommandList from './CommandList';
import { TBlockMenuItem } from '../../Utils/TiptapTypes';
import BlockMenuItems from '../../Utils/MenuItems';

const CommandsSuggestions = {
  items: ({ query }: { query: string }): TBlockMenuItem[] => BlockMenuItems
    .filter((item) => item.label.toLowerCase().startsWith(query.toLowerCase())),

  render: () => {
    let component: ReactRenderer;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(CommandList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        document.body.appendChild(component.element);
      },

      onUpdate(props: any) {
        component?.updateProps(props);
      },

      onKeyDown(props: any) {
        // console.log(props.event.key);
        if (props.event.key === 'ArrowDown') {
          return true;
        }
        if (props.event.key === 'ArrowUp') {
          return true;
        }
        if (props.event.key === 'Enter') {
          return true;
        }
        if (props.event.key === 'Escape') {
          component?.destroy();
          return true;
        }

        return undefined;
      },

      onExit() {
        component?.destroy();
      },
    };
  },
};

export default CommandsSuggestions;
