import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import BackToHomeButton from '../../shared/Buttons/BackToHomeButton';
import EditLayoutToggleButtons from '../Layout/EditLayoutToggleButtons';
import StagesSidebarInviteBtn from './StagesSidebarInviteBtn';
import { StagesToolbarWrapper, ToolbarPlaceholder, ToolbarRightSection } from './Styles/ToolbarStyles';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectIsOwnersTeammate, selectProcess } from '../../routes-old/process/state/processSlice';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import { ECreateTemplateRequestType, EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { EFeedTab, setFeedTab, setFeedTitle } from '../DealFeed/lib/feedSlice';
import { LineChartIcon20 } from '../../icons/LineChartIcon';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import { ThreeDotsVert20px } from '../../icons/ThreeDots';
import { createTemplate } from '../../pages/templates/lib/templatesSlice';
import { TemplatesIcon } from '../Tiptap/icons/MenuIcons';
import { InfoIcon } from '../../icons/InfoIcon';
import CustomizationButton from '../../routes-old/process/DealFeed/Customization/CustomizationButton';

interface Props {
  toolbarPlace?: 'section' | 'stage' | null
}

const RoomToolbar = ({ toolbarPlace }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const isTeammate = useAppSelector(selectIsOwnersTeammate);
  const [checkPermissions] = useDealPermissions();
  const isDeal: boolean = useAppSelector((state) => state.process?.process?.type) === EProcessType.DEAL;
  const [anchor, setAnchor] = useState<Element | null>(null);
  const canInvite = checkPermissions(
    EUserDealPermissions.DEAL_USERS_INVITE,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_MANAGE,
  ) && isTeammate;

  const process = useAppSelector(selectProcess);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCreateTemplate = async () => {
    try {
      const template = await dispatch(createTemplate({
        title: `${process.title} Template`,
        type: EProcessType.TEMPLATE,
        templateId: process.id,
        createType: ECreateTemplateRequestType.DEAL,
      })).unwrap();
      if (template) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ToolbarPlaceholder>
        <StagesToolbarWrapper
          className={classNames({ 'stages-toolbar-wrapper-justify-start': !canInvite })}
        >
          <BackToHomeButton
            templateToolbar={!!toolbarPlace}
            key="back-to-home-button"
          />
          {(canInvite || (toolbarPlace === 'stage' && !isMobile)) && (
          <>
            <EditLayoutToggleButtons
              color="dark"
            />
            <ToolbarRightSection
              className={classNames({ 'toolbar-right-section-mobile': isMobile })}
            >
              {
                isDeal
                && canInvite
                && (
                  <AppIconButton
                    onClick={() => {
                      dispatch(setFeedTab(EFeedTab.INTERNAL));
                      dispatch(setFeedTitle('Activity'));
                    }}
                    variant="transparent-white"
                    icon={<LineChartIcon20 />}
                    size="l"
                  />
                )
                }
              {!toolbarPlace && <CustomizationButton />}
              {
                isDeal
                && canInvite
                && (
                  <AppIconButton
                    onClick={(e) => {
                      setAnchor(e.currentTarget);
                    }}
                    variant="transparent-white"
                    icon={<ThreeDotsVert20px />}
                    size="l"
                  />
                )
              }
              {isDeal && (
                <StagesSidebarInviteBtn
                  key="invite-button"
                  toolbar
                />
              )}
            </ToolbarRightSection>
          </>
          )}
        </StagesToolbarWrapper>
      </ToolbarPlaceholder>
      <AppDropdown
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => { setAnchor(null); }}
        items={[
          {
            key: 'save-room-template',
            label: 'Save room as template',
            startIcon: <TemplatesIcon />,
            onClick: () => {
              onCreateTemplate();
              setAnchor(null);
            },
          },
          {
            key: 'room-info',
            label: 'Room details',
            startIcon: <InfoIcon />,
            onClick: () => {
              dispatch(setFeedTab(EFeedTab.ROOM_INFO));
              dispatch(setFeedTitle('Room details'));
              setAnchor(null);
            },
          },
        ]}
      />
    </>
  );
};

export default RoomToolbar;
