import { Box, Popover } from '@mui/material';
import { useCurrentEditor } from '@tiptap/react';
import { useRef, useState } from 'react';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  ArrowDown,
  BoldIcon, ItalicIcon, Justify, LinkIcon, StrikeThrough, UnderlineIcon,
} from './icons/ToolbarIcons';
import {
  BulletListIcon, Header1Icon, Header2Icon, Header3Icon, NumberedListIcon, TextIcon,
} from './icons/MenuIcons';
import { TiptapMenu, TiptapMenuItem } from './Components/Menu/TiptapMenu';
import { AppButton } from '../../shared/AppButton/AppButton';
import { AppInput } from '../../shared/AppInput/AppInput';

const TiptabToolbarItems = (): JSX.Element | null => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [uAnchor, setUAnchor] = useState<HTMLButtonElement | null>(null);
  const urlRef = useRef<HTMLInputElement | null>(null);
  const [menuItems, setMenuItems] = useState<'align' | 'type'>('align');
  const { editor } = useCurrentEditor();

  const checkAlignActive = (align: 'left' | 'right' | 'center' | 'justify') => editor?.isActive('paragraph', { textAlign: align })
    || editor?.isActive('heading', { textAlign: align });

  const getAlignIcon = (): JSX.Element | null => {
    if (editor?.isActive({ textAlign: 'left' })) {
      return <AlignLeft />;
    }
    if (editor?.isActive({ textAlign: 'center' })) {
      return <AlignCenter />;
    }
    if (editor?.isActive({ textAlign: 'right' })) {
      return <AlignRight />;
    }
    if (editor?.isActive({ textAlign: 'justify' })) {
      return <Justify />;
    }
    return null;
  };

  const checkTypeActive = (level: 1 | 2 | 3 | 0) => (editor?.isActive('paragraph') && level === 0)
    || editor?.isActive('heading', { level });

  const getTypeIcon = (): JSX.Element | null => {
    if (editor?.isActive({ level: 1 })) {
      return <Header1Icon />;
    }
    if (editor?.isActive({ level: 2 })) {
      return <Header2Icon />;
    }
    if (editor?.isActive({ level: 3 })) {
      return <Header3Icon />;
    }
    return <TextIcon />;
  };

  const handleLink = (url?: string) => {
    if (typeof url !== 'string') {
      setUAnchor(null);
      return;
    }
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink()
        .run();
      setUAnchor(null);
      return;
    }
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run();
    setUAnchor(null);
  };

  const alignMenuItems = [
    (
      <TiptapMenuItem
        key="toolbar-item-align-l"
        onClick={() => {
          editor?.chain().focus().setTextAlign('left').run();
          setAnchor(null);
        }}
        icon={<AlignLeft />}
        title="Align Left"
        isActive={checkAlignActive('left')}
      />
    ),
    (
      <TiptapMenuItem
        key="toolbar-item-align-c"
        onClick={() => {
          editor?.chain().focus().setTextAlign('center').run();
          setAnchor(null);
        }}
        icon={<AlignCenter />}
        title="Align Center"
        isActive={checkAlignActive('center')}
      />
    ),
    (
      <TiptapMenuItem
        key="toolbar-item-align-r"
        onClick={() => {
          editor?.chain().focus().setTextAlign('right').run();
          setAnchor(null);
        }}
        icon={<AlignRight />}
        title="Align Right"
        isActive={checkAlignActive('right')}
      />
    ),
    (
      <TiptapMenuItem
        key="toolbar-item-align-j"
        onClick={() => {
          editor?.chain().focus().setTextAlign('justify').run();
          setAnchor(null);
        }}
        icon={<Justify />}
        title="Justify"
        isActive={checkAlignActive('justify')}
      />
    ),
  ];

  const typeMenuItems = [
    (
      <TiptapMenuItem
        key="toolbar-item-align-l"
        onClick={() => {
          editor?.commands.toggleHeading({ level: 1 });
          setAnchor(null);
        }}
        icon={<Header1Icon />}
        title="Heading 1"
        isActive={checkTypeActive(1)}
      />
    ),
    (
      <TiptapMenuItem
        key="toolbar-item-align-c"
        onClick={() => {
          editor?.commands.toggleHeading({ level: 2 });
          setAnchor(null);
        }}
        icon={<Header2Icon />}
        title="Heading 2"
        isActive={checkTypeActive(2)}
      />
    ),
    (
      <TiptapMenuItem
        key="toolbar-item-align-r"
        onClick={() => {
          editor?.commands.toggleHeading({ level: 3 });
          setAnchor(null);
        }}
        icon={<Header3Icon />}
        title="Heading 3"
        isActive={checkTypeActive(3)}
      />
    ),
    (
      <TiptapMenuItem
        key="toolbar-item-align-j"
        onClick={() => {
          editor?.commands.setParagraph();
          setAnchor(null);
        }}
        icon={<TextIcon />}
        title="Text"
        isActive={checkTypeActive(0)}
      />
    ),
  ];

  return (
    <>
      <AppButton
        size="m"
        onClick={(e) => { setUAnchor(null); setMenuItems('type'); setAnchor(e.currentTarget); }}
        autoWidth
        sx={{
          padding: '6px',
          width: '44px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {
              getTypeIcon()
            }
          <ArrowDown />
        </Box>
      </AppButton>
      <AppIconButton
        onClick={() => { editor?.chain().focus().toggleBold().run(); }}
        icon={<BoldIcon />}
        variant={editor?.isActive('bold') ? 'primary' : undefined}
      />
      <AppIconButton
        onClick={() => { editor?.chain().focus().toggleItalic().run(); }}
        icon={<ItalicIcon />}
        variant={editor?.isActive('italic') ? 'primary' : undefined}
      />
      <AppIconButton
        onClick={() => { editor?.chain().focus().toggleStrike().run(); }}
        icon={<StrikeThrough />}
        variant={editor?.isActive('strike') ? 'primary' : undefined}
      />
      <AppIconButton
        onClick={() => { editor?.chain().focus().toggleUnderline().run(); }}
        icon={<UnderlineIcon />}
        variant={editor?.isActive('underline') ? 'primary' : undefined}
      />
      <AppIconButton
        onClick={() => { editor?.chain().focus().toggleBulletList().run(); }}
        icon={<BulletListIcon sx={{ fontSize: '20px' }} />}
        variant={editor?.isActive('bulletList') ? 'primary' : undefined}
      />
      <AppIconButton
        onClick={() => { editor?.chain().focus().toggleOrderedList().run(); }}
        icon={<NumberedListIcon sx={{ fontSize: '20px' }} />}
        variant={editor?.isActive('orderedList') ? 'primary' : undefined}
      />
      <AppIconButton
        onClick={(e) => { setAnchor(null); setUAnchor(e.currentTarget); }}
        icon={<LinkIcon />}
        variant={editor?.isActive('link') ? 'primary' : undefined}
      />
      <AppButton
        size="m"
        onClick={(e) => { setUAnchor(null); setMenuItems('align'); setAnchor(e.currentTarget); }}
        autoWidth
        sx={{
          padding: '6px',
          width: '44px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {
              getAlignIcon()
            }
          <ArrowDown />
        </Box>
      </AppButton>
      <TiptapMenu
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        sx={{ zIndex: 10000 }}
      >
        {
          menuItems === 'align'
           && alignMenuItems
        }
        {
          menuItems === 'type'
           && typeMenuItems
        }
      </TiptapMenu>
      <Popover
        open={!!uAnchor}
        anchorEl={uAnchor}
        onClose={() => setUAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 10000 }}
        slotProps={{
          paper: {
            sx: {
              display: 'flex',
              width: '400px',
              padding: '16px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '12px',
              borderRadius: '10px',
              background: '#F8FAFC',
              // eslint-disable-next-line max-len
              boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04), 0px 1px 1px 0px rgba(30, 41, 59, 0.07), 0px 0px 1px 0px rgba(30, 41, 59, 0.08), 0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
            },
          },
        }}
      >
        <AppInput
          autoFocus
          fullWidth
          placeholder="Link"
          inputRef={urlRef}
          onFocus={(e) => e.target.select()}
          defaultValue={editor?.getAttributes('link').href}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <AppButton
            variant="danger"
            onClick={() => setUAnchor(null)}
            sx={{
              width: '20%',
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            fullWidth
            onClick={() => handleLink(urlRef?.current?.value)}
            sx={{
              width: '80%',
            }}
          >
            Save
          </AppButton>
        </Box>
      </Popover>
    </>
  );
};

export default TiptabToolbarItems;
