import {
  Box,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../../hooks/stateHooks';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { CloseIcon } from '../../../../icons/Close16px';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { addBlure } from '../../../process/helpers';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import {
  ApiTokenDialogContentWrapper,
  ApiTokenDialogFooterWrapper,
  ApiTokenDialogTitle,
  ApiTokenDialogTitleWrapper,
  StyledApitokenDialog,
} from './styles/ApiTokenDialogStyles';
import { createApiToken } from './lib/publicApiTokenSlice';
import { ApiTokenDTO } from '../../../../../shared/api-token/ApiTokenDTO';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCopy: (text: string) => void;
}

const GenerateApiTokeDialog = ({
  isOpen,
  onClose,
  onCopy,
}: Props) => {
  const dispatch = useAppDispatch();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [tokenName, setTokenName] = useState('');
  const [newToken, setNewToken] = useState<ApiTokenDTO | undefined>(undefined);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setNewToken(undefined);
    setTokenName('');
  }, [isOpen]);

  useEffect(() => {
    switch (isOpen) {
      case true:
        addBlure(true);
        break;
      case false:
        addBlure(false);
        break;
      default:
        addBlure(false);
        break;
    }
  }, [isOpen]);

  const createNewToken = async () => {
    const token = await dispatch(createApiToken({ name: tokenName })).unwrap();
    if (token) {
      setNewToken(token);
    }
  };

  const copyToClipboardNewToken = async () => {
    if (newToken) {
      onCopy(newToken.token);
    }
  };

  return (
    <StyledApitokenDialog
      ref={dialogRef}
      open={isOpen}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        id: 'create-deal-dialog',
      }}
    >
      <ApiTokenDialogTitleWrapper>
        <ApiTokenDialogTitle>
          {!newToken ? 'Generate' : 'New'}
          {' '}
          Api Token
        </ApiTokenDialogTitle>
        <AppIconButton
          icon={<CloseIcon />}
          onClick={onClose}
        />
      </ApiTokenDialogTitleWrapper>
      <Box>
        <ApiTokenDialogContentWrapper>
          <Box>
            {!newToken && (
            <AppInput
              fullWidth
              required
              size="m"
              label="Name"
              value={tokenName}
              onChange={(e) => {
                setTokenName(e.target.value);
              }}
            />
            )}
            {newToken && (
              <p
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {newToken.token}
              </p>
            )}
          </Box>
        </ApiTokenDialogContentWrapper>
        <ApiTokenDialogFooterWrapper>
          <AppButton
            variant="secondary"
            size="l"
            onClick={onClose}
          >
            {!newToken ? 'Cancel' : 'Close'}
          </AppButton>
          <AppButton
            variant="primary"
            size="l"
            disabled={!tokenName && !newToken}
            onClick={!newToken ? createNewToken : copyToClipboardNewToken}
            sx={{
              marginLeft: '5px',
            }}
          >
            {!newToken ? 'Generate' : 'Copy To Clipboard'}
          </AppButton>
        </ApiTokenDialogFooterWrapper>
      </Box>
    </StyledApitokenDialog>
  );
};

export default GenerateApiTokeDialog;
