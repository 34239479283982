import { Box } from '@mui/material';
import PaymentPeriod from './PaymentPeriod';
import { SubscriptionGridCard } from './SubscriptionGridCard';
import { useSubscriptions } from '../../hooks/useSubscriptions';

export const AppSumoSubscriptionPlans = () => {
  const { checkIfSubscriptionActive, appSumoSubscriptions } = useSubscriptions();

  return (
    <Box
      sx={{
        paddingTop: '32px',
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
      }}
    >
      {
          appSumoSubscriptions.map((subscription) => {
            const description = typeof subscription.description === 'string'
              ? subscription.description
              : <PaymentPeriod options={subscription.description} type={subscription.type} />;

            return (
              <SubscriptionGridCard
                title={subscription.title}
                description={description}
                features={subscription.features}
                type={subscription.type}
                btnTitle={subscription.btnTitle}
                current={checkIfSubscriptionActive(subscription)}
              />
            );
          })
            }
    </Box>
  );
};
