import { TabContext, TabPanel } from '@mui/lab';
import {
  AppMobileBottomDrawer,
  MobileBottomDrawerPuller,
  PullerWrapper,
} from './Styles/MobileBottomDrawerStyles';
import RoomGeneralMenu from '../RoomGeneralMenu/RoomGeneralMobileMenu';
import { EBottomDrawerTabs, selectMobileBottomDrawerTab, setMobileBottomDrawerTab } from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import LibraryCreateItemMenu from '../../pages/library/libraryMobileMenu/LibraryCreateItemMenu';

const drawerBleeding = 56;

export const MobileBottomDrawer = () => {
  const bottomDrawerTab = useAppSelector(selectMobileBottomDrawerTab);
  const dispatch = useAppDispatch();
  const isTabsVisible = bottomDrawerTab !== EBottomDrawerTabs.NULL;
  const { closeDrawer } = useMobileBottomDrawer();
  const toggleDrawer = () => dispatch(setMobileBottomDrawerTab(EBottomDrawerTabs.ROOM_GENERAL_MENU));

  return (
    <AppMobileBottomDrawer
      anchor="bottom"
      open={isTabsVisible}
      onClose={closeDrawer}
      onOpen={toggleDrawer}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen
      ModalProps={{
        keepMounted: true,
      }}
    >
      <PullerWrapper>
        <MobileBottomDrawerPuller />
      </PullerWrapper>
      {isTabsVisible && (
        <TabContext
          value={bottomDrawerTab}
        >
          <TabPanel
            value={EBottomDrawerTabs.ROOM_GENERAL_MENU}
            sx={{
              padding: '0px',
            }}
          >
            <RoomGeneralMenu />
          </TabPanel>
          <TabPanel
            value={EBottomDrawerTabs.LIBRARY_CREATE_ITEM_MENU}
            sx={{
              padding: '0px',
            }}
          >
            <LibraryCreateItemMenu />
          </TabPanel>
        </TabContext>
      )}
    </AppMobileBottomDrawer>
  );
};
