import { lighten, styled } from '@mui/system';
import {
  Autocomplete,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const CurrencyItem = styled<any>(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  backgroundColor: stagesetColors.white[100],
  '&:not(:last-child) path': {
    stroke: stagesetColors.newGrey[400],
  },
  '&:hover': {
    backgroundColor: lighten(stagesetColors.newGrey[100], 0.6),
  },
  '&:active': {
    backgroundColor: stagesetColors.newGrey[100],
  },
  '&.disabled': {
    stroke: stagesetColors.newGrey[300],
  },
  '& [data-focused=y]': {
    backgroundColor: stagesetColors.newGrey[100],
  },
}));

export const CurrencyLogo = styled<any>(Typography)(() => ({
  width: '32px',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
  '&.currency-input-logo': {
    textAlign: 'center',
    color: stagesetColors.newGrey[800],
  },
}));

export const CurrencyAutocomplete = styled<any>(Autocomplete)(() => ({
  padding: '0',
}));

export const CurrencyPaper = styled<any>(Paper)(() => ({
  marginTop: '8px',
  border: '1px solid',
  borderRadius: '10px',
  borderColor: stagesetColors.newGrey[400],
  backgroundColor: stagesetColors.white[100],
}));
