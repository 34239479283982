import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccessLinkDTO, AccessLinkProtectionUpdate } from '../../../../shared/AccessLinkDTO';
import { AddStageToProcessData, ProcessStageDTO } from '../../../../shared/Field/Field';
import { DeleteFileRequest, FileDTO } from '../../../../shared/FileDTO';
import {
  IProcessChangeOwnerRequest, ProcessDTO, ProcessField,
} from '../../../../shared/process/ProcessMilestoneActionDTO';
import { TaskDTO } from '../../../../shared/TaskDTO';
import { EUserProcessStatus } from '../../../../shared/user-process';
import { OrganizationUserDTO, ProcessUserDTO } from '../../../../shared/UserDTO';
import { AccessLinkApi } from '../../../api/AccessLinkApi';
import { FileStorageApi, IUploadFilesFormData } from '../../../api/FileStorageApi';
import { ProcessApi } from '../../../api/ProcessApi';
import { appReducerName } from '../../../core/store/appState/appState';
import { AsyncThunkConfig, RootState } from '../../../core/store/store';
import { processSlice } from './processSlice';
import { setCurrentStage, setItem } from '../../../features/ProcessFields/lib/processItemsSlice';

const processApi = new ProcessApi();
const fileStorageApi = new FileStorageApi();
const accessLinkApi = new AccessLinkApi();

export const processReducerToken = 'process';
export enum EProcessActions {
  GET_PROCESS = 'GET_PROCESS',
  UPLOAD_FILES = 'UPLOAD_FILES',
  UPLOAD_FIELD_FILE = 'UPLOAD_FIELD_FILE',
  GET_FILES = 'GET_FILES',
  GET_USERS = 'GET_USERS',
  DELETE_PROCESS_FILE = 'DELETE_PROCESS_FILE',
  UPDATE_PROCESS = 'UPDATE_PROCESS',
  INVITE_USER_TO_PROCESS = 'INVITE_USER_TO_PROCESS',
  ADD_USER_BY_ID_TO_PROCESS = 'ADD_USER_BY_ID_TO_PROCESS',
  ADD_USER_BY_ID_TO_PROCESS_BY_Id = 'ADD_USER_BY_ID_TO_PROCESS_BY_Id',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  UPLOAD_LOGO = 'UPLOAD_LOGO',
  UPLOAD_BUYER_LOGO = 'UPLOAD_BUYER_LOGO',
  UPDATE_MILESTONE_STATUS = 'UPDATE_MILESTONE_STATUS',
  UPDATE_ACCESS_LINK_BY_ID = 'UPDATE_ACCESS_LINK_BY_ID',
  UPDATE_ACCESS_PROTECTION = 'UPDATE_ACCESS_PROTECTION',
  GET_ACCESS_LINK = 'GET_ACCESS_LINK',
  GET_ACCESS_LINK_BY_HASH = 'GET_ACCESS_LINK_BY_HASH',
  COPY_TEAM_FILE_TO_PROCESS = 'COPY_TEAM_FILE_TO_PROCESS',
  INVITE_MANY_USERS_TO_PROCESS = 'INVITE_MANY_USERS_TO_PROCESS',
  UPDATE_PROCESS_FIELDS = 'UPDATE_PROCESS_FIELDS',
  GET_PROCESS_FIELDS = 'GET_PROCESS_FIELDS',
  CREATE_FIELD_IN_PROCESS = 'CREATE_FIELD_IN_PROCESS',
  UPLOAD_BACKGROUND = 'UPLOAD_BACKGROUND',
  DELETE_BACKGROUND = 'DELETE_BACKGROUND',
  UPDATE_SVG_COLORS = 'UPDATE_SVG_COLORS',
  UPLOAD_WIDE_LOGO = 'UPLOAD_WIDE_LOGO',
  GET_PROCESS_LAST_ACTIVE_DATE = 'GET_PROCESS_LAST_ACTIVE_DATE',
  GET_PROCESS_GUESTS = 'GET_PROCESS_GUESTS',
  CHANGE_PROCESS_OWNER = 'CHANGE_PROCESS_OWNER',
}

export const getProcess = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.GET_PROCESS}`,
  async (id: string) => processApi.getProcess(id),
);

export const getProcessLastActiveDate = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.GET_PROCESS_LAST_ACTIVE_DATE}`,
  async (id: string) => processApi.getProcessLastActiveDate(id),
);

export const updateProcess = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.UPDATE_PROCESS}`,
  async (payload: Partial<ProcessDTO> & Pick<ProcessDTO, 'id'>) => processApi.updateProcess(payload),
);

export const deleteProcessBackground = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.DELETE_BACKGROUND}`,
  async (payload:string) => processApi.deleteProcessBackground(payload),
);

export const getProcessUsers = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.GET_USERS}`,
  async (payload: string) => processApi.getProcessUsers(payload),
);

export const uploadFiles = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.UPLOAD_FILES}`,
  async (payload: IUploadFilesFormData, thunkApi) => {
    const updateProgress = function (percent: number | undefined, name: string | undefined): void {
      const data = {
        progress: percent,
        name,
      };
      thunkApi.dispatch(processSlice.actions.updateFileUploadProgress(data));
    };
    return fileStorageApi.uploadFiles(payload, updateProgress);
  },
);

export const uploadFieldFile = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.UPLOAD_FIELD_FILE}`,
  async (payload: IUploadFilesFormData) => fileStorageApi.uploadFieldFile(payload),
);

export const inviteUserToProcess = createAsyncThunk<ProcessUserDTO, { email: string, message?: string }, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.INVITE_USER_TO_PROCESS}`,
  async (payload: { email: string, message?: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const isUserInTheProcessAlready: boolean = state.process.processUsers.find(
      (user: ProcessUserDTO) => user.email === payload.email
        && user.processStatus !== EUserProcessStatus.DEACTIVATED
        && user.processStatus !== EUserProcessStatus.INVITED_AND_DEACTIVATED,
    );
    if (isUserInTheProcessAlready) {
      throw new Error('nope');
    }
    const isInvitingTeammate: ProcessUserDTO | undefined = state.app?.users
      ?.find((user: OrganizationUserDTO) => user.email === payload.email);

    if (isInvitingTeammate) {
      return processApi.addUserToProcessById({
        userId: isInvitingTeammate.id,
        processId: state.process.process.id,
        rolesIds: [],
      });
    }
    return processApi.inviteUserToProcess({
      email: payload.email,
      message: payload.message ?? '',
      processId: state.process.process.id,
      rolesIds: [],
    });
  },
);

export const inviteManyUsersToProcess = createAsyncThunk<ProcessUserDTO[], { emails: string[], message?: string }, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.INVITE_MANY_USERS_TO_PROCESS}`,
  async (payload: { emails: string[], message?: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const teammates: string[] = [];
    const newUsers: string[] = [];

    payload.emails.forEach((email) => {
      const isUserInTheProcessAlready: boolean = state.process.processUsers.find(
        (user: ProcessUserDTO) => user.email.toLowerCase() === email.toLowerCase()
          && user.processStatus !== EUserProcessStatus.DEACTIVATED
          && user.processStatus !== EUserProcessStatus.INVITED_AND_DEACTIVATED,
      );
      const isTeammate: ProcessUserDTO = state.app?.users?.find(
        (user: OrganizationUserDTO) => user.email.toLowerCase() === email.toLowerCase(),
      );
      if (isTeammate && !isUserInTheProcessAlready) {
        teammates.push(isTeammate.id);
      }
      if (!isTeammate && !isUserInTheProcessAlready) {
        newUsers.push(email.toLowerCase());
      }
    });

    return processApi.inviteManyUsersToProcess({
      teammates,
      newUsers,
      processId: state.process.process.id,
      message: payload.message,
    });
  },
);

export const addUserByIdToProcess = createAsyncThunk<ProcessUserDTO, string, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.ADD_USER_BY_ID_TO_PROCESS}`,
  async (payload: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return processApi.addUserToProcessById({
      userId: payload,
      processId: state.process.process.id,
      rolesIds: [],
    });
  },
);

export const addUserByIdToProcessById = createAsyncThunk<ProcessUserDTO, { userId: string, processId: string }, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.ADD_USER_BY_ID_TO_PROCESS_BY_Id}`,
  async ({ userId, processId }: { userId: string, processId: string }, _) => processApi.addUserToProcessByIdNoCheck({
    userId,
    processId,
    rolesIds: [],
  }),
);

export const uploadLogo = createAsyncThunk(
  `${appReducerName}/${EProcessActions.UPLOAD_LOGO}`,
  async (payload: IUploadFilesFormData) => processApi.uploadLogo(payload),
);

export const uploadWideLogo = createAsyncThunk(
  `${appReducerName}/${EProcessActions.UPLOAD_WIDE_LOGO}`,
  async (payload: IUploadFilesFormData) => processApi.uploadWideLogo(payload),
);

export const uploadBackground = createAsyncThunk(
  `${appReducerName}/${EProcessActions.UPLOAD_BACKGROUND}`,
  async (payload: IUploadFilesFormData) => processApi.uploadBackground(payload),
);

export const deactivateUser = createAsyncThunk<ProcessUserDTO, string, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.DEACTIVATE_USER}`,
  async (payload: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.deactivateUser({
      userId: payload,
      processId: state.process.process.id,
    });
  },
);

export const updateProcessFields = createAsyncThunk<ProcessField[], ProcessField[], AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.UPDATE_PROCESS_FIELDS}`,
  async (payload: ProcessField[], thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.updateProcessFields(
      state.process.process.id,
      payload,
    );
  },
);

export const addStageToProcess = createAsyncThunk<{
  fields: ProcessField[];
  newField: string;
  newFieldData: ProcessStageDTO;
}, AddStageToProcessData, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.CREATE_FIELD_IN_PROCESS}`,
  async (payload: AddStageToProcessData, thunkAPI) => {
    const newStage = await processApi.addStageToProcess(payload);
    if (newStage) {
      // SET NEW STAGE ITEM AND NEW CURRENT STAGE
      thunkAPI.dispatch(setItem(newStage.newFieldData));
      thunkAPI.dispatch(setCurrentStage(newStage.newFieldData.id));
    }
    return newStage;
  },
);

export const getProcessFields = createAsyncThunk<ProcessField[], undefined, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.GET_PROCESS_FIELDS}`,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.getProcessFields(
      state.process.process.id,
    );
  },
);

export const getProcessFiles = createAsyncThunk<FileDTO[], undefined, AsyncThunkConfig>(
  `${processReducerToken}/${EProcessActions.GET_FILES}`,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.getProcessFiles(
      state.process.process.id,
      state.process.process?.organizationId,
    );
  },
);

export const updateMilestoneStatus = createAsyncThunk<TaskDTO, TaskDTO, AsyncThunkConfig>(
  `${appReducerName}/${EProcessActions.UPDATE_MILESTONE_STATUS}`,
  async (payload: TaskDTO, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.updateTask(payload, state.process.process.id);
  },
);

export const deleteProcessFile = createAsyncThunk<string, DeleteFileRequest, AsyncThunkConfig>(
  `${appReducerName}/${EProcessActions.DELETE_PROCESS_FILE}`,
  async (payload: DeleteFileRequest, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return fileStorageApi.deleteFileByPath({
      ...payload,
      processId: state.process.process.id,
    });
  },
);

export const copyTeamFileToProcess = createAsyncThunk(
  `${appReducerName}/${EProcessActions.COPY_TEAM_FILE_TO_PROCESS}`,
  async (payload: FileDTO, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return fileStorageApi.copyTeamFileToProcess({ ...payload, processId: state.process.process.id });
  },
);

export const updateAccessLinkById = createAsyncThunk(
  `${appReducerName}/${EProcessActions.UPDATE_ACCESS_LINK_BY_ID}`,
  async ({ update, id }: { update: Partial<AccessLinkDTO>, id: string }) => accessLinkApi.updateLinkById(id, update),
);

export const updateAccessProtection = createAsyncThunk(
  `${appReducerName}/${EProcessActions.UPDATE_ACCESS_PROTECTION}`,
  async ({ update, id }: { update: AccessLinkProtectionUpdate, id: string }) => accessLinkApi.updateLinkProtection(id, update),
);

export const getProcessAccessLink = createAsyncThunk(
  `${appReducerName}/${EProcessActions.GET_ACCESS_LINK}`,
  async (processId: string) => accessLinkApi.getAccessLinkByProcessId(processId),
);

export const getProcessGuests = createAsyncThunk(
  `${processReducerToken}/${EProcessActions.GET_PROCESS_GUESTS}`,
  async (id: string) => accessLinkApi.getProcessGuests(id),
);

export const getProcessAccessLinkByHash = createAsyncThunk(
  `${appReducerName}/${EProcessActions.GET_ACCESS_LINK_BY_HASH}`,
  async (hash: string) => accessLinkApi.getAccessLinkByHash(hash),
);

export const changeProcessOwner = createAsyncThunk(
  `${appReducerName}/${EProcessActions.CHANGE_PROCESS_OWNER}`,
  async (payload: IProcessChangeOwnerRequest, { dispatch }) => {
    await processApi.changeProcessOwner(payload);
    dispatch(getProcess(payload.processId));
  },
);
