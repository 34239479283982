import {
  Box, MenuItem, SvgIcon, Typography,
} from '@mui/material';
import classNames from 'classnames';
import ChevronRight from '../../../icons/ChevronRight.svg';

interface Props {
  itemName: string;
  itemAction: () => void;
  icon?: JSX.Element;
  subText?: string;
  hasSubMenu?: boolean
  hidden?: boolean
}

const AddSectionItem = ({
  itemName,
  itemAction,
  icon,
  subText,
  hasSubMenu,
  hidden,
}: Props): JSX.Element => {
  const handleAction = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    itemAction();
  };
  return (
    <MenuItem
      onClick={handleAction}
      className={classNames({ hidden })}
      sx={{
        padding: '0px',
        height: '40px',
        '&:hover': {
          background: '#E5EDFF',
        },
        '&.hidden': {
          display: 'none',
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '4px 16px',
        width: '100%',
      }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <Box
            sx={{
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon || null}
          </Box>
          <Box
            sx={{
              height: '40px',
              paddingX: '8px',
              minWidth: '150px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {itemName}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'rgba(112, 132, 180, 1)',
              }}
            >
              {subText}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
        }}
        >
          {hasSubMenu && (
          <SvgIcon
            component={ChevronRight}
            sx={{
              fill: 'transparent',
              '& path': {
                stroke: (theme) => theme.palette.grey[400],
              },
            }}
          />
          )}
        </Box>
      </Box>
    </MenuItem>
  );
};

export default AddSectionItem;
