import { Box } from '@mui/system';
import { Avatar } from '@mui/material';
import { iconColorPalette } from '../../routes-old/process/helpers';

type Props = {
  name: string,
  position: string,
  avatar: string,
  colorId?: string
};

const OwnerCardHeader = ({
  name, position, avatar, colorId,
}: Props): JSX.Element => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'row',
  }}
  >
    <Box>
      <Avatar
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          color: colorId ? iconColorPalette[colorId].letter : '',
          background: colorId ? iconColorPalette[colorId].back : '',
        }}
        src={avatar}
        alt={name}
      >
        {name[0]}
      </Avatar>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '12px',
      }}
    >
      <Box sx={{
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      >
        {name}
      </Box>
      <Box sx={{
        fontSize: '12px',
        lineHeight: '16px',
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      >
        {position}
      </Box>
    </Box>

  </Box>
);

export default OwnerCardHeader;
