import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsOwnersTeammate, selectProcess } from '../../routes-old/process/state/processSlice';
// import ActiveUsersToolbar from '../../shared/Toolbar/ActiveUsers';
import SquaredLogoPanel from '../OrgLogoPanel/SquaredLogoPanel';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { FormattedLabel } from '../../shared/AssigneeSelection/FormattedLabel';
import StagesSidebarInviteBtn from './StagesSidebarInviteBtn';
import { StagesSidebarHeaderWrapper, StagesSidebarLogoTitleWrapper } from './Styles/SidebarStagesTabStyles';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import useDealPermissions from '../../hooks/useDealPermissions';

const StagesSidebarHeader = (): JSX.Element => {
  const process = useAppSelector(selectProcess);
  const isTeammate = useAppSelector(selectIsOwnersTeammate);
  const [checkPermissions] = useDealPermissions();
  const canInvite = checkPermissions(
    EUserDealPermissions.DEAL_USERS_INVITE,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_MANAGE,
  ) && isTeammate;
  return (
    <StagesSidebarHeaderWrapper
      className={classNames({ 'stages-sidebar-header-wrapper-mobile': isMobile })}
    >
      <StagesSidebarLogoTitleWrapper>
        <SquaredLogoPanel />
        <FormattedLabel
          label={process.sellerName || ''}
          len={24}
          tooltip
          sx={{
            fontWeight: 600,
            lineHeight: '26px',
            color: (theme) => greySidebarContrastText(theme, 0.8),
          }}
        />
      </StagesSidebarLogoTitleWrapper>
      {/* <ActiveUsersToolbar /> */}
      {' '}
      {/* TODO rework logic and return component */}
      {isMobile && !canInvite && <StagesSidebarInviteBtn />}
    </StagesSidebarHeaderWrapper>
  );
};

export default StagesSidebarHeader;
