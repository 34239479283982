import {
  Box,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import { TemplatePickerDialog } from './TemplatePickerStyles';
import TemplatesTabsHeader from './TemplatePickerHeader';
import Template from './Template';
import TemplateList from './TemplateList';
import { setSelectedTemplateData } from '../../pages/templates/lib/templatesSlice';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppDispatch } from '../../hooks/stateHooks';
import { CreateDealForm } from './CreateDealForm';
import { MobileTabs } from './MobileTabs/MobileTabs';
import { resetDealCreationData } from './lib/dealCreationDataSlice';

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void;
};

export const TemplatePicker = ({
  open, setOpen,
}: Props) => {
  const dispatch = useAppDispatch();
  const [chosenTemplate, chooseTemplate] = useState<string>();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(resetDealCreationData());
  }, [open]);

  const onTemplateSelect = (id: string, title: string) => {
    dispatch(setSelectedTemplateData({ id, title, type: EProcessType.TEMPLATE }));
  };

  const onTemplateUse = (id: string) => {
    chooseTemplate(id);
    setActiveTab(1);
  };

  if (isMobile) return <MobileTabs open={open} setOpen={setOpen} />;

  return (
    <TemplatePickerDialog
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(26, 25, 41, 0.4)',
        },
      }}
      PaperProps={{
        className: classNames('dialog-paper', { 'dialog-paper-mobile': isMobile }),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <TemplatesTabsHeader onClose={() => setOpen(false)} />
        <Box
          sx={{
            height: isMobile ? 'calc( 100% )' : 'calc( 100% - 64px )',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: 'calc( 100% - 0px )',
            gap: '24px',
            padding: isMobile ? '0' : '0 24px',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              maxWidth: isMobile ? '100%' : '390px',
              minWidth: isMobile ? '100%' : '390px',
            }}
            hidden={isMobile}
          >
            <SwipeableViews
              index={activeTab}
              animateHeight
              onChangeIndex={setActiveTab}
              disabled
              containerStyle={{
                height: '100%',
              }}
              style={{
                overflowY: 'hidden',
                height: '100%',
              }}
            >
              <TemplateList onTemplateSelect={onTemplateSelect} onTemplateUse={onTemplateUse} />
              <CreateDealForm templateId={chosenTemplate!} onGoBack={() => setActiveTab(0)} />
            </SwipeableViews>
          </Box>
          {
          !isMobile
          && (
            <Box
              sx={{
                background: (theme) => theme.palette.grey[100],
                borderRadius: '10px 10px 0 0',
                borderWidth: '1px 1px 0px 1px',
                borderStyle: 'solid',
                borderColor: '#EEF0FA',
                height: 'calc( 100% )',
                width: 'calc( 100% - 414px)',
                maxWidth: 'calc( 100% - 414px)',
                minWidth: 'calc( 100% - 414px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0px 0px 1px 0px rgba(0, 57, 96, 0.1), 0px 0px 18px -2px rgba(0, 57, 96, 0.04)',
                overflow: 'hidden',
                '& .template-header': {
                  paddingX: '40px',
                },
              }}
            >
              <Template />
            </Box>
          )
        }
        </Box>
      </Box>
    </TemplatePickerDialog>
  );
};
