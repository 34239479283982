import { styled } from '@mui/styles';
import { Box, Dialog } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const InviteToTeamDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& .dialog-paper': {
    overflow: 'visible',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '400px',
    marginTop: '40px',
    maxHeight: '745px',
    height: '100%',
    '@media (max-height: 420px)': {
      overflow: 'auto',
    },
    '@media (max-width: 420px)': {
      maxWidth: '100%',
      justifyContent: 'center',
    },
    '& .grid-container': {
      minWidth: '400px',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0px 0px 18px -2px rgba(30, 41, 59, 0.10), 0px 0px 3px 0px rgba(30, 41, 59, 0.10)',
      '@media (max-width: 600px)': {
        maxWidth: '100%',
        minWidth: 'unset',
      },
    },
    '& .grid-container.grid-container-mobile': {
      minWidth: 'auto',
      height: '100%',
      borderRadius: '0px',
    },
    '& .paper-wrapper': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxShadow: 'none',
      maxHeight: '745px',
      minWidth: '400px',
      height: 'auto',
      '@media (max-width: 600px)': {
        maxWidth: '100%',
        minWidth: 'unset',
      },
    },
    '& .paper-wrapper.paper-wrapper-mobile': {
      maxHeight: '100%',
      minWidth: 'auto',
      width: '100%',
    },
    '& .dialog-title-container': {
      padding: '16px 16px 16px 24px',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .dialog-title': {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '26px',
        color: stagesetColors.newGrey[800],
      },
    },
    '& .dialog-content': {
      maxHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '0',
      '& .team-tab-dialog-content-paper': {
        '& .MuiTextField-root': {
          m: 1,
          margin: '0',
          fontSize: '16px !important',
        },
        width: '100%',
        gap: '16px',
        boxShadow: 'none',
        alignItems: 'center',
        display: 'inline-flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        padding: '8px 24px 16px 24px',
        '& .text-field': {
          width: '100%',
          '& .input': {
            borderRadius: '10px',
            minHeight: '48px',
            maxHeight: '179px',
            fontSize: '16px',
            padding: '0px 14px',
            alignContent: 'flex-end',
            overflow: 'hidden',
            '& .MuiChip-root': {
              margin: '2px 5px 2px 0px',
              borderRadius: '8px',
              height: '24px',
              fontSize: '13px',
              border: '1px solid #bdbdbd',
              '& .MuiSvgIcon-root': {
                color: 'rgba(0, 0, 0, 0.26)',
                '&:hover': {
                  color: 'rgba(0, 0, 0, 0.4)',
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
            },
            minWidth: '200px',
            flex: 1,
          },
        },
      },
      '& .circular-progress': {
        color: '#fff',
      },
    },
    '& .dialog-content.dialog-content-mobile': {
      maxHeight: '100%',
    },
  },
  '& .dialog-paper.dialog-paper-mobile': {
    width: '100%',
    margin: '0px',
    maxHeight: '100%',
  },
}));

export const TeamTabModalButtonContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '12px 24px',
  background: stagesetColors.newGrey[50],
  borderTop: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  width: '100%',
  justifyContent: 'center',
  height: '64px',
  '&.button-container-mobile': {
    height: '48px',
    padding: '4px 24px',
  },
}));
