import {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Check } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  LibraryFilesFilterCategory,
  LibraryFilesFilterParams,
  LibraryFilesFilterSet,
  LibraryFilesFilterTitle,
  LibraryFilesMultipleFilter,
  LibraryFilesOwnerFilterItem,
  SubItem,
} from '../../../../shared/library/LibraryFilter';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import useDebounce from '../../../hooks/useDebounce';
import {
  selectLibraryFilesCollectFilterSetInfoDialogStatus,
  selectLibraryFilesExpanded,
  selectLibraryFilesFilterParams,
  selectLibraryFilesFilterSearch,
  selectLibraryFilesGridFilter,
  selectLibraryFilesManageFilterSetsDialogStatus,
  selectLibraryFilesPopupCategory,
  setLibraryFilesFilterExpanded,
  setLibraryFilesFilterPopupCategory,
  setLibraryFilesGridFilter,
  setLibraryFilesManageFilterSetsDialogStatus,
  setLibraryFilesMobileFilterMenu,
  setLibraryFilesSearch,
  setLibraryFilesNewFilterSetDialogStatus,
  selectLibraryFilesFilterInfo,
  allFilesFiltersSelector,
  createNewFilter,
  getUserFilesFilters,
  updateFilter,
  deleteFilter,
  getLibraryFilesFilterInfo,
  selectLibraryFilesMobileFilterMenu,
} from '../lib/libraryFilesFilterSlice';
import { AppDropdownItemProps } from '../../../shared/AppDropdown/AppDropdownItem';
import { EFileType } from '../../../../shared/library/Library';
import { AppAvatar } from '../../../shared/AppAvatar';
import { AppButton } from '../../../shared/AppButton/AppButton';
import TypesIcon from '../../../icons/Types.svg';
import OwnerIcon from '../../../icons/CrownGrey.svg';
import LinkIcon from '../../../icons/link20.svg';
import { selectFilesLoadingJobs, selectLibraryGridToRender } from '../lib/librarySlice';

type UpdateMultipleFilter<T = {}> = { category: LibraryFilesFilterCategory, item: SubItem<T>, title: LibraryFilesFilterTitle };
type RemoveMultipleFilterValue = { category: LibraryFilesFilterCategory, value: string };

export const useLibraryFilesGridFilter = () => {
  const dispatch = useAppDispatch();
  const search: string = useAppSelector(selectLibraryFilesFilterSearch);
  const gridFilters = useAppSelector(selectLibraryFilesGridFilter);
  const libraryFilesFilterInfos = useAppSelector(selectLibraryFilesFilterInfo);
  const expanded = useAppSelector(selectLibraryFilesExpanded);
  const popupCategory = useAppSelector(selectLibraryFilesPopupCategory);
  const newFilterSetDialogStatus = useAppSelector(selectLibraryFilesCollectFilterSetInfoDialogStatus);
  const manageFilterSetsDialogStatus = useAppSelector(selectLibraryFilesManageFilterSetsDialogStatus);
  const filterParams: LibraryFilesFilterParams = useAppSelector(selectLibraryFilesFilterParams);
  const [anchorEl, setAnchorEl] = useState<DOMRect>();
  const debouncedGridFilters = useDebounce(gridFilters, 250);
  const debouncedFilterSearch = useDebounce(search, 250);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const savedFilters = useAppSelector(allFilesFiltersSelector);
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const loadingJobs = useAppSelector(selectFilesLoadingJobs);

  useEffect(() => {
    dispatch(getUserFilesFilters());
  }, [gridToRender]);

  useEffect(() => {
    dispatch(getLibraryFilesFilterInfo());
  }, [debouncedGridFilters, debouncedFilterSearch]);

  const closeMenu = () => {
    if (isMobile) {
      dispatch(setLibraryFilesMobileFilterMenu(false));
    } else {
      setAnchorEl(undefined);
    }
  };

  const openMenu = (node: Element, category?: LibraryFilesFilterCategory) => {
    dispatch(setLibraryFilesFilterPopupCategory(category || null));
    if (isMobile) {
      dispatch(setLibraryFilesMobileFilterMenu(true));
    } else {
      setAnchorEl(node.getBoundingClientRect());
    }
  };

  const getCategoryItem = (category: LibraryFilesFilterCategory, value: string | number) => category in filterParams
    && (filterParams[category]! as LibraryFilesMultipleFilter)
      .subItems.find((subItem) => subItem.value === value);

  const updateFilterParams = async (params: LibraryFilesFilterParams) => {
    dispatch(setLibraryFilesGridFilter(params));
  };

  const updateSearch = (searchQuery: string) => {
    dispatch(setLibraryFilesSearch(searchQuery));
  };

  const saveNewFilter = async (filterSet: LibraryFilesFilterSet) => {
    await dispatch(createNewFilter(filterSet)).unwrap();
    enqueueSnackbar('The filter set has been saved', {
      variant: 'success',
    });
  };

  const updateFilterSet = async (id: string, name: string) => {
    await dispatch(updateFilter({ id, filterSet: { name } as Partial<LibraryFilesFilterSet> })).unwrap();
    enqueueSnackbar('The filter set has been successfully changed', {
      variant: 'success',
    });
  };

  const deleteFilterSet = async (deleteId: string) => {
    if (deleteId) {
      await dispatch(deleteFilter(deleteId)).unwrap();
    }
  };

  const getFilterInfo = () => {
    dispatch(getLibraryFilesFilterInfo());
  };

  const updateMultipleFilter = (toUpdate: UpdateMultipleFilter) => {
    const mutableFilters: LibraryFilesFilterParams = JSON.parse(JSON.stringify(filterParams));
    const currentFilter = mutableFilters[toUpdate.category];
    if (!currentFilter) {
      mutableFilters[toUpdate.category] = {
        category: toUpdate.category,
        title: toUpdate.title,
        subItems: [toUpdate.item],
      } as LibraryFilesMultipleFilter;
      return updateFilterParams(mutableFilters);
    }
    const currentMultipleFilter = currentFilter as LibraryFilesMultipleFilter;
    const itemIndex = currentMultipleFilter.subItems.findIndex((subItem) => toUpdate.item.value === subItem.value);
    if (itemIndex === -1) {
      currentMultipleFilter.subItems.push(toUpdate.item);
    } else {
      if (currentMultipleFilter.subItems.length === 1) {
        delete mutableFilters[currentMultipleFilter.category];
        return updateFilterParams(mutableFilters);
      }
      currentMultipleFilter.subItems.splice(itemIndex, 1);
    }
    mutableFilters[currentMultipleFilter.category] = currentMultipleFilter;
    return updateFilterParams(mutableFilters);
  };

  const removeMultipleFilter = (toRemove: RemoveMultipleFilterValue) => {
    const mutableFilters: LibraryFilesFilterParams = JSON.parse(JSON.stringify(filterParams));
    const currentFilter = mutableFilters[toRemove.category];
    if (!currentFilter) {
      return null;
    }
    const currentMultipleFilter = currentFilter as LibraryFilesMultipleFilter;
    const itemIndex = currentMultipleFilter.subItems.findIndex((subItem) => toRemove.value === subItem.value);
    if (itemIndex === -1) return null;
    if (currentMultipleFilter.subItems.length === 1) {
      delete mutableFilters[currentMultipleFilter.category];
      return updateFilterParams(mutableFilters);
    }
    currentMultipleFilter.subItems.splice(itemIndex, 1);
    mutableFilters[currentMultipleFilter.category] = currentMultipleFilter;
    return updateFilterParams(mutableFilters);
  };

  const removeFilter = (key: LibraryFilesFilterCategory) => {
    const mutableFilters: LibraryFilesFilterParams = JSON.parse(JSON.stringify(filterParams));
    delete mutableFilters[key];
    updateFilterParams(mutableFilters);
  };

  const multipleMenuItemParams = (
    filter: UpdateMultipleFilter,
    icon?: ReactElement,
  ): Partial<AppDropdownItemProps> & Pick<AppDropdownItemProps, 'label'> => ({
    label: filter.item.title,
    onClick: () => {
      dispatch(setLibraryFilesFilterPopupCategory(filter.category));
      updateMultipleFilter(filter);
    },
    startIcon: icon,
    endIcon: getCategoryItem(filter.category, filter.item.value) ? (
      <Box sx={{
        position: 'absolute',
        right: '15px',
        top: '30%',
      }}
      >
        <Check />
      </Box>
    ) : undefined,
    sx: {
      minWidth: '200px',
    },
    selected: !!getCategoryItem(filter.category, filter.item.value),
    selectable: ['owner', 'type'].includes(filter.category),
    value: filter.item.value,
  });

  const resetCategoryFilterParams = (category: LibraryFilesFilterCategory): Partial<AppDropdownItemProps> => ({
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();
      removeFilter(category);
    },
    endIcon: category in filterParams ? undefined : (
      <Box sx={{
        position: 'absolute',
        right: '15px',
        top: '30%',
      }}
      >
        <Check />
      </Box>
    ),
  });

  const typeOpts = useMemo(() => [
    {
      label: t('Filters_All'),
      ...resetCategoryFilterParams('type'),
    },
    multipleMenuItemParams({
      category: 'type',
      title: 'Type',
      item: { title: 'File', value: EFileType.FILE },
    }, TypesIcon),
    multipleMenuItemParams({
      category: 'type',
      title: 'Type',
      item: { title: 'Link', value: EFileType.LINK },
    }, LinkIcon),
  ], [filterParams]);

  const ownerOpts = useMemo(() => [
    {
      label: t('Filters_All'),
      ...resetCategoryFilterParams('owner'),
    },
  ].concat((libraryFilesFilterInfos && libraryFilesFilterInfos.owner ? libraryFilesFilterInfos.owner : []).map(
    (owner: LibraryFilesOwnerFilterItem) => multipleMenuItemParams({
      category: 'owner',
      title: 'Owner',
      item: {
        title: owner.name, value: owner.id, avatar: owner.avatar, colorId: owner.colorId,
      },
    }, <AppAvatar src={owner.avatar} name={owner.name} colorId={owner.colorId} />),
  )), [filterParams, libraryFilesFilterInfos]);

  const allOpts: AppDropdownItemProps[] = useMemo(() => {
    const mainOpts: AppDropdownItemProps[] = [
      {
        label: 'Owner',
        startIcon: <OwnerIcon />,
        childrenItems: ownerOpts,
      },
      {
        label: 'Type',
        startIcon: <TypesIcon />,
        childrenItems: typeOpts,
      },
    ];

    const savedFiltersOpts: AppDropdownItemProps[] = [];

    if (savedFilters?.length) {
      const savedFiltersHeaderOpts = [
        { label: '', divider: true },
        {
          label: 'Saved',
          category: true,
          endIcon:
  <AppButton
    onClick={() => {
      dispatch(setLibraryFilesManageFilterSetsDialogStatus(true));
      dispatch(setLibraryFilesMobileFilterMenu(false));
      setAnchorEl(undefined);
    }}
    size="xs"
    sx={{
      padding: 0,
    }}
  >
    Settings
  </AppButton>,
        },
      ];

      const savedFiltersMenuItems = savedFilters?.map((filter) => ({
        label: filter.name,
        onClick: () => {
          updateFilterParams(filter.filterParams);
          enqueueSnackbar(`The filter set "${filter.name}" has been chosen`, {
            variant: 'success',
          });
          closeMenu();
        },
      }));

      savedFiltersOpts
        .push(...savedFiltersHeaderOpts, ...savedFiltersMenuItems);
    }

    return mainOpts.concat(savedFiltersOpts);
  }, [typeOpts, ownerOpts]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    updateSearch(e.target.value);
  };

  const getPopupItems = () => {
    switch (popupCategory) {
      case 'type':
        return typeOpts;
      case 'owner':
        return ownerOpts;
      default:
        return allOpts;
    }
  };

  const mobileIndex = useMemo(() => {
    switch (popupCategory) {
      case 'type':
        return 2;
      case 'owner':
        return 1;
      default:
        return 0;
    }
  }, [popupCategory]);

  const openFilter = () => {
    dispatch(setLibraryFilesFilterExpanded(true));
  };

  const closeFilter = () => {
    if (isMobile) return;
    dispatch(setLibraryFilesFilterExpanded(false));
  };

  return {
    libraryFilesFilterInfos,
    removeFilter,
    allOpts,
    anchorEl,
    expanded,
    ownerOpts,
    typeOpts,
    handleSearchChange,
    getPopupItems,
    setAnchorEl,
    setLibraryFilesFilterPopupCategory,
    popupCategory,
    mobileIndex,
    getCategoryItem,
    removeMultipleFilter,
    openFilter,
    closeFilter,
    newFilterSetDialogStatus,
    manageFilterSetsDialogStatus,
    openMenu,
    closeMenu,
    debouncedGridFilters,
    debouncedFilterSearch,
    updateFilterParams,
    updateSearch,
    filterParams,
    search,
    setLibraryFilterExpanded: setLibraryFilesFilterExpanded,
    setLibraryNewFilterSetDialogStatus: setLibraryFilesNewFilterSetDialogStatus,
    setLibraryManageFilterSetsDialogStatus: setLibraryFilesManageFilterSetsDialogStatus,
    setMobileFilterMenu: setLibraryFilesMobileFilterMenu,
    selectMobileFilterMenu: selectLibraryFilesMobileFilterMenu,
    savedFilters,
    saveNewFilter,
    updateFilterSet,
    deleteFilterSet,
    gridToRender,
    loadingJobs,
    getFilterInfo,
  };
};
