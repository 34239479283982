import type { ThemeOptions } from '@mui/material/styles';

/**
 * Style overrides for Material UI components.
 */
export const components: ThemeOptions['components'] = {
  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/Button
  MuiButton: {
    styleOverrides: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        color: 'white',
      },
    },
  },
  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/ButtonGroup
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        padding: '0 0 16px 0',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '5px',
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: '0 2px 2px rgba(0, 0, 0, .15)',
        borderRadius: '5px',
      },
    },
  },
};
