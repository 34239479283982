import { styled } from '@mui/styles';
import { Avatar, Box } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const CompanyLogoPlaceholderFrame = styled(Box)(() => ({
  width: '72px',
  height: '72px',
  padding: '16px',
  backgroundColor: stagesetColors.white[100],
  borderRadius: '16px',
  borderColor: stagesetColors.newGrey[100],
  border: '1px solid',
}));

export const CompanyLogo = styled(Avatar)(() => ({
  width: '72px',
  height: '72px',
  borderRadius: '16px',
  borderColor: stagesetColors.newGrey[100],
  border: '1px solid',
  display: 'flex',
  '& img': {
    objectFit: 'cover',
    flex: 1,
  },
}));
