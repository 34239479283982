import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { selectUserId, selectTeammatesIdsMap } from '../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import useProcessTimelines from '../../hooks/useProcessTimelines';
import ProcessTimelineSession from './ProcessTimelineSession';
import { selectProcessId } from '../../routes-old/process/state/processSlice';
import { getProcessLastActiveDate } from '../../routes-old/process/state/dealActions';
import { AppLoader } from '../../shared/AppLoader/AppLoader';
import { stagesetColors } from '../../theme/stagesetPalette';
import { NoContentBalloonPlaceholder } from '../../shared/NoContentBalloonPlaceholder/NoContentBalloonPlaceholder';

const TimelineContent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(selectProcessId);
  const userId = useAppSelector(selectUserId);
  const teammatesIds = useAppSelector(selectTeammatesIdsMap);

  const { timeline, refetch } = useProcessTimelines();

  useEffect(() => {
    dispatch(getProcessLastActiveDate(processId));
    refetch();
  }, [processId]);

  const sessions = useMemo(() => {
    let currentDate: number;
    let newDate: number;

    const res = timeline?.map((item, i) => {
      let showDate = false;

      newDate = new Date(item[0].createdAt).setHours(0, 0, 0, 0);

      if (newDate !== currentDate) {
        currentDate = newDate;
        showDate = true;
      }

      return (
        <React.Fragment
          key={`timeline-fragment-${i}-${item[0]?._id}`}
        >
          {
          showDate && (
          <Box
            key={`timeline-date-${new Date(item[0].createdAt).getTime()}`}
            sx={{
              color: stagesetColors.grey[400],
              maxWidth: '900px',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '50px',
              fontWeight: 500,
              fontSize: '14px',
              marginTop: '12px',
              marginBottom: '0px',
              padding: '4px 6px',
              background: '#FFF',
              borderRadius: '6px',
              // eslint-disable-next-line max-len
              boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04), 0px 1px 1px 0px rgba(30, 41, 59, 0.07), 0px 0px 1px 0px rgba(30, 41, 59, 0.08), 0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
            }}
          >
            {new Date(item[0].createdAt).toLocaleDateString()}
          </Box>
          )
          }
          <ProcessTimelineSession
            key={`timeline-session-${i}-${item[0]?._id}`}
            session={item}
            isLast={timeline.length - 1 === i}
          />
        </React.Fragment>
      );
    });

    return res;
  }, [
    timeline,
    teammatesIds,
  ]);

  if ((!timeline && !sessions) || sessions?.length === 0) {
    const placeholder = () => {
      if (!timeline && !sessions) {
        return (
          <AppLoader
            size={40}
          />
        );
      } return (
        <NoContentBalloonPlaceholder description="No activity yet" size={80} />
      );
    };
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          marginTop: '4px',
          zIndex: 3,
          paddingBottom: '84px',
          paddingTop: '32px',
          background: 'transparent',
          alignItems: 'center',
          height: 'calc(100vh - 76px)',
        }}
      >
        {placeholder()}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        zIndex: 3,
        paddingBottom: '84px',
        background: 'transparent',
        alignItems: 'center',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '0px',
          display: 'none',
        },
      }}
    >
      {sessions}
    </Box>
  );
};

export default TimelineContent;
