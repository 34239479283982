import useFieldsLayoutSlice from '../../hooks/useFieldsLayoutSlice';
import { AppIconButton } from '../AppButton/AppIconButton';
import { CloseIcon } from '../../icons/Close16px';
import { BurgerMenuIcon } from '../../icons/BurgerMenuIcon';

const StagesSidebarButton = (): JSX.Element => {
  const { isDrawerOpen, onDrawerStatusUpdate } = useFieldsLayoutSlice();
  return (
    <AppIconButton
      onClick={() => onDrawerStatusUpdate(!isDrawerOpen)}
      variant="secondary"
      icon={isDrawerOpen ? <CloseIcon /> : <BurgerMenuIcon />}
    />
  );
};

export default StagesSidebarButton;
