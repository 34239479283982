import { Box, Typography } from '@mui/material';
import { ESubscriptionType } from '../../../shared/Subscription';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { StripeInterval } from '../../../shared/stripe';

export type PaymentPeriodOptionProps = {
  value: string | JSX.Element,
  saleText?: string | JSX.Element,
  perUser?: boolean
  type?: ESubscriptionType,
  interval?: StripeInterval,
};

export const PaymentPeriodOption = ({
  value, saleText, perUser, type, interval,
}: PaymentPeriodOptionProps) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
  }}
  >
    <Box>
      <Typography
        color={type === ESubscriptionType.SELLER_PREMIUM ? '#fff' : stagesetPalette.grey?.[600]}
        fontSize={32}
        fontWeight={600}
      >
        {value}
      </Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '12px',
      height: '40px',
      justifyContent: 'flex-end',
    }}
    >
      <Box>
        <Typography
          fontWeight={600}
          fontSize={14}
          color="#C94937"
        >
          {saleText}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize={16}
          color={type === ESubscriptionType.SELLER_PREMIUM ? stagesetPalette.secondary?.dark : stagesetPalette.grey?.[300]}
        >
          {perUser && `/ ${interval} per user`}
        </Typography>
      </Box>
    </Box>
  </Box>
);
