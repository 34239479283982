import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { InviteToTeamDialog, TeamTabModalButtonContainer } from './TeamTabInviteToTeamPopupStyles';
import { AppButton } from '../../shared/AppButton/AppButton';
import { AppInput } from '../../shared/AppInput/AppInput';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { CloseIcon20 } from '../../icons/CloseIcon';

export interface FormState {
  inviteEmail: string;
}

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void;
  onInvite: (email: string) => void;
  loading: boolean
};

export const TeamTabInviteToTeamPopup = ({
  open,
  setOpen,
  onInvite,
  loading,
}: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  const settingsFormSchemaConfig = useMemo(() => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    validationSchemaConfigTemp.inviteEmail = yup
      .string()
      .required(t('Error_Email_is_required'))
      .email(t('Error_The_email_must_be_a_valid_email_address'));
    return validationSchemaConfigTemp;
  }, []);

  const schema = yup.object(settingsFormSchemaConfig).required();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<FormState>({
    mode: 'onChange',
    defaultValues: {
      inviteEmail: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isValid || !isDirty) {
      setDisabled(true);
    }
    if (isDirty && isValid) {
      setDisabled(false);
    }
  }, [isValid, isDirty]);

  const onFormUpdate = (data: FormState): void => {
    onInvite(data.inviteEmail);
    console.log('data.inviteEmail');
    console.log(data.inviteEmail);
  };

  return (
    <InviteToTeamDialog
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        className: classNames('dialog-paper', { 'dialog-paper-mobile': isMobile }),
      }}
    >
      <Grid
        className={classNames('grid-container', { 'grid-container-mobile': isMobile })}
        container
        component="form"
        onSubmit={handleSubmit(onFormUpdate)}
        noValidate
        autoComplete="off"
      >
        <Paper
          className={classNames('paper-wrapper', { 'paper-wrapper-mobile': isMobile })}
        >
          <DialogTitle
            className="dialog-title-container"
          >
            <Typography
              className="dialog-title"
            >
              Send invite
            </Typography>
            <AppIconButton
              icon={<CloseIcon20 />}
              variant="transparent"
              onClick={() => setOpen(false)}
            />
          </DialogTitle>
          <DialogContent
            className={classNames('dialog-content', { 'dialog-content-mobile': isMobile })}
          >
            <Box
              className="InvitePaperControllerBox"
              id="invite-many-users-to-process-form"
            >
              <Paper
                className="team-tab-dialog-content-paper"
              >
                <Controller
                  name="inviteEmail"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <AppInput
                      placeholder="Enter email to invite user"
                      color="primary"
                      fullWidth
                      size="l"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Paper>
            </Box>
          </DialogContent>
          <TeamTabModalButtonContainer
            className={classNames('button-container', { 'button-container-mobile': isMobile })}
          >
            <AppButton
              fullWidth
              loading={loading}
              variant="primary"
              size="l"
              type="submit"
              disabled={disabled}
            >
              Send invitation
            </AppButton>
          </TeamTabModalButtonContainer>
        </Paper>
      </Grid>
    </InviteToTeamDialog>
  );
};
