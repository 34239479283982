import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStateStatus } from '../../../../core/commonTypes';
import { RootState } from '../../../../core/store/store';
import {
  FilterCategory, FilterInfo, FilterParams,
} from '../../../../../shared/process/ProcessFilter';
import { EDealsActions } from '../../../../routes-old/deals/state/dealsSlice';
import { ProcessApi } from '../../../../api/ProcessApi';
import { DealsType } from '../../../../../shared/process/Process';
import { getUserData, getUserFilter } from '../../../../core/store/appState/appState';
import { UserDTO } from '../../../../../shared/UserDTO';

export interface DataGridFilter {
  status: EStateStatus;
  search: string;
  expanded: boolean;
  popupCategory: FilterCategory | null;
  filterInfo: FilterInfo;
  newFilterSetDialog: boolean;
  manageFilterSetsDialog: boolean;
  mobileMenu: boolean;
  gridFilters: Record<DealsType.BUYER_DEALS | DealsType.SELLER_DEALS | DealsType.TEAM_DEALS, FilterParams>;
  filterInfos: Record<DealsType.BUYER_DEALS | DealsType.SELLER_DEALS, FilterInfo>;
}
const processApi: ProcessApi = new ProcessApi();

export const dealsGridFilterReducerName: string = 'dealsGridFilter';

export const getProcessesFilterInfo = createAsyncThunk(
  `${dealsGridFilterReducerName}/${EDealsActions.GET_FILTER_INFO}`,
  async () => processApi.getFilterInfo(),
);

const initialState: DataGridFilter = {
  status: EStateStatus.IDLE,
  filterInfo: {
    owners: [],
    companies: [],
  },
  search: '',
  expanded: false,
  popupCategory: null,
  newFilterSetDialog: false,
  manageFilterSetsDialog: false,
  mobileMenu: false,
  gridFilters: {
    [DealsType.SELLER_DEALS]: {},
    [DealsType.BUYER_DEALS]: {},
    [DealsType.TEAM_DEALS]: {},
  },
  filterInfos: {
    [DealsType.SELLER_DEALS]: {
      owners: [],
      companies: [],
    },
    [DealsType.BUYER_DEALS]: {
      owners: [],
      companies: [],
    },
  },
};

export const dealsGridSlice = createSlice({
  name: dealsGridFilterReducerName,
  initialState,
  reducers: {
    resetFilter: () => initialState,
    setSearch: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      search: payload,
    }),
    setGridFilters: (state, { payload }: PayloadAction<{ grid: DealsType, params: FilterParams }>) => ({
      ...state,
      gridFilters: {
        ...state.gridFilters,
        [payload.grid]: payload.params,
      },
    }),
    setExpanded: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      expanded: payload,
    }),
    setPopupCategory: (state, { payload }: PayloadAction<FilterCategory | null>) => ({
      ...state,
      popupCategory: payload,
    }),
    setNewFilterSetDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      newFilterSetDialog: payload,
    }),
    setManageFilterSetsDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      manageFilterSetsDialog: payload,
    }),
    setMobileFilterMenu: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      mobileMenu: payload,
    }),
  },
  extraReducers: ((builder) => builder
    .addCase(getProcessesFilterInfo.fulfilled, (state, action) => {
      state.filterInfos = action.payload;
    })
    .addCase(getUserData.fulfilled, (state, action) => {
      const userDto: UserDTO = action.payload;
      let sellerFilter: Record<DealsType.SELLER_DEALS, FilterParams> = { [DealsType.SELLER_DEALS]: {} };
      let buyerFilter: Record<DealsType.BUYER_DEALS, FilterParams> = { [DealsType.BUYER_DEALS]: {} };
      if (userDto.sellerFilterParams) {
        sellerFilter = {
          [DealsType.SELLER_DEALS]: userDto.sellerFilterParams,
        };
      }
      if (userDto.buyerFilterParams) {
        buyerFilter = {
          [DealsType.BUYER_DEALS]: userDto.buyerFilterParams,
        };
      }
      return {
        ...state,
        gridFilters: {
          ...state.gridFilters,
          ...sellerFilter,
          ...buyerFilter,
        },
      };
    })
    .addCase(getUserFilter.fulfilled, (state, action) => {
      const userDto: UserDTO = action.payload;
      let sellerFilter: Record<DealsType.SELLER_DEALS, FilterParams> = { [DealsType.SELLER_DEALS]: {} };
      let buyerFilter: Record<DealsType.BUYER_DEALS, FilterParams> = { [DealsType.BUYER_DEALS]: {} };
      if (userDto.sellerFilterParams) {
        sellerFilter = {
          [DealsType.SELLER_DEALS]: userDto.sellerFilterParams,
        };
      }
      if (userDto.buyerFilterParams) {
        buyerFilter = {
          [DealsType.BUYER_DEALS]: userDto.buyerFilterParams,
        };
      }
      return {
        ...state,
        gridFilters: {
          ...state.gridFilters,
          ...sellerFilter,
          ...buyerFilter,
        },
      };
    })
  ),
});

export const {
  resetFilter,
  setSearch,
  setGridFilters,
  setExpanded,
  setPopupCategory,
  setNewFilterSetDialogStatus,
  setManageFilterSetsDialogStatus,
  setMobileFilterMenu,
} = dealsGridSlice.actions;

export const selectFilterSearch = (state: RootState) => state.dealsGridFilter.search;
export const selectGridFilters = (state: RootState) => state.dealsGridFilter.gridFilters;
export const selectFilterInfo = (state: RootState) => state.dealsGridFilter.filterInfo;
export const selectExpanded = (state: RootState) => state.dealsGridFilter.expanded;
export const selectPopupCategory = (state: RootState) => state.dealsGridFilter.popupCategory;
export const selectCollectFilterSetInfoDialogStatus = (state: RootState) => state
  .dealsGridFilter
  .newFilterSetDialog;
export const selectManageFilterSetsDialogStatus = (state: RootState) => state
  .dealsGridFilter
  .manageFilterSetsDialog;
export const selectMobileFilterMenu = (state: RootState) => state
  .dealsGridFilter
  .mobileMenu;
export const selectFilterInfos = (state: RootState) => state.dealsGridFilter.filterInfos;
