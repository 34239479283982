import { ECustomEventsNames } from '../../../../shared/events/CustomEvents';
import { TiptapCustomEventDetail } from '../TiptapEventListnerPlugin';

const useEditorEvent = () => {
  const dispatchEditorEvent = (detail:TiptapCustomEventDetail) => {
    document.dispatchEvent(new CustomEvent(`${ECustomEventsNames.TIPTAP_EDITOR_CUSTOM_EVENT}`, {
      detail,
    }));
  };

  return { dispatchEditorEvent };
};

export default useEditorEvent;
