import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { AppButton } from '../AppButton/AppButton';
import useCelloUi from '../../hooks/useCelloUi';
// import bg from './CelloBannerBG.png';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectOrganization } from '../../core/store/appState/appState';
import { CelloApi } from '../../api/CelloApi';
import {
  CelloBackgroundImage,
  CelloBannerContainer,
  CelloBannerImageContainer,
  CelloBannerInfoBox,
  CelloBannerSubtitle,
  CelloBannerTextBox,
  CelloBannerTitle,
} from './Styles/CelloBannerStyles';

const celloApi = new CelloApi();

const CelloBannerNew = (): JSX.Element | null => {
  const organization = useAppSelector(selectOrganization);
  const [referrerName, setReferrerName] = useState('');
  const { open } = useCelloUi();
  const bg = 'https://d2nfbjsnctzi3k.cloudfront.net/cello-banner/5de829aca76d3ad174da.png';

  const getReferrerName = async () => {
    if (organization.celloReferrer) {
      celloApi.getReferrerName(organization.celloReferrer).then((res) => setReferrerName(res));
    }
  };

  useEffect(() => {
    if (organization?.celloReferrer && !referrerName) {
      getReferrerName();
    }
  }, [organization?.celloReferrer, referrerName]);

  if (!organization.celloReferrer) {
    return null;
  }

  return (
    <CelloBannerContainer
      className={classNames({ 'cello-banner-container-mobile': isMobile })}
    >
      <CelloBannerInfoBox
        className={classNames({ 'cello-banner-info-box-mobile': isMobile })}
      >
        <CelloBannerTextBox
          className={classNames({ 'cello-banner-text-box-mobile': isMobile })}
        >
          <CelloBannerTitle>
            You have 30% off thanks to being invited
            {referrerName ? ` by ${referrerName}` : ''}
          </CelloBannerTitle>
          <CelloBannerSubtitle>
            Invite friends and earn up to $5,000 per referral
          </CelloBannerSubtitle>
        </CelloBannerTextBox>
        <AppButton
          onClick={open}
          variant="tertiary-newGrey"
          size={isMobile ? 'm' : 'm-minWidth'}
        >
          Invite friends
        </AppButton>
      </CelloBannerInfoBox>
      <CelloBannerImageContainer
        className={classNames({ 'cello-image-container-mobile': isMobile })}
      >
        <CelloBackgroundImage
          className={classNames({ 'cello-background-image-mobile': isMobile })}
          component="img"
          alt=""
          src={bg}
        />
      </CelloBannerImageContainer>
    </CelloBannerContainer>
  );
};

export default CelloBannerNew;
