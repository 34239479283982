import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogOutIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M7.5 18.2651H4.16667C3.72464 18.2651
      3.30072 18.0895 2.98816 17.777C2.67559
      17.4644 2.5 17.0405 2.5 16.5985V4.9318C2.5
      4.48978 2.67559 4.06585 2.98816 3.75329C3.30072
      3.44073 3.72464 3.26514 4.16667 3.26514H7.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.334 14.932L17.5007 10.7653L13.334 6.59863"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 10.7651H7.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

  </SvgIcon>
);
