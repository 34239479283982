import { SwitchProps } from '@mui/material';
import classNames from 'classnames';
import StyledAppSwitch from './StyledSwitch/StyledAppSwitch';
import { CheckIconBold12px } from '../../icons/CheckIcon';
import { CancelIconBold12px } from '../../icons/CancelIcon';

export type AppSwitchSize = 'medium';

export type AppSwitchColor = 'primary' | 'secondary';

export type AppInputProps = {
  size?: AppSwitchSize
  color?: AppSwitchColor
} & Omit<SwitchProps, 'size' | 'color'>;

export const AppSwitch = ({
  disabled,
  size = 'medium',
  color = 'primary',
  className,
  ...props
}: SwitchProps & { backgroundColor?: string | ((theme: any) => string); }) => (
  <StyledAppSwitch
    {...props}
    disabled={disabled}
    className={classNames(
      `size-${size} color-${color}`,
      { 'switch-checked': props.checked },
      { 'switch-disabled': disabled },
      className,
    )}
    disableRipple
    icon={<CancelIconBold12px />}
    checkedIcon={<CheckIconBold12px />}
  />
);
