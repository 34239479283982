import { useEffect, useRef, useState } from 'react';
import {
  Box,
  DialogContent,
} from '@mui/material';
import { MediaFieldMenuState } from './lib/MediaFieldMenuState';
import { StyledMediaMenuDialog } from './styles/StyledMediaMenuDialog';
import MediaFieldMenuContainer from './MediaFieldMenuContainer';
import { AddsectionChildData, EFieldsubType, EMediaFieldStorageType } from '../../../../../shared/Field/Field';
import { LibraryFileDTO } from '../../../../../shared/library/LibraryDTO';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onResult: (childData: AddsectionChildData, files?: File[]) => void;
};

const MediaFieldMenu = ({
  isOpen,
  onClose,
  onResult,
}: Props): JSX.Element => {
  const [menuState, setMenuState] = useState(MediaFieldMenuState.UPLOAD);
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setMenuState(MediaFieldMenuState.UPLOAD);
  }, [isOpen]);

  const handleFileUpload = (files: File[]) => {
    onResult({ storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC }, files);
    handleClose();
  };

  const handleLinkAdd = (link: string) => {
    handleClose();
  };

  const handleLibraryItemAdd = (libraryItem: LibraryFileDTO) => {
    onResult({
      storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
      embedData: {
        newUrl: libraryItem.fileUid,
        newSubType: libraryItem.fileSubtype || EFieldsubType.EMPTY,
        name: libraryItem.name,
      },
    });
    handleClose();
  };

  const content = (): JSX.Element | null => {
    switch (menuState) {
      // case MediaFieldMenuState.LIBRARY:
      //   return (
      //     <MediaFieldMenuLibrary
      //       setMenuState={setMenuState}
      //       onLibraryItemAdd={handleLibraryItemAdd}
      //     />
      //   );
      default:
        return (
          <MediaFieldMenuContainer
            menuState={menuState}
            setMenuState={setMenuState}
            onFileUpload={handleFileUpload}
            onLinkAdd={handleLinkAdd}
            onLibraryItemAdd={handleLibraryItemAdd}
          />
        );
    }
  };

  return (
    <StyledMediaMenuDialog
      ref={dialogRef}
      open={isOpen}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        id: 'media-node-upload-dialog',
        sx: {
          boxShadow: 'none',
          overflow: 'visible',
        },
      }}
    >
      <Box
        component="form"
        id="media-node-upload-menu"
        autoComplete="off"
        noValidate
      >
        <DialogContent sx={{
          padding: '0px !important',
        }}
        >
          <Box
            sx={{
              alignItems: 'center',
              width: '100%',
            }}
          >
            {content()}
          </Box>
        </DialogContent>
      </Box>
    </StyledMediaMenuDialog>
  );
};

export default MediaFieldMenu;
