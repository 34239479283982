import { OrganizationAdminDTO, OrganizationDTO } from '../../../../../shared/OrganizationDTO';
import { UserRolesDTO } from '../../../../../shared/user-organization';
import { InputComponent, ResultComponent } from '../../../../types/hoc';
import { useOrganizationFetchData } from '../../helpers/useOrganizationFetch';
import { useUserOrganizationFetch } from '../../helpers/useUserOrganizationFetch';

type InputProps = {
  organizationAdmin: OrganizationAdminDTO;
};
type ResultProps = {
  organization: OrganizationDTO;
  organizationAdmin: OrganizationAdminDTO;
  users: UserRolesDTO[];
};

// eslint-disable-next-line react/display-name, max-len
export const withOrganizationDetails = <P extends InputProps, R extends ResultProps>(Component: InputComponent<R>): ResultComponent<P> => (props: InputProps) => {
  const { organizationAdmin } = props;
  const { data: organization } = useOrganizationFetchData(organizationAdmin?.id);
  const { data: users } = useUserOrganizationFetch(organizationAdmin?.id);

  if (!organization) return null;
  // @ts-ignore
  return <Component organization={organization} users={users} {...props} />;
};
