import { SxProps, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TemplateVarDropdown } from '../TemplateVarDropdown/TemplateVarDropdown';
import { useAppSelector } from '../../hooks/stateHooks';
import { useTemplateVars } from '../../hooks/useTemplateVars';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { selectCurrentStage } from '../ProcessFields/lib/processItemsSlice';
import { useItemUpdate } from '../Layout/ItemContextProvider';

const StagePresentationTitle = (): JSX.Element => {
  const isLayoutEditActive = useAppSelector(selectLayouEditingStatus);
  const currentStage = useAppSelector(selectCurrentStage);
  const { onUpdate } = useItemUpdate(currentStage?.id);

  const [textFieldValue, setTextFieldValue] = useState(currentStage?.title ?? '');

  const inputRef = useRef<HTMLInputElement>();

  const {
    anchorTemplatePos, setAnchorTemplatePos, onInsertTemplateVar, lastPos,
  } = useTemplateVars({ input: inputRef, textFieldValue, setTextFieldValue });

  useEffect(() => {
    if (currentStage?.title) {
      setTextFieldValue(currentStage?.title);
    }
  }, [currentStage?.title]);

  useEffect(() => {
    if (!anchorTemplatePos && lastPos) {
      setTimeout(() => {
        inputRef.current?.setSelectionRange(lastPos, lastPos);
        inputRef.current?.focus();
      });
    }
  }, [anchorTemplatePos, lastPos]);

  const titleStyles: SxProps = {
    color: '#1A1929',
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: 600,
    wordBreak: 'break-word',
    overflow: isMobile ? 'auto !important' : undefined,
    whiteSpace: 'break-spaces',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };

  const onStageTitleUpdate = (newTitle: string) => {
    if (newTitle !== currentStage?.title) {
      onUpdate({
        title: newTitle,
      });
    }
  };

  if (!isLayoutEditActive) {
    return (
      <Typography
        sx={titleStyles}
      >
        {currentStage?.title}
      </Typography>
    );
  }

  return (
    <>
      <TextField
        fullWidth
        multiline
        variant="standard"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        InputProps={{
          disableUnderline: true,
          sx: {
            padding: '0px',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          ref: inputRef,
          onBlur: (e) => onStageTitleUpdate(e.target.value),
          onKeyDown: (e) => {
            if (e.nativeEvent.key === 'Enter') {
              inputRef.current!.blur();
            }
          },
          sx: titleStyles,
        }}
      />
      <TemplateVarDropdown
        onInsert={onInsertTemplateVar}
        open={!!anchorTemplatePos}
        onClose={() => {
          setAnchorTemplatePos(null);
          setTimeout(() => {
            if (lastPos) inputRef.current?.setSelectionRange(lastPos, lastPos);
            inputRef.current?.focus();
          });
        }}
        anchorReference="anchorPosition"
        anchorPosition={anchorTemplatePos || undefined}
      />
    </>
  );
};

export default StagePresentationTitle;
