import {
  ToggleButton, ToggleButtonGroup, Typography, styled,
} from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const PTTabHeader = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500,
  color: stagesetColors.grey[600],
}));

export const PTTabToggleButton = styled(ToggleButton)(() => ({
  padding: '4px 10px',
  minHeight: '36px',
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: 600,
  borderRadius: '8px',
  '&.Mui-selected': {
    color: stagesetColors.newGrey[800],
    backgroundColor: stagesetColors.newGrey[200],
  },
}));

export const PTToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  padding: '2px',
  marginLeft: '8px',
  borderRadius: '10px',
}));
