import { Box, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMemo } from 'react';
import { ESubscriptionType } from '../../../../shared/Subscription';
import { useAppSelector } from '../../../hooks/stateHooks';
import useUserOrganizationPermissions from '../../../hooks/useOrganizationPermissions';
import { EUserOrganizationPermissions } from '../../../../shared/permissions';
import PagePresentation from '../../../common/PagePresentation/PagePresentation';
import PagePresentationNoPermissions from './tabItems/SubscriptionTab/NoPermissionPlaceholder';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsTabCardsWrapper,
  SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';
import {
  IntegrationDescription,
  IntegrationHeaderContainer,
  IntegrationHeaderTitle,
  IntegrationButtonsWrapper,
  IntegrationTabCard,
  IntegrationTitleWrapper,
  IntegrationButtonsContainer,
  IntegrationsTabGridWrapper,
  IntegrationsTabGrid,
} from './Styles/IntegrationsTabStyles';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { useIntegrationsSettings } from '../../../hooks/useIntegrationsSettings';
import LightTooltip from '../../../shared/LightToolTip/LightToolTip';

const premiumSubscriptionTypes = [
  ESubscriptionType.SELLER_PREMIUM,
  ESubscriptionType.SELLER_APPSUMO_TIER_2,
  ESubscriptionType.SELLER_APPSUMO_TIER_3,
];

const TeammanagerIntegrationsTab = (): JSX.Element => {
  const { t } = useTranslation();
  const subscriptionType: ESubscriptionType = useAppSelector((state) => state.billing.subscription.type);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const isPageAvailable: boolean = premiumSubscriptionTypes.includes(subscriptionType);
  const isBillingAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);
  const { integrationsArray } = useIntegrationsSettings();

  const integrationsCards = useMemo(() => (
    integrationsArray.map((item) => (
      <Box
        key={item.integrationName}
      >
        <IntegrationTabCard>
          <IntegrationHeaderContainer>
            <IntegrationTitleWrapper>
              <Box>
                {item.integrationLogo}
              </Box>
              <IntegrationHeaderTitle>
                {item.integrationName}
              </IntegrationHeaderTitle>
            </IntegrationTitleWrapper>
          </IntegrationHeaderContainer>
          <IntegrationDescription>
            {t('Integrations_Pipedrive_text')}
          </IntegrationDescription>
          <IntegrationButtonsContainer>
            {item.isUserHaveIntegration && (
              <LightTooltip title={item.integration?.crmEmail} placement="bottom-start">
                <IntegrationButtonsWrapper>
                  <AppButton
                    variant="secondary-success"
                    sx={{
                      minWidth: 'auto !important',
                      pointerEvents: 'none',
                    }}
                  >
                    {t('Integrations_Connected')}
                  </AppButton>
                  <AppButton
                    onClick={item.onIntegrationCancel}
                    variant="danger-transparent"
                    sx={{
                      minWidth: 'auto !important',
                    }}
                  >
                    {t('Integrations_Remove_Connection')}
                  </AppButton>
                </IntegrationButtonsWrapper>
              </LightTooltip>
            )}
            {!item.isUserHaveIntegration && (
              <Box>
                <AppButton
                  onClick={item.onIntegrationSet}
                  variant="secondary"
                  disabled={item.isUserHaveAnyCrmIntegration}
                  sx={{
                    minWidth: 'auto !important',
                  }}
                >
                  {t('Integrations_Connect')}
                </AppButton>
              </Box>
            )}
          </IntegrationButtonsContainer>
        </IntegrationTabCard>
      </Box>
    ))
  ), [integrationsArray]);

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Integrations_Integrations')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Setup your CRM integration
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      {isPageAvailable
        && (
        <SettingsTabCardsWrapper>
          <IntegrationsTabGridWrapper>
            <IntegrationsTabGrid
              className={classNames({ 'integrations-tab-grid-container-mobile': isMobile })}
            >
              {integrationsCards}
            </IntegrationsTabGrid>
          </IntegrationsTabGridWrapper>
        </SettingsTabCardsWrapper>
        )}
      {
        !isPageAvailable
        && isBillingAvailable
        && (
          <PagePresentation
            title="Integrations"
            // eslint-disable-next-line max-len
            description="With Integrations, updating your CRM is done automatically for you. Unlock the company plan to connect your Salesforce account to Stageset."
          />
        )
      }
      {
        !isPageAvailable
        && !isBillingAvailable
        && (
          <PagePresentationNoPermissions
            title="No Access"
            description="You do not have access to this page"
          />
        )
      }
    </SettingsTabContentWrapper>
  );
};

export default TeammanagerIntegrationsTab;
