import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import LibraryLayoutWrapper from './LibraryLayoutWrapper';
import { EFileEditType, ELibraryType } from '../../../shared/library/Library';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  selectLibraryGridToRender,
  selectIsFileItemDialogOpen,
  changeFileItemDialogStatus,
  selectIsFileLinkDialogOpen,
  changeFileLinkDialogStatus,
  setLibraryFeedTab,
  setLibraryFeedTitle,
} from './lib/librarySlice';
import { StyledLibraryPaper } from './styles/LibraryStyle';
import LibraryHeader from './LibraryHeader';
import LibraryFileGrid from './LibraryFileGrid';
import LibraryProductGrid from './LibraryProductGrid';
import LibraryItemDialog from './LibraryItemDialog';
import { FileWithOwnerDTO, ProductWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import LibraryLinkItemDialog from './LibraryLinkItemDialog';
import LibraryFeed from './LibraryFeed/LibraryFeed';
import { ELibraryFeedTab } from './helpers/library.helper';
import { MobileBottomDrawer } from '../../features/MobileSwipeableDrawer/MobileBottomDrawer';

const LibraryPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const isFileItemDialogOpen = useAppSelector(selectIsFileItemDialogOpen);
  const isFileLinkDialogOpen = useAppSelector(selectIsFileLinkDialogOpen);

  const onCreateFileItemOpen = (item?: FileWithOwnerDTO, editType: EFileEditType = EFileEditType.None) => {
    dispatch(changeFileItemDialogStatus({
      open: true,
      item,
      editType,
    }));
  };

  const onCreateFileLinkOpen = (item?: FileWithOwnerDTO) => {
    dispatch(changeFileLinkDialogStatus({
      open: true,
      item,
    }));
  };

  const onCreateProductItemOpen = (item?: ProductWithOwnerDTO) => {
    dispatch(setLibraryFeedTitle('Edit product'));
    dispatch(setLibraryFeedTab({
      open: true,
      type: ELibraryFeedTab.PRODUCT,
      item,
    }));
  };

  const renderGrid = () => {
    switch (gridToRender) {
      case ELibraryType.FILE:
        return (
          <LibraryFileGrid
            onCreateFileItemDialogOpen={onCreateFileItemOpen}
            onCreateFileLinkDialogOpen={onCreateFileLinkOpen}
          />
        );

      case ELibraryType.PRODUCT:
        return (
          <LibraryProductGrid
            onCreateProductItemDialogOpen={onCreateProductItemOpen}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <LibraryLayoutWrapper
        className={isMobile ? 'mobile' : ''}
      >
        <Box
          className={isMobile ? 'mobile' : ''}
          sx={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <LibraryHeader />
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              maxHeight: isMobile ? 'calc( 100% - 136px )' : 'calc( 100% - 160px )',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <StyledLibraryPaper
              className={isMobile ? 'mobile' : ''}
              sx={{
                backgroundColor: 'transparent',
                width: '100%',
                height: 'calc( 100% )',
                // overflow: 'overlay',
                padding: !isMobile ? '0 24px 0 24px' : '',
              }}
            >
              {renderGrid()}
            </StyledLibraryPaper>
          </Box>
        </Box>
        <MobileBottomDrawer />
      </LibraryLayoutWrapper>
      <LibraryItemDialog
        isOpen={isFileItemDialogOpen}
        onClose={() => dispatch(changeFileItemDialogStatus({ open: false, item: undefined }))}
      />
      <LibraryLinkItemDialog
        isOpen={isFileLinkDialogOpen}
        onClose={() => dispatch(changeFileLinkDialogStatus({ open: false, item: undefined }))}
      />
      <LibraryFeed />
    </>
  );
};

export default LibraryPage;
