import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { organizationGetSubscription } from '../core/store/appState/appState';
import { useAppDispatch } from '../hooks/stateHooks';

interface Props {
  isPremiumDialogOpen: boolean;
  onDialogClose: () => void;
}

const rows: string[] = [
  'Activity tracking',
  'Unlimited Internal Collaborators',
  'Management Overview',
  'Template analytics',
  'Salesperson analytics',
];

const PremiumDialog = ({ isPremiumDialogOpen, onDialogClose }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const onConfirm = () => {
    dispatch(organizationGetSubscription());
    onDialogClose();
  };
  return (
    <Dialog open={isPremiumDialogOpen} onClose={() => onDialogClose()}>
      <DialogContent sx={{
        height: '340px',
        width: '450px',
      }}
      >
        <DialogTitle>
          Try Premium
        </DialogTitle>
        {
          rows.map((row: string, i: number) => (
            <Grid
              key={i}
              container
              sx={{
                backgroundColor: i % 2 === 0 ? '#F3F8FA' : 'none',
                height: '48px',
                px: '8px',
                justifyContent: 'center',
              }}
            >
              <Grid
                xs={8}
                item
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1">
                  {row}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                xs={2}
                item
              >
                <Remove color="error" />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                xs={2}
                item
              >
                <Add color="success" />
              </Grid>
            </Grid>
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDialogClose()}>Cancel</Button>
        <Button color="success" onClick={() => onConfirm()} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PremiumDialog;
