export class RoleDTO {
  name: string;
  id: string;
  roleType: ERoleTypes;
}

export enum ERoleTypes {
  ORGANIZATION = 'ORGANIZATION',
  DEAL = 'DEAL',
}

export enum EUserOrganizationPermissions {
  ORGANIZATION_NAV_DEALS = 'ORGANIZATION_NAV_DEALS',
  ORGANIZATION_NAV_TEAM = 'ORGANIZATION_NAV_TEAM',
  ORGANIZATION_NAV_FILES = 'ORGANIZATION_NAV_FILES',
  ORGANIZATION_NAV_BILLING = 'ORGANIZATION_NAV_BILLING',
  ORGANIZATION_NAV_INTEGRATION = 'ORGANIZATION_NAV_INTEGRATION',
  ORGANIZATION_TEAM_REMOVE = 'ORGANIZATION_TEAM_REMOVE',
  ORGANIZATION_TEAM_MANAGE = 'ORGANIZATION_TEAM_MANAGE',
  ORGANIZATION_TEAM_INVITE = 'ORGANIZATION_TEAM_INVITE',
  ORGANIZATION_DEALS_EDIT_ALL = 'ORGANIZATION_DEALS_EDIT_ALL',
  ORGANIZATION_DEALS_VIEW_ALL = 'ORGANIZATION_DEALS_VIEW_ALL',
  ORGANIZATION_DEALS_OWNED = 'ORGANIZATION_DEALS_OWNED',
  ORGANIZATION_DEALS_PARTICIPANT = 'ORGANIZATION_DEALS_PARTICIPANT',
  ORGANIZATION_DEALS_MANAGE = 'ORGANIZATION_DEALS_MANAGE',
  ORGANIZATION_NOTHING = 'ORGANIZATION_NOTHING',
  ORGANIZATION_TEMPLATE_VIEW = 'ORGANIZATION_TEMPLATE_VIEW',
  ORGANIZATION_TEMPLATE_CREATE = 'ORGANIZATION_TEMPLATE_CREATE',
  ORGANIZATION_TEMPLATE_DELETE = 'ORGANIZATION_TEMPLATE_DELETE',
  ORGANIZATION_TEMPLATE_EDIT = 'ORGANIZATION_TEMPLATE_EDIT',
}

export enum EUserDealPermissions {
  DEAL_VIEW = 'DEAL_VIEW',
  DEAL_ARCHIVE = 'DEAL_ARCHIVE',
  DEAL_SUMMARY_EDIT = 'DEAL_SUMMARY_EDIT',
  DEAL_SHARE = 'DEAL_SHARE',
  DEAL_LOGO_EDIT = 'DEAL_LOGO_EDIT',
  DEAL_USERS_INVITE = 'DEAL_USERS_INVITE',
  DEAL_USERS_DELETE = 'DEAL_USERS_DELETE',
  DEAL_MILESTONE_EDIT = 'DEAL_MILESTONE_EDIT',
  DEAL_MILESTONE_CREATE = 'DEAL_MILESTONE_CREATE',
  DEAL_MILESTONE_DELETE = 'DEAL_MILESTONE_DELETE',
  DEAL_MILESTONE_ASSIGN = 'DEAL_MILESTONE_ASSIGN',
  DEAL_MILESTONE_REORDER = 'DEAL_MILESTONE_REORDER',
  DEAL_MILESTONE_STATUS = 'DEAL_MILESTONE_STATUS',
  DEAL_FILES_SEE = 'DEAL_FILES_SEE',
  DEAL_FILES_UPLOAD = 'DEAL_FILES_UPLOAD',
  DEAL_FILES_DOWNLOAD = 'DEAL_FILES_DOWNLOAD',
  DEAL_FILES_DELETE = 'DEAL_FILES_DELETE',
  DEAL_FILES_DELETE_OWN = 'DEAL_FILES_DELETE_OWN',
  DEAL_FILES_TEAM_STORAGE = 'DEAL_FILES_TEAM_STORAGE',
  DEAL_ACTIONS_EDIT = 'DEAL_ACTIONS_EDIT',
  DEAL_ACTIONS_CREATE = 'DEAL_ACTIONS_CREATE',
  DEAL_ACTIONS_DELETE = 'DEAL_ACTIONS_DELETE',
  DEAL_ACTIONS_ASSIGN = 'DEAL_ACTIONS_ASSIGN',
  DEAL_ACTIONS_REORDER = 'DEAL_ACTIONS_REORDER',
  DEAL_ACTIONS_DATE = 'DEAL_ACTIONS_DATE',
  DEAL_ACTIONS_STATUS = 'DEAL_ACTIONS_STATUS',
  DEAL_FEED_STALKER = 'DEAL_FEED_STALKER',
  DEAL_CHAT_SEE = 'DEAL_CHAT_SEE',
  DEAL_CHAT_USE = 'DEAL_CHAT_USE',
  DEAL_SHARED_VIEW = 'DEAL_SHARED_VIEW',
  DEAL_SHARED_FILES_SEE = 'DEAL_SHARED_FILES_SEE',
  DEAL_LAYOUT_EDIT = 'DEAL_LAYOUT_EDIT',
  DEAL_TEXT_FIELD_EDIT = 'DEAL_TEXT_FIELD_EDIT',
  DEAL_MEDIA_FIELD_EDIT = 'DEAL_MEDIA_FIELD_EDIT',
  DEAL_SHARED_CHAT_SEE = 'DEAL_SHARED_CHAT_SEE',
}

export enum ProcessAccessSettings {
  DEAL_ENTER = 'DEAL_ENTER',
}

export enum EOrganizationRoles {
  OWNER = 'OWNER',
  EMPLOYEE = 'EMPLOYEE',
  SELLER = 'SELLER',
  ADMIN = 'ADMIN',
}

export enum EDealRoles {
  DEAL_OWNER = 'DEAL_OWNER',
  DEAL_SELLER = 'DEAL_SELLER',
  DEAL_BUYER_VIEWER = 'DEAL_BUYER_VIEWER',
  DEAL_BUYER_EDITOR = 'DEAL_BUYER_EDITOR',
  DEAL_ACCESS_LINK_EXTENDED = 'DEAL_ACCESS_LINK_EXTENDED',
  DEAL_ACCESS_LINK_UNPROTECTED = 'DEAL_ACCESS_LINK_UNPROTECTED',
}

export type TPermissions<T extends string | number | symbol> = {
  [permission in T]?: boolean;
}

export type TAllPermissionsNames = EUserDealPermissions
  | EUserOrganizationPermissions;

export type TAllRoleNames = EDealRoles
  | EOrganizationRoles;

export interface IUserPermissions {
  [ERoleTypes.ORGANIZATION]: TPermissions<EUserOrganizationPermissions>,
  [ERoleTypes.DEAL]: TPermissions<EUserDealPermissions>,
}

export type TAllPermissions = TPermissions<TAllPermissionsNames>;

export const organizationTemplatePermissions: EUserOrganizationPermissions[] = [
  EUserOrganizationPermissions.ORGANIZATION_TEMPLATE_VIEW,
  EUserOrganizationPermissions.ORGANIZATION_TEMPLATE_EDIT,
  EUserOrganizationPermissions.ORGANIZATION_TEMPLATE_CREATE,
  EUserOrganizationPermissions.ORGANIZATION_TEMPLATE_DELETE
]

export const buyerRoles: EDealRoles[] = [
  EDealRoles.DEAL_BUYER_EDITOR,
  EDealRoles.DEAL_BUYER_VIEWER
];

export const sellerRoles: EDealRoles[] = [
  EDealRoles.DEAL_SELLER,
  EDealRoles.DEAL_OWNER,
]
