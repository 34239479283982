/* eslint-disable react/no-unused-prop-types */
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { ESubscriptionType } from '../../../../../shared/Subscription';
import {
  ButtonContainer,
  CardBox, CardTitle, FeatureBox, FeatureContainer, FeatureTitle, TitleContainer,
} from '../Styles/SubscriptionTabGridCardStyles';
import { CheckIcon21px } from '../../../../icons/CheckIcon';
import { SubscriptionTabButton } from './SubscriptionTab/SubscriptionTabButton';
import { CircledCheckIcon24 } from '../../../../icons/CircledCheckIcon';

interface Props {
  title: string,
  description: string | JSX.Element,
  features?: string[],
  type?: ESubscriptionType,
  btnTitle?: string,
  btnIcon?: JSX.Element,
  currentBtnTitle?: string,
  current?: boolean,
  currentBtnIcon?: JSX.Element,
  priceId?: string,
}

export const SubscriptionTabGridCard = (props: Props): JSX.Element => {
  const {
    title,
    description,
    features,
    type,
    btnTitle,
    current,
    currentBtnTitle,
    currentBtnIcon,
    btnIcon,
    priceId,
  } = {
    btnTitle: 'Get started',
    currentBtnTitle: 'Current plan',
    currentBtnIcon: <CircledCheckIcon24 />,
    ...props,
  };

  return (
    <CardBox
      className={classNames(
        { 'card-box-premium': type === ESubscriptionType.SELLER_PREMIUM },
        { 'card-box-mobile': isMobile },
      )}
    >
      <TitleContainer
        className={classNames({ 'title-container-premium': type === ESubscriptionType.SELLER_PREMIUM })}
      >
        <CardTitle
          className={classNames({ 'card-title-premium': type === ESubscriptionType.SELLER_PREMIUM })}
        >
          {title}
        </CardTitle>
        {description}
      </TitleContainer>
      <FeatureContainer
        className={classNames({ 'feature-container-premium': type === ESubscriptionType.SELLER_PREMIUM })}
      >
        {
          features?.map((feature, i) => (
            <FeatureBox
              key={`${title}-subscription-feature-${i}`}
            >
              <CheckIcon21px />
              <FeatureTitle
                className={classNames({ 'feature-title-premium': type === ESubscriptionType.SELLER_PREMIUM })}
              >
                {feature}
              </FeatureTitle>
            </FeatureBox>
          ))
        }
      </FeatureContainer>
      <ButtonContainer>
        <SubscriptionTabButton
          current={current}
          currentIcon={currentBtnIcon}
          currentTitle={currentBtnTitle}
          icon={btnIcon}
          title={btnTitle}
          priceId={priceId}
          type={type}
        />
      </ButtonContainer>
    </CardBox>
  );
};
