import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { stagesetPalette } from '../../theme/stagesetPalette';

const LibraryLayoutWrapper = styled(Grid)(() => ({
  alignContent: 'center',
  maxHeight: '100vh',
  height: '100vh',
  overflow: 'hidden',
  background: stagesetPalette.background?.default,
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
    backgroundColor: '#c7c7c7',
    opacity: '50%',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#7E7E7E',
  },
}));

export default LibraryLayoutWrapper;
