import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { ESidebarTabs, setSidebarTab } from '../Layout/lib/fieldsLayoutSlice';
import SidebarStagesTab from './SidebarStagesTab';
import SidebarFiltersTab from './SidebarFiltersTab';
import SidebarsTabsWrapper from './SidebarTabsWrapper';
import {
  selectIsOwnersTeammate,
  selectIsProcessOwner,
  selectIsTemplate,
} from '../../routes-old/process/state/processSlice';
import { AppSidebar } from './Styles/SidebarStyles';
import SidebarInternalTab from './SidebarInternalTab';
import NewPageMainTab from '../NewPageAdding/NewPageMainTab';
import NewPageMediaTab from '../NewPageAdding/NewPageMediaTab';
import NewPageEmbedTab from '../NewPageAdding/NewPageEmbedTab';
import NewPageCanvasTab from '../NewPageAdding/NewPageCanvasTab';

const StagesSidebar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isOwner = useAppSelector(selectIsProcessOwner);
  const isTeammate = useAppSelector(selectIsOwnersTeammate);
  const isTemplate = useAppSelector(selectIsTemplate);

  useEffect(() => () => {
    dispatch(setSidebarTab(ESidebarTabs.STAGES));
  }, []);

  return (
    <AppSidebar
      className={classNames('sidebar', { 'sidebar-mobile': isMobile })}
    >
      <SidebarsTabsWrapper>
        <SidebarStagesTab />
        {
          (
            (isOwner || isTeammate)
            || isTemplate
          )
          && (
            <>
              <NewPageMainTab />
              <NewPageMediaTab />
              <NewPageEmbedTab />
              <NewPageCanvasTab />
            </>
          )
        }
        {
          (isOwner || isTeammate)
            && !isTemplate
            && (
              <>
                <SidebarInternalTab />
                <SidebarFiltersTab />
              </>
            )
          }
      </SidebarsTabsWrapper>
    </AppSidebar>
  );
};

export default StagesSidebar;
