import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EStateStatus, getInitialBaseState, IStateBase } from '../commonTypes';
import { CrmApi } from '../../api/CrmApi';

const crmApi: CrmApi = new CrmApi();

export interface SalesforceIntegrationState extends IStateBase {
  integration: any | null;
  isUserHaveIntegration: boolean;
}

export enum ESalesForceIntegrationActions {
  GET_INTEGRATION = 'GET_INTEGRATION',
  CANCEL_INTEGRATION = 'CANCEL_INTEGRATION',
}

export const salesForceIntegrationReducerName = 'salesForceIntegration';

const initialState: SalesforceIntegrationState = {
  ...getInitialBaseState(),
  integration: null,
  isUserHaveIntegration: false,
};

export const salesforceGetIntegration = createAsyncThunk(
  `${salesForceIntegrationReducerName}/${ESalesForceIntegrationActions.GET_INTEGRATION}`,
  async () => crmApi.getUserIntegration('salesforce'),
);

export const salesforceCancelIntegration = createAsyncThunk(
  `${salesForceIntegrationReducerName}/${ESalesForceIntegrationActions.CANCEL_INTEGRATION}`,
  async () => crmApi.cancelUserIntegration(),
);

export const salesforce = createSlice({
  name: salesForceIntegrationReducerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(salesforceGetIntegration.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(salesforceGetIntegration.fulfilled, (state, { payload }) => {
      state.integration = payload;
      state.isUserHaveIntegration = true;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(salesforceGetIntegration.rejected, (state) => {
      state.status = EStateStatus.IDLE;
      state.isUserHaveIntegration = false;
    });
    builder.addCase(salesforceCancelIntegration.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(salesforceCancelIntegration.fulfilled, (state) => {
      state.integration = null;
      state.isUserHaveIntegration = false;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(salesforceCancelIntegration.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
  },
});
