import { SvgIcon, SvgIconProps } from '@mui/material';

export const Rocket20px = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_25644_21760)">
      <path
        d="M6.03266 12.9583L6.08362 12.908L6.12484
      12.8495C6.1271 12.8462 6.12959 12.8432 6.13228
      12.8403C6.32323 12.7246 6.51962 12.5467 6.63082
      12.2686C6.72789 12.0259 6.72409 11.7894 6.70757
      11.6224C6.80148 8.0025 7.84912 4.47128 9.74503
      1.38574L9.75308 1.37265L9.76062 1.35925C9.78245
      1.32044 9.81433 1.28833 9.85288 1.26626C9.89149
      1.24414 9.93535 1.23289 9.97982 1.23369C10.0243
      1.23449 10.0678 1.24732 10.1057 1.27084C10.1434
      1.2943 10.1742 1.32759 10.1947 1.36724L10.2106
      1.39803L10.2291 1.42733C11.234 3.01766 12.0002
      4.74672 12.5033 6.55932L12.5033 6.55934L12.5063
      6.5696C13.0143 8.30932 13.2884 10.1088 13.3216
      11.9208C13.3234 12.211 13.4383 12.4895 13.6425
      12.6965L13.683 12.7375L13.7289 12.7722C14.058
      13.0217 14.3252 13.3435 14.5099 13.7128C14.6943
       14.0815 14.7915 14.4877 14.7939 14.8999C14.7939
       14.9006 14.7939 14.9012 14.7939 14.9019L14.7941
       18.2318C14.3518 17.6271 13.8959 17.0055 13.4171
       16.4156C13.108 16.0167 12.707 15.6986 12.2483
       15.4884C11.7934 15.2798 11.2952 15.1836
       10.7956 15.2076L9.1747 15.2075C9.17414
       15.2075 9.17358 15.2075 9.17302 15.2075C8.61801
       15.2055 8.07102 15.3398 7.58 15.5984C7.0945
       15.8541 6.67837 16.2236 6.36716 16.6753L5.15123
       18.2907L5.14435 14.9904C5.16908 14.2243 5.48698
       13.4968 6.03266 12.9583ZM14.813 19.2313C14.8129
       19.2307 14.8127 19.2301 14.8126 19.2294L14.813 19.2313Z"
        stroke="#0073F5"
        strokeWidth="1.6"
        fill="none"
      />
      <circle
        cx="9.99759"
        cy="8.64798"
        r="0.8"
        stroke="#0073F5"
        strokeWidth="1.3405"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_25644_21760">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
