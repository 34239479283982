import { Box, Fade, Typography } from '@mui/material';
import BackToLogin from '../../../shared/AuthBtns/BackToLogin';
import { TitleTypographyWrapper, TitleTypography } from '../Components/AuthStyledComponents';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';

const ResetPasswordSuccess = (): JSX.Element => (
  <AuthPaper>
    <Fade in exit timeout={500}>
      <Box
        sx={{
          display: 'flex',
          gap: '36px',
          flexDirection: 'column',
        }}
      >
        <TitleTypographyWrapper>
          <TitleTypography>
            Check your email
          </TitleTypography>
          <Typography
            sx={{
              color: ' #7084B4',
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            An email with a recovery link was just sent to your email
          </Typography>
        </TitleTypographyWrapper>
        <BackToLogin />
      </Box>
    </Fade>
  </AuthPaper>
);
export default ResetPasswordSuccess;
