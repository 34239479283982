import { Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { EAppRoutes } from '../../core/router';
import ChevronLeft from '../../icons/ChevronLeft.svg';
import { FormattedLabel } from '../AssigneeSelection/FormattedLabel';
import { AppIconButton } from '../AppButton/AppIconButton';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import { useAppSelector } from '../../hooks/stateHooks';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { AppButton } from '../AppButton/AppButton';
import { stagesetColors } from '../../theme/stagesetPalette';
import RoomToolbarTemplateHeader from '../../features/Stages/RoomToolbarTemplateHeader';

interface Props {
  roomGeneralMobileMenu?: boolean
  templateMenu?: boolean
  templateToolbar?: boolean
}

const BackToHomeButton = ({
  roomGeneralMobileMenu,
  templateMenu,
  templateToolbar,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const summaryTitle: string | null = templateMenu
    || templateToolbar ? null : useAppSelector((state) => state.process.process.summaryTitle);
  const { closeDrawer } = useMobileBottomDrawer();
  const isDeal: boolean = useAppSelector((state) => state.process?.process?.type) === EProcessType.DEAL;
  const isTemplate: boolean = useAppSelector((state) => state.process?.process?.type) === EProcessType.TEMPLATE;
  const templateTitle = useAppSelector((state) => state.process?.process?.title);

  const currentRoute = () => {
    switch (isDeal) {
      case true:
        return EAppRoutes.home;
      case false:
        return EAppRoutes.templates;
      default:
        return EAppRoutes.home;
    }
  };

  const backToHomeButtonTitle = () => {
    if (isTemplate && templateTitle) return templateTitle;
    if (isDeal && summaryTitle) return summaryTitle;
    return '';
  };

  if (templateMenu) {
    return (
      <AppIconButton
        disableRipple
        fullWidth
        icon={<ChevronLeft />}
        onClick={() => navigate(currentRoute())}
      />
    );
  }

  if (roomGeneralMobileMenu) {
    return (
      <AppButton
        fullWidth
        disableRipple
        variant="transparent-grey-icon"
        size="l-flex-start"
        startIcon={<ChevronLeft />}
        onClick={() => {
          navigate(currentRoute());
          closeDrawer();
        }}
      >
        {isDeal ? t('Room_Overview') : t('Room_Templates')}
      </AppButton>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: isMobile ? '100%' : 'calc(50% - 165px)',
        minWidth: isMobile ? '120px' : '250px',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <Link
        href={currentRoute()}
        onMouseDown={(e) => {
          if (e.button === 0 && !e.ctrlKey && !e.metaKey) {
            e.preventDefault();
            navigate(currentRoute());
          }
        }}
        sx={{
          textDecoration: 'none',
        }}
      >
        <AppIconButton
          icon={<ChevronLeft />}
          disableRipple
          size="l"
          variant="transparent-white"
        />
      </Link>
      {templateToolbar && <RoomToolbarTemplateHeader />}
      {!templateToolbar && (
      <FormattedLabel
        label={backToHomeButtonTitle()}
        len={24}
        sx={{
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '22px',
          color: stagesetColors.white[100],
        }}
      />
      )}
    </Box>
  );
};

export default BackToHomeButton;
