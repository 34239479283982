import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import useProcessBackground from '../../hooks/useProcessBackground';

const SolidColorBackground = () => {
  const {
    backgroundColor,
  } = useProcessBackground();

  return (
    <Box
      sx={{
        minHeight: isMobile ? '120px' : '250px',
        width: '100%',
        objectFit: 'fill',
        maxHeight: isMobile ? '120px' : '250px',
        background: backgroundColor ?? '#FFF',
      }}
    />
  );
};

export default SolidColorBackground;
