import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EAppRoutes } from '../../../core/router';
import { useQuery } from '../../../hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { getProcessAccessLinkByHash } from '../../process/state/dealActions';
import { selectAccessLink } from '../../process/state/processSlice';

const DealGuestRedirect = (): null => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id, hash } = useParams();
  const query = useQuery();

  const accessLink = useAppSelector(selectAccessLink);

  useEffect(() => {
    if (hash) {
      if (hash) dispatch(getProcessAccessLinkByHash(hash));
    }
    if (id) {
      navigate(`${EAppRoutes.deal}/${id}`);
    }
    if (query.has('processId')) {
      navigate(`${EAppRoutes.deal}/${query.get('processId')}`);
    }
  }, [id, query]);

  useEffect(() => {
    if (accessLink) {
      navigate(`${EAppRoutes.deal}/${accessLink.processId}`);
    }
  }, [accessLink]);

  return null;
};

export default DealGuestRedirect;
