import { SvgIcon, SvgIconProps } from '@mui/material';

export const MediaFieldUploadIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M18 12.6992V16.0326C18 16.4746 17.8244
    16.8985 17.5118 17.2111C17.1993 17.5236 16.7754
    17.6992 16.3333 17.6992H4.66667C4.22464 17.6992
    3.80072 17.5236 3.48816 17.2111C3.17559 16.8985
    3 16.4746 3 16.0326V12.6992"
      stroke="#1A1929"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.6668 6.86589L10.5002 2.69922L6.3335 6.86589"
      stroke="#1A1929"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.5 2.69922V12.6992"
      stroke="#1A1929"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
