import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const AssigneesListWrappers = styled(Box)(() => ({
  flex: 1,
  flexGrow: 1,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
    position: 'relative',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.2)',
    borderRadius: '6px',
  },
}));

export default AssigneesListWrappers;
