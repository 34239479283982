import { ProcessStageDTO } from '../Field/Field';
import { DealNotificationPayload } from '../notifications/Notifications';

export enum EEventTypes {
  DEAL = 'DEAL',
}

export enum EDealEventName {
  DEAL_USER_ADDED = 'DEAL_USER_ADDED',
  DEAL_USER_DELETED = 'DEAL_USER_DELETED',
  DEAL_USER_ONLINE = 'DEAL_USER_ONLINE',
  DEAL_USER_OFFLINE = 'DEAL_USER_OFFLINE',
  DEAL_FILE_ADDED = 'DEAL_FILE_ADDED',
  // DOCUMENT ADDED FOR SHARE FILE FIELD
  DEAL_DOCUMENT_ADDED = '  DEAL_DOCUMENT_ADDED',
  DEAL_FILE_DELETED = 'DEAL_FILE_DELETED',
  DEAL_MESSAGE_ADDED = 'DEAL_MESSAGE_ADDED',
  DEAL_TASK_TITLE_UPDATED = 'DEAL_TASK_TITLE_UPDATED',
  DEAL_TASK_STATUS_UPDATED = 'DEAL_TASK_STATUS_UPDATED',
  DEAL_TASK_ASSIGNEES_UPDATED = 'DEAL_TASK_ASSIGNEES_UPDATED',
  DEAL_TASK_CREATED = 'DEAL_TASK_CREATED',
  DEAL_TASK_DELETED = 'DEAL_TASK_DELETED',
  DEAL_TASK_EVENT_LINK_CLICKED = 'DEAL_TASK_EVENT_LINK_CLICKED',
  DEAL_SUBTASK_ADDED = 'DEAL_SUBTASK_ADDED',
  DEAL_SUBTASK_DELETED = 'DEAL_SUBTASK_DELETED',
  DEAL_TASK_DOCUMENT_ADDED = 'DEAL_TASK_DOCUMENT_ADDED',
  DEAL_TASK_TIMELINE_UPDATED = 'DEAL_TASK_TIMELINE_UPDATED',
  DEAL_TASK_NESTED_ORDER_UPDATED = 'DEAL_TASK_NESTED_ORDER_UPDATED',
  DEAL_TASK_DESCRIPTION_UPDATED = 'DEAL_TASK_DESCRIPTION_UPDATED',
  DEAL_TASK_EVENT_UPDATED = 'DEAL_TASK_EVENT_UPDATED',
  DEAL_TASK_DOCUMENT_DOWNLOADED = 'DEAL_TASK_DOCUMENT_DOWNLOADED',
  DEAL_TASK_DOCUMENT_DELETED = 'DEAL_TASK_DOCUMENT_DELETED',
  //Task description link clicked
  DEAL_TASK_LINK_CLICKED = 'DEAL_TASK_LINK_CLICKED',
  DEAL_SUMMARY_UPDATED = 'DEAL_SUMMARY_UPDATED',
  DEAL_FIELD_UPDATED = 'DEAL_FIELD_UPDATED',
  DEAL_FIELD_DELETED = 'DEAL_FIELD_DELETED',
  DEAL_FIELD_CREATED = 'DEAL_FIELD_CREATED',
  DEAL_ACCESS_LINK_UPDATED = 'DEAL_ACCESS_LINK_UPDATED',
  DEAL_FILE_DOWNLOADED = 'DEAL_FILE_DOWNLOADED',
  DEAL_LINK_CLICKED = 'DEAL_LINK_CLICKED',
  DEAL_VIDEO_WATCHED = 'DEAL_VIDEO_WATCHED',
  DEAL_DOCUMENT_INTERACTION = 'DEAL_DOCUMENT_INTERACTION',
  DEAL_INVITE_LINK_BTN_INTERACTION = 'DEAL_INVITE_LINK_BTN_INTERACTION',
  DEAL_STAGE_SELECTED = 'DEAL_STAGE_SELECTED',
  DEAL_WEB_EMBED_INTERACTION = 'DEAL_WEB_EMBED_INTERACTION',
  DEAL_RICH_MEDIA_INTERACTION = 'DEAL_RICH_MEDIA_INTERACTION',
  DEAL_LINK_PREVIEW_INTERACTION = 'DEAL_LINK_PREVIEW_INTERACTION',
  ITEM_FILE_UPLOADED = 'ITEM_FILE_UPLOADED',
  DEAL_BUTTON_INTERACTION = 'DEAL_BUTTON_INTERACTION',
  DEAL_PRICING_TABLE_UNITS_CHANGE = 'DEAL_PRICING_TABLE_UNITS_CHANGE',
}

export const DealEventsFilterArray = [
  EDealEventName.DEAL_USER_ADDED,
  EDealEventName.DEAL_USER_DELETED,
  EDealEventName.DEAL_STAGE_SELECTED,
  EDealEventName.DEAL_INVITE_LINK_BTN_INTERACTION,
  EDealEventName.DEAL_DOCUMENT_ADDED,
  EDealEventName.DEAL_FILE_DELETED,
  EDealEventName.DEAL_TASK_CREATED,
  EDealEventName.DEAL_TASK_DELETED,
  EDealEventName.DEAL_TASK_STATUS_UPDATED,
  EDealEventName.DEAL_TASK_TITLE_UPDATED,
  EDealEventName.DEAL_TASK_ASSIGNEES_UPDATED,
  EDealEventName.DEAL_TASK_EVENT_LINK_CLICKED,
  EDealEventName.DEAL_TASK_DOCUMENT_ADDED,
  EDealEventName.DEAL_TASK_TIMELINE_UPDATED,
  EDealEventName.DEAL_TASK_NESTED_ORDER_UPDATED,
  EDealEventName.DEAL_TASK_DESCRIPTION_UPDATED,
  EDealEventName.DEAL_TASK_EVENT_UPDATED,
  EDealEventName.DEAL_TASK_DOCUMENT_DOWNLOADED,
  EDealEventName.DEAL_TASK_DOCUMENT_DELETED,
  EDealEventName.DEAL_TASK_LINK_CLICKED,
  EDealEventName.DEAL_SUBTASK_ADDED,
  EDealEventName.DEAL_SUBTASK_DELETED,
  // EDealEventName.DEAL_SUMMARY_UPDATED,
  // EDealEventName.DEAL_ACCESS_LINK_UPDATED,
  EDealEventName.DEAL_FILE_DOWNLOADED,
  EDealEventName.DEAL_LINK_CLICKED,
  EDealEventName.DEAL_VIDEO_WATCHED,
  EDealEventName.DEAL_DOCUMENT_INTERACTION,
  EDealEventName.DEAL_WEB_EMBED_INTERACTION,
  EDealEventName.DEAL_RICH_MEDIA_INTERACTION,
  EDealEventName.DEAL_LINK_PREVIEW_INTERACTION,
  EDealEventName.ITEM_FILE_UPLOADED,
]

export enum EDealEventsFilterNames {
  DEAL_USER_ADDED = 'User invite',
  DEAL_USER_DELETED = 'User removed',
  DEAL_STAGE_SELECTED = 'Page opened',
  DEAL_INVITE_LINK_BTN_INTERACTION = 'Invite button clicked',
  '  DEAL_DOCUMENT_ADDED' = 'Uploaded file',
  DEAL_FILE_DELETED = 'Removed file',
  DEAL_TASK_STATUS_UPDATED = 'Updated action status',
  DEAL_TASK_TITLE_UPDATED = 'Updated action title',
  DEAL_TASK_ASSIGNEES_UPDATED = 'Updated action ownership',
  DEAL_TASK_CREATED = 'Action created',
  DEAL_TASK_DELETED = 'Action deleted',
  DEAL_TASK_EVENT_LINK_CLICKED = 'Action link clicked',
  DEAL_SUBTASK_ADDED = 'Subaction created',
  DEAL_SUBTASK_DELETED = 'Subaction deleted',
  DEAL_TASK_DOCUMENT_ADDED = 'Action document added',
  DEAL_TASK_TIMELINE_UPDATED = 'Action date updated',
  DEAL_TASK_NESTED_ORDER_UPDATED = 'Subactions reordered',
  DEAL_TASK_DESCRIPTION_UPDATED = 'Action description updated',
  DEAL_TASK_EVENT_UPDATED = 'Action link updated',
  DEAL_TASK_DOCUMENT_DOWNLOADED = 'Action document dowloaded',
  DEAL_TASK_DOCUMENT_DELETED = 'Action document deleted',
  DEAL_TASK_LINK_CLICKED = 'Action description link clicked',
  DEAL_SUMMARY_UPDATED = 'Process summary updated',
  DEAL_ACCESS_LINK_UPDATED = 'Process access lick updated',
  DEAL_FILE_DOWNLOADED = 'Process file downloaded',
  DEAL_LINK_CLICKED = 'Link in process clicked',
  DEAL_VIDEO_WATCHED = 'Video watched',
  DEAL_DOCUMENT_INTERACTION = 'Document interaction',
  DEAL_WEB_EMBED_INTERACTION = 'Embeded site interaction',
  DEAL_RICH_MEDIA_INTERACTION = 'Embeded rich media interaction',
  DEAL_LINK_PREVIEW_INTERACTION = 'Embeded link preview interaction',
}

export type EventNames = EDealEventName;

export class StagesetEvent {
  id: string;
  name: EventNames;
  type: EEventTypes;
  createdAt: string;
  payload: EventPayload;
  producerServiceName: string;
}

export interface DealEventPayload {
  producerId: string;
  processId: string;
  stageId?: string;
  data: DealNotificationPayload;
  itemData?: ProcessStageDTO;
}

export type EventPayload = DealEventPayload;

export type CreateEventRequest = Omit<StagesetEvent, 'id' | 'createdAt'>
