import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronRight20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M7.5 15.2651L12.5 10.2651L7.5 5.26514"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
