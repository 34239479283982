import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { CalendarIcon20px } from '../../../../icons/Calendar';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { CloseIcon } from '../../../../icons/Close16px';
import { AgendaButtonTitle, StyledPickersLayout } from './styles/AgendaStyles';
import { ChevronLeft20 } from '../../../../icons/ChevronLeft20';
import { ChevronRight20 } from '../../../../icons/ChevronRight';
import { ChevronDown20 } from '../../../../icons/ChevronDown';

interface Props {
  date?: DateTime<true> | DateTime<false> | string | null;
  onDateChange: (newDate?: DateTime<true> | DateTime<false> | string | null) => void;
}

const AgendaTimePicker = ({
  date,
  onDateChange,
}: Props): JSX.Element => {
  const [dateToDisplay, setDateToDisplay] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (date) {
      setDateToDisplay(date as string);
    } else {
      setDateToDisplay(null);
    }
  }, [date]);

  const handleDateChange = (newDate: DateTime<true> | DateTime<false> | string | null) => {
    setOpen(false);
    onDateChange(newDate?.toString() ?? null);
    setDateToDisplay(newDate as string ?? null);
  };

  useEffect(() => {
    setOpen(false);
  }, [dateToDisplay]);

  return (
    <Box>
      <Box
        sx={{
          height: '40px',
          width: 'auto',
          maxWidth: '200px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '& .MuiTextField-root': {
            visibility: 'hidden',
            height: '0px',
            width: '0px',
            '& .MuiInputBase-root': {
              visibility: 'hidden',
              height: '0px',
              width: '0px',
            },
          },
        }}
      >
        <DatePicker
          open={open}
          onClose={() => setOpen(false)}
          DialogProps={{
            onClick: (e: Event) => e.stopPropagation(),
          }}
          value={dateToDisplay ? DateTime.fromISO(dateToDisplay) : DateTime.now()}
          onChange={handleDateChange}
          slots={{
            leftArrowIcon: ChevronLeft20,
            rightArrowIcon: ChevronRight20,
            switchViewIcon: ChevronDown20,
            layout: StyledPickersLayout,
          }}
          slotProps={{
            switchViewButton: {
              disableRipple: true,
            },
            previousIconButton: {
              disableRipple: true,
            },
            nextIconButton: {
              disableRipple: true,
            },
            desktopPaper: {
              sx: {
                borderRadius: '12px',
              },
            },
          }}
          sx={{
            top: '25px',
          }}
        />
        <AppButton
          size={dateToDisplay ? 'l-ds' : 'l'}
          variant="tertiary-newGrey-ds-left"
          startIcon={<CalendarIcon20px />}
          onClick={() => setOpen(true)}
          sx={{
            justifyContent: dateToDisplay ? 'start' : 'center',
          }}
        >
          <AgendaButtonTitle>
            {dateToDisplay ? DateTime.fromISO(dateToDisplay).toJSDate().toLocaleDateString() : 'Set date'}
          </AgendaButtonTitle>
        </AppButton>
        {dateToDisplay
          && (
          <AppIconButton
            size="l-ds-icon"
            variant="tertiary-newGrey-ds-right"
            icon={<CloseIcon />}
            onClick={() => handleDateChange(null)}
          />
          )}

      </Box>
    </Box>
  );
};

export default AgendaTimePicker;
