import { SvgIcon } from '@mui/material';

export const CompanyLogoGlobe = (): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0008 17.2C13.9772 17.2 17.2008
13.9764 17.2008 9.99999C17.2008 6.02354
13.9772 2.79999 10.0008 2.79999C6.02433
2.79999 2.80078 6.02354 2.80078 9.99999C2.80078
 13.9764 6.02433 17.2 10.0008 17.2Z"
      stroke="#7084B4"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2.80078 10H17.2008"
      stroke="#7084B4"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.0008 2.79999C12.0018 4.7716
13.139 7.33025 13.2008 9.99999C13.139 12.6697
12.0018 15.2284 10.0008 17.2C7.99976 15.2284
6.86258 12.6697 6.80078 9.99999C6.86258 7.33025
7.99976 4.7716 10.0008 2.79999V2.79999Z"
      stroke="#7084B4"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
