import classNames from 'classnames';
import { FormProvider } from 'react-hook-form';
import { TabContext } from '@mui/lab';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import {
  ENewPageTabs,
  ESidebarTabs, selectNewPageTab, selectSidebarTab,
  setNewPageTab,
} from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { CloseIcon20 } from '../../icons/CloseIcon';
import {
  HeaderTitle,
  SidebarHeader,
  SidebarTabContentWrapper,
  SidebarTabPanel,
} from '../Stages/Styles/SidebarStyles';
import NewPageTabFooter from './NewPageTabFooter';
import {
  NewPageFormWrapper,
  NewPagePaddingBox,
  NewPageTabPanel,
} from './Styles/NewPageMainTabStyles';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import NewPageTemplateTab from './NewPageTemplateTab';
import NewPageCanvasInputs from './NewPageTabComponents/NewPageCanvasInputs';
import { NewPageContextProvider } from './NewPageContextProvider/NewPageContextProvider';
import useCreateNewPage from '../../hooks/useCreateNewPage';

const NewPageCanvasTab = () => {
  const currentSidebarTab = useAppSelector(selectSidebarTab) === ESidebarTabs.CANVAS;

  const newPageTab = useAppSelector(selectNewPageTab);
  const dispatch = useAppDispatch();

  const {
    disabled,
    templateError,
    stageCreation,
    newPageFormMethods,
    submitNewPageForm,
    onClose,
    goBack,
    clearField,
    templatesMenuItems,
    newPageStateForTabs,
    templateTitle,
  } = useCreateNewPage();

  const {
    handleSubmit,
  } = newPageFormMethods;

  return (
    <SidebarTabPanel
      value={ESidebarTabs.CANVAS}
      className={classNames('sidebar-new-page', { 'active-sidebar-tab': currentSidebarTab })}
    >
      <NewPageContextProvider props={{
        methods: {
          clearField,
          goBack,
        },
        data: {
          stageCreation,
          templateTitle,
          templateError,
          templatesMenuItems,
          disabled,
        },
      }}
      >
        <NewPageFormWrapper
          component="form"
          onSubmit={handleSubmit(submitNewPageForm)}
        >
          <SidebarTabContentWrapper
            className="sidebar-wrapper-new-page"
          >
            <SidebarHeader>
              <HeaderTitle
                className="grey-header-title"
              >
                Canvas
              </HeaderTitle>
              <AppIconButton
                disableRipple
                icon={<CloseIcon20 />}
                onClick={onClose}
              />
            </SidebarHeader>
            <NewPagePaddingBox>
              <AppTabs
                color="white"
                variant="boxedNew"
                size="l-new"
                value={newPageStateForTabs}
                onChange={(option) => {
                  dispatch(setNewPageTab(option.value));
                }}
                options={[
                  {
                    title: 'Blank', value: ENewPageTabs.BLANK,
                  },
                  {
                    title: 'Templates', value: ENewPageTabs.TEMPLATE,
                  },
                ]}
              />
            </NewPagePaddingBox>
            <TabContext
              value={newPageTab}
            >
              <FormProvider {...newPageFormMethods}>
                <NewPageTabPanel
                  value={ENewPageTabs.BLANK}
                >
                  <NewPageCanvasInputs />
                </NewPageTabPanel>
                <NewPageTabPanel
                  value={ENewPageTabs.TEMPLATE}
                >
                  <NewPageTemplateTab />
                </NewPageTabPanel>
                <NewPageTabPanel
                  value={ENewPageTabs.TEMPLATE_EDIT}
                >
                  <NewPageCanvasInputs />
                </NewPageTabPanel>
              </FormProvider>
            </TabContext>
          </SidebarTabContentWrapper>
          <NewPageTabFooter />
        </NewPageFormWrapper>
      </NewPageContextProvider>
    </SidebarTabPanel>
  );
};

export default NewPageCanvasTab;
