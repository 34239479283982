import { Box } from '@mui/material';
import * as React from 'react';
import { AddCircleOutlined, Close, LibraryAddOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ECreateTemplateRequestType, EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../common/DeleteTypography';
import { EMixPanelEvents } from '../../core/Analytics';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  createProcess,
  deleteProcess, getProcesses, openEditDealDialog, processByIdSelector,
} from './state/dealsSlice';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import Trash from '../../icons/Trash.svg';
import { ThreeDotsVert20px } from '../../icons/ThreeDots';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import useModalWindow from '../../hooks/useModalWindow';
import CollectTemplateInfoDialog, { ICreateTemplatePopup } from '../../features/CollectTemplateInfoDialog/CollectTemplateInfoDialog';
import { createTemplate } from '../../pages/templates/lib/templatesSlice';
import { PenIcon20 } from '../../icons/PenIcon';
import { DuplicateIcon20 } from '../../icons/DuplicateIcon';
import { BookOpenIcon20 } from '../../icons/BookOpenIcon';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';

interface Props {
  trackEvent: (event: EMixPanelEvents) => void;
  itemType: EProcessType;
  id: string;
  title: string;
  openCreateDealDialog: (id: string) => void;
  allowDuplicate?: boolean;
}

const DealsListMenu = ({
  trackEvent,
  itemType,
  id,
  title,
  openCreateDealDialog,
  allowDuplicate,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const process = useAppSelector((state) => processByIdSelector(state, id));
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [collectDialog, setCollectDialog] = useState(false);
  const { setTemplateModalWindowState, onModalWindowStateReset, setProcessCopyModalWindowState } = useModalWindow();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCreateTemplate = async (name: string) => {
    try {
      setTemplateModalWindowState();
      const template = await dispatch(createTemplate({
        title: name,
        type: EProcessType.TEMPLATE,
        templateId: id,
        createType: ECreateTemplateRequestType.DEAL,
      })).unwrap();
      if (template) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      onModalWindowStateReset();
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(EMixPanelEvents.THREE_DOTS_CLICKED);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    trackEvent(EMixPanelEvents.THREE_DOTS_EDIT_OPTION_CLICKED);
    dispatch(openEditDealDialog(id));
    handleClose();
  };

  const handleCreateClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    trackEvent(EMixPanelEvents.THREE_DOTS_CREATE_OPTION_CLICKED);
    setCollectDialog(true);
    handleClose();
  };

  const onCollectedTemplateInfo = (data: ICreateTemplatePopup) => {
    onCreateTemplate(data.name);
  };

  const handleDeleteClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    trackEvent(EMixPanelEvents.THREE_DOTS_DELETE_OPTION_CLICKED);
    setDeleteDialogOpen(true);
    handleClose();
  };

  const onItemDelete = (): void => {
    setDeleteDialogOpen(false);
    dispatch(deleteProcess(id));
  };

  const onCloneTemplate = (): void => {
    dispatch(createTemplate({
      title: `${title} Copy`,
      type: EProcessType.TEMPLATE,
      templateId: id,
      createType: ECreateTemplateRequestType.CLONE,
    }));
  };

  const cloneProcess = async () => {
    try {
      setProcessCopyModalWindowState();
      await dispatch(createProcess({
        title: `${title} Copy`,
        value: process?.value ?? 0,
        client: process?.client,
        logo: process?.buyerLogoSrc,
        type: EProcessType.DEAL,
        templateId: id,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(getProcesses());
      onModalWindowStateReset();
      setAnchorEl(null);
    }
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to delete
      <b>
        {' '}
        {title}
        {' '}
        {itemType === EProcessType.TEMPLATE ? 'template' : 'room'}
      </b>
      ?
    </DeleteTypography>
  );

  return (
    <Box>
      <AppIconButton
        id="seller-deals-more-menu"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleOpen}
        transparentStroke
        icon={<ThreeDotsVert20px />}
      />
      <AppDropdown
        size="s"
        items={[
          {
            label: 'Clone Template',
            onClick: () => {
              onCloneTemplate();
              handleClose();
            },
            startIcon: <LibraryAddOutlined />,
            hidden: itemType !== EProcessType.TEMPLATE,
          },
          {
            label: 'Edit Room',
            onClick: handleEditClick,
            startIcon: <PenIcon20 />,
            hidden: itemType !== EProcessType.DEAL,
          },
          {
            label: 'Create Template',
            onClick: handleCreateClick,
            startIcon: <BookOpenIcon20 />,
            hidden: itemType !== EProcessType.DEAL,
          },
          {
            label: 'Duplicate Room',
            onClick: cloneProcess,
            startIcon: <DuplicateIcon20 />,
            hidden: itemType !== EProcessType.DEAL || !allowDuplicate,
          },
          {
            label: 'Create Room',
            onClick: () => {
              openCreateDealDialog(id);
              handleClose();
            },
            startIcon: <AddCircleOutlined />,
            hidden: itemType !== EProcessType.TEMPLATE,
          },
          {
            label: '',
            divider: true,
          },
          {
            label: 'Delete',
            onClick: handleDeleteClick,
            startIcon: <Trash />,
          },
        ]}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocus
        disableAutoFocusItem
        lastElementHasAnotherColor
      />
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={isDeleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={onItemDelete}
        dialogContentText={deleteTypography}
        dialogTitle={itemType === EProcessType.TEMPLATE ? 'Delete template' : 'Delete room'}
      />
      <CollectTemplateInfoDialog open={collectDialog} setOpen={setCollectDialog} onSubmit={onCollectedTemplateInfo} />
    </Box>
  );
};

export default DealsListMenu;
