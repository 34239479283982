import { SvgIcon, SvgIconProps } from '@mui/material';

export const MoreVertical16 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      width: '16px',
      height: '16px',
    }}
  >
    <path
      d="M7.9974 7.33341C7.62921 7.33341 7.33073
      7.63189 7.33073 8.00008C7.33073 8.36827
      7.62921 8.66675 7.9974 8.66675C8.36559
      8.66675 8.66406 8.36827 8.66406 8.00008C8.66406
      7.63189 8.36559 7.33341 7.9974 7.33341Z"
      fill="none"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9974 11.9999C7.62921 11.9999 7.33073
      12.2984 7.33073 12.6666C7.33073 13.0348 7.62921
      13.3333 7.9974 13.3333C8.36559 13.3333 8.66406
      13.0348 8.66406 12.6666C8.66406 12.2984 8.36559
      11.9999 7.9974 11.9999Z"
      fill="none"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9974 2.66667C7.62921 2.66667 7.33073
      2.96514 7.33073 3.33333C7.33073 3.70152 7.62921
      4 7.9974 4C8.36559 4 8.66406 3.70152 8.66406
      3.33333C8.66406 2.96514 8.36559 2.66667 7.9974 2.66667Z"
      fill="none"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
