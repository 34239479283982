import classNames from 'classnames';
import { FormProvider } from 'react-hook-form';
import { TabContext } from '@mui/lab';
import { useEffect } from 'react';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import {
  ENewPageTabs,
  ESidebarTabs,
  selectNewPageEmbedTab,
  selectNewPageTab,
  selectSidebarTab,
  setNewPageTab,
} from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { CloseIcon20 } from '../../icons/CloseIcon';
import {
  HeaderTitle,
  SidebarHeader,
  SidebarTabContentWrapper,
  SidebarTabPanel,
} from '../Stages/Styles/SidebarStyles';
import NewPageTabFooter from './NewPageTabFooter';
import {
  NewPageFormWrapper,
  NewPagePaddingBox,
  NewPageTabPanel,
} from './Styles/NewPageMainTabStyles';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import NewPageEmbedInputs from './NewPageTabComponents/NewPageEmbedInputs';
import { NewPageContextProvider } from './NewPageContextProvider/NewPageContextProvider';
import { ContentType } from '../../../shared/Stage';
import useCreateNewPage from '../../hooks/useCreateNewPage';
import NewPageMediaLibraryList from './NewPageTabComponents/NewPageMediaLibraryList';
import { EFileType } from '../../../shared/library/Library';

const NewPageEmbedTab = () => {
  const currentSidebarTab = useAppSelector(selectSidebarTab) === ESidebarTabs.EMBED;
  const newPageTab = useAppSelector(selectNewPageTab);
  const newPageEmbedTab = useAppSelector(selectNewPageEmbedTab);
  const dispatch = useAppDispatch();

  const {
    disabled,
    templateError,
    stageCreation,
    newPageFormMethods,
    submitNewPageForm,
    onClose,
    goBack,
    clearField,
    templatesMenuItems,
    newPageStateForTabs,
    libraryFileToAdd,
  } = useCreateNewPage();

  const {
    handleSubmit,
    setValue,
  } = newPageFormMethods;

  const nameToWitoutExt = (input: string | undefined): string | undefined => {
    if (!input) {
      return input;
    }
    if (input.lastIndexOf('.') < 0) {
      return input;
    }
    return input.substring(0, input.lastIndexOf('.'));
  };

  useEffect(() => {
    if (newPageEmbedTab) setValue('contentType', newPageEmbedTab);
  }, [newPageEmbedTab]);

  return (
    <SidebarTabPanel
      value={ESidebarTabs.EMBED}
      className={classNames('sidebar-new-page', { 'active-sidebar-tab': currentSidebarTab })}
    >
      <NewPageContextProvider
        props={{
          methods: {
            clearField,
            goBack,
          },
          data: {
            templateTitle: nameToWitoutExt(libraryFileToAdd?.name),
            templateError,
            templatesMenuItems,
            disabled,
            stageCreation,
            libraryFileToAdd,
          },
        }}
      >
        <NewPageFormWrapper
          component="form"
          onSubmit={handleSubmit(submitNewPageForm)}
        >
          <SidebarTabContentWrapper
            className="sidebar-wrapper-new-page"
          >
            <SidebarHeader>
              <HeaderTitle
                className="grey-header-title"
              >
                {`${newPageEmbedTab === ContentType.URL
                  ? 'Embed'
                  : newPageEmbedTab.split('_').join(' ').toLowerCase()}`}
              </HeaderTitle>
              <AppIconButton
                disableRipple
                icon={<CloseIcon20 />}
                onClick={onClose}
              />
            </SidebarHeader>
            <NewPagePaddingBox>
              <AppTabs
                color="white"
                variant="boxedNew"
                size="l-new"
                value={newPageStateForTabs}
                onChange={(option) => {
                  dispatch(setNewPageTab(option.value));
                }}
                options={[
                  {
                    title: 'Blank', value: ENewPageTabs.BLANK,
                  },
                  {
                    title: 'Library', value: ENewPageTabs.LIBRARY,
                  },
                ]}
              />
            </NewPagePaddingBox>
            <TabContext
              value={newPageTab}
            >
              <FormProvider {...newPageFormMethods}>
                <NewPageTabPanel
                  value={ENewPageTabs.BLANK}
                >
                  <NewPageEmbedInputs />
                </NewPageTabPanel>
                <NewPageTabPanel
                  value={ENewPageTabs.LIBRARY}
                >
                  {/* <NewPageTemplateTab /> */}
                  <NewPageMediaLibraryList libraryFileType={EFileType.LINK} linkType={newPageEmbedTab} />
                </NewPageTabPanel>
                <NewPageTabPanel
                  value={ENewPageTabs.LIBRARY_EDIT}
                >
                  <NewPageEmbedInputs />
                </NewPageTabPanel>
              </FormProvider>
            </TabContext>
          </SidebarTabContentWrapper>
          <NewPageTabFooter />
        </NewPageFormWrapper>
      </NewPageContextProvider>
    </SidebarTabPanel>
  );
};

export default NewPageEmbedTab;
