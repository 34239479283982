import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      fill="none"
      d="M0.833008 9.99992C0.833008 9.99992 4.16634 3.33325
      9.99967 3.33325C15.833 3.33325 19.1663 9.99992 19.1663
      9.99992C19.1663 9.99992 15.833 16.6666 9.99967
      16.6666C4.16634 16.6666 0.833008 9.99992 0.833008 9.99992Z"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929
      11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5
      11.3807 8.61929 12.5 10 12.5Z"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
