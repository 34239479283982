import {
  AnyAction, applyMiddleware, combineReducers, configureStore,
} from '@reduxjs/toolkit';
import {
  FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createEpicMiddleware } from 'redux-observable';
import { processActivityOnlineOfflineSlice } from '../../features/ActivityLog/lib/processActivityOnlineOfflineSlice';
import { milestonesFieldApi } from '../../features/MilestonesField/lib/milestonesFieldApi';
import { tasksApi } from '../../features/Tasks/lib/tasksApi';
import { authReducerToken, authSlice, EAuthActions } from '../../routes-old/auth/state/authState';
import { billingSlice } from '../../routes-old/Billing/state/billingSlice';
import { buyerDealsSlice } from '../../routes-old/BuyerDeals/state/buyerDealsSlice';
import { dealsSlice } from '../../routes-old/deals/state/dealsSlice';
import { teamDealsSlice } from '../../routes-old/deals/state/teamDealsSlice';
import { dealNotificationsSlice } from '../../routes-old/process/state/dealNotificationsSlice';
import { processActivityLogSlice } from '../../features/ActivityLog/lib/processActivityLogSlice';
import { permissionsSlice } from '../../routes-old/process/state/permissionsSlice';
import { processSlice } from '../../routes-old/process/state/processSlice';
import { tasksSlice } from '../../routes-old/process/state/tasksSlice';
import { salesforce } from '../crmintegrations/salesforce';
import { appSlice } from './appState/appState';
import { dealsGridSlice } from '../../features/Deals/DataGridFilter/lib/dealsGridSlice';
import { feedSlice } from '../../features/DealFeed/lib/feedSlice';
import { processFieldsApi } from '../../features/ProcessFields/lib/processFieldsApi';
import { shareDealPopupSlice } from '../../features/ShareDealPopup/lib/shareDealPopupSlice';
import { sectionsSlice } from '../../features/BlockContentSection/lib/sectionsSlice';
import { sideMenuSlice } from '../../features/SideMenu/lib/sideMenuSlice';
import { templatesSlice } from '../../pages/templates/lib/templatesSlice';
import { linkPreviewApi } from '../../features/LinkPreview/lib/linkPreviewApi';
import { embedIframeApi } from '../../features/MediaField/lib/embedIframeApi';
import { pipedrive } from '../crmintegrations/pipedrive';
import { hubspot } from '../crmintegrations/hubspot';
import { dealCreationDataSlice } from '../../features/TemplatePicker/lib/dealCreationDataSlice';
import { modalWindowSlice } from '../../features/AppLoadingModalWindow/lib/appModalWindowSlice';
import { fieldsLayoutSlice } from '../../features/Layout/lib/fieldsLayoutSlice';
import { dealCustomizationSlice } from '../../features/DealCustomizationMenu/lib/dealCustomizationSlice';
import { dealFilterApi } from '../../features/Deals/DataGridFilter/api/dealFilterApi';
import { esignatureSlice } from '../../features/ESignature/lib/esignatureSlice';
import { rootEpic } from './epic/rootEpic';
import { pricingTableItemParamsSlice } from '../../features/PricingTable/lib/pricingTableSlice';
import { adminSlice } from '../../routes-old/admin/state/adminSlice';
import { librarySlice } from '../../pages/library/lib/librarySlice';
import { libraryFilesGridFilterSlice } from '../../pages/library/lib/libraryFilesFilterSlice';
import { libraryProductGridFilterSlice } from '../../pages/library/lib/libraryProductFilterSlice';
import { libraryRtkApi } from '../../pages/library/lib/libraryRTKApi';
import { processItemsSlice } from '../../features/ProcessFields/lib/processItemsSlice';
import { publicApiTokenSlice } from '../../routes-old/account/SettingsTabs/public-api-settings/lib/publicApiTokenSlice';

// https://round.fun/2020/09/08/react-native-how-to-use-redux-persist-for-nested-state/
const authPersistReducer = persistReducer({
  key: authReducerToken,
  version: 1,
  storage,
  blacklist: [
    'status',
    'error',
    'isShareSingUpOpen',
    'isShareProtectedAuthDialogOpen',
    'isSignupDialogOpen',
  ],
}, authSlice.reducer);

const appReducer = combineReducers({
  process: processSlice.reducer,
  processItems: processItemsSlice.reducer,
  processActivityLog: processActivityLogSlice.reducer,
  processActivityOnlineOffline: processActivityOnlineOfflineSlice.reducer,
  dealNotifications: dealNotificationsSlice.reducer,
  feed: feedSlice.reducer,
  fieldsLayout: fieldsLayoutSlice.reducer,
  dealCustomization: dealCustomizationSlice.reducer,
  sections: sectionsSlice.reducer,
  shareDealPopup: shareDealPopupSlice.reducer,
  auth: authPersistReducer,
  app: appSlice.reducer,
  dealsGridFilter: dealsGridSlice.reducer,
  modalWindow: modalWindowSlice.reducer,
  dealCreationData: dealCreationDataSlice.reducer,
  templates: templatesSlice.reducer,
  deals: dealsSlice.reducer,
  buyerDeals: buyerDealsSlice.reducer,
  teamDeals: teamDealsSlice.reducer,
  tasks: tasksSlice.reducer,
  billing: billingSlice.reducer,
  pipedrive: pipedrive.reducer,
  hubspot: hubspot.reducer,
  salesForce: salesforce.reducer,
  permissions: permissionsSlice.reducer,
  sideMenu: sideMenuSlice.reducer,
  esignature: esignatureSlice.reducer,
  [milestonesFieldApi.reducerPath]: milestonesFieldApi.reducer,
  [processFieldsApi.reducerPath]: processFieldsApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [linkPreviewApi.reducerPath]: linkPreviewApi.reducer,
  [embedIframeApi.reducerPath]: embedIframeApi.reducer,
  [dealFilterApi.reducerPath]: dealFilterApi.reducer,
  pricingTableParams: pricingTableItemParamsSlice.reducer,
  admin: adminSlice.reducer,
  library: librarySlice.reducer,
  libraryFilesFilter: libraryFilesGridFilterSlice.reducer,
  libraryProductFilter: libraryProductGridFilterSlice.reducer,
  [libraryRtkApi.reducerPath]: libraryRtkApi.reducer,
  apiTokens: publicApiTokenSlice.reducer,
});

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === `${authReducerToken}/${EAuthActions.LOGOUT}/pending`) {
    state = {} as RootState;
    localStorage.clear();
    sessionStorage.clear();
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [''],
  blacklist: [
    processFieldsApi.reducerPath,
    tasksApi.reducerPath,
    linkPreviewApi.reducerPath,
    embedIframeApi.reducerPath,
    dealFilterApi.reducerPath,
    libraryRtkApi.reducerPath,
  ],
};

const reducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware();
const middlewareEnhancer = applyMiddleware(epicMiddleware);

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(milestonesFieldApi.middleware)
    .concat(processFieldsApi.middleware)
    .concat(tasksApi.middleware)
    .concat(linkPreviewApi.middleware)
    .concat(embedIframeApi.middleware)
    .concat(dealFilterApi.middleware)
    .concat(libraryRtkApi.middleware),
  enhancers: [middlewareEnhancer],
});

epicMiddleware.run(rootEpic);

export type AsyncThunkConfig = {
  /** return type for `thunkApi.getState` */
  state?: RootState,
  /** type for `thunkApi.dispatch` */
  dispatch?: AppDispatch
  /** type of the `extra` argument for the thunk middleware, which will be passed in as `thunkApi.extra` */
  extra?: unknown
  /** type to be passed into `rejectWithValue`'s first argument that will end up on `rejectedAction.payload` */
  rejectValue?: unknown
  /** return type of the `serializeError` option callback */
  serializedErrorType?: unknown
  /** type to be returned from the `getPendingMeta` option callback & merged into `pendingAction.meta` */
  pendingMeta?: unknown
  /** type to be passed into the second argument of `fulfillWithValue` to finally be merged into `fulfilledAction.meta` */
  fulfilledMeta?: unknown
  /** type to be passed into the second argument of `rejectWithValue` to finally be merged into `rejectedAction.meta` */
  rejectedMeta?: unknown
};

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
