import { Box } from '@mui/material';
import { selectConResizeStatus } from '../../features/BlockContentSection/lib/sectionsSlice';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectStage } from '../../routes-old/process/state/processSlice';
import { StageContentType } from '../../../shared/Stage';
import { useCurrentStage } from '../../hooks/useCurrentStage';

interface Props {
  width?: number;
  height?: number;
}

const IFrameCover = ({
  width,
  height,
}: Props): JSX.Element | null => {
  const colResizeStatus = useAppSelector(selectConResizeStatus);
  const currentStage = useCurrentStage();

  const renderHeight = () => {
    if (height) return height;
    if (currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE) {
      return '100vh';
    }
    return '430px';
  };

  if (!colResizeStatus) {
    return null;
  }

  return (
    <Box
      sx={{
        width: width ?? '100%',
        height: '0px',
        maxWidth: 'calc( 100% )',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 10,
          height: renderHeight(),
          width: width ?? '100%',
          maxWidth: 'calc( 100% )',
          background: 'transparent',
          pointerEvents: colResizeStatus ? 'all' : 'none',
        }}
      />
    </Box>
  );
};

export default IFrameCover;
