import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { stagesetPalette } from '../../theme/stagesetPalette';

export const NavigationLink = styled(NavLink)(() => ({
  width: '100%',
  height: '70px',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: stagesetPalette.grey?.[600],
  fontSize: '16px',
  '&:hover': {
    color: 'black',
  },
  '& .LinkInfoContainer': {
    width: '100%',
    height: '70px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& .LinkIcon': {
      '& svg': {
        display: 'flex',
        width: '20px',
        height: '20px',
        fontSize: '20px',
      },
    },
    '& .LinkTitle': {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      marginLeft: '15px',
    },
  },
}));
