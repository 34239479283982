export enum EAdminDataGridColumns {
  organizationName = 'organizationName',
  ownder = 'ownder',
  admins = 'admins',
  users = 'users',
  tariff = 'tariff',
  domain = 'domain',
  menu = 'menu',
}

export enum EAdminFeedTab {
  none = 'none',
  organizationDetails = 'organizationDetails',
}

export interface SelectOrganizationPayload {
  organizationId: string;
  organizationName: string;
}
