import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import classNames from 'classnames';
import {
  $createParagraphNode, $getSelection, $isRangeSelection, LexicalEditor,
} from 'lexical';
import { useRef, useState } from 'react';
import { useActionOnOutsideClick } from '../../../hooks/useClickedOutside';
import { ELexicalBlockTypes, getBlockName, LexicalBlockTypes } from '../LexicalRTEhelpers';

interface Props {
  editor: LexicalEditor;
  blockType: LexicalBlockTypes;
}

const LexicalRTEHeaderMenu = ({
  editor,
  blockType,
}: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const headerOptionsMenuRef = useRef<HTMLDivElement>(null);

  const isBlockTypeListed = !(
    blockType === ELexicalBlockTypes.H1
    || blockType === ELexicalBlockTypes.H2
    || blockType === ELexicalBlockTypes.PARAGRAPH
  );

  useActionOnOutsideClick(headerOptionsMenuRef, () => setOpen(false), open);

  const formatBlock = (newBlockType: LexicalBlockTypes): void => {
    editor.update(() => {
      const selection = $getSelection();
      switch (newBlockType) {
        case ELexicalBlockTypes.PARAGRAPH:
          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createParagraphNode());
          }
          break;
        case ELexicalBlockTypes.H1:
          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode('h1'));
          }
          break;
        case ELexicalBlockTypes.H2:
          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode('h2'));
          }
          break;
        default:
      }
    });
    setOpen(false);
  };

  return (
    <Box
      sx={{
        height: '32px',
        background: '#fff',
        display: 'flex',
        alignItems: 'flex-start',
        width: '145px',
      }}
    >
      <Box
        ref={headerOptionsMenuRef}
        className={classNames('HeaderOptions', {
          'HeaderOptions-opened': open,
          'HeaderOptions-expanded': isBlockTypeListed,

        })}
        onClick={() => { if (!open) setOpen(true); }}
        sx={{
          background: '#fff',
          borderRadius: '8px',
          overflow: 'hidden',
          border: (theme) => `1px solid ${theme.palette.grey[200]}`,
          width: '100%',
          height: '32px',
          zIndex: 999,
          transition: '0.2s',
          '&.HeaderOptions-opened': {
            height: '96px',
            border: (theme) => `1px solid ${theme.palette.primary.light}`,
            '&.HeaderOptions-expanded': {
              height: '128px',
            },
          },
          '& .HeaderOptionsItem': {
            cursor: 'pointer',
            paddingX: '12px',
            height: '32px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          '& .HeaderOptionsMenuIcon': {
            transition: '0.2s',
            color: (theme) => theme.palette.grey[400],
            '&.HeaderOptionsMenuIcon-open': {
              transform: 'scale(1, -1)',
            },
          },
          '& .HeaderOptionsItem-active': {
            '&:hover': {
              background: '#B7DCFF',
            },
          },
          '& .HeaderOptionsItem-h1': {
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 600,
          },
          '& .HeaderOptionsItem-h2': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
          },
          '& .HeaderOptionsItem-p': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400,
          },
          '& .HeaderOptionsItem-hidden': {
            display: 'none',
          },
        }}
      >
        <Box
          className={classNames(
            'HeaderOptionsItem',
            {
              'HeaderOptionsItem-h1': blockType === ELexicalBlockTypes.H1,
              'HeaderOptionsItem-h2': blockType === ELexicalBlockTypes.H2,
              'HeaderOptionsItem-p': blockType === ELexicalBlockTypes.PARAGRAPH,
            },
          )}
          onClick={() => setOpen(false)}
        >
          {getBlockName(blockType)}
          <KeyboardArrowDownRounded
            className={classNames('HeaderOptionsMenuIcon', { 'HeaderOptionsMenuIcon-open': open })}
            sx={{
              transition: '0.2s',
              '&.HeaderOptionsMenuIcon-open': {
                transform: 'scale(1, -1)',
              },
            }}
          />
        </Box>
        <Divider />
        <Box
          className={classNames(
            'HeaderOptionsItem HeaderOptionsItem-active HeaderOptionsItem-h1',
            { 'HeaderOptionsItem-hidden': blockType === ELexicalBlockTypes.H1 },
          )}
          onClick={() => formatBlock(ELexicalBlockTypes.H1)}
        >
          Heading 1
        </Box>
        <Box
          className={classNames(
            'HeaderOptionsItem HeaderOptionsItem-active HeaderOptionsItem-h2',
            { 'HeaderOptionsItem-hidden': blockType === ELexicalBlockTypes.H2 },
          )}
          onClick={() => formatBlock(ELexicalBlockTypes.H2)}
        >
          Heading 2
        </Box>
        <Box
          className={classNames(
            'HeaderOptionsItem HeaderOptionsItem-active HeaderOptionsItem-p',
            { 'HeaderOptionsItem-hidden': blockType === ELexicalBlockTypes.PARAGRAPH },
          )}
          onClick={() => formatBlock(ELexicalBlockTypes.PARAGRAPH)}
        >
          Normal Text
        </Box>
      </Box>
    </Box>
  );
};

export default LexicalRTEHeaderMenu;
