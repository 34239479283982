import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectUser, updateUserData, uploadUserAvatar } from '../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { ExternalLinks } from '../../shared/UserDTO';

export interface FormState {
  avatar?: File | string | null;
  fullName: string | undefined;
  phone: string | undefined;
  lastName: string;
  email?: string;
  contactEmail: string;
  title: string | undefined;
  linkedIn?: string;
  calendly?: string;
  externalLinks?: ExternalLinks;
}
const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];

export const useProfileSettings = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(true);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const profileFormSchemaConfig = useMemo(() => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    validationSchemaConfigTemp.avatar = yup.mixed()
      .test(
        'Please select a file',
        (files) => {
          if (!files || typeof files === 'string') return true;
          return files?.size > 0;
        },
      )
      .test(
        'fileSize',
        'File is too large',
        (files) => {
          if (!files || typeof files === 'string') return true;
          return files?.size <= FILE_SIZE;
        },
      )
      .test(
        'fileType',
        'jpg, jpeg, png, webp, svg only',
        (files) => {
          if (!files || typeof files === 'string') return true;
          return SUPPORTED_FORMATS.includes(files?.type);
        },
      );
    validationSchemaConfigTemp.fullName = yup.string().required('First Name is required');
    validationSchemaConfigTemp.lastName = yup.string().required('Last Name is required');
    validationSchemaConfigTemp.phone = yup.lazy((value) => {
      if (!value) return yup.mixed();
      return yup
        .string();
    });
    validationSchemaConfigTemp.email = yup
      .string()
      .email()
      .required('Email is requered');
    validationSchemaConfigTemp.contactEmail = yup
      .string()
      .required(t('Error_Email_is_required'))
      .email(t('Error_The_email_must_be_a_valid_email_address'));
    validationSchemaConfigTemp.title = yup.string().required('Job title is required');
    validationSchemaConfigTemp.externalLinks = yup.object().shape({
      linkedIn: yup.string().url('Must be a valid URL'),
      calendly: yup.string().url('Must be a valid URL'),
    });
    return validationSchemaConfigTemp;
  }, [user]);

  const schema = yup.object(profileFormSchemaConfig).required();

  const defaultUserObject: FormState = {
    fullName: user.name,
    lastName: user.lastName,
    phone: user.phone,
    email: user.email,
    title: user.title,
    contactEmail: user.contactEmail ? user.contactEmail : user.email,
    externalLinks: {
      linkedIn: user.externalLinks?.linkedIn ?? '',
      calendly: user.externalLinks?.calendly ?? '',
    },
    avatar: user.avatarSrc ?? '',
  };

  const profileFormMethods = useForm<FormState>({
    mode: 'onSubmit',
    defaultValues: defaultUserObject,
    resolver: yupResolver(schema),
  });

  const {
    formState: { isValid, isDirty },
    reset,
  } = profileFormMethods;

  useEffect(() => {
    reset(defaultUserObject);
  }, [user]);

  useEffect(() => {
    if (!isDirty) {
      setDisabled(true);
    }
    if (isDirty) {
      setDisabled(false);
    }
  }, [isDirty]);

  const submitProfileForm = (data: FormState): void => {
    if (!isValid || !isDirty) {
      setDisabled(true);
      return;
    }
    if (user && data) {
      delete data.email;
      dispatch(updateUserData({
        ...user,
        ...data,
      }));

      if (user && data.avatar !== user.avatarSrc) {
        dispatch(uploadUserAvatar({
          file: data.avatar as File,
          userId: user.id,
        }));
      }
      if (user && !data.avatar && data.avatar !== user.avatarSrc) {
        dispatch(updateUserData({
          ...user,
          avatarSrc: '',
        }));
      }
      if (user && data.avatar && data.avatar !== user.avatarSrc) {
        dispatch(uploadUserAvatar({
          file: data.avatar as File,
          userId: user.id,
        }));
      }
    }
  };

  return {
    submitProfileForm,
    profileFormMethods,
    disabled,
  };
};
