import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { setTitleEditingTaskId } from '../state/tasksSlice';
import { ETaskStatus } from '../../../../shared/TaskDTO';
import { AppInputWithLimitCounter } from '../../../shared/AppInput/AppInputWithLimitCounter';
import { AppInputVariant } from '../../../shared/AppInput/AppInput';

interface IAddTaskProps {
  onSave: (title: string) => void;
  title: string;
  isEditable?: boolean;
  isMilestone?: boolean;
  fontWeight?: number;
  maxRows?: number;
  placeholder?: string;
  taskStatus?: ETaskStatus
  variant?: AppInputVariant
}

const StyledBox = styled(Box)(() => ({
  width: '100%',
  maxHeight: '250px',
  minHeight: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingHeight: '5px',
  paddingRight: '15px',
  cursor: 'pointer',
}));

export const TaskTitleWithEditor = ({
  onSave,
  title,
  isEditable,
  isMilestone,
  fontWeight = 500,
  maxRows = 5,
  placeholder,
  taskStatus,
  variant = 'lite',
}: IAddTaskProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleSave = (value: string) => {
    onSave(value);
    dispatch(setTitleEditingTaskId(''));
  };

  return (
    <StyledBox
      id="milestone-title-form"
      sx={{
        padding: isMilestone ? '10px 0 4px 0' : '0px',
        alignSelf: isMilestone ? '' : 'flex-start',
        flex: 1,
        pointerEvents: isEditable ? 'all' : 'none',
      }}
    >
      <AppInputWithLimitCounter
        placeholder={placeholder}
        maxRows={maxRows}
        disabled={!isEditable}
        variant={variant}
        title={title}
        onSave={handleSave}
        fontWeight={fontWeight}
        inputGrowsWithText
        sx={{
          maxWidth: '600px',
          textDecoration: taskStatus === ETaskStatus.COMPLETE ? 'line-through' : '',
        }}
      />
    </StyledBox>
  );
};
