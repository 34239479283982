import { BubbleMenu, useCurrentEditor } from '@tiptap/react';
import { useState } from 'react';
import { Box } from '@mui/material';
import { AppMobileBottomDrawer, MobileBottomDrawerPuller, PullerWrapper } from '../MobileSwipeableDrawer/Styles/MobileBottomDrawerStyles';
import TiptapNodeMenuItems from './CreateNodeMenu/TiptapNodeMenuItems';
import useTiptapBlockMenu from './Hooks/useTiptapBlockMenu';
import { useAppDispatch } from '../../hooks/stateHooks';
import { EFeedTab, setFeedTab } from '../DealFeed/lib/feedSlice';
import TiptabToolbarItems from './TiptapToolbarItems';
import TiptapToolbarItemsWrapper from './TiptapToolbarItemsWrapper';
import TiptapButtonNodeToolbarItems from './TiptapButtonNodeToolbarItems';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { Add20px } from '../../icons/Add';
import { AppButton } from '../../shared/AppButton/AppButton';
import { CloseIcon20 } from '../../icons/CloseIcon';
import TrashIcon from '../../icons/TrashIcon';

const TiptapMobileToolbar = ({
  show,
  setShow,
}: {
  show: boolean,
  setShow: (x: boolean) => void
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { editor } = useCurrentEditor();
  const [open, setOpen] = useState<boolean>(false);

  const { insertParagraph } = useTiptapBlockMenu({ editor, position: editor?.state.selection.$anchor.pos });

  const handleClick = () => {
    if (editor) {
      if (!['heading', 'paragraph'].includes(editor.state.selection.$anchor.parent.type.name)) {
        insertParagraph(editor.state.selection.$anchor.pos + 1, true);
      } else if (editor.state.selection.$anchor.parent.textContent) {
        const list = editor.state.selection.$anchor.path.find(
          (node: any) => node.type && ['bulletList', 'orderedList'].includes(node.type.name),
        );
        if (list) {
          const node = editor.$node(list.type.name, { blockId: list.attrs.blockId });
          if (node) {
            insertParagraph(node.to - 1, true);
          }
        } else {
          insertParagraph(editor.$pos(editor.state.selection.$anchor.pos).to, true);
        }
      }
    }
  };

  const onDelete = () => {
    if (!['heading', 'paragraph'].includes(editor?.state?.selection?.$anchor?.parent?.type?.name)) {
      editor?.commands.deleteRange({ from: editor?.state?.selection?.from, to: editor?.state?.selection?.to });
    }
  };

  return (
    <>
      <BubbleMenu
        shouldShow={(e) => e.editor.isEditable}
      >
        <Box
          sx={{
            display: show ? 'flex' : 'none',
            height: '40px',
            justifyContent: 'center',
          }}
        >
          <TiptapToolbarItemsWrapper>
            <AppIconButton
              onClick={() => {
                handleClick();
                setOpen(true);
              }}
              icon={<Add20px />}
            />
            {
            editor
            && editor?.state?.selection?.node?.type?.name === 'agenda' && (
              <AppButton
                onClick={() => dispatch(setFeedTab(EFeedTab.AGENDA))}
                sx={{
                  fintWeight: 600,
                }}
              >
                Open
              </AppButton>
            )
          }
            {
            editor
            && (
              editor?.state?.selection?.$anchor?.parent?.type?.name === 'paragraph'
              || editor?.state?.selection?.$anchor?.parent?.type?.name === 'heading'
            )
            && (
              <TiptabToolbarItems />
            )
          }
            {
            editor
            && editor?.state?.selection?.node?.type?.name === 'buttonNode'
            && (
              <TiptapButtonNodeToolbarItems />
            )
            }
            {
            editor
            && (
              !(
                editor?.state?.selection?.$anchor?.parent?.type?.name === 'paragraph'
                  || editor?.state?.selection?.$anchor?.parent?.type?.name === 'heading'
              )
            )
            && (
              <AppIconButton
                onClick={onDelete}
                icon={<TrashIcon />}
                variant="danger-transparent"
              />
            )
          }

          </TiptapToolbarItemsWrapper>
          <Box
            onClick={() => setShow(false)}
            sx={{
              background: '#F8FAFC',
              padding: '4px',
              width: '36px',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0px 10px 10px 0px',
              // eslint-disable-next-line max-len
              boxShadow: '-3px 0px 2px 0px rgba(30, 41, 59, 0.04), -1px 0px 1px 0px rgba(30, 41, 59, 0.07), 0px 0px 1px 0px rgba(30, 41, 59, 0.08), 0px 0px 0px 0px rgba(0, 113, 190, 0.08)',
            }}
          >
            <CloseIcon20 />
          </Box>
        </Box>
      </BubbleMenu>
      <AppMobileBottomDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {}}
        swipeAreaWidth={56}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true,
        }}
      >
        <PullerWrapper>
          <MobileBottomDrawerPuller />
        </PullerWrapper>
        <TiptapNodeMenuItems
          editor={editor}
          position={editor?.state.selection.$anchor.pos}
          onClose={() => setOpen(false)}
        />
      </AppMobileBottomDrawer>
    </>
  );
};

export default TiptapMobileToolbar;
