import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReplaceIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    sx={{
      width: '20px',
      height: '20px',
    }}
    {...props}
  >
    <path
      d="M0.875 3.375V8.375H5.875"
      fill="none"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.125 16.625V11.625H14.125"
      fill="none"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.125 7.5C15.75 3.625 11.375 1.5 7.5
      2.875C6.5 3.25 5.5 3.875 4.75 4.75L0.875 8.375"
      fill="none"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.125 11.625L15.25 15.25C12.375 18.125 7.625
      18.125 4.625 15.25C3.875 14.5 3.25 13.5 2.875 12.5"
      fill="none"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
