import { SvgIcon, SvgIconProps } from '@mui/material';

export const BuildingsIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M18.2117 17.3093H3.6416C3.08932 17.3093 2.6416
      16.8616 2.6416 16.3093V6.76946C2.6416 6.39343 2.85255
      6.04918 3.1876 5.87846L8.00503 3.42379C8.67035 3.08478
      9.45903 3.56808 9.45903 4.31479V6.15667"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M9.44458 17.2146V9.19524C9.44458 8.64295 9.8923 8.19524
      10.4446 8.19524H15.0041C15.5564 8.19524 16.0041 8.64296 16.0041
      9.19524V17.2146"
      fill="none"
      strokeWidth="1.6"
    />
    <path
      d="M5.0163 14.1944H6.6736"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M11.8958 11.2711H13.5531"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M11.8958 14.1944H13.5531"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M5.0163 11.5013H6.6736"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M5.0163 8.7852H6.6736"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </SvgIcon>
);
