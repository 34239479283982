import { SvgIcon, Box } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import DOC from '../../icons/Documents/DOC.svg';
import CSV from '../../icons/Documents/CSV.svg';
import FILE from '../../icons/Documents/FILE.svg';
import JPG from '../../icons/Documents/JPG.svg';
import PDF from '../../icons/Documents/PDF.svg';
import TXT from '../../icons/Documents/TXT.svg';
import XSL from '../../icons/Documents/XSL.svg';
import ZIP from '../../icons/Documents/ZIP.svg';

type Props = {
  ext?: string
  size?: number
};

export const DocumentIcon = ({
  ext,
  size = 20,
}: Props) => {
  const Icon = useMemo<ReactNode>(() => {
    switch (ext) {
      case 'csv': return CSV;
      case 'doc': return DOC;
      case 'jpg': return JPG;
      case 'pdf': return PDF;
      case 'txt': return TXT;
      case 'xsl': return XSL;
      case 'zip': return ZIP;
      default: return FILE;
    }
  }, [ext]);

  return (
    <Box
      className="docIconContainer"
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
      }}
    >
      <SvgIcon
        component={Icon}
      />
    </Box>
  );
};
