import { TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { stagesetColors } from '../../theme/stagesetPalette';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { updateSelectedTemplateData } from '../../pages/templates/lib/templatesSlice';
import { ProcessStageDTO } from '../../../shared/Field/Field';
import { selectCurrentStage } from '../ProcessFields/lib/processItemsSlice';
import useProcessField from '../../hooks/useProcessField';

const RoomToolbarTemplateHeader = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const selectedTemplateData = useAppSelector(selectCurrentStage);
  const { onFieldUpdate } = useProcessField<ProcessStageDTO>({ id: selectedTemplateData?.id });
  const [titleForDisplay, setTitleForDisplay] = useState<string>(selectedTemplateData?.title || '');

  useEffect(() => {
    if (inputRef?.current && selectedTemplateData && selectedTemplateData.title) {
      inputRef.current.value = selectedTemplateData?.title;
      setTitleForDisplay(selectedTemplateData?.title);
    }
  }, [selectedTemplateData]);

  const onTitleUpdate = (newTitle: string) => {
    onFieldUpdate({ title: newTitle });
    dispatch(updateSelectedTemplateData({
      title: newTitle,
    }));
  };

  const handleSave = () => {
    if (inputRef.current && inputRef.current.value !== selectedTemplateData?.title && onTitleUpdate) {
      setTitleForDisplay(inputRef.current.value);
      onTitleUpdate(inputRef.current.value);
    }
  };

  return (
    <TextField
      sx={{
        width: '100%',
      }}
      multiline
      maxRows={2}
      variant="standard"
      size="small"
      fullWidth
      defaultValue={titleForDisplay || 'New Section'}
      InputProps={{
        disableUnderline: true,
        sx: {
          borderRadius: '4px',
          paddingX: '8px',
          paddingY: '4px',
          '&:hover': {
            background: stagesetColors.newGrey[500],
          },
          '&.Mui-focused': {
            background: `${stagesetColors.newGrey[400]} !important`,
            outline: `1 px solid${stagesetColors.newGrey[700]}`,
          },
        },
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ref: inputRef,
        onKeyDown: (e) => {
          if (e.nativeEvent.key === 'Enter') {
            inputRef.current?.blur();
          }
        },
        onBlur: handleSave,
        sx: {
          color: stagesetColors.white[100],
          padding: '0px',
          height: '32px',
          fontSize: '18px',
          fontWeight: 600,
        },
      }}
    />
  );
};

export default RoomToolbarTemplateHeader;
