import { AppBar, Box, styled } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const MobileBottomMenuAppbar = styled(AppBar)(() => ({
  top: 'auto',
  bottom: 0,
  backgroundColor: stagesetColors.white[100],
  boxShadow: '0px 0px 0px 0px rgba(12, 16, 23, 0.8), 0px 0px 1px 0px rgba(12, 16, 23, 0.8)',
  borderRadius: '10px 10px 0 0',
  zIndex: 100,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '48px',
  height: '48px',
  padding: '8px',
}));

export const ButtonPlaceholderLarge = styled(Box)(() => ({
  width: '40px',
}));
