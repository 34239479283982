import { Box, CircularProgress } from '@mui/material';
import { SocialAuthQuery } from '../../../../shared/schema/auth.shcema';
import { SessionStorageWrapper } from '../../../common/sessionStorageWrapper';
import { config } from '../../../core';

const SocialAuthRedirect = (): JSX.Element => {
  const googleQuery: string = window.location.search;
  const sessionStorageWrapper: SessionStorageWrapper = new SessionStorageWrapper();
  const socialAuthQuery: SocialAuthQuery = sessionStorageWrapper.getSocialAuthQuery();

  if (googleQuery && socialAuthQuery && socialAuthQuery.provider) {
    const url: URL = new URL(`${config.api.origin}/${socialAuthQuery.provider}/redirect${googleQuery}`);
    for (const key in socialAuthQuery) {
      // @ts-ignore
      url.searchParams.append(key, socialAuthQuery[key]);
    }
    sessionStorageWrapper.clearSocialAuthQuery();
    window.location.href = url.toString();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default SocialAuthRedirect;
