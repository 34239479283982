import {
  Box, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect } from 'react';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppInput } from '../../AppInput/AppInput';
import { AppLinkButton } from '../../AppButton/AppLinkButton';
import { AppButton } from '../../AppButton/AppButton';
import { AppIconButton } from '../../AppButton/AppIconButton';

type FormState = {
  link: string;
};

export type Props = {
  onGoBack: (tab?: number) => void;
  onClose: () => void;
  onCreate: (state: FormState) => void;
  link: string;
};

export const LinkTab = ({
  onGoBack, onClose, onCreate, link,
}: Props) => {
  const schema = yup.object({
    link: yup.string().required('Name is required'),
  }).required();

  const {
    handleSubmit,
    control,
    setValue,
  } = useForm<FormState>({
    defaultValues: {
      link,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('link', link);
  }, [link]);

  const onFormSubmit = async (data: FormState) => {
    onCreate(data);
    onClose();
  };

  return (
    <Box
      className="mobile"
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: '16px 16px 8px 16px',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '20px',
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
          >

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              >
                <AppIconButton
                  icon={<ChevronLeft />}
                  onClick={() => onGoBack(1)}
                />
                <Typography sx={{
                  fontFamily: 'Manrope',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: (theme) => theme.palette.grey[600],
                  maxWidth: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                >
                  Hyperlink
                </Typography>
              </Box>
            </Box>

            <Box sx={{
              display: 'flex',
            }}
            >
              <AppLinkButton
                onClick={onClose}
                sx={{
                  paddingRight: '0px !important',
                  minWidth: '100% !important',
                  width: '100% !important',
                }}
              >
                Cancel
              </AppLinkButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        >
          <Controller
            name="link"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                label="Link"
                value={value}
                defaultValue={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
      </Box>

      <AppButton
        variant="primary"
        size="l"
        type="submit"
        fullWidth
      >
        Save
      </AppButton>
    </Box>
  );
};
