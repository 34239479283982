import { useAppDispatch } from '../../../hooks/stateHooks';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { resetSelectedTemplateData } from '../../../pages/templates/lib/templatesSlice';
import { ChevronLeft20 } from '../../../icons/ChevronLeft20';

const TemplateCloseButton = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(resetSelectedTemplateData());
  return (
    <AppIconButton
      key="close-stage-template-dialog"
      size="l"
      disableRipple
      icon={<ChevronLeft20 />}
      transparentStroke
      onClick={onClose}
    />
  );
};

export default TemplateCloseButton;
