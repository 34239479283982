import {
  SyntheticEvent, useEffect, useMemo, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useAppSelector } from '../../hooks/stateHooks';
import { EMenuTabs, selectCurrentMenuTab, selectDealViewMode } from './lib/fieldsLayoutSlice';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import EditIcon from '../../icons/Edit16.svg';
import EyeIcon from '../../icons/Eye16.svg';
import CPU from '../../icons/CPU.svg';
import { LineChartIcon20 } from '../../icons/LineChartIcon';
import { PenIcon20 } from '../../icons/PenIcon';
import { EyeIcon20 } from '../../icons/EyeIcon';
import { selectTemplatesTab, selectTemplateData } from '../../pages/templates/lib/templatesSlice';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';
import { ToggleButtonTab, ToggleButtonTabs } from './Styles/ToggleButtonStyles';

interface Props {
  disableText?: boolean;
  variant?: 'regular' | 'reverse',
  color?: 'light' | 'dark',
}

const EditLayoutToggleButtons = ({
  disableText,
  variant = 'regular',
  color = 'light',
}: Props): JSX.Element => {
  const selectedTemplateData = useAppSelector(selectTemplateData);
  const isLayoutEditActive = useAppSelector(selectLayouEditingStatus);
  const initialState = selectedTemplateData?.type || isLayoutEditActive ? 'editor' : 'preview';
  const [localValue, setLocalValue] = useState<'editor' | 'preview' | 'internal'>(initialState);
  const currentTab = useAppSelector(selectCurrentMenuTab);
  const currentLibraryTab = useAppSelector(selectTemplatesTab);
  const appState = useAppSelector(selectDealViewMode);

  const getControls = useMemo(() => {
    switch (currentTab === EMenuTabs.ROOMS) {
      case true:
        return [
          {
            title: disableText ? '' : 'Edit', icon: isMobile ? <PenIcon20 /> : <EditIcon />, value: 'editor',
          },
          {
            title: disableText ? '' : 'Preview', icon: isMobile ? <EyeIcon20 /> : <EyeIcon />, value: 'preview',
          },
        ];
      case false:
        return [
          {
            title: disableText ? '' : 'Edit', icon: isMobile ? <PenIcon20 /> : <EditIcon />, value: 'editor',
          },
          {
            title: disableText ? '' : 'Preview', icon: isMobile ? <EyeIcon20 /> : <EyeIcon />, value: 'preview',
          },
        ];
      default:
        return [
          {
            title: disableText ? '' : 'Edit', icon: isMobile ? <PenIcon20 /> : <EditIcon />, value: 'editor',
          },
          {
            title: disableText ? '' : 'Preview', icon: isMobile ? <EyeIcon20 /> : <EyeIcon />, value: 'preview',
          },
        ];
    }
  }, [variant, currentTab, currentLibraryTab]);

  const handleChange = (event: SyntheticEvent, newValue: 'editor' | 'preview' | 'internal') => {
    setLocalValue(newValue);
  };

  const dispatchChangeLayoutState = () => {
    document.dispatchEvent(new CustomEvent(ECustomEventsNames.CHANGE_LAYOUT_STATE, { detail: localValue }));
  };

  const controlButtons = useMemo(() => getControls.map((item) => (
    <ToggleButtonTab
      className={classNames(
        { 'toggle-button-tab-desktop-dark': (color === 'dark') },
        { 'toggle-button-tab-desktop-light': (color === 'light') },
        { 'toggle-button-tab-desktop': (!isMobile) },
        { 'toggle-button-tab-mobile': (isMobile) },
      )}
      disableRipple
      key={item.value}
      icon={item.icon}
      label={disableText ? undefined : item.title}
      value={item.value}
      iconPosition="start"
    />
  )), [appState, currentTab, currentLibraryTab]);

  useEffect(() => {
    dispatchChangeLayoutState();
  }, [localValue]);

  if (!currentTab && !currentLibraryTab) {
    return (
      <AppSkeleton
        animation="wave"
        variant="rectangular"
        width="375px"
        height="48px"
        sx={{
          borderRadius: '4px',
        }}
      />
    );
  }
  return (
    <ToggleButtonTabs
      className={classNames(
        { 'toggle-button-tabs-desktop-dark': (color === 'dark') },
        { 'toggle-button-tabs-desktop-light': (color === 'light') },
        { 'toggle-button-tabs-desktop': (!isMobile) },
        { 'toggle-button-tabs-mobile': (isMobile) },
      )}
      value={appState}
      onChange={handleChange}
    >
      {controlButtons}
    </ToggleButtonTabs>
  );
};

export default EditLayoutToggleButtons;
