import { ProcessStageDTO } from '../../../shared/Field/Field';
import Tiptap from '../Tiptap/Tiptap';
import { FileUploadPrivider } from '../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { StageContentType } from '../../../shared/Stage';
import { ItemContextProvider } from './ItemContextProvider';

interface Props {
  stage: ProcessStageDTO;
}

const StageContent = ({ stage }: Props): JSX.Element | null => {
  if (!stage) {
    return null;
  }

  switch (stage?.stageContentType) {
    case StageContentType.DEFAULT:
      return (
        <ItemContextProvider id={stage.id}>
          <FileUploadPrivider entityId={stage.id}>
            <Tiptap key={stage.id} content={stage.items} offline={stage.template} />
          </FileUploadPrivider>
        </ItemContextProvider>
      );
    case StageContentType.WIDESCREENSTAGE:
      return (
        <ItemContextProvider id={stage.id}>
          <FileUploadPrivider entityId={stage.id}>
            <Tiptap key={stage.id} content={stage.items} wide offline={stage.template} />
          </FileUploadPrivider>
        </ItemContextProvider>
      );
    default:
      return null;
  }
};

export default StageContent;
