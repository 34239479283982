import { SvgIcon, SvgIconProps } from '@mui/material';

export const EraserIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '16px',
      width: '16px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_36684_45228)">
      <path
        d="M10.0385 4.53188L3.58152 10.9888C3.0679 11.5024 3.0679
        12.3352 3.58152 12.8488L4.97331 14.2406L9.68874 14.2406L14.682
        9.17545C15.1956 8.66183 15.1956 7.82909 14.682 7.31547L11.8984
        4.53188C11.3848 4.01826 10.5521 4.01826 10.0385 4.53188Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.3477 17.1141L18.6132 17.1141"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_36684_45228">
        <rect width="20" height="20" transform="translate(0.78125 0.868652)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
