import { SvgIcon, SvgIconProps } from '@mui/material';

export const LineChartIcon20 = ({ stroke, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      fill="none"
      d="M18.8337 10H15.5003L13.0003 17.5L8.00033 2.5L5.50033 10H2.16699"
      stroke={stroke ?? 'black'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

  </SvgIcon>
);
