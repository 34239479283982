import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrganizationDTO, OrganizationNameAndLogoDTO } from '../../../../shared/OrganizationDTO';
import { RoleDTO, TAllPermissions } from '../../../../shared/permissions';
import { DeactivateUserRequest, IAddUserToOrganizationRequest, UpdateOrganizationOwnerRequest } from '../../../../shared/user-organization';
import {
  OrganizationUserDTO, TUpdateUserDataRequest, UserDTO, UserTourStatus,
} from '../../../../shared/UserDTO';
import { FileStorageApi, IUploadFilesFormData } from '../../../api/FileStorageApi';
import { IUpdateOrgFormData, OrganizationApi } from '../../../api/OrganizationApi';
import { UserApi } from '../../../api/UserApi';
import { UserOrganizationApi } from '../../../api/UserOrganizationApi';
import { EStateStatus, getInitialBaseState, IStateBase } from '../../commonTypes';
import { RootState } from '../store';
import { ILoginResponse } from '../../../../shared/schema/token.schema';
import { SharedLocalStorage } from '../../helpers/SharedLocalStorage';
import { FilterSet } from '../../../../shared/process/ProcessFilter';

const userOrganizationApi = new UserOrganizationApi();
const userApi = new UserApi();
const fileStorageApi = new FileStorageApi();
const organizationApi = new OrganizationApi();

export interface IAppState extends IStateBase {
  user: OrganizationUserDTO | null;
  organization: OrganizationDTO | null;
  organizationSignup: OrganizationNameAndLogoDTO | null;
  permissions: TAllPermissions | null;
  users: OrganizationUserDTO[] | null;
  roles: RoleDTO[] | null;
  invites: any[] | null;
  userOrganizationDataLoadingStatus: EStateStatus;
  domains?: string[];
}

export enum EAppStateActions {
  GET_USER_ORGANIZATION_DATA = 'GET_USER_ORGANIZATION_DATA',
  GET_USER_USER_DATA = 'GET_USER_USER_DATA',
  GET_ORGANIZATION_DATA_BY_HASH = 'GET_ORGANIZATION_DATA_BY_HASH',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR',
  ORGANIZATION_ADD_USER = 'ORGANIZATION_ADD_USER',
  ORGANIZATION_DEACTIVATE_USER = 'ORGANIZATION_DEACTIVATE_USER',
  ORGANIZATION_GET_SUBSCRIPTION = 'ORGANIZATION_GET_SUBSCRIPTION',
  ORGANIZATION_UPDATE_USER = 'ORGANIZATION_UPDATE_USER',
  ORGANIZATION_UPDATE_NAME = 'ORGANIZATION_UPDATE_NAME',
  ORGANIZATION_UPLOAD_LOGO = 'ORGANIZATION_UPLOAD_LOGO',
  ORGANIZATION_UPLOAD_WIDE_LOGO = 'ORGANIZATION_UPLOAD_WIDE_LOGO',
  ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE',
  ORGANIZATION_TRANSFER = 'ORGANIZATION_TRANSFER',
  DECLINE_TRANSFER = 'DECLINE_TRANSFER',
  GET_DOMAIN = 'GET_DOMAIN',
  REDIRECT_ON_ORGANIZATION_UPDATE = 'REDIRECT_ON_ORGANIZATION_UPDATE',
  UPDATE_USER_FILTER = 'UPDATE_USER_FILTER',
  GET_USER_USER_FILTER = 'GET_USER_USER_FILTER',
  ORGANIZATION_UPDATE_OWNER = 'ORGANIZATION_UPDATE_OWNER',
}

export const appReducerName = 'app';

const initialState: IAppState = {
  ...getInitialBaseState(),
  user: null,
  organization: null,
  organizationSignup: null,
  permissions: null,
  users: null,
  roles: null,
  invites: null,
  userOrganizationDataLoadingStatus: EStateStatus.IDLE,
};

export const getUserOrganization = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.GET_USER_ORGANIZATION_DATA}`,
  async () => {
    const result = await userOrganizationApi.getUserOrganizationData();
    if (result.newTokenData) {
      // localStorage.setItem('token', result.newTokenData.access_token);
      // localStorage.setItem('tokenType', result.newTokenData.tokenType);
      SharedLocalStorage.setItem('token', result.newTokenData.access_token);
      SharedLocalStorage.setItem('tokenType', result.newTokenData.tokenType);
      window.location.reload();
    }
    return result;
  },
);

export const organizationAddUser = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_ADD_USER}`,
  async (payload: IAddUserToOrganizationRequest) => userOrganizationApi.organizationAddUser(payload),
);

export const organizationDeactivateUser = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_DEACTIVATE_USER}`,
  async (payload: DeactivateUserRequest) => userOrganizationApi.organizationDeactivateUser(payload),
);

export const getOrganizationNameAndLogoByHash = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.GET_ORGANIZATION_DATA_BY_HASH}`,
  async (hash: string | null) => organizationApi.getOrganizationNameAndLogoByHash(hash),
);

export const getUserData = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.GET_USER_USER_DATA}`,
  async () => userApi.getUserData(),
);

export const getUserFilter = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.GET_USER_USER_FILTER}`,
  async () => userApi.getUserData(),
);

export const updateUserData = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.UPDATE_USER_DATA}`,
  async (payload: TUpdateUserDataRequest) => userApi.updateUserData(payload),
);

export const uploadUserAvatar = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.UPLOAD_USER_AVATAR}`,
  async (payload: IUploadFilesFormData) => fileStorageApi.uploadAvatar(payload),
);

export const organizationGetSubscription = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_GET_SUBSCRIPTION}`,
  async () => userOrganizationApi.getSubscription(),
);

export const updateOrganizationUser = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPDATE_USER}`,
  async (payload: OrganizationUserDTO) => userOrganizationApi.updateUser(payload),
);

export const updateOrganizationOwner = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPDATE_OWNER}`,
  async (payload: UpdateOrganizationOwnerRequest) => userOrganizationApi.updateOrganizationOwner(payload),
);

export const transferUserToNewOrganization = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_TRANSFER}`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const result: ILoginResponse = await userOrganizationApi.transferUserToNewOrganization(payload);
      if (result) {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('tokenType', result.tokenType);
        window.location.reload();
      }
      return result;
    } catch (e) {
      return rejectWithValue(e.data);
    }
  },

);

export const declineOrganizationInvite = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.DECLINE_TRANSFER}`,
  async (payload: string) => userOrganizationApi.declineOrganizationInvite(payload),
);

export const updateOrganizationName = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPDATE_NAME}`,
  async (payload: OrganizationDTO) => organizationApi.updateOrganization(payload),
);

export const updateOrganization = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPDATE}`,
  async (payload: Partial<OrganizationDTO>) => organizationApi.updateOrganization(payload),
);

export const uploadTeamLogo = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPLOAD_LOGO}`,
  async (payload: IUploadFilesFormData) => fileStorageApi.uploadTeamLogo(payload),
);

export const uploadTeamWideLogo = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPLOAD_WIDE_LOGO}`,
  async (payload: IUploadFilesFormData) => fileStorageApi.uploadTeamWideLogo(payload),
);

export const updateTeamLogo = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.ORGANIZATION_UPDATE}`,
  async (payload: IUpdateOrgFormData) => organizationApi.updateOrganizationLogo(payload),
);

export const redirectAfterOrgUpdate = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.REDIRECT_ON_ORGANIZATION_UPDATE}`,
  async (payload: Partial<OrganizationDTO>) => payload,
);

export const updateUserFilter = createAsyncThunk(
  `${appReducerName}/${EAppStateActions.UPDATE_USER_FILTER}`,
  async (payload: Partial<FilterSet>) => userApi.saveUserLastFilter(payload),
);

export const appSlice = createSlice({
  name: appReducerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserOrganization.pending, (state) => {
      state.userOrganizationDataLoadingStatus = EStateStatus.LOADING;
    });
    builder.addCase(getUserOrganization.fulfilled, (state, action) => {
      const {
        user,
        users,
        organization,
        permissions,
        roles,
        invites,
      } = action.payload;
      state.user = user;
      state.permissions = permissions;
      state.organization = organization;
      state.users = users;
      state.invites = invites;
      state.roles = roles;
      state.userOrganizationDataLoadingStatus = EStateStatus.IDLE;
    });
    builder.addCase(getUserOrganization.rejected, (state) => {
      state.userOrganizationDataLoadingStatus = EStateStatus.ERROR;
    });
    builder.addCase(updateUserData.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(getOrganizationNameAndLogoByHash.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(getOrganizationNameAndLogoByHash.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      const {
        name,
        organizationLogo,
        role,
      } = action.payload;
      state.organizationSignup = {
        name,
        organizationLogo,
        role,
      };
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getOrganizationNameAndLogoByHash.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getUserData.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      // @ts-ignore
      state.user = action.payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(transferUserToNewOrganization.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(transferUserToNewOrganization.fulfilled, (state) => {
      state.status = EStateStatus.IDLE;
      state.invites = null;
    });
    builder.addCase(transferUserToNewOrganization.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(declineOrganizationInvite.pending, (state, action) => {
      state.status = EStateStatus.LOADING;
      state.invites = state?.invites?.filter((invite) => invite.id !== action.payload) ?? null;
    });
    builder.addCase(declineOrganizationInvite.fulfilled, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(declineOrganizationInvite.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateUserData.fulfilled, (state, { payload }) => {
      // @ts-ignore
      state.user = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateUserData.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(uploadUserAvatar.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(uploadUserAvatar.fulfilled, (state, { payload }) => {
      // @ts-ignore
      state.user = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(uploadUserAvatar.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(organizationAddUser.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(organizationAddUser.fulfilled, (state, { payload }) => {
      // @ts-ignore
      state.users = [...state.users, payload];
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(organizationAddUser.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(organizationDeactivateUser.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(organizationDeactivateUser.fulfilled, (state, { payload }) => {
      state.users = [...state.users].filter((user: UserDTO) => user.id !== payload);
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(organizationDeactivateUser.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(organizationGetSubscription.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(organizationGetSubscription.fulfilled, (state, { payload }) => {
      // @ts-ignore
      state.subscription = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(organizationGetSubscription.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });

    builder.addCase(updateOrganizationUser.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(updateOrganizationUser.fulfilled, (state, { payload }) => {
      state.users = state.users?.map((user: OrganizationUserDTO) => ((user.id !== payload.id) ? user : payload));
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateOrganizationUser.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateOrganizationOwner.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(updateOrganizationOwner.fulfilled, (state, { payload }) => {
      state.users = state.users?.map((user: OrganizationUserDTO) => ((user.id !== payload.newOwner?.id) ? user : payload.newOwner));
      state.users = state.users?.map((user: OrganizationUserDTO) => ((user.id !== payload.oldOwner?.id) ? user : payload.oldOwner));
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateOrganizationOwner.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });

    builder.addCase(updateOrganizationName.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(updateOrganizationName.fulfilled, (state, { payload }) => {
      state.organization = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateOrganizationName.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });

    builder.addCase(uploadTeamLogo.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(uploadTeamLogo.fulfilled, (state, { payload }) => {
      state.organization = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(uploadTeamLogo.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });

    builder.addCase(uploadTeamWideLogo.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(uploadTeamWideLogo.fulfilled, (state, { payload }) => {
      state.organization = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(uploadTeamWideLogo.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });

    builder.addCase(updateTeamLogo.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(updateTeamLogo.fulfilled, (state, { payload }) => {
      state.organization = payload;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(updateTeamLogo.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
  },
});

export const selectUser = (state: RootState): OrganizationUserDTO => state.app?.user;
export const selectUserId = (state: RootState): string | undefined => state.app?.user?.id
  ?? state.auth?.email
  ?? state.auth?.guestId;

export const selectTourStatus = (state: RootState, tourKey: keyof UserTourStatus): boolean => !!state.app?.user?.tourStatus?.[tourKey];

export const selectEventProducerId = (state: RootState): string | undefined => state.app?.user?.id
  ?? state.auth?.email
  ?? state.auth?.guestId;

export const selectOrganization = (state: RootState): OrganizationDTO => state.app?.organization;
export const selectOrganizationId = (state: RootState): string | undefined => state.app?.organization?.id;
export const selectOrganizationLogo = (state: RootState): string => state.app?.organization?.organizationLogo ?? '';
export const selectOrganizationWideLogo = (state: RootState): string => state.app?.organization?.wideLogo ?? '';
export const selectOrganizationName = (state: RootState): string => state.app?.organization?.name ?? '';
export const selectUserAvatar = (state: RootState) => state.app?.user?.avatarSrc;
export const selectIsTeammate = (state: RootState, id:string) => !!state?.app?.users?.find((user) => user.id === id);
export const selectTeammatesIds = (state: RootState): string[] => state?.app?.users?.map((user) => user.id);
// eslint-disable-next-line max-len
export const selectOrganizationSignup = (state: RootState): OrganizationNameAndLogoDTO => state.app?.organizationSignup ?? { name: '', organizationLogo: '', role: '' };
export const selectTeammatesIdsMap = (state: RootState): Map<string, string> => {
  const idsMap = new Map();

  state?.app?.users?.forEach((user) => idsMap.set(user.id, user.id));

  return idsMap;
};
export const selectIsUserAdmin = (state: RootState) => !!state?.app?.user?.isAdmin;
export const selectOrganizationCurrency = (state: RootState): string => state.app?.organization?.currency;
