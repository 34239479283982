import {
  Box,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkIcon from '../../../icons/link20.svg';
import DocumentIcon from '../../../icons/DocIcon.svg';
import { EFileType } from '../../../../shared/library/Library';
import { AppInput } from '../../../shared/AppInput/AppInput';
import SearchActive from '../../../icons/SearchActive.svg';
import { LibraryFileDTO } from '../../../../shared/library/LibraryDTO';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { setLibraryFileToAdd } from '../../BlockContentSection/lib/sectionsSlice';
import { ENewPageTabs, setNewPageTab } from '../../Layout/lib/fieldsLayoutSlice';
import { useGetLibraryFilesQuery } from '../../../pages/library/lib/libraryRTKApi';
import { ContentType } from '../../../../shared/Stage';
import { DocumentLinkIcon } from '../../DocumentLink/DocumentLinkIcon';

type Props = {
  libraryFileType: EFileType;
  linkType?: ContentType,
};

const NewPageMediaLibraryList = ({
  libraryFileType,
  linkType,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const [rows, setRows] = useState<LibraryFileDTO[]>([]);
  const { t } = useTranslation();
  const { data: files, refetch, isUninitialized } = useGetLibraryFilesQuery(
    {
      types: [libraryFileType],
      // eslint-disable-next-line no-nested-ternary
      ...(libraryFileType === EFileType.LINK && linkType
        ? (linkType !== ContentType.URL ? { linkTypes: [linkType] } : {})
        : {}),
    },
  );

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, []);

  useEffect(() => {
    let tempRows: LibraryFileDTO[] = files || [];

    if (searchFilter) {
      tempRows = tempRows.filter((x: Partial<LibraryFileDTO>) => (
        !!x.name && x.name.toUpperCase().indexOf(searchFilter.toUpperCase()) >= 0
      ));
    }

    setRows(tempRows);
  }, [searchFilter, files]);

  const fileIcon = (type: EFileType, fileLinkType: ContentType | undefined): JSX.Element | null => {
    switch (type) {
      case EFileType.FILE:
        return (
          <DocumentIcon />
        );
      case EFileType.LINK:
        return (
          <DocumentLinkIcon contentType={fileLinkType} />
        );
      default:
        return null;
    }
  };

  const handleLibraryItemClick = async (item: LibraryFileDTO) => {
    await dispatch(setLibraryFileToAdd(item));
    dispatch(setNewPageTab(ENewPageTabs.LIBRARY_EDIT));
  };

  return (
    <Box
      sx={{
        paddingTop: '20px',
        height: 'calc(100% - 164px)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '5px 22px',
        }}
      >
        <AppInput
          defaultValue={searchFilter}
          type="text"
          value={searchFilter}
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          sx={{
            width: '100%',
          }}
          placeholder={t('Templates_Search')}
          InputProps={{
            sx: {
              borderRadius: '10px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  component={SearchActive}
                  sx={{
                    color: 'transparent',
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          marginBottom: '12px',
          padding: '0 4px',
          height: '100%',
        }}
      >
        <List>
          {
            rows.map((x: LibraryFileDTO) => (
              <ListItemButton
                onClick={() => {
                  handleLibraryItemClick(x);
                }}
                key={x.name}
              >
                <ListItemIcon>
                  {fileIcon(x.type, x.linkType)}
                </ListItemIcon>
                <ListItemText primary={x.name} />
              </ListItemButton>
            ))
          }
        </List>
      </Box>
    </Box>
  );
};

export default NewPageMediaLibraryList;
