export enum ELibraryType {
  FILE = 'FILE',
  PRODUCT = 'PRODUCT',
}

export enum EFileType {
  FILE = 'FILE',
  LINK = 'LINK',
}

export enum EFileEditType {
  None = 'None',
  EditName = 'EditName',
  EditFile = 'EditFile',
}
