import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const greyContrastText = (
  theme: Theme,
  opacity: number,
  variant: 'primary' | 'secondary' = 'primary',
) => alpha(
  theme.palette[variant].contrastText,
  opacity,
);

export const greySidebarContrastText = (
  theme: Theme,
  opacity: number,
) => alpha(
  theme.dealOverrides.sidebar.contrastText,
  opacity,
);
