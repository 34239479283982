import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';

export const DocumentsFieldWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  paddingBottom: '0px',
  flexDirection: 'column',
  '& .MediaFieldMenu': {
    visibility: 'hidden',
  },
  '&:hover': {
    '& .MediaFieldMenu': {
      visibility: 'visible',
    },
  },
  '& .list': {
    gap: '4px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
  '& .list-item': {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '10px',
    alignItems: 'center',
    minHeight: '40px',
    border: '1px solid transparent',
    '&:not(.mobile):hover': {
      background: 'rgba(238, 240, 250, 0.4)',
      border: '1px solid rgba(238, 240, 250, 0.5)',
      '& .list-item-right-block-control': {
        opacity: 1,
        transition: 'opacity 0.2s ease-in-out',
      },
    },
  },
  '& .list-item-right-block': {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    flex: 1,
  },
  '& .list-item-left-block': {
    display: 'flex',
    gap: '14px',
    alignItems: 'center',
    flex: 10,
    overflow: 'hidden',
  },
  '& .list-item-left-block-text': {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    textDecoration: 'none',
    overflow: 'hidden',
    width: '100%',
  },
  '& .list-item-left-block-title': {
    color: theme.palette.grey[600],
    fontWeight: 400,
    fontFamily: 'Inter',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '& .list-item-size-text': {
    color: theme.palette.grey[400],
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  '& .list-item-left-block-icon-container': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .list-item-left-block-description': {
    display: 'flex',
    fontSize: '12px',
    color: theme.palette.error[600],
    '&-hidden': {
      display: 'none',
    },
  },
  '& .list-item-right-block-control': {
    display: 'flex',
    alignItems: 'center',
    '&-hidden': {
      display: 'none',
    },
    opacity: isMobile ? 1 : 0,
  },
  '& .input': {
    display: 'none',
  },
}));
