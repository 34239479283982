import { SxProps, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TemplateVarDropdown } from '../TemplateVarDropdown/TemplateVarDropdown';
import { useAppSelector } from '../../hooks/stateHooks';
import { useTemplateVars } from '../../hooks/useTemplateVars';
import { selectLayouEditingStatus, selectProcessId } from '../../routes-old/process/state/processSlice';
import { selectCurrentStage } from '../ProcessFields/lib/processItemsSlice';
import { useItemUpdate } from '../Layout/ItemContextProvider';

const StagePresentationDescription = (): JSX.Element => {
  const isLayoutEditActive = useAppSelector(selectLayouEditingStatus);
  const currentStage = useAppSelector(selectCurrentStage);
  const { onUpdate } = useItemUpdate(currentStage.id);

  const [textFieldValue, setTextFieldValue] = useState(currentStage?.description ?? '');

  const inputRef = useRef<HTMLInputElement>();

  const {
    anchorTemplatePos, setAnchorTemplatePos, onInsertTemplateVar, lastPos,
  } = useTemplateVars({ input: inputRef, textFieldValue, setTextFieldValue });

  useEffect(() => {
    if (currentStage?.description) {
      setTextFieldValue(currentStage?.description);
    }
  }, [currentStage?.description]);

  useEffect(() => {
    if (!anchorTemplatePos && lastPos) {
      setTimeout(() => {
        inputRef.current?.setSelectionRange(lastPos, lastPos);
        inputRef.current?.focus();
      });
    }
  }, [anchorTemplatePos, lastPos]);

  const descriptionStyles: SxProps = {
    color: '#7084B4',
    fontSize: '16px',
    lineHeight: '26px',
    wordBreak: 'break-word',
    maxHeight: isMobile ? '156px' : undefined,
    overflow: isMobile ? 'auto !important' : undefined,
    whiteSpace: 'break-spaces',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };

  const onStageDescriptionUpdate = (newDescription: string) => {
    if (newDescription !== currentStage?.description) {
      onUpdate({
        description: newDescription,
      });
    }
  };

  if (!isLayoutEditActive) {
    return (
      <Typography
        sx={descriptionStyles}
      >
        {currentStage?.description}
      </Typography>
    );
  }

  return (
    <>
      <TextField
        fullWidth
        multiline
        variant="standard"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        placeholder="Add description"
        InputProps={{
          disableUnderline: true,
          sx: {
            overflow: isMobile ? 'auto' : undefined,
            padding: '0px',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          ref: inputRef,
          onBlur: (e) => onStageDescriptionUpdate(e.target.value),
          onKeyDown: (e) => {
            if (e.nativeEvent.key === 'Enter') {
              inputRef.current!.blur();
            }
          },
          sx: descriptionStyles,
        }}
      />
      <TemplateVarDropdown
        onInsert={onInsertTemplateVar}
        open={!!anchorTemplatePos}
        onClose={() => {
          setAnchorTemplatePos(null);
          setTimeout(() => {
            if (lastPos) inputRef.current?.setSelectionRange(lastPos, lastPos);
            inputRef.current?.focus();
          });
        }}
        anchorReference="anchorPosition"
        anchorPosition={anchorTemplatePos || undefined}
      />
    </>
  );
};

export default StagePresentationDescription;
