import { TextFieldProps, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { AppInput, AppInputSize, AppInputVariant } from './AppInput';
import { TemplateVarDropdown } from '../../features/TemplateVarDropdown/TemplateVarDropdown';
import { useTemplateVars } from '../../hooks/useTemplateVars';

export type AppInputProps = {
  title?: string
  charactersLimit?: number
  size?: AppInputSize
  variant?: AppInputVariant
  disabled?: boolean
  placeholder?: string
  maxRows?: number
  fontWeight?: number
  inputGrowsWithText?: boolean
  onSave: (value: string) => void
} & Omit<TextFieldProps, 'size' | 'variant'>;

export const AppInputWithLimitCounter = ({
  title = 'New Agenda',
  size = 's',
  charactersLimit = 300,
  variant = 'transparent',
  disabled,
  placeholder = '',
  maxRows = 2,
  fontWeight = 500,
  inputGrowsWithText,
  onSave,
  sx,
  ...inputProps
}: AppInputProps) => {
  const inputRef = useRef<HTMLTextAreaElement>();
  const [textFieldValue, setTextFieldValue] = useState<string>(title);
  const [limitCounter, setLimitCounter] = useState<number | undefined>(undefined);
  const {
    setAnchorTemplatePos, anchorTemplatePos, lastPos, onInsertTemplateVar,
  } = useTemplateVars({ input: inputRef, textFieldValue, setTextFieldValue });

  useEffect(() => {
    if (title !== textFieldValue) {
      setTextFieldValue(title);
    }
    if (inputRef.current && inputRef.current.value !== title) {
      inputRef.current.value = title;
    }
  }, [title]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (inputRef.current.value.length >= charactersLimit && inputRef?.current?.value) {
      inputRef.current.value = inputRef.current.value.slice(0, charactersLimit);
      setLimitCounter(0);
    } else if (inputRef.current.value.length >= charactersLimit - 15) {
      setLimitCounter(charactersLimit - inputRef.current.value.length);
    } else {
      setLimitCounter(undefined);
    }
  }, [textFieldValue]);

  const startEditing = () => {
    if (inputRef?.current?.value && inputRef.current.value.length >= (charactersLimit - 15)) {
      setLimitCounter(charactersLimit - inputRef.current.value.length);
    }
  };

  const onSubmit = () => {
    if (inputRef?.current?.value && !disabled) {
      if (title !== inputRef.current.value) {
        onSave(inputRef.current.value.trim());
      }
    }
    setLimitCounter(undefined);
  };

  return (
    <>
      {inputGrowsWithText && (
        <Typography
          sx={{
            paddingLeft: '3px',
            paddingRight: '6px',
            fontWeight,
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            height: '0px',
            overflow: 'hidden',
          }}
        >
          {`${textFieldValue}`}
        </Typography>
      )}
      <AppInput
        {...inputProps}
        fullWidth
        multiline
        maxRows={maxRows}
        size={size}
        disabled={disabled}
        variant={variant}
        placeholder={placeholder}
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        InputProps={{ disableUnderline: true }}
        helperTextPlacement="end"
        error={!!limitCounter}
        helperText={limitCounter?.toString() || ''}
        sx={{ ...sx }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          maxLength: charactersLimit,
          ref: inputRef,
          onKeyDown: (e) => {
            if (e.nativeEvent.key === 'Enter') {
              inputRef.current?.blur();
            }
          },
          onBlur: onSubmit,
          onFocus: startEditing,
        }}
      />
      <TemplateVarDropdown
        onInsert={onInsertTemplateVar}
        open={!!anchorTemplatePos}
        onClose={() => {
          setAnchorTemplatePos(null);
          setTimeout(() => {
            if (lastPos) inputRef.current?.setSelectionRange(lastPos, lastPos);
            inputRef.current?.focus();
          });
        }}
        anchorReference="anchorPosition"
        anchorPosition={anchorTemplatePos || undefined}
      />
    </>
  );
};
