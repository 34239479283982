import { EProcessType } from '../process/ProcessMilestoneActionDTO';
import { ETaskStatus } from '../TaskDTO';
import { ContentType, StageContentType, StageSignature, WideScreenSectionType } from "../Stage";
import { EntityFilesRecord, FielItemsRecord, FileItem } from '../FileItems/FileItems';
import { ObjectBase } from '../Tiptap/TiptapTypes';

export enum EFieldType {
  MILESTONES = 'MILESTONES',
  TEXT_EDITOR = 'TEXT_EDITOR',
  MEDIA = 'MEDIA',
  SECTION = 'SECTION',
  EMPTY = 'EMPTY',
  STAGE = 'STAGE',
  AGENDA = 'AGENDA',
  DOCUMENTS = 'DOCUMENTS',
  SINGLE_LINK = 'SINGLE_LINK',
  PRICING_TABLE = 'PRICING_TABLE',
  PRICE_TABLE_ITEM = 'PRICE_TABLE_ITEM',
  BUTTON = 'BUTTON',
}

export enum EContentDisplayDirection {
  COLUMN = 'COLUMN',
  ROW = 'ROW',
}

export enum EFieldsubType {
  VIDEO = 'VIDEO',
  IMG = 'IMG',
  FILE = 'FILE',
  EMPTY = '',
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  MIXED = 'MIXED',
  MIXED_URL = 'MIXED_URL',
  IFRAME = 'IFRAME',
}

export enum EMediaFieldStorageType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL_S3_PUBLIC = ' INTERNAL_S3_PUBLIC',
}

export interface FieldBase {
  processId: string;
  targetId?: string;
  id: string;
  type: EFieldType;
}

export interface ExtendedFieldBase extends FieldBase {
  stageId?: string;
  parentId?: string;
}

export type ProcessStageDTO = FieldBase & {
  organizationId: string;
  nestedIds: string[];
  template?: boolean;
  restricted?: boolean;
  status: ETaskStatus;
  title?: string;
  lock?: boolean;
  hide?: boolean;
  ownerId?: string;
  updatedAt?: string;
  data?: any;
  activeTasks: number;
  completedTasks: number;
  stageId?: string;
  description?: string;
  stageContentType?: StageContentType;
  wideScreenSectionType?: WideScreenSectionType;
  contentType?: ContentType;
  contentInitiated?: boolean;
  signature: StageSignature;
  producerId?: string;
  items: { [key: string]: any };
  files: { [key: string]: FileItem };
};

export interface FieldSectionDTO extends ExtendedFieldBase {
  type: EFieldType.SECTION;
  parentId: string,
  organizationId?: string,
  contentDisplayDirection: EContentDisplayDirection,
  contentDisplayType: EFieldType,
  stageContentType?: StageContentType;
  nestedIds: string[],
  depth: number,
  title?: string,
  ownerId?: string,
  updatedAt?: string;
  colWidth?: number[];
  items: { [key: string]: any };
  files: { [key: string]: FileItem };
  template?: boolean;
}

export interface AddSectionsData {
  processId: string,
  parentId: string,
  isTemplate?: boolean,
  position: number,
  parentDepth: number,
  count?: number,
  contentDisplayType?: EFieldType,
  contentDisplayDirection?: EContentDisplayDirection,
  childData?: AddsectionChildData,
  producerId?: string,
  organizationId?: string,
  title?: string,
  ownerId?: string,
  stageId?: string,
}

export interface AddBlockToStageData {
  processId: string,
  stageId: string,
  position: number,
  isTemplate?: boolean,
  templateId?: string,
}

export interface EmbedFieldUpdateData {
  newUrl: string,
  newSubType: EFieldsubType,
  name?: string;
}

export interface AddsectionChildData {
  storageType?: EMediaFieldStorageType,
  embedData?: EmbedFieldUpdateData,
  subTupe?: EFieldsubType,
  previewLink?: string
}

export interface DeleteSectionsData {
  processId: string,
  id: string,
  parentId: string,
}

export interface MoveSectionsData {
  processId: string,
  id: string,
  parentId: string,
  position: number,
  modifier: 0 | -1,
  producerId?: string,
}

export interface MediaFieldDTO extends ExtendedFieldBase {
  title?: string;
  description?: string;
  type: EFieldType.MEDIA;
  url: string;
  subType: EFieldsubType;
  storageType: EMediaFieldStorageType;
  width?: number;
  height?: number;
  align?: string;
  adjustBy?: string;
}

export interface UploadedDocument {
  id: string;
  name: string;
  url: string;
  type: EFieldsubType;
  storageType: EMediaFieldStorageType;
  size: number;
  item?: string
}

export interface DocumentsFieldDTO extends ExtendedFieldBase {
  type: EFieldType.DOCUMENTS;
  docs: UploadedDocument[];
}

export interface MediaFieldUpdate extends Partial<MediaFieldDTO> {
  processId: string;
  type: EFieldType.MEDIA;
}

export interface DocumentsFieldUpdate extends Partial<DocumentsFieldDTO> {
  processId: string;
  type: EFieldType.DOCUMENTS;
}

export interface PricingTableFieldUpdate extends Partial<PriceTableFieldDTO> {
  processId: string;
  type: EFieldType.PRICING_TABLE;
}

export interface TextEditorFieldDTO extends ExtendedFieldBase {
  type: EFieldType;
  description: string;
  title: string;
  isSummary: boolean;
  subType?: EFieldsubType;
}

export interface LinkPreviewFieldDTO extends ExtendedFieldBase {
  type: EFieldType.SINGLE_LINK;
  link: string
}


export interface TextFieldUpdate extends Partial<TextEditorFieldDTO> {
  processId: string;
  type: EFieldType.TEXT_EDITOR;
}

export interface LinkPreviewUpdate extends Partial<LinkPreviewFieldDTO> {
  processId: string;
  type: EFieldType.SINGLE_LINK;
}

export interface LinkPreviewData {
  title?: string,
  description?: string,
  image?: string
  error?: string
}

export interface EmbedIframeData {
  html?: string,
  error?: string,
  url?: string,
  rel?: string[],
}

export type LinkPreviewFullDTO = {
  processId: string;
  type: EFieldType.SINGLE_LINK;
} & LinkPreviewFieldDTO & LinkPreviewData

export interface FieldSectionUpdate extends Partial<FieldSectionDTO> {
  processId: string;
  type: EFieldType.SECTION;
}

export interface MilestonesFieldDTO extends ExtendedFieldBase {
  title?: string;
  type: MilestonesFieldType;
  milestonesIds: string[];
}

export interface PriceTableFieldItemDTO extends ExtendedFieldBase {
  type: EFieldType.PRICE_TABLE_ITEM;
}

export interface PriceTableFieldDTO extends ExtendedFieldBase {
  title?: string;
  type: EFieldType.PRICING_TABLE;
  milestonesIds: string[];
  description: string;
}

export type AllFieldsDTO = TextEditorFieldDTO
  | MediaFieldDTO
  | MilestonesFieldDTO
  | FieldSectionDTO
  | ProcessStageDTO
  | LinkPreviewFieldDTO
  | DocumentsFieldDTO
  | PriceTableFieldDTO
  | ButtonFieldDTO
  | TextEditorFieldDTO

export type TStageItem = {
  id: string;
  type: EFieldType;
  nestedIds?: string[];
  docs?: UploadedDocument[];
  link?: string;
  description?: string;
  title?: string;
  isSummary?: boolean;
  subType?: EFieldsubType;
  url?: string;
  storageType?: EMediaFieldStorageType;
  milestonesIds?: string[];
  parentId?: string;
  organizationId?: string;
  contentDisplayDirection?: EContentDisplayDirection;
  contentDisplayType?: EFieldType;
  depth?: number;
  ownerId?: string;
  template?: boolean;
  restricted?: boolean;
  status?: ETaskStatus;
  activeTasks?: number;
  completedTasks?: number;
  colWidth?: number[];
  width?: number;
  height?: number;
  align?: string;
  adjustBy?: string;
  stageContentType?: StageContentType;
  wideScreenSectionType?: WideScreenSectionType;
  lock?: boolean;
  hide?: boolean;
  contentInitiated: boolean;
  signature: StageSignature;
  processId: string;
  stageId: string;
  items: ObjectBase;
  files: FielItemsRecord;
}

export type TCreateProcessItemReq = {
  tempId: string;
  data: TStageItem;
  position: number;
}

export type TUpdateProcessItem = {
  id: string;
  data?: Partial<ProcessStageDTO>;
  files?: FielItemsRecord;
  processId?: string;
}

export type TUploadProcessItemFile = {
  localId?: string,
  localUrl: string,
  name: string,
  type: string,
  file?: FormData,
  entityId: string;
  fileId: string;
}

export interface MilestonesFieldUpdate extends Partial<MilestonesFieldDTO> {
  processId: string;
  type: MilestonesFieldType;
  title?: string;
}

export interface ProcessStageUpdate extends Partial<ProcessStageDTO> {
  processId: string;
  type: EFieldType.STAGE;
  title?: string;
}

export type AllFieldsUpdates = (
  TextFieldUpdate
  | MediaFieldUpdate
  | MilestonesFieldUpdate
  | FieldSectionUpdate
  | ProcessStageUpdate
  | LinkPreviewUpdate
  | DocumentsFieldUpdate
  | PricingTableFieldUpdate
) & {
  type: EFieldType,
  parentId?: string,
}

export type CreateFieldTemplate = {
  type: EFieldType,
  title: string,
  organizationId: string,
  lock?: boolean,
  hide?: boolean;
}

export interface AddStageToProcessData {
  processId: string;
  position: number;
  isTemplate?: boolean;
  title?: string;
  templateId?: string;
  lock?: boolean;
  hide?: boolean;
  stage?: boolean;
  contentType?: ContentType;
  stageContentType?: StageContentType;
  wideScreenSectionType?: WideScreenSectionType;
  items?: ObjectBase;
  files?: ObjectBase;
  client?: string;
  clientName?: string;
}

export interface UpdateStageByTemplate {
  processId: string;
  position: number;
  isTemplate?: boolean;
  title?: string;
  templateId?: string;
  lock?: boolean;
  hide?: boolean;
  stage?: boolean;
  contentType?: ContentType;
  stageContentType?: StageContentType;
  wideScreenSectionType?: WideScreenSectionType;
}

export interface DeleteProcessStageData {
  processId: string;
  stageId: string
}

export type CloneSectionData = {
  toCloneId: string,
  newProcessId: string,
  organizationId: string,
  isTemplate?: boolean,
  parentId?: string,
  toProcessType?: EProcessType,
  client?: string,
  mustacheValuesToParse?: { [key: string]: string },
  ownerId?: string,
  stageId?: string,
  title?: string,
  forTemplate?: boolean,
}

export type MilestonesFieldType = EFieldType.MILESTONES | EFieldType.AGENDA;

export interface ICreateStageData {
  processId: string,
  organizationId: string,
  template: boolean,
  title: string,
  ownerId?: string,
  activeTasks?: number,
  lock?: boolean,
  hide?: boolean;
  description?: string,
  contentType?: ContentType,
  stageContentType?: StageContentType
  wideScreenSectionType?: WideScreenSectionType;
  items?: ObjectBase;
  files?: ObjectBase;
  parseFiles?: boolean;
  fromTemplate?: string;
}

export interface IFieldsToDelete {
  milestones: string[];
  text: string[];
  document: string[];
  linkPrev: string[];
  media: string[];
}

export interface ButtonFieldDTO extends ExtendedFieldBase {
  title?: string;
  type: EFieldType.BUTTON;
  url?: string;
  widthType?: string;
  color?: string;
  align?: string;
}

export interface ButtonFieldUpdateData {
  title?: string;
  url?: string;
  widthType?: string;
  color?: string;
  align?: string;
}
