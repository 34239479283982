import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../../hooks/useOrganization';
import {
  SettingsFormWrapper,
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsTabContentWrapper,
  SettingsTabSubtitle,
  SettingsTabSubtitleWrapper,
  SettingsTabData,
} from './Styles/GeneralTabStyles';
import {
  AvatarFieldWrapper,
  TeammateTitle,
  TeammateTitleWrapper,
} from './Styles/ProfileTabStyles';
import { useOrganizationSettings } from '../../../hooks/useOrganizationSettings';
import {
  CompanyLogoPlaceholderFrame,
  CompanyLogo,
} from './Styles/OrganizationTabStyles';
import { CopyIcon16 } from '../../../icons/CopyIcon';
import { avatarHandler } from './Helper/AvatarHandler';
import { CompanyLogoPlaceholder40 } from '../../../icons/CompanyLogoPlaceholder';
import { AppDashedBorderBox } from '../../../shared/AppDashedBorderBox/AppDashedBorderBox';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';

const TeammateOrganizationTab = () => {
  const { organization } = useOrganization();
  const { handleCopyId } = useOrganizationSettings();
  const { t } = useTranslation();

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Settings_Organization')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Setup your company
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      <SettingsFormWrapper
        className={classNames('settings-column', { 'settings-mobile': isMobile })}
      >
        <AvatarFieldWrapper>
          {organization?.organizationLogo ? (
            <CompanyLogo
              variant="rounded"
              className="organization-logo"
              src={avatarHandler(organization?.organizationLogo) as string}
            />
          ) : (
            <CompanyLogoPlaceholderFrame>
              <CompanyLogoPlaceholder40 />
            </CompanyLogoPlaceholderFrame>
          )}
          <TeammateTitleWrapper>
            <TeammateTitle>
              {organization.name}
            </TeammateTitle>
          </TeammateTitleWrapper>
        </AvatarFieldWrapper>
        <SettingsTabSubtitleWrapper>
          <SettingsTabSubtitle>
            Currency
          </SettingsTabSubtitle>
          <AppDashedBorderBox />
          <SettingsTabData>
            {organization?.currency}
          </SettingsTabData>
        </SettingsTabSubtitleWrapper>
        <SettingsTabSubtitleWrapper>
          <SettingsTabSubtitle>
            Organization ID
          </SettingsTabSubtitle>
          <AppDashedBorderBox />
          <AppLinkButton
            endIcon={<CopyIcon16 />}
            onClick={handleCopyId}
            sx={{
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 400,
            }}
          >
            {organization?.orgSupportId}
          </AppLinkButton>
        </SettingsTabSubtitleWrapper>
      </SettingsFormWrapper>
    </SettingsTabContentWrapper>
  );
};
export default TeammateOrganizationTab;
