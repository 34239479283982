import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DealFilterSetDTO, FilterSet } from '../../../../../shared/process/ProcessFilter';
import { config } from '../../../../core';
import { DealsType } from '../../../../../shared/process/Process';

const baseQuery = fetchBaseQuery({
  baseUrl: new URL(`${config.api.prefix}deal-filter`, config.api.origin).href,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${localStorage.getItem('token') ?? sessionStorage.getItem('token')}`);
    return headers;
  },
});

enum EDealFilterApiTagTypes {
  SAVED_FILTERS = 'SAVED_FILTERS',
}

export const dealFilterApi = createApi({
  reducerPath: 'DEAL_FILTERS',
  baseQuery,
  tagTypes: [
    EDealFilterApiTagTypes.SAVED_FILTERS,
  ],
  endpoints: (builder) => ({
    getSavedFilterSets: builder.query<DealFilterSetDTO[], { grid: DealsType }>({
      query: (arg) => `my/${arg.grid}`,
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({ type: EDealFilterApiTagTypes.SAVED_FILTERS as const, id })),
          { type: EDealFilterApiTagTypes.SAVED_FILTERS, id: 'LIST' },
        ]
        : [{ type: EDealFilterApiTagTypes.SAVED_FILTERS, id: 'LIST' }]),
    }),
    saveFilterSet: builder.mutation<DealFilterSetDTO, FilterSet>({
      query: (payload) => ({ url: 'my', method: 'POST', body: payload }),
      invalidatesTags: [{ type: EDealFilterApiTagTypes.SAVED_FILTERS, id: 'LIST' }],
    }),
    updateFilterSet: builder.mutation<{ ok: true }, { id: string, data: Partial<FilterSet> }>({
      query: (payload) => ({ url: `my/${payload.id}`, method: 'PATCH', body: payload.data }),
      invalidatesTags: [{ type: EDealFilterApiTagTypes.SAVED_FILTERS, id: 'LIST' }],
    }),
    deleteFilterSet: builder.mutation<{ ok: true }, { id: string }>({
      query: (payload) => ({ url: `my/${payload.id}`, method: 'DELETE' }),
      invalidatesTags: [{ type: EDealFilterApiTagTypes.SAVED_FILTERS, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetSavedFilterSetsQuery,
  useSaveFilterSetMutation,
  useUpdateFilterSetMutation,
  useDeleteFilterSetMutation,
} = dealFilterApi;
