import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EStateStatus, getInitialBaseState, IStateBase } from '../commonTypes';
import { CrmApi } from '../../api/CrmApi';

const crmApi: CrmApi = new CrmApi();

export interface PipedriveIntegrationState extends IStateBase {
  integration: any | null;
  isUserHaveIntegration: boolean;
}

export enum EPipedriveIntegrationActions {
  GET_INTEGRATION = 'GET_INTEGRATION',
  CANCEL_INTEGRATION = 'CANCEL_INTEGRATION',
}

export const pipedriveIntegrationReducerName = 'pipedriveIntegration';

const initialState: PipedriveIntegrationState = {
  ...getInitialBaseState(),
  integration: null,
  isUserHaveIntegration: false,
};

export const pipedriveGetIntegration = createAsyncThunk(
  `${pipedriveIntegrationReducerName}/${EPipedriveIntegrationActions.GET_INTEGRATION}`,
  async () => crmApi.getUserIntegration('pipedrive'),
);

export const pipedriveCancelIntegration = createAsyncThunk(
  `${pipedriveIntegrationReducerName}/${EPipedriveIntegrationActions.CANCEL_INTEGRATION}`,
  async () => crmApi.cancelUserIntegration(),
);

export const pipedrive = createSlice({
  name: pipedriveIntegrationReducerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pipedriveGetIntegration.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(pipedriveGetIntegration.fulfilled, (state, { payload }) => {
      state.integration = payload;
      state.isUserHaveIntegration = true;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(pipedriveGetIntegration.rejected, (state) => {
      state.status = EStateStatus.IDLE;
      state.isUserHaveIntegration = false;
    });
    builder.addCase(pipedriveCancelIntegration.pending, (state) => {
      state.status = EStateStatus.LOADING;
    });
    builder.addCase(pipedriveCancelIntegration.fulfilled, (state) => {
      state.integration = null;
      state.isUserHaveIntegration = false;
      state.status = EStateStatus.IDLE;
    });
    builder.addCase(pipedriveCancelIntegration.rejected, (state) => {
      state.status = EStateStatus.IDLE;
    });
  },
});
