import {
  Box, Typography, TypographyProps, styled,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledItemsContainer = styled(Box)(() => ({
  width: '12%',
  padding: '10px',
  textAlign: 'right',
  borderRight: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

export const StyledItemsContainerMobile = styled(Box)(() => ({
  width: '100%',
  padding: '4px 0px',
  textAlign: 'right',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '&:first-of-type': {
    padding: '0 0 12px 0',
    justifyContent: 'flex-start',
  },
  '&:last-child': {
    padding: '4px 0 0 0',
  },
}));

export const StyledMobileTitle = styled(Typography)(() => ({
  textAlign: 'left',
  width: '50%',
  color: stagesetColors.newGrey[400],
  fontSize: '14px',
  lineHeight: '16px',
}));

export const StyledValue = styled(Typography)(() => ({
  textAlign: 'right',
  fontSize: '14px',
  lineHeight: '16px',
  width: '100%',
}));

export const StyledValueMobile = styled(Typography)(() => ({
  textAlign: 'right',
  fontSize: '16px',
  lineHeight: '22px',
  width: '50%',
}));

export const ItemsContainer = (props: TypographyProps): JSX.Element => (
  isMobile
    ? <StyledItemsContainerMobile {...props} />
    : <StyledItemsContainer {...props} />

);

export const MobileTitle = (props: TypographyProps): JSX.Element => (
  <StyledMobileTitle {...props} />
);

export const Value = (props: TypographyProps): JSX.Element => (
  isMobile
    ? <StyledValueMobile {...props} />
    : <StyledValue {...props} />
);
