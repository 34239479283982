import {
  Box, CardMedia,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import {
  selectBuyerLogo,
  selectIsTemplate,
  selectLayouEditingStatus,
  selectSellerLogo,
} from '../../routes-old/process/state/processSlice';
import LogoSidePanel from '../LogoSidePanel/LogoSidePanel';
import { CompanyLogoGlobe } from '../../icons/CompanyLogoGlobe';
import useDealCreationData from '../TemplatePicker/lib/useDealCreationDataSlice';
import { EFieldType, ProcessStageDTO } from '../../../shared/Field/Field';
import useProcessField from '../../hooks/useProcessField';
import useProcessBackground from '../../hooks/useProcessBackground';
import { EProcessBackgroundStorageType, EProcessBackgroundType } from '../../../shared/process/ProcessMilestoneActionDTO';
import SolidColorBackground from './SolidColorBackground';
import { selectLogoDisplayStatus } from '../DealCustomizationMenu/lib/dealCustomizationSlice';
import { useContentWidth } from '../../hooks/useContentWidth';
import StagePresentationTitle from './StagePresentationTitle';
import StagePresentationDescription from './StagePresentationDescription';
import { selectCurrentStage } from '../ProcessFields/lib/processItemsSlice';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';

export enum EBackgroundStatus {
  LOADING = 'LOADING',
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
}

const CompanyLogoPlaceholder = ({ offset }: { offset?: boolean }): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '72px',
      maxHeight: '72px',
      borderRadius: '16px',
      width: '72px',
      border: (theme) => `1px solid ${theme.palette.grey[100]}`,
      background: 'white',
      marginLeft: offset ? '-8px' : undefined,
      '& svg': {
        width: '40px',
        height: '40px',
      },
    }}
  >
    <CompanyLogoGlobe />
  </Box>
);

const DealPresentationCard = ({ isLoaded }: { isLoaded: boolean }): JSX.Element | null => {
  const isTemplate = useAppSelector(selectIsTemplate);
  const stage = useAppSelector(selectCurrentStage);
  const { contentWidth } = useContentWidth();
  const isLayouyEditActive = useAppSelector(selectLayouEditingStatus);
  const sellerLogo = useAppSelector(selectSellerLogo);
  const buyerLogo = useAppSelector(selectBuyerLogo);
  const [backgroundStatus, setBackgroundStatus] = useState<EBackgroundStatus>(EBackgroundStatus.LOADING);

  const { data: currentStage } = useProcessField({ id: stage?.id, type: EFieldType.STAGE }) as { data: ProcessStageDTO };

  const displayLogo = useAppSelector(selectLogoDisplayStatus);

  const {
    url: background,
    display,
    backgroundType,
    backgroundStorageType,
  } = useProcessBackground();

  useEffect(() => {
    if (!display) {
      setBackgroundStatus(EBackgroundStatus.HIDDEN);
    } else if (backgroundType === EProcessBackgroundType.COLOR) {
      setBackgroundStatus(EBackgroundStatus.VISIBLE);
    } else if (
      backgroundStorageType === EProcessBackgroundStorageType.AWS
      && backgroundType === EProcessBackgroundType.IMG
      && background
    ) {
      setBackgroundStatus(EBackgroundStatus.VISIBLE);
    } else {
      setBackgroundStatus(EBackgroundStatus.HIDDEN);
    }
  }, [display, backgroundType, backgroundStorageType, background]);

  const renderDescription = useMemo(
    () => <StagePresentationDescription />,
    [currentStage?.id, isLayouyEditActive, currentStage?.description],
  );

  const {
    creatorLogo, clientLogo,
  } = useDealCreationData();

  const marginOffset = backgroundStatus === EBackgroundStatus.VISIBLE
  && displayLogo
  && (
    !!sellerLogo
    || !!buyerLogo
    || isTemplate
    || isLayouyEditActive
  ) ? '-70px' : undefined;

  if (!currentStage) {
    return null;
  }

  return (
    <>
      { backgroundStatus === EBackgroundStatus.LOADING && (
        <AppSkeleton
          animation="wave"
          variant="rectangular"
          sx={{
            width: '100%',
            minHeight: isMobile ? '120px' : '250px',
            maxHeight: isMobile ? '120px' : '250px',
            marginBottom: '-6px',
          }}
        />
      )}
      {
        background
        && backgroundStatus === EBackgroundStatus.VISIBLE
        && backgroundType === EProcessBackgroundType.IMG
        && (
          <Box
            component="img"
            src={background}
            onLoad={() => setBackgroundStatus(EBackgroundStatus.VISIBLE)}
            sx={{
              display: backgroundStatus === EBackgroundStatus.VISIBLE ? 'flex' : 'none',
              minHeight: isMobile ? '120px' : '250px',
              maxHeight: isMobile ? '120px' : '250px',
              width: '100%',
              objectFit: 'cover',
              aspectRatio: '109/25',
              marginBottom: '-6px',
            }}
          />
        )
      }
      {
        backgroundStatus === EBackgroundStatus.VISIBLE
        && backgroundType === EProcessBackgroundType.COLOR
        && (
        <SolidColorBackground />
        )
      }
      <Box
        sx={{
          width: '100%',
          backgroundColor: stagesetColors.white[100],
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // zIndex: 4,
            paddingX: isMobile ? '8px' : '59px',
            paddingY: '32px',
            marginX: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              maxWidth: contentWidth,
              gap: '16px',
            }}
          >
            {
          isTemplate && displayLogo
          && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: backgroundStatus === EBackgroundStatus.VISIBLE ? marginOffset : undefined,
                // background: '#FFFFFF',
                borderRadius: '16px',
                minHeight: '72px',
              }}
            >
              {
                clientLogo ? (
                  <CardMedia
                    className="CardImage"
                    component="img"
                    image={clientLogo}
                    alt="logo"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      maxWidth: '72px', // width of logo slot
                      maxHeight: '72px',
                      objectFit: 'cover',
                      borderRadius: '12px',
                      width: 'auto',
                      border: '1px solid',
                      borderColor: stagesetColors.newGrey[100],
                      background: stagesetColors.white[100],
                    }}
                  />
                ) : (
                  <CompanyLogoPlaceholder />
                )
              }
              {
                creatorLogo ? (
                  <CardMedia
                    className="CardImage"
                    component="img"
                    image={creatorLogo}
                    alt="logo"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      maxWidth: '72px', // width of logo slot
                      maxHeight: '72px',
                      objectFit: 'cover',
                      borderRadius: '12px',
                      width: 'auto',
                      border: '1px solid',
                      borderColor: stagesetColors.newGrey[100],
                      background: stagesetColors.white[100],
                      marginLeft: '-16px',
                    }}
                  />
                ) : (
                  <CompanyLogoPlaceholder
                    offset
                  />
                )
              }
            </Box>
          )
        }
            {
          !isTemplate && displayLogo
            && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  marginTop: backgroundStatus === EBackgroundStatus.VISIBLE ? marginOffset : undefined,
                }}
              >
                <LogoSidePanel isLoaded={isLoaded} />
              </Box>
            )
          }
            <Box
              key={`stage-presentation-text-${currentStage?.id}`}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'flex-start',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <StagePresentationTitle />
              </Box>
              {
            (currentStage?.description || isLayouyEditActive)
            && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                {renderDescription}
              </Box>
            )
          }
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DealPresentationCard;
