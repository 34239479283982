import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ESidebarTabs } from '../../Layout/lib/fieldsLayoutSlice';
import { DEFAULT_PRICING_ITEM, PricingItem } from '../../../../shared/pricing-table/PricingTableTypes';
import { RootState } from '../../../core/store/store';

export enum EPricingTableEditState {
  CLOSED = 'CLOSED',
  ADDING = 'ADDING',
  EDITING = 'EDITING',
  SAVING = 'SAVING',
}

export enum EProductTabState {
  BLANK = 'BLANK',
  EDIT = 'EDIT',
  PRODUCT_LIST = 'PRODUCT_LIST',
  ADD_PRODUCT_FROM_LIST = 'ADD_PRODUCT_FROM_LIST',
}

const pricingTableParamsReducerName: string = 'pricingTableItemParams';

export interface IPricingTableItemParamsState {
  title: string;
  isDrawerOpen: boolean;
  sidebarTab: ESidebarTabs;
  paramsState: EPricingTableEditState;
  pricingTableEditItemId?: number;
  pricingTableFieldId?: string;
  product?: PricingItem;
  drawerTabState: EProductTabState;
}

const initialState: IPricingTableItemParamsState = {
  title: '',
  isDrawerOpen: false,
  sidebarTab: ESidebarTabs.PRICING_TABLE_DETAILS,
  paramsState: EPricingTableEditState.CLOSED,
  pricingTableEditItemId: undefined,
  pricingTableFieldId: undefined,
  product: undefined,
  drawerTabState: EProductTabState.BLANK,
};

export const pricingTableItemParamsSlice = createSlice({
  name: pricingTableParamsReducerName,
  initialState,
  reducers: {
    createNewProduct: (state, { payload }: PayloadAction<{ fieldId: string }>) => ({
      ...state,
      title: 'Add product',
      isDrawerOpen: true,
      paramsState: EPricingTableEditState.ADDING,
      pricingTableFieldId: payload.fieldId,
      product: DEFAULT_PRICING_ITEM,
      drawerTabState: EProductTabState.BLANK,
    }),
    editProduct: (state, { payload }: PayloadAction<{ fieldId: string; productId: number; product: PricingItem }>) => ({
      ...state,
      title: 'Edit product',
      isDrawerOpen: true,
      paramsState: EPricingTableEditState.EDITING,
      pricingTableEditItemId: payload.productId,
      pricingTableFieldId: payload.fieldId,
      product: payload.product,
      drawerTabState: EProductTabState.EDIT,
    }),
    closeParams: (state) => ({
      ...state,
      title: '',
      isDrawerOpen: false,
      paramsState: EPricingTableEditState.CLOSED,
      product: undefined,
      pricingTableEditItemId: undefined,
      pricingTableFieldId: undefined,
    }),
    saveItem: (state, { payload }: PayloadAction<{ product: PricingItem }>) => ({
      ...state,
      product: payload.product,
      paramsState: EPricingTableEditState.SAVING,
    }),
    setProduct: (state, { payload }: PayloadAction<{ tabState: EProductTabState; product: PricingItem; }>) => ({
      ...state,
      product: payload.product,
      drawerTabState: payload.tabState,
    }),
    setTabState: (state, { payload }: PayloadAction<EProductTabState>) => ({
      ...state,
      drawerTabState: payload,
    }),
  },
});

export const {
  createNewProduct,
  editProduct,
  closeParams,
  saveItem,
  setProduct,
  setTabState,
} = pricingTableItemParamsSlice.actions;

export const selectState = (state: RootState): EPricingTableEditState | undefined => state.pricingTableParams.paramsState;
export const selectProduct = (state: RootState): PricingItem | undefined => state.pricingTableParams.product;
export const selectTitle = (state: RootState): string | undefined => state.pricingTableParams.title;
export const selectItemId = (state: RootState): string | undefined => state.pricingTableParams.pricingTableEditItemId;
export const selectFieldId = (state: RootState): string | undefined => state.pricingTableParams.pricingTableFieldId;
export const selectDrawerTabState = (state: RootState): string | undefined => state.pricingTableParams.drawerTabState;
