import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
}

const UsersListDivider = ({ title }:Props): JSX.Element => (
  <Box sx={{
    display: 'flex',
    flex: 1,
    height: '40px',
    mt: '10px',
    mx: '17px',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <Typography
      color="text.secondary"
      gutterBottom
    >
      {' '}
      {title}
    </Typography>
  </Box>
);

export default UsersListDivider;
