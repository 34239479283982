import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { EFieldType } from '../../../shared/Field/Field';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  resetSelectedTemplateData,
  selectTemplateData,
  selectTemaplatesLoadingStatus,
  setNewTemplateDialogStatus,
} from '../../pages/templates/lib/templatesSlice';
import SectionDisplay from './SectionDisplay';
import StageDisplay from './StageDisplay';
import { DealLayout } from '../../routes-old/process/DealLayout';
import { EStateStatus } from '../../core/commonTypes';
import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';

const TemplateDisplay = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedTemplateData = useAppSelector(selectTemplateData);
  const templatesLoadingStatus = useAppSelector(selectTemaplatesLoadingStatus);

  const onClose = isMobile ? () => {
    dispatch(resetSelectedTemplateData());
  } : undefined;

  const openNewTemplateDialog = () => {
    dispatch(setNewTemplateDialogStatus(true));
  };

  if (selectedTemplateData && selectedTemplateData.id && selectedTemplateData.type === EProcessType.TEMPLATE) {
    return (
      <DealLayout
        toFetchId={selectedTemplateData.id}
      />
    );
  }

  if (
    selectedTemplateData
    && selectedTemplateData.id
    && selectedTemplateData.type === EFieldType.STAGE
  ) {
    return (
      <StageDisplay
        id={selectedTemplateData.id}
        onClose={onClose}
      />
    );
  }

  if (
    selectedTemplateData
    && selectedTemplateData.id
    && selectedTemplateData.type === EFieldType.SECTION
  ) {
    return (
      <SectionDisplay
        id={selectedTemplateData.id}
        onClose={onClose}
      />
    );
  }

  if (templatesLoadingStatus === EStateStatus.LOADING) {
    return (
      <AppSkeleton
        animation="wave"
        variant="rectangular"
        sx={{
          borderRadius: '16px',
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        lineHeight: '24px',
        color: (theme) => theme.palette.grey[400],
        userSelect: 'none',
      }}
    >
      <Box>
        Select a template for previewing
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
        }}
      >
        or
        <Box
          onClick={openNewTemplateDialog}
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: 500,
            cursor: 'pointer',
          }}
        >
          Create new
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateDisplay;
