import { TabPanel } from '@mui/lab';
import {
  styled, Box, Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const SidebarTabPanel = styled(Box)(() => ({
  padding: 0,
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'column',
  '&.active-sidebar-tab': {
    height: 'calc( 100% )',
  },
}));

export const NewPageSwitchesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const NewPageSwitchWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
}));

export const NewPageSwitchTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
}));

export const NewPageFormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

export const NewPageTabPanel = styled(TabPanel)(() => ({
  height: '100%',
  padding: '0px',
}));

export const NewPagePaddingBox = styled(Box)(() => ({
  padding: '0px 24px',
  '&.new-page-template-error': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const NewPageEmbedRadioButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '16px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  borderRadius: '12px',
  background: stagesetColors.white[100],
}));

export const NewPageEmbedRadioButtonGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const NewPageEmbedRadioButtonTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const NewPageEmbedRadioButton = styled(FormControlLabel)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '12px',
  marginLeft: '0px',
  '& span': {
    padding: '0px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    color: stagesetColors.newGrey[800],
    '& span:first-of-type': {
      color: stagesetColors.newGrey[500],
      stroke: stagesetColors.newGrey[500],
    },
    '& input': {
      height: '20px',
      width: '20px',
    },
  },
  '& .Mui-checked:first-of-type': {
    '& span': {
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
    },
  },
}));

export const NewPageTemplateErrorText = styled(Typography)(() => ({
  marginTop: '32px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
}));

export const NewPageTemplateItemsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 176px)',
  overflow: 'auto',
}));

export const NewPageButtonTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const NewPageButtonSubtitle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14px',
  color: stagesetColors.newGrey[500],
}));

export const NewPageButtonTextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const NewPageTemplateTitleContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '8px 16px',
}));

export const NewPageFileItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  padding: '8px 0px',
  marginTop: '8px',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '22px',
  stroke: stagesetColors.newGrey[500],
}));

export const NewPageFileTitleGroup = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '12px',
}));

export const NewPageDragAndDropContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  whiteSpace: 'normal',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '8px',
}));

export const NewPageDragAndDropTitleAndIcon = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[800],
  '& svg': {
    top: '2px',
    right: '4px',
    display: 'inline-block',
    position: 'relative',
    verticalAlign: 'bottom',
  },
}));

export const NewPageDragAndDropFileFormats = styled(Typography)(() => ({
  marginTop: '8px',
  fontSize: '12px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
  textAlign: 'center',
}));

export const NewPageMainTabItemTitle = styled(Typography)(() => ({
  padding: '16px 24px 4px 24px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  color: stagesetColors.newGrey[500],
}));

export const NewPageMenuContainer = styled(Box)(() => ({
  overflowY: 'auto',
  height: 'calc( 100% - 64px)',
  maxHeight: 'calc( 100% - 64px)',
}));
