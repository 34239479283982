/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeaderIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M5.2168 10H15.2168"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.3833 2.82129H6.04997"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.3833 2.82129H16.05"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.3833 16.6665H16.05"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.3833 16.6665H6.04997"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.2168 3.3335V16.6668"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.2168 3.3335V16.6668"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const Header1Icon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M3.41016 10.7881H9.41016"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.41016 5.42773V15.4277"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.41016 5.42773V15.4277"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.4102 7.80869L15.4102 5.42773V15.4277"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.4102 15.4277H17.4102"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const Header2Icon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <g
      clipPath="url(#clip0_36319_31400)"
    >
      <path
        d="M12.7847 8.06793C12.85 7.40826 13.1325 6.80082 13.5817 6.35405C14.0308 5.90728 14.6174 5.65027 15.2367 5.62889C15.8561 5.60751 16.4678 5.82315 16.9627 6.23733C17.3946 6.5988 17.713 7.09094 17.8769 7.64425C17.9246 7.80561 17.9382 7.97461 17.9376 8.14212V8.14212C17.9349 8.86307 17.6084 9.518 17.0676 9.88721L14.4818 11.6526C13.4222 12.3759 12.7847 13.6613 12.7847 15.0741L12.7847 15.1467L17.9118 15.1467"
        strokeWidth="1.66"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.41016 10.7881H9.41016"
        strokeWidth="1.66"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.41016 5.42773V15.4277"
        strokeWidth="1.66"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.41016 5.42773V15.4277"
        strokeWidth="1.66"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_36319_31400">
        <rect width="20" height="20" fill="white" transform="translate(0.410156 0.427734)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const Header3Icon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M12.9883 7.13022C13.3208 6.86737
      13.7195 6.68074 14.1501 6.58637C14.5808
      6.492 15.0306 6.49271 15.4609 6.58842C15.8912
      6.68414 16.2891 6.87201 16.6206 7.1359C16.952
      7.3998 17.2071 7.73184 17.3639 8.10353C17.5206
      8.47521 17.5744 8.87545 17.5206 9.26987C17.4668
      9.66429 17.3071 10.0411 17.055 10.368C16.803 10.6949
      16.4662 10.9621 16.0736 11.1467C15.6809 11.3313 14.7584
      11.4449 14.3151 11.4449C14.7584 11.4449 15.6809 11.5241
      16.0736 11.7087C16.4662 11.8932 16.803 12.1604 17.055
      12.4873C17.3071 12.8142 17.4668 13.1911 17.5206
      13.5855C17.5744 13.9799 17.5206 14.3801 17.3639
      14.7518C17.2071 15.1235 16.952 15.4555 16.6206
      15.7194C16.2891 15.9833 15.8912 16.1712 15.4609
      16.2669C15.0306 16.3626 14.5808 16.3633 14.1501
      16.269C13.7195 16.1746 13.3208 15.988 12.9883 15.7251"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.41016 11.7881H9.41016"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.41016 6.42773V16.4277"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.41016 6.42773V16.4277"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const TextIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M3.55029 5.8335V3.3335H16.8836V5.8335"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.7168 16.6665H12.7168"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.2168 3.3335V16.6668"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const TaskIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M9.58331 4.17908H17.9166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.58331 12.4776H17.9166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.58331 7.49033H13.2226"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.58331 15.8498H13.2226"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="2.08331"
      y="3.34452"
      width="4"
      height="4"
      rx="1"
      strokeWidth="1.6"
      mask="url(#path-5-inside-1_91_906)"
      fill="none"
    />
    <rect
      x="2.08331"
      y="11.6553"
      width="4"
      height="4"
      rx="1"
      strokeWidth="1.6"
      mask="url(#path-6-inside-2_91_906)"
      fill="none"
    />
  </SvgIcon>
);

export const MediaIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M16.0501 3H4.38346C3.46299 3 2.7168
      3.74619 2.7168 4.66667V16.3333C2.7168
      17.2538 3.46299 18 4.38346 18H16.0501C16.9706
      18 17.7168 17.2538 17.7168 16.3333V4.66667C17.7168
      3.74619 16.9706 3 16.0501 3Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.30029 8.8335C7.99065 8.8335 8.55029
      8.27385 8.55029 7.5835C8.55029 6.89314 7.99065
      6.3335 7.30029 6.3335C6.60994 6.3335 6.05029
      6.89314 6.05029 7.5835C6.05029 8.27385 6.60994
      8.8335 7.30029 8.8335Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.7166 13.0002L13.55 8.8335L4.3833 18.0002"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const EmbedIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M10.2166 18.8332C14.819 18.8332 18.55
      15.1022 18.55 10.4998C18.55 5.89746 14.819
      2.1665 10.2166 2.1665C5.61426 2.1665 1.8833
      5.89746 1.8833 10.4998C1.8833 15.1022 5.61426
      18.8332 10.2166 18.8332Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.7503 6.9668L11.9836 12.2668L6.68359
      14.0335L8.45026 8.73346L13.7503 6.9668Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const FileIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M11.0503 2.1665H5.21696C4.77493 2.1665
      4.35101 2.3421 4.03845 2.65466C3.72589 2.96722
      3.55029 3.39114 3.55029 3.83317V17.1665C3.55029
      17.6085 3.72589 18.0325 4.03845 18.345C4.35101
      18.6576 4.77493 18.8332 5.21696 18.8332H15.217C15.659
      18.8332 16.0829 18.6576 16.3955 18.345C16.708 18.0325
      16.8836 17.6085 16.8836 17.1665V7.99984L11.0503 2.1665Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.0503 2.1665V7.99984H16.8836"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const TwoColumnsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M17.2286 3.48816C16.9161 3.17559 16.4922 3 16.0501 3H4.38346C3.94144 3 3.51751 3.17559 3.20495 3.48816C2.89239 3.80072 2.7168 4.22464 2.7168 4.66667V16.3333C2.7168 16.7754 2.89239 17.1993 3.20495 17.5118C3.51751 17.8244 3.94144 18 4.38346 18H16.0501C16.4922 18 16.9161 17.8244 17.2286 17.5118C17.5412 17.1993 17.7168 16.7754 17.7168 16.3333V4.66667C17.7168 4.22464 17.5412 3.80072 17.2286 3.48816Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.2168 18V3"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const ThreeColumnsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M17.2286 3.48816C16.9161 3.17559 16.4922 3
      16.0501 3H4.38346C3.94144 3 3.51751 3.17559 3.20495
      3.48816C2.89239 3.80072 2.7168 4.22464 2.7168
      4.66667V16.3333C2.7168 16.7754 2.89239 17.1993
      3.20495 17.5118C3.51751 17.8244 3.94144 18 4.38346
      18H16.0501C16.4922 18 16.9161 17.8244 17.2286 17.5118C17.5412
      17.1993 17.7168 16.7754 17.7168 16.3333V4.66667C17.7168 4.22464
      17.5412 3.80072 17.2286 3.48816Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.7168 18V3"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.7168 18V3"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const TemplatesIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M7.80758 11.6621H4.81445C4.26217 11.6621 3.81445
      12.1098 3.81445 12.6621V15.6552C3.81445 16.2075 4.26217
      16.6552 4.81445 16.6552H7.80758C8.35987 16.6552 8.80758
      16.2075 8.80758 15.6552V12.6621C8.80758 12.1098 8.35987
      11.6621 7.80758 11.6621Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16.1865 11.666H13.1934C12.6411 11.666 12.1934 12.1137
      12.1934 12.666V15.6591C12.1934 16.2114 12.6411 16.6591
      13.1934 16.6591H16.1865C16.7388 16.6591 17.1865 16.2114
      17.1865 15.6591V12.666C17.1865 12.1137 16.7388 11.666
      16.1865 11.666Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16.1845 3.33984H13.1914C12.6391 3.33984 12.1914
      3.78756 12.1914 4.33984V7.33297C12.1914 7.88526 12.6391
      8.33297 13.1914 8.33297H16.1845C16.7368 8.33297 17.1845
      7.88526 17.1845 7.33297V4.33984C17.1845 3.78756 16.7368
      3.33984 16.1845 3.33984Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.81445 5.82031L8.81253 5.85739"
      strokeWidth="1.6"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M6.29492 8.33887L6.332 3.34079"
      strokeWidth="1.6"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);

export const BulletListIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      fontSize: '20px',
    }}
  >
    <path
      d="M6.8833 5.5H17.7166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.8833 10.5H17.7166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.8833 15.5H17.7166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2.7168 5.5H2.72513"
      stroke="#1E293B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2.7168 10.5H2.72513"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2.7168 15.5H2.72513"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const NumberedListIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M6.8833 5.5H17.7166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.8833 10.5H17.7166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.8833 15.5H17.7166"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.35791 4.15625V7H2.69971V4.89844L2.05127 5.08789V4.58789L3.29736 4.15625H3.35791Z"
      fill="none"
    />
    <path
      d="M3.88525 11.4922V12H1.90088V11.5703L2.81494 10.6094C2.89567 10.5169 2.96077 10.4342 3.01025 10.3613C3.05973 10.2871 3.09554 10.2201 3.11768 10.1602C3.14111 10.1003 3.15283 10.0462 3.15283 9.99805C3.15283 9.91602 3.14046 9.84766 3.11572 9.79297C3.09229 9.73698 3.05713 9.69466 3.01025 9.66602C2.96468 9.63737 2.90804 9.62305 2.84033 9.62305C2.77262 9.62305 2.71338 9.64258 2.6626 9.68164C2.61182 9.7207 2.5721 9.77409 2.54346 9.8418C2.51611 9.90951 2.50244 9.98568 2.50244 10.0703H1.84229C1.84229 9.89583 1.8846 9.73633 1.96924 9.5918C2.05518 9.44727 2.17432 9.33203 2.32666 9.24609C2.479 9.15885 2.65544 9.11523 2.85596 9.11523C3.06559 9.11523 3.24137 9.14779 3.3833 9.21289C3.52523 9.27799 3.632 9.3724 3.70361 9.49609C3.77653 9.61849 3.81299 9.76628 3.81299 9.93945C3.81299 10.0384 3.79736 10.1335 3.76611 10.2246C3.73486 10.3158 3.68994 10.4062 3.63135 10.4961C3.57275 10.5846 3.50114 10.6758 3.4165 10.7695C3.33317 10.8633 3.23812 10.9629 3.13135 11.0684L2.76416 11.4922H3.88525Z"
      fill="none"
    />
    <path
      d="M2.50049 15.3027H2.8208C2.90283 15.3027 2.96989 15.2884 3.02197 15.2598C3.07536 15.2298 3.11507 15.1882 3.14111 15.1348C3.16715 15.0801 3.18018 15.0156 3.18018 14.9414C3.18018 14.8841 3.16781 14.8314 3.14307 14.7832C3.11963 14.735 3.08317 14.6966 3.03369 14.668C2.98421 14.638 2.92106 14.623 2.84424 14.623C2.79215 14.623 2.74137 14.6341 2.69189 14.6562C2.64242 14.6771 2.6014 14.707 2.56885 14.7461C2.5376 14.7852 2.52197 14.8327 2.52197 14.8887H1.86182C1.86182 14.7272 1.90609 14.5892 1.99463 14.4746C2.08447 14.3587 2.20231 14.2702 2.34814 14.209C2.49528 14.1465 2.65348 14.1152 2.82275 14.1152C3.02458 14.1152 3.20166 14.1465 3.354 14.209C3.50635 14.2702 3.62484 14.3613 3.70947 14.4824C3.79541 14.6022 3.83838 14.7507 3.83838 14.9277C3.83838 15.0254 3.81559 15.1172 3.77002 15.2031C3.72445 15.2891 3.66064 15.3646 3.57861 15.4297C3.49658 15.4948 3.40023 15.5462 3.28955 15.584C3.18018 15.6204 3.06038 15.6387 2.93018 15.6387H2.50049V15.3027ZM2.50049 15.793V15.4648H2.93018C3.0721 15.4648 3.20166 15.4811 3.31885 15.5137C3.43604 15.5449 3.53695 15.5918 3.62158 15.6543C3.70622 15.7168 3.77132 15.7943 3.81689 15.8867C3.86247 15.9779 3.88525 16.0827 3.88525 16.2012C3.88525 16.334 3.85856 16.4525 3.80518 16.5566C3.75179 16.6608 3.67692 16.7487 3.58057 16.8203C3.48421 16.8919 3.37158 16.9466 3.24268 16.9844C3.11377 17.0208 2.9738 17.0391 2.82275 17.0391C2.70426 17.0391 2.58577 17.0234 2.46729 16.9922C2.3488 16.9596 2.24072 16.9102 2.14307 16.8438C2.04541 16.776 1.96663 16.6901 1.90674 16.5859C1.84814 16.4805 1.81885 16.3548 1.81885 16.209H2.479C2.479 16.2689 2.49528 16.3236 2.52783 16.373C2.56169 16.4212 2.60661 16.4596 2.6626 16.4883C2.71859 16.5169 2.77913 16.5312 2.84424 16.5312C2.92497 16.5312 2.99333 16.5163 3.04932 16.4863C3.10661 16.4551 3.15023 16.4134 3.18018 16.3613C3.21012 16.3092 3.2251 16.2513 3.2251 16.1875C3.2251 16.0911 3.20882 16.0143 3.17627 15.957C3.14502 15.8997 3.0988 15.8581 3.0376 15.832C2.9777 15.806 2.90544 15.793 2.8208 15.793H2.50049Z"
      fill="none"
    />
  </SvgIcon>
);

export const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M2.77734 5H4.44401H17.7773"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.94385 4.99984V3.33317C6.94385
      2.89114 7.11944 2.46722 7.432 2.15466C7.74456
      1.8421 8.16849 1.6665 8.61051 1.6665H11.9438C12.3859
      1.6665 12.8098 1.8421 13.1224 2.15466C13.4349 2.46722
      13.6105 2.89114 13.6105 3.33317V4.99984M16.1105
      4.99984V16.6665C16.1105 17.1085 15.9349 17.5325
      15.6224 17.845C15.3098 18.1576 14.8859 18.3332
      14.4438 18.3332H6.11051C5.66849 18.3332 5.24456
      18.1576 4.932 17.845C4.61944 17.5325 4.44385 17.1085
      4.44385 16.6665V4.99984H16.1105Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M16.9437 5.68262L7.77702 14.8493L3.61035 10.6826"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const PricingTableIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M16.2435 4.42773H4.57682C3.65635
      4.42773 2.91016 5.17393 2.91016 6.0944V17.7611C2.91016
      18.6815 3.65635 19.4277 4.57682 19.4277H16.2435C17.164
      19.4277 17.9102 18.6815 17.9102 17.7611V6.0944C17.9102
      5.17393 17.164 4.42773 16.2435 4.42773Z"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.4097 6.99707V16.8586"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.6515 8.79004H9.28959C8.8735 8.79004
      8.47444 8.95533 8.18022 9.24956C7.886 9.54378
      7.7207 9.94283 7.7207 10.3589C7.7207 10.775
      7.886 11.1741 8.18022 11.4683C8.47444 11.7625
      8.8735 11.9278 9.28959 11.9278H11.5309C11.947
      11.9278 12.346 12.0931 12.6402 12.3873C12.9345
      12.6816 13.0997 13.0806 13.0997 13.4967C13.0997
      13.9128 12.9345 14.3119 12.6402 14.6061C12.346
      14.9003 11.947 15.0656 11.5309 15.0656H7.7207"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const ButtonIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <rect
      x="2.3"
      y="6.22969"
      width="15.4"
      height="7.07285"
      rx="2.2"
      strokeWidth="1.6"
      fill="none"
    />
    <path
      d="M6.00004 9.76611H9.95097H14"
      strokeWidth="1.6"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);

export const ThreeDotsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
    {...props}
  >
    <path
      d="M8.17769 7.33317C7.8095 7.33317 7.51103
      7.63165 7.51103 7.99984C7.51103 8.36803
      7.8095 8.6665 8.17769 8.6665C8.54588
      8.6665 8.84436 8.36803 8.84436 7.99984C8.84436
      7.63165 8.54588 7.33317 8.17769 7.33317Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.17769 12.0002C7.8095 12.0002 7.51103
      12.2986 7.51103 12.6668C7.51103 13.035 7.8095
      13.3335 8.17769 13.3335C8.54588 13.3335 8.84436
      13.035 8.84436 12.6668C8.84436 12.2986 8.54588
      12.0002 8.17769 12.0002Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.17769 2.66667C7.8095 2.66667 7.51103 2.96514
      7.51103 3.33333C7.51103 3.70152 7.8095 4 8.17769
      4C8.54588 4 8.84436 3.70152 8.84436 3.33333C8.84436
      2.96514 8.54588 2.66667 8.17769 2.66667Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const ReplaceIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_33157_39260)">
      <path
        d="M1.77539 3.375V8.375H6.77539"
        strokeWidth="1.6"
        strokeMiterlimit="2.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20.0254 16.625V11.625H15.0254"
        strokeWidth="1.6"
        strokeMiterlimit="2.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.0254 7.50005C16.6504 3.62505 12.2754 1.50005 8.40039 2.87505C7.40039 3.25005 6.40039 3.87505 5.65039 4.75005L1.77539 8.37505"
        strokeWidth="1.6"
        strokeMiterlimit="2.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20.0254 11.625L16.1504 15.25C13.2754 18.125 8.52539 18.125 5.52539 15.25C4.77539 14.5 4.15039 13.5 3.77539 12.5"
        strokeWidth="1.6"
        strokeMiterlimit="2.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_33157_39260">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.900391)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
