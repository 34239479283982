import { useTranslation } from 'react-i18next';
import { StripeInterval } from '../../../../../shared/stripe';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { selectIsAppsumo, selectSubscriptionInteval, setSubscriptionInterval } from '../../../Billing/state/billingSlice';
import { AppTabs } from '../../../../shared/AppTabs/AppTabs';
import { IntervalSelectWrapper } from '../Styles/SubscriptionTabStyles';

const SubscriptionTabIntervalSelect = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAppSumo = useAppSelector(selectIsAppsumo);
  const interval = useAppSelector(selectSubscriptionInteval);

  const setInterval = (val: StripeInterval) => {
    dispatch(setSubscriptionInterval(val));
  };

  if (isAppSumo) {
    return null;
  }

  return (
    <IntervalSelectWrapper>
      <AppTabs
        value={interval}
        onChange={(option) => setInterval(option.value)}
        size="l-new"
        variant="boxedNew"
        color="white"
        options={
        [
          {
            title: t('Subscriptions_Pay_monthly'),
            value: 'month',
          },
          {
            title: t('Subscriptions_Pay_yearly'),
            value: 'year',
          },
        ]
      }
      />
    </IntervalSelectWrapper>
  );
};

export default SubscriptionTabIntervalSelect;
