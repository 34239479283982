import {
  Box, Button, Card, CardActions, CardContent, Grid, Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { EAppRoutes } from '../../core/router';

interface Props {
  deal: ProcessDTO;
}

const BuyerDealItem = ({ deal }: Props): JSX.Element => {
  const {
    summaryTitle, id,
  } = deal;
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={3}
    >
      <Card
        sx={{
          borderRadius: '16px',
          p: '8px',
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            component="div"
            sx={{
              mb: '10px',
              cursor: 'default',
            }}
          >
            {summaryTitle}
          </Typography>
          <Box
            sx={{
              cursor: 'default',
              alignItems: 'left',
              alignSelf: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              p: 0,
              m: 0,
              ml: '12px',
              mt: '12px',
              maxHeight: '170px',
              height: '170px',
              overflow: 'clip',
              borderRadius: '4px',
              color: '#65676D',
              '& p': {
                marginTop: '0px',
                marginBottom: '0px',
              },
              '#mui-rte-toolbar': {
                backgroundColor: 'red',
              },
              fontSize: '12px',
            }}
          />
        </CardContent>
        <CardActions>
          <Button onClick={() => navigate(`${EAppRoutes.deal}/${id}`)} size="small">OPEN</Button>
        </CardActions>
      </Card>

    </Grid>
  );
};

export default BuyerDealItem;
