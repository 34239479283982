import {
  Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';
import Add from '../../../icons/StageMenu/Add.svg';
import CanvasIcon from '../../../icons/AddSectionIcons/Canvas20.svg';
import Squar from '../../../icons/StageMenu/Squar.svg';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import CompassIcon from '../../../icons/AddSectionIcons/Compass20.svg';
import MediaIcon from '../../../icons/AddSectionIcons/Media20.svg';

export type Props = {
  onTemplate: () => void;
  onNew: () => void;
  onEmbed: () => void;
  onMedia: () => void;
  onGoBack?: (tab?: number) => void;
  onClose: () => void;
};

export const AddTab = ({
  onTemplate, onNew, onGoBack, onClose, onEmbed, onMedia,
}: Props) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '16px 16px 8px 16px',
  }}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      marginBottom: '20px',
    }}
    >
      {onGoBack ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        >
          <Box>
            <AppLinkButton
              startIcon={<ChevronLeft />}
              onClick={() => onGoBack(0)}
              sx={{
                width: 'auto !important',
                paddingLeft: '0 !important',
                paddingRight: '0 !important',
              }}
            >
              Add page
            </AppLinkButton>
          </Box>
        </Box>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        >
          <Typography
            sx={{
              fontFamily: 'Manrope',
              fontSize: '14px',
              fontWeight: 600,
              color: (theme) => theme.palette.grey[600],
            }}
          >
            Add page
          </Typography>
        </Box>
      )}
      <Box sx={{
        display: 'flex',
      }}
      >
        <AppLinkButton
          onClick={onClose}
          sx={{
            paddingRight: '0px !important',
            minWidth: '100% !important',
            width: '100% !important',
          }}
        >
          Cancel
        </AppLinkButton>
      </Box>
    </Box>
    <Box sx={{
      height: '100%',
      overflow: 'overlay',
    }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={onTemplate}
            sx={{
              padding: '0px',
              height: '40px',
            }}
          >
            <ListItemIcon sx={{
              minWidth: 'auto',
              marginRight: '15px',
            }}
            >
              <Squar />
            </ListItemIcon>
            <ListItemText primary="From templates" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={onNew}
            sx={{
              padding: '0px',
              height: '40px',
            }}
          >
            <ListItemIcon sx={{
              minWidth: 'auto',
              marginRight: '15px',
            }}
            >
              <CanvasIcon />
            </ListItemIcon>
            <ListItemText primary="Canvas" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={onMedia}
            sx={{
              padding: '0px',
              height: '40px',
            }}
          >
            <ListItemIcon sx={{
              minWidth: 'auto',
              marginRight: '15px',
            }}
            >
              <MediaIcon sx={{ color: '#7084b4' }} />
            </ListItemIcon>
            <ListItemText primary="Media" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={onEmbed}
            sx={{
              padding: '0px',
              height: '40px',
            }}
          >
            <ListItemIcon sx={{
              minWidth: 'auto',
              marginRight: '15px',
            }}
            >
              <CompassIcon />
            </ListItemIcon>
            <ListItemText primary="Embed" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  </Box>
);
