import { SvgIcon, SvgIconProps } from '@mui/material';

const CopyLinkIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.53418 10.7331C8.84891 11.1538 9.25045 11.502 9.71157
 11.7539C10.1727 12.0059 10.6826 12.1557 11.2067 12.1932C11.7308
  12.2307 12.2569 12.1551 12.7492 11.9715C13.2415 11.7878 13.6885
   11.5004 14.06 11.1288L16.2586 8.93022C16.9261 8.23912 17.2955
    7.3135 17.2871 6.35272C17.2788 5.39194 16.8934 4.47288 16.214
     3.79348C15.5346 3.11408 14.6155 2.7287 13.6547 2.72035C12.694
      2.712 11.7683 3.08135 11.0772 3.74884L9.8167 5.00205"
      stroke="#0071BE"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.466 9.26733C11.1512 8.84657 10.7497 8.49842 10.2886
 8.24649C9.82745 7.99456 9.31754 7.84475 8.79344 7.80721C8.26933
  7.76968 7.74328 7.8453 7.25097 8.02894C6.75866 8.21259 6.3116
   8.49997 5.94012 8.87158L3.74152 11.0702C3.07403 11.7613 2.70468
    12.6869 2.71303 13.6477C2.72138 14.6085 3.10675 15.5275 3.78615
     16.2069C4.46555 16.8863 5.38462 17.2717 6.3454 17.2801C7.30617
      17.2884 8.2318 16.9191 8.9229 16.2516L10.1761 14.9984"
      stroke="#0071BE"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

export default CopyLinkIcon;

export const CopyLinkIconSq = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#clip0_11137_149443)">
      <path
        d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5
       9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667
       18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333
        16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.16669 12.5H3.33335C2.89133 12.5 2.4674
       12.3244 2.15484 12.0118C1.84228 11.6993 1.66669
       11.2754 1.66669 10.8333V3.33333C1.66669 2.8913
       1.84228 2.46738 2.15484 2.15482C2.4674 1.84226
        2.89133 1.66666 3.33335 1.66666H10.8334C11.2754
        1.66666 11.6993 1.84226 12.0119 2.15482C12.3244
         2.46738 12.5 2.8913 12.5 3.33333V4.16666"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_11137_149443">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
