import { useAppSelector } from './stateHooks';
import { EOrganizationRoles } from '../../shared/permissions';
import findOrganizationOwner from '../core/store/appState/selectors/ownerSelector';

export const useOrganization = () => {
  const {
    users,
    roles,
    organization,
    user,
  } = useAppSelector((state) => state.app);
  const organizationOwner = useAppSelector(findOrganizationOwner);

  const roleNames: {
    [key in EOrganizationRoles | string]: string
  } = {
    [EOrganizationRoles.OWNER]: 'Owner',
    [EOrganizationRoles.SELLER]: 'User',
    [EOrganizationRoles.EMPLOYEE]: 'Employee',
    [EOrganizationRoles.ADMIN]: 'Administrator',
  };

  return {
    users,
    roles,
    organization,
    roleNames,
    organizationOwner,
    currentUser: user,
  };
};
