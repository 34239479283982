import {
  Box, Button, Paper, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EAppRoutes } from '../../../../../core/router';

interface Props {
  title: string;
  description: string;
}

const PagePresentationNoPermissions = ({ title, description }: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '30vh',
      }}
    >
      <Paper
        sx={{
          borderRadius: '16px',
          maxWidth: '800px',
          p: '32px',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-around',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            mb: '32px',
          }}
          variant="h3"
        >
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
        <Button
          variant="contained"
          sx={{
            m: '32px',
            mt: '48px',
          }}
          onClick={() => navigate(EAppRoutes.home)}
        >
          Home
        </Button>
      </Paper>
    </Box>
  );
};

export default PagePresentationNoPermissions;
