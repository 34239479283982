import { useEffect, useState } from 'react';
import { config } from '../core';

const useCelloScript = () => {
  const [isLoaded, setLoaded] = useState(false);
  const scriptId = 'cello-script';
  const { scriptUrl } = config.cello;

  useEffect(() => {
    const existingScript = document.getElementById(scriptId);
    if (!isLoaded && scriptUrl) {
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.type = 'module';
        script.id = scriptId;
        document.body.appendChild(script);

        script.onload = () => {
          setLoaded(true);
        };
      }
    }

    return () => {
      if (existingScript && isLoaded) {
        existingScript.remove();
      }
    };
  }, [isLoaded, scriptUrl]);

  return isLoaded;
};

export default useCelloScript;
