import { UserDTO } from '../UserDTO';
import { EFileType } from './Library';
import {
	DEFAULT_PRICING_ITEM,
	PricingItem,
} from '../pricing-table/PricingTableTypes';
import { LibraryFilesFilterParams, LibraryProductFilterParams } from './LibraryFilter';
import { ContentType } from '../Stage';
import { EFieldsubType } from '../Field/Field';

export interface LibraryFileDTO {
	id: string;
	name: string;
	userId: string;
	organizationId: string;
	updated: Date;
	link: string;
	fileUid: string;
	type: EFileType;
	linkType?: ContentType;
	fileSubtype?: EFieldsubType;
}

export interface ProductDTO extends PricingItem {
	id?: string;
	userId?: string;
	organizationId?: string;
	updated?: Date;
	pricingPeriod: string;
	currency: string;
}

export interface FileWithOwnerDTO extends LibraryFileDTO {
	owner: UserDTO;
}

export interface ProductWithOwnerDTO extends ProductDTO {
	owner?: UserDTO;
}

export type FileRequestData = {
	id?: string;
	name: string;
	userId: string;
	link?: string;
	// ToDo add file data
}

export type FileCreateData = {
	name: string;
	userId: string;
	file?: File;
	organizationId: string;
	type: EFileType;
	link?: string;
	linkType?: ContentType;
	fileSubtype?: EFieldsubType;
}

export type FileUpdateData = FileCreateData & {
	id: string;
	fileUid: string;
}

export type FileGetRequest = {
	search?: string;
	filter?: LibraryFilesFilterParams;
	params?: Record<string, any>;
}

export type FileGetByTypeRequest = {
	types?: EFileType[];
	linkTypes?: ContentType[];
}

export type FileGetRequestQuery = {
	name?: string;
	owners?: string;
	types?: string;
	linkTypes?: string;
};

export type ProductGetRequest = {
	search?: string;
	filter?: LibraryProductFilterParams;
	params?: Record<string, any>;
}

export type ProductCreateData = PricingItem & {
	userId: string;
	organizationId: string;
	pricingPeriod: string;
	currency: string;
}

export type ProductUpdateData = ProductCreateData & {
	id?: string;
}

export const DEFAULT_PRODUCT_DTO: ProductWithOwnerDTO = {
	...DEFAULT_PRICING_ITEM,
	owner: undefined,
	userId: undefined,
	organizationId: undefined,
	updated: undefined,
	pricingPeriod: '',
	currency: '',
};

export type CheckUrlRequest = {
	url: string;
}

export type CheckUrlResponse = {
	status: boolean;
	message: string;
}

export type UrlAndTypeResponse = {
	name: string;
	url: string;
	fileSubType: EFieldsubType;
	linkType: ContentType;
}
