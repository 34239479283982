import {
  Box,
} from '@mui/material';
import { useState } from 'react';
import { OrganizationAdminDTO, OrganizationDTO } from '../../../../../shared/OrganizationDTO';
import { withOrganizationDetails } from './withOrganizationDetails';
import OrganizationDetailsGeneral from './organization-details-general/OrganizationDetailsGeneral';
import OrganizationDetailsUsers from './organization-details-users/OrganizationDetailsUsers';
import { UserRolesDTO } from '../../../../../shared/user-organization';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { useAppDispatch } from '../../../../hooks/stateHooks';
import { updateAdminOrganization } from '../../state/adminSlice';
import useScreenDimensions from '../../../../hooks/useScreenDimensions';

interface Props {
  organizationAdmin: OrganizationAdminDTO;
  organization: OrganizationDTO;
  users: UserRolesDTO[];
}

interface InputProps {
  organizationAdmin: OrganizationAdminDTO;
}

const OrganizationDetails = ({
  organization,
  users,
  organizationAdmin,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [height] = useScreenDimensions(true);
  const [organizationAdminObject, setOrganizationAdminObject] = useState<OrganizationAdminDTO>(organizationAdmin);

  const handleSubmit = () => {
    dispatch(updateAdminOrganization({ organization: organizationAdminObject, users }));
  };

  const setUser = (newUser: Partial<UserRolesDTO>) => {
    const userToChange = users.find((x: UserRolesDTO) => x.user.id === newUser.user?.id);
    if (!userToChange || !newUser?.user) {
      return;
    }
    userToChange.user = {
      ...newUser.user,
    };
    if (!newUser?.roles) {
      return;
    }
    userToChange.roles = newUser.roles;
  };

  const setOrganizationAdmin = (newOrg: OrganizationAdminDTO) => {
    setOrganizationAdminObject(newOrg);
  };

  const heights = {
    header: '50px',
    footer: '60px',
    detailsContainer: () => `calc(${height - 204}px)`,
  };

  return (
    <>
      <Box
        sx={{
          paddingX: '24px',
          height: heights.detailsContainer(),
          maxHeight: heights.detailsContainer(),
          backgroundColor: (theme) => theme.palette.newGrey[100],
          paddingTop: '20px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box>
            <Box
              sx={{
                paddingBottom: '15px',
              }}
            >
              <OrganizationDetailsGeneral
                organization={organization}
                organizationAdmin={organizationAdmin}
                setOrganizationAdmin={setOrganizationAdmin}
                userRoles={users}
              />
            </Box>
            <Box>
              <OrganizationDetailsUsers organization={organization} users={users} setUser={setUser} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '16px 16px 8px',
          boxShadow: '0px 0px 7px -3px rgba(0, 57, 96, 0.1), 0px -5px 5px -2px rgba(0, 57, 96, 0.05)',
        }}
      >
        <AppButton
          onClick={handleSubmit}
          fullWidth
          variant="primary-grey"
          size="l"
        >
          Save
        </AppButton>
      </Box>
    </>
  );
};

export default withOrganizationDetails<InputProps, Props>(OrganizationDetails);
