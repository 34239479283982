import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { AppLinkButton } from '../../../../../shared/AppButton/AppLinkButton';
import { allFormats } from '../../../../MediaField/Helpers/MediaFieldHelpers';
import { useFileUploadContext } from '../../../../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { DocumentsNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import FileAddIcon20px from '../../../icons/FileAdd';
import MediaFieldMenu from '../../../../BlockContentSection/BlockContentRowMenu/MediaFieldMenu/MediaFieldMenu';
import { AddsectionChildData, EFieldsubType } from '../../../../../../shared/Field/Field';

const inputAccepts = allFormats.map((format) => `.${format}`).join(',');

const DocumentNodeUploadBtn = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { data, onUpdate } = useBlockContext<DocumentsNodeAttrs>();
  const [mediaMenuOpen, setMediaMenuOpen] = useState(false);

  const { onUpload } = useFileUploadContext();

  const handleMediaMenuClose = () => {
    setMediaMenuOpen(false);
  };

  const onFileUploadTry = () => {
    // inputRef.current?.click();
    setMediaMenuOpen(true);
  };

  const handleUpload = async (f: File[]) => {
    const docs = [...data.docs];
    const reqs = [];
    for (const file of f) {
      reqs.push(onUpload(file, data.blockId));
    }
    const newIds = await Promise.all(reqs);
    onUpdate({ docs: [...docs, ...newIds] });
  };

  const handleMediaFieldAdd = (childData?: AddsectionChildData, mediaFiles?: File[]) => {
    if (mediaFiles?.length) {
      handleUpload(mediaFiles);
    } else if (childData?.embedData?.newUrl && childData.embedData.newUrl.startsWith('library_')) {
      const docs = [...data.docs];
      onUpdate({ docs: [...docs, childData.embedData.newUrl] });
    }
  };

  return (
    <>
      <Box>
        {/* <input
          type="file"
          ref={inputRef}
          onChange={(e) => e.target.files && handleUpload([...e.target.files])}
          className="input"
          accept={inputAccepts}
          multiple
        /> */}
        <AppLinkButton
          size="l"
          startIcon={<FileAddIcon20px />}
          sx={{
            justifyContent: 'flex-start',
            minWidth: 'auto !important',
            paddingY: '0px !important',
            height: '24px !important',
          }}
          onClick={onFileUploadTry}
        >
          File
        </AppLinkButton>
      </Box>
      <MediaFieldMenu
        isOpen={mediaMenuOpen}
        onClose={handleMediaMenuClose}
        onResult={handleMediaFieldAdd}
      />
    </>
  );
};

export default DocumentNodeUploadBtn;
