import { memo, useMemo } from 'react';
import { ListProps } from '@mui/material';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { SideMenuItem } from './SideMenuItem';
import { sideMenuItems, SideMenuItem as SideMenuItemType } from '../../core';
import { ESubscriptionType } from '../../../shared/Subscription';
import { useAppSelector } from '../../hooks/stateHooks';
import { EAppRoutes } from '../../core/router';
import Briefcase from '../../icons/Briefcase.svg';
import { SideMenuItemsList } from './SideMenuStyles';

type Props = {
  isOpened: boolean
  listProps?: ListProps
};

const SideMenuList = ({ isOpened, listProps }: Props) => {
  const { pathname } = useLocation();
  const billing = useAppSelector((state) => state.billing);
  const isBuyer = billing.subscription?.type === ESubscriptionType.BUYER;

  const menuItems = useMemo<SideMenuItemType[]>(() => {
    if (pathname === EAppRoutes.billing && isBuyer) {
      return [
        {
          text: 'Deals',
          icon: Briefcase,
          link: EAppRoutes.home,
          sidebarTab: null,
        },
      ];
    }

    return sideMenuItems;
  }, [pathname, isBuyer]);

  const items = menuItems.map((item) => (
    <SideMenuItem
      key={item.text}
      premiumOnly={item.premiumOnly}
      expanded={isOpened}
      text={item.text}
      // icon={<NormalizedSvgIcon component={item.icon} />}
      icon={item.icon}
      link={item.link}
      menuTab={item.menuTab}
      disabled={item.disabled}
      tooltip={item.tooltip}
      subscriptionExeptions={item.subscriptionExeptions}
      adminOnly={item.adminOnly}
    />
  ));

  return (
    <SideMenuItemsList
      className={classNames({ 'side-menu-items-list-mobile': isMobile })}
      disablePadding
    >
      { items }
    </SideMenuItemsList>
  );
};

export default memo(SideMenuList);
