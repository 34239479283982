import { styled } from '@mui/styles';
import { Box, Dialog } from '@mui/material';

export const TemplatePickerDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& .dialog-paper': {
    overflow: 'visible',
    boxShadow: 'none',
    maxWidth: '1415px',
    marginTop: '40px',
    maxHeight: 'calc(100vh - 100px)',
    height: '100%',
    borderRadius: '16px',
    width: '100%',
    '@media (max-height: 420px)': {
      overflow: 'auto',
    },
    '@media (max-width: 420px)': {
      maxWidth: '100%',
      justifyContent: 'center',
    },
    '& .grid-container': {
      minWidth: '600px',
      '@media (max-width: 600px)': {
        maxWidth: '100%',
        minWidth: 'unset',
      },
    },
    '& .grid-container.grid-container-mobile': {
      minWidth: 'auto',
      height: '100%',
    },
    '& .paper-wrapper': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderRadius: '16px',
      boxShadow: 'none',
      maxHeight: '745px',
      minWidth: '600px',
      height: 'auto',
      '@media (max-width: 600px)': {
        maxWidth: '100%',
        minWidth: 'unset',
      },
    },
    '& .paper-wrapper.paper-wrapper-mobile': {
      maxHeight: '100%',
      minWidth: 'auto',
      width: '100%',
    },
    '& .dialog-title-container': {
      padding: '29px 29px 16px 29px',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .dialog-title': {
        fontSize: '20px',
        fontWeight: 600,
        color: '#455273',
        '& .dialog-title-close-icon-button': {
          height: '20px',
          width: '20px',
          '& .dialog-title-close-icon': {
            fontSize: '20px',
            color: '#0073F5',
          },
        },
      },
    },
    '& .dialog-content': {
      maxHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '0',
      '& .dialog-content-paper': {
        '& .MuiTextField-root': {
          m: 1,
          margin: '0',
          fontSize: '16px !important',
        },
        boxShadow: 'none',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-between',
        marginTop: '8px',
        marginBottom: '10px',
        paddingLeft: '29px',
        paddingRight: '29px',
        '& .text-field': {
          width: '100%',
          '& .input': {
            borderRadius: '8px',
            minHeight: '48px',
            maxHeight: '179px',
            fontSize: '16px',
            padding: '0px 14px',
            alignContent: 'flex-end',
            overflow: 'hidden',
            '& .MuiChip-root': {
              margin: '2px 5px 2px 0px',
              borderRadius: '8px',
              height: '24px',
              fontSize: '13px',
              border: '1px solid #bdbdbd',
              '& .MuiSvgIcon-root': {
                color: 'rgba(0, 0, 0, 0.26)',
                '&:hover': {
                  color: 'rgba(0, 0, 0, 0.4)',
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
            },
            minWidth: '200px',
            flex: 1,
          },
        },
      },
      '& .users-list': {
        paddingLeft: '29px',
        paddingRight: '29px',
        '& .user-item-wrapper': {
          minHeight: '60px',
          border: '1px solid #EEF0FA',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '8px',
          padding: '10px 12px',
          justifyContent: 'space-between',
          flexDirection: 'row',

          '& .user-name-block': {
            display: 'flex',
            alignItems: 'center',
            flex: 5,
            '& .avatar-wrapper': {
              cursor: 'pointer',
            },
            '& .user-title-container': {
              height: '40px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            '& .user-name': {
              marginLeft: '13px',
              fontSize: '16px',
              fontWeight: 500,
              maxWidth: '250px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              lineHeight: '20px',
            },
            '& .user-name.user-name-mobile': {
              fontSize: '14px',
              maxWidth: '180px',
              lineHeight: '12px',
            },
            '& .user-title': {
              marginLeft: '13px',
              fontSize: '12px',
              fontWeight: 400,
              color: 'rgba(170, 170, 170, 1)',
            },
          },
          '& .user-controls-block': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flex: 1,
            '& .role-picker-wrapper': {
              display: 'flex',
              minWidth: '100px',
              justifyContent: 'right',
              '& .role-picker': {
                '& .MuiSelect-select': {
                  padding: '10px 30px 10px 10px !important',
                },
              },
            },
            '& .role-picker-wrapper.role-picker-wrapper-mobile': {
              justifyContent: 'left',
              display: 'none',
            },
            '& .menu': {
              marginLeft: '10px',
              opacity: 1,
            },
            '& .menu.menu-hidden': {
              opacity: 0,
            },
          },
        },
        '& .user-item-wrapper.user-item-wrapper-mobile': {
          alignItems: 'flex-start',
        },
      },
      '& .circular-progress': {
        color: '#fff',
      },
    },
    '& .dialog-content.dialog-content-mobile': {
      maxHeight: '100%',
    },
  },
  '& .dialog-paper.dialog-paper-mobile': {
    width: '100%',
    margin: '0px',
    maxHeight: '100%',
  },
}));

export const CreateDealFormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '&.mobile': {
    padding: '16px 16px 8px 16px',
    justifyContent: 'space-between',
  },
  // Paper
  '& .MuiPaper-root, .MuiPaper-elevation, .css-1trks2i-MuiPaper-root-MuiDialog-paper': {
    maxWidth: '500px',
    width: '100%',
    borderRadius: '5px',
    '& h2': {
      margin: '0',
    },
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(128, 128, 128, .5)',
      opacity: '50%',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#7E7E7E',
    },
  },
  // Title
  '& .MuiTypography-root, .MuiTypography-h6, .MuiDialogTitle-root, .css-kxlkcf-MuiTypography-root-MuiDialogTitle-root': {
    boxShadow: 'none',
    margin: '0',
    padding: '0',
  },
  // FieldsContainer
  '& .MuiDialogContent-root, .css-qu6ihs-MuiDialogContent-root': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-evenly',
    height: 'auto',
    padding: '0',
    width: '100%',
    '& .MuiFormControl-root, .MuiTextField-root, .css-1u3bzj6-MuiFormControl-root-MuiTextField-root': {
      width: '100%',
      margin: '5px 0',
    },
  },
  // ButtonsContainer
  '& .MuiDialogActions-root, .MuiDialogActions-spacing, .css-hlj6pa-MuiDialogActions-root': {
    justifyContent: 'space-around',
    marginTop: '10px',
    padding: '0',
    '& .StyledCreateBytton': {
      width: '100%',
      backgroundColor: '#62B839',
      color: '#fff',
      borderRadius: '5px',
      height: '3rem',
    },
  },
  '#client-name-field': {
    width: '100%',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    height: '40px',
    alignItems: 'center',
  },
}));
