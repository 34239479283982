import {
  createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import { ProcessDTO, ProcessStatsDTO } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { ProcessApi } from '../../../api/ProcessApi';
import { ProcessStatsApi } from '../../../api/ProcessStatsApi';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import { prepareStats } from './calculate-status-and-last-milestone';
import { updateProcess } from './dealsSlice';
import { DealsType } from '../../../../shared/process/Process';

const processApi: ProcessApi = new ProcessApi();
const processStatsApi: ProcessStatsApi = new ProcessStatsApi();

const teamDealsAdapter = createEntityAdapter<ProcessDTO>({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

export enum ETeamDealsActions {
  GET_TEAM_DEALS = 'GET_TEAM_DEALS',
  GET_TEAM_DEALS_STATS = 'GET_TEAM_DEALS_STATS',
  GET_TEAM_DEALS_OWNERS = 'GET_TEAM_DEALS_OWNERS',
}

const teamDealsReducerName: string = 'teamDeals';

export const initialState = teamDealsAdapter.getInitialState({
  status: EStateStatus.IDLE,
  isProcessStatsLoaded: false,
  isFirstFetchFinished: false,
});

export const getTeamDeals = createAsyncThunk(
  `${teamDealsReducerName}/${ETeamDealsActions.GET_TEAM_DEALS}`,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.getTeamDeals({
      search: state.dealsGridFilter.search,
      params: state.dealsGridFilter.gridFilters[DealsType.TEAM_DEALS],
    });
  },
);

export const getTeamProcessesStats = createAsyncThunk(
  `${teamDealsReducerName}/${ETeamDealsActions.GET_TEAM_DEALS_STATS}`,
  async (payload: string[]) => processStatsApi.getTeamDealsStats(payload),
);

export const teamDealsSlice = createSlice({
  name: teamDealsReducerName,
  initialState,
  reducers: {
    clearTeamDeals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamDeals.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.isProcessStatsLoaded = false;
      })
      .addCase(getTeamDeals.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        teamDealsAdapter.setAll(state, action.payload);
        state.isFirstFetchFinished = true;
      })
      .addCase(getTeamDeals.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getTeamProcessesStats.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getTeamProcessesStats.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        action.payload.forEach((processStats: ProcessStatsDTO) => {
          teamDealsAdapter.updateOne(state, {
            id: processStats.processId,
            changes: {
              processStats: {
                ...processStats,
                ...prepareStats(processStats.tasks),
              },
            },
          });
        });
        state.isProcessStatsLoaded = true;
      })
      .addCase(getTeamProcessesStats.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        teamDealsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
      });
  },
});

export const {
  selectAll,
  selectIds,
} = teamDealsAdapter.getSelectors();

export const teamDealsSelector = (state: RootState) => selectAll(state.teamDeals);
export const selectFirstFetchStatus = (state: RootState): boolean => state.teamDeals.isFirstFetchFinished;
