import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PolicyDisclaimer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography
        sx={{
          color: '#7084B4',
          textAlign: 'center',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '16px',
          '& a': {
            fontStyle: 'inherit',
            color: '#7084B4',
            textDecoration: 'none',
          },
        }}
      >
        <a
          target="_blank"
          href="https://stageset.io/privacy-policy-for-3c-automation-ag/"
          rel="noreferrer"
        >
          {t('Room_View_your_room_disclaimer')}
        </a>
        .
      </Typography>
    </Box>
  );
};

export default PolicyDisclaimer;
