import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { EJWTTokenType, IShareJWTTokenPayload, TUnifiedToken } from '../../../../shared/schema/token.schema';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { useQuery } from '../../../hooks/useQuery';
import { setTokenAndTokenType } from '../state/authState';
import { Analytics, EMixPanelEvents } from '../../../core/Analytics';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';

const analytics: Analytics = Analytics.getInstance();

const GoogleAuthRedirect = (): JSX.Element => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token: string | null = query.get('token');
  const authType: string | null = query.get('auth_type');
  const token_type: EJWTTokenType | null = query.get('token_type') as EJWTTokenType;
  const error: string | null = query.get('error');
  const isSignup: boolean = authType === 'signup';

  if (token_type && token && !error) {
    localStorage.setItem('token', token);
    localStorage.setItem('tokenType', token_type);
    dispatch(setTokenAndTokenType({
      tokenType: token_type,
      access_token: token,
    }));
    const { email }: IShareJWTTokenPayload = jwtDecode<TUnifiedToken>(token);
    analytics.track(EMixPanelEvents.ACCOUNT_SOCIAL_LOGIN);
    analytics.userLogin({
      data: { email },
    });
  }

  const onBackToLoginClick = (): void => {
    if (isSignup) {
      navigate(`${EAppRoutes.auth}/${EAppRoutes.signup}`);
    } else {
      navigate(`${EAppRoutes.auth}/${EAppRoutes.login}`);
    }
  };

  return (
    <AuthPaper>
      <Box
        sx={{
          width: '325px',
          margin: '16px 0',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-around',
          textAlign: 'center',
        }}
      >
        {
        !error && (
          <CircularProgress sx={{ mt: '32px' }} color="primary" />
        )
      }
        {
        error && (
          <>
            <Typography variant="body2" color="info">{error}</Typography>
            <Typography
              color="primary"
              onClick={() => onBackToLoginClick()}
              sx={{
                cursor: 'pointer',
                marginTop: '32px',
                textDecoration: 'underline',
              }}
            >
              Back to
              {' '}
              {
                isSignup ? 'Signup' : 'Login'
              }
            </Typography>
          </>
        )
      }
      </Box>
    </AuthPaper>
  );
};

export default GoogleAuthRedirect;
