import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#7084B4',
    color: '#fff',
    padding: '4px 8px',
    boxShadow: theme.shadows[1],
    fontSize: '12px',
    lineHeight: '16px',
    borderRarius: '4px',
  },
}));

export default LightTooltip;
