import { SvgIcon, SvgIconProps } from '@mui/material';

export const MenuLogo22 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="26"
    height="22"
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '22px',
      width: '26px',
    }}
    {...props}
  >
    <path
      d="M21.3225 15.0363L17.4623 15.043L14.699
      11.3941C14.5649 11.217 14.4824 11.0055 14.4608
      10.7834C14.4392 10.5613 14.4793 10.3375 14.5766
      10.1373C14.674 9.9371 14.8246 9.76848 15.0116
      9.65051C15.1986 9.53253 15.4144 9.4699 15.6347
      9.4697L20.8861 9.46484C21.0674 9.46468 21.2463
      9.50681 21.409 9.58798C21.5716 9.66916 21.7136
      9.78718 21.824 9.93292L23.0287 11.5238C24.1153
      12.9587 23.1077 15.0332 21.3225 15.0363Z"
      fill="url(#paint0_linear_32223_4631)"
    />
    <path
      d="M18.7497 21.6014C18.7454 21.6014 18.7412
      21.6014 18.737 21.6014L1.51677 21.6172C0.388393
      21.6183 -0.0980504 20.1666 0.797197 19.4699L6.23653
      15.2367C6.44237 15.0765 6.6945 14.9896 6.95394
      14.9893L17.484 14.9796L21.343 14.9729C23.1277
      14.9698 24.135 12.8933 23.0487 11.457L23.4231
      11.952C26.4031 15.8921 23.6416 21.5869 18.7497
      21.6014Z"
      fill="#0894E4"
    />
    <path
      d="M4.94055 7.18054L8.80081 7.17383L11.564
      10.8227C11.6981 10.9998 11.7807 11.2113 11.8023
      11.4334C11.824 11.6555 11.7838 11.8793 11.6865
      12.0795C11.5891 12.2797 11.4385 12.4483 11.2515
      12.5663C11.0645 12.6843 10.8486 12.7469 10.6284
      12.7471L5.37697 12.752C5.19567 12.7521 5.01676
      12.71 4.85412 12.6288C4.69148 12.5477 4.54947
      12.4296 4.43911 12.2839L3.23439 10.693C2.14776
      9.25811 3.15538 7.18362 4.94055 7.18054Z"
      fill="url(#paint1_linear_32223_4631)"
    />
    <path
      d="M7.49043 0.515846C7.49467 0.515846 7.49882
      0.515846 7.50306 0.515846L24.6539 0.500001C25.7777
      0.498956 26.2622 1.95059 25.3705 2.64732L19.9531
      6.88049C19.7481 7.04069 19.497 7.12763 19.2386
      7.12787L8.75104 7.13764L4.90752 7.14436C3.13009
      7.14745 2.12684 9.22391 3.20876 10.6602L2.83588
      10.1652C-0.132115 6.22511 2.61818 0.530337
      7.49043 0.515846Z"
      fill="#27AE60"
    />
    <defs>
      <linearGradient
        id="paint0_linear_32223_4631"
        x1="22.1865"
        y1="18.7863"
        x2="15.8342"
        y2="5.07953"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.1617"
          stopColor="#631A13"
        />
        <stop
          offset="0.8096"
          stopColor="#D74F3C"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_32223_4631"
        x1="4.65666"
        y1="6.84321"
        x2="9.05773"
        y2="12.9646"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.1617"
          stopColor="#631A13"
        />
        <stop
          offset="0.9814"
          stopColor="#D74F3C"
        />
      </linearGradient>
    </defs>
  </SvgIcon>
);
