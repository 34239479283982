import { SvgIcon, SvgIconProps } from '@mui/material';

export const ClearIcon16px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '16px',
    }}
  >
    <path
      d="M9 3L3 9"
      stroke="#7084B4"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3 3L9 9"
      stroke="#7084B4"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const ClearIcon20px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '21px',
      height: '20px',
    }}
  >
    <path
      d="M15.125 5L5.125 15"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.125 5L15.125 15"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
