import {
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { CloseIcon } from '../../../../icons/Close16px';
import useDebounce from '../../../../hooks/useDebounce';
import { UrlCheckResponse, checkUrl } from './helpers/link.helper';
import { AppLoader } from '../../../../shared/AppLoader/AppLoader';
import { AppInput } from '../../../../shared/AppInput/AppInput';

enum LinkInpuState {
  EMPTY = 'EMPTY',
  CHECKING = 'CHECKING',
  WITH_VALUE = 'WITH_VALUE',
}

type Props = {
  onLinkAdd: (link: string) => void;
};

const MediaFieldMenuEmbedLink = ({
  onLinkAdd,
}: Props): JSX.Element => {
  const [link, setLink] = useState('');
  const [linkInputState, setLinkInputState] = useState(LinkInpuState.EMPTY);
  const [isLinkValid, setIsLinkValid] = useState(true);
  const debouncedValue = useDebounce(link, 300);

  const onInsert = () => {
    onLinkAdd(link);
  };

  const handleUrlCheck = (url: string) => {
    setLinkInputState(LinkInpuState.CHECKING);

    checkUrl(url)
      .then((response: UrlCheckResponse) => {
        if (response.status) {
          setIsLinkValid(true);
        } else {
          setIsLinkValid(false);
        }
        setLinkInputState(LinkInpuState.WITH_VALUE);
      })
      .catch(() => {
        setIsLinkValid(false);
        setLinkInputState(LinkInpuState.WITH_VALUE);
      });
  };

  useEffect(() => {
    setIsLinkValid(true);
  }, [link]);

  useEffect(() => {
    if (debouncedValue) {
      handleUrlCheck(debouncedValue);
    }
  }, [debouncedValue]);

  const inputButtonIcon = () => {
    switch (linkInputState) {
      case LinkInpuState.WITH_VALUE:
        return (
          <CloseIcon
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setLink('');
            }}
          />
        );
      case LinkInpuState.CHECKING:
        return (
          <Box
            id="spinner-container"
            sx={{
              padding: '4px !important',
            }}
          >
            <AppLoader
              size={32}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        padding: '15px 6px 0',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <AppInput
          fullWidth
          placeholder="Link"
          color="primary"
          defaultValue={link}
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
          error={!isLinkValid}
          helperText={isLinkValid ? '' : 'Incorrect link'}
          InputProps={{
            endAdornment: inputButtonIcon(),
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          paddingTop: '20px',
        }}
      >
        <AppButton
          variant="primary"
          size="l"
          fullWidth
          onClick={onInsert}
          disabled={!link}
        >
          Save
        </AppButton>
      </Box>
    </Box>
  );
};

export default MediaFieldMenuEmbedLink;
