import { ApiService } from '../core/api';
import {
  CheckUrlRequest,
  CheckUrlResponse,
  FileCreateData,
  FileGetByTypeRequest,
  FileGetRequest,
  FileUpdateData,
  FileWithOwnerDTO,
  ProductCreateData,
  ProductGetRequest,
  ProductUpdateData,
  ProductWithOwnerDTO,
  UrlAndTypeResponse,
} from '../../shared/library/LibraryDTO';
import { LibraryFilesFilterInfo, LibraryFilesMultipleFilter } from '../../shared/library/LibraryFilter';

export class LibraryApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('library');
  }

  async createFileItem(payload: FileCreateData): Promise<FileWithOwnerDTO> {
    const formData: FormData = new FormData();
    formData.append('name', payload.name);
    formData.append('userId', payload.userId);
    formData.append('organizationId', payload.organizationId);
    formData.append('type', payload.type);
    formData.append('link', payload.link || '');
    formData.append('linkType', payload.linkType || '');
    formData.append('fileSubtype', payload.fileSubtype || '');
    if (payload.file) {
      formData.append('file', await payload.file.slice());
    }

    return this.api.post<FileWithOwnerDTO>('files', formData);
  }

  async updateFileItem(payload: FileUpdateData): Promise<FileWithOwnerDTO> {
    const formData: FormData = new FormData();
    formData.append('id', payload.id);
    formData.append('name', payload.name);
    formData.append('userId', payload.userId);
    formData.append('organizationId', payload.organizationId);
    formData.append('type', payload.type);
    formData.append('link', payload.link || '');
    formData.append('fileUid', payload.fileUid);
    formData.append('linkType', payload.linkType || '');
    formData.append('fileSubtype', payload.fileSubtype || '');
    if (payload.file) {
      formData.append('file', await payload.file.slice());
    }

    return this.api.put<FileWithOwnerDTO>(`files/${payload.id}`, formData);
  }

  async getFileItems(payload: FileGetRequest): Promise<FileWithOwnerDTO[]> {
    return this.api.get<FileWithOwnerDTO[]>(`files${LibraryApi.getPayloadToFilesQuery(payload)}`);
  }

  async getFileItemsByType(payload: FileGetByTypeRequest): Promise<FileWithOwnerDTO[]> {
    let queryString: string = '';

    if (payload?.types && payload?.types?.length) {
      queryString = `?types=${payload.types.join(',')}`;
    }
    return this.api.get<FileWithOwnerDTO[]>(`files${queryString}`);
  }

  async deleteFileItem(libItemId: string): Promise<void> {
    return this.api.delete(`files/${libItemId}`);
  }

  async createProductItem(payload: ProductCreateData): Promise<ProductWithOwnerDTO> {
    return this.api.post<ProductWithOwnerDTO>('products', payload);
  }

  async updateProductItem(payload: ProductUpdateData): Promise<ProductWithOwnerDTO> {
    return this.api.put<ProductWithOwnerDTO>(`products/${payload.id}`, payload);
  }

  async getProductItems(payload: ProductGetRequest): Promise<ProductWithOwnerDTO[]> {
    return this.api.get<ProductWithOwnerDTO[]>(`products${LibraryApi.getPayloadToQuery(payload)}`);
  }

  async deleteProductItem(libItemId: string): Promise<void> {
    return this.api.delete(`products/${libItemId}`);
  }

  async getDownloadFile(payload: string): Promise<any> {
    return this.api.get(`file/download/${payload}`, {
      responseType: 'blob',
    });
  }

  async getFilterInfo(): Promise<LibraryFilesFilterInfo> {
    return this.api.get('files-filter-info');
  }

  async getProductFilterInfo(): Promise<LibraryFilesFilterInfo> {
    return this.api.get('products-filter-info');
  }

  async checkUrl(payload: CheckUrlRequest): Promise<CheckUrlResponse> {
    return this.api.post('check-url', payload);
  }

  async getUrlByFileUid(fileUid: string): Promise<UrlAndTypeResponse> {
    return this.api.get(`file/get-url/${fileUid}`);
  }

  private static getPayloadToQuery(payload: ProductGetRequest): string {
    const queryValues: string[] = [];

    if (payload.search) {
      queryValues.push(
        `name=${encodeURIComponent(payload.search)}`,
      );
    }
    if (payload.filter) {
      if ((payload.filter?.owner as LibraryFilesMultipleFilter)?.subItems) {
        const owners: string[] = [];
        for (const owner of (payload.filter.owner as LibraryFilesMultipleFilter).subItems) {
          owners.push(owner.value);
        }
        if (owners.length > 0) {
          queryValues.push(
            `owners=${owners.join(',')}`,
          );
        }
      }
    }

    return queryValues.length > 0 ? `?${queryValues.join('&')}` : '';
  }

  private static getPayloadToFilesQuery(payload: FileGetRequest): string {
    const queryValues: string[] = [];
    if (payload.search) {
      queryValues.push(
        `name=${encodeURIComponent(payload.search)}`,
      );
    }
    if (payload.filter) {
      if ((payload.filter?.owner as LibraryFilesMultipleFilter)?.subItems) {
        const owners: string[] = [];
        for (const owner of (payload.filter.owner as LibraryFilesMultipleFilter).subItems) {
          owners.push(owner.value);
        }
        if (owners.length > 0) {
          queryValues.push(
            `owners=${owners.join(',')}`,
          );
        }
      }
      if ((payload.filter?.type as LibraryFilesMultipleFilter)?.subItems) {
        const types: string[] = [];
        for (const type of (payload.filter.type as LibraryFilesMultipleFilter).subItems) {
          types.push(type.value);
        }
        if (types.length > 0) {
          queryValues.push(
            `types=${types.join(',')}`,
          );
        }
      }
    }

    return queryValues.length > 0 ? `?${queryValues.join('&')}` : '';
  }
}
