import { styled } from '@mui/styles';
import { Avatar, Box, Typography } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const HiddenInput = styled('input')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
}));

export const SettingsAvatar = styled(Avatar)(() => ({
  width: 72,
  height: 72,
  border: stagesetColors.newGrey[200],
  background: 'white',
  '& svg': {
    fontSize: '40px',
  },
}));

export const SettingsAvatarPlaceholder = styled(Typography)(() => ({
  width: 72,
  height: 72,
  border: '1px solid',
  borderRadius: '50%',
  color: stagesetColors.newGrey[600],
  borderColor: stagesetColors.white[100],
  backgroundColor: stagesetColors.newGrey[100],
  fontWeight: 600,
  fontSize: '32px',
  textAlign: 'center',
  lineHeight: '72px',
}));

export const AvatarFieldWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '72px',
  gap: '16px',
  gridColumn: 'span 2',
  '&.avatar-field-wrapper-teammanager': {
    gap: '24px',
  },
}));

export const TeammateTitle = styled(Typography)(() => ({
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
}));

export const TeammateTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const AvatarCaption = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
  '&.settings-avatar-caption-error': {
    color: stagesetColors.red[600],
  },
}));

export const AvatarButtonGroup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const AvatarControlsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));
