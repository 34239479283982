import styled from '@emotion/styled';
import { Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { isMobile } from 'react-device-detect';
import { stagesetColors, stagesetPalette } from '../../../theme/stagesetPalette';

export const StyledDealsTabButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  flex: 0,
  width: '100%',
  '&.mobile': {
    height: '60px',
    maxHeight: '60px',
    minHeight: '60px',
    padding: '0 10px',
    width: '100%',
  },
}));

// DealListPaper
export const StyledDealListPaper = styled(Paper)(() => ({
  margin: '0',
  border: 'none',
  borderRadius: '0',
  alignSelf: 'center',
  boxShadow: 'none',
  transition: 'none',
  '& .MuiDataGrid-root': {
    border: 'none',
  },
}));

// DataGrid/Header+rows
export const DealsGrid = styled(DataGrid)((theme) => ({
  width: '100%',
  border: 'none',
  borderRadius: '0 !important',
  '& .MuiDataGrid-main': {
    height: 'calc( 100vh - 160px )', // TODO calculate height propertly
    width: '100%',
    alignSelf: 'center',
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'none',
      borderTop: 'none',
      minHeight: '100px',
    },
  },

  '& .MuiDataGrid-virtualScroller': {
    background: '#fff',
  },

  '& .MuiDataGrid-columnHeader': {
    padding: 0,
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },

  '& .MuiDataGrid-columnHeaderWrapper': {
    paddingLeft: '16px',
  },
  // data container
  '& .MuiDataGrid-root, .MuiDataGrid-dataContainer': {
    minWidth: isMobile ? window.innerWidth : '900px !important',
  },
  // row
  '& .MuiDataGrid-row': {
    width: '100%',
    border: '1px solid #EEF0FA',
    backgroundColor: 'white',
    boxShadow: '0px 0px 2px -2px rgba(0, 57, 96, 0.1), 0px 0px 5px -2px rgba(0, 57, 96, 0.05)',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid #E5EDFF',
      backgroundColor: '#F5F9FF',
    },
    '&.SelectedRow': {
      border: `1px solid ${stagesetPalette.newGrey?.[400]}`,
    },
  },
  '& .MuiDataGrid-root, .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: 0,
    padding: '8px 24px',
    background: '#fff',
    border: '1px solid #EEF0FA',
    borderRadius: '16px 16px 0px 0px',
  },
  // header
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeaderWrapper': {
    '&.MuiDataGrid-root, .MuiDataGrid-columnHeader--sortable, .MuiDataGrid-columnHeader': {
      padding: '0 5px',
      outline: 'none !important',
    },
  },
  // header column aligment
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeader--alignCenter, .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'flex-start',
    fontSize: '14px',
    textTransform: 'uppercase',
    '& .MuiDataGrid-columnHeaderTitle': {
      textTransform: 'none',
      fontWeight: '400',
      color: stagesetPalette.grey?.[400],
    },
  },
  // scrollbar
  '& .MuiDataGrid-root, .MuiDataGrid-window': {
    '&::-webkit-scrollbar': {
      overflow: 'auto',
      width: '7px',
      height: '7px',
      display: isMobile ? 'none' : '',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(128, 128, 128, .5)',
      opacity: '50%',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#7E7E7E',
    },
  },
  '& .MuiDataGrid-cell': {
    borderBottomColor: 'transparent',
    padding: '8px 24px',
  },
  // rows
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    left: 0,
    right: 0,
  },
  '& .MuiDataGrid-renderingZone': {
    width: '100%',
    // row
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #F1F1F1',
      width: '100%',
      height: '100%',
      color: 'black',
      borderRight: '1px solid #F9F9F9',
      alignItems: 'center',
      fontSize: '13px',
      ':hover': {
        background: '#F9F9F9',
        transition: '0.3s',
        '& .StyledMoreMenuButton': {
          visibility: 'visible',
          backgroundColor: '#F9F9F9',
        },
        '& .DealStatusText': {
          color: 'black',
        },
      },
      // cells
      '& .MuiDataGrid-cell, .pro-grid-cell, .MuiDataGrid-cell--textLeft': {
        border: 'none',
        height: '100%',
        outline: 'none !important',
        '& .RowLink': {
          width: '100%',
          color: 'inherit',
          height: '51px',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  },
  '&.hiddenHeader .MuiDataGrid-columnHeader': {
    display: 'none',
  },
}));

export const LastActiveContainer = styled(Box)(() => ({
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  '& .LastActiveName': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '22px',
    fontSize: 'inherit',
  },
  '& .LastActiveDate': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '19px',
    fontSize: '12px',
    marginLeft: '5px',
    color: 'rgba(126, 126, 126, 1)',
  },
}));
