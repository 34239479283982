import * as React from 'react';
import { Container, CssBaseline, Typography } from '@mui/material';
import { AppButton } from '../shared/AppButton/AppButton';
import { config } from '../core/config';
import { EAppRoutes } from '../core/router';

export type ErrorPageProps = {
  error?: Error;
};

export const UserNotFound = (props: ErrorPageProps): JSX.Element => {
  const { error } = props;

  const goToLogin = () => {
    window.location.href = `${config.app.origin}/${EAppRoutes.login}`;
  };

  return (
    <Container sx={{ marginTop: '43vh' }}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontSize: '2em',
            fontWeight: 300,
            '& strong': {
              fontWeight: 400,
            },
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <strong>
            User not found :(
            {JSON.stringify(error, null, 2)}
          </strong>
        </Typography>
        <AppButton
          onClick={goToLogin}
          fullWidth
          variant="primary"
          size="l"
        >
          Go to Login
        </AppButton>
      </Container>
    </Container>
  );
};
