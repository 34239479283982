import { useEffect } from 'react';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';
import { useAppDispatch } from '../../hooks/stateHooks';
import { addFieldToRefetch } from '../Layout/lib/fieldsLayoutSlice';

// Plugin for live updates
const ProcessFieldsControllerPlugin = (): null => {
  const dispatch = useAppDispatch();

  // Add field to refetch for live updates
  const changeSource = (e: Event & { detail: string }) => {
    dispatch(addFieldToRefetch(e.detail));
  };

  useEffect(() => {
    document.addEventListener(ECustomEventsNames.REFETCH_PROCESS_FIELD, changeSource as EventListener);
    return () => document.removeEventListener(ECustomEventsNames.REFETCH_PROCESS_FIELD, changeSource as EventListener);
  }, []);

  return null;
};

export default ProcessFieldsControllerPlugin;
