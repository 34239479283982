import { useNavigate } from 'react-router-dom';
import {
  MouseEvent, useRef, useState,
} from 'react';
import { Box, Tooltip } from '@mui/material';
import { iconColorPalette } from '../../routes-old/process/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  BottomBox,
  OwnerCardPopover,
  RocketButtonPlaceholder,
  StyledAvatar,
  UpgradePopoverSubtitle,
  UpgradePopoverTitle,
  UpgradeSubscriptionPopover,
} from './SideMenuStyles';
import { EAppRoutes } from '../../core/router';
import { ESubscriptionType } from '../../../shared/Subscription';
import {
  ESettingsTabs, ESidebarTabs, setSettingsTab, setSidebarTab,
} from '../Layout/lib/fieldsLayoutSlice';
import { RocketIcon20 } from '../../icons/RocketIcon';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import SideMenuFooter from './SideMenuFooter';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppButton } from '../../shared/AppButton/AppButton';

const SideMenuBottom = () => {
  const upgradeSubscriptionBox = useRef(null);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.app.user);
  const billing = useAppSelector((state) => state.billing);
  // const hideTryPremiumSection = !isOpened || isBuyer || !shouldDisplay || (tokenType === EJWTTokenType.SHARE);
  const showTryPremiumSection = billing.subscription?.type === ESubscriptionType.SELLER;
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [upgradePopoverAnchor, setUpgradePopoverAnchor] = useState<Element | null>(null);
  const [ownerPopoverStatus, setOwnerPopoverStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleUserAvatarClick = (event: MouseEvent<Element>) => {
    setOwnerPopoverStatus(!ownerPopoverStatus);
    setAnchor(event.currentTarget ? event.currentTarget : null);
  };

  const handleSubscriptionsSettingsButtonClick = () => {
    navigate(EAppRoutes.settings);
    dispatch(setSidebarTab(ESidebarTabs.SETTINGS));
    sessionStorage.setItem('settings-tab', ESettingsTabs.SUBSCRIPTION);
    dispatch(setSettingsTab(ESettingsTabs.SUBSCRIPTION));
    setUpgradePopoverAnchor(null);
  };

  return (
    <BottomBox>
      {showTryPremiumSection
        ? (
          <Box
            ref={upgradeSubscriptionBox}
          >
            <Tooltip
              title="Subscriptions"
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    background: stagesetColors.newGrey[800],
                  },
                },
              }}
            >
              <AppIconButton
                disableRipple
                variant="gradient"
                size="l"
                icon={<RocketIcon20 />}
                onClick={(event) => setUpgradePopoverAnchor(event.currentTarget)}
              />
            </Tooltip>
            <UpgradeSubscriptionPopover
              open={!!upgradePopoverAnchor}
              anchorEl={upgradePopoverAnchor}
              onClose={() => setUpgradePopoverAnchor(null)}
              marginThreshold={26}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box>
                <UpgradePopoverTitle>
                  Upgrade your plan
                </UpgradePopoverTitle>
                <UpgradePopoverSubtitle>
                  Get more information about your customers actions
                </UpgradePopoverSubtitle>
              </Box>
              <AppButton
                startIcon={<RocketIcon20 />}
                variant="tertiary-newGrey"
                fullWidth
                disableRipple
                onClick={handleSubscriptionsSettingsButtonClick}
              >
                Upgrade
              </AppButton>
            </UpgradeSubscriptionPopover>
          </Box>
        ) : (
          <RocketButtonPlaceholder />
        )}
      <AppIconButton
        size="xxl"
        disableRipple
        onClick={handleUserAvatarClick}
        icon={(
          <StyledAvatar
            alt={user?.name || ''}
            src={user?.avatarSrc}
            sx={{
              color: user?.colorId ? iconColorPalette[user.colorId].letter : '',
              background: user?.colorId ? iconColorPalette[user.colorId].back : '',
            }}
          >
            {user?.name ? user?.name[0] : undefined}
          </StyledAvatar>
        )}
      />
      <OwnerCardPopover
        id={ownerPopoverStatus ? 'owner-card-popover' : undefined}
        open={ownerPopoverStatus}
        anchorEl={anchor}
        onClose={handleUserAvatarClick}
        marginThreshold={12}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <SideMenuFooter
          setAnchor={setAnchor}
          setOwnerPopoverStatus={setOwnerPopoverStatus}
        />
      </OwnerCardPopover>
    </BottomBox>
  );
};

export default SideMenuBottom;
