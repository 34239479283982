import { AppIconButton } from '../../../../../../shared/AppButton/AppIconButton';
import { useBlockContext } from '../../../../TiptapBlockContextProvider';
import { MediaNodeAttrs } from '../../../../../../../shared/Tiptap/TiptapTypes';
import AdjustByHeight from '../../../../../../icons/AdjustByHeight.svg';
import AdjustByWidth from '../../../../../../icons/AdjustByWidth.svg';
import WideScreenPdfSectionMenu from '../../../../../BlockContentSection/BlockContentRowMenu/WideScreenPdfSectionMenu';
import { EFieldsubType } from '../../../../../../../shared/Field/Field';

interface Props {
  isPdf?: boolean;
}

const ToolbarAdjustButton = ({ isPdf }: Props): JSX.Element | null => {
  const { data, onUpdate } = useBlockContext<MediaNodeAttrs>();
  const onAdjustByUpdate = () => {
    if (data.adjustBy === 'width') {
      onUpdate({ adjustBy: 'height' });
    } else {
      onUpdate({ adjustBy: 'width' });
    }
  };
  switch (data?.subType) {
    case EFieldsubType.IMG:
      return (
        <AppIconButton
          onClick={onAdjustByUpdate}
          icon={data?.adjustBy === 'width' ? <AdjustByWidth /> : <AdjustByHeight />}
        />
      );
    case EFieldsubType.FILE:
      switch (isPdf) {
        case true:
          return <WideScreenPdfSectionMenu />;
        default:
          return null;
      }
    default:
      return null;
  }
};

export default ToolbarAdjustButton;
