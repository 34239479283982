import { Opportunity } from '../../shared/crm/Opportunity';
import { ApiService } from '../core/api';

export class CrmApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('crm');
  }

  async getOpportunities(): Promise<Opportunity[]> {
    return this.api.get<Opportunity[]>('/opportunities');
  }

  async getDealContacts(dealId: string): Promise<any[]> {
    return this.api.get<any[]>(`/deal/${dealId}/contacts`);
  }

  async getUserIntegration(crmName: string): Promise<any> {
    return this.api.get<any>(`/user-integration/${crmName}`);
  }

  async getProcessOpportunityContacts(processId: string): Promise<any[]> {
    return this.api.get<any[]>(`process-opportunity-contacts/${processId}`);
  }

  async cancelUserIntegration(): Promise<void> {
    await this.api.delete<void>('/user-integration');
  }
}
