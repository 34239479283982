import {
  styled,
  Box,
  Typography,
} from '@mui/material';
import { PickersLayout } from '@mui/x-date-pickers';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../../../../theme/stagesetPalette';

export const AgendaEventLinkButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '100%',
  overflow: 'auto',
}));

export const AgendaEventPopoverHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  padding: '16px 24px',
  justifyContent: 'space-between',
}));
export const AgendaEventPopoverTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const AgendaEventPopoverTitle = styled(Typography)(() => ({
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
}));

export const AgendaEventPopoverSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  color: stagesetColors.newGrey[500],
}));

export const AgendaEventPopoverInputsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 24px 16px 24px',
  gap: '16px',
}));

export const AgendaButtonTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
}));

export const StyledPickersLayout = styled(PickersLayout)(() => ({
  '& .MuiPickersCalendarHeader-label': {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 500,
  },
  '& .MuiPickersCalendarHeader-root': {
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
  },
  ...!isMobile && {
    '& .MuiDateCalendar-root': {
      border: '1px solid',
      borderRadius: '12px',
      borderColor: stagesetColors.newGrey[400],
    },
  },
  '& .MuiDayCalendar-weekDayLabel': {
    color: stagesetColors.newGrey[500],
  },
  '& .MuiButtonBase-root, .MuiPickersYear-yearButton': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    borderRadius: '8px',
    '&:not(.Mui-selected)': {
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[500],
    },
    '&:hover': {
      backgroundColor: stagesetColors.newGrey[200],
    },
    '&.Mui-selected': {
      backgroundColor: stagesetColors.newGrey[800],
      color: stagesetColors.white[100],
      stroke: stagesetColors.white[100],
    },
    '&.Mui-selected:focus': {
      backgroundColor: stagesetColors.newGrey[800],
    },
    '&.Mui-selected:hover': {
      backgroundColor: stagesetColors.newGrey[600],
    },
  },
}));

export const AgendaItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '2px',
  padding: '4px 24px 4px 2px',
  width: '100%',
  overflow: 'auto',
  '&.agenda-items-container-mobile': {
    padding: '4px 24px 4px 24px',
  },
  '&.agenda-file-container': {
    alignItems: 'center',
  },
}));

export const AgendaItemControlsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2px',
  padding: '7px 0px',
}));

export const AgendaFileItemWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '5px 4px',
  justifyContent: 'flex-start',
  width: '100%',
  overflow: 'hidden',
  '&:hover': {
    borderRadius: '8px',
    backgroundColor: stagesetColors.newGrey[50],
    boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  },
}));
