import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlusIcon16 } from '../../icons/PlusIcon';

const StyledAvatarPlaceholder = styled(Avatar)(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  border: '1px dashed',
  borderRadius: '50%',
  background: 'transparent',
}));

interface Props {
  isShifted?: boolean;
  isParentHovered?: boolean;
}

const UserAvatarsEmptyIcon = ({ isShifted, isParentHovered }: Props): JSX.Element => (
  <StyledAvatarPlaceholder
    className="empty-avatar"
    sx={{
      left: isShifted ? '-10px' : 0,
    }}
  >
    <PlusIcon16 />
  </StyledAvatarPlaceholder>
);

export default UserAvatarsEmptyIcon;
