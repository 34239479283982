import {
  Box,
} from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ExtendedGridColumn, ExtendedGridColumns } from '../../types/mobile-table';
import MobileTableRow from './MobileTableRow';

export type MobileGridProps = {
  onRowClick: (row: any) => void;
  renderTitle: (row: any) => string | JSX.Element
  getId: (row: any) => string | number;
  renderMenu: (row: any) => JSX.Element | null
  rows: readonly GridValidRowModel[]
  columns: ExtendedGridColumns
};

const MobileTable = ({
  rows, columns, renderTitle, renderMenu, getId, onRowClick,
}: MobileGridProps) => {
  const renderCellValue = (column: ExtendedGridColumn, row: GridValidRowModel) => {
    if (typeof column.renderMobileCell === 'boolean') {
      return row[column.field];
    }
    return column.renderMobileCell!({ row, value: row[column.field] });
  };

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'overlay',
      gap: '8px',
      paddingBottom: '40px',
      '& .RowLink': {
        height: 'auto',
      },
    }}
    >
      {rows.map((row) => (
        <MobileTableRow
          onClick={() => onRowClick(row)}
          key={getId(row)}
          title={renderTitle(row)}
          menu={renderMenu(row)}
          data={
          columns
            .filter((column) => column.renderMobileCell && column.headerName)
            .map((column) => ({
              key: column.headerName!,
              value: renderCellValue(column, row),
            }))
        }
        />
      ))}
    </Box>
  );
};

export default MobileTable;
