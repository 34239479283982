import classNames from 'classnames';
import { TabContext } from '@mui/lab';
import { FormProvider } from 'react-hook-form';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import {
  ENewPageTabs,
  ESidebarTabs,
  selectNewPageTab,
  selectSidebarTab,
  setNewPageTab,
} from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { CloseIcon20 } from '../../icons/CloseIcon';
import {
  HeaderTitle,
  SidebarHeader,
  SidebarTabContentWrapper,
  SidebarTabPanel,
} from '../Stages/Styles/SidebarStyles';
import NewPageTabFooter from './NewPageTabFooter';
import {
  NewPageFormWrapper,
  NewPagePaddingBox,
  NewPageTabPanel,
} from './Styles/NewPageMainTabStyles';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import NewPageMediaInputs from './NewPageTabComponents/NewPageMediaInputs';
import { NewPageContextProvider } from './NewPageContextProvider/NewPageContextProvider';
import NewPageMediaLibraryList from './NewPageTabComponents/NewPageMediaLibraryList';
import useCreateNewPage from '../../hooks/useCreateNewPage';
import { EFileType } from '../../../shared/library/Library';

const NewPageMediaTab = () => {
  const currentSidebarTab = useAppSelector(selectSidebarTab) === ESidebarTabs.MEDIA;
  const newPageTab = useAppSelector(selectNewPageTab);
  const dispatch = useAppDispatch();

  const {
    newPageFormMethods,
    submitNewPageForm,
    disabled,
    onClose,
    goBack,
    templateError,
    templatesMenuItems,
    templateTitle,
    clearField,
    maxSize,
    stageCreation,
    newPageStateForTabs,
    libraryFileToAdd,
  } = useCreateNewPage();

  const {
    handleSubmit,
  } = newPageFormMethods;

  const nameToWitoutExt = (input: string | undefined): string | undefined => {
    if (!input) {
      return input;
    }
    if (input.lastIndexOf('.') < 0) {
      return input;
    }
    return input.substring(0, input.lastIndexOf('.'));
  };

  return (
    <SidebarTabPanel
      value={ESidebarTabs.MEDIA}
      className={classNames('sidebar-new-page', { 'active-sidebar-tab': currentSidebarTab })}
    >
      <NewPageContextProvider props={{
        methods: {
          clearField,
          goBack,
        },
        data: {
          stageCreation,
          templateTitle,
          templateError,
          templatesMenuItems,
          disabled,
          maxSize,
          libraryFileTitle: nameToWitoutExt(libraryFileToAdd?.name),
          libraryFileToAdd,
        },
      }}
      >
        <NewPageFormWrapper
          component="form"
          onSubmit={handleSubmit(submitNewPageForm)}
        >
          <SidebarTabContentWrapper
            className="sidebar-wrapper-default"
          >
            <SidebarHeader>
              <HeaderTitle
                className="grey-header-title"
              >
                Media
              </HeaderTitle>
              <AppIconButton
                disableRipple
                icon={<CloseIcon20 />}
                onClick={onClose}
              />
            </SidebarHeader>
            <NewPagePaddingBox>
              <AppTabs
                color="white"
                variant="boxedNew"
                size="l-new"
                value={newPageStateForTabs}
                onChange={(option) => {
                  dispatch(setNewPageTab(option.value));
                }}
                options={[
                  {
                    title: 'Blank', value: ENewPageTabs.BLANK,
                  },
                  {
                    title: 'Library', value: ENewPageTabs.LIBRARY,
                  },
                ]}
              />
            </NewPagePaddingBox>
            <TabContext
              value={newPageTab}
            >
              <FormProvider {...newPageFormMethods}>
                <NewPageTabPanel
                  value={ENewPageTabs.BLANK}
                >
                  <NewPageMediaInputs />
                </NewPageTabPanel>
                <NewPageTabPanel
                  value={ENewPageTabs.LIBRARY}
                >
                  <NewPageMediaLibraryList libraryFileType={EFileType.FILE} />
                </NewPageTabPanel>
                <NewPageTabPanel
                  value={ENewPageTabs.LIBRARY_EDIT}
                >
                  <NewPageMediaInputs />
                </NewPageTabPanel>
              </FormProvider>
            </TabContext>
          </SidebarTabContentWrapper>
          <NewPageTabFooter />
        </NewPageFormWrapper>
      </NewPageContextProvider>
    </SidebarTabPanel>
  );
};

export default NewPageMediaTab;
