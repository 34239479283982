import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
  setPasswordVisible: (e: boolean) => void;
  isPasswordVisible: boolean;
}

const PasswordVisibilityIcon = ({
  setPasswordVisible,
  isPasswordVisible,
}: Props): JSX.Element => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setPasswordVisible(!isPasswordVisible)}
    >
      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>
);

export default PasswordVisibilityIcon;
