import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { StageContentType } from '../../../shared/Stage';
import { useItemContext } from '../Layout/ItemContextProvider';
import TiptapDragHandle from './TiptapDragHandle';
import TiptapNodeMenuBtn from './TiptapNodeMenuBtn';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';

const TiptapNodeControls = (): JSX.Element | null => {
  const { data } = useItemContext();
  const isLayoutEditable = useAppSelector(selectLayouEditingStatus);
  if (data?.stageContentType !== StageContentType.DEFAULT || isMobile) {
    return null;
  }

  return (
    <Box
      className="nodeControls"
      suppressContentEditableWarning
      contentEditable="false"
      sx={{
        display: 'flex',
        marginRight: '4px',
        maxWidth: '48px',
        minWidth: '48px',
        width: '48px',
        maxHeight: '24px',
        minHeight: '24px',
        height: '24px',
        '& .NodeDndHandle': {
          visibility: 'hidden',
          '&.NodeDndHandle-active': {
            visibility: 'visible',
          },
        },
        '& .NodeMenuBtn': {
          visibility: 'hidden',
          '&.NodeMenuBtn-active': {
            visibility: 'visible',
          },
        },
      }}
    >
      {
        isLayoutEditable
        && (
          <>
            <TiptapNodeMenuBtn />
            <TiptapDragHandle />
          </>
        )
      }
    </Box>
  );
};

export default TiptapNodeControls;
