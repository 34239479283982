import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { EFieldType, FieldSectionDTO, ProcessStageDTO } from '../../../shared/Field/Field';
import { EProcessType, ECreateTemplateRequestType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { EAppRoutes } from '../../core/router';
import { useCreateFieldTemplateMutation } from '../../features/ProcessFields/lib/processFieldsApi';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  ETemplatesTypes,
  createTemplate,
  selectTemplatesTab,
  setSelectedTemplateData,
} from './lib/templatesSlice';
import { AppButton } from '../../shared/AppButton/AppButton';
import { Add20px } from '../../icons/Add';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import CollectTemplateInfoDialog from '../../features/CollectTemplateInfoDialog/CollectTemplateInfoDialog';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';
import useSideMenu from '../../hooks/useSideMenu';

const analytics: Analytics = Analytics.getInstance();

const CreateNewTemplateBtn = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationId: string = useAppSelector((state) => state.app.organization.id);
  const type = useAppSelector(selectTemplatesTab);
  const [dialog, setDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const [createFieldTemplate] = useCreateFieldTemplateMutation();
  const { isOpened } = useSideMenu();

  const onTemplateCreate = async (name: string) => {
    let newTemplate: ProcessDTO | ProcessStageDTO | FieldSectionDTO | undefined;
    switch (type) {
      case ETemplatesTypes.ROOMS:
        newTemplate = await dispatch(createTemplate({
          title: name,
          type: EProcessType.TEMPLATE,
          createType: ECreateTemplateRequestType.BLANK,
          templateId: ' ',
        })).unwrap();
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: name,
            type: EProcessType.TEMPLATE,
          }));
          analytics.track(EMixPanelEvents.CREATE_ROOM_TEMPLATE_FROM_SCRATCH);
        }
        break;
      case ETemplatesTypes.PAGES:
        newTemplate = await createFieldTemplate({
          type: EFieldType.STAGE,
          title: name,
          organizationId,
        }).unwrap();
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: name,
            type: EFieldType.STAGE,
          }));
          analytics.track(EMixPanelEvents.CREATE_PAGE_TEMPLATE_FROM_SCRATCH);
        }
        break;
      case ETemplatesTypes.SECTIONS:
        newTemplate = await createFieldTemplate({
          type: EFieldType.SECTION,
          title: name,
          organizationId,
        }).unwrap();
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: name,
            type: EFieldType.SECTION,
          }));
          analytics.track(EMixPanelEvents.CREATE_SECTION_TEMPLATE_FROM_SCRATCH);
        }
        break;
      default:
        break;
    }
    if (newTemplate) {
      if (newTemplate.type === EProcessType.TEMPLATE) {
        navigate(`${EAppRoutes.deal}/${newTemplate.id}`);
      }
      if (newTemplate.type !== EProcessType.TEMPLATE) {
        navigate(`${EAppRoutes.templates}/${newTemplate.type}/${newTemplate.id}`);
      }
    }
  };

  const renderPopupType = () => {
    switch (type) {
      case ETemplatesTypes.ROOMS:
        return 'room';
      case ETemplatesTypes.SECTIONS:
        return 'section';
      case ETemplatesTypes.PAGES:
        return 'page';
      default:
        return 'room';
    }
  };

  const mobileButton = () => {
    switch (isOpened) {
      case false:
        return (
          <AppIconButton
            onClick={() => setDialog(true)}
            size="l"
            variant="primary"
            icon={<Add20px />}
          />
        );
      default:
        return (
          <Box
            sx={{
              width: '40px',
            }}
          />
        );
    }
  };

  return (
    <>
      {
        isMobile ? mobileButton()
          : (
            <AppButton
              onClick={() => setDialog(true)}
              size="l"
              variant="primary"
              startIcon={<Add20px />}
            >
              {t('Templates_Template')}
            </AppButton>
          )
      }

      <CollectTemplateInfoDialog
        setOpen={setDialog}
        open={dialog}
        onSubmit={({ name }) => onTemplateCreate(name)}
        type={renderPopupType()}
      />
    </>

  );
};

export default CreateNewTemplateBtn;
