import { Box } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../../hooks/stateHooks';
import StageContent from '../../../features/Layout/StageContent';
import { ProcessStageDTO } from '../../../../shared/Field/Field';
import { selectCurrentStage } from '../../../features/ProcessFields/lib/processItemsSlice';

export const DefaultDealView = () => {
  const stage = useAppSelector(selectCurrentStage);
  return (
    <Box
      className="DefaultDealViewWrapper"
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 3,
        paddingX: 'initial',
        background: '#FFFFFF',
        flex: '1 1 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: isMobile ? '' : '24px',
          }}
        >
          <StageContent
            stage={stage as ProcessStageDTO}
          />
        </Box>
      </Box>
    </Box>
  );
};
