import { ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import FeedListItem from '../FeedListItem';
import { AppSkeleton } from '../../../../shared/AppSkeleton/AppSkeleton';

export const UserItemSkeleton = (): JSX.Element => (
  <FeedListItem>
    <ListItemAvatar>
      <AppSkeleton animation="wave" variant="circular" width={40} height={40} />
    </ListItemAvatar>
    <ListItemText>
      <AppSkeleton animation="wave" height={21} width="100%" />
      <AppSkeleton animation="wave" height={20} width="35%" />
    </ListItemText>
  </FeedListItem>
);
