export enum ECustomEventsNames {
  ALIGN_MEDIA = 'align-media',
  CHANGE_FIELD_SOURCE = 'change-fiel-source',
  REFETCH_PROCESS_FIELD = 'refetch-process-field',
  IMG_ADJUST = "img-adjust",
  IMG_ADJUST_FOR_BUTTONS = "img-adjust-for-buttons",
  PDF_SCALE = "pdf-scale",
  PDF_ADJUST = "pdf-adjust",
  PDF_SCALE_FOR_BUTTONS = "pdf-scale-for-buttons",
  CHANGE_LAYOUT_STATE = "change-layout-state",
  PRICING_TABLE_PERIOD_TO_UPDATE = "pricing-table-period-to-update",
  CHANGE_BUTTON_WIDTH = 'CHANGE_BUTTON_WIDTH',
  CHANGE_BUTTON_COLOR = 'CHANGE_BUTTON_COLOR',
  CHANGE_BUTTON_URL = 'CHANGE_BUTTON_URL',
  CHANGE_BUTTON_ALIGN = 'CHANGE_BUTTON_ALIGN',
  PROCESS_ITEM_UPDATE = "process-item-update",
  TIPTAP_BLOCK_UPDATE = 'tiptap-block-update',
  TIPTAP_BLOCK_CREATE = 'tiptap-block-create',
  TIPTAP_EDITOR_CUSTOM_EVENT = 'tiptap-editor-custom-event',
  TIPTAP_OPEN_BLOCK_MENU = 'tiptap-blockmenu-open',
}

export enum EAlignMediaOptions {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
}
