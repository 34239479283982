import { SvgIcon, SvgIconProps } from '@mui/material';

export const AnimatedLoaderIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <circle
      cx="50%"
      cy="50%"
      r="9"
      fill="none"
      strokeWidth="1.6"
    />
  </SvgIcon>
);

export const LoaderIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <defs>
      <clipPath
        id="cut-off"
      >
        <rect
          x="0"
          y="0"
          width="100%"
          height="50%"
        />
      </clipPath>
      <clipPath
        id="cut-off-2"
      >
        <rect
          x="50%"
          y="50%"
          width="50%"
          height="50%"
        />
      </clipPath>
      <linearGradient
        id="gradient"
      >
        <stop
          offset="0%"
        />
        <stop
          offset="100%"
          stopOpacity="0.33"
        />
      </linearGradient>
      <linearGradient
        id="gradient-2"
        gradientTransform="rotate(90)"
      >
        <stop
          offset="50%"
          stopOpacity="0.33"
        />
        <stop
          offset="100%"
          stopOpacity="0"
        />
      </linearGradient>
    </defs>
    <circle
      cx="50%"
      cy="50%"
      r="7"
      stroke="url(#gradient)"
      strokeWidth="3"
      fill="none"
      clipPath="url(#cut-off)"
    />
    <circle
      cx="50%"
      cy="50%"
      r="7"
      stroke="url(#gradient-2)"
      strokeWidth="3"
      fill="none"
      clipPath="url(#cut-off-2)"
    />
  </SvgIcon>
);
