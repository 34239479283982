import { Box } from '@mui/material';
import { useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';
import { useBlockContext } from './TiptapBlockContextProvider';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import TrashIcon from '../../icons/TrashIcon';
import { DnDIcon16 } from './icons/DnDIcon';

const TiptapDragHandle = (): JSX.Element => {
  const { editor } = useCurrentEditor();
  const { getPos, node } = useBlockContext();
  const [a, setA] = useState<HTMLDivElement | null>(null);

  const onDelete = () => {
    const from = getPos();
    const to = from + node.nodeSize;
    const header = editor?.$pos(from);
    if (header?.node.type.name === 'tableHeader') {
      editor?.commands.deleteRange({ from: from - 1, to });

      const headerParent = editor?.$pos(header.parent!.pos);
      if (headerParent?.children.length === 1) {
        const pos = headerParent.range.from;
        const { content } = headerParent.children[0];
        editor?.chain().focus(pos).deleteTable().run();
        editor?.chain().focus(pos - 2).insertContentAt(pos - 2, content.toJSON()).run();
      }

      return;
    }
    editor?.commands.deleteRange({ from, to });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setA(e.currentTarget);
    e.currentTarget.classList.add('NodeDndHandle-active');
  };

  const handleClose = () => {
    const elements = document.querySelectorAll('.NodeDndHandle-active');
    elements.forEach((item) => item.classList.remove('NodeDndHandle-active'));
    setA(null);
  };

  return (
    <>
      <Box
        className="drag-handle NodeDndHandle"
        suppressContentEditableWarning
        contentEditable="false"
        draggable="true"
        data-drag-handle
        onClick={handleClick}
        sx={{
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          stroke: '#1E293B',
          '&:hover': {
            stroke: '#FB982A',
          },
        }}
      >
        <DnDIcon16 />
      </Box>
      {
        a
        && (
          <AppDropdown
            id="section-options-menu"
            lastElementHasAnotherColor
            items={[
              {
                label: 'Delete',
                onClick: onDelete,
                startIcon: <TrashIcon />,
                hidden: false,
              },
            ]}
            anchorEl={a}
            open={!!a}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          />
        )
      }
    </>
  );
};

export default TiptapDragHandle;
