import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { EFieldType, ProcessStageDTO } from '../../../shared/Field/Field';
import useProcessField from '../../hooks/useProcessField';

type Props = {
  id: string;
  onClick: (id: string) => void;
  search?: string;
};

export const StageListItem = ({ id, onClick, search }: Props) => {
  const { data } = useProcessField({ id, type: EFieldType.STAGE });

  const stage = data as ProcessStageDTO;

  if (!stage || (search && !stage.title?.toLowerCase().includes(search.toLowerCase()))) return null;

  return (
    <ListItem
      disablePadding
      onClick={() => onClick(id)}
      sx={{
        border: '1px solid #EEF0FA',
        borderRadius: '12px',
      }}
    >
      <ListItemButton
        disableRipple
      >
        <ListItemText primary={stage?.title} />
      </ListItemButton>
    </ListItem>
  );
};
