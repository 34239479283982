import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { ELocalStorageItems } from '../../../shared/LocalStorageItems/LocalStorageItems';
import { UserDTO } from '../../../shared/UserDTO';
import { updateUserData, uploadUserAvatar } from '../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { setIsSignupDialogOpen } from '../../routes-old/auth/state/authState';
import AvatarFilePreviev from '../../shared/AvatarFilePreview/AvatarFilePreview';

const DealConfirmBuyerAdditionalIfroForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user: UserDTO = useAppSelector((state) => state.app.user);
  const [userAvatarFile, setUserAvatarFile] = useState<File | undefined>(undefined);
  const fullNameRef = useRef<HTMLInputElement | null>(null);
  const jobTitleRef = useRef<HTMLInputElement | null>(null);

  const [isLoading, setLoading] = useState(false);

  const onSkip = () => {
    localStorage.removeItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA);
    dispatch(setIsSignupDialogOpen(false));
  };

  const onSave = async () => {
    setLoading(true);
    if (user?.id) {
      if (userAvatarFile) {
        try {
          await dispatch(uploadUserAvatar({ file: userAvatarFile, userId: user.id })).unwrap();
        } catch (e) {
          setLoading(false);
          console.log(e);
        }
      }
      if (fullNameRef?.current?.value || jobTitleRef?.current?.value) {
        const newName = fullNameRef?.current?.value ? fullNameRef.current.value : (user?.name ?? '');
        const newTittle = jobTitleRef?.current?.value ? jobTitleRef.current.value : (user?.title ?? '');
        try {
          await dispatch(updateUserData({
            ...user,
            fullName: newName,
            title: newTittle,
          }));
        } catch (e) {
          setLoading(false);
          console.log(e);
        }
      }
      localStorage.removeItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA);
      dispatch(setIsSignupDialogOpen(false));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '18px',
          lineHeight: '24px',
          color: '#455273',
          fontWeight: 600,
        }}
      >
        Tell us about yourself
      </Typography>
      <AvatarFilePreviev
        setAvatarFile={setUserAvatarFile}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <TextField
          fullWidth
          autoComplete="off"
          size="small"
          label="Full Name"
          variant="outlined"
          inputRef={fullNameRef}
          sx={{
            borderRadius: '8px',
          }}
          InputProps={{
            sx: {
              borderRadius: '8px',
            },
          }}
        />
        <TextField
          fullWidth
          autoComplete="off"
          size="small"
          label="Job Title"
          variant="outlined"
          inputRef={jobTitleRef}
          sx={{
            borderRadius: '8px',
          }}
          InputProps={{
            sx: {
              borderRadius: '8px',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          width: 'calc( 100% + 64px)',
          background: '#F5F9FF',
          marginLeft: '-32px',
          marginBottom: '-32px',
          paddingX: '32px',
          height: '64px',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={() => onSkip()}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '65px',
            background: 'transparent',
            fontSize: '14px',
            lineHeight: '16px',
            color: '#0073F5',
            fontWeight: 600,
            cursor: 'pointer',
          }}
        >
          Later
        </Box>
        {
          !isLoading
          && (
          <Button
            fullWidth
            size="small"
            onClick={() => onSave()}
            sx={{
              borderRadius: '8px',
              height: '40px',
              background: '#0073F5',
              transition: '0.2s',
              '&:hover': {
                background: '#1F88FF',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16px',
                letterSpacing: '.5px',
                color: 'white',
              }}
            >
              Save
            </Typography>
          </Button>
          )
        }
        {
          isLoading
          && (
            <LoadingButton
              fullWidth
              loading
              sx={{
                borderRadius: '8px',
                height: '40px',
              }}
            />
          )
        }
      </Box>
    </Box>
  );
};

export default DealConfirmBuyerAdditionalIfroForm;
