import { InputAdornment, SvgIcon } from '@mui/material';
import { useMemo, useState } from 'react';
import { AppButton } from '../../shared/AppButton/AppButton';
import { DealEventsFilterArray, EDealEventsFilterNames } from '../../../shared/events/Events';
import ProcessTimelineEventsFilterListItem from './ProcessTimelineEventsFilterListItem';
import useProcessTimelineEventsFilter from '../../hooks/useProcessTimelineEventsFilter';
import SearchActive from '../../icons/SearchActive.svg';
import { AppInput } from '../../shared/AppInput/AppInput';
import { CloseIcon20 } from '../../icons/CloseIcon';
import {
  ProcessTimelineMenuItemContainer,
  ProcessTimelineMenuItemHeader,
  ProcessTimelineMenuItemNotFoundContainer,
  ProcessTimelineMenuItemNotFoundText,
  ProcessTimelineMenuItemSearchVariantsContainer,
  ProcessTimelineMenuItemTitle,
} from './Styles/ProcessTimelineStyles';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsEventsFilterActive } from '../../features/ActivityLog/lib/processActivityLogSlice';

const ProcessTimelineEventsFilterList = () => {
  const { changeUserFilterStatus } = useProcessTimelineEventsFilter();
  const isEventsFilterActive = useAppSelector(selectIsEventsFilterActive);

  const [inputFilter, setInputFilter] = useState('');
  const filteredEventNames = useMemo(() => DealEventsFilterArray.filter(
    (event) => !!EDealEventsFilterNames[event]?.includes(inputFilter),
  ), [inputFilter, DealEventsFilterArray]);

  return (
    <ProcessTimelineMenuItemContainer>
      <ProcessTimelineMenuItemHeader>
        <ProcessTimelineMenuItemTitle>
          Events
        </ProcessTimelineMenuItemTitle>
        {isEventsFilterActive && (
        <AppButton
          size="s"
          variant="lite-danger"
          onClick={(e) => {
            e.stopPropagation();
            changeUserFilterStatus();
          }}
        >
          Reset
        </AppButton>
        )}
      </ProcessTimelineMenuItemHeader>
      { DealEventsFilterArray.length > 10 ? (
        <AppInput
          fullWidth
          value={inputFilter}
          onChange={(e) => setInputFilter(e.target.value)}
          placeholder="Search"
          size="m"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  component={SearchActive}
                  sx={{
                    color: 'transparent',
                    position: 'relative',
                    top: '1px',
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (inputFilter !== ''
              ? (
                <button
                  className="end-adorment-button"
                  type="button"
                  aria-label="name"
                  onClick={() => {
                    setInputFilter('');
                  }}
                  onMouseDown={() => {
                    setInputFilter('');
                  }}
                >
                  <CloseIcon20 />
                </button>
              ) : ''
            ),
          }}
        />
      ) : <> </>}
      <ProcessTimelineMenuItemSearchVariantsContainer>
        {filteredEventNames.length !== 0 ? (
          filteredEventNames.map((event) => (
            <ProcessTimelineEventsFilterListItem
              key={`timeline-event-filter-item-${event}`}
              name={event}
            />
          ))
        ) : (
          <ProcessTimelineMenuItemNotFoundContainer>
            <ProcessTimelineMenuItemNotFoundText>
              No matching results
            </ProcessTimelineMenuItemNotFoundText>
          </ProcessTimelineMenuItemNotFoundContainer>
        )}
      </ProcessTimelineMenuItemSearchVariantsContainer>
    </ProcessTimelineMenuItemContainer>
  );
};

export default ProcessTimelineEventsFilterList;
