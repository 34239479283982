import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FileGetByTypeRequest, LibraryFileDTO } from '../../../../shared/library/LibraryDTO';
import { config } from '../../../core';

const paramsToQueryString = (payload: FileGetByTypeRequest): string => {
  let queryString: string = '';
  let hasType: boolean = false;

  if (payload?.types && payload?.types?.length) {
    hasType = true;
    queryString = `?types=${payload.types.join(',')}`;
  }
  if (payload?.linkTypes) {
    queryString = `${queryString}${hasType ? '&' : ''}linkTypes=${payload.linkTypes.join(',')}`;
  }

  return queryString;
};

const baseQuery = fetchBaseQuery({
  baseUrl: new URL(`${config.api.prefix}library`, config.api.origin).href,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${localStorage.getItem('token') ?? sessionStorage.getItem('token')}`);
    return headers;
  },
});

export const libraryRtkApi = createApi({
  reducerPath: 'LIBRARY_RTK_API',
  baseQuery,
  endpoints: (builder) => ({
    getLibraryFiles: builder.query<LibraryFileDTO[], FileGetByTypeRequest>({
      query: (queryString: FileGetByTypeRequest) => `files${paramsToQueryString(queryString)}`,
    }),
  }),
});

export const {
  useGetLibraryFilesQuery,
} = libraryRtkApi;
