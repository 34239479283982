import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APIDocEvents } from '../../../../shared/events/APIDocumentEvents';
import { EIdTagsForAPIs } from '../../../../shared/ProcessIdsTags';
import {
  CreateTaskRequest, DeleteTaskResponse, ETaskTypes, TaskDTO, UpdateTaskRequest, UpdateTaskResponse,
} from '../../../../shared/TaskDTO';
import { config } from '../../../core';

const baseQuery = fetchBaseQuery({
  baseUrl: new URL(`${config.api.prefix}task`, config.api.origin).href,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${localStorage.getItem('token') ?? sessionStorage.getItem('token')}`);
    return headers;
  },
});

enum ETasksApiTagTypes {
  TASKS = 'TASKS',
}

export const tasksApi = createApi({
  reducerPath: 'TASKS',
  baseQuery,
  tagTypes: [
    ETasksApiTagTypes.TASKS,
  ],
  endpoints: (builder) => {
    const taskDataUpdated = new Event(APIDocEvents.PROCESS_FIELDS_DATA_UPDATE);
    const dispatchUpdateEvent = () => {
      document.dispatchEvent(taskDataUpdated);
    };
    return {
      getTaskById: builder.query<TaskDTO, { id: string, processId: string }>({
        // query: ({ id, processId }) => `${processId}/${id}`,
        query: ({ id, processId }) => {
          if (processId === EIdTagsForAPIs.TEMPLATE) {
            return `templates-tasks/${id}`;
          }
          return `${processId}/${id}`;
        },
        providesTags: (result, error, { id }) => {
          if (result) {
            return ([{ type: ETasksApiTagTypes.TASKS, id }]);
          }
          return [];
        },
      }),
      updateTaskById: builder.mutation<UpdateTaskResponse, UpdateTaskRequest>({
        query: ({
          id, processId, update, isAgenda,
        }) => ({
          method: 'PATCH',
          url: processId === EIdTagsForAPIs.TEMPLATE ? `templates-tasks/${id}` : `${processId}/${id}`,
          body: { update, isAgenda },
        }),
        invalidatesTags: (result, error, { id }) => {
          const itemsToRevalidate: { type: ETasksApiTagTypes, id: string }[] = [{ type: ETasksApiTagTypes.TASKS, id }];
          if (result && result.revalidateParent) {
            itemsToRevalidate.push(
              { type: ETasksApiTagTypes.TASKS, id: result.task.parentId },
            );
          }
          return itemsToRevalidate;
        },
        onCacheEntryAdded: dispatchUpdateEvent,
      }),
      createTask: builder.mutation<TaskDTO, CreateTaskRequest>({
        query: (data: CreateTaskRequest) => ({
          method: 'POST',
          url: data.processId === EIdTagsForAPIs.TEMPLATE ? 'templates-tasks' : `${data.processId}`,
          body: data,
        }),
        invalidatesTags: (result) => {
          if (result?.type === ETaskTypes.ACTION) {
            return [{ type: ETasksApiTagTypes.TASKS, id: result.parentId }];
          }
          return [];
        },
        onCacheEntryAdded: dispatchUpdateEvent,
      }),
      deleteTaskById: builder.mutation<DeleteTaskResponse, { processId: string, id: string, isAgenda?: boolean }>({
        query: ({ processId, id, isAgenda }) => ({
          method: 'DELETE',
          url: processId === EIdTagsForAPIs.TEMPLATE ? `templates-tasks/${id}` : `${processId}/${id}`,
          body: { processId, id, isAgenda },
        }),
        invalidatesTags: (result, error, { id }) => {
          const itemsToRevalidate: { type: ETasksApiTagTypes, id: string }[] = [];
          if (result?.deletedIds) {
            result.deletedIds.forEach((taskId: string) => itemsToRevalidate.push(
              ({ type: ETasksApiTagTypes.TASKS, id: taskId }),
            ));
          }
          if (result?.revalidateParent) {
            itemsToRevalidate.push(
              ({ type: ETasksApiTagTypes.TASKS, id: result.parentId }),
            );
          }
          return itemsToRevalidate;
        },
        onCacheEntryAdded: dispatchUpdateEvent,
      }),
    };
  },
});

export const {
  useGetTaskByIdQuery,
  useCreateTaskMutation,
  useUpdateTaskByIdMutation,
  useDeleteTaskByIdMutation,
  useLazyGetTaskByIdQuery,
} = tasksApi;
