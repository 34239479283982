import {
  Box, List,
} from '@mui/material';
import { useState } from 'react';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';
import { useAppSelector } from '../../../hooks/stateHooks';
import { StageListItem } from '../StageListItem';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { useGetStagesTemplatesByOrgIdQuery } from '../../ProcessFields/lib/processFieldsApi';
import { selectTemplatesFilter } from '../../../pages/templates/lib/templatesSlice';

export type Props = {
  onTemplateSelect: (id: string) => void;
  onGoBack: (tab?: number) => void;
  onClose: () => void;
};

export const TemplatesTab = ({ onTemplateSelect, onGoBack, onClose }: Props) => {
  const organizationId: string = useAppSelector((state) => state.app.organization?.id);
  const filter: string = useAppSelector(selectTemplatesFilter);
  const { data: stageTemplates } = useGetStagesTemplatesByOrgIdQuery({ organizationId, filter });
  const [search, setSearch] = useState('');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: '16px 16px 8px 16px',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginBottom: '20px',
      }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <AppLinkButton
            // startIcon={<ChevronLeft />}
            // onClick={() => onGoBack()}
            sx={{
              width: 'auto !important',
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
            }}
          >
            Add page
          </AppLinkButton>
        </Box>
        <Box sx={{
          display: 'flex',
        }}
        >
          <AppLinkButton
            onClick={onClose}
            sx={{
              paddingRight: '0px !important',
              minWidth: '100% !important',
              width: '100% !important',
            }}
          >
            Cancel
          </AppLinkButton>
        </Box>
      </Box>
      <Box>
        <AppInput
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          size="m"
          placeholder="Search"
          sx={{
            marginBottom: '16px',
          }}
        />
      </Box>
      <Box sx={{
        height: '100%',
        overflow: 'overlay',
      }}
      >
        <List sx={{
          gap: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {stageTemplates?.map((template) => (
            <StageListItem id={template.id} key={template.id} onClick={(id) => onTemplateSelect(id)} search={search} />
          ))}
        </List>
      </Box>
    </Box>
  );
};
