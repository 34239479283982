import { Box } from '@mui/material';
import { useDroppable } from '@dnd-kit/core';
import { ProcessStatus } from '../../../shared/process/ProcessMilestoneActionDTO';
import { selectFirstFetchStatus } from '../../routes-old/deals/state/dealsSlice';
import { useAppSelector } from '../../hooks/stateHooks';
import DealBoardCardSkeleton from './DealBoardCardSkeleton';

type Props = {
  children: React.ReactNode;
  status: ProcessStatus
};

export const DealBoardColumn = ({ children, status }: Props) => {
  const { setNodeRef } = useDroppable({
    id: status,
  });
  const firstFetchStatus = useAppSelector(selectFirstFetchStatus);

  return (
    <Box className="column" ref={setNodeRef}>
      {children}
      {
              !firstFetchStatus
              && (
              <DealBoardCardSkeleton />
              )
            }
    </Box>
  );
};
