import { Box } from '@mui/material';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { Add20px } from '../../icons/Add';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import { EBottomDrawerTabs } from '../Layout/lib/fieldsLayoutSlice';
import { selectLibraryGridToRender, setLibraryFeedTab, setLibraryFeedTitle } from '../../pages/library/lib/librarySlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { ELibraryType } from '../../../shared/library/Library';
import { ELibraryFeedTab } from '../../pages/library/helpers/library.helper';
import { ProductWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import useSideMenu from '../../hooks/useSideMenu';

const MobileBottomMenuLibraryAddButton = () => {
  const { openDrawer } = useMobileBottomDrawer();
  const { isOpened } = useSideMenu();
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const dispatch = useAppDispatch();

  const onCreateProductItemOpen = (item?: ProductWithOwnerDTO) => {
    dispatch(setLibraryFeedTitle(item?.name || ''));
    dispatch(setLibraryFeedTab({
      open: true,
      type: ELibraryFeedTab.PRODUCT,
      item,
    }));
  };

  const handleCreateButtonClick = () => {
    if (gridToRender === ELibraryType.FILE) {
      openDrawer(EBottomDrawerTabs.LIBRARY_CREATE_ITEM_MENU);
    } else {
      onCreateProductItemOpen();
    }
  };

  if (!isOpened) {
    return (
      <AppIconButton
        onClick={handleCreateButtonClick}
        size="l"
        variant="primary"
        icon={<Add20px />}
      />
    );
  }

  return (
    <Box
      sx={{
        width: '40px',
      }}
    />
  );
};

export default MobileBottomMenuLibraryAddButton;
