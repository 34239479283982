import { styled } from '@mui/styles';
import {
  Box,
  Button,
  MenuItem, Paper, Popover, TextField, Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const SettingsTeamListPaper = styled(Paper)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  maxWidth: '1080px',
  margin: '0px',
  padding: '0',
  alignSelf: 'left',
  backgroundColor: 'transparent',
  overflow: 'hidden',
  '&.organizations-users-paper': {
    border: '1px solid',
    borderRadius: '16px',
    borderColor: stagesetColors.newGrey[200],
    backgroundColor: stagesetColors.white[100],
    boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08), '
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08), '
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07), '
    + '0px 3px 2px 0px rgba(30, 41, 59, 0.04)',
  },
  '&.organization-users-paper-mobile': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

export const TeamTabMobileTableWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'overlay',
  boxShadow: 'none',
  gap: '8px',
  '& .RowLink': {
    height: 'auto',
  },
}));

export const TeamTabDesktopDataGrid = styled(DataGrid)(() => ({
  '&.MuiDataGrid-root--densityStandard': {
    border: 'none',
  },
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeader--alignCenter, .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    '& .MuiDataGrid-columnHeaderTitle': {
      textTransform: 'none',
      color: stagesetColors.newGrey[500],
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16px',
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-root, .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  // header
  '& .MuiDataGrid-columnHeaders': {
    padding: '8px 24px',
  // row
  },
  '& .MuiDataGrid-row': {
    width: '100%',
    paddingLeft: '24px',
    '&:not(:first-child)': {
      borderTop: '1px solid',
      borderColor: stagesetColors.newGrey[200],
    },
    '&:hover': {
      backgroundColor: stagesetColors.newGrey[50],
    },
  },
  // cell
  '& .MuiDataGrid-cell': {
    border: 'none',
    fontSize: '14px',
    lineHeight: '22px',
    color: stagesetColors.newGrey[800],
  },
  // scroll
  '& .MuiDataGrid-root, .MuiDataGrid-window': {
    '&::-webkit-scrollbar': {
      overflow: 'auto',
      width: '7px',
      height: '7px',
      display: isMobile ? 'none' : '',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(128, 128, 128, .5)',
      opacity: '50%',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#7E7E7E',
    },
  },
}));

export const TeamPopoverMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  fontSize: '16px',
  lineHeight: '22px',
  background: 'transparent !important',
  padding: '8px',
  '&.cell-more-menu-remove': {
    width: '264px',
    justifyContent: 'flex-start',
    gap: '12px',
    color: stagesetColors.red[600],
    borderTop: '1px solid',
    borderColor: stagesetColors.newGrey[100],
    '& svg': {
      fill: 'none',
      '& path': {
        stroke: stagesetColors.red[600],
      },
    },
  },
  '&.cell-more-menu': {
    width: '264px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: stagesetColors.newGrey[400],
    '& svg': {
      fill: 'none',
      '& path': {
        stroke: stagesetColors.newGrey[400],
      },
    },
  },
  '&.team-role-menu': {
    width: '224px',
    justifyContent: 'space-between',
    '& svg': {
      visibility: 'hidden',
      background: 'transparent',
      '& path': {
        stroke: stagesetColors.newGrey[500],
      },
    },
  },
  '&.team-role-menu-selected': {
    width: '224px',
    justifyContent: 'space-between',
    '& svg': {
      visibility: 'visible',
      background: 'transparent',
      '& path': {
        stroke: stagesetColors.newGrey[500],
      },
    },
  },
}));

export const TeamPopoverButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '9px 16px',
  '&.more-menu-remove-button': {
    justifyContent: 'flex-start',
    gap: '12px',
    color: stagesetColors.red[600],
    '& svg': {
      fill: 'none',
      '& path': {
        stroke: stagesetColors.red[600],
      },
    },
  },
}));

export const RoleTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  '& .role-title-desktop': {
    color: stagesetColors.newGrey[800],
    padding: '5px 8px',
    marginLeft: '-5px',
  },
}));

export const TitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const TitleWrapperWithIcon = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const RoleField = styled(TextField)(() => ({
  '& .MuiSelect-iconStandard': {
    display: 'none !important',
  },
  '& .MuiSelect-select': {
    padding: '0px !important',
    boxShadow: 'none',
  },
  '& .MuiInputBase-root': {
    '&:hover': {
      boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 3px 2px 0px rgba(30, 41, 59, 0.04)',
      '& .MuiSelect-iconStandard': {
        display: 'flex',
      },
    },
    '& :active': {
      boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 3px 2px 0px rgba(34, 89, 177, 0.04)',
    },
  },
  '& .Mui-focused': {
    borderColor: stagesetColors.newGrey[400],
  },
}));

export const NameTitleWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  gap: '12px',
  alignItems: 'center',
}));

export const NameTitleTextFieldsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const CellTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
  '&.cell-title-mobile': {
    fontSize: '16px',
  },
  '&.cell-title-grey': {
    fontSize: '14px',
    lineHeight: '16px',
    color: stagesetColors.newGrey[500],
  },
  '&.cell-title-grey-small': {
    fontSize: '14px',
    lineHeight: '16px',
    color: stagesetColors.newGrey[500],
  },
}));

export const TeamTabMoreMenuPopover = styled(Popover)(() => ({
  marginLeft: '-70px',
  marginTop: '50px',
  '&.more-menu-popover-mobile': {
    marginLeft: '-10px',
  },
  '& .MuiPopover-paper': {
    paddingX: '8px',
    fontSize: '16px',
    lineHeight: '22px',
    color: stagesetColors.newGrey[800],
    border: '1px solid',
    borderRadius: '8px',
    borderColor: stagesetColors.newGrey[400],
    backgroundColor: stagesetColors.white[100],
    boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 3px 2px 0px rgba(30, 41, 59, 0.04)',
  },

}));

export const MobileTableRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4px 0px',
}));

export const TeamTabMobileRowWrapper = styled(Box)(() => ({
  width: '100%',
  borderRadius: '12px',
  padding: '16px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.white[100],
  boxShadow: '0px 1px 2px 0px rgba(0, 113, 190, 0.02),'
    + '0px 0px 5px 0px rgba(0, 113, 190, 0.06)',
}));
