import { Box, Typography } from '@mui/material';
import {
  // gridNumberComparator,
  GridRowsProp, GridSortModel, gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { EntityId } from '@reduxjs/toolkit';
import {
  EProcessType, ProcessDTO, ProcessStatus,
} from '../../../shared/process/ProcessMilestoneActionDTO';
import { NavigationLink } from '../../common/ToolBarNavigation/NavigationStyle';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';
import { EStateStatus } from '../../core/commonTypes';
import { EAppRoutes } from '../../core/router';
import { selectFilterSearch, selectGridFilters } from '../../features/Deals/DataGridFilter/lib/dealsGridSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { clearProcessState } from '../process/state/processSlice';
import { DealsGrid } from './DealPageStyles/DealsListStyle';
import LastActiveCell from './DealsLastActiveCell';
import DealsListMenu from './DealsListMenu';
import {
  allDealsSelector,
  getProcesses,
  updateProcess,
  selectFirstFetchStatus,
  selectProcessesLoadingJobs,
  setCurrentPage,
  selectDealsCurrentPage,
  selectDealsTotalCount,
  lastIdSelector,
  totalFetchedSelector,
  getAdditionalProcesses,
  setSortModel,
  selectSortModel,
} from './state/dealsSlice';
import { ExtendedGridColumns } from '../../types/mobile-table';
import { CrossDimensionDataGrid } from '../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { NameCell } from '../../features/Organization/OrganizationUsersDataGrid/NameCell';
import { DealHealth } from '../../shared/DealHealth/DealHealth';
import { DealStatus } from '../../shared/DealStatus/DealStatus';
import {
  EDataGridColumns,
  GridItemSkeleton,
  skeletonRows,
  GridTitleItemSkeleton,
} from './DealsHelpers/DealsHelpers';
import { DealsType } from '../../../shared/process/Process';
import { selectOrganization, selectUserId } from '../../core/store/appState/appState';
import { DefaultOrgCurrency } from '../../../shared/OrganizationDTO';
import UserItemAvatar from '../../shared/UserAvatar/UserItemAvatar';
import { UserDTO } from '../../../shared/UserDTO';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { LibraryGridContainer } from '../../pages/library/styles/LibraryGridStyle';

const analytics: Analytics = Analytics.getInstance();

interface Props {
  onCreateDealDialogOpen: (templateId: string) => void;
}

const SellerDataGrid = ({
  onCreateDealDialogOpen,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const processes = useAppSelector(allDealsSelector);
  const organization = useAppSelector(selectOrganization);
  const { status, isProcessStatsLoaded } = useAppSelector((state) => state.deals);
  const processesLoadingJobs = useAppSelector(selectProcessesLoadingJobs);
  const filter = useAppSelector(selectGridFilters);
  const search = useAppSelector(selectFilterSearch);
  const firstFetchStatus = useAppSelector(selectFirstFetchStatus);
  const { t } = useTranslation();
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const isDealsEditable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_DEALS_MANAGE);
  const userId = useAppSelector(selectUserId);
  const selecteCurrentPage = useAppSelector(selectDealsCurrentPage);
  const processTotalCount = useAppSelector(selectDealsTotalCount);
  const processTotalFetched = useAppSelector(totalFetchedSelector);
  const lastProcessId = useAppSelector(lastIdSelector);
  const selectedSortModel = useAppSelector(selectSortModel);
  const [sortModel, setGridSortModel] = useState(selectedSortModel);

  useEffect(() => {
    setGridSortModel(selectedSortModel);
    if (status !== EStateStatus.LOADING) {
      dispatch(getProcesses());
      dispatch(clearProcessState());
    }
  }, [selectedSortModel]);

  const updateProcessStatus = (id: string, processStatus: ProcessStatus, currentStatus: ProcessStatus) => {
    if (processStatus === currentStatus) return;
    dispatch(updateProcess({
      id,
      status: processStatus,
    }));
  };

  const isEditAccessible = (owner: UserDTO): boolean => isDealsEditable || (userId === owner?.id);
  const isLast = (processId: string): boolean => processId === lastProcessId;

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getProcesses());
      dispatch(clearProcessState());
    }
  }, []);

  useEffect(() => {
    dispatch(getProcesses());
  }, [filter[DealsType.SELLER_DEALS], search]);

  useEffect(() => {
    dispatch(getProcesses());
  }, [selecteCurrentPage]);

  const onRowClick = (row: any) => {
    const {
      title, template, lastActive, id,
    } = row;
    const { lastMilestone } = row.lastMileStone;
    analytics.track(EMixPanelEvents.ROW_CLICKED, {
      dealId: id,
      dealTitle: title,
      dealTemplate: template,
      lastActive,
      lastMilestone: {
        lastMilestoneId: lastMilestone?.id,
        lastMilestoneTitle: lastMilestone?.title,
      },
    });
    navigate(`${EAppRoutes.deal}/${row.id}`);
  };

  const preventLinkBehavior = (e: BaseSyntheticEvent) => {
    e.preventDefault();
  };

  const getTemplateTitle = (templateId: string | undefined): string | undefined => processes.find(
    (process: ProcessDTO) => process.id === templateId,
  )?.title;

  const valueFormatter = (value: number) => {
    const valueFormatted = value.toLocaleString();
    return `${valueFormatted}`;
  };

  const loadAdditionalDeals = () => {
    if (processTotalFetched < processTotalCount) {
      dispatch(getAdditionalProcesses());
    }
  };

  const columns: ExtendedGridColumns = [
    {
      field: EDataGridColumns.title,
      sortable: true,
      headerName: t('Overview_Name'),
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 4,
      renderCell: ({
        id,
        value,
      }) => (
        value !== 'skeleton' ? (
          <NavigationLink
            onClick={preventLinkBehavior}
            className="RowLink"
            to={`${EAppRoutes.deal}/${id}`}
          >
            <NameCell name={value.dealName} title={value.company} avatarSrc={value.logo} />
          </NavigationLink>
        ) : (
          <GridTitleItemSkeleton />
        )
      ),
    },
    {
      sortable: true,
      headerName: t('Overview_Health'),
      field: EDataGridColumns.health,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({
        id,
        value,
      }) => (
        value !== 'skeleton' ? (
          <DealHealth lastActive={value} callback={loadAdditionalDeals} isLast={isLast(id.toString())} />
        ) : (
          <GridItemSkeleton />
        )
      ),
      renderMobileCell: ({ row, value }) => (
        <DealHealth lastActive={value} />
      ),
    },
    {
      field: EDataGridColumns.owner,
      sortable: true,
      headerName: t('Overview_Owner'),
      sortComparator: (val1, val2, conf1, conf2) => gridStringOrNumberComparator(val1?.name, val2?.name, conf1, conf2),
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 0.8,
      renderCell: ({
        value,
      }) => {
        const owner: UserDTO = value as UserDTO;
        return (value !== 'skeleton' ? (
          <UserItemAvatar
            size={30}
            marginLeft={11}
            colorId={owner?.colorId}
            userName={owner?.name}
            fontSize={16}
            fontWeight={400}
            avatarSrc={owner?.avatarSrc}
          />
        ) : (
          <GridItemSkeleton />
        )
        );
      },
      renderMobileCell: ({ value }) => {
        const owner: UserDTO = value as UserDTO;
        return (
          <UserItemAvatar
            size={30}
            marginLeft={11}
            colorId={owner?.colorId}
            userName={owner?.name}
            fontSize={16}
            fontWeight={400}
            avatarSrc={owner?.avatarSrc}
          />
        );
      },
    },
    {
      sortable: true,
      headerName: t('Overview_Value'),
      field: EDataGridColumns.value,
      headerAlign: 'left',
      flex: 1,
      renderCell: ({
        id,
        value,
      }) => (
        value !== 'skeleton' ? (
          <NavigationLink
            onClick={preventLinkBehavior}
            className="RowLink"
            to={`${EAppRoutes.deal}/${id}`}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
              }}
            >
              <Typography>
                {getSymbolFromCurrency(organization.currency || DefaultOrgCurrency)}
                &nbsp;
                {valueFormatter(value as number)}
              </Typography>
            </Box>
          </NavigationLink>
        ) : (
          <GridItemSkeleton />
        )
      ),
      renderMobileCell: ({ row, value }) => (
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
            }}
          >
            <Typography>
              {getSymbolFromCurrency(organization.currency || DefaultOrgCurrency)}
              &nbsp;
              {valueFormatter(value as number)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      sortable: true,
      headerName: t('Overview_Last_active'),
      field: EDataGridColumns.lastActive,
      headerAlign: 'left',
      flex: 1.2,
      renderCell: (
        {
          id,
          value,
        },
      ) => (
        value !== 'skeleton' ? (
          <NavigationLink
            onClick={preventLinkBehavior}
            className="RowLink"
            to={`${EAppRoutes.deal}/${id}`}
          >
            <LastActiveCell
              value={value}
            />
          </NavigationLink>
        ) : (
          <GridItemSkeleton />
        )
      ),
      renderMobileCell: ({ row, value }) => (
        <LastActiveCell
          value={value}
        />
      ),
    },
    {
      sortable: true,
      headerName: t('Overview_Status'),
      field: EDataGridColumns.status,
      flex: 1.3,
      renderCell: (
        {
          id,
          value,
          row,
        },
      ) => (
        value !== 'skeleton' ? (
          <DealStatus
            status={value}
            onChooseStatus={(processStatus) => updateProcessStatus(id as string, processStatus, value)}
            disableEdit={!isEditAccessible(row.owner)}
          />
        ) : (
          <GridItemSkeleton />
        )
      ),
      renderMobileCell: ({ row, value }) => (
        <DealStatus
          status={value}
          onChooseStatus={(processStatus) => updateProcessStatus(row.id as string, processStatus, value)}
          disableEdit={!isDealsEditable}
        />
      ),
    },
    {
      field: EDataGridColumns.menu,
      cellClassName: 'pro-grid-cell',
      hideSortIcons: true,
      align: 'right',
      renderCell: (
        {
          id,
          value,
          row,
        },
      ) => (
        value !== 'skeleton' && isEditAccessible(row.owner)
          ? (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            >
              <DealsListMenu
                // @ts-ignore
                trackEvent={value.trackEvent}
                id={id as string}
                // @ts-ignore
                itemType={value?.type as EProcessType}
                // @ts-ignore
                title={value?.title as string}
                openCreateDealDialog={onCreateDealDialogOpen}
                allowDuplicate
              />
            </Box>
          ) : <Box />
      ),
    },
  ];

  let rows: GridRowsProp;
  if (processes.length) {
    rows = processes
      .filter((process: ProcessDTO) => process.type === EProcessType.DEAL)
      .map((process) => {
        const trackEvent = (event: EMixPanelEvents) => {
          analytics.track(event, {
            dealId: process.id,
            dealTitle: process.title,
            dealTemplate: process.templateId,
            lastActive: process.processStats?.lastActiveUser,
            lastMilestone: {
              lastMilestoneId: process.processStats?.lastMilestone?.id,
              lastMilestoneTitle: process.processStats?.lastMilestone?.title,
            },
          });
        };

        return {
          [EDataGridColumns.status]: process.status,
          [EDataGridColumns.menu]: {
            trackEvent,
            type: process.type,
            title: process.title,
            notifications: process?.notifications,
          },
          [EDataGridColumns.title]: {
            dealName: process.title,
            logo: process.buyerLogoSrc,
            company: process.client,
          },
          id: process.id,
          [EDataGridColumns.owner]: process.processStats?.owner,
          [EDataGridColumns.value]: process.value ?? 0,
          [EDataGridColumns.health]: process.lastActive,
          [EDataGridColumns.lastActive]: process.lastActive,
          [EDataGridColumns.client]: process.client,
          [EDataGridColumns.template]: process.templateId ? getTemplateTitle(process.templateId) : '-',
          [EDataGridColumns.lastMileStone]: {
            dealTitle: process.title,
            lastActive: process.processStats?.lastActiveUser,
            dealTemplate: process.templateId,
            status: process.processStats?.status,
            lastMilestone: process.processStats?.lastMilestone,
            tasksStats: process.processStats?.tasksStats,
            tasksStatus: process.processStats?.tasksStatus,
          },
        };
      });
  } else if (processes && !processes.length) {
    rows = [];
  }

  const handleSetSortModel = (model: GridSortModel) => {
    dispatch(setSortModel(model));
  };

  return (
    <LibraryGridContainer
      className={firstFetchStatus ? 'grid-loaded' : 'grid-loading'}
      id="my-deals-tab-container"
    >
      <Box sx={{
        display: 'inline-flex',
        gap: '4px',
        marginBottom: '12px',
        marginLeft: '12px',
      }}
      >
        {
          firstFetchStatus ? (
            <>
              <Typography sx={{
                fontSize: '16px',
                color: (theme) => theme.palette.grey[600],
              }}
              >
                {processTotalCount}
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                color: (theme) => theme.palette.grey[400],
                paddingTop: '2px',
              }}
              >
                {t('Overview_Rooms').toLowerCase()}
              </Typography>
            </>
          ) : (
            <GridItemSkeleton />
          )
        }
      </Box>
      <CrossDimensionDataGrid
        rows={rows}
        columns={columns}
        getId={(row) => row.id}
        renderMobileTitle={(row) => (
          <NameCell
            name={row[EDataGridColumns.title].dealName}
            title={row[EDataGridColumns.title].company}
            avatarSrc={row[EDataGridColumns.title].logo}
          />
        )}
        renderMobileMenu={(row) => {
          const value = row[EDataGridColumns.menu];
          return (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
              <DealsListMenu
                // @ts-ignore
                trackEvent={value.trackEvent}
                id={row.id as string}
                // @ts-ignore
                itemType={value?.type as EProcessType}
                // @ts-ignore
                title={value?.title as string}
                openCreateDealDialog={onCreateDealDialogOpen}
                allowDuplicate
              />
            </Box>
          );
        }}
        onRowClick={(row) => {
          onRowClick(row);
        }}
        renderDesktopGridComponent={(props) => (
          <DealsGrid
            // loading={processesLoadingJobs > 0}
            disableSelectionOnClick
            disableColumnMenu
            columns={columns}
            rows={firstFetchStatus ? rows : skeletonRows}
            headerHeight={36}
            rowHeight={65}
            onRowClick={(rowParams) => onRowClick(rowParams.row)}
            getRowClassName={(params) => (params.row.status === 1 ? 'highlighted_row' : '')}
            hideFooter
            sortModel={sortModel}
            onSortModelChange={handleSetSortModel}
          />
        )}
      />
    </LibraryGridContainer>
  );
};

export default SellerDataGrid;
