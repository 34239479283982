import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EUserOrganizationPermissions } from '../../../../shared/permissions';
import useUserOrganizationPermissions from '../../../hooks/useOrganizationPermissions';
import { useAppSelector } from '../../../hooks/stateHooks';
import { selectOrgSubscriptionStatus } from '../../Billing/state/billingSlice';
import { ESubscriptionStatus } from '../../../../shared/Subscription';
import OrganizationUserInvite
  from '../../../widgets/OrganizationInfoPanel/OrganizationInfoPanelLowerSection/OrganizationInfoUserInvite/OrganizationUserInvite';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';
import { SettingsTeamListPaper, TeamTabDesktopDataGrid } from './Styles/TeamTabStyles';
import { TeamTabDataGrid } from './tabItems/TeamTabDataGrid';
import { ETeamTabDataGridColumns, useTeamTabDataGrid } from '../../../hooks/useTeamTabDataGrid';
import { CellMoreMenuPopover } from './tabItems/TeamTabCells/CellMoreMenuPopover';
import { NameTitleCell } from './tabItems/TeamTabCells/NameTitleCell';
import { TeamTabTableContextProvider } from './tabItems/TeamTabTableContext';

const TeamTab = () => {
  const subscriptionStatus = useAppSelector(selectOrgSubscriptionStatus);
  const { columns, rows } = useTeamTabDataGrid();
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const inviteAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_INVITE)
    && subscriptionStatus === ESubscriptionStatus.ACTIVE;
  const { t } = useTranslation();

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper
        className={classNames({ 'settings-heading-team-desktop': !isMobile })}
      >
        <Box>
          <SettingsCardsHeader>
            {t('Team_Team')}
          </SettingsCardsHeader>
          <SettingsCardsDescription>
            Setup your team
          </SettingsCardsDescription>
        </Box>
        {
            inviteAvailable && !isMobile && (
              <OrganizationUserInvite />
            )
          }
      </SettingsCardsHeadingWrapper>
      <SettingsTeamListPaper
        className={classNames(
          'content-paper',
          { 'organizations-users-paper': !isMobile },
          { 'organization-users-paper-mobile': isMobile },
        )}
      >
        <TeamTabDataGrid
          columns={columns}
          rows={rows}
          getId={(row) => row.id}
          renderMobileTitle={(row) => (
            <NameTitleCell
              name={row[ETeamTabDataGridColumns.title].name ?? row[ETeamTabDataGridColumns.title].email}
              title={row[ETeamTabDataGridColumns.title].title}
              colorId={row[ETeamTabDataGridColumns.title].colorId}
              avatarSrc={row[ETeamTabDataGridColumns.title].avatarSrc}
            />
          )}
          renderMobileMenu={(row) => (
            <TeamTabTableContextProvider
              props={row.menu}
            >
              <CellMoreMenuPopover />
            </TeamTabTableContextProvider>
          )}
          renderDesktopGridComponent={(props) => (
            <TeamTabDesktopDataGrid
              {...props}
              autoHeight
              disableSelectionOnClick
              disableColumnMenu
              hideFooter
              columns={columns}
              rows={rows}
              headerHeight={40}
              rowHeight={60}
              getRowSpacing={() => ({
                top: 0,
                bottom: 0,
              })}
            />
          )}
        />
      </SettingsTeamListPaper>
    </SettingsTabContentWrapper>
  );
};

export default TeamTab;
