import { NavLink, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';
import { EAppRoutes } from '../../core/router';
import { SideMenuListItem } from './SideMenuStyles';
import useSideMenu from '../../hooks/useSideMenu';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { ESubscriptionType, premiumSubscriptionTypes } from '../../../shared/Subscription';
import { SideMenuItem as TSideMenuItem } from '../../core/menu/sideMenu';
import { EMenuTabs, setCurrentMenuTab } from '../Layout/lib/fieldsLayoutSlice';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { AppButton } from '../../shared/AppButton/AppButton';
import { selectIsUserAdmin } from '../../core/store/appState/appState';
import { stagesetColors } from '../../theme/stagesetPalette';

export type SideMenuItemProps = {
  disabled?: boolean,
} & TSideMenuItem;

export const SideMenuItem = ({
  icon,
  text,
  premiumOnly,
  link,
  menuTab,
  disabled,
  subscriptionExeptions,
  adminOnly,
}: SideMenuItemProps) => {
  const location = useLocation();
  const { close } = useSideMenu();
  const dispatch = useAppDispatch();
  const currentSubscriptionType: ESubscriptionType = useAppSelector((state) => state.billing?.subscription?.type);
  const isPremium: boolean = premiumSubscriptionTypes.includes(currentSubscriptionType);
  const actionsDisabled = (disabled || (premiumOnly && !isPremium)) && !subscriptionExeptions?.includes(currentSubscriptionType);
  const isCurrent: boolean = location.pathname === link;
  const setMenuItem = (menuItem: EMenuTabs) => {
    dispatch(setCurrentMenuTab(menuItem));
  };
  const isAdmin = useAppSelector(selectIsUserAdmin);

  if (adminOnly && !isAdmin) {
    return null;
  }

  return (
    <SideMenuListItem
      key={text}
      disablePadding
      className={classNames({ 'side-menu-list-item-mobile': isMobile })}
    >
      <Tooltip
        title={text}
        placement="right"
        componentsProps={{
          tooltip: {
            sx: {
              background: stagesetColors.newGrey[800],
            },
          },
        }}
      >
        <NavLink
          to={link || EAppRoutes.home}
          className="list-item-nav-link"
          style={({ isActive }) => ({
            pointerEvents: isActive || actionsDisabled ? 'none' : 'initial',
          })}
          onClick={() => {
            setMenuItem(menuTab);
            if (isMobile) close();
          }}
        >
          {isMobile ? (
            <AppButton
              startIcon={icon}
              variant={isCurrent ? 'secondary-newGrey' : 'transparent-newGrey'}
              size="xl"
              fullWidth
            >
              {text}
            </AppButton>
          ) : (
            <AppIconButton
              icon={icon}
              variant={isCurrent ? 'secondary-black-icon' : 'transparent-black-icon'}
              size="l"
            />
          )}
        </NavLink>
      </Tooltip>
    </SideMenuListItem>
  );
};

export default SideMenuItem;
