import { AvatarGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { stagesetColors } from '../../theme/stagesetPalette';

const UserAvatarsGroup = styled(AvatarGroup)(() => ({
  alignItems: 'center',
  minHeight: '24px',
  transition: 'backgroundColor 0.35s',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  background: 'none',
  flex: 0,
  '& .MuiAvatar-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: stagesetColors.newGrey[100],
    color: stagesetColors.newGrey[800],
    background: 'transparent',
    position: 'relative',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
  },
}));

export default UserAvatarsGroup;
