import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';
import { useGetEmbedIframeMutation } from './lib/embedIframeApi';
import MediaResizePlugin from './MediaResizePlugin';
import IFrameCover from '../../shared/IFrameCover/IFrameCover';
import { StageContentType } from '../../../shared/Stage';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { EFieldsubType } from '../../../shared/Field/Field';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsToolbarNotShown } from '../../routes-old/auth/state/authState';
import { MediaNodeAttrs, TiptapNodeUpdate } from '../../../shared/Tiptap/TiptapTypes';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';
import { AppSkeletonStrings } from '../../shared/AppSkeleton/AppSkeletonStrings';

export type Props = {
  type: EFieldsubType.MIXED | EFieldsubType.MIXED_URL;
  url: string;
  onUpdate: (data: TiptapNodeUpdate<MediaNodeAttrs>) => void;
  width?: number;
  height?: number;
};

export const IFramelyComponent = ({
  type,
  url,
  onUpdate,
  width: initialWidth,
  height: initialHeight,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<string>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [html, setHtml] = useState<{ __html: string }>();
  const [fetchEmbedIframe, { data: embedIframe }] = useGetEmbedIframeMutation();
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const currentStage = useCurrentStage();
  const { createLinkPreviewInteraction, createRichMediaInteraction } = useFrontEventsApi();

  const [isActive, setActive] = useState(false);
  const [isInteractionLogged, setInteractionLogged] = useState(false);
  const isToolbarNotShown = useAppSelector(selectIsToolbarNotShown);
  const wideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;

  useEffect(() => {
    if (url) {
      setError(undefined);
      setIsLoaded(false);
      fetchEmbedIframe({ link: url, media: type === EFieldsubType.MIXED });
    }
  }, [url]);

  useEffect(() => {
    if (embedIframe) {
      setIsLoaded(true);
      if (embedIframe.html) {
        setHtml({ __html: embedIframe.html });
      } else if (embedIframe.error) {
        setError(embedIframe.error);
      }
    }
  }, [embedIframe]);

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.iframely && window.iframely.load();
  });

  const eventHandler = () => {
    if (!isInteractionLogged && type === EFieldsubType.MIXED) {
      createRichMediaInteraction({
        linkUrl: url,
      });
      setInteractionLogged(true);
    }
    if (!isInteractionLogged && type === EFieldsubType.VIDEO) {
      createRichMediaInteraction({
        linkUrl: url,
      });
      setInteractionLogged(true);
    }
    if (!isInteractionLogged && type === EFieldsubType.MIXED_URL) {
      createLinkPreviewInteraction({
        linkUrl: url,
      });
      setInteractionLogged(true);
    }
  };

  useEffect(() => {
    if (isActive && !isInteractionLogged) {
      window.addEventListener('blur', eventHandler);
    }
    return () => {
      if (isActive) {
        window.removeEventListener('blur', eventHandler);
      }
    };
  }, [isActive, isInteractionLogged]);

  const renderHeight = () => {
    if (wideScreenStage) {
      if (isMobile) return 'calc(100vh - 52px) !important';
      if (!isToolbarNotShown) return 'calc(100vh - 48px) !important';
      return '100vh !important';
    }
    if (height) return height;
    return '100%';
  };

  const embedStyles = !wideScreenStage ? {
    '& > div': {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      '& > div': {
        height: type === EFieldsubType.MIXED_URL ? undefined : '100% !important',
      },
    },
  } : {};

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: wideScreenStage ? '100%' : width ?? '100%',
        height: wideScreenStage ? '100%' : height ?? '100%',
        '& iframe': {
          width: wideScreenStage ? '100%' : width ?? '100%',
          height: renderHeight(),
        },
        '& .iframely-component': {
          height: renderHeight(),
        },
      }}
    >
      <IFrameCover
        width={width}
        height={height}
      />
      {
        error
          && (
            <div>
              Error:
              {error}
            </div>
          )
      }
      {
        !isLoaded && !html && !error
          && (
            <AppSkeletonStrings />
          )
      }
      {
        isLoaded && type === EFieldsubType.MIXED && !html && !error
          && (
          <Box
            sx={{
              width: wideScreenStage ? '100%' : width ?? '100%',
              height: wideScreenStage ? '100%' : height ?? '100%',
            }}
          >
            <iframe
              id="doc-iframe-id"
              title="media-field"
              className={width || height ? 'widht-height' : ''}
              src={url.startsWith('http') ? url : `//${url}`}
              style={{
                userSelect: 'none',
                margin: '0px',
                borderRadius: wideScreenStage ? undefined : '10px',
                background: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                aspectRatio: isMobile ? '1.52' : undefined,
                height: height || '100%',
              }}
              onMouseEnter={() => setActive(true)}
              onMouseLeave={() => setActive(false)}
            />
          </Box>
          )
      }
      {
        isLoaded && type === EFieldsubType.MIXED_URL && !html && !error
          && (
          <Box
            sx={{
              width: wideScreenStage ? '100%' : width ?? '100%',
              height: wideScreenStage ? '100%' : height ?? '100%',
            }}
          >
            <a
              href={url.startsWith('http') ? url : `//${url}`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'unset',
              }}
            >
              <Typography
                sx={{
                  color: '#7084B4',
                  fontSize: '14px',
                  lineHeight: '16px',
                  textDecoration: 'none',
                  padding: '16px 12px',
                  border: '1px solid #EEF0FA',
                  borderRadius: '16px',
                }}
              >
                {url}
              </Typography>
            </a>
          </Box>
          )
      }
      {
        html && !error
          && (
          <Box
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            className="iframely-component"
            dangerouslySetInnerHTML={html}
            sx={{
              userSelect: 'none',
              width: wideScreenStage ? '100%' : width ?? '100%',
              height: wideScreenStage ? '100%' : height ?? '100%',
              ...embedStyles,
            }}
          />
          )
      }
      {
        containerRef.current
        && !isMobile
        && !wideScreenStage
        && (
          <MediaResizePlugin
            onUpdate={onUpdate}
            containerRef={containerRef.current}
            width={width}
            height={height}
            onWidthUpdate={(newWidth) => setWidth(newWidth)}
            onHeightUpdate={(newHeight) => setHeight(newHeight)}
          />
        )
      }
    </Box>
  );
};
