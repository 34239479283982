import { useEffect, useState } from 'react';
import DealConfirmBuyerAccountFormOne from '../../../features/DealConfirmBuyerAccount/DealConfirmBuyerAccountFormOne';
import DealConfirmBuyerAdditionalIfroForm from '../../../features/DealConfirmBuyerAccount/DealConfirmBuyerAdditionalIfroForm';
import { useAppSelector } from '../../../hooks/stateHooks';

export enum EDealAccConfirmForms {
  INITIAL_FORM = 'INITIAL_FORM',
  ADDITIONAL_DATA_FORM = 'ADDITIONAL_DATA_FORM',
}

const DealConfirmBuyerAccount = (): JSX.Element => {
  const user = useAppSelector((state) => state.app.user);
  const [currentForm, setCurrentForm] = useState<EDealAccConfirmForms>(EDealAccConfirmForms.INITIAL_FORM);

  useEffect(() => {
    if (user) {
      setCurrentForm(EDealAccConfirmForms.ADDITIONAL_DATA_FORM);
    }
  }, [user]);

  return (
    <>
      {
        currentForm === EDealAccConfirmForms.INITIAL_FORM
        && (
          <DealConfirmBuyerAccountFormOne />
        )
      }
      {
        currentForm === EDealAccConfirmForms.ADDITIONAL_DATA_FORM
        && (
        <DealConfirmBuyerAdditionalIfroForm />
        )
      }
    </>
  );
};

export default DealConfirmBuyerAccount;
