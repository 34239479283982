import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../../shared/permissions';
import { ProcessApi } from '../../../api/ProcessApi';
import { EStateStatus } from '../../../core/commonTypes';
import { getUserOrganization } from '../../../core/store/appState/appState';

const processApi = new ProcessApi();

const permissionsReducerToken = 'permissions';

export interface PermissionsState {
  status: EStateStatus;
  isTaskEditable: boolean | undefined;
  isActionCreationEnabled: boolean | undefined;
  isUserAssignEnabled: boolean | undefined;
  isActionDeleteEnabled: boolean | undefined;
  isActionDateEditable: boolean | undefined;
  isActionStatusEditable: boolean | undefined;
  isMilestoneTitleEditable: boolean | undefined;
  isMilestoneAssignPermitted: boolean | undefined;
  isDndEnabled: boolean | undefined;
  isActionDndEnabled: boolean | undefined;
  isTaskCreateEnabled: boolean | undefined;
  isTaskDeleteEnabled: boolean | undefined;
  isTaskStatusEditable: boolean | undefined;
  isLogoEditable: boolean | undefined;
  isSummaryEditable: boolean | undefined;
}

export enum EPermissionsActions {
  GET_USER_PROCESS_PERMISSIONS = 'GET_USER_PROCESS_PERMISSIONS',
}

export const initialState: PermissionsState = {
  status: EStateStatus.IDLE,
};

export const getUserProcessPermissions = createAsyncThunk(
  `${permissionsReducerToken}/${EPermissionsActions.GET_USER_PROCESS_PERMISSIONS}`,
  async (id: string) => processApi.getUserProcessPermissions(id),
);

export const permissionsSlice = createSlice({
  name: permissionsReducerToken,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProcessPermissions.fulfilled, (state, { payload }) => {
        state.status = EStateStatus.IDLE;
        state.isTaskEditable = payload[EUserDealPermissions.DEAL_ACTIONS_EDIT];
        state.isActionCreationEnabled = payload[EUserDealPermissions.DEAL_ACTIONS_CREATE];
        state.isUserAssignEnabled = payload[EUserDealPermissions.DEAL_ACTIONS_ASSIGN];
        state.isActionDeleteEnabled = payload[EUserDealPermissions.DEAL_ACTIONS_DELETE];
        state.isActionDateEditable = payload[EUserDealPermissions.DEAL_ACTIONS_DATE];
        state.isActionStatusEditable = payload[EUserDealPermissions.DEAL_ACTIONS_STATUS];
        state.isMilestoneTitleEditable = payload[EUserDealPermissions.DEAL_MILESTONE_EDIT];
        state.isMilestoneAssignPermitted = payload[EUserDealPermissions.DEAL_MILESTONE_ASSIGN];
        state.isDndEnabled = payload[EUserDealPermissions.DEAL_MILESTONE_REORDER];
        state.isActionDndEnabled = payload[EUserDealPermissions.DEAL_ACTIONS_REORDER];
        state.isTaskCreateEnabled = payload[EUserDealPermissions.DEAL_MILESTONE_CREATE];
        state.isTaskDeleteEnabled = payload[EUserDealPermissions.DEAL_MILESTONE_DELETE];
        state.isTaskStatusEditable = payload[EUserDealPermissions.DEAL_MILESTONE_STATUS];
        state.isLogoEditable = payload[EUserDealPermissions.DEAL_LOGO_EDIT];
        state.isSummaryEditable = payload[EUserDealPermissions.DEAL_SUMMARY_EDIT];
      });
  },
});
