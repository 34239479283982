import 'react-image-crop/dist/ReactCrop.css';
import { TabPanelProps } from '@mui/lab';
import { Box } from '@mui/material';
import {
  selectDealThemePaletteSidebar,
  selectLogoDisplayStatus,
  setDealThemePaletteSidebar,
} from '../../../../features/DealCustomizationMenu/lib/dealCustomizationSlice';
import { EFeedTab, setFeedTab } from '../../../../features/DealFeed/lib/feedSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { selectProcess } from '../../state/processSlice';
import useProcessBackground from '../../../../hooks/useProcessBackground';
import { ThemePalette } from '../../../../../shared/process/ProcessMilestoneActionDTO';
import { updateProcess } from '../../state/dealActions';
import { DealCustomizationContainer, DealFeedContentWrapper, DealFeedTabPanel } from '../Styles/DealFeedStyles';
import DealCustomizationBannerSection from '../../../../features/DealCustomizationMenu/DealCustomizationBannerSection';
import BackgroundColorPickerPanel from '../../../../features/DealCustomizationMenu/BackgroundColorPickerPanel';
import { AppButton } from '../../../../shared/AppButton/AppButton';

interface Props extends TabPanelProps {
  value: EFeedTab,
}

const CustomizationTab = ({
  value,
}: Props) => {
  const dispatch = useAppDispatch();
  const process = useAppSelector(selectProcess);
  const currentDealPaletteSidebar = useAppSelector(selectDealThemePaletteSidebar);
  const displayLogo = useAppSelector(selectLogoDisplayStatus);
  const {
    background: localId,
    backgroundType: type,
    backgroundStorageType: backgroundStorage,
    url,
    display,
    backgroundColor,
  } = useProcessBackground();

  const goBack = () => {
    dispatch(setFeedTab(EFeedTab.NONE));
  };

  const onNewBackgroundSelect = async () => {
    const themePalette: ThemePalette = {
      sidebar: currentDealPaletteSidebar,
    };
    await dispatch(updateProcess({
      id: process.id,
      styles: {
        backgroundStorage,
        type: type ?? process.styles?.type,
        localId,
        url,
        displayBackground: display ?? true,
        backgroundColor: backgroundColor ?? '',
        displayLogo,
        theme: themePalette,
      },
    }));
    dispatch(setDealThemePaletteSidebar(currentDealPaletteSidebar));
  };

  const onSave = () => {
    onNewBackgroundSelect();
    goBack();
  };

  return (
    <DealFeedTabPanel
      value={value}
    >
      <DealFeedContentWrapper>
        <DealCustomizationContainer>
          <DealCustomizationBannerSection />
          <BackgroundColorPickerPanel />
        </DealCustomizationContainer>
        <Box
          sx={{
            padding: '0px 16px',
          }}
        >
          <AppButton
            size="l"
            fullWidth
            variant="primary"
            onClick={onSave}
          >
            Done
          </AppButton>
        </Box>
      </DealFeedContentWrapper>
    </DealFeedTabPanel>
  );
};

export default CustomizationTab;
