import {
  Box, Typography, BoxProps,
} from '@mui/material';
import { StyledTopBar } from './AppTopBarStyles';

type Props = {
  title: string;
  toolsSlot?: JSX.Element | null | false;
  secondLevelSlot?: JSX.Element | null | false;
} & BoxProps;

const AppTopBar = ({
  title, toolsSlot, secondLevelSlot, ...boxProps
}: Props): JSX.Element => (
  <StyledTopBar {...boxProps}>
    <Box className="content-container">
      <Box className="first-level">
        <Box className="title-container">
          <Typography
            className="title"
            variant="h2"
            fontWeight={600}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          {toolsSlot}
        </Box>
      </Box>
      <Box className="second-level">
        {secondLevelSlot}
      </Box>
    </Box>
  </StyledTopBar>
);

export default AppTopBar;
