import { ChangeEvent, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { AppIconButton } from '../../../../../shared/AppButton/AppIconButton';
import { ReplaceIcon, ThreeDotsIcon } from '../../../icons/MenuIcons';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { MediaNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import { useCurrentStage } from '../../../../../hooks/useCurrentStage';
import { StageContentType } from '../../../../../../shared/Stage';
import AlignLeft from '../../../../../icons/align-left20px.svg';
import AlignCenter from '../../../../../icons/align-center20px.svg';
import AlignRight from '../../../../../icons/align-right20px.svg';
import { EAlignMediaOptions } from '../../../../../../shared/events/CustomEvents';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../../../../routes-old/process/state/processSlice';
import {
  ENewPageTabs,
  ESidebarTabs,
  setNewPageTab,
  setSidebarTab,
} from '../../../../Layout/lib/fieldsLayoutSlice';
import { setCurrentStageNeedsToBeUpdated } from '../../../../BlockContentSection/lib/sectionsSlice';
import ToolbarAdjustButton from './components/ToolbarAdjustButton';
import { AppDropdown } from '../../../../../shared/AppDropdown/AppDropdown';
import { getSubtypeByMIME, mediaFieldFormats, mediaFieldMimes } from '../../../../MediaField/Helpers/MediaFieldHelpers';
import { useFileUploadContext } from '../../../../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { EMediaFieldStorageType } from '../../../../../../shared/Field/Field';

interface Props {
  isPdf?: boolean;
  anchor: HTMLButtonElement | null;
  setAnchor: (state: HTMLButtonElement | null) => void;
}

const MAX_SIZE = 1024 * 1024 * 50;

const InternalMediaToolbarItems = ({ isPdf, setAnchor, anchor }: Props): JSX.Element => {
  const currentStage = useCurrentStage();
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);
  const { data, onUpdate } = useBlockContext<MediaNodeAttrs>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { onUpload } = useFileUploadContext();
  const inputAccepts = mediaFieldMimes(mediaFieldFormats);

  const dispatch = useAppDispatch();

  const onReplaceMedia = () => {
    if (fileInputRef?.current) fileInputRef.current.click();
    setAnchor(null);
  };

  const handleWizardButtonClick = () => {
    dispatch(setSidebarTab(ESidebarTabs.NEWPAGE));
    dispatch(setNewPageTab(ENewPageTabs.BLANK));
    dispatch(setCurrentStageNeedsToBeUpdated(true));
  };

  const onAlignUpdate = (align: EAlignMediaOptions) => {
    onUpdate({ align });
  };

  const handleMediaFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.[0]) return;
    if (event?.target?.files?.[0].size > MAX_SIZE) {
      enqueueSnackbar('File must be smaller than 50mb!', { variant: 'error' });
    } else {
      try {
        const itemId = await onUpload(event.target.files[0], data.blockId);
        onUpdate({
          doc: itemId,
          subType: getSubtypeByMIME(event.target.files[0].type),
          storageType: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
        });
      } catch (e) {
        console.log('error occurred file uploading process');
      }
    }
  };

  return (
    <>
      {
        currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE
        && (
          <>
            <ToolbarAdjustButton isPdf={isPdf} />
            {isLayoutEditMode && (
            <AppIconButton
              onClick={handleWizardButtonClick}
              icon={<ReplaceIcon />}
            />
            )}
          </>
        )
      }
      {
        isLayoutEditMode
        && currentStage?.stageContentType !== StageContentType.WIDESCREENSTAGE
        && (
        <>
          <AppIconButton
            variant={data?.align === EAlignMediaOptions.START ? 'primary' : 'transparent'}
            onClick={() => onAlignUpdate(EAlignMediaOptions.START)}
            icon={<AlignLeft />}
          />
          <AppIconButton
            variant={!data?.align || data?.align === EAlignMediaOptions.CENTER ? 'primary' : 'transparent'}
            onClick={() => onAlignUpdate(EAlignMediaOptions.CENTER)}
            icon={<AlignCenter />}
          />
          <AppIconButton
            variant={data?.align === EAlignMediaOptions.END ? 'primary' : 'transparent'}
            onClick={() => onAlignUpdate(EAlignMediaOptions.END)}
            icon={<AlignRight />}
          />
          <AppIconButton
            onClick={(e) => setAnchor(e.currentTarget)}
            icon={<ThreeDotsIcon />}
          />
        </>
        )
      }
      <AppDropdown
        id="section-options-menu"
        items={[
          {
            label: 'Replace media',
            onClick: onReplaceMedia,
            startIcon: <ReplaceIcon />,
            hidden: false,
          },
        ]}
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
      <input
        hidden
        type="file"
        accept={inputAccepts}
        ref={fileInputRef}
        onChange={handleMediaFileChange}
        className="input"
      />
    </>
  );
};

export default InternalMediaToolbarItems;
