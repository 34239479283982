import { styled } from '@mui/styles';
import { Box } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const ToolbarPlaceholder = styled(Box)(() => ({
  width: '100%',
  height: '48px',
  minHeight: '48px',
  maxHeight: '48px',
  position: 'relative',
}));

export const StagesToolbarWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  width: '100%',
  height: '48px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 24px',
  backgroundColor: stagesetColors.newGrey[800],
  boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  '& .stages-toolbar-wrapper-justify-start': {
    justifyContent: 'flex-start',
  },
}));

export const ToolbarRightSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  minWidth: '120px',
  width: 'calc(50% - 165px)',
  justifyContent: 'flex-end',
  '& .toolbar-right-section-mobile': {
    width: 'calc(50% - 90px)',
  },
}));
