import { LibraryApi } from '../../../../../api/LibraryApi';

export type UrlCheckResponse = {
  status: boolean;
  message: string;
};

export const checkUrl = async (url: string): Promise<UrlCheckResponse> => {
  const libraryApi: LibraryApi = new LibraryApi();
  try {
    return await libraryApi.checkUrl({ url });
  } catch (error: any) {
    return { status: false, message: `Error: ${error?.message}` };
  }
};
