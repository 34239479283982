import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from './state/processSlice';
import { selectDealViewMode } from '../../features/Layout/lib/fieldsLayoutSlice';

const DealIntercomPlugin = (): null => {
  const isLayoutEditActive = useAppSelector(selectLayouEditingStatus);
  const isLocationIsDealPage = window.location.href.includes('/deal/');
  const viewMode = useAppSelector(selectDealViewMode);
  const { user } = useAppSelector((state) => state.app);

  if ((isLayoutEditActive || viewMode === 'internal') && isLocationIsDealPage) {
    if (!isMobile) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'm6snnga4',
        name: `${user?.name}`, // Full name
        email: `${user?.email}`, // Email address
        // created_at: 'user.createdAt', // Signup date as a Unix timestamp
      });
    }
  }
  if (!isLayoutEditActive && viewMode !== 'internal' && isLocationIsDealPage) {
    window.Intercom('shutdown');
  }
  return null;
};

export default DealIntercomPlugin;
