import { Box, Typography } from '@mui/material';
import PasswordSecurityIdentificatorIcon from './PasswordSecurityIdentificatorIcon';

interface Props {
  text: string;
  status: boolean;
  error?: boolean;
}

const PasswordSecurityIdentificatorItem = ({
  text,
  status,
  error,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      gap: '4px',
    }}
  >
    <PasswordSecurityIdentificatorIcon
      status={status}
      error={error}
    />
    <Typography
      sx={{
        color: '#7084B4',
        fontSize: '12px',
        lineHeight: '16px',
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default PasswordSecurityIdentificatorItem;
