import { styled } from '@mui/styles';
import { Box } from '@mui/material';

export const StagesSidebarHeaderWrapper = styled(Box)(() => ({
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&.stages-sidebar-header-wrapper-mobile': {
    padding: '12px 16px',
  },
}));

export const SidebarStagesPagesContainer = styled(Box)(() => ({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc( 100% )',
  '&::-webkit-scrollbar': {
    width: '0',
    height: '0',
    display: 'none',
  },
}));

export const StagesSidebarLogoTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '16px',
}));
