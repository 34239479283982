import {
  Node, NodeViewProps, ReactNodeViewRenderer, mergeAttributes,
} from '@tiptap/react';
import { ExtensionNames } from '../../Utils/TiptapHelpers';
import ButtonNodeComponent from './ButtonNodeComponents/ButtonNodeComponent';
import TiptapNodeWrapper from '../../TiptapNodeWrapper';

const ButtonNodeExtension = Node.create({
  name: ExtensionNames.BUTTON, // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      widthType: {
        default: null,
      },
      title: {
        default: null,
      },
      color: {
        default: 'primary',
      },
      url: {
        default: null,
      },
      align: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: ExtensionNames.BUTTON,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [ExtensionNames.BUTTON, mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer((nodeProps: NodeViewProps) => (
      <TiptapNodeWrapper
        {...nodeProps}
        component={<ButtonNodeComponent />}
        className={ExtensionNames.DOCUMENTS}
      />
    ));
  },
});

export default ButtonNodeExtension;
