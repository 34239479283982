import { ProcessStatsDTO } from '../../shared/process/ProcessMilestoneActionDTO';
import { ApiService } from '../core/api';

export class ProcessStatsApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('process-stats');
  }

  async getProcessesStats(payload: string[]): Promise<ProcessStatsDTO[]> {
    return this.api.post<ProcessStatsDTO[]>('/get-for-ids', {
      ids: payload,
      getOwner: true,
    });
  }

  async getBuyerDealsStats(payload: string[]): Promise<ProcessStatsDTO[]> {
    return this.api.post<ProcessStatsDTO[]>('/get-for-ids', {
      ids: payload,
    });
  }

  async getTeamDealsStats(payload: string[]): Promise<ProcessStatsDTO[]> {
    return this.api.post<ProcessStatsDTO[]>('/get-for-ids', {
      ids: payload,
      getOwner: true,
    });
  }
}
