import { useState } from 'react';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { MenuTemplateIcon20 } from '../../icons/MenuIcons/TemplateIcon';
import { AppButton } from '../../shared/AppButton/AppButton';
import CollectTemplateInfoDialog, { ICreateTemplatePopup } from '../CollectTemplateInfoDialog/CollectTemplateInfoDialog';
import { ECreateTemplateRequestType, EProcessType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import useModalWindow from '../../hooks/useModalWindow';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';
import { createTemplate } from '../../pages/templates/lib/templatesSlice';

const analytics: Analytics = Analytics.getInstance();

const RGMSaveRoomAsTemplateButton = (): JSX.Element | null => {
  const [collectDialog, setCollectDialog] = useState(false);
  const dispatch = useAppDispatch();
  const process: ProcessDTO = useAppSelector((state) => state.process?.process);

  const { closeDrawer } = useMobileBottomDrawer();

  const { setTemplateModalWindowState, onModalWindowStateReset } = useModalWindow();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCreateTemplate = async (name: string) => {
    try {
      setTemplateModalWindowState();
      const template = await dispatch(createTemplate({
        title: name,
        type: EProcessType.TEMPLATE,
        templateId: process.id,
        createType: ECreateTemplateRequestType.DEAL,
      })).unwrap();
      if (template) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      analytics.track(EMixPanelEvents.TEMPLATE_FROM_ROOM_CREATED);
      onModalWindowStateReset();
      closeDrawer();
    }
  };

  const onCollectedTemplateInfo = (data: ICreateTemplatePopup) => {
    onCreateTemplate(data.name);
  };

  return (
    <>
      <AppButton
        size="l-flex-start"
        variant="transparent-grey-icon"
        fullWidth
        disableRipple
        startIcon={<MenuTemplateIcon20 />}
        onClick={() => setCollectDialog(true)}
      >
        Save room as template
      </AppButton>
      <CollectTemplateInfoDialog
        open={collectDialog}
        setOpen={setCollectDialog}
        onSubmit={onCollectedTemplateInfo}
      />
    </>
  );
};

export default RGMSaveRoomAsTemplateButton;
