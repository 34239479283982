import {
  Avatar, Box, Typography, SvgIcon, Popover,
} from '@mui/material';
import { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectProcessUserById } from '../../routes-old/process/state/processSlice';
import { Rocket20px } from '../../icons/Rocket';
import { EAppRoutes } from '../../core/router';
import { iconColorPalette } from '../../routes-old/process/helpers';
import { ChevronDown20 } from '../../icons/ChevronDown';
import {
  ESettingsTabs, ESidebarTabs, setSettingsTab, setSidebarTab,
} from '../../features/Layout/lib/fieldsLayoutSlice';
import { EFeedTab, setFeedTab, setIsInternalFilters } from '../../features/DealFeed/lib/feedSlice';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  sessionData: {
    id: string,
    startDate: string,
    endDate: string,
  },
  eventsCount: number,
  open: boolean;
  tooltipOpen: boolean;
  closeTooltip: () => void;
}

const ProcessTimelineSessionHeader = ({
  sessionData,
  eventsCount,
  open,
  tooltipOpen,
  closeTooltip,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => selectProcessUserById(state, sessionData.id));
  const popperAnchor = useRef<HTMLDivElement>(null);

  const startDate = new Date(sessionData.startDate);
  const endDate = new Date(sessionData.endDate);

  const sessionInfo = useMemo(() => {
    const startHours = startDate.getHours() < 10 ? `0${startDate.getHours()}` : startDate.getHours();
    const startMinutes = startDate.getMinutes() < 10 ? `0${startDate.getMinutes()}` : startDate.getMinutes();
    const endHours = endDate.getHours() < 10 ? `0${endDate.getHours()}` : endDate.getHours();
    const endMinutes = endDate.getMinutes() < 10 ? `0${endDate.getMinutes()}` : endDate.getMinutes();

    return `${endHours}:${endMinutes}-${startHours}:${startMinutes}`;
  }, [eventsCount, startDate, endDate]);

  const colorId = useMemo(() => user?.colorId, [user?.id]);

  const handleUpgradeButtonClick = () => {
    dispatch(setSidebarTab(ESidebarTabs.SETTINGS));
    sessionStorage.setItem('settings-tab', ESettingsTabs.SUBSCRIPTION);
    dispatch(setSettingsTab(ESettingsTabs.SUBSCRIPTION));
    navigate(EAppRoutes.settings);
    dispatch(setFeedTab(EFeedTab.NONE));
    dispatch(setIsInternalFilters(false));
  };

  return (
    <>
      <Box
        sx={{
          padding: '16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '16px',
          height: '76px',
          '&:hover': {
            background: eventsCount ? stagesetColors.newGrey[50] : undefined,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            height: '44px',
          }}
        >
          <Avatar
            src={user?.avatarSrc}
            sx={{
              width: '40px',
              height: '40px',
              background: colorId ? `${iconColorPalette[colorId].back}` : undefined,
              color: colorId ? `${iconColorPalette[colorId].letter}` : undefined,
            }}
          >
            {user?.name?.[0] ?? user?.email?.[0]}
          </Avatar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                color: stagesetColors.newGrey[800],
                fontSize: '16px',
                lineHeight: '26px',
              }}
            >
              {user?.name ?? user?.email}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '4px',
              }}
            >
              <Typography
                sx={{
                  color: stagesetColors.newGrey[500],
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
              >
                {sessionInfo}
              </Typography>
              <Typography
                sx={{
                  color: stagesetColors.newGrey[300],
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
              >
                •
              </Typography>
              <Typography
                sx={{
                  color: stagesetColors.newGrey[500],
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
              >
                {`${eventsCount} activities`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
          ref={popperAnchor}
        >
          <ChevronDown20
            className={classNames('TmelineFiltegGroupIcon', { 'TmelineFiltegGroupIcon-open': open })}
            sx={{
              stroke: stagesetColors.newGrey[500],
              visibility: eventsCount > 0 ? 'visible' : 'hidden',
              transition: 'transform 0.2s',
              '&.TmelineFiltegGroupIcon-open': {
                transform: 'scale(1, -1)',
              },
            }}
          />
        </Box>
      </Box>
      <Popover
        open={tooltipOpen}
        anchorEl={popperAnchor.current}
        onClose={closeTooltip}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            padding: '16px',
            background: '#D1E7FF',
            width: '220px',
            borderRadius: '16px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Typography
            sx={{
              width: '190px',
              color: '#1A1929',
              fontSize: '14px',
              lineHeight: '16px',
            }}
          >
            Upgrade your plan to get more information about your customers actions
          </Typography>
          <Box
            onClick={handleUpgradeButtonClick}
            sx={{
              width: '100%',
              background: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6px 12px',
              gap: '8px',
              borderRadius: '8px',
              cursor: 'pointer',
              transition: 'background 0.2s',
              '&:hover': {
                background: '#E5EDFF',
              },
            }}
          >
            <Rocket20px
              sx={{
                fontSize: '20px',
              }}
            />
            <Typography
              sx={{
                color: '#0073F5',
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 500,
              }}
            >
              Upgrade
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ProcessTimelineSessionHeader;
