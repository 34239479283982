import { Box, Chip } from '@mui/material';
import { AppAvatar } from '../../shared/AppAvatar';
import { AppLinkButton } from '../../shared/AppButton/AppLinkButton';
import Cross from '../../icons/DealsFiltersInput/Cross.svg';
import {
  LibraryFilesFilterCategory,
  LibraryFilesMultipleFilter,
} from '../../../shared/library/LibraryFilter';

type Props = {
  filter: LibraryFilesMultipleFilter,
  preview?: boolean,
  onDelete: (category: LibraryFilesFilterCategory) => void,
  onClick: (e: any) => void,
};

export const LibraryDeepChip = ({
  filter,
  preview,
  onDelete,
  onClick,
}: Props) => (
  <Chip
    onClick={onClick}
    clickable={false}
    sx={{
      display: 'flex',
      background: (theme) => theme.palette.background.default,
      fontSize: '14px',
      color: (theme) => theme.palette.grey[400],
      padding: '2px 8px 2px 8px',
      borderRadius: '8px',
      height: 'auto',
      cursor: 'pointer',
      '& .MuiChip-label': {
        padding: 0,
      },
    }}
    label={(
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
      >
        {filter.title}
        {' '}
        {filter.subItems.slice(0, preview ? 1 : Infinity).map((item) => (
          <Chip
            avatar={filter.category === 'owner' ? <AppAvatar src={item.avatar} name={item.title} colorId={item.colorId} /> : undefined}
            key={item.value}
            label={item.title}
            sx={{
              minHeight: '16px',
              height: 'auto',
              padding: filter.category === 'owner' ? '2px 6px 2px 0px' : '0px 6px',
              background: (theme) => theme.palette.grey[100],
              borderRadius: filter.category === 'owner' ? '12px 4px 4px 12px' : '4px',
              gap: '12px',
              cursor: 'pointer',
              '& .MuiChip-label': {
                padding: 0,
              },
            }}
          />
        ))}
        {preview && filter.subItems.length > 1 && `+${filter.subItems.length - 1}`}
        <AppLinkButton
          autoWidth
          onClick={(e) => {
            e.stopPropagation();
            onDelete(filter.category);
          }}
          transparentStroke
          size="s"
          variant="lite-secondary"
          sx={{
            marginLeft: '6px',
            width: 'auto',
            padding: '0 !important',
            minWidth: 'auto',
            '& g': {
              stroke: 'inherit',
              color: 'inherit',
            },
          }}
        >
          <Cross />
        </AppLinkButton>
      </Box>
    )}
  />
);
