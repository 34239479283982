import { isMobile } from 'react-device-detect';
import { MouseEvent } from 'react';
import { EFeedTab, selectFeedTab, setFeedTab } from '../../../../features/DealFeed/lib/feedSlice';
import { selectDealViewMode } from '../../../../features/Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { useMobileBottomDrawer } from '../../../../hooks/useMobileBottomDrawer';
import { selectLayouEditingStatus } from '../../state/processSlice';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { SettingsIcon } from '../../../../icons/SettingsIcon';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { BroomIcon20 } from '../../../../icons/BroomIcon';

interface Props {
  roomGeneralMobileMenu?: boolean
}

const CustomizationButton = ({ roomGeneralMobileMenu }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const currentTab = useAppSelector(selectFeedTab);
  const viewMode = useAppSelector(selectDealViewMode);
  const { closeDrawer } = useMobileBottomDrawer();

  if (
    (!isLayoutEdit && !isMobile)
    || (currentTab === EFeedTab.CUSTOMIZATION && isMobile)
  ) return null;

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setFeedTab(EFeedTab.CUSTOMIZATION));
    if (roomGeneralMobileMenu) {
      closeDrawer();
    }
  };

  if (roomGeneralMobileMenu) {
    return (
      <AppButton
        fullWidth
        disableRipple
        variant="transparent-grey-icon"
        size="l-flex-start"
        onClick={onClick}
        startIcon={<SettingsIcon />}
      >
        Customization
      </AppButton>
    );
  }

  if (!isMobile) {
    return (
      <AppIconButton
        disableRipple
        variant="transparent-white"
        size="l"
        onClick={onClick}
        icon={<BroomIcon20 />}
        hidden={viewMode === 'internal'}
      />
    );
  }

  return (
    <AppIconButton
      variant="secondary"
      onClick={onClick}
      size="l"
      sx={{
        zIndex: 10,
        position: 'absolute',
        right: isMobile ? '32px' : '122px',
        top: '32px',
      }}
      icon={<SettingsIcon />}
    />
  );
};

export default CustomizationButton;
