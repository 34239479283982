import { isMobile } from 'react-device-detect';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { SidebarContent } from '../../Stages/Styles/SidebarStyles';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { CloseIcon20 } from '../../../icons/CloseIcon';
import {
  NewPageDragAndDropContainer,
  NewPageDragAndDropFileFormats,
  NewPageDragAndDropTitleAndIcon,
  NewPageFileItem,
  NewPageFileTitleGroup,
  NewPageSwitchTitle,
  NewPageSwitchWrapper,
  NewPageSwitchesWrapper,
} from '../Styles/NewPageMainTabStyles';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { useNewPageContext } from '../NewPageContextProvider/NewPageContextProvider';
import { FilesDragAndDrop } from '../../../common/FilesDragAndDrop/FilesDragAndDrop';
import { UploadIcon20 } from '../../../icons/UploadIcon';
import TrashIcon from '../../../icons/TrashIcon';
import { MediaFieldUploadIcon } from '../../../icons/DndIcons/DnDIcons';
import { FormattedLabel } from '../../../shared/AssigneeSelection/FormattedLabel';
import { mediaFieldFormats, mediaFieldMimes } from '../../MediaField/Helpers/MediaFieldHelpers';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { getIcon } from '../Helpers/NewPageHelpers';
import { selectSidebarTab } from '../../Layout/lib/fieldsLayoutSlice';
import { useAppSelector } from '../../../hooks/stateHooks';

const NewPageMediaInputs = (): JSX.Element => {
  const currentSidebarTab = useAppSelector(selectSidebarTab);
  const { control, setValue, trigger } = useFormContext();
  const { methods, data } = useNewPageContext();
  const { clearField } = methods;
  const {
    libraryFileTitle, templateTitle, maxSize, libraryFileToAdd,
  } = data;

  useEffect(() => {
    if (libraryFileTitle) {
      setValue('title', libraryFileTitle);
    }
    setValue('libraryFileToAdd', libraryFileToAdd);
    trigger();
  }, [libraryFileTitle]);

  const dndText: JSX.Element = (
    <NewPageDragAndDropContainer>
      <NewPageDragAndDropTitleAndIcon>
        <MediaFieldUploadIcon />
        Drop your file here or
        <Box
          component="span"
          sx={{
            textDecoration: 'underline',
          }}
        >
          &nbsp;browse
        </Box>
      </NewPageDragAndDropTitleAndIcon>
      <NewPageDragAndDropFileFormats
        className="DnDAcceptedFormats"
      >
        {mediaFieldFormats.join(', ').toUpperCase()}
      </NewPageDragAndDropFileFormats>
    </NewPageDragAndDropContainer>
  );

  return (
    <SidebarContent
      className="sidebar-new-page"
    >
      {(libraryFileTitle || templateTitle) && (
        <AppButton
          disableRipple
          fullWidth
          disabled
          variant="secondary-disabled"
          size="xl-flex-start"
          startIcon={getIcon(currentSidebarTab)}
        >
          <FormattedLabel
            label={libraryFileTitle || templateTitle}
            len={24}
            tooltip
            placement="right"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: stagesetColors.newGrey[800],
            }}
          />
        </AppButton>
      )}
      <Controller
        key="Name"
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => (
          <AppInput
            className={classNames('settings-menu-input', { 'settings-menu-input-full': isMobile })}
            fullWidth
            required
            size="l"
            label="Name"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <button
                  className="end-adorment-button"
                  type="button"
                  aria-label="title"
                  onClick={() => clearField('title')}
                  onMouseDown={() => clearField('title')}
                >
                  <CloseIcon20 />
                </button>
              ),
            }}
          />
        )}
      />
      <NewPageSwitchesWrapper>
        <Controller
          key="Lock"
          name="lock"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NewPageSwitchWrapper>
              <AppSwitch
                checked={value}
                onChange={onChange}
              />
              <NewPageSwitchTitle>
                Lock
              </NewPageSwitchTitle>
            </NewPageSwitchWrapper>
          )}
        />
        <Controller
          key="Hide"
          name="hide"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NewPageSwitchWrapper>
              <AppSwitch
                checked={value}
                onChange={onChange}
              />
              <NewPageSwitchTitle>
                Hide for buyer
              </NewPageSwitchTitle>
            </NewPageSwitchWrapper>
          )}
        />
      </NewPageSwitchesWrapper>
      {
        !(templateTitle || libraryFileTitle) && (
          <Controller
            name="file"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box>
                <FilesDragAndDrop
                  newPageMediaInput
                  dropzoneOptions={{
                    accept: mediaFieldMimes(mediaFieldFormats),
                    maxSize,
                  }}
                  onFileDrop={onChange}
                  text={dndText}
                  icon={<UploadIcon20 />}
                  border="1px dashed"
                  borderColor={stagesetColors.newGrey[300]}
                  borderRadius="12px"
                  background={stagesetColors.white[100]}
                  styles={{
                    height: '110px',
                    maxWidth: 'unset',
                    minHeight: '110px',
                    background: stagesetColors.white[100],
                    padding: '16px 24px',
                  }}
                  textStyle={{
                    alignSelf: 'center',
                    fontSize: '14px',
                    lineHeight: '16px',
                    fontWieght: 500,
                  }}
                  textContainerStyles={{
                    background: stagesetColors.white[100],
                    padding: '0px',
                  }}
                />
                {value && value.length > 0 && (
                  <NewPageFileItem>
                    <NewPageFileTitleGroup>
                      {getIcon(currentSidebarTab)}
                      <FormattedLabel
                        label={value?.[0]?.name}
                        len={24}
                        tooltip
                        placement="right"
                        sx={{
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: stagesetColors.newGrey[800],
                        }}
                      />
                    </NewPageFileTitleGroup>
                    <AppIconButton
                      disableRipple
                      value={undefined}
                      icon={<TrashIcon />}
                      variant="danger-transparent"
                      onClick={() => {
                        clearField('file');
                      }}
                    />
                  </NewPageFileItem>
                )}
              </Box>
            )}
          />
        )
      }
    </SidebarContent>
  );
};

export default NewPageMediaInputs;
