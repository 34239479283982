import { SvgIcon, SvgIconProps } from '@mui/material';

export const AirBalloonIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3567 0.846405C26.9307 1.89954 22.6779 4.21901 18.5964
      8.3508C10.6708 16.3739 8.75842 27.6131 13.5836 37.8102C15.2789
      41.3929 15.1551 41.2313 25.4268 53.2717L34.0767 63.4111V65.4028C34.0767
      66.9202 33.9704 67.3945 33.6298 67.3945C32.5528 67.3945 32.2003 68.3124
      32.2003 71.1177C32.2003 74.6049 32.8201 76.4842 34.5377 78.2032C37.5965
      81.2656 42.4408 81.2656 45.4996 78.2032C47.2172 76.4842 47.837 74.6049
      47.837 71.1177C47.837 68.3124 47.4845 67.3945 46.4075 67.3945C46.0672
      67.3945 45.9606 66.9224 45.9606 65.4191V63.4433L49.3225 59.4797L52.6844
      55.5157L54.4044 55.4988C55.3504 55.4894 56.6852 55.2298 57.3704 54.9218C58.5688
      54.383 58.6729 54.3833 60.1099 54.9299C61.9244 55.6199 65.0889 55.6731 66.8718
      55.0433C67.9977 54.6457 68.2957 54.6457 69.2464 55.0433C70.5549 55.5908 73.1152
      55.6331 74.7765 55.1347C76.3495 54.6629 78.3382 52.9432 79.1479 51.3548C81.5203
      46.6996 78.7176 41.0832 73.4811 39.9985C72.7891 39.8551 71.9075 39.2753 71.0831
      38.421C70.3663 37.6781 69.3108 36.8312 68.7376 36.5395C68.1644 36.2477 67.6555
      35.975 67.6071 35.9337C67.5583 35.8924 67.836 34.7481 68.2244 33.3907C68.8036
      31.3661 68.9318 30.1355 68.9384 26.54C68.9474 21.5754 68.7038 20.7483 67.234
      20.7483C66.7342 20.7483 66.2126 20.9243 66.075 21.1397C65.9371 21.355 65.8157
      23.6442 65.8051 26.2269C65.7888 30.1887 65.6784 31.2462 65.0999 32.9922L64.4137
      35.0615L62.6918 35.2393C59.5536 35.5636 56.1326 37.7397 54.8357 40.2364C54.537
      40.8109 54.1395 41.0973 53.6401 41.0973C53.2285 41.0973 52.6756 41.1806 52.411
      41.282C52.0029 41.4389 51.9757 41.3083 52.2318 40.4211C53.9619 34.4263 55.1093
      25.7748 55.1518 18.4004C55.1909 11.6182 54.9251 10.4521 52.5402 6.95486L51.2624
      5.08056L52.4386 5.57269C54.258 6.33374 56.572 7.90406 58.5666 9.73108C60.5978
      11.5919 61.4347 11.7337 62.209 10.3478C62.6762 9.51194 62.6553 9.46467 61.125
      7.89248C58.2279 4.9162 54.6433 2.81869 50.1221 1.45406C47.5987 0.692378 46.8309
      0.614425 40.9569 0.522072C36.4632 0.45132 33.8913 0.54837 32.3567 0.846405ZM38.455
      25.4443V47.3586H35.8058H33.1566L32.2147 44.3062C28.8087 33.2677 26.8873 17.8738
      28.2677 12.6826C29.3598 8.57589 32.8405 5.0455 36.8691 3.95792C37.6553 3.74598
      38.334 3.56284 38.3768 3.55094C38.4196 3.53936 38.455 13.3914 38.455 25.4443ZM45.6235
      4.92622C47.8332 6.04729 49.9232 8.24843 51.0341 10.6249C51.8462 12.3624 52.0251 13.1788
      52.1462 15.7043C52.5049 23.1789 50.7251 35.1921 47.968 43.9077L46.8766
      47.3586H44.2296H41.5823V25.4774V3.59665L42.8576 3.88341C43.5588 4.0412
      44.8035 4.51047 45.6235 4.92622ZM28.6342 5.35918C28.5445 5.50444 27.984
      6.34783 27.3889 7.23317C25.1169 10.613 24.8467 11.7857 24.888 18.0873C24.9433
      26.5199 26.1489 34.6645 28.6064 43.2102C29.2071 45.2986 29.6984 47.0862 29.6984
      47.1829C29.6984 47.2793 28.5251 47.3586 27.0909 47.3586H24.4836L21.5749 43.9236C18.0851
      39.8028 17.3842 38.7973 16.2387 36.268C14.7216 32.9189 14.2557 30.648 14.2419
      26.54C14.2316 23.4657 14.3614 22.3565 14.9565 20.4353C16.9627 13.9574 21.0063
      9.02419 26.7275 6.07547C28.8037 5.00574 28.8672 4.98163 28.6342 5.35918ZM63.965
      14.3528C63.3817 14.9367 63.3226 16.089 63.849 16.6159C64.6789 17.4468 66.601
      16.7098 66.601 15.5606C66.601 14.9824 65.4808 13.861 64.9032 13.861C64.6577
      13.861 64.2355 14.0823 63.965 14.3528ZM65.8192 38.6198C66.7108 38.8596 67.6089
      39.5201 68.9465 40.9198C70.6381 42.6899 70.9727 42.8999 72.344 43.0518C76.9387
      43.5599 78.5108 49.1511 74.764 51.6569C73.3958 52.572 71.2213 52.6086 69.799
      51.7402C68.5268 50.9638 68.2807 50.9638 66.7574 51.7414C64.9739 52.6518 62.3216
      52.587 60.3463 51.5849C59.4979 51.1544 58.615 50.8022 58.3849 50.8022C58.1544
      50.8022 57.4892 51.1544 56.9063 51.5849C55.5246 52.6055 53.3026 52.6649 52.0949
      51.7139C50.3304 50.3245 49.8488 48.0103 50.9487 46.2046C51.7437 44.8991 52.6231
      44.4399 54.714 44.2376C56.3868 44.0758 56.4478 44.0354 57.1058 42.6626C58.778 39.1742
      62.1186 37.6242 65.8192 38.6198ZM47.8242 51.3974C48.0309 51.897 48.5406 52.6258
      48.9566 53.0174C49.3728 53.4088 49.7134 53.853 49.7134 54.0045C49.7134 54.1561 48.4118
      55.8118 46.8206 57.6842L43.9278 61.0885L40.1137 61.111L36.2993 61.1332L32.4777 56.6721C30.3761
      54.2183 28.3015 51.8234 27.868 51.3501L27.0796 50.4892H37.2641H47.4483L47.8242 51.3974ZM11.983
      55.6143C10.1685 56.2088 8.05227 57.8079 7.15722 59.2608C6.63746 60.1045 6.17493 60.493 5.68362
      60.4987C4.69538 60.5103 2.81523 61.5243 1.80354 62.5915C-0.675503 65.2065 -0.590127
      69.0227 2.00556 71.6211C3.07011 72.6868 3.72622 73.0512 5.15854 73.3721C6.79258
      73.7383 7.13877 73.7174 8.74059 73.1554C10.238 72.6304 10.6564 72.5935 11.4279
      72.9172C13.0898 73.6144 15.4531 73.7534 17.1556 73.2537C18.5617 72.8411 18.8994
      72.8408 20.0781 73.2515C22.4227 74.0683 25.7433 73.2356 27.3945 71.4164C28.2414
      70.4838 28.9347 69.0956 29.2228 67.757C29.9677 64.2961 27.2676 60.2075 23.8741
      59.6581C22.9991 59.5166 22.2895 59.0786 21.3234 58.0843C20.5869 57.3267 19.3291
      56.4164 18.5279 56.0617C16.71 55.2568 13.7015 55.0511 11.983 55.6143ZM17.4405
      59.1262C18.3343 59.5723 19.0692 60.03 19.0739 60.1437C19.0786 60.2576 19.4864
      60.8383 19.9802 61.4344C20.8312 62.4622 21.1317 62.5771 23.2139 62.6732C24.1803
      62.7177 25.9516 64.6352 26.1436 65.8442C26.6777 69.2115 23.3659 71.468 20.207
      69.8889L18.789 69.1802L17.2276 69.8526C15.1469 70.7486 13.5617 70.7089 11.6919
      69.7136L10.1673 68.9019L8.55139 69.7769C7.14471 70.5385 6.781 70.6134 5.73991
      70.3535C1.89517 69.3949 2.64479 63.6377 6.61463 63.6377C7.93531 63.6377 9.05802
      62.9731 9.05802 62.1914C9.05802 61.477 11.0367 59.4919 12.3389 58.8996C14.0733
      58.111 15.535 58.1758 17.4405 59.1262ZM42.8333
      65.8292V67.3945H40.0187H37.204V65.8292V64.2639H40.0187H42.8333V65.8292ZM44.7097
      72.1029C44.7097 74.2524 43.7899 75.9783 42.2159 76.7822C38.799 78.5272 35.3276 76.169 35.3276
      72.1029V70.5251H40.0187H44.7097V72.1029Z"
    />
  </SvgIcon>
);
