import {
  Avatar, Box, MenuItem, Typography,
} from '@mui/material';
import { ClearbitAutocompleteDTO, EClearbitLogoStatus } from '../../../shared/ClearbitDTO';
import useClearbitAutocomplete from './useClearbitAutocomplete';

export const initialCompany: ClearbitAutocompleteDTO = {
  name: '',
  domain: '',
  logo: '',
};

export const disabledLogoCompany: ClearbitAutocompleteDTO = {
  name: '',
  domain: '',
  logo: EClearbitLogoStatus.DISABLED,
};

interface Props {
  value: string;
  isAutocompleteVisible: boolean;
  setSelectedCompany: (company: ClearbitAutocompleteDTO) => void;
  positionOffset?: string;
}

const CompanyAndLogoSelect = ({
  value,
  isAutocompleteVisible,
  setSelectedCompany,
  positionOffset = '-20px',
}: Props): JSX.Element => {
  const companysData = useClearbitAutocomplete(value, true);

  return (
    <Box
      sx={{
        width: '100%',
        zIndex: 5,
        marginTop: positionOffset,
      }}
    >
      <Box
        sx={{
          display: isAutocompleteVisible ? '' : 'none',
          boxShadow: '0px 9px 13px 0px rgb(0 0 0 / 20%)',
          width: '320px',
          position: 'absolute',
          zIndex: 10,
        }}
      >
        {
        !!companysData.length
        && (
          <Box
            sx={{
              background: 'white',
              overflow: 'auto',
              zIndex: 10,
            }}
          >
            {
              companysData.map((company: ClearbitAutocompleteDTO) => (
                <MenuItem
                  id="company-information-autocomplete"
                  key={company.domain}
                  sx={{
                    display: 'flex',
                    maxHeight: '64px',
                    paddingY: '8px',
                  }}
                  onClick={() => setSelectedCompany(company)}
                >
                  <Avatar
                    src={company.logo}
                    alt=""
                    sx={{
                      height: '48px',
                      width: '48px',
                      marginRight: '10px',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      maxWidth: '230px',
                    }}
                  >
                    <Typography
                      sx={{
                        maxWidth: '230px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fonstSize: '16px',
                      }}
                    >
                      {company.name}
                    </Typography>
                    <Typography
                      sx={{
                        maxWidth: '230px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fonstSize: '13px',
                        color: '#757070',
                      }}
                    >
                      {company.domain}
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            }
          </Box>
        )
      }
        {
        !companysData.length
          && !!value
          && (
            <Box
              sx={{
                background: 'white',
                overflow: 'auto',
                zIndex: 10,
              }}
            >
              <MenuItem>
                Loading...
              </MenuItem>
            </Box>
          )
      }
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      />
    </Box>
  );
};

export default CompanyAndLogoSelect;
