import {
  ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { Theme } from '@mui/system';

type OptionItemProps = {
  title: string,
  onClick: () => void,
  startIcon: JSX.Element,
  endIcon?: JSX.Element,
  labelColor?: string | ((theme: Theme) => string)
};

export const OptionItem = ({
  title,
  onClick,
  startIcon,
  labelColor,
  endIcon,
}: OptionItemProps) => (
  <ListItem
    key={title}
    disablePadding
    onClick={onClick}
  >
    <ListItemButton
      disableRipple
      sx={{
        padding: '0px',
        height: '40px',
      }}
    >
      <ListItemIcon sx={{
        minWidth: 'auto',
        marginRight: '15px',
      }}
      >
        {startIcon}
      </ListItemIcon>
      <ListItemText sx={{ color: labelColor }} primary={title} />
      {endIcon}
    </ListItemButton>
  </ListItem>
);
