import classNames from 'classnames';
import { AppBaseButton, AppBaseButtonProps } from './AppBaseButton';

type AppButtonSize =
  | 'xs'
  | 's'
  | 's-without-padding'
  | 'm'
  | 'm-auto'
  | 'm-minWidth'
  | 'l'
  | 'l-auto'
  | 'l-ds'
  | 'l-flex-start'
  | 'l-flex-start-new-page'
  | 'l-grid-span-full'
  | 'l-minWidth'
  | 'l-contactCard'
  | 'l-contactCard-mobile'
  | 'xl'
  | 'xl-flex-start'
  | 'xl-flex-start-with-padding'
  | 'xxl'
  | 'xxxl-flex-start-new-page';

type AppButtonComponent = 'label';

export type AppButtonProps = {
  size?: AppButtonSize,
  component?: AppButtonComponent,
} & Omit<AppBaseButtonProps, 'size'>;

export const AppButton = ({ className, ...buttonProps }: AppButtonProps) => (
  <AppBaseButton {...buttonProps} className={classNames('app-button', className)} />
);
