import { BrowserTracing } from '@sentry/tracing';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';

import { App } from './common';
import history from './core/browser-history';
import { config } from './core';
import { persistor, store } from './core/store/store';
import { Suspense } from 'react';

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter basename={config.app.env === 'local' ? '' : '/app'}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<div>Loading...</div>}> {/* Loading fallback */}
          <App history={history} />
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
