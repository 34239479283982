import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { IFramelyComponent } from '../../../../MediaField/IFramelyComponent';
import { EFieldsubType } from '../../../../../../shared/Field/Field';

const IframelyNodeComponent = (): JSX.Element => {
  const { data } = useBlockContext();

  return (
    <IFramelyComponent
      onUpdate={() => {}}
      // onUpdate={onUpdate}
      url={data.url}
      type={EFieldsubType.MIXED_URL}
    />
  );
};

export default IframelyNodeComponent;
