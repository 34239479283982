import { Extension } from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import { TCommandListProps } from '../../Utils/TiptapTypes';

const CommandsExtension = Extension.create({
  name: 'commands',

  addOptions() {
    return {
      suggestion: {
        pluginKey: new PluginKey('nodeCommands'),
        char: '/',
        command: ({ editor, range, props }: TCommandListProps) => {
          props?.command({ editor, range });
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export default CommandsExtension;
