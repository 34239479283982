type TValidateImageData = {
  file?: File | null,
  formats: string[],
  maxSize?: number,
  minHeight?: number,
  minWidth?: number,
  onError?: () => void;
};

type TValidateImageReturn = {
  file: File,
  tempUrl: string,
} | false;

export const validateImg = async ({
  file,
  formats,
  maxSize = 0,
  minHeight = 0,
  minWidth = 0,
  onError = () => {},
}:TValidateImageData): Promise<TValidateImageReturn> => {
  // validate file existance
  if (!file) return false;
  // validate format
  const format: string = file.name.split('.').pop()?.toLowerCase() ?? 'error';
  if (!format || !formats.includes(format)) {
    onError();
    return false;
  }
  // validate size
  if (file.size > maxSize) {
    onError();
    return false;
  }
  // validate resolution
  const img = new Image();
  const tempUrl = URL.createObjectURL(file);
  img.src = tempUrl;
  await img.decode();
  const { height, width } = img;
  if (!height || !width || height < minHeight || width < minWidth) {
    onError();
    return false;
  }
  // return data
  return {
    file,
    tempUrl,
  };
};

export const parseUrlStart = (url: string): string => {
  if (
    url.startsWith('http')
    || url.startsWith('mailto')
    || url.startsWith('//')
  ) {
    return url;
  }
  return url.replace(/^/, '//') ?? '';
};
