import { TabPanel } from '@mui/lab';
import { Box } from '@mui/system';
import { Typography } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useState } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { EFeedTab } from '../lib/feedSlice';
import { DefaultOrgCurrency } from '../../../../shared/OrganizationDTO';
import { formateValueForLastActive } from '../../../routes-old/process/helpers';
import ProcessHealthDisplay from '../../../widgets/ProcessTimeline/ProcessHealthDisplay';
import ProcessScore from '../../ProcessScore/ProcessScore';
import { selectOrganization } from '../../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { selectIsProcessOwner, selectProcess, selectProcessOwner } from '../../../routes-old/process/state/processSlice';
import useUserOrganizationPermissions from '../../../hooks/useOrganizationPermissions';
import { EUserOrganizationPermissions } from '../../../../shared/permissions';
import { OrganizationUserDTO } from '../../../../shared/UserDTO';
import DeleteTypography from '../../../common/DeleteTypography';
import ConfirmationDialog from '../../../common/ConfirmationDialog/ConfirmationDialog';
import NormalizedSvgIcon from '../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import AlertTriangle from '../../../icons/AlertTriangle.svg';
import { changeProcessOwner } from '../../../routes-old/process/state/dealActions';

const RoomInfoTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const process = useAppSelector(selectProcess);
  const organization = useAppSelector(selectOrganization);
  const isUserProcessOwner = useAppSelector(selectIsProcessOwner);
  const owner = useAppSelector(selectProcessOwner);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const isDealsEditable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_DEALS_MANAGE);
  const isEditAccessible = (): boolean => isDealsEditable || isUserProcessOwner;
  const users: OrganizationUserDTO[] = useAppSelector((state) => state.app.users);
  const [selectedProcessOwner, setSelectedProcessOwner] = useState<string | null>(owner);
  const [openOwner, setOpenOwner] = useState(false);
  const [isChangeOwnerConfirmationDialogOpen, setChangeOwnerConfirmationDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedProcessOwner(owner);
  }, []);

  const handleChangeOwner = () => {
    if (selectedProcessOwner === owner) {
      setChangeOwnerConfirmationDialogOpen(true);
    }
  };

  const onChangeOwnerCancel = (): void => {
    setChangeOwnerConfirmationDialogOpen(false);
  };

  const onChangeOwnerConfirm = (): void => {
    setChangeOwnerConfirmationDialogOpen(false);
    if (process?.id && selectedProcessOwner) {
      dispatch(changeProcessOwner({
        processId: process.id,
        newOwnerId: selectedProcessOwner,
      }));
    }
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to change the Owner?
    </DeleteTypography>
  );

  return (
    <TabPanel
      value={EFeedTab.ROOM_INFO}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        gap: '10px',
        background: '#FFF',
        padding: '16px 24px',
        border: '1px solid #E2E8F0',
        borderRadius: '16px',
        '& .row': {
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
        },
        '& .title': {
          fontSize: '14px',
          fontFamily: 'Inter',
          minWidth: '120px',
          fontWeight: '14px',
          color: '#64748B',
        },
        '& .value': {
          fontSize: '16px',
          fontFamily: 'Inter',
          display: 'flex',
          gap: '4px',
          color: '#0F172A',
          fontWeight: 500,
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      >
        <ProcessHealthDisplay />
        <Box className="row">
          <Box className="title">Company</Box>
          <Box className="value">{process?.client}</Box>
        </Box>
        <Box className="row">
          <Box className="title">Value</Box>
          <Box className="value">
            <Typography>
              {getSymbolFromCurrency(organization.currency || DefaultOrgCurrency)}
                &nbsp;
              {process?.value}
            </Typography>
          </Box>
        </Box>
        <Box className="row">
          <Box className="title">Last active</Box>
          <Box className="value">{formateValueForLastActive(process?.lastActive)}</Box>
        </Box>
        <ProcessScore />
      </Box>
      {isEditAccessible() && (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        gap: '0px',
        background: '#FFF',
        padding: '16px 24px',
        border: '1px solid #E2E8F0',
        borderRadius: '16px',
        marginTop: '15px',
        '& .row': {
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
        },
        '& .title': {
          fontSize: '14px',
          fontFamily: 'Inter',
          minWidth: '120px',
          fontWeight: '14px',
          color: '#64748B',
        },
        '& .value': {
          fontSize: '16px',
          fontFamily: 'Inter',
          display: 'flex',
          gap: '4px',
          color: '#0F172A',
          fontWeight: 500,
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      >
        <Box className="row">
          <Box
            sx={{
              paddingTop: '16px',
            }}
          >
            <InputLabel id="role-select-label">Owner</InputLabel>
            <Select
              fullWidth
              labelId="role-select-label"
              id="role-select"
              open={openOwner}
              onClose={() => {
                setOpenOwner(false);
              }}
              onOpen={() => {
                setOpenOwner(true);
              }}
              value={selectedProcessOwner}
              onChange={(event: any) => {
                setSelectedProcessOwner(event.target.value);
                handleChangeOwner();
              }}
              sx={{
                borderRadius: '10px',
                '& .MuiSelect-select': {
                  padding: '8px',
                },
              }}
            >
              {
            !!users && users.map((item: OrganizationUserDTO) => (
              <MenuItem value={item.id} key={item.id}>{item.email}</MenuItem>
            ))
          }
            </Select>
          </Box>
        </Box>
      </Box>
      )}
      <ConfirmationDialog
        isDialogOpen={isChangeOwnerConfirmationDialogOpen}
        onCancel={onChangeOwnerCancel}
        onConfirm={onChangeOwnerConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Change"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </TabPanel>
  );
};

export default RoomInfoTab;
