import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';

const TiptapToolbarItemsWrapper = ({ children }: { children: any }) => (
  <Box
    sx={{
      display: 'flex',
      padding: '4px',
      gap: '2px',
      height: 'auto',
      overflow: 'auto',
      borderRadius: isMobile ? '10px 0px 0px 10px' : '10px',
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) -9.58%, #E2E8F0 100%)',
      backdropFilter: 'blur(14px)',
      maxWidth: isMobile ? 'calc( 100vw - 44px )' : undefined,
    }}
  >
    {children}
  </Box>
);

export default TiptapToolbarItemsWrapper;
