import { Box } from '@mui/material';
import PaymentPeriod from './PaymentPeriod';
import { SubscriptionGridCard } from './SubscriptionGridCard';
import { useSubscriptions } from '../../hooks/useSubscriptions';

export const SubscriptionPlans = () => {
  const { subscriptions, currentPlan, subscription: current } = useSubscriptions();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
      }}
    >
      {
          subscriptions.map((subscription, i) => {
            const description = typeof subscription.description === 'string'
              ? subscription.description
              : <PaymentPeriod options={subscription.description} type={subscription.type} />;

            return (
              <SubscriptionGridCard
                key={`stageset-subscription-card-${i}`}
                title={subscription.title}
                description={description}
                features={subscription.features}
                type={subscription.type}
                btnTitle={subscription.btnTitle}
                current={
                  ((currentPlan?.priceId === subscription.priceId)
                    && current?.type === subscription.type)
                }
                priceId={subscription.priceId}
              />
            );
          })
            }
    </Box>
  );
};
