import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/stateHooks';
import AuthLayout from '../routes-old/auth/AuthLayout';
import AppLayout from './AppLayout';
import { useAppTheme } from '../hooks/useAppTheme';
import { logout, setTokenAndTokenType } from '../routes-old/auth/state/authState';
import { OrganizationSubdomainHelper } from '../core/helpers/OrganizationSubdomainHelper';
import { SharedLocalStorage } from '../core/helpers/SharedLocalStorage';
import { selectOrganizationLogo, selectOrganizationSignup } from '../core/store/appState/appState';
import CelloAttribution from '../features/CelloIntegration/CelloAttribution';
import { selectProcessOrgSquaredLogo } from '../routes-old/process/state/processSlice';
import { config } from '../core';

const Layout = (): JSX.Element => {
  const {
    isAuthenticated,
  } = useAppSelector((state) => state.auth);

  const { currentTheme } = useAppTheme();
  const dispatch = useAppDispatch();
  const orgLogo = useAppSelector(selectOrganizationLogo);
  const processOrgLogo = useAppSelector(selectProcessOrgSquaredLogo);
  const accConfirmData = useAppSelector(selectOrganizationSignup);

  useEffect(() => {
    const payload = OrganizationSubdomainHelper.getLoginForSubdomain();
    if (!isAuthenticated) {
      if (payload) {
        dispatch(setTokenAndTokenType(payload));
        SharedLocalStorage.removeCookie('token');
        SharedLocalStorage.removeCookie('tokenType');
      }
    } else if (!payload) {
      /**
       * Do log out if state says isAuthenticated == true but nothing in localstorage
       */
      const localToken = localStorage.getItem('tokenType');
      const sessionToken = sessionStorage.getItem('tokenType');
      if (!localToken && !sessionToken) {
        dispatch(logout());
      }
    }
  }, []);

  useEffect(() => {
    let { favicon } = config;
    if ((isAuthenticated && !!orgLogo) || processOrgLogo || accConfirmData?.organizationLogo) {
      favicon = accConfirmData.organizationLogo || processOrgLogo || orgLogo;
    }

    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    // Added the href for linter to not complain
    const links: NodeListOf<Element & { href: string }> = document.querySelectorAll<Element & { href: string; }>("link[rel~='icon']");
    if (links && links.length) {
      for (const l of links) {
        l.href = favicon;
      }
    }
    link = favicon;
  }, [isAuthenticated, orgLogo, processOrgLogo]);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <CelloAttribution />
      {isAuthenticated ? <AppLayout /> : <AuthLayout />}
    </ThemeProvider>
  );
};

export default Layout;
