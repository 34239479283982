import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AppButton } from '../../shared/AppButton/AppButton';
import Add from '../../icons/StageMenu/Add.svg';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { useAppDispatch } from '../../hooks/stateHooks';
import { ESidebarTabs, setSidebarTab } from '../Layout/lib/fieldsLayoutSlice';

export type Props = {
  variant: 'initial' | 'absolute'
};

export const NewPageButton = ({ variant }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '48px',
        marginBottom: '16px',
        alignItems: 'center',
        '&:hover': {
          background: (theme) => alpha(theme.dealOverrides.sidebar.contrastText, 0.1),
          '& .AddStageTemplateBtn': {
            visibility: 'visible',
          },
        },
      }}
    >
      <AppButton
        sx={{
          position: variant,
          paddingLeft: '24px !important',
          width: '100% !important',
          height: '100% !important',
          justifyContent: 'start',
          gap: '6px',
          color: (theme) => `${greySidebarContrastText(theme, 0.5)} !important`,
          background: 'transparent !important',
          '& svg': {
            width: '25px',
            height: '25px',
            stroke: (theme) => greySidebarContrastText(theme, 0.5),
          },
          '& span': {
            margin: 0,
          },
        }}
        startIcon={<Add />}
        size="m"
        variant="transparent"
        onClick={(e) => {
          dispatch(setSidebarTab(ESidebarTabs.NEWPAGE));
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '22px',
            color: (theme) => `${greySidebarContrastText(theme, 1)} !important`,
          }}
        >
          New page
        </Typography>
      </AppButton>
    </Box>
  );
};
