import { TabContext, TabPanel } from '@mui/lab';
import { StageContentType } from '../../../shared/Stage';
import DealPresentationCard from '../../features/DealPresentationCard/DealPresentationCard';
import { useAppSelector } from '../../hooks/stateHooks';
import { DefaultDealView } from './Views/DefaultDealView';
import { InternalDealView } from './Views/InternalDealView';
import {
  selectIsOwnersTeammate,
  selectIsProcessOwner,
  selectIsTemplate,
} from './state/processSlice';
import { selectDealViewMode } from '../../features/Layout/lib/fieldsLayoutSlice';
import { selectCurrentStageWithUpdates, selectProcessItemsLoadingStatus } from '../../features/ProcessFields/lib/processItemsSlice';
import { AppSkeletonStrings } from '../../shared/AppSkeleton/AppSkeletonStrings';
import { EStateStatus } from '../../core/commonTypes';

const DealContentTabs = (): JSX.Element | null => {
  const viewMode = useAppSelector(selectDealViewMode);
  const isOwner = useAppSelector(selectIsProcessOwner);
  const isTeammate = useAppSelector(selectIsOwnersTeammate);
  const isTemplate = useAppSelector(selectIsTemplate);
  const currentStage = useAppSelector(selectCurrentStageWithUpdates);
  const currentStageLoadingStatus = useAppSelector(selectProcessItemsLoadingStatus);
  return (
    <TabContext
      value={viewMode}
    >
      <TabPanel
        value={viewMode}
        sx={{
          padding: '0px',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {currentStageLoadingStatus === EStateStatus.LOADING
          && viewMode !== 'internal' && (
          <AppSkeletonStrings />
        )}
        {currentStageLoadingStatus !== EStateStatus.LOADING
          && currentStage?.stageContentType !== StageContentType.WIDESCREENSTAGE
          && viewMode !== 'internal' && (
          <DealPresentationCard isLoaded />
        )}
        { (viewMode === 'preview' || viewMode === 'editor')
          && (
          <DefaultDealView />
          )}
        {
        (isOwner || isTeammate)
        && !isTemplate && viewMode === 'internal'
        && (
        <InternalDealView />
        )
      }
      </TabPanel>
    </TabContext>
  );
};

export default DealContentTabs;
