import { MenuItem, Popper } from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react';
import { TCommandListProps } from '../../Utils/TiptapTypes';
import { useAppSelector } from '../../../../hooks/stateHooks';
import { selectLayouEditingStatus, selectProcess } from '../../../../routes-old/process/state/processSlice';
import { selectIsTemplateItem } from '../../../ProcessFields/lib/processItemsSlice';

interface ITempVars {
  company: '{{company_name}}',
  firstName: '{{first_name}}',
  lastName: '{{last_name}}',
  roomName: '{{room_name}}',
  clientName: '{{client_name}}',
}

const tempVarsMap: ITempVars = {
  company: '{{company_name}}',
  firstName: '{{first_name}}',
  lastName: '{{last_name}}',
  roomName: '{{room_name}}',
  clientName: '{{client_name}}',
};

const TemplateVarsList = (props: TCommandListProps): JSX.Element => {
  const {
    decorationNode,
    editor,
    range,
  } = props;
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const isTemplate = useAppSelector(selectIsTemplateItem);
  const process = useAppSelector(selectProcess);

  const replaceText = (x: keyof ITempVars) => {
    let value;
    if (process) {
      switch (x) {
        case 'clientName':
          value = process.clientName;
          break;
        case 'company':
          value = process.client;
          break;
        default:
          break;
      }
    }

    editor.chain().deleteRange(range).insertContent(isTemplate ? tempVarsMap[x] : value ?? tempVarsMap[x]).run();
  };

  return (
    <NodeViewWrapper>
      <Popper
        open={decorationNode && isLayoutEdit}
        disablePortal
        anchorEl={decorationNode}
        placement="right-start"
        sx={{
          zIndex: 11,
          border: '1px solid black',
          borderRadius: '8px',
          width: '240px',
          overflow: 'hidden',
        }}
        modifiers={
          [
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]
        }
      >
        <MenuItem
          onClick={() => replaceText('company')}
        >
          {isTemplate ? ' Company Name' : process?.client ?? ' Company Name'}
        </MenuItem>
        {
          (isTemplate || process.clientName) && (
            <MenuItem
              onClick={() => replaceText('clientName')}
            >
              {isTemplate ? 'Client Name' : process.clientName}
            </MenuItem>
          )
        }
        {/* <MenuItem
          onClick={() => replaceText('firstName')}
        >
          {isTemplate ? 'First Name' : process.firstName}
        </MenuItem> */}
        {/* <MenuItem
          onClick={() => replaceText('lastName')}
        >
          {isTemplate ? 'Last Name' : 'PLACEHOLDER'}
        </MenuItem> */}
      </Popper>
    </NodeViewWrapper>
  );
};

export default TemplateVarsList;
