import { useState, useEffect } from 'react';
import { ClearbitAutocompleteDTO } from '../../../shared/ClearbitDTO';
import { ClearbitApi } from '../../api/ClearbitApi';
import useDebounce from '../../hooks/useDebounce';

const clearbitApi: ClearbitApi = new ClearbitApi();

const useClearbitAutocomplete = (
  value: string,
  isActive: boolean,
): ClearbitAutocompleteDTO[] => {
  const debouncedValue = useDebounce(value, 500);
  const [companysData, setCompanyesData] = useState<ClearbitAutocompleteDTO[]>([]);

  const getAutocomplete = async (filter: string) => {
    const data: ClearbitAutocompleteDTO[] = await clearbitApi.getClearbitAutocomplete(filter);
    setCompanyesData(data);
  };

  useEffect(() => {
    setCompanyesData([]);
    if (!!debouncedValue && isActive) {
      getAutocomplete(debouncedValue);
    }
  }, [debouncedValue, isActive]);

  return companysData;
};

export default useClearbitAutocomplete;
