import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';

export const dealCreationDataSliceName: string = 'dealCreationData';

export type DealCreationDataSliceType = {
  clientLogo?: string;
  clientName?: string;
};

const initialState: DealCreationDataSliceType = {
  clientLogo: '',
  clientName: '',
};

export const dealCreationDataSlice = createSlice({
  name: dealCreationDataSliceName,
  initialState,
  reducers: {
    resetDealCreationData: () => initialState,
    updateDealCreationData: (state, { payload }: PayloadAction<Partial<DealCreationDataSliceType>>) => ({
      ...state,
      ...payload,
    }),
  },
  extraReducers: ((builder) => builder

  ),
});

export const {
  resetDealCreationData,
  updateDealCreationData,
} = dealCreationDataSlice.actions;

export const selectDealToCreateClientName = (state: RootState): string => state.dealCreationData.clientName;
export const selectDealToCreateClientLogo = (state: RootState): string => state.dealCreationData.clientLogo;
