import { SvgIcon, SvgIconProps } from '@mui/material';

export const CircledCheckIcon24 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '25px',
      height: '24px',
    }}
    {...props}
  >
    <path
      d="M12.5 22C18.0228 22 22.5 17.5228
      22.5 12C22.5 6.47715 18.0228 2 12.5
      2C6.97715 2 2.5 6.47715 2.5 12C2.5
      17.5228 6.97715 22 12.5 22Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16.7008 9L10.9258 15L8.30078 12.2727"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
