import { useCurrentEditor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TiptapMenu } from '../Components/Menu/TiptapMenu';
import TiptapNodeMenuItems from './TiptapNodeMenuItems';
import { ECustomEventsNames } from '../../../../shared/events/CustomEvents';

const TiptapNodeMenu = (): JSX.Element | null => {
  const [anchor, setAnchor] = useState<HTMLElement | Element | null>(null);
  const [position, setPosition] = useState<number | undefined>(undefined);
  const { editor } = useCurrentEditor();
  const [isTable, setIsTable] = useState<boolean>(false);

  useEffect(() => {
    const handleMenuEvent = (e: CustomEvent<{ newBlockId: string, isTable?: boolean }>) => {
      const pos = editor?.$pos(editor?.state.selection.$anchor.pos);
      if (pos && pos.node.type.name !== 'doc') {
        setPosition(editor?.$pos(editor?.state.selection.$anchor.pos).from);
      }
      setAnchor(document.getElementById(`${e.detail.newBlockId}`));
      setIsTable(!!e.detail.isTable);
    };
    window.addEventListener(ECustomEventsNames.TIPTAP_OPEN_BLOCK_MENU, handleMenuEvent as EventListener);
    return () => {
      window.removeEventListener(ECustomEventsNames.TIPTAP_OPEN_BLOCK_MENU, handleMenuEvent as EventListener);
    };
  }, []);

  const onClose = () => {
    setIsTable(false);
    setAnchor(null);
  };

  const handleClose = () => {
    const elements = document.querySelectorAll('.NodeMenuBtn-active');
    elements.forEach((item) => item.classList.remove('NodeMenuBtn-active'));
    onClose();
  };

  return (
    <TiptapMenu
      className={classNames('TiptapBlockMenu', { 'TiptapBlockMenu-open': !!anchor })}
      open={!!anchor}
      anchorEl={anchor}
      onClose={handleClose}
    >
      <TiptapNodeMenuItems
        editor={editor}
        position={position}
        onClose={handleClose}
        isTable={isTable}
      />
    </TiptapMenu>
  );
};

export default TiptapNodeMenu;
