import { useState } from 'react';

export enum EPasswordSecurityStatuses {
  UPPER = 'UPPER',
  LOWER = 'LOWER',
  NUMBER = 'NUMBER',
  LENGTH = 'LENGTH',
}

export const defaultPasswodStatuses = {
  [EPasswordSecurityStatuses.UPPER]: false,
  [EPasswordSecurityStatuses.LOWER]: false,
  [EPasswordSecurityStatuses.NUMBER]: false,
  [EPasswordSecurityStatuses.LENGTH]: false,
};

export type PasswordStatusType = typeof defaultPasswodStatuses;

export const passwordStatusManager = (): [
  PasswordStatusType,
  (value: PasswordStatusType) => void,
  () => boolean,
] => {
  const [passwordStatuses, setPasswordStatuses] = useState(defaultPasswodStatuses);

  const onStatusesUpdate = (newStatuses: PasswordStatusType) => {
    setPasswordStatuses(newStatuses);
  };

  const checkPasswordStatus = (): boolean => Object.values(passwordStatuses).reduce((a, b) => a && b);

  return [passwordStatuses, onStatusesUpdate, checkPasswordStatus];
};

export const passwordStatusesParser = (value: string, currentStatuses: PasswordStatusType) => {
  const newStatuses = currentStatuses;
  if (value.length >= 8) {
    newStatuses[EPasswordSecurityStatuses.LENGTH] = true;
  } else {
    newStatuses[EPasswordSecurityStatuses.LENGTH] = false;
  }
  if (value?.match(/(?=.*[A-Z])/)) {
    newStatuses[EPasswordSecurityStatuses.UPPER] = true;
  } else {
    newStatuses[EPasswordSecurityStatuses.UPPER] = false;
  }
  if (value?.match(/(?=.*[a-z])/)) {
    newStatuses[EPasswordSecurityStatuses.LOWER] = true;
  } else {
    newStatuses[EPasswordSecurityStatuses.LOWER] = false;
  }
  if (value?.match(/(?=.*\d)/)) {
    newStatuses[EPasswordSecurityStatuses.NUMBER] = true;
  } else {
    newStatuses[EPasswordSecurityStatuses.NUMBER] = false;
  }
  return (newStatuses);
};
