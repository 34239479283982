import {
  createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import {
  ETaskStatus, TaskDTO,
} from '../../../../shared/TaskDTO';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';

export interface IMilestonesAdditionalState {
  status: EStateStatus;
  error?: string;
  expandedMilestoneIds: string[];
  selectedTaskId?: string;
  titleEditingTaskId?: string;
  isStartDialogOpen: boolean;
  openedStatusMilestoneId?: string;
  openedTargetStatus?: ETaskStatus;
  milestoneToCompleteId?: string;
  milestoneToStartId?: string;
  expandedMilestonesIdsMap: {
    [key: string]: boolean;
  }
}

const tasksAdapter = createEntityAdapter<TaskDTO>({
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

export const tasksReducerName: string = 'tasks';
export enum EMilestoneActions {
  UPDATE_TASK = 'UPDATE_TASK',
  CREATE_TASK = 'CREATE_TASK',
  DELETE_TASK = 'DELETE_TASK',
  UPDATE_PARENT_TASK = 'UPDATE_PARENT_TASK',
}

export type TTasksState = IMilestonesAdditionalState & EntityState<TaskDTO>;

export const initialState: TTasksState = tasksAdapter.getInitialState({
  status: EStateStatus.IDLE,
  expandedMilestoneIds: [],
  expandedMilestonesIdsMap: {},
});

export const tasksSlice = createSlice({
  name: tasksReducerName,
  initialState,
  reducers: {
    clearTasksState: (state) => tasksAdapter.removeAll(state),
    setSelectedTaskId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedTaskId = payload;
    },
    setTitleEditingTaskId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.titleEditingTaskId = payload;
    },
    setMilestoneToCompleteId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.milestoneToCompleteId = payload;
    },
    setMilestoneToStartId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.milestoneToStartId = payload;
    },
    setOpenedStatusMilestoneId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.openedStatusMilestoneId = payload;
    },
    setOpenedTargetStatus: (state, { payload }: PayloadAction<ETaskStatus | undefined>) => {
      state.openedTargetStatus = payload;
    },
    addExpandedMilestoneId: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestoneIds.push(payload);
      state.expandedMilestonesIdsMap[payload] = true;
    },
    removeExpandedMilestoneId: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestoneIds = state.expandedMilestoneIds.filter((id: string) => id !== payload);
      delete state.expandedMilestonesIdsMap[payload];
    },
    removeOtherExpandedMilestoneIds: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestoneIds = state.expandedMilestoneIds.filter((id: string) => id === payload);
      state.expandedMilestonesIdsMap = {
        [payload]: true,
      };
    },
    resetExpandingMilestonesIds: (state) => {
      state.expandedMilestoneIds = [];
      state.expandedMilestonesIdsMap = {};
    },
    revertExpandedMilestoneIdStatus: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestonesIdsMap[payload] = !state.expandedMilestonesIdsMap[payload];
    },
  },
});

export const {
  clearTasksState,
  addExpandedMilestoneId,
  removeExpandedMilestoneId,
  setSelectedTaskId,
  setTitleEditingTaskId,
  removeOtherExpandedMilestoneIds,
  resetExpandingMilestonesIds,
  setOpenedStatusMilestoneId,
  setOpenedTargetStatus,
  revertExpandedMilestoneIdStatus,
  setMilestoneToCompleteId,
  setMilestoneToStartId,
} = tasksSlice.actions;

export const selectIsTaskStatusOpened = (state: RootState, taskId: string): boolean => state.tasks.openedStatusMilestoneId === taskId;
export const selectIsTaskSelected = (state: RootState, taskId: string): boolean => state.tasks.selectedTaskId === taskId;
