import { MouseEvent, useState } from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import OwnerCard from './OwnerCard';
import { ProcessUserDTO } from '../../../shared/UserDTO';
import { useAppSelector } from '../../hooks/stateHooks';
import { iconColorPalette } from '../../routes-old/process/helpers';
import { AppButton } from '../../shared/AppButton/AppButton';
import {
  OwnerCardStyledAvatar,
  OwnerContactInfoWrapper,
  OwnerContactsPopover,
} from './Styles/OwnerCardStyles';
import { useDealTheme } from '../../hooks/useDealTheme';
import { ClearIcon20px } from '../../icons/ClearIcon';
import { FormattedLabel } from '../../shared/AssigneeSelection/FormattedLabel';

const OwnerCardPopover = (): JSX.Element => {
  const [ownerPopoverStatus, setOwnerPopoverStatus] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<Element | null>(null);
  const ownerId = useAppSelector((state) => state.process.owner);
  const owner = useAppSelector(
    (state) => state.process.processUsers.find((user: ProcessUserDTO) => user.id === ownerId),
  );
  const { dealTheme: { dealOverrides: { sidebar: { contrastText } } } } = useDealTheme();
  const changeColorOnDesktop = contrastText === '#000000' || isMobile;

  const handleClick = (event: MouseEvent<Element>) => {
    setOwnerPopoverStatus(!ownerPopoverStatus);
    if (!anchor && !isMobile) setAnchor(!anchor ? event.currentTarget : null);
  };

  return (
    <AppButton
      size={isMobile ? 'l-contactCard-mobile' : 'l-contactCard'}
      disableRipple
      variant={changeColorOnDesktop ? 'transparent-black' : 'transparent-white'}
      onClick={handleClick}
      startIcon={(
        ownerPopoverStatus
          ? (
            <OwnerCardStyledAvatar
              className={classNames(
                'avatar-with-clear-icon',
                { 'avatar-with-clear-icon-black': !changeColorOnDesktop },
              )}
              alt="clear-icon"
              src=""
            >
              <ClearIcon20px />
            </OwnerCardStyledAvatar>
          )
          : (
            <OwnerCardStyledAvatar
              alt={owner?.name || ''}
              src={owner?.avatarSrc}
              sx={{
                color: owner?.colorId ? iconColorPalette[owner.colorId].letter : '',
                background: owner?.colorId ? iconColorPalette[owner.colorId].back : '',
              }}
            >
              {owner?.name ? owner?.name[0] : undefined}
            </OwnerCardStyledAvatar>
          )
      )}
    >
      <OwnerContactInfoWrapper>
        <FormattedLabel
          label={owner?.name || ''}
          len={20}
          tooltip
          sx={{
            fontWeight: 600,
          }}
        />
      </OwnerContactInfoWrapper>
      <OwnerContactsPopover
        className={classNames(
          { 'owner-contacts-popover-mobile': isMobile },
          { 'owner-contacts-popover-desktop': !isMobile },
        )}
        id={ownerPopoverStatus ? 'owner-card-popover' : undefined}
        open={ownerPopoverStatus}
        anchorEl={anchor}
        onClose={handleClick}
        anchorOrigin={{
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: isMobile ? 'left' : 'center',
        }}
        transformOrigin={{
          vertical: isMobile ? 'top' : 'bottom',
          horizontal: isMobile ? 'center' : 'center',
        }}
      >
        <OwnerCard isLoaded />
      </OwnerContactsPopover>
    </AppButton>
  );
};

export default OwnerCardPopover;
