import { templateVars } from '../../../shared/templateVars';
import { AppDropdown, AppDropdownProps } from '../../shared/AppDropdown/AppDropdown';
import { selectIsTemplateItem } from '../ProcessFields/lib/processItemsSlice';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectProcess } from '../../routes-old/process/state/processSlice';

type Props = {
  onInsert: (str: string) => void
} & Omit<AppDropdownProps, 'items'>;

export const TemplateVarDropdown = ({ onInsert, ...props }: Props) => {
  const isTemplate = useAppSelector(selectIsTemplateItem);
  const process = useAppSelector(selectProcess);

  return (
    <AppDropdown
      className="TemplateVarDropdown"
      {...props}
      keyboardControl
      disablePortal
      items={[
        {
          key: 'Company name',
          label: isTemplate ? 'Company name' : process.client ?? 'Company name',
          onClick: (e) => {
            e.stopPropagation();
            onInsert(isTemplate ? `{{${templateVars.companyName}}}` : process.client ?? templateVars.companyName);
          },
        },
        {
          key: 'Client name',
          label: isTemplate ? 'Client name' : process.clientName,
          onClick: (e) => {
            e.stopPropagation();
            onInsert(isTemplate ? `{{${templateVars.clientName}}}` : process.clientName);
          },
          hide: isTemplate ? false : !process.clientName,
        },
        // {
        //   key: 'First name',
        //   label: isTemplate ? 'First name' : process.client ?? 'Company name',
        //   onClick: (e) => {
        //     e.stopPropagation();
        //     onInsert(isTemplate ? `{{${templateVars.firstName}}}` : process.firstName);
        //   },
        //   hide: isTemplate ? false : !process.firstName,
        // },
        // {
        //   key: 'Last name',
        //   label: isTemplate ? 'Last name' : process.client ?? 'Company name',
        //   onClick: (e) => {
        //     e.stopPropagation();
        //     onInsert(isTemplate ? `{{${templateVars.lastName}}}` : process.lastName);
        //   },
        //   hide: isTemplate ? false : !process.lastName,
        // },
      ]}
    />
  );
};
