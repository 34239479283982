import {
  Box,
  FormControl,
  FormGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import {
  selectProcessCurrency,
} from '../../../routes-old/process/state/processSlice';
import {
  EPricingTableEditState,
  EProductTabState,
  saveItem,
  selectProduct,
  selectState,
  setTabState,
  selectDrawerTabState,
} from '../../PricingTable/lib/pricingTableSlice';
import { DEFAULT_PRICING_ITEM, PricingItem } from '../../../../shared/pricing-table/PricingTableTypes';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppButton } from '../../../shared/AppButton/AppButton';
import useScreenDimensions from '../../../hooks/useScreenDimensions';
import { EFeedTab, setFeedTab } from '../../DealFeed/lib/feedSlice';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { handleNumericalData } from '../Helpers/PricingTableHelpers';
import { PTTabHeader } from '../Styles/SidebarPricingTableTabStyles';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppTabs } from '../../../shared/AppTabs/AppTabs';
import CurrencySelector from '../../../shared/CurrencySelector/CurrencySelector';

const PricingTableItemDetailsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const paramsState = useAppSelector((state) => selectState(state));
  const paramsProduct = useAppSelector((state) => selectProduct(state));
  const [productObject, setProductObject] = useState<PricingItem>(paramsProduct || DEFAULT_PRICING_ITEM);
  const currency = useAppSelector(selectProcessCurrency);
  const [height] = useScreenDimensions(true);
  const [unitsInputError, setUnitsInputError] = useState<boolean>(false);
  const [unitsMinInputError, setUnitsMinInputError] = useState<boolean>(false);
  const [unitsMaxInputError, setUnitsMaxInputError] = useState<boolean>(false);
  const [unitsHelperText, setUnitsHelperText] = useState<string>('');
  const [minUnitsHelperText, setMinUnitsHelperText] = useState<string>('');
  const [maxUnitsHelperText, setMaxUnitsHelperText] = useState<string>('');
  const drawerTabState = useAppSelector(selectDrawerTabState);
  const isNewProduct = paramsState === EPricingTableEditState.ADDING && drawerTabState !== EProductTabState.ADD_PRODUCT_FROM_LIST;

  const handleUnitsInput = (event: string | undefined) => {
    const newValue = handleNumericalData({
      currentValue: event,
      previousValue: productObject.units.value,
      fraction: true,
    });
    const units = {
      ...productObject.units || {
        enabled: false, min: 0, max: undefined, value: 0,
      },
      value: newValue,
    };
    if (productObject.units.enabled) {
      if (newValue
        && productObject.units.min
        && newValue < productObject.units.min) {
        setUnitsInputError(true);
        setUnitsHelperText('units must be greater than min');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      if (newValue
        && productObject.units.max
        && newValue > productObject.units.max) {
        setUnitsInputError(true);
        setUnitsHelperText('units must be less than max');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
    }
    setUnitsInputError(false);
    setUnitsHelperText('');
    setProductObject({
      ...productObject,
      units,
    });
    return null;
  };

  const handleMinUnitsInput = (event: string | undefined) => {
    if (productObject.units.enabled) {
      const newValue = handleNumericalData({
        currentValue: event,
        previousValue: productObject.units.min,
        fraction: true,
      });
      const units = {
        ...productObject.units || {
          enabled: false, min: 0, max: undefined, value: 0,
        },
        min: newValue,
      };
      if (newValue
        && productObject.units.value
        && newValue > productObject.units.value) {
        setUnitsMinInputError(true);
        setMinUnitsHelperText('min must be less than units');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      if (newValue
        && productObject.units.max
        && newValue > productObject.units.max) {
        setUnitsMinInputError(true);
        setMinUnitsHelperText('min must be greater than max');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      setUnitsMinInputError(false);
      setMinUnitsHelperText('');
      setProductObject({
        ...productObject,
        units,
      });
      return null;
    } return null;
  };

  const handleMaxUnitsInput = (event: string | undefined) => {
    if (productObject.units.enabled) {
      const newValue = handleNumericalData({
        currentValue: event,
        previousValue: productObject.units.min,
        fraction: true,
      });
      const units = {
        ...productObject.units || {
          enabled: false, min: 0, max: 0, value: 0,
        },
        max: newValue,
      };
      if (newValue
        && productObject.units.value
        && newValue < productObject.units.value) {
        setUnitsMaxInputError(true);
        setMaxUnitsHelperText('max must be greater than units');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      if (newValue
        && productObject.units.min
        && newValue < productObject.units.min) {
        setUnitsMaxInputError(true);
        setMaxUnitsHelperText('max must be greater than min');
        setProductObject({
          ...productObject,
          units,
        });
        return null;
      }
      setUnitsMaxInputError(false);
      setMaxUnitsHelperText('');
      setProductObject({
        ...productObject,
        units,
      });
      return null;
    } return null;
  };

  const handleCurrSelect = (val: string) => {
    setProductObject({
      ...productObject,
      currency: val,
    });
  };

  const heights = {
    header: '50px',
    footer: '60px',
    // eslint-disable-next-line max-len
    stagesContainer: () => `calc(${height - (isMobile ? 156 : 204) - (isNewProduct ? 47 : 0)}px)`,
  };

  useEffect(() => {
    if (productObject.units.enabled) {
      handleMinUnitsInput(productObject.units.min?.toString());
      handleMaxUnitsInput(productObject.units.max?.toString());
    } else {
      setUnitsInputError(false);
      setUnitsMinInputError(false);
      setUnitsMaxInputError(false);
      setUnitsHelperText('');
    }
  }, [productObject.units.enabled]);

  useEffect(() => {
    if (paramsProduct) {
      setProductObject(paramsProduct);
    }
  }, [paramsState, paramsProduct]);

  const handleSubmit = () => {
    dispatch(saveItem({ product: productObject }));
    dispatch(setFeedTab(EFeedTab.NONE));
  };

  const handleBackToLibrary = () => {
    dispatch(setTabState(EProductTabState.PRODUCT_LIST));
  };
  return (
    <>
      {isNewProduct ? (
        <Box
          sx={{
            height: '12px',
            paddingX: '24px',
            marginBottom: '6px',
            marginTop: '-12px',
          }}
        />
      ) : null}
      <Box
        sx={{
          paddingX: '24px',
          height: heights.stagesContainer(),
          maxHeight: heights.stagesContainer(),
          paddingTop: isNewProduct ? '24px' : '8px',
          width: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '100%',
          }}
        >
          <AppInput
            fullWidth
            label="Name"
            defaultValue={productObject.name}
            value={productObject.name}
            onChange={(event) => {
              setProductObject({
                ...productObject,
                name: event.target.value,
              });
            }}
          />
          <AppInput
            fullWidth
            label="Description"
            defaultValue={productObject.shortDescription}
            value={productObject.shortDescription}
            multiline
            rows={4}
            onChange={(event) => {
              setProductObject({
                ...productObject,
                shortDescription: event.target.value,
              });
            }}
          />
          <AppInput
            sx={{
              '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                '-moz-appearance': 'textfield',
                margin: 0,
              },
            }}
            error={unitsInputError}
            helperText={unitsHelperText}
            fullWidth
            label="Units"
            value={productObject.units.value}
            onChange={(event) => {
              let newValue = event.target.value.replaceAll(/[^0-9]/g, '');
              if (!newValue && event.target.value) {
                newValue = '0';
              }
              handleUnitsInput(newValue);
            }}
          />
          <AppInput
            sx={{
              '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                '-moz-appearance': 'textfield',
                margin: 0,
              },
            }}
            fullWidth
            label="Price"
            defaultValue={productObject.price}
            value={productObject.price}
            type="number"
            onChange={(event) => {
              const newValue = handleNumericalData({
                currentValue: event.target.value,
                previousValue: productObject.price,
                fraction: true,
              });
              setProductObject({
                ...productObject,
                price: newValue,
              });
            }}
          />
          <CurrencySelector
            initialValue={productObject.currency ?? currency}
            onSelect={handleCurrSelect}
            height="40px"
          />
          <FormGroup
            sx={{
              width: '100%',
            }}
          >
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <AppSwitch
                checked={productObject.units.enabled}
                onChange={(e) => {
                  const units = {
                    ...productObject.units || {
                      enabled: false, min: 0, max: undefined, value: 0,
                    },
                    enabled: e.target.checked,
                  };
                  setProductObject({
                    ...productObject,
                    units,
                  });
                }}
              />
              <PTTabHeader>
                Variable quantity
              </PTTabHeader>
            </FormControl>
            {productObject.units.enabled && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px',
                  justifyContent: 'space-between',
                  paddingTop: '24px',
                  width: '100%',
                }}
              >
                <FormControl
                  sx={{
                    width: '50%',
                  }}
                >
                  <AppInput
                    sx={{
                      width: '100%',
                      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        '-moz-appearance': 'textfield',
                        margin: 0,
                      },
                    }}
                    error={unitsMinInputError}
                    helperText={minUnitsHelperText}
                    fullWidth
                    label="Min"
                    value={productObject.units.min}
                    onChange={(event) => {
                      let newValue = event.target.value.replaceAll(/[^0-9]/g, '');
                      if (!newValue && event.target.value) {
                        newValue = '0';
                      }
                      handleMinUnitsInput(newValue);
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    width: '50%',
                  }}
                >
                  <AppInput
                    sx={{
                      width: '100%',
                      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        '-moz-appearance': 'textfield',
                        margin: 0,
                      },
                    }}
                    error={unitsMaxInputError}
                    helperText={maxUnitsHelperText}
                    fullWidth
                    label="Max"
                    value={(productObject.units.max)}
                    onChange={(event) => {
                      let newValue = event.target.value.replaceAll(/[^0-9]/g, '');
                      if (!newValue && event.target.value) {
                        newValue = '0';
                      }
                      handleMaxUnitsInput(newValue);
                    }}
                  />
                </FormControl>
              </Box>
            )}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                alignItems: 'center',
                paddingTop: '24px',
              }}
            >
              <AppSwitch
                checked={productObject.tax.enabled}
                onChange={(e) => {
                  const tax = {
                    ...productObject.tax || {
                      enabled: false, isPercentage: false, value: 0,
                    },
                    enabled: e.target.checked,
                  };
                  setProductObject({
                    ...productObject,
                    tax,
                  });
                }}
              />
              <PTTabHeader>
                Tax
              </PTTabHeader>
            </FormControl>
            {productObject.tax.enabled
                && (
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '12px',
                      justifyContent: 'space-between',
                      paddingTop: '24px',
                      width: '100%',
                    }}
                  >
                    <AppInput
                      sx={{
                        width: '50%',
                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                          '-webkit-appearance': 'none',
                          '-moz-appearance': 'textfield',
                          margin: 0,
                        },
                      }}
                      label="Tax"
                      defaultValue={productObject.tax.value}
                      value={productObject.tax.value}
                      type="number"
                      onChange={(event) => {
                        const newValue = handleNumericalData({
                          currentValue: event.target.value,
                          previousValue: productObject.tax.value,
                          fraction: true,
                          isPercentage: productObject.tax.isPercentage,
                        });
                        const tax = {
                          ...productObject.tax || { enabled: false, isPercentage: false, value: 0 },
                          value: newValue,
                        };
                        setProductObject({
                          ...productObject,
                          tax,
                        });
                      }}
                    />
                    <AppTabs
                      color="white"
                      variant="boxedNew"
                      size="l-new-pt"
                      value={productObject.tax.isPercentage ? '0' : '1'}
                      onChange={(option) => {
                        const newValue = handleNumericalData({
                          currentValue: `${productObject.tax.value}`,
                          fraction: true,
                          togglePercents: option.value === '0',
                        });
                        const tax = {
                          ...productObject.tax || {
                            enabled: false, isPercentage: false, value: 0,
                          },
                          isPercentage: option.value === '0',
                          value: newValue,
                        };
                        setProductObject({
                          ...productObject,
                          tax,
                        });
                      }}
                      options={[
                        {
                          title: '%', value: '0',
                        },
                        {
                          title: `${getSymbolFromCurrency(currency)}`, value: '1',
                        },
                      ]}
                    />
                  </FormControl>
                )}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '24px',
                gap: '12px',
              }}
            >
              <AppSwitch
                checked={productObject.discount.enabled}
                onChange={(e) => {
                  const discount = {
                    ...productObject.discount || {
                      enabled: false, name: '', isPercentage: false, value: 0,
                    },
                    enabled: e.target.checked,
                  };
                  setProductObject({
                    ...productObject,
                    discount,
                  });
                }}
              />
              <PTTabHeader>
                Discount
              </PTTabHeader>
            </FormControl>
            {productObject.discount.enabled
                && (
                  <FormControl
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      paddingTop: '24px',
                      flexDirection: 'column',
                    }}
                  >
                    <AppInput
                      label="Name"
                      defaultValue={productObject.discount.name}
                      value={productObject.discount.name}
                      onChange={(e) => {
                        const discount = {
                          ...productObject.discount || { enabled: false, isPercentage: false, value: 0 },
                          name: e.target.value,
                        };
                        setProductObject({
                          ...productObject,
                          discount,
                        });
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <AppInput
                        sx={{
                          width: '50%',
                          '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            '-moz-appearance': 'textfield',
                            margin: 0,
                          },
                        }}
                        label="Discount"
                        value={productObject.discount.value}
                        defaultValue={productObject.discount.value}
                        type="number"
                        onChange={(event) => {
                          const newValue = handleNumericalData({
                            currentValue: event.target.value,
                            previousValue: productObject.discount.value,
                            fraction: true,
                            isPercentage: productObject.discount.isPercentage,
                          });
                          const discount = {
                            ...productObject.discount || {
                              enabled: false, name: '', isPercentage: false, value: 0,
                            },
                            value: newValue,
                          };
                          setProductObject({
                            ...productObject,
                            discount,
                          });
                        }}
                      />
                      <AppTabs
                        color="white"
                        variant="boxedNew"
                        size="l-new-pt"
                        value={productObject.discount.isPercentage ? '0' : '1'}
                        onChange={(option) => {
                          const newValue = handleNumericalData({
                            currentValue: `${productObject.discount.value}`,
                            fraction: true,
                            togglePercents: option.value === '0',
                          });
                          const discount = {
                            ...productObject.discount || {
                              enabled: false, name: '', isPercentage: false, value: 0,
                            },
                            isPercentage: option.value === '0',
                            value: newValue,
                          };
                          setProductObject({
                            ...productObject,
                            discount,
                          });
                        }}
                        options={[
                          {
                            title: '%', value: '0',
                          },
                          {
                            title: `${getSymbolFromCurrency(currency)}`, value: '1',
                          },
                        ]}
                      />
                    </Box>
                  </FormControl>
                )}
          </FormGroup>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
        }}
      >
        {drawerTabState === EProductTabState.ADD_PRODUCT_FROM_LIST && (
          <Box
            sx={{
              marginRight: '15px',
              '& button': {
                backgroundColor: `${stagesetColors.newGrey[200]} !important`,
                padding: '20px 15px !important',
                minWidth: '50px !important',
              },
            }}
          >
            <AppButton
              onClick={handleBackToLibrary}
            >
              <ChevronLeft />
            </AppButton>
          </Box>
        )}
        <AppButton
          disabled={unitsInputError || unitsMaxInputError || unitsMinInputError}
          onClick={handleSubmit}
          fullWidth
          variant={(unitsInputError || unitsMaxInputError || unitsMinInputError) ? 'transparent' : 'primary'}
          size="l"
          sx={{
            flex: 1,
          }}
        >
          {paramsState === EPricingTableEditState.EDITING ? 'Save' : 'Add'}
        </AppButton>
      </Box>
    </>
  );
};

export default PricingTableItemDetailsTab;
