import { SocialAuthQuery } from '../../shared/schema/auth.shcema';
import { SharedSessionStorage } from '../core/helpers/SharedSessionStorage';

enum ESessionStorageKeys {
  SOCIAL_AUTH_QUERY = 'SOCIAL_AUTH_QUERY',
}

export class SessionStorageWrapper {
  private getObject<T>(key: string): T {
    // const value: string = sessionStorage.getItem(key);
    const value = SharedSessionStorage.getItem(key);
    return value ? JSON.parse(value) : {};
  }

  private storeObject(key: string, value: Object): void {
    // sessionStorage.setItem(key, JSON.stringify(value));
    SharedSessionStorage.setItem(key, JSON.stringify(value));
  }

  storeSocialAuthQuery(query: SocialAuthQuery): void {
    this.storeObject(ESessionStorageKeys.SOCIAL_AUTH_QUERY, query);
  }

  getSocialAuthQuery(): SocialAuthQuery {
    return this.getObject<SocialAuthQuery>(ESessionStorageKeys.SOCIAL_AUTH_QUERY);
  }

  clearSocialAuthQuery(): void {
    // sessionStorage.removeItem(ESessionStorageKeys.SOCIAL_AUTH_QUERY);
    SharedSessionStorage.clearItem(ESessionStorageKeys.SOCIAL_AUTH_QUERY);
  }
}
