import {
  Box, Divider,
  MenuItem, MenuItemProps, Typography,
} from '@mui/material';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import ChevronRight from '../../icons/ChevronRight.svg';
import { stagesetColors } from '../../theme/stagesetPalette';

export type AppDropdownItemProps = {
  label: string;
  labelColor?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  childrenItems?: AppDropdownItemProps[];
  autoSize?: boolean;
  hideNavigation?: boolean;
  selected?: boolean;
  selectable?: boolean;
  divider?: boolean;
  category?: boolean;
  subLabel?: string;
  hide?: boolean;
} & MenuItemProps;

export const AppDropdownItem = memo(({
  label,
  startIcon,
  endIcon,
  labelColor,
  childrenItems,
  autoSize,
  hideNavigation,
  selected,
  divider,
  category,
  subLabel,
  ...restProps
}: AppDropdownItemProps) => {
  const memoizedEndIcon = useMemo(() => {
    if (endIcon) return endIcon;
    if (childrenItems && !hideNavigation) {
      return (
        <Box sx={{
          position: 'absolute',
          right: '15px',
          top: '30%',
        }}
        >
          <ChevronRight />
        </Box>
      );
    }
    return null;
  }, [endIcon, childrenItems, hideNavigation]);

  if (divider) return <Divider />;

  if (category) {
    return (
      <MenuItem
        disableRipple
        sx={{
          paddingY: '0 !important',
          height: 'auto !important',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'initial',
          '&:hover': {
            background: 'initial',
          },
        }}
      >
        <Typography sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: (theme) => theme.palette.grey[500],
        }}
        >
          {label}
        </Typography>
        {endIcon}
      </MenuItem>
    );
  }

  return (
    <MenuItem
      disableRipple
      selected={selected}
      {...restProps}
      className={classNames('app-dropdown-item', { 'auto-size': autoSize || !!subLabel }, { disabled: restProps.disabled })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          alignItems: 'center',
        }}
      >
        {startIcon}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            className="app-dropdown-label"
            sx={{
              color: stagesetColors.newGrey[600] ?? 'text.primary',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '22px',
            }}
          >
            {label}
          </Typography>
          {!!subLabel && (
          <Typography sx={{
            width: '100%',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: stagesetColors.newGrey[500],
          }}
          >
            {subLabel}
          </Typography>
          )}
        </Box>
      </Box>
      {memoizedEndIcon}
    </MenuItem>
  );
});
