import {
  RadioButtonContainer,
  RadioButtonTitle,
  ShareLinkRadioButton,
} from '../../../routes-old/process/DealFeed/Users/Styles/UsersTabStyles';

interface Props {
  text?: string;
  checked?: boolean;
  onChange?: () => void;
}

const ShareLinkRadioItem = ({
  text,
  checked,
  onChange,
}: Props): JSX.Element => (
  <RadioButtonContainer
    onClick={onChange}
  >
    <ShareLinkRadioButton
      disableRipple
      checked={checked}
    />
    <RadioButtonTitle>
      {text}
    </RadioButtonTitle>
  </RadioButtonContainer>
);

export default ShareLinkRadioItem;
