import {
  Box,
  Grid, InputAdornment, SvgIcon, TextField,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import SearchActive from '../../icons/SearchActive.svg';
import Minimiz from '../../icons/DealsFiltersInput/Minimiz.svg';
import Save from '../../icons/DealsFiltersInput/Save.svg';
import Add from '../../icons/DealsFiltersInput/Add.svg';
import Reset from '../../icons/DealsFiltersInput/Reset.svg';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { AppButton } from '../../shared/AppButton/AppButton';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import { AppLoader } from '../../shared/AppLoader/AppLoader';
import { useAppDispatch } from '../../hooks/stateHooks';
import { AppDispatch } from '../../core/store/store';
import { LibraryFilterAutocomplete } from './LibraryFilterAutocomlete';
import NewFilterSetDialog from '../../features/Deals/DataGridFilter/GridFilterInput/NewFilterSetDialog';
import ManageLibraryFilterSets from './ManageLibraryFilterSets';
import { LibraryMobileTabs } from './MobileTabs/LibraryMobileTabs';

type Props = {
  allOpts: any[];
  ownerOpts: any[];
  typeOpts: any[];
  expanded: boolean;
  removeFilter: (key: any) => void;
  anchorEl: DOMRect | undefined;
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  getPopupItems: () => any[];
  mobileIndex: number;
  popupCategory: any;
  removeMultipleFilter: (toRemove: any) => Promise<void> | null,
  closeFilter: () => void;
  newFilterSetDialogStatus: any;
  manageFilterSetsDialogStatus: any;
  openMenu: (node: Element, category?: any) => void;
  closeMenu: () => void;
  updateSearch: (searchQuery: string) => void;
  updateFilterParams: any;
  filterParams: any;
  setLibraryFilterExpanded: any;
  search: string;
  setLibraryNewFilterSetDialogStatus: any;
  setLibraryManageFilterSetsDialogStatus: any;
  savedFilters: any[];
  saveNewFilter: (filterSet: any) => Promise<void>;
  updateFilterSet: (id: string, name: string) => Promise<void>;
  deleteFilterSet: (id: string) => Promise<void>;
  gridToRender: any;
  loadingJobs: any;
  getFilterInfo: () => void;
  setMobileFilterMenu: any;
  debouncedGridFilters: any;
  selectMobileFilterMenu: any;
};

const LibraryGridFilterInput = ({
  allOpts,
  ownerOpts,
  typeOpts,
  expanded,
  removeFilter,
  anchorEl,
  handleSearchChange,
  getPopupItems,
  mobileIndex,
  popupCategory,
  removeMultipleFilter,
  closeFilter,
  newFilterSetDialogStatus,
  manageFilterSetsDialogStatus,
  openMenu,
  closeMenu,
  updateSearch,
  updateFilterParams,
  filterParams,
  setLibraryFilterExpanded,
  search,
  setLibraryNewFilterSetDialogStatus,
  setLibraryManageFilterSetsDialogStatus,
  savedFilters,
  saveNewFilter,
  updateFilterSet,
  deleteFilterSet,
  gridToRender,
  loadingJobs,
  setMobileFilterMenu,
  debouncedGridFilters,
  getFilterInfo,
  selectMobileFilterMenu,
}: Props): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleFilterSave = async (filterSet: any) => {
    await saveNewFilter(filterSet);
  };

  return (
    <>
      <Grid
        container
        p={0.5}
        sx={{
          height: expanded ? 'calc(100%)' : '30px',
          transition: 'all 0.3s ease-in-out',
          borderRadius: '10px',
          border: (theme) => `1px solid ${theme.palette.grey[200]}`,
          background: '#fff',
          boxSizing: 'content-box',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            '& .MuiInputAdornment-positionEnd': {
              height: 'auto',
            },
          }}
        >
          <Grid item xs={expanded ? 12 : true} p={0}>
            <TextField
              fullWidth
              value={search}
              autoComplete="off"
              onChange={handleSearchChange}
              variant="outlined"
              placeholder={t('Overview_Search')}
              sx={{
                paddingLeft: '12px',
              }}
              InputProps={{
                sx: {
                  border: 'none',
                  padding: '0px !important',
                  '& input': {
                    padding: '0px !important',
                    height: '30px',
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      component={SearchActive}
                      sx={{
                        color: 'transparent',
                        position: 'relative',
                        top: '1px',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: expanded && Object.values(filterParams).length ? (
                  <InputAdornment
                    position="end"
                  >
                    {!isMobile && <AppIconButton onClick={() => closeFilter()} icon={<Minimiz />} />}
                  </InputAdornment>
                ) : (
                  <InputAdornment
                    position="end"
                  >
                    <AppButton
                      autoWidth
                      onClick={(e) => {
                        openMenu(e.currentTarget);
                      }}
                      startIcon={<Add />}
                      sx={{
                        color: (theme) => theme.palette.grey[400],
                        stroke: (theme) => theme.palette.grey[400],
                        position: 'relative',
                        top: '-1px',
                      }}
                    >
                      {t('Overview_Filters')}
                    </AppButton>
                  </InputAdornment>
                ),
              }}
            />

          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              display: loadingJobs <= 0 ? 'none' : 'flex',
              flexDirection: 'row',
            }}
          >
            <AppLoader
              size={32}
            />
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              display: expanded || !Object.values(filterParams).length ? 'none' : 'flex',
              flexDirection: 'row',
            }}
          >
            <LibraryFilterAutocomplete
              filterParams={filterParams}
              preview
              hidden={expanded}
              onDelete={(category) => removeFilter(category)}
              expanded={expanded}
              setExpanded={(val: boolean) => dispatch(setLibraryFilterExpanded(val))}
              onChipClick={(e: any, category) => {
                openMenu(e.currentTarget, category);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LibraryFilterAutocomplete
            filterParams={filterParams}
            hidden={!expanded}
            onDelete={(category) => removeFilter(category)}
            expanded={expanded}
            setExpanded={(val: boolean) => dispatch(setLibraryFilterExpanded(val))}
            onChipClick={(e: any, category) => {
              openMenu(e.currentTarget, category);
            }}
          />
        </Grid>

        <Grid
          container
          mt={1}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            md={4}
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <AppButton
              autoWidth
              startIcon={<Add />}
              transparentStroke
              sx={{
                color: (theme) => theme.palette.grey[400],
                stroke: (theme) => theme.palette.grey[400],
              }}
              onClick={(e) => {
                openMenu(e.currentTarget);
              }}
            >
              Add
            </AppButton>
            <AppButton
              autoWidth
              onClick={() => dispatch(setLibraryNewFilterSetDialogStatus(true))}
              startIcon={<Save />}
              transparentStroke
              sx={{
                color: (theme) => theme.palette.grey[400],
                stroke: (theme) => theme.palette.grey[400],
              }}
            >
              Save
            </AppButton>
          </Grid>
          <Grid
            item
            md={8}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <AppButton
              autoWidth
              variant="danger-transparent"
              startIcon={<Reset />}
              onClick={() => {
                updateFilterParams({});
                closeFilter();
              }}
            >
              Reset
            </AppButton>
          </Grid>
        </Grid>
      </Grid>
      {
        isMobile
          ? (
            <LibraryMobileTabs
              allOpts={allOpts}
              ownerOpts={ownerOpts}
              typeOpts={typeOpts}
              debouncedGridFilters={debouncedGridFilters}
              filterParams={filterParams}
              categoryTab={mobileIndex}
              getFilterInfo={getFilterInfo}
              setMobileFilterMenu={setMobileFilterMenu}
              setLibraryFilterExpanded={setLibraryFilterExpanded}
              selectMobileFilterMenu={selectMobileFilterMenu}
            />
          )
          : (
            <AppDropdown
              hideNavigation
              searchType="multiselect"
              onChipDelete={(item) => {
                if (!popupCategory) return;
                removeMultipleFilter({
                  category: popupCategory,
                  value: item.value as string,
                });
              }}
              sx={{
                maxHeight: '400px',
                maxWidth: '250px',
                '& li': {
                  height: '50px',
                  width: '100%',
                  gap: '0 !important',
                  '& svg': {
                    marginRight: '5px',
                  },
                },
                '& .app-dropdown-label': {
                  width: '142px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              }}
              autoSize
              preventFallback
              items={getPopupItems()}
              disableAutoFocus
              disableAutoFocusItem
              id="action-more-menu"
              anchorPosition={anchorEl && {
                top: anchorEl.top + anchorEl.height,
                left: anchorEl.left,
              }}
              open={!!anchorEl}
              onMouseDown={(e) => e.stopPropagation()}
              onClose={() => {
                closeMenu();
              }}
              anchorReference="anchorPosition"
            />
          )
      }
      <NewFilterSetDialog
        open={newFilterSetDialogStatus}
        setOpen={(status) => dispatch(setLibraryNewFilterSetDialogStatus(status))}
        onSubmit={({ name }) => handleFilterSave({ name, libraryItemType: gridToRender, filterParams })}
      />
      <ManageLibraryFilterSets
        open={manageFilterSetsDialogStatus}
        setOpen={(status) => dispatch(setLibraryManageFilterSetsDialogStatus(status))}
        savedFilters={savedFilters}
        updateFilterSet={updateFilterSet}
        deleteFilterSet={deleteFilterSet}
      />
    </>
  );
};

export default LibraryGridFilterInput;
