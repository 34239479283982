import { TabContext } from '@mui/lab';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectSidebarTab } from '../Layout/lib/fieldsLayoutSlice';

interface Props {
  children: Array<JSX.Element | undefined | boolean>;
}

const SidebarsTabsWrapper = ({
  children,
}: Props): JSX.Element => {
  const tab = useAppSelector(selectSidebarTab);

  return (
    <TabContext
      value={tab}
    >
      {children}
    </TabContext>
  );
};

export default SidebarsTabsWrapper;
