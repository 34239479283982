import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { ESubscriptionType } from '../../../../../shared/Subscription';
import { StripeInterval } from '../../../../../shared/stripe';
import { CardOrdinaryText, CardSubtitle } from '../Styles/SubscriptionTabGridCardStyles';

export type SubscriptionTabPaymentPeriodOptionProps = {
  value: string | JSX.Element,
  saleText?: string | JSX.Element,
  perUser?: boolean
  type?: ESubscriptionType,
  interval?: StripeInterval,
};

export const SubscriptionTabPaymentPeriodOption = ({
  value, saleText, perUser, type, interval,
}: SubscriptionTabPaymentPeriodOptionProps) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
  }}
  >
    <CardSubtitle
      className={classNames(
        { 'card-subtitle-premium': type === ESubscriptionType.SELLER_PREMIUM },
        { 'card-subtitle-black': type !== ESubscriptionType.SELLER_PREMIUM },
      )}
    >
      {value}
    </CardSubtitle>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '12px',
      height: '40px',
      justifyContent: 'flex-end',
    }}
    >
      <Typography
        fontWeight={600}
        fontSize={14}
        color="#C94937"
      >
        {saleText}
      </Typography>
      <CardOrdinaryText
        className={classNames({ 'card-ordinary-text-premium': type === ESubscriptionType.SELLER_PREMIUM })}
      >
        {perUser && `/ ${interval} per user`}
      </CardOrdinaryText>
    </Box>
  </Box>
);
