import { Box } from '@mui/system';
import { useEffect } from 'react';
import { EIdTagsForAPIs } from '../../../shared/ProcessIdsTags';
import { useDeleteSectionMutation } from '../ProcessFields/lib/processFieldsApi';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import BlockContentSectionContainer from '../BlockContentSection/BlockContentSectionContainer';
import { handleScroll } from '../../routes-old/process/helpers';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import { selectSectionToDeleteData, setSectionToDeleteData } from '../BlockContentSection/lib/sectionsSlice';
import DealFeed from '../../routes-old/process/DealFeed/DealFeed';
import { useContentWidth } from '../../hooks/useContentWidth';
import RoomToolbar from '../Stages/RoomToolbar';
import { getTemplateSectionItems } from '../ProcessFields/lib/processItemsSlice';

interface Props {
  id: string;
  onClose?: () => void;
  toolbar?: boolean;
}

const SectionDisplay = ({
  id,
  onClose,
  toolbar,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { contentWidth } = useContentWidth();
  const sectionToDeleteData = useAppSelector(selectSectionToDeleteData);
  const [deleteSection] = useDeleteSectionMutation();

  useEffect(() => {
    dispatch(getTemplateSectionItems(id));
  }, [id]);

  const onSectionDeleteConfirm = () => {
    if (sectionToDeleteData) {
      deleteSection({ id: sectionToDeleteData.id, processId: EIdTagsForAPIs.TEMPLATE, parentId: sectionToDeleteData.parentId });
      dispatch(setSectionToDeleteData(undefined));
    }
  };

  const onDeleteDialogAccept = (isAccepted: boolean) => {
    if (isAccepted) {
      onSectionDeleteConfirm();
    }
    if (!isAccepted) {
      dispatch(setSectionToDeleteData(undefined));
    }
  };

  return (
    <>
      {toolbar && !onClose && <RoomToolbar toolbarPlace="section" />}
      <Box
        onScroll={(e) => handleScroll(e.target as Element, 'SectionDisplayScrollableContainer-active')}
        className="SectionDisplayScrollableContainer"
        sx={{
          width: '100%',
          maxWidth: contentWidth,
          marginTop: toolbar ? '24px' : undefined,
          padding: '16px',
          overflow: 'auto',
          maxHeight: 'calc( 100% )',
          display: 'flex',
          justifyContent: 'center',
          '&::-webkit-scrollbar': {
            width: '7px',
            height: '7px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: '#c7c7c7',
            opacity: '50%',
            visibility: 'hidden',
            '&:hover': {
              visibility: 'visible',
            },
          },
          '&.SectionDisplayScrollableContainer-active': {
            '&::-webkit-scrollbar-thumb': {
              visibility: 'visible',
            },
          },
        }}
      >
        <BlockContentSectionContainer />
        <DealFeed />
      </Box>
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={!!sectionToDeleteData}
        onConfirm={() => onDeleteDialogAccept(true)}
        onCancel={() => onDeleteDialogAccept(false)}
        dialogContentText={(
          <Box>
            {
              sectionToDeleteData?.popUpText ?? 'Are you sure you want to delete section?'
            }
          </Box>
        )}
        dialogTitle={sectionToDeleteData?.popUpTitle ?? 'Delete section'}
        confirmText="Delete"
      />
    </>
  );
};

export default SectionDisplay;
