import { styled } from '@mui/styles';
import {
  Box, Typography,
} from '@mui/material';
import { stagesetColors } from '../../../../../theme/stagesetPalette';

export const SharePreviewTabItemWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
}));

export const SharePreviewTabItemTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: stagesetColors.newGrey[500],
}));

export const SharePreviewTabDashedBorderBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  height: '1px',
  border: '1px dashed',
  borderColor: stagesetColors.newGrey[200],
}));

export const SharePreviewTabColorViewer = styled(Box)(() => ({
  width: '190px',
  height: '32px',
  paddingY: '8px',
  display: 'flex',
  gap: '8px',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[300],
  alignItems: 'center',
  paddingLeft: '12px',
}));

export const SharePreviewTabColorIcon = styled(Box)(() => ({
  width: '20px',
  height: '20px',
  borderRadius: '10px',
}));

export const SharePreviewTabColorTitle = styled(Box)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  color: stagesetColors.newGrey[800],
}));
