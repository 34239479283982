import classNames from 'classnames';
import { Tabs, TabsProps } from '@mui/material';
import { useMemo } from 'react';
import { StyledAppTab, StyledAppTabs } from './AppTabsStyles';

export type AppTabColor = 'white' | 'light' | 'blue' | 'light-newPage';
export type AppTabSize = 'l' | 'l-new' | 'm' | 'm-new';
export type AppTabVariant = 'boxed' | 'boxedNew' | 'boxedNew-dark' | 'boxedNew-contrast' | 'light' | 'boxed-newPage';
export type AppTabOption = {
  title: string,
  value: any,
  disabled?: boolean,
  icon?: JSX.Element,
};

export type AppTabsProps = {
  options: AppTabOption[];
  value: any;
  onChange: (option: AppTabOption) => void;
  color?: AppTabColor;
  size?: AppTabSize;
  variant?: AppTabVariant;
} & Omit<TabsProps, 'variant' | 'onChange'>;

export const AppTabs = ({
  options,
  color = 'blue',
  size = 'm',
  variant = 'boxed',
  value,
  onChange,
  ...tabsProps
}: AppTabsProps): JSX.Element => {
  const optionsMap = useMemo(() => {
    const map = new Map();
    options.forEach((option) => {
      map.set(option.value, option);
    });
    return map;
  }, [options]);

  return (
    <StyledAppTabs className={classNames({ hidden: tabsProps.hidden })}>
      <Tabs
        value={value}
        onChange={(_, changedValue) => onChange(optionsMap.get(changedValue))}
        className={classNames('tab-panel', `color-${color}`, `size-${size}`, `variant-${variant}`)}
        TabIndicatorProps={{
          hidden: variant !== 'light',
          sx: {
            marginTop: '10px',
            maxWidth: '28px',
            marginLeft: `calc(100% / ${options.length} / 2 - 14px)`,
          },
        }}
        {...tabsProps}
      >
        {options.map((option) => (
          <StyledAppTab
            key={option.value}
            disableRipple
            icon={option.icon}
            iconPosition="start"
            disabled={option.disabled}
            label={option.title}
            value={option.value}
            className={classNames(
              {
                active: value === option.value,
                inactive: value !== option.value,
                disabled: option.disabled,
              },
              `color-${color}`,
              `size-${size}`,
              `variant-${variant}`,
            )}
          />
        ))}
      </Tabs>
    </StyledAppTabs>
  );
};

export default AppTabs;
