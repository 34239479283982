import { Box } from '@mui/material';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import {
  $getSelection,
  createCommand,
  EditorState, LexicalCommand,
} from 'lexical';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import React, { useState, useRef, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import LexicalRTEEditModePlugin from './LexicalRTEEditModePlugin';
import { useActionOnOutsideClick } from '../../hooks/useClickedOutside';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import LexicalRTEToolbarPlugin from './LexicalRTEToolbarPlugin';
import { $createMentionNode } from './LexicalRTETemplateNode';
import { stagesetColors } from '../../theme/stagesetPalette';
import { LexicalRTEWrapperBox } from './Styles/LexicalRTEStyles';

export const CREATE_TEMPLATE_NODE: LexicalCommand<string> = createCommand();

function onChange(editorState: EditorState) {
  editorState.read(() => {
  });
}

interface Props {
  rteString?: string;
  saveContent: (newContent: string) => void;
  isRowSelected?: boolean;
  editable?: boolean;
  toolbar?: boolean;
  agendaId?: string;
  parentDepth?: number;
  agendaTabDescription?: boolean;
}

const Placeholder = () => (
  <Box sx={{
    height: '0px',
    overflow: 'visible',
    position: 'relative',
    textOverflow: 'ellipsis',
    top: '-24px',
    fontSize: '16px',
    userSelect: 'none',
    pointerEvents: 'none',
    color: (theme) => theme.palette.grey[400],
  }}
  >
    Set description
  </Box>
);

// Lexical provider is in BlockContentRowWrapper
// Lexical toolbar is in BlockContentRowMenu
const LexicalRTE = ({
  rteString,
  saveContent,
  isRowSelected,
  editable,
  toolbar,
  agendaId,
  parentDepth,
  agendaTabDescription,
}: Props) => {
  const [editor] = useLexicalComposerContext();
  const isEditLayoutActiveState: boolean = useAppSelector(selectLayouEditingStatus);
  const isEditLayoutActive: boolean = editable ?? isEditLayoutActiveState;
  const [editMode, setEditMode] = useState(false);
  const isEditActive = editMode || !isEditLayoutActive;

  const RTEcontainerRef = useRef<HTMLDivElement | null>(null);

  const actionOnClickOutside = () => {
    editor.blur();
    setEditMode(false);
  };

  useEffect(() => {
    editor.registerCommand(
      CREATE_TEMPLATE_NODE,
      (payload: string) => {
        const selection = $getSelection();
        editor.update(() => {
          if (selection !== null) selection.insertNodes([$createMentionNode(payload)]);
        });
        return false;
      },
      0,
    );
    const onKeyDown = (e) => { if (e.key === '/') { console.log(true); } };
    editor.registerRootListener((
      rootElement: null | HTMLElement,
      prevRootElement: null | HTMLElement,
    ) => {
      if (prevRootElement !== null) {
        prevRootElement.removeEventListener('keydown', onKeyDown);
      }
      if (rootElement !== null) {
        rootElement.addEventListener('keydown', onKeyDown);
      }
    });
  }, []);

  useActionOnOutsideClick(RTEcontainerRef, actionOnClickOutside, editMode);

  const handleRTEcontainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isRowSelected) {
      e.stopPropagation();
    }
    if (isEditLayoutActive) setEditMode(true);
  };

  return (
    <LexicalRTEWrapperBox
      onMouseDown={(e) => isRowSelected && e.stopPropagation()}
      onClick={handleRTEcontainerClick}
      ref={RTEcontainerRef}
      className={classNames(
        { 'agenda-tab-description': agendaTabDescription },
      )}
      sx={{
        marginLeft: parentDepth && parentDepth === 1 && !isMobile ? '-8px' : undefined,
        '&:not(.agenda-tab-description)': {
          '&:hover': {
            background: isEditActive ? stagesetColors.white[100] : 'rgba(238, 240, 250, 0.5)',
          },
        },
      }}
    >
      <LexicalRTEEditModePlugin
        rteString={rteString}
        saveContent={saveContent}
        editMode={editMode}
        editable={editable}
        agendaId={agendaId}
      />
      {
        toolbar
        && editMode
        && (
          <LexicalRTEToolbarPlugin show />
        )
      }
      <RichTextPlugin
        contentEditable={(
          <ContentEditable
            style={{
              outline: 'none',
            }}
          />
              )}
        placeholder={isEditLayoutActive ? <Placeholder /> : null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={onChange} />
      <ListPlugin />
      <HistoryPlugin />
      <LinkPlugin />
    </LexicalRTEWrapperBox>
  );
};

export default LexicalRTE;
