import {
  SwipeableDrawer,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch } from 'react-redux';
import {
  $getSelection, $isRangeSelection, FORMAT_ELEMENT_COMMAND, LexicalEditor, RangeSelection,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';
import { Puller } from '../../Puller/Puller';
import { CommonOptionsTab } from './CommonOptionsTab';
import { AlignOptionsTab } from './AligmentOptionsTab';
import { LinkTab } from './LinkTab';
import {
  changeMoveRowMode,
} from '../../../features/BlockContentSection/lib/sectionsSlice';

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void;
  editor: LexicalEditor;
  onBulletList: () => void;
  onNumberList: () => void;
  onDelete: () => void;
  showControls?: boolean;
};

export const MobileTabs = ({
  open, setOpen, editor, onBulletList, onNumberList, onDelete, showControls,
}: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const [link, setLink] = useState<string>('');

  function getSelectedNode(selection: RangeSelection) {
    const { anchor } = selection;
    const { focus } = selection;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }

  useEffect(() => {
    if (editor) {
      const editorState = editor.getEditorState();
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          const parent = node.getParent();
          if ($isLinkNode(parent)) {
            setLink(parent.getURL());
          } else {
            setLink('');
          }
        }
      });
    }
  }, [editor, open]);

  useEffect(() => {
    if (open) {
      setActiveTab(0);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLink('');
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="temporary"
      onOpen={() => {
        setOpen(true);
      }}
      swipeAreaWidth={56}
      disableSwipeToOpen
      sx={{
        zIndex: 1350,
      }}
      PaperProps={{
        sx: {
          borderRadius: '0px',
          marginBottom: '50px',
          '& .mobile-table-row': {
            borderWidth: '1px',
            borderRadius: '12px',
          },
          '& .mobile-table-row-header': {
            minHeight: 'auto',
            padding: '0px',
          },
        },
      }}
    >
      <Puller />
      <SwipeableViews
        index={activeTab}
        onChangeIndex={setActiveTab}
        disabled
        containerStyle={{
          height: '100%',
        }}
        style={{
          height: '100%',
        }}
      >
        <CommonOptionsTab
          showControls={showControls}
          onClose={handleClose}
          onAlignment={() => setActiveTab(1)}
          onHyperlink={() => setActiveTab(2)}
          onBulletList={() => {
            onBulletList();
            handleClose();
          }}
          onNumberedList={() => {
            onNumberList();
            handleClose();
          }}
          onDelete={onDelete}
          onMoveMode={() => {
            dispatch(changeMoveRowMode());
          }}
        />
        <AlignOptionsTab
          onAlignChange={(type) => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, type);
            handleClose();
          }}
          onClose={handleClose}
          onGoBack={() => setActiveTab(0)}
        />
        <LinkTab
          onClose={handleClose}
          link={link}
          onCreate={({ link: newLink }) => {
            if (newLink.split(':')[0] === 'https') {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: newLink, target: '_blank' });
            } else {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: `https://${newLink}`, target: '_blank' });
            }
            if (!newLink) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            }
          }}
          onGoBack={() => {
            setActiveTab(0);
          }}
        />
      </SwipeableViews>

    </SwipeableDrawer>
  );
};
