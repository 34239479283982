import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';

const Item = () => (
  <AppSkeleton
    animation="wave"
    variant="rectangular"
    width="100%"
    height="95px"
    sx={{
      borderRadius: '12px',
    }}
  />
);

const DealBoardCardSkeleton = () => (
  <>
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
  </>
);

export default DealBoardCardSkeleton;
