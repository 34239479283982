import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import LibraryGridFilterInput from './LibraryGridFilterInput';
import { ELibraryType } from '../../../shared/library/Library';
import { selectLibraryGridToRender, setLibraryGridToRender } from './lib/librarySlice';

import { useLibraryFilesGridFilter } from './helpers/useLibraryFilesFilters';
import { useLibraryProductGridFilter } from './helpers/useLibraryProductFilters';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import LibraryHeaderUploadButton from './LibraryHeaderUploadButton';

const LibraryHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const filesHooks = useLibraryFilesGridFilter();
  const productHooks = useLibraryProductGridFilter();

  const handleChange = (newValue: ELibraryType) => {
    dispatch(setLibraryGridToRender(newValue));
  };

  const options = [
    { title: 'Files', value: ELibraryType.FILE },
    { title: 'Products', value: ELibraryType.PRODUCT },
  ];

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 0,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            height: '56px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: isMobile ? '16px' : '24px',
            background: 'white',
            borderBottom: '1px solid #EEF0FA',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              lineHeight: '26px',
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Library
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              '& button': {
                minWidth: '70px',
              },
            }}
          >
            <AppTabs
              variant="boxedNew-contrast"
              size="m-new"
              value={gridToRender}
              onChange={(option) => handleChange(option.value)}
              options={options as any[]}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          marginTop: isMobile ? '20px' : '24px',
          marginBottom: isMobile ? '20px' : '40px',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingX: isMobile ? '5px' : '24px',
          gap: '24px',
        }}
      >
        <LibraryGridFilterInput
          {... ((gridToRender === ELibraryType.FILE) ? filesHooks : productHooks)}
        />
        {!isMobile && (
        <LibraryHeaderUploadButton />
        )}
      </Box>
    </>
  );
};

export default LibraryHeader;
