import { Box, DialogProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DealSignupDialogWrapper from '../../routes-old/process/SignupDialog/DealSignupDialogWrapper';
import AuthButton from '../../routes-old/auth/Components/AuthButton';
import { EStateStatus } from '../../core/commonTypes';
import DealConfirmBuyerAccountHeader from '../DealConfirmBuyerAccount/DealConfirmBuyerAccountHeader';
import PolicyDisclaimer from './PolicyDisclaimer';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsSignupDialogOpen } from '../../routes-old/auth/state/authState';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';

const analytics: Analytics = Analytics.getInstance();

const PolicyDisclaimerDialog = (props: Partial<DialogProps>): JSX.Element | null => {
  const isSignUp = useAppSelector(selectIsSignupDialogOpen);
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(!!sessionStorage.getItem('showPolicyDisclaimer'));

  const onAccept = () => {
    setOpen(false);
    sessionStorage.removeItem('showPolicyDisclaimer');
    analytics.track(EMixPanelEvents.VIEW_ROOM_ANONYMOUS_BUTTON_PRESSED);
  };

  useEffect(() => {
    if (isSignUp) {
      onAccept();
    }
  }, [isSignUp]);

  if (!open) {
    return null;
  }
  return (
    <DealSignupDialogWrapper
      {...props}
      open={open}
    >
      <DealConfirmBuyerAccountHeader />
      <AuthButton
        status={EStateStatus.IDLE}
        title={t('Room_View_your_room')}
        onClick={onAccept}
      />
      <PolicyDisclaimer />
      <Box>
        <LanguageSwitcher
          anchorVertical="top"
          anchorHorizontal="right"
          transformVertical="top"
          transformHorizontal="left"
        />
      </Box>
    </DealSignupDialogWrapper>
  );
};

export default PolicyDisclaimerDialog;
