import { MutableRefObject, useEffect, useState } from 'react';

type Props = {
  input: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | undefined>;
  textFieldValue?: string;
  setTextFieldValue: (text: string) => void
};

export const useTemplateVars = ({ input, textFieldValue, setTextFieldValue }: Props) => {
  const [anchorTemplatePos, setAnchorTemplatePos] = useState<null | { left: number, top: number }>(null);
  const [prevVal, setPrevVal] = useState(textFieldValue || '');
  const [lastPos, setLastPos] = useState<number>();

  const onInsertTemplateVar = (str: string) => {
    const selection = input?.current?.selectionStart;
    if (input && typeof selection === 'number') {
      const firstPart = `${input.current?.value.slice(0, selection - 1)}${str}`;
      const fieldValue = `${firstPart}${input.current?.value.slice(selection)}`;
      setTextFieldValue(fieldValue);
      setLastPos(firstPart.length);
      setAnchorTemplatePos(null);
    }
  };

  /**
   * returns x, y coordinates for absolute positioning of a span within a given text input
   * at a given selection point
   */
  const getCaretXY = () => {
    if (!input || !input.current) return { top: 0, left: 0 };
    const {
      top: inputY,
      left: inputX,
    } = input.current.getBoundingClientRect();
    const selectionPoint = input.current?.selectionStart || 1;
    // create a dummy element that will be a clone of our input
    const div = document.createElement('div');
    // get the computed style of the input and clone it onto the dummy element
    const copyStyle = getComputedStyle(input.current);
    for (const prop of copyStyle) {
      // @ts-ignore
      div.style[prop] = copyStyle[prop as keyof CSSStyleDeclaration];
    }
    // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
    const swap = '.';
    const inputValue = input.current?.tagName === 'INPUT' ? input.current?.value.replace(/ /g, swap) : input.current?.value;
    // set the div content to that of the textarea up until selection
    const textContent = inputValue.substr(0, selectionPoint - 1);
    // set the text content of the dummy element div
    div.textContent = textContent;
    if (input.current?.tagName === 'TEXTAREA') div.style.height = 'auto';
    // if a single line input then the div needs to be single line and not break out like a text area
    if (input.current?.tagName === 'INPUT') div.style.width = 'auto';
    // create a marker element to obtain caret position
    const span = document.createElement('span');
    // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
    span.textContent = inputValue.substr(selectionPoint) || '.';
    // append the span marker to the div
    div.appendChild(span);
    // append the dummy element to the body
    document.body.appendChild(div);
    // get the marker position, this is the caret position top and left relative to the input
    const {
      offsetLeft: spanX, offsetHeight,
    } = span;
    // lastly, remove that dummy element
    // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
    document.body.removeChild(div);
    // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
    const { clientHeight, scrollHeight, clientWidth } = input.current;
    const offsetTop = offsetHeight > clientHeight ? scrollHeight - offsetHeight : clientHeight - offsetHeight;
    const offsetLeft = spanX > clientWidth ? clientWidth : spanX;
    return {
      top: inputY + offsetTop + 20,
      left: inputX + offsetLeft,
    };
  };

  useEffect(() => {
    if (input.current !== document.activeElement || !textFieldValue) return;
    if (prevVal.length < textFieldValue.length) {
      if (input.current?.value.substr(input.current.selectionStart! - 1, 1) === '@') {
        setAnchorTemplatePos(getCaretXY());
      } else {
        setAnchorTemplatePos(null);
      }
    } else {
      setAnchorTemplatePos(null);
    }
    setPrevVal(textFieldValue);
  }, [textFieldValue]);

  return {
    anchorTemplatePos,
    setAnchorTemplatePos,
    getCaretXY,
    onInsertTemplateVar,
    lastPos,
  };
};
