import { Checkbox } from '@mui/material';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsEventsFilterActive } from '../../features/ActivityLog/lib/processActivityLogSlice';
import { EDealEventName, EDealEventsFilterNames } from '../../../shared/events/Events';
import useProcessTimelineEventsFilter from '../../hooks/useProcessTimelineEventsFilter';
import { stagesetColors } from '../../theme/stagesetPalette';
import { ProcessTimelineMenuItemSearchItem, ProcessTimelineMenuItemSearchItemTitle } from './Styles/ProcessTimelineStyles';

interface Props {
  name: EDealEventName;
}

const ProcessTimelineEventsFilterListItem = ({
  name,
}: Props) => {
  const { isSelected, changeUserFilterStatus } = useProcessTimelineEventsFilter(name);

  const isFilterActive = useAppSelector(selectIsEventsFilterActive);

  return (
    <ProcessTimelineMenuItemSearchItem
      onClick={() => changeUserFilterStatus()}
    >
      <Checkbox
        disableRipple
        checked={isSelected && isFilterActive}
        sx={{
          padding: '0px',
          '& svg': {
            fill: isSelected && isFilterActive ? stagesetColors.newGrey[800] : stagesetColors.newGrey[500],
          },
        }}
      />
      <ProcessTimelineMenuItemSearchItemTitle>
        {EDealEventsFilterNames[name]}
      </ProcessTimelineMenuItemSearchItemTitle>
    </ProcessTimelineMenuItemSearchItem>
  );
};

export default ProcessTimelineEventsFilterListItem;
