import {
  MutableRefObject, useEffect, useState,
} from 'react';

export function useClickedOutside<T extends HTMLElement>(ref: MutableRefObject<T | undefined>): [boolean] {
  const [isClickedOutside, setClickedOutside] = useState(true);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isClickedOutside && ref?.current && !ref.current.contains(e.target)) {
        setClickedOutside(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
      setClickedOutside(true);
    };
  }, [isClickedOutside]);

  return [isClickedOutside];
}

export function useActionOnOutsideClick(
  ref: MutableRefObject<any>,
  action: () => void,
  toCheck?: boolean,
  additionalData?: any,
): void {
  useEffect(() => {
    function checkClickOutside(e: MouseEvent): void {
      // todo: look for better approach
      const rteToolbar = document.querySelector('.LexicalRTEToolbar');
      const linkMenu = document.querySelector('.LexicalRTELinkSettings');
      const inlineToolbar = document.querySelector('.InlineToolbarPopper');
      const mobileRTEMenu = document.querySelector('.LexicalRTEToolbarMobileMenu');
      const templateVarDropdown = document.querySelector('.TemplateVarDropdown');
      if (
        ref?.current && !ref.current.contains(e.target)
        && !linkMenu?.contains(e.target)
        && !inlineToolbar?.contains(e.target)
        && !mobileRTEMenu?.contains(e.target)
        && !rteToolbar?.contains(e.target)
        && !templateVarDropdown?.contains(e.target)
      ) {
        action();
      }
    }
    if (toCheck ?? true) {
      document.addEventListener('mousedown', checkClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', checkClickOutside);
    };
  }, [ref, toCheck, additionalData]);
}
