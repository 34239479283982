import { Box, Collapse } from '@mui/material';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ProcessTimelineSessionItem from './ProcessTimelineSessionItem';
import ProcessTimelineSessionHeader from './ProcessTimelineSessionHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  increaseLogLimit,
  selectEventsFilterMap,
  selectIsEventsFilterActive,
} from '../../features/ActivityLog/lib/processActivityLogSlice';
import { selectIsSubscriptionPremium } from '../../routes-old/Billing/state/billingSlice';
import { EDealEventName } from '../../../shared/events/Events';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  session: any[];
  isLast: boolean;
}

const ProcessTimelineSession = ({
  session,
  isLast,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<Element | null>(null);
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isPremium = useAppSelector(selectIsSubscriptionPremium);

  const isEventFilterActive = useAppSelector(selectIsEventsFilterActive);
  const eventFilterMap = useAppSelector(selectEventsFilterMap);

  let observer: IntersectionObserver | undefined;

  useEffect(() => {
    if (isLast && wrapperRef.current && !observer) {
      observer = new IntersectionObserver((ent) => {
        if (ent[0].isIntersecting) {
          dispatch(increaseLogLimit());
          observer!.unobserve(ent[0].target);
        }
      });

      observer.observe(wrapperRef.current!);
    }
    return () => {
      if (isLast && wrapperRef.current && observer) {
        observer.unobserve(wrapperRef.current);
        observer = undefined;
      }
    };
  }, [isLast, wrapperRef, observer]);

  const sessionData = {
    id: session[0].payload.producerId,
    startDate: session[0].createdAt,
    endDate: session[session.length - 1].createdAt,
  };

  const sessionItems = useMemo(() => {
    let count = 0;
    const res = session.map((item, i) => {
      if (
        item.name !== EDealEventName.DEAL_USER_ONLINE
        && item.name !== EDealEventName.DEAL_USER_OFFLINE
        && item.name !== EDealEventName.DEAL_FIELD_CREATED
        && item.name !== EDealEventName.DEAL_FIELD_UPDATED
        && item.name !== EDealEventName.DEAL_FIELD_DELETED
        && (
          !isEventFilterActive || eventFilterMap.has(item.name)
        )
      ) {
        count += 1;
      }
      return (
        <ProcessTimelineSessionItem
          key={`timeline-session-${sessionData.id}-item-${i}`}
          item={item}
        />
      );
    });
    return { items: res, count };
  }, [session?.[0]?._id, isEventFilterActive, eventFilterMap]);

  const handleHeaderClick = () => {
    if (!!sessionItems?.count && isPremium) {
      setOpen(!open);
    }
    if (!!sessionItems?.count && !isPremium && !tooltipOpen) {
      setTooltipOpen(true);
    }
  };

  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  return (
    <Box
      ref={isLast ? wrapperRef : undefined}
      className="TimelineSessionWrapper"
      sx={{
        border: '1px solid',
        borderColor: stagesetColors.newGrey[200],
        borderRadius: '16px',
        background: stagesetColors.white[100],
        maxWidth: '900px',
        width: '100%',
        cursor: 'pointer',
        transition: 'background 0.2s',
        height: 'auto',
        overflow: 'hidden',
        display: 'inline-table',
      }}
      onClick={handleHeaderClick}
    >
      <ProcessTimelineSessionHeader
        sessionData={sessionData}
        eventsCount={sessionItems.count}
        open={open}
        tooltipOpen={tooltipOpen}
        closeTooltip={closeTooltip}
      />
      {
        isPremium
        && !!sessionItems.count
        && (
          <Collapse
            in={open}
            onClick={(e) => e.stopPropagation()}
            sx={{
              cursor: 'default',
              background: stagesetColors.white[100],
              '& .MuiCollapse-wrapperInner': {
                padding: '8px 24px 16px 24px',
              },
            }}
          >
            {sessionItems.items}
          </Collapse>
        )
      }
    </Box>
  );
};

export default ProcessTimelineSession;
