import { alpha } from '@mui/material/styles';
import { Box } from '@mui/material';
import { StripeInterval } from '../../../shared/stripe';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectIsAppsumo, selectSubscriptionInteval, setSubscriptionInterval } from '../../routes-old/Billing/state/billingSlice';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import { useTranslation } from "react-i18next";

const SubscriptionIntervalSelect = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAppSumo = useAppSelector(selectIsAppsumo);
  const interval = useAppSelector(selectSubscriptionInteval);

  const setInterval = (val: StripeInterval) => {
    dispatch(setSubscriptionInterval(val));
  };

  if (isAppSumo) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '200px',
      }}
    >
      <AppTabs
        value={interval}
        onChange={(option) => setInterval(option.value)}
        size="l"
        variant="boxed"
        color="white"
        options={
        [
          {
            title: t('Subscriptions_Pay_monthly'),
            value: 'month',
          },
          {
            title: t('Subscriptions_Pay_yearly'),
            value: 'year',
          },
        ]
    }
        sx={{
          '&.MuiTabs-root': {
            borderRadius: '10px !important',
            padding: '4px !important',
            height: '48px !important',
            boxShadow: '0px 1px 2px rgba(0, 57, 96, 0.1), 0px 1px 5px rgba(0, 57, 96, 0.05) !important',
            background: (theme) => `${alpha(theme.palette.grey[200], 0.6)} !important`,
          },
          '& button': {
            '&.inactive': {
              minWidth: '90px',
              color: (theme) => `${alpha(theme.palette.grey[500], 1.0)} !important`,
              '& svg': {
                transition: '0.2s',
                '& path': {
                  transition: '0.2s',
                  stroke: (theme) => `${alpha(theme.palette.grey[500], 1.0)} !important`,
                },
              },
            },
            '&.active': {
              minWidth: '90px',
              background: '#fff !important',
              color: (theme) => `${alpha(theme.palette.primary.main, 1.0)} !important`,
              '& svg': {
                transition: '0.2s',
                '& path': {
                  transition: '0.2s',
                  stroke: (theme) => `${alpha(theme.palette.grey[500], 1.0)} !important`,
                },
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default SubscriptionIntervalSelect;
