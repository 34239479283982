import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    height="20"
    width="21"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      d="M10.1587 16.6667H17.6587"
      color="inherit"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9087 2.91669C14.2402 2.58517 14.6899 2.39893
     15.1587 2.39893C15.3908 2.39893 15.6207 2.44465 15.8352
      2.53349C16.0497 2.62233 16.2445 2.75254 16.4087
       2.91669C16.5728 3.08085 16.7031 3.27572 16.7919
        3.4902C16.8807 3.70467 16.9265 3.93455 16.9265
         4.16669C16.9265 4.39884 16.8807 4.62871 16.7919
          4.84319C16.7031 5.05766 16.5728 5.25254 16.4087
           5.41669L5.99202 15.8334L2.65869 16.6667L3.49202
            13.3334L13.9087 2.91669Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const PreviewIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M0.666504 7.99984C0.666504 7.99984 3.33317 2.6665
 7.99984 2.6665C12.6665 2.6665 15.3332 7.99984 15.3332
  7.99984C15.3332 7.99984 12.6665 13.3332 7.99984
  13.3332C3.33317 13.3332 0.666504 7.99984 0.666504
  7.99984Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8 10C9.10457 10 10 9.10457
10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6
6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const SaveIcon = (): JSX.Element => (
  <SvgIcon
    height="20"
    width="20"
    viewBox="0 0 20 20"
    sx={{
      color: 'transparent',
    }}
  >
    <path
      d="M15.8333 17.5H4.16667C3.72464 17.5 3.30072
       17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754
        2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072
         2.98816 2.98816C3.30072 2.67559 3.72464 2.5
          4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5
           16.2754 17.3244 16.6993 17.0118 17.0118C16.6993
            17.3244 16.2754 17.5 15.8333 17.5Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1663 17.5V10.8333H5.83301V17.5"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83301 2.5V6.66667H12.4997"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
