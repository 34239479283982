import { Box, List } from '@mui/material';
import { EUserDealPermissions } from '../../../../../shared/permissions';
import { EUserProcessStatus } from '../../../../../shared/user-process';
import { OrganizationUserDTO, ProcessUserDTO } from '../../../../../shared/UserDTO';
import { useAppSelector } from '../../../../hooks/stateHooks';
import FeedList from '../FeedList';
import RemoveUser from './RemoveProvessUserActionButton';

import { UserItem } from './UserItem/UserItem';
import { UserItemSkeleton } from './UserItemSkeleton';

interface UserListProps {
  processUsers?: ProcessUserDTO[];
  onlineUserIds: string[];
  isShared: boolean;
}

export const DealUsersList = ({
  processUsers,
  onlineUserIds,
  isShared,
}: UserListProps): JSX.Element => {
  const currentUserPermissions = useAppSelector((state) => state.process?.currentUserPermissions);
  // const accessLink = useAppSelector((state) => state.process?.accessLink);
  const owner = useAppSelector((state) => state.process?.owner);
  const user = useAppSelector((state) => state.app.user);
  const users = useAppSelector((state) => state.app.users);
  const isTeamMember = (userId: string) => users?.find((orgUser: OrganizationUserDTO) => userId === orgUser.id);

  const isRemoveUserAvailable: boolean = currentUserPermissions[EUserDealPermissions.DEAL_USERS_DELETE];

  const processUsersItems: JSX.Element[] | undefined = processUsers?.filter(
    (processUser: ProcessUserDTO) => processUser.processStatus !== EUserProcessStatus.DEACTIVATED
      && processUser.processStatus !== EUserProcessStatus.INVITED_AND_DEACTIVATED,
  )
    .map((processUser: ProcessUserDTO) => (
      <UserItem
        user={processUser}
        isDeleteActive={isRemoveUserAvailable && user?.id !== processUser.id}
        key={processUser.id}
        isTeamMember={!!isTeamMember(processUser.id)}
        isOnline={onlineUserIds.includes(processUser.id)}
        linkedIn={processUser.externalLinks?.linkedIn}
        calendly={processUser.externalLinks?.calendly}
        isBadgeEnabled
        secondaryAction={(
          <RemoveUser
            isSelf={user?.id === processUser.id}
            isRemoveUserAvailable={isRemoveUserAvailable && (processUser.id !== owner)}
            isShared={isShared}
            name={processUser.name}
            id={processUser.id}
            email={processUser.email}
          />
        )}
      />
    ));

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        marginX: 0,
        paddingX: 0,
        maxWidth: '100%',
        height: 'auto',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: '0px',
          height: '0px',
          display: 'none',
        },
      }}
    >
      {processUsers && (
        <FeedList
          sx={{
            maxHeight: '100%',
            paddingTop: '0px',
          }}
        >
          {processUsersItems}
        </FeedList>
      )}
      {!processUsers?.length && (
        <List
          sx={{
            flexGrow: 1,
          }}
        >
          <UserItemSkeleton />
          <UserItemSkeleton />
          <UserItemSkeleton />
          <UserItemSkeleton />
        </List>
      )}
    </Box>
  );
};
