import { styled } from '@mui/styles';
import {
  Box, Collapse, Radio, Typography,
} from '@mui/material';
import { stagesetColors } from '../../../../../theme/stagesetPalette';

export const RadioButtonTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
}));

export const RadioButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
}));

export const ShareLinkRadioWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '11px 24px',
  justifyContent: 'space-between',
}));

export const CopyLinkButtonWrapper = styled(Box)(() => ({
  padding: '16px 24px',
}));

export const ShareLinkRadioButton = styled(Radio)(() => ({
  width: '20px',
  height: '20px',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
}));

export const ShareDealDomainProtectionCollapse = styled(Collapse)(() => ({
  '&.ShareLinkSettingCollapse > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
    overflow: 'hidden',
    margin: '0px 8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    gap: '10px',
    background: stagesetColors.newGrey[50],
    boxShadow: '0px 0px 12px -4px rgba(30, 41, 59, 0.25) inset',
  },
}));
