import { Box } from '@mui/system';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { FilesDragAndDrop } from '../../common/FilesDragAndDrop/FilesDragAndDrop';
import { MediaFieldUploadIcon } from '../../icons/DndIcons/DnDIcons';
import { AppLoader } from '../../shared/AppLoader/AppLoader';
import { allImgFormats, mediaFieldMimes } from '../MediaField/Helpers/MediaFieldHelpers';
import { UploadIcon20 } from '../../icons/UploadIcon';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  isVisible: boolean;
  onUpload: (files: File[]) => void;
  loading?: boolean;
}

const MAX_SIZE = 1024 * 1024 * 10;

const CustomBackgroundUpload = ({
  isVisible,
  onUpload,
  loading,
}: Props): JSX.Element => {
  const dndText: JSX.Element = useMemo(() => (
    loading ? (
      <AppLoader size={30} />)
      : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
          }}
        >

          <MediaFieldUploadIcon />
          <Typography
            sx={{
              alignSelf: 'center',
              fontSize: '14px',
              lineHeight: '16px',
              fontWeight: 600,
              color: stagesetColors.newGrey[800],
            }}
          >
            Upload
          </Typography>
        </Box>
      )
  ), [loading]);

  const handleFileUpload = (files: File[]) => {
    if (files?.length) {
      onUpload(files);
    }
  };

  return (
    <Box
      sx={{
        width: 'calc( 100% )',
        borderRadius: '12px',
        visibility: !isVisible ? 'hidden' : 'visible',
      }}
    >
      <FilesDragAndDrop
        newPageMediaInput
        dropzoneOptions={{
          accept: mediaFieldMimes(allImgFormats),
          maxSize: MAX_SIZE,
        }}
        onFileDrop={handleFileUpload}
        text={dndText}
        icon={<UploadIcon20 />}
        border="1px dashed"
        borderColor={stagesetColors.newGrey[300]}
        borderRadius="12px"
        background={stagesetColors.white[100]}
        styles={{
          height: '55px',
          maxWidth: 'unset',
          minHeight: '55px',
          background: stagesetColors.white[100],
          padding: '16px 24px',
        }}
        textContainerStyles={{
          background: stagesetColors.white[100],
          padding: '0px',
        }}
      />
    </Box>
  );
};

export default CustomBackgroundUpload;
