import {
  Box, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { EOrganizationRoles, RoleDTO } from '../../../../../../shared/permissions';
import { UserRolesDTO } from '../../../../../../shared/user-organization';
import { useAppSelector } from '../../../../../hooks/stateHooks';
import { AppInput } from '../../../../../shared/AppInput/AppInput';
import UserDetailsItemHeader from './UserDetailsItemHeader';

interface Props {
  user: UserRolesDTO;
  isExpanded: boolean;
  organizationId: string;
  setExpanded: (expanded: string) => void;
  setUserObject: (user: Partial<UserRolesDTO>) => void;
}

const UserDetailsItem = ({
  user,
  isExpanded,
  setExpanded,
  organizationId,
  setUserObject,
}: Props): JSX.Element => {
  const [userObject, setUser] = useState(user);
  const [openRole, setOpenRole] = useState(false);
  const { roles } = useAppSelector((state) => state.app);
  const roleNames: {
    [key in EOrganizationRoles | string]: string
  } = {
    [EOrganizationRoles.OWNER]: 'Owner',
    [EOrganizationRoles.SELLER]: 'User',
    [EOrganizationRoles.EMPLOYEE]: 'Employee',
    [EOrganizationRoles.ADMIN]: 'Administrator',
  };

  useEffect(() => {
    setUser(user);
  }, [user]);

  const userChanged = (newUser: UserRolesDTO) => {
    setUser(newUser);
    setUserObject(newUser);
  };

  return (
    <>
      <Box
        onClick={() => {
          setExpanded(user.user.id);
        }}
        sx={{
          width: '100%',
        }}
      >
        <UserDetailsItemHeader
          user={user.user}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          organizationId={organizationId}
          isOwner={user.roles && user.roles[0].name === EOrganizationRoles.OWNER}
        />
      </Box>
      {
        isExpanded && (
          <Box
            id="expanded-user"
            sx={{
              backgroundColor: '#F8FAFC', // (theme) => theme.palette.newGrey[50],
              marginLeft: '-12px',
              marginRight: '-12px',
              padding: '16px 12px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <AppInput
                fullWidth
                label="Name"
                defaultValue={userObject.user.name}
                value={userObject.user.name}
                rows={4}
                onChange={(event: any) => {
                  userChanged({
                    ...userObject,
                    user: {
                      ...userObject.user,
                      name: event.target.value,
                    },
                  });
                }}
              />
            </Box>
            <Box
              sx={{
                paddingTop: '16px',
              }}
            >
              {user.roles[0]?.name === EOrganizationRoles.OWNER && (
                <AppInput
                  fullWidth
                  defaultValue={user.roles[0]?.name}
                  value={user.roles[0]?.name}
                  label="Role"
                  disabled
                />
              )}
              {user.roles[0]?.name !== EOrganizationRoles.OWNER && (
                <>
                  <InputLabel id="role-select-label">Role</InputLabel>
                  <Select
                    fullWidth
                    labelId="role-select-label"
                    id="role-select"
                    open={openRole}
                    onClose={() => {
                      setOpenRole(false);
                    }}
                    onOpen={() => {
                      setOpenRole(true);
                    }}
                    value={userObject.roles?.length ? userObject.roles[0]?.name : undefined}
                    label="Role"
                    onChange={(event: any) => {
                      userChanged({
                        ...userObject,
                        roles: [roles.find((x: RoleDTO) => x.name === event.target.value)],
                      });
                    }}
                    sx={{
                      borderRadius: '10px',
                      '& .MuiSelect-select': {
                        padding: '8px',
                      },
                    }}
                  >
                    {
                      !!roles && roles.map((item: RoleDTO) => {
                        if (item.name !== EOrganizationRoles.OWNER) {
                          return (
                            <MenuItem value={item.name} key={item.name}>{roleNames[item.name]}</MenuItem>
                          );
                        }
                        return null;
                      })
                    }
                  </Select>
                </>
              )}
            </Box>
            <Box
              sx={{
                paddingTop: '16px',
              }}
            >
              <AppInput
                fullWidth
                label="Email"
                defaultValue={userObject.user.email}
                value={userObject.user.email}
                rows={4}
                onChange={(event: any) => {
                  userChanged({
                    ...userObject,
                    user: {
                      ...userObject.user,
                      email: event.target.value,
                    },
                  });
                }}
                disabled
              />
            </Box>
            <Box
              sx={{
                paddingTop: '16px',
              }}
            >
              <AppInput
                fullWidth
                label="LinkedIn"
                defaultValue={userObject.user.externalLinks?.linkedIn}
                value={userObject.user.externalLinks?.linkedIn}
                rows={4}
                onChange={(event: any) => {
                  userChanged({
                    ...userObject,
                    user: {
                      ...userObject.user,
                      externalLinks: {
                        ...userObject.user.externalLinks,
                        linkedIn: event.target.value,
                      },
                    },
                  });
                }}
              />
            </Box>
            <Box
              sx={{
                paddingTop: '16px',
              }}
            >
              <AppInput
                fullWidth
                label="Meeting scheduler link"
                defaultValue={userObject.user.externalLinks?.calendly}
                value={userObject.user.externalLinks?.calendly}
                rows={4}
                onChange={(event: any) => {
                  userChanged({
                    ...userObject,
                    user: {
                      ...userObject.user,
                      externalLinks: {
                        ...userObject.user.externalLinks,
                        calendly: event.target.value,
                      },
                    },
                  });
                }}
              />
            </Box>
          </Box>
        )
      }
    </>
  );
};

export default UserDetailsItem;
