import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import { StageMenuLabels } from '../../hooks/useStages';
import AddEmbedFieldSectionMenu from '../BlockContentSection/BlockContentRowMenu/AddEmbedFieldSectionMenu';
import { selectEmbedSectionData } from '../BlockContentSection/lib/sectionsSlice';

interface Props {
  anchor: HTMLElement;
  menuVariant: StageMenuLabels;
  setAnchor: (anch: HTMLElement | null) => void;
  setMenuVariant: (label: StageMenuLabels) => void;
}

const StageSidebarEmbedMenu = ({
  anchor,
  menuVariant,
  setAnchor,
  setMenuVariant,
}: Props) => {
  const selectedData = useAppSelector(selectEmbedSectionData);

  useEffect(() => {
    if (!selectedData && menuVariant === 'slides') {
      setMenuVariant('default');
      setAnchor(null);
    }
  }, [selectedData]);

  return (
    <AddEmbedFieldSectionMenu
      anchorEl={anchor}
      open={menuVariant === 'slides'}
      handleClose={() => {
        setAnchor(null);
        setMenuVariant('default');
      }}
      goBack={() => {
        setMenuVariant('default');
      }}
      newStage
    />
  );
};

export default StageSidebarEmbedMenu;
