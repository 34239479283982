import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Container, SxProps, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { stagesetPalette } from '../../theme/stagesetPalette';

const baseStyle = {
  boxSizing: 'border-box',
  height: '100%',
  maxHeight: '100%',
  width: '100%',
  maxWidth: '100%',
  flex: 1,
  alignItems: 'center',
  color: 'rgba(101, 103, 109, 0.5)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  padding: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
};

export interface IFilesDragAndDropProps {
  newPageMediaInput?: boolean,
  onFileDrop: (files: File[], rejected?: FileRejection[]) => void;
  dropzoneOptions?: DropzoneOptions;
  text?: string | JSX.Element;
  styles?: React.CSSProperties;
  icon?: JSX.Element | 'none';
  textStyle?: SxProps;
  textContainerStyles?: SxProps;
  mainContainerStyles?: SxProps;
  isDisabled?: boolean;
  border?: string;
  borderColor?: string;
  borderRadius?: string;
  background?: string;
  iconSize?: {
    height: string,
    width: string,
  };
}

export const FilesDragAndDrop = ({
  newPageMediaInput,
  onFileDrop,
  dropzoneOptions,
  text,
  styles,
  icon,
  textStyle,
  isDisabled,
  iconSize,
  border,
  borderColor,
  borderRadius,
  background,
  textContainerStyles,
  mainContainerStyles,
}: IFilesDragAndDropProps): JSX.Element => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (newPageMediaInput && isDragReject) return undefined;
      return onFileDrop(acceptedFiles, rejectedFiles);
    },
    ...dropzoneOptions,
    disabled: isDisabled,
  });

  const borderColorPicker = () => {
    if (isDragAccept) return '#00e676';
    if (isDragReject) return '#FB5F4C';
    if (isDragActive) return '#2196f3';
    if (borderColor) return borderColor;
    return stagesetPalette.primary?.main;
  };

  const style = useMemo(() => ({
    ...mainContainerStyles,
    ...baseStyle,
    ...styles,
    border: border ?? '1px solid',
    borderColor: borderColorPicker(),
    borderRadius: borderRadius ?? '16px',
    background: background ?? '#E5E5E5',
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
  ]);

  return (
    <Container
      className="DnDContainer"
      {
      // @ts-ignore
      ...getRootProps({ style })
      }
      sx={{
        '& .DnDAcceptedFormats': {
          color: isDragReject ? '#FB5F4C' : '',
        },
      }}
    >
      <input {...getInputProps()} />
      <Typography
        variant="h4"
        className="DnDText"
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
          height: '100%',
          maxHeight: '100%',
          width: '100%',
          maxWidth: '100%',
          display: 'flex',
          padding: '5px',
          background: 'white',
          borderRadius: 'inherit',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          ...textContainerStyles,
        }}
      >
        {!icon && (
          <CloudUploadOutlinedIcon sx={{
            height: iconSize?.height ?? '100%',
            maxHeight: iconSize?.height ?? '100%',
            width: iconSize?.height ?? '100%',
            maxWidth: iconSize?.height ?? '100%',
            fontSize: '500px',
          }}
          />
        )}
        {
          text
          || (
          <Typography
            sx={textStyle}
          >
            Drag and drop the file here to upload
          </Typography>
          )
        }
      </Typography>
    </Container>
  );
};
