import { Box } from '@mui/material';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import MediaResizePlugin from './MediaResizePlugin';
import IFrameCover from '../../shared/IFrameCover/IFrameCover';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';
import { StageContentType } from '../../../shared/Stage';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { selectIsToolbarNotShown } from '../../routes-old/auth/state/authState';
import { useAppSelector } from '../../hooks/stateHooks';
import { MediaNodeAttrs, TiptapNodeUpdate } from '../../../shared/Tiptap/TiptapTypes';

interface Props {
  url: string;
  onUpdate: (data: TiptapNodeUpdate<MediaNodeAttrs>) => void;
  width?: number;
  height?: number;
}

const MediaFieldIframe = ({
  url: src,
  onUpdate,
  width: initialWidth,
  height: initialHeight,
}: Props): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [isActive, setActive] = useState(false);
  const [isInteractionLogged, setInteractionLogged] = useState(false);
  const currentStage = useCurrentStage();
  const isToolbarNotShown = useAppSelector(selectIsToolbarNotShown);
  const wideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;

  const { createWebEmbedInteraction } = useFrontEventsApi();

  const getDefaultWidth = useMemo(() => {
    if (isMobile) {
      return window.screen.width - 32;
    }
    if (width) {
      return `${width}px`;
    }
    if (!width && iframeRef.current?.parentElement) {
      return `${iframeRef.current.parentElement?.clientWidth}px`;
    }
    return 'calc( 100% )';
  }, [width, iframeRef.current]);

  const getMaxWidth = useCallback(() => {
    if (iframeRef.current) {
      return `${iframeRef.current?.parentElement?.clientWidth}px`;
    }
    return 'calc( 100% )';
  }, [iframeRef.current]);

  const parentRef = iframeRef.current?.parentElement;

  const generateIframeSize = useCallback(() => {
    let calcHeight;
    if (isMobile && wideScreenStage) {
      calcHeight = 'calc( 100% - 54px )';
    } else {
      calcHeight = height ?? '100%';
    }

    return {
      width: isMobile ? '100%' : (width ?? parentRef?.clientWidth ?? '100%'),
      height: calcHeight,
      maxWidth: parentRef?.clientWidth,
    };
  }, [width, height, parentRef]);

  const eventHandler = () => {
    if (!isInteractionLogged) {
      createWebEmbedInteraction({
        linkUrl: src,
      });
      setInteractionLogged(true);
    }
  };

  const renderHeight = () => {
    if (height) return height - 2;
    if (wideScreenStage) {
      if (!isToolbarNotShown) return 'calc(100vh - 48px)';
      return '100vh';
    }
    return isMobile ? undefined : '524px';
  };

  const renderWidth = () => {
    if (wideScreenStage) {
      return '100%';
    }
    return getDefaultWidth === '0px' ? '100%' : getDefaultWidth;
  };

  const renderMaxWidth = () => {
    if (wideScreenStage) {
      return '100%';
    }
    return getMaxWidth;
  };

  useEffect(() => {
    if (isActive && !isInteractionLogged) {
      window.addEventListener('blur', eventHandler);
    }
    return () => {
      if (isActive) {
        window.removeEventListener('blur', eventHandler);
      }
    };
  }, [isActive, isInteractionLogged]);

  return (
    <Box
      sx={{
        height: renderHeight(),
        width: renderWidth(),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: wideScreenStage ? undefined : '10px',
        maxWidth: renderMaxWidth(),
      }}
      ref={iframeRef}
    >
      <IFrameCover
        width={width}
        height={height}
      />
      <iframe
        id="doc-iframe-id"
        title="media-field"
        className={width || height ? 'widht-height' : ''}
        src={src.startsWith('http') ? src : `//${src}`}
        style={{
          userSelect: 'none',
          margin: '0px',
          borderRadius: wideScreenStage ? undefined : '10px',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          width: generateIframeSize().width,
          height: generateIframeSize().height,
          maxWidth: generateIframeSize().maxWidth,
          aspectRatio: isMobile ? '1.52' : undefined,
        }}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      />
      {
        iframeRef?.current
        && !isMobile
        && !wideScreenStage
        && (
          <MediaResizePlugin
            containerRef={iframeRef.current}
            width={width}
            height={height}
            onUpdate={onUpdate}
            onWidthUpdate={(newWidth) => setWidth(newWidth)}
            onHeightUpdate={(newHeight) => setHeight(newHeight)}
          />
        )
      }
    </Box>
  );
};

export default MediaFieldIframe;
