import { OrganizationDTO } from '../../../../../../shared/OrganizationDTO';
import { UserRolesDTO } from '../../../../../../shared/user-organization';
import { InputComponent, ResultComponent } from '../../../../../types/hoc';

interface InputProps {
  organization: OrganizationDTO;
  userRoles: UserRolesDTO[];
}

interface ResultProps {
  organization: OrganizationDTO;
  userRoles: UserRolesDTO[];
}

// eslint-disable-next-line react/display-name, max-len
export const withOrganizationDetailsGeneral = <P extends InputProps, R extends ResultProps>(Component: InputComponent<R>): ResultComponent<P> => (props) => {
  // eslint-disable-next-line react/prop-types
  const { organization, userRoles } = props;
  // const { data: organization } = useOrganizationFetchData(organization.id);

  // if (!organization) return null;
  // @ts-ignore
  return <Component organization={organization} {...props} userRoles={userRoles} />;
};
