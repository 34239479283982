import {
  Box, Collapse, Switch, Typography,
} from '@mui/material';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import { stagesetColors } from '../../../theme/stagesetPalette';

interface Props {
  children?: JSX.Element;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  title?: string;
  description?: string;
  contentDistance?: number;
  contentSpacing?: number;
  contentShiht?: number;
}

const SwitchWithTitleWrapper = ({
  onChange,
  children,
  isChecked,
  title,
  description,
  contentDistance,
  contentSpacing,
  contentShiht,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
      padding: '12px 16px',
      backgroundColor: stagesetColors.white[100],
      borderRadius: '12px',
      // eslint-disable-next-line max-len
      boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
        + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
        + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
        + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
        }}
      >
        <AppSwitch
          checked={isChecked}
          onChange={(e) => onChange(e?.target?.checked || false)}
          // focusVisibleClassName=".Mui-focusVisible"
          // disableRipple
        />
        {
          (title || description)
          && (
            <Box>
              {
                title
                && (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: 600,
                      color: stagesetColors.newGrey[800],
                    }}
                  >
                    {title}
                  </Typography>
                )
              }
              {/* // new invite use side menu styles */}
              {
                description
                && (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '16px',
                      letterSpacing: '0px',
                      fontWeight: 400,
                      color: stagesetColors.newGrey[500],
                    }}
                  >
                    {description}
                  </Typography>
                )
              }
            </Box>
          )
    }
      </Box>
      {
        children && (
          <Collapse
            in={isChecked}
            sx={{
              '& .MuiCollapse-wrapperInner': {
                marginTop: `${contentDistance ?? 8}px`,
                marginLeft: `${contentShiht ?? 0}px`,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: `${contentSpacing ?? 0}px`,
              },
            }}
          >
            {children}
          </Collapse>
        )
      }
    </Box>
  </Box>
);

export default SwitchWithTitleWrapper;
