import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { CelloApi } from '../../api/CelloApi';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectUser } from '../../core/store/appState/appState';
import useCelloScript from '../../hooks/useCelloScript';
import { EAppRoutes } from '../../core/router';

const celloApi = new CelloApi();

const CelloIntegration = (): any => {
  const isLoaded = useCelloScript();
  const user = useAppSelector(selectUser);
  const [isActive, setActive] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const { pathname } = useLocation();

  const getToken = async () => {
    setActive(false);
    celloApi.generateToken().then((res) => setToken(res));
  };

  useEffect(() => () => {
    if (typeof window.Cello === 'function') {
      window.Cello('shutdown');
    }
  }, []);

  useEffect(() => {
    if (typeof window?.Cello === 'function' && isLoaded && isActive) {
      if (
        pathname.includes(EAppRoutes.deal)
        || pathname.includes(`${EAppRoutes.templates}/`)
      ) {
        console.log(pathname);
        window.Cello('hide');
      } else {
        window.Cello('show');
      }
    }
  }, [pathname, isLoaded, isActive, window?.Cello]);

  useEffect(() => {
    if (isLoaded) {
      getToken();
      window.cello = window.cello || { cmd: [] };
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded && token && user && !isActive) {
      const { productId } = jwtDecode<any>(token);
      window.cello.cmd.push((cello) => {
        cello.boot({
          productId,
          token,
          hideDefaultLauncher: true,
          productUserDetails: {
            firstName: (user?.name?.split(' ').length ?? 0) > 1 ? user?.name?.split(' ')[0] : user.name,
            fullName: user.name,
            email: user.email,
          },
          onTokenExpiring: async () => {
            await getToken();
          },
        });
      });
      setActive(true);
    }
  }, [isLoaded, token, isActive, user]);

  return null;
};

export default CelloIntegration;
