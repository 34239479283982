import { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import Matercolor from 'matercolors';
import { Theme } from '@mui/system';
import { useAppSelector } from './stateHooks';
import { useAppTheme } from './useAppTheme';
import { ThemePalette } from '../../shared/process/ProcessMilestoneActionDTO';
import {
  selectDealThemePaletteCommon, selectDealThemePaletteSidebar,
} from '../features/DealCustomizationMenu/lib/dealCustomizationSlice';
import { getPaletteDefaultParams, paletteDefaultArgs } from '../theme';

const colorNameToHex = (color: 'white' | 'black') => (color === 'white' ? '#ffffff' : '#000000');

export const useDealTheme = () => {
  // const dealThemePaletteCommon = useAppSelector(selectDealThemePaletteCommon);
  const dealThemePaletteSidebar = useAppSelector(selectDealThemePaletteSidebar);
  const { mode, currentTheme } = useAppTheme();
  const [dealTheme, setDealTheme] = useState<Theme>(currentTheme);

  const updateDealTheme = (palette?: ThemePalette) => {
    if (!palette) {
      setDealTheme(currentTheme);
      return;
    }
    const paletteParams = getPaletteDefaultParams(mode);
    // if (palette.common) {
    //   const commonColor = new Matercolor(palette.common, { showContrastText: true });
    //   paletteParams.palette.primary = {
    //     light: commonColor[300].hex,
    //     main: commonColor[600].hex,
    //     dark: commonColor[900].hex,
    //     50: commonColor[50].hex,
    //     100: commonColor[100].hex,
    //     200: commonColor[200].hex,
    //     300: commonColor[300].hex,
    //     400: commonColor[500].hex,
    //     500: commonColor[700].hex,
    //     600: commonColor[900].hex,
    //     contrastText: colorNameToHex(commonColor[900].contrastText),
    //   };
    //   paletteParams.palette.secondary = {
    //     light: commonColor.C300.hex,
    //     main: commonColor.C600.hex,
    //     dark: commonColor.C900.hex,
    //     50: commonColor.C50.hex,
    //     100: commonColor.C100.hex,
    //     200: commonColor.C200.hex,
    //     300: commonColor.C300.hex,
    //     400: commonColor.C500.hex,
    //     500: commonColor.C700.hex,
    //     600: commonColor.C900.hex,
    //     contrastText: colorNameToHex(commonColor.C900.contrastText),
    //   };
    //   paletteParams.dealOverrides.primaryContrast = {
    //     light: colorNameToHex(commonColor[300].contrastText),
    //     main: colorNameToHex(commonColor[600].contrastText),
    //     dark: colorNameToHex(commonColor[900].contrastText),
    //     50: colorNameToHex(commonColor[50].contrastText),
    //     100: colorNameToHex(commonColor[100].contrastText),
    //     200: colorNameToHex(commonColor[200].contrastText),
    //     300: colorNameToHex(commonColor[300].contrastText),
    //     400: colorNameToHex(commonColor[500].contrastText),
    //     500: colorNameToHex(commonColor[700].contrastText),
    //     600: colorNameToHex(commonColor[900].contrastText),
    //   };
    //   paletteParams.dealOverrides.secondaryContrast = {
    //     light: colorNameToHex(commonColor.C300.contrastText),
    //     main: colorNameToHex(commonColor.C600.contrastText),
    //     dark: colorNameToHex(commonColor.C900.contrastText),
    //     50: colorNameToHex(commonColor.C50.contrastText),
    //     100: colorNameToHex(commonColor.C100.contrastText),
    //     200: colorNameToHex(commonColor.C200.contrastText),
    //     300: colorNameToHex(commonColor.C300.contrastText),
    //     400: colorNameToHex(commonColor.C500.contrastText),
    //     500: colorNameToHex(commonColor.C700.contrastText),
    //     600: colorNameToHex(commonColor.C900.contrastText),
    //   };
    // }

    if (palette.sidebar) {
      const sidebarColor = new Matercolor(palette.sidebar, { showContrastText: true });
      const colorData: {
        hex: string,
        contrastText: string
      } | undefined = Object.values(sidebarColor).find((c) => c.hex === sidebarColor.color.toLowerCase());
      paletteParams.dealOverrides.sidebar = {
        backgroundColor: sidebarColor.color,
        contrastText: colorNameToHex(colorData?.contrastText ?? sidebarColor[600].contrastText),
      };
    }
    setDealTheme(createTheme(paletteParams, paletteDefaultArgs));
  };

  useEffect(() => {
    updateDealTheme({ sidebar: dealThemePaletteSidebar });
  }, [dealThemePaletteSidebar]);

  return { dealTheme };
};
