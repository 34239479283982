import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { EOrganizationRoles, RoleDTO } from '../../../../../shared/permissions';
import { organizationAddUser } from '../../../../core/store/appState/appState';
import { useAppDispatch } from '../../../../hooks/stateHooks';
import { useBlure } from '../../../../hooks/useBlure';

import { useOrganization } from '../../../../hooks/useOrganization';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import ShareIcon from '../../../../icons/ShareIcon';
import { TeamTabInviteToTeamPopup } from '../../../../features/InviteToTeamPopup/TeamTabInviteToTeamPopup';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { AvatarPlusIcon20 } from '../../../../icons/AvatarPlusIcon';

const OrganizationUserInvite = (): JSX.Element => {
  const { roles, organization } = useOrganization();
  const [isInviteDialogOpen, setInviteDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const inviteSchema = yup.object({
    email: yup.string().trim().email().required('Email is required'),
  }).required();

  const handleInviteDialogOpen = () => {
    setInviteDialogOpen(true);
  };

  const handleInviteDialogClose = () => {
    setInviteDialogOpen(false);
  };

  useBlure(isInviteDialogOpen);

  const onUserInvite = async (email: string): Promise<void> => {
    setLoading(true);
    const roleId = roles?.find((role: RoleDTO) => role.name === EOrganizationRoles.SELLER)?.id;
    if (!roleId) {
      enqueueSnackbar('No role id', { variant: 'error' });
      setLoading(false);
      return;
    }
    try {
      const { email: validatedEmail } = await inviteSchema.validate({ email });
      if (organization.id) {
        await dispatch(organizationAddUser({
          email: validatedEmail,
          roleIds: [roleId],
          organizationId: organization.id,
        })).unwrap();
      }
      handleInviteDialogClose();
      enqueueSnackbar('The user has been invited', { variant: 'success' });
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    }
    setLoading(false);
  };

  const renderButton = useMemo(() => {
    if (isMobile) {
      return (
        <AppIconButton
          onClick={handleInviteDialogOpen}
          icon={<AvatarPlusIcon20 />}
          variant="primary"
          size="l"
        />
      );
    } return (
      <AppButton
        onClick={handleInviteDialogOpen}
        startIcon={<ShareIcon />}
        variant="primary"
        size="l-auto"
      >
        {t('Team_Invite')}
      </AppButton>
    );
  }, []);

  return (
    <>
      {renderButton}
      <TeamTabInviteToTeamPopup
        open={isInviteDialogOpen}
        setOpen={setInviteDialogOpen}
        onInvite={onUserInvite}
        loading={loading}
      />
    </>
  );
};

export default OrganizationUserInvite;
