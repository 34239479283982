import { AppSkeleton } from './AppSkeleton';
import { stagesetColors } from '../../theme/stagesetPalette';
import {
  SkeletonStringsContainer,
  SkeletonStringsLayout,
  SkeletonStringsTemplateContainer,
} from './AppSkeletonStyles';

export const AppSkeletonStrings = () => (
  <SkeletonStringsLayout>
    <SkeletonStringsContainer>
      <AppSkeleton
        animation="wave"
        variant="rectangular"
        sx={{
          maxWidth: '500px',
          width: '100%',
          borderRadius: '30px',
          height: '39px',
          border: '1px solid',
          borderColor: stagesetColors.newGrey[100],
        }}
      />
      <AppSkeleton
        animation="wave"
        variant="rectangular"
        sx={{
          maxWidth: '700px',
          width: '100%',
          borderRadius: '30px',
          height: '26px',
          border: '1px solid',
          borderColor: stagesetColors.newGrey[100],
        }}
      />
    </SkeletonStringsContainer>
  </SkeletonStringsLayout>
);

export const AppSkeletonTemplateStrings = () => (
  <SkeletonStringsTemplateContainer>
    <AppSkeleton
      animation="wave"
      variant="rectangular"
      sx={{
        maxWidth: '160px',
        width: '100%',
        height: '22px',
        borderRadius: '16px',
      }}
    />
    <AppSkeleton
      animation="wave"
      variant="rectangular"
      sx={{
        maxWidth: '50px',
        width: '30%',
        height: '14px',
        borderRadius: '16px',
      }}
    />
  </SkeletonStringsTemplateContainer>
);
