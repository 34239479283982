import { MobileSubscriptionsGridContainer } from './SubscriptionsStyles';
import PaymentPeriod from './PaymentPeriod';
import { useSubscriptions } from '../../hooks/useSubscriptions';
import { SubscriptionGridCard } from './SubscriptionGridCard';

export const MobileSubscriptionGrid = () => {
  const {
    checkIfSubscriptionActive,
    subscriptions,
    appSumoSubscriptions,
    checkIfCurrentSubscriptionIsAppSumo,
    currentPlan,
    subscription: current,
  } = useSubscriptions();
  return (
    <MobileSubscriptionsGridContainer>
      {
          checkIfCurrentSubscriptionIsAppSumo && (
            appSumoSubscriptions.map((subscription) => {
              const description = typeof subscription.description === 'string'
                ? subscription.description
                : <PaymentPeriod options={subscription.description} type={subscription.type} />;

              return (
                <SubscriptionGridCard
                  key={`mobile-subscription-card-${subscription.type}`}
                  title={subscription.title}
                  description={description}
                  features={subscription.features}
                  type={subscription.type}
                  btnTitle={subscription.btnTitle}
                  current={checkIfSubscriptionActive(subscription)}
                />
              );
            })
          )
      }
      {
          !checkIfCurrentSubscriptionIsAppSumo && (
            subscriptions.map((subscription) => {
              const description = typeof subscription.description === 'string'
                ? subscription.description
                : <PaymentPeriod options={subscription.description} type={subscription.type} />;

              return (
                <SubscriptionGridCard
                  key={`mobile-subscription-card-${subscription.type}`}
                  title={subscription.title}
                  description={description}
                  features={subscription.features}
                  type={subscription.type}
                  btnTitle={subscription.btnTitle}
                  current={subscription.priceId === currentPlan?.priceId && (current?.type === subscription?.type)}
                  priceId={subscription.priceId}
                />
              );
            })
          )
      }
    </MobileSubscriptionsGridContainer>
  );
};
