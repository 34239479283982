import { AuthQueryParams, EActivationLinkType } from '../../../shared/schema/auth.shcema';
import { useQuery } from '../../hooks/useQuery';

const useAuthQuery = (): [params: AuthQueryParams] => {
  const query = useQuery();
  const email: string | null = query.get('email');
  const hash: string | null = query.get('hash');
  const type: EActivationLinkType = query.get('invite-type') as EActivationLinkType;
  const fullName: string | null = query.get('fullName') as EActivationLinkType;
  const joinedDeal: string | null = query.get('joinedDeal');
  return [
    {
      email,
      type,
      hash,
      fullName,
      joinedDeal,
    },
  ];
};

export default useAuthQuery;
