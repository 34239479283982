import { alpha, styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

export const StageButton = styled(Button)(({ theme }) => ({
  borderRadius: '0',
  '& .MuiButton-startIcon': {
    stroke: greySidebarContrastText(theme, 0.8),
    minWidth: '25px',
    marginRight: '12px',
  },
  '& path': {
    stroke: 'inherit',
  },
  ':hover': {
    // color: theme.palette.primary.main,
    '& .MuiButton-startIcon': {
      // stroke: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  '&.MuiButtonBase-root': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    height: '52px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: greySidebarContrastText(theme, 0.8),
    display: 'flex',
    justifyContent: 'flex-start',
    stroke: greySidebarContrastText(theme, 0.8),
    '&:hover': {
      background: greySidebarContrastText(theme, 0.1),
    },
    '& svg': {
      stroke: 'inherit',
    },
  },
}));
