import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { EStateStatus } from '../../../core/commonTypes';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import BackToLogin from '../../../shared/AuthBtns/BackToLogin';
import AuthButton from '../Components/AuthButton';
import { TitleTypography, TitleTypographyWrapper } from '../Components/AuthStyledComponents';
import FormError from '../Components/FormError';
import { resetAuthError, resetPassword } from '../state/authState';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';
import { useTranslation } from "react-i18next";

export interface IFormState {
  email: string;
}

const ResetPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status, error } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const schema = yup.object({
    email: yup.string().required(t('Error_Email_is_required')).email(t('Error_The_email_must_be_a_valid_email_address')),
  });

  const {
    handleSubmit,
    control,
    register,
  } = useForm<IFormState>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  register('email', {
    onChange: () => {
      dispatch(resetAuthError());
    },
  });

  useEffect(() => () => {
    dispatch(resetAuthError());
  }, []);

  const onSubmit = async (data: IFormState): Promise<void> => {
    try {
      await dispatch(resetPassword(data?.email)).unwrap();
      navigate(`${EAppRoutes.auth + EAppRoutes.resetPasswordSuccess}`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthPaper>
      <>
        <TitleTypographyWrapper>
          <TitleTypography>
            Password recovery
          </TitleTypography>
          <Typography
            sx={{
              color: ' #7084B4',
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            Enter your email address and we&apos;ll send a recovery link
          </Typography>
        </TitleTypographyWrapper>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          {
          status === EStateStatus.ERROR
          && error?.message
          && (
            <FormError message={error?.message} isVisible={status === EStateStatus.ERROR} />
          )
        }
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          />
          <AuthButton status={status} title="Reset" />
        </Box>
        <BackToLogin />
      </>
    </AuthPaper>
  );
};

export default ResetPassword;
