import { Box, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch } from '../../../hooks/stateHooks';
import SocialAuth from '../Login/SocialAuth';
import { resetAuthError } from '../state/authState';
import { TitleAdditionalTypography, TitleTypography, TitleTypographyWrapper } from './AuthStyledComponents';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';

const InactiveAccountSocialLogin = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetAuthError());
  }, []);
  return (
    <AuthPaper>
      <Box>
        <TitleTypographyWrapper
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '325px',
            flexDirection: 'column',
          }}
        >
          <TitleTypography>
            Account isn't active
          </TitleTypography>
          <TitleAdditionalTypography>
            Back to
            {' '}
            <Link
              onClick={() => navigate(`${EAppRoutes.auth}${EAppRoutes.login}`)}
            >
              Log in
            </Link>
          </TitleAdditionalTypography>
        </TitleTypographyWrapper>
        <Box
          sx={{
            maxWidth: '325px',
            marginTop: '20px',
          }}
        >
          <Typography>
            Please, choose one of the following options to activate your account:
          </Typography>
        </Box>
        <SocialAuth disableDivider />
      </Box>
    </AuthPaper>
  );
};
export default InactiveAccountSocialLogin;
