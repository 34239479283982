import {
  MenuProps, Menu, MenuItemProps, MenuItem, Typography, Theme, Box,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { CheckIcon } from '../../icons/MenuIcons';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const TiptapMenu = ({
  children,
  width,
  ...rest
}: MenuProps & {
  [key: string]: any,
  width?: '240px' | string,
}): JSX.Element => (
  <Menu
    {...rest}
    MenuListProps={{
      sx: {
        padding: '0px',
      },
    }}
    slotProps={{
      paper: {
        sx: {
          padding: '8px',
          borderRadius: '10px',
          width: width ?? '240px',
          border: '1px solid',
          borderColor: stagesetColors.newGrey[400],
          background: stagesetColors.white[100],
          ...isMobile ? {
            maxHeight: '380px',
            overflow: 'auto',
          } : {
            maxHeight: '618px',
            overflow: 'hidden',
          },
          // eslint-disable-next-line max-len
          boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
        },
      },
    }}
  >
    {children}
  </Menu>
);

export const TiptapMenuItem = (
  {
    icon,
    title,
    textColor,
    isActive,
    sx = {},
    ...rest
  }: MenuItemProps & {
    icon?: JSX.Element,
    title?: string,
    textColor?: string | ((theme: Theme) => string),
    isActive?: boolean,
  },
): JSX.Element => (
  <MenuItem
    {...rest}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      height: '40px',
      maxHeight: '40px',
      minHeight: '40px',
      padding: '9px 12px',
      borderRadius: '10px',
      ...sx,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
        '& path': {
          stroke: stagesetColors.newGrey[500],
        },
        '& rect': {
          stroke: stagesetColors.newGrey[500],
        },
      }}
    >
      {icon}
      <Typography
        sx={{
          color: stagesetColors.newGrey[800],
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
    </Box>
    {
      isActive
      && (
        <CheckIcon />
      )
    }
  </MenuItem>
);
