import {
  Box,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';
import { CreateDealFormWrapper } from '../Styles/StageStyles';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppButton } from '../../../shared/AppButton/AppButton';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';

type FormState = {
  title: string;
  lock: boolean;
};

export type Props = {
  onGoBack: (tab?: number) => void;
  onClose: () => void;
  onCreate: (state: FormState) => void;
};

export const CreateStageTab = ({ onGoBack, onClose, onCreate }: Props) => {
  const schema = yup.object({
    lock: yup.bool(),
    title: yup.string().required('Name is required'),
  }).required();

  const {
    handleSubmit,
    control,
  } = useForm<FormState>({
    defaultValues: {
      title: '',
      lock: false,
    },
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (data: FormState) => {
    onCreate(data);
    onClose();
  };

  return (
    <CreateDealFormWrapper
      className="mobile"
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '20px',
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
          >

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <Box sx={{
                display: 'flex',
              }}
              >
                <AppLinkButton
                  startIcon={<ChevronLeft />}
                  onClick={() => onGoBack(1)}
                  sx={{
                    width: 'auto !important',
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                  }}
                >
                  Add page
                </AppLinkButton>
              </Box>
            </Box>

            <Box sx={{
              display: 'flex',
            }}
            >
              <AppLinkButton
                onClick={onClose}
                sx={{
                  paddingRight: '0px !important',
                  minWidth: '100% !important',
                  width: '100% !important',
                }}
              >
                Cancel
              </AppLinkButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        >
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <AppInput
                label="Name"
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <Box>
              Lock
            </Box>
            <Box>
              <Controller
                name="lock"
                control={control}
                render={({ field: { onChange } }) => (
                  <AppSwitch onChange={onChange} />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <AppButton
        variant="primary"
        size="l"
        type="submit"
      >
        Add
      </AppButton>
    </CreateDealFormWrapper>
  );
};
