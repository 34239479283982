import { SvgIcon, SvgIconProps } from '@mui/material';

export const UpdateIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      fill="none"
      d="M0.875 3.375V8.375H5.875"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M19.125 16.625V11.625H14.125"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M17.125 7.50005C15.75 3.62505 11.375
      1.50005 7.5 2.87505C6.5 3.25005
      5.5 3.87505 4.75 4.75005L0.875 8.37505"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M19.125 11.625L15.25 15.25C12.375 18.125
      7.625 18.125 4.625 15.25C3.875
      14.5 3.25 13.5 2.875 12.5"
      strokeWidth="1.6"
      strokeMiterlimit="2.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
