import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { stagesetColors } from '../../theme/stagesetPalette';

export const BalloonPlaceholderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  '& svg': {
    width: '80px',
    height: '80px',
    '& path': {
      fill: stagesetColors.newGrey[500],
    },
  },
}));

export const BalloonPlaceholderDescription = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  color: stagesetColors.newGrey[500],
}));
