import * as React from 'react';
import { Container, CssBaseline, Typography } from '@mui/material';

export type ErrorPageProps = {
  error?: Error;
};

export const ErrorPage = (props: ErrorPageProps): JSX.Element => {
  const { error } = props;
  console.log(error);

  return (
    <Container sx={{ marginTop: '43vh' }}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontSize: '2em',
            fontWeight: 300,
            '& strong': {
              fontWeight: 400,
            },
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <strong>
            Error :(
            {JSON.stringify(error, null, 2)}
          </strong>
        </Typography>
      </Container>
    </Container>
  );
};
