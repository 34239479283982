import { AppButton } from '../../shared/AppButton/AppButton';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { NewPageFooterContainer } from './Styles/NewPageTabFooterStyles';
import { ChevronLeft20 } from '../../icons/ChevronLeft20';
import { useNewPageContext } from './NewPageContextProvider/NewPageContextProvider';
import { useAppSelector } from '../../hooks/stateHooks';
import { ENewPageTabs, selectNewPageTab } from '../Layout/lib/fieldsLayoutSlice';

const NewPageTabFooter = () => {
  const { methods, data } = useNewPageContext();
  const { goBack } = methods;
  const { disabled, stageCreation } = data;
  const currentNewPageTab = useAppSelector(selectNewPageTab);
  const isNewPageTabNotTemplate = currentNewPageTab !== ENewPageTabs.TEMPLATE;
  return (
    <NewPageFooterContainer>
      <AppIconButton
        disableRipple
        variant="secondary"
        size="l"
        icon={<ChevronLeft20 />}
        onClick={goBack}
      />
      {isNewPageTabNotTemplate && (
        <AppButton
          loading={stageCreation}
          type="submit"
          fullWidth
          disabled={disabled}
          disableRipple
          size="l"
          variant="primary"
        >
          Add page
        </AppButton>
      )}
    </NewPageFooterContainer>
  );
};

export default NewPageTabFooter;
