import {
  createContext, useContext, useEffect, useState,
} from 'react';
import {
  FieldBase, ProcessStageDTO, TUploadProcessItemFile,
} from '../../../shared/Field/Field';
import useProcessField from '../../hooks/useProcessField';
import { ECustomEventsNames } from '../../../shared/events/CustomEvents';

interface Props {
  id: string;
  children: JSX.Element;
}

export const useItemUpdate = (id: string) => {
  const onUpdate = (updateData: Partial<ProcessStageDTO>, files?:TUploadProcessItemFile[]) => {
    document.dispatchEvent(new CustomEvent(ECustomEventsNames.PROCESS_ITEM_UPDATE, {
      detail: {
        id,
        files,
        data: { ...updateData },
      },
    }));
  };
  return { onUpdate };
};

export const ItemContext = createContext<{
  data: ProcessStageDTO | null,
  onUpdate:((newData: Partial<ProcessStageDTO>, file?:TUploadProcessItemFile[]) => void) | null
}>(
    {
      data: null,
      onUpdate: null,
    });

export const ItemContextProvider = (
  {
    id,
    children,
  }: Props,
) => {
  const [context, setContext] = useState<any>(ItemContext);
  const {
    data,
  } = useProcessField<FieldBase>({ id });

  const { onUpdate } = useItemUpdate(id);

  useEffect(() => {
    if (data) {
      setContext({
        data,
        onUpdate: (newData?: { [key: string]: any }, files?:TUploadProcessItemFile[]) => {
          if (newData) {
            onUpdate(newData, files);
          }
        },
      });
    }
  }, [data, id]);

  return (
    <ItemContext.Provider value={context}>
      {children}
    </ItemContext.Provider>
  );
};

export const useItemContext = () => {
  const c = useContext(ItemContext);
  return c;
};
