import { CSSObject, styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  Avatar, Button, List, ListItem, Popover, SwipeableDrawer, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom';
import { stagesetColors } from '../../theme/stagesetPalette';

export const drawerWidth = isMobile ? '100%' : 286;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: isMobile ? 'calc(100% - 16px)' : drawerWidth,
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  height: isMobile ? 'calc(100% - 64px)' : undefined,
  margin: isMobile ? '8px' : undefined,
  marginBottom: '56px',
  boxShadow: isMobile ? '0 0 0 64px rgba(0, 0, 0, 0.4)' : 'none',
  zIndex: 100,
  borderRadius: '12px',
});

export const openedMixinMobileFullScreen = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  height: '100%',
  width: '100%',
  zIndex: 101,
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: isMobile ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const paperMixin = (open: boolean | undefined, theme: Theme): CSSObject => ({
  justifyContent: 'space-between',
  ...(open ? openedMixin(theme) : closedMixin(theme)),
});

export const paperMixinMobileFullScreen = (open: boolean | undefined, theme: Theme): CSSObject => ({
  justifyContent: 'space-between',
  ...(open ? openedMixinMobileFullScreen(theme) : closedMixin(theme)),
});

export const drawerMixin = (open: boolean | undefined, theme: Theme): CSSObject => ({
  ...(open ? openedMixin(theme) : closedMixin(theme)),
});

export const drawerMixinMobileFullScreen = (open: boolean | undefined, theme: Theme): CSSObject => ({
  ...(open ? openedMixinMobileFullScreen(theme) : closedMixin(theme)),
});

export const SideMenuDrawerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const SideMenuDrawer = styled(SwipeableDrawer)(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...drawerMixin(open, theme),
    '& .MuiDrawer-paper': {
      ...paperMixin(open, theme),
    },
    '& .MuiPaper-elevation': {
      border: 'none',
    },
  }),
);

export const SideMenu = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '56px',
  backgroundColor: stagesetColors.white[100],
  borderRight: `1px solid ${stagesetColors.newGrey[200]}`,
  justifyContent: 'space-between',
}));

export const SideMenuUpperSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '56px',
}));

export const MobileSideMenuDrawerFullScreen = styled(SwipeableDrawer)(
  ({ theme, open }) => ({
    width: '100%',
    boxSizing: 'border-box',
    ...drawerMixinMobileFullScreen(open, theme),
    '& .MuiDrawer-paper': {
      ...paperMixinMobileFullScreen(open, theme),
    },
  }),
);

export const LogoWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 12px',
  height: '56px',
  '&.logo-wrapper-mobile': {
    justifyContent: 'flex-start',
  },
}));

export const SideMenuContentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  '& .toolbar': {
    display: 'none',
    '&-mobile': {
      display: 'flex',
    },
  },
}));

export const SideMenuItemsList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  '&.side-menu-items-list-mobile': {
    gap: '0px',
    width: '100%',
  },
}));

export const SideMenuListItem = styled(ListItem)(() => ({
  '.list-item-nav-link': {
    textDecoration: 'none',
    width: '100%',
    '& button': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  '&.side-menu-list-item-mobile': {
    padding: '0px 8px',
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
  },
}));

export const StyledAvatar = styled(Avatar)(() => ({
  height: '32px',
  minHeight: '32px',
  width: '32px',
  minWidth: '32px',
  fontSize: '16px',
}));

export const BottomBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100px',
  width: '56px',
  gap: '4px',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SideMenuLogo = styled(Box)(() => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SideMenuTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '26px',
  color: stagesetColors.black[800],
}));

export const LogoNavLink = styled(NavLink)(() => ({
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));

export const UpgradeSubscriptionPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    marginLeft: '10px',
    width: '220px',
    borderRadius: '16px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    boxShadow: '0px 17px 16px -9px rgba(0, 57, 96, 0.20)',
    background: 'linear-gradient(139deg, #E5EDFF 10.78%, #D2E6FF 50.62%, #8FC3FF 104.52%)',
  },
}));

export const OwnerCardPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), '
    + '0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
    borderRadius: '14px',
    border: '1px solid',
    borderColor: stagesetColors.newGrey[200],
    padding: '8px',
  },
}));

export const LogOutButton = styled(Button)(() => ({
  display: 'flex',
  padding: '9px 16px',
  justifyContent: 'flex-start',
  gap: '12px',
  lineHeight: '22px',
  fontSize: '16px',
  fontWeight: 500,
  color: stagesetColors.red[600],
  '& svg': {
    fill: 'none',
    '& path': {
      stroke: stagesetColors.red[600],
    },
  },
  '&.log-out-button-mobile': {
    padding: '8px 20px',
  },
}));

export const ProfileSettingsButton = styled(Button)(() => ({
  display: 'flex',
  padding: '8px 10px',
  justifyContent: 'flex-start',
  gap: '12px',
  flexDirection: 'row',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: stagesetColors.newGrey[50],
  },
  '&.profile-settings-button-mobile': {
    padding: '12px 14px',
  },
  '&.profile-settings-button-subscription': {
    padding: '8px 14px 8px 20px',
    alignItems: 'flex-start',
    '& svg': {
      marginTop: '5px',
      '& path': {
        stroke: stagesetColors.newGrey[500],
      },
      '& circle': {
        stroke: stagesetColors.newGrey[500],
      },
    },
  },
}));

export const UserTextInfoWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
}));

export const SettingsButtonTitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[800],
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  textAlign: 'start',
}));

export const SettingsButtonSubtitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[400],
  fontSize: '12px',
  lineHeight: '14px',
  textAlign: 'start',
}));

export const RocketButtonPlaceholder = styled(Box)(() => ({
  height: '40px',
  width: '40px',
}));

export const FooterWrapper = styled(Box)(() => ({
  '&.side-menu-footer-wrapper-mobile': {
    padding: '8px 0px',
    borderTop: `1px solid ${stagesetColors.newGrey[200]}`,
  },
}));

export const UpgradePopoverTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 600,
  marginBottom: '6px',
}));

export const UpgradePopoverSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
}));
