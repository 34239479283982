import { EFieldsubType } from '../Field/Field';
import { ContentType } from '../Stage';

export type FileItem = {
  name: string;
  url: string;
  size: number;
  status: UploadItemStatus;
  subType?: EFieldsubType;
  linkType?: ContentType;
};

export type FileEventData = Omit<FileItem, 'status'> & { docId: string }

export type FielItemsRecord = {
  [key: string]: FileItem;
};

export type EntityFilesRecord = {
  [key: string]: FielItemsRecord;
}

export type TFileItemUpload = {
  entityId: string;
  file: File;
  fileId: string;
  fileType: string;
  fieldId?: string;
  userId?: string;
  organizationId?: string;
  uploaderOrganization?: string;
  processId?: string;
} & FileItem;

export enum UploadItemStatus {
  UPLOADED,
  UPLOAD_FAILED,
  IN_PROGRESS,
}
