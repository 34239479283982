import { Box, Typography, styled } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const CelloBannerContainer = styled(Box)(() => ({
  display: 'flex',
  height: 'auto',
  maxHeight: '152px',
  flexDirection: 'row',
  borderRadius: '24px',
  width: '100%',
  minWidth: '400px',
  maxWidth: '1080px',
  background: 'linear-gradient(143deg, #E5EDFF 40.08%, #C2DEFF 89.91%)',
  justifyContent: 'space-between',
  overflow: 'hidden',
  '&.cello-banner-container-mobile': {
    height: '343px',
    maxHeight: '343px',
    flexDirection: 'column',
    minWidth: '300px',
  },
}));

export const CelloBannerInfoBox = styled(Box)(() => ({
  padding: '24px 0px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  '&.cello-banner-info-box-mobile': {
    padding: '24px 24px 24px 24px',
  },
}));

export const CelloBannerTextBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  gap: '8px',
  '&.cello-banner-text-box-mobile': {
    width: '100%',
  },
}));

export const CelloBannerTitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[800],
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '26px',
}));

export const CelloBannerSubtitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[500],
  fontSize: '16px',
  lineHeight: '22px',
}));

export const CelloBannerImageContainer = styled(Box)(() => ({
  maxWidth: '350px',
  height: 'calc( 100% )',
  display: 'flex',
  aspectRatio: 1388 / 608,
  '&.cello-image-container-mobile': {
    width: '100%',
    objectFit: 'cover',
    maxWidth: '100%',
  },
}));

export const CelloBackgroundImage = styled('img')(() => ({
  height: '100%',
  width: undefined,
  '&.cello-background-image-mobile': {
    height: undefined,
    width: '100%',
  },
}));
