import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import TemplateList from '../TemplateList';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';

export type Props = {
  onTemplateSelect: (id: string, title: string) => void;
  onGoBack: () => void;
};

export const TemplateListTab = ({ onTemplateSelect, onGoBack }: Props) => {
  const [] = useState();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: '16px 16px 8px 16px',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginBottom: '20px',
      }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <Typography
            sx={{
              fontFamily: 'Manrope',
              fontSize: '14px',
              fontWeight: 600,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            New room
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
        }}
        >
          <AppLinkButton
            onClick={onGoBack}
            sx={{
              paddingRight: '0px !important',
              minWidth: '100% !important',
              width: '100% !important',
            }}
          >
            Cancel
          </AppLinkButton>
        </Box>
      </Box>
      <Box sx={{
        height: '100%',
        overflow: 'overlay',
      }}
      >
        <TemplateList onTemplateSelect={onTemplateSelect} onTemplateUse={() => {}} />
      </Box>
    </Box>
  );
};
