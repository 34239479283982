import { ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const FeedListItem = styled(ListItem)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '100%',
  padding: '12px 24px',
  '& .MuiListItemButton-root': {
    borderRadius: '5px',
  },
  '& .MuiListItemSecondaryAction-root': {
    bottom: '0px',
  },
  '& .deal-user-list-remove-icon': {
    display: 'block',
  },
  // '&:hover': {
  //   '& .deal-user-list-remove-icon': {
  //     display: 'block',
  //   },
  // },
}));

export default FeedListItem;
