import { TabPanel, TabPanelProps } from '@mui/lab';
import { Box } from '@mui/material';
import PricingTableItemDetailsTab from './PricingTableItemDetailsTab';
import { stagesetColors } from '../../../theme/stagesetPalette';
import {
  selectState,
  EPricingTableEditState,
  setProduct,
  selectDrawerTabState,
  EProductTabState,
  setTabState,
} from '../../PricingTable/lib/pricingTableSlice';
import ProductListTab from './ProductListTab';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import useScreenDimensions from '../../../hooks/useScreenDimensions';
import { DEFAULT_PRICING_ITEM } from '../../../../shared/pricing-table/PricingTableTypes';
import { AppTabs } from '../../../shared/AppTabs/AppTabs';

interface Props extends TabPanelProps {

}

const SidebarPricingTableItemDetailsTab = ({
  value,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const paramsState = useAppSelector((state) => selectState(state));
  const drawerTabState = useAppSelector(selectDrawerTabState);
  const [height] = useScreenDimensions(true);

  const handleStateChange = (
    newState: EProductTabState,
  ) => {
    if (newState === EProductTabState.BLANK) {
      dispatch(setProduct({
        product: DEFAULT_PRICING_ITEM,
        tabState: EProductTabState.BLANK,
      }));
    }
    dispatch(setTabState(newState));
  };

  return (
    <TabPanel
      value={value}
      id="pricing-table-side-panel"
      sx={{
        padding: '0px',
        // display: tab === ESidebarTabs.FILTERS ? 'flex' : 'none',
        flexDirection: 'column',
        height: `calc(${height - 48}px)`,
        justifyContent: 'space-between',
        paddingTop: '16px',
        backgroundColor: stagesetColors.white[100],
        width: '100%',
      }}
    >
      {(paramsState === EPricingTableEditState.ADDING && drawerTabState !== EProductTabState.ADD_PRODUCT_FROM_LIST) && (
        <Box
          sx={{
            margin: '0 20px',
          }}
        >
          <AppTabs
            color="white"
            variant="boxedNew"
            size="l-new"
            value={drawerTabState}
            onChange={(option) => {
              handleStateChange(option.value);
            }}
            options={[
              {
                title: 'Blank', value: EProductTabState.BLANK,
              },
              {
                title: 'Library', value: EProductTabState.PRODUCT_LIST,
              },
            ]}
          />
        </Box>
      )}
      {drawerTabState === EProductTabState.PRODUCT_LIST
        ? (
          <ProductListTab />
        )
        : (
          <PricingTableItemDetailsTab />
        )}
    </TabPanel>
  );
};

export default SidebarPricingTableItemDetailsTab;
