import { DealsType } from '../../../../shared/process/Process';

export function sessionToDealType(sessionDealtType: DealsType | undefined): DealsType {
  const dealType: DealsType = sessionDealtType || DealsType.SELLER_DEALS;

  if (dealType === DealsType.TEAM_DEALS) {
    sessionStorage.setItem('dealGridToRender', DealsType.SELLER_DEALS);
    return DealsType.SELLER_DEALS;
  }

  return dealType;
}
