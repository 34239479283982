import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DragEndEvent, DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import classNames from 'classnames';
import { ESidebarTabs, selectSidebarTab } from '../Layout/lib/fieldsLayoutSlice';
import StagesSidebarFooter from './StagesSidebarFooter';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus, selectProcessFields } from '../../routes-old/process/state/processSlice';
import { selectCreateDealDialogStatus } from '../../routes-old/deals/state/dealsSlice';
import { EAppRoutes } from '../../core/router';
import { useSIdebarStageDnD } from '../../hooks/useSIdebarStageDnD';
import { useStages } from '../../hooks/useStages';
import StagesSidebarItemWrapper from './StagesSidebarItemWrapper';
import { SidebarStagesPagesContainer } from './Styles/SidebarStagesTabStyles';
import StagesSidebarHeader from './StagesSidebarHeader';
import { SidebarTabPanel } from './Styles/SidebarStyles';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import useDealPermissions from '../../hooks/useDealPermissions';
import { NewPageButton } from '../NewPageAdding/NewPageButton';

interface Props {
  isPreview?: boolean,
}

const SidebarStagesTab = ({
  isPreview: preview,
}: Props): JSX.Element => {
  const location = useLocation();
  const processStages = useAppSelector(selectProcessFields);
  const [localStages, setLocalStages] = useState<string[]>(processStages?.map((stage) => stage.id) ?? []);
  const { swapPositions } = useStages();
  const [containerHeight, setContainerHeight] = useState<string>('');
  const isDealCreate = useAppSelector(selectCreateDealDialogStatus);
  const currentTab = useAppSelector(selectSidebarTab) === ESidebarTabs.STAGES;
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const [checkPermissions] = useDealPermissions();
  const isLayoutEditable = checkPermissions(
    EUserDealPermissions.DEAL_LAYOUT_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const isPreview = preview || isDealCreate || location.pathname === EAppRoutes.templates;

  const {
    handleDragStart,
    handleDragEnd,
    sensors,
  } = useSIdebarStageDnD();

  useEffect(() => {
    if (processStages) {
      setLocalStages(processStages.map((stage) => stage.id));
    }
  }, [processStages]);

  const updateOrder = async (event: DragEndEvent) => {
    const reorderElements = handleDragEnd(event);
    if (!reorderElements) return;
    const { current, target } = reorderElements;
    const swappedStages = await swapPositions(current, target);
    setLocalStages(swappedStages.map((stage) => stage.id));
  };

  const stagesToRender = useMemo(() => localStages?.map((stage) => (
    <StagesSidebarItemWrapper
      key={`stage-sidebar-item-${stage}`}
      id={stage}
    />
  )), [localStages, isLayoutEditable]);

  return (
    <SidebarTabPanel
      className={classNames('sidebar-themed', { 'active-sidebar-tab': currentTab })}
      value={ESidebarTabs.STAGES}
    >
      <StagesSidebarHeader />
      <SidebarStagesPagesContainer
        sx={{
          height: containerHeight,
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={updateOrder}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={localStages}
            strategy={verticalListSortingStrategy}
          >
            {stagesToRender}
          </SortableContext>
        </DndContext>
        {!isPreview && isLayoutEditable && isLayoutEdit && <NewPageButton variant="initial" />}
      </SidebarStagesPagesContainer>
      <StagesSidebarFooter setContainerHeight={setContainerHeight} />
    </SidebarTabPanel>
  );
};

export default SidebarStagesTab;
