import {
  Box, Collapse, SvgIcon,
} from '@mui/material';
import {
  memo, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { alpha } from '@mui/material/styles';
import { useStages } from '../../hooks/useStages';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import BasciStageIcon from '../../icons/StageMenu/BasciStageIcon.svg';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import Lock from '../../icons/StageMenu/Lock.svg';
import { MobileTabs } from './StageOptionsMobileTabs/MobileTabs';
import useFieldsLayoutSlice from '../../hooks/useFieldsLayoutSlice';
import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';
import StagesSidebarItemTitle from './StagesSidebarItemTitle';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions } from '../../../shared/permissions';
import CollectTemplateInfoDialog from '../CollectTemplateInfoDialog/CollectTemplateInfoDialog';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { StageContentType, WideScreenSectionType } from '../../../shared/Stage';
import Sign20 from '../../icons/Sign20.svg';
import CompassIcon from '../../icons/AddSectionIcons/Compass.svg';
import MediaIcon from '../../icons/AddSectionIcons/Media24.svg';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';
import StageSidebarEmbedMenu from './StageSidebarEmbedMenu';
import { MoreVertical20 } from '../../icons/MoreVertical';
import StagesSidebarBookmarks from './StagesSidebarBookmarks';
import { StrikedEyeIcon20 } from '../../icons/StrikedEyeIcon';

interface Props {
  id: string;
}

// eslint-disable-next-line react/display-name
const StagesSidebarItem = memo(({
  id,
}: Props): JSX.Element | null => {
  const isLayoutEditable = useAppSelector(selectLayouEditingStatus);
  const {
    defaultMenuItems,
    createNewStageMenuItems,
    onStageSelect,
    stage,
    onStageDelete,
    menuVariant,
    setMenuVariant,
    templatesMenuItems,
    anchor,
    deleteDialog,
    setDeleteDialog,
    setAnchor,
    textFieldRef,
    setDisabled,
    disabled,
    handleSave,
    isActiveStage,
    stageCreation,
    onCreateTemplate,
    collectDialog,
    setCollectDialog,
  } = useStages(id);
  const { createStageSelectedEvent } = useFrontEventsApi();
  const [mobileMenu, setMobileMenu] = useState(false);
  const isWideScreenStage = stage?.stageContentType === StageContentType.WIDESCREENSTAGE;
  const isWideScreenEmbed = stage?.wideScreenSectionType === WideScreenSectionType.EMBED;
  const isWideScreenMedia = stage?.wideScreenSectionType === WideScreenSectionType.MEDIA;
  const isProposal = stage?.stageContentType === StageContentType.ESIGNATURE;

  const {
    onDrawerStatusUpdate,
  } = useFieldsLayoutSlice();
  const menuItems = useMemo(() => {
    switch (menuVariant) {
      case 'default':
        return defaultMenuItems;
      case 'createNewStage':
        return createNewStageMenuItems;
      case 'slides':
        return createNewStageMenuItems;
      default:
        return templatesMenuItems;
    }
  }, [menuVariant, defaultMenuItems, createNewStageMenuItems, templatesMenuItems, textFieldRef, setAnchor]);

  const [checkPermissions] = useDealPermissions();

  const [open, setOpen] = useState(false);

  const isLayoutEditPermitted = checkPermissions(EUserDealPermissions.DEAL_LAYOUT_EDIT);

  useEffect(() => {
    if (isActiveStage) {
      setOpen(true);
    }
    return () => {
      if (isActiveStage) {
        setOpen(false);
      }
    };
  }, [isActiveStage]);

  if (!stage) {
    return null;
  }

  return (
    <>
      <Box
        className={classNames('StagesSidebarItem', {
          'StagesSidebarItem-selected': isActiveStage,
          'StagesSidebarItem-locked': stage?.lock,
        })}
        sx={{
          overflow: 'hidden',
        }}
        onClick={() => {
          if (isActiveStage && !isWideScreenStage && !isProposal) {
            setOpen(!open);
          } else {
            onStageSelect();
            createStageSelectedEvent({ stageId: stage.id, wideScreenSectionType: stage.wideScreenSectionType });
            if (isMobile) {
              onDrawerStatusUpdate(false);
            }
          }
        }}
      >
        <Box
          className="StagesSidebarItemHeader"
          sx={{
            display: 'flex',
            fontSize: '16px',
            lineHeight: '24px',
            minHeight: '52px',
            fontWeight: 500,
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            background: (theme) => (open
              ? alpha(theme.dealOverrides.sidebar.contrastText, 0.1)
              : ''),
            '&:hover': {
              background: (theme) => alpha(theme.dealOverrides.sidebar.contrastText, 0.1),
              '& .StageOptionsBtn': {
                visibility: 'visible',
              },
            },
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          >
            <Box
              className={
                classNames(
                  'StagesSidebarItemExpanded',
                  // {
                  // 'StagesSidebarItemExpanded-active':
                  // open && !stage?.lock
                  // }
                )
              }
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
                height: '52px',
                paddingLeft: '24px',
                // '&.StagesSidebarItemExpanded-active div:not(.static-icon) svg': {
                //   transform: 'scale(1, -1)',
                // },
                // '&:hover': {
                //   '& .ExpandedStageIcon': {

                //   },
                // },
                '& .static-icon': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '25px',
                  height: '25px',
                },
                '& svg': {
                  width: '20px',
                  height: '20px',
                },
              }}
            >
              {
              !stage?.lock && !stage?.hide && !isWideScreenStage && !isProposal
                && (
                <Box sx={{
                  borderRadius: '8px',
                  height: '25px',
                  width: '25px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '25px',
                  '&:hover': {
                    background: (theme) => alpha(theme.dealOverrides.sidebar.contrastText, 0.1),
                  },
                }}
                >
                  <SvgIcon
                    className="ExpandedStageIcon"
                    component={BasciStageIcon}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    sx={{
                      fill: 'none',
                      transition: 'transform 0.2s',
                      stroke: (theme) => (stage?.lock
                        ? greySidebarContrastText(theme, 0.3)
                        : greySidebarContrastText(theme, 0.8)),
                      '& path': {
                        stroke: 'inherit',
                      },
                    }}
                  />
                </Box>
                )
            }
              {
              !stage?.lock && !stage?.hide && isWideScreenStage && isWideScreenEmbed
                && (
                <Box
                  className="static-icon"
                  sx={{
                    borderRadius: '8px',
                    height: '25px',
                    width: '25px',
                  }}
                >
                  <SvgIcon
                    component={CompassIcon}
                    sx={{
                      fill: 'none',
                      stroke: (theme) => greySidebarContrastText(theme, 0.8),
                      '& path': {
                        stroke: 'inherit',
                      },
                    }}
                  />
                </Box>
                )
            }
              {
              !stage?.lock && !stage?.hide && isWideScreenStage && isWideScreenMedia
                && (
                <Box
                  className="static-icon"
                  sx={{
                    borderRadius: '8px',
                    height: '25px',
                    width: '25px',
                  }}
                >
                  <SvgIcon
                    component={MediaIcon}
                    sx={{
                      fill: 'none',
                      stroke: (theme) => greySidebarContrastText(theme, 0.8),
                      '& path': {
                        stroke: 'inherit',
                      },
                    }}
                  />
                </Box>
                )
            }
              {
              !stage?.lock && !stage?.hide && isProposal
                && (
                <Box
                  className="static-icon"
                  sx={{
                    borderRadius: '8px',
                    height: '25px',
                    width: '25px',
                  }}
                >
                  <SvgIcon
                    component={Sign20}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    sx={{
                      fill: 'none',
                      stroke: (theme) => greySidebarContrastText(theme, 0.8),
                      '& path': {
                        stroke: 'inherit',
                      },
                    }}
                  />
                </Box>
                )
            }
              {
              stage?.lock && !stage?.hide
                && (
                  <SvgIcon
                    component={Lock}
                    className="static-icon"
                    sx={{
                      width: '25px',
                      height: '25px',
                      color: 'transparent',
                      fontSize: '20px',
                      position: 'relative',
                      left: '2px',
                      stroke: (theme) => (!isLayoutEditPermitted
                        ? greySidebarContrastText(theme, 0.3)
                        : greySidebarContrastText(theme, 0.8)),
                      '& path': {
                        stroke: 'inherit',
                      },
                    }}
                  />
                )
              }
              {
              stage?.hide && !stage?.lock
                && (
                  <Box
                    className="static-icon"
                  >
                    <StrikedEyeIcon20
                      sx={{
                        stroke: (theme) => greySidebarContrastText(theme, 0.8),
                      }}
                    />
                  </Box>
                )
            }
            </Box>
            <StagesSidebarItemTitle
              title={stage?.title}
              disabled={(stage?.lock && !isLayoutEditPermitted) || disabled}
              lock={(stage?.lock && !isLayoutEditPermitted)}
              setDisabled={setDisabled}
              textFieldRef={textFieldRef}
              handleSave={handleSave}
            />
          </Box>
          <Box
            sx={{
              width: '40px',
              height: '52px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: '8px',
            }}
          >
            {
            isLayoutEditable
            && (
            <AppIconButton
              className="StageOptionsBtn"
              icon={<MoreVertical20 />}
              disabled={!isLayoutEditable}
              onClick={(e) => {
                e.stopPropagation();
                if (isMobile) {
                  setMobileMenu(true);
                  return;
                }
                setAnchor(e.currentTarget);
              }}
              sx={{
                visibility: isMobile ? 'visible' : 'hidden',
                stroke: (theme) => (stage?.lock && !isLayoutEditPermitted
                  ? `${greySidebarContrastText(theme, 0.3)} !important`
                  : `${greySidebarContrastText(theme, 0.8)} !important`),
                '&:hover': {
                  background: (theme) => `${alpha(theme.dealOverrides.sidebar.contrastText, 0.1)} !important`,
                  '& svg': {
                    stroke: (theme) => (stage?.lock && !isLayoutEditPermitted
                      ? `${greySidebarContrastText(theme, 0.3)} !important`
                      : `${greySidebarContrastText(theme, 0.8)} !important`),
                  },
                },
              }}
            />
            )
}
          </Box>
        </Box>
        <Collapse
          in={
              open
              && !isWideScreenStage
              && !isProposal
            }
          sx={{
            '& .MuiCollapse-wrapperInner': {
              overflow: 'hidden',
            },
          }}
        >
          <StagesSidebarBookmarks />
          <Box
            sx={{
              paddingBottom: '8px',
            }}
          />
        </Collapse>
      </Box>
      {
        stageCreation
        && (
        <Box
          sx={{
            height: '66px',
            width: '100%',
            paddingTop: '12px',
          }}
        >
          <AppSkeleton
            animation="wave"
            variant="rectangular"
            width="calc( 100% )"
            height="calc( 100% )"
            sx={{
              border: '1px solid #EEF0FA',
              background: '#F9FBFF',
            }}
          />
        </Box>
        )
      }
      {
        isMobile
        && isLayoutEditPermitted
        && mobileMenu
        && (
          <MobileTabs
            open={mobileMenu}
            setOpen={setMobileMenu}
            id={id}
            onDelete={() => setDeleteDialog(true)}
            onEdit={() => {
              setMobileMenu(false);
              setDisabled(false);
              setTimeout(() => {
                textFieldRef.current?.focus();
              }, 100);
            }}
          />
        )
      }
      {
        isLayoutEditPermitted
        && (
          <>
            <AppDropdown
              items={menuItems}
              anchorEl={anchor}
              open={!!anchor}
              lastElementHasAnotherColor={menuVariant === 'default'}
              onClose={() => {
                setAnchor(null);
                setMenuVariant('default');
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
            <ConfirmationDialog
              icon={<DeleTeIcon />}
              titleVariant="danger-primary"
              isDialogOpen={deleteDialog}
              onConfirm={() => onStageDelete()}
              onCancel={() => setDeleteDialog(false)}
              dialogContentText={(
                <Box>
                  <span>
                    Are you sure you want delete stage
                  </span>
                  <b>
                    {' '}
                    &quot;
                    {stage?.title}
                    &quot;
                  </b>
                  <span>
                    ?
                  </span>
                </Box>
          )}
              dialogTitle="Delete stage?"
              confirmText="Delete"
            />
            <CollectTemplateInfoDialog
              open={collectDialog}
              setOpen={setCollectDialog}
              onSubmit={(data) => onCreateTemplate(data.name)}
              type="page"
            />
          </>
        )
      }
      {
        !isMobile
        && anchor
        && (
          <StageSidebarEmbedMenu
            anchor={anchor}
            menuVariant={menuVariant}
            setAnchor={setAnchor}
            setMenuVariant={setMenuVariant}
          />
        )
}
    </>
  );
});

export default StagesSidebarItem;
