import { SvgIcon } from '@mui/material';

const SquaredLogoPlaceholderIcon = (): JSX.Element => (
  <SvgIcon
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: 'unset',
      height: 'unset',
    }}
  >
    <path
      d="M0.219071 14.3101C4.28379 13.0556 8.63927 13.1483 12.425 14.3101C16.3194 15.5051 19.6129 17.7596 21.5171
    15.6203C21.6501 15.864 21.7566 16.1024 21.8382 16.3356C21.8405 16.3791 21.8429 16.4226 21.8453 16.4661C21.881
    17.1142 21.9167 17.7639 21.7794 18.3901C21.6347 19.0499 21.2638 19.6636 20.684 20.1044C17.9854 22.1559 14.7066
    23.3004 11.3175 23.3739C7.92842 23.4474 4.60307 22.4461 1.81804 20.5135C1.43145 20.2453 1.12885 19.9011 0.916395
    19.515C0.627687 18.9904 0.431672 18.4048 0.344966 17.8123C0.174346 16.6464 0.133552 15.4721 0.219071 14.3101Z"
      fill="#BECDEA"
    />
    <path
      d="M14.6613 2.15494C14.4405 6.91542 11.0355 10.5476 8.08437 13.6957C5.33011 16.6337 2.97113 19.1501 3.96472
    21.7685C3.20221 21.4022 2.46637 20.9745 1.76482 20.4876C1.37823 20.2194 1.07563 19.8752 0.863172 19.4892C0.574464
    18.9646 0.378449 18.3789 0.291744 17.7865C-0.1991 14.4323 0.384508 11.0089 1.95892 8.00678C3.53333 5.00471 6.01773
    2.57812 9.05603 1.07479C9.09899 1.05354 9.14222 1.03343 9.18568 1.01445C9.87247 0.714504 10.6488 0.580463 11.3963
    0.633355C11.7414 0.657767 12.0879 0.743753 12.4207 0.896963C13.2037 1.25746 13.9526 1.67863 14.6613 2.15494Z"
      fill="#7084B4"
    />
    <path
      d="M18.74 21.3722C14.7788 18.7908 13.1923 13.9115 11.9486 9.80485C10.7679 5.90607 10.5482 2.3478 7.71045
    1.96083C7.73064 1.922 7.75108 1.8838 7.77178 1.84621C8.20415 1.57825 8.65037 1.33015 9.10923 1.10312C9.15219
    1.08186 9.19542 1.06175 9.23888 1.04277C9.92567 0.742824 10.702 0.608783 11.4495 0.661675C11.7946 0.686087 12.1411
    0.772074 12.4739 0.925283C15.5531 2.34292 18.1045 4.69898 19.7624 7.65578C21.1213 10.0795 21.8228 12.8049 21.813
    15.5631C21.8119 15.8628 21.8286 16.1655 21.8453 16.4685C21.8809 17.1166 21.9167 17.7663 21.7794 18.3926C21.6347
    19.0523 21.2638 19.666 20.684 20.1068C20.2213 20.4946 19.2067 21.1215 18.74 21.3722Z"
      fill="#A3B0D1"
    />
    <circle cx="11.0005" cy="12.4778" r="4.50148" fill="#F5F9FF" />
  </SvgIcon>
);

export default SquaredLogoPlaceholderIcon;
