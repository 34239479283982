import { CustomDomainStatus } from "../../shared/domains/CustomDomainStatus";
import { ApiService } from "../core/api";

export class CustomDomainApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('custom-domain');
  }

  getCustomDomainStatus(customDomain: string, organizationId: string): Promise<CustomDomainStatus> {
    return this.api.get<CustomDomainStatus>(`/status/${organizationId}/${customDomain}`);
  }

  activateCustomDomain(payload: Record<string, unknown>): Promise<unknown> {
    return this.api.post(`/activate`, payload);
  }
}
