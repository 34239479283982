import {
  EModalWindowStates,
  IModalWindowState,
  TModalWindowStates,
  getModalWindowState,
  resetModalWindowState,
  setModalWindowState,
  updateModalWindowState,
} from '../features/AppLoadingModalWindow/lib/appModalWindowSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';

const useModalWindow = () => {
  const dispatch = useAppDispatch();
  const { open, text, subText } = useAppSelector(getModalWindowState);

  const onModalWindowStateSet = (data: IModalWindowState | TModalWindowStates) => {
    dispatch(setModalWindowState(data));
  };

  const onModalWindowStateUpdate = (data: Partial<IModalWindowState>) => {
    dispatch(updateModalWindowState(data));
  };

  const onModalWindowStateReset = () => {
    dispatch(resetModalWindowState());
  };

  const setTemplateModalWindowState = () => {
    dispatch(setModalWindowState(EModalWindowStates.TEMPLATE));
  };

  const setProcessCopyModalWindowState = () => {
    dispatch(setModalWindowState(EModalWindowStates.PROCESS));
  };

  return {
    open,
    text,
    subText,
    onModalWindowStateSet,
    onModalWindowStateUpdate,
    onModalWindowStateReset,
    setTemplateModalWindowState,
    setProcessCopyModalWindowState,
  };
};

export default useModalWindow;
