import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import PagePresentationNoPermissions from '../../common/PagePresentation/PagePresentationNoPermissions';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { SubscriptionGrid } from '../../features/Subscriptions/SubscriptionGrid';
import SubscriptionStatsSection from '../../features/Subscriptions/SubscriptionStatsSection';
import AppTopBar from '../../shared/AppTopBar/AppTopBar';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  organizationGetSubscription, selectTourStatus, selectUser, updateUserData,
} from '../../core/store/appState/appState';
import {
  billingGetNextInvoice,
  getStagesetPlans,
  getStagesetPrices,
} from './state/billingSlice';
import SubscriptionIntervalSelect from '../../features/Subscriptions/SubscriptionIntervalSelect';
import CelloBanner from '../../shared/CelloBanner/CelloBanner';
import { useTranslation } from "react-i18next";

const Billing = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const shouldSeeBilling = useAppSelector((state) => selectTourStatus(state, 'shouldSeeBilling'));

  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const [sessionId, setSessionId] = useState('');

  const isPageAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);

  useEffect(() => {
    dispatch(getStagesetPlans());
    dispatch(getStagesetPrices());
    dispatch(billingGetNextInvoice());
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      dispatch(organizationGetSubscription());
      setSessionId(query.get('session_id'));
    }
  }, [sessionId]);

  useEffect(() => {
    if (shouldSeeBilling) {
      dispatch(updateUserData({
        ...user,
        tourStatus: {
          ...user.tourStatus,
          shouldSeeBilling: false,
        },
      }));
    }
  }, [shouldSeeBilling]);

  return (
    <>
      <AppTopBar title={t('Subscriptions_Subscription')} />
      {isPageAvailable && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'overlay',
            width: '100%',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isMobile ? '24px 16px' : '0px 24px 0px 24px',
            overflow: 'overlay',
            maxHeight: '100%',
            width: '100%',
            height: '100%',
          }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '1000px',
                gap: '24px',
                padding: '24px 0px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <SubscriptionStatsSection />
              <CelloBanner />
              <SubscriptionIntervalSelect />
              <SubscriptionGrid />
            </Box>
          </Box>
        </Box>
      )}
      {!isPageAvailable && (
        <PagePresentationNoPermissions
          title="No Access"
          description="You do not have access to this page"
        />
      )}
    </>
  );
};

export default Billing;
