import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import useProcessTimelineDateFilter from '../../hooks/useProcessTimelineDateFilter';
import { CalendarIcon20px } from '../../icons/Calendar';
import {
  ProcessTimelineMenuItemContainer,
  ProcessTimelineMenuItemHeader,
  ProcessTimelineMenuItemNotFoundText,
  ProcessTimelineMenuItemTitle,
} from './Styles/ProcessTimelineStyles';
import { StyledPickersLayout } from '../../routes-old/process/DealFeed/Agenda/styles/AgendaStyles';
import { ChevronLeft20 } from '../../icons/ChevronLeft20';
import { ChevronRight20 } from '../../icons/ChevronRight';
import { ChevronDown20 } from '../../icons/ChevronDown';
import { AppInput } from '../../shared/AppInput/AppInput';
import { AppButton } from '../../shared/AppButton/AppButton';

const ProcessTimelineDateFilter = () => {
  const {
    timelineStart, timelineEnd, setStartDate, setEndDate,
  } = useProcessTimelineDateFilter();

  const isTimelineStartAndEndDefault = DateTime
    .fromMillis(timelineStart)
    .toFormat('dd-MM-yyyy')
    === DateTime
      .fromMillis(new Date(new Date()
        .setHours(0, 0, 0, 0))
        .setDate(new Date()
          .getDate() - 30))
      .toFormat('dd-MM-yyyy')
    && DateTime
      .fromMillis(timelineEnd)
      .toFormat('dd-MM-yyyy')
    === DateTime
      .fromMillis(new Date()
        .setHours(23, 59, 59, 999))
      .toFormat('dd-MM-yyyy');

  const changeDate = (newDate: DateTime<true> | DateTime<false>, date: 'start' | 'end') => {
    if (!newDate) return;
    if (newDate.invalid === 'Invalid') return;
    if (newDate.invalid?.reason === 'invalid input' || newDate.invalid?.reason === 'unparsable') return;
    const millis = newDate.toMillis();

    if (millis < 0) return;

    switch (date) {
      case 'start':
        setStartDate(millis);
        break;
      case 'end':
        setEndDate(millis);
        break;
      default:
        break;
    }
  };

  return (
    <ProcessTimelineMenuItemContainer>
      <ProcessTimelineMenuItemHeader>
        <ProcessTimelineMenuItemTitle>
          Date
        </ProcessTimelineMenuItemTitle>
        {!isTimelineStartAndEndDefault && (
          <AppButton
            variant="lite-danger"
            size="s"
            onClick={(e) => {
              e.stopPropagation();
              setStartDate(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 30));
              setEndDate(new Date().setHours(23, 59, 59, 999));
            }}
          >
            Reset
          </AppButton>
        )}
      </ProcessTimelineMenuItemHeader>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <DatePicker
          disableFuture
          label="Start"
          onChange={(date) => changeDate(date, 'start')}
          value={timelineStart ? DateTime.fromMillis(timelineStart) : timelineStart}
          slots={{
            leftArrowIcon: ChevronLeft20,
            rightArrowIcon: ChevronRight20,
            switchViewIcon: ChevronDown20,
            layout: StyledPickersLayout,
            textField: AppInput,
            openPickerIcon: CalendarIcon20px,
          }}
          slotProps={{
            switchViewButton: {
              disableRipple: true,
            },
            previousIconButton: {
              disableRipple: true,
            },
            nextIconButton: {
              disableRipple: true,
            },
            openPickerButton: {
              disableRipple: true,
            },
            desktopPaper: {
              sx: {
                borderRadius: '12px',
              },
            },
          }}
        />
        <ProcessTimelineMenuItemNotFoundText>
          —
        </ProcessTimelineMenuItemNotFoundText>
        <DatePicker
          disableFuture
          label="End"
          onChange={(date) => changeDate(date, 'end')}
          value={timelineEnd ? DateTime.fromMillis(timelineEnd) : timelineEnd}
          slots={{
            leftArrowIcon: ChevronLeft20,
            rightArrowIcon: ChevronRight20,
            switchViewIcon: ChevronDown20,
            layout: StyledPickersLayout,
            textField: AppInput,
            openPickerIcon: CalendarIcon20px,
          }}
          slotProps={{
            switchViewButton: {
              disableRipple: true,
            },
            previousIconButton: {
              disableRipple: true,
            },
            nextIconButton: {
              disableRipple: true,
            },
            openPickerIcon: {
              disableRipple: true,
            },
            desktopPaper: {
              sx: {
                borderRadius: '12px',
              },
            },
          }}
        />
      </Box>
    </ProcessTimelineMenuItemContainer>
  );
};

export default ProcessTimelineDateFilter;
