import {
  EntityState, PayloadAction, createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import {
  LibraryFilesFilterCategory,
  LibraryFilesFilterInfo,
  LibraryFilesFilterParams,
  LibraryFilesFilterSet,
  LibraryFilterSetDTO,
} from '../../../../shared/library/LibraryFilter';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import { LibraryApi } from '../../../api/LibraryApi';
import { LibraryFilterApi } from '../../../api/LibraryFilterApi';

export enum ELibraryFilesFilterActions {
  GET_FILTER_INFO = 'GET_FILTER_INFO',
  GET_ALL_FILES_FILTERS = 'GET_ALL_FILES_FILTERS',
  SAVE_NEW_FILTER = 'SAVE_NEW_FILTER',
  UPDATE_FILTER = 'UPDATE_FILTER',
  DELETE_FILTER = 'DELETE_FILTER',
}

const libraryApi: LibraryApi = new LibraryApi();
const libraryFilterApi: LibraryFilterApi = new LibraryFilterApi();

const filtersFilesAdapter = createEntityAdapter<LibraryFilterSetDTO>({});
const filtersProductsAdapter = createEntityAdapter<LibraryFilterSetDTO>({});

export interface LibraryFilesFilterState {
  status: EStateStatus;
  search: string;
  expanded: boolean;
  popupCategory: LibraryFilesFilterCategory | null;
  filterInfo: LibraryFilesFilterInfo;
  newFilterSetDialog: boolean;
  manageFilterSetsDialog: boolean;
  mobileMenu: boolean;
  filterParams: LibraryFilesFilterParams;
  filesFilters: EntityState<LibraryFilterSetDTO>;
  productsFilters: EntityState<LibraryFilterSetDTO>;
}

export const libraryFilesFilterReducerName: string = 'libraryFilesGridFilter';

const initialState: LibraryFilesFilterState = {
  status: EStateStatus.IDLE,
  filterInfo: {
    owner: [],
    type: [],
  },
  search: '',
  expanded: false,
  popupCategory: null,
  newFilterSetDialog: false,
  manageFilterSetsDialog: false,
  mobileMenu: false,
  filterParams: {},
  filesFilters: filtersFilesAdapter.getInitialState(),
  productsFilters: filtersProductsAdapter.getInitialState(),
};

export const getLibraryFilesFilterInfo = createAsyncThunk(
  `${libraryFilesFilterReducerName}/${ELibraryFilesFilterActions.GET_FILTER_INFO}`,
  async () => libraryApi.getFilterInfo(),
);

export const getUserFilesFilters = createAsyncThunk(
  `${libraryFilesFilterReducerName}/${ELibraryFilesFilterActions.GET_ALL_FILES_FILTERS}`,
  async () => libraryFilterApi.getLibraryFilesFilters(),
);

export const createNewFilter = createAsyncThunk(
  `${libraryFilesFilterReducerName}/${ELibraryFilesFilterActions.SAVE_NEW_FILTER}`,
  async (payload: LibraryFilesFilterSet) => libraryFilterApi.createLibraryFilter(payload),
);

export const updateFilter = createAsyncThunk(
  `${libraryFilesFilterReducerName}/${ELibraryFilesFilterActions.UPDATE_FILTER}`,
  async (payload: {
    id: string;
    filterSet: Partial<LibraryFilesFilterSet>;
  }) => libraryFilterApi.updateFilter(payload.id, payload.filterSet),
);

export const deleteFilter = createAsyncThunk(
  `${libraryFilesFilterReducerName}/${ELibraryFilesFilterActions.DELETE_FILTER}`,
  async (payload: string, thunkAPI) => {
    await libraryFilterApi.deleteFilter(payload);
    thunkAPI.dispatch(getUserFilesFilters());
  },
);

export const libraryFilesGridFilterSlice = createSlice({
  name: libraryFilesFilterReducerName,
  initialState,
  reducers: {
    resetFilter: () => initialState,
    setLibraryFilesSearch: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      search: payload,
    }),
    setLibraryFilesGridFilter: (state, { payload }: PayloadAction<LibraryFilesFilterParams>) => ({
      ...state,
      filterParams: payload,
    }),
    setLibraryFilesFilterExpanded: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      expanded: payload,
    }),
    setLibraryFilesFilterPopupCategory: (state, { payload }: PayloadAction<LibraryFilesFilterCategory | null>) => ({
      ...state,
      popupCategory: payload,
    }),
    setLibraryFilesNewFilterSetDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      newFilterSetDialog: payload,
    }),
    setLibraryFilesManageFilterSetsDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      manageFilterSetsDialog: payload,
    }),
    setLibraryFilesMobileFilterMenu: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      mobileMenu: payload,
    }),
  },
  extraReducers: ((builder) => builder
    .addCase(getLibraryFilesFilterInfo.fulfilled, (state, action) => {
      state.filterInfo = action.payload;
    })
    .addCase(getUserFilesFilters.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(getUserFilesFilters.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      filtersFilesAdapter.setAll(state.filesFilters, action.payload);
    })
    .addCase(getUserFilesFilters.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(createNewFilter.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(createNewFilter.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      filtersFilesAdapter.setAll(state.filesFilters, action.payload);
    })
    .addCase(createNewFilter.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(updateFilter.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(updateFilter.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      filtersFilesAdapter.updateOne(state.filesFilters, {
        id: action.payload.id,
        changes: {
          ...action.payload,
        },
      });
    })
    .addCase(updateFilter.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
  ),
});

export const {
  resetFilter,
  setLibraryFilesSearch,
  setLibraryFilesGridFilter,
  setLibraryFilesFilterExpanded,
  setLibraryFilesFilterPopupCategory,
  setLibraryFilesNewFilterSetDialogStatus,
  setLibraryFilesManageFilterSetsDialogStatus,
  setLibraryFilesMobileFilterMenu,
} = libraryFilesGridFilterSlice.actions;

export const {
  selectAll: filesSelectAll,
  selectById: filesSelectById,
  selectIds: filesSelectIds,
  selectTotal: filesSelectTotal,
} = filtersFilesAdapter.getSelectors();

export const allFilesFiltersSelector = (state: RootState) => filesSelectAll(state.libraryFilesFilter.filesFilters);
export const selectLibraryFilesFilterSearch = (state: RootState) => state.libraryFilesFilter.search;
export const selectLibraryFilesGridFilter = (state: RootState) => state.libraryFilesFilter.filter;
export const selectLibraryFilesFilterInfo = (state: RootState) => state.libraryFilesFilter.filterInfo;
export const selectLibraryFilesExpanded = (state: RootState) => state.libraryFilesFilter.expanded;
export const selectLibraryFilesPopupCategory = (state: RootState) => state.libraryFilesFilter.popupCategory;
export const selectLibraryFilesCollectFilterSetInfoDialogStatus = (state: RootState) => state
  .libraryFilesFilter
  .newFilterSetDialog;
export const selectLibraryFilesManageFilterSetsDialogStatus = (state: RootState) => state
  .libraryFilesFilter
  .manageFilterSetsDialog;
export const selectLibraryFilesMobileFilterMenu = (state: RootState) => state
  .libraryFilesFilter
  .mobileMenu;
export const selectLibraryFilesFilterParams = (state: RootState) => state
  .libraryFilesFilter
  .filterParams;
