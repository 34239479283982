import {
  Avatar, Box, Button, Typography,
} from '@mui/material';
import classNames from 'classnames';
import { ChangeEvent, useRef, useState } from 'react';
import TrashIcon from '../../icons/TrashIcon';
import { stagesetColors } from '../../theme/stagesetPalette';
import NormalizedSvgIcon from '../NormalizedSvgIcon/NormalizedSvgIcon';
import UploadIcon from '../../icons/Upload.svg';
import { AvatarIcon20 } from '../../icons/AvatarIcon';

interface Props {
  setAvatarFile: (file: File | undefined) => void;
}

const AvatarFilePreviev = ({
  setAvatarFile,
}: Props): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const maxFileSize = 1024 * 1024; // 1mb
  const [fileUrl, setFileUrl] = useState<string>('');

  const [fileError, setFileError] = useState(false);

  const validateFile = (file?: File): boolean => {
    if (!file || (file.size > maxFileSize)) {
      setFileError(true);
      return false;
    }
    return true;
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const [file] = e.target.files || [];

    if (!validateFile(file)) return;
    setFileError(false);
    setAvatarFile(file);
    setFileUrl(URL.createObjectURL(file));
  };

  const handleLogoRemove = () => {
    setAvatarFile(undefined);
    setFileUrl('');
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
      }}
    >
      <Avatar
        src={fileUrl}
        sx={{
          width: 72,
          height: 72,
          border: '1px solid #EEF0FA',
          background: 'white',
          '& svg': {
            fontSize: '40px',
          },
        }}
      >
        <AvatarIcon20 />
      </Avatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <input
            hidden
            type="file"
            accept="image/png, image/jpeg"
            ref={fileInputRef}
            onChange={handleLogoChange}
            className="input"
          />
          <Button
            startIcon={<NormalizedSvgIcon component={UploadIcon} />}
            variant="contained"
            disableRipple
            className="upload-button"
            onClick={handleUploadClick}
            sx={{
              stroke: '#0073F5 !important',
              color: '#0073F5',
              minWidth: '32px',
              height: '32px',
              backgroundColor: '#E5EDFF',
              transition: '0.2s',
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: '#1F88FF',
                stroke: 'white !important',
                color: 'white',
              },
            }}
          >
            Upload photo
          </Button>
          <Button
            className={classNames('remove-button', { 'remove-button-hidden': !fileUrl })}
            onClick={handleLogoRemove}
            disableRipple
            sx={{
              '&.remove-button': {
                borderRadius: '6px',
                stroke: stagesetColors.red?.[600],
                backgroundColor: stagesetColors.red?.[100],
                minWidth: '32px',
                height: '32px',
                '&:hover': {
                  stroke: 'white',
                  backgroundColor: stagesetColors.red?.[500],
                },
                '&:active': {
                  stroke: 'white',
                  backgroundColor: stagesetColors.red?.[400],
                },
                '&-hidden': {
                  display: 'none',
                },
              },
            }}
          >
            <TrashIcon />
          </Button>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '12px',
              color: fileError ? '#C94937' : '#7D89A7',
              lineHeight: '16px',
            }}
          >
            JPG, PNG no more than 1 Mb
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AvatarFilePreviev;
