import { useMemo, useState } from 'react';
import {
  DragEndEvent, DragStartEvent, PointerSensor, TouchSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { isMobile } from 'react-device-detect';

export const useSIdebarStageDnD = () => {
  const [draggableElementId, setDraggableElementId] = useState<string | number | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setDraggableElementId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return null;
    setDraggableElementId(null);
    return {
      current: active.id,
      target: over.id,
    };
  };

  const sensors = useSensors(
    useSensor(isMobile ? TouchSensor : PointerSensor, {
      activationConstraint: isMobile ? {
        delay: 1000,
        tolerance: 5,
      } : {
        distance: 5,
      },
    }),
  );

  const returnObject = useMemo(() => ({
    draggableElementId,
    handleDragStart,
    handleDragEnd,
    sensors,
  }), [draggableElementId]);

  return returnObject;
};
