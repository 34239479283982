import { UserAppStatus } from '../../../shared/UserDTO';
import { selectUser } from '../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { logout } from '../../routes-old/auth/state/authState';

const GuestFallback = (): null => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  if (!user || user.appStatus === UserAppStatus.GUEST) {
    setTimeout(() => dispatch(logout()), 2000);
  }
  return null;
};

export default GuestFallback;
