import { Box } from '@mui/material';
import { ESocialAuthProvider, ESocialAuthTypes } from '../../../../shared/schema/auth.shcema';
import { GoogleGLogo } from '../../../common/GoogleGLogo';
import { SessionStorageWrapper } from '../../../common/sessionStorageWrapper';
import { config } from '../../../core';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { LinkedInAuthIcon } from '../../../icons/LinkedInAuthIcon';
import WhiteIconButton from '../../../shared/Buttons/WhiteIconButton';
import { logout } from '../state/authState';
import { DomainHelper } from '../../../core/helpers/DomainHelper';
import { OrganizationSubdomainHelper } from '../../../core/helpers/OrganizationSubdomainHelper';
import { useQuery } from '../../../hooks/useQuery';
import { useTranslation } from "react-i18next";

interface Props {
  isSignup?: boolean;
}

export const SocialAuth = ({ isSignup }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const sessionStorageWrapper: SessionStorageWrapper = new SessionStorageWrapper();
  const { t } = useTranslation();

  const getCelloReferrer = () => {
    if (query.has('ucc')) {
      return query.get('ucc');
    }
    if (typeof window.CelloAttribution === 'function') {
      return window.CelloAttribution('getReferral');
    }
    return undefined;
  };

  const onSocialButtonClick = (provider: ESocialAuthProvider, socialAuthType: ESocialAuthTypes): void => {
    sessionStorageWrapper.storeSocialAuthQuery({
      socialAuthType,
      provider,
      celloReferrer: getCelloReferrer(),
    });
    if (!DomainHelper.isCustomDomain) {
      window.location.href = `${config.api.origin}/${provider}`;
    } else {
      OrganizationSubdomainHelper.redirectToCustomDomainSocialLogin(socialAuthType, provider, getCelloReferrer());
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        gap: '12px',
      }}
    >
      <WhiteIconButton
        icon={<GoogleGLogo />}
        text={isSignup ? t('Auth_Sign_up_with_Google') : t('Auth_Log_in_with_Google')}
        width="100%"
        onClick={() => {
          dispatch(logout());
          if (isSignup) {
            onSocialButtonClick(ESocialAuthProvider.google, ESocialAuthTypes.SIGNUP);
          } else {
            onSocialButtonClick(ESocialAuthProvider.google, ESocialAuthTypes.LOGIN);
          }
        }}
      />
      <WhiteIconButton
        icon={<LinkedInAuthIcon />}
        onClick={() => {
          dispatch(logout());
          if (isSignup) {
            onSocialButtonClick(ESocialAuthProvider.linkedin, ESocialAuthTypes.SIGNUP);
          } else {
            onSocialButtonClick(ESocialAuthProvider.linkedin, ESocialAuthTypes.LOGIN);
          }
        }}
      />
    </Box>
  );
};

export default SocialAuth;
