import * as React from 'react';
import { ReactElement, useMemo } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { AppButton } from '../AppButton/AppButton';
import Pause from '../../icons/DealStatusIcons/Pause.svg';
import Cross from '../../icons/DealStatusIcons/Cross.svg';
import Check from '../../icons/DealStatusIcons/Check.svg';
import Play from '../../icons/DealStatusIcons/Play.svg';
import { ProcessStatus } from '../../../shared/process/ProcessMilestoneActionDTO';
import { AppDropdown } from '../AppDropdown/AppDropdown';

export type Props = { status: ProcessStatus, onChooseStatus: (status: ProcessStatus) => void, disableEdit?: boolean };

export const DealStatus = ({ status, onChooseStatus, disableEdit }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const renderCheckIcon = (activeStatus: ProcessStatus) => {
    if ((status === activeStatus) || (!status && activeStatus === ProcessStatus.IN_PROGRESS)) {
      return (
        <Box sx={{
          position: 'absolute',
          right: '15px',
          top: '10px',
        }}
        >
          <Check />
        </Box>
      );
    }
    return undefined;
  };

  const params: { icon: ReactElement, status: string } = useMemo(() => {
    switch (status) {
      case ProcessStatus.IN_PROGRESS:
        return {
          icon: <Play />,
          status: t('Status_In_progress'),
        };
      case ProcessStatus.ON_HOLD:
        return {
          icon: <Pause />,
          status: t('Status_On_hold'),
        };
      case ProcessStatus.CLOSED_LOST:
        return {
          icon: <Cross />,
          status: t('Status_Closed_lost'),
        };
      case ProcessStatus.CLOSED_WON:
        return {
          icon: <Check />,
          status: t('Status_Closed_won'),
        };
      default:
        return {
          icon: <Play />,
          status: t('Status_In_progress'),
        };
    }
  }, [status]);

  return (
    <>
      {disableEdit
        ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              sx={{
                paddingRight: '5px',
                paddingTop: '4px',
              }}
            >
              {params.icon}
            </Typography>
            <Typography
              sx={{
                paddingRight: '5px',
                paddingTop: '2px',
              }}
            >
              {params.status}
            </Typography>
          </Box>
        )
        : (
          <AppButton
            initialStroke
            startIcon={params.icon}
            variant="transparent"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!disableEdit) {
                setAnchorEl(e.currentTarget);
              }
            }}
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              color: (theme) => theme.palette.grey[600],
              paddingX: '5px !important',
              minWidth: 'auto !important',
            }}
          >
            {params.status}
          </AppButton>
        )}
      <AppDropdown
        sx={{
          '& .MuiList-root': {
            minWidth: '200px',
          },
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={(e) => e.stopPropagation()}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        items={[
          {
            label: t('Status_In_progress'),
            startIcon: <Play />,
            endIcon: renderCheckIcon(ProcessStatus.IN_PROGRESS),
            onClick: () => {
              onChooseStatus(ProcessStatus.IN_PROGRESS);
            },
          },
          {
            label: t('Status_On_hold'),
            startIcon: <Pause />,
            endIcon: renderCheckIcon(ProcessStatus.ON_HOLD),
            onClick: () => {
              onChooseStatus(ProcessStatus.ON_HOLD);
            },
          },
          {
            label: t('Status_Closed_won'),
            startIcon: <Check />,
            endIcon: renderCheckIcon(ProcessStatus.CLOSED_WON),
            onClick: () => {
              onChooseStatus(ProcessStatus.CLOSED_WON);
            },
          },
          {
            label: t('Status_Closed_lost'),
            startIcon: <Cross />,
            endIcon: renderCheckIcon(ProcessStatus.CLOSED_LOST),
            onClick: () => {
              onChooseStatus(ProcessStatus.CLOSED_LOST);
            },
          },
        ]}
      />
    </>
  );
};
