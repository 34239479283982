import { ReactElement } from 'react';
import { Box } from '@mui/system';

type Props = {
  icon: ReactElement,
  text: string,
  link: string
};

const ContactRow = ({ icon, text, link }: Props): JSX.Element => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

  }}
  >
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    >
      {icon}
    </Box>
    <Box sx={{
      marginLeft: '13px',
      marginTop: '6px',
      marginBottom: '6px',
      maxWidth: '250px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
    >
      <Box
        component="a"
        sx={{
          fontSize: '16px',
          textDecoration: 'none',
          color: '#0073F5',
          '&:hover': {
            color: (theme) => theme.palette.warning[600],
          },
        }}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </Box>
    </Box>
  </Box>
);

export default ContactRow;
