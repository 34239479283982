import { useMemo } from 'react';
import {
  setActiveSection,
  setActiveSectionUpdateDisabled,
  selectIsActiveSection,
  selectIsActiveSectionUpdateDisabled,
  updateFieldsLayoutState,
  selectStagesDrawerStatus,
  setStagesDrawerOpen,
} from '../features/Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { EComponentsIds } from '../../shared/ComponentsIdsAndClassNames/ComponentsIdsAndClassNames';

const useFieldsLayoutSlice = (id?: string) => {
  const dispatch = useAppDispatch();
  const isActiveSection = useAppSelector((state) => selectIsActiveSection(state, id));
  const isActiveSectionUpdateDisabled = useAppSelector(selectIsActiveSectionUpdateDisabled);
  const isDrawerOpen = useAppSelector(selectStagesDrawerStatus);

  const onActiveSectionUpdateStatusChange = (status: boolean) => {
    dispatch(setActiveSectionUpdateDisabled(status));
  };

  const onSetActiveSection = () => {
    if (!isActiveSection) {
      dispatch(setActiveSection(id));
    }
  };

  const getLayoutWrapperRef = document.getElementById(EComponentsIds.PROCESS_LAYOUT_WRAPPER);

  const getGeneratedBlockId = useMemo(() => `${EComponentsIds.FIELS_LAYOUT_BLOCK}-${id}`, [id]);

  let scrollTimeout: any;

  const handleScrollToBlockTimeout = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      onActiveSectionUpdateStatusChange(false);
      getLayoutWrapperRef!.removeEventListener('scroll', handleScrollToBlockTimeout);
    }, 100);
  };

  const scrollToBlock = (position?: ScrollLogicalPosition) => {
    if (id) {
      dispatch(updateFieldsLayoutState({
        activeSection: id,
        isActiveSectionUpdateDisabled: true,
      }));
      getLayoutWrapperRef?.addEventListener('scroll', handleScrollToBlockTimeout);
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
        block: position ?? 'center',
        inline: 'nearest',
      });
    }
  };

  const onDrawerStatusUpdate = (newStatus: boolean) => {
    dispatch(setStagesDrawerOpen(newStatus));
  };

  return {
    isActiveSection,
    onSetActiveSection,
    getGeneratedBlockId,
    scrollToBlock,
    isActiveSectionUpdateDisabled,
    getLayoutWrapperRef,
    onDrawerStatusUpdate,
    isDrawerOpen,
  };
};

export default useFieldsLayoutSlice;
