import { Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import { MediaFieldUpdateData } from '../MediaFieldContainer';
import { FileStorageApi } from '../../../api/FileStorageApi';
import { EMediaFieldStorageType } from '../../../../shared/Field/Field';
import { isPDF } from '../Helpers/MediaFieldHelpers';
import useFrontEventsApi from '../../../hooks/useFrontEventsApi';
import { useCurrentStage } from '../../../hooks/useCurrentStage';
import { StageContentType } from '../../../../shared/Stage';
import PdfPageContainer from './PdfPageContainer';
import { AppLoader } from '../../../shared/AppLoader/AppLoader';
import MediaResizePlugin from '../MediaResizePlugin';
import MediaFieldPDFControls from '../MediaFieldPDFControls';
import { AppSkeleton } from '../../../shared/AppSkeleton/AppSkeleton';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const filesApi = new FileStorageApi();

interface Props {
  url: string;
  siblingsCount?: number;
  onUpdate: (data: MediaFieldUpdateData) => void;
  width?: number;
  height?: number;
  storageType?: EMediaFieldStorageType;
  name: string,
  // mediaComponentWrapperRef?: RefObject<HTMLDivElement> | undefined
}

const MediaFieldPdfFile = ({
  url,
  siblingsCount,
  onUpdate,
  width: initialWidth,
  height: initialHeight,
  storageType,
  // mediaComponentWrapperRef,
  name,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const pdfDocRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [controlsHeight, setControlsHeight] = useState<number>(0);
  const [isInteractionLogged, setInteractionLogged] = useState(false);
  const [isControlsVisible, setControlsVisible] = useState<boolean>(false);
  const [file, setFile] = useState<ArrayBuffer | undefined>(undefined);
  const [pdfLoading, setPdfLoading] = useState<boolean>(true);

  const mediaComponentWrapperRef = containerRef?.current?.closest('.MediaContentWrapper') as HTMLDivElement
    ?? undefined;

  const s3LinkFormated = url.includes('cloudfront.net') ? decodeURI(url.split('.net/').pop()!) : url;

  const { createDocumentInteraction } = useFrontEventsApi();

  const currentStage = useCurrentStage();
  const wideScreenStage = currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE;

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (s3LinkFormated) {
      setPdfLoading(true);
      filesApi.streamFileByPath(s3LinkFormated).then((buffer: ArrayBuffer) => setFile(buffer));
    }
  }, [s3LinkFormated]);

  const handleControlsResize = (): void => {
    if (pdfDocRef?.current?.clientHeight) {
      setControlsHeight(pdfDocRef.current.clientHeight);
    }
  };

  useEffect(() => {
    handleControlsResize();
  }, [pdfDocRef, s3LinkFormated]);

  const pdfHeight = () => {
    if (wideScreenStage) {
      return '100%';
    }
    return isMobile ? controlsHeight : (height ?? controlsHeight) || 'auto';
  };

  const onDocumentLoadProgress = () => {
    if (!pdfLoading) {
      setPdfLoading(true);
    }
  };

  // TODO fix skeleton
  const containerWidth = mediaComponentWrapperRef?.clientWidth;
  const containerHeight = mediaComponentWrapperRef?.clientHeight;

  const skeletonWidth = () => {
    if (width) return width;
    if (containerWidth) return `${containerWidth}px`;
    return 'calc (100%)';
  };

  const skeletonHeight = () => {
    if (height) return height;
    if (containerHeight) return `${containerHeight}px`;
    return '500px';
  };

  const onDocumentLoadSuccess = (docData: pdfjs.PDFDocumentProxy): void => {
    setNumPages(docData.numPages);
    if (!wideScreenStage) {
      setPdfLoading(false);
    }
    if (docData.numPages > 1) {
      setControlsVisible(true);
    }
  };

  const eventHandler = () => {
    if (!isInteractionLogged) {
      createDocumentInteraction({
        linkUrl: s3LinkFormated,
        isPdf: isPDF(url),
        storageType,
        name,
      });
      setInteractionLogged(true);
    }
  };

  const updateCurrentPage = (newPage: number) => {
    setPageNumber(newPage);
    eventHandler();
  };

  useEffect(() => {
    handleControlsResize();
  }, [pdfDocRef, s3LinkFormated]);

  const renderPdfWidth = () => {
    if (wideScreenStage) {
      return isMobile ? window.screen.width : undefined;
    }
    return isMobile ? window.screen.width : (width ?? (900 / (siblingsCount ?? 1)));
  };

  const PdfPageSetter = useMemo(() => {
    const pdfPages: [] = [];
    if (numPages) {
      if (!!numPages && numPages > 0) {
        return Array.from({ length: numPages }).map((_, index) => (
          <PdfPageContainer
            key={index}
            index={index + 1}
            setPdfLoading={setPdfLoading}
            mediaComponentWrapperRef={mediaComponentWrapperRef}
            numPages={numPages}
          />
        ));
      }
    }
    return pdfPages;
  }, [numPages]);

  const renderPdf = () => {
    if (wideScreenStage) {
      return (
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            overflow: 'auto',
            width: isMobile ? '100vw' : 'calc(100vw - 300px)',
            '& .react-pdf__Page': {
              paddingBottom: '5px',
            },
            '& .react-pdf__Document': {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            },
            '& .react-pdf__Page__textContent': {
              zIndex: 0,
            },
          }}
          onClick={eventHandler}
        >
          {pdfLoading
            && (
            <AppSkeleton
              animation="wave"
              variant="rectangular"
              width="calc( 100% )"
              height="2000px"
              sx={{
                border: '1px solid #EEF0FA',
                background: 'fff',
              }}
            />
            )}
          <Box
            ref={containerRef}
            sx={{
              overflow: 'auto',
              display: pdfLoading ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadProgress={onDocumentLoadProgress}
              noData=""
              renderMode="canvas"
            >
              {PdfPageSetter}
            </Document>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        ref={iframeRef}
        onClick={eventHandler}
        sx={{
          // minHeight: '350px',
          position: 'relative',
          width: pdfLoading ? `${width}px` : 'calc( 100% )',
          '& .react-pdf__Page': {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '5px',
            maxWidth: isMobile ? '100vw' : '100%',
            '& .react-pdf__Page__canvas': {
              maxWidth: isMobile ? '100vw' : '100%',
              height: 'auto !important',
            },
          },
          '& .react-pdf__Document': {
            maxWidth: isMobile ? '100vw' : '100%',
          },
          '& .react-pdf__Page__textContent': {
            zIndex: 0,
          },
        }}
      >
        {pdfLoading
            && (
            <AppSkeleton
              animation="wave"
              variant="rectangular"
              height={skeletonHeight()}
              width={skeletonWidth()}
              sx={{
                border: '1px solid #EEF0FA',
                background: 'fff',
              }}
            />

            )}
        {isControlsVisible && (
          <MediaFieldPDFControls
            controlsHeight={isMobile ? controlsHeight : height ?? controlsHeight}
            pageNumber={pageNumber}
            setPageNumber={updateCurrentPage}
            numPages={numPages}
          />
        )}
        <Box
          ref={containerRef}
          sx={{
            overflow: 'hidden',
            // minHeight: '350px',
            height: pdfHeight(),
            display: pdfLoading ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            noData=""
            renderMode="canvas"
          >
            <Page
              scale={1}
              inputRef={pdfDocRef}
              pageNumber={pageNumber}
              renderTextLayer
              renderAnnotationLayer
              onLoadSuccess={handleControlsResize}
              width={renderPdfWidth()}
              height={isMobile ? controlsHeight : height ?? undefined}
              loading={<AppLoader size={48} />}
            />
          </Document>
        </Box>
        {(iframeRef?.current && numPages)
          && !wideScreenStage
          && !isMobile
          && (
          <MediaResizePlugin
            containerRef={iframeRef.current}
            width={width}
            height={height}
            onUpdate={onUpdate}
            onWidthUpdate={(newWidth) => setWidth(newWidth)}
            onHeightUpdate={(newHeight) => setHeight(newHeight)}
          />
          )}
      </Box>
    );
  };

  return (
    <>
      {renderPdf()}
    </>
  );
};

export default MediaFieldPdfFile;
