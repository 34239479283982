import { Box, Button, List } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EUserProcessStatus } from '../../../../../shared/user-process';
import { ProcessUserDTO } from '../../../../../shared/UserDTO';
import { UserItem } from './UserItem/UserItem';
import UsersListDivider from './UsersListDivider';

interface UserListProps {
  assigneesIds?: string[];
  onlineUserIds: string[];
  processUsers: ProcessUserDTO[];
  onAssigneeRemove: (userId: string) => void;
  onAssigneeAdd: (userId: string) => void;
}

const StyledActionButton = styled(Button)(() => ({
  width: '60px',
  fontSize: '14px',
  fontWeight: 700,
}));

const AssigneesList = ({
  assigneesIds,
  processUsers,
  onAssigneeAdd,
  onAssigneeRemove,
  onlineUserIds,
}: UserListProps): JSX.Element => {
  const assignees: ProcessUserDTO[] = [];
  const possibleAssignees: ProcessUserDTO[] = [];

  for (const processUser of processUsers) {
    if (assigneesIds?.includes(processUser.id)) {
      assignees.push(processUser);
    } else {
      possibleAssignees.push(processUser);
    }
  }

  const showAssignees = assignees && assignees.length > 0;
  const showPossibleAssignees = possibleAssignees && possibleAssignees.length > 0;

  const assigneesItems: JSX.Element[] = assignees.map((user: ProcessUserDTO) => (
    <UserItem
      user={user}
      isBadgeEnabled
      key={user.id}
      isOnline={onlineUserIds.includes(user.id)}
      secondaryAction={(
        <StyledActionButton
          onClick={() => onAssigneeRemove(user.id)}
        >
          Remove
        </StyledActionButton>
      )}
    />
  ));

  const possibleAssigneesItems: JSX.Element[] = possibleAssignees
    .filter(
      (user: ProcessUserDTO) => user.processStatus !== EUserProcessStatus.DEACTIVATED
        && user.processStatus !== EUserProcessStatus.INVITED_AND_DEACTIVATED,
    )
    .map((user: ProcessUserDTO) => (
      <UserItem
        user={user}
        key={user.id}
        isOnline={onlineUserIds.includes(user.id)}
        secondaryAction={(
          <StyledActionButton
            onClick={() => onAssigneeAdd(user.id)}
          >
            Add
          </StyledActionButton>
        )}
      />
    ));

  return (
    <Box
      id="deal-feed-assignees-list"
      sx={{
        flex: 1,
        mx: 0,
        px: 0,
      }}
    >
      {showAssignees
        && (
          <>
            <UsersListDivider title="Owners" />
            <List
              sx={{
                flexGrow: 1,
              }}
            >
              {assigneesItems}
            </List>
          </>
        )}
      {showPossibleAssignees
        && (
          <>
            <UsersListDivider title="Add Owner" />
            <List
              sx={{
                flexGrow: 1,
              }}
            >
              {possibleAssigneesItems}
            </List>
          </>
        )}
    </Box>
  );
};

export default AssigneesList;
