import { GridValidRowModel } from '@mui/x-data-grid';
import { ExtendedGridColumn, ExtendedGridColumns } from '../../../../types/mobile-table';
import TeamTabMobileTableRow from './TeamTabMobileTableRow';
import { TeamTabMobileTableWrapper } from '../Styles/TeamTabStyles';
import { TeamTabTableContextProvider } from './TeamTabTableContext';

export type MobileGridProps = {
  onRowClick: (row: any) => void;
  renderTitle: (row: any) => string | JSX.Element
  getId: (row: any) => string | number;
  renderMenu: (row: any) => JSX.Element | null
  rows: readonly GridValidRowModel[]
  columns: ExtendedGridColumns
};

const TeamTabMobileTable = ({
  rows,
  columns,
  renderTitle,
  renderMenu,
  getId,
  onRowClick,
}: MobileGridProps) => {
  const renderCellValue = (column: ExtendedGridColumn, row: GridValidRowModel) => {
    if (typeof column.renderMobileCell === 'boolean') {
      return row[column.field];
    }
    return column.renderMobileCell!({ row, value: row[column.field] });
  };

  return (
    <TeamTabMobileTableWrapper>
      {rows.map((row) => (
        <TeamTabTableContextProvider
          props={row.menu}
          key={getId(row)}
        >
          <TeamTabMobileTableRow
            onClick={() => onRowClick(row)}
            key={getId(row)}
            title={renderTitle(row)}
            menu={renderMenu(row)}
            data={
              columns
                .filter((column) => column.renderMobileCell && column.headerName)
                .map((column) => ({
                  key: column.headerName!,
                  value: renderCellValue(column, row),
                }))
            }
          />
        </TeamTabTableContextProvider>
      ))}
    </TeamTabMobileTableWrapper>
  );
};

export default TeamTabMobileTable;
