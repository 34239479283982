import { useTranslation } from 'react-i18next';
import { EditIcon, SaveIcon } from '../../icons/ControlsIcons/EditBtnIcons';
import { AppButton } from '../AppButton/AppButton';

interface Props {
  onClick: () => void;
  active?: boolean,
  icon?: JSX.Element,
  activeIcon?: JSX.Element,
}

const EditButton = ({
  onClick,
  active,
  icon = <EditIcon />,
  activeIcon = <SaveIcon />,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const onButtonClick = () => {
    onClick();
  };

  return (
    <AppButton
      onClick={onButtonClick}
      startIcon={active ? activeIcon : icon}
      variant="secondary"
      sx={{
        width: 'min-content',
        minWidth: 'min-content',
      }}
    >
      {active ? t('Templates_Save') : t('Templates_Edit')}
    </AppButton>
  );
};

export default EditButton;
