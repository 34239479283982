import { ContentType } from '../../../../shared/Stage';
import { ESidebarTabs } from '../../Layout/lib/fieldsLayoutSlice';
import CompassIcon from '../../../icons/AddSectionIcons/Compass20.svg';
import MediaIcon from '../../../icons/AddSectionIcons/Media20.svg';
import CanvasIcon from '../../../icons/AddSectionIcons/Canvas20.svg';
import { PictureIcon20 } from '../../../icons/PictureIcon';
import { CompanyLogoGlobe } from '../../../icons/CompanyLogoGlobe';
import { PageIcon20 } from '../../../icons/PageIcon';

type NewPageTabItems = {
  key: string,
  label: string,
  subTitle: string,
  sidebarTab: ESidebarTabs,
  startIcon: JSX.Element,
};

export const newPageMainItems: NewPageTabItems[] = [
  {
    key: 'Blank',
    label: 'Canvas',
    subTitle: 'Builder for any purpose',
    sidebarTab: ESidebarTabs.CANVAS,
    startIcon: <CanvasIcon />,
  },
  {
    key: 'Media',
    label: 'Media',
    subTitle: 'Upload any media file',
    sidebarTab: ESidebarTabs.MEDIA,
    startIcon: <MediaIcon />,
  },
  {
    key: 'Embed',
    label: 'Embed',
    subTitle: 'Add link to any resource',
    sidebarTab: ESidebarTabs.EMBED,
    startIcon: <CompassIcon />,
  },
];

export const getIcon = (
  currentSidebarTab: ESidebarTabs,
): JSX.Element => {
  switch (currentSidebarTab) {
    case ESidebarTabs.MEDIA:
      return <PictureIcon20 />;
    case ESidebarTabs.EMBED:
      return <CompanyLogoGlobe />;
    default:
      return <PageIcon20 />;
  }
};

export const templateContentType = (
  currentSidebarTab: ESidebarTabs,
  contentType?: ContentType,
) => {
  if (contentType) return contentType;
  switch (currentSidebarTab) {
    case (ESidebarTabs.CANVAS):
      return ContentType.CANVAS;
      break;
    case (ESidebarTabs.MEDIA):
      return ContentType.MEDIA;
      break;
    case (ESidebarTabs.EMBED):
      return ContentType.URL;
      break;
    default:
      return undefined;
  }
};
