import { LoadingButton } from '@mui/lab';
import { Button, DialogActions } from '@mui/material';
import { styled } from '@mui/styles';
import { stagesetPalette } from '../../theme/stagesetPalette';

const btnHeight = '40px';
const btnWidth = '25%';

export const StyledDialogActions = styled(DialogActions)(() => ({
  justifyContent: 'flex-end',
  gap: '8px',
  padding: '12px 24px',
  background: '#F5F9FF',
  borderTop: '1px solid #EEF0FA',
}));

export const ConfirmButton = styled(Button)(() => ({
  width: btnWidth,
  backgroundColor: stagesetPalette.primary?.main,
  color: '#fff',
  borderRadius: '5px',
  height: btnHeight,
  margin: '0px',
  '&:hover': {
    width: btnWidth,
    backgroundColor: stagesetPalette.primary?.dark,
    color: '#fff',
    borderRadius: '5px',
  },
  '&:active': {
    color: 'white',
    backgroundColor: stagesetPalette.primary?.light,
  },
}));

export const LoadingConfirmButton = styled(LoadingButton)(() => ({
  width: btnWidth,
  backgroundColor: '#0073F5',
  color: '#fff',
  borderRadius: '5px',
  height: btnHeight,
  margin: '0px',
}));

export const CancelButton = styled(Button)(() => ({
  width: btnWidth,
  backgroundColor: 'transparent',
  color: '#0073F5',
  borderRadius: '5px',
  height: btnHeight,
  margin: '0px',
}));
