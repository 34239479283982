import {
  closeSideMenu,
  delayOpenMenu,
  delaySideMenu,
  openSideMenu,
  selectSideMenuOptions,
  toggleSideMenu,
  toggleUserMenu,
} from '../features/SideMenu/lib/sideMenuSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { Analytics, EMixPanelEvents } from '../core/Analytics';
import { logout } from '../routes-old/auth/state/authState';

type UseSideMenuResult = {
  isOpened: boolean,
  isUserMenuOpened: boolean,
  open: () => void,
  delay: () => void,
  close: () => void,
  toggle: () => void,
  toggleUserPanel: () => void,
  onLogout: () => void,
};

const useSideMenu = (): UseSideMenuResult => {
  const { isMenuOpened, isUserMenuOpened } = useAppSelector(selectSideMenuOptions);
  const dispatch = useAppDispatch();
  const analytics: Analytics = Analytics.getInstance();

  const onLogout = () => {
    dispatch(logout());
    analytics.track(EMixPanelEvents.ACCOUNT_LOGOUT);
    analytics.userLogout();
  };

  const openDrawer = () => {
    dispatch(openSideMenu());
  };

  const toggleDrawer = () => {
    dispatch(toggleSideMenu());
  };

  const delayDrawer = () => {
    dispatch(delaySideMenu());
    dispatch(delayOpenMenu());
  };

  const closeDrawer = () => {
    dispatch(closeSideMenu());
  };

  const toggleUserPanel = () => {
    dispatch(toggleUserMenu());
  };

  return {
    isOpened: isMenuOpened,
    isUserMenuOpened,
    open: openDrawer,
    delay: delayDrawer,
    close: closeDrawer,
    toggle: toggleDrawer,
    toggleUserPanel,
    onLogout,
  };
};

export default useSideMenu;
