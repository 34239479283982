import { selectTimelineEnd, selectTimelineStart, setTimelinesDateFilter } from '../features/ActivityLog/lib/processActivityLogSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';

const useProcessTimelineDateFilter = () => {
  const dispatch = useAppDispatch();
  const timelineStart = useAppSelector(selectTimelineStart);
  const timelineEnd = useAppSelector(selectTimelineEnd);

  const setStartDate = (date: number) => {
    dispatch(setTimelinesDateFilter({ date, point: 'start' }));
  };

  const setEndDate = (date: number) => {
    dispatch(setTimelinesDateFilter({ date, point: 'end' }));
  };

  return {
    timelineStart, timelineEnd, setStartDate, setEndDate,
  };
};

export default useProcessTimelineDateFilter;
