import { EOrganizationRoles, RoleDTO } from '../../../../../shared/permissions';
import { OrganizationUserDTO } from '../../../../../shared/UserDTO';
import { RootState } from '../../store';

const findOrganizationOwner = (state: RootState): OrganizationUserDTO | undefined => {
  const { roles, users } = state.app;
  const ownerRole = roles?.find((role: RoleDTO) => role.name === EOrganizationRoles.OWNER);
  const owner = users?.find((user: OrganizationUserDTO) => (user.rolesIds.includes(ownerRole.id)));

  return owner;
};

export default findOrganizationOwner;
