import {
  Box,
} from '@mui/material';
import * as React from 'react';
import ConfirmationDialog from '../../../common/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from '../../../hooks/stateHooks';
import Trash from '../../../icons/Trash.svg';
import Refresh from '../../../icons/Refresh20.svg';
import { ThreeDotsVert20px } from '../../../icons/ThreeDots';
import { AppDropdown } from '../../../shared/AppDropdown/AppDropdown';
import DeleteTypography from '../../../common/DeleteTypography';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { adminDeleteOrganization, adminRestoreOrganization } from '../state/adminSlice';

interface Props {
  organizationId: string;
  title: string;
}

const AdminOrganizationsListMenu = ({
  organizationId,
  title,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [isRestoreDialogOpen, setRestoreDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleDeleteClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setDeleteDialogOpen(true);
    handleClose();
  };

  const handleRestoreClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setRestoreDialogOpen(true);
    handleClose();
  };

  const onItemDelete = (): void => {
    setDeleteDialogOpen(false);
    dispatch(adminDeleteOrganization({ organizationId, type: 'delete' }));
  };

  const onItemRestore = (): void => {
    setRestoreDialogOpen(false);
    dispatch(adminRestoreOrganization(organizationId));
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to delete
      <b>
        {' '}
        {title}
        {' '}
      </b>
      ?
    </DeleteTypography>
  );

  const restoreTypography = (
    <DeleteTypography>
      Are you sure you want to restore
      <b>
        {' '}
        {title}
        {' '}
      </b>
      ?
    </DeleteTypography>
  );

  return (
    <Box>
      <AppIconButton
        id="seller-deals-more-menu"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleOpen}
        transparentStroke
        icon={<ThreeDotsVert20px />}
      />
      <AppDropdown
        items={[
          {
            label: 'Restore',
            onClick: handleRestoreClick,
            startIcon: <Refresh />,
          },
          {
            label: '',
            divider: true,
          },
          {
            label: 'Delete',
            onClick: handleDeleteClick,
            startIcon: <Trash />,
          },
        ]}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocus
        disableAutoFocusItem
        lastElementHasAnotherColor
      />
      <ConfirmationDialog
        isDialogOpen={isDeleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={onItemDelete}
        dialogContentText={deleteTypography}
        dialogTitle="Delete organization"
      />
      <ConfirmationDialog
        isDialogOpen={isRestoreDialogOpen}
        onCancel={() => setRestoreDialogOpen(false)}
        onConfirm={onItemRestore}
        dialogContentText={restoreTypography}
        dialogTitle="Resore organization"
      />
    </Box>
  );
};

export default AdminOrganizationsListMenu;
