import { Box, CircularProgress } from '@mui/material';
import { EJWTTokenType } from '../../../../shared/schema/token.schema';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { useQuery } from '../../../hooks/useQuery';
import { setTokenAndTokenType } from '../state/authState';

const SocialConfirmationsSuccessRedirect = (): JSX.Element => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const access_token: string | null = query.get('access_token');
  const tokenType: EJWTTokenType = query.get('tokenType') as EJWTTokenType;

  if (tokenType && access_token) {
    dispatch(setTokenAndTokenType({
      tokenType,
      access_token,
    }));
  }
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default SocialConfirmationsSuccessRedirect;
