import { ApiService } from '../core/api';
import { ClearbitAutocompleteDTO } from '../../shared/ClearbitDTO';

export class ClearbitApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('clearbit');
  }

  async getClearbitAutocomplete(filter: string): Promise<ClearbitAutocompleteDTO[]> {
    return this.api.get(`autocomplete/${filter}`);
  }
}
