import { SvgIcon, SvgIconProps } from '@mui/material';

export const PenAndPaperIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      fill="none"
      d="M10.5 2.5H4.66667C4.22464 2.5 3.80072
      2.67559 3.48816 2.98816C3.17559 3.30072
      3 3.72464 3 4.16667V15.8333C3 16.2754
      3.17559 16.6993 3.48816 17.0118C3.80072
      17.3244 4.22464 17.5 4.66667 17.5H16.3333C16.7754
      17.5 17.1993 17.3244 17.5118 17.0118C17.8244
      16.6993 18 16.2754 18 15.8333V10"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M15.4796 2.93243C15.7565 2.65555 16.132 2.5 16.5236
      2.5C16.9152 2.5 17.2907 2.65555 17.5676 2.93243C17.8445
      3.2093 18 3.58483 18 3.97639C18 4.36796 17.8445 4.74348
      17.5676 5.02036L10.9558 11.6321L8.17188 12.3281L8.86785
      9.54421L15.4796 2.93243Z"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
