import { List } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import DocumentNodeItem from './DocumentNodeItem';
import { DocumentsFieldWrapper } from '../../../../DocumentsField/DocumentsFieldStyles';
import DocumentNodeUploadBtn from './DocumentNodeUploadBtn';
import { DocumentsNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';

const DocumentsNodeComponent = () => {
  const { data, onUpdate } = useBlockContext<DocumentsNodeAttrs>();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (typeof data.docs === 'string') {
      onUpdate({ docs: (data.docs as string).split(',') });
    }
    if (!valid) {
      setValid(true);
    }
  }, [data.docs, valid]);

  if (!valid) {
    return null;
  }

  return (
    <DocumentsFieldWrapper>
      {
        typeof data.docs !== 'string' && (
          <List className="list">
            {data?.docs?.map((id: string, i: number) => (
              <DocumentNodeItem
                key={`doc-item-${id}-${i}`}
                id={id}
                fileIndex={i}
              />
            ))}
          </List>
        )
      }
      <DocumentNodeUploadBtn />
    </DocumentsFieldWrapper>
  );
};

export default DocumentsNodeComponent;
