// import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import { useAppSelector } from '../../hooks/stateHooks';
// import { AppLinkButton } from '../../shared/AppButton/AppLinkButton';
import BackToHomeButton from '../../shared/Buttons/BackToHomeButton';
import StagesSidebarInviteBtn from '../Stages/StagesSidebarInviteBtn';
import {
  RoomGeneralMenuFooter,
  RoomGeneralMenuTabWrapper,
  // RoomButtonWrapper,
} from './Styles/RoomGeneralMenuStyles';
import { EMenuTabs, selectCurrentMenuTab } from '../Layout/lib/fieldsLayoutSlice';
import RGMSaveRoomAsTemplateButton from './RGMSaveRoomAsTemplateButton';
import RGMActivityBtn from './RGMActivityBtn';
import CustomizationButton from '../../routes-old/process/DealFeed/Customization/CustomizationButton';

const RoomGeneralMenu = (): JSX.Element | null => {
  const currentTab = useAppSelector(selectCurrentMenuTab);
  // const { closeDrawer } = useMobileBottomDrawer()
  return (
    <RoomGeneralMenuTabWrapper>
      {/* <RoomButtonWrapper>
        <AppLinkButton>More</AppLinkButton>
        <AppLinkButton
          onClick={closeDrawer}
        >
          Close
        </AppLinkButton>
      </RoomButtonWrapper> */}
      { (!currentTab
        || currentTab !== EMenuTabs.TEMPLATES) && (
          <RGMSaveRoomAsTemplateButton />
      )}
      <StagesSidebarInviteBtn
        roomGeneralMobileMenu
      />
      <RGMActivityBtn />
      <CustomizationButton
        roomGeneralMobileMenu
      />
      <RoomGeneralMenuFooter>
        <BackToHomeButton
          roomGeneralMobileMenu
        />
      </RoomGeneralMenuFooter>
    </RoomGeneralMenuTabWrapper>
  );
};

export default RoomGeneralMenu;
