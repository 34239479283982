import {
  Avatar, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import * as React from 'react';
import { FormattedLabel } from './FormattedLabel';

interface Props {
  OptionProps: React.HTMLAttributes<HTMLLIElement>;
  state: AutocompleteRenderOptionState;
  title: string;
  avatar: string;
}

const UserSelectionListItem = ({ OptionProps, title, avatar }: Props): JSX.Element => (
  <ListItem
    {...OptionProps}
    sx={{
      display: OptionProps['aria-selected'] ? 'none' : 'flex',
      height: '40px',
      padding: '9px 12px !important',
      flexDirection: 'row',
      gap: '12px',
    }}
    onClick={(e) => {
      e.stopPropagation();
      // DO NOT REMOVE - onClick isn't working on mobile devices we use onMouseDown to fix it.
    }}
    onMouseDown={(e) => {
      if (OptionProps && OptionProps.onClick) {
        OptionProps.onClick(e);
      }
    }}
  >
    <ListItemAvatar
      sx={{
        height: '20px',
        minWidth: '20px',
      }}
    >
      <Avatar
        sx={{
          height: '20px',
          width: '20px',
        }}
        src={avatar}
      />
    </ListItemAvatar>
    <ListItemText>
      <FormattedLabel
        label={title || ''}
        len={22}
        tooltip
        placement="top"
        sx={{
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 500,
        }}
      />
    </ListItemText>
  </ListItem>
);

export default UserSelectionListItem;
