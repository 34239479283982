import {
  Divider, IconButton, ListItem, ListItemButton, ListItemText, MenuItem, Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import Check from '../../../../icons/DealStatusIcons/Check.svg';

export type MobileFilterItemProps = {
  label: string,
  onClick?: (e: any) => void,
  childrenItems?: any,
  endIcon?: ReactNode,
  startIcon?: ReactNode,
  divider?: boolean,
  category?: boolean,
};

export const MobileFilterItem = ({
  divider,
  category,
  label,
  endIcon,
  startIcon,
  onClick,
}: MobileFilterItemProps) => {
  if (divider) return <Divider />;

  if (category) {
    return (
      <MenuItem
        disableRipple
        sx={{
          padding: '0 !important',
          height: 'auto !important',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'initial',
          '&:hover': {
            background: 'initial',
          },
        }}
      >
        <Typography sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: (theme) => theme.palette.grey[500],
        }}
        >
          {label}
        </Typography>
        {endIcon}
      </MenuItem>
    );
  }

  return (
    <ListItem
      key={label}
      disablePadding
      onClick={onClick}
      secondaryAction={endIcon && (
      <IconButton edge="end">
        <Check />
      </IconButton>
      )}
    >
      <ListItemButton
        disableRipple
        sx={{
          padding: '5px',
        }}
      >
        {startIcon && (
        <IconButton edge="start">
          {startIcon}
        </IconButton>
        ) }
        <ListItemText
          primary={label}
          sx={{
            '& span': {
              maxWidth: 'calc(90%)',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
