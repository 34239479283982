import { isMobile } from 'react-device-detect';
import { TeamPopoverButton, TeamPopoverMenuItem } from '../../Styles/TeamTabStyles';
import { LogOutIcon20 } from '../../../../../icons/LogOutIcon';
import RoleSelectorCell from './RoleSelectorCell';
import { useTeamTabTableContext } from '../TeamTabTableContext';

interface Props {
  handleRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CellMoreMenu = ({ handleRemove }: Props): JSX.Element => {
  const teammate = useTeamTabTableContext();

  return (
    <>
      {!!teammate
        && isMobile
        && (
        <TeamPopoverMenuItem
          className="cell-more-menu"
        >
          <RoleSelectorCell
            mobilePopup={isMobile}
          />
        </TeamPopoverMenuItem>
        )}
      <TeamPopoverMenuItem
        className="cell-more-menu-remove"
      >
        <TeamPopoverButton
          className="more-menu-remove-button"
          onClick={handleRemove}
          fullWidth
        >
          <LogOutIcon20 />
          Remove
        </TeamPopoverButton>
      </TeamPopoverMenuItem>
    </>
  );
};

export default CellMoreMenu;
