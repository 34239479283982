import { useEffect, useRef, useState } from 'react';
import ReactCrop, { PixelCrop, type Crop } from 'react-image-crop';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { canvasPreview, centerAspectCrop } from './ImageCropHelpers';
import { AppButton } from '../../shared/AppButton/AppButton';

interface Props {
  image?: File;
  onCropAccept: (file: File) => Promise<void>;
  onCancel: () => void;
  customAspect?: number;
}

const DEFAULT_ASPECT = 109 / 25;

const ImageCrop = ({
  image,
  onCropAccept,
  onCancel,
  customAspect,
}: Props) => {
  const [src, setSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [finalCrop, setFinalCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [loading, setLoading] = useState(false);

  const aspect = customAspect || DEFAULT_ASPECT;

  useEffect(() => {
    if (finalCrop && imgRef.current && canvasRef.current && src) {
      canvasPreview(imgRef.current, canvasRef.current, finalCrop, 1, 0);
    }
  }, [finalCrop, imgRef?.current, canvasRef?.current, src]);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(image);
    }
  }, [image]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  }

  const fileExtension = () => {
    if (image && image.name) {
      return (/[.]/.exec(image.name)) ? /[^.]+$/.exec(image.name) : undefined;
    } return undefined;
  };

  const onFinish = async () => {
    setLoading(true);
    if (!canvasRef.current) {
      setLoading(false);
      throw new Error('Crop canvas does not exist');
    }
    canvasRef.current.toBlob(async (blob) => {
      if (!blob) {
        setLoading(false);
        throw new Error('Failed to create blob');
      }
      const croppedFile = new File(
        [blob],
        `${uuidv4()}.${fileExtension()}`,
        { type: image?.type, lastModified: Date.now() } ?? 'cropped file',
      );
      if (croppedFile) {
        setLoading(false);
        await onCropAccept(croppedFile);
        setSrc('');
      } else {
        setLoading(false);
      }
    });
  };

  if (!image) return null;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          alignItems: 'center',
          maxHeight: 'calc( 80vh - 64px )',
        }}
      >
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          onComplete={setFinalCrop}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Background crop"
            src={src}
            onLoad={onImageLoad}
            style={{
              width: '100%',
            }}
          />
        </ReactCrop>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <AppButton
            variant="danger-transparent"
            size={isMobile ? 's' : 'l'}
            onClick={onCancel}
          >
            Cancel
          </AppButton>
          <AppButton
            variant="primary"
            loading={loading}
            size={isMobile ? 's' : 'l'}
            onClick={onFinish}
          >
            Save
          </AppButton>
        </Box>
      </Box>
      {
        finalCrop
        && (
          <canvas
            ref={canvasRef}
            hidden
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: finalCrop.width,
              height: finalCrop.height,
            }}
          />
        )
      }
    </>
  );
};

export default ImageCrop;
