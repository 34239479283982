import { ILoginResponse } from '@schema-api/token.schema';
import { OrganizationDTO } from './OrganizationDTO';
import { RoleDTO, TAllPermissions } from './permissions';
import { SubscriptionDTO } from './Subscription';
import { OrganizationUserDTO, UserDTO } from './UserDTO';

export enum EUserOrganizationStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
  TRANSFERED = 'TRANSFERED',
}

export class UserOrganizationDTO {
  organization: OrganizationDTO;
  user: OrganizationUserDTO;
  permissions: TAllPermissions;
  users?: OrganizationUserDTO[];
  roles: RoleDTO[];
  invites: any[];
  newTokenData?: ILoginResponse;
}

export interface IAddUserToOrganizationRequest {
  email: string;
  organizationId: string;
  roleIds: string[];
}

export interface DeactivateUserRequest {
  userId: string;
}

export interface UserRolesDTO {
  user: UserDTO;
  roles: RoleDTO[];
}
export interface UpdateOrganizationOwnerRequest {
  userId: string;
  ownerId: string;
  oldOwnerNewRoleId: string;
  ownerRoleId: string;
}

export interface UpdateOrganizationOwnerResponse {
  newOwner: OrganizationUserDTO;
  oldOwner: OrganizationUserDTO;
}

export interface UpdateOrganizationCDEnablesRequest {
  enableCustomDomain: boolean;
}

export interface UpdateOrganizationPublicApiEnablesRequest {
  enablePublicApi: boolean;
}
