import { EDealEventName } from '../../shared/events/Events';
import { selectEventsFilterStatus, setTimelinesEventFilter } from '../features/ActivityLog/lib/processActivityLogSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';

const useProcessTimelineEventsFilter = (eventName?: EDealEventName) => {
  const dispatch = useAppDispatch();

  const isSelected = useAppSelector((state) => selectEventsFilterStatus(state, eventName));

  const changeUserFilterStatus = () => {
    dispatch(setTimelinesEventFilter(eventName));
  };

  return {
    isSelected,
    changeUserFilterStatus,
  };
};

export default useProcessTimelineEventsFilter;
