import { SelectChangeEvent } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ECustomEventsNames } from '../../../../shared/events/CustomEvents';
import AdjustByHeight from '../../../icons/AdjustByHeight.svg';
import AdjustByWidth from '../../../icons/AdjustByWidth.svg';
import Separator from '../../../icons/Separator.svg';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { CheckIcon20px } from '../../../icons/CheckIcon';
import { PdfMenuFormControl, PdfMenuItem, PdfMenuSelect } from './Styles/WideScreenPdfSectionMenuStyles';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { PlusIcon20 } from '../../../icons/PlusIcon';
import { MinusIcon20 } from '../../../icons/MinusIcon';

export const pdfScaleValues: { id: string | '', scale: number, adjust: 'height' | 'width' | 'default' }[] = [
  { id: '1', scale: 10, adjust: 'default' },
  { id: '2', scale: 50, adjust: 'default' },
  { id: '3', scale: 60, adjust: 'default' },
  { id: '4', scale: 75, adjust: 'default' },
  { id: '5', scale: 100, adjust: 'default' },
  { id: '6', scale: 125, adjust: 'default' },
  { id: '7', scale: 150, adjust: 'default' },
  { id: '8', scale: 200, adjust: 'default' },
  { id: '9', scale: 300, adjust: 'default' },
  { id: '10', scale: 400, adjust: 'default' },
];
export const MAX_ZOOM = 800;
export const MIN_ZOOM = 10;
export const SCROLL_SENSITIVITY = 0.0005;

const WideScreenPdfSectionMenu = (): JSX.Element | null => {
  const setInitialScale = isMobile ? pdfScaleValues[2] : pdfScaleValues[4];

  const [percentageForPdfContainer, setPercentageForPdfContainer] = useState<{
    id: string | '',
    scale: number,
    adjust: 'height' | 'width' | 'default'
  }>(setInitialScale);
  const [percentageFromPdfContainer, setPercentageFromPdfContainer] = useState<{
    id: string | '',
    scale: number,
    adjust: 'height' | 'width' | 'default'
  }>(setInitialScale);

  const dispatchRescalePdfEvent = (pdfScaleObject: { id: string, scale: number }) => {
    document.dispatchEvent(new CustomEvent(
      ECustomEventsNames.PDF_SCALE,
      {
        detail: {
          id: pdfScaleObject.id,
          scale: pdfScaleObject.scale,
          adjust: 'default',
        },
      },
    ));
  };

  useEffect(() => {
    dispatchRescalePdfEvent(percentageForPdfContainer);
  }, [percentageForPdfContainer]);

  useEffect(() => {
    const onEvent = (e: CustomEvent) => {
      setPercentageFromPdfContainer(e.detail);
    };
    document.addEventListener(ECustomEventsNames.PDF_SCALE_FOR_BUTTONS, onEvent as EventListener);
    return () => {
      document.removeEventListener(ECustomEventsNames.PDF_SCALE_FOR_BUTTONS, onEvent as EventListener);
    };
  }, [percentageFromPdfContainer]);

  const dispatchAdjustPdfEvent = (adjust: string) => {
    document.dispatchEvent(new CustomEvent(ECustomEventsNames.PDF_SCALE, { detail: { id: '', scale: null, adjust } }));
  };

  const handleAdjust = () => {
    if (percentageFromPdfContainer.adjust === 'width') {
      dispatchAdjustPdfEvent('height');
    } else {
      dispatchAdjustPdfEvent('width');
    }
  };

  const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    const result = pdfScaleValues.filter((item) => item.id === event.target.value);
    setPercentageForPdfContainer(result[0]);
  };

  const handleScalePlusButton = () => {
    if (Math.floor(percentageFromPdfContainer.scale) > MAX_ZOOM - 100) {
      setPercentageForPdfContainer({ id: '', scale: MAX_ZOOM, adjust: 'default' });
    } else if (Math.floor(percentageFromPdfContainer.scale) > 200) {
      const dataForScaleObject: number = Math.floor(
        percentageFromPdfContainer.scale
        + Math.floor(percentageFromPdfContainer.scale / 100) * 10,
      );
      setPercentageForPdfContainer({ id: '', scale: dataForScaleObject, adjust: 'default' });
    } else if (percentageFromPdfContainer.scale > 0) {
      const dataForScaleObject: number = Math.floor(percentageFromPdfContainer.scale + 10);
      setPercentageForPdfContainer({ id: '', scale: dataForScaleObject, adjust: 'default' });
    }
  };

  const handleScaleMinusButton = () => {
    if (percentageFromPdfContainer.scale <= MIN_ZOOM + 10) {
      setPercentageForPdfContainer({ id: '', scale: MIN_ZOOM, adjust: 'default' });
    } else if (percentageFromPdfContainer.scale <= 100) {
      const dataForScaleObject = Math.floor(percentageFromPdfContainer.scale - 10);
      setPercentageForPdfContainer({ id: '', scale: dataForScaleObject, adjust: 'default' });
    } else if (percentageFromPdfContainer.scale <= MAX_ZOOM) {
      const dataForScaleObject = Math.floor(percentageFromPdfContainer.scale - Math.floor(percentageFromPdfContainer.scale / 100) * 10);
      setPercentageForPdfContainer({ id: '', scale: dataForScaleObject, adjust: 'default' });
    }
  };

  const dropDownElements = pdfScaleValues.map((item) => (
    <PdfMenuItem
      key={item.id}
      value={item.id}
    >
      {`${item.scale}%`}
      <CheckIcon20px />
    </PdfMenuItem>
  ));

  return (
    <>
      <AppIconButton
        onClick={handleScaleMinusButton}
        icon={<MinusIcon20 />}
      />
      <PdfMenuFormControl>
        <PdfMenuSelect
          renderValue={() => (`${percentageFromPdfContainer.scale}%`)}
          displayEmpty
          value={percentageFromPdfContainer.id}
          onChange={handleDropdownChange}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: '4px',
                marginLeft: '20px',
                borderRadius: '10px',
                border: '1px solid',
                width: '123px',
                backgroundColor: 'white',
                borderColor: `${stagesetColors.grey[400]} !important`,
                padding: '0 8px',
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiSvgIcon-root': {
                    visibility: 'visible',
                  },
                },
              },
            },
          }}
        >
          {dropDownElements}
        </PdfMenuSelect>
      </PdfMenuFormControl>
      <AppIconButton
        onClick={handleScalePlusButton}
        icon={<PlusIcon20 />}
      />
      <Separator />
      <Tooltip
        title={percentageFromPdfContainer.adjust === 'width' ? 'Full width' : 'Full height'}
        placement="bottom"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              background: stagesetColors.grey[400],
            },
          },
          arrow: {
            sx: {
              color: stagesetColors.grey[400],
            },
          },
        }}
      >
        <AppIconButton
          onClick={handleAdjust}
          icon={percentageFromPdfContainer.adjust === 'width' ? <AdjustByWidth /> : <AdjustByHeight />}
        />
      </Tooltip>
    </>
  );
};

export default WideScreenPdfSectionMenu;
