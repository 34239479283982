import { Checkbox } from '@mui/material';
import useProcessTimelineUsersFilter from '../../hooks/useProcessTimelineUsersFilter';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectIsUserFilterActive } from '../../features/ActivityLog/lib/processActivityLogSlice';
import { ProcessTimelineMenuItemSearchItem, ProcessTimelineMenuItemSearchItemTitle } from './Styles/ProcessTimelineStyles';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  id: string;
  name: string;
}

const ProcessTimeLineUsersFilterListItem = ({
  id,
  name,
}: Props) => {
  const { isSelected, changeUserFilterStatus } = useProcessTimelineUsersFilter(id);

  const isFilterActive = useAppSelector(selectIsUserFilterActive);

  return (
    <ProcessTimelineMenuItemSearchItem
      onClick={() => changeUserFilterStatus()}
    >
      <Checkbox
        disableRipple
        checked={isSelected && isFilterActive}
        sx={{
          padding: '0px',
          '& svg': {
            fill: isSelected && isFilterActive ? stagesetColors.newGrey[800] : stagesetColors.newGrey[500],
          },
        }}
      />
      <ProcessTimelineMenuItemSearchItemTitle>
        {name}
      </ProcessTimelineMenuItemSearchItemTitle>
    </ProcessTimelineMenuItemSearchItem>
  );
};

export default ProcessTimeLineUsersFilterListItem;
