import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import SideMenuBottom from './SideMenuBottom';
import SideMenuContent from './SideMenuContent';
import {
  SideMenu, SideMenuUpperSection,
} from './SideMenuStyles';
import SideMenuLogoSection from './SideMenuLogoSection';

const CommonSideMenu = () => {
  const { pathname } = useLocation();

  const isHidden = useMemo(() => pathname.split('/').includes('deal'), [pathname]);

  if (isHidden) {
    return null;
  }

  return (
    <SideMenu>
      <SideMenuUpperSection>
        <SideMenuLogoSection />
        <SideMenuContent />
      </SideMenuUpperSection>
      <SideMenuBottom />
    </SideMenu>
  );
};

export default CommonSideMenu;
