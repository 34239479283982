import classNames from 'classnames';
import { AppBaseButton, AppBaseButtonProps } from './AppBaseButton';

type AppIconButtonSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'l-ds-icon' | 'xl' | 'xxl';

export type AppIconButtonProps = {
  size?: AppIconButtonSize,
  icon: JSX.Element | string,
} & Omit<AppBaseButtonProps, 'children' | 'startIcon' | 'endIcon'>;

export const AppIconButton = ({ icon, className, ...buttonProps }: AppIconButtonProps) => (
  <AppBaseButton {...buttonProps} className={classNames('icon-button', className)}>
    {icon}
  </AppBaseButton>
);
