import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { useAppSelector } from '../../../hooks/stateHooks';
import { EWSDealEvents } from '../../../../shared/websocket/websocket';
import { AdminWSCommands } from '../../../../shared/admin/Commands';
import { selectUserId } from '../../../core/store/appState/appState';
import { selectAuthToken } from '../../auth/state/authState';
import { config } from '../../../core';

const AdminRoomStats = (): JSX.Element => {
  const token = useAppSelector(selectAuthToken);
  const userId = useAppSelector(selectUserId);

  const [roomId, setRoomId] = useState<string>('');
  const [socketId, setSocketId] = useState<string>('');
  const [stageId, setStageId] = useState<string>('');
  const [newItems, setNewItems] = useState<string>('');
  const [socket, setSocket] = useState<undefined | Socket>(undefined);
  const [processData, setProcessData] = useState<null | { [key: string]: any }>(null);

  const onWsMessage = (name, data) => {
    setProcessData(data);
  };

  const onConnect = () => {
    const s = io(config.api.wsHref, {
      query: {
        roomId,
        token,
        userId,
      },
    });
    s.connect();
    s.onAny((message: EWSDealEvents, event: any) => {
      onWsMessage(message, event);
    });
    setSocket(s);
  };

  const onDisconnect = () => {
    socket?.disconnect();
    setProcessData(null);
    setSocket(undefined);
  };

  const onGetStats = () => {
    socket?.emit(
      'adminCommand',
      {
        command: AdminWSCommands.ROOM_STATS,
        processId: roomId,
      },
    );
  };

  const forceStageUpdate = () => {
    socket?.emit(
      'adminCommand',
      {
        command: AdminWSCommands.FORCE_STAGE_ITEMS_UPDATE,
        processId: roomId,
        newItems,
        stageId,
        forSocket: socketId,
      },
    );
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '16px',
        width: '100%',
        '& .fieldName': {
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500,
          width: '85px',
        },
        '& .statsItemWrapper': {
          background: 'white',
          border: '1px solid #c3d2d9',
          borderRadius: '12px',
          padding: '8px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <Typography
          className="fieldName"
        >
          Room ID
        </Typography>
        <AppInput
          onChange={(e) => setRoomId(e.target.value)}
          value={roomId}
        />
        <AppButton
          color="primary"
          size="m"
          onClick={socket ? onDisconnect : onConnect}
        >
          {socket ? 'Disconnect' : 'Connect'}
        </AppButton>
        {
          socket
          && (
            <AppButton
              color="primary"
              size="m"
              onClick={onGetStats}
            >
              Get Room stats
            </AppButton>
          )
        }
      </Box>
      <Box
        className="statsItemWrapper"
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          User sockets
        </Typography>
        {
        processData?.users
        && Object.keys(processData.users).map((key) => (
          <Box
            key={`user-${key}`}
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              {key}
              :
            </Typography>
            {
              processData.users[key].map((userSocketId: string) => (
                <Typography
                  key={`socket-${userSocketId}`}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setSocketId(userSocketId)}
                >
                  {userSocketId}
                </Typography>
              ))
        }
          </Box>
        ))
        }
      </Box>
      <Box
        className="statsItemWrapper"
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          Socket stages
        </Typography>
        {
        processData?.usersStage
        && Object.keys(processData.usersStage).map((key) => !!processData.usersStage[key].length && (
          <Box
            key={`user-${key}`}
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              {key}
              :
            </Typography>
            {
              processData.usersStage[key].map((socketStageId: string) => (
                <Typography
                  key={`socket-${socketStageId}`}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => { setStageId(socketStageId); setSocketId(key); }}
                >
                  {socketStageId}
                </Typography>
              ))
        }
          </Box>
        ))
        }
      </Box>
      <Box
        className="statsItemWrapper"
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          Stage users
        </Typography>
        {
        processData?.stageUsers
        && Object.keys(processData.stageUsers).map((key) => !!processData.stageUsers[key].length && (
          <Box
            key={`user-${key}`}
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              {key}
              :
            </Typography>
            {
              processData.stageUsers[key].map((sId: string) => (
                <Typography
                  key={`stage-${sId}`}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => { setStageId(key); setSocketId(sId); }}
                >
                  {sId}
                </Typography>
              ))
        }
          </Box>
        ))
        }
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <Typography
          className="fieldName"
        >
          Page ID
        </Typography>
        <AppInput
          onChange={(e) => setStageId(e.target.value)}
          value={stageId}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <Typography
          className="fieldName"
        >
          Socket ID
        </Typography>
        <AppInput
          onChange={(e) => setSocketId(e.target.value)}
          value={socketId}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <Typography
          className="fieldName"
        >
          New Items
        </Typography>
        <AppInput
          onChange={(e) => setNewItems(e.target.value)}
          value={newItems}
        />
      </Box>
      <AppButton
        onClick={forceStageUpdate}
      >
        Send
      </AppButton>
    </Box>
  );
};

export default AdminRoomStats;
