import { createContext, useContext } from 'react';

export const MobileTableContext = createContext({ user: 'Dima' });

export const TeamTabTableContextProvider = ({
  children,
  props,
}: any): JSX.Element => (
  <MobileTableContext.Provider value={props}>
    {children}
  </MobileTableContext.Provider>
);

export const useTeamTabTableContext = () => {
  const c = useContext(MobileTableContext);
  return c;
};
