import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { EAppRoutes } from '../../core/router';
import MenuLogo from '../../icons/MenuLogo.svg';
import {
  SideMenuLogo, LogoNavLink, LogoWrapper, SideMenuTitle,
} from './SideMenuStyles';

const SideMenuLogoSection = () => (
  <LogoWrapper
    className={classNames({ 'logo-wrapper-mobile': isMobile })}
  >
    <LogoNavLink
      to={`${EAppRoutes.home}`}
      sx={{
        textDecoration: 'none',
      }}
    >
      <SideMenuLogo>
        <MenuLogo />
      </SideMenuLogo>
      {isMobile && (
        <SideMenuTitle>
          Stageset
        </SideMenuTitle>
      )}
    </LogoNavLink>
  </LogoWrapper>
);

export default SideMenuLogoSection;
