import {
  Box,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../common/DeleteTypography';
import { useAppDispatch } from '../../hooks/stateHooks';
import { leaveDeal } from '../BuyerDeals/state/buyerDealsSlice';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { ThreeDotsVert20px } from '../../icons/ThreeDots';
import { ECreateTemplateRequestType, EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import Trash from '../../icons/Trash.svg';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import useModalWindow from '../../hooks/useModalWindow';
import CollectTemplateInfoDialog, {
  ICreateTemplatePopup,
} from '../../features/CollectTemplateInfoDialog/CollectTemplateInfoDialog';
import { createTemplate } from '../../pages/templates/lib/templatesSlice';
import { BookOpenIcon20 } from '../../icons/BookOpenIcon';

interface Props {
  id: string;
  title: string;
}

const JoinedDealsListMenu = ({
  id,
  title,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isLeaveDealDialogOpen, setLeaveDealDialogOpen] = useState<boolean>(false);
  const { setTemplateModalWindowState, onModalWindowStateReset } = useModalWindow();
  const [collectDialog, setCollectDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onCreateTemplate = async (name: string) => {
    try {
      setTemplateModalWindowState();
      const template = await dispatch(createTemplate({
        title: name,
        type: EProcessType.TEMPLATE,
        templateId: id,
        createType: ECreateTemplateRequestType.DEAL,
      })).unwrap();
      if (template) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      onModalWindowStateReset();
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    // trackEvent(EMixPanelEvents.THREE_DOTS_CLICKED);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleLeaveClick = (): void => {
    handleClose();
    setLeaveDealDialogOpen(true);
  };

  const onLeaveConfirm = (processId: string): void => {
    setLeaveDealDialogOpen(false);
    dispatch(leaveDeal(processId));
  };

  const handleCreateClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setCollectDialog(true);
    handleClose();
  };

  const onCollectedTemplateInfo = (data: ICreateTemplatePopup) => {
    onCreateTemplate(data.name);
  };

  const leaveTypography = (
    <DeleteTypography>
      Are you sure you want to leave this deal?
    </DeleteTypography>
  );

  return (
    <>
      <AppIconButton
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleOpen}
        transparentStroke
        icon={<ThreeDotsVert20px />}
      />
      <AppDropdown
        items={[
          {
            label: 'Create Template',
            onClick: handleCreateClick,
            startIcon: <BookOpenIcon20 />,
            hidden: false,
          },
          {
            label: '',
            divider: true,
          },
          {
            label: 'Leave Deal',
            onClick: handleLeaveClick,
            startIcon: <Trash />,
          },
        ]}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocus
        disableAutoFocusItem
        lastElementHasAnotherColor
      />
      <ConfirmationDialog
        titleVariant="danger-primary"
        isDialogOpen={isLeaveDealDialogOpen}
        dialogTitle="Leave Deal"
        dialogContentText={leaveTypography}
        onCancel={() => setLeaveDealDialogOpen(false)}
        onConfirm={() => onLeaveConfirm(id)}
      />
      <CollectTemplateInfoDialog open={collectDialog} setOpen={setCollectDialog} onSubmit={onCollectedTemplateInfo} />
    </>
  );
};

export default JoinedDealsListMenu;
