import { Box, styled } from '@mui/material';

const TiptapStyledWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .tiptap-node-wrapper-canvas': {
    padding: '4px 0px',
  },
  '&.multiselect': {
    '& .selectedNode': {
      background: '#E5EDFF',
    },
  },
  '& .tiptap-list-item': {
    '& .nodeControls': {
      display: 'none',
    },
  },
  '&.TiptapEditorWrapper': {
    '& .column-resize-handle': {
      display: 'none',
      width: '0px',
      right: '-2px',
      top: 0,
      bottom: '-2px',
    },
    '&.editable': {
      '& .column-resize-handle': {
        backgroundColor: '#E2E8F0',
        pointerEvents: 'none',
        position: 'absolute',
        right: '-2px',
        top: 0,
        width: '2px',
        borderRadius: '1px',
        display: 'block',
      },
      '& .resize-cursor': {
        cursor: 'col-resize',
      },
      '& tr': {
        '&:hover': {
          '& p.is-empty::before': {
            color: '#adb5bd',
            float: 'left',
            height: 0,
            pointerEvents: 'none',
            fontWeight: 400,
          },
        },
      },
    },
    maxWidth: '800px',
    '&:has(.WrappedNode-mobile)': {
      maxWidth: 'unset',
    },
    '&.widescreen': {
      maxWidth: 'unset !important',
    },
    '& .tippy-box': {
      maxWidth: 'unset !important',
    },
    '& div[id^=tippy]': {
      zIndex: '4 !important',
    },
    '& .tiptap': {
      border: 'none',
      outline: 'none',
    },
    '& .WrappedNode': {
      margin: '0px',
      wordBreak: 'break-word',
    },
    '&.mobile': {
      '& table': {
        display: 'flex',
        '& colgroup': {
          display: 'none',
        },
        '& tbody:not(.total-table-body-mobile)': {
          '& tr': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },
    '& .tableWrapper': {
      width: '100%',
      '& table': {
        borderSpacing: '0px',
        width: '100%',
        tableLayout: 'fixed',
        '& tr': {
          width: '100%',
          '& th': {
            verticalAlign: 'top',
            width: '100%',
            position: 'relative',
          },
        },
        '& td': {
          verticalAlign: 'top',
        },
      },
    },
    '& .tiptap-column-table': {
      borderSpacing: '0px',
      width: '100%',
      tableLayout: 'fixed',
      '& tr': {
        width: '100%',
        '& th': {
          verticalAlign: 'top',
          width: '100%',
          position: 'relative',
        },
      },
      '& td': {
        verticalAlign: 'top',
      },
    },
    '&:not(.widescreen)': {
      '& .react-renderer': {
        margin: '0px 8px',
      },
    },
    '& .node-paragraph': {
      '& p.is-empty::before': {
        color: 'transparent',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
        fontWeight: 400,
      },
      '& .selectedNode .is-empty::before': {
        color: '#adb5bd',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
        fontWeight: 400,
      },
      '&.is-editor-empty p.is-empty::before': {
        color: '#adb5bd',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
        fontWeight: 400,
      },
      '&:hover': {
        '& p.is-empty::before': {
          color: '#adb5bd',
          float: 'left',
          height: 0,
          pointerEvents: 'none',
          fontWeight: 400,
        },
      },
      '& .WrappedNode': {
        width: '100%',
        '&.text-align-left': {
          textAlign: 'left',
        },
        '&.text-align-center': {
          textAlign: 'center',
        },
        '&.text-align-right': {
          textAlign: 'right',
        },
        '&.text-align-justify': {
          textAlign: 'justify',
        },
        '& *': {
          width: '100%',
          color: theme.palette.grey[800],
          fontSize: '16px',
          lineHeight: '22px',
          textAlign: 'inherit',
          fontWeight: 400,
          '& strong': {
            fontWeight: 600,
          },
        },
      },
    },
    '& .node-heading': {
      '&:not(:first-of-type)': {
        marginTop: '12px',
      },
      '& .h1': {
        '& .nodeControls': {
          marginTop: '10px',
        },
      },
      '& .h2': {
        '& .nodeControls': {
          marginTop: '6px',
        },
      },
      '& .WrappedNode': {
        width: '100%',
        '&.text-align-left': {
          textAlign: 'left',
        },
        '&.text-align-center': {
          textAlign: 'center',
        },
        '&.text-align-right': {
          textAlign: 'right',
        },
        '&.text-align-justify': {
          textAlign: 'justify',
        },
      },
      '& .level-1 > *': {
        color: theme.palette.grey[800],
        fontSize: '24px',
      },
      '& .level-2 > *': {
        color: theme.palette.grey[800],
        fontSize: '20px',
      },
      '& .level-3 > *': {
        color: theme.palette.grey[800],
        fontSize: '16px',
      },
      '& .level-4 > *': {
        color: theme.palette.grey[800],
        fontSize: '24px',
      },
      '& .level-5 > *': {
        color: theme.palette.grey[800],
        fontSize: '22px',
      },
      '& .level-6 > *': {
        fontWeight: 600,
        color: theme.palette.grey[800],
        fontSize: '20px',
      },
    },
  },
}));

export default TiptapStyledWrapper;
