import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import EmbedNodeItems from './EmbedNodeItems';
import AppTabs from '../../../../shared/AppTabs/AppTabs';
import { useAppSelector } from '../../../../hooks/stateHooks';
import EmbedNodeMenuLibrary from './EmbedNodeMenuLibrary';
import { LibraryFileDTO } from '../../../../../shared/library/LibraryDTO';
import useEditorEvent from '../../Hooks/useTiptapEditorEvents';
import { TiptapCustomEvents } from '../../TiptapEventListnerPlugin';
import { ExtensionNames } from '../../Utils/TiptapHelpers';
import { EFieldsubType, EMediaFieldStorageType } from '../../../../../shared/Field/Field';
import { ContentType } from '../../../../../shared/Stage';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import ChevronLeft from '../../../../icons/ChevronLeft.svg';

enum EmbedMenuState {
  DEFAULT = 'DEFAULT',
  LIBRARY = 'LIBRARY',
}

interface Props {
  handleClose: () => void;
  goBack: () => void;
  position?: number;
  items: string;
}

const EmbedNodeMenu = ({
  handleClose,
  position,
  goBack,
  items,
}: Props): JSX.Element => {
  const isShared: boolean = useAppSelector((state) => state.auth?.isSharedUser);
  const [menuState, setMenuState] = useState(EmbedMenuState.DEFAULT);
  const { dispatchEditorEvent } = useEditorEvent();

  useEffect(() => {
    setMenuState(EmbedMenuState.DEFAULT);
  }, [items]);

  const getLibraryLinkSubtype = (linkType: ContentType | undefined) => {
    if (linkType !== ContentType.URL) {
      return EFieldsubType.MIXED;
    }
  };

  const handleLibraryItemAdd = (libraryItem: LibraryFileDTO) => {
    dispatchEditorEvent(
      {
        position,
        eventType: TiptapCustomEvents.CREATE,
        type: ExtensionNames.MEDIA,
        attrs: {
          subType: getLibraryLinkSubtype(libraryItem.linkType),
          storageType: EMediaFieldStorageType.EXTERNAL,
          src: libraryItem.fileUid,
        },
      },
    );
    handleClose();
  };

  const content = (): JSX.Element | null => {
    switch (menuState) {
      case EmbedMenuState.LIBRARY:
        return (
          <EmbedNodeMenuLibrary
            onLibraryItemAdd={handleLibraryItemAdd}
          />
        );
      default:
        return (
          <EmbedNodeItems
            handleClose={handleClose}
            goBack={goBack}
            position={position}
          />
        );
    }
  };

  const handleStateChange = (
    newState: EmbedMenuState,
  ) => {
    setMenuState(newState);
  };

  return (
    <>
      <Box
        onClick={(e) => { e.stopPropagation(); goBack(); }}
        sx={{
          width: '100%',
          height: '52px',
          paddingX: '16px',
          borderBottom: '1px solid #EEF0FA',
          background: '#FCFDFD',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          cursor: 'pointer',
        }}
      >
        <AppIconButton icon={<ChevronLeft />} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
          }}
        >
          Embed
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          {!isShared && (
            <AppTabs
              color="white"
              variant="boxedNew"
              size="l-new"
              value={menuState}
              onChange={(option) => {
                handleStateChange(option.value);
              }}
              options={[
                {
                  title: 'Default', value: EmbedMenuState.DEFAULT,
                },
                {
                  title: 'From Library', value: EmbedMenuState.LIBRARY,
                },
              ]}
            />
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          {content()}
        </Box>
      </Box>
    </>
  );
};

export default EmbedNodeMenu;
