import { useMemo } from 'react';
import classNames from 'classnames';
import { LoadingButtonProps } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { StyledAppButton } from './AppButtonStyles';
import { AppLoader } from '../AppLoader/AppLoader';

type AppBaseButtonSize =
  | 'xxs'
  | 'xs'
  | 's'
  | 's-without-padding'
  | 'm'
  | 'm-auto'
  | 'm-minWidth'
  | 'l'
  | 'l-auto'
  | 'l-ds'
  | 'l-ds-icon'
  | 'l-flex-start'
  | 'l-flex-start-new-page'
  | 'l-grid-span-full'
  | 'l-minWidth'
  | 'l-contactCard'
  | 'l-contactCard-mobile'
  | 'xl'
  | 'xl-flex-start'
  | 'xl-flex-start-with-padding'
  | 'xxl'
  | 'xxxl-flex-start-new-page';

type AppBaseButtonComponent = 'label';

export type AppBaseButtonVariant =
  | 'accent'
  | 'buyer-burger-button-themed'
  | 'danger'
  | 'danger-primary'
  | 'danger-secondary'
  | 'danger-transparent'
  | 'gradient'
  | 'lite-primary'
  | 'lite-danger'
  | 'lite-secondary'
  | 'outline'
  | 'primary'
  | 'primary-newGrey'
  | 'primary-grey'
  | 'primary-reverse'
  | 'primary-success'
  | 'secondary'
  | 'secondary-newGrey'
  | 'secondary-disabled'
  | 'secondary-black-icon'
  | 'secondary-success'
  | 'transparent'
  | 'transparent-newGrey'
  | 'transparent-grey'
  | 'transparent-grey-icon'
  | 'transparent-contactCard'
  | 'transparent-black'
  | 'transparent-white'
  | 'transparent-without-stroke'
  | 'transparent-black-icon'
  | 'tertiary'
  | 'tertiary-newGrey'
  | 'tertiary-newGrey-ds-left'
  | 'tertiary-newGrey-ds-right';

export type AppBaseButtonProps = {
  size?: AppBaseButtonSize,
  variant?: AppBaseButtonVariant,
  component?: AppBaseButtonComponent,
  transparentStroke?: boolean,
  initialStroke?: boolean,
  autoWidth?: boolean,
  fixedColors?: boolean,
} & Omit<LoadingButtonProps, 'variant' | 'size'>;

type DynamicProps = Pick<AppBaseButtonProps, 'startIcon' | 'endIcon' | 'disabled' | 'children' | 'loading'>;

export const AppBaseButton = ({
  size = 'm',
  children,
  startIcon,
  endIcon,
  disabled,
  loading,
  className,
  variant = className?.includes('link-button') ? 'lite-primary' : 'transparent',
  initialStroke,
  transparentStroke,
  autoWidth,
  fixedColors,
  ...restProps
}: AppBaseButtonProps) => {
  const dynamicProps = useMemo<DynamicProps>(() => {
    if (loading) {
      return {
        startIcon: undefined,
        endIcon: undefined,
        disabled: true,
        children: null,
        loading: true,
      };
    }
    return {
      startIcon,
      endIcon,
      disabled,
      children,
      loading: false,
    };
  }, [loading, disabled, endIcon, startIcon, children]);

  return (
    <StyledAppButton
      className={classNames(`size-${size} variant-${variant}`, {
        disabled: dynamicProps.disabled,
        mobile: isMobile,
        hidden: restProps.hidden,
        'fixed-colors': fixedColors,
        'initial-stroke': initialStroke,
        'auto-width': autoWidth,
      }, className)}
      disableRipple
      loadingIndicator={<AppLoader size={20} />}
      {...restProps}
      {...dynamicProps}
    />
  );
};
