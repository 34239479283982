import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import LogoSidePanelWrapper from './LogoSidePanelWrapper';
import CompanyLogo from '../../common/CompanyLogo';
import { ELogoType, EProcessType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppSelector } from '../../hooks/stateHooks';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import useDealPermissions from '../../hooks/useDealPermissions';
import useDealCreationData from '../TemplatePicker/lib/useDealCreationDataSlice';

const LogoSidePanel = ({ isLoaded }: { isLoaded: boolean; }): JSX.Element | null => {
  const process: ProcessDTO = useAppSelector((state) => state.process.process);
  const { clientLogo, creatorLogo } = useDealCreationData();
  const [checkPermissions] = useDealPermissions();
  const isSummaryEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_SUMMARY_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );

  if (!(process?.buyerLogoSrc || process?.sellerLogoSrc || isSummaryEditable)) {
    return null;
  }

  return (
    <LogoSidePanelWrapper>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: isMobile ? 'flex-start' : 'center',
          borderRadius: '16px',
          '& .SellerLogoItemDisplay': {
            marginLeft: process?.buyerLogoSrc ? '-8px' : '0px',
          },
        }}
      >
        <CompanyLogo
          src={process.type === EProcessType.DEAL ? process?.buyerLogoSrc : clientLogo}
          logoType={ELogoType.BuyerLogo}
          isEditable={isSummaryEditable}
          logoTitle="Partner logo"
          isLoaded={isLoaded}
        />
        <CompanyLogo
          src={process.type === EProcessType.DEAL ? process?.sellerLogoSrc : creatorLogo}
          className="SellerLogoItemDisplay"
          logoType={ELogoType.SellerLogo}
          isEditable={isSummaryEditable}
          logoTitle="Seller logo"
          isLoaded={isLoaded}
        />
      </Box>
    </LogoSidePanelWrapper>
  );
};

export default LogoSidePanel;
