import { TabPanel } from '@mui/lab';
import {
  Box,
  MenuItem,
  MenuList,
  styled,
  Typography,
} from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { AppSkeleton } from '../../../shared/AppSkeleton/AppSkeleton';

export const TemplatesTabPanel = styled(TabPanel)(() => ({
  height: '100%',
  width: '100%',
  padding: 0,
}));

export const TemplateTabItem = styled(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '74px',
  width: '100%',
  borderRadius: '12px',
  padding: '16px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.white[100],
  '&.template-item-selected': {
    backgroundColor: stagesetColors.newGrey[50],
  },
  '&:hover': {
    borderColor: stagesetColors.newGrey[400],
    backgroundColor: stagesetColors.newGrey[50],
  },
  '&.template-item-mobile': {
    height: '102px',
    minHeight: '102px',
  },
  '&.template-skeleton': {
    '&:hover': {
      borderColor: stagesetColors.newGrey[200],
      backgroundColor: stagesetColors.white[100],
    },
  },
}));

export const TemplateTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
  wordBreak: 'break-word',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const TemplateSubtitle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14px',
  color: stagesetColors.newGrey[500],
  '&.template-mobile-description-typography': {
    fontSize: '14px',
    lineHeight: '16px',
  },
  '&.template-mobile-time-typography': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: stagesetColors.newGrey[800],
  },
}));

export const TemplateContentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: 'calc(100% - 36px)',
  '&.template-content-container-mobile': {
    width: '100%',
  },
}));

export const TemplateTabList = styled(MenuList)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  gap: '8px',
  overflow: 'auto',
  '&.template-menu-list-mobile': {
    height: 'calc(100% - 48px)',
    padding: '0px 16px 8px 16px',
  },
}));

export const TemplateUpperWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  '&.template-upper-wrapper-mobile': {
    justifyContent: 'space-between',
  },
}));

export const TemplateBottomWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  '&.template-bottom-wrapper-mobile': {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '4px',
  },
}));

export const TemplateTextSkeleton = styled(AppSkeleton)(() => ({
  '&.template-desktop-title': {
    maxWidth: '160px',
    width: '100%',
    height: '22px',
    borderRadius: '16px',
  },
  '&.template-mobile-title': {
    maxWidth: '240px',
    width: '100%',
    height: '32px',
    borderRadius: '16px',
  },
  '&.template-desktop-description-typography': {
    maxWidth: '88px',
    width: '30%',
    height: '16px',
    borderRadius: '16px',
  },
  '&.template-mobile-description-typography': {
    maxWidth: '88px',
    width: '30%',
    height: '16px',
    borderRadius: '16px',
  },
  '&.template-mobile-time-typography': {
    maxWidth: '98px',
    width: '50%',
    height: '22px',
    borderRadius: '16px',
  },
}));
