import classNames from 'classnames';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AppButton } from '../../shared/AppButton/AppButton';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import {
  ESidebarTabs,
  selectSidebarTab,
  setNewPageEmbedTab,
  setSidebarTab,
} from '../Layout/lib/fieldsLayoutSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../hooks/stateHooks';
import { CloseIcon20 } from '../../icons/CloseIcon';
import {
  HeaderTitle,
  SidebarHeader,
  SidebarTabContentWrapper,
  SidebarTabPanel,
} from '../Stages/Styles/SidebarStyles';
import {
  NewPageButtonSubtitle,
  NewPageButtonTextWrapper,
  NewPageButtonTitle,
  NewPageMainTabItemTitle,
  NewPageMenuContainer,
} from './Styles/NewPageMainTabStyles';
import {
  MediaSource,
  mediaSources,
} from '../MediaField/Helpers/MediaFieldHelpers';
import { newPageMainItems } from './Helpers/NewPageHelpers';
import { selectCurrentStageNeedsToBeUpdated } from '../BlockContentSection/lib/sectionsSlice';

const NewPageMainTab = () => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(selectSidebarTab) === ESidebarTabs.NEWPAGE;
  const mediaId = useAppSelector(selectCurrentStageNeedsToBeUpdated);

  const onClose = () => {
    dispatch(setSidebarTab(ESidebarTabs.STAGES));
  };

  const handleClick = (item: MediaSource) => {
    dispatch(setNewPageEmbedTab(item.contentTag));
    dispatch(setSidebarTab(ESidebarTabs.EMBED));
  };

  const mainMenuButtons = newPageMainItems.map((item) => (
    <AppButton
      fullWidth
      disableRipple
      variant="transparent-grey-icon"
      size="xxxl-flex-start-new-page"
      key={item.key}
      startIcon={item.startIcon}
      onClick={() => dispatch(setSidebarTab(item.sidebarTab))}
    >
      <NewPageButtonTextWrapper>
        <NewPageButtonTitle>{item.label}</NewPageButtonTitle>
        <NewPageButtonSubtitle>{item.subTitle}</NewPageButtonSubtitle>
      </NewPageButtonTextWrapper>
    </AppButton>
  ));

  const getEmbedOptions = () => {
    let typeTag = '';
    let newTypeTag = '';
    let header = false;

    return mediaSources.map((source: MediaSource) => {
      newTypeTag = source.typeTag ?? '';
      header = false;
      if (newTypeTag !== typeTag) {
        typeTag = newTypeTag;
        header = true;
      }
      return (
        <Box
          key={source.name}
        >
          {
            header
            && !isMobile
            && (
              <NewPageMainTabItemTitle>
                {typeTag}
              </NewPageMainTabItemTitle>
            )
          }
          <AppButton
            fullWidth
            disableRipple
            variant="transparent-without-stroke"
            size="xl-flex-start-with-padding"
            key={source.name}
            startIcon={source.icon}
            onClick={() => handleClick(source)}
          >
            <NewPageButtonTitle>{source.name}</NewPageButtonTitle>
          </AppButton>
        </Box>
      );
    });
  };

  return (
    <SidebarTabPanel
      value={ESidebarTabs.NEWPAGE}
      className={classNames('sidebar-new-page', { 'active-sidebar-tab': currentTab })}
    >
      <SidebarTabContentWrapper
        className="sidebar-wrapper-default"
      >
        <SidebarHeader>
          <HeaderTitle
            className="grey-header-title"
          >
            {mediaId ? 'Edit page' : 'Add page'}
          </HeaderTitle>
          <AppIconButton
            disableRipple
            icon={<CloseIcon20 />}
            onClick={() => onClose()}
          />
        </SidebarHeader>
        <NewPageMenuContainer>
          {mainMenuButtons}
          {getEmbedOptions()}
        </NewPageMenuContainer>
      </SidebarTabContentWrapper>
    </SidebarTabPanel>
  );
};

export default NewPageMainTab;
