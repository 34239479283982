import { Typography, Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { AppSwitch } from '../../shared/AppSwitch/AppSwitch';
import { selectTimelineTeamfilter, setTimelinesTeamFilter } from '../../features/ActivityLog/lib/processActivityLogSlice';
import { stagesetColors } from '../../theme/stagesetPalette';

const ProcessTimelineTeammatesFilter = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isFilterActive = useAppSelector(selectTimelineTeamfilter);

  const onFilterChange = () => {
    dispatch(setTimelinesTeamFilter(!isFilterActive));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <AppSwitch
        onChange={onFilterChange}
        checked={isFilterActive}
      />
      <Typography
        sx={{
          color: stagesetColors.newGrey[800],
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 600,
        }}
      >
        Show team members
      </Typography>
    </Box>
  );
};

export default ProcessTimelineTeammatesFilter;
