import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const SettingsLayout = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: stagesetColors.newGrey[100],
  '& .mobile-settings-layout': {
    flexDirection: 'column',
  },
}));

export const SettingsScrollableContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc( 100% )',
  width: 'calc( 100% )',
  overflow: 'auto',
  '&.mobile-settings-scrollable-container': {
    height: 'calc( 100% - 48px )',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  // '@-moz-document url-prefix()': {
  //   scrollbarWidth: 'none',
  // },
  '&.settings-scrollable-container-active': {
    '&::-webkit-scrollbar-thumb': {
      visibility: 'visible',
    },
    // '@-moz-document url-prefix()': {
    //   scrollbarWidth: 'thin',
    // },
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
    backgroundColor: '#c7c7c7',
    opacity: '50%',
    visibility: 'hidden',
    '&:hover': {
      visibility: 'visible',
    },
  },
  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
  },
}));

export const SettingsTabsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SettingsCardsTabPanel = styled(TabPanel)(() => ({
  height: '100%',
  width: '100%',
  padding: 0,
  '&.settings-cards-mobile': {
    width: '100vw',
  },
}));

export const SettingsCardsHeadingWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '6px',
  '&.heading-with-logo': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '&.mobile-heading-with-logo': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  '&.settings-heading-team-desktop': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const SettingsTabContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 32px',
  gap: '24px',
  '&.settings-content-wrapper-mobile': {
    padding: '12px 16px',
  },
  '&.settings-content-wrapper-appsumo': {
    gap: '40px',
  },
}));

export const SettingsTabCardsWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
}));

export const SettingsTabCardsGrid = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, max(300px, (100%/2 - 20px))), 1fr))',
  justifyContent: 'flex-start',
  width: '100%',
  maxWidth: '1080px',
  gridGap: '20px',
  '&.single-column-grid': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '&.cards-grid-aligned': {
    alignItems: 'start',
  },
}));

export const SettingsTabSubtitle = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
}));

export const SettingsTabSubtitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirectionL: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
}));

export const SettingsTabData = styled(Typography)(() => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const SettingsFormWrapper = styled(Box)(() => ({
  display: 'flex',
  borderRadius: '20px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.white[100],
  boxShadow: '0px 0px 0px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 3px 2px 0px rgba(30, 41, 59, 0.04)',
  padding: '24px',
  maxWidth: '520px',
  width: '100%',
  minWidth: '300px',
  gap: '24px',
  '&.settings-mobile': {
    padding: '16px',
  },
  '&.settings-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '&.settings-column': {
    display: 'flex',
    flexDirection: 'column',
  },
  '&.settings-grid-single-column': {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

export const SettingsCardsHeader = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 'normal',
  color: stagesetColors.newGrey[800],
}));

export const SettingsCardsDescription = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: 'normal',
  color: stagesetColors.newGrey[500],
}));
