import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { isMobile } from 'react-device-detect';
import { stagesetPalette } from '../theme/stagesetPalette';

export const AppDataGrid = styled(DataGrid)(() => ({
  width: '100%',
  border: 'none',
  borderRadius: '0 !important',
  '& .MuiDataGrid-main': {
    height: 'calc( 100vh - 160px )', // TODO calculate height propertly
    width: '100%',
    alignSelf: 'center',
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'none',
      borderTop: 'none',
      minHeight: '100px',
    },
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },

  '& .MuiDataGrid-columnHeaderWrapper': {
    paddingLeft: '16px',
  },
  // data container
  '& .MuiDataGrid-root, .MuiDataGrid-dataContainer': {
    minWidth: isMobile ? window.innerWidth : '900px !important',
  },
  // row
  '& .MuiDataGrid-row': {
    width: '100%',
    border: `solid 1px ${stagesetPalette.grey?.[100]}`,
    borderRadius: '10px',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  },
  '& .MuiDataGrid-root, .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: 0,
    paddingBottom: '12px',
  },
  // header
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeaderWrapper': {
    '&.MuiDataGrid-root, .MuiDataGrid-columnHeader--sortable, .MuiDataGrid-columnHeader': {
      padding: '0 5px',
      outline: 'none !important',
    },
  },
  // header column aligment
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeader--alignCenter, .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'flex-start',
    fontSize: '14px',
    textTransform: 'uppercase',
    '& .MuiDataGrid-columnHeaderTitle': {
      textTransform: 'none',
      fontWeight: '400',
      color: stagesetPalette.grey?.[400],
    },
  },
  // scrollbar
  '& .MuiDataGrid-root, .MuiDataGrid-window': {
    '&::-webkit-scrollbar': {
      overflow: 'auto',
      width: '7px',
      height: '7px',
      display: isMobile ? 'none' : '',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(128, 128, 128, .5)',
      opacity: '50%',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#7E7E7E',
    },
  },
  '& .MuiDataGrid-cell': {
    borderBottomColor: 'transparent',
  },
  // rows
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    left: 0,
    right: 0,
  },
  '& .MuiDataGrid-renderingZone': {
    width: '100%',
    // row
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #F1F1F1',
      width: '100%',
      height: '100%',
      color: 'black',
      borderRight: '1px solid #F9F9F9',
      alignItems: 'center',
      fontSize: '13px',
      ':hover': {
        background: '#F9F9F9',
        transition: '0.3s',
        '& .StyledMoreMenuButton': {
          visibility: 'visible',
          backgroundColor: '#F9F9F9',
        },
        '& .DealStatusText': {
          color: 'black',
        },
      },
      // cells
      '& .MuiDataGrid-cell, .pro-grid-cell, .MuiDataGrid-cell--textLeft': {
        padding: '0 0',
        border: 'none',
        height: '100%',
        outline: 'none !important',
        '& .RowLink': {
          width: '100%',
          padding: '0 11px',
          color: 'inherit',
          height: '51px',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  },
}));
