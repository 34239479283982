import { Box, ListItem, Typography } from '@mui/material';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import DeleteSectionSvg from '../../../../../icons/SectionMenuIcons/Delete';
import { AppIconButton } from '../../../../../shared/AppButton/AppIconButton';
import { AppLoader } from '../../../../../shared/AppLoader/AppLoader';
import { DocumentIcon } from '../../../../DocumentsField/DocumentIcon';
import DocReupload from '../../../../../icons/DocReupload.svg';
import { useFileItemSelect, useLibraryFile } from '../../../../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { UploadItemStatus } from '../../../../../../shared/FileItems/FileItems';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { DocumentsNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import useFrontEventsApi from '../../../../../hooks/useFrontEventsApi';

interface Props {
  id: string;
  fileIndex: number;
}

const DocumentNodeItem = ({
  id,
  fileIndex,
}: Props): JSX.Element | null => {
  const stateFile = useFileItemSelect(id);
  const [file, setFile] = useState(stateFile);
  const { data, onUpdate } = useBlockContext<DocumentsNodeAttrs>();
  const { createDealFileDownLoadEvent, createDealFileDeletedEvent } = useFrontEventsApi();

  const setFileFromLib = async () => {
    const fileItem = await useLibraryFile(id);
    setFile(fileItem);
  };

  useEffect(() => {
    if (typeof id === 'string' && id?.startsWith('library_')) {
      setFileFromLib();
    } else {
      setFile(stateFile);
    }
  }, [id, stateFile]);

  if (!file) {
    return null;
  }

  const {
    name, size, url, status,
  } = file;

  const renderSize = (rawSize?: number) => {
    if (!rawSize) return null;
    if (rawSize > 1_000_000) return `${(rawSize / 1_000_000).toFixed(2)}mb`;
    return `${(rawSize / 1000).toFixed(2)}Kb`;
  };

  const onDeleteBtnClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const updatedDocs = [...data.docs];
    updatedDocs.splice(fileIndex, 1);
    onUpdate({ docs: updatedDocs });
    createDealFileDeletedEvent({
      docId: id, docName: file.name, docSize: file.size, docUrl: file.url,
    });
  };

  return (
    <ListItem
      key={id}
      className={classNames(
        'list-item',
        {
          mobile: isMobile,
        },
      )}
    >
      <a
        href={url?.replaceAll('+', '%2B') || ''}
        className="list-item-left-block-text"
        download
        target="_blank"
        rel="noreferrer"
        onClick={() => createDealFileDownLoadEvent({
          docId: id,
          docUrl: url || '',
          docName: name,
          docSize: size,
        })}
      >
        <Box className="list-item-left-block">
          <Box className="list-item-left-block-icon-container">
            <DocumentIcon ext={name?.split('.').pop()} />
          </Box>

          <Typography className="list-item-left-block-title">
            {name}
          </Typography>
          <Box className={classNames('list-item-left-block-description', {
            'list-item-left-block-description-hidden': status !== UploadItemStatus.UPLOAD_FAILED,
          })}
          >
            Upload error
          </Box>
          <Box className="list-item-size-text">
            {renderSize(size)}
          </Box>
          <AppLoader
            size={20}
            hidden={status !== UploadItemStatus.IN_PROGRESS}
          />
        </Box>
        <Box className="list-item-right-block">
          <Box
            className={classNames('list-item-right-block-control', {
              'list-item-right-block-control-hidden': status !== UploadItemStatus.UPLOAD_FAILED,
            })}
          // onClick={() => handleReupload(listedFile as FailedItem)}
          >
            <DocReupload />
          </Box>
          <Box
            className={classNames('list-item-right-block-control', {
              'list-item-right-block-control-hidden': status === UploadItemStatus.IN_PROGRESS,
            })}
          >
            <AppIconButton
              variant="danger-transparent"
              icon={<DeleteSectionSvg />}
              onClick={onDeleteBtnClick}
            />
          </Box>
        </Box>
      </a>
    </ListItem>
  );
};

export default DocumentNodeItem;
