import { EUserOrganizationPermissions } from '../../shared/permissions';
import { ESubscriptionType } from '../../shared/Subscription';
import { useAppSelector } from './stateHooks';

const useUserOrganizationStatus = () => {
  const { type } = useAppSelector((state) => state.billing.subscription);
  const { enableCustomDomain } = useAppSelector((state) => state.app.organization);

  const checkLimits = (): boolean => {
    if (type === ESubscriptionType.SELLER_PREMIUM) {
      return true;
    }
    if (type !== ESubscriptionType.SELLER_PREMIUM) {
      return false;
    }
    return false;
  };
  const isPremium = (): boolean => [
    ESubscriptionType.SELLER_PREMIUM,
    ESubscriptionType.SELLER_APPSUMO_TIER_1,
    ESubscriptionType.SELLER_APPSUMO_TIER_2,
    ESubscriptionType.SELLER_APPSUMO_TIER_3,
  ].indexOf(type) >= 0 || enableCustomDomain;
  const hasCustomDomains = (): boolean => type === ESubscriptionType.SELLER_APPSUMO_TIER_3 || enableCustomDomain;
  return {
    checkLimits,
    isPremium,
    hasCustomDomains,
  };
};

export default useUserOrganizationStatus;
