import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import useSideMenu from '../../hooks/useSideMenu';
import { useAppSelector } from '../../hooks/stateHooks';
import { ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import useFieldsLayoutSlice from '../../hooks/useFieldsLayoutSlice';
import { CloseIcon20 } from '../../icons/CloseIcon';
import { BurgerIcon20 } from '../../icons/BurgerIcon';
import { useDealTheme } from '../../hooks/useDealTheme';

interface Props {
  themed?: boolean
}

const MobileBottomMenuBurgerButton = ({ themed }: Props) => {
  const process: ProcessDTO = useAppSelector((state) => state.process.process);
  const { isDrawerOpen, onDrawerStatusUpdate } = useFieldsLayoutSlice();
  const { isOpened, toggle } = useSideMenu();
  const { dealTheme: { dealOverrides: { sidebar: { backgroundColor } } } } = useDealTheme();
  const isDealThemeWhite: boolean = backgroundColor === '#ffffff';

  const buttonVariant = () => {
    if (themed) {
      if (isDealThemeWhite) {
        return 'primary';
      } return 'buyer-burger-button-themed';
    } return 'transparent';
  };

  return (
    <AppIconButton
      icon={(isOpened || isDrawerOpen) ? <CloseIcon20 /> : <BurgerIcon20 />}
      disableRipple
      size="l"
      variant={buttonVariant()}
      onClick={process ? () => onDrawerStatusUpdate(!isDrawerOpen) : toggle}
      className="menu-icon"
    />
  );
};

export default MobileBottomMenuBurgerButton;
