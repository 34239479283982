import { SvgIcon } from '@mui/material';

export const BoardsIcon = (): JSX.Element => (
  <SvgIcon width="27" height="27" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" />
  </SvgIcon>
);

export const TeamIcon = (): JSX.Element => (
  <SvgIcon width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.16634 17.102V14.033C8.16634 12.8569 9.07842 11.932 10.4278 11.404C9.55756 11.0804 8.63644
     10.9152 7.70801 10.9163C5.97551 10.9163 4.37501 11.4819 3.08159 12.438C3.48264 13.5722 4.15601 14.5906
     5.04257 15.4039C5.92914 16.2171 7.00178 16.8002 8.16634 17.102ZM16.3063 13.7452C15.9443 13.2566 14.7388
     12.7497 13.208 12.7497C11.3692 12.7497 9.99967 13.4803 9.99967 14.033V17.333C11.2719 17.3337 12.5223 17.0033
     13.6281 16.3742C14.7339 15.7451 15.6569 14.8391 16.3063 13.7452ZM7.75384 9.54134C8.30085 9.54134 8.82546
     9.32404 9.21225 8.93725C9.59904 8.55046 9.81634 8.02585 9.81634 7.47884C9.81634 6.93183 9.59904 6.40723
      9.21225 6.02043C8.82546 5.63364 8.30085 5.41634 7.75384 5.41634C7.20683 5.41634 6.68223 5.63364 6.29543
       6.02043C5.90864 6.40723 5.69134 6.93183 5.69134 7.47884C5.69134 8.02585 5.90864 8.55046 6.29543 8.93725C6.68223
        9.32404 7.20683 9.54134 7.75384 9.54134ZM13.208 10.458C13.6942 10.458 14.1606 10.2649 14.5044 9.92104C14.8482
         9.57722 15.0413 9.1109 15.0413 8.62467C15.0413 8.13844 14.8482 7.67213 14.5044 7.32831C14.1606 6.9845 13.6942
          6.79134 13.208 6.79134C12.7218 6.79134 12.2555 6.9845 11.9116 7.32831C11.5678 7.67213 11.3747 8.13844 11.3747
           8.62467C11.3747 9.1109 11.5678 9.57722 11.9116 9.92104C12.2555 10.2649 12.7218 10.458 13.208 10.458ZM9.99967
            19.1663C4.93692 19.1663 0.833008 15.0624 0.833008 9.99967C0.833008 4.93692 4.93692 0.833008 9.99967
             0.833008C15.0624 0.833008 19.1663 4.93692 19.1663 9.99967C19.1663 15.0624 15.0624 19.1663 9.99967 19.1663Z"
    />
  </SvgIcon>
);

export const CloudIcon = (): JSX.Element => (
  <SvgIcon width="29" height="23" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00036 16.9861C6.54441 16.8999 5.13514 16.4404 3.9081 15.652C2.68106 14.8636 1.67754 13.7727
     0.994047 12.4843C0.310558 11.1958 -0.0298987 9.7532 0.00542321 8.29512C0.0407452 6.83705 0.450657
      5.41259 1.19573 4.15876C1.94081 2.90492 2.99598 1.8639 4.25976 1.13582C5.52353 0.407743 6.9534
       0.0170994 8.41182 0.00146504C9.87024 -0.0141693 11.3081 0.345732 12.5872 1.04655C13.8663 1.74737
        14.9436 2.76553 15.7154 4.00311C17.3778 4.05621 18.9567 4.7446 20.1269 5.92658C21.2972 7.10856
         21.9698 8.69419 22.0063 10.3571C22.0428 12.02 21.4404 13.6336 20.3232 14.8658C19.206 16.098
          17.6589 16.8551 16.0004 16.9811V17.0001H8.00036V16.9861Z"
    />
  </SvgIcon>
);

export const BillingIcon = (): JSX.Element => (
  <SvgIcon width="27" height="27" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523
     20 10 20ZM6.5 12V14H9V16H11V14H12C12.663 14 13.2989 13.7366 13.7678 13.2678C14.2366 12.7989 14.5
      12.163 14.5 11.5C14.5 10.837 14.2366 10.2011 13.7678 9.73223C13.2989 9.26339 12.663 9 12 9H8C7.86739
       9 7.74021 8.94732 7.64645 8.85355C7.55268 8.75979 7.5 8.63261 7.5 8.5C7.5 8.36739 7.55268 8.24021
        7.64645 8.14645C7.74021 8.05268 7.86739 8 8 8H13.5V6H11V4H9V6H8C7.33696 6 6.70107 6.26339 6.23223
         6.73223C5.76339 7.20107 5.5 7.83696 5.5 8.5C5.5 9.16304 5.76339 9.79893 6.23223 10.2678C6.70107
          10.7366 7.33696 11 8 11H12C12.1326 11 12.2598 11.0527 12.3536 11.1464C12.4473 11.2402 12.5
           11.3674 12.5 11.5C12.5 11.6326 12.4473 11.7598 12.3536 11.8536C12.2598 11.9473 12.1326
            12 12 12H6.5Z"
    />
  </SvgIcon>
);

export const IntegrationIcon = (): JSX.Element => (
  <SvgIcon width="27" height="27" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 3H14C14.5304 3 15.0391 3.21071 15.4142 3.58579C15.7893 3.96086
       16 4.46957 16 5V13.17C16.6675 13.4059 17.2301 13.8702 17.5884
        14.4808C17.9466 15.0914 18.0775 15.809 17.9578 16.5068C17.8381
         17.2046 17.4756 17.8375 16.9344 18.2939C16.3931 18.7502 15.708
          19.0005 15 19.0005C14.292 19.0005 13.6069 18.7502 13.0656
           18.2939C12.5244 17.8375 12.1619 17.2046 12.0422 16.5068C11.9225
           15.809 12.0534 15.0914 12.4116 14.4808C12.7699 13.8702 13.3325 13.4059
           14 13.17V5H12V8L7.5 4L12 0V3ZM2 6.83C1.33251 6.59409 0.769905 6.1298 0.411639
            5.51919C0.0533734 4.90859 -0.0774873 4.19098 0.042188 3.49321C0.161863
             2.79545 0.524368 2.16246 1.06563 1.70613C1.60689 1.2498 2.29205 0.999512
              3 0.999512C3.70795 0.999512 4.39311 1.2498 4.93437 1.70613C5.47563 2.16246
              5.83814 2.79545 5.95781 3.49321C6.07749 4.19098 5.94662 4.90859 5.58836
              5.51919C5.23009 6.1298 4.66749 6.59409 4 6.83V13.17C4.66749 13.4059 5.23009
              13.8702 5.58836 14.4808C5.94662 15.0914 6.07749 15.809 5.95781 16.5068C5.83814
               17.2046 5.47563 17.8375 4.93437 18.2939C4.39311 18.7502 3.70795 19.0005 3
               19.0005C2.29205 19.0005 1.60689 18.7502 1.06563 18.2939C0.524368 17.8375
               0.161863 17.2046 0.042188 16.5068C-0.0774873 15.809 0.0533734 15.0914 0.411639
               14.4808C0.769905 13.8702 1.33251 13.4059 2 13.17V6.83Z"
    />
  </SvgIcon>
);
