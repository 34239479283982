import {
  Box, Radio, styled, Typography,
} from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const CODOrganizationListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const CODLoaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CODOrganizationItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '12px',
}));

export const CODAvatarPlaceholderContainer = styled(Box)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  borderRadius: '8px',
  '& path': {
    stroke: stagesetColors.newGrey[500],
  },
}));

export const CODTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '12px',
}));

export const CODOrganizationTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500,
  paddingTop: '5px',
}));

export const CODRadioButton = styled(Radio)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500,
  paddingTop: '5px',
}));
