import { Autocomplete, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

export const InviteUserAutocompleteForm = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  padding: isMobile ? '16px' : '20px 24px',
}));

export const InviteUserAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiInputBase-root': {
    display: 'flex',
    gap: '4px',
  },
}));
