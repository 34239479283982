import { Autocomplete, Chip } from '@mui/material';
import { AutoCompleteProps } from 'antd';
import { AppDropdownItemProps } from './AppDropdownItem';
import { AutocompleteTextField } from './AutocompleteTextField';
import Cross from '../../icons/DealsFiltersInput/Cross.svg';
import { AppLinkButton } from '../AppButton/AppLinkButton';

type Props = {
  chosenItems: AppDropdownItemProps[],
  onChange: (value: string) => void,
  onChipDelete?: (item: AppDropdownItemProps, index: number) => void,
  value: string,
};

export const DropdownSearch = ({
  chosenItems, onChange, onChipDelete, value,
}: Props & AutoCompleteProps) => (
  <Autocomplete
    open
    disablePortal
    renderInput={(params) => (
      <AutocompleteTextField params={params} value={value} onChange={(val) => onChange(val)} />
    )}
    options={chosenItems}
    PopperComponent={() => null}
    popupIcon={null}
    multiple
    freeSolo
    value={chosenItems}
    renderTags={(val: AppDropdownItemProps[]) => val.map((item, index) => (
      <Chip
        key={item.label}
        label={item.label}
        avatar={item.startIcon}
        deleteIcon={(
          <AppLinkButton
            autoWidth
            onClick={(e) => {
              e.stopPropagation();
              if (onChipDelete) {
                onChipDelete(item, index);
              }
            }}
            transparentStroke
            size="s"
            variant="lite-secondary"
            sx={{
              marginLeft: '6px',
              width: 'auto',
              padding: '0 !important',
              minWidth: 'auto',
              '& g': {
                stroke: 'inherit',
                color: 'inherit',
              },
            }}
          >
            <Cross />
          </AppLinkButton>
        )}
        onDelete={() => {
          if (onChipDelete) {
            onChipDelete(item, index);
          }
        }}
        sx={{
          margin: '0px 3px 6px 0px',
          borderRadius: item.startIcon ? '16px 4px 4px 16px' : '4px',
          border: (theme) => `border: 1px solid ${theme.palette.grey[200]}`,
          background: (theme) => theme.palette.background.default,
          '& .MuiAvatar-root': {
            width: '28px',
            height: '28px',
            marginLeft: 0,
          },
        }}
      />
    ))}
  />
);
