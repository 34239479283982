import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { EProcessType } from '../../shared/process/ProcessMilestoneActionDTO';
import { useAppSelector } from '../hooks/stateHooks';
import { selectProcess } from '../routes-old/process/state/processSlice';
import { selectCurrentStage } from '../features/ProcessFields/lib/processItemsSlice';

const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const TabTitlePlugin = (): null => {
  const process = useAppSelector(selectProcess);
  const currentStage = useAppSelector(selectCurrentStage);

  const location = useLocation();

  useEffect(() => {
    if (process?.type === EProcessType.DEAL) {
      document.title = `${process.client} x ${process.sellerName}` ?? 'Stageset';
    }
    return () => { document.title = 'Stageset'; };
  }, [process]);

  useEffect(() => {
    const pathNameItems = location?.pathname?.split('/')?.filter((item) => !!item);
    if (pathNameItems.length) {
      switch (pathNameItems[0]) {
        case 'home':
          document.title = 'Rooms - Stageset';
          break;
        case 'deal':
          if (process?.type === EProcessType.DEAL) {
            document.title = `${process.client} x ${process.sellerName}` ?? 'Stageset';
            break;
          }
          if (process?.type === EProcessType.TEMPLATE) {
            document.title = process.title ?? 'Stageset';
            break;
          }
          document.title = 'Stageset';
          break;
        case 'templates':
          if (!!pathNameItems[1] && currentStage?.title) {
            document.title = currentStage.title;
            break;
          }
          document.title = 'Templates - Stageset';
          break;
        case 'auth':
          if (pathNameItems[1]) {
            document.title = `${capitalize(pathNameItems[1]).replace('-', ' ')} - Stageset`;
            break;
          }
          document.title = 'Auth';
          break;
        default:
          document.title = `${capitalize(pathNameItems[0])} - Stageset`;
          break;
      }
    } else {
      document.title = 'Stageset';
    }
  }, [location, process, currentStage]);

  return null;
};

export default TabTitlePlugin;
