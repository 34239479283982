import { Box, TextField, Typography } from '@mui/material';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AccessLinkDTO, AccessLinkProtectionUpdate } from '../../../../shared/AccessLinkDTO';
import SwitchWithTitleWrapper from '../Utils/SwitchWithTitleWrapper';
import { ProtectedIcon20px, UnprotectedIcon20px } from '../../../icons/ProtectionIcons';
import { ClearIcon16px } from '../../../icons/ClearIcon';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';

interface Props {
  isChecked: boolean;
  password?: string;
  onLinkUpdate: (update: Partial<AccessLinkDTO>) => void;
  onLinkProtectionUpdate: (update: AccessLinkProtectionUpdate) => void;
}

const ShareDealPasswordProtection = ({
  isChecked,
  password,
  onLinkUpdate,
  onLinkProtectionUpdate,
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(isChecked);
  const [localPassword, setLocalPassword] = useState(password);
  const { t } = useTranslation();

  const onPasswordChange = (newPassword: string = ''): void => {
    if (newPassword.length > 0) {
      onLinkUpdate({ password: newPassword, isPasswordProtected: true });
      return;
    }
    if (newPassword.length === 0) {
      onLinkUpdate({ password: '', isPasswordProtected: false });
    }
  };

  const clearPassword = () => {
    onLinkUpdate({ password: '', isPasswordProtected: false });
    if (localPassword) {
      setLocalPassword('');
    }
  };

  const onProtectionStatusChange = (newStatus: boolean) => {
    setChecked(newStatus);
    if (password && newStatus) {
      onLinkUpdate({ isPasswordProtected: true });
    }
    if (!newStatus) {
      onLinkUpdate({ isPasswordProtected: false });
    }
  };

  useEffect(() => {
    setLocalPassword(password);
  }, [password]);

  const endAornment = useMemo(() => {
    if (isChecked) {
      return (
        <Box
          sx={{
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ProtectedIcon20px
            className="CheckIconPassword"
          />
          <Box
            className="ClearIconPassword"
            onClick={(e) => {
              e.stopPropagation(); clearPassword();
            }}
          >
            <AppLinkButton
              variant="lite-secondary"
              size="xs"
              autoWidth
              startIcon={<ClearIcon16px />}
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                color: (theme) => theme.palette.grey[400],
                '& svg': {
                  height: '13px',
                  width: '13px',
                },
              }}
            >
              Reset
            </AppLinkButton>
          </Box>
          <AppLinkButton
            variant="lite-secondary"
            size="xs"
            className="ShareOptionsSavePassword"
            autoWidth
            sx={{
              fontSize: '12px',
              lineHeight: '16px',
              whiteSpace: 'nowrap',
              color: (theme) => theme.palette.grey[300],
            }}
          >
            Press Enter
          </AppLinkButton>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          width: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          className="ShareOptionsSavePassword"
          sx={{
            fontSize: '12px',
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            color: (theme) => theme.palette.grey[300],
          }}
        >
          Press Enter
        </Typography>
        <UnprotectedIcon20px className="UncheckIconPassword" />
      </Box>
    );
  }, [isChecked, localPassword]);

  return (
    <SwitchWithTitleWrapper
      isChecked={checked}
      onChange={onProtectionStatusChange}
      title={t('Room_Restricted_by_password')}
      description={t('Room_Set_password_to_protect_your_room')}
    >
      <AppInput
        fullWidth
        size="m"
        placeholder={t('Room_Password')}
        value={localPassword}
        onChange={(e) => setLocalPassword(e.target.value)}
        autoComplete="off"
        sx={{
          '& .CheckIconPassword': {
            display: 'flex',
          },
          '& .ClearIconPassword': {
            display: 'none',
          },
          '& .ShareOptionsSavePassword': {
            display: 'none',
          },
          '&:hover': {
            '& .CheckIconPassword': {
              display: 'none',
            },
            '& .ClearIconPassword': {
              display: 'flex',
            },
            '& .Mui-focused': {
              '& .CheckIconPassword': {
                display: 'none',
              },
              '& .ClearIconPassword': {
                display: 'none',
              },
            },
          },
          '& .Mui-focused': {
            '& .CheckIconPassword': {
              display: 'none',
            },
            '& .UncheckIconPassword': {
              display: 'none',
            },
            '& .ClearIconPassword': {
              display: 'none',
            },
            '& .ShareOptionsSavePassword': {
              display: localPassword ? 'flex' : 'none',
            },
          },
        }}
        InputProps={{
          endAdornment: endAornment,
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
            },
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          onBlur: (e) => onPasswordChange(e.target.value),
          onKeyDown: (e) => {
            if (e.nativeEvent.key === 'Enter') {
              e.currentTarget.blur();
            }
          },
        }}
      />
    </SwitchWithTitleWrapper>
  );
};

export default ShareDealPasswordProtection;
