import { Box } from '@mui/material';
import { useRef } from 'react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import Add from '../../../../icons/Add.svg';
import { allFormats } from '../../../../features/MediaField/Helpers/MediaFieldHelpers';
import { useAppSelector } from '../../../../hooks/stateHooks';
import FileDisplay from '../../../../shared/FileDisplay/FileDisplay';
import { AppLinkButton } from '../../../../shared/AppButton/AppLinkButton';
import {
} from '../../../../features/FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { FileEventData } from '../../../../../shared/FileItems/FileItems';
import { selectFeedAgenda } from '../../../../features/DealFeed/lib/feedSlice';
import useFrontEventsApi from '../../../../hooks/useFrontEventsApi';

const inputAccepts = allFormats.map((format) => `.${format}`).join(',');

interface Props {
  docs: string[];
  onFileUpload: (files: File[]) => Promise<void>
  onFileDelete: (id: string) => void;
  onDocsUpdate: (docsArray: string[]) => void
}

const AgendaFiles = ({
  docs = [],
  onFileUpload,
  onFileDelete,
  onDocsUpdate,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { blockId } = useAppSelector(selectFeedAgenda);
  const { createTaskFileDownLoadEvent, createTaskFileDeletedEvent } = useFrontEventsApi();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0.01,
      },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = docs.findIndex((item) => item === active.id);
      const newIndex = docs.findIndex((item) => item === over.id);
      onDocsUpdate(arrayMove(docs, oldIndex, newIndex));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={docs}
          strategy={verticalListSortingStrategy}
        >
          {docs?.map((doc, i) => (
            <FileDisplay
              key={`${doc}-agenda-file-${i}`}
              id={doc}
              onFileDelete={(file: FileEventData) => {
                onFileDelete(doc);
                if (blockId) {
                  createTaskFileDeletedEvent({
                    docName: file.name!,
                    docSize: file.size!,
                    docUrl: file.url!,
                    taskId: blockId,
                    fieldId: blockId,
                    docId: doc,
                  });
                }
              }}
              onDownload={(file: FileEventData) => blockId && createTaskFileDownLoadEvent({
                docName: file.name!,
                docSize: file.size!,
                docUrl: file.url!,
                taskId: blockId,
                fieldId: blockId,
                docId: doc,
              })}
            />
          ))}
        </SortableContext>
      </DndContext>
      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={(e) => e.target.files && onFileUpload([...e.target.files])}
        className="input"
        accept={inputAccepts}
      />
      <AppLinkButton
        size="l"
        startIcon={<Add />}
        sx={{
          justifyContent: 'flex-start',
          padding: '6px 28px !important',
          width: '100px',
          fontSize: '14px',
          lineHeight: '16px',
          paddingTop: '8px',
        }}
        onClick={() => inputRef.current?.click()}
      >
        File
      </AppLinkButton>
    </Box>
  );
};

export default AgendaFiles;
