import { Box, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '../../../api/AuthApi';
import { EStateStatus } from '../../../core/commonTypes';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { useQuery } from '../../../hooks/useQuery';
import { resetAuthError } from '../state/authState';
import AuthButton from './AuthButton';
import { TitleAdditionalTypography, TitleTypography, TitleTypographyWrapper } from './AuthStyledComponents';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';

const authApi: AuthApi = new AuthApi();

const ResendActivationLetter = (): JSX.Element => {
  const query = useQuery();
  const navigate = useNavigate();
  const email: string | null = query.get('email');
  const [status, setStatus] = useState<EStateStatus>(EStateStatus.IDLE);

  const onResendButtonClick = async (): Promise<void> => {
    setStatus(EStateStatus.LOADING);
    try {
      if (email) {
        await authApi.resendActivationEmail(email);
        setStatus(EStateStatus.IDLE);
        navigate(EAppRoutes.auth + EAppRoutes.signupSuccess);
      }
    } catch (e) {
      setStatus(EStateStatus.IDLE);
      console.log(e);
    }
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetAuthError());
  }, []);

  return (
    <AuthPaper>
      <Box>
        <TitleTypographyWrapper
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '325px',
            flexDirection: 'column',
          }}
        >
          <TitleTypography>
            Account isn't active
          </TitleTypography>
          <TitleAdditionalTypography>
            Back to
            {' '}
            <Link
              onClick={() => navigate(`${EAppRoutes.auth}${EAppRoutes.login}`)}
            >
              Log in
            </Link>
          </TitleAdditionalTypography>
        </TitleTypographyWrapper>
        <Box
          sx={{
            margin: '32px 0',
            height: '75px',
            maxWidth: '325px',
          }}
        >
          <Typography>
            Please check your email to activate your account:
            {' '}
            <Typography
              sx={{
                display: 'inline',
                fontWeight: 500,
              }}
            >
              {email}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '20px',
          }}
        >
          <AuthButton
            title="Resend Activation Email"
            status={status}
            onClick={() => onResendButtonClick()}
          />
        </Box>
      </Box>
    </AuthPaper>
  );
};

export default ResendActivationLetter;
