import { Box } from '@mui/material';
import {
  AgendaDocumentsInfo, AgendaEventInfo, AgendaSubtasksInfo, AgendaTimelineInfo,
} from './AgendaNodeInfoItems';

const AgendaNodeInfo = (): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      gap: '20px',
    }}
  >
    <AgendaTimelineInfo />
    <AgendaDocumentsInfo />
    <AgendaSubtasksInfo />
    <AgendaEventInfo />
  </Box>
);

export default AgendaNodeInfo;
