import { Box, Dialog, Typography } from '@mui/material';
import { AppLoader } from '../../shared/AppLoader/AppLoader';
import useModalWindow from '../../hooks/useModalWindow';

const AppLoadingModalWindow = (): JSX.Element => {
  const { open, text, subText } = useModalWindow();
  return (
    <Dialog
      open={open}
      sx={{
        '& > .MuiDialog-container ': {
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          width: '376px',
          padding: '16px 16px 16px 24px',
          gap: '16px',
          borderRadius: '16px',
          flexDirection: 'row',
        },
      }}
    >
      <Box>
        <AppLoader size={24} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 600,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400,
            color: (theme) => theme.palette.grey[400],
          }}
        >
          {subText}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default AppLoadingModalWindow;
