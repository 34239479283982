import { BoxProps } from '@mui/material';
import { AirBalloonIcon } from '../../icons/AirBalloonIcon';
import { BalloonPlaceholderContainer, BalloonPlaceholderDescription } from './NoContentBalloonPlaceholderStyles';

interface BalloonPlaceholderProps {
  description: string;
  size?: number;
}

export const NoContentBalloonPlaceholder = ({ description, size }: BalloonPlaceholderProps, props: BoxProps) => (
  <BalloonPlaceholderContainer
    {...props}
  >
    <AirBalloonIcon
      sx={{
        fontSize: size,
      }}
    />
    <BalloonPlaceholderDescription>
      {description}
    </BalloonPlaceholderDescription>
  </BalloonPlaceholderContainer>
);
