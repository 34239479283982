import { SvgIcon, useTheme } from '@mui/material';

export const ExternalLinkGrey16px = (): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    sx={{
      fontSize: '16px',
    }}
  >
    <path
      d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095
 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971
  14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203
   2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052
    4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
      stroke="#7084B4"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10 2H14V6"
      stroke="#7084B4"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.66663 9.33333L14 2"
      stroke="#7084B4"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const ExternalLink = (): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993
14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333
17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816
17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5
6.22464 2.67559 5.80072 2.98816 5.48816C3.30072
5.17559 3.72464 5 4.16667 5H9.16667"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.5 2.5H17.5V7.5"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.33325 11.6667L17.4999 2.5"
        stroke={theme.palette.primary[600]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
