import {
  RefObject,
} from 'react';
import { isPDF } from './Helpers/MediaFieldHelpers';
import { EMediaFieldStorageType } from '../../../shared/Field/Field';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import MediaFieldIFrameFile from './MediaFieldFileComponents/MediaFieldIFrameFile';
import MediaFieldPdfFile from './MediaFieldFileComponents/MediaFieldPdfFile';
import { TiptapNodeUpdate, MediaNodeAttrs } from '../../../shared/Tiptap/TiptapTypes';

interface Props {
  url: string;
  siblingsCount?: number;
  onUpdate: (data: TiptapNodeUpdate<MediaNodeAttrs>) => void;
  width?: number;
  height?: number;
  storageType?: EMediaFieldStorageType;
  mediaComponentWrapperRef?: RefObject<HTMLDivElement>
}

const MediaFieldFile = ({
  url,
  siblingsCount,
  onUpdate,
  width: initialWidth,
  height: initialHeight,
  storageType,
  mediaComponentWrapperRef,
}: Props): JSX.Element => {
  const renderFile = () => {
    if (isPDF(url)) {
      return (
        <MediaFieldPdfFile
          url={url}
          siblingsCount={siblingsCount}
          onUpdate={onUpdate}
          width={initialWidth}
          height={initialHeight}
          storageType={storageType}
          mediaComponentWrapperRef={mediaComponentWrapperRef}
        />
      );
    }
    return (
      <MediaFieldIFrameFile
        url={url}
        onUpdate={onUpdate}
        width={initialWidth}
        height={initialHeight}
        storageType={storageType}
      />
    );
  };
  return (
    <>
      {renderFile()}
    </>
  );
};

export default MediaFieldFile;
