import { UserDTO } from '../UserDTO';

export interface AuthResponse {
    access_token: string;
    refreshToken: string;
    user: UserDTO;
}

export enum EAuthResponcesAndErrors {
    EMAIL_TAKEN = 'Email is already confirmed'
}
