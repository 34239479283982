import { SvgIcon } from '@mui/material';
import ContactRow from '../ContactRow';
import LinkedInIcon from '../../../icons/LinkedInContact.svg';

type Props = {
  linkedInLink: string
};

export const LinkedInContact = ({ linkedInLink }: Props): JSX.Element => (
  <ContactRow
    text="Let’s connect"
    icon={(
      <SvgIcon
        viewBox="0 0 20 20"
        component={LinkedInIcon}
        sx={{
          color: 'transparent',
        }}
      />
    )}
    link={linkedInLink}
  />
);

export default LinkedInContact;
