
export const LANGUAGES = [
  {
    lng: 'en',
    name: 'English',
    flag: 'us',
  },
  {
    lng: 'de',
    name: 'Deutsch',
    flag: 'de',
  },
  {
    lng: 'fr',
    name: 'French',
    flag: 'fr',
  },
  {
    lng: 'pg',
    name: 'Portuguese',
    flag: 'pg',
  }
];

export const DEFAULT_LANG = 'en';
export const BASE_FLAG_URL = 'https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/1x1/';

export function getLanguageName(lngCode: string): string | undefined {
  return LANGUAGES.find(x => x.lng === lngCode)?.name;
}

export function getLanguageFlag(lngCode: string): string | undefined {
  const flag = LANGUAGES.find(x => x.lng === lngCode)?.flag;

  return flag && BASE_FLAG_URL + `${flag}.svg`;
}
