import { LoadingButton } from '@mui/lab';
import {
  Box, ButtonProps, Typography,
} from '@mui/material';
import { EStateStatus } from '../../../core/commonTypes';
import { AppButton } from '../../../shared/AppButton/AppButton';

interface Props extends ButtonProps {
  status: EStateStatus;
  title: string;
}

const AuthButton = ({ status, title, ...props }: Props): JSX.Element => (
  <Box
    sx={{
      width: '100%',
      height: '40px',
      marginTop: '12px',
    }}
  >
    {status === EStateStatus.LOADING ? (
      <LoadingButton
        fullWidth
        loading
        size="small"
        sx={{
          height: '40px',
        }}
      />
    ) : (
      <AppButton
        type="submit"
        fullWidth
        size="l"
        variant="primary"
        {...props}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '.5px',
            color: 'white',
          }}
        >
          {title}
        </Typography>
      </AppButton>
    )}
  </Box>
);

export default AuthButton;
