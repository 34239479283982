import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';

export const StyledTopBar = styled(Box)(() => ({
  background: 'white',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignContent: 'center',
  minHeight: '56px',
  borderBottom: '1px solid #EEF0FA',
  paddingRight: isMobile ? '16px' : '24px',
  paddingLeft: isMobile ? '16px' : '24px',
  zIndex: 2,
  '& .tab-selector-wrapper': {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  '& .content-container': {
    width: '100%',
    display: 'flex',
    minHeight: '56px',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .first-level': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
    },
    '& .second-level': {
      display: 'flex',
    },
    '& .title-container': {
      display: 'flex',
      width: 'auto',
      alignItems: 'center',
      '& .title': {
        fontWeight: 500,
        fontSize: '20px',
        letterSpacing: '-1px',
        marginRight: '10px',
      },
    },
  },
}));
