import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon20px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fontSize: '20px',
    }}
    {...props}
  >
    <path
      d="M16.6666 5L7.49998 14.1667L3.33331 10"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export const CheckIcon21px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="21"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '21px',
    }}
    {...props}
  >
    <path
      fill="none"
      d="M17.0716 5L7.90495 14.1667L3.73828 10"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const CheckIconBold12px = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '21px',
    }}
    {...props}
  >
    <path
      d="M10 3L4.5 8.5L2 6"
      fill="none"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
