import { Checkbox } from '@mui/material';
import { styled } from '@mui/system';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledAppCheckbox = styled<any>(Checkbox)(({ sx }) => ({
  ...sx,
  '&.size': {
    '&-s': {
      height: '20px',
      width: '20px',
      '& svg': {
        height: '20px',
        width: '20px',
      },
    },
    '&-m': {
      marginLeft: '-6px',
      maxHeight: '46px',
      maxWidth: '44px',
      alignSelf: 'flex-start',
      padding: '0px 9px',
    },
  },
  '&.variant': {
    '&-primary': {
      '& svg': {
        color: stagesetColors.newGrey[500],
      },
      '&:hover svg': {
        color: stagesetColors.newGrey[300],
      },
    },
    '&-success-primary': {
      '& svg': {
        stroke: 'none',
        color: stagesetColors.green[600],
      },
      '&:hover svg': {
        stroke: 'none',
        color: stagesetColors.green[500],
      },
    },
    '&-secondary': {
      '& svg': {
        stroke: 'none',
        color: stagesetColors.white[100],
      },
      '&:hover svg': {
        stroke: 'none',
        color: stagesetColors.white[100],
      },
    },
  },
}));
