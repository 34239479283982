import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessLinkDTO, AccessLinkProtectionUpdate } from '../../../../shared/AccessLinkDTO';
import { EJWTTokenType } from '../../../../shared/schema/token.schema';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { updateAccessLinkById, updateAccessProtection } from '../../../routes-old/process/state/dealActions';
import ShareDealDomainProtection from './ShareDealDomainProtection';
import ShareDealPasswordProtection from './ShareDealPasswordProtection';
import ShareLinkRadioItem from './ShareLinkRadioItem';
import CopyLinkButton from '../Buttons/CopyLinkButton';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { ChevronDown20 } from '../../../icons/ChevronDown';
import {
  CopyLinkButtonWrapper,
  ShareDealDomainProtectionCollapse,
  ShareLinkRadioWrapper,
} from '../../../routes-old/process/DealFeed/Users/Styles/UsersTabStyles';
import { ChevronUp20 } from '../../../icons/ChevronUp';

enum LinkStatus {
  OPEN = 'OPEN',
  RESTRICTED = 'RESTRICTED',
  CLOSED = 'CLOSED',
}

const ShareDealLinkAndOptions = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const accessLink: AccessLinkDTO = useAppSelector((state) => state.process?.accessLink);
  const isShared: boolean = useAppSelector((state) => state.auth?.isSharedUser);
  const tokenType: EJWTTokenType = useAppSelector((state) => state.auth?.tokenType);

  const [linkStatus, setLinkStatus] = useState<LinkStatus | null>(null);
  const [emailOptionsExtended, setEmailOptionsEtended] = useState(false);

  useEffect(() => {
    if (accessLink && !linkStatus) {
      if (!accessLink.isActive) {
        setLinkStatus(LinkStatus.CLOSED);
      } else if (!accessLink.isEmailProtected) {
        setLinkStatus(LinkStatus.OPEN);
      } else if (accessLink.isEmailProtected) {
        setLinkStatus(LinkStatus.RESTRICTED);
      }
    }
  }, [accessLink, linkStatus]);

  const onLinkProtectionUpdate = (update: AccessLinkProtectionUpdate): void => {
    dispatch(
      updateAccessProtection(
        {
          id: accessLink.id,
          update,
        },
      ),
    );
  };

  const onLinkUpdate = (update: Partial<AccessLinkDTO>, newStatus?: LinkStatus): void => {
    if (linkStatus === newStatus) {
      return;
    }
    if (newStatus) {
      setLinkStatus(newStatus);
      setEmailOptionsEtended(newStatus === LinkStatus.RESTRICTED);
    }
    dispatch(
      updateAccessLinkById(
        {
          id: accessLink.id,
          update,
        },
      ),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '12px',
      }}
    >
      <ShareLinkRadioWrapper>
        <ShareLinkRadioItem
          text={t('Room_Anyone_with_the_link')}
          checked={linkStatus === LinkStatus.OPEN}
          onChange={() => onLinkUpdate(
            {
              isActive: true,
              isEmailProtected: false,
            },
            LinkStatus.OPEN,
          )}
        />
      </ShareLinkRadioWrapper>
      <ShareLinkRadioWrapper>
        <ShareLinkRadioItem
          text={t('Room_Require_email')}
          checked={linkStatus === LinkStatus.RESTRICTED}
          onChange={() => onLinkUpdate(
            {
              isActive: true,
              isEmailProtected: true,
            },
            LinkStatus.RESTRICTED,
          )}
        />
        <AppIconButton
          onClick={() => setEmailOptionsEtended((curr) => !curr)}
          icon={emailOptionsExtended ? <ChevronUp20 /> : <ChevronDown20 />}
        />
      </ShareLinkRadioWrapper>
      {
            !isShared && tokenType !== EJWTTokenType.SHARE
            && (
            <ShareDealDomainProtectionCollapse
              unmountOnExit
              className="ShareLinkSettingCollapse"
              in={emailOptionsExtended}
            >
              <ShareDealDomainProtection
                isChecked={!!accessLink.isDomainProtected}
                onLinkUpdate={onLinkUpdate}
                emailsAndDomains={accessLink.emailsAndDomains ?? []}
              />
              <ShareDealPasswordProtection
                onLinkUpdate={onLinkUpdate}
                onLinkProtectionUpdate={onLinkProtectionUpdate}
                isChecked={!!accessLink.isPasswordProtected}
                password={accessLink?.password || ''}
              />
            </ShareDealDomainProtectionCollapse>
            )
      }
      <ShareLinkRadioWrapper>
        <ShareLinkRadioItem
          text={t('Room_Only_people_with_access')}
          checked={linkStatus === LinkStatus.CLOSED}
          onChange={() => onLinkUpdate(
            {
              isActive: false,
              isEmailProtected: false,
            },
            LinkStatus.CLOSED,
          )}
        />
      </ShareLinkRadioWrapper>
      <CopyLinkButtonWrapper>
        <CopyLinkButton />
      </CopyLinkButtonWrapper>
    </Box>
  );
};

export default ShareDealLinkAndOptions;
