import { Box } from '@mui/system';
import { isMobile } from 'react-device-detect';

type Props = {
  children: JSX.Element[] | JSX.Element;
};

const LogoSidePanelWrapper = ({ children }: Props): JSX.Element => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: isMobile ? '0px' : '10px',
    flexDirection: 'column',
  }}
  >
    {children}
  </Box>
);

export default LogoSidePanelWrapper;
