import {
  createAsyncThunk, createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { EDealEventName, StagesetEvent } from '../../../../shared/events/Events';
import { ProcessApi } from '../../../api/ProcessApi';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';

const processApi = new ProcessApi();

export interface ProcessActivityLogAdditionalState {
  status: EStateStatus;
  limit: number;
  isFirstLoadComplete: boolean;
  skip: number;
  total: number;
  selectedTasksIds: string[];
  startDate?: string;
  endDate?: string;
  producerId?: string;
  name?: string;
  timelines: any;
  timelinesEventFilter:{ [key:string]: boolean };
  timelinesUserFilter: { [key: string]: boolean };
  timelinesTeamFilter: boolean;
  timelineStart: number;
  timelineEnd: number;
  inactiveDays: number,
  uniqueEntries: number,
}

const processActivityLogAdapter = createEntityAdapter<StagesetEvent>();

export const processActivityLogReducerName: string = 'processActivityLog';
export enum EProcessActivityLogSlice {
  GET_PROCESS_ACTIVITY_LOG = 'GET_PROCESS_ACTIVITY_LOG',
  GET_PROCESS_TIMELINES = 'GET_PROCESS_TIMELINES',
}

export const getProcessActivityLog = createAsyncThunk(
  `${processActivityLogReducerName}/${EProcessActivityLogSlice.GET_PROCESS_ACTIVITY_LOG}`,
  async (stub, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const {
      limit,
      skip,
      selectedTasksIds,
      endDate,
      startDate,
      producerId,
      name,
    } = state.processActivityLog;
    return processApi.getProcessActivityLog({
      processId: state.process.process.id,
      limit,
      skip,
      tasksIds: selectedTasksIds,
      eventNames: [],
      endDate,
      startDate,
      producerId,
      name,
    });
  },
);

export const getProcessTimelines = createAsyncThunk(
  `${processActivityLogReducerName}/${EProcessActivityLogSlice.GET_PROCESS_TIMELINES}`,
  async (req: any): Promise<any> => processApi.getProcessTimelines(req),
);

const initialState = processActivityLogAdapter.getInitialState<ProcessActivityLogAdditionalState>({
  status: EStateStatus.IDLE,
  limit: 20,
  skip: 0,
  total: 0,
  isFirstLoadComplete: false,
  selectedTasksIds: [],
  timelines: {},
  timelinesEventFilter: { },
  timelinesUserFilter: {},
  timelinesTeamFilter: false,
  timelineStart: new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 30),
  timelineEnd: new Date().setHours(23, 59, 59, 999),
  inactiveDays: 0,
  uniqueEntries: 0,
});

export const processActivityLogSlice = createSlice({
  name: processActivityLogReducerName,
  initialState,
  reducers: {
    resetLogsState: () => initialState,
    setActivityLogFilterEvents: (state, { payload }: PayloadAction<string>) => ({
      ...initialState,
      selectedTasksIds: state?.selectedTasksIds,
      startDate: state?.startDate,
      endDate: state?.endDate,
      producerId: state?.producerId,
      name: payload,
    }),
    setActivityLogFilterUsers: (state, { payload }: PayloadAction<string>) => ({
      ...initialState,
      selectedTasksIds: state?.selectedTasksIds,
      startDate: state?.startDate,
      endDate: state?.endDate,
      producerId: payload,
      name: state?.name,
    }),
    setActivityLogFilterTasksIds: (state, { payload }: PayloadAction<string[]>) => ({
      ...initialState,
      selectedTasksIds: payload,
      startDate: state?.startDate,
      endDate: state?.endDate,
      producerId: state?.producerId,
      name: state?.name,
    }),
    setActivityLogFilterDateRange: (state, { payload }: PayloadAction<{ startDate: string; endDate: string; }>) => ({
      ...initialState,
      selectedTasksIds: state.selectedTasksIds,
      startDate: payload.startDate,
      endDate: payload.endDate,
      producerId: state?.producerId,
      name: state?.name,
    }),
    setScoreData: (state, { payload }: PayloadAction<{ inactiveDays: number; uniqueEntries: number; }>) => {
      if (payload.inactiveDays) {
        state.inactiveDays = payload.inactiveDays;
      }
      if (payload.uniqueEntries) {
        state.uniqueEntries = payload.uniqueEntries;
      }
    },
    setTimelinesDateFilter: (state, { payload }: PayloadAction<{ date: number, point: 'start' | 'end' }>) => {
      switch (payload.point) {
        case 'start':
          state.timelineStart = payload.date;
          break;
        case 'end':
          state.timelineEnd = payload.date;
          break;
        default:
          break;
      }
    },
    setTimelinesEventFilter: (state, { payload }: PayloadAction<EDealEventName | undefined>) => {
      if (!payload) {
        state.timelinesEventFilter = initialState.timelinesEventFilter;
      } else if (!state.timelinesEventFilter[payload]) {
        state.timelinesEventFilter[payload] = true;
      } else {
        delete state.timelinesEventFilter[payload];
      }
    },
    setTimelinesTeamFilter: (state, { payload }: PayloadAction<boolean>) => {
      state.timelinesTeamFilter = payload;
    },
    setTimelinesUserFilter: (state, { payload }: PayloadAction<string | undefined>) => {
      if (!payload) {
        state.timelinesUserFilter = initialState.timelinesUserFilter;
      } else if (!state.timelinesUserFilter[payload]) {
        state.timelinesUserFilter[payload] = true;
      } else {
        delete state.timelinesUserFilter[payload];
      }
    },
    increaseLogLimit: (state) => {
      state.limit += 10;
    },
    clearActivityLogFilterDateRange: (state) => ({
      ...initialState,
      selectedTasksIds: state.selectedTasksIds,
      startDate: undefined,
      endDate: undefined,
      producerId: state?.producerId,
      name: state?.name,
    }),
  },
  extraReducers: ((builder) => builder
    .addCase(getProcessActivityLog.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      state.skip += action.payload.data.length;
      state.total = action.payload.total ? action.payload.total : 0;
      processActivityLogAdapter.setMany(state, action.payload.data);
      state.isFirstLoadComplete = true;
    })
    .addCase(getProcessActivityLog.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(getProcessActivityLog.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(getProcessTimelines.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      state.timelines = { ...action.payload };
    })
    .addCase(getProcessTimelines.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(getProcessTimelines.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
  ),
});

export const {
  resetLogsState,
  setActivityLogFilterTasksIds,
  setActivityLogFilterDateRange,
  setActivityLogFilterUsers,
  setActivityLogFilterEvents,
  clearActivityLogFilterDateRange,
  setTimelinesEventFilter,
  setTimelinesUserFilter,
  setTimelinesDateFilter,
  setScoreData,
  setTimelinesTeamFilter,
  increaseLogLimit,
} = processActivityLogSlice.actions;

export const { selectById, selectAll } = processActivityLogAdapter.getSelectors();

export const selectUserFilterStatus = (state: RootState, userId: string) => {
  if (state.processActivityLog.timelinesUserFilter[userId]) {
    return true;
  }
  if (!Object.keys(state.processActivityLog.timelinesUserFilter).length) {
    return true;
  }
  return false;
};

export const selectEventsFilterStatus = (state: RootState, eventName?: EDealEventName) => {
  if (!eventName) {
    return false;
  }
  if (state.processActivityLog.timelinesEventFilter[eventName]) {
    return true;
  }
  if (!Object.keys(state.processActivityLog.timelinesEventFilter).length) {
    return true;
  }
  return false;
};

export const selectIsUserFilterActive = (state: RootState) => !!Object.keys(state.processActivityLog.timelinesUserFilter).length;
export const selectIsEventsFilterActive = (state: RootState) => !!Object.keys(state.processActivityLog.timelinesEventFilter).length;

export const selectEventsFilterKeys = (state: RootState) => Object.keys(state.processActivityLog.timelinesEventFilter);
export const selectEventsFilterMap = (state: RootState) => new Map(Object.entries(state.processActivityLog.timelinesEventFilter));

export const selectUserFilterKeys = (state: RootState) => Object.keys(state.processActivityLog.timelinesUserFilter);
export const selectUserFilterMap = (state: RootState) => new Map(Object.entries(state.processActivityLog.timelinesUserFilter));

export const selectEventsTimelineKeys = (state: RootState) => Object.keys(state?.processActivityLog?.timelines)
  .sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

export const selectTimelineStart = (state: RootState) => state.processActivityLog.timelineStart;
export const selectTimelineEnd = (state: RootState) => state.processActivityLog.timelineEnd;
export const selectTimelineTeamfilter = (state: RootState): boolean => state.processActivityLog.timelinesTeamFilter;

export const getLogLimit = (state: RootState): number => state.processActivityLog.limit;
// eslint-disable-next-line max-len
export const getLogCount = (state: RootState): number => state.processActivityLog?.timelines?.[state.processActivityLog?.timelineStart]?.length;

export const selectProcessScore = (state: RootState) => {
  const { inactiveDays: b9, uniqueEntries: b7 } = state.processActivityLog;
  const { lastActive } = state.process.process;

  if (!lastActive) {
    return 0;
  }

  const millisInHour = 1000 * 60 * 60;

  const b2 = Math.E;
  const b3 = 4;
  const b4 = -0.003;
  const b5 = Math.floor(
    Math.floor(
      new Date().setMinutes(0, 0, 0) - (new Date(lastActive).setMinutes(0, 0, 0)),
    ) / millisInHour,
  );
  const b6 = 0.2;
  const b8 = -0.2;

  return (((b2 ** (b3 + b4 * b5 + b6 * b7 + b8 * b9)) / (1 + b2 ** (b3 + b4 * b5 + b6 * b7 + b8 * b9))) * 100).toFixed(2);

  // (b2**(b3+b4*b5+b6*b7+b*b9))/(1+b2**(b3+b4*b5+b6*b7+b8*b9))
};
