import {
  Box, CircularProgress, Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionsStats } from './SubscriptionsStyles';
import { useSubscriptions } from '../../hooks/useSubscriptions';
import { ESubscriptionStatus, ESubscriptionType } from '../../../shared/Subscription';
import { useAppSelector } from '../../hooks/stateHooks';
import BigRocket from '../../icons/BigRocket.svg';
import AppSumo from '../../icons/AppSumo.svg';
import NormalizedSvgIcon from '../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { AppButton } from '../../shared/AppButton/AppButton';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../common/DeleteTypography';
import AlertTriangle from '../../icons/AlertTriangle.svg';

const SubscriptionStatsSection = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    subscriptionNextPayment,
    onSubscriptionStatusUpdate,
    isLoading,
    checkIfCurrentSubscriptionIsAppSumo,
    currentPlan,
    nextInvoice,
  } = useSubscriptions();
  const { subscription } = useAppSelector((state) => state.billing);
  const isAppSumoSubscription: boolean = subscription && checkIfCurrentSubscriptionIsAppSumo;
  const showUnsubscribeButton: boolean = !!currentPlan;

  const isCanceled: boolean = subscription?.status === ESubscriptionStatus.CANCELED;

  const dialogTitle = isCanceled
    ? 'Resume Stageset Subscription' : 'Cancel Stageset Subscription';

  const confirmText = isCanceled
    ? 'Resume' : 'Unsubscribe';

  const cancelTypography: JSX.Element = isCanceled
    ? (
      <DeleteTypography>
        Are you sure you want to resume your subscription?
      </DeleteTypography>
    ) : (
      <DeleteTypography>
        Are you sure you want to cancel your subscription?
      </DeleteTypography>
    );
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  const onStatusChangeConfirm = (): void => {
    onSubscriptionStatusUpdate();
    setConfirmationDialogOpen(false);
  };

  const title = useMemo(() => {
    if (isAppSumoSubscription) {
      return 'AppSumo Tier';
    }
    switch (subscription?.type) {
      case ESubscriptionType.SELLER_BASIC:
        return 'Basic';
      case ESubscriptionType.SELLER_PREMIUM:
        return 'Pro';
      default:
        return 'Trial';
    }
  }, [isAppSumoSubscription, currentPlan]);

  const formNexPaymentData = () => {
    if (nextInvoice?.endAt) {
      return {
        label: 'Ends at:',
        info: subscriptionNextPayment,
      };
    }
    return {
      label: t('Subscriptions_Next_Payment'),
      info: subscriptionNextPayment,
    };
  };

  const description = useMemo(() => {
    if (isAppSumoSubscription) {
      return {
        label: 'Lifetime',
        info: '',
      };
    }
    switch (subscription?.type) {
      case ESubscriptionType.SELLER_BASIC:
        return formNexPaymentData();
      case ESubscriptionType.SELLER_PREMIUM:
        return formNexPaymentData();
      default:
        return {
          label: 'Free-to-use',
          info: '',
        };
    }
  }, [currentPlan, subscriptionNextPayment, isAppSumoSubscription]);

  return (
    <Box sx={{
      width: '100%',
    }}
    >
      <SubscriptionsStats>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                height: '64px',
                width: '64px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
                background: '#F9FBFF',
                marginRight: '31px',
                boxShadow: '0px 3px 2px 0px rgba(0, 113, 190, 0.04), '
                      + '0px 1px 1px 0px rgba(0, 113, 190, 0.07), '
                      + '0px 0px 1px 0px rgba(0, 113, 190, 0.08)',
              }}
            >
              <NormalizedSvgIcon component={BigRocket} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '20px',
                  fontWeight: 600,
                  color: stagesetPalette.grey?.[600],
                }}
              >
                {title}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: stagesetPalette.grey?.[600],
                }}
              >
                <Typography className="header-text">
                  {description.label}
                </Typography>
                {description.info}
              </Box>
              {
                !!nextInvoice?.credit
                && !!nextInvoice?.total
                && !(nextInvoice.total > 0)
                && Math.abs(nextInvoice.credit) > nextInvoice?.total
                && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: stagesetPalette.grey?.[600],
                  }}
                >
                  <Typography className="header-text">
                    Current balance:
                  </Typography>
                  {`$${Math.abs(nextInvoice.credit) / 100}`}
                </Box>
                )
          }
            </Box>
          </Box>
          <Box>
            {
                isAppSumoSubscription && (
                <Box
                  sx={{
                    display: 'flex',
                    width: '114px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '31px',
                  }}
                >
                  <NormalizedSvgIcon component={AppSumo} />
                </Box>
                )
            }
          </Box>
        </Box>
      </SubscriptionsStats>
      <ConfirmationDialog
        isDialogOpen={isConfirmationDialogOpen}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={onStatusChangeConfirm}
        dialogContentText={cancelTypography}
        dialogTitle={dialogTitle}
        titleVariant={isCanceled ? 'primary' : 'danger-primary'}
        confirmText={confirmText}
        icon={isCanceled ? undefined : <NormalizedSvgIcon component={AlertTriangle} />}
      />
    </Box>
  );
};

export default SubscriptionStatsSection;
