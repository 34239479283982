import { useMemo } from 'react';
import TeamTabMobileHeader from './TeamTabMobileHeader';
import {
  TeamTabMobileRowWrapper, MobileTableRow, CellTitle,
} from '../Styles/TeamTabStyles';

type Props = {
  title: string | JSX.Element,
  menu: JSX.Element | null,
  data: { key: string, value: JSX.Element }[]
  onClick: () => void;
};

const TeamTabMobileTableRow = ({
  title,
  data,
  menu,
  onClick,
}: Props) => {
  const renderData = useMemo(() => data.map((row) => (
    <MobileTableRow
      key={row.key}
    >
      <CellTitle
        className="cell-title-grey"
      >
        {row.key}
      </CellTitle>
      <CellTitle
        className="cell-title-mobile"
      >
        {row.value}
      </CellTitle>
    </MobileTableRow>
  )), [data]);

  return (
    <TeamTabMobileRowWrapper
      onClick={() => onClick()}
      className="mobile-table-row"
    >
      <TeamTabMobileHeader title={title} menu={menu} />
      {renderData}
    </TeamTabMobileRowWrapper>
  );
};

export default TeamTabMobileTableRow;
