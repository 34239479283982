import { ThemeProvider } from '@mui/system';
import { useAppTheme } from '../hooks/useAppTheme';
import { ErrorPage } from '../common/ErrorPage';

type Props = {
  error?: Error
};

const AppFallback = ({ error }: Props): JSX.Element => {
  const { currentTheme } = useAppTheme();

  return (
    <ThemeProvider theme={currentTheme}>
      <ErrorPage error={error} />
    </ThemeProvider>
  );
};

export default AppFallback;
