import {
  Box, List, Typography,
} from '@mui/material';
import { ElementFormatType } from 'lexical/nodes/LexicalElementNode';
import { AppLinkButton } from '../../AppButton/AppLinkButton';
import { OptionItem } from './OptionItem';
import {
  RTEFormatCenterIcon, RTEFormatJustifyIcon,
  RTEFormatLeftIcon,
} from '../../../icons/LexicalToolbar/LexicalToolbarIcons';
import LexicalRTEBtnBase from '../LexicalRTEToolbarBtns/LexicalRTEBtnBase';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppIconButton } from '../../AppButton/AppIconButton';

export type Props = {
  onClose: () => void;
  onAlignChange: (type: ElementFormatType) => void;
  onGoBack: () => void;
};

export const AlignOptionsTab = ({
  onClose, onAlignChange, onGoBack,
}: Props) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '16px 16px 8px 16px',
  }}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      marginBottom: '20px',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      >
        <AppIconButton
          icon={<ChevronLeft />}
          onClick={() => onGoBack()}
        />
        <Typography
          sx={{
            fontFamily: 'Manrope',
            fontSize: '14px',
            fontWeight: 600,
            color: (theme) => theme.palette.grey[600],
            maxWidth: '100px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          Align
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
      }}
      >
        <AppLinkButton
          onClick={onClose}
          sx={{
            paddingRight: '0px !important',
            minWidth: '100% !important',
            width: '100% !important',
          }}
        >
          Cancel
        </AppLinkButton>
      </Box>
    </Box>
    <Box sx={{
      height: '100%',
      overflow: 'overlay',
    }}
    >
      <List sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <OptionItem
          title="Align left"
          onClick={() => onAlignChange('left')}
          startIcon={<LexicalRTEBtnBase content={<RTEFormatLeftIcon />} />}
        />
        <OptionItem
          title="Align center"
          onClick={() => onAlignChange('center')}
          startIcon={<LexicalRTEBtnBase content={<RTEFormatCenterIcon />} />}
        />
        <OptionItem
          title="Align right"
          onClick={() => onAlignChange('right')}
          startIcon={<LexicalRTEBtnBase content={<RTEFormatJustifyIcon />} />}
        />
        <OptionItem
          title="Justify"
          onClick={() => onAlignChange('justify')}
          startIcon={<LexicalRTEBtnBase content={<RTEFormatJustifyIcon />} />}
        />
      </List>
    </Box>
  </Box>
);
