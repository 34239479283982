const useCelloUi = () => {
  const useAction = (action: 'open' | 'close' | 'show' | 'hide' | 'shutdown') => {
    if (typeof window.Cello !== 'function') {
      return;
    }
    switch (action) {
      case 'open':
        window.Cello('open');
        break;
      case 'close':
        window.Cello('close');
        break;
      case 'show':
        window.Cello('show');
        break;
      case 'hide':
        window.Cello('hide');
        break;
      case 'shutdown':
        window.Cello('shutdown');
        break;
      default:
        break;
    }
  };
  const open = () => useAction('open');
  const close = () => useAction('close');

  return { open, close };
};

export default useCelloUi;
