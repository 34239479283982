import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  useSensors,
  useSensor,
  KeyboardSensor,
  PointerSensor,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Add from '../../../../icons/Add.svg';
import AgendaSubtaskItem from './AgendaSubtaskItem';
import { AppLinkButton } from '../../../../shared/AppButton/AppLinkButton';

export type SubTaskType = { name: string, status: boolean, id: number };
export type SubTaskVariableType = { name?: string, status?: boolean };
export type SubTaskArrayType = Array<SubTaskType>;
interface Props {
  subTasks?: SubTaskArrayType;
  onUpdate: (subTasks: Array<{ name: string, status: boolean }>) => void;
}

const AgendaSubTasks = ({
  subTasks = [],
  onUpdate,
}: Props): JSX.Element => {
  const [localSubtasksWithId, setLocalSubtasksWithId] = useState<SubTaskArrayType | []>([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0.01,
      },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const updateSubtask = (subTasksArray: SubTaskArrayType) => {
    const newSubtasks = subTasksArray.map((item) => ({ name: item.name, status: item.status }));
    onUpdate(newSubtasks);
  };

  const onCreate = () => {
    setLocalSubtasksWithId((cur) => {
      const newSubtasks = [...cur, { name: 'New task', status: false, id: cur.length + 1 }];
      updateSubtask(newSubtasks);
      return newSubtasks;
    });
  };

  const onDuplicate = (pos: number, data: SubTaskVariableType) => {
    setLocalSubtasksWithId((cur) => {
      const newData: SubTaskType = {
        name: `${data?.name || 'Task'} copy`,
        status: data?.status || false,
        id: cur.length + 1,
      };
      const newSubtasks = [...cur];
      newSubtasks.splice(pos + 1, 0, newData);
      updateSubtask(newSubtasks);
      return newSubtasks;
    });
  };

  const onSubtaskUpdate = (position: number, newData?: SubTaskVariableType) => {
    if (newData) {
      setLocalSubtasksWithId((cur) => {
        const newSubtasks = [...cur];
        newSubtasks[position] = { ...newSubtasks[position], ...newData };
        updateSubtask(newSubtasks);
        return newSubtasks;
      });
    } else {
      setLocalSubtasksWithId((cur) => {
        const newSubtasks = [...cur];
        newSubtasks.splice(position, 1);
        updateSubtask(newSubtasks);
        return newSubtasks;
      });
    }
  };

  useEffect(() => {
    const subtaskArrayWithIds = subTasks.map(
      (item, index) => ({ ...item, id: index + 1 }),
    );
    if (subtaskArrayWithIds !== localSubtasksWithId) setLocalSubtasksWithId(subtaskArrayWithIds);
  }, [subTasks]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = localSubtasksWithId.findIndex((item) => item.id === active.id);
      const newIndex = localSubtasksWithId.findIndex((item) => item.id === over.id);
      setLocalSubtasksWithId(arrayMove(localSubtasksWithId, oldIndex, newIndex));
      updateSubtask(arrayMove(localSubtasksWithId, oldIndex, newIndex));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={localSubtasksWithId}
          strategy={verticalListSortingStrategy}
        >
          {localSubtasksWithId?.map((item, i) => (
            <AgendaSubtaskItem
              key={`subt-${item.name}-${i}`}
              id={item.id}
              subTask={item}
              pos={i}
              onSubtaskUpdate={onSubtaskUpdate}
              onDuplicate={onDuplicate}
            />
          ))}
        </SortableContext>
      </DndContext>
      <AppLinkButton
        size="l"
        startIcon={<Add />}
        sx={{
          justifyContent: 'flex-start',
          padding: '6px 28px !important',
          width: '100px',
          fontSize: '14px',
          lineHeight: '16px',
          paddingTop: '8px',
        }}
        onClick={onCreate}
      >
        Task
      </AppLinkButton>
    </Box>
  );
};
export default AgendaSubTasks;
