import { ApiService } from '../core/api';
import { ApiTokenDTO } from '../../shared/api-token/ApiTokenDTO';
import { CreateApiTokenPayload } from '../../shared/api-token/ApiToken';

export class PublicApiTokenApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('api-token');
  }

  async createApiToken(payload: CreateApiTokenPayload): Promise<ApiTokenDTO> {
    return this.api.post('', payload);
  }

  async getApiTokens(): Promise<ApiTokenDTO[]> {
    return this.api.get('');
  }

  async deleteToken(apiTokenId: string): Promise<void> {
    return this.api.delete(apiTokenId);
  }
}
