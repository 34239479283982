import { SubscriptionPlans } from './SubscriptionPlans';
import { SubscriptionsTabPaper } from './SubscriptionsStyles';
import { useSubscriptions } from '../../hooks/useSubscriptions';
import { AppSumoSubscriptionPlans } from './AppSumoSubscriptionPlans';

const DesktopSubscriptionGrid = (): JSX.Element => {
  const { checkIfCurrentSubscriptionIsAppSumo } = useSubscriptions();
  return (
    <SubscriptionsTabPaper>
      {
            checkIfCurrentSubscriptionIsAppSumo && (
            // <SubscriptionsTabsControls isCurrentSubscriptionIsAppSumo={checkIfCurrentSubscriptionIsAppSumo} />
            <AppSumoSubscriptionPlans />
            )
        }
      {
            !checkIfCurrentSubscriptionIsAppSumo && (
            <SubscriptionPlans />
            )
        }

    </SubscriptionsTabPaper>
  );
};

export default DesktopSubscriptionGrid;
