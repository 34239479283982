import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../hooks/stateHooks';
import { useBlure } from '../../hooks/useBlure';
import { selectIsSignupDialogOpen } from '../auth/state/authState';

const DealBlurPlugin = (): null => {
  const isShareSingUpOpen: boolean = useAppSelector((state) => state.auth.isShareSingUpOpen);
  const isShareProtectedAuthDialogOpen: boolean = useAppSelector((state) => state.auth.isShareProtectedAuthDialogOpen);
  const isSignupDialogOpen = useAppSelector(selectIsSignupDialogOpen);
  // const isPolicyDialog = !!sessionStorage.getItem('showPolicyDisclaimer');

  useBlure((isShareSingUpOpen && !isMobile) || isShareProtectedAuthDialogOpen || isSignupDialogOpen);

  return null;
};

export default DealBlurPlugin;
