import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { MediaNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import { EMediaFieldStorageType } from '../../../../../../shared/Field/Field';
import MediaNodeToolbar from '../MediaNodeToolbar/MediaNodeToolbar';
import ExternalMediaToolbarItems from '../MediaNodeToolbar/ExternalMediaToolbarItems';
import InternalMediaToolbarItems from '../MediaNodeToolbar/InternalMediaToolbarItems';
import { selectLayouEditingStatus } from '../../../../../routes-old/process/state/processSlice';
import { useAppSelector } from '../../../../../hooks/stateHooks';

interface Props {
  children: any;
  isPdf?: boolean;
}

const MediaNodeContentWrapper = ({ children, isPdf }: Props): JSX.Element | null => {
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);
  const { data } = useBlockContext<MediaNodeAttrs>();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const renderToolbar = useMemo(() => {
    switch (data?.storageType) {
      case EMediaFieldStorageType.EXTERNAL:
        return (
          <MediaNodeToolbar>
            <ExternalMediaToolbarItems />
          </MediaNodeToolbar>
        );
      case EMediaFieldStorageType.INTERNAL_S3_PUBLIC:
        return (
          <MediaNodeToolbar>
            <InternalMediaToolbarItems
              isPdf={isPdf}
              setAnchor={setAnchor}
              anchor={anchor}
            />
          </MediaNodeToolbar>
        );
      default:
        return null;
    }
  }, [data?.storageType, isLayoutEditMode, anchor]);

  return (
    <Box
      className="MediaContentWrapper"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: data?.align || 'center',
        flexDirection: 'column',
        width: '100%',
        '& .media-node-toolbar': {
          display: anchor ? 'flex' : 'none',
        },
        '& .media-node-toolbar-widescreen:has(button)': {
          display: 'flex',
          position: 'fixed',
        },
        '&:hover': {
          '& .media-node-toolbar:has(button)': {
            display: 'flex',
          },
        },
      }}
    >
      {renderToolbar}
      {children}
    </Box>
  );
};

export default MediaNodeContentWrapper;
