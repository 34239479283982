import { SvgIcon, SvgIconProps } from '@mui/material';

export const UploadIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      width: '21px',
      height: '21px',
    }}
  >
    <path
      fill="none"
      d="M17.7983 12.584V15.9173C17.7983 16.3593
      17.6227 16.7833 17.3102 17.0958C16.9976 17.4084
      16.5737 17.584 16.1317 17.584H4.46501C4.02298
      17.584 3.59906 17.4084 3.2865 17.0958C2.97393
      16.7833 2.79834 16.3593 2.79834 15.9173V12.584"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M14.4652 6.75065L10.2985 2.58398L6.13184 6.75065"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M10.2983 2.58398V12.584"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
