import * as yup from 'yup';
import {
  Box,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { AppButton } from '../../shared/AppButton/AppButton';
import { CloseIcon } from '../../icons/Close16px';
import {
  LibraryDialogContentWrapper,
  LibraryDialogFooterWrapper,
  LibraryDialogMenuItem,
  LibraryDialogTitle,
  LibraryDialogTitleWrapper,
  StyledLibraryDialog,
} from './styles/LibraryDialogStyle';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { addBlure } from '../../routes-old/process/helpers';
import { selectUserId, selectOrganizationId } from '../../core/store/appState/appState';
import {
  createFileItem,
  selectEditLinkItem,
  updateFileItem,
} from './lib/librarySlice';
import { EFileType } from '../../../shared/library/Library';
import { AppInput } from '../../shared/AppInput/AppInput';
import { FileWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import {
  MediaSource,
  compareLinkAndSource,
  formatUrl,
  mediaSources,
} from '../../features/MediaField/Helpers/MediaFieldHelpers';
import { stagesetColors } from '../../theme/stagesetPalette';
import { ChevronDown20 } from '../../icons/ChevronDown';
import { CloseIcon20 } from '../../icons/CloseIcon';
import { ContentType } from '../../../shared/Stage';

export interface IEditDealFormState {
  title: string;
  client: string;
  value: number;
  logo: string;
}

type LibraryLinkPayload = {
  name: string | '',
  link: string | '',
  linkType: ContentType | '',
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LibraryLinkItemDialog = ({
  isOpen,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const [dialogTitle, setDialogTitle] = useState('Add Link');
  const dialogRef = useRef<HTMLDivElement>(null);
  const userId = useAppSelector(selectUserId);
  const organizationId = useAppSelector(selectOrganizationId);
  const editItem: FileWithOwnerDTO = useAppSelector(selectEditLinkItem);

  const libraryDialogFormSchemaConfig = () => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    validationSchemaConfigTemp.name = yup.string().required('Link name is required');
    validationSchemaConfigTemp.link = yup.string()
      .required('Link on embed is reqired')
      .test(
        'url test',
        'Must be a valid link',
        (value, context) => {
          if (context.parent.linkType === '') return true;
          if (value) {
            const result = compareLinkAndSource(value.trim(), context.parent.linkType);
            return result;
          }
          return false;
        },
      );
    validationSchemaConfigTemp.linkType = yup.string().notRequired();
    return validationSchemaConfigTemp;
  };

  const defaultLibraryDialogObject: LibraryLinkPayload = {
    name: editItem?.name ?? '',
    link: editItem?.link ?? '',
    linkType: editItem?.linkType ?? '',
  };

  const schema = yup.object(libraryDialogFormSchemaConfig()).required();

  const handleClose = () => {
    onClose();
  };

  const {
    formState: { isValid },
    reset,
    trigger,
    setValue,
    handleSubmit,
    control,
  } = useForm<LibraryLinkPayload>({
    mode: 'onChange',
    defaultValues: defaultLibraryDialogObject,
    resolver: yupResolver(schema),
  });

  const clearField = (field: 'name'
  | 'link'
  | 'linkType') => {
    if (field === 'linkType') {
      setValue(field, '', { shouldDirty: true });
    } else {
      setValue(field, '', { shouldDirty: true });
    }
    trigger(field);
  };

  useEffect(() => {
    const action: string = editItem ? 'Edit link' : 'Add link';
    setDialogTitle(action);
  }, [editItem]);

  const handleClick = (data: any) => {
    const formattedUrl: string = formatUrl(data.link, data.linkType);
    if (editItem && !!userId && !!organizationId) {
      dispatch(updateFileItem({
        id: editItem.id,
        name: data.name,
        userId,
        organizationId,
        type: EFileType.LINK,
        link: formattedUrl,
        fileUid: editItem.fileUid,
        linkType: data.linkType,
      }));
      onClose();
    } else if (!!userId && !!organizationId) {
      dispatch(createFileItem({
        name: data.name,
        userId,
        organizationId,
        type: EFileType.LINK,
        link: formattedUrl,
        linkType: data.linkType,
      }));
      onClose();
    }
  };

  useEffect(() => {
    switch (isOpen) {
      case true:
        reset(defaultLibraryDialogObject);
        addBlure(true);
        break;
      case false:
        addBlure(false);
        break;
      default:
        addBlure(false);
        break;
    }
  }, [isOpen]);

  return (
    <StyledLibraryDialog
      ref={dialogRef}
      open={isOpen}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        id: 'create-deal-dialog',
      }}
    >
      <LibraryDialogTitleWrapper>
        <LibraryDialogTitle>
          {dialogTitle}
        </LibraryDialogTitle>
        <AppIconButton
          icon={<CloseIcon />}
          onClick={onClose}
        />
      </LibraryDialogTitleWrapper>
      <Box
        component="form"
        id="create-deal-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(handleClick)}
      >
        <LibraryDialogContentWrapper>
          <Controller
            key="Name"
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                fullWidth
                required
                size="m"
                label="Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  endAdornment: (value !== ''
                    ? (
                      <button
                        className="end-adorment-button"
                        type="button"
                        aria-label="name"
                        onClick={() => clearField('name')}
                        onMouseDown={() => clearField('name')}
                      >
                        <CloseIcon20 />
                      </button>
                    ) : null
                  ),
                }}
              />
            )}
          />
          <Controller
            key="Link"
            name="link"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                fullWidth
                required
                size="m"
                label="Link"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  endAdornment: (value !== ''
                    ? (
                      <button
                        className="end-adorment-button"
                        type="button"
                        aria-label="link"
                        onClick={() => clearField('link')}
                        onMouseDown={() => clearField('link')}
                      >
                        <CloseIcon20 />
                      </button>
                    ) : null
                  ),
                }}
              />
            )}
          />
          <Controller
            key="LinkType"
            name="linkType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                fullWidth
                label="Type"
                size="m"
                select
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                sx={{
                  '& .MuiSelect-select': {
                    height: 'unset !important',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                  },
                  '& .MuiInputBase-root > svg': {
                    stroke: stagesetColors.grey[500],
                  },
                  '& .MuiSelect-icon': {
                    marginRight: value === '' ? '0px' : '25px',
                    color: 'transparent',
                    stroke: stagesetColors.grey[500],
                  },
                }}
                SelectProps={{
                  IconComponent: (props) => <ChevronDown20 {...props} />,
                }}
                InputProps={{
                  endAdornment: (value !== ''
                    ? (
                      <button
                        className="end-adorment-button"
                        type="button"
                        aria-label="linkType"
                        onClick={() => clearField('linkType')}
                        onMouseDown={() => clearField('linkType')}
                      >
                        <CloseIcon20 />
                      </button>
                    ) : null
                  ),
                }}
              >
                {mediaSources.map((item: MediaSource) => (
                  <LibraryDialogMenuItem
                    value={item.contentTag}
                    key={item.contentTag}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '20px !important',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        margin: '0 !important',
                      }}
                      primary={item.name}
                    />
                  </LibraryDialogMenuItem>
                ))}
              </AppInput>
            )}
          />
        </LibraryDialogContentWrapper>
        <LibraryDialogFooterWrapper>
          <AppButton
            variant="primary"
            size="l"
            fullWidth
            disabled={!isValid}
            type="submit"
          >
            Save
          </AppButton>
        </LibraryDialogFooterWrapper>
      </Box>
    </StyledLibraryDialog>
  );
};

export default LibraryLinkItemDialog;
