import { SvgIcon } from '@mui/material';

export const LinkedInAuthIcon = (): JSX.Element => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.05776 1.06064C2.57831 1.16401 2.17776
      1.39246 1.78822 1.78471C1.39348 2.18228
      1.20561 2.50348 1.07955 2.99635C0.718162
       4.40949 1.62515 5.90184 3.04874 6.23627C3.357
       6.3087 3.96841 6.29911 4.28273 6.21693C5.26941
       5.95892 6.01363 5.15839 6.21959 4.13355C6.58923
       2.29433 4.90922 0.661562 3.05776 1.06064ZM16.5366
       8.02563C15.2309 8.26142 14.0762 8.99559 13.3899
        10.0264L13.1799 10.342L13.1683 9.32121L13.1568
        8.30041H10.9655H8.77427V15.6502V23H11.0503H13.3263L13.3407
        18.8416C13.3539 15.0333 13.3617 14.6487 13.4334
        14.2748C13.7284 12.737 14.466 12.0438 15.8877
        11.9682C17.0885 11.9043 17.8505 12.4046 18.2061
        13.4904C18.4111 14.1162 18.4134 14.1683 18.4322
        18.7126L18.45 23H20.7255H23.0011L23.0004 18.6267C23
        16.138 22.981 13.9755 22.9562 13.6086C22.8172
        11.5482 22.3577 10.2138 21.4752 9.30797C20.7768
        8.59113 19.9528 8.20576 18.7244 8.02155C18.18
         7.93988 16.9991 7.94207 16.5366 8.02563ZM1.33851
         15.6498V23H3.63814H5.93776L5.92689 15.6609L5.91602
          8.3219L3.62726 8.31077L1.33851 8.29963V15.6498Z"
      fill="#0A66C2"
    />
  </SvgIcon>
);
