import { SvgIcon, SvgIconProps } from '@mui/material';

export const LockIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M15.25 9.24999H4.75C3.92157 9.24999 3.25 9.92157 3.25
      10.75V16C3.25 16.8284 3.92157 17.5 4.75 17.5H15.25C16.0784
      17.5 16.75 16.8284 16.75 16V10.75C16.75 9.92157 16.0784
      9.24999 15.25 9.24999Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.24997 9.25V6.25C6.24997 5.25544 6.64506 4.30161
      7.34832 3.59835C8.05158 2.89509 9.00541 2.5 9.99997
      2.5C10.9945 2.5 11.9484 2.89509 12.6516 3.59835C13.3549
      4.30161 13.75 5.25544 13.75 6.25V9.25"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
