import {
  createAsyncThunk, createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { OnlineOfflineActivityItemDTO } from '../../../../shared/events/activity-log';
import { StagesetEvent } from '../../../../shared/events/Events';
import { ProcessApi } from '../../../api/ProcessApi';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';

const processApi = new ProcessApi();

export interface ProcessActivityOnlineOfflineAdditionalState {
  status: EStateStatus;
  limit: number;
  skip: number;
  selectedUserIds: string[];
  startDate?: string;
  endDate?: string;
}

const processActivityOnlineOfflineAdapter = createEntityAdapter<OnlineOfflineActivityItemDTO>();

export const processActivityOnlineOfflineReducerName: string = 'processActivityOnlineOffline';
export enum EProcessActivityOnlineOfflineSlice {
  GET_ONLINE_OFFLINE_LOG = 'GET_ONLINE_OFFLINE_LOG',
}

export const getProcessOnlineOfflineLog = createAsyncThunk(
  `${processActivityOnlineOfflineReducerName}/${EProcessActivityOnlineOfflineSlice.GET_ONLINE_OFFLINE_LOG}`,
  async (stub, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const {
      limit,
      skip,
      selectedUserIds,
      endDate,
      startDate,
    } = state.processActivityOnlineOffline;
    return processApi.getProcessOnlineOfflineLog({
      processId: state.process.process.id,
      limit,
      skip,
      tasksIds: [],
      eventNames: [],
      endDate,
      startDate,
    });
  },
);

const initialState = processActivityOnlineOfflineAdapter.getInitialState<ProcessActivityOnlineOfflineAdditionalState>({
  status: EStateStatus.IDLE,
  limit: 2000,
  skip: 0,
  selectedUserIds: [],
});

export const processActivityOnlineOfflineSlice = createSlice({
  name: processActivityOnlineOfflineReducerName,
  initialState,
  reducers: {
    resetLogsState: () => initialState,
    setActivityLogFilterUsersIds: (state, { payload }: PayloadAction<string[]>) => ({
      ...initialState,
      selectedUsersIds: payload,
      startDate: state?.startDate,
      endDate: state?.endDate,
    }),
    setOnlineOfflineFilterDateRange: (state, { payload }: PayloadAction<{ startDate: string; endDate: string; }>) => ({
      ...initialState,
      selectedUserIds: state.selectedUserIds,
      startDate: payload.startDate,
      endDate: payload.endDate,
    }),
    clearOnlineOfflineFilterFilterDateRange: (state) => ({
      ...initialState,
      selectedUserIds: state.selectedUserIds,
      startDate: undefined,
      endDate: undefined,
    }),
  },
  extraReducers: ((builder) => builder
    .addCase(getProcessOnlineOfflineLog.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      processActivityOnlineOfflineAdapter.setMany(state, action.payload);
    })
    .addCase(getProcessOnlineOfflineLog.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(getProcessOnlineOfflineLog.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
  ),
});

export const {
  resetLogsState,
  setActivityLogFilterUsersIds,
  setOnlineOfflineFilterDateRange,
  clearOnlineOfflineFilterFilterDateRange,
} = processActivityOnlineOfflineSlice.actions;

export const { selectById, selectAll } = processActivityOnlineOfflineAdapter.getSelectors();
