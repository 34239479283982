import { Box } from '@mui/system';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { Add20px } from '../../icons/Add';
import useDealsSlice from '../../hooks/useDealsSlice';
import useSideMenu from '../../hooks/useSideMenu';

const MobileBottomMenuAddButton = () => {
  const { isOpened } = useSideMenu();
  const { onDialogStatusChange } = useDealsSlice();

  if (!isOpened) {
    return (
      <AppIconButton
        onClick={() => onDialogStatusChange(true)}
        size="l"
        variant="primary"
        icon={<Add20px />}
      />
    );
  }
  return (
    <Box
      sx={{
        width: '40px',
      }}
    />
  );
};

export default MobileBottomMenuAddButton;
