import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
// import { useMemo } from 'react';
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import { EComponentsIds } from '../../../shared/ComponentsIdsAndClassNames/ComponentsIdsAndClassNames';
import { selectDealViewMode } from '../../features/Layout/lib/fieldsLayoutSlice';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';

interface Props {
  children: JSX.Element;
  toFetchId?: string | null | undefined;
  onWrapperReady?: (status: boolean) => void;
}

const ProcessLayoutWrapper = ({
  children,
  toFetchId,
  onWrapperReady,
}: Props): JSX.Element => {
  const isBackDropOpen: string = useAppSelector((state) => state?.tasks?.selectedTaskId);
  const viewMode = useAppSelector(selectDealViewMode);
  const isTemplatePreview: boolean = useAppSelector((state) => state.process?.process?.type) === EProcessType.TEMPLATE && !!toFetchId;

  useEffect(() => {
    if (onWrapperReady) {
      onWrapperReady(true);
    }
    return () => {
      if (onWrapperReady) {
        onWrapperReady(false);
      }
    };
  }, []);

  return (
    <Box
      className="ProcessLayoutWrapper"
      id={EComponentsIds.PROCESS_LAYOUT_WRAPPER}
      sx={{
        display: 'flex',
        flex: 1,
        width: isTemplatePreview || isMobile || viewMode === 'internal' ? 'calc( 100% )' : 'calc( 100% - 300px )',
        maxWidth: isTemplatePreview || isMobile || viewMode === 'internal' ? 'calc( 100% )' : 'calc( 100% - 300px )',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'relative',
        overflowY: isBackDropOpen ? 'hidden' : 'overlay',
        overflowX: 'hidden',
        height: 'calc( 100%)',
        minHeight: 'calc( 100%)',
        '@-moz-document url-prefix()': {
          overflow: 'auto',
          scrollbarWidth: 'none',
          MozBackfaceVisibility: 'hidden',
        },
        WebkitBackfaceVisibility: 'hidden',
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default ProcessLayoutWrapper;
