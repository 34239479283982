import { SvgIcon, SvgIconProps } from '@mui/material';

export const AppSumoIcon33 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="149"
    height="33"
    viewBox="0 0 149 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '149px',
      height: '33px',
    }}
    {...props}
  >
    <path
      d="M89.4433 18.4719C89.4433 21.4298 88.0781
      21.4298 87.3955 21.4298C86.713 21.4298 85.3478
      21.4298 85.3478 18.4719V10.1293H81.8591V19.0028C81.8591
      22.4157 84.1343 24.8426 87.3955 24.8426C90.6567 24.8426
      92.932 22.4157 92.932 19.0028V10.1293H89.4433V18.4719ZM123.648
      9.67428C119.401 9.67428 115.912 13.0872 115.912 17.4101C115.912
      21.6573 119.401 25.146 123.648 25.146C127.895 25.146 131.384 21.6573
      131.384 17.4101C131.384 13.163 127.895 9.67428 123.648
      9.67428ZM123.648 21.5814C121.297 21.5814 119.477 19.6854
      119.477 17.3343C119.477 15.059 121.297 13.163 123.572
      13.0872C125.847 13.0872 127.743 14.9074 127.819
      17.1826V17.2585C127.819 19.6854 125.923 21.5814
      123.648 21.5814ZM25.4328 7.70239L17.6211 24.691H21.5649L23.0817
      21.4298H27.7839L29.3007 24.691H33.2445L25.4328
      7.70239ZM23.916 18.5478L25.357 14.9832L26.798
      18.5478H23.916ZM42.2697 10.2052H36.5815V24.5393H40.1461V20.4438H42.0422C45.5309
      20.4438 47.7303 18.4719 47.7303 15.2866V15.2107C47.8061
      12.1012 45.6826 10.2052 42.2697 10.2052ZM44.1657
      15.4383C44.1657 16.6517 43.3315 17.4101 41.9663
      17.4101H40.1461V13.4664H41.9663C43.4073 13.3905
      44.1657 14.1489 44.1657 15.4383ZM56.983 10.2052H51.2949V24.5393H54.8594V20.4438H56.7555C60.2442
      20.4438 62.4436 18.4719 62.4436 15.2866V15.2107C62.5194
      12.1012 60.3959 10.2052 56.983 10.2052ZM58.879 15.4383C58.879
      16.6517 58.0448 17.4101 56.6796 17.4101H54.8594V13.4664H56.6796C58.1206
      13.3905 58.879 14.1489 58.879 15.4383ZM73.0614 14.7557C70.2553
      13.9973 69.6486 13.6939 69.6486 12.708V12.6321C69.6486 11.8737
      70.407 11.3428 71.6204 11.3428C73.1373 11.4186 74.5783 11.9495
      75.7917 12.9355L75.8676 13.0113L77.8395 10.1293L77.7636
      10.0535C76.0193 8.68833 73.8199 7.92992 71.6204 7.92992C68.2076
      7.92992 65.7806 9.97765 65.7806 12.9355V13.0113C65.7806 16.4242
      68.1317 17.3343 71.3171 18.0927C74.0474 18.7753 74.5024 19.2303
      74.5024 20.0646V20.1404C74.5024 21.0506 73.6682 21.5814 72.303
      21.5814C70.5587 21.5056 68.8143 20.823 67.525 19.6096L67.4492
      19.5337L65.1739 22.264L65.2497 22.3399C67.1458 24.0842 69.6486
      24.9943 72.2272 24.9943C76.0193 24.9943 78.3704 23.0224 78.3704
      19.8371V19.7612C78.3704 16.7276 76.2468 15.5141 73.0614
      14.7557ZM104.687 25.2977L100.364 18.0169L99.0752
      24.7668H95.9657L99.151 9.67428L104.763 19.1545L110.072
      9.67428L113.258 24.7668H109.921L108.707 18.0927L104.687
      25.2977Z"
      fill="#FFBC00"
    />
  </SvgIcon>
);
