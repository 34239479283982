import { PaletteMode } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import { components } from './components';
import { stagesetPalette } from './stagesetPalette';

/**
 * Customized Material UI themes for "light" and "dark" modes.
 *
 * @see https://next.material-ui.com/customization/default-theme/
 */

const defaultContrastTextColor = '#000';

export const getPaletteDefaultParams = (mode: 'light' | 'dark') => ({
  palette: {
    mode,
    ...stagesetPalette,
  },
  dealOverrides: {
    // primaryContrast: {
    //   light: defaultContrastTextColor,
    //   main: defaultContrastTextColor,
    //   dark: defaultContrastTextColor,
    //   50: defaultContrastTextColor,
    //   100: defaultContrastTextColor,
    //   200: defaultContrastTextColor,
    //   300: defaultContrastTextColor,
    //   400: defaultContrastTextColor,
    //   500: defaultContrastTextColor,
    //   600: defaultContrastTextColor,
    // },
    // secondaryContrast: {
    //   light: defaultContrastTextColor,
    //   main: defaultContrastTextColor,
    //   dark: defaultContrastTextColor,
    //   50: defaultContrastTextColor,
    //   100: defaultContrastTextColor,
    //   200: defaultContrastTextColor,
    //   300: defaultContrastTextColor,
    //   400: defaultContrastTextColor,
    //   500: defaultContrastTextColor,
    //   600: defaultContrastTextColor,
    // },
    sidebar: {
      backgroundColor: '#ffffff',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: [
      '"Roboto"',
    ].join(','),
  },
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1366,
      xl: 1536,
    },
  },
});

export const paletteDefaultArgs = {
  typography: {
    h1: { fontSize: '2em' },
    h2: { fontSize: '1.5em' },
    h3: { fontSize: '1.3em' },
    h4: { fontSize: '1em' },
    h5: { fontSize: '0.8em' },
    h6: { fontSize: '0.7em' },
    button: { textTransform: 'none' },
  },
};

const [lightTheme, darkTheme] = (['light', 'dark'] as PaletteMode[]).map((mode) => createTheme(
  getPaletteDefaultParams(mode),
  paletteDefaultArgs,
));

export default {
  light: lightTheme,
  dark: darkTheme,
} as { [key in PaletteMode]: Theme };
