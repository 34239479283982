import TemplatesFilter from '../../pages/templates/TemplatesFilter';
import { SidebarContent } from '../Stages/Styles/SidebarStyles';
import { useNewPageContext } from './NewPageContextProvider/NewPageContextProvider';
import { NewPagePaddingBox, NewPageTemplateErrorText, NewPageTemplateItemsContainer } from './Styles/NewPageMainTabStyles';

const NewPageTemplateTab = () => {
  const { data } = useNewPageContext();
  const { templateError, templatesMenuItems } = data;

  return (
    <SidebarContent
      className="sidebar-new-page-template"
    >
      <NewPagePaddingBox>
        <TemplatesFilter
          newPageTemplate
        />
      </NewPagePaddingBox>
      {templateError && (
        <NewPagePaddingBox
          className="new-page-template-error"
        >
          <NewPageTemplateErrorText>{templateError}</NewPageTemplateErrorText>
        </NewPagePaddingBox>
      )}
      {templatesMenuItems && templatesMenuItems[0][0] && (
        <NewPageTemplateItemsContainer>
          {templatesMenuItems}
        </NewPageTemplateItemsContainer>
      )}
    </SidebarContent>
  );
};

export default NewPageTemplateTab;
