import { TabContext } from '@mui/lab';
import Drawer from '@mui/material/Drawer';
import { Box, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { ELibraryFeedTab } from '../helpers/library.helper';
import {
  selectLibraryFeedTab,
  setLibraryFeedTab,
} from '../lib/librarySlice';
import ProductFeedTab from './tabs/ProductFeedTab';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const LibraryFeed = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentFeedTab: ELibraryFeedTab = useAppSelector(selectLibraryFeedTab);
  const [open, setOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState('600px');
  const [drawerAnchor, setDrawerAnchor] = useState<Anchor>('right');

  const toggleDrawer = (anchor: Anchor) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(setLibraryFeedTab({
      open: false,
      type: ELibraryFeedTab.NONE,
      item: undefined,
    }));
  };

  useEffect(() => {
    switch (currentFeedTab) {
      case ELibraryFeedTab.NONE:
        setOpen(false);
        break;
      case ELibraryFeedTab.PRODUCT:
        setDrawerAnchor('right');
        setDrawerWidth('500px');
        setOpen(true);
        break;
      default:
        setOpen(false);
        break;
    }
  }, [currentFeedTab]);

  return (
    <Drawer
      anchor={drawerAnchor}
      open={open}
      keepMounted
      disablePortal={isMobile}
      variant={isMobile ? 'persistent' : 'temporary'}
      onClose={toggleDrawer(drawerAnchor)}
      slotProps={{
        backdrop: {
          sx: {
            background: 'rgba(26, 25, 41, 0.1)',
          },
        },
      }}
      hideBackdrop={isMobile}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : drawerWidth,
          overflow: 'hidden',
          zIndex: 1300,
          position: isMobile ? 'absolute' : 'fixed',
        },
      }}
      sx={{
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          marginX: '0px',
          display: 'flex',
          flex: 1,
          position: 'sticky',
          maxHeight: '100%',
          maxWidth: '100%',
          border: '1px solid #E6E6E6',
          borderTop: 'none',
          backgroundColor: stagesetColors.background?.[300],
        }}
      >
        <Stack
          id="deal-feed"
          sx={{
            flex: 1,
            width: '100%',
          }}
        >
          <TabContext
            value={currentFeedTab}
          >
            <ProductFeedTab
              value={ELibraryFeedTab.PRODUCT}
            />
          </TabContext>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default LibraryFeed;
