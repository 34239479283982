import { EBottomDrawerTabs, setMobileBottomDrawerTab } from '../features/Layout/lib/fieldsLayoutSlice';
import { useAppDispatch } from './stateHooks';

export const useMobileBottomDrawer = () => {
  const dispatch = useAppDispatch();

  const closeDrawer = () => {
    dispatch(setMobileBottomDrawerTab(EBottomDrawerTabs.NULL));
  };

  const openDrawer = (tab: EBottomDrawerTabs) => dispatch(setMobileBottomDrawerTab(tab));
  return {
    closeDrawer,
    openDrawer,
  };
};
