import { useEffect } from 'react';
import useFieldsLayoutSlice from './useFieldsLayoutSlice';
import useScreenDimensions from './useScreenDimensions';
import { useAppSelector } from './stateHooks';
import { selectStageFirstLastChildStatus } from '../features/ProcessFields/lib/processItemsSlice';

const useBookmarkSetter = (id: string): void => {
  const [height] = useScreenDimensions(true);

  const {
    isActiveSection,
    onSetActiveSection,
    isActiveSectionUpdateDisabled,
    getLayoutWrapperRef,
  } = useFieldsLayoutSlice(id);

  const [isFirst, isLast] = useAppSelector((state) => selectStageFirstLastChildStatus(state, id));

  const onScrollHandler = () => {
    const scrollToRef = document.getElementById(id);
    if (!scrollToRef) { return; }
    const elemtntPositionTop: number = scrollToRef.getBoundingClientRect().top;
    const elemtntPositionBottom: number = scrollToRef.getBoundingClientRect().bottom;

    if ((elemtntPositionTop > height) || (elemtntPositionBottom < 0)) {
      return;
    }

    const detectionLine: number[] = [height / 2 + 10, height / 2 - 10];

    const shouldUpdate: boolean = (
      isFirst && (elemtntPositionBottom > detectionLine[1])
    )
      || (
        isLast && (elemtntPositionBottom < height)
      )
      || (
        (detectionLine[0] > elemtntPositionTop)
      && (elemtntPositionTop > detectionLine[1])
      );

    if (shouldUpdate && !isActiveSection) {
      onSetActiveSection();
    }
  };

  useEffect(() => {
    if (!isActiveSection && getLayoutWrapperRef && !isActiveSectionUpdateDisabled) {
      getLayoutWrapperRef.addEventListener('scroll', onScrollHandler);
    }
    return () => {
      if (!isActiveSection && getLayoutWrapperRef) {
        getLayoutWrapperRef.removeEventListener('scroll', onScrollHandler);
      }
    };
  }, [isActiveSection, isActiveSectionUpdateDisabled]);
};

export default useBookmarkSetter;
