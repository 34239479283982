import {
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';

const sectionsSliceReducerName = 'esignatureSlice';

type ESignatureSliceType = {
  deleteRequestModalData?: boolean;
  deleteUserRequestModalData?: string;
  deleteUserRequestModalDataId?: string;
  resendUserRequestModalData?: string;
};

const initialState: ESignatureSliceType = {
};

export const esignatureSlice = createSlice({
  name: sectionsSliceReducerName,
  initialState,
  reducers: {
    setDeleteRequestModalData: (state, { payload }) => ({
      ...state,
      deleteRequestModalData: payload,
    }),
    setDeleteUserRequestModalData: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setResendUserRequestModalData: (state, { payload }) => ({
      ...state,
      resendUserRequestModalData: payload,
    }),
  },
});

export const {
  setDeleteRequestModalData,
  setDeleteUserRequestModalData,
  setResendUserRequestModalData,
} = esignatureSlice.actions;

export const selectDeleteRequestModalData = (state: RootState) => (state.esignature.deleteRequestModalData);
export const selectDeleteUserRequestModalData = (state: RootState) => (state.esignature.deleteUserRequestModalData);
export const selectDeleteUserRequestModalDataId = (state: RootState) => (state.esignature.deleteUserRequestModalDataId);
export const selectResendUserRequestModalData = (state: RootState) => (state.esignature.resendUserRequestModalData);
