import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledSkeleton = styled(Skeleton)(() => ({
  border: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  backgroundColor: stagesetColors.newGrey[50],
}));

export const SkeletonStringsLayout = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

export const SkeletonStringsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  maxWidth: '800px',
  padding: isMobile ? '50px 8px' : '50px 59px',
}));

export const SkeletonStringsTemplateContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: '100%',
  height: '100%',
  padding: '16px',
}));
