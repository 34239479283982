import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import {
  CellHeader,
  CellInfo,
  DomainCellTitle,
  DomainTable,
  DomianTableListElement,
  InfoTableBullet,
  InfoTableSubtitle,
  InfoTableTitle,
  InfoTableWrapper,
  InstructionsTable,
  TableRowWrapper,
} from '../../Styles/CustomDomainTabStyles';
import { config } from '../../../../../core/config';
import { AppDashedBorderBox } from '../../../../../shared/AppDashedBorderBox/AppDashedBorderBox';
import { SettingsFormWrapper } from '../../Styles/GeneralTabStyles';

const PremiumUserGuide = () => (
  <SettingsFormWrapper
    className={classNames('settings-column', { 'settings-mobile': isMobile })}
  >
    <InfoTableTitle
      className={classNames({ 'info-table-title-mobile': isMobile })}
    >
      To set custom domain for your Stageset account perform the following steps:
    </InfoTableTitle>
    <InstructionsTable>
      <DomianTableListElement>
        <InfoTableBullet>
          1
        </InfoTableBullet>
        <InfoTableSubtitle>
          Specify
          <span>
            &thinsp;subdomain&thinsp;
          </span>
          for your organization. It should include only latin symbols (a-z) and digits (0-9).
        </InfoTableSubtitle>
      </DomianTableListElement>
      <DomianTableListElement>
        <InfoTableBullet>
          2
        </InfoTableBullet>
        <InfoTableSubtitle>
          Once subdomain is set specify
          <span>
            &thinsp;custom domain&thinsp;
          </span>
          that you own and save.
          <br />
          Custom domain can look like
          <span>
            &thinsp;&ldquo;company.com&ldquo;&thinsp;
          </span>
          (first level domain) or it can be
          <span>
            &thinsp;&ldquo;subname.company.com&ldquo;&thinsp;
          </span>
          (second level domain).
        </InfoTableSubtitle>
      </DomianTableListElement>
      <DomainTable>
        <DomianTableListElement>
          <InfoTableBullet>
            3
          </InfoTableBullet>
          <InfoTableSubtitle>
            Add new
            <span>
              &thinsp;DNS record&thinsp;
            </span>
            for your
            <span>
              &thinsp;custom domain.
            </span>
            <br />
            Particular steps may vary depending on the registrator (Name.com, GoDaddy.com and so on).
            Typically following record is sufficient to serve this purpose.
          </InfoTableSubtitle>
        </DomianTableListElement>
        <InfoTableWrapper>
          <TableRowWrapper>
            <DomainCellTitle
              className="cell-title-grey"
            >
              Type
            </DomainCellTitle>
            <AppDashedBorderBox />
            <CellInfo>
              A
            </CellInfo>
          </TableRowWrapper>
          <TableRowWrapper>
            <DomainCellTitle
              className="cell-title-grey"
            >
              Address/Content/Value
            </DomainCellTitle>
            <AppDashedBorderBox />
            <CellInfo>
              { config.app.approximatedClusterIP }
            </CellInfo>
          </TableRowWrapper>
          <TableRowWrapper>
            <DomainCellTitle
              className="cell-title-grey"
            >
              TTL
            </DomainCellTitle>
            <AppDashedBorderBox />
            <CellInfo>
              3600
            </CellInfo>
          </TableRowWrapper>
          <CellHeader>If your domain is the first level domain</CellHeader>
          <TableRowWrapper>
            <DomainCellTitle
              className="cell-title-grey"
            >
              Hostname/Name
            </DomainCellTitle>
            <AppDashedBorderBox />
            <CellInfo>
              @
            </CellInfo>
          </TableRowWrapper>
          <CellHeader>If your domain is the second level domain</CellHeader>
          <TableRowWrapper>
            <DomainCellTitle
              className="cell-title-grey"
            >
              Hostname/Name
            </DomainCellTitle>
            <AppDashedBorderBox />
            <CellInfo>
              subname
            </CellInfo>
          </TableRowWrapper>
        </InfoTableWrapper>
      </DomainTable>

      <DomianTableListElement>
        <InfoTableBullet>
          4
        </InfoTableBullet>
        <InfoTableSubtitle>
          Periodically check your DNS status by clicking on
          <span>
            &thinsp;Update&thinsp;
          </span>
          button.
          It can take some time but typically it is a matter of several minutes.
        </InfoTableSubtitle>
      </DomianTableListElement>
      <DomianTableListElement>
        <InfoTableBullet>
          5
        </InfoTableBullet>
        <InfoTableSubtitle>
          Once DNS status is confirmed press
          <span>
            &thinsp;;Activate&thinsp;
          </span>
          button to use your
          <span>
            &thinsp;custom domain&thinsp;.
          </span>
        </InfoTableSubtitle>
      </DomianTableListElement>
      <DomainCellTitle>
        If you faced any problems, please contact us.
      </DomainCellTitle>
    </InstructionsTable>
  </SettingsFormWrapper>
);

export default PremiumUserGuide;
