import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { OrganizationAdminDTO, OrganizationDTO } from '../../../../../../shared/OrganizationDTO';
import { withOrganizationDetailsGeneral } from './withOrganizationDetailsGeneral';
import { AppInput } from '../../../../../shared/AppInput/AppInput';
import AppCurrencySelector from '../../../../../shared/AppCurrencySelector/AppCurrencySelector';
import { UserRolesDTO } from '../../../../../../shared/user-organization';
import { EOrganizationRoles, RoleDTO } from '../../../../../../shared/permissions';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../../../../common/DeleteTypography';
import NormalizedSvgIcon from '../../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import AlertTriangle from '../../../../../icons/AlertTriangle.svg';
import { useOrganization } from '../../../../../hooks/useOrganization';
import { adminUpdateOrganizationCDEnabled, adminUpdateOrganizationOwner, adminUpdateOrganizationPublicApiEnabled } from '../../../state/adminSlice';
import { useAppDispatch } from '../../../../../hooks/stateHooks';
import { AppSwitch } from '../../../../../shared/AppSwitch/AppSwitch';
import { PTTabHeader } from '../../../../../features/Stages/Styles/SidebarPricingTableTabStyles';

interface Props {
  organization: OrganizationDTO;
  organizationAdmin: OrganizationAdminDTO,
  userRoles: UserRolesDTO[];
  setOrganizationAdmin: (org: OrganizationAdminDTO) => void;
}

interface InputProps {
  organization: OrganizationDTO;
  organizationAdmin: OrganizationAdminDTO,
  userRoles: UserRolesDTO[];
  setOrganizationAdmin: (org: OrganizationAdminDTO) => void;
}

const OrganizationDetailsGeneral = ({
  organization,
  organizationAdmin,
  userRoles,
  setOrganizationAdmin,
}: Props): JSX.Element => {
  const {
    roles, roleNames,
  } = useOrganization();
  const dispatch = useAppDispatch();
  const [organizationAdminObject, setOrganizationAdminObject] = useState<OrganizationAdminDTO>(organizationAdmin);
  const [currency, setCurrency] = useState(organizationAdmin.currency);
  const [orgOwnerId, setOrgOwnerId] = useState<string | null>(null);
  const [openOwner, setOpenOwner] = useState(false);
  const [isChangeOwnerConfirmationDialogOpen, setChangeOwnerConfirmationDialogOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [cdEnabled, setCDEnabled] = useState(!!organizationAdmin?.enableCustomDomain);
  const [publicApiEnabled, setPublicApiEnabled] = useState(!!organizationAdmin?.isPublicApiEnabled);

  const userRole = roles?.find((x: RoleDTO) => x.name === EOrganizationRoles.SELLER);
  const adminRole = roles?.find((x: RoleDTO) => x.name === EOrganizationRoles.ADMIN);
  const ownerRole = roles?.find((x: RoleDTO) => x.name === EOrganizationRoles.OWNER);

  useEffect(() => {
    const owner = userRoles?.find((x: UserRolesDTO) => x.roles[0].name === EOrganizationRoles.OWNER);
    if (owner?.user) {
      setOrgOwnerId(owner.user.id);
      setSelectedUserId(owner.user.id);
    }
  }, [userRoles]);

  const changeCurrency = (newCurrency: string) => {
    setOrganizationAdmin({
      ...organizationAdminObject,
      currency: newCurrency,
    });
    setCurrency(newCurrency);
  };

  const handleChangeOwner = () => {
    setChangeOwnerConfirmationDialogOpen(true);
  };

  const onChangeOwnerConfirm = (): void => {
    setChangeOwnerConfirmationDialogOpen(false);
    if (!!value && !!selectedUserId && !!orgOwnerId) {
      dispatch(adminUpdateOrganizationOwner({
        organizationId: organizationAdmin.id,
        oldOwnerId: orgOwnerId,
        newOwnerId: selectedUserId,
        oldOwnerRoleId: value,
        newOwnerRoleId: ownerRole.id,
      }));
    }
  };

  const onChangeOwnerCancel = (): void => {
    setChangeOwnerConfirmationDialogOpen(false);
    setSelectedUserId(orgOwnerId);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to change the Owner?
      If yes select role for the old owner
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {!!userRole && (<FormControlLabel value={userRole.id} control={<Radio />} label={roleNames[userRole.name]} />)}
          {!!adminRole && (<FormControlLabel value={adminRole.id} control={<Radio />} label={roleNames[adminRole.name]} />)}
        </RadioGroup>
      </FormControl>
    </DeleteTypography>
  );

  const changeCustomDomainAccessibility = (newValue: boolean) => {
    setCDEnabled(newValue);
    dispatch(adminUpdateOrganizationCDEnabled({
      organizationId: organizationAdmin.id,
      enableCustomDomain: newValue,
    }));
  };

  const changePublicApiAccessibility = (newValue: boolean) => {
    setPublicApiEnabled(newValue);
    dispatch(adminUpdateOrganizationPublicApiEnabled({
      organizationId: organizationAdmin.id,
      enablePublicApi: newValue,
    }));
  };

  return (
    <Box
      className="AdminOrganizationDetailsSection"
    >
      <Typography
        className="OrganizationSectionTitle"
      >
        General
      </Typography>
      <Box
        sx={{
          paddingTop: '16px',
        }}
      >
        <InputLabel id="role-select-label">Owner</InputLabel>
        <Select
          fullWidth
          labelId="role-select-label"
          id="role-select"
          open={openOwner}
          onClose={() => {
            setOpenOwner(false);
          }}
          onOpen={() => {
            setOpenOwner(true);
          }}
          value={selectedUserId}
          onChange={(event: any) => {
            setSelectedUserId(event.target.value);
            handleChangeOwner();
          }}
          sx={{
            borderRadius: '10px',
            '& .MuiSelect-select': {
              padding: '8px',
            },
          }}
        >
          {
            !!userRoles && userRoles.map((item: UserRolesDTO) => (
              <MenuItem value={item.user.id} key={item.user.id}>{item.user.email}</MenuItem>
            ))
          }
        </Select>
      </Box>
      <Box
        sx={{
          paddingTop: '12px',
        }}
      >
        <AppInput
          fullWidth
          label="Tariff"
          defaultValue={organizationAdminObject.tariff}
          value={organizationAdminObject.tariff}
          rows={4}
          disabled
        />
      </Box>
      <Box
        sx={{
          paddingTop: '12px',
        }}
      >
        <AppInput
          fullWidth
          label="Domain"
          defaultValue={organizationAdminObject.domain}
          value={organizationAdminObject.domain}
          rows={4}
          disabled
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <AppSwitch
          checked={cdEnabled}
          onChange={(e) => {
            changeCustomDomainAccessibility(e.target.checked);
          }}
        />
        <PTTabHeader>
          Custom domain
        </PTTabHeader>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <AppSwitch
          checked={publicApiEnabled}
          onChange={(e) => {
            changePublicApiAccessibility(e.target.checked);
          }}
        />
        <PTTabHeader>
          Public Api
        </PTTabHeader>
      </Box>
      <Box
        sx={{
          paddingTop: '12px',
        }}
      >
        <AppCurrencySelector
          currency={currency}
          setCurrency={changeCurrency}
          size={50}
        />
      </Box>
      <Box
        sx={{
          paddingTop: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              flex: 2,
            }}
          >
            Rooms
          </Box>
          <Box
            sx={{
              flex: 5,
            }}
          >
            {organizationAdminObject.totalProcesses}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              flex: 2,
            }}
          >
            Organization ID
          </Box>
          <Box
            sx={{
              flex: 5,
            }}
          >
            {organizationAdminObject.orgSupportId}
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        isDialogOpen={isChangeOwnerConfirmationDialogOpen}
        onCancel={onChangeOwnerCancel}
        onConfirm={onChangeOwnerConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Change"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </Box>
  );
};

export default withOrganizationDetailsGeneral<InputProps, Props>(OrganizationDetailsGeneral);
