import { styled } from '@mui/styles';
import { Box } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const SubscriptionTabHeaderTextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const SubscriptionBoldTitle = styled(Box)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500,
  color: stagesetColors.newGrey[800],
}));

export const AppSumoLogoWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '114px',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '31px',
}));

export const IntervalSelectWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  paddingTop: '16px',
}));

export const SubscriptionsTabGridContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 344px))',
  gap: '24px',
  justifyContent: 'center',
  '&.subscriptions-tab-grid-container-mobile': {
    gap: '12px',
  },
}));

export const ButtonPlaceholder = styled(Box)(() => ({
  height: '48px',
}));
