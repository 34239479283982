import {
  Node, NodeViewProps, ReactNodeViewRenderer, mergeAttributes,
} from '@tiptap/react';
import { ExtensionNames } from '../../Utils/TiptapHelpers';
import MediaNodeComponent from './MediaNodeComponent/MediaNodeComponent';
import TiptapNodeWrapper from '../../TiptapNodeWrapper';

const MediaNodeExtension = Node.create({
  name: ExtensionNames.MEDIA, // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      src: {
        default: null,
      },
      subType: {
        default: null,
      },
      storageType: {
        default: null,
      },
      adjustBy: {
        default: null,
      },
      align: {
        default: null,
      },
      doc: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: ExtensionNames.MEDIA,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [ExtensionNames.MEDIA, mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer((nodeProps: NodeViewProps) => (
      <TiptapNodeWrapper
        {...nodeProps}
        component={<MediaNodeComponent />}
        className={ExtensionNames.MEDIA}
      />
    ));
  },
});

export default MediaNodeExtension;
