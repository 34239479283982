import { SvgIcon, SvgIconProps } from '@mui/material';

export const QuestionIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <g clipPath="url(#clip0_33130_55716)">
      <path
        fill="none"
        d="M10.0003 18.3334C14.6027
      18.3334 18.3337 14.6025 18.3337
      10.0001C18.3337 5.39771 14.6027
      1.66675 10.0003 1.66675C5.39795
      1.66675 1.66699 5.39771 1.66699
      10.0001C1.66699 14.6025 5.39795
      18.3334 10.0003 18.3334Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M7.5752 7.49999C7.77112
        6.94304 8.15782 6.47341
        8.66682 6.17426C9.17583
        5.87512 9.77427 5.76577
        10.3562 5.86558C10.9381
        5.96539 11.4659 6.26792
        11.8461 6.71959C12.2263
        7.17126 12.4344 7.74292
        12.4335 8.33332C12.4335
        9.99999 9.93353 10.8333
        9.93353 10.8333"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M10 14.1667H10.0067"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33130_55716">
        <rect
          width="20"
          height="20"
          fill="none"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
