import { ApiService } from '../core/api';

export class CelloApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('cello');
  }

  async generateToken(): Promise<string> {
    return this.api.get('jwt');
  }

  async getReferrerName(ucc: string): Promise<string> {
    return this.api.get(`referrer-name/${ucc}`);
  }
}
