import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon16 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '16px',
      width: '16px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_28804_21320)">
      <path
        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6
      7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333
      14.6667H13.3333C14.0697 14.6667 14.6667 14.0697
      14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6
      13.3333 6Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.33301 10.0002H2.66634C2.31272 10.0002
      1.97358 9.85969 1.72353 9.60964C1.47348 9.35959
      1.33301 9.02045 1.33301 8.66683V2.66683C1.33301
      2.31321 1.47348 1.97407 1.72353 1.72402C1.97358
      1.47397 2.31272 1.3335 2.66634 1.3335H8.66634C9.01996
      1.3335 9.3591 1.47397 9.60915 1.72402C9.8592 1.97407
      9.99967 2.31321 9.99967 2.66683V3.3335"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_28804_21320">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const CopyIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_36684_41724)">
      <path
        d="M17.4479 8.36865H9.94792C9.02744 8.36865 8.28125
        9.11484 8.28125 10.0353V17.5353C8.28125 18.4558 9.02744
        19.202 9.94792 19.202H17.4479C18.3684 19.202 19.1146
        18.4558 19.1146 17.5353V10.0353C19.1146 9.11484
        18.3684 8.36865 17.4479 8.36865Z"
        stroke="#64748B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.94922 13.3686H4.11589C3.67386 13.3686 3.24993 13.193
        2.93737 12.8805C2.62481 12.5679 2.44922 12.144 2.44922
        11.7019V4.20194C2.44922 3.75992 2.62481 3.33599 2.93737
        3.02343C3.24993 2.71087 3.67386 2.53528 4.11589 2.53528H11.6159C12.0579
        2.53528 12.4818 2.71087 12.7944 3.02343C13.107 3.33599 13.2826 3.75992
        13.2826 4.20194V5.03528"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_28804_21320">
        <rect width="16" height="16" fill="none" transform="translate(0.78125 0.868652)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
