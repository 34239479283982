import { Box, Typography } from '@mui/material';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';

interface Props {
  controlsHeight: number;
  pageNumber: number;
  setPageNumber: (number: number) => void;
  numPages: number | null;
}

const MediaFieldPDFControls = ({
  controlsHeight,
  pageNumber,
  setPageNumber,
  numPages,
}:Props): JSX.Element => (
  <Box
    sx={{
      height: '0px',
    }}
  >
    <Box
      sx={{
        // minHeight: '350px',
        height: `${controlsHeight}px`,
        width: '100%',
        background: 'transparent',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .PdfControls': {
          visibility: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          background: 'rgb(0 0 0  / 65%)',
          opacity: 0,
          transition: 'opacity .3s',
          '& .PdfControlsIcons': {
            color: 'rgb(255 255 255 / 60%)',
            fontSize: '65px',
            '&:hover': {
              color: 'rgb(255 255 255 / 90%)',
            },
          },
        },
        '& .PdfButtons': {
          cursor: 'pointer',
          width: '70px',
          display: 'flex',
          alignItems: 'center',
        },
        '& .PdfPages': {
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'flex-end',
          height: '30px',
          borderRadius: '5px',
          marginBottom: '10px',
          width: '150px',
        },
        '&:hover': {
          '& .PdfControls': {
            visibility: 'visible',
            opacity: 1,
          },
        },
      }}
    >
      <Box
        className="PdfControls PdfButtons"
        onClick={(e) => {
          e.stopPropagation();
          if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
          }
        }}
      >
        <ArrowBackIosRounded
          className="PdfControlsIcons"
        />
      </Box>
      <Box
        className="PdfControls PdfPages"
      >
        <Typography>
          Page
          {' '}
          {pageNumber}
          {' '}
          of
          {' '}
          {numPages}
        </Typography>
      </Box>
      <Box
        className="PdfControls PdfButtons"
        onClick={(e) => {
          e.stopPropagation();
          if (numPages && (pageNumber < numPages)) {
            setPageNumber(pageNumber + 1);
          }
        }}
      >
        <ArrowForwardIosRounded
          className="PdfControlsIcons"
        />
      </Box>
    </Box>
  </Box>
);

export default MediaFieldPDFControls;
