import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { EUserDealPermissions } from '../../../shared/permissions';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { inviteManyUsersToProcess } from '../../routes-old/process/state/dealActions';
import { AppButton } from '../../shared/AppButton/AppButton';
import UserChip from '../../shared/AssigneeSelection/UserChip';
import { AppInput } from '../../shared/AppInput/AppInput';
import { InviteUserAutocomplete, InviteUserAutocompleteForm } from './Styles/InviteUserPanelStyles';

export interface PanelFormState {
  email: string;
}

const InviteUserPanel = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentUserPermissions = useAppSelector((state) => state.process.currentUserPermissions);
  const [inputValue, setInputValue] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [emailsArray, setEmailsArray] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isInviteAvailable = currentUserPermissions[EUserDealPermissions.DEAL_USERS_INVITE];

  const onUserInvite = (e: React.FormEvent): void => {
    e.preventDefault();
    if (isInviteAvailable) {
      try {
        dispatch(inviteManyUsersToProcess({ emails: emailsArray, message }));
        enqueueSnackbar(
          'Invites have been sent',
          {
            variant: 'success',
          },
        );
      } catch (error) {
        console.log(error);
      }
    }
    setEmailsArray([]);
    setErrorMessage(null);
  };

  if (!isInviteAvailable) {
    return null;
  }

  const validateEmail = (email: string) => String(email)
    .toLowerCase()
    .match(
      /\S+@\S+\.\S+/,
    );

  useEffect(() => {
    if (inputValue.length > 0) {
      setErrorMessage(null);
    }
  }, [inputValue]);

  const handleKeyPress = (value: string[]) => {
    if (value.length > 0) {
      if (validateEmail(value[value.length - 1])) {
        setEmailsArray(value);
      } else {
        setErrorMessage('Email must be valid');
      }
    } else {
      setEmailsArray([]);
      setMessage('');
      setErrorMessage(null);
    }
  };

  return (
    <InviteUserAutocompleteForm
      component="form"
      onSubmit={onUserInvite}
    >
      <InviteUserAutocomplete
        freeSolo
        autoComplete={false}
        multiple
        options={[]}
        filterSelectedOptions
        value={emailsArray}
        onChange={(e, value) => {
          handleKeyPress(value as string[]);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <AppInput
            {...params}
            placeholder={emailsArray.length === 0 ? 'Enter email to invite user' : undefined}
            multiline
            label="Add users via email"
            error={!!errorMessage}
            autoComplete="off"
            helperText={errorMessage || ''}
            size="m"
          />
        )}
        renderTags={(tagValue, tagProps) => (tagValue.map((tag, index) => (
          <UserChip
            {...tagProps({ index })}
            className="EmailAutocompleteChip"
            variant="outlined"
            key={`chip-tag-${index}`}
            label={tag as string}
            size="small"
          />
        ))
        )}
      />
      {emailsArray.length > 0 && (
        <>
          <AppInput
            fullWidth
            multiline
            autoFocus
            color="primary"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            minRows={6}
            maxRows={12}
            placeholder="Your message"
          />
          <AppButton
            type="submit"
            size="l"
            variant="primary"
            fullWidth
          >
            Invite
          </AppButton>
        </>
      )}
    </InviteUserAutocompleteForm>
  );
};

export default InviteUserPanel;
