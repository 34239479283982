import {
  EntityState, PayloadAction, createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import { EFileEditType, ELibraryType } from '../../../../shared/library/Library';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import {
  FileCreateData,
  FileGetRequest,
  FileUpdateData,
  FileWithOwnerDTO,
  ProductCreateData,
  ProductGetRequest,
  ProductUpdateData,
  ProductWithOwnerDTO,
} from '../../../../shared/library/LibraryDTO';
import { LibraryApi } from '../../../api/LibraryApi';
import { ELibraryFeedTab, EditFileItemPayload, EditProductItemPayload } from '../helpers/library.helper';

const libraryApi: LibraryApi = new LibraryApi();

interface ILibraryState {
  status: EStateStatus;
  isFirstFetchFinished: boolean;
  filesLoadingJobs: number;
  isProductsFirstFetchFinished: boolean;
  productsLoadingJobs: number;
  gridToRender: ELibraryType;
  isFileItemDialogOpen: boolean;
  isFileLinkDialogOpen: boolean;
  editItem?: FileWithOwnerDTO;
  editType?: EFileEditType;
  editLinkItem?: FileWithOwnerDTO;
  isLibraryFeedOpen: boolean;
  selectedLibraryTab: ELibraryFeedTab;
  feedLibraryTitle: string;
  editProductItem?: ProductWithOwnerDTO;
  files: EntityState<FileWithOwnerDTO>;
  products: EntityState<ProductWithOwnerDTO>;
}

enum ELibraryActions {
  CREATE_LIBRARY_FILE = 'CREATE_LIBRARY_FILE',
  UPDATE_LIBRARY_FILE = 'UPDATE_LIBRARY_FILE',
  GET_LIBRARY_FILES = 'GET_LIBRARY_FILES',
  DELETE_LIBRARY_FILE = 'DELETE_LIBRARY_FILE',
  CREATE_LIBRARY_PRODUCT = 'CREATE_LIBRARY_PRODUCT',
  UPDATE_LIBRARY_PRODUCT = 'UPDATE_LIBRARY_PRODUCT',
  GET_LIBRARY_PRODUCTS = 'GET_LIBRARY_PRODUCTS',
  DELETE_LIBRARY_PRODUCT = 'DELETE_LIBRARY_PRODUCT',
}

const libraryFilesAdapter = createEntityAdapter<FileWithOwnerDTO>();
const libraryProductsAdapter = createEntityAdapter<ProductWithOwnerDTO>();

export const libraryReducerName: string = 'library';

export const initialState: ILibraryState = {
  status: EStateStatus.IDLE,
  isFirstFetchFinished: false,
  filesLoadingJobs: 0,
  isProductsFirstFetchFinished: false,
  productsLoadingJobs: 0,
  gridToRender: ELibraryType.FILE,
  isFileItemDialogOpen: false,
  isFileLinkDialogOpen: false,
  isLibraryFeedOpen: false,
  selectedLibraryTab: ELibraryFeedTab.NONE,
  feedLibraryTitle: '',
  editType: EFileEditType.None,
  files: libraryFilesAdapter.getInitialState(),
  products: libraryProductsAdapter.getInitialState(),
};

export const createFileItem = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.CREATE_LIBRARY_FILE}`,
  async (payload: FileCreateData) => libraryApi.createFileItem(payload),
);

export const updateFileItem = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.UPDATE_LIBRARY_FILE}`,
  async (payload: FileUpdateData) => libraryApi.updateFileItem(payload),
);

export const getFileItems = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.GET_LIBRARY_FILES}`,
  async (payload: FileGetRequest, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const requestParams: Record<string, any> = {
      search: state.libraryFilesFilter.search,
      filter: state.libraryFilesFilter.filterParams,
      payload,
    };
    return libraryApi.getFileItems(requestParams);
  },
);

export const deleteFileItem = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.DELETE_LIBRARY_FILE}`,
  async (payload: string, { dispatch, getState }) => {
    await libraryApi.deleteFileItem(payload);
    const state: RootState = getState() as RootState;
    dispatch(getFileItems(state.library.gridToRender));
  },
);

export const createProductItem = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.CREATE_LIBRARY_PRODUCT}`,
  async (payload: ProductCreateData) => libraryApi.createProductItem(payload),
);

export const updateProductItem = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.UPDATE_LIBRARY_PRODUCT}`,
  async (payload: ProductUpdateData) => libraryApi.updateProductItem(payload),
);

export const getProductItems = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.GET_LIBRARY_PRODUCTS}`,
  async (payload: ProductGetRequest, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const requestParams: Record<string, any> = {
      search: state.libraryProductFilter.search,
      filter: state.libraryProductFilter.filterParams,
      payload,
    };
    return libraryApi.getProductItems(requestParams);
  },
);

export const deletePriductItem = createAsyncThunk(
  `${libraryReducerName}/${ELibraryActions.DELETE_LIBRARY_PRODUCT}`,
  async (payload: string, { dispatch, getState }) => {
    await libraryApi.deleteProductItem(payload);
    const state: RootState = getState() as RootState;
    dispatch(getProductItems(state.library.gridToRender));
  },
);

export const librarySlice = createSlice({
  name: libraryReducerName,
  initialState,
  reducers: {
    setLibraryGridToRender: (state, { payload }: PayloadAction<ELibraryType>) => {
      state.gridToRender = payload;
    },
    changeFileItemDialogStatus: (state, { payload }: PayloadAction<EditFileItemPayload>) => {
      state.isFileItemDialogOpen = payload.open;
      state.editItem = payload.item;
      state.editType = payload.editType || EFileEditType.None;
    },
    changeFileLinkDialogStatus: (state, { payload }: PayloadAction<EditFileItemPayload>) => {
      state.editLinkItem = payload.item;
      state.isFileLinkDialogOpen = payload.open;
    },
    setLibraryFeedTab: (state, { payload }: PayloadAction<EditProductItemPayload>) => {
      state.selectedLibraryTab = payload.type;
      state.isLibraryFeedOpen = payload.open;
      state.editProductItem = payload.item;
    },
    setLibraryFeedTitle: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      feedLibraryTitle: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFileItems.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.filesLoadingJobs += 1;
      })
      .addCase(getFileItems.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getFileItems.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isFirstFetchFinished = true;
        state.filesLoadingJobs -= 1;
        libraryFilesAdapter.setAll(state.files, action.payload);
      })
      .addCase(createFileItem.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.filesLoadingJobs += 1;
      })
      .addCase(createFileItem.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(createFileItem.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isFirstFetchFinished = true;
        state.filesLoadingJobs -= 1;
        libraryFilesAdapter.addOne(state.files, action.payload);
      })
      .addCase(updateFileItem.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.filesLoadingJobs += 1;
      })
      .addCase(updateFileItem.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateFileItem.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isFirstFetchFinished = true;
        state.filesLoadingJobs -= 1;
        libraryFilesAdapter.updateOne(state.files, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
      })
      .addCase(getProductItems.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.productsLoadingJobs += 1;
      })
      .addCase(getProductItems.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProductItems.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isProductsFirstFetchFinished = true;
        state.productsLoadingJobs -= 1;
        libraryProductsAdapter.setAll(state.products, action.payload);
      })
      .addCase(createProductItem.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.productsLoadingJobs += 1;
      })
      .addCase(createProductItem.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(createProductItem.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isProductsFirstFetchFinished = true;
        state.productsLoadingJobs -= 1;
        libraryProductsAdapter.addOne(state.products, action.payload);
      })
      .addCase(updateProductItem.pending, (state) => {
        state.status = EStateStatus.LOADING;
        state.productsLoadingJobs += 1;
      })
      .addCase(updateProductItem.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateProductItem.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isProductsFirstFetchFinished = true;
        state.productsLoadingJobs -= 1;
        libraryProductsAdapter.updateOne(state.products, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
      });
  },
});

export const {
  setLibraryGridToRender,
  changeFileItemDialogStatus,
  changeFileLinkDialogStatus,
  setLibraryFeedTab,
  setLibraryFeedTitle,
} = librarySlice.actions;

export const {
  selectAll,
  selectById,
  selectIds,
} = libraryFilesAdapter.getSelectors();
export const {
  selectAll: selectAllProducts,
  selectById: selectByIdProducts,
  selectIds: selectIdsProducts,
} = libraryProductsAdapter.getSelectors();

export const allFilesSelector = (state: RootState) => selectAll(state.library.files);
export const allProductsSelector = (state: RootState) => selectAllProducts(state.library.products);
export const selectLibraryGridToRender = (state: RootState) => state.library.gridToRender;
export const selectFilesLoadingJobs = (state: RootState) => state.library.filesLoadingJobs;
export const selectProductsLoadingJobs = (state: RootState) => state.library.productsLoadingJobs;
export const selectFilesFirstFetchStatus = (state: RootState) => state.library.isFirstFetchFinished;
export const selectProductsFirstFetchStatus = (state: RootState) => state.library.isProductsFirstFetchFinished;
export const selectIsFileItemDialogOpen = (state: RootState) => state.library.isFileItemDialogOpen;
export const selectIsFileLinkDialogOpen = (state: RootState) => state.library.isFileLinkDialogOpen;
export const selectLoadingStatus = (state: RootState) => state.library.status;
export const selectEditItem = (state: RootState) => state.library.editItem;
export const selectEditLinkItem = (state: RootState) => state.library.editLinkItem;
export const selectLibraryFeedOpen = (state: RootState): boolean => state.library.isLibraryFeedOpen;
export const selectLibraryFeedTab = (state: RootState): ELibraryFeedTab => state.library.selectedLibraryTab;
export const selectLibraryFeedTitle = (state: RootState): string => state?.library?.feedLibraryTitle;
export const selectEditProductItem = (state: RootState) => state.library.editProductItem;
export const selectEditFileType = (state: RootState) => state.library.editType;
