import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/styles';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const ToggleButtonTabs = styled(Tabs)(() => ({
  gap: '8px',
  padding: '5px',
  borderRadius: '12px',
  backdropFilter: 'none',
  boxShadow: 'none',
  '& .MuiTabs-indicator': {
    display: 'none',
    height: '0px',
  },
  '&.toggle-button-tabs-desktop': {
    height: '40px',
    minHeight: '40px',
    padding: '2px',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px 0px rgba(30, 41, 59, 0.20) inset',
    '& .MuiTabs-flexContainer': {
      gap: '2px',
    },
  },
  '&.toggle-button-tabs-desktop-light': {
    backgroundColor: stagesetColors.newGrey[200],
  },
  '&.toggle-button-tabs-desktop-dark': {
    backgroundColor: stagesetColors.newGrey[700],
  },
  '&.toggle-button-tabs-mobile': {
    height: '40px',
    minHeight: '40px',
    padding: '2px',
    borderRadius: '10px',
    backgroundColor: stagesetColors.newGrey[100],
    boxShadow: '0px 0px 4px 0px rgba(30, 41, 59, 0.20) inset',
  },
}));

export const ToggleButtonTab = styled(Tab)(() => ({
  '&.toggle-button-tab-desktop': {
    height: '36px',
    minHeight: '36px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    '& svg': {
      marginRight: '0px',
    },
  },
  '&.toggle-button-tab-desktop-light': {
    color: stagesetColors.newGrey[800],
    '& svg': {
      '& path': {
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&.Mui-selected': {
      backgroundColor: stagesetColors.newGrey[800],
      color: stagesetColors.white[100],
      '& svg': {
        '& path': {
          stroke: stagesetColors.white[100],
        },
      },
    },
    '&:hover:not(.Mui-selected)': {
      backgroundColor: stagesetColors.newGrey[100],
    },
  },
  '&.toggle-button-tab-desktop-dark': {
    color: stagesetColors.white[100],
    '& svg': {
      '& path': {
        stroke: stagesetColors.white[100],
      },
    },
    '&.Mui-selected': {
      backgroundColor: stagesetColors.white[100],
      color: stagesetColors.newGrey[800],
      '& svg': {
        '& path': {
          stroke: stagesetColors.newGrey[800],
        },
      },
    },
    '&:hover:not(.Mui-selected)': {
      backgroundColor: stagesetColors.newGrey[500],
    },
  },
  '&.toggle-button-tab-desktop-reverse': {
    color: stagesetColors.white[100],
    '& svg': {
      '& path': {
        stroke: stagesetColors.white[100],
      },
    },
    '&.Mui-selected': {
      backgroundColor: stagesetColors.white[100],
      color: stagesetColors.newGrey[800],
      '& svg': {
        '& path': {
          stroke: stagesetColors.newGrey[800],
        },
      },
    },
    '&:hover:not(.Mui-selected)': {
      backgroundColor: stagesetColors.newGrey[500],
    },
  },
  '&.toggle-button-tab-mobile': {
    height: '36px',
    minHeight: '36px',
    minWidth: '52px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: stagesetColors.newGrey[800],
    '& svg': {
      marginRight: '0px',
      '& path': {
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&.Mui-selected': {
      backgroundColor: stagesetColors.newGrey[800],
      color: stagesetColors.white[100],
      '& svg': {
        '& path': {
          stroke: stagesetColors.white[100],
        },
      },
    },
  },
}));
