import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { styled } from '@mui/styles';
import { DialogActions } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { AppInput } from '../../../../shared/AppInput/AppInput';

export interface ICreateFilterSetPopup {
  name: string;
}

export const StyledDialogActions = styled(DialogActions)(() => ({
  justifyContent: 'flex-end',
  gap: '16px',
  padding: '12px 24px',
  background: '#F5F9FF',
  borderTop: '1px solid #EEF0FA',
}));

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit: (data: ICreateFilterSetPopup) => void
};

const NewFilterSetDialog = ({
  open, setOpen, onSubmit,
}: Props) => {
  const schema = yup.object({
    name: yup.string().required('Name is required'),
  }).required();

  const {
    handleSubmit,
    reset,
    control,
  } = useForm<ICreateFilterSetPopup>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onFormSubmit = async (data: ICreateFilterSetPopup) => {
    try {
      onSubmit(data);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      onBackdropClick={() => handleClose()}
      BackdropProps={{
        sx: {
          backgroundColor: '#4e4e4e21',
        },
      }}
      PaperProps={{
        sx: {
          overflowX: 'hidden',
          minWidth: isMobile ? '90%' : '500px',
          borderRadius: '16px',
        },
      }}
      sx={{
        marginTop: '40px',
        '& .MuiDialog-container': {
          height: 'auto',
        },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle
          sx={{
            padding: '16px 24px',
            fontSize: '18px',
            color: (theme) => theme.palette.grey[500],
            fontFamily: 'Inter',
            fontWeight: 600,
          }}
        >
          Save filter
        </DialogTitle>
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            padding: '10px 24px 24px 24px !important',
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppInput
                label="Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
                size="l"
              />
            )}
          />
        </DialogContent>
        <StyledDialogActions>
          <AppButton fullWidth variant="primary" type="submit" size="l">Save</AppButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

export default NewFilterSetDialog;
