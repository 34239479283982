import { OrganizationDTO, OrganizationNameAndLogoDTO } from '../../shared/OrganizationDTO';
import { ApiService } from '../core/api';

export interface IUpdateOrgFormData {
  id: string;
  organizationLogo?: string;
}

export class OrganizationApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('organization');
  }

  async getOrganizationData(id: string): Promise<OrganizationDTO> {
    return this.api.get<OrganizationDTO>(`/get-organization-data/${id}`);
  }

  async getOrganizationNameAndLogoByHash(hash: string | null): Promise<OrganizationNameAndLogoDTO> {
    return this.api.get<OrganizationNameAndLogoDTO>(`/get-organization-data-by-hash/${hash}`);
  }

  async updateOrganization(payload: Partial<OrganizationDTO>): Promise<OrganizationDTO> {
    return this.api.post<OrganizationDTO>('/update-organization', payload);
  }

  async updateOrganizationLogo(payload: IUpdateOrgFormData): Promise<OrganizationDTO> {
    return this.api.post<OrganizationDTO>('/update-organization-logo', payload);
  }

  async getAllOrganizations(): Promise<OrganizationDTO[]> {
    return this.api.get<OrganizationDTO[]>('');
  }
}
