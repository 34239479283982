import {
  EntityState, createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import { ApiTokenDTO } from '../../../../../../shared/api-token/ApiTokenDTO';
import { PublicApiTokenApi } from '../../../../../api/PublicApiTokenApi';
import { EStateStatus } from '../../../../../core/commonTypes';
import { CreateApiTokenPayload } from '../../../../../../shared/api-token/ApiToken';
import { RootState } from '../../../../../core/store/store';

const publicApiTokenApi = new PublicApiTokenApi();

interface IPublicApiTokenAdditionalState {
  status: EStateStatus;
  isFirstFetchFinished: boolean;
}

const apiTokensAdapter = createEntityAdapter<ApiTokenDTO>({});

export const apiTokensReducerName: string = 'apiTokens';

export enum PublicApiTokenActions {
  CREATE_TOKEN = 'CREATE_TOKEN',
  GET_TOKENS = 'GET_TOKENS',
  DELETE_TOKEN = 'DELETE_TOKEN',
}

export type IPublicApiTokenState = IPublicApiTokenAdditionalState & EntityState<ApiTokenDTO>;

export const initialState: IPublicApiTokenState = apiTokensAdapter.getInitialState({
  status: EStateStatus.IDLE,
  isFirstFetchFinished: false,
});

export const getApiTokens = createAsyncThunk(
  `${apiTokensReducerName}/${PublicApiTokenActions.GET_TOKENS}`,
  async () => publicApiTokenApi.getApiTokens(),
);

export const createApiToken = createAsyncThunk(
  `${apiTokensReducerName}/${PublicApiTokenActions.CREATE_TOKEN}`,
  async (payload: CreateApiTokenPayload) => publicApiTokenApi.createApiToken(payload),
);

export const deleteApiToken = createAsyncThunk(
  `${apiTokensReducerName}/${PublicApiTokenActions.DELETE_TOKEN}`,
  async (apiTokenId: string, { dispatch }) => {
    await publicApiTokenApi.deleteToken(apiTokenId);
    dispatch(getApiTokens());
  },
);

export const publicApiTokenSlice = createSlice({
  name: apiTokensReducerName,
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getApiTokens.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getApiTokens.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getApiTokens.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isFirstFetchFinished = true;
        apiTokensAdapter.setAll(state, action.payload);
      })
      .addCase(createApiToken.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(createApiToken.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(createApiToken.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.isFirstFetchFinished = true;
        apiTokensAdapter.addOne(state, action.payload);
      });
  },
});

export const {
  selectAll,
  selectById,
  selectIds,
  selectTotal,
} = apiTokensAdapter.getSelectors();

export const allApiTokensSelector = (state: RootState) => selectAll(state.apiTokens);
export const selectFirstFetchStatus = (state: RootState): boolean => state.apiTokens.isFirstFetchFinished;
