import { useLayoutEffect, useState } from 'react';

const useScreenDimensions = (isMounted: boolean): [number, number] => {
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [isMounted]);
  return [screenHeight, screenWidth];
};

export default useScreenDimensions;
