import { isMobile } from 'react-device-detect';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { SidebarContent } from '../../Stages/Styles/SidebarStyles';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { CloseIcon20 } from '../../../icons/CloseIcon';
import { NewPageSwitchTitle, NewPageSwitchWrapper, NewPageSwitchesWrapper } from '../Styles/NewPageMainTabStyles';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import { FormattedLabel } from '../../../shared/AssigneeSelection/FormattedLabel';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { useNewPageContext } from '../NewPageContextProvider/NewPageContextProvider';
import { getIcon } from '../Helpers/NewPageHelpers';
import { selectSidebarTab } from '../../Layout/lib/fieldsLayoutSlice';
import { useAppSelector } from '../../../hooks/stateHooks';

const NewPageCanvasInputs = (): JSX.Element => {
  const currentSidebarTab = useAppSelector(selectSidebarTab);
  const { control } = useFormContext();
  const { methods, data } = useNewPageContext();
  const { clearField } = methods;
  const { templateTitle } = data;

  return (
    <SidebarContent
      className="sidebar-new-page"
    >
      {templateTitle && (
      <AppButton
        disableRipple
        fullWidth
        disabled
        variant="secondary-disabled"
        size="xl-flex-start"
        startIcon={getIcon(currentSidebarTab)}
      >
        <FormattedLabel
          label={templateTitle}
          len={24}
          tooltip
          placement="right"
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            color: stagesetColors.newGrey[800],
          }}
        />
      </AppButton>
      )}
      <Controller
        key="Name"
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => (
          <AppInput
            className={classNames('settings-menu-input', { 'settings-menu-input-full': isMobile })}
            fullWidth
            required
            size="l"
            label="Name"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <button
                  className="end-adorment-button"
                  type="button"
                  aria-label="title"
                  onClick={() => clearField('title')}
                  onMouseDown={() => clearField('title')}
                >
                  <CloseIcon20 />
                </button>
              ),
            }}
          />
        )}
      />
      <NewPageSwitchesWrapper>
        <Controller
          key="Lock"
          name="lock"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NewPageSwitchWrapper>
              <AppSwitch
                checked={value}
                onChange={onChange}
              />
              <NewPageSwitchTitle>
                Lock
              </NewPageSwitchTitle>
            </NewPageSwitchWrapper>
          )}
        />
        <Controller
          key="Hide"
          name="hide"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NewPageSwitchWrapper>
              <AppSwitch
                checked={value}
                onChange={onChange}
              />
              <NewPageSwitchTitle>
                Hide for buyer
              </NewPageSwitchTitle>
            </NewPageSwitchWrapper>
          )}
        />
      </NewPageSwitchesWrapper>
    </SidebarContent>
  );
};

export default NewPageCanvasInputs;
