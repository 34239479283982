import { Box, Typography } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';
import { BaseSyntheticEvent, useEffect } from 'react';
import moment from 'moment';
import { CrossDimensionDataGrid } from '../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { GridItemSkeleton, GridTitleItemSkeleton, fileSkeletonRows } from '../../routes-old/deals/DealsHelpers/DealsHelpers';
import { LibraryGrid } from './styles/LibraryStyle';
import { EFileGridColumns } from './helpers/library.helper';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  selectFilesFirstFetchStatus,
  selectLoadingStatus,
  getFileItems,
  allFilesSelector,
} from './lib/librarySlice';
import { NavigationLink } from '../../common/ToolBarNavigation/NavigationStyle';
import { ExtendedGridColumns } from '../../types/mobile-table';
import { EAppRoutes } from '../../core/router';
import { EStateStatus } from '../../core/commonTypes';
import { EFileEditType, EFileType } from '../../../shared/library/Library';
import UserItemAvatar from '../../shared/UserAvatar/UserItemAvatar';
import { UserDTO } from '../../../shared/UserDTO';
import LibraryGridItemMenu from './LibraryGridItemMenu';
import { FileWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import { selectLibraryFilesFilterSearch, selectLibraryFilesFilterParams } from './lib/libraryFilesFilterSlice';
import { DocumentIcon } from '../../features/DocumentsField/DocumentIcon';
import { DocumentLinkIcon } from '../../features/DocumentLink/DocumentLinkIcon';
import { ContentType } from '../../../shared/Stage';
import { FileItemContainer, FileItemName, LibraryGridContainer } from './styles/LibraryGridStyle';

interface Props {
  onCreateFileItemDialogOpen: (item?: FileWithOwnerDTO, editType?: EFileEditType) => void;
  onCreateFileLinkDialogOpen: (item?: FileWithOwnerDTO) => void;
}

const LibraryFileGrid = ({
  onCreateFileItemDialogOpen,
  onCreateFileLinkDialogOpen,
}: Props): JSX.Element => {
  const files = useAppSelector(allFilesSelector);
  const filesTotalCount = files ? files.length : 0;

  const dispatch = useAppDispatch();
  const firstFetchStatus = useAppSelector(selectFilesFirstFetchStatus);
  const status = useAppSelector(selectLoadingStatus);
  const filterParams = useAppSelector(selectLibraryFilesFilterParams);
  const search = useAppSelector(selectLibraryFilesFilterSearch);

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getFileItems({}));
    }
  }, []);

  useEffect(() => {
    dispatch(getFileItems({}));
  }, [filterParams, search]);

  const preventLinkBehavior = (e: BaseSyntheticEvent) => {
    e.preventDefault();
  };

  const onRowClick = (row: any) => { }; // ToDo

  const getExtension = (fileLink: string): string => fileLink.substring(fileLink.lastIndexOf('.') + 1);

  const getFileIcon = (fileType: EFileType, fileLink: string, linkType: ContentType) => {
    switch (fileType) {
      case EFileType.FILE:
        return <DocumentIcon ext={getExtension(fileLink)} />;
      case EFileType.LINK:
        return <DocumentLinkIcon contentType={linkType} />;
      default:
        return null;
    }
  };

  const columns: ExtendedGridColumns = [
    {
      field: EFileGridColumns.name,
      sortable: true,
      headerName: 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 6,
      renderCell: ({
        value,
        row,
      }) => (
        value !== 'skeleton' ? (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
            }}
          >
            {getFileIcon(row.type, row.link, row.linkType)}
            <Typography
              sx={{
                fontWeight: '500',
                paddingLeft: '4px',
              }}
            >
              {value}
            </Typography>
          </Box>
        ) : (
          <GridTitleItemSkeleton
            disableSubtext
            fileTitle
          />
        )
      ),
    },
    {
      field: EFileGridColumns.owner,
      sortable: true,
      headerName: 'Owner',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: ({
        value,
      }) => {
        const owner: UserDTO = value as UserDTO;
        return (value !== 'skeleton' ? (
          <UserItemAvatar
            size={30}
            marginLeft={11}
            colorId={owner?.colorId}
            userName={owner?.name}
            fontSize={16}
            fontWeight={400}
            avatarSrc={owner?.avatarSrc}
          />
        ) : (
          <GridItemSkeleton />
        )
        );
      },
    },
    {
      field: EFileGridColumns.updated,
      sortable: true,
      headerName: 'Updated',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: ({
        id,
        value,
      }) => (
        value !== 'skeleton' ? (
          <NavigationLink
            onClick={preventLinkBehavior}
            className="RowLink"
            to={`${EAppRoutes.deal}/${id}`} // ToDo
          >
            {moment(value).format('DD.MM.YYYY')}
          </NavigationLink>
        ) : (
          <GridItemSkeleton />
        )
      ),
    },
    {
      field: EFileGridColumns.menu,
      sortable: true,
      headerName: '',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 0.5,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton'
          ? (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            >
              <LibraryGridItemMenu
                item={value}
                onCreateFileItemDialogOpen={
                  (value?.type as EFileType) === EFileType.FILE ? onCreateFileItemDialogOpen : onCreateFileLinkDialogOpen
                }
                onCreateProductItemDialogOpen={(item?: ProductWithOwnerDTO) => { }}
              />
            </Box>
          ) : <Box />
      ),
    },
  ];

  let rows: GridRowsProp = [];
  if (files.length) {
    rows = files.map((file) => ({
      ...file,
      [EFileGridColumns.name]: file.name,
      [EFileGridColumns.owner]: file.owner,
      [EFileGridColumns.updated]: file.updated,
      [EFileGridColumns.menu]: file,
    }));
  }

  return (
    <LibraryGridContainer
      className={firstFetchStatus ? 'grid-loaded' : 'grid-loading'}
      id="my-deals-tab-container"
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          gap: '4px',
          marginBottom: '12px',
          marginLeft: '12px',
        }}
      >
        {
          firstFetchStatus ? (
            <>
              <Typography sx={{
                fontSize: '16px',
                color: (theme) => theme.palette.grey[600],
              }}
              >
                {filesTotalCount}
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                color: (theme) => theme.palette.grey[400],
                paddingTop: '2px',
              }}
              >
                files
              </Typography>
            </>
          ) : (
            <GridItemSkeleton />
          )
        }
      </Box>
      <CrossDimensionDataGrid
        rows={firstFetchStatus ? rows : fileSkeletonRows}
        columns={columns}
        getId={(row) => row.id}
        renderMobileTitle={(row) => (
          row?.name !== 'skeleton' ? (
            <FileItemContainer>
              {getFileIcon(row.type, row.link, row.linkType)}
              <FileItemName>
                {row[EFileGridColumns.name]}
              </FileItemName>
            </FileItemContainer>
          ) : (
            <GridTitleItemSkeleton
              disableSubtext
              fileTitle
            />
          )
        )}
        renderMobileMenu={(row) => {
          const value = row[EFileGridColumns.menu];
          return (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {row?.name !== 'skeleton'
                ? (
                  <LibraryGridItemMenu
                    item={value}
                    onCreateFileItemDialogOpen={onCreateFileItemDialogOpen}
                    onCreateProductItemDialogOpen={(item: any) => { }}
                  />
                ) : <Box />}
            </Box>
          );
        }}
        onRowClick={(row) => {
          onRowClick(row);
        }}
        renderDesktopGridComponent={() => (
          <LibraryGrid
            // loading={processesLoadingJobs > 0}
            disableSelectionOnClick
            disableColumnMenu
            columns={columns}
            rows={firstFetchStatus ? rows : fileSkeletonRows} // ToDo
            headerHeight={36}
            rowHeight={65}
            onRowClick={(rowParams) => onRowClick(rowParams.row)}
            getRowClassName={(params) => (params.row.status === 1 ? 'highlighted_row' : '')}
            hideFooter
          />
        )}
      />
    </LibraryGridContainer>
  );
};

export default LibraryFileGrid;
