import { selectUserFilterStatus, setTimelinesUserFilter } from '../features/ActivityLog/lib/processActivityLogSlice';
import { useAppDispatch, useAppSelector } from './stateHooks';

const useProcessTimelineUsersFilter = (userId?: string) => {
  const dispatch = useAppDispatch();

  const isSelected = !!(userId && useAppSelector((state) => selectUserFilterStatus(state, userId)));

  const changeUserFilterStatus = () => {
    dispatch(setTimelinesUserFilter(userId));
  };

  return {
    isSelected,
    changeUserFilterStatus,
  };
};

export default useProcessTimelineUsersFilter;
