import { styled, Switch } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

const StyledAppSwitch = styled(Switch)(({ theme }) => ({
  display: 'flex',
  '&.switch-disabled': {
    '& .MuiSwitch-switchBase': {
      backgroundColor: stagesetColors.newGrey[100],
      '& svg': {
        stroke: stagesetColors.newGrey[400],
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: stagesetColors.newGrey[300],
      opacity: 1,
    },
  },
  '&.MuiSwitch-root:not(.switch-disabled)': {
    '&.color': {
      '&-primary': {
        '& .MuiSwitch-switchBase': {
          backgroundColor: stagesetColors.white[100],
          '& svg': {
            stroke: stagesetColors.newGrey[400],
          },
        },
        '& .MuiSwitch-track': {
          backgroundColor: stagesetColors.newGrey[400],
        },
        '&.switch-checked': {
          '& .MuiSwitch-switchBase': {
            '& svg': {
              stroke: stagesetColors.newGrey[800],
            },
          },
          '& .MuiSwitch-track': {
            backgroundColor: stagesetColors.newGrey[800],
          },
        },
      },
    },
  },
  '& .MuiSwitch-switchBase': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2px',
    padding: '2px',
    width: '16px',
    height: '16px',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&.Mui-checked': {
      transform: 'translateX(16px)',
    },
    '& svg': {
      width: '12px',
      height: '12px',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '14px',
    opacity: '1 !important',
    boxSizing: 'border-box',
  },
  '&.size': {
    '&-medium': {
      width: '36px',
      height: '20px',
      padding: '0px',
    },
  },
}));

export default StyledAppSwitch;
