import { v4 as uuidv4 } from 'uuid';
import { ExtensionNames } from './TiptapHelpers';
import { FieldSectionDTO, ProcessStageDTO } from '../../../../shared/Field/Field';
import { FielItemsRecord } from '../../../../shared/FileItems/FileItems';
import { TiptapNodeBase } from '../../../../shared/Tiptap/TiptapTypes';

const CheckMap: { [key: string]: boolean } = {
  [ExtensionNames.AGENDA]: true,
  [ExtensionNames.DOCUMENTS]: true,
  [ExtensionNames.MEDIA]: true,
  [ExtensionNames.TABLE]: true,
  [ExtensionNames.TABLE_ROW]: true,
  [ExtensionNames.TABLE_HEADER]: true,
  [ExtensionNames.PARAGRAPH]: true,
  [ExtensionNames.BULLET_LIST]: true,
  [ExtensionNames.ORDERED_LIST]: true,
  [ExtensionNames.HEADING]: true,
  [ExtensionNames.TEXT]: true,
  listItem: true,
  doc: true,
};

export const prepareMergeData = (
  from: FieldSectionDTO,
  to: ProcessStageDTO,
  info?: {
    isTemplate?: boolean,
    buyerName?: string,
    clientName?: string,
  },
): any | void => {
  if (
    (!from.files || !Object.keys(from.files).length)
    && (!from.items || !Object.keys(from.items).length)
  ) {
    return false;
  }

  const { files, items } = from;
  const currentFiles = to.files ?? {};

  const parsedFiles: FielItemsRecord = {};

  const generateId = (): string => {
    const uuid = uuidv4();
    if (currentFiles[uuid]) {
      return generateId();
    }
    return uuid;
  };

  const parseContent = (toParse: TiptapNodeBase): TiptapNodeBase => {
    const toReturn: TiptapNodeBase = { ...toParse, content: [] };
    if (toParse.type && CheckMap[toParse.type]) {
      if (toParse?.attrs?.doc && files?.[toParse?.attrs?.doc] && !toParse.attrs.doc.includes('library_file')) {
        const newId = generateId();
        toReturn.attrs = { ...toReturn.attrs, doc: newId };
        parsedFiles[newId] = files[toParse?.attrs?.doc];
      }
      if (toParse?.attrs?.docs?.length) {
        const newDocs: string[] = [];
        for (const doc of toParse.attrs.docs) {
          if (!doc.includes('library_file')) {
            const newId = generateId();
            newDocs.push(newId);
            parsedFiles[newId] = files[doc];
          } else {
            newDocs.push(doc);
          }
        }
        toReturn.attrs = { ...toReturn.attrs, docs: newDocs };
      }
      if (!info?.isTemplate && info?.buyerName && toParse.type === ExtensionNames.TEXT) {
        toReturn.text = toReturn.text?.replaceAll('{{company_name}}', info.buyerName || '{{company_name}}');
      }
      if (!info?.isTemplate && info?.clientName && toParse.type === ExtensionNames.TEXT) {
        toReturn.text = toReturn.text?.replaceAll('{{client_name}}', info.clientName || '{{client_name}}');
      }
      if (!info?.isTemplate && info?.buyerName && toParse.type === ExtensionNames.AGENDA) {
        toReturn.attrs = {
          ...toReturn.attrs,
          name: toReturn.attrs.name?.replaceAll('{{company_name}}', info.buyerName || '{{company_name}}'),
          description: toReturn.attrs.description?.replaceAll('{{company_name}}', info.buyerName || '{{company_name}}'),
        };
      }
      if (!info?.isTemplate && info?.clientName && toParse.type === ExtensionNames.AGENDA) {
        toReturn.attrs = {
          ...toReturn.attrs,
          name: toReturn.attrs.name?.replaceAll('{{client_name}}', info.clientName || '{{client_name}}'),
          description: toReturn.attrs.description?.replaceAll('{{client_name}}', info.clientName || '{{client_name}}'),
        };
      }
      if (toParse?.content?.length) {
        for (const item of toParse.content) {
          toReturn.content.push(parseContent(item));
        }
      }
    }
    return toReturn;
  };

  return ({ items: parseContent(items as TiptapNodeBase), files: parsedFiles });
};
