import { DialogProps } from '@mui/material';
import DealProtectedAuth from '../../auth/DealProtectedAuth/DealProtectedAuth';
import DealSignupDialogWrapper from './DealSignupDialogWrapper';

const ProtectedSignupDialog = (props: DialogProps): JSX.Element => (
  <DealSignupDialogWrapper
    {...props}
  >
    <DealProtectedAuth />
  </DealSignupDialogWrapper>
);

export default ProtectedSignupDialog;
