import {
  Box,
} from '@mui/material';
import { useState } from 'react';
import { PaymentPeriodOptionProps, PaymentPeriodOption } from './PaymentPeriodOption';
import { ESubscriptionType } from '../../../shared/Subscription';

export type PaymentPeriodOptionType = {
  title: string,
  value: PaymentPeriodOptionProps,
};

interface Props {
  options: PaymentPeriodOptionType[],
  type?: ESubscriptionType,
}

const PaymentPeriod = ({
  options,
  type,
}: Props): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box>
      <PaymentPeriodOption {...options[activeIndex].value} type={type} />
    </Box>
  );
};

export default PaymentPeriod;
