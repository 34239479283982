import { Box } from '@mui/material';
import { EPasswordSecurityStatuses, PasswordStatusType } from './helpers/PasswordSecurityCheckHelpers';
import PasswordSecurityIdentificatorItem from './PasswordSecurityIdentificatorItem';

interface Props {
  statuses: PasswordStatusType,
  error?: boolean,
}

const PasswordSecurityIdentificator = ({
  statuses,
  error,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
    }}
  >
    <Box
      sx={{
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      <PasswordSecurityIdentificatorItem
        status={statuses[EPasswordSecurityStatuses.LENGTH]}
        text="8 characters minimum"
        error={error}
      />
      <PasswordSecurityIdentificatorItem
        status={statuses[EPasswordSecurityStatuses.UPPER]}
        text="One uppercase"
        error={error}
      />
    </Box>
    <Box
      sx={{
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      <PasswordSecurityIdentificatorItem
        status={statuses[EPasswordSecurityStatuses.LOWER]}
        text="One lowercase"
        error={error}
      />
      <PasswordSecurityIdentificatorItem
        status={statuses[EPasswordSecurityStatuses.NUMBER]}
        text="One number"
        error={error}
      />
    </Box>
  </Box>
);

export default PasswordSecurityIdentificator;
