import {
  FormControl,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const PdfMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '22px',
  fontWeight: 500,
  fontSize: '16px',
  padding: '9px 16px',
  borderRadius: '8px',
  '& svg': {
    visibility: 'hidden',
    background: 'transparent',
    '& path': {
      stroke: stagesetColors.grey[500],
    },
  },
}));

export const PdfMenuFormControl = styled(FormControl)(() => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: stagesetColors.grey[200],
  },
  '& .MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: stagesetColors.grey[400],
  },
  '& .MuiInputBase-root:hover fieldset': {
    border: '1px solid',
    borderColor: stagesetColors.grey[300],
  },
}));

export const PdfMenuSelect = styled(Select)(() => ({
  width: '82px',
  height: '32px',
  borderRadius: '8px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  paddingTop: '5px',
  backgroundColor: stagesetColors.white[100],
}));
