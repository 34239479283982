import { ProcessTaskBase } from './process-task-base';

export enum ETaskTypes {
  MILESTONE = 'MILESTONE',
  ACTION = 'ACTION',
  PRICE_TABLE_ITEM = 'PRICE_TABLE_ITEM',
}

export enum ETaskSubTypes {
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  MEETING = 'MEETING',
}

export enum ETaskStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  OVERDUE = 'OVERDUE',
}

export type AssigneeTeam = 'buyer' | 'seller';

export class TaskDTO extends ProcessTaskBase {
  parentId: string;
  estimationDays?: number;
  assigneesIds?: string[];
  timeLineStartDate?: Date | null;
  timeLineEndDate?: Date | null;
  status: ETaskStatus;
  type: ETaskTypes;
  order?: number;
  deleted: boolean;
  subtaskData?: SubtaskData;
  nestedTasksIds?: string[];
  completedTasks?: number;
  fieldId: string;
  activeTasks?: number;
  stageId?: string;
  docs?: TaskFileItem[];
  eventData?: TaskEventData;
  assigneesTeams: AssigneeTeam[]
}

export type TCreateTaskRequest = Pick<TaskDTO, 'parentId'
  | 'title'
  | 'type'
  | 'description'
  | 'order'
  | 'estimationDays'
  | 'subtaskData'
>

export interface CreateTaskRequest {
  task: TCreateTaskRequest;
  oldOrder: string[];
  processId: string;
  fieldId: string;
  stageId?: string;
  isAgenda?: boolean;
}

export interface CreateTaskResponse {
  task: TaskDTO;
  reorder: string[];
}

export class MilestoneDTO extends TaskDTO {
  actions: TaskDTO[];
}

export interface IAddUserToProcessRequest {
  email: string;
  rolesIds: string[];
  processId: string;
  message: string;
}

export interface IAddManyUsersToProcessRequest {
  teammates: string[];
  newUsers: string[];
  processId: string;
  message?: string;
}

export interface AddUserByIdToProcessRequest {
  userId: string;
  rolesIds?: string[];
  processId: string;
}

export interface DeactivateUserRequest {
  userId: string;
  processId: string;
}

export interface SubtaskMeetingData {
  date?: Date;
  link?: string;
  subtaskType: ETaskSubTypes.MEETING;
}

export interface SubtaskDocRequestData {
  fileName?: string;
  fullPath?: string;
  subtaskType: ETaskSubTypes.DOCUMENT_REQUEST;
}

export type SubtaskData = SubtaskMeetingData | SubtaskDocRequestData;

export interface UpdateTaskRequest {
  processId: string;
  id: string;
  update: Partial<TaskDTO>;
  isAgenda?: boolean;
}

export interface UpdateTaskResponse {
  isMilestoneStarted?: boolean;
  isAllTasksInMilestoneCompleted?: boolean;
  task: TaskDTO;
  revalidateParent?: boolean;
}

export interface DeleteTaskResponse {
  deletedIds: string[];
  revalidateParent: boolean;
  parentId: string
}

export interface TaskFileItem {
  name: string;
  url: string;
  size: number;
}

export interface TaskEventData {
  url: string;
  name: string;
}
