import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Radio } from '@mui/material';
import { useEffect } from 'react';
import { SidebarContent } from '../../Stages/Styles/SidebarStyles';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { CloseIcon20 } from '../../../icons/CloseIcon';
import {
  NewPageEmbedRadioButton,
  NewPageEmbedRadioButtonContainer,
  NewPageEmbedRadioButtonGroup,
  NewPageEmbedRadioButtonTitle,
  NewPageSwitchTitle,
  NewPageSwitchWrapper,
  NewPageSwitchesWrapper,
} from '../Styles/NewPageMainTabStyles';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import { stagesetColors } from '../../../theme/stagesetPalette';
import { useNewPageContext } from '../NewPageContextProvider/NewPageContextProvider';
import {
  iframeSiteOption,
  iframeSourceNoContentOption,
  iframeSourceOption,
} from '../../MediaField/Helpers/MediaFieldHelpers';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { FormattedLabel } from '../../../shared/AssigneeSelection/FormattedLabel';
import { selectNewPageEmbedTab, selectSidebarTab } from '../../Layout/lib/fieldsLayoutSlice';
import { useAppSelector } from '../../../hooks/stateHooks';
import { ContentType } from '../../../../shared/Stage';
import { getIcon } from '../Helpers/NewPageHelpers';
import { EFieldsubType } from '../../../../shared/Field/Field';

const NewPageEmbedInputs = (): JSX.Element => {
  const currentSidebarTab = useAppSelector(selectSidebarTab);
  const newPageEmbedTab = useAppSelector(selectNewPageEmbedTab);
  const { control, setValue, trigger } = useFormContext();
  const { methods, data } = useNewPageContext();
  const { clearField } = methods;
  const {
    templateTitle, libraryFileToAdd,
  } = data;

  useEffect(() => {
    if (templateTitle) {
      setValue('title', templateTitle);
    }
    if (libraryFileToAdd?.link) {
      setValue('url', libraryFileToAdd.fileUid);
      if (libraryFileToAdd.linkType !== ContentType.URL) {
        setValue('subType', EFieldsubType.MIXED);
      }
      trigger();
    }
    setValue('libraryFileToAdd', libraryFileToAdd);
  }, [templateTitle]);

  const radioButtonGroup = [iframeSourceOption, iframeSiteOption, iframeSourceNoContentOption].map(((item) => (
    <NewPageEmbedRadioButton
      key={item.subType}
      value={item.subType}
      control={<Radio />}
      label={item.name}
    />
  )));

  return (
    <SidebarContent
      className="sidebar-new-page"
    >
      {templateTitle && (
        <AppButton
          disableRipple
          fullWidth
          disabled
          variant="secondary-disabled"
          size="xl-flex-start"
          startIcon={getIcon(currentSidebarTab)}
        >
          <FormattedLabel
            label={templateTitle}
            len={24}
            tooltip
            placement="right"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: stagesetColors.newGrey[800],
            }}
          />
        </AppButton>
      )}
      <Controller
        key="Title"
        name="title"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <AppInput
            fullWidth
            required
            label="Name"
            size="l"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            InputProps={{
              endAdornment: (
                <button
                  className="end-adorment-button"
                  type="button"
                  aria-label="title"
                  onClick={() => clearField('title')}
                  onMouseDown={() => clearField('title')}
                >
                  <CloseIcon20 />
                </button>
              ),
            }}
          />
        )}
      />
      {!templateTitle && (
        <Controller
          key="Url"
          name="url"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <AppInput
              fullWidth
              required
              label="Link"
              size="l"
              marginBottom="20px"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              multiline
              InputProps={{
                endAdornment: (
                  <button
                    className="end-adorment-button"
                    type="button"
                    aria-label="url"
                    onClick={() => clearField('url')}
                    onMouseDown={() => clearField('url')}
                  >
                    <CloseIcon20 />
                  </button>
                ),
              }}
            />
          )}
        />
      )}
      <NewPageSwitchesWrapper>
        <Controller
          key="Lock"
          name="lock"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NewPageSwitchWrapper>
              <AppSwitch
                checked={value}
                onChange={onChange}
              />
              <NewPageSwitchTitle>
                Lock
              </NewPageSwitchTitle>
            </NewPageSwitchWrapper>
          )}
        />
        <Controller
          key="Hide"
          name="hide"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NewPageSwitchWrapper>
              <AppSwitch
                checked={value}
                onChange={onChange}
              />
              <NewPageSwitchTitle>
                Hide for buyer
              </NewPageSwitchTitle>
            </NewPageSwitchWrapper>
          )}
        />
      </NewPageSwitchesWrapper>
      {(!templateTitle && newPageEmbedTab === ContentType.URL) && (
        <NewPageEmbedRadioButtonContainer>
          <NewPageEmbedRadioButtonTitle>
            Embed type
          </NewPageEmbedRadioButtonTitle>
          <Controller
            key="SubType"
            name="subType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <NewPageEmbedRadioButtonGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={onChange}
                >
                  {radioButtonGroup}
                </NewPageEmbedRadioButtonGroup>
              </FormControl>
            )}
          />
        </NewPageEmbedRadioButtonContainer>
      )}
    </SidebarContent>
  );
};

export default NewPageEmbedInputs;
