import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import {
  ReactNodeViewRenderer,
} from '@tiptap/react';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Table from '@tiptap/extension-table';
import ListItem from '@tiptap/extension-list-item';
import TiptapNodeWrapper from '../../TiptapNodeWrapper';

export const WrappedParagraph = Paragraph.extend({
  draggable: true,
  selectable: true,
  addKeyboardShortcuts() {
    return {
      Delete: () => {
        this.editor.view.dragging = null;
      },
      Backspace: () => {
        this.editor.view.dragging = null;
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeWrapper);
  },
});

export const WrappedHeading = Heading.extend({
  draggable: true,
  selectable: true,
  addAttributes() {
    return {
      bookMark: {
        default: null,
      },
      level: {
        default: 1,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer((props) => (
      <TiptapNodeWrapper
        {...props}
        _as={`h${props.node.attrs.level}`}
      />
    ));
  },
});

export const WrappedListItem = ListItem.extend({
  draggable: true,
  selectable: true,
  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeWrapper);
  },
});

export const WrappedBulletList = BulletList.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer((props: any) => <TiptapNodeWrapper {...props} />, { contentDOMElementTag: 'div' });
  },
});

export const WrappedOrderedList = OrderedList.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer((props: any) => <TiptapNodeWrapper {...props} />, { contentDOMElementTag: 'div' });
  },
});

export const WrappedTable = Table.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer((props: any) => <TiptapNodeWrapper {...props} disableMenus />);
  },
});
