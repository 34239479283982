import {
  Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';
import { useStages } from '../../../hooks/useStages';
import { AppDropdownItemProps } from '../../../shared/AppDropdown/AppDropdownItem';
import ChevronRight from '../../../icons/StageMenu/ChevronRight.svg';
import CollectTemplateInfoDialog from '../../CollectTemplateInfoDialog/CollectTemplateInfoDialog';
import { stagesetColors } from '../../../theme/stagesetPalette';

export type Props = {
  onGoBack: () => void;
  id: string;
  onChangeTab: (tab: number) => void;
  onDelete: () => void;
  onClose: () => void;
  onEdit: () => void;
};

export const CommonOptionsTab = ({
  onGoBack, id, onChangeTab, onDelete, onClose, onEdit,
}: Props) => {
  const {
    mobileOptionsMenuItems, stage, collectDialog, setCollectDialog, onCreateTemplate,
  } = useStages(id);

  const handleClick = (props: AppDropdownItemProps, e: any) => {
    if (props.disabled) return;
    if (props.label === 'Add page') {
      onChangeTab(1);
      return;
    }
    if (props.label === 'Delete') {
      onDelete();
      onGoBack();
      return;
    }
    if (props.label === 'Rename') {
      onEdit();
      onGoBack();
      return;
    }
    if (props.onClick) props.onClick(e);
    onGoBack();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: '16px 16px 8px 16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <Typography
            sx={{
              fontFamily: 'Manrope',
              fontSize: '14px',
              fontWeight: 600,
              color: (theme) => theme.palette.grey[600],
              maxWidth: '100px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {stage?.title}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
        }}
        >
          <AppLinkButton
            onClick={onClose}
            sx={{
              paddingRight: '0px !important',
              minWidth: '100% !important',
              width: '100% !important',
            }}
          >
            Cancel
          </AppLinkButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          overflow: 'overlay',
        }}
      >
        <List sx={{
          display: 'flex',
          flexDirection: 'column',
          '& li:not(:last-of-type)': {
            '& svg': {
              '& path': {
                stroke: stagesetColors.newGrey[500],
              },
            },
          },
        }}
        >
          {mobileOptionsMenuItems.map((props) => (
            <ListItem
              key={props.title}
              disablePadding
              onClick={(e) => handleClick(props, e)}
            >
              <ListItemButton
                disableRipple
                disabled={props.disabled}
                onClick={(e) => {
                  if (props.disabled) e.stopPropagation();
                }}
                sx={{
                  padding: '0px',
                  height: '40px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    marginRight: '15px',

                  }}
                >
                  {props.startIcon}
                </ListItemIcon>
                <ListItemText sx={{ color: props.labelColor }} primary={props.label} />
                { props.showNext && <ChevronRight /> }
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <CollectTemplateInfoDialog
        open={collectDialog}
        setOpen={setCollectDialog}
        onSubmit={(data) => onCreateTemplate(data.name)}
        type="page"
      />
    </Box>
  );
};
