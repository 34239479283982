import { MenuItem } from '@mui/material';
import { ChangeEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AppInput } from '../../shared/AppInput/AppInput';
import {
  DEFAULT_LANG,
  LANGUAGES, getLanguageFlag,
} from './Languages';
import { InputImage, InputText, InputValueWrapper } from './LanguageSwitcherInputStyles';
import { stagesetColors } from '../../theme/stagesetPalette';
import { ChevronDown20 } from '../../icons/ChevronDown';

const SettingLanguageSwitcherInput = (): JSX.Element => {
  const { i18n } = useTranslation();

  return (
    <AppInput
      className={classNames(
        'settings-menu-input',
        'settings-menu-input-full',
      )}
      fullWidth
      select
      disabled={false}
      value={i18n?.language || DEFAULT_LANG}
      defaultValue={i18n?.language || DEFAULT_LANG}
      size="l"
      label="Language"
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        i18n.changeLanguage(event.target.value);
      }}
      SelectProps={{
        sx: {
          '& svg': {
            fill: 'none',
            '& path': {
              stroke: stagesetColors.newGrey[800],
            },
          },
        },
        IconComponent: ChevronDown20,
      }}
    >
      {LANGUAGES.map((lng) => (
        <MenuItem
          key={lng.lng}
          value={lng.lng}
        >
          <InputValueWrapper>
            <InputImage
              component="img"
              alt={`Language flag ${lng.lng}`}
              src={getLanguageFlag(lng.lng)}
            />
            <InputText>
              {lng.name}
            </InputText>
          </InputValueWrapper>
        </MenuItem>
      ))}
    </AppInput>
  );
};

export default SettingLanguageSwitcherInput;
