import { Box, styled, Typography } from '@mui/material';

export const TitleTypographyWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  flexDirection: 'column',
  gap: '8px',
}));

export const TitleTypography = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '28px',
  color: '#1A1929',
}));

export const EnterDealRoomTypography = styled(Typography)(() => ({
  color: '#1A1929',
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '28px',
}));

export const TitleAdditionalTypography = styled(Typography)(() => ({
  // width: '100%',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#1A1929',
  display: 'flex',
  gap: '8px',
}));

export const OptionsContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& a': {
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#0073F5',
  },
}));
