import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { ELocalStorageItems } from '../../../shared/LocalStorageItems/LocalStorageItems';
import { ESocialAuthProvider, ESocialAuthTypes } from '../../../shared/schema/auth.shcema';
import { GoogleGLogo } from '../../common/GoogleGLogo';
import { SessionStorageWrapper } from '../../common/sessionStorageWrapper';
import { config } from '../../core';
import {
  Analytics,
  EMixPanelEvents,
} from '../../core/Analytics';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { LinkedInAuthIcon } from '../../icons/LinkedInAuthIcon';
import AuthButton from '../../routes-old/auth/Components/AuthButton';
import PasswordVisibilityIcon from '../../routes-old/auth/Components/PasswordVisibilityIcon';
import { confirmProcessAccount } from '../../routes-old/auth/state/authState';
import useAuthQuery from '../../routes-old/auth/useAuthQuery';
import WhiteIconButton from '../../shared/Buttons/WhiteIconButton';
import {
  passwordStatusManager,
  EPasswordSecurityStatuses,
  passwordStatusesParser,
} from '../../shared/PasswordSecurityCheck/helpers/PasswordSecurityCheckHelpers';
import PasswordSecurityIdentificator from '../../shared/PasswordSecurityCheck/PasswordSecurityIdentificator';
import DealConfirmBuyerAccountHeader from './DealConfirmBuyerAccountHeader';

interface FormState {
  password: string;
}

const analytics: Analytics = Analytics.getInstance();

const DealConfirmBuyerAccountFormOne = (): JSX.Element => {
  const sessionStorageWrapper: SessionStorageWrapper = new SessionStorageWrapper();
  const dispatch = useAppDispatch();
  const [prams] = useAuthQuery();
  const { email, type, hash } = prams;
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  const { status } = useAppSelector((state) => state.auth);

  const [passwordStatuses, setPasswordStatuses, checkPasswordStatus] = passwordStatusManager();

  const validationSchemaConfig = {
    password: yup.string()
      .ensure()
      .min(8, EPasswordSecurityStatuses.LENGTH)
      .matches(/(?=.*[A-Z])/, EPasswordSecurityStatuses.UPPER)
      .matches(/(?=.*[a-z])/, EPasswordSecurityStatuses.LOWER)
      .matches(/(?=.*\d)/, EPasswordSecurityStatuses.NUMBER)
      .test((value: string) => {
        setPasswordStatuses(passwordStatusesParser(value, passwordStatuses));
        return checkPasswordStatus();
      }),
  };

  const schema = yup.object(validationSchemaConfig).required();

  if (localStorage.getItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA)) {
    localStorage.removeItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA);
  }

  const onSubmit = async (data: FormState) => {
    if (email && hash && type) {
      try {
        localStorage.setItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA, 'true');
        await dispatch(confirmProcessAccount({
          ...data,
          title: 'Guest',
          fullName: email.split('@')[0],
          hash,
          email,
          type,
        })).unwrap();
      } catch (e) {
        console.log(e);
      }
      analytics.addUser(EMixPanelEvents.ACCOUNT_CONFIRMED, {
        email,
        data,
      });
    }
  };

  const {
    handleSubmit,
    control,
    // For some reason password status check does not work without form state errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isSubmitted },
  } = useForm<FormState>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const clearLocalStorageAuth = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('persist:auth');
    localStorage.removeItem('persist:root');
  };

  const onAuthButtonClick = async (provider: ESocialAuthProvider): Promise<void> => {
    if (email && hash) {
      clearLocalStorageAuth();
      localStorage.setItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA, 'true');
      sessionStorageWrapper.storeSocialAuthQuery({
        socialAuthType: ESocialAuthTypes.PROCESS_SIGNUP,
        email,
        fullName: email.split('@')[0],
        title: 'Guest',
        hash,
        provider,
      });
      analytics.addUser(EMixPanelEvents.ACCOUNT_CONFIRMED, {
        email,
        // @ts-ignore
        data,
      });
      window.location.href = `${config.api.origin}/${provider}`;
    }
  };

  return (
    <>
      <DealConfirmBuyerAccountHeader />
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '100%',
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            width: '100%',
            gap: '12px',
          }}
        >
          <WhiteIconButton
            icon={<GoogleGLogo />}
            text="Sign up with google"
            width="100%"
            onClick={() => onAuthButtonClick(ESocialAuthProvider.google)}
          />
          <WhiteIconButton
            icon={<LinkedInAuthIcon />}
            onClick={() => onAuthButtonClick(ESocialAuthProvider.linkedin)}
          />
        </Box>
        <Typography
          sx={{
            color: ' #7084B4',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          or
        </Typography>
        <>
          <TextField
            disabled
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              borderRadius: '8px',
            }}
            value={email}
            InputProps={{
              sx: {
                borderRadius: '8px',
              },
            }}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  borderRadius: '8px',
                }}
                value={value}
                onChange={onChange}
                error={!!error && isSubmitted}
                type={isPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                  endAdornment: <PasswordVisibilityIcon
                    setPasswordVisible={setPasswordVisible}
                    isPasswordVisible={isPasswordVisible}
                  />,
                }}
              />
            )}
          />
          <PasswordSecurityIdentificator
            statuses={passwordStatuses}
            error={isSubmitted}
          />
        </>
        <AuthButton status={status} title="Get Started" />
      </Box>
    </>
  );
};

export default DealConfirmBuyerAccountFormOne;
