import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { stagesetColors } from '../../theme/stagesetPalette';

export const UserAvatarBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  borderRadius: '8px',
  background: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.35s',
  '& .empty-avatar': {
    borderColor: stagesetColors.newGrey[400],
    color: stagesetColors.newGrey[400],
    '& path': {
      stroke: stagesetColors.newGrey[400],
    },
  },
  '& .empty-avatar:hover': {
    borderColor: stagesetColors.newGrey[500],
    color: stagesetColors.newGrey[500],
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
  },
}));
