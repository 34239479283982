import { FilterParams } from './process/ProcessFilter';
import { EUserOrganizationStatus } from './user-organization';
import { EUserProcessStatus } from './user-process';

export type ExtendedUserDocument = UserDTO & { organizationId: string };

export class UserDTO {
  id: string;
  email: string;
  contactEmail?: string;
  name?: string;
  phone?: string;
  title?: string;
  avatarSrc?: string;
  isActivated: boolean; // TODO rename to isActive;
  externalLinks?: ExternalLinks;
  tourStatus?: UserTourStatus;
  colorId?: number;
  appStatus?: UserAppStatus;
  sellerFilterParams?: FilterParams;
  buyerFilterParams?: FilterParams;
  isAdmin?: boolean;
  deleted?: boolean;
  lastName?: string;
}

export type CreateUserData = {
  email: string,
  fullName?: string,
  phone?: string,
  isAppsumo?: boolean,
  contactEmail?: string,
  appStatus?: UserAppStatus,
}

export interface ExternalLinks {
  linkedIn?: string;
  calendly?: string;
}

export interface UserTourStatus {
  isDealsPageCompleted?: boolean;
  isTemplatesPageCompleted?: boolean;
  isDealPageCompleted?: boolean;
  shouldSeeBilling?: boolean;
}

//New addition! To find all active users look for users with no appStatus and users with appStatus = "ACTIVE"
export enum UserAppStatus {
  ACTIVE = "ACTIVE",
  GUEST = "GUEST",
}

export enum EUserToursNames {
  TOUR_DEALS_PAGE = 'isDealsPageCompleted',
  TOUR_PROCESS_PAGE = 'isDealPageCompleted?',
}

export class ProcessUserDTO extends UserDTO {
  processStatus: EUserProcessStatus;
  joinedAt?: number;
  lastActive?: number;
}

export class OrganizationUserDTO extends UserDTO {
  rolesIds: string[];
  organizationStatus: EUserOrganizationStatus;
}

export type TUpdateUserDataRequest = Omit<UserDTO,
  'isActivated'
  | 'email'
>
