import { useAppDispatch, useAppSelector } from './stateHooks';
import { EProcessBackgroundType } from '../../shared/process/ProcessMilestoneActionDTO';
import {
  CustomizationProcessStyles,
  resetDealStyles,
  selectBackground,
  selectBackgroundStorageType,
  selectBackgroundType,
  selectProcessBackgroundColor,
  selectProcessBackgroundDisplayStatus,
  selectProcessBackgroundUrl,
  setBackgroundDisplayStatus,
  setBackgroundType,
  setDealStyles,
} from '../features/DealCustomizationMenu/lib/dealCustomizationSlice';

const useProcessBackground = () => {
  const dispatch = useAppDispatch();
  const background = useAppSelector(selectBackground);
  const backgroundType = useAppSelector(selectBackgroundType);
  const backgroundStorageType = useAppSelector(selectBackgroundStorageType);
  const url = useAppSelector(selectProcessBackgroundUrl);
  const display = useAppSelector(selectProcessBackgroundDisplayStatus);
  const backgroundColor = useAppSelector(selectProcessBackgroundColor);

  const onBackgroundTypeUpdate = (newType?: EProcessBackgroundType) => {
    dispatch(setBackgroundType(newType));
  };

  const onBackgroundDisplayUpdate = (status?:boolean) => {
    dispatch(setBackgroundDisplayStatus(status));
  };

  const onBackgroundDataUpdate = (
    newData?: Partial<CustomizationProcessStyles>,
  ) => {
    if (newData) {
      dispatch(setDealStyles(newData));
    } else {
      dispatch(resetDealStyles());
    }
  };

  const onCustomizationTabClose = () => {
    dispatch(resetDealStyles());
  };

  return {
    display,
    url,
    background,
    backgroundColor,
    backgroundType,
    onBackgroundTypeUpdate,
    backgroundStorageType,
    onBackgroundDisplayUpdate,
    onBackgroundDataUpdate,
    onCustomizationTabClose,
  };
};

export default useProcessBackground;
