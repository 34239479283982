import {
  Box,
  MenuProps,
} from '@mui/material';
import * as React from 'react';
import {
  memo, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { StyledAppDropdown } from './AppDropdownStyles';
import { AppDropdownItem, AppDropdownItemProps } from './AppDropdownItem';
import ChevronLeft from '../../icons/ChevronLeft.svg';
import { DropdownSearch } from './DropdownSearch';
import { AppInput } from '../AppInput/AppInput';

type DropdownItem = AppDropdownItemProps;
type SearchType = 'default' | 'multiselect';
type AppDropdownSize =
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl';
type AppDropdownVariant = 'primary';

export type AppDropdownProps = {
  items: Array<DropdownItem | null>;
  hideNavigation?: boolean;
  keyboardControl?: boolean;
  preventFallback?: boolean;
  autoSize?: boolean;
  onChipDelete?: (item: AppDropdownItemProps, index: number) => void;
  searchType?: SearchType,
  lastElementHasAnotherColor?: boolean,
  size?: AppDropdownSize,
  variant?: AppDropdownVariant,
} & Omit<MenuProps, 'variant' | 'size'>;

type PathEntry = { index: number, key: string | number, label?: string };

type DropdownContext = {
  path: PathEntry[]
};

// eslint-disable-next-line react/display-name
export const AppDropdown = memo(({
  size = 'm',
  variant = 'primary',
  items,
  keyboardControl,
  preventFallback,
  className,
  autoSize,
  hideNavigation,
  onChipDelete,
  searchType,
  lastElementHasAnotherColor,
  ...restProps
}: AppDropdownProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [itemsContext, setItemsContext] = useState<DropdownContext>({ path: [] });

  const ensurePath = (pathEntry: PathEntry) => items[pathEntry.index] && items[pathEntry.index].label === pathEntry.key;

  const contextItems = useMemo(() => {
    let current: DropdownItem[] = items;
    for (const pathEntry of itemsContext.path) {
      const hasItems = ensurePath(pathEntry) && items[pathEntry.index].childrenItems;
      if (!hasItems) break;
      current = items[pathEntry.index].childrenItems!;
    }
    return current;
  }, [itemsContext, items]);

  const multiselect = useMemo(() => searchType === 'multiselect' && contextItems.some((item) => item.selectable), [contextItems]);

  const goBack = () => {
    setItemsContext((context) => {
      const newContext = { ...context };
      newContext.path.pop();
      return newContext;
    });
  };

  const goToRoot = () => {
    setItemsContext((context) => {
      const newContext = { ...context };
      newContext.path = [];
      return newContext;
    });
  };

  const handleItemClick = (event: React.MouseEvent<HTMLLIElement>, index: number, props: AppDropdownItemProps) => {
    event.stopPropagation();
    if (props.childrenItems) {
      setItemsContext({ path: [...itemsContext.path, { key: props.label, index, label: props.label }] });
    }
    return props.onClick && props.onClick(event);
  };

  useEffect(() => {
    if (!restProps.open) {
      setTimeout(() => {
        goToRoot();
        setSearchQuery('');
      }, 500);
    }
  }, [restProps.open]);

  const onClose = (event: any, reason: any) => {
    setTimeout(goToRoot, 500);
    if (restProps.onClose) {
      restProps.onClose(event, reason);
    }
  };

  const getCurrentLabel = () => (itemsContext.path.length ? itemsContext.path[itemsContext.path.length - 1].label : '');

  const onKeyDown = (e: any, index?: number, props?: AppDropdownItemProps) => {
    e.stopPropagation();
    if (!keyboardControl) return;
    if (e.key === 'ArrowRight' && props && props.childrenItems && typeof index === 'number') {
      setItemsContext({ path: [...itemsContext.path, { key: props.label, index, label: props.label }] });
    }
    if (e.key === 'ArrowLeft') {
      goBack();
    }
    if (e.key === 'Enter') {
      if (props?.onClick) e.target.click();
    }
  };

  const onFocus = (e: any) => {
    if (!keyboardControl) return;
    e.target.dataset.focused = 'y';
  };

  const onBlur = (e: any) => {
    if (!keyboardControl) return;
    e.target.dataset.focused = 'n';
  };

  const selectedItems = useMemo(() => {
    if (!multiselect) return [];
    return contextItems.filter((item) => item.selected);
  }, [contextItems]);

  const menuItems = useMemo(() => {
    const isItemVisible = (item: AppDropdownItemProps) => !item.hidden;
    const isItemLabelContainsSearchStr = (item: AppDropdownItemProps) => item.label?.toLowerCase().includes(searchQuery?.toLowerCase());
    const isItemSelectedAsAutocompleteChip = (item: AppDropdownItemProps) => !multiselect || !item.selected;
    const options = contextItems
      .filter((item) => isItemVisible(item)
        && isItemLabelContainsSearchStr(item)
        && isItemSelectedAsAutocompleteChip(item));
    if (!options.length) {
      return [<AppDropdownItem
        hideNavigation={hideNavigation}
        autoSize={autoSize}
        key="No options"
        label="No options"
        disabled
      />];
    }
    return options.map((props, index) => (
      <AppDropdownItem
        hideNavigation={hideNavigation}
        autoFocus={keyboardControl}
        autoSize={autoSize}
        onKeyDown={(e) => onKeyDown(e, index, props)}
        onFocus={onFocus}
        onBlur={onBlur}
        key={props.label + props.id + props.key}
        {...props}
        onClick={(e) => handleItemClick(e, index, props)}
      />
    ));
  }, [searchQuery, contextItems]);

  return (
    <StyledAppDropdown
      // className={classNames(`size-${size} variant-${variant}`, {
      className={classNames(`size-${size} variant-${variant}`, {
      }, className)}
      {...restProps}
      onClose={onClose}
      onKeyDown={(e: any) => e.stopPropagation()}
      PaperProps={{
        className: (classNames(
          `size-${size} variant-${variant}`,
          { 'different-color-for-last-element': lastElementHasAnotherColor },
        )),
      }}
    >
      {
        getCurrentLabel() && !hideNavigation
          ? (
            <AppDropdownItem
              autoSize={autoSize}
              key={`${getCurrentLabel()}-back`}
              label={`${getCurrentLabel()}`}
              onClick={() => goBack()}
              startIcon={<ChevronLeft />}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={(e) => onKeyDown(e)}
            />
          ) : null
      }
      { searchType === 'multiselect' && (
      <DropdownSearch
        chosenItems={selectedItems}
        onChange={(val) => setSearchQuery(val)}
        value={searchQuery}
        onChipDelete={(item, index) => {
          if (onChipDelete) {
            onChipDelete(item, index);
          }
        }}
      />
      ) }
      {
        searchType === 'default' && (
        <Box key="search-container" className="search-container" onKeyDown={(e) => e.stopPropagation()}>
          <AppInput
            fullWidth
            className="search"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            placeholder="Search"
          />
        </Box>
        )
      }
      {menuItems}
    </StyledAppDropdown>
  );
});
