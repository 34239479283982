import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  EFieldsubType,
  EMediaFieldStorageType,
  ProcessStageDTO,
  TUploadProcessItemFile,
} from '../../shared/Field/Field';
import {
  ContentType,
  StageContentType,
  WideScreenSectionType,
} from '../../shared/Stage';
import {
  selectSidebarTab,
  selectNewPageTab,
  selectNewPageEmbedTab,
  ENewPageTabs,
  ESidebarTabs,
  setNewPageEmbedTab,
  setNewPageTab,
  setSidebarTab,
  setStagesDrawerOpen,
} from '../features/Layout/lib/fieldsLayoutSlice';
import {
  compareLinkAndSource,
  EMediaProvider,
  EMediaTypeTags,
  formatUrl,
  getSubtypeByMIME,
} from '../features/MediaField/Helpers/MediaFieldHelpers';
import { generateDocWithCanvasNode, generateDocWithMediaNode } from '../features/Tiptap/Utils/TiptapHelpers';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { useStages } from './useStages';
import { getIcon, templateContentType } from '../features/NewPageAdding/Helpers/NewPageHelpers';
import { AppButton } from '../shared/AppButton/AppButton';
import { FormattedLabel } from '../shared/AssigneeSelection/FormattedLabel';
import { stagesetColors } from '../theme/stagesetPalette';
import { selectOrganizationId } from '../core/store/appState/appState';
import { useGetStagesTemplatesByOrgIdQuery } from '../features/ProcessFields/lib/processFieldsApi';
import { useItemFileUpload } from '../features/FilesUpload/FilesUploadProvider/FilesUploadProvider';
import {
  selectCurrentStageNeedsToBeUpdated,
  setCurrentStageNeedsToBeUpdated,
  selectLibraryFileToAdd,
  setLibraryFileToAdd,
} from '../features/BlockContentSection/lib/sectionsSlice';
import { selectCurrentStage } from '../features/ProcessFields/lib/processItemsSlice';
import { FileItem } from '../../shared/FileItems/FileItems';
import { ECustomEventsNames } from '../../shared/events/CustomEvents';
import useEditorEvent from '../features/Tiptap/Hooks/useTiptapEditorEvents';
import { TiptapCustomEvents } from '../features/Tiptap/TiptapEventListnerPlugin';
import { selectNextPosition, selectProcess } from '../routes-old/process/state/processSlice';
import { selectTemplatesFilter } from '../pages/templates/lib/templatesSlice';
import { LibraryFileDTO } from '../../shared/library/LibraryDTO';

type CreateStagePayload = {
  title?: string;
  lock?: boolean;
  hide?: boolean;
  templateId?: string;
  position?: number;
  stageContentType?: StageContentType;
  wideScreenSectionType?: WideScreenSectionType;
  file?: File[];
  url?: string;
  subType?: EFieldsubType;
  contentType?: ContentType;
  source?: string;
  typeTag?: EMediaTypeTags;
  provider?: EMediaProvider;
  organisationId?: string;
  producerId?: string;
  files?: { [key: string]: FileItem };
  items?: { [key: string]: any };
  doc?: string;
  libraryFileToAdd?: LibraryFileDTO;
  client?: string;
  clientName?: string;
};

const widescreenContentTypeConverter = {
  [ESidebarTabs.EMBED]: 1,
  [ESidebarTabs.MEDIA]: 1,
  [ESidebarTabs.CANVAS]: 0,
};

const widescreenSectionTypeConverter = {
  [ESidebarTabs.EMBED]: WideScreenSectionType.EMBED,
  [ESidebarTabs.MEDIA]: WideScreenSectionType.MEDIA,
  [ESidebarTabs.CANVAS]: null,
};

const widescreenStorageTypeConverter = {
  [ESidebarTabs.EMBED]: EMediaFieldStorageType.EXTERNAL,
  [ESidebarTabs.MEDIA]: EMediaFieldStorageType.INTERNAL_S3_PUBLIC,
};

const FILE_SIZE = 1024 * 1024 * 10;

const useCreateNewPage = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectTemplatesFilter);
  const currentStage = useAppSelector(selectCurrentStage);
  const organizationId = useAppSelector(selectOrganizationId);
  const currentSidebarTab = useAppSelector(selectSidebarTab);
  const currentNewPageTab = useAppSelector(selectNewPageTab);
  const currentPosition = useAppSelector(selectNextPosition);
  const newPageEmbedTab = useAppSelector(selectNewPageEmbedTab);
  const { onUpload } = useItemFileUpload();
  const { dispatchEditorEvent } = useEditorEvent();
  const [templateTitle, setTemplateTitle] = useState<string | undefined>(undefined);
  const [templateError, setTemplateError] = useState<string | undefined>(undefined);
  const [stageCreation, setStageCreation] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const isCurrentStageNeedsToBeUpdated = useAppSelector(selectCurrentStageNeedsToBeUpdated);
  const { onCreateNewStage } = useStages();
  const libraryFileToAdd = useAppSelector(selectLibraryFileToAdd);
  const process = useAppSelector(selectProcess);

  // eslint-disable-next-line no-nested-ternary
  const newPageStateForTabs = currentNewPageTab === ENewPageTabs.TEMPLATE_EDIT
    ? ENewPageTabs.TEMPLATE
    : (currentNewPageTab === ENewPageTabs.LIBRARY_EDIT ? ENewPageTabs.LIBRARY : currentNewPageTab);

  const { data: stageTemplates, refetch, isUninitialized } = useGetStagesTemplatesByOrgIdQuery(
    {
      organizationId: organizationId!, filter,
    },
    {
      skip: !organizationId,
    },
  );

  useEffect(() => {
    if (currentNewPageTab !== ENewPageTabs.LIBRARY_EDIT) {
      dispatch(setLibraryFileToAdd(undefined));
    }
    if (currentNewPageTab === ENewPageTabs.TEMPLATE && !isUninitialized) {
      refetch();
    }
  }, [currentNewPageTab]);

  const defaultNewPageObject: CreateStagePayload = {
    title: `Page ${currentPosition}`,
    lock: false,
    hide: false,
    position: currentPosition,
    subType: EFieldsubType.MIXED,
    file: undefined,
    url: undefined,
    templateId: undefined,
    contentType: undefined,
    source: undefined,
    typeTag: undefined,
    provider: undefined,
    items: undefined,
    files: undefined,
    client: process?.client,
  };

  const profileFormSchemaConfig = () => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    validationSchemaConfigTemp.title = yup.string().required('Page title is required');
    if (currentNewPageTab === ENewPageTabs.BLANK) {
      if (currentSidebarTab === ESidebarTabs.MEDIA) {
        validationSchemaConfigTemp.file = yup.mixed()
          .test(
            'required',
            'Please select a file',
            (file) => file?.length > 0,
          )
          .test(
            'required',
            'File is too large',
            (file) => {
              if (file) {
                return file?.[0]?.size <= FILE_SIZE;
              }
              return false;
            },
          );
      }
      if (currentSidebarTab === ESidebarTabs.EMBED && newPageEmbedTab !== ContentType.URL) {
        validationSchemaConfigTemp.url = yup.string()
          .required('Link on embed is reqired')
          .test(
            'url test',
            'Must be a valid link',
            (value, context) => {
              if (value) {
                const result = compareLinkAndSource(value.trim(), context.parent.contentType);
                return result;
              }
              return false;
            },
          );
      }
      if (currentSidebarTab === ESidebarTabs.EMBED && newPageEmbedTab === ContentType.URL) {
        validationSchemaConfigTemp.url = yup.string()
          .required('Link on embed is reqired');
      }
    }
    return validationSchemaConfigTemp;
  };

  const schema = yup.object(profileFormSchemaConfig()).required();

  const newPageFormMethods = useForm<CreateStagePayload>({
    mode: 'onChange',
    defaultValues: defaultNewPageObject,
    resolver: yupResolver(schema),
  });

  const {
    formState: { isValid },
    reset,
    setValue,
    trigger,
  } = newPageFormMethods;

  const onClose = () => {
    dispatch(setSidebarTab(ESidebarTabs.STAGES));
    dispatch(setNewPageTab(ENewPageTabs.BLANK));
    dispatch(setNewPageEmbedTab(ContentType.URL));
    setTemplateTitle(undefined);
    setTemplateError(undefined);
    dispatch(setCurrentStageNeedsToBeUpdated(false));
    reset(defaultNewPageObject);
    if (isMobile) {
      dispatch(setStagesDrawerOpen(false));
    }
  };

  const setStageItems = (data: CreateStagePayload, doc: undefined | string, subType: EFieldsubType) => {
    const formattedUrl: string | null = data.url ? formatUrl(data.url, data.contentType) : null;
    if (data.templateId) return data.items;
    switch (widescreenContentTypeConverter[currentSidebarTab]) {
      case 1:
        return generateDocWithMediaNode(
          {
            src: formattedUrl,
            subType,
            storageType: widescreenStorageTypeConverter[currentSidebarTab],
            doc,
          },
        );
      default:
        return generateDocWithCanvasNode();
    }
  };

  const createStage = async (data: CreateStagePayload) => {
    let doc: undefined | string;
    let subType = data.subType || EFieldsubType.EMPTY;
    if (data.file?.[0]) {
      doc = uuidv4();
      subType = getSubtypeByMIME(data.file[0].type);
    }
    if (data.libraryFileToAdd) {
      doc = data.libraryFileToAdd.fileUid;
      if (data.libraryFileToAdd.fileSubtype) {
        subType = data.libraryFileToAdd.fileSubtype;
      }
    }
    const newStage = await onCreateNewStage({
      title: data.title,
      stageContentType: widescreenContentTypeConverter[currentSidebarTab],
      contentType: data.contentType ?? newPageEmbedTab,
      position: currentPosition,
      lock: data.lock,
      hide: data.hide,
      subType,
      wideScreenSectionType: widescreenSectionTypeConverter[currentSidebarTab],
      items: setStageItems(data, doc, subType), // ToDo add lib id instead of doc
      files: data.templateId ? data.files : undefined,
      templateId: data.templateId,
      client: process.client,
      clientName: process.clientName,
    });
    if (doc && data.file?.[0]) {
      onUpload(data.file[0], { docId: doc, itemId: newStage.newField });
    }
    return newStage;
  };

  const updateStage = (
    id: string,
    eventType: TiptapCustomEvents,
    updateData: Partial<ProcessStageDTO>,
    files?: TUploadProcessItemFile[],
  ) => {
    dispatchEditorEvent({ eventType, newContent: updateData.items });
    document.dispatchEvent(new CustomEvent(ECustomEventsNames.PROCESS_ITEM_UPDATE, {
      detail: {
        id,
        files,
        data: { ...updateData },
      },
    }));
  };

  const submitNewPageForm = async (data?: CreateStagePayload) => {
    if (!data) return;
    const payload = { ...data, clientName: process.clientName };
    payload.files = data?.files;
    payload.items = data?.items;
    setStageCreation(true);
    let doc: undefined | string;
    let subType = data.subType || EFieldsubType.EMPTY;

    if (data.file?.[0]) {
      doc = uuidv4();
      subType = getSubtypeByMIME(data.file[0].type);
    }
    if (data.libraryFileToAdd) {
      doc = data.libraryFileToAdd.fileUid;
      if (data.libraryFileToAdd.fileSubtype) {
        subType = data.libraryFileToAdd.fileSubtype;
      }
    }
    switch (isCurrentStageNeedsToBeUpdated) {
      case true:
        updateStage(currentStage.id, TiptapCustomEvents.CLEAR_CONTENT, {
          items: {},
          files: {},
        });
        updateStage(currentStage.id, TiptapCustomEvents.SET_CONTENT, {
          title: data.title,
          stageContentType: widescreenContentTypeConverter[currentSidebarTab],
          contentType: data.contentType ?? newPageEmbedTab,
          lock: data.lock,
          hide: data.hide,
          subType,
          wideScreenSectionType: widescreenSectionTypeConverter[currentSidebarTab],
          items: setStageItems(data, doc, subType),
          files: data.templateId ? data.files : undefined,
        });
        if (doc && data.file?.[0]) {
          onUpload(data.file[0], { docId: doc, itemId: currentStage.id });
        }
        break;
      default:
        await createStage(payload);
        break;
    }
    setStageCreation(false);
    onClose();
  };

  const goBack = () => {
    dispatch(setSidebarTab(ESidebarTabs.NEWPAGE));
    dispatch(setNewPageTab(ENewPageTabs.BLANK));
    dispatch(setNewPageEmbedTab(ContentType.URL));
    setTemplateError(undefined);
    setTemplateError(undefined);
  };

  useEffect(() => {
    if (currentNewPageTab === ENewPageTabs.BLANK) {
      if (!isCurrentStageNeedsToBeUpdated && !templateTitle) reset(defaultNewPageObject);
      setTemplateTitle(undefined);
      setTemplateError(undefined);
    }
  }, [currentNewPageTab, currentSidebarTab]);

  useEffect(() => {
    if (isCurrentStageNeedsToBeUpdated) {
      setValue('title', currentStage.title);
      setValue('contentType', currentStage.contentType);
      trigger('title');
    }
  }, [isCurrentStageNeedsToBeUpdated]);

  useEffect(() => {
    if (!isValid) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isValid]);

  const clearField = (field: 'title'
  | 'subType'
  | 'url'
  | 'lock'
  | 'hide'
  | 'stageContentType'
  | 'wideScreenSectionType'
  | 'position'
  | 'templateId'
  | 'file'
  | `file.${number}`) => {
    setValue(field, '', { shouldDirty: true });
    trigger(field);
  };

  const templatesMenuItems = useMemo(() => [
    (stageTemplates || [])
      .filter((template) => {
        switch (currentSidebarTab) {
          case ESidebarTabs.CANVAS:
            return !('wideScreenSectionType' in template) || template.wideScreenSectionType === null;
          case ESidebarTabs.EMBED:
            if (newPageEmbedTab === ContentType.URL) {
              return template.wideScreenSectionType === 0;
            }
            return newPageEmbedTab === template.contentType;
          case ESidebarTabs.MEDIA:
            return template.wideScreenSectionType === 1;
          default:
            return false;
        }
      })
      .map((template) => (
        <AppButton
          disableRipple
          fullWidth
          variant="transparent-grey-icon"
          size="l-flex-start-new-page"
          key={template.id}
          startIcon={getIcon(currentSidebarTab)}
          onClick={() => {
            setValue('title', isCurrentStageNeedsToBeUpdated ? currentStage.title : template.title);
            setValue('templateId', template.id);
            setValue('items', template.items);
            setValue('files', template.files);
            setValue('position', currentPosition + 1);
            setValue('contentType', templateContentType(currentSidebarTab, template?.contentType));
            setValue('subType', template?.items?.content?.[0]?.attrs?.subType ?? EFieldsubType.MIXED);
            setTemplateTitle(template.title);
            dispatch(setNewPageTab(ENewPageTabs.TEMPLATE_EDIT));
            setTemplateError(undefined);
            trigger('title');
          }}
        >
          <FormattedLabel
            label={template.title}
            len={24}
            tooltip
            placement="right"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: stagesetColors.newGrey[800],
            }}
          />
        </AppButton>
      )),
  ], [currentSidebarTab, stageTemplates, newPageEmbedTab]);

  return {
    submitNewPageForm,
    newPageFormMethods,
    goBack,
    clearField,
    onClose,
    disabled,
    templatesMenuItems,
    newPageStateForTabs,
    stageCreation,
    templateError,
    templateTitle,
    maxSize: FILE_SIZE,
    libraryFileToAdd,
  };
};

export default useCreateNewPage;
