import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import {
  TemplateBottomWrapper,
  TemplateContentContainer,
  TemplateTabItem,
  TemplateTextSkeleton,
  TemplateUpperWrapper,
} from '../../pages/templates/Styles/TemplatesStyles';

const TemplateTabItemSkeleton = ({ key }: { key: number }): JSX.Element | null => (
  <TemplateTabItem
    key={`template-tab-skeleton-item-${key}`}
    className={classNames('template-skeleton', { 'template-item-mobile': isMobile })}
    disableRipple
  >
    <TemplateContentContainer
      className={isMobile ? 'template-content-container-mobile' : undefined}
    >
      <TemplateUpperWrapper
        className={isMobile ? 'template-upper-wrapper-mobile' : undefined}
      >
        <TemplateTextSkeleton
          className={isMobile ? 'template-mobile-title' : 'template-desktop-title'}
          animation="wave"
          variant="rectangular"
        />
      </TemplateUpperWrapper>
      <TemplateBottomWrapper
        className={isMobile ? 'template-bottom-wrapper-mobile' : undefined}
      >
        <TemplateTextSkeleton
          className={isMobile ? 'template-mobile-description-typography' : 'template-desktop-description-typography'}
          animation="wave"
          variant="rectangular"
        />
        {isMobile && (
          <TemplateTextSkeleton
            className="template-mobile-time-typography"
            animation="wave"
            variant="rectangular"
          />
        )}
      </TemplateBottomWrapper>
    </TemplateContentContainer>
  </TemplateTabItem>
);

export default TemplateTabItemSkeleton;
