import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { ESidebarTabs, selectSidebarTab, setSidebarTab } from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import ProcessTimeLineUsersFilterList from '../../widgets/ProcessTimeline/ProcessTimeLineUsersFilterList';
import ProcessTimelineDateFilter from '../../widgets/ProcessTimeline/ProcessTimelineDateFilter';
import ProcessTimelineEventsFilterList from '../../widgets/ProcessTimeline/ProcessTimelineEventsFilterList';
import ProcessTimelineTeammatesFilter from '../../widgets/ProcessTimeline/ProcessTimelineTeammatesFilter';
import {
  HeaderTitle, SidebarContent, SidebarHeader, SidebarTabContentWrapper,
  SidebarTabPanel,
} from './Styles/SidebarStyles';
import { CloseIcon20 } from '../../icons/CloseIcon';
import { useDealTheme } from '../../hooks/useDealTheme';

const SidebarFiltersTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { dealTheme: { dealOverrides: { sidebar: { contrastText } } } } = useDealTheme();
  const changeColor = contrastText === '#000000';
  const currentTab = useAppSelector(selectSidebarTab) === ESidebarTabs.FILTERS;
  const onClick = () => {
    if (isMobile) {
      dispatch(setSidebarTab(ESidebarTabs.STAGES));
    } else dispatch(setSidebarTab(ESidebarTabs.INTERNAL));
  };

  return (
    <SidebarTabPanel
      value={ESidebarTabs.FILTERS}
      className={classNames('sidebar-themed', { 'active-sidebar-tab': currentTab })}
    >
      <SidebarTabContentWrapper
        className="sidebar-wrapper-default"
      >
        <SidebarHeader>
          <HeaderTitle>
            Filters
          </HeaderTitle>
          <AppIconButton
            variant={changeColor ? 'transparent' : 'transparent-white'}
            transparentStroke
            icon={<CloseIcon20 />}
            onClick={onClick}
          />
        </SidebarHeader>
        <SidebarContent
          className="sidebar-filters"
        >
          <ProcessTimeLineUsersFilterList />
          <ProcessTimelineEventsFilterList />
          <ProcessTimelineDateFilter />
          <ProcessTimelineTeammatesFilter />
        </SidebarContent>
      </SidebarTabContentWrapper>
    </SidebarTabPanel>
  );
};

export default SidebarFiltersTab;
