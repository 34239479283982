import { InfoTableSubtitle, InfoTableTitle } from '../../Styles/CustomDomainTabStyles';
import { SettingsFormWrapper } from '../../Styles/GeneralTabStyles';

export const OrdinaryUserInitialHint = () => (
  <SettingsFormWrapper
    className="settings-column"
  >
    <InfoTableTitle>
      Specify subdomain for your organization
    </InfoTableTitle>
    <InfoTableSubtitle>
      The chosen subdomain should include only latin symbols (a-z)
      and digits (0-9)
    </InfoTableSubtitle>
  </SettingsFormWrapper>
);

export const OrdinaryUserSuccessHint = () => (
  <SettingsFormWrapper
    className="settings-column"
  >
    <InfoTableTitle>
      Your subdomain is set
    </InfoTableTitle>
    <InfoTableSubtitle>
      It is automatically applied for all links of your organization
    </InfoTableSubtitle>
  </SettingsFormWrapper>
);
