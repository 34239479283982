import { LibraryFilterSetDTO, LibraryFilesFilterSet } from '../../shared/library/LibraryFilter';
import { ApiService } from '../core/api';

export class LibraryFilterApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('library-filter');
  }

  async createLibraryFilter(payload: LibraryFilesFilterSet): Promise<LibraryFilterSetDTO[]> {
    return this.api.post('', payload);
  }

  async getLibraryFilesFilters(): Promise<LibraryFilterSetDTO[]> {
    return this.api.get('files');
  }

  async getLibraryProductFilters(): Promise<LibraryFilterSetDTO[]> {
    return this.api.get('products');
  }

  async updateFilter(filterId: string, payload: Partial<LibraryFilesFilterSet>): Promise<LibraryFilterSetDTO> {
    return this.api.put(`files/${filterId}`, payload);
  }

  async updateProductFilter(filterId: string, payload: Partial<LibraryFilesFilterSet>): Promise<LibraryFilterSetDTO> {
    return this.api.put(`products/${filterId}`, payload);
  }

  async deleteFilter(filterId: string): Promise<void> {
    return this.api.delete(filterId);
  }
}
