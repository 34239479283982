import { useEffect, useState } from 'react';

function useDebounceSwitch(
  value: boolean,
  delay: number = 500,
  flip?: boolean,
): [
    boolean,
    (value: boolean, timerShoudStart?: boolean) => void,
  ] {
  const [switchValue, setSwitchValue] = useState<boolean>(value);
  let timer: NodeJS.Timeout;

  const updateSwitchValue = (val: boolean, startTimer: boolean = true) => {
    if (startTimer) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => setSwitchValue(typeof flip === 'undefined' ? val : flip), delay);
    } else {
      setSwitchValue(val);
    }
  };

  useEffect(() => () => { if (timer) clearTimeout(timer); }, []);

  return [switchValue, updateSwitchValue];
}

export default useDebounceSwitch;
