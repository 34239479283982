import { combineEpics } from "redux-observable";
import { redirectOnOrganizationUpdateEpic, subdomainLoginSuccessEpic, subdomainLogoutSuccessEpic, subdomainOrgLoadSuccessEpic, subdomainconfirmProcessAccountSuccessEpic } from "./subdomainEpic";

export const rootEpic = combineEpics(
  subdomainOrgLoadSuccessEpic,
  subdomainLoginSuccessEpic,
  subdomainLogoutSuccessEpic,
  subdomainconfirmProcessAccountSuccessEpic,
  redirectOnOrganizationUpdateEpic,
);
