import { Tooltip, Typography, TypographyProps } from '@mui/material';

interface Props {
  label?: string,
  len?: number,
  placement?:
  'bottom'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
  | undefined,
  labelFontWeight?: number,
  tooltip?: boolean,
}

export const FormattedLabel = ({
  label,
  len,
  placement,
  tooltip,
  ...labelProps
}: Props & TypographyProps): JSX.Element | null => {
  if (!label) return null;
  if (label && len && label.length > len && !tooltip) {
    return (
      <Typography
        {...labelProps}
      >
        {label.substring(0, len - 3)}
        ...
      </Typography>
    );
  }
  if (label && len && label.length > len) {
    return (
      <Tooltip
        title={label}
        placement={placement || 'bottom'}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              background: (theme) => theme.palette.grey[400],
            },
          },
          arrow: {
            sx: {
              color: (theme) => theme.palette.grey[400],
            },
          },
        }}
      >
        <Typography
          {...labelProps}
        >
          {label.substring(0, len - 3)}
          ...
        </Typography>
      </Tooltip>
    );
  } return (
    <Typography
      {...labelProps}
    >
      {label}
    </Typography>
  );
};
