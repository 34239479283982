import {
  Box, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { GridRowsProp } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { EStateStatus } from '../../../core/commonTypes';
import {
  allOrganizationsSelector,
  getAdminOrganizations,
  selectFirstFetchStatus,
  selectOrganizationsLoadingJobs,
  setAdminFeedTab,
  setCurrentOrganization,
} from '../state/adminSlice';
import { OrganizationAdminDTO } from '../../../../shared/OrganizationDTO';
import { EAdminDataGridColumns, EAdminFeedTab } from '../helpers/AdminHelpers';
import { DealsGrid } from '../../deals/DealPageStyles/DealsListStyle';
import { CrossDimensionDataGrid } from '../../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { AppSkeleton } from '../../../shared/AppSkeleton/AppSkeleton';
import { skeletonRows } from '../../deals/DealsHelpers/DealsHelpers';
import { ExtendedGridColumns } from '../../../types/mobile-table';

const GridItemSkeleton = () => (
  <AppSkeleton
    animation="wave"
    variant="rectangular"
    width="101px"
    height="32px"
    sx={{
      borderRadius: '16px',
    }}
  />
);

interface Props {
  searchFilter: string;
}

const AdminOrganizationsDataGrid = ({
  searchFilter,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const adminOrganizations: OrganizationAdminDTO[] = useAppSelector(allOrganizationsSelector);
  const { status } = useAppSelector((state) => state.admin);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const firstFetchStatus = useAppSelector(selectFirstFetchStatus);
  const organizationsLoadingJobs = useAppSelector(selectOrganizationsLoadingJobs);

  useEffect(() => {

  }, [searchFilter, adminOrganizations]);

  const onRowClick = (organization: OrganizationAdminDTO) => {
    dispatch(setCurrentOrganization(organization));
    dispatch(setAdminFeedTab(EAdminFeedTab.organizationDetails));
  };

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getAdminOrganizations());
    }
  }, []);

  useEffect(() => {
    let tempRows = !adminOrganizations ? [] : adminOrganizations.map((org: OrganizationAdminDTO) => ({
      ...org,
      [EAdminDataGridColumns.organizationName]: org.name,
      [EAdminDataGridColumns.ownder]: org.ownerCount,
      [EAdminDataGridColumns.admins]: org.adminCount,
      [EAdminDataGridColumns.users]: org.userCount,
      [EAdminDataGridColumns.tariff]: org.tariff,
      [EAdminDataGridColumns.domain]: org.domain,
    }));

    if (searchFilter) {
      tempRows = tempRows.filter((x: Partial<OrganizationAdminDTO>) => (!!x.name && x.name.indexOf(searchFilter) >= 0)
          || (!!x.email && x.email.indexOf(searchFilter) >= 0));
    }

    setRows(tempRows);
  }, [searchFilter, adminOrganizations]);

  const columns: ExtendedGridColumns = [
    {
      field: EAdminDataGridColumns.organizationName,
      sortable: true,
      headerName: 'Organization',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 4,
    },
    {
      field: EAdminDataGridColumns.ownder,
      sortable: true,
      headerName: 'Owner',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
    },
    {
      field: EAdminDataGridColumns.admins,
      sortable: true,
      headerName: 'Admins',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
    },
    {
      field: EAdminDataGridColumns.users,
      sortable: true,
      headerName: 'Users',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
    },
    {
      field: EAdminDataGridColumns.tariff,
      sortable: true,
      headerName: 'Tariff',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 2,
    },
    {
      field: EAdminDataGridColumns.domain,
      sortable: true,
      headerName: 'Domain',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 2,
    },
  ];

  return (
    <Box
      id="my-deals-tab-container"
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        padding: '20px',
        width: '100%',
      }}
    >
      <Box sx={{
        display: 'inline-flex',
        gap: '4px',
        marginBottom: '12px',
        marginLeft: '12px',
      }}
      >
        {
          firstFetchStatus ? (
            <>
              <Typography sx={{
                fontSize: '16px',
                color: (theme) => theme.palette.grey[600],
              }}
              >
                {adminOrganizations.length}
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                color: (theme) => theme.palette.grey[400],
                paddingTop: '2px',
              }}
              >
                Organizations
              </Typography>
            </>
          ) : (
            <GridItemSkeleton />
          )
        }
      </Box>
      <CrossDimensionDataGrid
        rows={rows}
        columns={columns}
        getId={(row) => row.id}
        renderMobileTitle={(row) => (
          <Box>
            {row[EAdminDataGridColumns.organizationName]}
          </Box>
        )}
        renderMobileMenu={() => (<>1</>)}
        onRowClick={(row) => {
          console.log(row);
        }}
        renderDesktopGridComponent={(props) => (
          <DealsGrid
            pageSize={25}
            loading={organizationsLoadingJobs > 0}
            disableSelectionOnClick
            disableColumnMenu
            columns={columns}
            rows={firstFetchStatus ? rows : skeletonRows}
            headerHeight={36}
            rowHeight={65}
            onRowClick={(rowParams) => onRowClick(rowParams.row)}
            getRowClassName={(params) => (params.row.status === 1 ? 'highlighted_row' : '')}
          />
        )}
      />
    </Box>
  );
};

export default AdminOrganizationsDataGrid;
