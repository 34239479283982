import { styled } from '@mui/material/styles';
import { Box, Tab } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledAppTabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 0,
  // width: '100%', //TODO check
  '&.hidden': {
    display: 'none',
  },
  justifyContent: 'space-between',
  '& .tab-panel': {
    display: 'flex',
    alignItems: 'center',
    flex: 0,
    flexWrap: 'nowrap',
    flexGrow: 1,
    '&.variant-boxed': {
      background: theme.palette.primary[50],
      boxShadow: '0px 3px 2px 0px rgba(0, 113, 190, 0.04), '
        + '0px 1px 1px 0px rgba(0, 113, 190, 0.07), 0px 0px 1px 0px rgba(0, 113, 190, 0.08)',
      '&.mobile': {
        display: 'flex',
      },
      '&.color': {
        '&-light': {
          background: theme.palette.common.white,
        },
      },
    },
    '&.variant-boxedNew': {
      boxShadow: '0px 3px 2px 0px rgba(0, 113, 190, 0.04),'
        + '0px 1px 1px 0px rgba(0, 113, 190, 0.07),'
        + '0px 0px 1px 0px rgba(0, 113, 190, 0.08),'
        + '0px 0px 0px 0px rgba(0, 113, 190, 0.08)',
      background: stagesetColors.white[100],
    },
    '&.variant-boxedNew-dark': {
      '& .MuiTabs-flexContainer': {
        gap: '2px',
      },
      boxShadow: '0px 0px 4px 0px rgba(30, 41, 59, 0.20) inset',
      backgroundColor: stagesetColors.newGrey[200],
    },
    '&.variant-boxedNew-contrast': {
      '& .MuiTabs-flexContainer': {
        gap: '2px',
      },
      backgroundColor: stagesetColors.white[100],
    },
    '&.variant-light .MuiTabs-flexContainer': {
      paddingBottom: '7px',
    },
    '&.size': {
      '&-l': {
        borderRadius: '10px',
        height: '40px',
        minHeight: '40px',
        '& .MuiTabs-flexContainer': {
          height: '40px',
          minHeight: '40px',
        },
      },
      '&-l-new': {
        borderRadius: '10px',
        padding: '2px',
        height: '40px',
        minHeight: '40px',
        '& .MuiTabs-flexContainer': {
          height: '36px',
          minHeight: '36px',
          gap: '2px',
        },
        '& button': {
          display: 'inline-flex',
          width: '100%',
        },
      },
      '&-l-new-pt': {
        borderRadius: '10px',
        padding: '2px',
        height: '40px',
        minHeight: '40px',
        '& .MuiTabs-flexContainer': {
          height: '36px',
          minHeight: '36px',
          gap: '2px',
        },
        '& button': {
          borderRadius: '8px',
          display: 'inline-flex',
          width: '56px',
          minWidth: '56px',
        },
      },
      '&-m': {
        borderRadius: '8px',
        height: '32px',
        minHeight: '32px',
        '& .MuiTabs-flexContainer': {
          height: '32px',
          minHeight: '32px',
        },
      },
      '&-m-new': {
        borderRadius: '10px',
        padding: '2px',
        height: '32px',
        minHeight: '32px',
        '& .MuiTabs-flexContainer': {
          height: '28px',
          minHeight: '28px',
          gap: '2px',
        },
        '& button': {
          display: 'inline-flex',
          width: '100%',
        },
      },
    },
  },
}));

export const StyledAppTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  padding: 0,
  height: '100%',
  minHeight: '100%',
  whiteSpace: 'nowrap',
  '&.inactive:not(.disabled)': {
    color: theme.palette.grey[500],
    '& path': {
      stroke: theme.palette.grey[500],
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
    '&.variant-boxedNew': {
      color: stagesetColors.newGrey[800],
      '& path': {
        color: stagesetColors.newGrey[800],
      },
      '&:hover': {
        backgroundColor: stagesetColors.newGrey[100],
      },
      '&:focus': {
        backgroundColor: stagesetColors.newGrey[100],
      },
    },
    '&.variant-boxedNew-dark': {
      color: stagesetColors.newGrey[800],
      '& svg': {
        '& path': {
          stroke: stagesetColors.newGrey[800],
        },
      },
      '&:hover:not(.Mui-selected)': {
        backgroundColor: stagesetColors.newGrey[100],
      },
    },
    '&.variant-boxedNew-contrast': {
      color: stagesetColors.newGrey[800],
      '& svg': {
        '& path': {
          stroke: stagesetColors.newGrey[800],
        },
      },
      '&:hover:not(.Mui-selected)': {
        backgroundColor: stagesetColors.newGrey[100],
      },
    },
  },
  '&.active:not(.disabled)': {
    '&.variant-boxed': {
      '&.color': {
        '&-white': {
          color: theme.palette.primary[500],
          backgroundColor: theme.palette.primary.contrastText,
          '& path': {
            stroke: theme.palette.primary[500],
          },
        },
        '&-light': {
          color: theme.palette.primary[500],
          backgroundColor: theme.palette.primary[100],
          '& path': {
            stroke: theme.palette.primary[500],
          },
        },
        '&-blue': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          '& path': {
            stroke: theme.palette.common.white,
          },
        },
      },
    },
    '&.variant-boxedNew': {
      backgroundColor: stagesetColors.newGrey[200],
      color: stagesetColors.newGrey[800],
      '& path': {
        color: stagesetColors.newGrey[800],
      },
    },
    '&.variant-boxedNew-dark': {
      backgroundColor: stagesetColors.newGrey[800],
      color: stagesetColors.white[100],
      '& svg': {
        '& path': {
          stroke: stagesetColors.white[100],
        },
      },
    },
    '&.variant-boxedNew-contrast': {
      backgroundColor: stagesetColors.newGrey[800],
      color: stagesetColors.white[100],
      '& svg': {
        '& path': {
          stroke: stagesetColors.white[100],
        },
      },
    },
  },
  '&.disabled': {
    color: theme.palette.grey[100],
    backgroundColor: 'transparent',
    '& path': {
      stroke: theme.palette.grey[100],
    },
  },
  '&.size': {
    '&-l': {
      borderRadius: '10px',
    },
    '&-l-new': {
      borderRadius: '10px',
      padding: '8px 16px',
    },
    '&-m': {
      borderRadius: '8px',
    },
    '&-m-new': {
      borderRadius: '8px',
      padding: '4px 10px',
    },
  },
}));
