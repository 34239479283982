import { SvgIcon, SvgIconProps } from '@mui/material';
import BurgerMenu from './BurgerMenu.svg';

export const BurgerMenuIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    component={BurgerMenu}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  />
);
