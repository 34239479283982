import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const SubdomainInputWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));

export const SubdomainSVGIconGreyWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    '& g': {
      '& path': {
        stroke: stagesetColors.newGrey[400],
      },
    },
  },
}));

export const SubdomainSVGIconRedWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    '& g': {
      '& path': {
        stroke: stagesetColors.red[600],
      },
    },
  },
}));

export const SubdomainLabel = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[500],
}));

export const DomainSwitchWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.question-icon': {
    '& svg': {
      '& path': {
        stroke: stagesetColors.newGrey[800],
      },
    },
  },
}));

export const ButtonWrapper = styled(Box)(() => ({
  paddingTop: '16px',
}));

export const InstructionsTable = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  '&.instructions-wrapper-mobile': {
    gap: '24px',
  },
}));

export const InfoTableTitle = styled(Typography)(() => ({
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
  paddingBottom: '24px',
}));

export const DomianTableListElement = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const InfoTableSubtitle = styled(Typography)(() => ({
  whiteSpace: 'wrap',
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[900],
  '& span': {
    fontWeight: 500,
  },
}));

export const InfoTableSubtitleBold = styled(Box)(() => ({
  fontWeight: 500,
}));

export const DomainTable = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const InfoTableBullet = styled(Typography)(() => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  minWidth: '24px',
  minHeight: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '22px',
  borderRadius: '6px',
  boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  backgroundColor: stagesetColors.newGrey[50],
}));

export const InfoTableWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  gap: '10px',
  padding: '0px 0px 0px 32px',
}));

export const TableRowWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirectionL: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
}));

export const CellHeader = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[900],
  fontWeight: 500,
  marginBottom: '-4px',
}));

export const DomainCellTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
}));

export const CellInfo = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const SwitchTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
}));

export const TitleSwitchGroup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  cursor: 'pointer',
}));

export const CustomDomainDNSStatusText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[500],
  '&.dns-status-success': {
    color: stagesetColors.green[600],
  },
  '&.dns-status-multiple-dns': {
    color: stagesetColors.red[600],
  },
}));

export const CustomDomainDNSAlertWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  cursor: 'pointer',
  '& path': {
    stroke: stagesetColors.red[600],
  },
}));

export const CustomDomainContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '48px',
  gap: '24px',
}));
