import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { TabPanel } from '@mui/lab';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const FeedHeader = styled(Box)(() => ({
  fontSize: isMobile ? '18px' : '20px',
  display: 'flex',
  flexDirection: 'row',
  padding: isMobile ? '16px' : '16px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
  '&:not(.feed-header-transparent)': {
    backgroundColor: stagesetColors.white[100],
    boxShadow: ' 0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  },
  '&.feed-header-transparent': {
    backgroundColor: 'transparent',
  },
}));

export const DealFeedTabPanel = styled(TabPanel)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: isMobile ? '16px' : '24px',
  height: '100%',
  '&.MuiTabPanel-root': {
    padding: 0,
  },
}));

export const DealFeedTabItemContainer = styled(Box)(() => ({
  '&.share-preview-tab-container': {
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile ? '16px 24px' : '24px',
    gap: isMobile ? '16px' : '24px',
  },
  borderRadius: '16px',
  background: stagesetColors.white[100],
  // eslint-disable-next-line max-len
  boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
              + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
              + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
              + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
}));

export const DealFeedTabItemWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: isMobile ? '16px' : '24px',
}));

export const DealFeedTabSubtitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '26px',
  color: stagesetColors.newGrey[800],
}));

export const DealFeedContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '8px 0px',
}));

export const DealCustomizationContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
  flexDirection: 'column',
  padding: '0px 24px',
}));
