import {
  memo,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useOrganizationSettings } from '../../../../../hooks/useOrganizationSettings';
import CurrencySelector from '../../../../../shared/CurrencySelector/CurrencySelector';

// eslint-disable-next-line react/display-name
export const CurrencySelectorFullName = memo(() => {
  const { teamManager, organization } = useOrganizationSettings();

  const { control } = useFormContext();

  return (
    <Controller
      name="teamCurrency"
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <CurrencySelector
          disabled={!teamManager}
          initialValue={organization.currency}
          onSelect={onChange}
          error={error}
          size="l"
        />
      )}
    />
  );
});
