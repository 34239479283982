import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { Box, Menu, TextField } from '@mui/material';
import {
  $getSelection, $isRangeSelection, LexicalEditor, RangeSelection,
} from 'lexical';
import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { AppButton } from '../../AppButton/AppButton';
import { AppIconButton } from '../../AppButton/AppIconButton';

function getSelectedNode(selection: RangeSelection) {
  const { anchor } = selection;
  const { focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

interface Props {
  editor: LexicalEditor;
  closeEditor: () => void;
  linkEditMode: boolean;
  toolbarRef: MutableRefObject<HTMLDivElement | undefined>;
}

const LexicalLinkEditor = ({
  editor,
  closeEditor,
  linkEditMode,
  toolbarRef,
}: Props): JSX.Element => {
  const [linkUrl, setLinkUrl] = useState('');
  const [oldUrl, setOldUrl] = useState<string>('');

  const linkEditorRef = useRef<HTMLDivElement | null>(null);

  const onClose = () => {
    setLinkUrl('');
    closeEditor();
  };

  useEffect(() => {
    if (editor) {
      const editorState = editor.getEditorState();
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          const parent = node.getParent();
          if ($isLinkNode(parent)) {
            setLinkUrl(parent.getURL());
            setOldUrl(parent.getURL());
          } else {
            setLinkUrl('');
          }
        }
      });
    }
  }, [editor, linkEditMode]);

  return (
    <Box
      ref={linkEditorRef}
      sx={{
        height: '0px',
        width: '32px',
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        zIndex: 2,
      }}
    >
      <Menu
        open={linkEditMode}
        anchorEl={toolbarRef?.current}
        onClose={onClose}
        onMouseDown={(e) => e.stopPropagation()}
        PaperProps={{
          sx: {
            padding: '0px',
            width: toolbarRef?.current?.clientWidth ?? 'auto',
            boxShadow: 'initial',
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box
          className="LexicalRTELinkSettings"
          sx={{
            height: '38px',
            width: 'auto',
            borderRadius: '8px',
            background: '#F9FBFF',
            padding: '3px',
            display: 'flex',
            gap: '8px',
            position: 'relative',
            marginLeft: '1px',
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <AppIconButton
            onClick={onClose}
            icon={<ArrowBackIosRounded sx={{ fontSize: '12px' }} />}
          />
          <TextField
            fullWidth
            size="small"
            value={linkUrl}
            placeholder="Link"
            onChange={(e) => setLinkUrl(e.target.value)}
            sx={{
              height: '30px',
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: (theme) => theme.palette.grey[400],
                },
              },
            }}
            InputProps={{
              autoComplete: 'off',
              sx: {
                height: '30px',
              },
            }}
          />
          <AppButton
            variant="primary"
            size="m"
            onClick={() => {
              if (linkUrl !== '' && oldUrl !== linkUrl) {
                if (linkUrl.split(':')[0] === 'https') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: linkUrl, target: '_blank' });
                } else {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: `https://${linkUrl}`, target: '_blank' });
                }
              }
              if (!linkUrl) {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
              }
              onClose();
            }}
            sx={{
              minWidth: 'unset !important',
              paddingY: '0 !important',
              position: 'relative',
              top: '-1px',
            }}
          >
            Save
          </AppButton>
        </Box>
      </Menu>
    </Box>
  );
};

export default LexicalLinkEditor;
