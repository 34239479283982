import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import { TabContext } from '@mui/lab';
import TemplateDisplay from '../../features/TemplateDisplay/TemplateDisplay';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  clearTemplatesState,
  ETemplatesTypes,
  getTemplates,
  selectAllTemplates,
  selectTemaplatesLoadingStatus,
  selectTemplatesFilter,
  selectTemplatesTab,
} from './lib/templatesSlice';
import MobileTemplateDisplay from '../../features/TemplateDisplay/MobileTemplateDisplay';
import TemplatesTabsHeader from './TemplatesTabsHeader';
import TemplatePreviewHeader from './TemplatePreviewHeader';
import { stagesetColors, stagesetPalette } from '../../theme/stagesetPalette';
import { resetItemsSlice } from '../../features/ProcessFields/lib/processItemsSlice';
import TemplateTab from '../../features/TemplatesLists/TemplateTab';
import { useGetSectionTemplatesByOrgIdQuery, useGetStagesTemplatesByOrgIdQuery } from '../../features/ProcessFields/lib/processFieldsApi';
import { EStateStatus } from '../../core/commonTypes';

const Templates = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(selectTemplatesTab);
  const organizationId: string = useAppSelector((state) => state.app.organization.id);
  const roomsLoadingStatus = useAppSelector(selectTemaplatesLoadingStatus);
  const filter: string = useAppSelector(selectTemplatesFilter);
  const roomTemplates = useAppSelector(selectAllTemplates);
  const { data: pageTemplates, isSuccess: pagesLoaded } = useGetStagesTemplatesByOrgIdQuery({ organizationId, filter });
  const { data: sectionTemplates, isSuccess: sectionsLoaded } = useGetSectionTemplatesByOrgIdQuery({ organizationId, filter });

  useEffect(() => () => { dispatch(resetItemsSlice()); }, []);

  useEffect(() => {
    if (roomsLoadingStatus !== EStateStatus.LOADING) {
      dispatch(getTemplates(filter));
    }
    return () => dispatch(clearTemplatesState());
  }, [filter]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          background: stagesetPalette.background?.default,
        }}
      >
        <TemplatesTabsHeader />
        <Box
          sx={{
            height: isMobile ? 'calc( 100% - 136px )' : 'calc( 100% - 160px )',
            display: 'flex',
            flexDirection: 'row',
            width: 'calc( 100% - 0px )',
            gap: '24px',
            padding: isMobile ? '0' : '0 24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: isMobile ? '100%' : '300px',
              maxWidth: isMobile ? '100%' : '300px',
              minWidth: isMobile ? '100%' : '300px',
            }}
          >
            <TabContext
              value={currentTab}
            >
              <TemplateTab
                tabValue={ETemplatesTypes.ROOMS}
                templates={roomTemplates}
                isSuccess={roomsLoadingStatus === EStateStatus.IDLE}
              />
              <TemplateTab
                tabValue={ETemplatesTypes.PAGES}
                templates={pageTemplates}
                isSuccess={pagesLoaded}
              />
              <TemplateTab
                tabValue={ETemplatesTypes.SECTIONS}
                templates={sectionTemplates}
                isSuccess={sectionsLoaded}
              />
            </TabContext>
          </Box>
          {
            !isMobile
            && (
              <Box
                sx={{
                  height: 'calc( 100% - 34px)',
                  width: 'calc( 100% - 324px)',
                  maxWidth: 'calc( 100% - 324px)',
                  minWidth: 'calc( 100% - 324px)',
                  borderRadius: '20px 20px 0px 0px',
                  border: '2px solid',
                  borderColor: stagesetColors.white[100],
                  boxShadow: ' 0px 0px 18px -2px rgba(0, 57, 96, 0.1), 0px 0px 3px rgba(0, 57, 96, 0.1)',
                  overflow: 'hidden',
                }}
              >
                <TemplatePreviewHeader />
                <Box
                  sx={{
                    height: 'calc( 100% - 56px)',
                    background: stagesetPalette.background?.default,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: currentTab === ETemplatesTypes.SECTIONS ? '24px' : '',
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '12px 12px 0px 0px',
                      overflow: 'hidden',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <TemplateDisplay />
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
      {
        isMobile
        && (
        <MobileTemplateDisplay />
        )
      }
    </>
  );
};

export default Templates;
