import {
  Box,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { OrganizationDTO } from '../../../../../../shared/OrganizationDTO';
// import { withOrganizationDetailsUsers } from './withOrganizationDetailsUsers';
import { UserRolesDTO } from '../../../../../../shared/user-organization';
import UserDetailsItem from './UserDetailsItem';

interface Props {
  organization: OrganizationDTO;
  users: UserRolesDTO[];
  setUser: (user: Partial<UserRolesDTO>) => void;
}

const OrganizationDetailsUsers = ({
  organization,
  users,
  setUser,
}: Props): JSX.Element => {
  const [expandedId, setExpandedId] = useState('');
  const setExpanded = (expanded: string) => {
    setExpandedId(expanded);
  };

  return (
    <Box className="AdminOrganizationDetailsSection">
      <Typography
        className="OrganizationSectionTitle"
      >
        Users
      </Typography>
      <Box>
        {
          !!users && users.map((userRole: UserRolesDTO) => (
            userRole?.user && userRole?.user?.name && (
              <UserDetailsItem
                user={userRole}
                key={userRole.user?.id}
                isExpanded={userRole.user?.id === expandedId}
                setExpanded={setExpanded}
                organizationId={organization?.id}
                setUserObject={setUser}
              />
            )
          ))
        }
      </Box>
    </Box>
  );
};

export default OrganizationDetailsUsers;
