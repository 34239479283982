import { PaletteOptions } from '@mui/material';

interface StagesetPaletteOptions extends PaletteOptions {
  newGrey: Color;
  white: Color;
  black: Color;
}

enum ColorNames {
  background = 'background',
  grey = 'grey',
  newGrey = 'newGrey',
  orange = 'orange',
  red = 'red',
  green = 'green',
  blue = 'blue',
  white = 'white',
  black = 'black',
}

interface Color {
  [key: number]: string;
}

export const stagesetColors: Record<ColorNames, Color> = {
  background: {
    300: '#F2F3F7',
    200: '#F9FBFF',
    100: '#FCFDFD',
  },
  grey: {
    // new colors
    900: '#0F172A',
    800: '#1E293B',
    700: '#334155',
    //
    600: '#1A1929',
    500: '#455273',
    400: '#7084B4',
    300: '#A3B0D1',
    200: '#C8D0E0',
    100: '#EEF0FA',
  },
  // new colors
  newGrey: {
    900: '#0F172A',
    800: '#1E293B',
    700: '#334155',
    600: '#475569',
    500: '#64748B',
    400: '#94A3B8',
    300: '#CBD5E1',
    200: '#E2E8F0',
    100: '#F1F5F9',
    50: '#F8FAFC',
  },
  // new colors
  blue: {
    // temporary colors. Delete after redesign.
    800: '#0073F5',
    700: '#0073F5',
    //
    600: '#0073F5',
    500: '#0894E4',
    400: '#5CA7F5',
    300: '#83C2FF',
    200: '#B7DCFF',
    100: '#E5EFFF',
    50: '#F5F9FF',
  },
  green: {
    600: '#27AE60',
    500: '#4EC055',
    400: '#72CB74',
    300: '#9CD99D',
    200: '#C4E8C3',
    100: '#F0FAF1',
  },
  red: {
    600: '#C94937',
    500: '#FB5F4C',
    400: '#FF9385',
    300: '#FFB7AD',
    200: '#FFDBD6',
    100: '#FFEDEB',
  },
  orange: {
    600: '#FB982A',
    500: '#FFB142',
    400: '#FFBF60',
    300: '#FFD28C',
    200: '#FEE3B9',
    100: '#FFF3E1',
  },
  white: {
    100: '#fff',
  },
  black: {
    800: '#000',
  },
};

export const stagesetPalette: StagesetPaletteOptions = {
  primary: {
    main: stagesetColors.newGrey[800],
    light: stagesetColors.blue[400],
    dark: stagesetColors.blue[500],
    50: stagesetColors.newGrey[50],
    100: stagesetColors.newGrey[100],
    200: stagesetColors.newGrey[200],
    300: stagesetColors.newGrey[300],
    400: stagesetColors.newGrey[400],
    500: stagesetColors.newGrey[500],
    600: stagesetColors.newGrey[600],
    // temporary colors. Delete after redesign.
    700: stagesetColors.newGrey[700],
    800: stagesetColors.newGrey[800],
    //
  },
  background: {
    default: '#F9FBFF',
  },
  secondary: {
    main: stagesetColors.newGrey[200],
    light: stagesetColors.blue[200],
    dark: stagesetColors.blue[300],
  },
  black: {
    black: stagesetColors.black[800],
  },
  white: {
    white: stagesetColors.white[100],
  },
  grey: {
    // new colors
    800: stagesetColors.grey[800],
    700: stagesetColors.grey[700],
    600: stagesetColors.grey[600],
    500: stagesetColors.grey[500],
    400: stagesetColors.grey[400],
    300: stagesetColors.grey[300],
    200: stagesetColors.grey[200],
    100: stagesetColors.grey[100],
  },
  // new colors
  newGrey: {
    900: stagesetColors.newGrey[900],
    800: stagesetColors.newGrey[800],
    700: stagesetColors.newGrey[700],
    600: stagesetColors.newGrey[600],
    500: stagesetColors.newGrey[500],
    400: stagesetColors.newGrey[400],
    300: stagesetColors.newGrey[300],
    200: stagesetColors.newGrey[200],
    100: stagesetColors.newGrey[100],
  },
  // new colors
  success: {
    main: stagesetColors.green[600],
    light: stagesetColors.green[400],
    dark: stagesetColors.green[500],
    100: stagesetColors.green[100],
    200: stagesetColors.green[200],
    300: stagesetColors.green[300],
    400: stagesetColors.green[400],
    500: stagesetColors.green[500],
    600: stagesetColors.green[600],
  },
  error: {
    main: stagesetColors.red[600],
    light: stagesetColors.red[100],
    dark: stagesetColors.red[200],
    600: stagesetColors.red[600],
    500: stagesetColors.red[500],
    400: stagesetColors.red[400],
    300: stagesetColors.red[300],
    200: stagesetColors.red[200],
    100: stagesetColors.red[100],
  },
  warning: {
    600: stagesetColors.orange[600],
    500: stagesetColors.orange[500],
    400: stagesetColors.orange[400],
    300: stagesetColors.orange[300],
    200: stagesetColors.orange[200],
    100: stagesetColors.orange[100],
  },
};
